import CarCoveragesDesktopSkeleton from './skeleton/car-coverages-desktop-skeleton';
import { TableData } from '../../../../../../../shared/interfaces/table';
import Table from '../../../../../../ui-elements/table/table';
import styles from './car-coverages-desktop.module.scss';

export interface CarCoveragesDesktopProps {
	coveragesTable?: TableData;
	loading?: boolean;
	noCoveragesText?: string;
}

export function CarCoveragesDesktop({ coveragesTable, loading, noCoveragesText }: CarCoveragesDesktopProps): JSX.Element {
	return loading ? (
		<CarCoveragesDesktopSkeleton />
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={'carCoveragesDesktop'}>
				{coveragesTable ? (
					<Table
						data={coveragesTable}
						className={'w-full CustomerDataMock mx-auto table-fixed carCoveragesDesktop__table'}
						tdClassName={'text-center'}
					/>
				) : (
					<>{noCoveragesText || 'No coverage'}</>
				)}
			</div>
		</>
	);
}
export default CarCoveragesDesktop;
