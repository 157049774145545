import { numberToLocaleWithcurrency } from '../../../../shared/services/utils.service';
import { addAlphaToColor } from '../../../../shared/services';
import { Button, Spinner } from '../../../../lib/ui-elements';
import { FlightGroup } from '../../../../lib/flights/shared';
import { ModelTypeEnum } from '../../../../shared/enums';
import styles from './fare-card.module.scss';
import { useState } from 'react';

export interface FareCardProps {
	colorPrimary?: string;
	fareGroup?: FlightGroup;
	useAlphaBackground?: boolean;
	t: (value: string) => string;
	businessModel?: ModelTypeEnum;
	markedRecommendation?: (fareGroup: FlightGroup) => void;
	onlySelectedRecommendation?: boolean;
}

export function FareCard({
	t,
	fareGroup,
	colorPrimary,
	businessModel,
	useAlphaBackground,
	markedRecommendation,
	onlySelectedRecommendation,
}: FareCardProps): JSX.Element {
	const alphaColorPrimary: string | undefined = colorPrimary ? addAlphaToColor(colorPrimary, 0.8) : undefined;
	const [isLoader, setIsLoader] = useState<boolean>(false);
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`fare-card relative flex flex-col rounded-lg pb-[15px] pt-[25px] w-72 ${useAlphaBackground ? 'scale-95' : ''}-`}
				style={{
					background: useAlphaBackground ? alphaColorPrimary : colorPrimary,
				}}
			>
				<div className='flex-grow flex flex-col px-[30px]'>
					<div className='flex items-start justify-between w-full mb-2'>
						<div>
							<p className='fare-card__title text-white mb-0 pt-[10px] text-[16px] leading-none'>
								<span className='fare-card__fareRate'>{t('rate')}:</span>
								{fareGroup?.labelRangePrice && <span className='fare-card__fareRangePrice ml-1 text-white'>{fareGroup.labelRangePrice}</span>}
							</p>
							<p className='fare-card__fareName text-white mb-0 text-[10px]'>{fareGroup?.brandName}</p>
						</div>

						<div className='fare-card__baggage flex items-center justify-end'>
							{fareGroup?.baggageInformation.map((baggage, index) => (
								<span key={index} className={`${baggage.type} text-white ${!baggage.isIncluded ? 'opacity-60' : ''}`}></span>
							))}
						</div>
					</div>

					<div className='flex items-center mb-2'>
						<span className='text-xs py-[1px] px-[4px] bg-white text-black rounded-full max-w-[40px] min-w-[20px] text-center mr-2 hidden'></span>
						<span className='text-xs py-[1px] px-[4px] bg-white text-black rounded-full max-w-[80px] min-w-[20px] text-center'>
							{fareGroup?.brandId}
						</span>
						{!!fareGroup?.classOfService && (
							<span className='ml-1 text-xs py-[1px] px-[10px] bg-white text-black rounded-full max-w-[80px] min-w-[20px] text-center'>
								{fareGroup?.classOfService}
							</span>
						)}
					</div>

					<div className='fare-card__items flex-grow border-b border-white mb-2 pb-2'>
						{fareGroup?.brandFeaturesSummary.map((brandFeature, index) => (
							<div key={index}>
								<p className='text-white flex items-center mb-2'>
									<span
										className={`
                ${brandFeature.anciliaryType === 'included' ? 'checkRoundIco' : ''}
                ${brandFeature.anciliaryType === 'notIncluded' ? 'unCheckRoundIco' : ''}
                ${brandFeature.anciliaryType === 'cost' ? 'cashIco' : ''}
                text-base mr-2`}
									></span>
									<span className='fare-card__items-text text-xs'>{brandFeature.description}</span>
								</p>
							</div>
						))}
					</div>

					<div className='fare-card__baggage mb-2 hidden'>
						{fareGroup?.baggageInformation.map((baggage, index) => (
							<div key={index}>
								<p className='flex items-center text-white text-xs flex justify-between mb-2'>
									<span className='fare-card__baggage-title'>{t(baggage.type)}:</span>
									<span className='fare-card__baggage-text'>{baggage.isIncluded ? t('include') : t('noIncluded')}</span>
								</p>
							</div>
						))}
					</div>

					<div className='fare-card__price my-4'>
						<div className='fare-card__pricePassengers flex items-center mb-3 hidden'>
							<p className='fare-card__pricePassengers-type text-xs text-white'>
								{t('adult')}: <span>1</span>
							</p>
							<p className='fare-card__pricePassengers-type text-xs text-white ml-2'>
								{t('children')}: <span>0</span>
							</p>
							<p className='fare-card__pricePassengers-type text-xs text-white ml-2'>
								{t('infant')}: <span>0</span>
							</p>
						</div>

						{businessModel === ModelTypeEnum.POINT_OR_MONEY && (
							<div className='fare-card__pricePointsOrCash'>
								<p className='text-white text-xs fare-card__priceTotal'>{t('totalflight')}:</p>

								<p className='text-white fare-card__priceTotal fare-card__grandTotalFormated'>
									{fareGroup?.fareGroupSummary?.totalPoints ? (
										<>
											{numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.totalPoints)}{' '}
											<span className=''>{fareGroup?.fareGroupSummary?.currencyPoints}</span>
										</>
									) : (
										<>
											{numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.totalFare as number, fareGroup?.fareGroupSummary?.currency)}{' '}
											<span className=''>{fareGroup?.fareGroupSummary?.currency}</span>
										</>
									)}
								</p>

								{!!fareGroup?.fareGroupSummary?.totalPoints && !!fareGroup?.fareGroupSummary?.totalFare && (
									<p className='text-white text-xs fare-card__pricePlusCash my-1'>
										{!!fareGroup?.fareGroupSummary?.totalPoints && t('+')}{' '}
										{numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.totalFare, fareGroup?.fareGroupSummary?.currency)}
										<span className='ml-1'>{fareGroup?.fareGroupSummary?.currency}</span>
									</p>
								)}

								{!!fareGroup?.fareGroupSummary?.equivalentInCash && (
									<p className='recommendation-v2__price-item recommendation-v2__price-otherValue text-xs text-white'>
										<span className='infoCirlce2Ico'></span> {t('saveUp')} {fareGroup.fareGroupSummary.equivalentInCash}
									</p>
								)}
							</div>
						)}

						{businessModel === ModelTypeEnum.CASH && (
							<div className='fare-card__priceCash'>
								<p className='text-white text-xs fare-card__priceTotal'>{t('totalflight')}:</p>

								<p className='text-white text-xs fare-card__priceTotal'>
									{numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.totalFare as number, fareGroup?.fareGroupSummary?.currency as string)}
									{fareGroup?.fareGroupSummary?.currency}
								</p>
							</div>
						)}

						{businessModel === ModelTypeEnum.POINTS && (
							<div className='fare-card__pricePoints'>
								<p className='text-white text-xs fare-card__priceTotal'>{t('totalflight')}:</p>

								<p className='text-white text-xs fare-card__priceTotal'>
									{numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.totalPoints as number)}
									{fareGroup?.fareGroupSummary?.currencyPoints}
								</p>
							</div>
						)}

						{businessModel === ModelTypeEnum.POINT_PLUS_MONEY && <></>}
					</div>
				</div>

				{!onlySelectedRecommendation && (
					<div className='fare-card__actions px-[20px]'>
						<Button
							type='button'
							text={t('toSelect')}
							customClass='text-black !bg-white rounded-lg w-full'
							content={isLoader ? <Spinner /> : <>{t('toSelect')}</>}
							onClick={() => {
								if (markedRecommendation && fareGroup) {
									fareGroup.isSelected = true;

									setIsLoader(true);

									markedRecommendation(fareGroup);
								}
							}}
						/>
					</div>
				)}
			</div>
		</>
	);
}

export default FareCard;
