import { HotelsDisneyResumeAsideWidgetProps } from '../../widgets/hotels-disney-search/hotels-disney-resume-aside/hotelsDisneyResumeAsideWidget';
import { AddonsInterface, TicketSubmitInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/hotels/addons.interface';
import { RecommendationInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/hotels/recommendation.interface';
import { HotelDisneyAddonsRequest } from '../../shared/interfaces/hotel-disnay-addons-request.interface';
import { HotelsDisneyService } from '../../shared/services/hotels-disney.service';
import { useDebounce, Spinner } from '@smartlinks/react-design-system';
import { useCallback, useState, lazy, Suspense } from 'react';
import { TFunction } from 'react-i18next';

const HotelsDisneyResumeAside = lazy(async () => {
	return await import('@smartlinks/react-design-system').then(module => {
		return { default: module.HotelsDisneyResumeAside };
	});
});

interface HotelsDisneyResumeAsideTemplateProps extends HotelsDisneyResumeAsideWidgetProps {
	hotelsDisneyResumeAsideService: HotelsDisneyService;
	t: TFunction<'translation', undefined>;
	hotel: RecommendationInterface;
}
function hotelsDisneyResumeAside({
	agency,
	hotelsDisneyResumeAsideService,
	hotel,
	t,
	callUrlSearch,
	resultAddons,
}: HotelsDisneyResumeAsideTemplateProps): JSX.Element {
	const addonsDebounceHandler = useDebounce();
	const [addons, setAddons] = useState<AddonsInterface[] | undefined>();
	const searchAddonsHandler = useCallback(
		(date: Date): void => {
			addonsDebounceHandler((value: Date): void => {
				let addonsList: AddonsInterface[] | undefined = [];
				try {
					void (async (tempValue: Date) => {
						const request: HotelDisneyAddonsRequest = {
							provider: hotel.provider,
							hotelId: hotel.id,
							roomId: hotel.rooms.innerCode,
							rateId: hotel.rooms.rateId,
							checkIn: tempValue,
							adultsQuantity: hotel.rooms.numberOfAdults,
							childrenQuantity: hotel.rooms.numberOfChildren,
							searchId: hotel.searchId,
							childrenAges: hotel.childrenAges,
						};

						addonsList = await hotelsDisneyResumeAsideService.getAddons(request);

						setAddons(addonsList);
					})(value);

					return;
				} catch (error) {
					console.error(error);
				}
				setAddons(addonsList);
			}, 300)(date);
		},
		[hotelsDisneyResumeAsideService, addonsDebounceHandler],
	);

	const searchHandler = useCallback(
		(data: TicketSubmitInterface): void => {
			const dataForm: TicketSubmitInterface = {
				...data,
			};

			if (resultAddons != null) {
				resultAddons({ ...dataForm });
			}
		},
		[hotelsDisneyResumeAsideService, agency, resultAddons],
	);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<HotelsDisneyResumeAside
					hotel={hotel}
					addons={addons}
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					t={t}
					emitDateSearch={searchAddonsHandler}
					emitSubmitSearch={searchHandler}
				/>
			</Suspense>
		</div>
	);
}

export default hotelsDisneyResumeAside;
