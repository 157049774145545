import { SlideInterface, SlideConfigInterface, SlideStylesInterface } from '../../../../../../shared/interfaces/generics/slider.interface';
import Slide from '../../../../../shared-components/slide/slide';

export interface SliderSkeletonProps {
	slides: number;
	slidesHeight?: string;
	vertical?: boolean;
	slideConfig?: SlideConfigInterface;
	isOverflowResponsive?: boolean;
	minWidthResponsive?: string;
	customContentSlides?: JSX.Element[];
	className?: string;
	outerClassName?: string;
	slideClassNames?: SlideStylesInterface;
}

export function SliderSkeleton({
	slides,
	slidesHeight,
	vertical,
	slideConfig,
	isOverflowResponsive,
	minWidthResponsive,
	customContentSlides,
	className,
	outerClassName,
	slideClassNames,
}: SliderSkeletonProps): JSX.Element {
	let slidesComponent: JSX.Element[] | null = null;
	if (customContentSlides && customContentSlides.length === slides) {
		slidesComponent = new Array(slides).fill(0).map((item: number, index: number) => {
			return (
				<Slide
					key={index}
					height={slidesHeight}
					data={{} as unknown as SlideInterface}
					config={slideConfig}
					loading={true}
					skeletonClassNames={slideClassNames}
				>
					{customContentSlides[index]}
				</Slide>
			);
		});
	} else {
		slidesComponent = new Array(slides).fill(0).map((item: number, index: number) => {
			return (
				<Slide
					key={index}
					height={slidesHeight}
					data={{} as unknown as SlideInterface}
					config={slideConfig}
					loading={true}
					skeletonClassNames={slideClassNames}
				/>
			);
		});
	}

	return (
		<div
			className={`sliderSkeleton animate-pulse ${isOverflowResponsive ? 'relative overflow-auto md:overflow-visible' : ''} ${outerClassName || ''}`}
		>
			<div
				className={`flex ${isOverflowResponsive ? 'overflow-auto md:overflow-visible' : ''} ${vertical ? 'flex-col' : 'flex-row'} ${className || ''}`}
				style={{
					minWidth: isOverflowResponsive ? minWidthResponsive : undefined,
				}}
			>
				{slidesComponent}
			</div>
		</div>
	);
}

SliderSkeleton.defaultProps = {
	className: '',
	outerClassName: '',
	slideClassName: '',
	slideImageClassName: '',
	slideContentClassName: '',
	slideTitleClassName: '',
	slideSubtitleClassName: '',
	slideDescriptionClassName: '',
	slideSubtitleLineClassName: '',
	slideButtonClassName: '',
};

export default SliderSkeleton;
