import useWindowWidth from '../../../../../shared/hooks/useWidth';
import useDebounce from '../../../../../shared/hooks/useDebounce';
import { MapUtil } from '../../../../../shared/utils/map.util';
import { useState, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';

/* eslint-disable-next-line */
export interface StaticMapProps {
	token: string;
	longitude: number;
	latitude: number;
	width: string;
	height: string;
	color?: string;
	mobileAdjustToWindow?: boolean;
	showInColor?: boolean;
}

export function StaticMap({ token, longitude, latitude, width, height, color, mobileAdjustToWindow, showInColor }: StaticMapProps): JSX.Element {
	const responsiveSize: number = 768;
	const [staticMapHtml, setStaticMapHtml] = useState<JSX.Element>();
	const windowWidth: number = useWindowWidth();
	const debounceHandler = useDebounce();
	const mapUtil: MapUtil = new MapUtil();
	const initMap = debounceHandler(() => {
		const tempWidth: string = mobileAdjustToWindow && windowWidth && windowWidth < responsiveSize ? String(windowWidth) : width;
		const mapElement: JSX.Element = mapUtil.initStaticMap(token, longitude, latitude, tempWidth, height, color, showInColor);
		setStaticMapHtml(mapElement);
	}, 300);

	useEffect(() => {
		if (!longitude || !latitude) {
			return;
		}

		initMap();
	}, [token, mobileAdjustToWindow, windowWidth, width, height, longitude, latitude, color]);

	if (!token) {
		return <>invalid token</>;
	}

	return <div className='staticMap'>{staticMapHtml}</div>;
}

export default StaticMap;
