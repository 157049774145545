import { FieldValues, UseFormGetValues, UseFormSetValue, ValidationRule } from 'react-hook-form';
import { BindTypes } from '../enums/dynamic-forms.enum';
import {
	Condition,
	DynamicFormErrorLabel,
	ElementSets,
	OptionsItems,
	RegexCondition,
	RegisterPropsInterface,
} from '../interfaces/dynamic-forms.interface';

export class DynamicFormService {
	private readonly getValues: UseFormGetValues<FieldValues>;

	constructor(getValues: UseFormGetValues<FieldValues>) {
		this.getValues = getValues;
	}

	public registerProps(elementSet: ElementSets): {
		errorData: DynamicFormErrorLabel;
		registerProps: RegisterPropsInterface;
	} {
		let registerProps: RegisterPropsInterface = {};
		const errorData: DynamicFormErrorLabel = {
			bind: elementSet.bindType,
			label: elementSet.label || '',
		};

		if (elementSet.elementStyle.max) {
			registerProps = {
				...registerProps,
				maxLength: Number(elementSet.elementStyle.max),
				minLength: Number(elementSet.elementStyle.min || 0),
			};
		}

		const regexCondition: RegexCondition | undefined = elementSet.elementStyle.regexCondition;
		if (regexCondition?.bind && regexCondition?.regex) {
			const pattern: ValidationRule<RegExp> | undefined = new RegExp(regexCondition.regex);
			registerProps = {
				...registerProps,
				pattern,
			};
		}

		return {
			errorData,
			registerProps,
		};
	}

	public inputValidator(inputValue: string, elementSet: ElementSets): boolean {
		const elementValue: string = inputValue;
		let controlFlag: boolean = true;

		if (!elementValue && elementSet.isRequired) {
			controlFlag = false;
		}

		if (elementSet?.elementStyle?.regexCondition?.bind) {
			const elementTargetName: BindTypes = elementSet.elementStyle.regexCondition.bind;
			const elementTargetValue: string = this.getValues(elementTargetName);
			const getRegex: Condition | undefined = elementSet.elementStyle.regexCondition.condition.find(
				(condition: Condition) => condition.value === elementTargetValue,
			);

			const pattern: ValidationRule<RegExp> | undefined = new RegExp(getRegex?.regex || '');
			if (getRegex?.regex && !pattern.test(elementValue)) {
				controlFlag = false;
			}
		}

		return controlFlag;
	}

	public dependencyValidator(
		elementSet: ElementSets,
		elementSets: ElementSets[],
		setValue?: UseFormSetValue<FieldValues>,
		element?: { target: HTMLSelectElement; form: HTMLFormElement },
	): void {
		const bindDependency: BindTypes | undefined = elementSet.elementStyle?.dependency;
		const firstPosition: number = 0;
		if (bindDependency) {
			const getElementDependency: ElementSets | undefined = elementSets.find((element: ElementSets) => element.bindType === bindDependency);
			const currentValue: string = this.getValues(elementSet.bindType);
			const validateDefaultValue: string | undefined =
				currentValue || elementSet.elementStyle.defaultValue || elementSet.elementStyle.option?.items[firstPosition].value;

			getElementDependency?.elementStyle.option?.items.forEach((item: OptionsItems) => {
				item.hidden = false;

				if (item.dependencyValue !== validateDefaultValue) {
					item.hidden = true;
				}
			});

			if (getElementDependency && !!setValue) {
				const getFirstOptionSelected = getElementDependency?.elementStyle.option?.items.find(item => !item.hidden);
				setValue(getElementDependency.bindType, getFirstOptionSelected?.value);

				if (element) {
					const getDependencySelect = element.target?.form?.querySelector(`select[name="${getElementDependency.bindType}"]`);
					const dependencyOptions = getDependencySelect?.querySelectorAll('option');
					dependencyOptions?.forEach((item: HTMLOptionElement) => {
						const getOptionValue: string = item.value;
						getElementDependency?.elementStyle.option?.items.forEach(optionItem => {
							if (optionItem.value === getOptionValue) {
								optionItem.hidden ? item.classList.add('hidden') : item.classList.remove('hidden');
							}
						});
					});
				}
			}
		}
	}
}
