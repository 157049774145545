{
	"elementNofound": "Element for render no found",
	"byDay": "por día",
	"coverages": "Coberturas",
	"transmission": "Transmisión",
	"BasicPlus": "Total",
	"Only": "Sólo carro",
	"onlyCar": "Sólo carro",
	"Basic": "Básica",
	"Full": "Total + combustible",
	"thisCoverageIncludes": "Esta cobertura incluye",
	"automatic": "Automático",
	"manual": "Manual",
	"amountPassengers": "Cantidad pasajeros",
	"amountDoors": "Cantidad puertas",
	"airConditioning": "Aire acondicionado",
	"amountBaggage": "Cantidad maletas",
	"theftProtectionPartial": "TP-Seguro robo( Parcial )",
	"collisionDamageWaiver": "Seguro de Colisión Choque y Robo (CDW – TP)",
	"superCollisionDamageWaiver": "SCDW-Super Cover.",
	"personalAccidentInsurances": "PAI-Seguro personal contra accidentes.",
	"theftProtection": "TP-Seguro contra robo.",
	"taxes": "TAX-Impuestos",
	"insurances": "Seguros",
	"lowDamageWaiver": "LDW-Seguro colisión y robo",
	"liabilitySupplementalInsurance": "LIS-Daños a terceros",
	"secondDriver": "ADDDRV-2° conductor",
	"fullGas": "GAS-Un tanque de gasolina",
	"book": "Reservar",
	"dailyRateText": "Día",
	"yes": "Si",
	"couldNotGetAgency": "Lo sentimos, no se pudo obtener la agencia",
	"noResultsForSelectedDates": "El servicio de renta de auto no está disponible en este destino.",
	"collisionDamageWaiverPartial": "CDW-Seguro colisión ( Parcial ).",
	"selectYourDestination": "Selecciona tu destino",
	"dropOffLocation": "Lugar de entrega",
	"addYourDestination": "Agrega tu destino",
	"selectYourDates": "Selecciona tus fechas",
	"labelDates": "Fechas",
	"addYourDates": "Agrega tus fechas",
	"selectYourDiscount": "Selecciona tu descuento",
	"withoutDiscount": "Sin descuento",
	"payOnLine": "Pago en línea",
	"discounts": "Descuentos",
	"discount": "Descuento",
	"selectADiscount": "Selecciona un descuento",
	"haveMyOwnDiscount": "Tengo mi propio descuento",
	"promotionalCode": "Código promocional",
	"selectApromotionalCode": "Selecciona un código promocional",
	"haveMyOwnPromotionalCode": "Tengo mi propio código promocional",
	"hertzGold": "Hertz Gold ",
	"enterTheCodeHertzGold": "Ingresa tu código Hertz",
	"enterYourHertzGoldCodeHere": "Ingresa aqui tu código hertz gold",
	"add": "Agregar",
	"youSaveUpToDescription": "Ahorras hasta un 30% seleccionando ésta opción.",
	"paymentOnArrival": "Pago en destino",
	"startYourSearch": "Empieza tu busqueda",
	"returnTheCarInTheSamePlace": "Devolver el auto en el mismo lugar",
	"searchByLocations": "Busca por localidades",
	"selectHours": "Seleccionar horas",
	"pickup": "Recogida",
	"return": "Devolución",
	"ready": "Listo",
	"enterADiscount": "Ingrese un descuento",
	"enterAPromoCode": "Ingrese un código promocional",
	"saveUpByAddingYourGoldHertzCode": "Ahorra hasta un 30% agregando tu código Gold Hertz",
	"next": "Siguiente",
	"back": "Atrás",
	"finalize": "Finalizar",
	"getBack": "Regresar",
	"viewDeal": "Ver oferta",
	"airportList": "Lista de Aeropuertos",
	"cityList": "Lista de ciudades",
	"selectYourDelivery": "Selecciona tu entrega",
	"selectYourHertzGold": "Selecciona tu Hertz Gold",
	"withoutHertzGold": "Sin Hertz Gold",
	"noHertzGold": "¿No tienes Hertz Gold?",
	"addItHere": "Agrégalo aquí",
	"select": "Seleccionar",
	"localities": "Localidades",
	"localittiesNoFound": "Lo sentimos, no hemos encontrado ningún resultado. Te recomendamos a buscar de nuevo con otra ciudad.",
	"results": "Resultados",
	"selectYourDiscounts": "Selecciona tus descuentos",
	"weSorry": "Lo sentimos",
	"search": "Buscar",
	"returnTheCarInOtherPlace": "Devolver el auto en otro lugar",
	"first": "Primera",
	"economic": "Económica",
	"business": "Negocio",
	"economy": "Turista",
	"premiumEconomy": "Económica Premium",
	"premiumFirst": "Primera Premium",
	"premiumBusiness": "Ejecutiva Premium",
	"allClassesLabel": "Todas las clases",
	"origin": "Origen",
	"destination": "Destino",
	"passengersAndClass": "Pasajeros & Clase",
	"selectTheClass": "Elige la clase",
	"adult": "Adulto",
	"adults": "Adultos",
	"adultsDescription": "Igual o mayores de 12 años",
	"child": "Niño",
	"children": "Niños",
	"childrenDescription": "Entre 2 y 11 años",
	"infant": "Infante",
	"infants": "Infantes",
	"infantsDescription": "Menores de 2 años",
	"all": "Todas",
	"comeBack": "Volver",
	"roundTrip": "Ida y vuelta",
	"onewayTrip": "Sólo ida",
	"multiTrip": "Multidestino",
	"airline": "Aerolínea",
	"searchTheAirline": "Busca la aerolínea",
	"onlyDirectFlights": "Sólo vuelos directos",
	"addFlight": "Agregar trayecto",
	"removeFlight": "Quitar trayecto",
	"startYourFlightsSearch": "Empieza tu busqueda",
	"ageOfChild": "Edad del Menor",
	"adultsAndChildren": "Adultos y Menores",
	"room": "Habitación",
	"removeRoom": "Eliminar Habitación",
	"addRoom": "Agregar Habitación",
	"destinationsList": "Lista de Destinos",
	"selectYourDate": "Selecciona tu fecha",
	"passengers": "Pasajeros",
	"passenger": "Pasajero",
	"ageOfPassenger": "Edad del pasajero",
	"startYourHotelsSearch": "Empieza tu busqueda",
	"startYourActivitiesSearch": "Empieza tu busqueda",
	"howManyDays": "¿Cuántos días en los parques?",
	"withPromoCode": "Tengo un código promocional",
	"promoCode": "Código promocional",
	"errorDateMinFlightMulti": "La fecha debe ser mayor a la anterior",
	"freeEntranceMessage": "Entrada gratuita para menores de 3 años (al momento de entrar al parque).",
	"adultsDisneyDescription": "Mayores de 10 años",
	"childrenDisneyDescription": "Entre 3 y 9 años",
	"infantDisneyDescription": "Niños menores de 3 años ingresan gratis al parque",
	"Mundial": "Mundial",
	"Domestic": "Nacional",
	"LATAM": "América latina",
	"USA": "Estados unidos",
	"EU": "Europa",
	"childOrAdult": "Joven o adulto",
	"childrenOrAdults": "Jovenes o adultos",
	"childrenAndAdults": "Jovenes y adultos",
	"olderAdult": "Adulto mayor",
	"olderAdults": "Adultos mayores",
	"olderAdultsDescription": "Entre 75 y 85 años",
	"childrenAndAdultsDescription": "Entre 0 y 74 años",
	"annualCoverage": "Cobertura anual",
	"datesMessage": "Selecciona una estadía de máximo 28 días.",
	"parks": "Parque",
	"allParks": "Todos los Parques (Orlando)",
	"checkIn": "Fecha de ingreso",
	"checkOut": "Fecha de retiro",
	"one": "1",
	"two": "2",
	"addYourTicketsToPackages": "Agrega tus entrada a parques",
	"selectDate": "Selecciona la fecha de tu entrada para conocer el precio total.",
	"days": "Dias",
	"improveTicket": "Mejora tu tipo de entrada",
	"VisitMultipleParks": "Visita un parque o varios parques por día, ¡varios parques con más diversión adicional!",
	"entrance": "Entrada",
	"price": "Precio",
	"nights": "Noches",
	"plusSign": "+",
	"packages": "paquetes",
	"of": "de",
	"taxesFees": "Impuestos y cargos: Incluidos",
	"disney": "disney",
	"reservationDetails": "Detalles de reserva",
	"reserve": "Reservar",
	"addonsAlert": "Lo sentimos, actualmente no tenemos parques disponibles para tu selección; por favor intenta otro día de ingreso a parque",
	"selectYourNationality": "Selecciona tu nacionalidad",
	"country": "País",
	"searchYourCountry": "Busca tu país",
	"startDisneyExperience": "Comienza tu Experiencia Disney",
	"buyYourStandardTickets": "Compra tus entradas estándar o escoge",
	"moreOptionsToEnjoy": "más opciones para disfrutar de tu estadía",
	"theMoreDaysYouChoose": "Entre más días escojas, menos pagarás por día.",
	"rememberCheckAvailabilityParks": "Recuerda verificar la disponibilidad de los parques antes de comprar",
	"seeCalendar": "Ver calendario",
	"class": "Clase",
	"passengersAndAirlines": "Pasajeros & aerolíneas",
	"optionalLabel": "Opcional",
	"requiredField": "Completa este campo",
	"cancel": "Cancelar",
	"apply": "Aplicar",
	"selectAnOrigin": "Selecciona un origen",
	"selectOrigin": "Seleccionar origen",
	"selectADestination": "Selecciona un destino",
	"selectDestination": "Seleccionar destino",
	"selectAnOption": "Selecciona una opción",
	"maximumAgeRestrictionMessageForChildren": "El valor debe ser igual/menor a 17",
	"messageMinimumAgeRestrictionForChildren": "El valor debe ser igual/mayor a 1",
	"points": "Puntos",
	"consultMyPoints": "Consultar mis puntos",
	"inputYourData": "Ingrese los datos de su",
	"bacCreditCard": "Tarjeta de crédito BAC",
	"forConsultingPoints": "para consultar cuántos puntos o millas tiene acumulados.",
	"cardHolderName": "Nombre del titular",
	"cardNumber": "Número de tarjeta",
	"expirationDate": "Fecha de expiración",
	"cvc": "CVV",
	"consulting": "Consultando...",
	"consult": "Consultar",
	"availableKey": "disponibles",
	"toConsultPoints": "Para consultar sus puntos o millas,",
	"mustEnterData": "debe ingresar una tarjeta de crédito de BAC.",
	"consultedCreditCard": "Tarjeta de crédito consultada",
	"close": "Cerrar",
	"consultAnotherCard": "Consultar otra tarjeta",
	"carsDisclaimer": "Por el momento este servicio no está disponible para destinos nacionales",
	"ticketsForSpecialEvents": "Tickets para Eventos Especiales",
	"buyNow": "Comprar ahora",
	"selectYourPassengers": "Selecciona pasajeros",
	"betweenOneAndSeventeenYears": "Entre 1 y 17 años",
	"overEighteenYears": "Mayor de 18 años",
	"flight": "Vuelo",
	"addAnotherFlight": "Agregar otro vuelo",
	"searchFlights": "Buscar vuelos",
	"remove": "Eliminar",
	"whereTravel": "¿A dónde viaja?",
	"whenTravel": "¿Cuándo viaja?",
	"travelFrom": "Viajo desde",
	"travelTo": "Viajo hacia",
	"departureDate": "Fecha de salida",
	"returnDate": "Fecha de regreso",
	"verifyInformation": "Para continuar, verifique la siguiente información:",
	"verifyStatusCard": "• Valide el estatus de su tarjeta.",
	"validateCardAffiliated": "• Valide que su tarjeta está afiliado a un plan de lealtad de puntos o millas.",
	"checkCardAgain": "Consulte de nuevo su tarjeta ",
	"contactCustomer": "o contacte a servicio al cliente de BAC para más información.",
	"unableVerifyCard": "No pudimos consultar la tarjeta ingresada",
	"guests": "Húespedes",
	"selectPickup": "Seleccionar recogida",
	"selectReturn": "Seleccionar devolución"
}
