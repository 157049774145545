import { FlightLegSummary, FlightSearchResult } from '../../shared/interfaces/flight-search-result.interface';
import { numberToLocaleWithcurrency } from '../../../../shared/services/utils.service';
import RecommendationDetail from '../recommendation-detail/recommendation-detail';
import SkeletonRecommendationV2 from './skeleton/recommendation-v2-skeleton';
import { ModelTypeEnum, ToolTipArrowType } from '../../../../shared/enums';
import { ToolTip } from '../../../../lib/ui-elements';
import { useEffect, useState } from 'react';

export interface RecommendationV2Props {
	callBack?: (data: FlightSearchResult) => void;
	t: (value: string) => string;
	colorPrimary?: string;
	toolTipText?: string;
	tagTitle?: string;
	pillMessageText?: string;
	flightData: FlightSearchResult[];
	onlySelectedRecommendation?: boolean;
	skeletonRecommendation?: number;
}

export function RecommendationV2({
	onlySelectedRecommendation,
	skeletonRecommendation,
	colorPrimary,
	toolTipText,
	pillMessageText,
	flightData,
	tagTitle,
	callBack,
	t,
}: RecommendationV2Props): JSX.Element {
	const [flightResult, setFlightResult] = useState<FlightSearchResult[]>([]);
	const [openDetail, setOpenDetail] = useState<boolean>(false);
	const [flighDetailData, setFlighDetailData] = useState<FlightSearchResult>();
	useEffect(() => {
		if (openDetail) {
			(document.querySelector('body') as HTMLBodyElement).style.overflow = 'hidden';

			return;
		}

		(document.querySelector('body') as HTMLBodyElement).style.overflow = 'auto';
	}, [openDetail]);

	useEffect(() => {
		if (!flightData?.length) {
			setOpenDetail(false);
		}

		if (onlySelectedRecommendation) {
			filterSelectedRecommendationReadOnly(flightData);
		}

		setFlightResult(flightData || []);
	}, [flightData, onlySelectedRecommendation]);

	const openDetailComponent = (flight: FlightSearchResult): void => {
		setFlighDetailData(flight);

		setOpenDetail(true);
	};

	const toolTipPerPassenger = (): JSX.Element => {
		return (
			<ToolTip
				arrowType={ToolTipArrowType.bottom}
				title={`<span class="infoCirlceIco" style="color: ${colorPrimary || ''}"></span>`}
				colorPrimary={'black'}
				body={`
      <p class="recommendation-v2__price-tooltip text-center text-xs text-white whitespace-nowrap">
        ${toolTipText || ''}
      </p>
      `}
				customClass={'pt-[5px] ml-[5px] relative hidden lg:flex'}
				toolTipCustomClass={'!left-auto !top-[-49px] w-[128px] !right-[-34px]'}
				arrowClassName={'!left-auto !right-[35px]'}
			/>
		);
	};

	const toolTipLegs = (legs: FlightLegSummary[]): JSX.Element => {
		let body = '';
		legs.forEach((leg: FlightLegSummary) => {
			body += `<div class='toolTipLegs flex items-center justify-between text-xs mr-[auto] ml-[auto]'>
        <div class='toolTipLegs__container w-[80px]'>
          <p class="toolTipLegs__name">${leg.layoverName}</p>
          <p class="toolTipLegs__time">${leg.layoverTime}</p>
        </div>
        <div class='toolTipLegs__line w-[40px]'>
          -------
        </div>
        <div class='toolTipLegs__container w-[80px]'>
          <p>${t('wait')}</p>
          <p>${leg.waitTime}</p>
        </div>
      </div>`;
		});

		return (
			<ToolTip
				arrowType={ToolTipArrowType.bottom}
				title={`<span class="infoCirlceIco opacity-0" style="color: ${colorPrimary || ''} "></span>`}
				colorPrimary={'black'}
				body={body}
				customClass={'pt-[5px] ml-[5px] flex justify-center top-[-8px] !absolute w-full left-0 right-0 hidden lg:flex'}
				toolTipCustomClass={' flex min-w-[221px] !top-auto bottom-[14px] !left-auto !right-auto mx-auto'}
			/>
		);
	};

	const filterSelectedRecommendationReadOnly = (flight: FlightSearchResult[]): void => {
		flight.forEach(itemFlight => {
			itemFlight.faresGroup = itemFlight.faresGroup.filter(fareGroup => !!fareGroup?.isSelected);
		});
	};

	return (
		<>
			{!flightData?.length
				? Array.from(Array(skeletonRecommendation || 10).keys()).map((item, index) => (
					<div key={index} className='mb-[25px]'>
						<SkeletonRecommendationV2 />
					</div>
				  ))
				: flightResult?.map((flight, index) => {
					const { flightInformation, businessModel, trackType } = flight;
					return (
						<div
							key={index}
							className={'recommendation-v2 rounded-lg bg-white shadow-lg cursor-pointer mb-[25px]'}
							onClick={() => openDetailComponent(flight)}
						>
							<div className={'recommendation-v2__container flex flex-col md:flex-row px-[20px] md:px-[35px] py-[12px]'}>
								<div className='recommendation-v2__container-item flex flex-col md:flex-row w-full md:w-[70%]'>
									<div className={'recommendation-v2__info w-full md:w-[40%] md:mr-[2px]'}>
										<div className={'recommendation-v2__departureOrArrival flex flex-col'}>
											<p
												className='recommendation-v2__departureOrArrivalText  flex items-center justify-between md:justify-start'
												style={{ color: colorPrimary }}
											>
												<span
													className={`${
														trackType === 'departure' ? 'flyPlaneIco' : 'icon-icon-plane-return-gray'
													} hidden md:block text-[21px] mr-[10px]`}
												></span>
												<span className='uppercase recommendation-v2__title'>{t(trackType || '') || '---'}</span>

												{tagTitle && (
													<>
														{(businessModel === ModelTypeEnum.POINT_OR_MONEY || businessModel === ModelTypeEnum.POINTS) &&
															!!flightInformation?.fareSummary?.points ? (
																<span
																	className='recommendation-v2__tag md:hidden text-white rounded-full px-4 text-xs py-[2px]'
																	style={{ background: colorPrimary }}
																>
																	{tagTitle || 'tagTitle'}
																</span>
															) : (
																<></>
															)}
													</>
												)}
											</p>

											<p className='recommendation-v2__subtile md:hidden'>
												{flightInformation.departureLocation.name} ({flightInformation.departureLocation.code}) -{' '}
												{flightInformation.arrivalLocation.name} ({flightInformation.arrivalLocation.code})
											</p>
										</div>

										<div>
											<span className='text-xs recommendation-v2__date'>{flightInformation.departure.dateFormated}</span>
										</div>

										<div className='items-center hidden md:flex'>
											<img src={flightInformation.airline.logoUrl} alt='' width={30} height={30} />
											<span className='text-xs recommendation-v2__airline w-[100px] ml-1'>{flightInformation.airline.name}</span>
											<div className='flex items-center hidden'>
												<span className='text-xs py-[1px] px-[4px] bg-black text-white rounded-full max-w-[40px] min-w-[20px] text-center mr-2'></span>
												<span className='text-xs py-[1px] px-[4px] bg-black text-white rounded-full max-w-[40px] min-w-[20px] text-center'></span>
											</div>
										</div>
										<span className='recommendation-v2__seeFareDetail text-xs hidden' style={{ color: colorPrimary }}>
											{t('seeFareDetail')}
										</span>
									</div>

									<div className={'recommendation-v2__dates w-full md:w-[60%] text-center'}>
										<div className='flex items-center justify-around pt-[4px]'>
											<div>
												<p className={'recommendation-v2__dates-code'}>{flightInformation.departureLocation.code}</p>
												<p className={'recommendation-v2__dates-name text-xs'}>{flightInformation.departureLocation.name}</p>
												<p className={'recommendation-v2__dates-hour'}>{flightInformation.departure.timeFormated}</p>
											</div>

											<div className='flex flex-col justify-center items-center mx-2 md:w-[35%] relative'>
												{!!flightInformation?.legSummary?.length && toolTipLegs(flightInformation?.legSummary)}
												<p className={'recommendation-v2__dates-scale text-xs'}>
													{t(flightInformation.stops)}
													{flightInformation.stops !== 'direct' && <span className='ml-1 hidden lg:inline infoCirlceIco'></span>}
												</p>
												<div className='hidden md:block border-b border-gray-500 h-[1px] w-full'></div>
												<p className={'recommendation-v2__dates-scaleHour text-xs'}>{flightInformation.totalTimeOfFlightFormated}</p>
											</div>

											<div>
												<p className={'recommendation-v2__dates-code'}>{flightInformation.arrivalLocation.code}</p>
												<p className={'recommendation-v2__dates-name text-xs'}>{flightInformation.arrivalLocation.name}</p>
												<p className={'recommendation-v2__dates-hour'}>{flightInformation.arrival.timeFormated}</p>
											</div>
										</div>

										<div className='flex items-center md:hidden'>
											<img src={flightInformation.airline.logoUrl} alt='' width={30} height={30} />
											<span className='text-xs recommendation-v2__airline w-[60px]'>{flightInformation.airline.name}</span>
											<div className='flex items-center hidden'>
												<span className='text-xs py-[1px] px-[4px] bg-black text-white rounded-full max-w-[40px] min-w-[20px] text-center mr-2'></span>
												<span className='text-xs py-[1px] px-[4px] bg-black text-white rounded-full max-w-[40px] min-w-[20px] text-center'></span>
											</div>
										</div>
									</div>
								</div>

								<div
									className={`recommendation-v2__price ${
										!tagTitle ? 'pt-[20px]' : ''
									} border-t border-gray-500 md:border-t-0 w-full flex flex-col items-end md:w-[30%]`}
								>
									{tagTitle && (
										<>
											{(businessModel === ModelTypeEnum.POINT_OR_MONEY || businessModel === ModelTypeEnum.POINTS) &&
												!!flightInformation?.fareSummary?.points ? (
													<>
														{!!flightInformation?.fareSummary?.equivalentInCash && (
															<span className='recommendation-v2__price-item recommendation-v2__subTag text-xs'>{t(tagTitle || 'tagTitle')}</span>
														)}

														<span
															className='recommendation-v2__price-item recommendation-v2__tag hidden md:block text-white rounded-full px-4 text-xs py-[2px]'
															style={{ background: colorPrimary }}
														>
															{flightInformation?.fareSummary?.equivalentInCash ? t(pillMessageText || '') : tagTitle || 'tagTitle'}
														</span>
													</>
												) : (
													<>
														{businessModel !== ModelTypeEnum.POINT_OR_MONEY && businessModel !== ModelTypeEnum.POINTS && (
															<span
																className='recommendation-v2__price-item recommendation-v2__tag hidden md:block text-white rounded-full px-4 text-xs py-[2px]'
																style={{ background: colorPrimary }}
															>
																{tagTitle || 'tagTitle'}
															</span>
														)}
													</>
												)}
										</>
									)}
									<p className='recommendation-v2__price-item text-xs recommendation-v2__price-from'>{t('fromTo')}</p>
									{businessModel === ModelTypeEnum.POINT_OR_MONEY && (
										<>
											<div className='recommendation-v2__price-item flex items-center'>
												<p className='recommendation-v2__price-value whitespace-nowrap leading-none'>
													{flightInformation?.fareSummary?.points ? (
														<>
															{numberToLocaleWithcurrency(flightInformation.fareSummary.points)} {flightInformation.fareSummary.pointsCurrency}
														</>
													) : (
														<>
															{numberToLocaleWithcurrency(flightInformation.fareSummary.amount, flightInformation.fareSummary.currency)}{' '}
															{flightInformation.fareSummary.currency}
														</>
													)}
												</p>
												{!!toolTipText && toolTipPerPassenger()}
											</div>
											{!!flightInformation?.fareSummary?.equivalentInCash && (
												<p className='recommendation-v2__price-item recommendation-v2__price-otherValue text-xs'>
													<span className='infoCirlce2Ico'></span> {t('saveUp')} {flightInformation.fareSummary.equivalentInCash}
												</p>
											)}
											{!!flightInformation?.fareSummary?.points && !!flightInformation?.fareSummary?.amount && (
												<p className='recommendation-v2__price-item recommendation-v2__price-otherValue text-xs'>
													{t('+')}
													{numberToLocaleWithcurrency(flightInformation.fareSummary.amount, flightInformation.fareSummary.currency)}
													<span className='ml-1'>{flightInformation.fareSummary.currency}</span>
												</p>
											)}
										</>
									)}

									{businessModel === ModelTypeEnum.CASH && (
										<>
											<div className='recommendation-v2__price-item flex items-center'>
												<p className='recommendation-v2__price-value whitespace-nowrap leading-none'>
													{numberToLocaleWithcurrency(flightInformation.fareSummary.amount, flightInformation.fareSummary.currency)}{' '}
													{flightInformation.fareSummary.currency}
												</p>
												{!!toolTipText && toolTipPerPassenger()}
											</div>
										</>
									)}

									{businessModel === ModelTypeEnum.POINTS && (
										<>
											<div className='recommendation-v2__price-item flex items-center'>
												<p className='recommendation-v2__price-value whitespace-nowrap leading-none'>
													{numberToLocaleWithcurrency(flightInformation.fareSummary.points)} {flightInformation.fareSummary.pointsCurrency}
												</p>
												{!!toolTipText && toolTipPerPassenger()}
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					);
				  })}

			{openDetail && flighDetailData && (
				<RecommendationDetail
					colorPrimary={colorPrimary}
					onlySelectedRecommendation={onlySelectedRecommendation}
					t={t}
					closeCallback={result => {
						if (result) {
							callBack && callBack(result);
						}

						setOpenDetail(false);
					}}
					flightDetail={flighDetailData}
				/>
			)}
		</>
	);
}

export default RecommendationV2;
