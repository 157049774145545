export const validateTypeObject = (value: {}): {} => {
	Object.keys(value).forEach(key => {
		if (Array.isArray(value[key])) {
			value[key] = value[key]
				.map((item: {}) => {
					const key: string[] = Object.keys(item);
					const value: unknown[] = Object.values(item);
					if (value.some(isTrutly => isTrutly)) {
						return key[0];
					}

					return null;
				})
				.filter((item: string) => !!item)
				.join();
		}
	});

	return value;
};
