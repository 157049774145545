import { ProductType } from '../../../shared/enums/product.enum';
import { Historical } from '../../../shared/interfaces/historical';

export const historicalMock: Historical[] = [
	{
		id: 'b865b0e2-9ef9-41c3-a055-bf3fed4fa834',
		productType: ProductType.hotel,
		providerCode: '235-7557821',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: '4fd6ecec-36d2-4af8-92f2-c2d40ebae47d',
		productType: ProductType.air,
		providerCode: 'ZPFTUS',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: 'f62ad4b8-e500-46e3-9355-465c4f84d483',
		productType: ProductType.hotel,
		providerCode: '296-709012',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 2500.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: 'ab92e6f7-5906-447c-9365-f10a5f3fa9fa',
		productType: ProductType.hotel,
		providerCode: '296-709011',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: '5c2fd330-fa54-4030-84b3-979dcc71fbb6',
		productType: ProductType.hotel,
		providerCode: '235-7398875',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: 'a742e8aa-6059-4466-aeca-b0256a373bc8',
		productType: ProductType.hotel,
		providerCode: '235-7398841',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: 'fef625d7-3c0b-4f75-a5a0-ac10223728c4',
		productType: ProductType.hotel,
		providerCode: '235-7398868',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: '1badccd5-8c0c-459d-8954-7511370c093d',
		productType: ProductType.car,
		providerCode: '235-7398908',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: '5aa4cb90-4312-465b-80a1-b751c62764cc',
		productType: ProductType.assistance,
		providerCode: '235-7398907',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
	{
		id: '149e8ce5-eae1-434d-9c9c-e1f0bd0120cf',
		productType: ProductType.activity,
		providerCode: '235-7428740',
		status: 'active',
		bookingDate: '2022-09-13T23:14:12.23Z',
		bookingDateFormated: '13 septiembre 2022',
		departureDate: '2023-07-04T00:00:00Z',
		fareSummary: {
			totalPoints: 0.0,
			totalCash: 1670.0,
			currency: 'MXN',
			pointsCurrency: 'ThankYou Points',
		},
	},
];
