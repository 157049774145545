import { DisneyDetailContextType, useDisneyDetailContext } from '../../disney/components/special-event-detail/special-event-detail';
import { DatePickerDefaultData, DatePickerColors } from '../../../shared/interfaces/date-picker.interface';
import { InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { validateCalendarRestrictions } from '../../../shared/utils';
import { DateLocales } from '../../../shared/enums/date.enum';
import styles from './date-picker-availability.module.scss';
import { useState, useEffect, useCallback } from 'react';
import DatePicker from '../date-picker/date-picker';
import { format, isEqual } from 'date-fns';

export interface DatePickerAvailabilityProps {
	name?: string;
	defaultData?: DatePickerDefaultData;
	locale?: DateLocales;
	minDate?: Date;
	maxDate?: Date;
	useButton?: boolean;
	colors?: DatePickerColors;
	onChange?: (e: InputEventsHandler) => void;
	className?: string;
	disabledDates?: Date[];
	availabilityDates?: Date[];
	t: (value: string) => string;
	disabledButtons?: boolean;
	calendarRestriction?: number;
}

export const DatePickerAvailability = ({
	name,
	defaultData,
	locale,
	minDate,
	maxDate,
	colors,
	onChange,
	className,
	disabledDates,
	availabilityDates,
	t,
	disabledButtons,
	calendarRestriction,
}: DatePickerAvailabilityProps): JSX.Element => {
	const [disabledDatesHandle, setDisabledDatesHandle] = useState<Date[]>([]);
	const { disneyDetail }: DisneyDetailContextType = useDisneyDetailContext();
	useEffect(() => {
		if (disabledDates) {
			setDisabledDatesHandle(disabledDates);
		}
	}, [disabledDates]);

	const customDayContent = (day: Date): JSX.Element => {
		const canSellThisDay: boolean = !!availabilityDates?.some(activeDate => {
			activeDate.setHours(0, 0, 0, 0);

			if (validateCalendarRestrictions(calendarRestriction || 0, activeDate)) {
				return false;
			}

			return isEqual(activeDate, day);
		});

		if (canSellThisDay) {
			if (disabledButtons) {
				disabledDatesHandle.push(day);
			}

			return (
				<span className='datePickerAvailability__activeDay py-[13px] px-[15px] rounded-md text-white' style={{ background: colors?.main }}>
					{format(day, 'd')}
				</span>
			);
		}

		disabledDatesHandle.push(day);

		return <span className='datePickerAvailability__inactiveDay'>{format(day, 'd')}</span>;
	};

	const onChangeDate = useCallback(
		(e: InputEventsHandler): void => {
			if (onChange) {
				onChange(e);
			}
		},
		[onChange, disneyDetail],
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`datePickerAvailability flex flex-col md:flex-row ${className || ''}`}>
				<div className='datePickerAvailability__calendar'>
					<DatePicker
						locale={locale}
						defaultData={defaultData?.startDate ? defaultData.startDate : new Date()}
						onChange={onChangeDate}
						colors={{
							main: colors?.main || '#FDD008',
							second: colors?.second || '#494848',
							third: colors?.third || '#7A8083',
						}}
						useButton={false}
						name={name || 'dateRange'}
						months={1}
						dayContentRenderer={customDayContent}
						disabledDates={disabledDatesHandle || []}
						minDate={minDate || new Date()}
						maxDate={maxDate}
					/>
				</div>

				<div className='datePickerAvailability__availabilityActions flex md:flex-col flex-wrap md:pt-[63px]'>
					<div className='flex items-center mr-4 mb-4'>
						<span
							className='datePickerAvailability__actionAvailability rounded-md w-[19px] h-[19px] bg-gray-500 mr-4'
							style={{ background: colors?.main || '' }}
						></span>
						<span className='datePickerAvailability__bold text-xs'>{t('availability')}</span>
					</div>

					<div className='flex items-center mr-4 mb-4'>
						<span className='datePickerAvailability__actionAvailability rounded-md w-[19px] h-[19px] bg-gray-500 mr-4'></span>
						<span className='datePickerAvailability__bold text-xs'>{t('noAvailability')}</span>
					</div>

					<div className='flex items-center mb-4'>
						<span className='datePickerAvailability__actionAvailability rounded-md w-[19px] h-[19px] bg-[#87AB5B] mr-4'></span>
						<span className='datePickerAvailability__bold text-xs'>{t('selected')}</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default DatePickerAvailability;
