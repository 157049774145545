import useCarSearchForm, { UseCarSearchFormInterface, UseCarSearchFormReturnInterface } from '../../../../shared/hooks/useCarSearchForm';
import DestinationField from '../../../../../shared-components/search/destination-field/destination-field';
import CarDestinations from '../../../../components/cars/car-search-form/car-destinations/car-destinations';
import DiscountField from '../../../../../shared-components/search/discount-field/discount-field';
import { SpreadedCarSearchFormMobile } from '../spreaded-form-mobile/spreaded-form-mobile';
import { CarSearchPaymentType } from '../../../../shared/enums/car-search-payment-type.enum';
import DatesField from '../../../../../shared-components/search/dates-field/dates-field';
import { DatesFieldTypes } from '../../../../../../shared/enums/search-fields.enum';
import { CarSearchFormProps, CarSearchFormRefHandle } from '../car-search-form';
import { ToolTipArrowType } from '../../../../../../shared/enums/tooltip.enum';
import { ListItemInterface } from 'shared/interfaces/list.interface';
import ToolTip from '../../../../../ui-elements/tool-tip/tool-tip';
import Button from '../../../../../ui-elements/button/button';
import styles from './spreaded-car-search-form.module.scss';
import { forwardRef, useRef } from 'react';

export const SpreadedCarSearchForm = forwardRef<CarSearchFormRefHandle, CarSearchFormProps>(
	(
		{
			mapBoxToken,
			destinations,
			traductions,
			calendarRestriction,
			colorPrimary,
			carsInSitePayment,
			colorSecondary,
			isLocationsLoading,
			carHistory,
			disabledPaymentType,
			emitInput,
			openLocalities,
			emitOptionActive,
			corporateDiscounts,
			corporateDiscountDefault,
			promoCodesWithoutCorporateDiscountCode,
			promoCodeWithoutCorporateDiscountCodeDefault,
			isNotHome,
			emitSubmitSearch,
			emitShowMobileFields,
			widgetStyles,
			language,
		},
		ref,
	) => {
		const defaultHour: string = '12:00';
		const {
			departureName,
			departureHiddenName,
			arrivalName,
			arrivalHiddenName,
			paymentTypeName,
			datesName,
			datesHiddenName,
			discountName,
			hertzDiscountName,
			departureId,
			arrivalId,
			datesId,
			discountId,
			hertzDiscountId,
			paymentType,
			paymentOptions,
			minDate,
			defaultDates,
			departureFieldRef,
			arrivalFieldRef,
			showOtherPlaceForArrival,
			showMobileFieldsContainer,
			mobileFieldStep,
			dateMobileFieldStep,
			mobileFormHeight,
			showMobileFieldsContainerHandler,
			onSubmit,
			nextMobileFieldHandler,
			backMobileFieldHandler,
			changePaymentTypeHandler,
			emitHandler,
			destinationsOtherPlaceForArrivalHandler,
			openDepartureLocalitiesHandler,
			openArrivalLocalitiesHandler,
			selectedDestination,
			changeDatesHandler,
			changeHertzDiscountHandler,
			getDiscountDisplayValue,
			changeDiscountPaymentTypeHandler,
			removeRewardsPointsDiscountValue,
			errors,
			isValid,
			register,
			getValues,
			setValue,
			trigger,
			watch,
		}: UseCarSearchFormReturnInterface = useCarSearchForm({
			ref,
			traductions,
			defaultHour,
			calendarRestriction,
			carsInSitePayment,
			carHistory,
			corporateDiscountDefault,
			promoCodeWithoutCorporateDiscountCodeDefault,
			isNotHome,
			disabledPaymentType,
			widgetStyles,
			openLocalities,
			emitOptionActive,
			emitInput,
			emitSubmitSearch,
			emitShowMobileFields,
			language,
			isSpreadForm: true,
		} as unknown as UseCarSearchFormInterface);

		const formRef = useRef<HTMLFormElement | null>(null);
		const { isHiddenPaymentMethodsInCars, isSecondaryTemplate, isEnabledTruncatedInputs, isCarsDisclaimerVisibled, isTertiaryTemplate } =
			widgetStyles || {};

		const handleSubmitButtonClick = (): void => {
			if (formRef.current) {
				formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
			}
		};

		const showOtherPlaceForArrivalSecondaryClass: string = showOtherPlaceForArrival ? 'md:w-4/6 pl-0' : 'md:w-3/6 md:pr-4';
		return (
			<>
				<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
				<div className='spreadedCarSearchForm__searchButton block md:hidden'>
					<SpreadedCarSearchFormMobile
						departureValue={watch(departureName) || ''}
						arrivalValue={watch(arrivalName) || ''}
						datesValue={watch(datesName) || ''}
						traductions={traductions}
						showMobileFieldsContainerHandler={showMobileFieldsContainerHandler}
						isSecondaryTemplate={!!widgetStyles?.isSecondaryTemplate}
						isTertiaryTemplate={!!widgetStyles?.isTertiaryTemplate}
						colorSecondary={colorSecondary ?? ''}
						truncate={widgetStyles?.isEnabledTruncatedInputs}
					>
						<div className={'flightsSearchForm__footer flex items-end'}>
							<Button
								type='submit'
								customClass={`flightsSearchForm__searchBtn text-white w-full text-[21px] items-center rounded-full
								${!isValid ? 'disabled cursor-not-allowed ' : ''}
								${isTertiaryTemplate ? 'rounded-none tertiary__shadow' : ''}
								${isTertiaryTemplate && !isValid ? 'tertiary__disabled' : ''}
								`}
								content={<span className={'text-base !text-[14px]'}>{traductions.searchText || 'search'}</span>}
								styles={{
									background: isValid ? colorSecondary ?? '#878787' : '#D1D1D1 ',
									color: isValid ? '' : '#9B9B9B',
									pointerEvents: !isValid ? 'none' : 'auto',
								}}
								disabled={!isValid}
								onClick={handleSubmitButtonClick}
							/>
						</div>
					</SpreadedCarSearchFormMobile>
				</div>
				<div className={'spreadedCarSearchForm flex flex-col'}>
					<form
						ref={formRef}
						onSubmit={onSubmit}
						className={`spreadedCarSearchForm__form w-full top-0 left-0 md:flex flex-col ${
							showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
						}
						${widgetStyles?.isTertiaryTemplate ? 'z-[2] md:z-auto' : ''}`}
						style={{
							height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
						}}
					>
						<div
							className='spreadedCarSearchForm__header block md:hidden pt-[33px] pb-[53px] px-[45px]'
							style={{
								backgroundColor: colorSecondary,
							}}
						>
							<button
								onClick={() => showMobileFieldsContainerHandler(false)}
								className='spreadedCarSearchForm__backBtn chevronLeftIco !p-0 text-white text-base font-bold'
								type='button'
							>
								<span className='pl-[10px]'>{traductions.getBackBtn || 'getBack'}</span>
							</button>
						</div>
						<div
							className={
								'spreadedCarSearchForm__body bg-white md:bg-transparent left-0 top-[-20px] md:top-0 relative md:h-auto px-0 pt-[20px] md:pt-0 rounded-t-[20px] md:rounded-t-none'
							}
						>
							<div className='spreadedCarSearchForm__toolbar hidden md:flex flex-row justify-between items-center mb-[10px]'>
								<div className={`spreadedCarSearchForm__paymentType flex items-center ${isHiddenPaymentMethodsInCars ? 'hidden' : ''}`}>
									{!disabledPaymentType?.onrental && (
										<label className='flex items-center'>
											<input
												type='radio'
												{...register(paymentTypeName, {
													onChange: changePaymentTypeHandler,
													value: paymentType,
												})}
												value={CarSearchPaymentType.onrental}
												className='m-0'
											/>
											<span
												className={`text-base font-extrabold pl-[10px] font-family-regular
												${isTertiaryTemplate ? 'text-[#4D4D4F] font-medium' : 'text-white'} 
											`}
											>
												{traductions?.typePayment?.parentTitle || 'payOnArrival'}
											</span>
										</label>
									)}
									{!disabledPaymentType?.online && (
										<label className='flex items-center ml-[15px]'>
											<input
												type='radio'
												{...register(paymentTypeName, {
													onChange: changePaymentTypeHandler,
												})}
												value={CarSearchPaymentType.online}
												className='m-0'
											/>
											<span
												className={`text-base font-extrabold pl-[10px] font-family-regular
												${isTertiaryTemplate ? 'text-[#4D4D4F] font-medium' : 'text-white'}
											`}
											>
												{traductions?.typePayment?.title || 'online'}
											</span>
											<ToolTip
												colorPrimary={colorPrimary}
												body={`
													<p class="onlinePaymentType__toolTipText text-xs text-white m-0 max-w-[160px]">
														${traductions?.onlinePaymentTypeTooltip || ''}
													</p>
													`}
												customClass='h-auto pt-[5px] ml-[5px] relative hidden'
												titleClassName='m-0'
												toolTipCustomClass={'onlinePaymentType__toolTip'}
												arrowType={ToolTipArrowType.left}
												alwaysOpen={true}
											/>
										</label>
									)}
								</div>
							</div>
							<div className='spreadedCarSearchForm__content relative'>
								<div className='flex flex-row flex-wrap'>
									<div
										className={`spreadedCarSearchForm__destinationContent w-full ${
											isSecondaryTemplate ? showOtherPlaceForArrivalSecondaryClass : 'md:w-3/5 md:pr-[15px]'
										}
											${isSecondaryTemplate ? 'isSecondaryTemplate' : ''}
											${isTertiaryTemplate ? 'md:w-3/6' : ''}
										`}
									>
										{isTertiaryTemplate && (
											<span className='hidden md:flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
												{traductions?.destinations?.title}
											</span>
										)}
										<div
											className={`spreadedCarSearchForm__destination w-full flex flex-row relative ${
												isSecondaryTemplate
													? 'isSecondaryTemplate items-start gap-8'
													: 'items-center  md:rounded-[10px] md:border md:border-solid bg-white'
											}
												${isTertiaryTemplate ? 'border-[#BEBEBE] md:rounded-none' : ''}
											`}
										>
											<DestinationField
												ref={departureFieldRef}
												fieldId={departureId}
												name={departureName}
												hiddenName={departureHiddenName}
												iconClassName={isSecondaryTemplate ? 'iconMarkerSecondary text-[#98A2B3]' : 'pinIco'}
												title={traductions.destinations.title}
												placeHolder={traductions.destinations.secondPlaceHolder}
												backButtonTraduction={traductions.backBtn}
												nextButtonTraduction={traductions.nextBtn}
												errorRequiredFieldTraduction={traductions.errorRequiredField}
												showSpinner={isLocationsLoading}
												colorSecondary={colorSecondary}
												className={`${mobileFieldStep === departureId ? 'z-50' : 'z-0'} md:z-auto  
													${isTertiaryTemplate ? 'border-0 md:border-solid border-[#BEBEBE]' : ''}`}
												required={true}
												register={register}
												setValue={setValue}
												getValues={getValues}
												trigger={trigger}
												errors={errors}
												useBackButton={false}
												onChange={emitHandler}
												onShowDestinations={emitOptionActive}
												nextCallBack={nextMobileFieldHandler}
												visibleTitle={isSecondaryTemplate}
												isSecondaryTemplate={isSecondaryTemplate}
												isTertiaryTemplate={isTertiaryTemplate}
												truncate={isEnabledTruncatedInputs}
												destinationsElement={
													<CarDestinations
														mapBoxToken={mapBoxToken}
														colorSecondary={colorSecondary}
														traductions={traductions}
														otherDestinationFlag={!showOtherPlaceForArrival}
														destinations={destinations || []}
														callBack={(item: ListItemInterface) => selectedDestination(item, departureName)}
														callBackShowOtherArrivalPlace={destinationsOtherPlaceForArrivalHandler}
														callBackOpenLocalities={openDepartureLocalitiesHandler}
														isSecondaryTemplate={isSecondaryTemplate}
														isTertiaryTemplate={isTertiaryTemplate}
													/>
												}
											/>
											{
												<DestinationField
													ref={arrivalFieldRef}
													fieldId={arrivalId}
													name={arrivalName}
													hiddenName={arrivalHiddenName}
													iconClassName={isSecondaryTemplate ? 'iconMarkerSecondary text-[#98A2B3]' : 'pinIco'}
													title={traductions.destinationsArrival.title}
													placeHolder={traductions.destinationsArrival.secondPlaceHolder}
													backButtonTraduction={traductions.backBtn}
													nextButtonTraduction={traductions.nextBtn}
													errorRequiredFieldTraduction={traductions.errorRequiredField}
													showSpinner={isLocationsLoading}
													colorSecondary={colorSecondary}
													className={`w-full ${mobileFieldStep === arrivalId ? 'z-50' : 'z-0'} md:z-auto ${
														isTertiaryTemplate ? '' : 'spreadedCarSearchForm__arrivalDestination'
													}`}
													required={showOtherPlaceForArrival}
													register={register}
													setValue={setValue}
													getValues={getValues}
													trigger={trigger}
													errors={errors}
													useBackButton={true}
													onChange={emitHandler}
													onShowDestinations={emitOptionActive}
													nextCallBack={nextMobileFieldHandler}
													backCallBack={backMobileFieldHandler}
													visibleTitle={isSecondaryTemplate}
													isSecondaryTemplate={isSecondaryTemplate}
													isTertiaryTemplate={isTertiaryTemplate}
													truncate={isEnabledTruncatedInputs}
													destinationsElement={
														<CarDestinations
															mapBoxToken={mapBoxToken}
															colorSecondary={colorSecondary}
															traductions={traductions}
															destinations={destinations || []}
															callBack={(item: ListItemInterface) => selectedDestination(item, arrivalName)}
															callBackOpenLocalities={openArrivalLocalitiesHandler}
															isSecondaryTemplate={isSecondaryTemplate}
															isTertiaryTemplate={isTertiaryTemplate}
														/>
													}
												/>
											}
										</div>
									</div>
									<div
										className={`spreadedCarSearchForm__dates w-full relative ${
											isSecondaryTemplate ? (showOtherPlaceForArrival ? 'md:w-2/6 md:pl-8' : 'md:w-3/6 md:pl-4') : 'md:w-2/5'
										} ${isSecondaryTemplate ? 'isSecondaryTemplate' : ''}
											${isTertiaryTemplate ? 'md:w-3/6' : ''}
										`}
									>
										{isTertiaryTemplate && (
											<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>{traductions?.dates?.title}</span>
										)}
										<DatesField
											fieldId={datesId}
											title={traductions.dates.title}
											placeHolder={traductions.dates.placeHolder}
											name={datesName}
											hiddenName={datesHiddenName}
											backButtonTraduction={traductions.backBtn}
											nextButtonTraduction={traductions.nextBtn}
											timeText={traductions?.datesCalendar?.time}
											startTimeText={traductions?.datesCalendar?.startTime}
											endTimeText={traductions?.datesCalendar?.endTime}
											buttonText={isSecondaryTemplate ? traductions?.apply : traductions?.datesCalendar?.button}
											cancelText={traductions?.cancel}
											type={DatesFieldTypes.startEndDates}
											language={language}
											config={{
												date: true,
												time: true,
											}}
											minDate={minDate}
											defaultDates={defaultDates}
											forceMobileStep={dateMobileFieldStep}
											useBackButton={true}
											iconClassName={
												isSecondaryTemplate
													? 'iconCalendarSecondary text-[#98A2B3]'
													: widgetStyles?.isTertiaryTemplate
														? 'iconCalendarDepartureTertiary'
														: 'searchCalendarIco'
											}
											visibleTitle={isSecondaryTemplate}
											isSecondaryTemplate={isSecondaryTemplate}
											isTertiaryTemplate={isTertiaryTemplate}
											errorRequiredFieldTraduction={traductions.errorRequiredField}
											required={true}
											colorPrimary={isTertiaryTemplate ? '#4D4D4D' : colorPrimary}
											colorSecondary={colorSecondary}
											colorThird={isSecondaryTemplate ? '#344054' : ''}
											className={`w-full ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
											register={register}
											setValue={setValue}
											getValues={getValues}
											trigger={trigger}
											watch={watch}
											errors={errors}
											nextCallBack={nextMobileFieldHandler}
											backCallBack={backMobileFieldHandler}
											onChange={changeDatesHandler}
											onShowDates={emitOptionActive}
										/>
									</div>
								</div>
								<div
									className={`spreadedCarSearchForm__discountContent flex flex-row flex-wrap md:mt-[20px] ${
										isSecondaryTemplate && !isNotHome ? 'md:justify-end' : ''
									}`}
								>
									<div
										className={`flex flex-row ${isSecondaryTemplate && !isNotHome ? 'w-full md:justify-end' : 'md:w-4/5'}
										${isTertiaryTemplate ? 'w-full' : ''} `}
									>
										<div
											className={`spreadedCarSearchForm__hertzDiscount hidden md:block md:w-3/3 w-full relative ${
												isSecondaryTemplate && !isNotHome ? 'md:pr-4' : 'md:pr-[8px]'
											} ${isSecondaryTemplate ? 'isSecondaryTemplate' : ''}`}
										>
											{isTertiaryTemplate && (
												<span className='hidden md:flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>{traductions.hertzGold.title}</span>
											)}
											<DiscountField
												fieldId={hertzDiscountId}
												title={traductions.hertzGold.title}
												placeHolder={traductions.hertzGold.secondPlaceHolder}
												name={hertzDiscountName}
												backButtonTraduction={traductions.backBtn}
												nextButtonTraduction={traductions.nextBtn}
												rewardsPointsMajorTitle={traductions?.hertzGold?.thirdTitle}
												rewardsPointsTitle={traductions?.hertzGold?.title}
												rewardsPointsPlaceHolder={traductions?.hertzGold?.placeHolder}
												buttonText={traductions?.actionBtn || traductions?.endBtn}
												config={{
													discount: false,
													promotionalCode: false,
													rewardsPoints: true,
												}}
												useBackButton={true}
												colorSecondary={colorSecondary}
												className={'w-full z-0 md:z-auto'}
												required={false}
												register={register}
												setValue={setValue}
												getValues={getValues}
												trigger={trigger}
												errors={errors}
												nextCallBack={nextMobileFieldHandler}
												backCallBack={backMobileFieldHandler}
												onShowDiscounts={emitOptionActive}
												onChangeDiscounts={changeHertzDiscountHandler}
												onRemoveHandler={removeRewardsPointsDiscountValue}
												visibleTitle={isSecondaryTemplate}
												isSecondaryTemplate={isSecondaryTemplate}
												isTertiaryTemplate={isTertiaryTemplate}
												truncate={isEnabledTruncatedInputs}
											/>
										</div>
										<div
											className={`spreadedCarSearchForm__discount w-full md:w-3/3 relative ${
												isSecondaryTemplate ? 'isSecondaryTemplate md:pl-4' : 'md:pl-[7px]'
											}`}
										>
											{isTertiaryTemplate && (
												<span className='hidden md:flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>{traductions.discounts.title}</span>
											)}
											<DiscountField
												fieldId={discountId}
												title={traductions.discounts.title}
												placeHolder={traductions.discounts.placeHolder}
												name={discountName}
												backButtonTraduction={traductions.backBtn}
												nextButtonTraduction={traductions.nextBtn}
												discountMajorTitle={traductions?.discounts?.title}
												discountTitle={traductions?.discountItem?.title}
												discountPlaceHolder={traductions?.discountItem?.placeHolder}
												discountExtraPlaceHolder={traductions?.discountItem?.extraPlaceHolder}
												discountHint={traductions?.discountItem?.hint}
												promotionalCodeTitle={traductions?.promoCode?.title}
												promotionalCodePlaceHolder={traductions?.promoCode?.placeHolder}
												promotionalCodeExtraPlaceHolder={traductions?.promoCode?.extraPlaceHolder}
												promotionalCodeHint={traductions?.promoCode?.hint}
												rewardsPointsMajorTitle={traductions?.hertzGold?.thirdTitle}
												rewardsPointsTitle={traductions?.hertzGold?.title}
												rewardsPointsPlaceHolder={traductions?.hertzGold?.placeHolder}
												buttonText={traductions?.actionBtn || traductions?.endBtn}
												config={{
													discount: true,
													promotionalCode: true,
													rewardsPoints: !isTertiaryTemplate,
													paymentTypes: !isTertiaryTemplate,
												}}
												corporateDiscounts={corporateDiscounts}
												promoCodesWithoutCorporateDiscountCode={promoCodesWithoutCorporateDiscountCode}
												getPaymentOption={paymentOptions}
												defaultPaymentType={paymentType}
												useBackButton={true}
												showAllTitles={true}
												colorSecondary={colorSecondary}
												className={`w-full ${mobileFieldStep === discountId ? 'z-50' : 'z-0'} md:z-auto  ${isSecondaryTemplate ? '' : 'relative'}`}
												required={false}
												register={register}
												setValue={setValue}
												getValues={getValues}
												trigger={trigger}
												errors={errors}
												nextCallBack={nextMobileFieldHandler}
												backCallBack={backMobileFieldHandler}
												onShowDiscounts={emitOptionActive}
												onChangeDiscounts={getDiscountDisplayValue}
												onChangePaymentType={changeDiscountPaymentTypeHandler}
												onRemoveHandler={removeRewardsPointsDiscountValue}
												visibleTitle={isSecondaryTemplate}
												isSecondaryTemplate={isSecondaryTemplate}
												isTertiaryTemplate={isTertiaryTemplate}
												isHiddenPaymentMethods={isHiddenPaymentMethodsInCars}
											/>
										</div>
									</div>
									{isCarsDisclaimerVisibled && isSecondaryTemplate && !isNotHome && (
										<div className='spreadedCarSearchForm__disclaimer hidden md:flex items-center justify-start md:pt-[20px] md:w-3/4'>
											<p className='m-0'>*{traductions?.carsDisclaimer || 'carsDisclaimer'}.</p>
										</div>
									)}
									<div
										className={`spreadedCarSearchForm__footer md:relative  hidden md:flex ${
											isSecondaryTemplate && !isNotHome ? ' md:pt-[20px] md:w-1/4 md:justify-between ' : 'md:pl-[15px] md:w-1/5 '
										} 
											${isSecondaryTemplate ? 'isSecondaryTemplate' : ''}
											${isTertiaryTemplate ? 'isTertiaryTemplate items-end' : ''}
										`}
									>
										<Button
											type='submit'
											customClass={`spreadedCarSearchForm__searchBtn text-white w-full text-[21px] items-center ${
												isSecondaryTemplate ? 'rounded-full' : 'rounded-lg'
											}
												${isTertiaryTemplate ? 'rounded-none tertiary__shadow' : ''}
												${isTertiaryTemplate && !isValid ? 'tertiary__disabled' : ''}
											`}
											content={
												<>
													{isSecondaryTemplate || isTertiaryTemplate ? null : <span className='searchIco text-[25px] font-extrabold' />}
													<span
														className={`${isSecondaryTemplate ? 'text-[16px]' : 'text-[21px] font-extrabold pl-[10px]'}
														${isTertiaryTemplate ? 'text-base !text-[14px] !pl-0 font-medium' : ''}
													`}
													>
														{traductions.searchText || 'search'}
													</span>
												</>
											}
											styles={{
												background: colorSecondary || '#878787',
												padding: isTertiaryTemplate && '11px 5px',
											}}
											disabled={!isValid && isTertiaryTemplate}
										/>
									</div>
									{isCarsDisclaimerVisibled && !isSecondaryTemplate && !isNotHome && (
										<div className='spreadedCarSearchForm__disclaimer hidden md:flex items-center justify-start md:pt-[20px] md:w-full'>
											<p className='m-0'>*{traductions?.carsDisclaimer || 'carsDisclaimer'}.</p>
										</div>
									)}
									<div className={'block md:hidden w-full z-40 bg-white absolute left-0 top-[20px] h-screen'} />
								</div>
							</div>
						</div>
					</form>
				</div>
			</>
		);
	},
);

export default SpreadedCarSearchForm;
