import SpecialServiceCard from '../../../special-service-card/special-service-card';
import useTextSkeleton from '../../../../../../../shared/hooks/useTextSkeleton';
import styles from './car-special-services-detail-skeleton.module.scss';

export const CarSpecialServicesDetailSkeleton = (): JSX.Element => {
	const descriptionComnponents = useTextSkeleton(2, 3, 20, 90, 100, 'carSpecialServicesDetailSkeleton__description h-[16px] m-0 mb-[5px]');
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={'carSpecialServicesDetailSkeleton animate-pulse order-2 md:order-1'}>
				<h3 className='carSpecialServicesDetailSkeleton__title h-[21px] w-[30%] mb-[10px] bg-gray-200' />
				<div className='pb-0 md:pb-[5px] m-0'>{descriptionComnponents}</div>
				{Array(4)
					.fill(0)
					.map((item: number, index: number): JSX.Element => {
						return (
							<SpecialServiceCard
								key={index}
								moreInformationText=''
								payOnLineText=''
								paymentOnArrivalText=''
								rateModel=''
								icon=''
								loading={true}
								className={
									'carSpecialServicesDetailSkeleton__item bg-white mt-[20px] md:mt-[25px] w-full rounded-[10px] shadow-md min-h-[65px] carSpecialServicesDetailSkeleton__item'
								}
							/>
						);
					})}
			</div>
		</>
	);
};

export default CarSpecialServicesDetailSkeleton;
