export enum LibFontIcons {
	whatappCircle = 'whatappCircleIco',
	facebookCircle = 'facebookCircleIco',
	instagramCircle = 'instagramCircleIco',
	youtubeCircle = 'youtubeCircleIco',
	tiktokCircle = 'tiktokCircleIco',
	twitterCircle = 'twitterCircleIco',
	person2 = 'person2Ico',
	heart = 'heartIco',
	search2 = 'search2Ico',
}
