import { CarsRatesModelsV2 } from '../../../../../shared/enums/filter-cars-cards-v2.enum';
import CarCoveragesDesktop from '../car-coverages-desktop/car-coverages-desktop';
import CarCoveragesMobile from '../car-coverages-mobile/car-coverages-mobile';
import CoverageCarCard from '../../../coverage-car-card/coverage-car-card';
import { CarInterfaceV2 } from '../../../../../shared/interfaces';
import styles from './car-coverages-detail-skeleton.module.scss';

export interface CarCoveragesDetailSkeletonProps {
	colorPrimary?: string;
	className?: string;
	coveragesMobileClassName?: string;
	coveragesDesktopClassName?: string;
}

export const CarCoveragesDetailSkeleton = ({
	colorPrimary,
	className,
	coveragesMobileClassName,
	coveragesDesktopClassName,
}: CarCoveragesDetailSkeletonProps): JSX.Element => {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`carCoveragesDetailSkeleton animate-pulse order-2 md:order-1 ${className || ''}`}>
				<CoverageCarCard
					loading={true}
					carInfo={{} as unknown as CarInterfaceV2}
					dailyRateText=''
					discountText=''
					includeAirConditionText=''
					includeLargeAirConditionText=''
					passengersText=''
					doorsText=''
					baggageText=''
					manualTransmissionText=''
					automaticTransmissionText=''
					rateText=''
					coverageButtonText=''
					rateModel={CarsRatesModelsV2.rate}
					multipleCompanies={false}
					showRate={false}
					showButtons={false}
					colorPrimary={colorPrimary || '#FDD008'}
					className={'bg-white mx-auto mt-[20px] w-full rounded-xl carCoveragesDetailSkeleton__boxShadow'}
					companyImageClassName='h-[15px] w-[41px]'
					carImageClassName=''
					carContainerClassName=''
				/>
				<p className='carCoveragesDetailSkeleton__coveragesTitle my-[20px] bg-gray-200 h-[21px] w-[40%] md:w-[20%]' />
				<div className={`carCoveragesDetailSkeleton__coveragesMobile ${coveragesMobileClassName || ''}`}>
					<CarCoveragesMobile loading={true} />
				</div>

				<div
					className={`carCoveragesDetailSkeleton__coveragesDesktop carCoveragesDetailSkeleton__coveragesDesktop ${coveragesDesktopClassName || ''}`}
				>
					<CarCoveragesDesktop loading={true} />
				</div>
			</div>
		</>
	);
};

export default CarCoveragesDetailSkeleton;
