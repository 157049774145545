import { MarkersInterface, OfficesCardinal } from '../../shared/interfaces/maps/static-maps.interface';

export const MapInteractiveMarksMock: MarkersInterface[] = [
	{
		longitude: -80.2805,
		latitude: 25.7822,
		popupMsg: '<p>7401 SOUTHWEST 40TH STREET</p><p>Desde</p><p>555 USD</p>',
		code: 'MIAC12',
		rentalCarsCompaniesCodes: [
			{
				code: 'ZE',
				officeCode: 'MIAC12',
				rentalCarName: 'Hertz',
			},
		],
		extra: {
			cityCode: 'MIAC12',
			cityName: 'MIAMI',
			code: 'MIACOP',
			countryCode: 'US',
			countryName: 'USA',
			id: '7d37b747-e038-41cc-b600-4bde03d36c16',
			isAirport: true,
			latitude: 0,
			longitude: 0,
			name: 'BIRD ROAD',
			region: '',
			airportCode: '',
			address: '7401 SOUTHWEST 40TH STREET',
			rentalCarsCompaniesCodes: [
				{
					code: 'ZT',
					officeCode: 'MIAN06',
					rentalCarName: 'thrifty',
				},
				{
					code: 'ZR',
					officeCode: 'MIAN06',
					rentalCarName: 'dollar',
				},
				{
					code: 'ZE',
					officeCode: 'MIAC09',
					rentalCarName: 'hertz',
				},
				{
					code: 'FF',
					officeCode: 'MIAC19',
					rentalCarName: 'fireFly',
				},
			],
		},
	},
	{
		longitude: -80.260189,
		latitude: 25.798494,
		popupMsg: '3900 NW 25TH STREET',
		code: 'MIAP52',
		rentalCarsCompaniesCodes: [
			{
				code: 'ZT',
				officeCode: 'MIAP52',
				rentalCarName: 'Thrifty',
			},
			{
				code: 'ZR',
				officeCode: 'MIAP01',
				rentalCarName: 'Dollar',
			},
		],
	},
	{
		longitude: -80.325722,
		latitude: 25.809242,
		popupMsg: '7930 NW 36TH STREET',
		code: 'MIAC09',
		rentalCarsCompaniesCodes: [
			{
				code: 'ZE',
				officeCode: 'MIAC09',
				rentalCarName: 'Hertz',
			},
		],
	},
	{
		longitude: -80.2474,
		latitude: 25.8089,
		popupMsg: '2940 NW 36TH STREET',
		code: 'MIAC17',
		rentalCarsCompaniesCodes: [
			{
				code: 'ZE',
				officeCode: 'MIAC17',
				rentalCarName: 'Hertz',
			},
		],
	},
	{
		longitude: -80.3372,
		latitude: 25.8106,
		popupMsg: '4101 NORTHWEST 87TH AVENUE',
		code: 'MIAC26',
		rentalCarsCompaniesCodes: [
			{
				code: 'ZE',
				officeCode: 'MIAC26',
				rentalCarName: 'Hertz',
			},
		],
	},
];

export const carSearchOfficesMock: {
	officesByLocation: OfficesCardinal[];
} = {
	officesByLocation: [
		{
			id: '1',
			zone: 'Miami north',
			locationName: 'Miami Beach',
			locations: [
				{
					cityCode: 'MIACOP',
					cityName: 'Miami Beach',
					code: 'MIACOP',
					countryCode: 'US',
					countryName: 'USA',
					id: '',
					isAirport: true,
					longitude: -80.2805,
					latitude: 25.7822,
					name: 'Miami - 2nd Street HLE',
					region: '',
					airportCode: '',
					address: '229 Southeast 2nd Street, Miami Downtown, Miami',
					rentalCarsCompaniesCodes: [
						{
							code: 'ZT',
							officeCode: 'MIAN06',
							rentalCarName: 'thrifty',
						},
						{
							code: 'ZR',
							officeCode: 'MIAN06',
							rentalCarName: 'dollar',
						},
						{
							code: 'ZE',
							officeCode: 'MIAC09',
							rentalCarName: 'hertz',
						},
						{
							code: 'FF',
							officeCode: 'MIAC19',
							rentalCarName: 'fireFly',
						},
					],
				},
			],
		},
		{
			id: '2',
			zone: 'Miami south',
			locationName: 'Miami south',
			locations: [
				{
					cityCode: 'MIACOP',
					cityName: 'Miami south',
					code: 'MIACOP',
					countryCode: 'US',
					countryName: 'USA',
					id: '',
					isAirport: true,
					longitude: -80.260189,
					latitude: 25.798494,
					name: 'Miami - 2nd Street HLE',
					region: '',
					airportCode: '',
					address: '229 Southeast 2nd Street, Miami Downtown, Miami',
					rentalCarsCompaniesCodes: [
						{
							code: 'ZT',
							officeCode: 'MIAN06',
							rentalCarName: 'thrifty',
						},
						{
							code: 'ZR',
							officeCode: 'MIAN06',
							rentalCarName: 'dollar',
						},
					],
				},
				{
					cityCode: 'HLE',
					cityName: 'Miami south',
					code: 'HLE',
					countryCode: 'US',
					countryName: 'USA',
					id: '',
					isAirport: true,
					longitude: -80.325722,
					latitude: 25.809242,
					name: 'Miami - 39th Avenue HLE ',
					region: '',
					airportCode: '',
					address: '229 Southeast 2nd HLE HLE HLE',
					rentalCarsCompaniesCodes: [
						{
							code: 'ZT',
							officeCode: 'MIAN06',
							rentalCarName: 'thrifty',
						},
						{
							code: 'ZR',
							officeCode: 'MIAN06',
							rentalCarName: 'dollar',
						},
					],
				},
				{
					cityCode: 'HLEE',
					cityName: 'Miami south',
					code: 'HLEE',
					countryCode: 'US',
					countryName: 'USA',
					id: '',
					isAirport: true,
					longitude: -80.2474,
					latitude: 25.8089,
					name: 'Miami - 39th ',
					region: '',
					airportCode: '',
					address: '229 Southeast 2nd',
					rentalCarsCompaniesCodes: [
						{
							code: 'ZT',
							officeCode: 'MIAN06',
							rentalCarName: 'thrifty',
						},
						{
							code: 'ZR',
							officeCode: 'MIAN06',
							rentalCarName: 'dollar',
						},
					],
				},
				{
					cityCode: 'HLEH',
					cityName: 'Miami south',
					code: 'HLEH',
					countryCode: 'US',
					countryName: 'USA',
					id: '',
					isAirport: true,
					longitude: -80.3372,
					latitude: 25.8106,
					name: 'Avenue HLE 3',
					region: '',
					airportCode: '',
					address: '2nd HLE HLE HLE',
					rentalCarsCompaniesCodes: [
						{
							code: 'ZT',
							officeCode: 'MIAN06',
							rentalCarName: 'thrifty',
						},
						{
							code: 'ZR',
							officeCode: 'MIAN06',
							rentalCarName: 'dollar',
						},
					],
				},
			],
		},
	],
};
