import { InputEventsHandler } from '../../../../shared/interfaces';
import { Image } from '../../../ui-elements/image/image';
import { Select } from '../../../../lib/ui-elements';
export interface HeaderDoubleBarProps {
	OnChangeLanguage?: (language: string) => void;
	backgroundColorHeader?: string;
	defaultLanguage?: string;
	hintActionText?: string;
	promoImage?: string;
	logoAlt?: string;
	logoUrl: string;
}

export const HeaderDoubleBar = ({
	logoUrl,
	logoAlt,
	hintActionText,
	backgroundColorHeader,
	promoImage,
	defaultLanguage,
	OnChangeLanguage,
}: HeaderDoubleBarProps): JSX.Element => {
	const changeLanguage = (event: InputEventsHandler): void => {
		if (OnChangeLanguage) {
			OnChangeLanguage(event.value as string);
		}
	};

	return (
		<div className='headerDoubleBar'>
			<div className='headerDoubleBar__container relative'>
				{!!hintActionText && (
					<div
						style={{ background: backgroundColorHeader || '' }}
						className='headerDoubleBar__top text-center'
						dangerouslySetInnerHTML={{ __html: hintActionText || '' }}
					></div>
				)}

				<div className='headerDoubleBar__bottom flex justify-between items-center py-2 px-2 md:px-[55px] extraInnerContainer'>
					<div className='headerDoubleBar__bottom-item'>
						<a href='/'>
							<span className='hidden'>{logoAlt || ''}</span>
							<Image
								url={logoUrl || ''}
								width={'300px'}
								height={'150x'}
								alt={logoAlt || ''}
								styles={{
									width: 'auto',
									maxWidth: '300px',
									maxHeight: '150px',
								}}
							/>
						</a>
					</div>

					<div className='headerDoubleBar__bottom-item'>
						<Select
							options={[
								{
									value: 'es',
									label: 'Español',
								},
								{
									value: 'en',
									label: 'Ingles',
								},
							]}
							defaultValue={defaultLanguage || 'es'}
							label={<span className='mapIco text-[15px] md:text-[20px] mr-[5px]' />}
							name={'language'}
							className={'headerDoubleBar_selectContainer items-center !flex-row'}
							labelClassName={'headerDoubleBar__label text-xs'}
							selectClassName={'headerDoubleBar__select text-xs font-medium bg-transparent w-auto inline-block mt-0'}
							onChange={changeLanguage}
							customClassOptionsContainer={'!min-w-[100px] md:left-[-22px]'}
						/>
					</div>
				</div>

				{!!promoImage && (
					<div className='headerDoubleBar__promoImage absolute top-0 right-[5%] shadow-lg hidden md:block bg-white rounded-bl-lg rounded-br-lg'>
						<div className='headerDoubleBar__promoImage-container px-3 py-6'>
							<Image
								url={promoImage}
								width={'300px'}
								height={'150x'}
								alt={logoAlt || ''}
								styles={{
									width: 'auto',
									maxWidth: '300px',
									maxHeight: '150px',
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
