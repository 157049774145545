import { DatePickerPackageData, DatePickerEventsHandler, InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { FormsInputsType, DatePickerKeys, DatePickerDirections } from '../../../shared/enums/inputs.enum';
import { DatePickerDefaultData, DatePickerColors } from '../../../shared/interfaces/date-picker.interface';
import { getDateLocal } from '../../../shared/utils/get-date-locale';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { DateLocales } from '../../../shared/enums/date.enum';
import Button from '../../ui-elements/button/button';
import styles from './date-range-picker.module.scss';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';

export interface DateRangePickerProps {
	name?: string;
	defaultData?: DatePickerDefaultData;
	locale?: DateLocales;
	minDate?: Date;
	maxDate?: Date;
	buttonText?: string;
	cancelText?: string;
	useButton?: boolean;
	isSecondaryTemplate?: boolean;
	colors?: DatePickerColors;
	onChange?: (e: InputEventsHandler) => void;
	handleCancelClick?: () => void;
	className?: string;
	dateRangeClassName?: string;
	direction?: DatePickerDirections;
	datesMessage?: string;
	weekdayDisplayFormat?: string;
}

export const DateRangePicker = ({
	name,
	defaultData,
	locale,
	minDate,
	maxDate,
	buttonText,
	cancelText,
	useButton,
	isSecondaryTemplate,
	weekdayDisplayFormat,
	colors,
	onChange,
	handleCancelClick,
	className,
	dateRangeClassName,
	direction,
	datesMessage,
}: DateRangePickerProps): JSX.Element => {
	const [range, setRange] = useState<DatePickerPackageData[]>([
		{
			startDate: defaultData?.startDate ? defaultData.startDate : new Date(),
			endDate: defaultData?.endDate ? defaultData.endDate : new Date(),
			key: DatePickerKeys.selection,
		},
	]);

	const realLocale: Object = useMemo(
		() =>
			getDateLocal(locale),
		[locale],
	);

	const dateHandler = useCallback(
		(event: DatePickerEventsHandler): void => {
			setRange([event.selection]);

			if (onChange && !useButton) {
				const dataEvent: InputEventsHandler = {
					type: FormsInputsType.dateRangePicker,
					name,
					value: {
						startDate: event.selection.startDate,
						endDate: event.selection.endDate,
					},
				};

				onChange(dataEvent);
			}
		},
		[name, useButton],
	);

	const buttonHandler = useCallback((): void => {
		if (onChange) {
			const firstElement: number = 0;
			const date: DatePickerPackageData = range[firstElement];
			const dataEvent: InputEventsHandler = {
				type: FormsInputsType.dateRangePicker,
				name,
				value: {
					startDate: date.startDate,
					endDate: date.endDate,
				},
			};

			onChange(dataEvent);
		}
	}, [name, range]);

	useEffect(() => {
		const root = document.documentElement;
		if (colors?.main) {
			root?.style.setProperty('--main-date-picker-color', colors.main);
		}

		if (colors?.second) {
			root?.style.setProperty('--second-date-picker-color', colors.second);
		}

		if (colors?.third) {
			root?.style.setProperty('--third-date-picker-color', colors.third);
		}
	}, [colors?.main, colors?.second, colors?.third]);

	useEffect(() => {
		if (defaultData) {
			setRange([
				{
					startDate: defaultData?.startDate ? defaultData.startDate : new Date(),
					endDate: defaultData?.endDate ? defaultData.endDate : new Date(),
					key: DatePickerKeys.selection,
				},
			]);
		}
	}, [defaultData]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`dateRangePicker inline-flex flex-col ${className || ''}`}>
				<DateRange
					ranges={range}
					locale={realLocale}
					minDate={minDate}
					maxDate={maxDate}
					className={`dateRangePicker__dateRangeWrapper ${dateRangeClassName || ''}`}
					monthDisplayFormat='MMMM yyyy'
					weekdayDisplayFormat={weekdayDisplayFormat ?? 'iiiii'}
					months={2}
					preventSnapRefocus={true}
					direction={direction}
					scroll={{ enabled: false }}
					moveRangeOnFirstSelection={false}
					showSelectionPreview={true}
					showMonthAndYearPickers={false}
					showMonthArrow={true}
					showDateDisplay={false}
					onChange={dateHandler}
				/>

				{datesMessage && <div className='dateRangePicker__datesMessage text-xs pl-3'>{datesMessage}</div>}

				{useButton && (
					<div className='dateRangePicker__buttonContainer flex flex-row justify-end gap-3'>
						{isSecondaryTemplate && (
							<Button
								text={cancelText}
								customClass={'dateRangePicker__cancelButton text-xs border border-gray-300 bg-white rounded-full'}
								onClick={handleCancelClick}
							/>
						)}
						<Button
							text={buttonText}
							customClass={`dateRangePicker__button text-xs ${isSecondaryTemplate ? 'rounded-full' : 'rounded-lg'}`}
							styles={{
								backgroundColor: colors?.main,
							}}
							onClick={buttonHandler}
						/>
					</div>
				)}
			</div>
		</>
	);
};

DateRangePicker.defaultProps = {
	className: '',
	dateRangeClassName: '',
	direction: DatePickerDirections.horizontal,
};

export default DateRangePicker;
