import { ListInterface, ListItemInterface } from '../../../shared/interfaces/list.interface';
import useWindowWidth from '../../../shared/hooks/useWidth';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import { useEffect, useState } from 'react';
export interface ListProps {
	items: ListInterface[];
	activeItemId?: string;
	callBack?: (item: ListItemInterface) => void;
	hoverCallBack?: (item?: ListItemInterface | null) => void;
	withPointer?: boolean;
	activeColor?: string;
	deactiveColor?: string;
	className?: string;
	titleClassName?: string;
	itemsClassName?: string;
	ulClassName?: string;
	liClassName?: string;
	listTitleClassName?: string;
	spinnerClassName?: string;
	secondaryColor?: string;
	iconClassName?: string;
	applyIconRotation?: boolean;
	isResponsiveDropdown?: boolean;
	height?: string;
	itemsStyle?: React.CSSProperties;
	useNativeAnchor?: boolean;
	truncate?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
}

export function List(props: ListProps): JSX.Element {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const [activeId, setActiveId] = useState<string>('');
	const [dropdownTitle, setDropdownTitle] = useState<string>('');
	const [isIconClicked, setIsIconClicked] = useState(false);
	useEffect(() => {
		setActiveId(props.activeItemId || '');
	}, [props.activeItemId]);

	const itemClicked = (item: ListItemInterface): void => {
		if (props?.callBack && !props?.useNativeAnchor) {
			props.callBack(item);

			setActiveId(item?.id || '');
		}
	};

	const hoverHandle = (item?: ListItemInterface | null): void => {
		if (props?.hoverCallBack) {
			props.hoverCallBack(item);
		}
	};

	return (
		<div
			className={`${props.className || ''}`}
			style={{
				height: props?.height,
			}}
		>
			{props.items.map((listItem: ListInterface, index: number) => (
				<div key={index} className={`list ${props.itemsClassName || ''}`}>
					{listItem.title && (
						<div className={`list__title ${props?.listTitleClassName || ''}`}>
							{listItem.title && (
								<div className={`flex flex-row items-center ${props.titleClassName || ''}`}>
									<div className='flex-grow'>{listItem.title}</div>
									{props.isResponsiveDropdown && windowWidth < responsiveSize && (
										<IcomoonIcon
											key={index}
											icon='chevronBottomIco'
											className={`cursor-pointer ${props.iconClassName || ''} ${isIconClicked ? 'rotate-180' : ''}`}
											onClick={() => {
												setDropdownTitle((prevState: string): string => {
													const tempTitle: string = String(listItem.title);
													return tempTitle === prevState ? '' : tempTitle;
												});

												if (props.applyIconRotation) {
													setIsIconClicked(prevState => !prevState);
												}
											}}
										/>
									)}
								</div>
							)}
						</div>
					)}
					{(!props.isResponsiveDropdown ||
						windowWidth >= responsiveSize ||
						(props.isResponsiveDropdown && windowWidth < responsiveSize && dropdownTitle === String(listItem.title))) && (
						<ul className={`${listItem.showLine ? 'border-b' : ''} ${props.ulClassName || ''}`}>
							{listItem.items.map((item: ListItemInterface, secondIndex: number) => {
								const isLastItem = secondIndex === listItem.items.length - 1;
								const itemStyle: React.CSSProperties = {
									...props?.itemsStyle,
									background: !item.noLinkElement && !!activeId && activeId === item?.id ? props.activeColor : props.deactiveColor,
								};

								return (
									<li
										onMouseOver={item.noLinkElement ? undefined : () => hoverHandle(item)}
										onMouseLeave={item.noLinkElement ? undefined : () => hoverHandle(null)}
										onClick={item.noLinkElement ? undefined : () => itemClicked(item)}
										key={`${secondIndex}-${item?.id}`}
										className={`item ${item?.id} ${
											!item.noLinkElement && !!activeId && activeId === item?.id ? 'list__item-active' : 'list__item-deactive'
										} ${!item.noLinkElement && props.withPointer ? 'cursor-pointer' : ''} ${props.liClassName || ''}
											${props.isTertiaryTemplate && !isLastItem ? 'border-b border-b-[#E6E6E6]' : ''}
											${props.isTertiaryTemplate && secondIndex === 0 ? 'border-t border-t-[#E6E6E6]' : ''}
											${props.isTertiaryTemplate ? 'hover:bg-[#84C4F1]' : ''}
										`}
										style={props.isTertiaryTemplate ? { ...itemStyle } : undefined}
									>
										{props?.useNativeAnchor || item.useNativeAnchor ? (
											<a
												className={`${props.truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap' : ''}`}
												href={item.link}
												target={item.original || '_blank'}
												rel='noreferrer'
											>
												{item.name}
											</a>
										) : (
											<span
												className={`${props.truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap' : ''} 
													${props.isSecondaryTemplate ? 'flex justify-between pr-2' : ''} 
													${props.isTertiaryTemplate ? 'text-[#6D6E71]' : ''}
												`}
											>
												{item.name}
												{props.isSecondaryTemplate && !!activeId && activeId === item?.id && (
													<IcomoonIcon
														color={props.secondaryColor}
														icon='checkIco'
														className='pl-[5px]'
														iconClassName={'text-[15px] selectedOptionIcon '}
													/>
												)}
											</span>
										)}
									</li>
								);
							})}
						</ul>
					)}
				</div>
			))}
		</div>
	);
}

List.defaultProps = {
	className: '',
	titleClassName: '',
	itemsClassName: '',
	liClassName: '',
	ulClassName: '',
	iconClassName: '',
};

export default List;
