import { FlightsSearchFormSubmitInterface, DisabledTripTypeFlightsSearchFormInterface, OptionSelect } from '@smartlinks/react-design-system';
import { FlightsSearchAirlinesInterface } from '../../shared/interfaces/flights-airports.interface';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import FlightsSearch from '../../templates/flights-search/flights-search';
import { FlightsService } from '../../shared/services/flights.service';
import { FlightsUtil } from '../../shared/utils/flights-search.util';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import { CommonsUtil } from '../../shared/utils/commons';
import styles from 'bundle-text:./flights-search.scss';
import { useEffect, useState, Suspense } from 'react';
import ReactDOM from 'react-dom';

export interface FlightsSearchWidgetProps extends SearchWidgetInterface<FlightsSearchFormSubmitInterface> {
	disabledTripType?: DisabledTripTypeFlightsSearchFormInterface;
}

const AppFlightsSearchWidget = ({
	token,
	language,
	agency,
	disabledTripType,
	callUrlSearch,
	callShowMobileFields,
}: FlightsSearchWidgetProps): JSX.Element => {
	const widgetClassName: string = 'flightsSearchWidget';
	const {
		agencyState,
		isLoading,
		widgetService: flightsService,
		widgetStyles,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<FlightsService, FlightsSearchFormSubmitInterface>({
		className: widgetClassName,
		styles: styles?.toString() || '',
		token,
		language,
		agency,
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: FlightsService,
	});

	const [airlines, setAirlines] = useState<OptionSelect[]>([]);
	useEffect(() => {
		if (!isLoading && !(agencyState == null) && !(Object.keys(agencyState).length === 0)) {
			((): void => {
				try {
					void (async () => {
						const tempAirlines: FlightsSearchAirlinesInterface[] | null = await flightsService.getAirlines();
						setAirlines([...FlightsUtil.mapAirlinesOptions(t, tempAirlines || [])]);
					})();

					return;
				} catch (error) {
					console.error(error);
				}
				setAirlines([]);
			})();
		}
	}, [isLoading]);

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
				{!isLoading && (
					<FlightsSearch
						agency={agencyState}
						airlines={airlines}
						flightsService={flightsService}
						callUrlSearch={urlSearchHandler}
						t={t}
						disabledTripType={disabledTripType}
						callShowMobileFields={showMobileFieldsHandler}
					/>
				)}
			</div>
		</Suspense>
	);
};

export function FlightsSearchWidget(props: FlightsSearchWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget flightSearch'>
					<AppFlightsSearchWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack != null) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
