import { FieldButtonEventHandlerInterface, NationalityFieldInputNamesInterface, OptionSelect } from '../../../../shared/interfaces';
import { DeepRequired, FieldErrorsImpl, FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import NationalityFieldList from './nationality-field-list/nationality-field-list';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { ElementTypes, SearchFieldsNames } from '../../../../shared/enums';
import { Backdrop, Button, Input } from '../../../ui-elements';
import { FC, useCallback, useEffect, useState } from 'react';

export interface NationalityFieldProps {
	fieldId?: string;
	title?: string;
	placeHolder?: string;
	name: string;
	searchPlaceHolder: string;
	backButtonTraduction?: string;
	nextButtonTraduction?: string;
	colorSecondary?: string;
	className?: string;
	filterFieldClassName?: string;
	required?: boolean;
	useBackButton?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	errorRequiredFieldTraduction?: string;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	register: UseFormRegister<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	trigger?: UseFormReturn['trigger'];
	nextCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	backCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	onShowModal?: (show: boolean) => void;
	visibleTitle?: boolean;
	truncate?: boolean;
	useLightTemplate?: boolean;
	isMobile?: boolean;
	showMobileActions?: boolean;
	disabled?: boolean;
	options?: OptionSelect[];
	defaultValue?: OptionSelect;
	iconClassName?: string;
	desktopModalClassName?: string;
}

export const NationalityField: FC<NationalityFieldProps> = ({
	fieldId,
	title,
	placeHolder,
	name,
	searchPlaceHolder,
	backButtonTraduction,
	nextButtonTraduction,
	colorSecondary,
	className,
	useBackButton,
	errorRequiredFieldTraduction,
	required,
	register,
	setValue,
	getValues,
	trigger,
	nextCallBack,
	backCallBack,
	visibleTitle,
	isSecondaryTemplate,
	isTertiaryTemplate,
	disabled,
	truncate,
	options,
	defaultValue,
	useLightTemplate,
	iconClassName,
}) => {
	const inputsNames: NationalityFieldInputNamesInterface = SearchFieldsUtil.getNationalityFieldInputNamesInterfaceInputsNames(name);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showMobileModal, setShowMobileModal] = useState<boolean>(false);
	const showModalHandler = useCallback((show: boolean) => {
		setShowModal(show);

		setShowMobileModal(show);
	}, []);

	const [initDefaultValue, setInitDefaultValue] = useState<OptionSelect>();
	useEffect(() => {
		if (defaultValue && setValue) {
			setInitDefaultValue(defaultValue);

			setValue(inputsNames.hiddenName, defaultValue.value);

			setValue(inputsNames.name, defaultValue.label);
		}
	}, [defaultValue]);

	const listHandler = useCallback(
		(selectedOption: OptionSelect): void => {
			if (setValue) {
				const tempNationalityNames: NationalityFieldInputNamesInterface = SearchFieldsUtil.getNationalityFieldInputNamesInterfaceInputsNames(name);
				setValue(tempNationalityNames.name, selectedOption.label);

				setValue(tempNationalityNames.hiddenName, selectedOption.value);
			}

			showModalHandler(false);
		},
		[name, setValue],
	);

	const clickNextHandler = useCallback(async () => {
		if (trigger) {
			const tempNames: NationalityFieldInputNamesInterface = SearchFieldsUtil.getNationalityFieldInputNamesInterfaceInputsNames(name);
			const resultName = await trigger(tempNames.name, { shouldFocus: true });
			const resultHiddenName = await trigger(tempNames.hiddenName, { shouldFocus: true });
			if (resultName && resultHiddenName && nextCallBack) {
				nextCallBack({ id: fieldId || SearchFieldsNames.nationality });

				showModalHandler(false);
			}
		}
	}, [name, fieldId, nextCallBack, trigger]);

	const clickBackHandler = useCallback(() => {
		if (backCallBack) {
			backCallBack({ id: fieldId || SearchFieldsNames.filter });

			showModalHandler(false);
		}
	}, [fieldId, backCallBack]);

	return (
		<div
			className={`nationalityField w-full ${
				isSecondaryTemplate ? '' : 'bg-white'
			}  md:bg-transparent absolute md:static max-h-screen md:max-h-[none] h-screen md:h-auto left-0 top-0 flex flex-col ${className || ''}`}
		>
			<div
				className={`nationalityField__inputContainer flex flex-col ${
					visibleTitle ? 'flex-col' : 'md:flex-row border-0 border-b md:border border-solid '
				} ${
					isSecondaryTemplate
						? 'gap-2 px-[16px] pt-[25px] pb-[2px] md:px-0 md:rounded-[8px] md:py-0'
						: 'bg-white md:px-0 px-[40px] py-[25px] md:py-[10px] md:pr-[10px] md:rounded-[10px] border-b md:border border-solid'
				} 
				${isTertiaryTemplate ? 'md:border-[#BEBEBE] md:rounded-none md:px-0' : ''}
				rounded-none relative`}
				onClick={() => showModalHandler(!showModal)}
			>
				{!isTertiaryTemplate && (
					<div
						className={`nationalityField__title ${
							visibleTitle ? '' : 'border-0 md:border-r md:border-solid'
						}  flex flex-row items-center overflow-x-hidden`}
						style={{ borderColor: disabled ? '#CCD0D6' : colorSecondary }}
					>
						{!!iconClassName && (
							<div
								className={`nationalityField__ico flex items-center md:justify-center ${
									visibleTitle ? '' : 'min-w-[20px] w-[20px] md:min-w-[70px] md:w-[70px]'
								}  pointers-none`}
							>
								{!isSecondaryTemplate && (
									<span className={`${iconClassName} text-[15px] md:text-[25px]`} style={{ color: disabled ? '#CCD0D6' : colorSecondary }} />
								)}
							</div>
						)}
						<span
							className={`nationalityField__subTitle ${
								isSecondaryTemplate ? 'pl-0 text-[#344054] text-sm font-medium leading-5' : 'pl-[10px] text-xs text-[#616161]'
							} ${visibleTitle ? '' : 'md:hidden'}  whitespace-nowrap`}
						>
							{title || 'title'}
						</span>
					</div>
				)}
				<div
					className={`nationalityField__container md:flex-grow relative w-full flex flex-row items-center
						${isSecondaryTemplate ? 'pl-0' : 'pl-[30px] md:pl-[25px]'}`}
				>
					<span
						className={'hidden text-title text-[#616161] whitespace-nowrap'}
						style={{
							color: colorSecondary,
						}}
					>
						{title || 'title'}
					</span>
					<div className='nationalityField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
						<Input
							register={register(inputsNames.hiddenName, {
								required: {
									value: required ?? false,
									message: `${errorRequiredFieldTraduction || 'requiredField'}.`,
								},
								disabled,
							})}
							type={ElementTypes.text}
							autocomplete={'off'}
						/>
					</div>
					<Input
						register={register(name, {
							required: {
								value: required ?? false,
								message: `${errorRequiredFieldTraduction || 'requiredField'}.`,
							},
							disabled,
						})}
						required={!isSecondaryTemplate ? required : false}
						defaultValue={initDefaultValue?.value}
						icon={isSecondaryTemplate ? iconClassName : undefined}
						type={ElementTypes.text}
						placeholder={placeHolder || 'destinationPlaceHolder'}
						isSecondaryTemplate={isSecondaryTemplate}
						className={`nationalityField__input ${showModal ? 'visible' : ''} ${
							isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'
						}  flex-grow `}
						inputClassName={`bg-transparent !p-0 font-normal md md:placeholder placeholder:font-normal 
							${isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base placeholder:text-[#C3C7CE]'}
							${isTertiaryTemplate ? '!text-[14px] !text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
						`}
						autocomplete={'off'}
						style={{
							color: disabled ? '#CCD0D6' : colorSecondary,
							lineHeight: isTertiaryTemplate ? '1.5rem' : '',
						}}
						truncate={truncate}
						children={
							isSecondaryTemplate ? (
								<div className={'flex items-center md:justify-center pointers-none'}>
									<span className={`${showModal ? 'chevronUpIco' : 'chevronBottomIco'} text-[7px]  ${useLightTemplate ? 'mt-1' : ''}`} />
								</div>
							) : undefined
						}
					/>
					{!isSecondaryTemplate && (
						<div className={'flex items-center md:justify-center pointers-none'}>
							<span
								style={{
									color: disabled ? '#CCD0D6' : colorSecondary,
								}}
								className={`${showModal ? 'chevronUpIco' : 'chevronBottomIco'} text-[7px]  ${useLightTemplate ? 'mt-1' : ''}
									${isTertiaryTemplate ? '!text-[#6D6E71] !text-[5px]' : ''}
								`}
							/>
						</div>
					)}
				</div>
			</div>
			{showModal && (
				<>
					<Backdrop
						show={showModal}
						onClick={() => showModalHandler(false)}
						zIndex={10}
						backgroundColor={'transparent'}
						opacity={1}
						className='hidden md:block destinationField__backdrop'
						portalBackdrop={true}
						styles={`
				.destinationField__backdrop {
				display: none;
				}

				@media(min-width: 768px) {
				.destinationField__backdrop {
					display: block;
				}
				}
			`}
					/>
					<div
						className={`destinationField__desktopDestinations hidden md:block w-full md:min-w-full md:absolute md:z-[12] md:pl-4  md:left-0 ${
							isSecondaryTemplate ? 'md:top-[105%]' : 'md:w-auto md:top-[120%]'
						}
							${isTertiaryTemplate ? '!top-[100%]' : ''}
						`}
					>
						<NationalityFieldList
							placeHolder={searchPlaceHolder || 'searchFilterPlaceHolder'}
							options={options || []}
							defaultValue={getValues ? getValues(inputsNames.name) || '' : ''}
							colorSecondary={colorSecondary}
							callBack={listHandler}
							lightTemplate={useLightTemplate}
							isSecondaryTemplate={isSecondaryTemplate}
							isTertiaryTemplate={isTertiaryTemplate}
							truncate={truncate}
						/>
					</div>
				</>
			)}
			<div
				className={`destinationField__mobileDestinations md:hidden w-full h-full flex-grow overflow-y-hidden ${options ? 'block' : 'flex'} ${
					isSecondaryTemplate ? 'px-4' : 'px-0 py-[2px]'
				}`}
			>
				{showMobileModal && (
					<NationalityFieldList
						placeHolder={searchPlaceHolder || 'searchFilterPlaceHolder'}
						options={options || []}
						defaultValue={getValues ? getValues(inputsNames.name) || '' : ''}
						colorSecondary={colorSecondary}
						callBack={listHandler}
						lightTemplate={useLightTemplate}
						isSecondaryTemplate={isSecondaryTemplate}
						isTertiaryTemplate={isTertiaryTemplate}
						truncate={truncate}
					/>
				)}
			</div>
			<div className='destinationField__actions w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid'>
				<Button
					onClick={clickNextHandler}
					type='button'
					customClass={`destinationField__nextButton my-[15px] w-full flex items-center justify-center text-base text-white font-family-regular ${
						isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'
					}
					${isTertiaryTemplate ? 'rounded-none' : ''}`}
					styles={{
						background: colorSecondary,
					}}
					text={nextButtonTraduction || 'next'}
				/>
				{useBackButton && (
					<Button
						onClick={clickBackHandler}
						type='button'
						customClass={`destinationField__backButton my-0 py-0 w-full flex items-center justify-center text-base bg-transparent ${
							isSecondaryTemplate ? 'rounded-full border border-gray-300' : 'rounded-[10px]'
						}
						${isTertiaryTemplate ? 'rounded-none' : ''}`}
						styles={{
							color: colorSecondary,
						}}
						text={backButtonTraduction || 'back'}
					/>
				)}
			</div>
		</div>
	);
};

NationalityField.defaultProps = {
	required: false,
};

export default NationalityField;
