import MultiLanguageCurrencySelector from './sub-components/multi-language-currency-selector/multi-language-currency-selector';
import MultiCurrencyConfirm from './sub-components/multi-currency-confirm/multi-currency-confirm';
import { MultiLanguage } from '../../../shared/interfaces/multi-language.interface';
import { MultiCurrency } from '../../../shared/interfaces/multi-currency.interface';
import { addAlphaToColor } from '../../../shared/services/utils.service';
import BackDrop from '../../ui-elements/backdrop/backdrop';
import { OptionSelect } from '../../../shared/interfaces';
import styles from './multi-language-currency.module.scss';
import { IcomoonIcon } from '../../ui-elements';
import { useEffect, useState } from 'react';

export interface MultiLanguageCurrencyProps {
	colorPrimary?: string;
	colorSecondary?: string;
	defaultLanguage?: string;
	defaultCurrency?: string;
	isShowCurrency?: boolean;
	isShowLanguage?: boolean;
	multiLanguages?: MultiLanguage[];
	multiCurrencies?: MultiCurrency[];
	t: (value: string) => string;
	save?: (language: string, currency: string) => void;
}

export function MultiLanguageCurrency({
	t,
	colorPrimary,
	colorSecondary,
	isShowCurrency,
	isShowLanguage,
	multiLanguages,
	multiCurrencies,
	defaultCurrency,
	defaultLanguage,
	save,
}: Readonly<MultiLanguageCurrencyProps>): JSX.Element {
	const splitCulture = (value: string): string => {
		const codeIndex: number = 1;
		return value.split('-')[codeIndex];
	};

	const languages: OptionSelect[] = (multiLanguages || []).map((item: MultiLanguage) => ({
		label: `${t(item.name)} (${splitCulture(item.code)}) `,
		value: item.code,
	}));

	const currencies: OptionSelect[] = (multiCurrencies || []).map((item: MultiCurrency) => ({
		label: `${t(item.name)} (${item.code}) - ${item.symbol}`,
		value: item.code,
		extraDescription: item.symbol,
	}));

	const getOptionValue = (options: OptionSelect[], value: string | undefined): OptionSelect | undefined => {
		return options.find(item => item.value === value);
	};

	const [currency, setCurrency] = useState<OptionSelect | undefined>(getOptionValue(currencies, defaultCurrency));
	const [language, setLanguage] = useState<OptionSelect | undefined>(getOptionValue(languages, defaultLanguage));
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showBackDrop, setShowBackDrop] = useState<boolean>(false);
	const [showModalCurrencyConfirm, setShowModalCurrencyConfirm] = useState<boolean>(false);
	const alphaColorPrimary: string | undefined = colorPrimary ? addAlphaToColor(colorPrimary, 0.25) : undefined;
	const toggleModal = (): void => {
		setShowBackDrop(!showBackDrop);

		setShowModal(!showModal);
	};

	useEffect(() => {
		setLanguage(getOptionValue(languages, defaultLanguage));
	}, [t]);

	const onSave = (language: string, currency: string): void => {
		setLanguage(getOptionValue(languages, language));

		if (isShowCurrency && currency !== defaultCurrency) {
			setCurrency(getOptionValue(currencies, currency));

			setShowModalCurrencyConfirm(!showModalCurrencyConfirm);
		} else {
			setShowBackDrop(!showBackDrop);

			save && save(language, currency);
		}

		setShowModal(!showModal);
	};

	const onSaveConfirm = (confirm: boolean): void => {
		setShowBackDrop(!showBackDrop);

		setShowModalCurrencyConfirm(!showModalCurrencyConfirm);

		if (confirm) {
			save && save(language?.value ?? '', currency?.value ?? '');
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<section className='multi-language-currency flex flex-row text-black text-sm' onClick={toggleModal} aria-hidden='true'>
				{isShowLanguage && (
					<div className='multi-language-currency__language flex flex-row'>
						<IcomoonIcon className='mr-2' icon='iconGlobe' fontSize={18} color={colorPrimary} />
						<span className='multi-language-currency__typeTitle mr-1'>{language?.label}</span>
					</div>
				)}
				{isShowCurrency && (
					<div className='multi-language-currency__currency ml-2'>
						<span className='multi-language-currency__typeTitle mr-1'>{currency?.extraDescription}</span>
						<span className='multi-language-currency__typeTitle'>{currency?.value}</span>
					</div>
				)}
			</section>
			<BackDrop show={showBackDrop} zIndex={80} backgroundColor={alphaColorPrimary} opacity={0.5} />
			{showBackDrop && (
				<div className='multi-language-currency__modal'>
					{showModal && (
						<MultiLanguageCurrencySelector
							t={t}
							colorPrimary={colorPrimary}
							colorSecondary={colorSecondary}
							isShowCurrency={isShowCurrency}
							isShowLanguage={isShowLanguage}
							currencies={currencies}
							languages={languages}
							defaultCurrency={defaultCurrency}
							defaultLanguage={defaultLanguage}
							close={toggleModal}
							onSave={onSave}
						/>
					)}
					{showModalCurrencyConfirm && (
						<MultiCurrencyConfirm t={t} colorPrimary={colorPrimary} colorSecondary={colorSecondary} onEvent={onSaveConfirm} />
					)}
				</div>
			)}
		</>
	);
}

export default MultiLanguageCurrency;
