import { InputEventsHandler, DatePickerData } from '../../../shared/interfaces/inputs.interface';
import TimeRangePicker, { TimeRangePickerProps } from '../time-range-picker/time-range-picker';
import DateRangePicker, { DateRangePickerProps } from '../date-range-picker/date-range-picker';
import { DatePickerInputs, FormsInputsType } from '../../../shared/enums/inputs.enum';
import { DateLocales } from '../../../shared/enums/date.enum';
import styles from './date-time-range-picker.module.scss';
import Button from '../../ui-elements/button/button';
import { useState, useCallback } from 'react';

export interface DateTimeRangePickerProps extends TimeRangePickerProps, DateRangePickerProps {
	onClick?: (e: InputEventsHandler) => void;
	timeRangePickerClassName?: string;
	dateRangePickerClassName?: string;
	rightContainerClassName?: string;
}

export const DateTimeRangePicker = (props: DateTimeRangePickerProps): JSX.Element => {
	const [date, setDate] = useState<DatePickerData>({
		startDate: props?.defaultData?.startDate,
		startTime: props?.defaultData?.startTime,
		endDate: props?.defaultData?.endDate,
		endTime: props?.defaultData?.endTime,
	});

	const changeHandler = useCallback((event: InputEventsHandler): void => {
		const temp: DatePickerData = event.value as DatePickerData;
		switch (event.name) {
			case DatePickerInputs.dateRange:
				setDate((prevState: DatePickerData): DatePickerData => {
					return {
						...prevState,
						endDate: temp.endDate,
						startDate: temp.startDate,
					};
				});

				break;
			case DatePickerInputs.timeRange:
				setDate((prevState: DatePickerData): DatePickerData => {
					return {
						...prevState,
						endTime: temp.endTime,
						startTime: temp.startTime,
					};
				});

				break;
			default:
				break;
		}
	}, []);

	const buttonHandler = useCallback((): void => {
		if (props?.onClick) {
			const dataEvent: InputEventsHandler = {
				type: FormsInputsType.dateTimeRangePicker,
				name: props?.name,
				value: {
					startDate: date.startDate,
					startTime: date.startTime,
					endDate: date.endDate,
					endTime: date.endTime,
				},
			};

			props.onClick(dataEvent);
		}
	}, [props?.name, date]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`dateTimeRangePicker flex-row ${props.className || ''}`}>
				<DateRangePicker
					name={DatePickerInputs.dateRange}
					locale={props?.locale || DateLocales.es}
					minDate={props.minDate}
					maxDate={props.maxDate}
					dateRangeClassName={props.dateRangeClassName}
					defaultData={props.defaultData}
					colors={props.colors}
					className={props.dateRangePickerClassName}
					onChange={changeHandler}
					isSecondaryTemplate={props.isSecondaryTemplate}
					weekdayDisplayFormat={props.weekdayDisplayFormat}
				/>
				<div className={`dateTimeRangePicker__time flex flex-col ${props.rightContainerClassName || ''}`}>
					<TimeRangePicker
						name={DatePickerInputs.timeRange}
						labels={props.labels}
						startTimeConfig={props.startTimeConfig}
						endTimeConfig={props.endTimeConfig}
						defaultData={props.defaultData}
						timePickerMaxHeight={props.timePickerMaxHeight}
						colors={props.colors}
						timePickerClassName={props.timePickerClassName}
						className={`dateTimeRangePicker__timeRangePicker ${props.timeRangePickerClassName || ''}`}
						onChange={changeHandler}
						isSecondaryTemplate={props.isSecondaryTemplate}
					/>
					<div className='dateTimeRangePicker__buttonContainer flex flex-row gap-3'>
						{props.isSecondaryTemplate && (
							<Button
								text={props?.labels?.cancelText}
								customClass={'dateTimeRangePicker__cancelButton mt-6 text-xs border border-gray-300 bg-white rounded-full'}
								onClick={props.handleCancelClick}
							/>
						)}
						<Button
							text={props?.labels?.button}
							customClass={`dateTimeRangePicker__button dateTimeBtn text-xs mt-6 ${props.isSecondaryTemplate ? 'rounded-full' : 'rounded-lg w-full'}`}
							styles={{
								backgroundColor: props.colors?.main,
							}}
							onClick={buttonHandler}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

DateTimeRangePicker.defaultProps = {
	className: '',
	timeRangeClassName: '',
	dateRangePickerClassName: '',
	rightContainerClassName: '',
};

export default DateTimeRangePicker;
