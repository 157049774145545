import useWindowWidth from '../../../../../../shared/hooks/useWidth';
import Slider from '../../slider/slider';

export interface SliderHomeSkeletonProps {
	className?: string;
}

export function SliderHomeSkeleton({ className }: SliderHomeSkeletonProps): JSX.Element {
	const buttons: number[] = [1, 2, 3];
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const iconWidth: number = 65;
	const half: number = 2;
	return (
		<div className={`sliderHomeSkeleton relative animate-pulse max-w-[1440px] ${className || ''}`}>
			<Slider
				slides={[]}
				slidesHeight={windowWidth >= responsiveSize ? '800px' : '640px'}
				config={{
					showContent: true,
					showLinkButton: true,
					showSubtitleLine: true,
					showBackgroundImage: true,
				}}
				loading={true}
				skeletonSlides={1}
				slidesSkeletonClassNames={{
					outClassName: 'w-full',
					contentClassName:
						'sliderHomeSkeleton__container z-10 pb-48 md:pb-[255px] px-6 sm:px-12 md:px-20 lg:px-28 xl:px-0 xl:pl-64 w-full xl:max-w-5xl',
					titleClassName: 'mb-2.5 w-4/5 h-6 md:h-14',
					subtitleClassName: 'w-2/4 h-5 md:h-10 mb-2.5',
					descriptionClassName: 'h-4 md:h-6 mt-2',
					subtitleLineClassName: 'border-white',
					buttonClassName: 'mt-8 rounded-full bg-white opacity-80 w-40 md:w-48 h-12 md:h-14',
				}}
				slidesWidth={0}
				slidesMargin={0}
				sliderSideMargin={0}
				slidesNumberShow={0}
			/>
			{windowWidth >= responsiveSize && (
				<div
					className='absolute bottom-32 z-20 flex flex-col justify-center h-14 w-16 bg-gray-200'
					style={{
						width: `${iconWidth}px`,
						left: `calc(50% - ${iconWidth / half}px)`,
					}}
				/>
			)}
			{
				<div className='absolute bottom-20 md:bottom-32 right-7 md:right-14 z-10 flex flex-row justify-end items-center'>
					{buttons.map((item: number, index: number) => {
						const isActive: boolean = index === 0;
						return (
							<div
								key={index}
								className={`mx-1.5 bg-gray-200 border-gray-200 ${
									isActive ? 'h-px w-8 md:w-12 border-b-4 rounded-xl' : 'h-1.5 md:h-3 w-1.5 md:w-3 rounded-full'
								}`}
							/>
						);
					})}
				</div>
			}
		</div>
	);
}

export default SliderHomeSkeleton;
