import { PsePaymentsInterface, BriefCasePaymentsInterface } from '../../../../../shared/interfaces/payments/payment-methods.interface';
import { PaymentStepResultEventInterface } from '../../../../../shared/interfaces/payments/steps.interface';
import { FormDynamic, Forms, FormsSets } from '../../../../../shared/interfaces/dynamic-forms.interface';
import { FormTypes, PaymentMethodsTypes } from '../../../../../shared/enums/dynamic-forms.enum';
import HorizontalDecription from '../../../../ui-elements/horizontal-description/horizontal-description';
import { IcomoonIcon } from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import DynamicForms from '../../generics/dynamic-forms/dynamic-forms';
import { TabData } from '../../../../../shared/interfaces/tabs';
import Tabs from '../../../../shared-components/tabs/tabs';
import Image from '../../../../ui-elements/image/image';
import styles from './payment-methods.module.scss';
import { useEffect, useState } from 'react';

const icons: string[] = ['ccMastercardIco', 'ccVisaIco', 'ccDinersClubIco', 'ccAmexIco'];
export interface PaymentMethodsProps {
	formDynamic: FormDynamic;
	idComponent: string;
	activeMethodTab: string;
	methodsTabs: TabData[];
	nextText: string;
	previousText: string;
	currency: string;
	pseInfo: PsePaymentsInterface;
	briefcaseInfo: BriefCasePaymentsInterface;
	onTabClick: (tab: string) => void;
	onNextClick: (data: PaymentStepResultEventInterface) => void;
	submitForm: (data: Record<string, string | boolean>) => void;
	onPreviousClick: (data: PaymentStepResultEventInterface) => void;
	errorForm?: (flag: boolean) => boolean;
	cancelForm?: (flag: boolean) => boolean;
}

export const PaymentMethods = (props: PaymentMethodsProps): JSX.Element => {
	const [formSet, setFormSet] = useState<FormsSets>();
	useEffect(() => {
		const paymentMethodsForm: Forms | undefined = props.formDynamic.forms.find(
			(tempForm: Forms): boolean => tempForm.formType === FormTypes.paymentMethods,
		);

		const targetFormSet: FormsSets | undefined = paymentMethodsForm
			? paymentMethodsForm.formDefintions.find((tempFormSet: FormsSets): boolean => tempFormSet.paymentMethodType === props.activeMethodTab)
			: undefined;

		setFormSet(targetFormSet ? { ...targetFormSet } : undefined);
	}, [props.activeMethodTab]);

	function onSubmitEvent(data: Record<string, string | boolean>): void {
		props.submitForm(data);

		props.onNextClick({ id: props.idComponent });
	}

	function onPreviousEvent(data: boolean): void {
		props.onPreviousClick({ id: props.idComponent });
	}

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='flex flex-col justify-center'>
				<Tabs activeTab={props.activeMethodTab} tabs={props.methodsTabs} onClick={props.onTabClick} className='pb-6' />
				<div className='flex'>
					{props.activeMethodTab === PaymentMethodsTypes.pse && (
						<div className='flex flex-col items-center'>
							<Image url={props.pseInfo.image} width={'237.27px'} height={'190px'} />
							<div className={'text-xs font-family-regular'}>
								<p className={'mt-2 paymentMethods__Pselabel'}>
									{props.pseInfo.firstParagraph}
									<b>{props.pseInfo.boldParagraph}</b>
									{props.pseInfo.secondParagraph}
								</p>
								<p className={'mt-2 paymentMethods__PsePaymentLabel'}>{props.pseInfo.thirdParagraph}</p>
							</div>
						</div>
					)}
					<div className='flex-grow flex flex-col w-full ml-2'>
						{props.activeMethodTab === PaymentMethodsTypes.creditCard && (
							<div className={'bg-white flex flex-row justify-start mb-3 md:pl-2'}>
								{icons.map(icon => (
									<IcomoonIcon key={icon} icon={icon} className={'m-1.5'} fontSize={25} color='#213261' />
								))}
							</div>
						)}
						{props.activeMethodTab === PaymentMethodsTypes.briefcase && (
							<h1 className={'paymentMethods__briefCaseTitle font-bold'}>{props.briefcaseInfo.title}</h1>
						)}
						{formSet && (
							<DynamicForms
								formSet={formSet}
								actionsClassName={'flex flex-col md:flex-row items-center md:justify-end mt-6'}
								submitButtonProps={{
									title: props.nextText,
									hide: false,
									className: 'w-full md:w-1/5 text-xs text-white rounded-lg my-2 md:my-0 paymentMethods__button',
								}}
								cancelButtonProps={{
									title: props.previousText,
									hide: false,
									className: 'w-full md:w-1/5 md:mr-2 text-xs text-white rounded-lg my-2 md:my-0 paymentMethods__button',
								}}
								showTextErrors={true}
								showlabel={true}
								containerElementClassName={'bg-gray-100 m-2'}
								submitForm={onSubmitEvent}
								cancelForm={onPreviousEvent}
								errorForm={props.errorForm}
							/>
						)}
						{props.activeMethodTab === PaymentMethodsTypes.briefcase && (
							<>
								<div className={'paymentMethods__briefCaseDescription'}>
									<HorizontalDecription
										label={props.briefcaseInfo.labelPurchase}
										value={props.briefcaseInfo.valuePurchase}
										detailedDescriptionRight={props.currency}
									/>
								</div>
								<div className={'paymentMethods__briefCaseDescription'}>
									<HorizontalDecription
										label={props.briefcaseInfo.labelnewBalance}
										value={props.briefcaseInfo.valueNewBalance}
										detailedDescriptionRight={props.currency}
										fullFontBold={true}
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

PaymentMethods.defaultProps = {
	currency: 'COP',
	maxFee: 1000000000,
	minFee: 0,
};

export default PaymentMethods;
