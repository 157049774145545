import { FlightPathsInterface, Legs, Baggage } from '../../../../../../shared/interfaces/flight-paths.interface';
import { differenceBetweenDates, removeZuluTime } from '../../../../../../shared/services/utils.service';
import { formatDate } from '../../../../../../shared/services/dates-managment.service';
import { DateFormat } from '../../../../../../shared/enums/date.enum';
import { Image } from '../../../../../ui-elements/image/image';
import styles from './flight-path.module.scss';

export interface FlightPathProps {
	title: string;
	titleJourney: string;
	tripType: string;
	timeHead?: string;
	baggageHead?: string;
	flightPaths: FlightPathsInterface;
	isActive?: boolean;
	typeFlight: string[];
	tooltipHead?: string;
	onClick?: () => void;
}

export function FlightPath(props: FlightPathProps): JSX.Element {
	const onePathFligth: number = 1;
	const arrivalDate: string = removeZuluTime(props.flightPaths.arrivalDate);
	const departureDate: string = removeZuluTime(props.flightPaths.departureDate);
	const validationArrivalDate: string = formatDate(arrivalDate, DateFormat.standarStringDate);
	const validationDepartureDate: string = formatDate(departureDate, DateFormat.standarStringDate);
	const isMultipleRoutes = (legs: Legs[]): boolean => {
		let multiPathFligth: boolean = false;
		if (legs.length > onePathFligth) {
			multiPathFligth = true;
		}

		return multiPathFligth;
	};

	function validationActiveBaggage(baggage: Baggage[], typeBaggage: string): boolean {
		let baggageActive: boolean = false;
		if (baggage && baggage.length) {
			baggage.forEach((element: Baggage) => {
				if (element.type === typeBaggage && element.isIncluded) {
					baggageActive = true;
				}
			});
		}

		return baggageActive;
	}
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='container w-full bg-white'>
				<div className='container w-full mt-0.5 shadow  p-5 bg-white flex'>
					<div className='flex-none w-25 flex-initial mr-5'>
						<h2 className={'font-family-bold text-left text-base leading-6 w-full flightPath__titleBlue'}>
							{props.tripType === 'multiple' ? props.titleJourney : props.title}
						</h2>
						<h4 className={'text-left font-sans text-xs'}>{formatDate(departureDate, DateFormat.largeDate)}</h4>
						<div className='flex'>
							<Image url={props.flightPaths.validationCarrier.logoUrl} width={'40px'} height={'40px'} customClass={'mx-auto m-3 mt-3'} />
							<div className='ml-2 mt-3'>
								<h4 className={'text-left font-sans text-xs font-family-regular'}>{props.flightPaths.validationCarrier.name}</h4>
								<div className='flex'>
									<div className='text-center'>
										<span className={'text-xs rounded-personal px-2 py-1 font-family-regular flightPath__roundedPersonal'}>
											{props.flightPaths.legs[0].classOfService}
										</span>
									</div>
									{props.flightPaths.fareFamily?.name ? (
										<div className='text-center'>
											<span className={'text-xs rounded-personal px-2 py-1 font-family-regular flightPath__roundedPersonal'}>
												{props.flightPaths.fareFamily?.name}
											</span>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className='flex-initial flex flex-grow mt-4'>
						<div className='flex-grow w-1	max-w-sm'>
							<p className={'font-family-bold text-left text-base leading-6 text-center flightPath__titleBlue'}>
								{props.flightPaths.departureCity.airportCode}
							</p>
							<p className={'text-left font-sans text-xs	mb-1 font-family-regular text-black text-opacity-75 my-1 text-center flightPath__titleBlue'}>
								{props.flightPaths.departureCity.cityName}
							</p>
							<p className='text-left font-sans text-base text-center text-gray-400 font-family-regular'>
								{formatDate(props.flightPaths.departureTime, DateFormat.timeTime)}
							</p>
						</div>
						<div className='flex-grow mt-9'>
							<h4 className='text-left font-sans text-center font-family-regular text-xs'>
								{isMultipleRoutes(props.flightPaths.legs) ? `${props.flightPaths.legs.length - 1} ${props.typeFlight[1]}` : props.typeFlight[0]}
							</h4>
							<div className='w-full relative'>
								<span className={`${isMultipleRoutes(props.flightPaths.legs) ? 'flightPath__isRouting' : 'block'}`}></span>
								<hr className='my-2' />
							</div>
						</div>
						<div className='flex-grow w-1	 max-w-sm'>
							<p className={'font-family-bold text-left text-base leading-6 text-center flightPath__titleBlue'}>
								{props.flightPaths.arrivalCity.airportCode}
							</p>
							<p className={'text-left font-sans text-xs	mb-1 font-family-regular text-black text-opacity-75 my-1 text-center flightPath__titleBlue'}>
								{props.flightPaths.arrivalCity.cityName}
							</p>
							<h2 className={'flex font-sans text-base font-family-regular justify-center items-center text-gray-400'}>
								{formatDate(props.flightPaths.arrivalTime, DateFormat.timeTime)}
								{validationArrivalDate !== validationDepartureDate && (
									<p className='text-[0.55rem] ml-1 text-red-500'>{`+ ${differenceBetweenDates(validationDepartureDate, validationArrivalDate)}`}</p>
								)}
							</h2>
						</div>
					</div>
					<div className='flex-initial flex-none w-30 m-2'>
						<p className={'title-black flightPath__titleBlue font-family-bold text-left text-xs leading-6 w-full text-center mt-3'}>
							{props.baggageHead}
						</p>
						<div className='flex mt-2 text-center'>
							<span
								className={`smallBagIco text-center mx-1 flightPath__bagIcon ${
									validationActiveBaggage(props.flightPaths.fareFamily?.baggage, 'personalItem') ? 'flightPath__bagActive' : ''
								}`}
							></span>
							<span
								className={`mediumBagIco text-center mx-1 flightPath__bagIcon ${
									validationActiveBaggage(props.flightPaths.fareFamily?.baggage, 'carryOn') ? 'flightPath__bagActive' : ''
								}`}
							></span>
							<span
								className={`largeBagIco text-center mx-1 flightPath__bagIcon ${
									validationActiveBaggage(props.flightPaths.fareFamily?.baggage, 'checked') ? 'flightPath__bagActive' : ''
								}`}
							></span>
						</div>
					</div>
					<div className='flex-initial flex-none w-30 m-2'>
						<p className={'title-black flightPath__titleBlue font-family-bold text-left text-xs leading-6 w-full text-center mt-3'}>
							{props.timeHead}
						</p>
						<p className={'text-left font-sans text-xs  text-center mt-5 fontRegular flightPath__titleBlue'}>
							{formatDate(props.flightPaths.flightDuration, DateFormat.hoursMinutesTime)}
						</p>
					</div>
					<div className='flex-initial flex-none w-30 m-1' onClick={props.onClick}>
						<p className='title-black title-blue-detail font-bold text-left text-xs leading-6 w-full text-center'></p>
						<span className={`text-center chevronRightIco mt-14 flightPath__iconArrow  ${!props.isActive ? 'open rotate-90' : '-rotate-90'}`}></span>
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightPath;
