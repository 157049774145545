import { Button } from '../../../../lib/ui-elements';
export interface ShowCaseNotificationProps {
	title: string;
	description: string;
	actionText?: string;
	actionLink?: string;
}

export const ShowCaseNotification = ({ title, description, actionText, actionLink }: ShowCaseNotificationProps): JSX.Element => {
	return (
		<div className='showCaseNotification bg-gray-500'>
			<div className='showCaseNotification__container px-8 py-4 flex items-center justify-between flex-col md:flex-row'>
				<div className={`${!actionText ? '' : 'max-w-[500px] lg:max-w-[750px]'}`}>
					<p className='showCaseNotification__title text-white text-xl'>{title}</p>
					<p className='showCaseNotification__description text-white'>{description}</p>
				</div>

				{actionText && (
					<div>
						<a href={actionLink} target={'_blank'} rel='noreferrer'>
							<Button text={actionText} customClass='showCaseNotification__btn mt-4 !bg-gray-100 text-white !px-[40px]' />
						</a>
					</div>
				)}
			</div>
		</div>
	);
};
