import { Image } from '../../../../../ui-elements/image/image';
import styles from './flights-banner-v1.module.scss';
export interface FlightsBannerV1Props {
	bannerImage: string;
	title: string;
	subtitle: string;
	description: string;
}

export function FlightsBannerV1(props: FlightsBannerV1Props): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='flightsBannerV1 relative'>
				<Image
					url={props.bannerImage}
					width={'1440px'}
					height={'533px'}
					mobileLargeHeight={true}
					customClass={'lg:w-full'}
					styles={{
						minHeight: '533px',
					}}
				/>

				<div className='absolute bottom-8 left-0 z-10 px-6 md:px-28 pb-20 max-w-4xl'>
					<p className={'text-sm md:text-1xl text-white font-family-black text-center md:text-left flightsBannerV1__subtitle'}>{props.subtitle}</p>
					<h1 className='text-6xl md:text-8xl text-white font-family-black text-center md:text-left'>{props.title}</h1>
					<p className={'text-sm md:text-base text-white font-family-regular text-center md:text-left flightsBannerV1__description'}>
						{props.description}
					</p>
				</div>
			</div>
		</>
	);
}

export default FlightsBannerV1;
