import { TableData } from '../../../shared/interfaces/table';
/* eslint-disable-next-line */

export interface TableProps {
	data: TableData;
	className?: string;
	iconHead?: string;
	tdClassName?: string;
}

export const Table = ({ data, className, iconHead, tdClassName }: TableProps): JSX.Element => {
	return (
		<table className={`libTable ${className || ''}`}>
			<thead>
				<tr>
					{data.head.map((label, index) => (
						<th key={index}>
							<div>
								<span className={iconHead} />
								{label.value}
							</div>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{data.rows.map((row, index) => (
					<tr className='table__tr' key={index}>
						{row.map((value, index) => (
							<td className={`${tdClassName || ''}`} key={index}>
								{value.value}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

Table.defaultProps = {
	className: '',
};

export default Table;
