import { getDaysFromDatesDiff } from '../../../../../../shared/services/dates-managment.service';
import { FareSummaryInterface } from '../../../../../../shared/interfaces/payments/summary.interface';
import { numberToLocaleWithcurrency } from '../../../../../../shared/services/utils.service';
import Spinner from '../../../../../ui-elements/spinner/spinner';
import { CarInterfaceV2 } from '../../../../shared/interfaces';
import Button from '../../../../../ui-elements/button/button';
import { useState, useEffect, useCallback } from 'react';
import styles from './car-float-button.module.scss';

export interface CarFloatButtonProps {
	coverageLabel?: string;
	totalDaysLabel?: string;
	coverageText?: string;
	totalDaysText?: string;
	btnActionLabel?: string;
	totalDaysLabelUnit?: string;
	summary: FareSummaryInterface;
	disabled?: boolean;
	carRecommendation: CarInterfaceV2;
	showChangedPrice?: boolean;
	currencySymbol?: string;
	callBack: (carRecommendation: CarInterfaceV2) => void;
}

export function CarFloatButton({
	summary,
	coverageLabel,
	coverageText,
	totalDaysLabel,
	btnActionLabel,
	disabled,
	carRecommendation,
	totalDaysLabelUnit,
	showChangedPrice,
	currencySymbol,
	callBack,
}: CarFloatButtonProps): JSX.Element {
	const [clickLoading, setClickLoading] = useState<boolean>(false);
	const [totalDays, setTotalDays] = useState<number>(0);
	const buttonClickHandler = useCallback(
		(data: CarInterfaceV2): void => {
			if (callBack) {
				callBack(data);

				setClickLoading(true);
			}
		},
		[callBack],
	);

	useEffect(() => {
		if (!disabled) {
			setClickLoading(false);
		}
	}, [disabled]);

	useEffect(() => {
		if (carRecommendation) {
			const pickUpDate: string = carRecommendation?.rentalSchedule?.pickUpDate ?? '';
			const dropOffDate: string = carRecommendation?.rentalSchedule?.dropOffDate ?? '';
			const totalDays: number = getDaysFromDatesDiff(dropOffDate, pickUpDate);
			setTotalDays(totalDays);
		}
	}, [carRecommendation]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={'carFloatButton'}>
				<div className={'carFloatButton__container m-[15px]'}>
					<p className='carFloatButton__regularText text-xs'>
						{coverageLabel || 'coverageLabel'}: {coverageText || 'coverageText'}
					</p>
					<p className='carFloatButton__mediumText text-xs font-family-bold'>
						{totalDaysLabel || 'totalDaysLabel'} {totalDays} {totalDaysLabelUnit || 'totalDaysLabelUnit'}
					</p>
					<p className='carFloatButton__boldTitle font-family-bold'>
						{typeof summary?.total === 'number' || summary?.total === undefined
							? `${summary?.currency} ${numberToLocaleWithcurrency(summary?.total, summary?.currency)}`
							: summary?.total}
					</p>
					{typeof summary?.subTotal === 'number' && showChangedPrice && (
						<p className='carFloatButton__boldTitle font-family-bold'>
							{`+ ${numberToLocaleWithcurrency(summary?.subTotal || 0, summary?.currency, '', currencySymbol)}`}
						</p>
					)}
				</div>

				<div className='carFloatButton__action m-[15px]'>
					<Button
						content={clickLoading && disabled ? <Spinner className={'carFloatButton__spinner'} /> : <span>{btnActionLabel || 'btnActionLabel'}</span>}
						customClass={'w-full rounded-full text-white  bg-black carFloatButton__spinner'}
						onClick={() => buttonClickHandler(carRecommendation)}
						disabled={disabled}
					/>
				</div>
			</div>
		</>
	);
}

export default CarFloatButton;
