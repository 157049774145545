import { DateInterface, DateConfigurationInterface } from '../interfaces/date.interface';
import { DateFormat } from '../enums/date.enum';
import { capitalize } from './utils.service';

const getConfigurationTime = (dateString: string): DateInterface => {
	const timeFormatDelimiter: string = ':';
	const maxNumbersOfSubStrings: number = 3;
	const minNumbersOfSubStrings: number = 2;
	const secondsIndex: number = 2;
	const minutesIndex: number = 1;
	const hoursIndex: number = 0;
	const zeroTime: string = '00';
	const newDate: string[] = dateString.split(timeFormatDelimiter);
	const createDate: DateInterface = {
		hours: newDate[hoursIndex],
		minutes: newDate[minutesIndex],
		seconds: newDate.length === maxNumbersOfSubStrings ? newDate[secondsIndex] : zeroTime,
	};

	return newDate.length === maxNumbersOfSubStrings || newDate.length === minNumbersOfSubStrings ? createDate : {};
};

const getConfigurationDate = (dateString: string, configuration: DateConfigurationInterface, culture: string = 'es-CO'): DateInterface => {
	const newDate: Date = new Date(dateString.replace('Z', ''));
	const newYear: string = newDate.toLocaleDateString(culture, {
		year: configuration.year,
	});

	const newMonth: string = newDate.toLocaleDateString(culture, {
		month: configuration.month,
	});

	const newDay: string = newDate.toLocaleDateString(culture, {
		day: configuration.day,
	});

	const newWeekday: string = newDate.toLocaleDateString(culture, {
		weekday: configuration.weekday,
	});

	const newTime: string = newDate.toLocaleTimeString(
		configuration.noSeconds ? [] : culture,
		configuration.hour12
			? {
				hour: configuration.hour,
				minute: configuration.minute,
				hour12: configuration.hour12,
			  }
			: {
				hour: configuration.hour,
				minute: configuration.minute,
				hourCycle: 'h23',
			  },
	);

	const createDate: DateInterface = {
		day: newDay,
		month: capitalize(newMonth),
		year: newYear,
		time: newTime,
		weekday: capitalize(newWeekday),
	};

	return createDate;
};

export const formatDate = (
	dateString: string,
	typeFormat: DateFormat = DateFormat.smallDate,
	culture: string = 'es-CO',
	yearFlag: boolean = true,
	hour12: boolean = true,
): string => {
	let date: DateInterface;
	switch (typeFormat) {
		case DateFormat.url:
			date = getConfigurationDate(
				dateString,
				{
					month: '2-digit',
					day: '2-digit',
					year: 'numeric',
				},
				culture,
			);

			return `${date.year || ''}${date.month || ''}${date.day || ''}`;
		case DateFormat.smallDate:
			date = getConfigurationDate(
				dateString,
				{
					month: '2-digit',
					day: '2-digit',
					year: 'numeric',
				},
				culture,
			);

			return `${date.year || ''}-${date.month || ''}-${date.day || ''}`;
		case DateFormat.largeDate:
			date = getConfigurationDate(
				dateString,
				{
					weekday: 'short',
					month: 'short',
					day: '2-digit',
					year: 'numeric',
				},
				culture,
			);

			return `${date?.weekday ? date?.weekday.replace('.', '') : ''}, ${date.month || ''} ${date.day || ''} ${
				yearFlag ? `. ${date.year || ''}` : ''
			}`;

		case DateFormat.completeDate:
			date = getConfigurationDate(
				dateString,
				{
					weekday: 'short',
					month: 'long',
					day: '2-digit',
					year: 'numeric',
				},
				culture,
			);

			return `${date?.weekday ? capitalize(date?.weekday) : ''}, ${date.day || ''} ${date.month || ''} ${date.year || ''}`;

		case DateFormat.fullDate:
			date = getConfigurationDate(
				dateString,
				{
					month: 'long',
					day: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					hour12,
				},
				culture,
			);

			return `${date.month || ''} ${date.day || ''}. ${date.year || ''} - ${date.time || ''}`;
		case DateFormat.fullDateTwentyFour:
			date = getConfigurationDate(
				dateString,
				{
					month: 'long',
					day: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					hour12,
				},
				culture,
			);

			return `${date.month || ''} ${date.day || ''}, ${date.year || ''} - ${date.time || ''}`;
		case DateFormat.standarStringDate:
			date = getConfigurationDate(
				dateString,
				{
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
				},
				culture,
			);

			return `${date.year || ''}-${date.month || ''}-${date.day || ''}`;
		case DateFormat.timeDate:
			date = getConfigurationDate(
				dateString,
				{
					hour: '2-digit',
					minute: '2-digit',
					hour12,
				},
				culture,
			);

			return `${date.time || ''}`;
		case DateFormat.timeTime:
			date = getConfigurationTime(dateString);

			return `${date.hours || ''}:${date.minutes || ''}`;
		case DateFormat.hoursMinutesTime:
			date = getConfigurationTime(dateString);

			return `${date.hours || ''}h${date.minutes || ''}m`;
		case DateFormat.weekdaySingle:
			date = getConfigurationDate(
				dateString,
				{
					weekday: 'short',
				},
				culture,
			);

			return date.weekday || '';
		case DateFormat.dayMonth:
			date = getConfigurationDate(
				dateString,
				{
					day: '2-digit',
					month: 'short',
				},
				culture,
			);

			return `${date.day || ''} ${date.month || ''}`;
		case DateFormat.daySingle:
			date = getConfigurationDate(
				dateString,
				{
					day: '2-digit',
				},
				culture,
			);

			return date.day || '';
		case DateFormat.monthYear:
			date = getConfigurationDate(
				dateString,
				{
					month: 'short',
					year: 'numeric',
				},
				culture,
			);

			return `${date.month || ''} ${date.year || ''}`;
		case DateFormat.birthdayDate:
			date = getConfigurationDate(
				dateString,
				{
					weekday: 'short',
					month: 'short',
					day: '2-digit',
					year: 'numeric',
				},
				culture,
			);

			return `${date.month || ''} ${date.day || ''}, ${yearFlag ? `${date.year || ''}` : ''}`;

		case DateFormat.weekdayDayMonth:
			date = getConfigurationDate(
				dateString,
				{
					weekday: 'short',
					day: '2-digit',
					month: '2-digit',
				},
				culture,
			);

			return `${date.weekday ?? ''}, ${date.day ?? ''}/${date.month ?? ''}`;
		default:
			return dateString;
	}
};

export const timeToMinutes = (time: string): number => {
	const hourToMinutes: number = 60;
	const timeDate: DateInterface = getConfigurationTime(time);
	return Number(timeDate.hours) * hourToMinutes + Number(timeDate.minutes);
};

export const timeFromMinutes = (allMinutes: number): string => {
	const hourToMinutes: number = 60;
	const dayToHours: number = 24;
	const zeroTime: number = 0;
	const minWithTwoDigits: number = 10;
	const extraDigit: string = '0';
	const noExtraDigit: string = '';
	function z(n: number): string {
		const digit: string = n < minWithTwoDigits ? extraDigit : noExtraDigit;
		return `${digit}${n}`;
	}
	const hours: number = ((allMinutes / hourToMinutes) | zeroTime) % dayToHours;
	const minutes: number = allMinutes % hourToMinutes;
	return `${z(hours)}:${z(minutes)}`;
};

export const addTimes = (time1: string, time2: string): string => {
	return timeFromMinutes(timeToMinutes(time1) + timeToMinutes(time2));
};

export const getDaysFromDatesDiff = (time1: string, time2: string): number => {
	const _time1: Date = new Date(time1.replace('Z', ''));
	const _time2: Date = new Date(time2.replace('Z', ''));
	if (!isValidDate(_time1) || !isValidDate(_time2)) return 0;

	const diff = _time1.getTime() - _time2.getTime();
	return Math.floor(diff / (1000 * 3600 * 24));
};

export const formatTimeWithDoubleDot = (time: String): string => {
	return time?.replace(/.{2}/, '$&:');
};

export const isValidDate = (date: Date): boolean => {
	return date instanceof Date && !isNaN(date.getTime());
};
