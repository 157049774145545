import { formatDate, isValidDate } from '../services/dates-managment.service';
import { DatesFieldTypes } from '../enums/search-fields.enum';
import {
	DatesFieldInputNamesInterface,
	DisplayValuesDatesFieldInterface,
	ConvertDatesResponseInterface,
	DiscountFieldInputNamesInterface,
	PassengersFieldInputNamesInterface,
	DatesFieldConfigInterface,
	FilterFieldInputNamesInterface,
	NationalityFieldInputNamesInterface,
} from '../interfaces/generics/search-fields.interface';
import { DateFormat } from '../enums/date.enum';

export class SearchFieldsUtil {
	public static getDatesInputsNames(name: string): DatesFieldInputNamesInterface {
		return {
			startDate: `${name}StartDate`,
			startTime: `${name}StartTime`,
			endDate: `${name}EndDate`,
			endTime: `${name}EndTime`,
		};
	}

	public static convertDatesFromUrl(date: string): string {
		const firstItem: number = 0;
		const dateChars: number = 8;
		const yearIndex: number = 4;
		const monthIndex: number = 6;
		const dayIndex: number = 8;
		return date.length === dateChars
			? `${date.substring(firstItem, yearIndex)}-${date.substring(yearIndex, monthIndex)}-${date.substring(monthIndex, dayIndex)}`
			: '';
	}

	private static convertDates(startDate?: string | Date, endDate?: string | Date): ConvertDatesResponseInterface {
		let tempStartDate: Date | undefined;
		let tempEndDate: Date | undefined;
		if (startDate && typeof startDate === 'string') {
			tempStartDate = new Date(`${startDate}T00:00:00`);
		} else if (startDate && startDate instanceof Date) {
			tempStartDate = startDate;
		}

		if (endDate && typeof endDate === 'string') {
			tempEndDate = new Date(`${endDate}T00:00:00`);
		} else if (endDate && endDate instanceof Date) {
			tempEndDate = endDate;
		}

		return {
			convertStartDate: tempStartDate && isValidDate(tempStartDate) ? tempStartDate : undefined,
			convertEndDate: tempEndDate && isValidDate(tempEndDate) ? tempEndDate : undefined,
		};
	}

	public static getDisplayDatesFormmated(
		type: DatesFieldTypes,
		config: DatesFieldConfigInterface,
		startDate?: string | Date,
		startTime?: string,
		endDate?: string | Date,
		endTime?: string,
		completeDate?: boolean,
		culture: string = 'es-CO',
		defaultDateFormat?: DateFormat,
	): DisplayValuesDatesFieldInterface {
		let startDateFormmated: string = '';
		let endDateFormmated: string = '';
		let largeStartDateFormmated: string = '';
		let largeEndDateFormmated: string = '';
		const { convertStartDate, convertEndDate } = this.convertDates(startDate, endDate);
		const dateFormat = completeDate ? DateFormat.completeDate : defaultDateFormat ?? DateFormat.largeDate;
		if (convertStartDate) {
			largeStartDateFormmated = `${formatDate(convertStartDate.toString() || '', dateFormat, culture, false)}${
				config.time ? ` - ${startTime || ''}` : ''
			}`;

			startDateFormmated = formatDate(convertStartDate.toString() || '');
		}

		if (convertEndDate) {
			const formattedDate: string = formatDate(convertEndDate.toString() || '', dateFormat, culture, false);
			const timeSuffix: string = config.time ? ` - ${endTime ?? ''}` : '';
			largeEndDateFormmated = `${formattedDate}${timeSuffix}`;

			endDateFormmated = formatDate(convertEndDate.toString() || '');
		}

		const datesToShow: string = `${type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate ? largeStartDateFormmated : ''}${
			type === DatesFieldTypes.startEndDates ? ` - ${largeEndDateFormmated}` : ''
		}`;

		return {
			startDate: startDateFormmated,
			endDate: endDateFormmated,
			largeStartDate: largeStartDateFormmated,
			largeEndDate: largeEndDateFormmated,
			dates: datesToShow,
		};
	}

	public static getDisplaySplitDatesFormmated(
		startDate: string | Date,
		startTime: string,
		endDate: string | Date,
		endTime: string,
		type: DatesFieldTypes = DatesFieldTypes.startEndDates,
		enabledDate: boolean = true,
		enabledTime: boolean = true,
		culture: string = 'es-CO',
	): DisplayValuesDatesFieldInterface {
		startDate = (type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && enabledDate ? startDate : '';

		startTime = (type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && enabledTime ? startTime : '';

		endDate = type === DatesFieldTypes.startEndDates && enabledDate ? endDate : '';

		endTime = type === DatesFieldTypes.startEndDates && enabledTime ? endTime : '';

		let startDateFormmated: string = '';
		let endDateFormmated: string = '';
		let largeStartDateFormmated: string = '';
		let largeEndDateFormmated: string = '';
		const { convertStartDate, convertEndDate } = this.convertDates(startDate, endDate);
		if (convertStartDate) {
			largeStartDateFormmated = `${formatDate(convertStartDate.toString() || '', DateFormat.birthdayDate, culture, true)} - ${startTime}`;

			startDateFormmated = formatDate(convertStartDate.toString() || '');
		}

		if (convertEndDate) {
			largeEndDateFormmated = `${formatDate(convertEndDate.toString() || '', DateFormat.birthdayDate, culture, true)} - ${endTime}`;

			endDateFormmated = formatDate(convertEndDate.toString() || '');
		}

		const datesToShow: string = `${largeStartDateFormmated} - ${largeEndDateFormmated}`;
		return {
			startDate: startDateFormmated,
			endDate: endDateFormmated,
			largeStartDate: largeStartDateFormmated,
			largeEndDate: largeEndDateFormmated,
			dates: datesToShow,
		};
	}

	public static getDiscountsInputsNames(name: string): DiscountFieldInputNamesInterface {
		return {
			name,
			discountName: `${name}DiscountName`,
			promotionalCodeName: `${name}PromotionalCodeName`,
			rewardsPointsName: `${name}RewardsPointsName`,
			discountNameText: `${name}DiscountNameText`,
			promotionalCodeNameText: `${name}PromotionalCodeNameText`,
			rewardsPointsNameText: `${name}RewardsPointsNameText`,
			discountNameSelect: `${name}DiscountNameSelect`,
			promotionalCodeNameSelect: `${name}PromotionalCodeNameSelect`,
			rewardsPointsNameSelect: `${name}RewardsPointsNameSelect`,
		};
	}

	public static getPassengersInputsNames(name: string): PassengersFieldInputNamesInterface {
		const passengerField: PassengersFieldInputNamesInterface = {
			name,
			adultsName: `${name}AdultsName`,
			childrenName: `${name}ChildrenName`,
			infantsName: `${name}InfantsName`,
			hiddenAdultsName: `${name}HiddenAdultsName`,
			hiddenChildrenName: `${name}HiddenChildrenName`,
			hiddenInfantsName: `${name}HiddenInfantsName`,
		};

		return passengerField;
	}

	public static getDisplayPassengersInfo(
		adultsValue: number,
		childrenValue: number,
		infantsValue: number,
		adultText?: string,
		adultsText?: string,
		childText?: string,
		childrenText?: string,
		infantText?: string,
		infantsText?: string,
		isTertiaryTemplate?: boolean,
	): string {
		const singularText: number = 1;
		let passengersDisplayValue: string = '';
		const separator: string = isTertiaryTemplate ? ' · ' : ', ';
		passengersDisplayValue += adultsValue ? `${adultsValue} ${adultsValue === singularText ? adultText || 'adult' : adultsText || 'adults'}` : '';

		if (childrenValue) {
			const childTextValue: string = childrenValue === singularText ? childText ?? 'child' : childrenText ?? 'children';
			const childrenDisplayValue: string = `${passengersDisplayValue ? separator : ''}${childrenValue} ${childTextValue}`;
			passengersDisplayValue += childrenDisplayValue;
		}

		if (infantsValue) {
			const infantsTextValue: string = infantsValue === singularText ? infantText ?? 'infant' : infantsText ?? 'infants';
			const infantsDisplayValue: string = `${passengersDisplayValue ? separator : ''}${infantsValue} ${infantsTextValue}`;
			passengersDisplayValue += infantsDisplayValue;
		}

		return passengersDisplayValue;
	}

	public static getFilterInputsNames(name: string): FilterFieldInputNamesInterface {
		return {
			name,
			hiddenName: `${name}HiddenName`,
		};
	}

	public static getNationalityFieldInputNamesInterfaceInputsNames(name: string): NationalityFieldInputNamesInterface {
		return {
			name,
			hiddenName: `${name}HiddenName`,
		};
	}
}
