function Loader(): JSX.Element {
	const styles = `
  .loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .loader__container {
    opacity: 0.2;
    margin-top: 60px;
    max-height: 510px;
    max-width: 290px;
    width: 100%;
  }

  .loader__item {
    animation: loader 1.2s linear infinite;
    background: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: inline-block;
    height: 50px;
    transform-origin: bottom center;
    width: 10px;
  }

  .loader__item:nth-child(1) {
    animation-delay: 0.1s;
  }

  .loader__item:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loader__item:nth-child(3) {
    animation-delay: 0.3s;
  }

  .loader__item:nth-child(4) {
    animation-delay: 0.4s;
  }

  .loader__item:nth-child(5) {
    animation-delay: 0.5s;
  }

  .loader__item:nth-child(6) {
    animation-delay: 0.6s;
  }

  .loader__item:nth-child(7) {
    animation-delay: 0.7s;
  }

  .loader__item:nth-child(8) {
    animation-delay: 0.8s;
  }

  @keyframes loader {
    0% {
      transform: scaleY(0.1);
      background: transparent;
    }
    50% {
      transform: scaleY(1);
      background: gray;
    }
    100% {
      transform: scaleY(0.1);
      background: transparent;
    }
  }
  `;

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles }}></style>
			<div className={`relative loader__container`}>
				<div className={`loader loader}`}>
					<div className={`loader__item `}></div>
					<div className={`loader__item `}></div>
					<div className={`loader__item `}></div>
					<div className={`loader__item `}></div>
					<div className={`loader__item `}></div>
					<div className={`loader__item `}></div>
					<div className={`loader__item `}></div>
					<div className={`loader__item `}></div>
				</div>
			</div>
		</>
	);
}

export default Loader;
