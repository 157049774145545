import { useEffect, useState } from 'react';
import styles from './toggle.module.scss';
export interface ToggleProps {
	label: string;
	colorPrimary?: string;
	className?: string;
	toggleClassName?: string;
	labelClassName?: string;
	checked?: boolean | undefined;
	readonly?: boolean;
	emitCheckedElement?: (value: { checked: boolean }) => void;
	normalSize?: boolean;
}

export const Toggle = ({
	checked,
	readonly,
	className,
	colorPrimary,
	label,
	emitCheckedElement,
	labelClassName,
	toggleClassName,
	normalSize,
}: ToggleProps): JSX.Element => {
	const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const emitChecked = (checkStatus: boolean): void => {
		if (readonly) {
			checkStatus = !!isChecked;
		}

		if (emitCheckedElement) {
			emitCheckedElement({ checked: checkStatus });
		}

		setIsChecked(checkStatus);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<label
				className={`toggle flex items-center cursor-pointer ${className || ''} ${normalSize ? 'toggle__normalSize gap-2' : ''} ${
					readonly ? 'hidden' : ''
				}`}
			>
				<span
					className={`cursor-pointer toggle ${toggleClassName || ''} ${isChecked ? 'toggle__checked' : ''} ${
						normalSize ? 'flex items-center' : 'scale-75 h-8 w-14 inline-block'
					}  relative pointer-events-none`}
				>
					<input
						className='opacity-0 w-0 h-0 hidden'
						type='checkbox'
						readOnly={readonly}
						defaultChecked={isChecked}
						onChange={e => emitChecked(e.target.checked)}
					/>
					{normalSize ? (
						<div
							className='relative inline-block w-9 h-5 rounded-full transition-colors duration-200 ease-in-out'
							style={{
								backgroundColor: isChecked ? colorPrimary : '#f0f0f0',
							}}
						>
							<div
								className={`absolute top-[2px] left-0 w-4 h-4 bg-white border-2 border-solid rounded-full transition-transform duration-200 ease-in-out 
								${checked ? 'transform translate-x-5' : ''}`}
							></div>
						</div>
					) : (
						<span
							className={'bg-gray-400 slide absolute pointer t-0 l-0 r-0 b-0 h-8 w-14 toggle__slide round'}
							style={{
								backgroundColor: isChecked ? colorPrimary || '#FDD008' : '',
							}}
						></span>
					)}
				</span>
				<p className={`${labelClassName || ''} ${normalSize ? 'toggle__label-normalSize' : 'text-xs ml-1 toggle__label'}`}>{label}</p>
			</label>
		</>
	);
};

Toggle.defaultProps = {
	className: '',
};

export default Toggle;
