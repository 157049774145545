import { ShortRecommendation } from '../../../../shared/interfaces/short-recommendation';
import { numberToLocaleWithcurrency } from '../../../../shared/services/utils.service';
import { formatDate } from '../../../../shared/services/dates-managment.service';
import { DateFormat } from '../../../../shared/enums/date.enum';
import Button from '../../../ui-elements/button/button';
import Image from '../../../ui-elements/image/image';
import styles from './recommendation.module.scss';
import { useState, useEffect } from 'react';

export interface RecommendationProps {
	data?: ShortRecommendation;
	onClick?: (data?: ShortRecommendation) => void;
}

export function Recommendation(props: RecommendationProps): JSX.Element {
	const [skeleton, setIsSkeleton] = useState<JSX.Element[]>([]);
	useEffect(() => {
		createskeleton();
	}, []);

	const createskeleton = (): void => {
		const arraySkeleton: JSX.Element[] = [];
		[...Array(1).keys()].forEach((item, index: number) => {
			const skeletonItem: JSX.Element = (
				<div className={'animate-pulse'} key={index}>
					<div className={'flex items-center bg-white rounded-2xl flex-col md:flex-row mb-9 md:h-28 recommendation__card'}>
						<div className='lg:w-1/5 flex items-center pt-7 md:pt-0 pl-1 pr-8 md:pr-0 flex-row md:flex-col w-full md:w-auto justify-between'>
							<div className='flex items-center md:order-2'>
								<div className={'bg-gray-300 ml-2 h-5 rounded recommendation__name'}></div>
							</div>

							<div className='block lg:hidden md:order-1 flex md:items-center md:relative md:-top-3 flex-col md:flex-row'>
								<div className='py-1 px-2 rounded-2xl bg-blue-900'></div>
								<div className='md:ml-2 self-end md:self-center'></div>
							</div>
						</div>
						<hr className={'hidden md:block bg-gray-200 recommendation__line'} />
						<div className='recommendation__destination flex items-center px-8 pt-7 md:pt-0 pb-7 md:pb-0 w-full md:w-3/5 lg:w-2/5 justify-between'>
							<div className='pr-7 lg:pr-14'>
								<div className='bg-gray-300 mb-2 h-3 w-28 rounded'></div>
								<div className='bg-gray-300 mb-2 h-3 w-28 rounded'></div>
								<div className='bg-gray-300 mb-2 h-3 w-28 rounded'></div>
							</div>

							<div>
								<div className='bg-gray-300 mb-2 h-3 w-28 rounded'></div>
								<div className='bg-gray-300 mb-2 h-3 w-28 rounded'></div>
								<div className='bg-gray-300 mb-2 h-3 w-28 rounded'></div>
							</div>
						</div>
						<hr className={'hidden md:block bg-gray-200 recommendation__line'} />
						<div className='lg:w-1/5 recommendation__days px-4 lg:px-8 hidden lg:block'>
							<div className='mt-2 bg-gray-300 h-5 rounded'></div>
						</div>
						<Button
							text={`
              <p class="bg-gray-300 top-3 absolute md:static h-3 w-8 mb-3 rounded"></p>
              <p class="bg-gray-300 flex items-end w-32 h-3 mb-3 rounded"></p>
              <p class="bg-gray-300 h-3 w-16 mb-3 rounded"> </p>`}
							customClass={`
              w-full  md:h-28 md:w-1/5 md:rounded-r-2xl flex items-start
              flex-row md:flex-col relative justify-between md:justify-center
              pt-7 md:pt-0 rounded-b-2xl md:rounded-bl-none bg-gray-100 px-3 lg:px-6`}
						/>
					</div>
				</div>
			);

			arraySkeleton.push(skeletonItem);
		});

		setIsSkeleton(arraySkeleton);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div>
				{props?.data ? (
					<div className={'flex items-center bg-white rounded-2xl flex-col md:flex-row mb-9 recommendation__card'}>
						<div className='lg:w-1/5 flex items-center pt-7 md:pt-0 pl-1 pr-8 md:pr-0 flex-row md:flex-col w-full md:w-auto justify-between'>
							<div className='flex items-center md:order-2'>
								<Image width={'45px'} height={'45px'} url={props?.data?.logo} />
								<p className={'font-family-bold text-xl text-gray-600 ml-2 recommendation__name'}>{props?.data?.name}</p>
							</div>

							<div className='block lg:hidden md:order-1 flex md:items-center md:relative md:-top-3 flex-col md:flex-row'>
								<span className='py-1 px-2 text-xs text-white font-family-bold rounded-2xl bg-blue-900'>Días de viaje</span>
								<p className='md:ml-2 text-xs text-gray-600 font-family-black self-end md:self-center'>{props?.data?.days} días</p>
							</div>
						</div>
						<hr className={'hidden md:block bg-gray-200 recommendation__line'} />
						<div className='recommendation__destination flex items-center px-8 pt-7 md:pt-0 pb-7 md:pb-0 w-full md:w-3/5 lg:w-2/5 justify-between'>
							<div className='pr-7 lg:pr-14'>
								<p className='font-family-bold text-xs text-gray-600 mb-1'>
									<span className='font-family-black'>SALIDA</span> BOG - {props?.data?.arrivalCode}
								</p>
								<p className='text-xs font-family-regular text-gray-600 mb-1'>Bogotá - {props?.data?.arrival}</p>
								<p className='text-xs font-family-bold text-gray-600 mb-1'>{formatDate(props?.data?.departureDate, DateFormat.largeDate)}</p>
							</div>

							<div>
								<p className='font-family-bold text-xs text-gray-600 mb-1'>
									<span className='font-family-black'>REGRESO</span> {props?.data?.arrivalCode} - BOG
								</p>
								<p className='text-xs font-family-regular text-gray-600 mb-1'>{props?.data?.arrival} - Bogotá</p>
								<p className='text-xs font-family-bold text-gray-600 mb-1'>{formatDate(props?.data?.arrivalDate, DateFormat.largeDate)}</p>
							</div>
						</div>
						<hr className={'hidden md:block bg-gray-200 recommendation__line'} />
						<div className='lg:w-1/5 recommendation__days px-4 lg:px-8 hidden lg:block'>
							<span className='py-1 px-2 text-xs text-white font-family-bold rounded-2xl bg-blue-900'>Días de viaje</span>
							<p className='mt-2 text-xl text-gray-600 font-family-black'>{props?.data?.days} días</p>
						</div>
						<Button
							data-testid='siguiente-button'
							onClick={e => {
								if (props.onClick) {
									props?.onClick(props?.data);
								}
							}}
							text={`
                <p class="text-xs font-family-regular text-gray-600 top-3 absolute md:static">Desde</p>
                <p class="text-xl text-2xl  lg:text-3xl font-family-bold text-gray-600 flex items-end w-40">$
                ${numberToLocaleWithcurrency(props?.data?.price || 0, props?.data?.currency)}
                <span class="text-sm hidden">${props?.data?.currency}</span></p>
                <p class="text-base font-family-bold text-blue-900">Siguiente </p>`}
							customClass={`
                w-full  md:h-28 md:w-1/5 md:rounded-r-2xl flex items-start
                flex-row md:flex-col relative justify-between md:justify-center
                pt-7 md:pt-0 rounded-b-2xl md:rounded-bl-none bg-gray-100 px-3 lg:px-6`}
						/>
					</div>
				) : (
					skeleton
				)}
			</div>
		</>
	);
}

export default Recommendation;
