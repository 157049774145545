export interface horizontalDecriptionProps {
	label?: string;
	labelContent?: JSX.Element;
	value?: string | number;
	valueContent?: JSX.Element;
	detailedDescriptionRight?: string;
	detailedDescriptionLeft?: string;
	fullFontBold?: boolean;
	noBoldLabel?: boolean;
	className?: string;
	valueClassName?: string;
	labelClassName?: string;
}

export const HorizontalDecription = (props: horizontalDecriptionProps): JSX.Element => {
	return (
		<div className={`flex justify-between items-center ${props.className || ''}`}>
			<div className={`${!props.noBoldLabel ? 'font-semibold' : ''} ${props.labelClassName || ''}`}>
				{props?.labelContent ? props.labelContent : <p>{props.label}</p>}
			</div>
			<div className={`text-right ${props.valueClassName || ''}`}>
				{props?.valueContent ? (
					props.valueContent
				) : (
					<p className={`${props.fullFontBold ? 'font-semibold' : ''}`}>
						{props.detailedDescriptionLeft && props.detailedDescriptionLeft} {props.value}
						{props.detailedDescriptionRight && props.detailedDescriptionRight}
					</p>
				)}
			</div>
		</div>
	);
};

HorizontalDecription.defaultProps = {
	className: '',
	valueClassName: '',
	labelClassName: '',
};

export default HorizontalDecription;
