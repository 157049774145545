import { SlideConfigInterface, SlideStylesInterface } from '../../../../shared/interfaces/generics/slider.interface';
import useTextSkeleton from '../../../../shared/hooks/useTextSkeleton';
import ButtonLink from '../../../ui-elements/button-link/button-link';

export interface SlideSkeletonProps {
	height?: string;
	children?: JSX.Element;
	config?: SlideConfigInterface;
	styles?: SlideStylesInterface;
}

export const SlideSkeleton = ({ height, children, config, styles }: SlideSkeletonProps): JSX.Element => {
	const descriptionComnponents = useTextSkeleton(2, 4, 20, 95, 100, styles?.descriptionClassName);
	return (
		<div className={`animate-pulse ${styles?.outClassName || ''}`}>
			<div
				className={`relative w-full bg-gray-100 ${styles?.className || ''}`}
				style={{
					height,
				}}
			>
				{config?.showContent &&
					(children || (
						<div className={`absolute bottom-0 left-0 ${styles?.contentClassName || ''}`}>
							{config?.showSubtitleLine && <div className={`h-px w-6 md:w-12 border-b-4 rounded-xl mb-2 ${styles?.subtitleLineClassName || ''}`} />}
							<p className={`font-black text-left bg-gray-200 ${styles?.subtitleClassName || ''}`} />
							<h3 className={`font-black mb-2.5 text-left bg-gray-200 ${styles?.titleClassName || ''}`} />
							{descriptionComnponents}
							{config?.showLinkButton && <ButtonLink href='' className={`cursor-default ${styles?.buttonClassName || ''}`} />}
						</div>
					))}
			</div>
			{config?.showOutContent && (
				<div className={`${styles?.outContentClassName || ''}`}>
					<h3 className={`font-black mb-2.5 text-left bg-gray-200 ${styles?.titleClassName || ''}`} />
				</div>
			)}
		</div>
	);
};

SlideSkeleton.defaultProps = {
	styles: {
		className: '',
		imageClassName: '',
		contentClassName: '',
		titleClassName: '',
		subtitleClassName: '',
		descriptionClassName: '',
		buttonClassName: '',
		subtitleLineClassName: '',
	},
};

export default SlideSkeleton;
