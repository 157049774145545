import IcomoonIcon from '../icomoon-icon/icomoon-icon';

export interface PointsButtonProps {
	userPoints: number | null;
	userCurrency: string;
	className?: string;
	clickPointsBtn: () => void;
	t: (label: string) => string;
}

function PointsButton({ className, userPoints, userCurrency, clickPointsBtn, t }: PointsButtonProps): JSX.Element {
	const buttonMessage: string = userPoints !== null && userPoints !== undefined ? `${userCurrency}: ${userPoints}` : t('consultMyPoints');
	return (
		<button
			onClick={clickPointsBtn}
			className={`${
				className || ''
			} button border border-solid border-[#1075BB] flex justify-center items-center w-[190px] h-[45px] text-[#1075BB] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] hover:shadow-xl`}
		>
			<div className='button__text flex flex-row justify-center gap-1.5 font-medium text-sm mb-0 items-center'>
				<IcomoonIcon icon='iconStar' fontSize={23} className='font-medium' />
				<div className='mt-[2px] text-[14px]'>{buttonMessage}</div>
			</div>
		</button>
	);
}

export default PointsButton;
