import { RecommendationInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/hotels/recommendation.interface';
import { TicketSubmitInterface } from '@smartlinks/react-design-system/dist/src/shared/interfaces/hotels/addons.interface';
import HotelsDisneyResumeAside from '../../../templates/hotels-disney-resume-aside/hotels-disney-resume-aside';
import { HotelsDisneyService } from '../../../shared/services/hotels-disney.service';
import { SearchWidgetInterface } from '../../../shared/interfaces/search.interface';
import useSearchWidget from '../../../shared/hooks/useSearchWidget';
import styles from 'bundle-text:./hotels-disney-resume-aside.scss';
import { CommonsUtil } from '../../../shared/utils/commons';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';

export interface HotelsDisneyResumeAsideWidgetProps extends SearchWidgetInterface<TicketSubmitInterface> {
	hotel: RecommendationInterface;
	resultAddons: (data: TicketSubmitInterface) => void;
}

const AppHotelsDisneyResumeAsidehWidget = ({
	token,
	language,
	agency,
	hotel,
	resultAddons,
	callUrlSearch,
	callShowMobileFields,
}: HotelsDisneyResumeAsideWidgetProps): JSX.Element => {
	const widgetClassName: string = 'hotelsDisneyResumeAsidehWidget';
	const {
		agencyState,
		isLoading,
		widgetStyles,
		widgetService: hotelsResumeAsideService,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<HotelsDisneyService, TicketSubmitInterface>({
		token,
		language,
		agency,
		className: widgetClassName,
		styles: styles?.toString() || '',
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: HotelsDisneyService,
	});

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
				{!isLoading && (
					<HotelsDisneyResumeAside
						hotel={hotel}
						language={language || 'es'}
						agency={agencyState}
						hotelsDisneyResumeAsideService={hotelsResumeAsideService}
						callUrlSearch={urlSearchHandler}
						resultAddons={resultAddons}
						t={t}
						callShowMobileFields={showMobileFieldsHandler}
					/>
				)}
			</div>
		</Suspense>
	);
};

export function HotelsDisneyResumeAsideWidget(props: HotelsDisneyResumeAsideWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget hotelsDisneyResumeAside'>
					<AppHotelsDisneyResumeAsidehWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
