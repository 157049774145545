import { SlideInterface } from '../../../shared/interfaces/generics/slider.interface';
import styles from './card-car-simple.module.scss';

export interface CardCarSimpleProps extends SlideInterface {
	icons: string[];
	customBlock?: string;
	customElementHover?: string;
	titleClassName?: string;
	subtitleClassName?: string;
	iconsClassName?: string;
	valueClassName?: string;
	arrowClassName?: string;
	imageClassName?: string;
}

export function CardCarSimple({
	title,
	subtitle,
	link,
	image,
	traits,
	icons,
	customBlock,
	customElementHover,
	titleClassName,
	subtitleClassName,
	iconsClassName,
	valueClassName,
	arrowClassName,
	imageClassName,
}: CardCarSimpleProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() }} />
			<div className={'cardCarSimple'}>
				<div className={`rounded-[20px] relative ${customBlock || ''} cardCarSimple__container`}>
					<img
						className={`w-full border-2 border-black rounded-[20px] object-cover overflow-hidden ${imageClassName || ''}`}
						src={image || ''}
						alt={title || ''}
					/>
					<div
						className={`w-full opacity-0 px-[35px] py-[14px] rounded-b-[20px] absolute bottom-0 flex flex-col justify-between bg-black text-white max-h-0 ${
							customElementHover || ''
						} cardCarSimple__infoContainer`}
					>
						<div className='text-[14px] flex justify-between items-center'>
							<div className='flex items-center'>
								<h2 className={`cardCarSimple__title font-bold ${titleClassName || ''}`}>{title}&nbsp;</h2>
								<p className={`cardCarSimple__subtitle ${subtitleClassName || ''}`}>{`· ${subtitle || ''}`}</p>
							</div>
							<a
								href={link || ''}
								target='_blank'
								className={`cardCarSimple__arrow iconIconArrowRightSelect text-[12px] leading-none ${arrowClassName || ''}`}
								rel='noreferrer'
							></a>
						</div>
						<div className='w-[130px] text-[12px] flex justify-between'>
							<div className='flex items-center'>
								<span className={`cardCarSimple__icon ${icons[0]} mr-[6px] ${iconsClassName || ''}`}></span>
								<p className={`cardCarSimple__value ${valueClassName || ''}`}>{traits?.passengers || 0}</p>
							</div>
							<div className='flex items-center'>
								<span className={`cardCarSimple__icon ${icons[1]} mr-[6px] ${iconsClassName || ''}`}></span>
								<p className={`cardCarSimple__value ${valueClassName || ''}`}>{traits?.keys || 0}</p>
							</div>
							<div className='flex items-center'>
								<span className={`cardCarSimple__icon ${icons[2]} mr-[6px] ${iconsClassName || ''}`}></span>
								<p className={`cardCarSimple__value ${valueClassName || ''}`}>{traits?.equipment || 0}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
