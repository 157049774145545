import { InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { InputType } from '../../../shared/enums';
import Input from '../../ui-elements/input/input';

export interface PromoCodeInputProps {
	promoCode?: string;
	promoCodeHandler?: (event: InputEventsHandler) => void;
	colorSecondary?: string;
	isTertiaryTemplate?: boolean;
	traductions: (label: string) => string;
}

export function PromoCodeInput({ promoCode, promoCodeHandler, colorSecondary, isTertiaryTemplate, traductions }: PromoCodeInputProps): JSX.Element {
	return (
		<Input
			value={promoCode}
			onChange={promoCodeHandler}
			type={InputType.text}
			label={isTertiaryTemplate ? '' : `${traductions('promoCode') || 'promoCode'}`}
			placeholder={traductions('promoCode') || 'promoCode'}
			defaultValue={''}
			name={'promoCode'}
			className={`${isTertiaryTemplate ? 'gap-2 py-2.5 px-3.5 border-[#BEBEBE] text-[#BEBEBE] border border-solid rounded-none'
				: 'w-full bg-white rounded-[10px] border border-solid py-[10px] px-[25px] flex-col justify-center'} `}
			labelClassName={'block text-xs'}
			inputClassName={
				`${isTertiaryTemplate ? 'text-[#4D4D4F] text-base !text-[14px] !p-0 md:placeholder placeholder:text-sm placeholder:text-[#9E9E9E]' : 'text-base' }  block w-full bg-white`
			}
			isTertiaryTemplate={isTertiaryTemplate}
			style={{
				color: isTertiaryTemplate ? undefined : colorSecondary,
			}}
		/>
	);
}

export default PromoCodeInput;
