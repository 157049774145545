import { TransmissionTypeV2, RentalCarsLogosV2, CarsRatesModelsV2, RentalCarsNamesV2 } from '../../../shared/enums/filter-cars-cards-v2.enum';
import { numberToLocaleWithcurrency, capitalize } from '../../../../../shared/services/utils.service';
import { coverageTypeCamel } from '../../../shared/utils/car-coverage-types.util';
import { CarBaggage } from '../../../shared/interfaces/short-car-recommendation';
import PcoMessage from '../../../../shared-components/pco-message/pco-message';
import { PcoPrice } from '../../../../shared-components/pco-price/pco-price';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import CoverageCarCardSkeleton from './skeleton/skeleton-coverage-car-card';
import { ModelTypeEnum } from '../../../../../shared/enums/model-type.enum';
import {
	CarInterfaceV2,
	RentalCarInterfaceV2,
	GroupedCoveragesInterfaceV2,
	CoveragesInterfaceV2,
} from '../../../shared/interfaces/car-recommendation-v2.interface';
import { ImageUtil } from '../../../../../shared/utils/image.util';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import ToolTip from '../../../../ui-elements/tool-tip/tool-tip';
import Spinner from '../../../../ui-elements/spinner/spinner';
import Button from '../../../../ui-elements/button/button';
import { useCallback, useEffect, useState } from 'react';
import Image from '../../../../ui-elements/image/image';
import Pill from '../../../../ui-elements/pill/pill';
import styles from './coverage-car-card.module.scss';

export interface CoverageCarCardProps {
	carInfo?: CarInterfaceV2;
	dailyRateText: string;
	discountText?: string;
	rateText?: string;
	coverageButtonText?: string;
	includeAirConditionText: string;
	includeLargeAirConditionText?: string;
	passengersText?: string;
	doorsText?: string;
	baggageText?: string;
	manualTransmissionText?: string;
	automaticTransmissionText?: string;
	colorPrimary?: string;
	rateModel?: CarsRatesModelsV2 | string;
	showRate?: boolean;
	showButtons?: boolean;
	showDiscount?: boolean;
	forceMobileDisplay?: boolean;
	multipleCompanies?: boolean;
	className?: string;
	companyImageClassName?: string;
	carImageClassName?: string;
	carContainerClassName?: string;
	textureImage?: string;
	filterCompany?: string | null;
	disabled?: boolean;
	loading?: boolean;
	style?: React.CSSProperties;
	isGrid?: boolean;
	isWithUser?: boolean;
	isShowPcoPrice?: boolean;
	currencySymbol?: string;
	pointsAccumulation?: number;
	informationMessage?: string;
	culture?: string;
	onClick?: (data: CarInterfaceV2, company: RentalCarInterfaceV2, coverage: GroupedCoveragesInterfaceV2) => void;
	onCardClick?: (data: CarInterfaceV2) => void;
	onShareClick?: (data: CarInterfaceV2) => void;
	t?: (item: string) => string;
	isActiveMultiLanguage?: boolean;
}

export const CoverageCarCard = ({
	carInfo,
	dailyRateText,
	discountText,
	includeAirConditionText,
	includeLargeAirConditionText,
	passengersText,
	doorsText,
	baggageText,
	manualTransmissionText,
	automaticTransmissionText,
	colorPrimary,
	rateModel,
	showRate,
	showButtons,
	className,
	companyImageClassName,
	carImageClassName,
	multipleCompanies,
	rateText,
	coverageButtonText,
	carContainerClassName,
	showDiscount,
	textureImage,
	filterCompany,
	disabled,
	loading,
	forceMobileDisplay,
	style,
	isWithUser,
	isShowPcoPrice,
	currencySymbol,
	culture,
	onClick,
	onShareClick,
	onCardClick,
	isGrid,
	t,
	pointsAccumulation,
	informationMessage,
	isActiveMultiLanguage,
}: CoverageCarCardProps): JSX.Element => {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const [coverages, setCoverages] = useState<GroupedCoveragesInterfaceV2[]>([]);
	const [totalRate, setTotalRate] = useState<number>(0);
	const [currency, setCurrency] = useState<string>('');
	const [pointsCurrency, setPointsCurrency] = useState<string>('');
	const [dailyRate, setDailyRate] = useState<number>(0);
	const [carBaggageCount, setCarBaggageCount] = useState<number>(0);
	const [activeCompany, setActiveCompany] = useState<string>('');
	const [activeCoverage, setActiveCoverage] = useState<string>('');
	const [clickLoading, setClickLoading] = useState<boolean>(false);
	const [carData, setCarData] = useState<CarInterfaceV2>();
	const isMobile: boolean = windowWidth < responsiveSize;
	let tempTotalRate: number = 0;
	const imageUtil: ImageUtil = new ImageUtil();
	const pointsAccumulationString: string = pointsAccumulation ? pointsAccumulation.toString() : '';
	const buttonsHandler = useCallback((data: CarInterfaceV2, company: RentalCarInterfaceV2): void => {
		if (data && company) {
			setActiveCompany((prevState: string): string => (company.rentalCarName !== prevState ? company.rentalCarName : ''));

			const tempCompany: RentalCarInterfaceV2 | undefined = data?.rateByRentalCar.find(
				(rentalCar: RentalCarInterfaceV2): boolean => rentalCar.rentalCarCode === company.rentalCarCode,
			);

			setCoverages(tempCompany && tempCompany.rateByCoverage.length ? [...tempCompany.rateByCoverage] : []);
		} else {
			setActiveCompany('');

			setCoverages([]);
		}
	}, []);

	const detailClickHandler = useCallback(
		(data: CarInterfaceV2, company: string, coverage: GroupedCoveragesInterfaceV2): void => {
			if (onClick) {
				const tempCompany: RentalCarInterfaceV2 | undefined = data?.rateByRentalCar.find(
					(rentalCar: RentalCarInterfaceV2): boolean => rentalCar.rentalCarName === company,
				);

				onClick(data, tempCompany ? { ...tempCompany } : ({} as unknown as RentalCarInterfaceV2), coverage);

				setClickLoading(true);

				setActiveCoverage(coverage.rateCodeType);
			}
		},
		[onClick],
	);

	const cardHandler = useCallback(
		(data: CarInterfaceV2): void => {
			if (onCardClick) {
				onCardClick(data);
			}
		},
		[onCardClick],
	);

	const setCarResultAndMapData = async (data: CarInterfaceV2): Promise<void> => {
		if (data?.car?.imageUrl) {
			const urlFormated: string = await imageUtil.optimizeHertzImages(data.car.imageUrl);
			data.car.imageUrlFormmated = urlFormated;
		}

		setCarData(data);
	};

	useEffect(() => {
		if (!loading) {
			let tempCurrency: string = '';
			let tempPointsCurrency: string = '';
			let tempDailyRate: number = 0;
			const rateModelPoints: CarsRatesModelsV2 = carInfo?.isAvailabilityInLocalCurrency ? CarsRatesModelsV2.equivalentRate : CarsRatesModelsV2.rate;
			const businessModel: ModelTypeEnum | string = carInfo?.businessModel || '';
			switch (businessModel) {
				case ModelTypeEnum.CASH:
					if (carInfo && rateModel && rateModel in carInfo) {
						tempTotalRate = carInfo[rateModel].rateFrom || 0;

						tempCurrency = carInfo[rateModel].currency || '';

						tempDailyRate = carInfo[rateModel].dailyRate || 0;
					}

					break;
				case ModelTypeEnum.POINTS:
					tempTotalRate = carInfo?.rate?.pointsFrom || 0;

					tempPointsCurrency = carInfo?.rate?.pointsCurrency || '';

					tempCurrency = '';

					tempDailyRate = 0;

					break;
				case ModelTypeEnum.POINT_OR_MONEY:
				case ModelTypeEnum.POINT_PLUS_MONEY:
					if (carInfo && rateModelPoints && rateModelPoints in carInfo) {
						tempTotalRate = carInfo[rateModelPoints]?.pointsFrom || 0;

						tempPointsCurrency = carInfo[rateModelPoints]?.pointsCurrency || '';

						tempCurrency = carInfo[rateModelPoints]?.currency || '';

						tempDailyRate = carInfo[rateModelPoints]?.rateFrom || 0;
					}

					break;
			}
			if (carInfo?.car.baggage?.length) {
				setCarBaggageCount(carInfo.car.baggage.reduce((count: number, baggae: CarBaggage): number => count + Number(baggae.quantity), 0));
			}

			setTotalRate(tempTotalRate);

			setCurrency(tempCurrency);

			setDailyRate(tempDailyRate);

			setPointsCurrency(tempPointsCurrency);
		}
	}, [rateModel, loading, carInfo]);

	useEffect(() => {
		if (!disabled) {
			setClickLoading(false);

			setActiveCoverage('');
		}
	}, [disabled]);

	useEffect(() => {
		if (carInfo) {
			void setCarResultAndMapData(carInfo);
		}
	}, [carInfo]);

	useEffect(() => {
		if (filterCompany) {
			setActiveCompany((prevState: string): string => (filterCompany !== prevState ? '' : prevState));
		}
	}, [filterCompany]);

	useEffect(() => {
		const root = document.documentElement;
		Object.entries(RentalCarsLogosV2).forEach(([name, rentalCar]): void => {
			root?.style.setProperty(`--coverage-car-card-${name}-primary-color`, rentalCar.primaryColor);

			root?.style.setProperty(`--coverage-car-card-${name}-secondary-color`, rentalCar.secondaryColor);
		});
	}, []);

	const maxCoveragesRow: number = 4;
	const maxCoveragesPadding: number = 2;
	const maxWidthCoverageCard: number = 100;
	const diffSpacesCards: number = 1;
	const widthSpaceCoverageCard: number = 1;
	const isMaxCoveragesRow: boolean = coverages.length > maxCoveragesRow;
	const extraPaddingCoverageCard: boolean = coverages.length <= maxCoveragesPadding;
	const lengthRow: number = isMaxCoveragesRow ? maxCoveragesRow : coverages.length;
	const widthCoverageCard: number = (maxWidthCoverageCard - (lengthRow - diffSpacesCards) * widthSpaceCoverageCard) / lengthRow;
	const firstElement: number = 0;
	return loading ? (
		<CoverageCarCardSkeleton
			multipleCompanies={multipleCompanies}
			showRate={showRate}
			showButtons={showButtons}
			className={className}
			carContainerClassName={carContainerClassName}
			carImageClassName={'h-36 w-60'}
			companyImageClassName={companyImageClassName}
		/>
	) : carData ? (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div
				className={`coverageCarCard ${onCardClick && !disabled ? 'cursor-pointer' : ''} ${className || ''}`}
				style={style}
				onClick={disabled ? undefined : onCardClick ? () => cardHandler(carData) : undefined}
			>
				<div
					className={`${carData?.car?.category || ''}  coverageCarCard__companyContainer w-full relative flex flex-col ${
						forceMobileDisplay ? 'px-2.5' : 'px-10'
					} ${forceMobileDisplay ? 'py-1' : activeCompany ? 'pt-5' : 'py-5'} ${carContainerClassName || ''}`}
				>
					<div
						className={`coverageCarCard__companyContainer flex flex-row text-[18px] ${
							discountText ? (forceMobileDisplay ? 'justify-between' : 'justify-between md:justify-end') : 'justify-end'
						} items-center mb-2.5`}
					>
						{discountText && showDiscount && (
							<Pill
								text={discountText}
								color='black'
								className={`justify-self-start ${
									forceMobileDisplay ? '' : 'md:absolute md:top-4 md:left-6'
								} text-xs font-bold text-center text-white`}
							/>
						)}
						{!multipleCompanies ? (
							carData?.rateByRentalCar &&
							carData.rateByRentalCar.length &&
							carData.rateByRentalCar[firstElement] &&
							carData.rateByRentalCar[firstElement].rentalCarName &&
							carData.rateByRentalCar[firstElement].rentalCarName.toLocaleLowerCase() in RentalCarsLogosV2 ? (
									activeCompany === carData.rateByRentalCar[firstElement].rentalCarName ? (
										RentalCarsLogosV2[carData.rateByRentalCar[firstElement].rentalCarName.toLocaleLowerCase()].filled
									) : (
										RentalCarsLogosV2[carData.rateByRentalCar[firstElement].rentalCarName.toLocaleLowerCase()].color
									)
								) : (
									<Image url={carData.rateByRentalCar[firstElement].rentalCarImageUrl || ''} customClass={`${companyImageClassName || ''}`} />
								)
						) : null}
					</div>
					{!multipleCompanies && (
						<button
							className={`inline-block md:hidden self-end ${showRate ? 'mb-2.5' : ''}`}
							onClick={() => (onShareClick ? onShareClick(carData) : null)}
						>
							<IcomoonIcon icon='shareIco' fontSize={15} />
						</button>
					)}

					{informationMessage && (
						<ToolTip
							title={'<span class="coverageCarCard__infoIcon icon infoCirlce2Ico"></span>'}
							colorPrimary={'black'}
							body={informationMessage}
							customClass={'coverageCarCard__infoIconToolTip'}
						/>
					)}

					<div className={`flex flex-col ${forceMobileDisplay ? 'items-center' : 'md:flex-row'}`}>
						<Image
							className={`w-[100%] mx-[auto] overflow-hidden ${carData.rateByRentalCar[firstElement].rentalCarCode}`}
							url={carData.car.imageUrlFormmated || ''}
							customClass={`${forceMobileDisplay ? '' : 'md:mr-3.5'} ${carImageClassName || ''}`}
						/>
						<div
							className={`coverageCarCard__infoContainer relative flex-grow mt-[5px] md:pl-[5px] flex flex-col 2xl:pt-[10px] ${
								forceMobileDisplay ? '' : 'md:mt-0'
							}`}
						>
							<div className={'flex flex-row items-end justify-between'}>
								<p
									className={`coverageCarCard__typeText font-bold text-black ${multipleCompanies ? 'text-xs' : 'text-base'} ${
										forceMobileDisplay ? 'text-center w-full' : 'text-left md:text-xl'
									}`}
								>
									{capitalize(carData.car.vehicleTypeLabel)}
								</p>
								{showRate && multipleCompanies && !isShowPcoPrice && (
									<p
										className={`coverageCarCard__rateText text-right font-medium text-black text-xs hidden leading-[10px] ${
											forceMobileDisplay ? '' : 'md:block'
										}`}
									>
										{rateText}
									</p>
								)}
							</div>
							<div className={`flex flex-col ${forceMobileDisplay ? '' : 'md:flex-row'}`}>
								<div className='flex-grow flex flex-col'>
									<p
										className={`coverageCarCard__modelText font-regular text-base text-gray-500 ${
											forceMobileDisplay ? 'text-center w-full' : 'text-left md:text-xl'
										}`}
									>
										{capitalize(carData.car.model)}
									</p>
									<div
										className={`flex-grow flex flex-row flex-wrap justify-start items-start ${
											showRate ? (forceMobileDisplay ? '' : 'mt-1.5') : 'mt-6'
										}`}
									>
										{!!carData.car.passengerQuantity && (
											<div
												className={`coverageCarCard__featureContainer flex align-center ${
													showRate ? `${forceMobileDisplay ? 'mr-2' : 'mr-4 md:mr-4'}` : 'w-2/4'
												}`}
											>
												<ToolTip
													title={`<span class="coverageCarCard__featureText icon passenger2Ico text-base font-medium uppercase mr-1" style="color: black"></span><span class="coverageCarCard__featureText text-xs font-medium text-color-jet">${
														carData.car.passengerQuantity
													}${showRate ? '' : ` ${passengersText || ''}`}</span>`}
													colorPrimary={'black'}
													body={`
                            <p class="coverageCarCard__featureToolTipText text-xs text-white whitespace-nowrap">
                              ${passengersText || ''}: ${carData.car.passengerQuantity}
                            </p>
                            `}
													toolTipCustomClass={'coverageCarCard__toolTip'}
												/>
											</div>
										)}
										{!!carData.car.doors && (
											<div
												className={`coverageCarCard__featureContainer flex align-center ${
													showRate ? `${forceMobileDisplay ? 'mr-2' : 'mr-4 md:mr-4'}` : 'w-2/4'
												}`}
											>
												<ToolTip
													title={`<span class="coverageCarCard__featureText icon door2Ico text-base font-medium uppercase mr-1" style="color: black"></span><span class="coverageCarCard__featureText text-xs font-medium text-color-jet">${
														carData.car.doors
													}${showRate ? '' : ` ${doorsText || ''}`}</span>`}
													colorPrimary={'black'}
													body={`
                            <p class="coverageCarCard__featureToolTipText text-xs text-white whitespace-nowrap">
                              ${doorsText || ''}: ${carData.car.doors}
                            </p>
                            `}
													toolTipCustomClass={'coverageCarCard__toolTip'}
												/>
											</div>
										)}
										{!!carData.car.baggage?.length && (
											<div
												className={`coverageCarCard__featureContainer flex align-center ${
													showRate ? `${forceMobileDisplay ? 'mr-2' : 'mr-4 md:mr-4'}` : 'w-2/4'
												}`}
											>
												<ToolTip
													title={`<span class="coverageCarCard__featureText icon baggage2Ico text-base font-medium uppercase mr-1" style="color: black"></span> <span class="coverageCarCard__featureText text-xs font-medium text-color-jet">${carBaggageCount}${
														showRate ? '' : ` ${baggageText || ''}`
													}</div>`}
													colorPrimary={'black'}
													body={`
                            <p class="coverageCarCard__featureToolTipText text-xs text-white whitespace-nowrap">
                              ${baggageText || ''}: ${carBaggageCount}
                            </p>
                            `}
													toolTipCustomClass={'coverageCarCard__toolTip'}
												/>
											</div>
										)}
										{carData.car.airConditioning && showRate && (
											<div
												className={`coverageCarCard__featureContainer flex align-center ${
													showRate ? `${forceMobileDisplay ? 'mr-2' : 'mr-4 md:mr-4'}` : 'w-2/4'
												}`}
											>
												<ToolTip
													title={`<span class="coverageCarCard__featureText icon airCondition2Ico text-base font-medium uppercase mr-1" style="color: black"></span><span class="coverageCarCard__featureText text-xs font-medium text-color-jet">${
														showRate ? includeAirConditionText || '' : includeLargeAirConditionText || ''
													}</span>`}
													colorPrimary={'black'}
													body={`
                            <p class="coverageCarCard__featureToolTipText text-xs text-white whitespace-nowrap">
                              ${includeLargeAirConditionText || ''}
                            </p>
                            `}
													toolTipCustomClass={'coverageCarCard__toolTip'}
												/>
											</div>
										)}
										<div className={`coverageCarCard__featureContainer flex align-center ${showRate ? '' : 'w-2/4'}`}>
											<ToolTip
												title={`<span class="coverageCarCard__featureText icon transmission2Ico text-base font-medium uppercase mr-1" style="color: black"></span><span class="coverageCarCard__featureText text-xs font-medium text-color-jet">
                          ${
		carData.car.transmmisionType === TransmissionTypeV2.manual
			? showRate
				? 'M'
				: manualTransmissionText || ''
			: showRate
				? 'A'
				: automaticTransmissionText || ''
		}</span>`}
												colorPrimary={'black'}
												body={`
                          <p class="coverageCarCard__featureToolTipText text-xs text-white whitespace-nowrap">
                            ${
		carData.car.transmmisionType === TransmissionTypeV2.manual
			? manualTransmissionText || ''
			: automaticTransmissionText || ''
		}
                          </p>
                          `}
												toolTipCustomClass={'coverageCarCard__toolTip'}
											/>
										</div>
										{carData.car.airConditioning && !showRate && (
											<div
												className={`coverageCarCard__featureContainer coverageCarCard__ACFeatureContainer flex align-center w-full ${
													forceMobileDisplay ? 'mr-2 md:w-2/4' : ''
												}`}
											>
												<ToolTip
													title={`<span class="coverageCarCard__featureText icon airCondition2Ico text-base font-medium uppercase mr-1" style="color: black"></span><span class="coverageCarCard__featureText text-xs font-medium text-color-jet">${
														showRate ? includeAirConditionText || '' : includeLargeAirConditionText || ''
													}</span>`}
													colorPrimary={'black'}
													body={`
                            <p class="coverageCarCard__featureToolTipText text-xs text-white whitespace-nowrap">
                              ${includeLargeAirConditionText || ''}
                            </p>
                            `}
													toolTipCustomClass={'coverageCarCard__toolTip'}
												/>
											</div>
										)}
									</div>
								</div>
								<div className={`flex flex-col ${forceMobileDisplay ? 'mt-[5px]' : 'mt-4 md:items-end md:mt-0'} ${isShowPcoPrice ? 'my-4' : ''}`}>
									{!multipleCompanies && (
										<button
											className={`hidden md:inline-block ${showRate ? 'mb-2.5' : ''}`}
											onClick={() => (onShareClick ? onShareClick(carData) : null)}
										>
											<IcomoonIcon icon='shareIco' fontSize={15} />
										</button>
									)}
									{carData.hasDropOffDetail && isGrid && (
										<div className='coverageCarCard__dropOffContainer flex font-bold text-[8px] mb-2'>
											<IcomoonIcon icon='pinIco' />
											<p className='coverageCarCard__dropOffTitle mx-2'>{(t && t('dropOffInfo')) || 'dropOffInfo'}</p>
										</div>
									)}
									{showRate && (
										<>
											{(!multipleCompanies || windowWidth < responsiveSize || forceMobileDisplay) && !isShowPcoPrice && (
												<p
													className={`coverageCarCard__rateText font-medium text-black text-xs text-center leading-[10px] ${
														forceMobileDisplay ? '' : 'md:text-right'
													}`}
												>
													{rateText}
												</p>
											)}
											{carData?.businessModel === ModelTypeEnum.CASH && (
												<>
													<p
														className={`coverageCarCard__${
															carData?.businessModel
														} coverageCarCard__fromTotalRateText text-center font-black text-2xl ml-0 ${
															forceMobileDisplay ? '' : 'md:text-right md:ml-3.5'
														}`}
														style={{ color: colorPrimary || 'rgb(21, 19, 134)' }}
													>
														{numberToLocaleWithcurrency(totalRate, currency)}
														<span
															className={`coverageCarCard__${carData?.businessModel} coverageCarCard__currencyRateText text-2xl ${
																forceMobileDisplay ? '' : 'md:text-xs'
															}`}
														>
															{currency}
														</span>
													</p>
													<p
														className={`coverageCarCard__${
															carData?.businessModel
														} coverageCarCard__fromDailyRateText flex flex-row items-center justify-center text-center font-normal text-black text-xs ${
															forceMobileDisplay ? '' : 'md:justify-end md:text-right md:text-sm'
														}`}
													>
														{showDiscount && (
															<span className='text-xs line-through text-gray-500 mr-1.5'>
																{numberToLocaleWithcurrency(dailyRate || 0, currency)}
															</span>
														)}
														{numberToLocaleWithcurrency(dailyRate || 0, currency)}/{dailyRateText}
													</p>
													{pointsAccumulationString && t && (
														<PcoMessage
															points={pointsAccumulationString}
															t={t}
															titleClassName={isGrid ? 'text-center' : 'md:items-end whitespace-nowrap'}
															isGridCard={isGrid}
														/>
													)}
												</>
											)}
											{carInfo?.businessModel === ModelTypeEnum.POINTS && (
												<>
													<p
														className={`coverageCarCard__${
															carInfo?.businessModel
														} coverageCarCard__fromTotalRateText text-center font-black text-2xl ml-0 ${
															forceMobileDisplay ? '' : 'md:text-right md:ml-3.5'
														}`}
														style={{ color: colorPrimary || 'rgb(21, 19, 134)' }}
													>
														{numberToLocaleWithcurrency(totalRate, currency)}{' '}
														<span
															className={`coverageCarCard__${carInfo?.businessModel} coverageCarCard__currencyRateText text-2xl ${
																forceMobileDisplay ? '' : 'md:text-xs'
															}`}
														>
															{pointsCurrency}
														</span>
													</p>
												</>
											)}

											{(carInfo?.businessModel === ModelTypeEnum.POINT_OR_MONEY || carInfo?.businessModel === ModelTypeEnum.POINT_PLUS_MONEY) && (
												<>
													{isShowPcoPrice ? (
														<PcoPrice
															points={carInfo.equivalentRate?.chargedPoints || 0}
															pointsCurrency={carInfo.equivalentRate?.pointsCurrency}
															sectionClassName='flex flex-column gap-4'
															isWithUser={!!isWithUser}
															showTaxesLabel={false}
															money={carInfo.equivalentRate?.chargedAmount || 0}
															moneyCurrency={carInfo.equivalentRate?.currency}
															totalAccumulation={carInfo.totalAccumulationProductPoint || 0}
															totalRedemptionPoints={carInfo.equivalentRate?.netTotalPoints || 0}
															fromLabel={(t && t('from')) || 'from'}
															taxesLabel={(t && t('taxesIncluded')) || 'taxesIncluded'}
															accumulationLabel={(t && t('youAccumulateUpTo')) || 'youAccumulateUpTo'}
															redemptionLabel={(t && t('orRedeemWith')) || 'orRedeemWith'}
															currencySymbol={currencySymbol || ''}
															primaryColor={colorPrimary || 'rgb(21, 19, 134)'}
															culture={culture || ''}
														/>
													) : (
														<>
															<p
																className={`coverageCarCard__${
																	carData?.businessModel
																} coverageCarCard__fromTotalRateText text-center font-black text-2xl ml-0 ${
																	forceMobileDisplay ? '' : 'md:text-right md:ml-3.5'
																}`}
																style={{ color: colorPrimary || 'rgb(21, 19, 134)' }}
															>
																{totalRate ? `${numberToLocaleWithcurrency(totalRate)} ` : `${numberToLocaleWithcurrency(dailyRate, currency)} `}
																<span
																	className={`coverageCarCard__${carData?.businessModel} coverageCarCard__currencyRateText text-2xl ${
																		forceMobileDisplay ? '' : 'md:text-xs'
																	}`}
																>
																	{totalRate ? pointsCurrency : currency}
																</span>
															</p>
															{!!totalRate && !!dailyRate && (
																<p
																	className={`coverageCarCard__${
																		carData?.businessModel
																	} coverageCarCard__fromDailyRateText flex flex-row items-center justify-center text-center font-normal text-black text-xs ${
																		forceMobileDisplay ? '' : 'md:justify-end md:text-right md:text-sm'
																	}`}
																>
																	{showDiscount && (
																		<span className='text-xs line-through text-gray-500 mr-1.5'>
																			{numberToLocaleWithcurrency(dailyRate, currency)} {currency}
																		</span>
																	)}
																	+ {currency} {numberToLocaleWithcurrency(dailyRate, currency)}
																</p>
															)}
														</>
													)}
												</>
											)}
										</>
									)}
								</div>
							</div>
							{carData.hasDropOffDetail && !isGrid && (
								<div className='coverageCarCard__dropOffContainer flex font-bold text-sm'>
									<IcomoonIcon icon='pinIco' />
									<p className='coverageCarCard__dropOffTitle mx-2'>{(t && t('dropOffInfo')) || 'dropOffInfo'}</p>
								</div>
							)}
							{showButtons && multipleCompanies && carData.rateByRentalCar.length && (
								<div
									className={`coverageCarCard__buttonsContainer relative -bottom-px flex flex-col mt-4 md:justify-end md:items-end flex-grow ${
										forceMobileDisplay ? '' : 'md:mt-6 2xl:mt-0'
									}`}
								>
									<div
										className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-center items-start ${forceMobileDisplay ? '' : 'md:justify-end'}`}
									>
										{carData.rateByRentalCar.map((company: RentalCarInterfaceV2, index: number) => {
											let showButton: boolean = true;
											if (filterCompany && filterCompany !== company.rentalCarName) {
												showButton = false;
											}

											return (
												<Button
													key={company.rentalCarCode}
													onClick={() => buttonsHandler(carData, company)}
													content={
														company.rentalCarName && company.rentalCarName.toLocaleLowerCase() in RentalCarsLogosV2 ? (
															activeCompany === company.rentalCarName ? (
																RentalCarsLogosV2[company.rentalCarName.toLocaleLowerCase()].filled
															) : (
																<>
																	<span
																		className='coverageCarCard__buttonFilledIco'
																		style={{
																			color: RentalCarsLogosV2[company.rentalCarName.toLocaleLowerCase()].secondaryColor,
																		}}
																	>
																		{RentalCarsLogosV2[company.rentalCarName.toLocaleLowerCase()].filled}
																	</span>
																	<span className='coverageCarCard__buttonColorIco'>
																		{RentalCarsLogosV2[company.rentalCarName.toLocaleLowerCase()].color}
																	</span>
																</>
															)
														) : (
															<Image url={company.rentalCarImageUrl || ''} customClass={`${companyImageClassName || ''}`} />
														)
													}
													customClass={`${
														activeCompany === company.rentalCarName
															? 'coverageCarCard__activeButton rounded-t-[20px] rounded-b-[20px] md:rounded-b-[0] border-[2px] border-black pt-1 pb-2 md:min-h-[50px]'
															: company.rentalCarName === RentalCarsNamesV2.fireFly
																? `coverageCarCard__${company.rentalCarName.toLocaleLowerCase()}Button rounded-[20px] border-[2px] border-black py-1 min-h-[30px]`
																: `coverageCarCard__button coverageCarCard__${company.rentalCarName.toLocaleLowerCase()}Button rounded-[20px] border-[2px] border-black py-1 min-h-[30px]`
													} 	text-[15px] md:min-w-[85px] px-5 mb-1.5 md:mb-0 ${
														index < carData.rateByRentalCar.length - 1 && !isMobile ? 'mr-[5px] md:mr-2.5' : 'mr-0'
													} ${showButton ? '' : 'hidden'}`}
													noHover={true}
													styles={{
														backgroundColor: activeCompany === company.rentalCarName ? 'black' : 'white',
														color: activeCompany === company.rentalCarName ? 'white' : 'black',
													}}
													disabled={disabled}
												/>
											);
										})}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				{activeCompany && (
					<>
						<div className='coverageCarCard__line w-full border-[#DDDDDD] md:border-black border-b-[1px] md:border-b-2 mt-6 md:mt-0' />
						<div
							className={`w-full px-[30px] md:px-12 py-5 flex flex-col md:flex-row ${
								isMaxCoveragesRow ? 'md:flex-wrap justify-start' : 'justify-between'
							}`}
						>
							{coverages.map((coverage: GroupedCoveragesInterfaceV2, index: number) => {
								let tempCurrency: string = '';
								let tempDailyRate: number = 0;
								let tempPointsCurrency: string = '';
								const rateModelPoints: CarsRatesModelsV2 = carInfo?.isAvailabilityInLocalCurrency
									? CarsRatesModelsV2.equivalentRate
									: CarsRatesModelsV2.rate;

								const businessModel: ModelTypeEnum | string = carInfo?.businessModel || '';
								const applyAccumulationProductPoint: boolean = !!coverage.totalAccumulationProductPoint;
								switch (businessModel) {
									case ModelTypeEnum.CASH:
										if (coverage && rateModel && rateModel in coverage) {
											tempTotalRate = coverage[rateModel].rateFrom || 0;

											tempCurrency = coverage[rateModel].currency || '';

											tempDailyRate = coverage[rateModel].dailyRate || 0;
										}

										break;
									case ModelTypeEnum.POINT_OR_MONEY:
									case ModelTypeEnum.POINT_PLUS_MONEY:
										if (coverage && rateModelPoints && rateModelPoints in coverage) {
											tempTotalRate = coverage[rateModelPoints]?.pointsFrom || 0;

											tempPointsCurrency = coverage[rateModelPoints]?.pointsCurrency || '';

											tempCurrency = coverage[rateModelPoints]?.currency || '';

											tempDailyRate = coverage[rateModelPoints]?.rateFrom || 0;
										}

										break;
									case ModelTypeEnum.POINTS:
										tempTotalRate = coverage?.rate?.pointsFrom || 0;

										tempPointsCurrency = coverage?.rate?.pointsCurrency || '';

										tempCurrency = '';

										tempDailyRate = 0;

										break;
								}
								const extraCountPosition: number = 1;
								const isMultiple: number = 0;
								const noLastItemRow: boolean = (index + extraCountPosition) % maxCoveragesRow !== isMultiple;
								const isOtherRow: boolean = index + extraCountPosition > maxCoveragesRow;
								const titleComponent: JSX.Element = (
									<span>{isActiveMultiLanguage && t ? t(coverageTypeCamel(coverage?.rateCodeType)) : coverage.rateTypeLabel || ''}</span>
								);

								return (
									<div
										key={`${coverage.rateCodeType}-${activeCompany}-${index}`}
										className={`coverageCarCard__coverageCard rounded-[10px] px-2.5 py-5 flex flex-col mb-2.5 md:mb-0 ${
											isOtherRow ? 'md:mt-2.5' : ''
										} ${coverage?.isRecommended ? 'coverageCarCard__recommendedCoverageCard' : ''}`}
										style={{
											width: windowWidth < responsiveSize ? undefined : `${widthCoverageCard}%`,
											marginRight: windowWidth >= responsiveSize && isMaxCoveragesRow && noLastItemRow ? `${widthSpaceCoverageCard}%` : undefined,
											backgroundImage: coverage?.isRecommended && textureImage ? `url(${textureImage})` : undefined,
											backgroundColor: coverage?.isRecommended ? colorPrimary : 'black',
											color: coverage?.isRecommended ? 'black' : 'white',
										}}
									>
										<div className={`flex flex-col flex-grow px-[20px] ${extraPaddingCoverageCard ? 'md:px-8' : 'md:px-2.5'}`}>
											<div className='relative mb-[5px]'>
												<p className='coverageCarCard__coverageTitleText text-left font-bold text-xs'>{titleComponent}</p>
											</div>
											{coverage?.isRecommended && (
												<div className='bg-[#FFF] rounded-full text-[#000] font-semibold text-[0.625rem] py-1 px-2 mb-[5px] md:mb-3.5 w-max'>
													<span>{t?.('recommended') ?? 'recommended'}</span>
												</div>
											)}
											<ul className='list-none flex-grow'>
												{coverage.coverages.map((item: CoveragesInterfaceV2, index: number) => {
													return (
														<li key={index} className='coverageCarCard__coverageItemText text-left font-normal text-[10px] flex flex-row items-start'>
															<span
																className={`${
																	item.included ? 'checkRoundIco' : 'unCheckRoundIco'
																} mr-1 mt-[2px] text-[8px] flex flex-row justify-center items-center`}
																style={{
																	color: coverage?.isRecommended ? 'black' : 'white',
																}}
															/>
															<p>{t ? t(item.tag || item.text) : ''}</p>
														</li>
													);
												})}
											</ul>
										</div>
										{carData?.businessModel === ModelTypeEnum.CASH && (
											<>
												<p
													className={`coverageCarCard__${carData?.businessModel} coverageCarCard__coverageTotalRateText text-center font-bold text-xs mt-4 md:mt-2.5`}
												>
													{numberToLocaleWithcurrency(tempTotalRate, tempCurrency)} {tempCurrency}
												</p>
												<p
													className={`coverageCarCard__${carData?.businessModel} coverageCarCard__coverageDailyRateText text-center font-normal text-[10px]`}
												>
													{numberToLocaleWithcurrency(tempDailyRate || 0, tempCurrency)}/{dailyRateText}
												</p>
												{applyAccumulationProductPoint && coverage.totalAccumulationProductPoint && t && (
													<PcoMessage
														points={coverage.totalAccumulationProductPoint.toString()}
														tooltipTitleColor='#ffffff'
														titleClassName='px-[10px] text-center text-white w-[210px]'
														isCoverageCard={true}
														t={t}
													/>
												)}
											</>
										)}
										{carInfo?.businessModel === ModelTypeEnum.POINTS && (
											<>
												<p
													className={`coverageCarCard__${carInfo?.businessModel} coverageCarCard__coverageTotalRateText text-center font-bold text-xs mt-4 md:mt-2.5`}
												>
													{numberToLocaleWithcurrency(tempTotalRate)} {tempPointsCurrency}
												</p>
											</>
										)}
										{(carInfo?.businessModel === ModelTypeEnum.POINT_OR_MONEY || carInfo?.businessModel === ModelTypeEnum.POINT_PLUS_MONEY) && (
											<>
												{isShowPcoPrice ? (
													<>
														<p
															className={`coverageCarCard__${carData?.businessModel} coverageCarCard__coverageTotalRateText text-center font-bold text-xs mt-4 md:mt-2.5`}
														>
															{`${numberToLocaleWithcurrency(coverage.equivalentRate?.chargedPoints || 0, '', culture)} ${
																coverage.equivalentRate?.pointsCurrency
															}`}
														</p>
														<p
															className={`coverageCarCard__${carData?.businessModel} coverageCarCard__coverageDailyRateText text-center font-normal text-[10px]`}
														>
															+{' '}
															{numberToLocaleWithcurrency(
																coverage.equivalentRate?.chargedAmount || 0,
																coverage.equivalentRate?.currency,
																'',
																currencySymbol,
															)}
														</p>
													</>
												) : (
													<>
														<p
															className={`coverageCarCard__${carData?.businessModel} coverageCarCard__coverageTotalRateText text-center font-bold text-xs mt-4 md:mt-2.5`}
														>
															{tempTotalRate
																? `${numberToLocaleWithcurrency(tempTotalRate)} ${tempPointsCurrency}`
																: `${numberToLocaleWithcurrency(tempDailyRate, tempCurrency)} ${tempCurrency}`}
														</p>
														{!!tempTotalRate && !!tempDailyRate && (
															<p
																className={`coverageCarCard__${carData?.businessModel} coverageCarCard__coverageDailyRateText text-center font-normal text-[10px]`}
															>
																+ {tempCurrency} {numberToLocaleWithcurrency(tempDailyRate, tempCurrency)}
															</p>
														)}
													</>
												)}
											</>
										)}
										<div className='px-[20px] md:px-0'>
											<Button
												onClick={() => detailClickHandler(carData, activeCompany, coverage)}
												content={
													clickLoading && disabled && activeCoverage && activeCoverage === coverage.rateCodeType ? (
														<Spinner
															className={coverage?.isRecommended ? 'coverageCarCard__recommendedSpinner' : 'coverageCarCard__noRecommendedSpinner'}
														/>
													) : (
														<span>{coverageButtonText}</span>
													)
												}
												customClass={`coverageCarCard__coverageButtonText w-full rounded-[12.5px] py-[4px] px-[0] font-bold text-xs mt-2.5 md:mt-4 flex flex-row items-center justify-center min-h-[32px] coverageCarCard__spinner
                              ${coverage?.isRecommended ? 'coverageCarCard__recommendedSpinner' : 'coverageCarCard__noRecommendedSpinner'}`}
												noHover={true}
												styles={{
													backgroundColor: coverage?.isRecommended ? 'black' : 'white',
													color: coverage?.isRecommended ? 'white' : 'black',
												}}
												disabled={disabled}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
		</>
	) : (
		<></>
	);
};

CoverageCarCard.defaultProps = {
	className: '',
	companyImageClassName: '',
	carImageClassName: '',
	carContainerClassName: '',
};

export default CoverageCarCard;
