import IcomoonIcon from '../icomoon-icon/icomoon-icon';

export interface GenericMessageProps {
	message?: string;
}

export function GenericMessage({ message }: GenericMessageProps): JSX.Element {
	return message ? (
		<div className='flex flex-row justify-start items-start text-xs sm:text-sm md:text-base p-5 min-w-[11px]'>
			<IcomoonIcon icon={'infoCirlce2Ico'} className='mr-1' fontSize={20} />
			{message}
		</div>
	) : (
		<></>
	);
}
