import { InputEventsHandler, OptionSelect } from '../../../../../shared/interfaces/inputs.interface';
import { ElementTypes } from '../../../../../shared/enums/dynamic-forms.enum';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import useDebounce from '../../../../../shared/hooks/useDebounce';
import Spinner from '../../../../ui-elements/spinner/spinner';
import { useState, useCallback, useEffect } from 'react';
import Input from '../../../../ui-elements/input/input';
import styles from './filter-field-list.module.scss';
export interface FilterFieldListProps {
	placeHolder: string;
	options: OptionSelect[];
	defaultValue?: string;
	colorSecondary?: string;
	lightTemplate?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	truncate?: boolean;
	callBack?: (selectedOption: OptionSelect) => void;
}

export function FilterFieldList({
	placeHolder,
	options,
	defaultValue,
	colorSecondary,
	lightTemplate,
	isSecondaryTemplate,
	isTertiaryTemplate,
	truncate,
	callBack,
}: FilterFieldListProps): JSX.Element {
	const [activeOption, setActiveOption] = useState<OptionSelect | undefined>(undefined);
	const [filteredOptions, setFilteredOptions] = useState<OptionSelect[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>('');
	const debounceHandler = useDebounce();
	const emitSelectedOption = useCallback(
		(selectedOption: OptionSelect) => {
			setInputValue('');

			setActiveOption(selectedOption);

			setFilteredOptions([...options]);

			if (callBack) {
				callBack(selectedOption);
			}
		},
		[options],
	);

	const filterHandler = useCallback(
		(event: InputEventsHandler): void => {
			let value: string = event.value ? String(event.value) : '';
			setInputValue(value);

			value = value.toLowerCase();

			setFilteredOptions([
				...options.filter((option: OptionSelect) => {
					return option.label.toLowerCase().includes(value);
				}),
			]);

			setIsLoading(false);
		},
		[options],
	);

	const searchInputHandler = useCallback(
		(event: InputEventsHandler): void => {
			setInputValue(event.value ? String(event.value) : '');

			setIsLoading(true);

			debounceHandler(filterHandler, 300)(event);
		},
		[debounceHandler, filterHandler],
	);

	const removeHandler = useCallback(() => {
		setInputValue('');

		const event: InputEventsHandler = {
			value: '',
		};

		filterHandler(event);
	}, [filterHandler]);

	useEffect(() => {
		setFilteredOptions([...options]);
	}, [options]);

	useEffect(() => {
		if (typeof defaultValue === 'string') {
			const defaultOption: OptionSelect | undefined = options.find((option: OptionSelect): boolean => {
				return option.label === defaultValue;
			});

			if (defaultOption) {
				setActiveOption(defaultOption);
			}
		}
	}, [defaultValue, options]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div
				className={`filterFieldList w-full rounded-lg flex bg-white  md:px-0 filterFieldList__container ${lightTemplate ? '!w-[300px]' : ''} 
					${isSecondaryTemplate ? 'z-50 mt-2 md:mt-0' : 'px-[40px]'} 
					${lightTemplate ? 'mt-2' : ''}
				`}
			>
				<div
					className={`flex flex-col w-full pb-[15px]'
					${isTertiaryTemplate ? 'md:rounded-t-none border border-solid border-[#BEBEBE]' : ''} 
				`}
				>
					<div
						className={`relative w-full flex flex-row flex-grow items-center px-[24px] py-[15px] border-b border-solid 
						${isTertiaryTemplate ? 'border-[#E6E6E6] px-[10px] py-[10px]' : 'border-[#7A8083]'}
					`}
					>
						<Input
							value={inputValue}
							type={ElementTypes.text}
							placeholder={placeHolder || 'searchPlaceHolder'}
							className={'filterFieldList__input !py-0 !px-0 flex-grow'}
							inputClassName={`bg-transparent !p-0 font-normal md  placeholder:text-base placeholder:font-normal md:placeholder placeholder:text-[#C3C7CE]'
								${isTertiaryTemplate ? 'text-[#4D4D4F] placeholder:text-sm placeholder:text-[#9E9E9E]' : '!text-base '}
							`}
							autocomplete={'off'}
							style={{
								color: isTertiaryTemplate ? 'text-[14px]' : colorSecondary,
							}}
							onChange={searchInputHandler}
							truncate={truncate}
						/>
						{isLoading ? (
							<Spinner className={'filterFieldList__spinner right-0 relative ml-[5px] bg-white'} />
						) : (
							<button
								className={`filterFieldList__removeBtn right-0 relative inline-block bg-white ml-[5px] cursor-pointer ${inputValue ? '' : 'hidden'}`}
								type='button'
								onClick={removeHandler}
							>
								<IcomoonIcon icon='closeIco' fontSize={10} />
							</button>
						)}
					</div>
					<ul className={`filterFieldList__list m-0 !p-0 pt-[15px] ${lightTemplate ? 'mt-2' : ''}`}>
						{!isLoading &&
							filteredOptions.map((option: OptionSelect, index: number) => {
								return (
									<li
										key={`${option.value}-${option.label}`}
										className={`filterFieldList__option list-none cursor-pointer   ${lightTemplate ? 'rounded ml-4 mr-2 !p-1' : ''} 
											${isSecondaryTemplate ? ' py-[12px] px-[14px] !border-0' : 'px-0 md:px-[35px] text-xs hover:bg-gray-200'} 
											${isTertiaryTemplate ? 'border-b border-solid border-[#E6E6E6]' : ''} 
											${activeOption?.value === option.value && isSecondaryTemplate ? 'selectedOption rounded-sm bg-[#F9FAFB]' : ''}
										`}
										onClick={() => emitSelectedOption(option)}
										style={{ padding: isTertiaryTemplate ? '10px 20px' : '' }}
									>
										<div className={`flex flex-row items-center ${isSecondaryTemplate || isTertiaryTemplate ? '' : 'pt-[10px]'}`}>
											<span
												className={`block flex-grow ${lightTemplate ? 'text-left' : ''} ${
													truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap' : ''
												}
													 ${isTertiaryTemplate ? 'text-[#4D4D4F]' : ''}
												`}
											>
												{option.label}
											</span>
											{activeOption?.value === option.value && (
												<IcomoonIcon icon='checkIco' color={isSecondaryTemplate ? colorSecondary : ''} fontSize={isSecondaryTemplate ? 15 : 10} />
											)}
										</div>
										{!isSecondaryTemplate && !isTertiaryTemplate && (
											<div
												className={`pt-[10px] ${filteredOptions.length - 1 !== index ? 'border-b border-solid border-[#7A8083]' : ''} ${
													lightTemplate ? 'border-b-0' : ''
												}`}
											/>
										)}
									</li>
								);
							})}
					</ul>
				</div>
			</div>
		</>
	);
}

export default FilterFieldList;
