import { ResponseHttp } from '../interfaces/response-http.interface';
import { Agency } from '../interfaces/agency.interface';
import { endPoints } from '../end-points/end-points';
import { HttpCall } from './http-call.service';

export interface DomainInterface {
	clientDomain: string;
	pathName: string;
}

class AgencyService {
	async getAgency(token: string): Promise<Agency | null> {
		const httpCall: HttpCall = new HttpCall();
		const url: string = `${process.env.REACT_APP_MANAGEMENT_API || ''}/${endPoints.getAgency}/`;
		const header = {
			Authorization: `Bearer ${token}`,
		};

		const agency: ResponseHttp<Agency> = await httpCall.get(url, header);
		if (agency.response != null) {
			const agencyResult: Agency = agency.response;
			return agencyResult;
		}

		if (agency.error) {
			throw new Error('fail getting agency');
		}

		return null;
	}
}

export default new AgencyService();
