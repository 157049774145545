import { DataResponseFlexibleFlights, FareSchedules, FlexibeFlightsPayload, Passengers } from '../interfaces/flexible-flights.interface';
import { formatDate, numberToLocaleWithcurrency } from '../../../../shared/services';
import { DestinationsFlightsSearchFormSubmitInterface } from '../interfaces';
import { flexibleFlightsParams } from '../../../../lib/flights/organisms';
import { DateFormat, ModelTypeEnum } from '../../../../shared/enums';
import { FlightsSearchFormUtil } from './flights-search-form.util';
import { RowData } from '../../../../shared/interfaces';
import { FlexibleFlightsEnums } from '../enums/index';
import { ToolTip } from '../../../../lib/ui-elements';
import { TableUtil } from '../../../../shared/utils';

export class FlexibleFlightsUtil {
	public static mapPayloadParams(params: flexibleFlightsParams, companyId: string, currency: string): FlexibeFlightsPayload {
		const arrayPassengers = (adult: number, children: number, infante: number): Passengers[] => {
			const array: Passengers[] = [];
			for (let i = 0; i < adult; i++) {
				array.push({ type: FlexibleFlightsEnums.adult });
			}

			for (let i = 0; i < children; i++) {
				array.push({ type: FlexibleFlightsEnums.children });
			}

			for (let i = 0; i < infante; i++) {
				array.push({ type: FlexibleFlightsEnums.infante });
			}

			return array;
		};

		return {
			flightSchedules:
				params.trip === FlexibleFlightsEnums.tripSingle
					? [
						{
							departureDate: params.departureDate,
							departureCity: params.departureCity,
							arrivalCity: params.arrivalCity,
							departureWindow: null,
						},
					  ]
					: [
						{
							departureDate: params.departureDate,
							departureCity: params.departureCity,
							arrivalCity: params.arrivalCity,
							departureWindow: null,
						},
						{
							departureDate: params.arrivalDate,
							departureCity: params.arrivalCity,
							arrivalCity: params.departureCity,
							departureWindow: null,
						},
					  ],
			passengers: arrayPassengers(params.adultPassengersAmount || 1, params.childrenPassengersAmount || 0, params.infantePassengersAmount || 0),
			directFlightsOnly: params.directFlightsOnly || false,
			trip: params.trip,
			cabin: 'any', // dinamico?
			currency,
			flightsCount: 1,
			companyId,
			promoCode: params.promoCode || null,
			resultsCount: 49,
			selectedAirline: 'all', // dinamico?
			airlineDetail: {
				provider: 'Sabre',
				airline: 'ALL',
			},
		};
	}

	public static mapFlexibleFlighttoTableData(
		nextLabel: string,
		ofLabel: string,
		mapHeaders: boolean,
		data: DataResponseFlexibleFlights,
		params: flexibleFlightsParams,
	): {
			head: RowData[];
			rows: RowData[][];
		} {
		const tableUtils = new TableUtil();
		const { businessModel } = data;
		const toolTip = (schedules: FareSchedules, url: string): JSX.Element => {
			// TO-DO
			let values = { cash: schedules.total, points: 0, currency: schedules?.currency, pointsCurrency: '' };

			if (businessModel === ModelTypeEnum.CASH) {
				values = { cash: schedules.total, points: 0, currency: schedules?.currency, pointsCurrency: '' };
			}

			if (businessModel === ModelTypeEnum.POINTS) {
				//
			}

			if (businessModel === ModelTypeEnum.POINT_OR_MONEY) {
				//
			}

			return (
				<ToolTip
					title={`$${numberToLocaleWithcurrency(Number(values.cash), values.currency)}`}
					customClass={`contPopUp colorTitle ${!schedules.isAvailable ? 'isAvailableFalse' : ''} ${schedules.isLowestFare ? 'colorLowPrice' : ''}`}
					toolTipCustomClass={`${schedules.isAvailable ? 'popUp' : 'hiddenToolTip'}`}
					body={`
              <h2>${schedules.departureLocation?.cityName || ''} - ${schedules.arrivalLocation?.cityName || ''}</h2>
              <p>
                ${formatDate(schedules.schedule.departureDate, DateFormat.daySingle)} ${ofLabel} ${formatDate(
					schedules.schedule.departureDate,
					DateFormat.monthYear,
					'es-CO',
				)} - ${formatDate(schedules.schedule.arrivalDate, DateFormat.daySingle)} ${ofLabel} ${formatDate(
					schedules.schedule.departureDate,
					DateFormat.monthYear,
					'es-CO',
				)}
					</p>
					<a href='${url}' target='_blank'>${nextLabel}</a>
        `}
				/>
			);
		};

		const DateFormatHtml = (departure: string): JSX.Element => {
			return (
				<h3>
					{formatDate(departure, DateFormat.weekdaySingle, 'es-CO').replace('.', '')}
					<p>{formatDate(departure, DateFormat.dayMonth, 'es-CO').replace('.', '')}</p>
				</h3>
			);
		};

		if (mapHeaders) {
			const datesArrival: RowData[] = data.arrivalDates.map(departure => ({
				value: DateFormatHtml(departure),
				label: '',
			}));

			return {
				head: datesArrival,
				rows: [[]],
			};
		}

		const datesDeparture: RowData[] = data.departureDates.map(departure => ({
			value: DateFormatHtml(departure),
			label: '',
		}));

		const createDestinations = (schedules: FareSchedules): DestinationsFlightsSearchFormSubmitInterface[] => {
			let destinations: DestinationsFlightsSearchFormSubmitInterface[] = [];
			if (params.trip === 'round') {
				destinations = [
					{
						departure: {
							airport: schedules.departureLocation?.airport || '',
							airportCode: schedules.departureLocation?.airportCode || '',
							date: schedules.schedule.departureDate,
						},
						arrival: {
							airport: schedules.arrivalLocation?.airport || '',
							airportCode: schedules.arrivalLocation?.airportCode || '',
							date: schedules.schedule.arrivalDate,
						},
						dates: '',
					},
					{
						departure: {
							airport: schedules.arrivalLocation?.airport || '',
							airportCode: schedules.arrivalLocation?.airportCode || '',
							date: schedules.schedule.arrivalDate,
						},
						arrival: {
							airport: schedules.departureLocation?.airport || '',
							airportCode: schedules.departureLocation?.airportCode || '',
							date: schedules.schedule.departureDate,
						},
						dates: '',
					},
				];
			}

			if (params.trip === 'single') {
				destinations = [
					{
						departure: {
							airport: schedules.departureLocation?.airport || '',
							airportCode: schedules.departureLocation?.airportCode || '',
							date: schedules.schedule.departureDate,
						},
						arrival: {
							airport: schedules.arrivalLocation?.airport || '',
							airportCode: schedules.arrivalLocation?.airportCode || '',
							date: schedules.schedule.departureDate,
						},
						dates: '',
					},
				];
			}

			return destinations;
		};

		const fortmatDataResponse = (schedules: FareSchedules): RowData => {
			const mapSearch = FlightsSearchFormUtil.mapSearchUrl({
				destinations: createDestinations(schedules),
				adults: params?.adultPassengersAmount || 1,
				children: params?.childrenPassengersAmount || 0,
				infants: params.infantePassengersAmount || 0,
				cabin: 'any',
				passengersAndExtraInfo: '',
				airline: 'all',
				airlineCode: '',
				tripType: params?.trip || 'round',
				onlyDirectFlight: params?.directFlightsOnly || false,
			});

			const dataRows: RowData = {
				value: schedules?.total ? toolTip(schedules, mapSearch) : '',
				label: '',
			};

			return dataRows;
		};

		const dataRows = tableUtils.chunkArray<FareSchedules, RowData>(data.fareSchedules, 7, fortmatDataResponse) as RowData[][];
		return {
			head: datesDeparture,
			rows: dataRows,
		};
	}
}
