import { Remarks } from '../../../../../../shared/interfaces/flights/remark.interface';
import { TableData, RowData } from '../../../../../../shared/interfaces/table';
import { Accordion } from '../../../../../ui-elements/accordion/accordion';
import Table from '../../../../../ui-elements/table/table';
import styles from './booking-remarks.module.scss';
import { useMemo } from 'react';

export interface BookingRemarksProps {
	title: string;
	commentHead: string;
	typeCommentHead: string;
	remarks: Remarks[];
	className?: string;
}

export const BookingRemarks = (props: BookingRemarksProps): JSX.Element => {
	const commentLabel: string = 'comment';
	const typeCommentLabel: string = 'typeComment';
	const logContent = useMemo<JSX.Element>(() => {
		const data: TableData = {
			head: [
				{ value: props.commentHead, label: commentLabel },
				{ value: props.typeCommentHead, label: typeCommentLabel },
			],
			rows: props.remarks.map((remark: Remarks): RowData[] => {
				return [
					{ value: remark.value, label: commentLabel },
					{
						value: remark.type.replace(/\b\w/g, l => l.toUpperCase()),
						label: typeCommentLabel,
					},
				];
			}),
		};

		return (
			<div className='w-full pb-3'>
				<Table data={data} className={'w-full CustomerDataMock table-auto mx-auto flightBookin__table'} />
			</div>
		);
	}, [props.remarks, props.commentHead, props.typeCommentHead, commentLabel, typeCommentLabel]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<Accordion
				title={props.title.toLocaleUpperCase()}
				content={logContent}
				titleCustomClass={'text-lg leading-6 font-medium text-color-primary'}
				className={props.className}
			/>
		</>
	);
};
