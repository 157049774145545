import { InputEventsHandler, OptionSelect, InputOtherConfig } from '../../../shared/interfaces/inputs.interface';
import { OptionsItems } from '../../../shared/interfaces/dynamic-forms.interface';
import { UseFormRegisterReturn, UseFormReturn } from 'react-hook-form';
import { FormsInputsType } from '../../../shared/enums/inputs.enum';
import { useState, useCallback, useEffect } from 'react';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import Backdrop from '../backdrop/backdrop';
import styles from './select.module.scss';

/* eslint-disable-next-line */
export interface SelectProps {
	options: OptionSelect[] | OptionsItems[];
	label?: string | JSX.Element;
	name?: string;
	placeHolder?: string;
	defaultValue?: string;
	disabled?: boolean;
	readonly?: boolean;
	required?: boolean;
	register?: UseFormRegisterReturn;
	otherConfig?: InputOtherConfig;
	isSelectInsideDiv?: boolean;
	onChange?: (e: InputEventsHandler) => void;
	onClick?: () => void;
	onFocus?: () => void;
	setValue?: UseFormReturn['setValue'];
	className?: string;
	selectClassName?: string;
	customSelectClassName?: string;
	customSelectPlaceholderClassName?: string;
	labelClassName?: string;
	selectConatinerClassName?: string;
	style?: React.CSSProperties;
	optionsMarginTop?: string;
	optionsHeight?: string;
	customClassOptionsContainer?: string;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	truncate?: boolean;
	colorSecondary?: string;
}

export const Select = ({
	options,
	label,
	name,
	placeHolder,
	defaultValue,
	disabled,
	readonly,
	required,
	register,
	otherConfig,
	isSelectInsideDiv,
	onChange,
	onClick,
	onFocus,
	setValue,
	className,
	selectClassName,
	customSelectClassName,
	customSelectPlaceholderClassName,
	labelClassName,
	selectConatinerClassName,
	style,
	optionsMarginTop,
	optionsHeight,
	customClassOptionsContainer,
	isSecondaryTemplate,
	isTertiaryTemplate,
	colorSecondary,
	truncate,
}: SelectProps): JSX.Element => {
	const [selectedValueOption, setSelectedValueOption] = useState<string>(defaultValue || '');
	const [selectedLabelOption, setSelectedLabelOption] = useState<string>('');
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [activeSelect, setActiveSelect] = useState<boolean>(false);
	const showDropdownHandler = useCallback(() => {
		if (!(disabled || readonly)) {
			if (onClick) {
				onClick();
			}

			setShowDropdown((prevState: boolean): boolean => !prevState);

			setActiveSelect((prevState: boolean): boolean => !prevState);
		}
	}, [onClick, disabled, readonly]);

	const changeHandler = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement> | string) => {
			let option: string;
			let tempEvent: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | undefined;
			if (typeof event === 'string') {
				option = event;

				tempEvent = undefined;
			} else {
				option = event.target.value;

				tempEvent = event;
			}

			if (typeof event === 'string' && name && setValue) {
				setValue(name, option, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
			}

			if (onChange) {
				const dataEvent: InputEventsHandler = {
					type: FormsInputsType.select,
					name,
					value: option,
					event: tempEvent,
				};

				onChange(dataEvent);
			}

			if (register?.onChange && typeof event !== 'string') {
				void register.onChange(event);
			}

			setSelectedValueOption(option);

			setShowDropdown(false);
		},
		[name, register, onChange, setValue],
	);

	useEffect(() => {
		let selectedItemOption: string | undefined;
		options.forEach((tempOption: OptionSelect | OptionsItems): void => {
			if (tempOption.value === selectedValueOption) {
				selectedItemOption = tempOption.label;
			}
		});

		if (selectedItemOption) {
			setSelectedLabelOption(selectedItemOption);
		}
	}, [selectedValueOption, options]);

	useEffect(() => {
		if (defaultValue || defaultValue === '') {
			setSelectedValueOption(defaultValue);
		}
	}, [defaultValue]);

	const selectElement: JSX.Element = (
		<>
			<div
				className={`selectInput__selectMobileContainer block md:hidden w-full select__selectContainer ${
					isSecondaryTemplate ? '' : activeSelect ? 'select__activeSelectContainer' : 'select__deactiveSelectContainer'
				} `}
			>
				{isSecondaryTemplate || isTertiaryTemplate ? (
					<>
						<div
							className={`selectInput__value flex flex-row justify-between items-center ${showDropdown ? 'active' : ''}`}
							onClick={showDropdownHandler}
							onFocus={onFocus}
						>
							<span
								className={`${(selectedValueOption ? customSelectClassName : customSelectPlaceholderClassName) || ''} ${
									truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-pre-wrap' : ''
								}`}
							>
								{selectedValueOption ? selectedLabelOption : placeHolder || ''}
							</span>
							{showDropdown ? (
								<IcomoonIcon
									icon='chevronUpIco'
									className='pl-[5px]'
									iconClassName={`${isSecondaryTemplate ? 'text-[7px]' : 'text-[5px]'}
									${isTertiaryTemplate ? 'hidden' : ''}
								`}
								/>
							) : (
								<IcomoonIcon
									icon='chevronBottomIco'
									className='pl-[5px]'
									iconClassName={`${isSecondaryTemplate ? 'text-[7px]' : 'text-[5px]'}
									${isTertiaryTemplate ? 'hidden' : ''}
								`}
								/>
							)}
						</div>
						<>
							<Backdrop
								show={showDropdown}
								onClick={showDropdownHandler}
								zIndex={10}
								backgroundColor={'transparent'}
								opacity={1}
								className='hidden md:block'
								portalBackdrop={true}
							/>
							<ul
								className={`selectInput__optionsContainer ${
									customClassOptionsContainer || ''
								} absolute z-[12] bg-white text-left m-0 !p-0 rounded-[10px] overflow-y-auto select__optionsContainer ${
									showDropdown ? 'min-h-[5px] opacity-100 visible' : 'min-h-0 opacity-0 invisible'
								} ${isSecondaryTemplate ? 'mt-[12px] left-[-16px] right-[-16px]' : 'w-full'}`}
								style={{
									marginTop: optionsMarginTop,
									maxHeight: optionsHeight,
								}}
							>
								{options.map((option: OptionSelect | OptionsItems) => {
									return (
										<li
											key={`${option.value}-${option.label}`}
											className={`selectInput__option list-none cursor-pointer  select__option flex justify-between items-center ${
												selectedValueOption === option.value ? 'selectedOption rounded-sm bg-[#F9FAFB]' : ''
											}${isSecondaryTemplate ? ' py-[12px] px-[14px] !border-0' : ' py-[10px] px-[25px]'}`}
											onClick={() => changeHandler(option.value)}
										>
											<span className={`${truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-pre-wrap' : ''}`}>{option.label}</span>
											{selectedValueOption === option.value && isSecondaryTemplate && (
												<IcomoonIcon color={colorSecondary} icon='checkIco' className='pl-[5px]' iconClassName={'text-[15px] selectedOptionIcon '} />
											)}
										</li>
									);
								})}
							</ul>
						</>
					</>
				) : (
					<select
						style={style ? { ...style, pointerEvents: readonly ? 'none' : undefined } : { pointerEvents: readonly ? 'none' : undefined }}
						onFocus={onFocus}
						onClick={showDropdownHandler}
						className={`selectInput__select
					select__select w-full text-sm rounded p-1 mt-1 min-xs ${disabled || readonly ? '' : 'cursor-pointer'} ${selectClassName || ''}`}
						value={selectedValueOption}
						disabled={disabled}
						name={name}
						id={name}
						required={required || false}
						{...register}
						{...otherConfig}
						onChange={changeHandler}
					>
						{placeHolder && (
							<option disabled value='' className='selectInput__option'>
								{placeHolder}
							</option>
						)}
						{options.map((option: OptionSelect | OptionsItems) => {
							return (
								<option
									key={`${option.value}-${option.label}`}
									value={option.value}
									className={`selectInput__option ${option.hidden ? 'hidden' : ''} ${
										truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-pre-wrap' : ''
									}`}
								>
									{option.label}
								</option>
							);
						})}
					</select>
				)}
			</div>
			<div
				style={style}
				className={`selectInput__select hidden md:block select__select w-full relative text-sm rounded p-1 mt-1 min-xs ${
					disabled || readonly ? '' : 'cursor-pointer'
				} ${selectClassName || ''}`}
			>
				<div
					className={`selectInput__value flex flex-row justify-between items-center ${showDropdown ? 'active' : ''}
						${isTertiaryTemplate ? 'py-2.5 px-3.5' : ''}`}
					onClick={showDropdownHandler}
					onFocus={onFocus}
				>
					<span
						className={`${(selectedValueOption ? customSelectClassName : customSelectPlaceholderClassName) || ''} ${
							truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-pre-wrap' : ''
						}`}
					>
						{selectedValueOption ? selectedLabelOption : placeHolder || ''}
					</span>
					{showDropdown ? (
						<IcomoonIcon icon='chevronUpIco' className='pl-[5px]' iconClassName={`${isSecondaryTemplate ? 'text-[7px]' : 'text-[5px]'}`} />
					) : (
						<IcomoonIcon icon='chevronBottomIco' className='pl-[5px]' iconClassName={`${isSecondaryTemplate ? 'text-[7px]' : 'text-[5px]'}`} />
					)}
				</div>
				<>
					<Backdrop
						show={showDropdown}
						onClick={showDropdownHandler}
						zIndex={10}
						backgroundColor={'transparent'}
						opacity={1}
						className='hidden md:block'
						portalBackdrop={true}
					/>
					<ul
						className={`selectInput__optionsContainer ${
							customClassOptionsContainer || ''
						} absolute z-[12] bg-white text-left m-0 !p-0 rounded-[10px] overflow-y-auto select__optionsContainer ${
							showDropdown ? 'min-h-[5px] opacity-100 visible' : 'min-h-0 opacity-0 invisible'
						} ${
							isSecondaryTemplate
								? 'mt-[12px] left-[-16px] right-[-16px]'
								: isTertiaryTemplate
									? 'w-full md:rounded-t-none text-[#000000] top-[45px]'
									: 'w-full'
						}`}
						style={{
							marginTop: optionsMarginTop,
							maxHeight: optionsHeight,
						}}
					>
						{options.map((option: OptionSelect | OptionsItems) => {
							return (
								<li
									key={`${option.value}-${option.label}`}
									className={`selectInput__option list-none cursor-pointer  select__option flex justify-between items-center ${
										selectedValueOption === option.value ? 'selectedOption rounded-sm bg-[#F9FAFB]' : ''
									}
										${isSecondaryTemplate ? ' py-[12px] px-[14px] !border-0' : 'py-[10px] px-[25px]'}
										${isTertiaryTemplate ? '!px-[14px]' : ''}
										${isTertiaryTemplate && selectedValueOption === option.value ? 'text-[#1075BB]' : ''}
										
									`}
									onClick={() => changeHandler(option.value)}
								>
									<span
										className={`overflow-ellipsis overflow-hidden max-w-full whitespace-pre-wrap ${
											truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-pre-wrap' : ''
										}`}
									>
										{option.label}
									</span>
									{selectedValueOption === option.value && isSecondaryTemplate && (
										<IcomoonIcon color={colorSecondary} icon='checkIco' className='pl-[5px]' iconClassName={'text-[15px] selectedOptionIcon '} />
									)}
								</li>
							);
						})}
					</ul>
				</>
			</div>
		</>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`selectInput flex items-center rounded-lg py-2.5 px-2 flex-col justify-center select ${className || ''}`}>
				{label && (
					<label className={`selectInput__label font-family-regular flex whitespace-pre-wrap w-full ${labelClassName || ''}`} htmlFor={name}>
						{label} {required ? '*' : ''}
					</label>
				)}
				{isSelectInsideDiv ? (
					<div className={`selectInput__selectContainer block w-full min-xs ${selectConatinerClassName || ''}`}>{selectElement}</div>
				) : (
					selectElement
				)}
			</div>
		</>
	);
};

Select.defaultProps = {
	selectClassName: '',
	className: '',
	labelClassName: '',
	selectConatinerClassName: '',
	customSelectClassName: '',
	customSelectPlaceholderClassName: '',
	optionsHeight: '200px',
};

export default Select;
