import { CMSGeneralComponentInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import InspirationalDestinationsSkeleton from './skeleton/skeleton-inspirational-destinations';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import useSlider from '../../../../../shared/hooks/useSlider';
import styles from './inspirational-destinations.module.scss';
import Slider from '../slider/slider';
import { useEffect } from 'react';

export interface InspirationalDestinationsProps extends CMSGeneralComponentInterface {
	loading?: boolean;
	transitionTime?: number;
	mainColor?: string;
	className?: string;
	titleClassName?: string;
	subtitleClassName?: string;
	descriptionClassName?: string;
	buttonClassName?: string;
	slideTitleClassName?: string;
	slideDescriptionClassName?: string;
}

export function InspirationalDestinations({
	title,
	subtitle,
	description,
	slides,
	loading,
	transitionTime,
	mainColor,
	className,
	titleClassName,
	subtitleClassName,
	descriptionClassName,
	buttonClassName,
	slideTitleClassName,
	slideDescriptionClassName,
}: InspirationalDestinationsProps): JSX.Element {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const slidesToShow: number = windowWidth < responsiveSize ? 1 : 3;
	const slidesMargin: number = windowWidth < responsiveSize ? 0 : 20;
	const sliderSideMargin: number = 0;
	const { nextPosition, previousPosition, sliderRef, slidesContainerRef, slidesWidth } = useSlider(
		slides.length - 1,
		slidesToShow,
		slidesToShow,
		transitionTime,
		0,
		slidesMargin,
		sliderSideMargin,
		false,
	);

	useEffect(() => {
		const root = document.documentElement;
		if (mainColor) {
			root?.style.setProperty('--inspirational-destinations-main-color', mainColor);
		}
	}, [mainColor]);

	return loading ? (
		<InspirationalDestinationsSkeleton className={className} />
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`inspirationalDestinations ${className || ''}`}>
				<div className='flex flex-col justify-start mb-4 w-full md:w-4/5'>
					<div
						className='h-px w-6 md:w-12 border-b-4 rounded-xl mb-2'
						style={{
							backgroundColor: mainColor,
							borderColor: mainColor,
						}}
					/>
					<p
						className={`font-bold text-left text-xs mb-2.5 md:mb-4 ${subtitleClassName || ''}`}
						style={{
							color: mainColor,
						}}
					>
						{subtitle ? subtitle.toUpperCase() : ''}
					</p>
					<h2 className={`font-bold text-black mb-2.5 text-left text-2xl md:text-5xl ${titleClassName || ''}`}>{title}</h2>
					<p
						className={`inspirationalDestinations__description font-normal text-black text-left text-xs md:text-base w-full md:w-[80%] ${
							descriptionClassName || ''
						}`}
					>
						{description}
					</p>
				</div>
				<div className='flex flex-row justify-end items-center mb-7 md:mb-14'>
					<div
						className={
							'flex justify-center items-center cursor-pointer rounded-full border-[3px] w-[30px] md:w-[50px] h-[30px] md:h-[50px] inspirationalDestinations__iconButton'
						}
						style={{
							borderColor: mainColor,
							color: mainColor,
						}}
						onClick={previousPosition}
					>
						<IcomoonIcon icon='chevronLeftIco' fontSize={windowWidth >= responsiveSize ? 16 : 10} iconClassName='font-black' />
					</div>
					<div
						className={
							'flex justify-center items-center cursor-pointer rounded-full border-[3px] w-[30px] md:w-[50px] h-[30px] md:h-[50px] ml-8 inspirationalDestinations__iconButton'
						}
						style={{
							borderColor: mainColor,
							color: mainColor,
						}}
						onClick={nextPosition}
					>
						<IcomoonIcon icon='chevronRightIco' fontSize={windowWidth >= responsiveSize ? 16 : 10} iconClassName='font-black' />
					</div>
				</div>
				<Slider
					slides={slides}
					slidesHeight={windowWidth >= responsiveSize ? '370px' : '300px'}
					config={{
						showContent: true,
						showLinkButton: true,
						showSubtitleLine: false,
						showBackgroundImage: true,
						showImageBackdrop: true,
					}}
					className='w-full justify-between items-center'
					slidesClassNames={{
						className: 'rounded-xl w-full inspirationalDestinations__slide',
						outClassName: 'inspirationalDestinations__slide w-full md:w-[32%]',
						imageClassName: 'rounded-xl object-cover',
						imageBackdropClassName: 'inspirationalDestinations__backdrop',
						contentClassName: 'px-6 pb-8 md:pb-10 w-full inspirationalDestinations__slideInfoContainer',
						titleClassName: `text-white text-2xl md:text-4xl ${slideTitleClassName || ''}`,
						subtitleClassName: 'hidden',
						descriptionClassName: `text-white text-base ${slideDescriptionClassName || ''}`,
						buttonClassName: `inspirationalDestinations__slideButton inline-flex w-full md:w-auto rounded-full text-black bg-white opacity-80 mt-4 text-xs md:text-base inspirationalDestinations__slideButton ${
							buttonClassName || ''
						}`,
					}}
					sliderRef={sliderRef}
					slidesContainerRef={slidesContainerRef}
					slidesWidth={slidesWidth}
					slidesMargin={slidesMargin}
					sliderSideMargin={sliderSideMargin}
					slidesNumberShow={slidesToShow}
				/>
			</div>
		</>
	);
}

InspirationalDestinations.defaultProps = {
	className: '',
	titleClassName: '',
	subtitleClassName: '',
	descriptionClassName: '',
	buttonClassName: '',
	slideTitleClassName: '',
	slideDescriptionClassName: '',
};

export default InspirationalDestinations;
