export enum LocalStorageHistoriesNames {
	carsSearch = 'carsSearchWidgetHistory',
	flightsSearch = 'flightsSearchWidgetHistory',
	hotelsSearch = 'hotelsSearchWidgetHistory',
	activitiesSearch = 'activitiesSearchWidgetHistory',
	assistancesSearch = 'assistancesSearchWidgetHistory',
	plansSearch = 'plansSearchWidgetHistory',
	disneySearch = 'disneySearchWidgetHistory',
	hotelsDisneySearch = 'hotelsDisneySearchWidgetHistory',
	hotelsDisneyResumeAside = 'hotelsDisneyResumeAside',
	specialEventsSearch = 'specialEventsSearchWidgetHistory',
	pointsConsultationWidget = 'pointsConsultationWidget',
}
