import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { InputEventsHandler, DatePickerData } from '../../../../shared/interfaces/inputs.interface';
import { SearchFieldsNames, DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { HotelsDisneySearchFormInputsNames } from '../enums/hotels-disney-search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
	RoomNameHotelsDisneySearchFormInterface,
	FieldRoomNameHotelsDisneySearchFormInterface,
	UseHotelsDisneySearchFormInterface,
	UseHotelsDisneySearchFormReturnInterface,
	HotelsDisneySearchFormSubmitInterface,
	RoomsHotelsDisneySearchFormSubmitInterface,
	RoomsFieldsRestrictionsInterfaceDisney,
} from '../interfaces/hotels-disney-search-form.interface';
import {
	FieldButtonEventHandlerInterface,
	DisplayValuesDatesFieldInterface,
	DatesFieldInputNamesInterface,
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import { HotelsDisneySearchFormUtil } from '../utils/hotels-disney-search-form.util';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import { HotelProvider } from '../../../../shared/enums/hotel-provider.enum';

const useHotelsDisneySearchForm = ({
	history,
	calendarRestriction,
	roomsRestriction,
	maxRooms,
	traductions,
	emitSubmitSearch,
	emitShowMobileFields,
}: UseHotelsDisneySearchFormInterface): UseHotelsDisneySearchFormReturnInterface => {
	const datesHiddenName: string = HotelsDisneySearchFormInputsNames.hiddenDates;
	const destinationId: SearchFieldsNames = SearchFieldsNames.destination;
	const destinationName: string = HotelsDisneySearchFormInputsNames.destination;
	const datesName: string = HotelsDisneySearchFormInputsNames.dates;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const defaultChildren: number = 0;
	const minRoomsNumber: number = 1;
	const defaultAdults: number = 1;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors },
	} = useForm();

	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(destinationId);
	const [totalChildren, setTotalChildren] = useState<number>(defaultChildren);
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
		endDate: new Date(),
	});

	const [roomsNumber, setRoomsNumber] = useState<number>(minRoomsNumber);
	const [totalAdults, setTotalAdults] = useState<number>(defaultAdults);
	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [childrenAges, setChildrenAges] = useState<number[][]>([]);
	const [minDate, setMinDate] = useState<Date>(new Date());
	const mobileFormHeight: number = useWindowHeight();
	const roomsNames: RoomNameHotelsDisneySearchFormInterface[] = useMemo(
		() =>
			((tempRoomsRestriction: RoomsFieldsRestrictionsInterfaceDisney, tempMaxRooms?: number): RoomNameHotelsDisneySearchFormInterface[] => {
				return HotelsDisneySearchFormUtil.getRoomsInputsNames(HotelsDisneySearchFormInputsNames.rooms, tempRoomsRestriction, tempMaxRooms);
			})(roomsRestriction as RoomsFieldsRestrictionsInterfaceDisney, maxRooms),
		[roomsRestriction, maxRooms],
	);

	const showMobileFieldsContainerHandler = useCallback((show: boolean): void => {
		setShowContainer(show);

		setMobileStep(datesId);
	}, []);

	const datesHandler = useCallback((event: InputEventsHandler): void => {
		const getData: DatePickerData = event.value as DatePickerData;
		setDefaultDates({
			startDate: getData.startDate,
			endDate: getData.endDate,
		});
	}, []);

	const roomsFieldNumberHandler = useCallback(
		(step: number): void => {
			const noPeople: number = 0;
			let newValue: number = roomsNumber + step;
			if (newValue > roomsNames.length || newValue < minRoomsNumber || (newValue > roomsNumber && totalAdults >= (roomsRestriction?.adults || 0))) {
				newValue = roomsNumber;
			}

			if (newValue > roomsNumber) {
				const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomsNames[newValue - 1].name);
				const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
					defaultAdults,
					defaultChildren,
					0,
					traductions('adult') || 'adult',
					traductions('adults') || 'adults',
					traductions('child') || 'child',
					traductions('children') || 'children',
					'',
					'',
				);

				setValue(tempPassengersNames.adultsName, defaultAdults);

				setValue(tempPassengersNames.childrenName, defaultChildren);

				setValue(tempPassengersNames.name, passengersDisplayValue || '');

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					tempState[newValue + 1] = [];

					return [...tempState];
				});
			}

			if (newValue < roomsNumber) {
				setMobileStep(roomsNames[newValue - 1].id);

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					tempState[newValue] = [];

					return [...tempState];
				});
			}

			setRoomsNumber(newValue);

			let tempTotalAdults: number = 0;
			let tempTotalChildren: number = 0;
			for (let i = 0; i < roomsNames.length; i++) {
				const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomsNames[i].name);
				if (i >= newValue) {
					setValue(tempPassengersNames.adultsName, noPeople);

					setValue(tempPassengersNames.childrenName, noPeople);

					setValue(tempPassengersNames.name, '');
				} else {
					tempTotalAdults += Number(getValues(tempPassengersNames.adultsName) || 0);

					tempTotalChildren += Number(getValues(tempPassengersNames.childrenName) || 0);
				}
			}

			setTotalAdults(tempTotalAdults || defaultAdults);

			setTotalChildren(tempTotalChildren || defaultChildren);
		},
		[roomsNumber, roomsNames, totalAdults, roomsRestriction, getValues, traductions],
	);

	const roomsModalInputsHandler = useCallback(
		(currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string): number => {
			const roomIndex: number = roomsNames.findIndex((roomName: RoomNameHotelsDisneySearchFormInterface): boolean => roomName.name === name);
			const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
			if (inputName === tempPassengersNames.hiddenAdultsName) {
				const adultsOutOfRestriction: boolean =
					newValue - currentData.adults > 0 && (roomsRestriction?.adults || 0) < newValue - currentData.adults + totalAdults;

				const tempNewValue: number = adultsOutOfRestriction ? currentData.adults : newValue;
				setTotalAdults((prevState: number): number => prevState + (tempNewValue - currentData.adults));

				return tempNewValue;
			}

			if (inputName === tempPassengersNames.hiddenChildrenName) {
				const childrenOutOfRestriction: boolean =
					newValue - currentData.children > 0 && (roomsRestriction?.children || 0) < newValue - currentData.children + totalChildren;

				const tempNewValue: number = childrenOutOfRestriction ? currentData.children : newValue;
				setTotalChildren((prevState: number): number => prevState + (tempNewValue - currentData.children));

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					if (tempState[roomIndex]) {
						if (tempState[roomIndex].length < tempNewValue) {
							tempState[roomIndex] = tempState[roomIndex].concat(Array(tempNewValue - tempState[roomIndex].length).fill(0));
						} else if (tempState[roomIndex].length > tempNewValue) {
							tempState[roomIndex].splice(tempNewValue);
						}
					} else {
						tempState[roomIndex] = [...Array(tempNewValue).fill(0)];
					}

					return [...tempState];
				});

				return tempNewValue;
			}

			return newValue;
		},
		[roomsNames, totalAdults, totalChildren, roomsRestriction],
	);

	const childrenAgesHandler = useCallback((event: InputEventsHandler, roomIndex: number, ageIndex: number): void => {
		const minAge: number = 0;
		const maxAge: number = 17;
		let newValue: number = Number(event.value);
		if (newValue < minAge) {
			newValue = minAge;
		}

		if (newValue > maxAge) {
			newValue = maxAge;
		}

		setChildrenAges((prevState: number[][]) => {
			const tempState: number[][] = [...prevState];
			tempState[roomIndex][ageIndex] = newValue;

			return [...tempState];
		});
	}, []);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const tempValidRoomsNames = roomsNames?.length ? [...roomsNames] : [];
			tempValidRoomsNames.splice(roomsNumber);

			const rooms: RoomsHotelsDisneySearchFormSubmitInterface[] = tempValidRoomsNames.map(
				(roomName: RoomNameHotelsDisneySearchFormInterface, roomIndex: number): RoomsHotelsDisneySearchFormSubmitInterface => {
					const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomName.name);
					return {
						adults: data[tempPassengersNames.adultsName] || 0,
						children: data[tempPassengersNames.childrenName] || 0,
						display: data[tempPassengersNames.name] || '',
						childrenAges: childrenAges[roomIndex]?.length ? [...childrenAges[roomIndex]] : [],
					};
				},
			);

			const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
			const results: HotelsDisneySearchFormSubmitInterface = {
				rooms: [...rooms],
				dates: data[datesName] || '',
				departureDate: data[tempDatesNames.startDate] || '',
				arrivalDate: data[tempDatesNames.endDate] || '',
				destination: HotelProvider.disney,
				destinationIata: HotelProvider.disney,
				urlPath: undefined,
			};

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.startEndDates,
				{
					date: true,
					time: false,
				},
				results.departureDate,
				'',
				results.arrivalDate,
				'',
			);

			setButtonSearchHistory({
				departure: results.destinationIata,
				arrival: '',
				departureDate: datesValues.largeStartDate || '',
				arrivalDate: datesValues.largeEndDate || '',
			});

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[roomsNumber, roomsNames, childrenAges, emitSubmitSearch],
	);

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			const firstIndex: number = 0;
			if (event.id === datesId) {
				setMobileStep(roomsNames[firstIndex].id);

				return;
			}

			const { roomName, roomIndex }: FieldRoomNameHotelsDisneySearchFormInterface = HotelsDisneySearchFormUtil.findRoomNameFromId(
				roomsNames,
				event.id,
			);

			if (roomIndex === roomsNumber - 1 && roomName) {
				void (async () => {
					const delayTime: number = 500;
					setTimeout(() => {
						void onSubmit();

						setShowContainer(false);
					}, delayTime);
				})();

				return;
			}

			if (roomName) {
				const nextIndex: number = typeof roomIndex === 'number' ? roomIndex + 1 : 0;
				setMobileStep(roomsNames[nextIndex].id);
			}
		},
		[roomsNames, roomsNumber, onSubmit],
	);

	const backMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			const { roomName, roomIndex }: FieldRoomNameHotelsDisneySearchFormInterface = HotelsDisneySearchFormUtil.findRoomNameFromId(
				roomsNames,
				event.id,
			);

			const beforeIndex: number = roomIndex ? roomIndex - 1 : 0;
			if (beforeIndex && roomName) {
				setMobileStep(roomsNames[beforeIndex].id);

				return;
			}

			if (roomName) {
				setMobileStep(datesId);
			}
		},
		[roomsNames],
	);

	useEffect(() => {
		setChildrenAges([
			...Array(maxRooms || (roomsRestriction?.adults || 0) + (roomsRestriction?.children || 0))
				.fill(0)
				.map(() => []),
		]);
	}, [roomsRestriction, maxRooms]);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-hotels-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		let counterAcceptableRooms: number = 0;
		const firstIndex: number = 0;
		const isRoomsDataOk: boolean = !!(history?.rooms?.length && roomsNames.length && history.rooms.length <= roomsNames.length);
		let tempTotalAdults: number = 0;
		let tempTotalChildren: number = 0;
		const tempRoomsChildrenAges: number[][] = [];
		if (isRoomsDataOk) {
			history?.rooms.forEach((room: RoomsHotelsDisneySearchFormSubmitInterface, index: number): void => {
				const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomsNames[index].name);
				const tempAdults: number = typeof room?.adults === 'number' ? room.adults : defaultAdults;
				const tempChildren: number = typeof room?.children === 'number' ? room.children : defaultChildren;
				const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
					tempAdults,
					tempChildren,
					0,
					traductions('adult') || 'adult',
					traductions('adults') || 'adults',
					traductions('child') || 'child',
					traductions('children') || 'children',
					'',
					'',
				);

				setValue(tempPassengersNames.adultsName, tempAdults);

				setValue(tempPassengersNames.childrenName, tempChildren);

				setValue(tempPassengersNames.name, passengersDisplayValue || room?.display || '');

				let tempChildrenAges: number[] = room?.childrenAges?.length ? [...room.childrenAges] : Array(tempChildren).fill(0);
				if (tempChildrenAges.length < tempChildren) {
					tempChildrenAges = tempChildrenAges.concat(Array(tempChildren).fill(0));
				} else {
					tempChildrenAges.splice(tempChildren);
				}

				tempRoomsChildrenAges.push([...tempChildrenAges]);

				tempTotalAdults += tempAdults;

				tempTotalChildren += tempChildren;

				counterAcceptableRooms++;
			});
		} else {
			const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(
				roomsNames?.length ? roomsNames[firstIndex].name : '',
			);

			const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
				defaultAdults,
				defaultChildren,
				0,
				traductions('adult') || 'adult',
				traductions('adults') || 'adults',
				traductions('child') || 'child',
				traductions('children') || 'children',
				'',
				'',
			);

			setValue(tempPassengersNames.adultsName, defaultAdults);

			setValue(tempPassengersNames.childrenName, defaultChildren);

			setValue(tempPassengersNames.name, passengersDisplayValue || '');

			tempRoomsChildrenAges.push([...Array(defaultChildren).fill(0)]);

			tempTotalAdults += defaultAdults;

			tempTotalChildren += defaultChildren;

			counterAcceptableRooms++;
		}

		setRoomsNumber(!counterAcceptableRooms ? minRoomsNumber : counterAcceptableRooms);

		setTotalAdults(tempTotalAdults || defaultAdults);

		setTotalChildren(tempTotalChildren || defaultChildren);

		setChildrenAges([...tempRoomsChildrenAges]);

		setValue(datesName, history?.dates || '');

		setValue(tempDatesNames.startDate, history?.departureDate || '');

		setValue(tempDatesNames.endDate, history?.arrivalDate || '');

		const destinationValue: string = history?.destination || traductions('allParks') || 'allParks';
		setValue(destinationName, destinationValue);

		const departureDate: string = history?.departureDate || '';
		const arrivalDate: string = history?.arrivalDate || '';
		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.startEndDates,
			{
				date: true,
				time: false,
			},
			departureDate,
			'',
			arrivalDate,
			'',
		);

		setButtonSearchHistory({
			departure: HotelProvider.disney,
			arrival: '',
			departureDate: datesValues.largeStartDate || '',
			arrivalDate: datesValues.largeEndDate || '',
		});

		const tempStartDate: Date = new Date(`${departureDate}T00:00:00`);
		const tempEndDate: Date = new Date(`${arrivalDate}T00:00:00`);
		setDefaultDates(
			tempStartDate >= minDate && tempEndDate >= minDate
				? {
					startDate: tempStartDate,
					endDate: tempEndDate,
				  }
				: {
					startDate: minDate,
					endDate: minDate,
				  },
		);
	}, [history, minDate, roomsNames, traductions]);

	return {
		datesName,
		datesHiddenName,
		roomsNames,
		destinationId,
		datesId,
		destinationName,
		mobileFieldStep: mobileStep,
		buttonSearchHistory,
		minDate,
		defaultDates,
		roomsNumber,
		showMobileFieldsContainer: showContainer,
		childrenAges,
		mobileFormHeight,
		errors,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		roomsFieldNumberHandler,
		roomsModalInputsHandler,
		childrenAgesHandler,
		onSubmit,
	};
};

export default useHotelsDisneySearchForm;
