import { differenceBetweenDates, removeZuluTime } from '../../../../../../shared/services/utils.service';
import { FlightConnectivityType } from '../../../../../../shared/enums/flight-connectivity-type';
import { formatDate } from '../../../../../../shared/services/dates-managment.service';
import { Legs } from '../../../../../../shared/interfaces/flight-paths.interface';
import { DateFormat } from '../../../../../../shared/enums/date.enum';
import styles from './flight-path-detail.module.scss';

export interface FlightPathDetailProps {
	titleFlight: string;
	titleTrain: string;
	legs: Legs[];
	flightDurationLabel?: string;
	waitTimeLabel?: string;
}

export function FlightPathDetail(props: FlightPathDetailProps): JSX.Element {
	const waitTimeDefaultValue: string = '00:00:00';
	const renderIcons = (index: number, iconValidation: string): JSX.Element => {
		const separatorLine: JSX.Element = (
			<div className='left-[2px] h-[100%] absolute top-[32px] flex flex-col items-center'>
				<div className='bg-black w-[1px] h-[70px]'></div>
				<div className='h-[7px] w-[7px] rounded-full border-black border-[1px]'></div>
				<div className='bg-black w-[1px] h-[70px]'></div>
			</div>
		);

		const pinIconSection: JSX.Element = <span className={'pinIco text-base flightPathDetail__iconPoint'}></span>;
		if (index < props.legs.length - 1) {
			return (
				<div className={'mr-4 mt-5 relative'}>
					<span className={`${iconValidation === 'TRAIN' ? 'iconTrain' : 'smallAirplaneIco'} text-base flightPathDetail__iconMain`}></span>
					{separatorLine}
				</div>
			);
		}

		return <div className={'mr-4 mt-5'}>{pinIconSection}</div>;
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={
					'container w-full bg-white shadow p-6 pr-12 border-t-[1px] border-b-[1px] border-black flightPathDetail flightPathDetail__textMain'
				}
			>
				{props.legs.map((item: Legs, index: number) => {
					const arrivalDate: string = removeZuluTime(item.arrivalDate);
					const departureDate: string = removeZuluTime(item.departureDate);
					const validationArrivalDate: string = formatDate(arrivalDate, DateFormat.standarStringDate);
					const validationDepartureDate: string = formatDate(departureDate, DateFormat.standarStringDate);
					return (
						<section className={'w-full mb-4 flex'} key={index}>
							{renderIcons(index, item.airplaneName || '')}
							<div className={'w-full'}>
								<h3 className={'text-right text-xs font-family-bold mb-1 flightPathDetail__textSecundary'}>
									{`${item.airplaneName === FlightConnectivityType.train ? props.titleTrain.toUpperCase() : props.titleFlight.toUpperCase()} ${
										item.operatingCarrier?.code
									} ${item.flightNumber} ${item.airplaneName === FlightConnectivityType.train ? '' : `- ${item.airplaneName || ''}`}`}
								</h3>
								<div className={'w-full flex mb-2'}>
									<p className={'w-1/2 text-left text-xs font-family-regular'}>
										{`${item.departureCity?.cityName} (${item.departureCity?.airportCode})`}
									</p>
									<p className={'w-1/2 text-right text-xs font-family-regular '}>
										{item.arrivalCity?.cityName} ({item.arrivalCity?.airportCode})
									</p>
								</div>
								<div className={'w-full flex mb-1 justify-between'}>
									<p className={'text-left font-family-regular pr-4 text-[10px]'}>{item.departureCity.airport}</p>
									<div className={'w-[60%] mt-[8px] border-dashed border-t-[2px] flightPathDetail__borderColor'} />
									<p className={'text-right font-family-regular pl-4 text-[10px]'}>{item.arrivalCity.airport}</p>
								</div>
								<div className={'w-full flex mb-1'}>
									<p className={'w-1/2 text-left font-family-regular text-[10px]'}>{formatDate(item.departureTime, DateFormat.timeTime)}</p>
									<p className={'w-1/2 text-right font-family-regular text-[10px]'}>{formatDate(item.arrivalTime, DateFormat.timeTime)}</p>
								</div>
								<div className={'w-full flex mb-1'}>
									<h2 className={'w-1/2 text-left font-family-regular text-[10px]'}>{formatDate(departureDate, DateFormat.largeDate)}</h2>
									<h2
										className={`flex justify-end items-center w-1/2 font-family-regular text-[10px] ${
											validationArrivalDate !== validationDepartureDate ? 'text-red-500' : ''
										}`}
									>
										{validationArrivalDate !== validationDepartureDate && (
											<p className={'mr-1 text-[10px] '}>{`+ ${differenceBetweenDates(validationDepartureDate, validationArrivalDate)}`}</p>
										)}
										{formatDate(arrivalDate, DateFormat.largeDate)}
									</h2>
								</div>
								<div
									className={`mt-[30px] w-full p-[6px] flex justify-center items-center rounded-lg border-[1px] border-[#878787] text-[#878787] font-family-bold text-sm ${
										item.waitTime === waitTimeDefaultValue ? '' : 'border-dashed'
									}`}
								>
									{item.technicalStop ? <span className='iconGas px-1'></span> : item.trainStation ? <span className='iconMen px-1'></span> : ''}
									{!item.technicalStop && item.waitTime === waitTimeDefaultValue ? (
										<p className={'text-xs'}>{`${props.flightDurationLabel || ''} : ${formatDate(
											item.flightDuration,
											DateFormat.hoursMinutesTime,
										)}`}</p>
									) : (
										<p className={'text-xs'}>{`${props.waitTimeLabel || ''} : ${formatDate(item.waitTime, DateFormat.hoursMinutesTime)}`}</p>
									)}
								</div>
							</div>
						</section>
					);
				})}
			</div>
		</>
	);
}

export default FlightPathDetail;
