import { AlertStates } from '../enums/alert.enum';
import { AlertStateInfo } from '../interfaces/alert.interface';

export const getAlertStateInfo = (state: AlertStates): AlertStateInfo => {
	switch (state) {
		case AlertStates.ok:
			return {
				state: AlertStates.ok,
				icon: 'correctStateIco',
				color: '#5BB55E',
			};
		case AlertStates.question:
			return {
				state: AlertStates.question,
				icon: 'questionStateIco',
				color: '#FFC43C',
			};
		case AlertStates.warning:
			return {
				state: AlertStates.warning,
				icon: 'exclamationStateIco',
				color: '#FF913C',
			};
		case AlertStates.error:
			return {
				state: AlertStates.error,
				icon: 'errorStateIco',
				color: '#D2413E',
			};
		default:
			return {
				state: AlertStates.unknown,
				icon: '',
				color: '',
			};
	}
};
