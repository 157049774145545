export enum InputType {
	number = 'number',
	text = 'text',
	email = 'email',
	tel = 'tel',
}

export enum FormsInputsType {
	number = 'number',
	text = 'text',
	select = 'select',
	radio = 'radio',
	datePicker = 'date-picker',
	dateRangePicker = 'date-range-picker',
	timePicker = 'time-picker',
	timeRangePicker = 'time-range-picker',
	dateTimeRangePicker = 'date-time-range-picker',
	range = 'range',
	checkbox = 'checkbox',
	counter = 'counter',
}

export enum DatePickerKeys {
	selection = 'selection',
}

export enum DatePickerDirections {
	horizontal = 'horizontal',
	vertical = 'vertical',
}

export enum DatePickerInputs {
	startDate = 'start-date',
	startTime = 'start-time',
	endDate = 'end-date',
	endTime = 'end-time',
	dateRange = 'date-range',
	timeRange = 'time-range',
	datePicker = 'date-picker',
}

export enum validateInputType {
	noCondition = 'none',
	onlyLetters = 'onlyLetters',
	onlyNumber = 'onlyNumber',
}
