import { CarsRecommendationsParams, CarRecommendationPayload } from '../../shared/interfaces/car-recommendation-payload.interface';
import CarRecommendations from '../../templates/car-recommendations/car-recommendations';
import { WidgetCommonInterface } from '../../shared/interfaces/commons.interface';
import { CarRecommendation } from '../../shared/interfaces/car-recommendation';
import ErrorTemplate from '../../templates/common/error/errorTemplate';
import { WidgetNamesEnum } from '../../shared/enums/widget-names.enum';
import useCommonWidget from '../../shared/hooks/useCommonWidget';
import { CarService } from '../../shared/services/cars.service';
import { Suspense, useState, useEffect, useMemo } from 'react';
import { CarUtil } from '../../shared/utils/car-search.util';
import styles from 'bundle-text:./car-recommendations.scss';
import Loader from '../../templates/common/loader/loader';
import { CommonsUtil } from '../../shared/utils/commons';
import ReactDOM from 'react-dom';

export interface carRecommendationProps extends WidgetCommonInterface, CarsRecommendationsParams {
	useEquivalentCurrency?: boolean;
	onlyDailyPrice?: boolean;
}

const AppCarRecommendationWidget = ({
	token,
	language,
	agency,
	pickupLocationCode,
	dropOffLocationCode,
	pickUpDate,
	pickUpTime,
	dropOffDate,
	dropOffTime,
	passengersQuantity,
	quantityOfRecommendations,
	rewardsPoints,
	corporateDiscount,
	promotionalDiscount,
	paymentType,
	useEquivalentCurrency,
	coverage,
	onlyDailyPrice,
}: carRecommendationProps): JSX.Element => {
	const widgetName: WidgetNamesEnum = WidgetNamesEnum.carRecommendationWidget;
	const [recommendations, setRecommendations] = useState<CarRecommendation[]>([]);
	const [isRecommendationsLoading, setIsRecommendationsLoading] = useState<boolean>(true);
	const [recommendationsError, setRecommendationsError] = useState<boolean>(false);
	const {
		agencyState,
		isLoading,
		agencyError,
		widgetService: carsService,
		widgetStyles,
		t,
	} = useCommonWidget({
		token,
		language,
		agency,
		className: widgetName,
		styles,
		WidgetServiceClass: CarService,
	});

	const mapSearch: CarRecommendationPayload = useMemo(
		() =>
			((): CarRecommendationPayload => {
				const params: CarsRecommendationsParams = {
					pickupLocationCode,
					dropOffLocationCode,
					pickUpDate,
					pickUpTime,
					dropOffDate,
					dropOffTime,
					passengersQuantity,
					quantityOfRecommendations,
					rewardsPoints,
					corporateDiscount,
					promotionalDiscount,
					paymentType,
					coverage,
				};

				return CarUtil.mapCarSearch(params, widgetName);
			})(),
		[
			pickupLocationCode,
			dropOffLocationCode,
			pickUpDate,
			pickUpTime,
			dropOffDate,
			dropOffTime,
			passengersQuantity,
			quantityOfRecommendations,
			rewardsPoints,
			corporateDiscount,
			promotionalDiscount,
			paymentType,
			coverage,
		],
	);

	useEffect(() => {
		if (!isLoading && !(agencyState == null) && !(Object.keys(agencyState).length === 0) && mapSearch && !(Object.keys(mapSearch).length === 0)) {
			setIsRecommendationsLoading(true);

			((): void => {
				try {
					void (async () => {
						const getRecommendations: CarRecommendation[] | [] = await carsService.getRecommendations(mapSearch);
						setRecommendations([...getRecommendations]);

						setIsRecommendationsLoading(false);
					})();

					return;
				} catch (error) {
					setRecommendationsError(true);

					console.error(error);
				}
				setIsRecommendationsLoading(false);

				setRecommendations([]);
			})();
		}

		if (agencyError) {
			setIsRecommendationsLoading(false);

			setRecommendations([]);
		}
	}, [isLoading, mapSearch]);

	if (isLoading || isRecommendationsLoading) {
		return <Loader />;
	}

	if (agencyError || recommendationsError) {
		return (
			<Suspense fallback={<div>...</div>}>
				<ErrorTemplate title={'Error'} description={t('couldNotGetAgency')} />
			</Suspense>
		);
	}

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div className={`${agencyState?.profile?.name?.replace(/\s/g, '') || ''} ${widgetName}`}>
				<CarRecommendations
					agency={agencyState}
					carList={recommendations}
					carSearch={mapSearch}
					useEquivalentCurrency={useEquivalentCurrency}
					onlyDailyPrice={!!onlyDailyPrice || onlyDailyPrice === undefined}
					carsService={carsService}
					t={t}
				/>
			</div>
		</Suspense>
	);
};

export function CarRecommendationWidget(props: carRecommendationProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className={`reactWidget carRecommendation`}>
					<AppCarRecommendationWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
