export enum AlertStates {
	ok = 'ok',
	question = 'question',
	warning = 'warning',
	error = 'error',
	unknown = 'unknown',
}

export enum AlertEventResponses {
	ok = 'ok',
	cancel = 'cancel',
	void = 'void',
}
