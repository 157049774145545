import { TableData, RowData } from '../interfaces/table';

export const headMockVoid: RowData[] = [
	{ value: <div className='rounded-md'></div>, label: '' },
	{ value: <div className='rounded-md'></div>, label: '' },
	{ value: <div className='rounded-md'></div>, label: '' },
	{ value: <div className='rounded-md'></div>, label: '' },
	{ value: <div className='rounded-md'></div>, label: '' },
	{ value: <div className='rounded-md'></div>, label: '' },
	{ value: <div className='rounded-md'></div>, label: '' },
];

export const bodyMockVoid: RowData[][] = [
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
];

export const bodyArrivalMockVoid: RowData[][] = [
	[
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
		{
			value: <div className='rounded-md'></div>,
			label: '',
		},
	],
];

export const DataVoidSkeletonMock: TableData = {
	head: headMockVoid,
	rows: bodyMockVoid,
};
export const DataVoidSkeletonSingleTripMock: TableData = {
	head: headMockVoid,
	rows: bodyArrivalMockVoid,
};
