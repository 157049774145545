import { HistoricalDetailData, HistoricalDetailProductDisney } from '../../../../shared/interfaces';
import HistoricalPassengersQuantityDetail from './historical-passanger-quantity';
import HistoricalItemInfo from './historical-item-info';
import { translateDay } from '../../../../shared/utils';

export interface HistoricalProductDisneyProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	historicalDetail: HistoricalDetailData<HistoricalDetailProductDisney>;
}

export function HistoricalProductDisney({ t, colorPrimary, colorSecondary, historicalDetail }: HistoricalProductDisneyProps): JSX.Element {
	return (
		<>
			<div className='historical-detail__split my-8' />
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__activity'
				isIcon={true}
				classIcon='iconResumen'
				title='inputType'
				value={historicalDetail.detail.ticketType}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__activity'
				isIcon={true}
				classIcon='iconResumen'
				title='numberOfDays'
				value={`${historicalDetail.detail.days} ${translateDay(t, historicalDetail.detail.days)}`}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__duration'
				isIcon={true}
				classIcon='calendarBlankIco'
				title='arrivalDate'
				value={historicalDetail.detail.dateFormated}
			/>

			<div className='historical-detail__split my-3' />
			<HistoricalPassengersQuantityDetail
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				historicalPassengersQuantity={historicalDetail.passengerQuantity}
			/>
		</>
	);
}

export default HistoricalProductDisney;
