import { ScrollHandlerType } from '../enums';

export class ScrollHandlers {
	private scrollCurrentPosition: number;
	constructor() {
		this.scrollCurrentPosition = 0;
	}

	public ScrollManualMovement(current: HTMLDivElement, type: string, childClassContainer: string): { type?: string; position?: number } {
		const container: HTMLDivElement = current.querySelector(childClassContainer) as HTMLDivElement;
		let scrollContainerWidth: number = 0;
		const scrollTwoCardsLimit: number = 300;
		(Array.from(container.children) as HTMLDivElement[]).forEach((item: HTMLDivElement) => {
			scrollContainerWidth = item.offsetWidth + scrollContainerWidth;
		});

		if (!scrollContainerWidth) {
			scrollContainerWidth = container?.offsetWidth;
		}

		if (type === ScrollHandlerType.left) {
			const currentPosition: number = this.scrollCurrentPosition - scrollTwoCardsLimit < 0 ? 0 : this.scrollCurrentPosition - scrollTwoCardsLimit;
			current.scrollLeft = currentPosition;

			this.scrollCurrentPosition = currentPosition;
		}

		if (type === ScrollHandlerType.right) {
			const currentPosition: number =
				this.scrollCurrentPosition + scrollTwoCardsLimit >= scrollContainerWidth
					? scrollContainerWidth
					: this.scrollCurrentPosition + scrollTwoCardsLimit;

			current.scrollLeft = currentPosition;

			this.scrollCurrentPosition = currentPosition;
		}

		return {
			type,
			position: this.scrollCurrentPosition,
		};
	}
}
