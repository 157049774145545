export enum DateFormat {
	url = 'url-format',
	smallDate = 'small-format',
	largeDate = 'large-format',
	fullDate = 'full-format',
	fullDateTwentyFour = 'full-format-24-hours',
	timeDate = 'time-format',
	timeTime = 'time-time-time',
	hoursMinutesTime = 'hours-minutes-time-format',
	weekdaySingle = 'short-weekday',
	dayMonth = 'digitDay-shortMonth',
	daySingle = 'digit-day',
	monthYear = 'shortMonth-numericYear',
	standarStringDate = 'YYYY-MM-DD',
	birthdayDate = 'birthday',
	completeDate = 'complete-format',
	weekdayDayMonth = 'weekday-day-month-format',
}

export enum DateLocales {
	ar = 'ar',
	bg = 'bg',
	ca = 'ca',
	cs = 'cs',
	cy = 'cy',
	da = 'da',
	de = 'de',
	el = 'el',
	enGB = 'enGB',
	enUS = 'enUS',
	eo = 'eo',
	es = 'es',
	et = 'et',
	faIR = 'faIR',
	fi = 'fi',
	fil = 'fil',
	fr = 'fr',
	hi = 'hi',
	hr = 'hr',
	hu = 'hu',
	hy = 'hy',
	id = 'id',
	is = 'is',
	it = 'it',
	ja = 'ja',
	ka = 'ka',
	ko = 'ko',
	lt = 'lt',
	lv = 'lv',
	mk = 'mk',
	nb = 'nb',
	nl = 'nl',
	pl = 'pl',
	pt = 'pt',
	ro = 'ro',
	ru = 'ru',
	sk = 'sk',
	sl = 'sl',
	sr = 'sr',
	sv = 'sv',
	th = 'th',
	tr = 'tr',
	uk = 'uk',
	vi = 'vi',
	zhCN = 'zhCN',
	zhTW = 'zhTW',
}
