import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { InputEventsHandler, DatePickerData } from '../../../../shared/interfaces/inputs.interface';
import { SearchFieldsNames, DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { AssistancesSearchFormInputsNames } from '../enums/assistances-search-fields.enum';
import { formatDate } from '../../../../shared/services/dates-managment.service';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { DateFormat } from '../../../../shared/enums/date.enum';
import {
	UseAssistancesSearchFormInterface,
	UseAssistancesSearchFormReturnInterface,
	AssistancesSearchFormSubmitInterface,
} from '../interfaces/assistances-search-form.interface';
import {
	FieldButtonEventHandlerInterface,
	DisplayValuesDatesFieldInterface,
	DatesFieldInputNamesInterface,
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

const useAssistancesSearchForm = ({
	history,
	calendarRestriction,
	widgetStyles,
	traductions,
	emitSubmitSearch,
	emitShowMobileFields,
}: UseAssistancesSearchFormInterface): UseAssistancesSearchFormReturnInterface => {
	const withPromoCodeName: string = AssistancesSearchFormInputsNames.withPromoCode;
	const destinationHiddenName: string = AssistancesSearchFormInputsNames.codeDestination;
	const annualCoverageName: string = AssistancesSearchFormInputsNames.annualCoverage;
	const destinationName: string = AssistancesSearchFormInputsNames.destination;
	const datesHiddenName: string = AssistancesSearchFormInputsNames.hiddenDates;
	const passengersName: string = AssistancesSearchFormInputsNames.passengers;
	const destinationId: SearchFieldsNames = SearchFieldsNames.destination;
	const passengersId: SearchFieldsNames = SearchFieldsNames.passengers;
	const datesName: string = AssistancesSearchFormInputsNames.dates;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const minChildrenAdults: number = 1;
	const minOlderAdults: number = 0;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm();

	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(destinationId);
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
		endDate: new Date(),
	});

	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [minDate, setMinDate] = useState<Date>(new Date());
	const mobileFormHeight: number = useWindowHeight();
	const [promoCode, setPromoCode] = useState<string>('');
	const destinationFieldHandler = useCallback((element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
		// TODO
	}, []);

	const showMobileFieldsContainerHandler = useCallback((show: boolean): void => {
		setShowContainer(show);

		setMobileStep(destinationId);
	}, []);

	const datesHandler = useCallback((event: InputEventsHandler): void => {
		const getData: DatePickerData = event.value as DatePickerData;
		const annualCoverageCheck: boolean = false;
		setDefaultDates({
			startDate: getData.startDate,
			endDate: getData.endDate,
		});

		setValue(annualCoverageName, annualCoverageCheck);
	}, []);

	const passengersHandler = useCallback(
		(passengersFieldInputs: PassengersFieldDataInterface, name: string): void | string => {
			const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
				passengersFieldInputs.adults,
				passengersFieldInputs.children,
				0,
				traductions('childOrAdult') || 'childOrAdult',
				traductions('childrenOrAdults') || 'childrenOrAdults',
				traductions('olderAdult') || 'olderAdult',
				traductions('olderAdults') || 'olderAdults',
				'',
				'',
			);

			return passengersDisplayValue;
		},
		[traductions],
	);

	const promoCodeHandler = useCallback((event: InputEventsHandler): void => {
		const value: string = String(event?.value);
		setPromoCode(value);
	}, []);

	const withPromoCodeHandler = useCallback((element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
		setPromoCode('');
	}, []);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(passengersName);
			const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
			const results: AssistancesSearchFormSubmitInterface = {
				passengers: {
					childrenAdults: data[tempPassengersNames.adultsName] || 0,
					olderAdults: data[tempPassengersNames.childrenName] || 0,
					display: data[tempPassengersNames.name] || '',
				},
				dates: data[datesName] || '',
				departureDate: data[tempDatesNames.startDate] || '',
				arrivalDate: data[tempDatesNames.endDate] || '',
				destination: data[destinationName] || '',
				destinationCode: data[destinationHiddenName] || '',
				annualCoverage: !!data[annualCoverageName],
				withPromoCode: !!data[withPromoCodeName],
				promoCode,
				urlPath: undefined,
			};

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.startEndDates,
				{
					date: true,
					time: false,
				},
				results.departureDate,
				'',
				results.arrivalDate,
				'',
			);

			setButtonSearchHistory({
				departure: results.destination,
				arrival: '',
				departureDate: datesValues.largeStartDate || '',
				arrivalDate: datesValues.largeEndDate || '',
			});

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[emitSubmitSearch, promoCode],
	);

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (event.id === destinationId) {
				setMobileStep(datesId);

				return;
			}

			if (event.id === datesId) {
				setMobileStep(passengersId);

				return;
			}

			if (event.id === passengersId) {
				void (async () => {
					const delayTime: number = 500;
					setTimeout(() => {
						void onSubmit();

						setShowContainer(false);

						setMobileStep(destinationId);
					}, delayTime);
				})();
			}
		},
		[onSubmit],
	);

	const backMobileFieldHandler = useCallback((event: FieldButtonEventHandlerInterface): void => {
		if (event.id === datesId) {
			setMobileStep(destinationId);

			return;
		}

		if (event.id === passengersId) {
			setMobileStep(datesId);
		}
	}, []);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-assistances-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		setMobileStep(destinationId);

		const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(passengersName);
		const tempChildrenAdults: number =
			typeof history?.passengers?.childrenAdults === 'number' ? history?.passengers?.childrenAdults : minChildrenAdults;

		setValue(tempPassengersNames.adultsName, tempChildrenAdults);

		const tempOlderAdults: number = typeof history?.passengers?.olderAdults === 'number' ? history?.passengers?.olderAdults : minOlderAdults;
		setValue(tempPassengersNames.childrenName, tempOlderAdults);

		const tempPassengersDisplay: string = SearchFieldsUtil.getDisplayPassengersInfo(
			tempChildrenAdults,
			tempOlderAdults,
			0,
			traductions('childOrAdult') || 'childOrAdult',
			traductions('childrenOrAdults') || 'childrenOrAdults',
			traductions('olderAdult') || 'olderAdult',
			traductions('olderAdults') || 'olderAdults',
			'',
			'',
		);

		setValue(tempPassengersNames.name, tempPassengersDisplay || history?.passengers?.display || '');

		setValue(withPromoCodeName, !!history?.withPromoCode);

		const tempPromoCode: string = history?.promoCode ?? '';
		setPromoCode(tempPromoCode);

		setValue(annualCoverageName, !!history?.annualCoverage);

		setValue(destinationName, history?.destination || '');

		setValue(destinationHiddenName, history?.destinationCode || '');

		let tempDepartureDate: string = history?.departureDate ? history.departureDate : '';
		let tempArrivalDate: string = history?.arrivalDate ? history.arrivalDate : '';
		let tempStartDate: Date = new Date(`${tempDepartureDate}T00:00:00`);
		let tempEndDate: Date = new Date(`${tempArrivalDate}T00:00:00`);
		const validDate: boolean =
			!!history?.destinationCode &&
			!!tempDepartureDate &&
			!!tempArrivalDate &&
			tempStartDate >= minDate &&
			tempEndDate >= minDate &&
			tempEndDate >= tempStartDate;

		if (!validDate) {
			tempDepartureDate = '';

			tempArrivalDate = '';

			tempStartDate = new Date('T00:00:00');

			tempEndDate = new Date('T00:00:00');
		}

		setValue(tempDatesNames.startDate, tempDepartureDate);

		setValue(tempDatesNames.endDate, tempArrivalDate);

		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.startEndDates,
			{
				date: true,
				time: false,
			},
			tempDepartureDate,
			'',
			tempArrivalDate,
			'',
		);

		setValue(datesName, validDate ? datesValues.dates : '');

		setButtonSearchHistory(
			history?.destinationCode
				? {
					departure: history?.destinationCode || '',
					arrival: '',
					departureDate: datesValues.largeStartDate || '',
					arrivalDate: datesValues.largeEndDate || '',
				  }
				: undefined,
		);

		setDefaultDates(
			validDate
				? {
					startDate: tempStartDate,
					endDate: tempEndDate,
				  }
				: {
					startDate: minDate,
					endDate: minDate,
				  },
		);
	}, [history, minDate, traductions]);

	const calculateAnnualCoverage = useCallback((element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		if (!element.target.checked) {
			setValue(tempDatesNames.endDate, '');

			setValue(tempDatesNames.startDate, '');

			setValue(datesName, '');

			return;
		}

		const daysOfYear: number = 364;
		const time: string = 'T00:00:00';
		const getStartDate: string = getValues(tempDatesNames.startDate);
		const startDate: Date = getValues(tempDatesNames.startDate) ? new Date(`${getStartDate}${time}`) : new Date();
		const endDate: Date = new Date(startDate);
		endDate.setDate(startDate.getDate() + daysOfYear);

		const endDateformat: string = formatDate(endDate.toISOString(), DateFormat.standarStringDate);
		setValue(tempDatesNames.endDate, endDateformat);

		const startDateformat: string = formatDate(startDate.toISOString(), DateFormat.standarStringDate);
		setValue(tempDatesNames.startDate, startDateformat);

		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.startEndDates,
			{
				date: true,
				time: false,
			},
			startDate,
			'',
			endDate,
			'',
			widgetStyles?.isTertiaryTemplate,
		);

		setValue(datesName, datesValues.dates);

		setDefaultDates({
			startDate,
			endDate,
		});
	}, []);

	return {
		destinationName,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		passengersName,
		destinationId,
		datesId,
		passengersId,
		withPromoCodeName,
		annualCoverageName,
		mobileFieldStep: mobileStep,
		buttonSearchHistory,
		minDate,
		promoCode,
		defaultDates,
		showMobileFieldsContainer: showContainer,
		mobileFormHeight,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		destinationFieldHandler,
		datesHandler,
		promoCodeHandler,
		withPromoCodeHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		passengersHandler,
		onSubmit,
		calculateAnnualCoverage,
	};
};

export default useAssistancesSearchForm;
