import { CarDestination, RentalCarCompaniesCode } from '../../../../shared/interfaces/car-destination.interface';
import { ListInterface, ListItemInterface } from '../../../../../../shared/interfaces';
import { RentalCarsLogosV2 } from '../../../../shared/enums/filter-cars-cards-v2.enum';
import { CarSearchTraductions } from '../../../../../cars/shared/interfaces';
import { Button, IcomoonIcon, List } from '../../../../../ui-elements';
import { MapUtil } from '../../../../../../shared/utils/map.util';
import Toggle from '../../../../../ui-elements/toggle/toggle';
import styles from './car-destinations.module.scss';
import { useEffect, useState } from 'react';
import { StaticMap } from '../../../maps';

export interface CarDestinationsProps {
	colorPrimary?: string;
	colorSecondary?: string;
	style?: any;
	destinations: ListInterface[];
	otherDestinationFlag?: boolean;
	traductions?: CarSearchTraductions;
	mapBoxToken?: String;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	callBack?: (destintion: ListItemInterface) => void;
	callBackOpenLocalities?: () => void;
	callBackShowOtherArrivalPlace?: (checked: boolean) => void;
	hideDropOff?: boolean;
}

export function CarDestinations({
	mapBoxToken,
	colorPrimary,
	colorSecondary,
	otherDestinationFlag,
	callBackOpenLocalities,
	style,
	destinations,
	callBack,
	callBackShowOtherArrivalPlace,
	traductions,
	isSecondaryTemplate,
	isTertiaryTemplate,
	hideDropOff,
}: CarDestinationsProps): JSX.Element {
	const [showExtraDestinationInfo, setShowExtraDestinationInfo] = useState<boolean>(false);
	const [extraInfo, setExtraInfo] = useState<CarDestination[]>();
	const [activeOfficeId, setActiveOfficeId] = useState<string>('');
	const [selectedLngLat, setSelectedLngLat] = useState<{
		longitude: number;
		latitude: number;
		color: string;
	} | null>();

	const mapUtil: MapUtil = new MapUtil();
	useEffect(() => {
		setShowExtraDestinationInfo(false);
	}, [destinations]);

	const hoverExtendDestinationInfo = (item: ListItemInterface | null | undefined): void => {
		const delayTime: number = 200;
		const secondDelayTime: number = 100;
		if (item?.original) {
			setTimeout(() => {
				setActiveOfficeId(item.id);

				setExtraInfo(
					item.original.map(
						(extra: CarDestination): CarDestination => ({
							...extra,
							showExtraInfo: false,
						}),
					),
				);

				setShowExtraDestinationInfo(true);
			}, delayTime);
		} else {
			setTimeout(() => {
				setActiveOfficeId('');

				setShowExtraDestinationInfo(false);
			}, secondDelayTime);
		}
	};

	const emitSelectedDestination = (item: ListItemInterface): void => {
		if (callBack && !item?.original) {
			setActiveOfficeId('');

			callBack(item);
		}

		if (item?.original) {
			hoverExtendDestinationInfo(item);
		}
	};

	const emitCheckedOtherArrivalPlace = (checked: boolean): void => {
		if (callBackShowOtherArrivalPlace) {
			callBackShowOtherArrivalPlace(checked);
		}
	};

	const emitSelectedOffice = (office: CarDestination): void => {
		const mapOffice: ListItemInterface = {
			id: office.code,
			link: '',
			name: office.name,
			originalName: office.name,
			value: office.code,
			original: null,
		};

		emitSelectedDestination(mapOffice);

		setShowExtraDestinationInfo(false);

		setActiveOfficeId('');
	};

	const showExtraInfoElement = (item: CarDestination, index: number, extraInfo: CarDestination[]): void => {
		const extraData = [...extraInfo];
		extraData.forEach(extra => {
			if (extra.code === item.code) {
				extra.showExtraInfo = !extra.showExtraInfo;

				return;
			}

			extra.showExtraInfo = false;
		});

		const pinColor: string = mapUtil.getColorForMap(colorSecondary || '');
		if (item.longitude && item.latitude) {
			setSelectedLngLat({
				longitude: item.longitude,
				latitude: item.latitude,
				color: pinColor,
			});
		}

		setExtraInfo(extraData);
	};

	const closeExtraInfoElements = (): void => {
		setShowExtraDestinationInfo(false);

		setActiveOfficeId('');

		setSelectedLngLat(null);

		setExtraInfo((prevState: CarDestination[] | undefined): CarDestination[] | undefined => {
			return prevState?.length
				? [
					...prevState?.map(
						(extra: CarDestination): CarDestination => ({
							...extra,
							showExtraInfo: false,
						}),
					),
				  ]
				: [];
		});
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div
				className={`carDestinations w-full rounded-lg flex bg-white carDestinations__container 
					${isSecondaryTemplate ? 'px-4 md:px-0' : ''}
					${isTertiaryTemplate ? 'md:rounded-t-none md:border border-solid border-[#BEBEBE] ' : ''} 
					`}
				style={{
					width: isTertiaryTemplate ? '' : showExtraDestinationInfo ? '710px' : '325px',
					boxShadow: isTertiaryTemplate ? '0px 0px 15px 0px #0000001A' : '',
					...style,
				}}
			>
				<div
					className={`flex flex-col ${isSecondaryTemplate ? (destinations.length ? 'pt-3' : '') : 'pt-5'} ${
						showExtraDestinationInfo ? 'w-1/2' : 'w-full'
					}`}
				>
					<div
						className={`carDestinations__list ${
							isSecondaryTemplate && destinations.length ? 'border-b border-b-[#EAECF0] carDestination__custom-scrollbar' : ''
						}`}
					>
						<List
							withPointer={true}
							ulClassName='pt-3'
							itemsClassName='text-xs'
							liClassName={`text-xs pl-5 p-1 text-gray-400 
								${isSecondaryTemplate ? 'hover:bg-gray-50 rounded-md' : 'hover:bg-gray-200'}
								${isTertiaryTemplate ? '' : 'mb-1'}
							`}
							items={destinations}
							deactiveColor={'transparent'}
							activeColor={isSecondaryTemplate ? '#F9FAFB' : '#E5E7EB'}
							activeItemId={activeOfficeId}
							className={destinations?.length && isSecondaryTemplate ? 'carDestinations__listContainer' : ''}
							hoverCallBack={item => {
								// hoverExtendDestinationInfo(item);
							}}
							callBack={e => {
								emitSelectedDestination(e);
							}}
							secondaryColor={colorSecondary}
							truncate={isSecondaryTemplate}
							isSecondaryTemplate={isSecondaryTemplate}
							isTertiaryTemplate={isTertiaryTemplate}
						/>
					</div>
					<div
						className={`carDestinations__otherPlace 
						${isSecondaryTemplate ? 'md:px-[14px] md:py-4 isSecondaryTemplate pt-4' : 'px-5 py-5'}
						${isTertiaryTemplate ? 'border-t border-t-[#E6E6E6]' : ''}
					`}
					>
						{callBackShowOtherArrivalPlace && hideDropOff === false && (
							<Toggle
								labelClassName={`${isSecondaryTemplate ? '' : 'text-gray-500 mb-0'}`}
								colorPrimary={colorSecondary}
								label={`${traductions?.destinationList?.title || 'otherPlace'}`}
								checked={otherDestinationFlag}
								emitCheckedElement={({ checked }) => emitCheckedOtherArrivalPlace(checked)}
								normalSize={isSecondaryTemplate}
								className={isSecondaryTemplate ? 'mb-4' : ''}
							/>
						)}
						<p
							className={`searchByLocations leading-none cursor-pointer ${isSecondaryTemplate ? '' : 'mt-2'}`}
							onClick={() => {
								if (callBackOpenLocalities) {
									callBackOpenLocalities();
								}
							}}
							style={{ color: isTertiaryTemplate ? '#4D4D4F' : '#000000' }}
						>
							<span className={`${isSecondaryTemplate ? 'iconMarkerSecondary' : 'locationMapIco'} pr-2 carDestinations__otherPlace-ico`}></span>
							<span
								className={`${isSecondaryTemplate ? '' : 'underline underline-offset-2'} 
								${isTertiaryTemplate ? '!text-[14px]' : ''}
							`}
							>
								{traductions?.destinationList?.hint || 'searchByLocation'}
							</span>
						</p>
					</div>
				</div>

				{showExtraDestinationInfo && (
					<div
						className={`w-1/2  flex flex-col carDestinations__officeContainer max-w-[91vw] ${isSecondaryTemplate ? 'bg-white' : 'bg-gray-100'}`}
						onMouseOver={() => {
							const delayTime: number = 200;
							setTimeout(() => {
								// setShowExtraDestinationInfo(true) //TO-DO
							}, delayTime);
						}}
					>
						<div className={'w-full flex flex-row justify-end pr-[10px] pb-[2px] carDestinations__close'}>
							<span onClick={closeExtraInfoElements} className={'closeIco'}></span>
						</div>
						<div
							className={`carDestination__offices md:h-[290px] overflow-y-auto ${
								isSecondaryTemplate ? 'h-[50%] carDestination__custom-scrollbar' : 'h-[380px]'
							}`}
						>
							{extraInfo?.map((item: CarDestination, index: number) => (
								<div key={index} className='extraDestinationInfo text-xs'>
									<div>
										<p
											className={`carDestination__officesTitle ${
												item.showExtraInfo
													? isSecondaryTemplate
														? 'flex items-center justify-between rounded-sm bg-[#F9FAFB] selectedListOption'
														: 'bg-black text-white'
													: ''
											} ${isSecondaryTemplate ? 'px-[14px] py-[12px]' : 'border-b border-gray-200 px-[20px] py-[13px]'} mb-0  cursor-pointer`}
											onClick={e => {
												showExtraInfoElement(item, index, extraInfo);
											}}
										>
											<span>{item.name}</span>
											{item.showExtraInfo && isSecondaryTemplate && (
												<IcomoonIcon color={colorSecondary} icon='checkIco' className='pl-[5px]' iconClassName={'text-[15px] selectedOptionIcon '} />
											)}
										</p>
										{item.showExtraInfo && (
											<div>
												<div className={`carDestination__officesDescription bg-white ${isSecondaryTemplate ? 'pt-4 pl-4 pb-3' : ' p-[20px]'}`}>
													<div className='flex items-center'>
														{item.rentalCarsCompaniesCodes.map((rents: RentalCarCompaniesCode, rentIndex: number) => (
															<div key={rentIndex} className='text-[20px] mr-[4px]'>
																{RentalCarsLogosV2[rents?.rentalCarName?.toLocaleLowerCase()] &&
																	RentalCarsLogosV2[rents?.rentalCarName?.toLocaleLowerCase()].color}
															</div>
														))}
													</div>
													<p className='carDestination__officesAddres my-[10px] '>{item.address}</p>
													<Button
														styles={{ background: colorSecondary || '#FDD008' }}
														customClass='carDestination__officesAddres-selectBtn rounded-full text-black text-xs  py-1'
														onClick={() => emitSelectedOffice(item)}
														text={traductions?.selectText || 'select'}
													/>
												</div>

												{selectedLngLat && (
													<div className=''>
														<div className='bg-gray-400 h-full'>
															<StaticMap
																token={mapBoxToken as string}
																longitude={selectedLngLat.longitude}
																latitude={selectedLngLat.latitude}
																width={'347'}
																height={'130'}
																color={selectedLngLat.color}
																mobileAdjustToWindow={true}
																showInColor={isSecondaryTemplate ?? false}
															/>
														</div>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default CarDestinations;
