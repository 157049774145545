import { FlightSearchResult } from '../interfaces/flight-search-result.interface';

export const RecommendationMock: FlightSearchResult[] = [
	{
		id: 0,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '10:28:00',
			totalTimeOfFlightFormated: '10hh 28mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T10:17:00',
				time: '10:17:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '10:17 a.m.',
			},
			arrival: {
				date: '2022-10-13T19:45:00',
				time: '19:45:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:45 p.m.',
			},
			airline: {
				code: 'DL',
				name: 'Delta Air Lines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
			},
			fareSummary: {
				amount: 1433,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
				equivalentInCash: '',
			},
			legSummary: [
				{
					layoverName: 'Atlanta(ATL)',
					layoverTime: '12:11 p.m.',
					waitTime: '03hh 49mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '01:54:00',
				timeOfFlightFormated: '01hh 54mm',
				flightNumber: '1482',
				airplaneName: 'BOEING 757',
				waitTime: '03:49:00',
				waitTimeFormated: '03hh 49mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				departure: {
					date: '2022-10-13T10:17:00',
					time: '10:17:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '10:17 a.m.',
				},
				arrival: {
					date: '2022-10-13T12:11:00',
					time: '12:11:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '12:11 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
			{
				timeOfFlight: '03:45:00',
				timeOfFlightFormated: '03hh 45mm',
				flightNumber: '981',
				airplaneName: 'BOEING 767-300',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T16:00:00',
					time: '16:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '04:00 p.m.',
				},
				arrival: {
					date: '2022-10-13T19:45:00',
					time: '19:45:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:45 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASICECON',
				labelRangePrice: 'Business',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1433,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.433 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1396,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2290,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 16357,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINCABIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2429,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.429 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2392,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3286,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 23471,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFMAIN',
				brandName: 'REFUNDABLE MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3426,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.426 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3389,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 4283,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 30593,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'COMFORTPLU',
				brandName: 'BASIC ECONOMY COMFORTABLE NAME LARGE FOR EXAMPLE',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 6296,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $6.296 MXN',
				},
				fareDetails: [
					{
						baseAmount: 6259,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 7153,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 51093,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFCPLUS',
				brandName: 'REFUNDABLE DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8289,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.289 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8252,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 9146,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65329,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 1,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '12:40:00',
			totalTimeOfFlightFormated: '12hh 40mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T08:05:00',
				time: '08:05:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '08:05 a.m.',
			},
			arrival: {
				date: '2022-10-13T19:45:00',
				time: '19:45:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:45 p.m.',
			},
			airline: {
				code: 'DL',
				name: 'Delta Air Lines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
			},
			fareSummary: {
				amount: 1433,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
				equivalentInCash: '',
			},
			legSummary: [
				{
					layoverName: 'Atlanta(ATL)',
					layoverTime: '10:01 a.m.',
					waitTime: '05hh 59mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '01:56:00',
				timeOfFlightFormated: '01hh 56mm',
				flightNumber: '741',
				airplaneName: 'BOEING 757',
				waitTime: '05:59:00',
				waitTimeFormated: '05hh 59mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				departure: {
					date: '2022-10-13T08:05:00',
					time: '08:05:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '08:05 a.m.',
				},
				arrival: {
					date: '2022-10-13T10:01:00',
					time: '10:01:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '10:01 a.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
			{
				timeOfFlight: '03:45:00',
				timeOfFlightFormated: '03hh 45mm',
				flightNumber: '981',
				airplaneName: 'BOEING 767-300',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T16:00:00',
					time: '16:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '04:00 p.m.',
				},
				arrival: {
					date: '2022-10-13T19:45:00',
					time: '19:45:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:45 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASICECON',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1433,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.433 MXN',
					equivalentInCash: '',
				},
				fareDetails: [
					{
						baseAmount: 1396,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2290,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 16357,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINCABIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2429,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.429 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2392,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3286,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 23471,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFMAIN',
				brandName: 'REFUNDABLE MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3426,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.426 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3389,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 4283,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 30593,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'COMFORTPLU',
				brandName: 'DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 6296,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $6.296 MXN',
				},
				fareDetails: [
					{
						baseAmount: 6259,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 7153,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 51093,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFCPLUS',
				brandName: 'REFUNDABLE DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8289,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.289 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8252,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 9146,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65329,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 2,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '13:45:00',
			totalTimeOfFlightFormated: '13hh 45mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T07:00:00',
				time: '07:00:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:00 a.m.',
			},
			arrival: {
				date: '2022-10-13T19:45:00',
				time: '19:45:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:45 p.m.',
			},
			airline: {
				code: 'DL',
				name: 'Delta Air Lines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
			},
			fareSummary: {
				amount: 1433,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [
				{
					layoverName: 'Atlanta(ATL)',
					layoverTime: '09:00 a.m.',
					waitTime: '07hh 00mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '02:00:00',
				timeOfFlightFormated: '02hh 00mm',
				flightNumber: '2774',
				airplaneName: 'BOEING 737-900',
				waitTime: '07:00:00',
				waitTimeFormated: '07hh 00mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				departure: {
					date: '2022-10-13T07:00:00',
					time: '07:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:00 a.m.',
				},
				arrival: {
					date: '2022-10-13T09:00:00',
					time: '09:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '09:00 a.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
			{
				timeOfFlight: '03:45:00',
				timeOfFlightFormated: '03hh 45mm',
				flightNumber: '981',
				airplaneName: 'BOEING 767-300',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T16:00:00',
					time: '16:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '04:00 p.m.',
				},
				arrival: {
					date: '2022-10-13T19:45:00',
					time: '19:45:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:45 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASICECON',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1433,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.433 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1396,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2290,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 16357,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINCABIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2429,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.429 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2392,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3286,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 23471,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFMAIN',
				brandName: 'REFUNDABLE MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3426,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.426 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3389,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 4283,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 30593,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'COMFORTPLU',
				brandName: 'DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 6296,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $6.296 MXN',
				},
				fareDetails: [
					{
						baseAmount: 6259,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 7153,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 51093,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFCPLUS',
				brandName: 'REFUNDABLE DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8289,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.289 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8252,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 9146,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65329,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 3,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '14:45:00',
			totalTimeOfFlightFormated: '14hh 45mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T06:00:00',
				time: '06:00:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '06:00 a.m.',
			},
			arrival: {
				date: '2022-10-13T19:45:00',
				time: '19:45:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:45 p.m.',
			},
			airline: {
				code: 'DL',
				name: 'Delta Air Lines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
			},
			fareSummary: {
				amount: 1433,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [
				{
					layoverName: 'Atlanta(ATL)',
					layoverTime: '07:56 a.m.',
					waitTime: '08hh 04mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '01:56:00',
				timeOfFlightFormated: '01hh 56mm',
				flightNumber: '2938',
				airplaneName: 'BOEING 737-900',
				waitTime: '08:04:00',
				waitTimeFormated: '08hh 04mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				departure: {
					date: '2022-10-13T06:00:00',
					time: '06:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '06:00 a.m.',
				},
				arrival: {
					date: '2022-10-13T07:56:00',
					time: '07:56:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:56 a.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
			{
				timeOfFlight: '03:45:00',
				timeOfFlightFormated: '03hh 45mm',
				flightNumber: '981',
				airplaneName: 'BOEING 767-300',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T16:00:00',
					time: '16:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '04:00 p.m.',
				},
				arrival: {
					date: '2022-10-13T19:45:00',
					time: '19:45:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:45 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASICECON',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1433,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.433 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1396,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2290,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 16357,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINCABIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2429,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.429 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2392,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3286,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 23471,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFMAIN',
				brandName: 'REFUNDABLE MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3426,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.426 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3389,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 4283,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 30593,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'COMFORTPLU',
				brandName: 'DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 6296,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $6.296 MXN',
				},
				fareDetails: [
					{
						baseAmount: 6259,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 7153,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 51093,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFCPLUS',
				brandName: 'REFUNDABLE DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8289,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.289 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8252,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 9146,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65329,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 4,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '03:39:00',
			totalTimeOfFlightFormated: '03hh 39mm',
			stops: 'direct',
			departure: {
				date: '2022-10-13T19:46:00',
				time: '19:46:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:46 p.m.',
			},
			arrival: {
				date: '2022-10-13T22:25:00',
				time: '22:25:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '10:25 p.m.',
			},
			airline: {
				code: 'AA',
				name: 'American Airlines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
			},
			fareSummary: {
				amount: 1632,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [],
		},
		legs: [
			{
				timeOfFlight: '02:39:00',
				timeOfFlightFormated: '02hh 39mm',
				flightNumber: '915',
				airplaneName: 'AIRBUS 319',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T19:46:00',
					time: '19:46:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:46 p.m.',
				},
				arrival: {
					date: '2022-10-13T22:25:00',
					time: '22:25:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '10:25 p.m.',
				},
				operatingCarrier: {
					code: 'AA',
					name: 'American Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
				},
				marketingCarrier: {
					code: 'AA',
					name: 'American Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'B',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASIC',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1632,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.632 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1595,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2489,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 17779,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2628,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.628 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2591,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3485,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 24893,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINFL',
				brandName: 'MAIN CABIN FLEXIBLE',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3127,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.127 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3090,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3984,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 28457,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 5,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '03:40:00',
			totalTimeOfFlightFormated: '03hh 40mm',
			stops: 'direct',
			departure: {
				date: '2022-10-13T10:55:00',
				time: '10:55:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '10:55 a.m.',
			},
			arrival: {
				date: '2022-10-13T13:35:00',
				time: '13:35:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '01:35 p.m.',
			},
			airline: {
				code: 'AA',
				name: 'American Airlines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
			},
			fareSummary: {
				amount: 1632,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [],
		},
		legs: [
			{
				timeOfFlight: '02:40:00',
				timeOfFlightFormated: '02hh 40mm',
				flightNumber: '913',
				airplaneName: 'AIRBUS 319',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T10:55:00',
					time: '10:55:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '10:55 a.m.',
				},
				arrival: {
					date: '2022-10-13T13:35:00',
					time: '13:35:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '01:35 p.m.',
				},
				operatingCarrier: {
					code: 'AA',
					name: 'American Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
				},
				marketingCarrier: {
					code: 'AA',
					name: 'American Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'B',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASIC',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1632,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.632 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1595,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2489,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 17779,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2628,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.628 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2591,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3485,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 24893,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINFL',
				brandName: 'MAIN CABIN FLEXIBLE',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3127,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.127 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3090,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3984,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 28457,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 6,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '03:46:00',
			totalTimeOfFlightFormated: '03hh 46mm',
			stops: 'direct',
			departure: {
				date: '2022-10-13T21:34:00',
				time: '21:34:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '09:34 p.m.',
			},
			arrival: {
				date: '2022-10-14T00:20:00',
				time: '00:20:00',
				dateFormated: '14 oct. 2022',
				timeFormated: '12:20 a.m.',
			},
			airline: {
				code: 'AA',
				name: 'American Airlines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
			},
			fareSummary: {
				amount: 1632,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [],
		},
		legs: [
			{
				timeOfFlight: '02:46:00',
				timeOfFlightFormated: '02hh 46mm',
				flightNumber: '1137',
				airplaneName: 'AIRBUS 319',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T21:34:00',
					time: '21:34:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '09:34 p.m.',
				},
				arrival: {
					date: '2022-10-14T00:20:00',
					time: '00:20:00',
					dateFormated: '14 oct. 2022',
					timeFormated: '12:20 a.m.',
				},
				operatingCarrier: {
					code: 'AA',
					name: 'American Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
				},
				marketingCarrier: {
					code: 'AA',
					name: 'American Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-AA.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'B',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASIC',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1632,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.632 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1595,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2489,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 17779,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2628,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.628 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2591,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3485,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 24893,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINFL',
				brandName: 'MAIN CABIN FLEXIBLE',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3127,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.127 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3090,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3984,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 28457,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 7,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '05:30:00',
			totalTimeOfFlightFormated: '05hh 30mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T12:37:00',
				time: '12:37:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '12:37 p.m.',
			},
			arrival: {
				date: '2022-10-13T17:07:00',
				time: '17:07:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '05:07 p.m.',
			},
			airline: {
				code: 'CM',
				name: 'Copa Airlines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
			},
			fareSummary: {
				amount: 1657,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [
				{
					layoverName: 'Panama City(PTY)',
					layoverTime: '02:42 p.m.',
					waitTime: '00hh 51mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '02:05:00',
				timeOfFlightFormated: '02hh 05mm',
				flightNumber: '440',
				airplaneName: 'BOEING 737-800',
				waitTime: '00:51:00',
				waitTimeFormated: '00hh 51mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'PTY',
					name: 'Panama City',
					airportName: 'Tocumen Intl',
				},
				departure: {
					date: '2022-10-13T12:37:00',
					time: '12:37:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '12:37 p.m.',
				},
				arrival: {
					date: '2022-10-13T14:42:00',
					time: '14:42:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '02:42 p.m.',
				},
				operatingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				marketingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'A',
				cabin: 'economy',
			},
			{
				timeOfFlight: '01:34:00',
				timeOfFlightFormated: '01hh 34mm',
				flightNumber: '413',
				airplaneName: 'BOEING 737-800',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'PTY',
					name: 'Panama City',
					airportName: 'Tocumen Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T15:33:00',
					time: '15:33:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '03:33 p.m.',
				},
				arrival: {
					date: '2022-10-13T17:07:00',
					time: '17:07:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '05:07 p.m.',
				},
				operatingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				marketingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'A',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASIC',
				brandName: 'ECONOMY BASIC',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 1993 MXN antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 1993 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  1993 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  1993 MXN despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1657,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.657 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1595,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 2514,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 17957,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'CLASSIC',
				brandName: 'ECONOMY CLASSIC',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 997 MXN antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 997 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  997 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  997 MXN despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2354,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.354 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2292,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 3211,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 22936,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'FULL',
				brandName: 'ECONOMY FULL',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '2 Maletas de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '2 Maletas de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8274,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.274 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8212,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 9131,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65221,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 8,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '05:39:00',
			totalTimeOfFlightFormated: '05hh 39mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T06:24:00',
				time: '06:24:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '06:24 a.m.',
			},
			arrival: {
				date: '2022-10-13T11:03:00',
				time: '11:03:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '11:03 a.m.',
			},
			airline: {
				code: 'CM',
				name: 'Copa Airlines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
			},
			fareSummary: {
				amount: 1657,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [
				{
					layoverName: 'Panama City(PTY)',
					layoverTime: '08:29 a.m.',
					waitTime: '01hh 00mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '02:05:00',
				timeOfFlightFormated: '02hh 05mm',
				flightNumber: '173',
				airplaneName: 'BOEING 737-800',
				waitTime: '01:00:00',
				waitTimeFormated: '01hh 00mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'PTY',
					name: 'Panama City',
					airportName: 'Tocumen Intl',
				},
				departure: {
					date: '2022-10-13T06:24:00',
					time: '06:24:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '06:24 a.m.',
				},
				arrival: {
					date: '2022-10-13T08:29:00',
					time: '08:29:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '08:29 a.m.',
				},
				operatingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				marketingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'A',
				cabin: 'economy',
			},
			{
				timeOfFlight: '01:34:00',
				timeOfFlightFormated: '01hh 34mm',
				flightNumber: '332',
				airplaneName: 'BOEING 737-800',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'PTY',
					name: 'Panama City',
					airportName: 'Tocumen Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T09:29:00',
					time: '09:29:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '09:29 a.m.',
				},
				arrival: {
					date: '2022-10-13T11:03:00',
					time: '11:03:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '11:03 a.m.',
				},
				operatingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				marketingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'A',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASIC',
				brandName: 'ECONOMY BASIC',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 1993 MXN antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 1993 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  1993 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  1993 MXN despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1657,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.657 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1595,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 2514,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 17957,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'CLASSIC',
				brandName: 'ECONOMY CLASSIC',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 997 MXN antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 997 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  997 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  997 MXN despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2354,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.354 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2292,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 3211,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 22936,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'FULL',
				brandName: 'ECONOMY FULL',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '2 Maletas de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '2 Maletas de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8274,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.274 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8212,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 9131,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65221,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 9,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '05:49:00',
			totalTimeOfFlightFormated: '05hh 49mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T12:37:00',
				time: '12:37:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '12:37 p.m.',
			},
			arrival: {
				date: '2022-10-13T17:26:00',
				time: '17:26:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '05:26 p.m.',
			},
			airline: {
				code: 'CM',
				name: 'Copa Airlines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
			},
			fareSummary: {
				amount: 1657,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [
				{
					layoverName: 'Panama City(PTY)',
					layoverTime: '02:42 p.m.',
					waitTime: '01hh 10mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '02:05:00',
				timeOfFlightFormated: '02hh 05mm',
				flightNumber: '440',
				airplaneName: 'BOEING 737-800',
				waitTime: '01:10:00',
				waitTimeFormated: '01hh 10mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'PTY',
					name: 'Panama City',
					airportName: 'Tocumen Intl',
				},
				departure: {
					date: '2022-10-13T12:37:00',
					time: '12:37:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '12:37 p.m.',
				},
				arrival: {
					date: '2022-10-13T14:42:00',
					time: '14:42:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '02:42 p.m.',
				},
				operatingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				marketingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'A',
				cabin: 'economy',
			},
			{
				timeOfFlight: '01:34:00',
				timeOfFlightFormated: '01hh 34mm',
				flightNumber: '411',
				airplaneName: 'BOEING 737-800',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'PTY',
					name: 'Panama City',
					airportName: 'Tocumen Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T15:52:00',
					time: '15:52:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '03:52 p.m.',
				},
				arrival: {
					date: '2022-10-13T17:26:00',
					time: '17:26:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '05:26 p.m.',
				},
				operatingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				marketingCarrier: {
					code: 'CM',
					name: 'Copa Airlines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-CM.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'A',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASIC',
				brandName: 'ECONOMY BASIC',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 1993 MXN antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 1993 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  1993 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  1993 MXN despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1657,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.657 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1595,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 2514,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 17957,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'CLASSIC',
				brandName: 'ECONOMY CLASSIC',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 997 MXN antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite cambios desde 997 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  997 MXN despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'cost',
						description: 'Permite reembolsos desde  997 MXN despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2354,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.354 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2292,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 3211,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 22936,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'FULL',
				brandName: 'ECONOMY FULL',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '2 Maletas de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '2 Maletas de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8274,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.274 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8212,
						totalTaxes: 919,
						discounts: 0,
						grandTotal: 9131,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65221,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
];

export const RecommendationsResumeMock: FlightSearchResult[] = [
	{
		id: 0,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '10:28:00',
			totalTimeOfFlightFormated: '10hh 28mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T10:17:00',
				time: '10:17:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '10:17 a.m.',
			},
			arrival: {
				date: '2022-10-13T19:45:00',
				time: '19:45:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:45 p.m.',
			},
			airline: {
				code: 'DL',
				name: 'Delta Air Lines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
			},
			fareSummary: {
				amount: 1433,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [
				{
					layoverName: 'Atlanta(ATL)',
					layoverTime: '12:11 p.m.',
					waitTime: '03hh 49mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '01:54:00',
				timeOfFlightFormated: '01hh 54mm',
				flightNumber: '1482',
				airplaneName: 'BOEING 757',
				waitTime: '03:49:00',
				waitTimeFormated: '03hh 49mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				departure: {
					date: '2022-10-13T10:17:00',
					time: '10:17:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '10:17 a.m.',
				},
				arrival: {
					date: '2022-10-13T12:11:00',
					time: '12:11:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '12:11 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
			{
				timeOfFlight: '03:45:00',
				timeOfFlightFormated: '03hh 45mm',
				flightNumber: '981',
				airplaneName: 'BOEING 767-300',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T16:00:00',
					time: '16:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '04:00 p.m.',
				},
				arrival: {
					date: '2022-10-13T19:45:00',
					time: '19:45:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:45 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASICECON',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1433,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.433 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1396,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2290,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 16357,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: true,
				isRecommended: false,
			},
			{
				brandId: 'MAINCABIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2429,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.429 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2392,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3286,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 23471,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFMAIN',
				brandName: 'REFUNDABLE MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3426,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.426 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3389,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 4283,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 30593,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'COMFORTPLU',
				brandName: 'DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 6296,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $6.296 MXN',
				},
				fareDetails: [
					{
						baseAmount: 6259,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 7153,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 51093,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: true,
				isRecommended: false,
			},
			{
				brandId: 'REFCPLUS',
				brandName: 'REFUNDABLE DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8289,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.289 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8252,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 9146,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65329,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
	{
		id: 1,
		provider: 'sabre',
		businessModel: 'pointsOrMoney',
		trackType: 'departure',
		flightInformation: {
			departureLocation: {
				code: 'MIA',
				name: 'Miami',
				airportName: 'Miami Intl',
			},
			arrivalLocation: {
				code: 'BOG',
				name: 'Bogota',
				airportName: 'El Dorado International',
			},
			totalTimeOfFlight: '12:40:00',
			totalTimeOfFlightFormated: '12hh 40mm',
			stops: '1_Scale',
			departure: {
				date: '2022-10-13T08:05:00',
				time: '08:05:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '08:05 a.m.',
			},
			arrival: {
				date: '2022-10-13T19:45:00',
				time: '19:45:00',
				dateFormated: '13 oct. 2022',
				timeFormated: '07:45 p.m.',
			},
			airline: {
				code: 'DL',
				name: 'Delta Air Lines',
				logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
			},
			fareSummary: {
				amount: 1433,
				points: 6122,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
			legSummary: [
				{
					layoverName: 'Atlanta(ATL)',
					layoverTime: '10:01 a.m.',
					waitTime: '05hh 59mm',
				},
			],
		},
		legs: [
			{
				timeOfFlight: '01:56:00',
				timeOfFlightFormated: '01hh 56mm',
				flightNumber: '741',
				airplaneName: 'BOEING 757',
				waitTime: '05:59:00',
				waitTimeFormated: '05hh 59mm',
				departureLocation: {
					code: 'MIA',
					name: 'Miami',
					airportName: 'Miami Intl',
				},
				arrivalLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				departure: {
					date: '2022-10-13T08:05:00',
					time: '08:05:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '08:05 a.m.',
				},
				arrival: {
					date: '2022-10-13T10:01:00',
					time: '10:01:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '10:01 a.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
			{
				timeOfFlight: '03:45:00',
				timeOfFlightFormated: '03hh 45mm',
				flightNumber: '981',
				airplaneName: 'BOEING 767-300',
				waitTime: '00:00:00',
				waitTimeFormated: '00hh 00mm',
				departureLocation: {
					code: 'ATL',
					name: 'Atlanta',
					airportName: 'Hartsfield Jackson Atlanta Intl',
				},
				arrivalLocation: {
					code: 'BOG',
					name: 'Bogota',
					airportName: 'El Dorado International',
				},
				departure: {
					date: '2022-10-13T16:00:00',
					time: '16:00:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '04:00 p.m.',
				},
				arrival: {
					date: '2022-10-13T19:45:00',
					time: '19:45:00',
					dateFormated: '13 oct. 2022',
					timeFormated: '07:45 p.m.',
				},
				operatingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				marketingCarrier: {
					code: 'DL',
					name: 'Delta Air Lines',
					logoUrl: 'https://smartlinksdev.s3.us-east-2.amazonaws.com/airlines/I-DL.jpg',
				},
				seatRemaing: '0',
				technicalStop: false,
				trainStation: false,
				transferFromAirport: false,
				classOfService: 'E',
				cabin: 'economy',
			},
		],
		faresGroup: [
			{
				brandId: 'BASICECON',
				brandName: 'BASIC ECONOMY',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: false,
						type: 'checked',
						capacity: 'Ninguna maleta',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'Ninguna maleta',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite cambios despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 1433,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $1.433 MXN',
				},
				fareDetails: [
					{
						baseAmount: 1396,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 2290,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 16357,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'MAINCABIN',
				brandName: 'MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 2429,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $2.429 MXN',
				},
				fareDetails: [
					{
						baseAmount: 2392,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 3286,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 23471,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFMAIN',
				brandName: 'REFUNDABLE MAIN CABIN',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 3426,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $3.426 MXN',
				},
				fareDetails: [
					{
						baseAmount: 3389,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 4283,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 30593,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'COMFORTPLU',
				brandName: 'DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 6296,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $6.296 MXN',
				},
				fareDetails: [
					{
						baseAmount: 6259,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 7153,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 51093,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
			{
				brandId: 'REFCPLUS',
				brandName: 'REFUNDABLE DELTA COMFORT PLUS',
				baggageInformation: [
					{
						isIncluded: true,
						type: 'personalItem',
						capacity: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						isIncluded: true,
						type: 'carryOn',
						capacity: '10 Kg',
					},
					{
						isIncluded: true,
						type: 'checked',
						capacity: '1 Maleta de 23 Kg',
					},
				],
				brandFeaturesSummary: [
					{
						anciliaryType: 'included',
						description: 'Articulo personal: morral o cartera (45x35x20 cm3)',
					},
					{
						anciliaryType: 'included',
						description: '10 Kg',
					},
					{
						anciliaryType: 'included',
						description: '1 Maleta de 23 Kg',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo antes de la salida del vuelo.',
					},
					{
						anciliaryType: 'included',
						description: 'Permite cambios sin costo despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
					{
						anciliaryType: 'notIncluded',
						description: 'No permite reembolsos despues de la salida del vuelo.',
					},
				],
				fareGroupSummary: {
					totalFare: 8289,
					totalPoints: 6122,
					currency: 'MXN',
					currencyPoints: 'ThankYou Points',
					grandTotalFormated: '6.122 ThankYou Points + $8.289 MXN',
				},
				fareDetails: [
					{
						baseAmount: 8252,
						totalTaxes: 894,
						discounts: 0,
						grandTotal: 9146,
						fee: 0,
						currency: 'MXN',
						passengerType: 'adult',
						passengerQuantity: 1,
						points: 65329,
						pointsCurrency: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
			},
		],
		trackUrl: '',
	},
];
