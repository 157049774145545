export enum SearchFieldsNames {
	destination = 'destinationField',
	dates = 'datesField',
	departureDestination = 'departureDestinationField',
	arrivalDestination = 'arrivalDestinationField',
	discount = 'discountField',
	passengers = 'passengersField',
	filter = 'filterField',
	finish = 'finish',
	days = 'days',
	nationality = 'nationality',
	rooms = 'rooms',
}

export enum SearchDatesFieldsNames {
	date = 'dateDatesField',
	time = 'timeDatesField',
}

export enum CarSearchFieldsNames {
	hertzDiscount = 'hertzDiscountField',
}

export enum CarSearchFormInputsNames {
	departureLocation = 'departureLocationCarSearch',
	iataDepartureLocation = 'iataDepartureLocationCarSearch',
	arrivalLocation = 'arrivalLocationCarSearch',
	iataArrivalLocation = 'iataArrivalLocationCarSearch',
	dates = 'datesCarSearch',
	hiddenDates = 'hiddenDatesCarSearch',
	discount = 'discountCarSearch',
	corporateDiscount = 'corporateDiscountCarSearch',
	promotionalCode = 'promotionalCodeCarSearch',
	rewardsPoints = 'rewardsPointsCarSearch',
	paymentType = 'paymentTypeCarSearch',
	hertzDiscount = 'hertzDiscountCarSearch',
	hertzRewardsPoints = 'hertzRewardsPointsCarSearch',
	otherPlaceForArrival = 'otherPlaceForArrivalCarSearch',
	corporateDiscountCar = 'corporateDiscountCarSearchCar',
	promotionalCodeCar = 'promotionalCodeCarSearchCar',
	rewardsPointsCar = 'rewardsPointsCarSearchCar',
	rewardsPointsHertzCar = 'rewardsPointsCarSearchHertzCar',
}

export enum DatesFieldTypes {
	oneDate = 'oneDate',
	startEndDates = 'startEndDates',
}

export enum CarDestinationsFieldTypes {
	arrivalLocation = 'arrivalLocation',
	departureLocation = 'departureLocation',
}
