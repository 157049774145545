import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { ResponseHttp } from '../interfaces/response-http.interface';

export class HttpCall {
	private readonly _axiosInstance: AxiosInstance = axios.create();

	public async get<T>(url: string, customHeaders?: AxiosRequestHeaders): Promise<ResponseHttp<T>> {
		try {
			const config: AxiosRequestConfig = {
				headers: {
					...customHeaders,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			};

			const response: AxiosResponse<T> = await this._axiosInstance.get(url, config);
			return { response: response.data };
		} catch (error) {
			return { error };
		}
	}

	public async post<T, U>(url: string, data: U, customHeaders?: AxiosRequestHeaders): Promise<ResponseHttp<T>> {
		try {
			const config = {
				headers: {
					...customHeaders,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					Accept: 'application/json',
				},
			};

			const response: AxiosResponse<T> = await this._axiosInstance.post(url, { ...data }, config);
			return { response: response.data };
		} catch (error) {
			return { error };
		}
	}
}
