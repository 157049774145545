import { MarkersInterface } from '../../../../../shared/interfaces/maps/static-maps.interface';
import { MapUtil } from '../../../../../shared/utils/map.util';
import { useState, useEffect, useMemo } from 'react';
import styles from './interactive-map.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import type mapboxgl from 'mapbox-gl';

export interface InteractiveMapProps {
	token: string;
	longitude: number;
	latitude: number;
	markers: MarkersInterface[];
	color?: string;
	height?: string;
	callBackMarker?: (e: MarkersInterface) => void;
	callBackPopupBtnSelected?: (e: MarkersInterface) => void;
	selectText?: string;
	isSecondaryTemplate?: boolean;
}

export function InteractiveMap({
	token,
	longitude,
	latitude,
	markers,
	color,
	callBackMarker,
	callBackPopupBtnSelected,
	height,
	selectText,
	isSecondaryTemplate,
}: InteractiveMapProps): JSX.Element {
	const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();
	const [markerList, setMarkerList] = useState<mapboxgl.Marker[]>([]);
	const mapUtil: MapUtil = new MapUtil();
	const generateId: string = useMemo(
		() =>
			((): string => {
				return mapUtil.interactiveMapId(5);
			})(),
		[],
	);

	useEffect(() => {
		if (!longitude || !latitude || !token) {
			return;
		}

		void initListMarkers();
	}, [token]);

	useEffect(() => {
		if (mapInstance) {
			markerList?.forEach(marker => {
				marker.remove();
			});

			void initCreateMarkers(mapInstance);
		}
	}, [markers, mapInstance]);

	useEffect(() => {
		if (mapInstance) {
			mapInstance.setCenter([longitude, latitude]);
		}
	}, [longitude, latitude]);

	const markerClick = (e: MarkersInterface): void => {
		if (callBackMarker) {
			callBackMarker(e);
		}
	};

	const initListMarkers = async (): Promise<void> => {
		const showInColor: boolean = isSecondaryTemplate ?? false;
		const map: mapboxgl.Map = (await mapUtil.initIteractiveCarMap(token, generateId, longitude, latitude, showInColor)) as unknown as mapboxgl.Map;
		setMapInstance(map);
	};

	const initCreateMarkers = async (mapInstance: mapboxgl.Map): Promise<void> => {
		const list: mapboxgl.Marker[] = (await mapUtil.createMarkers(
			mapInstance,
			markerClick,
			markers,
			color,
			'withOutMarker',
			callBackPopupBtnSelected,
			selectText,
		)) as unknown as mapboxgl.Marker[];

		setMarkerList(list);
	};

	if (!token) {
		return <>invalid token</>;
	}

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				id={generateId}
				style={{
					height: height ? `${height}px` : '700px',
				}}
				className={'interactiveMap'}
			></div>
		</>
	);
}

export default InteractiveMap;
