export function DisneySkeletonRecommendation(): JSX.Element {
	return (
		<>
			<div className={'disney-recommendation mx-[6px] my-4 w-[300px] h-[302px] rounded-lg bg-white shadow-lg animate-pulse'}>
				<div className={'disney-recommendation-card__container relative flex flex-col p-5 justify-between h-full'}>
					<div className='bg-gray-200 rounded h-[21px] w-1/3'></div>
					<div className='bg-gray-200 rounded h-[100px] w-full'></div>
					<div className='bg-gray-200 rounded h-[40px] w-5/6 self-center'></div>
					<div className='bg-gray-200 rounded-md h-[50px] w-full'></div>
				</div>
			</div>
		</>
	);
}

export default DisneySkeletonRecommendation;
