import { ProductType } from '../../../shared/enums/product.enum';
import {
	HistoricalDetailData,
	HistoricalDetailProductActivity,
	HistoricalDetailProductAir,
	HistoricalDetailProductCar,
	HistoricalDetailProductDisney,
	HistoricalDetailProductHotel,
} from '../../interfaces';
import { ObjectLiteral } from '../../../shared/enums/object-literal.enum';
import { PassangerType } from '../../../lib/disney';

export const historicalDetailBaseMock = (
	productType: ProductType,
): HistoricalDetailData<
	| HistoricalDetailProductDisney
	| HistoricalDetailProductHotel
	| HistoricalDetailProductAir
	| HistoricalDetailProductCar
	| HistoricalDetailProductActivity
> => {
	const historicalDetailProcucts = {
		[ProductType.hotel]: historicalDetailHotelMock,
		[ProductType.activity]: historicalDetailActivityMock,
		[ProductType.car]: historicalDetailCarMock,
		[ProductType.air]: historicalDetailAirMock,
		[ProductType.plan]: historicalDetailDisneyMock,
		[ObjectLiteral.default]: historicalDetailHotelMock,
	};

	return {
		detail: historicalDetailProcucts[productType] || historicalDetailProcucts[ObjectLiteral.default],
		historicalResponse: {
			id: 'b865b0e2-9ef9-41c3-a055-bf3fed4fa834',
			productType,
			providerCode: '235-7557821',
			status: 'active',
			bookingDate: '2022-11-02T14:42:00.92Z',
			bookingDateFormated: '02 noviembre 2022',
			departureDate: '2022-11-02T14:42:00.92Z',
			fareSummary: {
				totalPoints: 0.0,
				totalCash: 1670.0,
				currency: 'MXN',
				pointsCurrency: 'ThankYou Points',
			},
		},
		passengerQuantity: {
			adults: 1,
			children: 0,
			infants: 0,
		},
	};
};

const historicalDetailHotelMock: HistoricalDetailProductHotel = {
	name: 'The Stiles Hotel, South Beach',
	phoneNumber: '+0013056747800',
	country: 'Estados Unidos',
	city: 'Miami Area - FL',
	address: 'COLLINS AVENUE, 1120',
	checkIn: '2023-06-15T00:00:00Z',
	checkInFormated: '15 junio 2023',
	checkOut: '2023-06-16T00:00:00Z',
	checkOutFormated: '16 junio 2023',
	numberOfNights: 1,
	customer: {
		name: 'Alonso Torres',
		documentType: {
			isAlphanumeric: true,
			corporate: true,
		},
		type: '',
	},
};

const historicalDetailAirMock: HistoricalDetailProductAir = {
	flights: [
		{
			departureDate: '2023-05-08T13:56:00Z',
			arrivalDate: '2023-05-08T22:55:00Z',
			departureDateFormated: '08 mayo 2023 01:56 p. m.',
			arrivalDateFormated: '08 mayo 2023 10:55 p. m.',
			from: 'Mexico City',
			to: 'Bogota',
			airline: 'Copa Airlines',
		},
		{
			departureDate: '2023-05-18T04:59:00Z',
			arrivalDate: '2023-05-18T12:40:00Z',
			departureDateFormated: '18 mayo 2023 04:59 a. m.',
			arrivalDateFormated: '18 mayo 2023 12:40 p. m.',
			from: 'Bogota',
			to: 'Cancun',
			airline: 'Copa Airlines',
		},
	],
	passengers: {
		adults: [
			{
				type: PassangerType.adult,
				fullName: 'SAMUEL MONSALVE',
			},
		],
		children: [],
		infants: [],
	},
};

const historicalDetailCarMock: HistoricalDetailProductCar = {
	pickUp: {
		location: '3900 NW 25TH STREET SUITE 410, MIAMI, FL',
		date: '09 enero 2023',
		time: '12:00',
	},
	dropOff: {
		location: '3900 NW 25TH STREET SUITE 410, MIAMI, FL',
		date: '10 enero 2023',
		time: '12:00',
	},
	rentalCarCompany: 'HERTZ',
	carModel: 'CHEVROLET SPARK OR SIMILAR',
	coverages: ['LDW-Seguro colisión y robo.', 'TAX-Impuestos.'],
	customer: {
		name: 'Alonso Torres',
		documentType: {
			isAlphanumeric: true,
			corporate: true,
		},
		type: '',
		email: 'amhiguitar@ultragroupla.com',
	},
};

const historicalDetailActivityMock: HistoricalDetailProductActivity = {
	name: 'Safari por los Everglades',
	description:
		'Recorre el Parque Nacional de Everglades, uno de los parque nacionales más grandes de los EE. UU. y la zona pantanosa más grandes del mundo.<br />\n<br />\nTras recogerte en el hotel, pondremos rumbo a los Everglades, ubicados a tan solo 50 minutos al oeste de Miami. Por el camino, aprende un poco más sobre las 120 especies de árboles nativos, las 300 especies de aves, las 30 de serpientes y muchos otros habitantes más, como el oso pardo, el zorro y el puma norteamericano.<br />\n<br />\nDisfruta de un recorrido de 30 minutos en hidrodeslizador y prepárate para asistir a un espectáculo de caimanes. Florida es el único lugar del mundo donde conviven caimanes y cocodrilos.<br />\n<br />\nPondremos punto final a la aventura con la vuelta al Miami. ',
	duration: 1,
	dateFormated: '18 octubre 2023',
	customer: {
		name: 'Alonso Torres',
		documentType: {
			isAlphanumeric: true,
			corporate: true,
		},
		type: '',
	},
};

const historicalDetailDisneyMock: HistoricalDetailProductDisney = {
	ticketType: 'Oferta Especial Fecha Flexible Básico',
	days: 2,
	dateFormated: '11 abril 2023',
	customer: {
		name: 'Alonso Torres',
		email: 'amhiguitar@ultragroupla.com',
		documentType: {
			isAlphanumeric: true,
			corporate: true,
		},
		type: '',
	},
};
