export function SkeletonFareSummary(): JSX.Element {
	return (
		<>
			<div className='faresummaryResumen__container p-6 animate-pulse'>
				<p className='faresummaryResumen__title'>
					<span className='faresummaryResumen__title-text uppercase  bg-gray-200 h-[20px] w-[179px] rounded-lg'></span>
				</p>

				<div className='faresummaryResumen__fareDetail mt-4'>
					<div className='faresummaryResumen__fareValues mb-2'>
						<div className='flex justify-between items-center'>
							<p className='faresummaryResumen__fareDetailCurrency text-xs bg-gray-200 h-[20px] w-[76px] rounded-lg'></p>

							<p className='faresummaryResumen__fareDetailPrice text-xs bg-gray-200 h-[20px] w-[87px] rounded-lg'></p>
						</div>
					</div>

					<div className='faresummaryResumen__fareValues mb-2'>
						<div className='flex justify-between items-center'>
							<p className='faresummaryResumen__fareDetailCurrency text-xs bg-gray-200 h-[20px] w-[127px] rounded-lg'></p>

							<p className='faresummaryResumen__fareDetailPrice text-xs bg-gray-200 h-[20px] w-[94px] rounded-lg'></p>
						</div>
					</div>

					<div className='faresummaryResumen__fareValues mb-2'>
						<div className='flex justify-between items-center'>
							<p className='faresummaryResumen__fareDetailCurrency text-xs bg-gray-200 h-[20px] w-[88px] rounded-lg'></p>

							<p className='faresummaryResumen__fareDetailPrice text-xs bg-gray-200 h-[20px] w-[55px] rounded-lg'></p>
						</div>
					</div>
				</div>

				<hr className='mt-4' />

				<div className='faresummaryResumen__fareDetail mt-4'>
					<div className='faresummaryResumen__fareValues'>
						<div className='flex justify-between items-center'>
							<p className='faresummaryResumen__fareCurrency bg-gray-200 h-[20px] w-[138px] rounded-lg'></p>

							<p className='faresummaryResumen__farePrice bg-gray-200 h-[20px] w-[57px] rounded-lg'></p>
						</div>
					</div>
				</div>

				<div className='faresummaryResumen__action mt-4'>
					<div className='bg-gray-200 h-[50px] rounded-lg text-white w-full'></div>
				</div>
			</div>
		</>
	);
}
