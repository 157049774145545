import { SpecialServiceInterface } from '../../lib/cars/shared/interfaces/special-services.interface';

export const CarSpecialServicesMock: SpecialServiceInterface[] = [
	{
		code: 'BST',
		name: 'Asientos niveladores para niños',
		description:
			'<h3>Sirius XM - Satelite radio</h3><br><p>Bienvenido/a al mundo de la radio por satélite. La radio por satélite se encuentra disponible en determinados vehículos de Hertz. Con SiriusXM, puedes escuchar:</p> <ul><li>Música sin interrupciones</li><li>Deportes en vivo</li><li> Entretenimiento exclusivo</li><li>Noticias internacionales</li><li>Programas para la familia entera y para niños</li></ul>',
		isPaidOnline: true,
		quantitySpecialEquipment: null,
		rate: {
			amount: 69.95,
			currency: 'USD',
		},
		equivalentRate: {
			amount: 285418.0,
			currency: 'COP',
		},
	},
	{
		code: 'CSI',
		name: 'Asiento para bebe',
		description:
			"<h3>Asiento para Bebé</h3><br><p>Evite los cargos por equipaje de las aerolíneas y permita que tengamos listo su asiento esperando por usted. Hertz puede brindarle un asiento auxiliar para Avoidirline baggage charges and have our seats waiting for you. Hertz can provide you with an infant car seat to be used for infants who are less than one year old and weigh up to 20 pounds or up to 9 kgs. Children are too large for this type of car seat if they weigh over 20 pounds/9 kgs or are longer than 26 inches/66 cms. Infant seats must be installed rear-facing. These seats are designed to support a child's neck, back and spinal cord</p>",
		isPaidOnline: true,
		quantitySpecialEquipment: {
			min: 1,
			max: 4,
		},
		rate: {
			amount: 69.95,
			currency: 'USD',
		},
		equivalentRate: {
			amount: 285418.0,
			currency: 'COP',
		},
	},
	{
		code: 'CST',
		name: 'Asientos para niños',
		description:
			'un asiento convertible para ser usado orientado hacia adelante para niños que pesan 20-40 libras o 8kgs-18 kgs y hasta 40 pulgadas /101 cms de altura. Se enfatiza a los padres de los niños que ya no caben en un asiento de seguridad infantil a utilizer este tipo de asientos orientados hacia atrás hasta que el niño llegue a los límites de de altura y peso superior al asiento.',
		isPaidOnline: true,
		quantitySpecialEquipment: {
			min: 1,
			max: 4,
		},
		rate: {
			amount: 69.95,
			currency: 'USD',
		},
		equivalentRate: {
			amount: 285418.0,
			currency: 'COP',
		},
	},
];
