import { ListInterface, ListItemInterface } from '../../../shared/interfaces/list.interface';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import useWindowWidth from '../../../shared/hooks/useWidth';
import Backdrop from '../../ui-elements/backdrop/backdrop';
import List from '../../ui-elements/list/list';
import { useState } from 'react';

export interface DropdownMenuProps {
	icon?: string;
	disabled?: boolean;
	menuItems?: ListInterface[];
	zIndex?: number;
	menuBackgroundColor?: string;
	menuTopPosition?: string;
	menuBottomPosition?: string;
	menuRightPosition?: string;
	menuLeftPosition?: string;
	menuWidth?: number;
	closeIconColor?: string;
	onClick?: (item: ListItemInterface) => void;
	className?: string;
	listClassName?: string;
	itemsClassName?: string;
	liClassName?: string;
	closeIconClassName?: string;
	dropdownClassName?: string;
	iconClassName?: string;
}

export function DropdownMenu({
	icon,
	disabled,
	menuItems,
	zIndex,
	menuBackgroundColor,
	menuTopPosition,
	menuBottomPosition,
	menuRightPosition,
	menuLeftPosition,
	menuWidth,
	closeIconColor,
	onClick,
	className,
	listClassName,
	itemsClassName,
	liClassName,
	closeIconClassName,
	dropdownClassName,
	iconClassName,
}: DropdownMenuProps): JSX.Element {
	const responsiveSize: number = 768;
	const diffZIndex: number = 1;
	const half: number = 2;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const windowWidth: number = useWindowWidth();
	return (
		<>
			<Backdrop
				show={isOpen}
				onClick={(): void => setIsOpen((prevState: boolean): boolean => !prevState)}
				zIndex={Number(zIndex) - diffZIndex}
				backgroundColor={'transparent'}
				opacity={0}
			/>
			<div className={`dropdownMenu flex flex-row items-center justify-center static md:relative ${className || ''}`}>
				<button
					className={'dropdownMenu__iconButton'}
					onClick={(): void => setIsOpen((prevState: boolean): boolean => !prevState)}
					disabled={disabled}
				>
					<IcomoonIcon icon={icon || 'dotsIco'} fontSize={iconClassName ? undefined : 15} className={iconClassName} />
				</button>
				{!disabled && isOpen && (
					<div
						className={`dropdownMenu__dropdown flex flex-col rounded-lg fixed md:absolute ${dropdownClassName || ''}`}
						style={{
							width: windowWidth < responsiveSize ? '100%' : `${menuWidth || '0'}px`,
							top: windowWidth < responsiveSize ? '0' : menuTopPosition,
							bottom: menuBottomPosition,
							right: menuRightPosition,
							left: windowWidth < responsiveSize ? '0' : menuLeftPosition || `calc(50% - ${Number(menuWidth) / half}px)`,
							zIndex: Number(zIndex),
							backgroundColor: menuBackgroundColor,
						}}
					>
						{windowWidth < responsiveSize && (
							<div className={`w-full flex justify-end px-7 mb-3.5 ${closeIconClassName || ''}`}>
								<div onClick={(): void => setIsOpen((prevState: boolean): boolean => !prevState)}>
									<IcomoonIcon
										icon='closeIco'
										fontSize={40}
										color={closeIconColor}
										style={{
											width: '40px',
											height: '40px',
										}}
									/>
								</div>
							</div>
						)}
						{menuItems && onClick && (
							<List
								items={menuItems}
								callBack={onClick}
								className={`flex flex-col rounded-lg ${listClassName || ''}`}
								withPointer={true}
								itemsClassName={itemsClassName}
								liClassName={liClassName}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
}

DropdownMenu.defaultProps = {
	className: '',
	listClassName: '',
	itemsClassName: '',
	liClassName: '',
	closeIconClassName: '',
	dropdownClassName: '',
	iconClassName: '',
};

export default DropdownMenu;
