import { faPlaneDeparture, faPlaneArrival, faCalendar, faUser } from '@awesome.me/kit-dc2201b3ec/icons/classic/solid';
import { DestinationsNamesFlightsSearchFormInterface, flightsSearchFormMobileFiled } from '../../../../shared';
import { InputEventsHandler, OptionSelect, TabData } from '../../../../../../shared/interfaces';
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Tabs, TripTypeSelector } from '../../../../../shared-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ElementTypes } from '../../../../../../shared/enums';
import { FlightTripTypes } from '../../../../shared/enums';
import { Input } from '../../../../../ui-elements';
import { FC, useEffect, useState } from 'react';

export interface FlightsSearchFormMobileProps {
	destinationsNames: DestinationsNamesFlightsSearchFormInterface[];
	tripTypeName: string;
	passengersName: string;
	passengersId: string;
	traductions: (label: string) => string;
	register: UseFormRegister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	tripTypeOnChange: (e: InputEventsHandler) => void;
	setValue: UseFormSetValue<FieldValues>;
	showMobileFieldsContainerHandler: (show: boolean, fieldId: string, fieldName: string) => void;
	tripTypeTabsHandler: (tab: string) => void;
	triptypeDefaultValue: string;
	tripTypesOptions: OptionSelect[];
	isSecondaryTemplate: boolean;
	truncate?: boolean;
	colorSecondary: string;
	customClass?: string;
	tripTypesTabs: TabData[];
	tripType: FlightTripTypes;
	isTertiaryTemplate?: boolean;
}

export const FlightsSearchFormMobile: FC<FlightsSearchFormMobileProps> = ({
	children,
	tripTypeName,
	passengersId,
	passengersName,
	tripTypesOptions,
	register,
	setValue,
	tripTypeOnChange,
	showMobileFieldsContainerHandler,
	traductions,
	watch,
	triptypeDefaultValue,
	isSecondaryTemplate,
	colorSecondary,
	truncate,
	destinationsNames,
	customClass,
	tripTypeTabsHandler,
	tripTypesTabs,
	tripType,
	isTertiaryTemplate,
}) => {
	const firstElement: number = 0;
	const [departureValue, setDepartureValue] = useState<string>('');
	const [arrivalValue, setArrivalValue] = useState<string>('');
	const [datesValue, setDatesValue] = useState<string>('');
	const [passengersValue, setPassengersValue] = useState<string>('');
	const handleClick = (id: string, name: string): void => {
		showMobileFieldsContainerHandler(true, id, name);
	};

	const departureInputValue: string = watch(destinationsNames[firstElement].departure.name);
	const arrivalInputValue: string = watch(destinationsNames[firstElement].arrival.name);
	const datesInputValue: string = watch(destinationsNames[firstElement].dates.name);
	const passengersInputValue: string = watch(passengersName);
	useEffect(() => {
		setDepartureValue(departureInputValue);

		setArrivalValue(arrivalInputValue);

		setDatesValue(datesInputValue);

		setPassengersValue(passengersInputValue);
	}, [destinationsNames, departureInputValue, arrivalInputValue, datesInputValue, passengersInputValue]);

	const fields: flightsSearchFormMobileFiled[] = [
		{
			label: traductions('origin') || 'origin',
			value: departureValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconLightCircleDotSecondary';
					case isTertiaryTemplate:
						return 'iconAirplaneDepartureTertiary';
					default:
						return faPlaneDeparture;
				}
			})(),
			placeholder: isSecondaryTemplate ? traductions('selectAnOrigin') || 'selectAnOrigin' : traductions('origin') || 'origin',
			idRef: destinationsNames[firstElement].departure.id,
			nameRef: destinationsNames[firstElement].departure.name,
		},
		{
			label: traductions('destination') || 'destination',
			value: arrivalValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconLightLocationDotSecondary';
					case isTertiaryTemplate:
						return 'iconAirplaneReturnTertiary';
					default:
						return faPlaneArrival;
				}
			})(),
			placeholder: isSecondaryTemplate ? traductions('selectADestination') || 'selectADestination' : traductions('destination') || 'destination',
			idRef: destinationsNames[firstElement].arrival.id,
			nameRef: destinationsNames[firstElement].arrival.name,
		},
		{
			label: traductions('labelDates') || 'labelDates',
			value: datesValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconLightCalendarSecondary';
					case isTertiaryTemplate:
						return 'iconCalendarDepartureTertiary';
					default:
						return faCalendar;
				}
			})(),
			placeholder: isSecondaryTemplate ? traductions('selectAnOption') || 'selectAnOption' : traductions('labelDates') || 'labelDates',
			idRef: destinationsNames[firstElement].dates.id,
			nameRef: destinationsNames[firstElement].dates.name,
		},
		{
			label: traductions('passengers') || 'passengers',
			value: passengersValue ?? '',
			icon: isSecondaryTemplate ? 'iconLightUserSecondary' : faUser,
			placeholder: isSecondaryTemplate ? traductions('selectAnOption') || 'selectAnOption' : traductions('passengers') || 'passengers',
			idRef: passengersId,
			nameRef: passengersName,
		},
	];

	return (
		<div className={`flex flex-col gap-4 ${customClass ?? ''}`}>
			<div>
				{isSecondaryTemplate ? (
					<TripTypeSelector
						name={tripTypeName}
						options={tripTypesOptions.filter(option => option.value !== FlightTripTypes.multiple)}
						register={register}
						onChange={tripTypeOnChange}
						defaultValue={triptypeDefaultValue}
						setValue={setValue}
						className='!py-0 !px-0 !w-fit justify-start'
						customSelectClassName='text-base text-white font-family-regular'
						optionsMarginTop={'10px'}
						isRadioInpustSelector={isSecondaryTemplate}
						isRadioCustom={true}
						customRadioLabelClassName='!text-base'
					/>
				) : (
					<Tabs
						className='flightsSearchForm__tripTypeTabs justify-center w-full'
						tabClassName={`${
							isTertiaryTemplate
								? 'hover:shadow-lg border border-[#BEBEBE] text-[#4D4D4F] text-sm min-w-[140px]'
								: 'rounded-full border-gray-0 text-white text-xs'
						} no-underline mr-2 py-[10px] px-[15px]
						`}
						activeTab={tripType}
						tabs={tripTypesTabs.filter(tab => tab.id !== FlightTripTypes.multiple)}
						activeColor={`${colorSecondary ?? ''}`}
						desactiveColor='#878787'
						tabActiveClass={`${isTertiaryTemplate ? 'border !border-[#0A609C] md:shadow-[0px_2px_2px_0px_rgba(16,117,187,0.22)]' : ''}`}
						isTertiaryTemplate={isTertiaryTemplate}
						onClick={tripTypeTabsHandler}
					/>
				)}
			</div>

			<div className='flex flex-col gap-2'>
				{fields.map((field: flightsSearchFormMobileFiled) => (
					<div
						key={field.idRef}
						className={`flex 
						${isSecondaryTemplate ? 'flex-col gap-1' : 'border bg-white gap-2 px-4 py-3 border-rad border-[#696969]'}
						${isTertiaryTemplate ? 'rounded-none border-[#BEBEBE]' : ''}
						${!isTertiaryTemplate && !isSecondaryTemplate ? 'rounded-lg' : ''}
					`}
					>
						{isSecondaryTemplate && (
							<div className='text-sm font-medium'>
								<span className='field__labelText'>{field.label}</span>
							</div>
						)}
						{!isSecondaryTemplate && (
							<div className='w-[24px]'>
								{typeof field.icon === 'string' ? (
									<span className={`${field.icon} text-base`}></span>
								) : (
									<FontAwesomeIcon
										data-testid='icon'
										icon={field.icon}
										width={24}
										height={24}
										fixedWidth={true}
										color={isTertiaryTemplate ? '#4D4D4F' : colorSecondary}
										fontSize={16}
									/>
								)}
							</div>
						)}
						<Input
							value={field.value}
							readonly={true}
							name={field.nameRef}
							icon={isSecondaryTemplate ? (field.icon as string) : ''}
							iconClassName={'text-[#98A2B3]'}
							type={ElementTypes.text}
							placeholder={field.placeholder}
							isSecondaryTemplate={isSecondaryTemplate}
							isTertiaryTemplate={isTertiaryTemplate}
							className={`destinationField__input cursor-pointer ${
								isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'
							}  flex-grow`}
							inputClassName={`bg-transparent !p-0 font-normal md md:placeholder placeholder:font-normal placeholder:text-[#696969]
								${isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base'}
								${isTertiaryTemplate ? '!text-[14px] text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
							`}
							autocomplete={'off'}
							style={{
								color: isTertiaryTemplate ? '#4D4D4F' : '#696969',
							}}
							truncate={truncate}
							onClick={() => handleClick(field.idRef, field.nameRef)}
						/>
					</div>
				))}
			</div>

			{children}
		</div>
	);
};
