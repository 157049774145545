import { CarsRatesModelsV2, SpecialServicesCodes, SpecialServicesIcons } from '../../../../shared/enums/filter-cars-cards-v2.enum';
import { SpecialServiceInterface, SpecialServiceEventHandler } from '../../../../shared/interfaces';
import CarSpecialServicesDetailSkeleton from './skeleton/car-special-services-detail-skeleton';
import SpecialServiceCard from '../../special-service-card/special-service-card';
import styles from './car-special-services-detail.module.scss';
import { useCallback, useState, useEffect } from 'react';

export interface CarSpecialServicesDetailProps {
	specialServices: SpecialServiceInterface[];
	title: string;
	description: string;
	moreInformationText: string;
	payOnLineText: string;
	paymentOnArrivalText: string;
	rateModel: CarsRatesModelsV2 | string;
	loading?: boolean;
	colorPrimary?: string;
	zIndexModal?: number;
	extraZIndexModal?: number;
	topModal?: string;
	onItemsChange?: (event: SpecialServiceEventHandler[]) => void;
}

export const CarSpecialServicesDetail = ({
	specialServices,
	title,
	description,
	moreInformationText,
	payOnLineText,
	paymentOnArrivalText,
	rateModel,
	loading,
	colorPrimary,
	zIndexModal,
	extraZIndexModal,
	topModal,
	onItemsChange,
}: CarSpecialServicesDetailProps): JSX.Element => {
	const [selectedSpecialServices, setSelectedSpecialServices] = useState<SpecialServiceEventHandler[]>([]);
	const checkboxChangeHandler = useCallback((event: SpecialServiceEventHandler) => {
		setSelectedSpecialServices((prevState: SpecialServiceEventHandler[]): SpecialServiceEventHandler[] => {
			const tempState: SpecialServiceEventHandler[] = [...prevState];
			const index: number = tempState.findIndex((item: SpecialServiceEventHandler) => item.code === event.code);
			if (index !== -1) {
				tempState[index].checked = event.checked;

				tempState[index].counter = event.counter;
			}

			return [...tempState];
		});
	}, []);

	useEffect(() => {
		if (specialServices && specialServices.length) {
			const tempSelectedSpecialServices: SpecialServiceEventHandler[] = specialServices.map(
				(specialService: SpecialServiceInterface): SpecialServiceEventHandler => ({
					code: specialService.code,
					counter:
						specialService.quantitySpecialEquipment && specialService.quantitySpecialEquipment.min ? specialService.quantitySpecialEquipment.min : 1,
					checked: false,
				}),
			);

			setSelectedSpecialServices([...tempSelectedSpecialServices]);
		}
	}, [specialServices]);

	useEffect(() => {
		if (onItemsChange) {
			onItemsChange([...selectedSpecialServices]);
		}
	}, [selectedSpecialServices]);

	return loading ? (
		<CarSpecialServicesDetailSkeleton />
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={'carSpecialServicesDetail order-2 md:order-1'}>
				<h3 className='carSpecialServicesDetail__title text-[21px] font-bold mb-[10px] md:mb-[5px]'>{title}</h3>
				<p
					className='carSpecialServicesDetail__description text-base font-normal pb-0 md:pb-[5px] m-0'
					dangerouslySetInnerHTML={{ __html: description || '' }}
				/>
				{specialServices.map((specialService: SpecialServiceInterface): JSX.Element => {
					let icon: string = '';
					const tempCode: string | undefined = Object.values(SpecialServicesCodes).includes(specialService.code as unknown as SpecialServicesCodes)
						? specialService.code
						: undefined;

					if (tempCode) {
						icon = SpecialServicesIcons[tempCode] || '';
					}

					return (
						<SpecialServiceCard
							key={specialService.code}
							icon={icon}
							specialService={specialService}
							moreInformationText={moreInformationText}
							payOnLineText={payOnLineText}
							paymentOnArrivalText={paymentOnArrivalText}
							rateModel={rateModel}
							loading={loading}
							colorPrimary={colorPrimary}
							className={
								'carSpecialServicesDetail__item bg-white mt-[20px] md:mt-[25px] w-full rounded-[10px] shadow-md min-h-[65px] carSpecialServicesDetail__item'
							}
							onCheckboxChange={checkboxChangeHandler}
							zIndexModal={zIndexModal}
							extraZIndexModal={extraZIndexModal}
							topModal={topModal}
						/>
					);
				})}
			</div>
		</>
	);
};

export default CarSpecialServicesDetail;
