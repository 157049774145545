import { ListInterface, ListItemInterface } from '../../../shared/interfaces/list.interface';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import { capitalize } from '../../../shared/services/utils.service';
import useWindowWidth from '../../../shared/hooks/useWidth';
import Backdrop from '../../ui-elements/backdrop/backdrop';
import Image from '../../ui-elements/image/image';
import styles from './short-profile.module.scss';
import List from '../../ui-elements/list/list';
import { useState, FC } from 'react';

export interface ShortProfileProps {
	imageUrl: string;
	onlyImage?: boolean;
	showMenu?: boolean;
	name?: string | JSX.Element;
	email?: string | JSX.Element;
	greetingText?: string;
	arrowUserIcon?: string;
	menuItems?: ListInterface[];
	userIcon?: string;
	noUserColor?: string;
	noUserLabel?: string;
	noUser?: boolean;
	zIndex?: number;
	menuBackgroundColor?: string;
	menuTopPosition?: string;
	menuBottomPosition?: string;
	menuRightPosition?: string;
	menuLeftPosition?: string;
	menuWidth?: number;
	closeIconColor?: string;
	onClick?: (item: ListItemInterface) => void;
	globalOnClick?: () => void;
	className?: string;
	listClassName?: string;
	itemsClassName?: string;
	liClassName?: string;
	iconWithUserColor?: string;
	closeIconClassName?: string;
	dropdownClassName?: string;
	infoContainerClassName?: string;
	nameClassName?: string;
	useIconWithNameInitials?: string;
}

export const ShortProfile: FC<ShortProfileProps> = ({
	imageUrl,
	onlyImage,
	showMenu,
	name,
	email,
	greetingText,
	menuItems,
	userIcon,
	noUserLabel,
	noUserColor,
	noUser,
	arrowUserIcon,
	zIndex,
	menuBackgroundColor,
	menuTopPosition,
	menuBottomPosition,
	menuRightPosition,
	menuLeftPosition,
	menuWidth,
	closeIconColor,
	onClick,
	globalOnClick,
	className,
	listClassName,
	itemsClassName,
	liClassName,
	closeIconClassName,
	dropdownClassName,
	infoContainerClassName,
	nameClassName,
	iconWithUserColor,
	children,
	useIconWithNameInitials,
}) => {
	const responsiveSize: number = 768;
	const diffZIndex: number = 1;
	const half: number = 2;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const windowWidth: number = useWindowWidth();
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<Backdrop
				show={isOpen}
				onClick={(): void => setIsOpen((prevState: boolean): boolean => !prevState)}
				zIndex={Number(zIndex) - diffZIndex}
				backgroundColor={'transparent'}
				opacity={0}
			/>
			<div className={`shortProfile flex-row items-center justify-end ${showMenu ? 'static md:relative' : ''} profile ${className || ''}`}>
				{!useIconWithNameInitials ? (
					<div className={'shortProfile__iconContainer flex justify-center items-center'}>
						<div
							className={`inline-block mx-auto ${showMenu ? 'cursor-pointer' : ''}`}
							onClick={globalOnClick || (showMenu ? (): void => setIsOpen((prevState: boolean): boolean => !prevState) : undefined)}
						>
							{imageUrl && !noUser ? (
								<Image
									url={imageUrl}
									width={'31px'}
									height={'31px'}
									styles={{
										width: '31px',
										height: '31px',
									}}
									customClass={'image'}
								/>
							) : (
								<div className='shortProfile__noUser flex items-center'>
									{iconWithUserColor && !noUser ? (
										<IcomoonIcon
											icon={userIcon ?? 'personIco'}
											fontSize={15}
											color={iconWithUserColor ?? 'white'}
											style={{
												width: '31px',
												height: '31px',
												backgroundColor: 'rgba(255, 255, 255, 0.3)',
											}}
											className='rounded-full'
										/>
									) : (
										<IcomoonIcon
											icon={userIcon ?? 'personIco'}
											fontSize={15}
											color={noUserColor ?? 'white'}
											style={{
												width: '31px',
												height: '31px',
												backgroundColor: 'rgba(255, 255, 255, 0.3)',
											}}
											className='rounded-full'
										/>
									)}
									{noUserLabel && noUser && <p className={`text-[${noUserColor ?? 'white'}] 'text-xs font-family-regular'`}>{noUserLabel}</p>}
								</div>
							)}
						</div>
					</div>
				) : (
					<div
						onClick={globalOnClick || (showMenu ? (): void => setIsOpen((prevState: boolean): boolean => !prevState) : undefined)}
						className={'shortProfile__nameInitialContainer flex justify-center items-center'}
					>
						<span className='block w-[30px] h-[30px] text-center py-[4px] px-[5px] uppercase bg-gray-500 text-white rounded-full'>
							{useIconWithNameInitials}
						</span>
					</div>
				)}

				{!onlyImage && !noUser && (
					<div className='shortProfile__infoContainerAndArrow'>
						<div className={`shortProfile__infoContainer flex flex-col items-start justify-center ${infoContainerClassName ?? 'ml-4'}`}>
							<div className={'shortProfile__name text-base font-family-bold name flex items-center'}>
								{greetingText ? (
									<span className={`${nameClassName ?? ''}`}>{capitalize(`${greetingText}, ${name?.toString() || ''}`)}</span>
								) : (
									<div className={`${nameClassName ?? 'text-white'}`}>{name}</div>
								)}
							</div>
							<div className={'shortProfile__email text-white inline-block text-xs font-family-regular email'}>{email}</div>
						</div>

						{!noUser && arrowUserIcon && (
							<div
								onClick={globalOnClick || (showMenu ? (): void => setIsOpen((prevState: boolean): boolean => !prevState) : undefined)}
								className={'shortProfile__arrowUserIcon flex justify-center items-center'}
							>
								<IcomoonIcon
									icon={arrowUserIcon}
									fontSize={15}
									color={noUserColor ?? 'white'}
									style={{
										width: '24px',
										height: '24px',
										backgroundColor: 'rgba(255, 255, 255, 0.3)',
									}}
									className='arrowUserIcon rounded-full'
								/>
							</div>
						)}
					</div>
				)}
				{isOpen && (
					<div
						className={`flex flex-col rounded-lg fixed md:absolute py-6 ${!(showMenu && isOpen) ? 'hidden' : ''} ${dropdownClassName || ''}`}
						style={{
							width: windowWidth < responsiveSize ? '100%' : `${menuWidth || '100'}px`,
							top: windowWidth < responsiveSize ? '0' : menuTopPosition,
							bottom: menuBottomPosition,
							right: menuRightPosition,
							left: windowWidth < responsiveSize ? '0' : menuLeftPosition || `calc(50% - ${Number(menuWidth) / half}px)`,
							zIndex: Number(zIndex),
							backgroundColor: menuBackgroundColor,
						}}
					>
						{windowWidth < responsiveSize && (
							<div className={`w-full flex justify-end px-7 mb-3.5 ${closeIconClassName || ''}`}>
								<div onClick={(): void => setIsOpen((prevState: boolean): boolean => !prevState)}>
									<IcomoonIcon
										icon='closeIco'
										fontSize={40}
										color={closeIconColor}
										style={{
											width: '40px',
											height: '40px',
										}}
									/>
								</div>
							</div>
						)}
						{menuItems && onClick && (
							<List
								items={menuItems}
								callBack={(item: ListItemInterface) => {
									setIsOpen(false);

									onClick(item);
								}}
								className={`flex flex-col rounded-lg ${listClassName || ''}`}
								withPointer={true}
								itemsClassName={itemsClassName}
								liClassName={liClassName}
							/>
						)}
						{children}
					</div>
				)}
			</div>
		</>
	);
};

ShortProfile.defaultProps = {
	className: '',
	listClassName: '',
	liClassName: '',
	itemsClassName: '',
	closeIconClassName: '',
	dropdownClassName: '',
};

export default ShortProfile;
