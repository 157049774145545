import { FlightPathsInterface } from '../../../../../../shared/interfaces/flight-paths.interface';
import { FlightTripTypes } from '../../../../shared/enums/flight-types.enum';
import { FlightPathDetail } from '../flight-path-detail/flight-path-detail';
import { FlightPath } from '../flight-path/flight-path';
import styles from './flight-paths.module.scss';
import { useState } from 'react';

export interface FlightPathsProps {
	title: string;
	titleJourney: string;
	titleDetailFlight: string;
	titleDetailTrain: string;
	tripType: FlightTripTypes;
	flightPaths: FlightPathsInterface;
	baggageHead?: string;
	timeHead?: string;
	typeFlight: string[];
	flightDurationTitle?: string;
	waitingTitle?: string;
	tooltipHead?: string;
}

export function FlightPaths(props: FlightPathsProps): JSX.Element {
	const [isActive, setIsActive] = useState(false);
	function ShowDetail(): void {
		setIsActive(!isActive);
	}
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='flightPaths container w-full bg-white'>
				{
					<div>
						<FlightPath
							tripType={props.tripType}
							title={props.title}
							titleJourney={props.titleJourney}
							flightPaths={props.flightPaths}
							isActive={isActive}
							baggageHead={props.baggageHead}
							timeHead={props.timeHead}
							typeFlight={props.typeFlight}
							tooltipHead={props.tooltipHead}
							onClick={() => {
								ShowDetail();
							}}
						/>
						<section className={`w-full accordion accordion__list ${isActive ? 'accordion__listOpen' : ''}`}>
							<FlightPathDetail
								titleFlight={props.titleDetailFlight}
								titleTrain={props.titleDetailTrain}
								legs={props.flightPaths.legs}
								flightDurationLabel={props.flightDurationTitle}
								waitTimeLabel={props.waitingTitle}
							/>
						</section>
					</div>
				}
			</div>
		</>
	);
}

export default FlightPaths;
