import { ShortRecommendation } from '../../../../../shared/interfaces/short-recommendation';
import Recommendation from '../../../components/recommendation/recommendation';
import styles from './lower-prices.module.scss';
import { useState, useEffect } from 'react';
export interface LowerPricesProps {
	styles?: any;
	customClass?: string;
	lowerPricesList: ShortRecommendation[];
	days: number;
	emitRecommendation?: (data?: ShortRecommendation) => void;
}

export function LowerPrices(props: LowerPricesProps): JSX.Element {
	const [lowerPriceList, setLowerPriceList] = useState<any[]>([]);
	useEffect(() => {
		createRecommendations();
	}, [props.lowerPricesList]);

	const createRecommendations = (): void => {
		const onlyThreeElements: number = 3;
		const getFirstThreeElements = props?.lowerPricesList?.slice(0, onlyThreeElements);
		if (getFirstThreeElements?.length) {
			setLowerPriceList(getFirstThreeElements);
		} else {
			setLowerPriceList([]);
		}
	};

	const currentDay = (): string => {
		const currentDate: Date = new Date();
		return currentDate.toLocaleDateString('es-CO', {
			day: '2-digit',
		});
	};

	const currentMonth = (): string => {
		const currentDate: Date = new Date();
		return currentDate.toLocaleDateString('es-CO', {
			month: 'long',
		});
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div id='lowerPrices' className={'lowerPrices flex justify-center'} style={{ ...props.styles }}>
				<div className='flex flex-col items-center p-16 px-6 md:p-16 w-full'>
					<h3 className='text-4xl text-white font-family-black mt-3.5 mb-3.5 text-center'>Precios más bajos</h3>
					<p className={'text-white font-family-regular pb-10 tracking-wider uppercase'}>
						HOY, {currentDay()} DE {currentMonth()}
					</p>

					<div className={'lowerPrices__container w-full'}>
						{lowerPriceList.length ? (
							lowerPriceList.map((item: any, index: number) => (
								<div key={index}>
									<Recommendation
										onClick={data => {
											if (props.emitRecommendation) {
												props.emitRecommendation(data);
											}
										}}
										data={item}
									/>
								</div>
							))
						) : (
							<div>
								<Recommendation />
								<Recommendation />
								<Recommendation />
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default LowerPrices;
