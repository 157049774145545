import { ToolTipArrowType } from '../../../shared/enums/tooltip.enum';
import { useState, useEffect, useCallback } from 'react';
import styles from './tool-tip.module.scss';

/* eslint-disable-next-line */
export interface ToolTipProps {
	title?: string;
	styles?: any;
	useInfoIcon?: boolean;
	customClass?: string;
	colorPrimary?: string;
	colorSecondary?: string;
	body: string;
	toolTipCustomClass?: string;
	titleClassName?: string;
	alwaysOpen?: boolean;
	arrowType?: ToolTipArrowType;
	preventMobile?: boolean;
	arrowClassName?: string;
	reverse?: boolean;
}

export function ToolTip(props: ToolTipProps): JSX.Element {
	const [useInfoIcon, setUseInfoIcon] = useState<boolean>(false);
	const [openContainer, setOpenContainer] = useState<boolean>(false);
	const [forceOpen, setForceOpen] = useState<boolean>(false);
	const [arrowStyle, setArrowStyle] = useState({});
	const [containerStyle, setContainerStyle] = useState({});
	const validateArrowType = (): void => {
		const setColor: string = props.colorPrimary || 'rgb(21, 19, 134)';
		let customStyle = {};
		if (props?.arrowType === ToolTipArrowType.top) {
			customStyle = {
				borderBottomColor: setColor,
			};
		}

		if (props?.arrowType === ToolTipArrowType.left) {
			customStyle = {
				borderRightColor: setColor,
			};
		}

		if (props?.arrowType === ToolTipArrowType.right) {
			customStyle = {
				borderLeftColor: setColor,
			};
		}

		if (props?.arrowType === ToolTipArrowType.bottom) {
			customStyle = {
				borderTopColor: setColor,
			};
		}

		if (!props?.arrowType) {
			customStyle = {
				borderBottomColor: setColor,
			};
		}

		setArrowStyle(customStyle);
	};

	useEffect(() => {
		if (props.useInfoIcon) {
			setUseInfoIcon(true);
		}

		validateArrowType();
	}, []);

	useEffect(() => {
		let customStyle = {};

		if (props.reverse) {
			if (props?.arrowType === ToolTipArrowType.left) {
				customStyle = {
					top: '0',
					left: '20px',
				};
			}
		}

		setContainerStyle(customStyle);
	}, [props.reverse]);

	useEffect(() => {
		if (props?.alwaysOpen && window.screen.width > 768) {
			setForceOpen(true);
		}
	}, [props?.alwaysOpen]);

	const openContainerMobile = useCallback(
		(open: boolean | null): void => {
			if (!props?.preventMobile) {
				setOpenContainer((prevState: boolean): boolean => (open !== null ? open : !prevState));
			}
		},
		[props?.preventMobile],
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`toolTip relative  h-6 ${props.customClass || ''}`}>
				<p
					className={`tooltip__name cursor-pointer flex font-family-bold ${props.titleClassName || ''}`}
					onClick={() => openContainerMobile(null)}
					style={{ color: props?.colorPrimary || '#000000' }}
				>
					<span
						className={`${props.titleClassName ? `${props.titleClassName}` : 'text-xs'} `}
						dangerouslySetInnerHTML={{ __html: props.title || '' }}
					></span>
					{useInfoIcon && <span className='infoCirlceIco text-xs mx-1' style={{ color: props.colorPrimary || 'rgb(21, 19, 134)' }}></span>}
				</p>
				<div onClick={() => openContainerMobile(false)} className={`toolTip__backDrop ${openContainer ? 'open' : ''}`}></div>
				<div
					className={`toolTip__container md:max-w-xs ${props.reverse ? 'toolTip__container-reverse' : ''} ${openContainer ? 'open' : ''} ${
						props.toolTipCustomClass || ''
					}`}
					style={{
						...props.styles,
						...containerStyle,
						background: props.colorPrimary || 'rgb(21, 19, 134)',
						display: forceOpen ? 'block' : '',
					}}
				>
					<div
						className={`toolTip_arrow ${props.arrowClassName || ''} ${
							props.arrowType ? `toolTip__arrow${props?.arrowType}` : 'toolTip__arrowtop  '
						} absolute  hidden md:block`}
						style={{
							...arrowStyle,
						}}
					></div>
					<span
						onClick={() => openContainerMobile(false)}
						className={'toolTip__close closeIco'}
						style={{ color: props.colorSecondary || '#ffffff' }}
					></span>
					<div
						className='toolTip__innerContainer'
						dangerouslySetInnerHTML={{ __html: props.body || '' }}
						style={{ color: props.colorSecondary || '#ffffff' }}
					></div>
				</div>
			</div>
		</>
	);
}

export default ToolTip;
