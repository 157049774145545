import { CardFormPropertiesToEncrypt } from '../../shared/enums/card-form-to-encrypt.enum';
import { CreateUserGuestResponse } from '../../shared/interfaces/user-guest.interface';
import { identifyCardFranchise } from '../../shared/utils/card-franchise.util';
import { CardInfoSubmit } from '../../shared/interfaces/card-info.interface';
import { EventsWindowCustom } from '../../shared/enums/events-window.enum';
import { createUserGuest } from '../../shared/services/bac-user.service';
import { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import { encryptObject } from '../../shared/utils/encrypt-object.util';
import { Agency } from '../../shared/interfaces/agency.interface';
import { Spinner } from '@smartlinks/react-design-system';
import { TFunction } from 'react-i18next';

const PointsConsultation = lazy(async () => {
	return await import('@smartlinks/react-design-system').then(module => {
		return { default: module.PointsConsultation };
	});
});

interface BacCardGuestProps {
	readonly agency: Agency | null;
	readonly token: string;
	t: TFunction<'translation', undefined>;
	readonly callBackSendToken: (token: string) => void;
	readonly user?: CreateUserGuestResponse | null;
}

function BacCardPoints({ token, t, agency, callBackSendToken, user }: Readonly<BacCardGuestProps>): JSX.Element {
	const [loading, setLoading] = useState<boolean | null>(null);
	const [error, setError] = useState<boolean | null>(null);
	const [statusCode, setStatusCode] = useState<number | null>(null);
	const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
	const [isResultOpen, setIsResultOpen] = useState<boolean>(false);
	const [guestInfo, setGuestInfo] = useState<CreateUserGuestResponse | null>(null);
	const [cardFranchise, setCardFranchise] = useState<string>('');
	const [cardNumber, setCardNumber] = useState<string>('');
	useEffect(() => {
		const handleUserGuestReceived = (event: Event): void => {
			const customEvent = event as CustomEvent;
			const { user }: { user: CreateUserGuestResponse } = customEvent.detail;
			if (user) {
				setGuestInfo(user);
			}
		};

		window.addEventListener(EventsWindowCustom.userGuestReceived, handleUserGuestReceived);

		return () => {
			window.removeEventListener(EventsWindowCustom.userGuestReceived, handleUserGuestReceived);
		};
	}, []);

	useEffect(() => {
		if (user) {
			setGuestInfo(user);
		}
	}, [user]);

	const handleFormOpen = (): void => {
		setLoading(null);

		setError(null);

		setStatusCode(null);

		setIsFormOpen(true);

		setIsResultOpen(false);
	};

	const handleCloseForm = (): void => {
		setIsFormOpen(false);
	};

	const handleResultClose = useCallback((): void => {
		if (guestInfo?.token) {
			callBackSendToken(guestInfo?.token || '');
		}

		setIsResultOpen(false);
	}, [guestInfo]);

	const handleGetPoints = async (cardData: CardInfoSubmit, token: string): Promise<void> => {
		const cardFourDigits: number = 4;
		const firstPosition: number = 0;
		setLoading(true);

		const franchise: string = identifyCardFranchise(cardData.number.substring(firstPosition, cardFourDigits));
		setCardFranchise(franchise);

		const cardNumber: string = cardData.number.substring(cardData.number.length - cardFourDigits);
		setCardNumber(cardNumber);

		const userData: CardInfoSubmit =
			agency && agency.customData ? encryptObject(cardData, Object.values(CardFormPropertiesToEncrypt), agency.customData) : cardData;

		const userGuest: CreateUserGuestResponse | null = await createUserGuest(userData, token, agency?.customData || '');
		if (typeof userGuest === 'number') {
			setLoading(false);

			setError(true);

			setIsResultOpen(true);

			setStatusCode(Number(userGuest));
		} else {
			setGuestInfo(userGuest);

			setLoading(false);

			setIsResultOpen(true);
		}
	};

	return (
		<>
			<Suspense fallback={<Spinner />}>
				<PointsConsultation
					loading={loading}
					error={error}
					statusCode={statusCode}
					isFormOpen={isFormOpen}
					isResultOpen={isResultOpen}
					openForm={handleFormOpen}
					closeForm={handleCloseForm}
					onCloseResult={handleResultClose}
					emitPoints={handleGetPoints}
					creditCardUser={guestInfo}
					t={t}
					token={token}
					franchise={cardFranchise}
					cardNumber={cardNumber}
				/>
			</Suspense>
		</>
	);
}

export default BacCardPoints;
