import { InputEventsHandler, OptionSelect, OptionsItems } from '../../../shared/interfaces';
import styles from './trip-type-selector.module.scss';
import { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import { RadioInput, Select } from '../../ui-elements';
import { useCallback } from 'react';

export interface TripTypeSelectorProps {
	name: string;
	options: OptionSelect[] | OptionsItems[];
	register: UseFormRegister<FieldValues>;
	onChange?: (e: InputEventsHandler) => void;
	setValue?: UseFormReturn['setValue'];
	defaultValue?: string;
	className?: string;
	customSelectClassName?: string;
	optionsMarginTop?: string;
	isRadioInpustSelector?: boolean;
	isRadioCustom?: boolean;
	customRadioLabelClassName?: string;
}

export const TripTypeSelector = ({
	name,
	options,
	register,
	onChange,
	setValue,
	defaultValue,
	className,
	customSelectClassName,
	optionsMarginTop,
	isRadioInpustSelector,
	isRadioCustom,
	customRadioLabelClassName,
}: TripTypeSelectorProps): JSX.Element => {
	const changeHandler = useCallback(
		(event: InputEventsHandler) => {
			if (name && setValue) {
				setValue(name, event.value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
			}

			if (onChange) {
				onChange(event);
			}
		},
		[name, register, onChange, setValue],
	);

	const ListRadio = (): JSX.Element => {
		return (
			<div className='tryType-radio__list flex justify-start flex-wrap md:flex-nowrap md:justify-center items-center gap-[24px]'>
				{options?.map((option, index) => (
					<RadioInput
						key={option.value}
						name={name}
						value={option.value}
						label={option.label}
						register={register(name, {
							onChange: (element: { target: InputEventsHandler }) => changeHandler(element.target),
						})}
						className={className}
						parentLabelClassName={'items-center'}
						labelClassName={`align-center text-xs font-normal leading-5 whitespace-nowrap text-[#101828] ${customRadioLabelClassName ?? ''}`}
						inputMarginCustomclass={'mr-2'}
						isCustom={isRadioCustom ?? false}
					/>
				))}
			</div>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			{isRadioInpustSelector ? (
				<ListRadio />
			) : (
				<Select
					name={name}
					options={options}
					register={register(name)}
					onChange={onChange}
					defaultValue={defaultValue}
					setValue={setValue}
					className={className}
					customSelectClassName={customSelectClassName}
					optionsMarginTop={optionsMarginTop}
				/>
			)}
		</>
	);
};

export default TripTypeSelector;
