import { GenericIntroduction } from '../../../generics/introductions/generic-introduction/generic-introduction';
import styles from './skeleton-cars-fleets.module.scss';

export interface SkeletonCarsFleetsProps {
	className?: string;
	introductionClassName?: string;
	introductionContainerClassName?: string;
	tabsContainerClassName?: string;
	onlyCards?: boolean;
}

export function SkeletonCarsFleets({
	className,
	introductionClassName,
	introductionContainerClassName,
	tabsContainerClassName,
	onlyCards,
}: SkeletonCarsFleetsProps): JSX.Element {
	const cardsElements: JSX.Element = (
		<>
			<div className={`${onlyCards ? 'animate-pulse' : ''} w-full md:flex md:flex-row md:justify-between md:items-center`}>
				{Array(3)
					.fill(0)
					.map((item: number, index: number) => (
						<div
							key={index}
							className='skeletonCarsFleets__topCard w-full md:w-[32%] md:min-w-[32%] h-[215px] mb-[15px] md:mb-[35px] rounded-[20px] bg-gray-200'
						/>
					))}
			</div>
			<div className={`${onlyCards ? 'animate-pulse' : ''} scale-90 pt-[10px] w-full hidden md:flex md:flex-row md:justify-between md:items-center`}>
				{Array(4)
					.fill(0)
					.map((item: number, index: number) => (
						<div key={index} className='skeletonCarsFleets__bottomCard min-w-[23.5%] w-[23.5%] h-[150px] rounded-[20px] bg-gray-200' />
					))}
			</div>
		</>
	);

	return onlyCards ? (
		cardsElements
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`skeletonCarsFleets animate-pulse ${className || ''}`}>
				<GenericIntroduction
					loading={true}
					title=''
					subtitle=''
					customClass={introductionContainerClassName}
					containerClassName={`md:px-12 ${introductionClassName || ''}`}
					link=''
					linkText=''
					description=''
				/>
				<div className={`skeletonCarsFleets__container md:flex md:flex-col md:items-center ${tabsContainerClassName || ''}`}>
					<div className='skeletonCarsFleets__tabs py-[10px] overflow-x-auto overflow-y-hidden'>
						<div className='flex justify-between h-[40px] min-w-[800px] w-full md:w-[800px]'>
							{Array(8)
								.fill(0)
								.map((item: number, index: number) => (
									<div className='w-[11%] h-[30px] rounded-[5px] bg-gray-200' key={index} />
								))}
						</div>
					</div>
					<div className='pt-[20px] flex flex-col items-center w-full'>{cardsElements}</div>
					<div className='skeletonCarsFleets__button pb-[30px] w-full md:w-[290px] h-[60px] mx-auto md:mt-[40px] rounded-[30px] bg-gray-200' />
				</div>
			</div>
		</>
	);
}
