import { Button, Checkbox, Input } from '../../../../lib/ui-elements';
import { ElementTypes, InputType } from '../../../../shared/enums';
import { useForm } from 'react-hook-form';

export interface ContactWithDescriptionProps {
	title: string;
	description: string;
	promoDescription?: string;
	privacityHtmlDescription?: string;
	actionText: string;
	t?: (value: string) => string;
	callBack?: (value: { phone: string; email: string; sendPromos: string }) => void;
}

export const ContactWithDescription = ({
	title,
	description,
	privacityHtmlDescription,
	promoDescription,
	actionText,
	t,
	callBack,
}: ContactWithDescriptionProps): JSX.Element => {
	const { register, handleSubmit } = useForm();
	const onSubmit = handleSubmit(data => {
		if (callBack) {
			callBack(data as { phone: string; email: string; sendPromos: string });
		}
	});

	return (
		<div className='contactWithDescription bg-gray-100'>
			<div className='contactWithDescription__container flex flex-col md:flex-row items-center justify-between py-[44px]'>
				<div className='px-3 md:px-6 lg:px-12 w-full md:w-6/12'>
					<h3 className='contactWithDescription__title text-[26px] lg:text-[36px] mb-6'>{title}</h3>
					<p className='contactWithDescription__description mb-4' dangerouslySetInnerHTML={{ __html: description }}></p>
				</div>

				<div className='px-3 md:px-6 lg:px-12 w-full md:w-6/12'>
					<form className='contactWithDescription__form' onSubmit={onSubmit}>
						<div className='contactWithDescription__item bg-white mb-4 px-[20px]'>
							<Input
								label={t ? t('phoneOrCellPhone') : 'phoneOrCellPhone'}
								type={'tel' as InputType}
								placeholder='ej. + (321) 00909'
								inputClassName='contactWithDescription__input bg-transparent'
								register={register('phone', {
									required: true,
								})}
							/>
						</div>

						<div className='contactWithDescription__item bg-white mb-4 px-[20px]'>
							<Input
								label={t ? t('email') : 'email'}
								placeholder='micorreo@mail.com'
								type={ElementTypes.email}
								inputClassName='contactWithDescription__input bg-transparent'
								register={register('email', {
									required: true,
									pattern: /^\S+@\S+$/,
								})}
							/>
						</div>

						<div className='contactWithDescription__item mb-4 flex'>
							<Checkbox label={promoDescription || ''} register={register('sendPromos')} labelClassName='text-xs' />
						</div>

						<Button customClass='contactWithDescription__btn !bg-gray-500 text-white mb-4' type='submit' text={actionText} />

						<div>
							<p className='contactWithDescription__privacy text-xs' dangerouslySetInnerHTML={{ __html: privacityHtmlDescription || '' }}></p>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
