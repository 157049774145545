export function SkeletonRecommendationV2(): JSX.Element {
	return (
		<>
			<div className={'recommendation-v2 rounded-lg bg-white shadow-lg animate-pulse'}>
				<div className={'recommendation-v2__container flex flex-col md:flex-row px-[20px] md:px-[35px] py-[12px]'}>
					<div className='flex flex-col md:flex-row w-full md:w-[70%]'>
						<div className={'recommendation-v2__info w-full md:w-[40%] md:mr-[2px]'}>
							<div className={'recommendation-v2__departureOrArrival flex flex-col'}>
								<p className='flex items-center justify-between md:justify-start mb-2'>
									<span className={'bg-gray-200 w-[24px] h-[21px] hidden md:block  mr-[10px] rounded-lg'}></span>
									<span className='uppercase recommendation-v2__title bg-gray-200 w-[52px] h-[24px] block rounded-lg'></span>
									<span className='recommendation-v2__tag md:hidden text-white  bg-gray-200 w-[123px] h-[20px] block rounded-full px-4 text-xs py-[2px] rounded-lg'></span>
								</p>

								<p className='recommendation-v2__subtile md:hidden bg-gray-200 w-[110px] h-[16px] block rounded-lg my-1'></p>
							</div>

							<div className='mb-1'>
								<span className='text-xs recommendation-v2__date bg-gray-200 w-[110px] h-[16px] block rounded-lg'></span>
							</div>

							<div className='items-center hidden md:flex mb-2'>
								<div className='bg-gray-200 w-[30px] h-[30px] rounded-lg'></div>
								<span className='text-xs recommendation-v2__airline ml-2 w-[100px] bg-gray-200 h-[16px] block rounded-lg'></span>
							</div>
						</div>

						<div className={'recommendation-v2__dates w-full md:w-[45%] text-center'}>
							<div className='flex items-center justify-around pt-[4px]'>
								<div className='flex justify-center flex-col items-center'>
									<p className={'recommendation-v2__dates-code bg-gray-200 w-[50px] h-[16px] block mb-1 rounded-lg'}></p>
									<p className={'recommendation-v2__dates-name text-xs bg-gray-200 w-[60px] h-[13px] block mb-1 rounded-lg'}></p>
									<p className={'recommendation-v2__dates-hour bg-gray-200 w-[50px] h-[16px] block rounded-lg'}></p>
								</div>

								<div className='flex flex-col justify-center items-center mx-2 md:w-[35%]'>
									<p className={'recommendation-v2__dates-scale text-xs bg-gray-200 w-[37px] h-[13px] block mb-1 rounded-lg'}></p>
									<div className='border-b border-gray-300 h-[1px] w-full '></div>
									<p className={'recommendation-v2__dates-scaleHour text-xs bg-gray-200 w-[37px] h-[13px] block mt-1 rounded-lg'}></p>
								</div>

								<div className='flex justify-center flex-col items-center'>
									<p className={'recommendation-v2__dates-code bg-gray-200 w-[50px] h-[16px] block mb-1 rounded-lg'}></p>
									<p className={'recommendation-v2__dates-name text-xs  bg-gray-200 w-[60px] h-[13px] block mb-1 rounded-lg'}></p>
									<p className={'recommendation-v2__dates-hour bg-gray-200 w-[50px] h-[16px] block rounded-lg'}></p>
								</div>
							</div>

							<div className='flex items-center md:hidden my-2'>
								<div className='bg-gray-200 w-[30px] h-[30px] rounded-lg mr-1'></div>
								<span className='text-xs recommendation-v2__airline w-[100px] bg-gray-200 h-[16px] block rounded-lg'></span>
							</div>
						</div>
					</div>

					<div className={'recommendation-v2__price border-t pt-2 md:pt-0 border-gray-500 md:border-t-0 w-full flex flex-col items-end md:w-[30%]'}>
						<span className='recommendation-v2__tag hidden md:block bg-gray-200 w-[123px] h-[20px] block rounded-full px-4 text-xs py-[2px] rounded-lg py-[2px] mb-2'></span>
						<p className='text-xs recommendation-v2__price-from'></p>
						<div className='flex items-center'>
							<p className='recommendation-v2__price-value whitespace-nowrap bg-gray-200 w-[173px] h-[24px] mb-1 rounded-lg'></p>
						</div>
						<p className='recommendation-v2__price-otherValue text-xs bg-gray-200 w-[69px] h-[16px] rounded-lg'></p>
					</div>
				</div>
			</div>
		</>
	);
}

export default SkeletonRecommendationV2;
