import { LocationOptionInterface } from '../../../shared/interfaces/generics/culture-inputs.interface';
import { InputEventsHandler, OptionSelect } from '../../../shared/interfaces/inputs.interface';
import { CultureInputsNames } from '../../../shared/enums/culture-inputs.enum';
import { useState, useCallback, useEffect } from 'react';
import Select from '../../ui-elements/select/select';
import Image from '../../ui-elements/image/image';
import styles from './culture-inputs.module.scss';

export interface CultureInputsProps {
	locations?: LocationOptionInterface[];
	currencies?: OptionSelect[];
	defaultLocationValue?: string;
	defaultCurrencyValue?: string;
	locationImageSize?: number;
	noShowCurrencySelect?: boolean;
	noShowLocationSelect?: boolean;
	onInputsChange?: (event: InputEventsHandler) => void;
	className?: string;
	locationSelectClassName?: string;
	currencySelectClassName?: string;
	locationSelectContainerClassName?: string;
	currencySelectContainerClassName?: string;
}

export const CultureInputs = ({
	locations,
	currencies,
	defaultLocationValue,
	defaultCurrencyValue,
	locationImageSize,
	noShowCurrencySelect,
	noShowLocationSelect,
	onInputsChange,
	className,
	locationSelectClassName,
	currencySelectClassName,
	locationSelectContainerClassName,
	currencySelectContainerClassName,
}: CultureInputsProps): JSX.Element => {
	const defaultImageSize: string = '40px';
	const [locationOptions, setLocationOptions] = useState<OptionSelect[]>([]);
	const [location, setLocation] = useState<string>(defaultLocationValue || '');
	const [currency, setCurrency] = useState<string>(defaultCurrencyValue || '');
	const selectHandler = useCallback(
		(event: InputEventsHandler): void => {
			if (event.name === CultureInputsNames.location) {
				setLocation(String(event.value));
			} else if (event.name === CultureInputsNames.curreny) {
				setCurrency(String(event.value));
			}

			if (onInputsChange) {
				onInputsChange(event);
			}
		},
		[onInputsChange],
	);

	useEffect(() => {
		setLocationOptions(
			locations && locations.length
				? locations.map((locationItem: LocationOptionInterface): OptionSelect => {
					return locationItem.option;
				  })
				: [],
		);
	}, []);

	const locationImage: LocationOptionInterface | undefined =
		locations && locations.length
			? locations.find((locationItem: LocationOptionInterface): boolean => locationItem.option.value === location)
			: undefined;

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`cultureInputs flex flex-row justify-start items-center ${className || ''}`}>
				{!noShowLocationSelect && (
					<div className={`flex flex-row justify-start items-center ${noShowLocationSelect ? 'hidden' : ''}`}>
						<Image
							url={locationImage ? locationImage.image : ''}
							width={locationImageSize ? `${locationImageSize}px` : defaultImageSize}
							height={locationImageSize ? `${locationImageSize}px` : defaultImageSize}
							styles={{
								width: locationImageSize ? `${locationImageSize}px` : defaultImageSize,
								height: locationImageSize ? `${locationImageSize}px` : defaultImageSize,
							}}
							customClass='align-middle object-cover rounded-full'
							className='block'
						/>
						<Select
							options={locationOptions}
							defaultValue={location}
							name={CultureInputsNames.location}
							className={`py-2.5 px-5 flex-col justify-center ${locationSelectContainerClassName || ''}`}
							selectClassName={`cultureInputs__select font-medium bg-transparent w-auto inline-block ${locationSelectClassName || ''}`}
							onChange={selectHandler}
						/>
					</div>
				)}

				{!noShowCurrencySelect && (
					<Select
						options={currencies && currencies.length ? currencies : []}
						defaultValue={currency}
						name={CultureInputsNames.curreny}
						className={`py-2.5 px-5 flex-col justify-center ${noShowCurrencySelect ? 'hidden' : ''} ${currencySelectContainerClassName || ''}`}
						selectClassName={`cultureInputs__select font-medium bg-transparent w-auto inline-block ${currencySelectClassName || ''}`}
						onChange={selectHandler}
					/>
				)}
			</div>
		</>
	);
};

CultureInputs.defaultProps = {
	className: '',
	locationSelectClassName: '',
	currencySelectClassName: '',
	locationSelectContainerClassName: '',
	currencySelectContainerClassName: '',
};

export default CultureInputs;
