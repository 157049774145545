import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import styles from './button-search.module.scss';

/* eslint-disable-next-line */
export interface ButtonSearchProps {
	iconName?: string;
	searchText?: string;
	colorSecondary?: string;
	placeHolder?: string;
	history?: ButtonSearchHistory;
	emitClick?: () => void;
	className?: string;
	isSecondaryTemplate?: boolean;
}

export function ButtonSearch({
	iconName,
	searchText,
	colorSecondary,
	emitClick,
	placeHolder,
	history,
	className,
	isSecondaryTemplate,
}: ButtonSearchProps): JSX.Element {
	const lineElement: JSX.Element = (
		<div
			className='buttonSearch__line w-[1px] h-full border-r border-r-solid mr-[10px]'
			style={{
				borderColor: colorSecondary,
			}}
		/>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`buttonSearch bg-white w-full pointers-none rounded-[10px] py-[5px] pr-[5px] flex flex-col ${className || ''}`}
				onClick={emitClick}
			>
				<div className={'buttonSearch__infoContainer w-full flex items-center'}>
					<div
						className='buttonSearch__ico text-center min-w-[70px] w-[70px] border-r border-r-solid pointers-none flex items-center justify-center py-[5px]'
						style={{
							borderColor: colorSecondary,
						}}
					>
						<span
							className={`${iconName || ''} text-[20px]`}
							style={{
								color: colorSecondary,
							}}
						/>
					</div>
					<div className='buttonSearch__container pointers-none overflow-x-hidden flex-grow flex items-center pl-[10px]'>
						{(history?.departure || history?.arrival) && (
							<div
								className='buttonSearch__destination flex items-center flex-wrap max-w-[50%] border-solid border-r-[1px]'
								style={{
									borderColor: colorSecondary,
								}}
							>
								{history?.departure && <span className='buttonSearch__departure block pr-[10px]'>{history.departure}</span>}
								{history?.arrival && (
									<>
										{history?.departure && lineElement}
										<span className={'buttonSearch__arrival block pr-[10px]'}>{history.arrival}</span>
									</>
								)}
							</div>
						)}
						{history?.departureDate && (
							<>
								{(history?.departure || history?.arrival) && lineElement}
								<div className={'buttonSearch__dates flex flex-col pr-[10px]'}>
									<span className='buttonSearch__departureDate whitespace-nowrap'>{history.departureDate}</span>
									{history?.arrivalDate && <span className='buttonSearch__arrivalDate whitespace-nowrap'>{history.arrivalDate}</span>}
								</div>
							</>
						)}
						{!history && (
							<span
								className='buttonSearch__placeHolder text-base font-bold pl-[15px]'
								style={{
									color: colorSecondary,
								}}
							>
								{placeHolder || 'Search ...'}
							</span>
						)}

						{history?.passengers && (
							<span
								className='buttonSearch__placeHolder text-base font-bold pl-[15px]'
								style={{
									color: colorSecondary,
								}}
							>
								{history?.passengers}
							</span>
						)}
					</div>
					<div
						className='buttonSearch__button min-w-[45px] w-[45px] h-[45px] pointers-none flex items-center justify-center rounded-[10px]'
						style={{
							background: colorSecondary,
						}}
					>
						<span className='searchIco text-white text-[20px]' />
					</div>
				</div>
				<div
					className={`buttonSearch__searchBtn  text-white w-full flex items-center justify-center py-3 px-6 mt-[10px] ${
						isSecondaryTemplate ? 'rounded-full' : 'rounded-lg'
					}`}
					style={{
						background: colorSecondary || '#878787',
					}}
				>
					{!isSecondaryTemplate && <span className='searchIco text-base font-extrabold' />}
					<span className={`text-base ${isSecondaryTemplate ? '' : 'pl-[10px]'}`}>{searchText || 'search'}</span>
				</div>
			</div>
		</>
	);
}

ButtonSearch.defaultProps = {
	iconName: 'planeIco',
	colorSecondary: '#878787',
	className: '',
};

export default ButtonSearch;
