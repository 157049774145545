import { GenericIntroduction } from '../../introductions/generic-introduction/generic-introduction';
import styles from './skeleton-informative-cards.module.scss';

export interface SkeletonInformativeCardsProps {
	className?: string;
	introductionContainerClassName?: string;
	introductionClassName?: string;
}

export function SkeletonInformativeCards({
	className,
	introductionContainerClassName,
	introductionClassName,
}: SkeletonInformativeCardsProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`skeletonInformativeCards animate-pulse ${className || ''}`}>
				<GenericIntroduction
					loading={true}
					title=''
					subtitle=''
					customClass={`${introductionClassName || ''}`}
					containerClassName={`w-full ${introductionContainerClassName || ''}`}
					link=''
					linkText=''
					description=''
				/>
				<div className='w-full block md:flex justify-between mt-2.5'>
					<div className='skeletonInformativeCards__card w-full md:w-[32%] h-[250px] md:h-[330px] bg-gray-200 rounded-[20px] mb-5 md:mb-0' />
					<div className='skeletonInformativeCards__card w-full md:w-[32%] h-[250px] md:h-[330px] bg-gray-200 rounded-[20px] mb-5 md:mb-0' />
					<div className='skeletonInformativeCards__card w-full md:w-[32%] h-[250px] md:h-[330px] bg-gray-200 rounded-[20px] mb-5 md:mb-0' />
				</div>
			</div>
		</>
	);
}
