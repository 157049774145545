import { PassengersFieldRestrictionsInterface, ListItemInterface } from '@smartlinks/react-design-system';
import { HotelsSearchLocationsInterface } from '../interfaces/hotels-locations.interface';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';

export class HotelsUtil {
	public static peopleByRoomRestrictions(maxAdults: number, maxChildren: number): PassengersFieldRestrictionsInterface {
		return {
			infants: {
				default: 0,
				minimum: 0,
				maximum: 0,
			},
			children: {
				default: 0,
				minimum: 0,
				maximum: maxAdults,
			},
			adults: {
				default: 1,
				minimum: 1,
				maximum: maxChildren,
			},
		};
	}

	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find((restriction: CalendarRestrictions) => restriction.product === CalendarRestrictionTypes.hotels)?.value || 0
		);
	}

	public static mapLocationsList(
		locations: HotelsSearchLocationsInterface[],
		isSecondaryTemplate: boolean = false,
		truncate: boolean = false,
	): ListItemInterface[] {
		const locationsList: ListItemInterface[] = [];
		locations.forEach((location: HotelsSearchLocationsInterface) => {
			const stateString: string = location.state ? `- ${location.state}` : '';
			const iataString: string = location.iata ? `(${location.iata})` : '';
			const locationKeyString: string = location.locationKey?.substring(0, 5) ?? '';
			const latitude: string = location.latitude ? String(location.latitude) : '';
			const longitude: string = location.longitude ? String(location.longitude) : '';
			const id: string = location.iata || `${locationKeyString}-${latitude}-${longitude}` || '';
			const mapAirport: ListItemInterface = {
				name: (
					<div>
						<span
							className={`${truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap' : ''} ${
								isSecondaryTemplate ? '' : 'md:ml-6 text-xs'
							} `}
						>
							<b>{`${location.city ?? ''} ${stateString} ${iataString}`}</b>
						</span>
						<br />
						<span
							className={`${truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap' : ''} ${
								isSecondaryTemplate ? '' : 'md:ml-6 text-xs'
							} `}
						>
							{location.state ? `${location.state}, ${location.country}` : location.country}
						</span>
					</div>
				),
				originalName: `${location.city ?? ''} ${stateString}`,
				id,
				value: location.iata,
				link: '',
				latitude: location.latitude ?? 0,
				longitude: location.longitude ?? 0,
				locationKey: location.locationKey ?? '',
			};

			locationsList.push({ ...mapAirport });
		});

		return [...locationsList];
	}
}
