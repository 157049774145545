import { SpecialServiceInterface, SpecialServiceEventHandler } from '../../../shared/interfaces';
import { numberToLocaleWithcurrency } from '../../../../../shared/services/utils.service';
import { InputEventsHandler } from '../../../../../shared/interfaces/inputs.interface';
import { CarsRatesModelsV2 } from '../../../shared/enums/filter-cars-cards-v2.enum';
import SpecialServiceCardSkeleton from './skeleton/skeleton-special-service-card';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import { FormsInputsType } from '../../../../../shared/enums/inputs.enum';
import Checkbox from '../../../../ui-elements/checkbox/checkbox';
import ToolTip from '../../../../ui-elements/tool-tip/tool-tip';
import Counter from '../../../../ui-elements/counter/counter';
import Modal from '../../../organisms/generics/modal/modal';
import { useState, useEffect, useCallback } from 'react';
import styles from './special-service-card.module.scss';

export interface SpecialServiceCardProps {
	specialService?: SpecialServiceInterface;
	moreInformationText: string;
	payOnLineText: string;
	paymentOnArrivalText: string;
	rateModel: CarsRatesModelsV2 | string;
	icon: string;
	loading?: boolean;
	colorPrimary?: string;
	zIndexModal?: number;
	extraZIndexModal?: number;
	topModal?: string;
	className?: string;
	style?: React.CSSProperties;
	onCheckboxChange?: (e: SpecialServiceEventHandler) => void;
}

export const SpecialServiceCard = ({
	specialService,
	moreInformationText,
	payOnLineText,
	paymentOnArrivalText,
	rateModel,
	icon,
	loading,
	colorPrimary,
	zIndexModal,
	extraZIndexModal,
	topModal,
	className,
	style,
	onCheckboxChange,
}: SpecialServiceCardProps): JSX.Element => {
	const [totalRate, setTotalRate] = useState<number>(0);
	const [currency, setCurrency] = useState<string>('');
	const [checked, setChecked] = useState<boolean>(false);
	const [counter, setCounter] = useState<number>(1);
	const [showModal, setShowModal] = useState<boolean>(false);
	const inputsChangeHandler = useCallback(
		(event: InputEventsHandler) => {
			if (specialService) {
				let tempCounter: number = counter;
				let tempChecked: boolean = checked;
				if (event.type === FormsInputsType.checkbox) {
					tempChecked = !!event.value;

					setChecked(tempChecked);
				} else if (event.type === FormsInputsType.counter) {
					tempCounter = Number(event.value);

					setCounter(tempCounter);
				}

				if (onCheckboxChange) {
					const tempValueEvent: SpecialServiceEventHandler = {
						code: specialService?.code,
						counter: tempCounter,
						checked: tempChecked,
					};

					onCheckboxChange({ ...tempValueEvent });
				}
			}
		},
		[specialService && specialService.code, counter, checked, onCheckboxChange],
	);

	useEffect(() => {
		if (!loading) {
			let tempTotalRate: number = 0;
			let tempCurrency: string = '';
			if (specialService && rateModel in specialService) {
				tempTotalRate = specialService[rateModel].amount || 0;

				tempCurrency = specialService[rateModel].currency || '';
			}

			setTotalRate(tempTotalRate);

			setCurrency(tempCurrency);
		}
	}, [rateModel, loading]);

	useEffect(() => {
		if (
			specialService &&
			specialService.quantitySpecialEquipment &&
			(specialService.quantitySpecialEquipment.min || specialService.quantitySpecialEquipment.min === 0)
		) {
			setCounter(specialService.quantitySpecialEquipment.min);
		}
	}, [specialService && specialService.quantitySpecialEquipment ? specialService.quantitySpecialEquipment.min : undefined]);

	return loading ? (
		<SpecialServiceCardSkeleton className={className} />
	) : specialService ? (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`specialServiceCard flex flex-col py-[15px] px-[15px] md:px-[35px] ${className || ''}`} style={style}>
				<div className='flex flex-row items-center'>
					<IcomoonIcon icon={icon} fontSize={35} color={'black'} iconClassName='specialServiceCard__icon' />
					<div className='flex-grow flex flex-col items-start justify-start pl-[16px] md:pl-[35px]'>
						<span className='specialServiceCard__name text-xs font-bold'>{specialService.name}</span>
						{specialService.description && specialService.description.replace(/\s/g, '') && (
							<button
								className='specialServiceCard__moreInformation inline-block mt-[5px] !p-0 text-[10px] font-medium'
								style={{
									color: colorPrimary,
								}}
								onClick={() => setShowModal(true)}
							>
								{moreInformationText}
							</button>
						)}
					</div>
					<div className='hidden md:flex flex-row items-center'>
						{!!specialService.quantitySpecialEquipment &&
							!!(specialService.quantitySpecialEquipment.max - specialService.quantitySpecialEquipment.min) && (
							<Counter
								defaultValue={specialService.quantitySpecialEquipment.min}
								name={`${specialService.code}-special-service-counter`}
								className={'pr-[48px] specialServiceCard__counter'}
								onChange={inputsChangeHandler}
								min={specialService.quantitySpecialEquipment.min}
								max={specialService.quantitySpecialEquipment.max}
							/>
						)}
						<p className='specialServiceCard__rate hidden md:block text-base font-bold' style={{ color: colorPrimary }}>
							{numberToLocaleWithcurrency(totalRate, currency)} <span className='specialServiceCard__currencyRate'>{currency}</span>
						</p>
					</div>
					<Checkbox
						name={`${specialService.code}-special-service-checkbox`}
						primaryColor={colorPrimary}
						secondaryColor={'#000000'}
						checkmarkContainerSize={20}
						className='specialServiceCard__select pl-[13px] md:pl-[25px]'
						onChange={inputsChangeHandler}
					/>
					<div className={'specialServiceCard__infoContainer flex justify-center pl-[15px] md:pl-[25px]'}>
						<ToolTip
							title={'<span class="specialServiceCard__infoIcon icon infoCirlce2Ico text-[15px]" style="color: black"></span>'}
							colorPrimary={'black'}
							body={`
                  <p class="specialServiceCard__infoText text-xs font-bold text-white m-0 whitespace-nowrap">
                    ${specialService.isPaidOnline ? payOnLineText : paymentOnArrivalText}
                  </p>
                  `}
							customClass='h-auto'
							titleClassName='m-0'
							toolTipCustomClass={'specialServiceCard__toolTip'}
						/>
					</div>
				</div>
				<div className='flex md:hidden flex-col items-center'>
					{!!specialService.quantitySpecialEquipment &&
						!!(specialService.quantitySpecialEquipment.max - specialService.quantitySpecialEquipment.min) && (
						<Counter
							defaultValue={specialService.quantitySpecialEquipment.min}
							name={`${specialService.code}-special-service-counter`}
							className={'pt-[16px] specialServiceCard__counter'}
							onChange={inputsChangeHandler}
							min={specialService.quantitySpecialEquipment.min}
							max={specialService.quantitySpecialEquipment.max}
						/>
					)}
					<p className='specialServiceCard__rate block md:hidden text-base text-center font-bold pt-[10px] m-0' style={{ color: colorPrimary }}>
						{numberToLocaleWithcurrency(totalRate, currency)} <span className='specialServiceCard__currencyRate'>{currency}</span>
					</p>
				</div>
				<Modal
					width={500}
					show={showModal}
					zIndex={zIndexModal}
					backgroundColor='rgba(0,0,0,0.4)'
					idComponent='special-service-description'
					onBackdropClick={() => setShowModal(false)}
					onCloseClick={() => setShowModal(false)}
					closeIconSize={16}
					showCloseButton={true}
					className='pt-5 pb-7 px-6'
					top={topModal || '0'}
					extraZIndex={extraZIndexModal}
				>
					<div
						className={
							'specialServiceCard__description flex flex-col justify-center items-center px-[5px] md:px-[36px] pb-[20px] md:pb-[7px] pt-[14px] md:pt-[34px]'
						}
						dangerouslySetInnerHTML={{ __html: specialService.description || '' }}
					/>
				</Modal>
			</div>
		</>
	) : (
		<></>
	);
};

SpecialServiceCard.defaultProps = {
	className: '',
};

export default SpecialServiceCard;
