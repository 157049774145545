import { PlansSearchFormSubmitInterface } from '../interfaces/plans-search-form.interface';
export class PlansSearchFormUtil {
	static mapSearchUrl(data: PlansSearchFormSubmitInterface): string {
		const searchUrl: string = `${data?.destination || ''}/${data?.departureDate || ''}`;
		return searchUrl;
	}

	public static historyHandle(
		historyJson: PlansSearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): PlansSearchFormSubmitInterface {
		const history: PlansSearchFormSubmitInterface = (historyJson && { ...historyJson }) || ({} as unknown as PlansSearchFormSubmitInterface);
		return { ...history };
	}
}
