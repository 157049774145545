import { CreateUserGuestResponse } from '../../shared/interfaces/user-guest.interface';
import BacCardPoints from '../../templates/bac-card-points/bac-card-points';
import { Agency } from '../../shared/interfaces/agency.interface';
import AgencyService from '../../shared/services/agency.service';
import userService from '../../shared/services/user.service';
import { CommonsUtil } from '../../shared/utils/commons';
import { useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

export interface PointsConsultationWidgetProps {
	elementId?: string;
	token: string;
	language?: string;
	callBack?: (element: HTMLDivElement | null) => void;
	callBackSendToken: (token: string) => void;
}

const AppPointsConsultationWidget = ({ token, callBackSendToken }: PointsConsultationWidgetProps): JSX.Element => {
	const widgetClassName: string = 'pointsConsultationWidget';
	const [agency, setAgency] = useState<Agency | null>(null);
	const [user, setUser] = useState<CreateUserGuestResponse | null>(null);
	const [loadingCardPoints, setLoadingCardPoints] = useState<boolean | null>(true);
	const { t } = useTranslation();
	const widgetStyle: React.CSSProperties = {
		fontFamily: '',
	};

	if (agency?.lookAndFeel?.style?.isSelfSourced) {
		widgetStyle.fontFamily = agency.lookAndFeel.style.fontFamily;
	}

	useEffect(() => {
		const fetchAgency = async (): Promise<void> => {
			try {
				const result = await AgencyService.getAgency(token);
				if (result) {
					setAgency(result);
				}
			} catch (error) {
				console.warn(error);
			}
		};

		const getUser = async (): Promise<void> => {
			try {
				const result = await userService.getUser(token);
				if (result) {
					setUser(result);
				}
			} catch (error) {
				console.warn(error);
			}
		};

		setLoadingCardPoints(true);

		Promise.all([fetchAgency(), getUser()]).finally(() => {
			setLoadingCardPoints(false);
		});
	}, [token]);

	return (
		<div id='pointsConsultationWidget' className={widgetClassName} style={widgetStyle}>
			<Suspense fallback={<div>...</div>}>
				{!loadingCardPoints && agency && <BacCardPoints agency={agency} token={token} t={t} callBackSendToken={callBackSendToken} user={user} />}
			</Suspense>
		</div>
	);
};

export function PointsConsultationWidget(props: PointsConsultationWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget pointsConsultation'>
					<AppPointsConsultationWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
