import { Image } from '../../../ui-elements/image/image';
export interface ShowCases {
	imageUrl: string;
	description?: string;
}

export interface ShowCaseMultiColumnProps {
	title?: string;
	options: ShowCases[];
}

export const ShowCaseMultiColumn = ({ title, options }: ShowCaseMultiColumnProps): JSX.Element => {
	return (
		<div className='showCasesMultiColumn'>
			<div className='showCasesMultiColumn__container'>
				<h2 className='showCasesMultiColumn__title text-center text-[26px] lg:text-[36px] mb-6 lg:mb-[67px]'>{title}</h2>

				<div className='showCasesMultiColumn__showcases flex justify-center items-center flex-col md:flex-row'>
					{options?.map((showcase, index) => (
						<div key={index} className='showCasesMultiColumn__showcases-item flex flex-col items-center md:px-[20px]'>
							<Image
								url={showcase.imageUrl}
								alt={showcase?.description || ''}
								width={'260px'}
								height={'165px'}
								className='showCasesMultiColumn__image overflow-hidden h-[165px]'
							/>
							<p
								className='showCasesMultiColumn__description md:h-[50px] text-center my-[10px] px-[10px] max-w-[550px]'
								dangerouslySetInnerHTML={{ __html: showcase?.description || '' }}
							></p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
