import { DisneyEventSearchResult, DisneyParkSearchResult } from '../interfaces';
import { ModelTypeEnum } from '../../../../shared/enums';
import { PassangerType } from '../enums/';

export const parkDayRecommendationMock: DisneyParkSearchResult[] = [
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 1,
			usageDate: {
				startDate: '2023-03-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2023-11-01T00:00:00',
				endDateFormated: 'mar., 01 nov. 2022',
				usageBufferDays: 0,
			},
			fareDetails: [
				{
					baseAmount: 149.1,
					baseAmountFormated: '$149.1 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 149.1,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$149.1 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 16401,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 143.78,
					baseAmountFormated: '$143.78 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 143.78,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$143.78 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 28401,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 146.44,
				totalPerDayPerPassengerFormated: '$146.44 USD',
				grandTotal: 292.88,
				amount: 292.88,
				amountFormated: '$292.88 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 292.88,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$292.88 USD',
				requiredPoints: 44400,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 1,
				usageBufferDays: 0,
				usageDate: {
					startDate: '2023-04-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2023-05-01T00:00:00',
					endDateFormated: 'mar., 01 nov. 2022',
					usageBufferDays: 0,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 146.44,
					totalPerDayPerPassengerFormated: '$146.44 USD',
					grandTotal: 292.88,
					amount: 292.88,
					amountFormated: '$292.88 USD',
					requiredPoints: 52749.0,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 292.88,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$292.88 USD',
				},
				fareDetails: [
					{
						baseAmount: 149.1,
						baseAmountFormated: '$149.1 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 149.1,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$149.1 USD',
						requiredPoints: 34882.0,
						pointsName: 'ThankYou Points',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
					},
					{
						baseAmount: 143.78,
						baseAmountFormated: '$143.78 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 143.78,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$143.78 USD',
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				promoCode: {
					code: 'DISF05',
					status: '',
					iconUrl: '',
					hidden: false,
					valueWithDiscount: 34.5,
					grandTotal: 0,
					currency: 'USD',
					valueWithDiscountPoints:500,
					isAppliedInPoints: true,
					grandTotalPoints: 700,
					currencyPoints: 'Thankyou Points',
					reason: '',
					percentageAmount: 30,
					fixedAmount: 0,
					requiredAmount: 0,
					totalFee: 0,
					payFeeInPoints: false,
					appliedByPassenger: false,
					pointsDiscountedValue: 200,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 1,
				usageBufferDays: 0,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-01T00:00:00',
					endDateFormated: 'mar., 01 nov. 2022',
					usageBufferDays: 0,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 215.665,
					totalPerDayPerPassengerFormated: '$215.67 USD',
					grandTotal: 431.33,
					amount: 431.33,
					amountFormated: '$431.33 USD',
					requiredPoints: 55280,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 431.33,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$431.33 USD',
				},
				fareDetails: [
					{
						baseAmount: 218.33,
						baseAmountFormated: '$218.33 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 218.33,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$218.33 USD',
						requiredPoints: 34882.0,
						pointsName: 'ThankYou Points',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
					},
					{
						baseAmount: 213,
						baseAmountFormated: '$213 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 213,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$213 USD',
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 1,
				usageBufferDays: 1,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-02T00:00:00',
					endDateFormated: 'mié., 02 nov. 2022',
					usageBufferDays: 1,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 215.665,
					totalPerDayPerPassengerFormated: '$215.67 USD',
					grandTotal: 431.33,
					amount: 431.33,
					amountFormated: '$431.33 USD',
					requiredPoints: 65635,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 431.33,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$431.33 USD',
				},
				fareDetails: [
					{
						baseAmount: 223.65,
						baseAmountFormated: '$223.65 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 223.65,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$223.65 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 218.33,
						baseAmountFormated: '$218.33 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 218.33,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$218.33 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 1,
				usageBufferDays: 1,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-02T00:00:00',
					endDateFormated: 'mié., 02 nov. 2022',
					usageBufferDays: 1,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 215.665,
					totalPerDayPerPassengerFormated: '$215.67 USD',
					grandTotal: 431.33,
					amount: 431.33,
					amountFormated: '$431.33 USD',
					requiredPoints: 74812,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 431.33,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$431.33 USD',
				},
				fareDetails: [
					{
						baseAmount: 239.63,
						baseAmountFormated: '$239.63 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 239.63,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$239.63 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 234.3,
						baseAmountFormated: '$234.3 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 234.3,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$234.3 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 2,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-04T00:00:00',
				endDateFormated: 'vie., 04 nov. 2022',
				usageBufferDays: 2,
			},
			fareDetails: [
				{
					baseAmount: 295.11,
					baseAmountFormated: '$295.11 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 295.11,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$295.11 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 18512,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 284.74,
					baseAmountFormated: '$284.74 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 284.74,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$284.74 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 39512,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 144.9625,
				totalPerDayPerPassengerFormated: '$144.96 USD',
				grandTotal: 579.85,
				amount: 579.85,
				amountFormated: '$579.85 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 579.85,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$579.85 USD',
				requiredPoints: 55511,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 2,
				usageBufferDays: 2,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-04T00:00:00',
					endDateFormated: 'vie., 04 nov. 2022',
					usageBufferDays: 2,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 215.665,
					totalPerDayPerPassengerFormated: '$215.67 USD',
					grandTotal: 431.33,
					amount: 431.33,
					amountFormated: '$431.33 USD',
					requiredPoints: 51230,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 431.33,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$431.33 USD',
				},
				fareDetails: [
					{
						baseAmount: 295.11,
						baseAmountFormated: '$295.11 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 295.11,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$295.11 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 284.74,
						baseAmountFormated: '$284.74 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 284.74,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$284.74 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 2,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-05T00:00:00',
					endDateFormated: 'sáb., 05 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 215.665,
					totalPerDayPerPassengerFormated: '$215.67 USD',
					grandTotal: 431.33,
					amount: 431.33,
					amountFormated: '$431.33 USD',
					requiredPoints: 31230,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 431.33,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$431.33 USD',
				},
				fareDetails: [
					{
						baseAmount: 369.66,
						baseAmountFormated: '$369.66 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 369.66,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$369.66 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 359.29,
						baseAmountFormated: '$359.29 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 359.29,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$359.29 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 2,
				usageBufferDays: 2,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-04T00:00:00',
					endDateFormated: 'vie., 04 nov. 2022',
					usageBufferDays: 2,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 184.9,
					totalPerDayPerPassengerFormated: '$184.9 USD',
					grandTotal: 739.6,
					amount: 739.6,
					amountFormated: '$739.6 USD',
					requiredPoints: 65336,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 739.6,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$739.6 USD',
				},
				fareDetails: [
					{
						baseAmount: 374.99,
						baseAmountFormated: '$374.99 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 374.99,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$374.99 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 364.61,
						baseAmountFormated: '$364.61 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 364.61,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$364.61 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 2,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-05T00:00:00',
					endDateFormated: 'sáb., 05 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 195.55,
					totalPerDayPerPassengerFormated: '$195.55 USD',
					grandTotal: 782.2,
					amount: 782.2,
					amountFormated: '$782.2 USD',
					requiredPoints: 45334,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 782.2,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$782.2 USD',
				},
				fareDetails: [
					{
						baseAmount: 396.29,
						baseAmountFormated: '$396.29 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 396.29,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$396.29 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 385.91,
						baseAmountFormated: '$385.91 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 385.91,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$385.91 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 33800.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 3,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-05T00:00:00',
				endDateFormated: 'sáb., 05 nov. 2022',
				usageBufferDays: 2,
			},
			fareDetails: [
				{
					baseAmount: 385.27,
					baseAmountFormated: '$385.27 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 385.27,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$385.27 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 29623,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 371.95,
					baseAmountFormated: '$371.95 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 371.95,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$371.95 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 41623,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 126.20333333333333,
				totalPerDayPerPassengerFormated: '$126.2 USD',
				grandTotal: 757.22,
				amount: 757.22,
				amountFormated: '$757.22 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 757.22,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$757.22 USD',
				requiredPoints: 66754,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 3,
				usageBufferDays: 2,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-05T00:00:00',
					endDateFormated: 'sáb., 05 nov. 2022',
					usageBufferDays: 2,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 126.20333333333333,
					totalPerDayPerPassengerFormated: '$126.2 USD',
					grandTotal: 757.22,
					amount: 757.22,
					amountFormated: '$757.22 USD',
					requiredPoints: 76544,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 757.22,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$757.22 USD',
				},
				fareDetails: [
					{
						baseAmount: 385.27,
						baseAmountFormated: '$385.27 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 385.27,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$385.27 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 371.95,
						baseAmountFormated: '$371.95 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 371.95,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$371.95 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINTS,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 3,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-06T00:00:00',
					endDateFormated: 'dom., 06 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 148.32,
					totalPerDayPerPassengerFormated: '$148.32 USD',
					grandTotal: 889.92,
					amount: 889.92,
					amountFormated: '$889.92 USD',
					requiredPoints: 87655,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 889.92,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$889.92 USD',
				},
				fareDetails: [
					{
						baseAmount: 451.62,
						baseAmountFormated: '$451.62 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 451.62,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$451.62 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882.0,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 438.3,
						baseAmountFormated: '$438.3 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 438.3,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$438.3 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882.0,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.CASH,
			},
			{
				name: 'Park Hopper',
				durationDays: 3,
				usageBufferDays: 2,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-05T00:00:00',
					endDateFormated: 'sáb., 05 nov. 2022',
					usageBufferDays: 2,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 149.9,
					totalPerDayPerPassengerFormated: '$149.9 USD',
					grandTotal: 899.4,
					amount: 899.4,
					amountFormated: '$899.4 USD',
					requiredPoints: 76766,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 899.4,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$899.4 USD',
				},
				fareDetails: [
					{
						baseAmount: 456.36,
						baseAmountFormated: '$456.36 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 456.36,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$456.36 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 443.04,
						baseAmountFormated: '$443.04 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 443.04,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$443.04 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 3,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-06T00:00:00',
					endDateFormated: 'dom., 06 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 156.22,
					totalPerDayPerPassengerFormated: '$156.22 USD',
					grandTotal: 937.32,
					amount: 937.32,
					amountFormated: '$937.32 USD',
					requiredPoints: 87654,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 937.32,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$937.32 USD',
				},
				fareDetails: [
					{
						baseAmount: 475.32,
						baseAmountFormated: '$475.32 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 475.32,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$475.32 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 462,
						baseAmountFormated: '$462 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 462,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$462 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINTS,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 4,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-07T00:00:00',
				endDateFormated: 'lun., 07 nov. 2022',
				usageBufferDays: 3,
			},
			fareDetails: [
				{
					baseAmount: 489.57,
					baseAmountFormated: '$489.57 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 489.57,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$489.57 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 31734,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 120.305,
				totalPerDayPerPassengerFormated: '$120.31 USD',
				grandTotal: 962.44,
				amount: 962.44,
				amountFormated: '$962.44 USD',
				currency: 'USD',
				netTotalFare: 962.44,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$962.44 USD',
				requiredPoints: 87500,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 4,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-07T00:00:00',
					endDateFormated: 'lun., 07 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 120.305,
					totalPerDayPerPassengerFormated: '$120.31 USD',
					grandTotal: 962.44,
					amount: 962.44,
					amountFormated: '$962.44 USD',
					requiredPoints: 45566,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 962.44,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$962.44 USD',
				},
				fareDetails: [
					{
						baseAmount: 489.57,
						baseAmountFormated: '$489.57 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 489.57,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$489.57 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 472.87,
						baseAmountFormated: '$472.87 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 472.87,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$472.87 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 4,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-08T00:00:00',
					endDateFormated: 'mar., 08 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 136.8925,
					totalPerDayPerPassengerFormated: '$136.89 USD',
					grandTotal: 1095.14,
					amount: 1095.14,
					amountFormated: '$1,095.14 USD',
					requiredPoints: 87677,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1095.14,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,095.14 USD',
				},
				fareDetails: [
					{
						baseAmount: 555.92,
						baseAmountFormated: '$555.92 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 555.92,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$555.92 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 539.22,
						baseAmountFormated: '$539.22 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 539.22,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$539.22 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 4,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-07T00:00:00',
					endDateFormated: 'lun., 07 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 140.4475,
					totalPerDayPerPassengerFormated: '$140.45 USD',
					grandTotal: 1123.58,
					amount: 1123.58,
					amountFormated: '$1,123.58 USD',
					requiredPoints: 67755,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1123.58,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,123.58 USD',
				},
				fareDetails: [
					{
						baseAmount: 570.14,
						baseAmountFormated: '$570.14 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 570.14,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$570.14 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 553.44,
						baseAmountFormated: '$553.44 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 553.44,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$553.44 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 4,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-08T00:00:00',
					endDateFormated: 'mar., 08 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 145.185,
					totalPerDayPerPassengerFormated: '$145.19 USD',
					grandTotal: 1161.48,
					amount: 1161.48,
					amountFormated: '$1,161.48 USD',
					requiredPoints: 87666,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1161.48,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,161.48 USD',
				},
				fareDetails: [
					{
						baseAmount: 589.09,
						baseAmountFormated: '$589.09 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 589.09,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$589.09 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 572.39,
						baseAmountFormated: '$572.39 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 572.39,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$572.39 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 5,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-08T00:00:00',
				endDateFormated: 'mar., 08 nov. 2022',
				usageBufferDays: 3,
			},
			fareDetails: [
				{
					baseAmount: 515.23,
					baseAmountFormated: '$515.23 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 515.23,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$515.23 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 31734,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 497.46,
					baseAmountFormated: '$497.46 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 497.46,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$497.46 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 43575,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 101.269,
				totalPerDayPerPassengerFormated: '$101.27 USD',
				grandTotal: 1012.69,
				amount: 1012.69,
				amountFormated: '$1,012.69 USD',
				currency: 'USD',
				netTotalFare: 1012.69,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$1,012.69 USD',
				requiredPoints: 65434,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 5,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-08T00:00:00',
					endDateFormated: 'mar., 08 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 101.269,
					totalPerDayPerPassengerFormated: '$101.27 USD',
					grandTotal: 1012.69,
					amount: 1012.69,
					amountFormated: '$1,012.69 USD',
					requiredPoints: 98777,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1012.69,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,012.69 USD',
				},
				fareDetails: [
					{
						baseAmount: 515.23,
						baseAmountFormated: '$515.23 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 515.23,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$515.23 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 497.46,
						baseAmountFormated: '$497.46 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 497.46,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$497.46 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 5,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-09T00:00:00',
					endDateFormated: 'mié., 09 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 114.24,
					totalPerDayPerPassengerFormated: '$114.24 USD',
					grandTotal: 1142.4,
					amount: 1142.4,
					amountFormated: '$1,142.4 USD',
					requiredPoints: 54454,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1142.4,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,142.4 USD',
				},
				fareDetails: [
					{
						baseAmount: 580.08,
						baseAmountFormated: '$580.08 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 580.08,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$580.08 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 562.32,
						baseAmountFormated: '$562.32 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 562.32,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$562.32 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 5,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-08T00:00:00',
					endDateFormated: 'mar., 08 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 117.02,
					totalPerDayPerPassengerFormated: '$117.02 USD',
					grandTotal: 1170.2,
					amount: 1170.2,
					amountFormated: '$1,170.2 USD',
					requiredPoints: 43555,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1170.2,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,170.2 USD',
				},
				fareDetails: [
					{
						baseAmount: 593.98,
						baseAmountFormated: '$593.98 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 593.98,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$593.98 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 576.22,
						baseAmountFormated: '$576.22 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 576.22,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$576.22 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 5,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-09T00:00:00',
					endDateFormated: 'mié., 09 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 120.726,
					totalPerDayPerPassengerFormated: '$120.73 USD',
					grandTotal: 1207.26,
					amount: 1207.26,
					amountFormated: '$1,207.26 USD',
					requiredPoints: 76444,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1207.26,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,207.26 USD',
				},
				fareDetails: [
					{
						baseAmount: 612.51,
						baseAmountFormated: '$612.51 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 612.51,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$612.51 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 594.75,
						baseAmountFormated: '$594.75 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 594.75,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$594.75 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 6,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-09T00:00:00',
				endDateFormated: 'mié., 09 nov. 2022',
				usageBufferDays: 3,
			},
			fareDetails: [
				{
					baseAmount: 532.78,
					baseAmountFormated: '$532.78 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 532.78,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$532.78 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 65435,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 514.32,
					baseAmountFormated: '$514.32 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 514.32,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$514.32 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 67345,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 87.25833333333334,
				totalPerDayPerPassengerFormated: '$87.26 USD',
				grandTotal: 1047.1,
				amount: 1047.1,
				amountFormated: '$1,047.1 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 1047.1,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$1,047.1 USD',
				requiredPoints: 67445,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 6,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-09T00:00:00',
					endDateFormated: 'mié., 09 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 87.25833333333334,
					totalPerDayPerPassengerFormated: '$87.26 USD',
					grandTotal: 1047.1,
					amount: 1047.1,
					amountFormated: '$1,047.1 USD',
					requiredPoints: 676855,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1047.1,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,047.1 USD',
				},
				fareDetails: [
					{
						baseAmount: 532.78,
						baseAmountFormated: '$532.78 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 532.78,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$532.78 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,

						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 514.32,
						baseAmountFormated: '$514.32 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 514.32,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$514.32 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,

						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 6,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-10T00:00:00',
					endDateFormated: 'jue., 10 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 98.06833333333333,
					totalPerDayPerPassengerFormated: '$98.07 USD',
					grandTotal: 1176.82,
					amount: 1176.82,
					amountFormated: '$1,176.82 USD',
					requiredPoints: 565444,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1176.82,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,176.82 USD',
				},
				fareDetails: [
					{
						baseAmount: 597.64,
						baseAmountFormated: '$597.64 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 597.64,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$597.64 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 579.18,
						baseAmountFormated: '$579.18 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 579.18,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$579.18 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 6,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-09T00:00:00',
					endDateFormated: 'mié., 09 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 100.38416666666667,
					totalPerDayPerPassengerFormated: '$100.38 USD',
					grandTotal: 1204.61,
					amount: 1204.61,
					amountFormated: '$1,204.61 USD',
					requiredPoints: 764455,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1204.61,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,204.61 USD',
				},
				fareDetails: [
					{
						baseAmount: 611.53,
						baseAmountFormated: '$611.53 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 611.53,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$611.53 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 593.08,
						baseAmountFormated: '$593.08 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 593.08,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$593.08 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 6,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-10T00:00:00',
					endDateFormated: 'jue., 10 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 103.4725,
					totalPerDayPerPassengerFormated: '$103.47 USD',
					grandTotal: 1241.67,
					amount: 1241.67,
					amountFormated: '$1,241.67 USD',
					requiredPoints: 656775,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1241.67,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,241.67 USD',
				},
				fareDetails: [
					{
						baseAmount: 630.06,
						baseAmountFormated: '$630.06 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 630.06,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$630.06 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 611.61,
						baseAmountFormated: '$611.61 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 611.61,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$611.61 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 7,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-10T00:00:00',
				endDateFormated: 'jue., 10 nov. 2022',
				usageBufferDays: 3,
			},
			fareDetails: [
				{
					baseAmount: 550.26,
					baseAmountFormated: '$550.26 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 550.26,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$550.26 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 99854,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 531.14,
					baseAmountFormated: '$531.14 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 531.14,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$531.14 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 56332,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 77.24285714285715,
				totalPerDayPerPassengerFormated: '$77.24 USD',
				grandTotal: 1081.4,
				amount: 1081.4,
				amountFormated: '$1,081.4 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 1081.4,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$1,081.4 USD',
				requiredPoints: 45633,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 7,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-10T00:00:00',
					endDateFormated: 'jue., 10 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 77.24285714285715,
					totalPerDayPerPassengerFormated: '$77.24 USD',
					grandTotal: 1081.4,
					amount: 1081.4,
					amountFormated: '$1,081.4 USD',
					requiredPoints: 767684,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1081.4,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,081.4 USD',
				},
				fareDetails: [
					{
						baseAmount: 550.26,
						baseAmountFormated: '$550.26 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 550.26,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$550.26 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 531.14,
						baseAmountFormated: '$531.14 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 531.14,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$531.14 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 7,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-11T00:00:00',
					endDateFormated: 'vie., 11 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 86.50857142857143,
					totalPerDayPerPassengerFormated: '$86.51 USD',
					grandTotal: 1211.12,
					amount: 1211.12,
					amountFormated: '$1,211.12 USD',
					requiredPoints: 657766,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1211.12,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,211.12 USD',
				},
				fareDetails: [
					{
						baseAmount: 615.12,
						baseAmountFormated: '$615.12 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 615.12,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$615.12 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 596,
						baseAmountFormated: '$596 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 596,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$596 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 7,
				usageBufferDays: 3,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-10T00:00:00',
					endDateFormated: 'jue., 10 nov. 2022',
					usageBufferDays: 3,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 88.49357142857143,
					totalPerDayPerPassengerFormated: '$88.49 USD',
					grandTotal: 1238.91,
					amount: 1238.91,
					amountFormated: '$1,238.91 USD',
					requiredPoints: 7655654,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1238.91,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,238.91 USD',
				},
				fareDetails: [
					{
						baseAmount: 629.02,
						baseAmountFormated: '$629.02 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 629.02,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$629.02 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 609.89,
						baseAmountFormated: '$609.89 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 609.89,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$609.89 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 7,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-11T00:00:00',
					endDateFormated: 'vie., 11 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 91.14071428571428,
					totalPerDayPerPassengerFormated: '$91.14 USD',
					grandTotal: 1275.97,
					amount: 1275.97,
					amountFormated: '$1,275.97 USD',
					requiredPoints: 656445,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1275.97,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,275.97 USD',
				},
				fareDetails: [
					{
						baseAmount: 647.55,
						baseAmountFormated: '$647.55 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 647.55,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$647.55 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 628.42,
						baseAmountFormated: '$628.42 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 628.42,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$628.42 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 8,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-12T00:00:00',
				endDateFormated: 'sáb., 12 nov. 2022',
				usageBufferDays: 4,
			},
			fareDetails: [
				{
					baseAmount: 580.71,
					baseAmountFormated: '$580.71 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 580.71,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$580.71 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 67543,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 560.7,
					baseAmountFormated: '$560.7 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 560.7,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$560.7 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 56533,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 71.338125,
				totalPerDayPerPassengerFormated: '$71.34 USD',
				grandTotal: 1141.41,
				amount: 1141.41,
				amountFormated: '$1,141.41 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 1141.41,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$1,141.41 USD',
				requiredPoints: 34223,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 8,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-12T00:00:00',
					endDateFormated: 'sáb., 12 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 71.338125,
					totalPerDayPerPassengerFormated: '$71.34 USD',
					grandTotal: 1141.41,
					amount: 1141.41,
					amountFormated: '$1,141.41 USD',
					requiredPoints: 565656,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1141.41,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,141.41 USD',
				},
				fareDetails: [
					{
						baseAmount: 580.71,
						baseAmountFormated: '$580.71 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 580.71,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$580.71 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 560.7,
						baseAmountFormated: '$560.7 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 560.7,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$560.7 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 8,
				usageBufferDays: 5,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-13T00:00:00',
					endDateFormated: 'dom., 13 nov. 2022',
					usageBufferDays: 5,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 79.445625,
					totalPerDayPerPassengerFormated: '$79.45 USD',
					grandTotal: 1271.13,
					amount: 1271.13,
					amountFormated: '$1,271.13 USD',
					requiredPoints: 356546,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1271.13,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,271.13 USD',
				},
				fareDetails: [
					{
						baseAmount: 645.57,
						baseAmountFormated: '$645.57 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 645.57,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$645.57 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 625.56,
						baseAmountFormated: '$625.56 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 625.56,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$625.56 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 8,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-12T00:00:00',
					endDateFormated: 'sáb., 12 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 81.183125,
					totalPerDayPerPassengerFormated: '$81.18 USD',
					grandTotal: 1298.93,
					amount: 1298.93,
					amountFormated: '$1,298.93 USD',
					requiredPoints: 3544666,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1298.93,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,298.93 USD',
				},
				fareDetails: [
					{
						baseAmount: 659.47,
						baseAmountFormated: '$659.47 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 659.47,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$659.47 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 639.46,
						baseAmountFormated: '$639.46 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 639.46,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$639.46 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 8,
				usageBufferDays: 5,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-13T00:00:00',
					endDateFormated: 'dom., 13 nov. 2022',
					usageBufferDays: 5,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 83.499375,
					totalPerDayPerPassengerFormated: '$83.5 USD',
					grandTotal: 1335.99,
					amount: 1335.99,
					amountFormated: '$1,335.99 USD',
					requiredPoints: 51230,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1335.99,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,335.99 USD',
				},
				fareDetails: [
					{
						baseAmount: 678,
						baseAmountFormated: '$678 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 678,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$678 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 657.99,
						baseAmountFormated: '$657.99 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 657.99,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$657.99 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 9,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-13T00:00:00',
				endDateFormated: 'dom., 13 nov. 2022',
				usageBufferDays: 4,
			},
			fareDetails: [
				{
					baseAmount: 598.88,
					baseAmountFormated: '$598.88 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 598.88,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$598.88 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 65334,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 578.28,
					baseAmountFormated: '$578.28 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 578.28,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$578.28 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 76443,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 65.39777777777778,
				totalPerDayPerPassengerFormated: '$65.4 USD',
				grandTotal: 1177.16,
				amount: 1177.16,
				amountFormated: '$1,177.16 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 1177.16,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$1,177.16 USD',
				requiredPoints: 22343,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 9,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-13T00:00:00',
					endDateFormated: 'dom., 13 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 65.39777777777778,
					totalPerDayPerPassengerFormated: '$65.4 USD',
					grandTotal: 1177.16,
					amount: 1177.16,
					amountFormated: '$1,177.16 USD',
					requiredPoints: 4556767,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1177.16,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,177.16 USD',
				},
				fareDetails: [
					{
						baseAmount: 598.88,
						baseAmountFormated: '$598.88 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 598.88,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$598.88 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 578.28,
						baseAmountFormated: '$578.28 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 578.28,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$578.28 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 9,
				usageBufferDays: 5,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-14T00:00:00',
					endDateFormated: 'lun., 14 nov. 2022',
					usageBufferDays: 5,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 72.60444444444444,
					totalPerDayPerPassengerFormated: '$72.6 USD',
					grandTotal: 1306.88,
					amount: 1306.88,
					amountFormated: '$1,306.88 USD',
					requiredPoints: 875656,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1306.88,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,306.88 USD',
				},
				fareDetails: [
					{
						baseAmount: 663.74,
						baseAmountFormated: '$663.74 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 663.74,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$663.74 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 643.14,
						baseAmountFormated: '$643.14 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 643.14,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$643.14 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 9,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-13T00:00:00',
					endDateFormated: 'dom., 13 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 74.14888888888889,
					totalPerDayPerPassengerFormated: '$74.15 USD',
					grandTotal: 1334.68,
					amount: 1334.68,
					amountFormated: '$1,334.68 USD',
					requiredPoints: 6767989,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1334.68,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,334.68 USD',
				},
				fareDetails: [
					{
						baseAmount: 677.64,
						baseAmountFormated: '$677.64 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 677.64,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$677.64 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 657.04,
						baseAmountFormated: '$657.04 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 657.04,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$657.04 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 9,
				usageBufferDays: 5,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-14T00:00:00',
					endDateFormated: 'lun., 14 nov. 2022',
					usageBufferDays: 5,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 76.20777777777778,
					totalPerDayPerPassengerFormated: '$76.21 USD',
					grandTotal: 1371.74,
					amount: 1371.74,
					amountFormated: '$1,371.74 USD',
					requiredPoints: 454566,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1371.74,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,371.74 USD',
				},
				fareDetails: [
					{
						baseAmount: 696.17,
						baseAmountFormated: '$696.17 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 696.17,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$696.17 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 675.57,
						baseAmountFormated: '$675.57 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 675.57,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$675.57 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 10,
			usageDate: {
				startDate: '2022-11-01T00:00:00',
				startDateFormated: 'mar., 01 nov. 2022',
				endDate: '2022-11-14T00:00:00',
				endDateFormated: 'lun., 14 nov. 2022',
				usageBufferDays: 4,
			},
			fareDetails: [
				{
					baseAmount: 612.34,
					baseAmountFormated: '$612.34 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 612.34,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$612.34 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 1,
					requiredPoints: 65775,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 591.21,
					baseAmountFormated: '$591.21 USD',
					totalTaxes: 0,
					discounts: 0,
					grandTotal: 591.21,
					totalFormated: '$166,03 USD',
					grandTotalFormated: '$591.21 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 1,
					requiredPoints: 65334,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 60.1775,
				totalPerDayPerPassengerFormated: '$60.18 USD',
				grandTotal: 1203.55,
				amount: 1203.55,
				amountFormated: '$1,203.55 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 1203.55,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$1,203.55 USD',
				requiredPoints: 11567,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 10,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-14T00:00:00',
					endDateFormated: 'lun., 14 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 60.1775,
					totalPerDayPerPassengerFormated: '$60.18 USD',
					grandTotal: 1203.55,
					amount: 1203.55,
					amountFormated: '$1,203.55 USD',
					requiredPoints: 6767788,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1203.55,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,203.55 USD',
				},
				fareDetails: [
					{
						baseAmount: 612.34,
						baseAmountFormated: '$612.34 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 612.34,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$612.34 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,

						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 591.21,
						baseAmountFormated: '$591.21 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 591.21,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$591.21 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,

						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 10,
				usageBufferDays: 5,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-15T00:00:00',
					endDateFormated: 'mar., 15 nov. 2022',
					usageBufferDays: 5,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 66.6635,
					totalPerDayPerPassengerFormated: '$66.66 USD',
					grandTotal: 1333.27,
					amount: 1333.27,
					amountFormated: '$1,333.27 USD',
					requiredPoints: 5576877,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1333.27,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,333.27 USD',
				},
				fareDetails: [
					{
						baseAmount: 677.2,
						baseAmountFormated: '$677.2 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 677.2,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$677.2 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 656.07,
						baseAmountFormated: '$656.07 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 656.07,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$656.07 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 10,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-14T00:00:00',
					endDateFormated: 'lun., 14 nov. 2022',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 68.0535,
					totalPerDayPerPassengerFormated: '$68.05 USD',
					grandTotal: 1361.07,
					amount: 1361.07,
					amountFormated: '$1,361.07 USD',
					requiredPoints: 5656767,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1361.07,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,361.07 USD',
				},
				fareDetails: [
					{
						baseAmount: 691.1,
						baseAmountFormated: '$691.1 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 691.1,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$691.1 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 669.97,
						baseAmountFormated: '$669.97 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 669.97,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$669.97 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 10,
				usageBufferDays: 5,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-15T00:00:00',
					endDateFormated: 'mar., 15 nov. 2022',
					usageBufferDays: 5,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 69.9065,
					totalPerDayPerPassengerFormated: '$69.91 USD',
					grandTotal: 1398.13,
					amount: 1398.13,
					amountFormated: '$1,398.13 USD',
					requiredPoints: 6767866,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1398.13,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,398.13 USD',
				},
				fareDetails: [
					{
						baseAmount: 709.63,
						baseAmountFormated: '$709.63 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 709.63,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$709.63 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 688.5,
						baseAmountFormated: '$688.5 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 688.5,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$688.5 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
	{
		paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
		information: {
			name: 'Base Ticket',
			durationDays: 11,
			usageDate: {
				startDate: '2024-04-15T00:00:00',
				startDateFormated: 'lun., 15 abr. 2024',
				endDate: '2024-04-15T00:00:00',
				endDateFormated: 'lun., 15 abr. 2024',
				usageBufferDays: 0,
				usageValidDates: 'el lun., 15 abr. 2024',
			},
			fareDetails: [
				{
					baseAmount: 158.69,
					baseAmountFormated: '$158,69 USD',
					totalTaxes: 0,
					discounts: 0,
					total: 158.69,
					totalFormated: '$158,69 USD',
					grandTotal: 317.38,
					grandTotalFormated: '$317,38 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.adult,
					passengerQuantity: 2,
					requiredPoints: 16401,
					pointsName: 'ThankYou Points',
				},
				{
					baseAmount: 153.36,
					baseAmountFormated: '$153,36 USD',
					totalTaxes: 0,
					discounts: 0,
					total: 153.36,
					totalFormated: '$153,36 USD',
					grandTotal: 306.72,
					grandTotalFormated: '$306,72 USD',
					fee: 0,
					currency: 'USD',
					passengerType: PassangerType.child,
					passengerQuantity: 2,
					requiredPoints: 28401,
					pointsName: 'ThankYou Points',
				},
			],
			fareSummary: {
				totalPerDayPerPassenger: 60.1775,
				totalPerDayPerPassengerFormated: '$60.18 USD',
				grandTotal: 1203.55,
				amount: 1203.55,
				amountFormated: '$1,203.55 USD',
				pointsFormated: '',
				currency: 'USD',
				netTotalFare: 1203.55,
				netTotalFee: 0,
				payFeeIn: 'points',
				feeAmount: 0,
				equivalentInCash: '$1,203.55 USD',
				requiredPoints: 44400,
				pointsName: 'ThankYou Points',
				pointValue: 0.16,
			},
		},
		fareGroup: [
			{
				name: 'Base Ticket',
				durationDays: 1,
				usageBufferDays: 4,
				usageDate: {
					startDate: '2024-04-15T00:00:00',
					startDateFormated: 'lun., 15 abr. 2024',
					endDate: '2024-04-15T00:00:00',
					endDateFormated: 'lun., 15 abr. 2024',
					usageBufferDays: 4,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 60.1775,
					totalPerDayPerPassengerFormated: '$60.18 USD',
					grandTotal: 1203.55,
					amount: 1203.55,
					amountFormated: '$1,203.55 USD',
					requiredPoints: 41230,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1203.55,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,203.55 USD',
				},
				fareDetails: [
					{
						baseAmount: 612.34,
						baseAmountFormated: '$612.34 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 612.34,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$612.34 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 591.21,
						baseAmountFormated: '$591.21 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 591.21,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$591.21 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: true,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper',
				durationDays: 1,
				usageBufferDays: 0,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-14T00:00:00',
					endDateFormated: 'lun., 14 nov. 2022',
					usageBufferDays: 0,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: false,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: false,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 68.0535,
					totalPerDayPerPassengerFormated: '$68.05 USD',
					grandTotal: 1361.07,
					amount: 1361.07,
					amountFormated: '$1,361.07 USD',
					requiredPoints: 55280,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1361.07,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,361.07 USD',
				},
				fareDetails: [
					{
						baseAmount: 691.1,
						baseAmountFormated: '$691.1 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 691.1,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$691.1 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 669.97,
						baseAmountFormated: '$669.97 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 669.97,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$669.97 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Water Park and Sports',
				durationDays: 1,
				usageBufferDays: 1,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-15T00:00:00',
					endDateFormated: 'mar., 15 nov. 2022',
					usageBufferDays: 1,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: true,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: false,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 66.6635,
					totalPerDayPerPassengerFormated: '$66.66 USD',
					grandTotal: 1333.27,
					amount: 1333.27,
					amountFormated: '$1,333.27 USD',
					requiredPoints: 64371,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1333.27,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,333.27 USD',
				},
				fareDetails: [
					{
						baseAmount: 677.2,
						baseAmountFormated: '$677.2 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 677.2,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$677.2 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 656.07,
						baseAmountFormated: '$656.07 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 656.07,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$656.07 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: false,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
			{
				name: 'Park Hopper Plus',
				durationDays: 1,
				usageBufferDays: 1,
				usageDate: {
					startDate: '2022-11-01T00:00:00',
					startDateFormated: 'mar., 01 nov. 2022',
					endDate: '2022-11-15T00:00:00',
					endDateFormated: 'mar., 15 nov. 2022',
					usageBufferDays: 1,
				},
				inclusions: [
					{
						isIncluded: true,
						description: 'Animal Kingdom',
					},
					{
						isIncluded: true,
						description: 'Magic Kingdom',
					},
					{
						isIncluded: true,
						description: 'Epcot',
					},
					{
						isIncluded: true,
						description: 'Hollywood Studios',
					},
					{
						isIncluded: true,
						description: 'Waterparks',
					},
					{
						isIncluded: false,
						description: 'NBA Experience',
					},
					{
						isIncluded: true,
						description: 'ESPN Sport Experience',
					},
					{
						isIncluded: true,
						description: 'Visit more than 1 park on the same day',
					},
				],
				fareGroupSummary: {
					totalPerDayPerPassenger: 69.9065,
					totalPerDayPerPassengerFormated: '$69.91 USD',
					grandTotal: 1398.13,
					amount: 1398.13,
					amountFormated: '$1,398.13 USD',
					requiredPoints: 74812,
					pointsName: 'ThankYou Points',
					pointValue: 0.16,
					pointsFormated: '',
					currency: 'USD',
					netTotalFare: 1398.13,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					equivalentInCash: '$1,398.13 USD',
				},
				fareDetails: [
					{
						baseAmount: 709.63,
						baseAmountFormated: '$709.63 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 709.63,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$709.63 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
					{
						baseAmount: 688.5,
						baseAmountFormated: '$688.5 USD',
						totalTaxes: 0,
						discounts: 0,
						grandTotal: 688.5,
						totalFormated: '$166,03 USD',
						grandTotalFormated: '$688.5 USD',
						fee: 0,
						currency: 'USD',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
						requiredPoints: 34882,
						pointsName: 'ThankYou Points',
					},
				],
				isSelected: true,
				isRecommended: false,
				passengersQuantity: {
					adults: 1,
					children: 1,
					infants: 0,
				},
				paymentMethod: ModelTypeEnum.POINT_PLUS_MONEY,
			},
		],
		searchId: '4e16db22-e1ac-4a4b-b84f-1a4e358b7965',
	},
];

export const specialEventRecommendationMock: DisneyEventSearchResult = {
	recommendations: [
		{
			businessModel: 'pointsOrMoney',
			paymentMethod: 'points',
			information: {
				name: 'Disney After Hours at Disneys Hollywood Studios',
				description:
					'prueba Disney After Hours en Hollywood Studios ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula erat et tellus ornare MDisney After Hours at Disneys Hollywood Studios ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula erat et tellus ornare',
				thumbnailUrl:
					'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
				durationDays: 1,
				usageDate: {
					startDate: '2024-04-03T00:00:00Z',
					startDateFormated: 'mié., 03 abr. 2024',
					endDate: '2024-08-29T00:00:00Z',
					endDateFormated: 'jue., 29 ago. 2024',
					usageBufferDays: 0,
					usageValidDates: 'entre el mié., 03 abr. 2024 y el jue., 29 ago. 2024',
				},
				fareDetails: [
					{
						baseAmount: 2779,
						baseAmountFormated: '$2.779,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 2779,
						totalFormated: '$2.779 MXN',
						grandTotal: 2779,
						grandTotalFormated: '$2.779,00 MXN',
						requiredAmount: 0,
						requiredPoints: 397000,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
					},
					{
						baseAmount: 2779,
						baseAmountFormated: '$2.779,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 2779,
						totalFormated: '$2.779 MXN',
						grandTotal: 2779,
						grandTotalFormated: '$2.779,00 MXN',
						requiredAmount: 0,
						requiredPoints: 397000,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
					},
				],
				fareSummary: {
					amount: 5558,
					amountFormated: '$5.558,00 MXN',
					currency: 'MXN',
					netTotalFare: 5558,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					requiredAmount: 0,
					requiredPoints: 794000,
					pointsName: 'Puntos',
					pointValue: 0.007,
					grandTotal: 5558,
					equivalentInCash: '$5.558,00 MXN',
					baseAmount: 5558,
					baseAmountFormated: '$5.558,00 MXN',
					totalTaxes: 0,
					fee: 0,
					passengerType: PassangerType.adult,
					passengerQuantity: 0,
					totalPerDayPerPassenger: 0,
					totalPerDayPerPassengerFormated: '$0 MXN',
					totalPerDayPerPassengerPoints: 397000,
				},
				promoCode: null,
			},
			fareGroup: [
				{
					id: 'a463f6ef-665e-40ee-8bbf-2cf97a50b47b~1fb601b2-c59b-4108-9969-29daab6aad38',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-04-03T00:00:00Z',
						startDateFormated: 'mié., 03 abr. 2024',
						endDate: '2024-04-03T00:00:00Z',
						endDateFormated: 'mié., 03 abr. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 03 abr. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5558,
						amountFormated: '$5.558,00 MXN',
						currency: 'MXN',
						netTotalFare: 5558,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 794000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5558,
						equivalentInCash: '$5.558,00 MXN',
						baseAmount: 5558,
						baseAmountFormated: '$5.558,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 397000,
					},
					fareDetails: [
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: true,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '1c35ad80-5358-4f84-9031-1c5352f4821a~f6494b99-dd0b-4bbb-9ccc-2e4c2f7be9f6',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-04-10T00:00:00Z',
						startDateFormated: 'mié., 10 abr. 2024',
						endDate: '2024-04-10T00:00:00Z',
						endDateFormated: 'mié., 10 abr. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 10 abr. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5558,
						amountFormated: '$5.558,00 MXN',
						currency: 'MXN',
						netTotalFare: 5558,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 794000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5558,
						equivalentInCash: '$5.558,00 MXN',
						baseAmount: 5558,
						baseAmountFormated: '$5.558,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 397000,
					},
					fareDetails: [
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '50880cc7-2cdf-4bdb-84d1-66e4340a0a30~ffb75686-ab95-4d2e-9631-d45d5c95565b',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-05-22T00:00:00Z',
						startDateFormated: 'mié., 22 may. 2024',
						endDate: '2024-05-22T00:00:00Z',
						endDateFormated: 'mié., 22 may. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 22 may. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5558,
						amountFormated: '$5.558,00 MXN',
						currency: 'MXN',
						netTotalFare: 5558,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 794000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5558,
						equivalentInCash: '$5.558,00 MXN',
						baseAmount: 5558,
						baseAmountFormated: '$5.558,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 397000,
					},
					fareDetails: [
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'cdd00820-5ba6-4da7-9bb9-50ff24f5a45f~0edf6967-fbc6-478c-8711-84c4ed276051',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-05-29T00:00:00Z',
						startDateFormated: 'mié., 29 may. 2024',
						endDate: '2024-05-29T00:00:00Z',
						endDateFormated: 'mié., 29 may. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 29 may. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5558,
						amountFormated: '$5.558,00 MXN',
						currency: 'MXN',
						netTotalFare: 5558,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 794000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5558,
						equivalentInCash: '$5.558,00 MXN',
						baseAmount: 5558,
						baseAmountFormated: '$5.558,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 397000,
					},
					fareDetails: [
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'cbb5f7cc-5c5a-40c6-857e-4f36f2888141~7ddd3e91-5793-4a48-8e1d-ae2a01cc7ecd',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-06-12T00:00:00Z',
						startDateFormated: 'mié., 12 jun. 2024',
						endDate: '2024-06-12T00:00:00Z',
						endDateFormated: 'mié., 12 jun. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 12 jun. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5240,
						amountFormated: '$5.240,00 MXN',
						currency: 'MXN',
						netTotalFare: 5240,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 748572,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5240,
						equivalentInCash: '$5.240,00 MXN',
						baseAmount: 5240,
						baseAmountFormated: '$5.240,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 374286,
					},
					fareDetails: [
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '5de55c36-cdfd-48b3-aeb2-5bd78a7e346d~c4a1396a-2923-41a1-a1f7-568708e0d38a',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-06-19T00:00:00Z',
						startDateFormated: 'mié., 19 jun. 2024',
						endDate: '2024-06-19T00:00:00Z',
						endDateFormated: 'mié., 19 jun. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 19 jun. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5240,
						amountFormated: '$5.240,00 MXN',
						currency: 'MXN',
						netTotalFare: 5240,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 748572,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5240,
						equivalentInCash: '$5.240,00 MXN',
						baseAmount: 5240,
						baseAmountFormated: '$5.240,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 374286,
					},
					fareDetails: [
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'fe4caa45-fc13-4a62-b0d0-c8b65480a671~42419fed-fcbd-4cab-910f-2fc16ec422b0',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-06-26T00:00:00Z',
						startDateFormated: 'mié., 26 jun. 2024',
						endDate: '2024-06-26T00:00:00Z',
						endDateFormated: 'mié., 26 jun. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 26 jun. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5240,
						amountFormated: '$5.240,00 MXN',
						currency: 'MXN',
						netTotalFare: 5240,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 748572,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5240,
						equivalentInCash: '$5.240,00 MXN',
						baseAmount: 5240,
						baseAmountFormated: '$5.240,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 374286,
					},
					fareDetails: [
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '420c5880-4a3a-463a-a31f-e718b4dd95fc~91d30144-1858-4242-8d0c-f4c19f5e6a90',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-07-10T00:00:00Z',
						startDateFormated: 'mié., 10 jul. 2024',
						endDate: '2024-07-10T00:00:00Z',
						endDateFormated: 'mié., 10 jul. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 10 jul. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5240,
						amountFormated: '$5.240,00 MXN',
						currency: 'MXN',
						netTotalFare: 5240,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 748572,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5240,
						equivalentInCash: '$5.240,00 MXN',
						baseAmount: 5240,
						baseAmountFormated: '$5.240,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 374286,
					},
					fareDetails: [
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '11260926-93cc-4c1d-a320-36e7d038a8f2~666abc3e-21fd-41fe-8e75-cf483a75e26a',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-07-17T00:00:00Z',
						startDateFormated: 'mié., 17 jul. 2024',
						endDate: '2024-07-17T00:00:00Z',
						endDateFormated: 'mié., 17 jul. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 17 jul. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5240,
						amountFormated: '$5.240,00 MXN',
						currency: 'MXN',
						netTotalFare: 5240,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 748572,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5240,
						equivalentInCash: '$5.240,00 MXN',
						baseAmount: 5240,
						baseAmountFormated: '$5.240,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 374286,
					},
					fareDetails: [
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '0b70d7f5-dbcf-4fe3-81b1-53f8c67da20d~3b55cde9-d298-4526-85c4-bfd148fdd0b3',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-07-24T00:00:00Z',
						startDateFormated: 'mié., 24 jul. 2024',
						endDate: '2024-07-24T00:00:00Z',
						endDateFormated: 'mié., 24 jul. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 24 jul. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4924,
						amountFormated: '$4.924,00 MXN',
						currency: 'MXN',
						netTotalFare: 4924,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 703429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4924,
						equivalentInCash: '$4.924,00 MXN',
						baseAmount: 4924,
						baseAmountFormated: '$4.924,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 351715,
					},
					fareDetails: [
						{
							baseAmount: 2462,
							baseAmountFormated: '$2.462,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2462,
							totalFormated: '$2.462 MXN',
							grandTotal: 2462,
							grandTotalFormated: '$2.462,00 MXN',
							requiredAmount: 0,
							requiredPoints: 351715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2462,
							baseAmountFormated: '$2.462,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2462,
							totalFormated: '$2.462 MXN',
							grandTotal: 2462,
							grandTotalFormated: '$2.462,00 MXN',
							requiredAmount: 0,
							requiredPoints: 351715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '5bd56305-40d1-4fc7-94af-a84734e21ca0~8671220e-5e7e-4c3c-8acd-9c2a19a5ba95',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-07-31T00:00:00Z',
						startDateFormated: 'mié., 31 jul. 2024',
						endDate: '2024-07-31T00:00:00Z',
						endDateFormated: 'mié., 31 jul. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 31 jul. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4924,
						amountFormated: '$4.924,00 MXN',
						currency: 'MXN',
						netTotalFare: 4924,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 703429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4924,
						equivalentInCash: '$4.924,00 MXN',
						baseAmount: 4924,
						baseAmountFormated: '$4.924,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 351715,
					},
					fareDetails: [
						{
							baseAmount: 2462,
							baseAmountFormated: '$2.462,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2462,
							totalFormated: '$2.462 MXN',
							grandTotal: 2462,
							grandTotalFormated: '$2.462,00 MXN',
							requiredAmount: 0,
							requiredPoints: 351715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2462,
							baseAmountFormated: '$2.462,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2462,
							totalFormated: '$2.462 MXN',
							grandTotal: 2462,
							grandTotalFormated: '$2.462,00 MXN',
							requiredAmount: 0,
							requiredPoints: 351715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '2dd619c3-2e70-49a5-bafe-77b9e6eb60de~08a5efd0-881a-4436-a05e-e696e3d526e2',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-08-07T00:00:00Z',
						startDateFormated: 'mié., 07 ago. 2024',
						endDate: '2024-08-07T00:00:00Z',
						endDateFormated: 'mié., 07 ago. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 07 ago. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4924,
						amountFormated: '$4.924,00 MXN',
						currency: 'MXN',
						netTotalFare: 4924,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 703429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4924,
						equivalentInCash: '$4.924,00 MXN',
						baseAmount: 4924,
						baseAmountFormated: '$4.924,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 351715,
					},
					fareDetails: [
						{
							baseAmount: 2462,
							baseAmountFormated: '$2.462,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2462,
							totalFormated: '$2.462 MXN',
							grandTotal: 2462,
							grandTotalFormated: '$2.462,00 MXN',
							requiredAmount: 0,
							requiredPoints: 351715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2462,
							baseAmountFormated: '$2.462,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2462,
							totalFormated: '$2.462 MXN',
							grandTotal: 2462,
							grandTotalFormated: '$2.462,00 MXN',
							requiredAmount: 0,
							requiredPoints: 351715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '4324735a-9814-4246-9f9d-e757957b5d65~bef05436-9132-4b0d-86d4-a69efc65f039',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-08-14T00:00:00Z',
						startDateFormated: 'mié., 14 ago. 2024',
						endDate: '2024-08-14T00:00:00Z',
						endDateFormated: 'mié., 14 ago. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 14 ago. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4606,
						amountFormated: '$4.606,00 MXN',
						currency: 'MXN',
						netTotalFare: 4606,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 658000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4606,
						equivalentInCash: '$4.606,00 MXN',
						baseAmount: 4606,
						baseAmountFormated: '$4.606,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 329000,
					},
					fareDetails: [
						{
							baseAmount: 2303,
							baseAmountFormated: '$2.303,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2303,
							totalFormated: '$2.303 MXN',
							grandTotal: 2303,
							grandTotalFormated: '$2.303,00 MXN',
							requiredAmount: 0,
							requiredPoints: 329000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2303,
							baseAmountFormated: '$2.303,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2303,
							totalFormated: '$2.303 MXN',
							grandTotal: 2303,
							grandTotalFormated: '$2.303,00 MXN',
							requiredAmount: 0,
							requiredPoints: 329000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '2bed7c07-ef9c-4c1f-b063-623203295bd2~7a954387-d11b-458f-b456-2df397891634',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-08-21T00:00:00Z',
						startDateFormated: 'mié., 21 ago. 2024',
						endDate: '2024-08-21T00:00:00Z',
						endDateFormated: 'mié., 21 ago. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el mié., 21 ago. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4606,
						amountFormated: '$4.606,00 MXN',
						currency: 'MXN',
						netTotalFare: 4606,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 658000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4606,
						equivalentInCash: '$4.606,00 MXN',
						baseAmount: 4606,
						baseAmountFormated: '$4.606,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 329000,
					},
					fareDetails: [
						{
							baseAmount: 2303,
							baseAmountFormated: '$2.303,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2303,
							totalFormated: '$2.303 MXN',
							grandTotal: 2303,
							grandTotalFormated: '$2.303,00 MXN',
							requiredAmount: 0,
							requiredPoints: 329000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2303,
							baseAmountFormated: '$2.303,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2303,
							totalFormated: '$2.303 MXN',
							grandTotal: 2303,
							grandTotalFormated: '$2.303,00 MXN',
							requiredAmount: 0,
							requiredPoints: 329000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'be7be907-6cd4-430c-baee-c324b6606344~5b4a3a23-2666-497c-840c-2da48c980a10',
					name: 'Disney After Hours at Disneys Hollywood Studios',
					description:
						'<b>prueba felipe Disney After Hours en Hollywood Studios</b><br>Boleto válido para la entrada a Disney After Hours en Hollywood Studios Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/smugglers-run/4027570_30_0516ZR_3700MS_aRGB_r2-16x9.jpg?2022-03-31T20:37:02+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-08-29T00:00:00Z',
						startDateFormated: 'jue., 29 ago. 2024',
						endDate: '2024-08-29T00:00:00Z',
						endDateFormated: 'jue., 29 ago. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 29 ago. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4606,
						amountFormated: '$4.606,00 MXN',
						currency: 'MXN',
						netTotalFare: 4606,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 658000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4606,
						equivalentInCash: '$4.606,00 MXN',
						baseAmount: 4606,
						baseAmountFormated: '$4.606,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 329000,
					},
					fareDetails: [
						{
							baseAmount: 2303,
							baseAmountFormated: '$2.303,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2303,
							totalFormated: '$2.303 MXN',
							grandTotal: 2303,
							grandTotalFormated: '$2.303,00 MXN',
							requiredAmount: 0,
							requiredPoints: 329000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2303,
							baseAmountFormated: '$2.303,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2303,
							totalFormated: '$2.303 MXN',
							grandTotal: 2303,
							grandTotalFormated: '$2.303,00 MXN',
							requiredAmount: 0,
							requiredPoints: 329000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
			],
			searchId: '5eb94f26-3e78-49a6-96f7-bdb40764e838',
			availabilityId: '1719163294',
		},
		{
			businessModel: 'pointsOrMoney',
			paymentMethod: 'points',
			information: {
				name: 'Disney After Hours en EPCOT',
				description:
					'prueba Disney After Hours en EPCOT ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula erat et tellus ornare Mickeys Mickeys MAGIC ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula erat et tellus ornare',
				thumbnailUrl:
					'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
				durationDays: 1,
				usageDate: {
					startDate: '2024-04-04T00:00:00Z',
					startDateFormated: 'jue., 04 abr. 2024',
					endDate: '2024-07-18T00:00:00Z',
					endDateFormated: 'jue., 18 jul. 2024',
					usageBufferDays: 0,
					usageValidDates: 'entre el jue., 04 abr. 2024 y el jue., 18 jul. 2024',
				},
				fareDetails: [
					{
						baseAmount: 2525,
						baseAmountFormated: '$2.525,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 2525,
						totalFormated: '$2.525 MXN',
						grandTotal: 2525,
						grandTotalFormated: '$2.525,00 MXN',
						requiredAmount: 0,
						requiredPoints: 360715,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
					},
					{
						baseAmount: 2525,
						baseAmountFormated: '$2.525,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 2525,
						totalFormated: '$2.525 MXN',
						grandTotal: 2525,
						grandTotalFormated: '$2.525,00 MXN',
						requiredAmount: 0,
						requiredPoints: 360715,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
					},
				],
				fareSummary: {
					amount: 5050,
					amountFormated: '$5.050,00 MXN',
					currency: 'MXN',
					netTotalFare: 5050,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					requiredAmount: 0,
					requiredPoints: 721429,
					pointsName: 'Puntos',
					pointValue: 0.007,
					grandTotal: 5050,
					equivalentInCash: '$5.050,00 MXN',
					baseAmount: 5050,
					baseAmountFormated: '$5.050,00 MXN',
					totalTaxes: 0,
					fee: 0,
					passengerType: PassangerType.adult,
					passengerQuantity: 0,
					totalPerDayPerPassenger: 0,
					totalPerDayPerPassengerFormated: '$0 MXN',
					totalPerDayPerPassengerPoints: 360715,
				},
				promoCode: null,
			},
			fareGroup: [
				{
					id: '289414d1-14ac-4ed6-8324-fa1d2b8f7bfc~34fd9598-108c-4e6e-aa8d-f1fdc7aa2388',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-04-04T00:00:00Z',
						startDateFormated: 'jue., 04 abr. 2024',
						endDate: '2024-04-04T00:00:00Z',
						endDateFormated: 'jue., 04 abr. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 04 abr. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5050,
						amountFormated: '$5.050,00 MXN',
						currency: 'MXN',
						netTotalFare: 5050,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 721429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5050,
						equivalentInCash: '$5.050,00 MXN',
						baseAmount: 5050,
						baseAmountFormated: '$5.050,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 360715,
					},
					fareDetails: [
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: true,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'd6bd02be-7c6b-436a-84a0-e19f09537af7~f4927012-ee4d-4e9d-897c-5f40e7614a9e',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-04-25T00:00:00Z',
						startDateFormated: 'jue., 25 abr. 2024',
						endDate: '2024-04-25T00:00:00Z',
						endDateFormated: 'jue., 25 abr. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 25 abr. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5050,
						amountFormated: '$5.050,00 MXN',
						currency: 'MXN',
						netTotalFare: 5050,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 721429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5050,
						equivalentInCash: '$5.050,00 MXN',
						baseAmount: 5050,
						baseAmountFormated: '$5.050,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 360715,
					},
					fareDetails: [
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '9099e88a-76a3-4320-869e-0b75d9eacb18~6f59a956-6df0-4323-877b-4499fc2f63a7',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-05-09T00:00:00Z',
						startDateFormated: 'jue., 09 may. 2024',
						endDate: '2024-05-09T00:00:00Z',
						endDateFormated: 'jue., 09 may. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 09 may. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5050,
						amountFormated: '$5.050,00 MXN',
						currency: 'MXN',
						netTotalFare: 5050,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 721429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5050,
						equivalentInCash: '$5.050,00 MXN',
						baseAmount: 5050,
						baseAmountFormated: '$5.050,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 360715,
					},
					fareDetails: [
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'bf7c33ce-7cc0-480c-8aeb-3cc038ab6df0~6734b3e7-f358-4efd-a38d-1fa42fadd1b5',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-05-23T00:00:00Z',
						startDateFormated: 'jue., 23 may. 2024',
						endDate: '2024-05-23T00:00:00Z',
						endDateFormated: 'jue., 23 may. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 23 may. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5050,
						amountFormated: '$5.050,00 MXN',
						currency: 'MXN',
						netTotalFare: 5050,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 721429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5050,
						equivalentInCash: '$5.050,00 MXN',
						baseAmount: 5050,
						baseAmountFormated: '$5.050,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 360715,
					},
					fareDetails: [
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '61db2cff-2c4f-4c0a-a4e9-ec9166380708~01e3f388-c183-4348-b070-513ed644cabb',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-05-30T00:00:00Z',
						startDateFormated: 'jue., 30 may. 2024',
						endDate: '2024-05-30T00:00:00Z',
						endDateFormated: 'jue., 30 may. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 30 may. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5050,
						amountFormated: '$5.050,00 MXN',
						currency: 'MXN',
						netTotalFare: 5050,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 721429,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5050,
						equivalentInCash: '$5.050,00 MXN',
						baseAmount: 5050,
						baseAmountFormated: '$5.050,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 360715,
					},
					fareDetails: [
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2525,
							baseAmountFormated: '$2.525,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2525,
							totalFormated: '$2.525 MXN',
							grandTotal: 2525,
							grandTotalFormated: '$2.525,00 MXN',
							requiredAmount: 0,
							requiredPoints: 360715,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '2e8061ff-a57b-4157-a3f4-14d56ffc184a~c9918dc8-3f96-4796-9a2b-641608fee326',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-06-06T00:00:00Z',
						startDateFormated: 'jue., 06 jun. 2024',
						endDate: '2024-06-06T00:00:00Z',
						endDateFormated: 'jue., 06 jun. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 06 jun. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4732,
						amountFormated: '$4.732,00 MXN',
						currency: 'MXN',
						netTotalFare: 4732,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 676000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4732,
						equivalentInCash: '$4.732,00 MXN',
						baseAmount: 4732,
						baseAmountFormated: '$4.732,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 338000,
					},
					fareDetails: [
						{
							baseAmount: 2366,
							baseAmountFormated: '$2.366,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2366,
							totalFormated: '$2.366 MXN',
							grandTotal: 2366,
							grandTotalFormated: '$2.366,00 MXN',
							requiredAmount: 0,
							requiredPoints: 338000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2366,
							baseAmountFormated: '$2.366,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2366,
							totalFormated: '$2.366 MXN',
							grandTotal: 2366,
							grandTotalFormated: '$2.366,00 MXN',
							requiredAmount: 0,
							requiredPoints: 338000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'e66faae7-e13d-43ee-8777-a0b0d0c6d35b~f9cfba6b-9b64-4a86-844a-d8b7fadf4601',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-06-20T00:00:00Z',
						startDateFormated: 'jue., 20 jun. 2024',
						endDate: '2024-06-20T00:00:00Z',
						endDateFormated: 'jue., 20 jun. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 20 jun. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4732,
						amountFormated: '$4.732,00 MXN',
						currency: 'MXN',
						netTotalFare: 4732,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 676000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4732,
						equivalentInCash: '$4.732,00 MXN',
						baseAmount: 4732,
						baseAmountFormated: '$4.732,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 338000,
					},
					fareDetails: [
						{
							baseAmount: 2366,
							baseAmountFormated: '$2.366,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2366,
							totalFormated: '$2.366 MXN',
							grandTotal: 2366,
							grandTotalFormated: '$2.366,00 MXN',
							requiredAmount: 0,
							requiredPoints: 338000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2366,
							baseAmountFormated: '$2.366,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2366,
							totalFormated: '$2.366 MXN',
							grandTotal: 2366,
							grandTotalFormated: '$2.366,00 MXN',
							requiredAmount: 0,
							requiredPoints: 338000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'ea82f685-509d-4cb5-af81-34fa43573ffe~f7a87343-6c3c-41d6-a5da-5f581c93e981',
					name: 'Disney After Hours en EPCOT',
					description:
						'<b>prueba felipe Disney After Hours en EPCOT</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/434/244/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/attractions/ratatouille/Ratatouille_YOUNG_ADULTS_RIDE_jrod-16x9.jpg?2023-02-13T14:40:45+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-07-18T00:00:00Z',
						startDateFormated: 'jue., 18 jul. 2024',
						endDate: '2024-07-18T00:00:00Z',
						endDateFormated: 'jue., 18 jul. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 18 jul. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: false,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 4732,
						amountFormated: '$4.732,00 MXN',
						currency: 'MXN',
						netTotalFare: 4732,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 676000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 4732,
						equivalentInCash: '$4.732,00 MXN',
						baseAmount: 4732,
						baseAmountFormated: '$4.732,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 338000,
					},
					fareDetails: [
						{
							baseAmount: 2366,
							baseAmountFormated: '$2.366,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2366,
							totalFormated: '$2.366 MXN',
							grandTotal: 2366,
							grandTotalFormated: '$2.366,00 MXN',
							requiredAmount: 0,
							requiredPoints: 338000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2366,
							baseAmountFormated: '$2.366,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2366,
							totalFormated: '$2.366 MXN',
							grandTotal: 2366,
							grandTotalFormated: '$2.366,00 MXN',
							requiredAmount: 0,
							requiredPoints: 338000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
			],
			searchId: '5eb94f26-3e78-49a6-96f7-bdb40764e838',
			availabilityId: '-1396920177',
		},
		{
			businessModel: 'pointsOrMoney',
			paymentMethod: 'points',
			information: {
				name: 'Disney After Hours at Magic Kingdom',
				description:
					'prueba Disney After Hours at Magic Kingdom ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula erat et tellus ornare Mickeys Mickeys MAGIC ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula erat et tellus ornare',
				thumbnailUrl:
					'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/832/468/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/dining/50th-celebration/journey-of-the-little-mermaid-gallery08-16x9.jpg?2022-03-10T19:41:10+00:00',
				durationDays: 1,
				usageDate: {
					startDate: '2024-04-08T00:00:00Z',
					startDateFormated: 'lun., 08 abr. 2024',
					endDate: '2024-06-27T00:00:00Z',
					endDateFormated: 'jue., 27 jun. 2024',
					usageBufferDays: 0,
					usageValidDates: 'entre el lun., 08 abr. 2024 y el jue., 27 jun. 2024',
				},
				fareDetails: [
					{
						baseAmount: 2779,
						baseAmountFormated: '$2.779,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 2779,
						totalFormated: '$2.779 MXN',
						grandTotal: 2779,
						grandTotalFormated: '$2.779,00 MXN',
						requiredAmount: 0,
						requiredPoints: 397000,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
					},
					{
						baseAmount: 2779,
						baseAmountFormated: '$2.779,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 2779,
						totalFormated: '$2.779 MXN',
						grandTotal: 2779,
						grandTotalFormated: '$2.779,00 MXN',
						requiredAmount: 0,
						requiredPoints: 397000,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
					},
				],
				fareSummary: {
					amount: 5558,
					amountFormated: '$5.558,00 MXN',
					currency: 'MXN',
					netTotalFare: 5558,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					requiredAmount: 0,
					requiredPoints: 794000,
					pointsName: 'Puntos',
					pointValue: 0.007,
					grandTotal: 5558,
					equivalentInCash: '$5.558,00 MXN',
					baseAmount: 5558,
					baseAmountFormated: '$5.558,00 MXN',
					totalTaxes: 0,
					fee: 0,
					passengerType: PassangerType.adult,
					passengerQuantity: 0,
					totalPerDayPerPassenger: 0,
					totalPerDayPerPassengerFormated: '$0 MXN',
					totalPerDayPerPassengerPoints: 397000,
				},
				promoCode: null,
			},
			fareGroup: [
				{
					id: 'dbe089ef-439b-4a32-95f5-e5264ce8ed07~1348caa1-7d83-44e7-877c-93be287806c3',
					name: 'Disney After Hours at Magic Kingdom',
					description:
						'<b>prueba felipe Disney After Hours at Magic Kingdom</b><br>Boleto válido para la entrada a Disney After Hours at Magic Kingdom Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/832/468/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/dining/50th-celebration/journey-of-the-little-mermaid-gallery08-16x9.jpg?2022-03-10T19:41:10+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-04-08T00:00:00Z',
						startDateFormated: 'lun., 08 abr. 2024',
						endDate: '2024-04-08T00:00:00Z',
						endDateFormated: 'lun., 08 abr. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el lun., 08 abr. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: true,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5558,
						amountFormated: '$5.558,00 MXN',
						currency: 'MXN',
						netTotalFare: 5558,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 794000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5558,
						equivalentInCash: '$5.558,00 MXN',
						baseAmount: 5558,
						baseAmountFormated: '$5.558,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 397000,
					},
					fareDetails: [
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: true,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '6c9d9ada-0a16-41ab-a770-13e3ecdddc5e~dd25637d-d776-466e-a69c-26f3a2ccb226',
					name: 'Disney After Hours at Magic Kingdom',
					description:
						'<b>prueba felipe Disney After Hours at Magic Kingdom</b><br>Boleto válido para la entrada a Disney After Hours at Magic Kingdom Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/832/468/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/dining/50th-celebration/journey-of-the-little-mermaid-gallery08-16x9.jpg?2022-03-10T19:41:10+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-04-22T00:00:00Z',
						startDateFormated: 'lun., 22 abr. 2024',
						endDate: '2024-04-22T00:00:00Z',
						endDateFormated: 'lun., 22 abr. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el lun., 22 abr. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: true,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5558,
						amountFormated: '$5.558,00 MXN',
						currency: 'MXN',
						netTotalFare: 5558,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 794000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5558,
						equivalentInCash: '$5.558,00 MXN',
						baseAmount: 5558,
						baseAmountFormated: '$5.558,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 397000,
					},
					fareDetails: [
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '3d079cd1-fd01-4a4f-ab56-5b2dfe0a4938~aa40c61e-693d-45d5-916a-143b3279906e',
					name: 'Disney After Hours at Magic Kingdom',
					description:
						'<b>prueba felipe Disney After Hours at Magic Kingdom</b><br>Boleto válido para la entrada a Disney After Hours at Magic Kingdom Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/832/468/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/dining/50th-celebration/journey-of-the-little-mermaid-gallery08-16x9.jpg?2022-03-10T19:41:10+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-04-29T00:00:00Z',
						startDateFormated: 'lun., 29 abr. 2024',
						endDate: '2024-04-29T00:00:00Z',
						endDateFormated: 'lun., 29 abr. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el lun., 29 abr. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: true,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5558,
						amountFormated: '$5.558,00 MXN',
						currency: 'MXN',
						netTotalFare: 5558,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 794000,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5558,
						equivalentInCash: '$5.558,00 MXN',
						baseAmount: 5558,
						baseAmountFormated: '$5.558,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 397000,
					},
					fareDetails: [
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2779,
							baseAmountFormated: '$2.779,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2779,
							totalFormated: '$2.779 MXN',
							grandTotal: 2779,
							grandTotalFormated: '$2.779,00 MXN',
							requiredAmount: 0,
							requiredPoints: 397000,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: '6364a286-dce2-46b3-b663-3bd3e108b442~5e36c570-5ef6-4e19-a4d1-3c27a08380cd',
					name: 'Disney After Hours at Magic Kingdom',
					description:
						'<b>prueba felipe Disney After Hours at Magic Kingdom</b><br>Boleto válido para la entrada a Disney After Hours at Magic Kingdom Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/832/468/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/dining/50th-celebration/journey-of-the-little-mermaid-gallery08-16x9.jpg?2022-03-10T19:41:10+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-06-13T00:00:00Z',
						startDateFormated: 'jue., 13 jun. 2024',
						endDate: '2024-06-13T00:00:00Z',
						endDateFormated: 'jue., 13 jun. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 13 jun. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: true,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5240,
						amountFormated: '$5.240,00 MXN',
						currency: 'MXN',
						netTotalFare: 5240,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 748572,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5240,
						equivalentInCash: '$5.240,00 MXN',
						baseAmount: 5240,
						baseAmountFormated: '$5.240,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 374286,
					},
					fareDetails: [
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
				{
					id: 'fc69ce0c-5613-4867-adf8-41927ed7c883~afbf5ad7-5eea-43f2-a079-193841036940',
					name: 'Disney After Hours at Magic Kingdom',
					description:
						'<b>prueba felipe Disney After Hours at Magic Kingdom</b><br>Boleto válido para la entrada a Disney After Hours at Magic Kingdom Hollywood Studios<br><b>prueba felipe Mickeys Very Merry Christmas Party</b><br>Boleto válido para la entrada a Disney After Hours en Disneys Hollywood Studios<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl:
						'https://cdn1.parksmedia.wdprapps.disney.com/resize/mwImage/1/832/468/75/vision-dam/digital/parks-platform/parks-global-assets/disney-world/dining/50th-celebration/journey-of-the-little-mermaid-gallery08-16x9.jpg?2022-03-10T19:41:10+00:00',
					durationDays: 1,
					usageBufferDays: 0,
					usageDate: {
						startDate: '2024-06-27T00:00:00Z',
						startDateFormated: 'jue., 27 jun. 2024',
						endDate: '2024-06-27T00:00:00Z',
						endDateFormated: 'jue., 27 jun. 2024',
						usageBufferDays: 0,
						usageValidDates: 'el jue., 27 jun. 2024',
					},
					inclusions: [
						{
							isIncluded: false,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: true,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: false,
							description: 'Epcot',
						},
						{
							isIncluded: false,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 5240,
						amountFormated: '$5.240,00 MXN',
						currency: 'MXN',
						netTotalFare: 5240,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 748572,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 5240,
						equivalentInCash: '$5.240,00 MXN',
						baseAmount: 5240,
						baseAmountFormated: '$5.240,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 374286,
					},
					fareDetails: [
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 2620,
							baseAmountFormated: '$2.620,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 2620,
							totalFormated: '$2.620 MXN',
							grandTotal: 2620,
							grandTotalFormated: '$2.620,00 MXN',
							requiredAmount: 0,
							requiredPoints: 374286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: false,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
			],
			searchId: '5eb94f26-3e78-49a6-96f7-bdb40764e838',
			availabilityId: '-702016404',
		},
		{
			businessModel: 'pointsOrMoney',
			paymentMethod: 'points',
			information: {
				name: 'Walt Disney World 4-Park Magic Ticket',
				description:
					'prueba Walt Disney World 4-Park Magic Ticket ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula erat et tellus ornare',
				thumbnailUrl:
					'https://www.universalorlando.com/webdata/k2/en/us/files/Images/gds/usf-diagon-alley-harry-potter-universal-wands-dad-son-c.jpg',
				durationDays: 4,
				usageDate: {
					startDate: '2024-04-02T00:00:00Z',
					startDateFormated: 'mar., 02 abr. 2024',
					endDate: '2024-09-22T00:00:00Z',
					endDateFormated: 'dom., 22 sep. 2024',
					usageBufferDays: 3,
					usageValidDates: 'entre el mar., 02 abr. 2024 y el dom., 22 sep. 2024',
				},
				fareDetails: [
					{
						baseAmount: 6218,
						baseAmountFormated: '$6.218,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 6218,
						totalFormated: '$6.218 MXN',
						grandTotal: 6218,
						grandTotalFormated: '$6.218,00 MXN',
						requiredAmount: 0,
						requiredPoints: 888286,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.adult,
						passengerQuantity: 1,
					},
					{
						baseAmount: 4962,
						baseAmountFormated: '$4.962,00 MXN',
						totalTaxes: 0,
						discounts: 0,
						total: 4962,
						totalFormated: '$4.962 MXN',
						grandTotal: 4962,
						grandTotalFormated: '$4.962,00 MXN',
						requiredAmount: 0,
						requiredPoints: 708858,
						pointsName: 'Puntos',
						fee: 0,
						currency: 'MXN',
						passengerType: PassangerType.child,
						passengerQuantity: 1,
					},
				],
				fareSummary: {
					amount: 11180,
					amountFormated: '$11.180,00 MXN',
					currency: 'MXN',
					netTotalFare: 11180,
					netTotalFee: 0,
					payFeeIn: 'points',
					feeAmount: 0,
					requiredAmount: 0,
					requiredPoints: 1597143,
					pointsName: 'Puntos',
					pointValue: 0.007,
					grandTotal: 11180,
					equivalentInCash: '$11.180,00 MXN',
					baseAmount: 11180,
					baseAmountFormated: '$11.180,00 MXN',
					totalTaxes: 0,
					fee: 0,
					passengerType: PassangerType.adult,
					passengerQuantity: 0,
					totalPerDayPerPassenger: 0,
					totalPerDayPerPassengerFormated: '$0 MXN',
					totalPerDayPerPassengerPoints: 199643,
				},
				promoCode: null,
			},
			fareGroup: [
				{
					id: '7bb997bb-c73d-42c4-b799-b424019ea1fa~0ed72b42-a73f-4824-a96f-b1503fba3e26',
					name: 'Walt Disney World 4-Park Magic Ticket',
					description:
						'<b>prueba felipe Walt Disney World 4-Park Magic Ticket</b><br>Boleto válido para la entrada a Disney After Hours en Walt Disney World 4-Park Magic Ticket<br><br><b>Horario del evento</b><br>El evento se llevará a cabo de 21:30 a 00:30 horas.<br>Si planea llegar antes de las 7:00 p. m., se requerirá una entrada válida al parque temático. También es posible que se requiera una reserva de parque temático para Disneys Hollywood Studios.<br><br><b>Registro de eventos</b><br>El check-in oficial para el evento se realizará en la entrada principal de Disneys Hollywood Studios a partir de las 7:00 p.m.',
					bannerUrl: 'https://www.universalorlando.com/webdata/k2/en/us/files/Images/gds/usf-diagon-alley-harry-potter-universal-wands-dad-son-c.jpg',
					durationDays: 4,
					usageBufferDays: 3,
					usageDate: {
						startDate: '2024-04-02T00:00:00Z',
						startDateFormated: 'mar., 02 abr. 2024',
						endDate: '2024-09-22T00:00:00Z',
						endDateFormated: 'dom., 22 sep. 2024',
						usageBufferDays: 3,
						usageValidDates: 'entre el mar., 02 abr. 2024 y el dom., 22 sep. 2024',
					},
					inclusions: [
						{
							isIncluded: true,
							description: 'Animal Kingdom',
						},
						{
							isIncluded: true,
							description: 'Magic Kingdom',
						},
						{
							isIncluded: true,
							description: 'Epcot',
						},
						{
							isIncluded: true,
							description: 'Hollywood Studios',
						},
						{
							isIncluded: false,
							description: 'Parques acuáticos',
						},
						{
							isIncluded: false,
							description: 'Experiencia NBA',
						},
						{
							isIncluded: false,
							description: 'Experiencia ESPN Sport',
						},
						{
							isIncluded: false,
							description: 'Visita más de 1 parque el mismo día',
						},
					],
					paymentMethod: 'points',
					fareGroupSummary: {
						amount: 11180,
						amountFormated: '$11.180,00 MXN',
						currency: 'MXN',
						netTotalFare: 11180,
						netTotalFee: 0,
						payFeeIn: 'points',
						feeAmount: 0,

						requiredAmount: 0,
						requiredPoints: 1597143,
						pointsName: 'Puntos',
						pointValue: 0.007,
						grandTotal: 11180,
						equivalentInCash: '$11.180,00 MXN',
						baseAmount: 11180,
						baseAmountFormated: '$11.180,00 MXN',
						totalTaxes: 0,
						fee: 0,
						passengerType: PassangerType.adult,
						passengerQuantity: 0,
						totalPerDayPerPassenger: 0,
						totalPerDayPerPassengerFormated: '$0 MXN',
						totalPerDayPerPassengerPoints: 199643,
					},
					fareDetails: [
						{
							baseAmount: 6218,
							baseAmountFormated: '$6.218,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 6218,
							totalFormated: '$6.218 MXN',
							grandTotal: 6218,
							grandTotalFormated: '$6.218,00 MXN',
							requiredAmount: 0,
							requiredPoints: 888286,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.adult,
							passengerQuantity: 1,
						},
						{
							baseAmount: 4962,
							baseAmountFormated: '$4.962,00 MXN',
							totalTaxes: 0,
							discounts: 0,
							total: 4962,
							totalFormated: '$4.962 MXN',
							grandTotal: 4962,
							grandTotalFormated: '$4.962,00 MXN',
							requiredAmount: 0,
							requiredPoints: 708858,
							pointsName: 'Puntos',
							fee: 0,
							currency: 'MXN',
							passengerType: PassangerType.child,
							passengerQuantity: 1,
						},
					],
					isSelected: false,
					isRecommended: true,
					hasSliderHidden: false,
					passengersQuantity: {
						adults: 1,
						children: 1,
						infants: 0,
					},
					ticketInformation: [
						{
							title: 'Uso y Acceso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Validez del boleto',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
						{
							title: 'Registro de eventos',
							items: ['Se registró varios eventos para esto ...', 'Los eventos son para ...'],
						},
						{
							title: 'Expiración y restricciones',
							items: ['El boleto se expira despues de tantos dias ...', 'Se debe cancelar despues de tantos meses ...'],
						},
						{
							title: 'Control de Prueba',
							items: ['Prueba para ver el funcionamiento de esto ...', 'Cuarta prueba x dado ...'],
						},
						{
							title: 'Politicas de uso',
							items: ['valido para dos dias tickets ...', 'total de cuatro bufferdays ...'],
						},
					],
				},
			],
			searchId: '5eb94f26-3e78-49a6-96f7-bdb40764e838',
			availabilityId: '1900669919',
		},
	],
	searchId: '5eb94f26-3e78-49a6-96f7-bdb40764e838',
	pagination: {
		currentPage: 0,
		pageSize: 0,
		totalResults: 0,
		itemsByPages: 0,
	},
	storageId: '94e63481-f797-4485-8e88-8406f1867b63',
	expirydDateToResponse: '0001-01-01T00:00:00',
};
