import { numberToLocaleWithcurrency } from '../../../shared/services/utils.service';
import { ObjectLiteral } from '../../../shared/enums/object-literal.enum';
import { ProductType } from '../../../shared/enums/product.enum';
import { Historical } from '../../../shared/interfaces';
import { Button, IcomoonIcon } from '../../ui-elements';
import styles from './historical-card.module.scss';

export interface HistoricalCardProps {
	colorPrimary?: string;
	colorSecondary?: string;
	historical: Historical;
	t: (value: string) => string;
	onSelected?: (item: Historical) => void;
}

export function HistoricalCard({ t, colorPrimary, colorSecondary, historical, onSelected }: HistoricalCardProps): JSX.Element {
	const ZERO: number = 0;
	const HistoricalStatus = ({ status }: { status: string }): JSX.Element => {
		const isActive: string = 'active';
		return <span style={{ color: status === isActive ? '#53B908' : 'black' }}>{t(status).toUpperCase()}</span>;
	};

	const HistoricalProductIcon = ({ historicalPorductType }: { historicalPorductType: ProductType }): JSX.Element => {
		const historicalProductIcons = {
			[ProductType.hotel]: 'hotelsIco',
			[ProductType.activity]: 'iconResumen',
			[ProductType.car]: 'carsIco',
			[ProductType.air]: 'frontPlaneIco',
			[ProductType.plan]: 'disneyIco',
			[ObjectLiteral.default]: 'iconResumen',
		};

		return (
			<IcomoonIcon
				className='mr-2'
				icon={historicalProductIcons[historicalPorductType] || historicalProductIcons[ObjectLiteral.default]}
				color={colorPrimary}
			/>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<section className='historical-card md:flex my-4 px-6 py-8 text-black rounded-lg bg-white shadow-lg justify-between text-[14px]'>
				<div className='historical-card__contentProduct mx-2 md:w-5/12'>
					<div className='historical-card__type flex flex-wrap'>
						<HistoricalProductIcon historicalPorductType={historical.productType} />
						<span style={{ color: colorPrimary }} className='historical-card__typeTitle mr-1'>
							{t('bookingType')}:
						</span>
						{t(historical.productType)}
					</div>
					<div className='historical-card__date flex flex-wrap'>
						<IcomoonIcon className='mr-2' icon='calendarBlankIco' color={colorPrimary} />
						<span style={{ color: colorPrimary }} className='historical-card__dateTitle mr-1'>
							{t('reservationDate')}:
						</span>
						{historical.bookingDateFormated}
					</div>
				</div>

				<div className='historical-card__contentInfo  mx-2  md:w-5/12'>
					<p className='historical-card__number flex-wrap'>
						<span style={{ color: colorPrimary }} className='historical-card__numberTile'>
							{t('reservationNumber')}:
						</span>{' '}
						{historical.providerCode}
					</p>
					<p className='historical-card__status flex-wrap'>
						<span style={{ color: colorPrimary }} className='historical-card__statusTitle'>
							{t('reservationStatus')}:
						</span>{' '}
						{<HistoricalStatus status={historical.status} />}
					</p>
				</div>

				<div className='historical-card__split border bg-gray-400' />

				<div className='historical-card__contentPurchase  mx-2  md:w-3/12'>
					{historical.fareSummary.totalPoints !== ZERO && (
						<p style={{ color: colorSecondary }} className='historical-card__point flex-wrap'>
							{`${numberToLocaleWithcurrency(historical.fareSummary.totalPoints)} ${historical.fareSummary.pointsCurrency}`}
						</p>
					)}

					{historical.fareSummary.totalCash !== ZERO && (
						<p className='historical-card__cash flex-wrap'>
							{historical.fareSummary.totalPoints !== ZERO && '+ '}

							{`${historical.fareSummary.currency} ${numberToLocaleWithcurrency(historical.fareSummary.totalCash, historical.fareSummary.currency)}`}
						</p>
					)}
				</div>
				<Button
					customClass='historical-card__button h-[36px] w-[160px] rounded-xl px-6 text-white items-center'
					onClick={() => onSelected && onSelected(historical)}
					styles={{
						background: colorSecondary,
					}}
					content={<span className='historical-card__buttonTitle text-[14px]'>{t('showDetails')}</span>}
				/>
			</section>
		</>
	);
}

export default HistoricalCard;
