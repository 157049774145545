import { CreateUserGuestResponse } from '../interfaces/user-guest.interface';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { endPoints } from '../end-points/end-points';
import { HttpCall } from './http-call.service';

class UserService {
	private readonly httpCall: HttpCall = new HttpCall();

	public async getUser(token: string): Promise<CreateUserGuestResponse | null> {
		try {
			const url: string = `${process.env.REACT_APP_MANAGEMENT_API ?? ''}/${endPoints.getUser}`;
			const header = {
				Authorization: `Bearer ${token}`,
			};

			const user: ResponseHttp<CreateUserGuestResponse> = await this.httpCall.get(url, header);
			if (user.response != null) {
				return user.response;
			}

			if (user.error) {
				throw new Error(`HTTP error when getting user data`);
			}

			return null;
		} catch (error) {
			throw new Error(`Error when getting user data `);
		}
	}
}

export default new UserService();
