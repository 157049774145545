import { Image } from '../../ui-elements/image/image';
export interface LogoProps {
	url: string;
	alt?: string;
}

export function Logo(props: LogoProps): JSX.Element {
	return (
		<div className='logo'>
			<Image
				url={props.url}
				width={'300px'}
				height={'150x'}
				alt={props.alt}
				styles={{
					width: 'auto',
					maxWidth: '300px',
					maxHeight: '150px',
				}}
			/>
		</div>
	);
}
export default Logo;
