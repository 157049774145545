export enum CalendarRestrictionTypes {
	flights = 'air',
	hotels = 'hotel',
	activities = 'activity',
	assistances = 'assistance',
	plans = 'plan',
	cars = 'cars',
	car = 'car',
	disney = 'plan',
	hotelsDisney = 'hotelDisney',
}
