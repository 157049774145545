import PassengersFieldInputs from '../../../../shared-components/search/passengers-field/passengers-field-inputs/passengers-field-inputs';
import { FieldValues, UseFormRegister, UseFormReturn, FieldErrorsImpl, DeepRequired, UseFormWatch } from 'react-hook-form';
import { SearchFieldsUtil } from '../../../../../shared/utils/search-fields.util';
import { ElementTypes } from '../../../../../shared/enums/dynamic-forms.enum';
import {
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
	PassengersFieldRestrictionsInterface,
	DisabledInputsPassengersFieldInterface,
	CustomTraductionsLabelsPassengersFieldInterface,
} from '../../../../../shared/interfaces/generics/search-fields.interface';
import { useCallback, useState, FC, useEffect, useRef } from 'react';
import { CounterInputData } from '../../../../../shared/interfaces';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import Backdrop from '../../../../ui-elements/backdrop/backdrop';
import Input from '../../../../ui-elements/input/input';
import styles from './passengers-field-v2.module.scss';

export interface PassengersFieldV2Props {
	title?: string;
	placeHolder?: string;
	name: string;
	backButtonTraduction?: string;
	nextButtonTraduction?: string;
	restrictions: PassengersFieldRestrictionsInterface;
	useBackButton?: boolean;
	required?: boolean;
	customTraductionsLabels?: CustomTraductionsLabelsPassengersFieldInterface;
	colorSecondary?: string;
	className?: string;
	desktopModalClassName?: string;
	isSecondaryTemplate?: boolean;
	onlyOneTypeOfPassenger?: boolean;
	useMinimunOnePassenger?: boolean;
	useOptionalText?: boolean;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	index: string | number;
	traductions: (label: string) => string;
	register: UseFormRegister<FieldValues>;
	watch?: UseFormWatch<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	onShowModal?: (show: boolean) => void;
	onChangePassengers?: (passengersFieldInputs: PassengersFieldDataInterface, name: string) => void | string;
	onModalInputsHandler?: (currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string) => number;
	onModalFieldHandler?: (data: PassengersFieldDataInterface, name: string) => PassengersFieldDataInterface;
	visibleTitle?: boolean;
	iconClassName?: string;
	disabledInputs?: DisabledInputsPassengersFieldInterface;
	passengersAmountsRestrictions?: number;
	passengersAlwaysVisible?: boolean;
	typeProduct?: string;
	maxRooms?: number;
	maxGuestsPerRoom?: number;
	maxPassengers?: number;
	truncate?: boolean;
	inputContainerClassName?: string;
	forceError?: boolean;
}

export const PassengersFieldV2: FC<PassengersFieldV2Props> = ({
	title,
	placeHolder,
	name,
	restrictions,
	errors,
	required,
	customTraductionsLabels,
	colorSecondary,
	className,
	desktopModalClassName,
	onlyOneTypeOfPassenger,
	useMinimunOnePassenger,
	useOptionalText,
	traductions,
	register,
	watch,
	setValue,
	getValues,
	onShowModal,
	onChangePassengers,
	onModalInputsHandler,
	onModalFieldHandler,
	visibleTitle,
	isSecondaryTemplate,
	disabledInputs,
	children,
	passengersAmountsRestrictions,
	typeProduct,
	maxRooms,
	maxGuestsPerRoom,
	maxPassengers,
	truncate,
	inputContainerClassName,
	forceError,
	index,
}) => {
	const inputsNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
	const responsiveSize: number = 768;
	const changeWindowWidth: number = useWindowWidth();
	const windowWidth: number = window.innerWidth;
	const [showModal, setShowModal] = useState<boolean>(false);
	const [childrenValue, setChildrenValue] = useState<number>(0);
	const [infantsValue, setInfantsValue] = useState<number>(0);
	const [adultsValue, setAdultsValue] = useState<number>(0);
	const [adultsRestriction, setAdultsRestriction] = useState<CounterInputData>({
		max: restrictions.adults.maximum,
		min: restrictions.adults.minimum,
	});

	const [childrenRestriction, setChildrenRestriction] = useState<CounterInputData>({
		max: restrictions.children.maximum,
		min: restrictions.children.minimum,
	});

	const [infantsRestriction, setInfantsRestriction] = useState<CounterInputData>({
		max: restrictions.infants.maximum,
		min: restrictions.infants.minimum,
	});

	const [iconClassNameValue, setIconClassNameValue] = useState<string>('chevronBottomIco text-[7px]');
	const watchAdults: string = watch ? watch(inputsNames.adultsName) : '';
	const watchChildren: string = watch ? watch(inputsNames.childrenName) : '';
	const watchInfants: string = watch ? watch(inputsNames.infantsName) : '';
	const showModalHandler = useCallback(
		(show: boolean) => {
			if (onShowModal) {
				onShowModal(show);
			}

			setShowModal(show);

			setIconClassNameValue(show ? 'chevronUpIco text-[7px]' : 'chevronBottomIco text-[7px]');
		},
		[onShowModal],
	);

	const changePassengersHandler = useCallback(
		(data: PassengersFieldDataInterface): void => {
			if (setValue) {
				const tempNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
				let passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
					data.adults,
					data.children,
					data.infants,
					traductions('adult') || 'adult',
					traductions('adults') || 'adults',
					traductions('child') || 'child',
					traductions('children') || 'children',
					traductions('infant') || 'infant',
					traductions('infants') || 'infants',
				);
				setValue(tempNames.adultsName, data.adults);

				setValue(tempNames.childrenName, data.children);

				setValue(tempNames.infantsName, data.infants);

				if (onChangePassengers) {
					passengersDisplayValue = onChangePassengers({ ...data }, name) || passengersDisplayValue;
				}

				setValue(tempNames.name, passengersDisplayValue);
			}
		},
		[name, setValue, onChangePassengers, showModalHandler, traductions],
	);

	useEffect(() => {
		setAdultsValue(Number(watchAdults || 0));

		setChildrenValue(Number(watchChildren || 0));

		setInfantsValue(Number(watchInfants || 0));
	}, [watchAdults, watchChildren, watchInfants]);

	useEffect(() => {
		if (maxPassengers && isSecondaryTemplate) {
			const maxAdults: number = maxPassengers - childrenValue - infantsValue;
			const maxChildren: number = maxPassengers - adultsValue - infantsValue;
			const maxInfants: number = maxPassengers - adultsValue - childrenValue;
			setAdultsRestriction({ ...adultsRestriction, max: maxAdults });

			setChildrenRestriction({ ...childrenRestriction, max: maxChildren });

			setInfantsRestriction({ ...infantsRestriction, max: maxInfants });
		}
	}, [adultsValue, infantsValue, childrenValue]);

	const divRef = useRef<HTMLDivElement>(null);
	const isMounted = useRef(false);
	const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
		const firstElement: number = 0;
		if (isMounted.current && divRef.current && !divRef?.current?.contains(event.composedPath()[firstElement] as Node)) {
			showModalHandler(false);
		}
	};

	useEffect(() => {
		isMounted.current = true;

		if (windowWidth <= responsiveSize && showModal) {
			document.addEventListener('mousedown', handleClickOutside);

			document.addEventListener('touchstart', handleClickOutside);
		} else {
			isMounted.current = false;

			document.removeEventListener('mousedown', handleClickOutside);

			document.removeEventListener('touchstart', handleClickOutside);
		}

		return () => {
			isMounted.current = false;

			document.removeEventListener('mousedown', handleClickOutside);

			document.removeEventListener('touchstart', handleClickOutside);
		};
	}, [changeWindowWidth, windowWidth, responsiveSize, showModal]);

	const defaultPassengersElement: JSX.Element = (
		<PassengersFieldInputs
			name={name}
			adultsDefaultValue={adultsValue || restrictions?.adults?.default}
			childrenDefaultValue={childrenValue || restrictions?.children?.default}
			infantsDefaultValue={infantsValue || restrictions?.infants?.default}
			adultsCounterData={adultsRestriction}
			childrenCounterData={childrenRestriction}
			infantsCounterData={infantsRestriction}
			traductions={traductions}
			customTraductionsLabels={customTraductionsLabels}
			colorSecondary={colorSecondary}
			className={isSecondaryTemplate ? (showModal ? '' : 'hidden') : ''}
			register={register}
			setValue={setValue}
			getValues={getValues}
			callBack={changePassengersHandler}
			changeInputsValidation={onModalInputsHandler}
			changeFieldValidation={onModalFieldHandler}
			disabledInputs={disabledInputs}
			passengersAmountsRestrictions={passengersAmountsRestrictions}
			onlyOneTypeOfPassenger={onlyOneTypeOfPassenger}
			useMinimunOnePassenger={useMinimunOnePassenger}
			typeProduct={typeProduct}
			maxRooms={maxRooms}
			maxGuestsPerRoom={maxGuestsPerRoom}
			isSecondaryTemplate={isSecondaryTemplate}
		>
			{children}
		</PassengersFieldInputs>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				data-testid='divRef'
				ref={divRef}
				className={`passengersField passengersField-room-v2 ${index} ${className ?? ''} relative w-full md:bg-transparent flex flex-col`}
			>
				<>
					<div
						data-testid='passengersField__inputContainer'
						className={`passengersField__inputContainer px-[16px] md:px-0 pt-[25px] pb-[2px] md:py-0 ${
							inputContainerClassName || ''
						}  flex flex-col md:flex-row`}
						onClick={() => showModalHandler(!showModal)}
					>
						<div className={`w-full flex flex-col md:flex-grow font-family-regular ${isSecondaryTemplate ? 'pl-0 gap-2' : 'pl-[30px] md:pl-[25px]'}`}>
							<span
								className={`${visibleTitle ? 'md:block' : ''} ${
									isSecondaryTemplate ? 'md:text-gray-700 text-sm font-medium leading-5' : 'hidden text-xs text-[#616161]'
								} whitespace-nowrap`}
								style={{
									color: isSecondaryTemplate ? undefined : colorSecondary,
								}}
							>
								{title || 'title'}
								{isSecondaryTemplate && useOptionalText && (
									<span className='text-gray-400 ml-[4px]'>({traductions('optionalLabel') || 'optional'})</span>
								)}
							</span>
							<div className='relative w-full flex flex-row items-center'>
								<div className='passengersField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
									<Input
										register={register(inputsNames.adultsName, {
											required: disabledInputs?.adults ? false : required,
										})}
										type={ElementTypes.text}
										autocomplete={'off'}
									/>
									<Input
										register={register(inputsNames.childrenName, {
											required: disabledInputs?.children ? false : required,
										})}
										type={ElementTypes.text}
										autocomplete={'off'}
									/>
									<Input
										register={register(inputsNames.infantsName, {
											required: disabledInputs?.infants ? false : required,
										})}
										type={ElementTypes.text}
										autocomplete={'off'}
									/>
								</div>
								<Input
									readonly={true}
									register={register(inputsNames.name, { required })}
									required={required}
									defaultValue={''}
									icon={isSecondaryTemplate ? iconClassNameValue : undefined}
									isSecondaryTemplate={isSecondaryTemplate}
									type={ElementTypes.text}
									placeholder={placeHolder || 'withOutPassengersPlaceHolder'}
									autocomplete={'off'}
									className={`passengersField__input ${
										errors[inputsNames.name] ||
										errors[inputsNames.adultsName] ||
										errors[inputsNames.infantsName] ||
										errors[inputsNames.childrenName] ||
										forceError
											? 'border-1 border-red-500 text-red-500'
											: ''
									} ${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'} ${
										showModal ? 'passengersField__openModal' : ''
									}`}
									inputClassName={
										'bg-transparent !p-0 !text-base font-normal md placeholder:text-base placeholder:font-normal md:placeholder placeholder:text-[#C3C7CE]'
									}
									style={{
										color: isSecondaryTemplate ? undefined : colorSecondary,
									}}
									truncate={truncate}
								/>
							</div>
						</div>
					</div>
					{showModal && (
						<>
							<Backdrop
								show={showModal}
								onClick={() => showModalHandler(false)}
								zIndex={10}
								backgroundColor={'transparent'}
								opacity={1}
								className='passengersField__backdrop'
								portalBackdrop={true}
								styles={`
											.passengersField__backdrop {
												display: none;
											}

											@media(min-width: 768px) {
												.passengersField__backdrop {
													display: block;
												}
											}
										`}
							/>
							<div
								className={`passengersField__desktopPassengers hidden md:flex w-full md:w-[375px] md:absolute md:z-[12]  md:left-0 rounded-[10px] bg-white ${
									desktopModalClassName || ''
								}`}
							>
								{defaultPassengersElement}
							</div>
						</>
					)}
					<div
						className={`passengersField__mobilePassengers w-full md:hidden h-full flex-grow overflow-y-auto ${
							isSecondaryTemplate ? 'px-[16px]' : ' px-[40px]'
						}`}
					>
						{showModal && (
							<>
								<Backdrop
									show={showModal}
									onClick={() => showModalHandler(!showModal)}
									zIndex={10}
									backgroundColor={'transparent'}
									opacity={1}
									className='hidden md:block passengersField__backdrop'
									portalBackdrop={true}
									styles={`
											.passengersField__backdrop {
												display: none;
											}

											@media(min-width: 768px) {
												.passengersField__backdrop {
													display: block;
												}
											}
										`}
								/>
								<div
									data-testid='passengersField__mobile'
									className={`passengersField__desktopPassengers flex w-full  absolute z-[12] mt-7  left-0 rounded-[8px] px-4 bg-white ${
										desktopModalClassName || ''
									}`}
								>
									{defaultPassengersElement}
								</div>
							</>
						)}
					</div>
				</>
			</div>
		</>
	);
};

PassengersFieldV2.defaultProps = {
	required: false,
};

export default PassengersFieldV2;
