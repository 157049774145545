import IcomoonIcon from '../../../ui-elements/icomoon-icon/icomoon-icon';

export interface HistoricalItemInfoProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	customClass: string;
	isIcon: boolean;
	classIcon: string;
	title: string;
	value: string;
}

export function HistoricalItemInfo({
	t,
	colorPrimary,
	colorSecondary,
	customClass,
	classIcon,
	title,
	value,
	isIcon,
}: HistoricalItemInfoProps): JSX.Element {
	return (
		<h3 className={`flex flex-wrap text-[14px] my-1 text-gray-500 ${customClass}`}>
			{isIcon && <IcomoonIcon className='mr-2' icon={classIcon} color={colorPrimary} />}
			<span className='historical-detail__itemInfo mr-1' style={{ color: colorPrimary }}>
				{t(title)}:
			</span>
			{value}
		</h3>
	);
}

export default HistoricalItemInfo;
