import { HistoricalPassengersQuantity } from '../../../../shared/interfaces';
import HistoricalItemInfo from './historical-item-info';

export interface HistoricalPassengersQuantityProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	historicalPassengersQuantity: HistoricalPassengersQuantity;
}

export function HistoricalPassengersQuantityDetail({
	t,
	colorPrimary,
	colorSecondary,
	historicalPassengersQuantity,
}: HistoricalPassengersQuantityProps): JSX.Element {
	const ZERO: number = 0;
	return (
		<>
			<div className='historical-detail__split my-4' />
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__adults'
				isIcon={true}
				classIcon='personIco'
				title='adults'
				value={historicalPassengersQuantity.adults.toString()}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__children'
				isIcon={true}
				classIcon='personIco'
				title='children'
				value={historicalPassengersQuantity.children.toString()}
			/>
			{historicalPassengersQuantity.infants > ZERO && (
				<HistoricalItemInfo
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					customClass='historical-detail__infants'
					isIcon={true}
					classIcon='personIco'
					title='infants'
					value={historicalPassengersQuantity.infants.toString()}
				/>
			)}
			<div className='historical-detail__split my-3' />
		</>
	);
}

export default HistoricalPassengersQuantityDetail;
