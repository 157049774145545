import styles from './button-link.module.scss';
import React from 'react';

export interface ButtonLinkProps {
	href: string;
	children?: string | JSX.Element;
	style?: React.CSSProperties;
	className?: string;
}

export const ButtonLink = (props: ButtonLinkProps): JSX.Element => {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<a
				href={props.href}
				className={`flex justify-center items-center py-3 px-6 text-base font-family-black button ${props.className || ''}`}
				style={props.style}
				target='_blank'
				rel='noreferrer'
			>
				{props?.children || ''}
			</a>
		</>
	);
};

ButtonLink.defaultProps = {
	className: '',
};

export default ButtonLink;
