import { FareDetailSummary } from '../../../shared/interfaces/summary-detail.interface';
import { SkeletonFareSummary } from './skeleton/fare-summary-skeleton';
import { ModelTypeEnum } from '../../../shared/enums';
import { Button } from '../../../lib/ui-elements';

export interface FaresummaryResumenProps {
	t: (item: string) => string;
	businessModel: ModelTypeEnum;
	faresummary: FareDetailSummary;
	colorPrimary: string;
	callBack: (faresummary: FareDetailSummary) => void;
}

export function FaresummaryResumen({ businessModel, faresummary, colorPrimary, callBack, t }: FaresummaryResumenProps): JSX.Element {
	return (
		<div className='faresummaryResumen bg-white rounded-lg shadow-lg' data-testid='faresummary-resumen'>
			{!faresummary ? (
				<SkeletonFareSummary />
			) : (
				<div className='faresummaryResumen__container p-6'>
					<p className='faresummaryResumen__title'>
						<span className='iconResumen mr-3'></span>
						<span className='faresummaryResumen__title-text uppercase'>{t('purchaseSummary')}</span>
					</p>

					<div className='faresummaryResumen__fareDetail mt-4'>
						<div className='faresummaryResumen__fareValues mb-2'>
							<div className='flex justify-between items-center'>
								<p className='faresummaryResumen__subtitles text-sm'>{t('quantityPassengers')} :</p>
							</div>
						</div>

						<div className='faresummaryResumen__fareValues mb-2'>
							{!!faresummary?.passengersQuantity?.adults && (
								<div className='flex justify-between items-center'>
									<p className='faresummaryResumen__fareDetailSubtitles text-sm'>{t('adults')}</p>

									<p className='faresummaryResumen__fareDetailPrice text-sm'>{faresummary?.passengersQuantity?.adults}</p>
								</div>
							)}

							{!!faresummary?.passengersQuantity?.children && (
								<div className='flex justify-between items-center'>
									<p className='faresummaryResumen__fareDetailSubtitles text-sm'>{t('children')}</p>

									<p className='faresummaryResumen__fareDetailPrice text-sm'>{faresummary?.passengersQuantity?.children}</p>
								</div>
							)}

							{!!faresummary?.passengersQuantity?.infants && (
								<div className='flex justify-between items-center'>
									<p className='faresummaryResumen__fareDetailSubtitles text-sm'>{t('infants')}</p>

									<p className='faresummaryResumen__fareDetailPrice text-sm'>{faresummary?.passengersQuantity?.infants}</p>
								</div>
							)}
						</div>

						{!!faresummary?.fee && (
							<div className='faresummaryResumen__fareValues mb-2'>
								<div className='flex justify-between items-center'>
									<p className='faresummaryResumen__fareDetailCurrency text-xs'>{t('emissionFee')}</p>

									<p className='faresummaryResumen__fareDetailPrice text-xs'>{faresummary.feeFormated}</p>
								</div>
							</div>
						)}
					</div>

					<hr className='mt-4' />

					<div className='faresummaryResumen__fareDetail mt-4'>
						{businessModel === ModelTypeEnum.POINT_OR_MONEY && (
							<div className='faresummaryResumen__fareValues'>
								<div className='flex justify-between items-center'>
									{faresummary.points ? (
										<>
											<p className='faresummaryResumen__fareCurrency'>{faresummary.pointsCurrency}</p>

											<p className='faresummaryResumen__farePrice'>{faresummary.pointsFormated}</p>
										</>
									) : (
										<>
											<p className='faresummaryResumen__fareCurrency'>{faresummary.currency}</p>

											<p className='faresummaryResumen__farePrice'>{faresummary.totalCashFormated}</p>
										</>
									)}
								</div>

								{!!faresummary.points && !!faresummary.cash && (
									<p className='faresummaryResumen__fareCash text-right'>
										+ <span className='faresummaryResumen__fareCash-currency mr-1'>{faresummary.currency}</span>
										<span className='faresummaryResumen__fareCash-value text-sm'>{faresummary.totalCashFormated}</span>
									</p>
								)}
							</div>
						)}

						{businessModel === ModelTypeEnum.CASH && (
							<div className='faresummaryResumen__fareValues'>
								<div className='flex justify-between items-center'>
									<p className='faresummaryResumen__fareCurrency'>{faresummary.currency}</p>

									<p className='faresummaryResumen__farePrice'>{faresummary.totalCashFormated}</p>
								</div>
							</div>
						)}

						{businessModel === ModelTypeEnum.POINTS && (
							<div className='faresummaryResumen__fareValues'>
								<div className='flex justify-between items-center'>
									<p className='faresummaryResumen__fareCurrency'>{faresummary.pointsCurrency}</p>

									<p className='faresummaryResumen__farePrice'>{faresummary.pointsFormated}</p>
								</div>
							</div>
						)}
						{!!faresummary.equivalentInCash && (
							<p className='faresummaryResumen__equivalentInCash text-xs text-right'>
								<span className='infoCirlce2Ico'></span> {t('saveUp')} {faresummary.equivalentInCash}
							</p>
						)}
					</div>

					<div className='faresummaryResumen__action mt-4'>
						<Button
							onClick={() => callBack(faresummary)}
							text={t('continue')}
							customClass='rounded-lg text-white w-full'
							styles={{ background: colorPrimary }}
						/>
					</div>
				</div>
			)}
		</div>
	);
}
