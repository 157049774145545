import { HotelsDisneyResumeAsideWidget } from './widgets/hotels-disney-search/hotels-disney-resume-aside/hotelsDisneyResumeAsideWidget';
import { HotelsDisneySearchWidget } from './widgets/hotels-disney-search/hotelsDisneySearchWidget';
import { SpecialEventsSearchWidget } from './widgets/special-events-search/special-events-search';
import { AssistancesSearchWidget } from './widgets/assistances-search/assistancesSearchWidget';
import { PointsConsultationWidget } from './widgets/bac-credomatic/pointsConsultationWidget';
import { CarRecommendationWidget } from './widgets/car-recommendations/car-recommendations';
import { ActivitiesSearchWidget } from './widgets/activities-search/activitiesSearchWidget';
import { RecommendationHotelDisneyMock } from './shared/mocks/recommendation.mock';
import { FlightsSearchWidget } from './widgets/flights-search/flightsSearchWidget';
import { HotelsSearchWidget } from './widgets/hotels-search/hotelsSearchWidget';
import { DisneySearchWidget } from './widgets/disney-search/disneySearchWidget';
import { WidgetsDeclaration } from './shared/interfaces/widgets-declaration';
import { CarsSearchWidget } from './widgets/cars-search/carsSearchWidget';
import PlansSearchWidget from './widgets/plans-search/plansSearchWidget';
import { useEffect, useCallback } from 'react';
import 'regenerator-runtime/runtime';
import { render } from 'react-dom';
import './shared/services/i18n';

declare let window: WidgetsDeclaration;

window.carRecommendationsWidget = CarRecommendationWidget;
window.carSearchWidget = CarsSearchWidget;
window.flightSearchWidget = FlightsSearchWidget;
window.hotelSearchWidget = HotelsSearchWidget;
window.activitySearchWidget = ActivitiesSearchWidget;
window.assistancesSearchWidget = AssistancesSearchWidget;
window.plansSearchWidget = PlansSearchWidget;
window.disneySearchWidget = DisneySearchWidget;
window.hotelDisneySearchWidget = HotelsDisneySearchWidget;
window.hotelsDisneyResumeAsideWidget = HotelsDisneyResumeAsideWidget;
window.pointsConsultationWidget = PointsConsultationWidget;
window.specialEventsSearchWidget = SpecialEventsSearchWidget;


function InitDevelopmentSmartLinksWidgets() {
	const executeInitSmartLinksWidgets = useCallback(() => {
		const tempToken =
			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJQRVRST0xJIEdJTUVORVogRVNQSU5PU0EiLCJlbWFpbCI6IjQ4OTA2MjA2MDA1MzExNDI3NEBzYW50YW5kZXJ1bmlxdWUuY29tIiwianRpIjoiNWVlZjEwYTYtOGRhZS00YjZiLWE3NTgtMjZjMzY5ODgyZDk1IiwiZ2VuZGVyIjoiTWFsZSIsImFnZW5jeUlkIjoiQ2lCdHd3M3JuUmtwZUZkUFIiLCJhZ2VuY3lOYW1lIjoiU2FudGFuZGVyIiwidXNlcklkIjoiYWE2ODQwOGEtZWJiMC00NTY3LWJjZWQtMjdhYTQ1OGNiYTIxIiwibmFtZSI6IlBFVFJPTEkgR0lNRU5FWiBFU1BJTk9TQSIsInVzZXJOYW1lIjoiNDg5MDYyMDYwMDUzMTE0Mjc0QHNhbnRhbmRlcnVuaXF1ZS5jb20iLCJjdWx0dXJlIjoiZXMtTVgiLCJhZ2VuY3lQcml2YXRlIjoiVHJ1ZSIsImFnZW5jeUFsaWFzIjoiIiwicnQiOiJ0bCIsImhhc0VuY3J5cHRlZEtleUFlcyI6IkZhbHNlIiwiZXhwIjoxNzEyNjQwMDc2LCJpc3MiOiJodHRwOi8vZGV2YXV0aC5zbWFydGxpbmtzLmRldiIsImF1ZCI6Imh0dHBzOi8vc2Rlci10ZXN0LnNtYXJ0bGlua3MuZGV2In0.3tBS2EoeRnmf0aFm6XBAI27PVaORUwPUzi4unDBbFnA';

		const callBack = (e: HTMLDivElement | null) => {
			if (e != null) {
				console.log('Widget On!');
			}
		};

		const callBackSearch = (e: any) => {
			console.log('callBackSearch: ');
			if (e) {
				console.log(e);
			}
		};

    const resultAddons = (e: any) => {
			console.log('resultAddons: ');
			if (e) {
				console.log(e);
			}
		};

/*
      window.carRecommendationsWidget({
        elementId: 'carRecommendationsWidget',
        pickupLocationCode: 'MIA',
        dropOffLocationCode: 'MIA',
        pickUpDate: '2023-04-18',
        pickUpTime: '0930',
        dropOffDate: '2023-04-20',
        dropOffTime: '0930',
        passengersQuantity: 1,
        quantityOfRecommendations: 30,
        language: 'es',
        token: tempToken,
        useEquivalentCurrency: true,
        paymentType: '0',
        callBack: callBack
      });
*/
/*
      window.carSearchWidget({
        elementId: 'carSearchWidget',
        language: 'es',
        token: tempToken,
        callUrlSearch: callBackSearch,
        layout: 'spreaded',
        callBack: callBack
      });
*/
      
      window.flightSearchWidget({
        elementId: 'flightSearchWidget',
        language: 'es',
        token: tempToken,
        callUrlSearch: callBackSearch,
        callBack: callBack
      });
      
		
      /*window.hotelSearchWidget({
        elementId: 'hotelSearchWidget',
        language: 'es',
        token: tempToken,
        callUrlSearch: callBackSearch,
        callBack: callBack
      }); */

		/* window.activitySearchWidget({
        elementId: 'activitySearchWidget',
        language: 'es',
        token: tempToken,
        callUrlSearch: callBackSearch,
        callBack: callBack
      }); */
      /*
		window.assistancesSearchWidget({
			elementId: 'assistancesSearchWidget',
			language: 'es',
			token: tempToken,
			callUrlSearch: callBackSearch,
			callBack,
		});
*/
		/* window.plansSearchWidget({
        elementId: 'plansSearchWidget',
        language: 'es',
        token: tempToken,
        callUrlSearch: callBackSearch,
        callBack: callBack
      }); */
    /*  
      window.hotelDisneySearchWidget({
        elementId: 'hotelDisneySearchWidget',
        language: 'es',
        token: tempToken,
        callUrlSearch: callBackSearch,
        callBack: callBack
      });
*/
      // window.hotelsDisneyResumeAsideWidget({
      //   elementId: 'hotelsDisneyResumeAsideWidget',
      //   language: 'es',
      //   token: tempToken,
      //   hotel: RecommendationHotelDisneyMock,
      //   callUrlSearch: callBackSearch,
      //   resultAddons: resultAddons,
      //   callBack: callBack
      // });
/*
/*
		 window.disneySearchWidget({
        elementId: 'disneySearchWidget',
        language: 'es',
        token: tempToken,
        callUrlSearch: callBackSearch,
        callBack: callBack
      });*/
      
			// window.specialEventsSearchWidget({
      //   elementId: 'disneySearchWidget',
      //   language: 'es',
      //   token: tempToken,
      //   callUrlSearch: callBackSearch,
      //   callBack: callBack,
			// 	customLayout: 'SEARCH'
      // });

      // window.pointsConsultationWidget({
      //   elementId: 'pointsConsultationWidget',
      //   language: 'es',
      //   token: tempToken,
      //   callBack: callBack
      // });

    

	}, []);

	useEffect(() => {
		if (window) {
			executeInitSmartLinksWidgets();
		}
	}, [executeInitSmartLinksWidgets]);
	return <></>;
}

if (process.env.NODE_ENV === 'development') {
	render(<InitDevelopmentSmartLinksWidgets />, document.getElementById('root'));
}
