import CarCoveragesMobileSkeleton from './skeleton/car-coverages-mobile-skeleton';
import styles from '../car-coverages-desktop/car-coverages-desktop.module.scss';
import { TableData, RowData } from '../../../../../../../shared/interfaces';
import innerStyles from './car-coverages-mobile.module.scss';
import { Table } from '../../../../../../ui-elements';

export interface CarCoveragesMobileProps {
	coveragesTables?: TableData[];
	loading?: boolean;
	noCoveragesText?: string;
}

export const CarCoveragesMobile = ({ coveragesTables, loading, noCoveragesText }: CarCoveragesMobileProps): JSX.Element => {
	const tableData: JSX.Element[] = [];
	coveragesTables?.forEach((coverage: TableData, index: number) => {
		const buttonElement: RowData[] | undefined = coverage.rows.length ? coverage.rows.find((row: RowData[]) => row.length === 1) : undefined;
		tableData.push(
			<div key={index}>
				<div className={'carCoveragesMobile__title'}>
					{coverage.head.map((label, tempIndex) => (
						<div key={tempIndex}>{label.value}</div>
					))}
				</div>
				<Table data={coverage} className={'w-full CustomerDataMock mx-auto table-fixed carCoveragesDesktop__table'} tdClassName={'text-center'} />
				<div className={'carCoveragesMobile__button py-[20px]'}>{buttonElement ? buttonElement[0].value : null}</div>
			</div>,
		);
	});

	return loading ? (
		<CarCoveragesMobileSkeleton />
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<style dangerouslySetInnerHTML={{ __html: innerStyles.toString() }}></style>
			<div className={'carCoveragesMobile flex flex-col carCoveragesDesktop carCoveragesMobile'}>
				{coveragesTables?.length ? <>{tableData.map(item => item)}</> : <>{noCoveragesText || 'No coverages'}</>}
			</div>
		</>
	);
};

export default CarCoveragesMobile;
