import { CarSearchFormInputsNames, ElementTypes, SearchFieldsNames } from '../../../../../../shared/enums';
import { faLocationDot, faCalendar, faTicket } from '@awesome.me/kit-dc2201b3ec/icons/classic/solid';
import { CarSearchTraductions, CarSearchFormMobileFiled } from '../../../../shared/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../../../../../ui-elements';
import { FC } from 'react';

export interface SpreadedCarSearchFormMobileProps {
	departureValue: string;
	arrivalValue?: string;
	datesValue: string;
	traductions: CarSearchTraductions;
	showMobileFieldsContainerHandler: (show: boolean, fieldId: string, fieldName: string) => void;
	isSecondaryTemplate: boolean;
	isTertiaryTemplate?: boolean;
	truncate?: boolean;
	colorSecondary: string;
	customClass?: string;
}

export const SpreadedCarSearchFormMobile: FC<SpreadedCarSearchFormMobileProps> = ({
	children,
	departureValue,
	arrivalValue,
	datesValue,
	traductions,
	showMobileFieldsContainerHandler,
	isSecondaryTemplate,
	colorSecondary,
	truncate,
	customClass,
	isTertiaryTemplate,
}) => {
	const handleClick = (fieldId: string, fieldName: string): void => {
		showMobileFieldsContainerHandler(true, fieldId, fieldName);
	};

	const fieldsCars: CarSearchFormMobileFiled[] = [
		{
			label: traductions.destinations.title,
			value: departureValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconMarkerSecondary';
					case isTertiaryTemplate:
						return 'pinIco';
					default:
						return faLocationDot;
				}
			})(),
			placeholder: traductions.destinations.placeHolder,
			idRef: SearchFieldsNames.departureDestination,
			nameRef: CarSearchFormInputsNames.departureLocation,
		},
		{
			label: traductions.destinationsArrival.title,
			value: arrivalValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconMarkerSecondary';
					case isTertiaryTemplate:
						return 'pinIco';
					default:
						return faLocationDot;
				}
			})(),
			placeholder: traductions.destinationsArrival.placeHolder,
			idRef: SearchFieldsNames.arrivalDestination,
			nameRef: CarSearchFormInputsNames.arrivalLocation,
		},
		{
			label: traductions.dates.title,
			value: datesValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconCalendarSecondary';
					case isTertiaryTemplate:
						return 'iconCalendarDepartureTertiary';
					default:
						return faCalendar;
				}
			})(),
			placeholder: traductions.dates.placeHolder,
			idRef: SearchFieldsNames.dates,
			nameRef: CarSearchFormInputsNames.dates,
		},
		{
			label: traductions.discounts.title,
			value: '',
			icon: isSecondaryTemplate ? 'iconDiscountSecondary' : faTicket,
			placeholder: traductions.discounts.placeHolder,
			idRef: SearchFieldsNames.discount,
			nameRef: `${CarSearchFormInputsNames.discount}DiscountName`,
		},
	];

	return (
		<div className={`flex flex-col gap-4 ${customClass ?? ''}`}>
			<div className='flex flex-col gap-2'>
				{fieldsCars.map(
					(field: CarSearchFormMobileFiled) =>
						!field.isHidden && (
							<div
								key={field.idRef}
								className={`flex 
								${isSecondaryTemplate ? 'flex-col gap-1' : 'border bg-white gap-2 px-4 py-3 border-rad border-[#696969]'}
								${isTertiaryTemplate ? 'rounded-none border-[#BEBEBE] min-h-[50px]' : ''}
								${!isTertiaryTemplate && !isSecondaryTemplate ? 'rounded-lg' : ''}
							`}
							>
								{isSecondaryTemplate && (
									<div className='text-sm font-medium'>
										<span className='field__labelText'>{field.label}</span>
									</div>
								)}
								{!isSecondaryTemplate && (
									<div className={`w-[24px] ${isTertiaryTemplate ? 'flex justify-center items-center' : ''}`}>
										{typeof field.icon === 'string' ? (
											<span
												className={`${field.icon} text-base
												${isTertiaryTemplate ? 'text-[#4D4D4F] flex justify-center' : ''}`}
											></span>
										) : (
											<FontAwesomeIcon
												data-testid='icon'
												icon={field.icon}
												width={24}
												height={24}
												fixedWidth={true}
												color={isTertiaryTemplate ? '#4D4D4F' : colorSecondary}
												fontSize={16}
											/>
										)}
									</div>
								)}
								<Input
									value={field.value}
									readonly={true}
									icon={isSecondaryTemplate ? (field.icon as string) : ''}
									iconClassName={'text-[#98A2B3]'}
									type={ElementTypes.text}
									placeholder={field.placeholder}
									isSecondaryTemplate={isSecondaryTemplate}
									isTertiaryTemplate={isTertiaryTemplate}
									className={`destinationField__input cursor-pointer ${
										isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'
									}  flex-grow`}
									inputClassName={`bg-transparent !p-0 font-normal md md:placeholder placeholder:font-normal placeholder:text-[#696969]
										${isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base'}
										${isTertiaryTemplate ? '!text-[14px] !text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
									`}
									autocomplete={'off'}
									style={{
										color: '#696969',
									}}
									truncate={truncate}
									onClick={() => handleClick(field.idRef, field.nameRef)}
								/>
							</div>
						),
				)}
			</div>
			{children}
		</div>
	);
};
