import { LocationOptionInterface } from '../../../../../shared/interfaces/generics/culture-inputs.interface';
import { InputEventsHandler, OptionSelect } from '../../../../../shared/interfaces/inputs.interface';
import { ListInterface, ListItemInterface } from '../../../../../shared/interfaces/list.interface';
import CultureInputs from '../../../../shared-components/culture-inputs/culture-inputs';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import Image from '../../../../ui-elements/image/image';
import Pill from '../../../../ui-elements/pill/pill';
import List from '../../../../ui-elements/list/list';
import styles from './cars-footer.module.scss';
import React from 'react';

export interface CarsFooterProps {
	linksList: ListInterface[];
	descriptionText: string;
	copyrightText: string;
	tripsText: string;
	legalList: ListInterface;
	socialList: ListInterface;
	locations: LocationOptionInterface[];
	currencies: OptionSelect[];
	defaultLocationValue?: string;
	defaultCurrencyValue?: string;
	showCurrencyInput?: boolean;
	showLocationInput?: boolean;
	showTripsButton?: boolean;
	onItemListClick?: (item: ListItemInterface) => void;
	onTripsClick?: () => void;
	onCultureChange?: (event: InputEventsHandler) => void;
	mainColor?: string;
	secondColor?: string;
	className?: string;
	descriptionClassName?: string;
	lineClassName?: string;
	legalClassName?: string;
	containerClassName?: string;
	linkTitleClassName?: string;
	linkLabelClassName?: string;
	pillClassName?: string;
	locationSelectClassName?: string;
	currencySelectClassName?: string;
	extraImageUrl?: string;
	useNativeAnchor?: boolean;
}

export function CarsFooter({
	linksList,
	descriptionText,
	copyrightText,
	tripsText,
	legalList,
	socialList,
	className,
	onItemListClick,
	onTripsClick,
	mainColor,
	secondColor,
	descriptionClassName,
	lineClassName,
	legalClassName,
	locations,
	currencies,
	defaultLocationValue,
	defaultCurrencyValue,
	showCurrencyInput,
	showLocationInput,
	showTripsButton,
	onCultureChange,
	containerClassName,
	linkTitleClassName,
	linkLabelClassName,
	pillClassName,
	locationSelectClassName,
	currencySelectClassName,
	extraImageUrl,
	useNativeAnchor,
}: CarsFooterProps): JSX.Element {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<footer className={`carsFooter w-full ${className || ''}`}>
				<div className={`w-full flex flex-col pt-10 md:pt-14 pb-14 md:pb-11 px-12 md:px-44 ${containerClassName || ''}`}>
					<div className={'carsFooter__item flex flex-col md:flex-row md:items-start md:justify-center mb-6 md:mb-14'}>
						<div className='carsFooter__menu md:flex-grow flex flex-col md:flex-row md:items-start md:justify-center mb-6 md:mb-0'>
							{linksList.map((item: ListInterface, index: number) => {
								return (
									<React.Fragment key={index}>
										<List
											items={[item]}
											callBack={onItemListClick}
											withPointer={true}
											titleClassName={`carsFooter__listTitle text-xs md:text-base font-bold text-left mb-5 ${linkTitleClassName || ''}`}
											liClassName={`carsFooter__itemList text-xs font-normal text-left mb-2.5 ${linkLabelClassName || ''}`}
											iconClassName={'carsFooter__arrowButton'}
											className={`mt-4 md:mt-0 ${index !== linksList.length - 1 ? 'md:mr-14' : ''}`}
											isResponsiveDropdown={true}
											useNativeAnchor={useNativeAnchor || false}
										/>
										{windowWidth < responsiveSize && <div className={`w-full border-b-2 ${lineClassName || ''}`} />}
									</React.Fragment>
								);
							})}
						</div>
						<div className='md:ml-9'>
							<div className='flex flex-col md:flex-row justify-start items-start md:items-center'>
								<Pill
									text={tripsText}
									className={`carsFooter__tripsButton text-base font-bold border-2 mr-5 cursor-pointer mb-4 md:mb-0 ${
										showTripsButton ? '' : 'hidden'
									} ${pillClassName || ''}`}
									style={{
										borderColor: mainColor,
										color: mainColor,
									}}
									onClick={onTripsClick}
								/>
								<div className='carsFooter__social flex flex-row justify-start items-center'>
									{socialList.items.map((item: ListItemInterface, index: number) => (
										<IcomoonIcon
											key={index}
											icon={String(item.name)}
											className='mr-5 cursor-pointer rounded-full'
											fontSize={25}
											color={secondColor}
											onClick={() => {
												if (onItemListClick) {
													onItemListClick(item);
												}
											}}
										/>
									))}
								</div>
							</div>

							{!!extraImageUrl && <Image className={''} url={extraImageUrl} customClass={'m-3'} />}

							<CultureInputs
								locations={locations}
								currencies={currencies}
								defaultLocationValue={defaultLocationValue}
								defaultCurrencyValue={defaultCurrencyValue}
								locationImageSize={40}
								locationSelectClassName={`text-xs font-medium bg-transparent ${locationSelectClassName || ''}`}
								currencySelectClassName={`ml-2 text-xs font-medium bg-transparent ${currencySelectClassName || ''}`}
								onInputsChange={onCultureChange}
								className='mt-4 md:mt-8'
								noShowCurrencySelect={!showCurrencyInput}
								noShowLocationSelect={!showLocationInput}
							/>
						</div>
					</div>

					<div className={`carsFooter__item carsFooter__line w-full border-b-2 ${lineClassName || ''}`} />

					<div
						className={`carsFooter__item carsFooter__bottom text-center font-normal text-xs py-8 md:py-14 ${descriptionClassName || ''}`}
						dangerouslySetInnerHTML={{ __html: descriptionText }}
					/>
					<div className={`w-full border-b-2 ${lineClassName || ''}`} />
					<div className={`carsFooter__legal font-normal text-xs text-left mt-6 md:mt-8 ${legalClassName || ''}`}>
						<span className='carsFooter__copytext'>{copyrightText}</span>
						{legalList.items.map((item: ListItemInterface, index: number) => {
							return (
								<span
									key={index}
									onClick={() => {
										if (onItemListClick) {
											onItemListClick(item);
										}
									}}
									className='ml-1.5 cursor-pointer'
								>
									· {item.name}
								</span>
							);
						})}
					</div>
				</div>
			</footer>
		</>
	);
}

CarsFooter.defaultProps = {
	className: '',
	descriptionClassName: '',
	lineClassName: '',
	legalClassName: '',
	linkTitleClassName: '',
	pillClassName: '',
};

export default CarsFooter;
