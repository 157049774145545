import { CMSGeneralDataInterface } from '../../../../../../shared/interfaces/generics/cms.interface';
import GenericIntroductionSkeleton from './skeleton/skeleton-generic-introduction';

/* eslint-disable-next-line */
export interface GenericIntroductionProps extends CMSGeneralDataInterface {
	loading?: boolean;
	customClass?: string;
	containerClassName?: string;
	titleClassName?: string;
	subtitleClassName?: string;
	descriptionClassName?: string;
	lineClassName?: string;
}

export function GenericIntroduction({
	loading,
	customClass,
	subtitle,
	title,
	description,
	containerClassName,
	titleClassName,
	subtitleClassName,
	descriptionClassName,
	lineClassName,
}: GenericIntroductionProps): JSX.Element {
	return loading ? (
		<GenericIntroductionSkeleton className={customClass} containerClassName={containerClassName} />
	) : (
		<div className={`genericIntroduction flex justify-center ${customClass || ''}`}>
			<div className={`genericIntroduction__content flex flex-col items-center mb-7 md:mb-14 ${containerClassName || ''}`}>
				<div className={`genericIntroduction__line h-px w-6 md:w-12 border-b-4 rounded-xl mb-4 ${lineClassName || ''}`} />
				<p className={`genericIntroduction__subtitle text-xs mb-2.5 md:mb-4 ${subtitleClassName || ''}`}>{subtitle}</p>
				<h2 className={`genericIntroduction__title text-3xl md:text-5xl mb-2.5 md:mb-6 text-center ${titleClassName || ''}`}>{title}</h2>
				<p className={`genericIntroduction__description text-xs md:text-base text-center ${descriptionClassName || ''}`}>{description}</p>
			</div>
		</div>
	);
}

export default GenericIntroduction;
