import { ListItemInterface } from '../../../../shared/interfaces';
import { Accordion } from '../../../../lib/ui-elements';
import { Image } from '../../../ui-elements/image/image';
import styles from './list-introduction.module.scss';
export interface ListIntroductionProps {
	options: ListItemInterface[];
	altImage?: string;
	imageUlr?: string;
	title: string;
}

export const ListIntroduction = ({ altImage, imageUlr, title, options }: ListIntroductionProps): JSX.Element => {
	return (
		<div className='listIntroduction bg-gray-100'>
			<style>{styles}</style>
			<div className='listIntroduction__container flex flex-col md:flex-row md:max-h-[431px]'>
				<div className='listIntroduction__item order-1 md:order md:w-6/12 flex items-center px-3 md:px-6 lg:px-12'>
					<div className='listIntroduction__info pt-16 w-full'>
						<h4 className='listIntroduction__title text-[26px] lg:text-[36px] mb-6 leading-none'>{title}</h4>
						<div className='listIntroduction__content md:h-[270px] overflow-y-auto'>
							{options?.map((option, index) => (
								<div key={index}>
									<Accordion
										title={option.name as string}
										content={option.value as string}
										openIcon={'chevronBottomIco'}
										closeIcon={'chevronUpIco'}
										className='shadow-none'
										titleCustomClass='listIntroduction__titleAccordeon'
										listCustomClass='listIntroduction__listAccordeon'
									/>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className='listIntroduction__item listIntroduction__image order md:order-1 md:w-6/12 overflow-hidden'>
					<Image url={imageUlr || ''} width={'100%'} height={'431px'} alt={altImage || ''} styles={{}} />
				</div>
			</div>
		</div>
	);
};
