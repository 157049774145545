import { DisabledPaymentTypeCarsSearchFormInterface, ListItemInterface, OptionSelect } from '@smartlinks/react-design-system';
import { CarRecommendationPayload, CarsRecommendationsParams } from '../interfaces/car-recommendation-payload.interface';
import { OfficesCardinal, SearchLocationsProps } from '../interfaces/locations.interface';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';
import { CorporateDiscounts } from '../interfaces/discounts.interface';
import { WidgetNamesEnum } from '../enums/widget-names.enum';
import { CarCoverages } from '../enums/car-coverages';
import { WidgetSearch } from '../enums/widget-search';

export class CarUtil {
	public static mapCarSearch(search: CarsRecommendationsParams, widgetName: WidgetNamesEnum): CarRecommendationPayload {
		const passengersByDefault: number = 1;
		const recommendationsByDefault: number = 3;
		return {
			pickupLocationCode: search.pickupLocationCode,
			dropOffLocationCode: search.dropOffLocationCode,
			pickUpDate: search.pickUpDate,
			pickUpTime: search.pickUpTime,
			dropOffDate: search.dropOffDate,
			dropOffTime: search.dropOffTime,
			paymentType: search.paymentType || '0',
			corporateDiscount: search.corporateDiscount || '',
			rewardsPoints: search.rewardsPoints || '',
			promotionalDiscount: search.promotionalDiscount || '',
			passengersQuantity: search.passengersQuantity || passengersByDefault,
			quantityOfRecommendations: search.quantityOfRecommendations || recommendationsByDefault,
			widgetName,
			coverage: search?.coverage || CarCoverages.noCoverage,
		};
	}

	public static mapCorporateDiscounts(corporateDiscounts: CorporateDiscounts[]): OptionSelect[] {
		let mapDiscounts: OptionSelect[] = [];
		corporateDiscounts.forEach((discount: CorporateDiscounts) => {
			const discountContent: OptionSelect = {
				label: discount.label,
				value: `${discount.code} - ${discount.label}`,
				dependencies: [],
			};

			if (discount.promotionalCode != null) {
				discountContent.dependencies = [
					{
						label: discount.promotionalCode.label,
						value: discount.promotionalCode.code,
					},
				];
			}

			mapDiscounts = [...mapDiscounts, ...[discountContent]];
		});

		return mapDiscounts;
	}

	public static mapPromocodesWithoutCorporateDiscount(promocodes: CorporateDiscounts[]): OptionSelect[] {
		let mapPromocodes: OptionSelect[] = [];
		const discountContent: OptionSelect = {
			label: '',
			value: '',
			dependencies: [],
		};

		promocodes.forEach((promocodes: CorporateDiscounts) => {
			if (promocodes.promotionalCode && discountContent.dependencies) {
				discountContent.dependencies.push({
					label: promocodes.promotionalCode.label,
					value: promocodes.promotionalCode.code,
				});
			}
		});

		mapPromocodes = [discountContent];

		return mapPromocodes;
	}

	public static mapCorporateDiscountDefault(corporateDiscountsDefault: CorporateDiscounts[]): OptionSelect {
		const firtsItem = 0;
		const discountContent: OptionSelect = {
			label: '',
			value: '',
			dependencies: [],
		};

		if (corporateDiscountsDefault.length) {
			const discount: CorporateDiscounts = corporateDiscountsDefault[firtsItem];
			discountContent.label = discount.label;

			discountContent.value = `${discount.code} - ${discount.label}`;

			if (discount.promotionalCode) {
				discountContent.dependencies = [
					{
						label: discount.promotionalCode.label,
						value: discount.promotionalCode.code,
					},
				];
			}
		}

		return discountContent;
	}

	public static mapPromocodesWithoutCorporateDiscountDefault(promocodesDefault: CorporateDiscounts[]): OptionSelect {
		const firtsItem = 0;
		const discountContent: OptionSelect = {
			label: '',
			value: '',
			dependencies: [],
		};

		if (promocodesDefault.length && promocodesDefault[firtsItem]?.promotionalCode?.label && promocodesDefault[firtsItem]?.promotionalCode?.code) {
			const valuesPromocode = {
				label: promocodesDefault[firtsItem].promotionalCode.label || '',
				value: promocodesDefault[firtsItem].promotionalCode.code,
			};

			discountContent.dependencies?.push(valuesPromocode);
		}

		return discountContent;
	}

	public static mapOfficesList(
		offices: OfficesCardinal[],
		localitiesText: string,
		mainColor?: string,
		customClass?: string,
		truncateClass?: string,
	): ListItemInterface[] {
		let destinations: ListItemInterface[] = [];

		offices.forEach(office => {
			const mapOffice: ListItemInterface[] = CarUtil.mapOffices(office, localitiesText, mainColor, customClass, truncateClass);
			destinations = [...destinations, ...mapOffice];
		});

		return destinations;
	}

	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find(
				(restriction: CalendarRestrictions) =>
					restriction.product === CalendarRestrictionTypes.car || restriction.product === CalendarRestrictionTypes.cars,
			)?.value || 0
		);
	}

	public static mapAirports(listItems: SearchLocationsProps[], customClass?: string): ListItemInterface[] {
		let destinations: ListItemInterface[] = [];
		listItems.forEach((location: SearchLocationsProps) => {
			const mapLocation = {
				name: <span className={customClass}>{location.name}</span>,
				originalName: location.name,
				id: location.code,
				value: location.code,
				link: '',
				original: null,
			};

			destinations = [...destinations, ...[mapLocation]];
		});

		return destinations;
	}

	public static mapOffices(
		office: OfficesCardinal,
		localitiesText: string,
		mainColor?: string,
		customClass?: string,
		truncateClass?: string,
	): ListItemInterface[] {
		const mapLocation = {
			name: (
				<p className={customClass}>
					{office.locationName}
					<span className={`officeCount pl-2 ${truncateClass ?? ''}`} style={{ color: mainColor }}>
						({office?.locations?.length} {localitiesText})
					</span>
				</p>
			),
			originalName: office.locationName,
			id: office.id || '',
			link: '',
			original: office?.locations,
		};

		return [mapLocation];
	}

	public static mapDisabledPaymentType(
		agency?: Agency | null,
		defaultDisabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface,
	): DisabledPaymentTypeCarsSearchFormInterface | undefined {
		let tempDisabledPaymentType: DisabledPaymentTypeCarsSearchFormInterface | undefined;
		if (agency != null && typeof agency?.carsCriteriaConfiguration?.showOnRentalPayment === 'boolean') {
			tempDisabledPaymentType = {
				onrental: !agency?.carsCriteriaConfiguration?.showOnRentalPayment,
			};
		}

		if (defaultDisabledPaymentType != null) {
			tempDisabledPaymentType = {
				onrental:
					typeof defaultDisabledPaymentType?.onrental === 'boolean' ? defaultDisabledPaymentType?.onrental : tempDisabledPaymentType?.onrental,
				online: defaultDisabledPaymentType?.online,
			};
		}

		return tempDisabledPaymentType;
	}

	public static hideDropOff(agency?: Agency | null): boolean {
		if (!agency?.widgets || !agency?.widgets.search) {
			return false;
		}

		return agency?.widgets.search?.includes(WidgetSearch.carHideDropOff) || false;
	}
}
