import { Accordion } from '../../../../../ui-elements/accordion/accordion';
import styles from './booking-observation.module.scss';

export interface BookingObservationsProps {
	title: string;
	ipAddresTitle: string;
	ipAddres: string;
	tokenUserTitle?: string;
	tokenUser?: string;
	searchIdTitle: string;
	searchId: string;
	className?: string;
}

export function BookingObservations(props: BookingObservationsProps): JSX.Element {
	const observationbElement: JSX.Element = (
		<div className={'w-full mx-4 py-2 text-xs bookingObservations'}>
			<div className={'flex bookingObservations__textSecundary'}>
				<p className={'text-xs'}>
					<b>{props.ipAddresTitle}: </b>
					{props.ipAddres}
				</p>
			</div>
			{props.tokenUser && (
				<div className={'flex bookingObservations__textSecundary'}>
					<p className={'text-xs'}>
						<b>{props.tokenUserTitle}: </b>
						{props.tokenUser}
					</p>
				</div>
			)}
			<div className={'flex bookingObservations__textSecundary'}>
				<p className={'text-xs'}>
					<b>{props.searchIdTitle}: </b> {props.searchId}
				</p>
			</div>
		</div>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<Accordion
				title={props.title.toLocaleUpperCase()}
				content={observationbElement}
				titleCustomClass={'text-lg leading-6 font-medium text-color-primary'}
				className={props.className}
			/>
		</>
	);
}

export default BookingObservations;
