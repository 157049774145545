import { FlightRoutePaymentsInterface } from '../../../../../shared/interfaces/payments/flight-route.interface';
import HorizontalDecription from '../../../../ui-elements/horizontal-description/horizontal-description';
import { numberToLocaleWithcurrency } from '../../../../../shared/services/utils.service';
import { formatDate } from '../../../../../shared/services/dates-managment.service';
import {
	TitlesSummaryPaymentsInterface,
	LabelsSummaryPaymentsInterface,
	SummaryPaymentFareInterface,
} from '../../../../../shared/interfaces/payments/summary.interface';
import { DateFormat } from '../../../../../shared/enums/date.enum';
import Pill from '../../../../ui-elements/pill/pill';
import styles from './summary-payment.module.scss';
import { useState } from 'react';
export interface SummaryPaymentProps {
	flightPaths: FlightRoutePaymentsInterface[];
	reserveNumber: string;
	titles: TitlesSummaryPaymentsInterface;
	labels: LabelsSummaryPaymentsInterface;
	values: SummaryPaymentFareInterface;
	className?: string;
}

export const SummaryPayment = (props: SummaryPaymentProps): JSX.Element => {
	const checkModule: number = 2;
	const defaultValue = 0;
	const [detailsState, setDetailsState] = useState<boolean>(false);
	const getGrandTotalAmount = (values: SummaryPaymentFareInterface): string => {
		const grandTotalAmount = values.base + values.ivaTax + values.otherTaxes;
		return numberToLocaleWithcurrency(grandTotalAmount);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`summaryPayment container bg-white mx-auto rounded-xl shadow-xl max-w-xs ${props.className || ''}`}>
				<section className={'container mx-autom-1 h-14 summaryPayment__contentSummary'}>
					<h1 className={'tracking-widest font-family-black text-center h-14 text-white p-4 headerSesion'}>{props.titles.main}</h1>
				</section>
				<div className={'p-6'}>
					<span className={'font-family-black text-base mb-1 subTitle'}>{`${props.titles.reserve} ${props.reserveNumber}`}</span>
					{props.flightPaths.map((item: FlightRoutePaymentsInterface, index: number) => (
						<div className={'py-2 grid grid-cols-1 contentRoute'} key={index}>
							{index % checkModule ? (
								<h2 className={'font-family-bold text-base my-1 subTitle'}>{props.titles.arrivalFlight}</h2>
							) : (
								<h2 className={'font-family-bold text-base my-1 subTitle'}>{props.titles.departureFlight}</h2>
							)}
							<div className={'grid grid-cols-1 text-xs'}>
								<span className={'font-latoItalic italic summaryPayment__textSmall'}>
									{index % checkModule
										? formatDate(item.arrival.dateTime, DateFormat.largeDate)
										: formatDate(item.departure.dateTime, DateFormat.largeDate)}
								</span>
								<span className={'text-xs flightDestinations'}>
									{' '}
									{`${item.departure.cityName} (${item.departure.code}) - ${item.arrival.cityName} (${item.arrival.code})`}
								</span>
								<span className={'font-family-regular fligthPlaque'}>{item.flightNumbers}</span>
							</div>
						</div>
					))}
				</div>
				<div className={'p-6'}>
					<div className={'w-full flex'}>
						<div className={'font-family-black w-3/12 mt-1 subTitle'}>{props.titles.total}</div>
						<div className={'font-family-black w-full text-2xl text-right subTitle'}>
							{props.values.currency}{' '}
							{props.values.total === defaultValue ? getGrandTotalAmount(props.values) : numberToLocaleWithcurrency(props.values.total)}
						</div>
					</div>
					<div className={'font-family-bold w-full text-xs text-right mt-2.5 links'}>
						<span className='cursor-pointer' onClick={(): void => setDetailsState((prevState: boolean): boolean => !prevState)}>
							{detailsState ? '-' : '+'} {props.titles.details}
						</span>
					</div>
				</div>
				<div className={`px-6 ${!detailsState ? 'hidden' : ''}`}>
					<div className={'w-full flex flex-col py-6 detailsContent'}>
						<HorizontalDecription
							label={props.labels.basePrice}
							value={`${props.values.currency} ${props.values.base !== defaultValue ? numberToLocaleWithcurrency(props.values.base) : 0}`}
							className={'text-sm font-family-bold mb-2.5 detailText'}
							noBoldLabel={true}
						/>
						<HorizontalDecription
							label={props.labels.tax}
							value={`${props.values.currency} ${props.values.ivaTax !== defaultValue ? numberToLocaleWithcurrency(props.values.ivaTax) : 0}`}
							className={'text-xs font-family-regular flightDestinations'}
							noBoldLabel={true}
						/>
						<HorizontalDecription
							label={props.labels.others}
							value={`${props.values.currency} ${props.values.otherTaxes !== defaultValue ? numberToLocaleWithcurrency(props.values.otherTaxes) : 0}`}
							className={'text-xs font-family-regular flightDestinations'}
							noBoldLabel={true}
						/>
						<HorizontalDecription
							label={props.labels.feeWithOutIva}
							value={`${props.values.currency} ${
								props.values.feeWithOutIva !== defaultValue ? numberToLocaleWithcurrency(props.values.feeWithOutIva) : 0
							}`}
							className={'text-xs font-family-regular flightDestinations'}
							noBoldLabel={true}
						/>
						<HorizontalDecription
							label={props.labels.ivaForFee}
							value={`${props.values.currency} ${props.values.ivaForFee !== defaultValue ? numberToLocaleWithcurrency(props.values.ivaForFee) : 0}`}
							className={'text-xs font-family-regular flightDestinations'}
							noBoldLabel={true}
						/>

						<HorizontalDecription
							label={props.labels.subtotal}
							value={`${props.values.currency} ${props.values.subTotal !== defaultValue ? numberToLocaleWithcurrency(props.values.subTotal) : 0}`}
							className={'text-sm font-family-bold mt-2.5 detailText'}
							noBoldLabel={true}
						/>
						<HorizontalDecription
							labelContent={<Pill text={props.labels.discount} color='#213261' className='text-xs text-white' />}
							value={`${props.values.currency} ${props.values.discount !== defaultValue ? numberToLocaleWithcurrency(props.values.discount) : 0}`}
							className={'text-sm font-family-bold mt-2.5 detailText'}
							noBoldLabel={true}
						/>
						<HorizontalDecription
							labelContent={<Pill text={props.labels.fee} color='#108FCF' className='text-xs text-white' />}
							value={`${props.values.currency} ${props.values.fee ? numberToLocaleWithcurrency(props.values.fee) : 0}`}
							className={'text-sm font-family-bold mt-0.5 detailText'}
							noBoldLabel={true}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

SummaryPayment.defaultProps = {
	className: '',
};

export default SummaryPayment;
