import { SearchLocationsProps } from '../../../../../shared/interfaces/maps/static-maps.interface';
import { WidgetStyleInterface } from '../../../../../shared/interfaces/widget-styles.interface';
import { CarDestinationsFieldTypes } from '../../../../../shared/enums/search-fields.enum';
import { CarSearchFormLayouts } from '../../../../../shared/enums/widgets-layouts.enum';
import SpreadedCarSearchForm from './spreaded-car-search-form/spreaded-car-search-form';
import { OptionSelect } from '../../../../../shared/interfaces/inputs.interface';
import { ListInterface } from '../../../../../shared/interfaces/list.interface';
import BarCarSearchForm from './bar-car-search-form/bar-car-search-form';
import {
	CarSearchFormSubmit,
	CarSearchTraductions,
	DisabledPaymentTypeCarsSearchFormInterface,
} from '../../../shared/interfaces/car-search-form.interface';
import { DateLocales } from '../../../../../shared/enums';

export interface CarSearchFormRefHandle {
	setDestinations: (input: string | CarDestinationsFieldTypes, office: SearchLocationsProps) => void;
}

/* eslint-disable-next-line */
export interface CarSearchFormProps {
	mapBoxToken: string;
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: CarSearchTraductions;
	hideDropOff: boolean;
	carsInSitePayment: boolean;
	destinations?: ListInterface[];
	corporateDiscounts?: OptionSelect[];
	corporateDiscountDefault?: OptionSelect;
	promoCodesWithoutCorporateDiscountCode?: OptionSelect[];
	promoCodeWithoutCorporateDiscountCodeDefault?: OptionSelect;
	isNotHome?: boolean;
	carHistory?: CarSearchFormSubmit | null;
	widgetStyles?: WidgetStyleInterface;
	calendarRestriction?: number;
	layout?: CarSearchFormLayouts;
	isLocationsLoading?: boolean;
	searchFormRef?: React.Ref<CarSearchFormRefHandle>;
	disabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface;
	emitInput?: (data?: string) => void;
	emitSubmitSearch?: (data?: CarSearchFormSubmit) => void;
	emitOptionActive: (data: boolean) => void;
	openLocalities?: (value: string) => void;
	emitShowMobileFields?: (show: boolean) => void;
	language?: DateLocales;
}

export function CarSearchForm(props: CarSearchFormProps): JSX.Element {
	return (
		<div className='w-full'>
			{props?.layout === CarSearchFormLayouts.bar && <BarCarSearchForm ref={props.searchFormRef} {...props} />}
			{props?.layout === CarSearchFormLayouts.spreaded && <SpreadedCarSearchForm ref={props.searchFormRef} {...props} />}
		</div>
	);
}

export default CarSearchForm;
