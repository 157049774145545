import { ListItemInterface } from '../../../../shared/interfaces';

export interface BannerMulticolumnProps {
	title?: string;
	options: ListItemInterface[];
}

export const BannerMulticolumn = ({ title, options }: BannerMulticolumnProps): JSX.Element => {
	return (
		<div className='BannerMulticolumn'>
			<div className='BannerMulticolumn__container'>
				<p className='BannerMulticolumn__title text-center text-[26px] lg:text-[36px] mb-6 lg:mb-[67px]'>{title}</p>

				<div className='BannerMulticolumn__list flex justify-center items-center flex-col md:flex-row flex-wrap'>
					{options?.map((option, index) => (
						<a
							href={option.link}
							key={index}
							target={'_blank'}
							className='BannerMulticolumn__anchor cursor-pointer md:mr-[40px] last:mr-0 w-full mb-6 md:w-[40%] lg:w-3/12'
							rel='noreferrer'
						>
							<div
								className='BannerMulticolumn__list-item pointers-none p-[10px] md:p-[20px] bg-no-repeat bg-cover flex flex-col justify-end items-start min-h-[380px] bg-gray-200'
								style={{ backgroundImage: `URL("${option?.original as string}")` }}
							>
								<p className='BannerMulticolumn__title text-white text-[21px] mt-[10px] px-[10px] max-w-[550px]'>{option.name || ''}</p>
								<p className='BannerMulticolumn__description text-white mb-[10px] px-[10px] max-w-[550px]'>{option.value || ''}</p>
							</div>
						</a>
					))}
				</div>
			</div>
		</div>
	);
};
