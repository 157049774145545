import { ListInterface, useDebounce, ListItemInterface } from '@smartlinks/react-design-system';
import { FlightsSearchAirportsInterface } from '../interfaces/flights-airports.interface';
import { FlightsService } from '../services/flights.service';
import { FlightsUtil } from '../utils/flights-search.util';
import { Agency } from '../interfaces/agency.interface';
import { useState, useCallback } from 'react';
import { TFunction } from 'react-i18next';

export interface UseSearchByWordInterface {
	flightsService: FlightsService;
	t: TFunction<'translation', undefined>;
	agency: Agency | null | undefined;
	isDeparture?: boolean;
}

export interface UseSearchByWordReturnInterface {
	isSearchLoading: boolean;
	airports: ListInterface[];
	callSearchByWord: (words: string) => void;
}

const useAirportsByWord = ({ flightsService, t, agency, isDeparture = true }: UseSearchByWordInterface): UseSearchByWordReturnInterface => {
	const searchDebounceHandler = useDebounce();
	const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
	const [airports, setAirports] = useState<ListInterface[]>([
		{
			title: (
				<span className={`${agency?.widgetStyles?.isSecondaryTemplate ? '' : 'frontPlaneIco pl-5'} md:block hidden destinationTitle`}>
					<span className={`${agency?.widgetStyles?.isSecondaryTemplate ? 'px-[14px]' : 'px-3 pl-title'}`}>{t('airportList')}</span>
				</span>
			),
			items: [],
		},
	]);

	const callSearchByWord = useCallback(
		(words: string): void => {
			setIsSearchLoading(true);

			searchDebounceHandler((value: string): void => {
				const airportsList: ListInterface[] = [
					{
						title: (
							<span className={`${agency?.widgetStyles?.isSecondaryTemplate ? '' : 'frontPlaneIco pl-5'} md:block hidden destinationTitle`}>
								<span className={`${agency?.widgetStyles?.isSecondaryTemplate ? 'px-[14px]' : 'px-3 pl-title'}`}>{t('airportList')}</span>
							</span>
						),
						items: [],
					},
				];

				const wordLimit: number = 3;
				if (value.length >= wordLimit) {
					((tempValue: string) => {
						void callApiAirportsByWords(tempValue);
					})(value);

					return;
				}

				setAirports([...airportsList]);

				setIsSearchLoading(false);
			}, 300)(words);
		},
		[flightsService, searchDebounceHandler],
	);

	const callApiAirportsByWords = async (tempValue: string): Promise<void> => {
		const tempAirports: FlightsSearchAirportsInterface[] | null = await flightsService.getAirportsByWords(tempValue);
		const truncatedInputs: boolean = agency?.widgetStyles?.isEnableTruncatedInputs || false;
		const isSecodnatyTemplate: boolean = agency?.widgetStyles?.isSecondaryTemplate || false;
		const mapAirportsList: ListItemInterface[] = FlightsUtil.mapAirportsList(tempAirports || [], truncatedInputs, isSecodnatyTemplate, isDeparture);
		const airportsList = [
			{
				title: (
					<span
						className={`${
							agency?.widgetStyles?.isSecondaryTemplate ? 'text-md font-medium leading-6' : 'frontPlaneIco pl-5'
						} md:block hidden destinationTitle`}
					>
						<span className={`${agency?.widgetStyles?.isSecondaryTemplate ? 'px-[14px]' : 'px-3 pl-title'}`}>{t('airportList')}</span>
					</span>
				),
				items: mapAirportsList,
			},
		];

		setAirports([...airportsList]);

		setIsSearchLoading(false);
	};

	return {
		isSearchLoading,
		airports,
		callSearchByWord,
	};
};

export default useAirportsByWord;
