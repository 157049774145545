import { DisneyDetailContextType, useDisneyDetailContext } from '../../disney/components/special-event-detail/special-event-detail';
import { FormsInputsType, DatePickerDirections } from '../../../shared/enums/inputs.enum';
import { DatePickerColors } from '../../../shared/interfaces/date-picker.interface';
import { InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { getDateLocal } from '../../../shared/utils/get-date-locale';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { DateLocales } from '../../../shared/enums/date.enum';
import Button from '../../ui-elements/button/button';
import 'react-date-range/dist/theme/default.css';
import styles from './date-picker.module.scss';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';


export interface DatePickerProps {
	name?: string;
	defaultData?: Date;
	locale?: DateLocales;
	minDate?: Date;
	maxDate?: Date;
	buttonText?: string;
	weekdayDisplayFormat?: string;
	useButton?: boolean;
	colors?: DatePickerColors;
	onChange?: (e: InputEventsHandler) => void;
	handleCancelClick?: () => void;
	className?: string;
	dateClassName?: string;
	cancelText?: string;
	direction?: DatePickerDirections;
	months?: number;
	dayContentRenderer?: (day: Date) => JSX.Element;
	disabledDates?: Date[];
	availabilityDates?: Date[];
	isSecondaryTemplate?: boolean;
}

export const DatePicker = ({
	name,
	defaultData,
	locale,
	minDate,
	maxDate,
	buttonText,
	useButton,
	cancelText,
	colors,
	onChange,
	handleCancelClick,
	className,
	dateClassName,
	weekdayDisplayFormat,
	direction,
	months,
	dayContentRenderer,
	disabledDates,
	isSecondaryTemplate,
}: DatePickerProps): JSX.Element => {
	const [date, setDate] = useState<Date>(defaultData || new Date());
	const { disneyDetail }: DisneyDetailContextType = useDisneyDetailContext();
	const dateLocale: Object = useMemo(
		() => getDateLocal(locale),
		[locale],
	);

	const dateHandler = useCallback(
		(newDate: Date): void => {
			setDate(newDate);

			if (onChange && !useButton) {
				const dataEvent: InputEventsHandler = {
					type: FormsInputsType.datePicker,
					name,
					value: newDate,
				};

				onChange(dataEvent);
			}
		},
		[name, useButton, disneyDetail],
	);

	const buttonHandler = useCallback((): void => {
		if (onChange) {
			const dataEvent: InputEventsHandler = {
				type: FormsInputsType.datePicker,
				name,
				value: date,
			};

			onChange(dataEvent);
		}
	}, [name, date, disneyDetail]);

	useEffect(() => {
		const root = document.documentElement;
		if (colors?.main) {
			root?.style.setProperty('--main-date-picker-color', colors.main);
		}

		if (colors?.second) {
			root?.style.setProperty('--second-date-picker-color', colors.second);
		}

		if (colors?.third) {
			root?.style.setProperty('--third-date-picker-color', colors.third);
		}
	}, [colors?.main, colors?.second, colors?.third]);

	useEffect(() => {
		if (defaultData) {
			setDate(defaultData || new Date());
		}
	}, [defaultData]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`datePicker inline-flex flex-col ${className || ''}`}>
				<Calendar
					date={date}
					locale={dateLocale}
					minDate={minDate}
					maxDate={maxDate}
					className={`datePicker__dateWrapper ${dateClassName || ''}`}
					monthDisplayFormat='MMMM yyyy'
					weekdayDisplayFormat={weekdayDisplayFormat ?? 'iiiii'}
					months={months || 2}
					preventSnapRefocus={true}
					direction={direction}
					scroll={{ enabled: false }}
					showSelectionPreview={true}
					showMonthAndYearPickers={false}
					showMonthArrow={true}
					showDateDisplay={false}
					onChange={dateHandler}
					dayContentRenderer={dayContentRenderer}
					disabledDates={disabledDates || []}
				/>
				{useButton && (
					<div className='datePicker__buttonContainer flex flex-row justify-end gap-3'>
						{isSecondaryTemplate && (
							<Button
								text={cancelText}
								customClass={'dateRangePicker__cancelButton text-xs border border-gray-300 bg-white rounded-full'}
								onClick={handleCancelClick}
							/>
						)}
						<Button
							text={buttonText}
							customClass={`datePicker__button text-xs ${isSecondaryTemplate ? 'rounded-full' : 'rounded-lg'}`}
							styles={{
								backgroundColor: colors?.main,
							}}
							onClick={buttonHandler}
						/>
					</div>
				)}
			</div>
		</>
	);
};

DatePicker.defaultProps = {
	className: '',
	dateRangeClassName: '',
	direction: DatePickerDirections.horizontal,
};

export default DatePicker;
