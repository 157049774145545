import { MouseEventHandler } from 'react';
import { Portal } from '../portal/Portal';

export interface BackdropProps {
	show: boolean;
	onClick?: MouseEventHandler;
	zIndex?: number;
	backgroundColor?: string;
	opacity?: number;
	className?: string;
	portalBackdrop?: boolean;
	styles?: string;
}

export const Backdrop = ({ show, onClick, zIndex, backgroundColor, opacity, portalBackdrop, className, styles }: BackdropProps): JSX.Element => {
	const stylesString = `
  .inline-backdrop {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position:fixed;
  }
  ${styles || ''}
  `;

	const element: JSX.Element = (
		<>
			<style dangerouslySetInnerHTML={{ __html: stylesString }}></style>
			<div
				className={`inline-backdrop ${className || ''}`}
				onClick={onClick}
				style={{
					zIndex,
					backgroundColor,
					opacity,
				}}
			/>
		</>
	);

	return show ? (
		<>
			{element}
			{portalBackdrop && <Portal>{element}</Portal>}
		</>
	) : (
		<></>
	);
};

Backdrop.defaultProps = {
	className: '',
};

export default Backdrop;
