import { CoveragesInterfaceV2, GroupedCoveragesInterfaceV2, RentalCarInterfaceV2, CarInterfaceV2 } from '../interfaces';
import { CarGroupedCoveragesCodesV2, CarsRatesModelsV2, SelectedRateV2 } from '../enums/filter-cars-cards-v2.enum';
import { numberToLocaleWithcurrency } from '../../../../shared/services';
import { ModelTypeEnum } from '../../../../shared/enums/model-type.enum';
import { TableData, RowData } from '../../../../shared/interfaces';
import { coverageTypeCamel } from './car-coverage-types.util';
import { Button } from '../../../ui-elements';

export class CarDetailUtil {
	private readonly isActiveMultiLanguage: boolean;
	constructor(isActiveMultiLanguage: boolean = false) {
		this.isActiveMultiLanguage = isActiveMultiLanguage;
	}

	public mapCoveragesForTable(
		rateByRentalCar: RentalCarInterfaceV2,
		businessModel: ModelTypeEnum | string,
		t: (item: string) => string,
		rateModel?: CarsRatesModelsV2,
		colorPrimary?: string,
		isAvailabilityInLocalCurrency?: boolean,
		callBack?: (outPutData: GroupedCoveragesInterfaceV2 | null) => void,
		showChangedPrice?: boolean,
		currencySymbol?: string,
		culture?: string,
	): TableData {
		const headTable: RowData[] = [{ value: '', label: '' }];
		const bodyTable: RowData[][] = [];
		const rates: RowData[] = [];
		const groupCoverages: CoveragesInterfaceV2[] = [];
		rateByRentalCar?.rateByCoverage?.forEach((rate: GroupedCoveragesInterfaceV2) => {
			headTable.push({
				value: (
					<div className={`carDetailCoverage__td ${rate?.selected ? 'carDetailCoverage__isSelected' : ''}`}>
						{this.isActiveMultiLanguage ? t(coverageTypeCamel(rate.rateCodeType)) : rate.rateTypeLabel}
					</div>
				),
				label: this.isActiveMultiLanguage ? t(coverageTypeCamel(rate.rateCodeType)) : rate.rateTypeLabel,
			});

			rate.coverages.forEach(coverage => {
				if (coverage.included) {
					coverage.uniqueValue = `${coverage.tag || ''}${coverage.text}`;

					groupCoverages.push(coverage);
				}
			});

			const isRecommended: JSX.Element = rate?.isRecommended ? (
				<span
					className='carDetailCoverage__isRecommended absolute p-[2px] top-[-11px] w-[95px] left-0 right-0 mx-auto rounded-full text-xs'
					style={{ background: colorPrimary }}
				>
					{t('recommended')}
				</span>
			) : (
				<></>
			);

			const rateModelPoints: CarsRatesModelsV2 = isAvailabilityInLocalCurrency ? CarsRatesModelsV2.equivalentRate : CarsRatesModelsV2.rate;
			rate.selectedRate = (businessModel === ModelTypeEnum.CASH ? rateModel : rateModelPoints) as unknown as SelectedRateV2 | undefined;

			switch (businessModel) {
				case ModelTypeEnum.CASH:
					if (rateModel === CarsRatesModelsV2.equivalentRate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									<span className='rate text-center'>
										{numberToLocaleWithcurrency(rate.equivalentRate.rateFrom || 0, rate.equivalentRate.currency || '')}{' '}
										{rate.equivalentRate.currency || ''}
									</span>
								</div>
							),
							label: '',
							outPutData: rate,
						});
					} else if (rateModel === CarsRatesModelsV2.rate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									<span className='rate text-center'>
										{numberToLocaleWithcurrency(rate.rate.rateFrom || 0, rate.rate.currency || '')} {rate.rate.currency || ''}
									</span>
								</div>
							),
							label: '',
							outPutData: rate,
						});
					}

					break;
				case ModelTypeEnum.POINTS:
					rates.push({
						value: (
							<div
								className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td ${
									rate?.selected ? 'carDetailCoverage__isSelected' : ''
								}`}
							>
								{isRecommended}
								<span className='rate text-center'>
									{numberToLocaleWithcurrency(rate.rate.pointsFrom || 0)} {rate.rate.pointsCurrency || ''}
								</span>
							</div>
						),
						label: '',
						outPutData: rate,
					});
				case ModelTypeEnum.POINT_OR_MONEY:
				case ModelTypeEnum.POINT_PLUS_MONEY:
					if (rateModelPoints === CarsRatesModelsV2.equivalentRate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td flex flex-col items-center ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									{showChangedPrice ? (
										<>
											<span className='pointsRate rate text-center mt-2'>
												{numberToLocaleWithcurrency(rate.equivalentRate?.chargedPoints || 0, '', culture)} {rate.equivalentRate?.pointsCurrency || ''}
											</span>
											<span className='cashRate rate text-center'>
												+{' '}
												{numberToLocaleWithcurrency(
													rate.equivalentRate?.chargedAmount || 0,
													rate.equivalentRate?.currency || '',
													'',
													currencySymbol || '',
												)}
											</span>
										</>
									) : (
										<>
											<span className='pointsRate rate text-center'>
												{rate.equivalentRate?.pointsFrom
													? `${numberToLocaleWithcurrency(rate.equivalentRate?.pointsFrom || 0)} ${rate.equivalentRate?.pointsCurrency || ''}`
													: `${numberToLocaleWithcurrency(rate.equivalentRate?.rateFrom || 0, rate.equivalentRate?.currency || '')} ${
														rate.equivalentRate?.currency || ''
													  }`}
											</span>
											{!!rate.equivalentRate?.pointsFrom && !!rate.equivalentRate?.rateFrom && (
												<span className='cashRate rate text-center'>
													+ {rate.equivalentRate?.currency || ''}{' '}
													{numberToLocaleWithcurrency(rate.equivalentRate?.rateFrom || 0, rate.equivalentRate?.currency || '')}
												</span>
											)}
										</>
									)}
								</div>
							),
							label: '',
							outPutData: rate,
						});
					} else if (rateModelPoints === CarsRatesModelsV2.rate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td flex flex-col items-center ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									<span className='pointsRate rate text-center'>
										{rate.rate.pointsFrom
											? `${numberToLocaleWithcurrency(rate.rate.pointsFrom || 0)} ${rate.rate.pointsCurrency || ''}`
											: `${numberToLocaleWithcurrency(rate.rate.rateFrom || 0, rate.rate.currency || '')} ${rate.rate.currency || ''}`}
									</span>
									{!!rate.rate.pointsFrom && !!rate.rate.rateFrom && (
										<span className='cashRate rate text-center'>
											+ {rate.rate.currency || ''} {numberToLocaleWithcurrency(rate.rate.rateFrom || 0, rate.rate.currency || '')}
										</span>
									)}
								</div>
							),
							label: '',
							outPutData: rate,
						});
					}

					break;
				default:
					break;
			}
			rate.rateTypeLabel = t(rate.rateTypeLabel);
		});

		const uniquesCoverages: CoveragesInterfaceV2[] = [...new Map(groupCoverages.map(item => [item.uniqueValue, item])).values()];
		uniquesCoverages.forEach(coverage => {
			const body: RowData[] = [];
			body.push({ value: t(coverage?.tag || coverage?.text), label: '' });

			rateByRentalCar?.rateByCoverage.forEach(rate => {
				body.push({
					value: this.validateRateTypeCodeIncludeCoverage(
						rate.rateCodeType as CarGroupedCoveragesCodesV2,
						rateByRentalCar?.rateByCoverage,
						coverage?.tag,
					),
					label: '',
				});
			});

			bodyTable.push([...body]);
		});

		bodyTable.push([{ value: <p className='carDetailCoverage__total'>{t('total')}</p>, label: '' }, ...rates]);

		const actions: RowData[] = [{ value: '', label: '' }];
		rates.forEach(rate => {
			const checkSelected: boolean = (rate.outPutData as GroupedCoveragesInterfaceV2)?.selected || false;
			actions.push({
				value: (
					<Button
						text={checkSelected ? t('selected') : t('toSelect')}
						styles={{
							background: checkSelected ? '#000000' : colorPrimary,
							color: checkSelected ? '#ffffff' : '#000000',
						}}
						onClick={() => {
							if (callBack) {
								const outPut: GroupedCoveragesInterfaceV2 = (rate?.outPutData as GroupedCoveragesInterfaceV2) || null;
								callBack(outPut);
							}
						}}
						customClass={`carDetailCoverages__btn w-full rounded-full text-white my-[20px] bg-black ${
							checkSelected ? 'carDetailCoverages__selectedBtn' : ''
						}`}
					/>
				),
				label: '',
			});
		});

		bodyTable.push([...actions]);

		return {
			head: headTable,
			rows: bodyTable,
		};
	}

	public mapMobileCoveragesforTable(
		rateByRentalCar: RentalCarInterfaceV2,
		businessModel: ModelTypeEnum | string,
		t: (item: string) => string,
		rateModel?: CarsRatesModelsV2,
		colorPrimary?: string,
		isAvailabilityInLocalCurrency?: boolean,
		callBack?: (outPutData: GroupedCoveragesInterfaceV2 | null) => void,
		showChangedPrice?: boolean,
		currencySymbol?: string,
		culture?: string,
	): TableData[] {
		const tableDataCoverages: TableData[] = [];
		rateByRentalCar.rateByCoverage.forEach(rate => {
			const rates: RowData[] = [];
			const headTable: RowData[] = [
				{
					value: (
						<div className={`carDetailCoverage__td flex items-center ${rate?.selected ? 'carDetailCoverage__isSelected' : ''}`}>
							{this.isActiveMultiLanguage ? t(coverageTypeCamel(rate.rateCodeType)) : rate.rateTypeLabel}
						</div>
					),
					label: this.isActiveMultiLanguage ? t(coverageTypeCamel(rate.rateCodeType)) : rate.rateTypeLabel,
				},
			];

			const bodyTable: RowData[][] = [];
			rate.coverages.forEach(coverage => {
				if (coverage.included) {
					bodyTable.push([
						{
							value: (
								<p className={`carDetailCoverage__td ${rate?.selected ? 'carDetailCoverage__isSelected' : ''}`}>
									<span className='relative z-[2]'>{t(coverage.tag || coverage.text)}</span>
								</p>
							),
							label: coverage.tag || '',
						},
						{
							value: this.validateRateTypeCodeIncludeCoverage(
								rate.rateCodeType as CarGroupedCoveragesCodesV2,
								rateByRentalCar?.rateByCoverage,
								coverage?.tag,
							),
							label: coverage.tag || '',
						},
					]);
				}
			});

			const isRecommended: JSX.Element = rate?.isRecommended ? (
				<span
					className='carDetailCoverage__isRecommended absolute p-[2px] top-[-11px] w-[95px] left-0 right-0 mx-auto rounded-full text-xs'
					style={{ background: colorPrimary }}
				>
					{t('recommended')}
				</span>
			) : (
				<></>
			);

			const rateModelPoints: CarsRatesModelsV2 = isAvailabilityInLocalCurrency ? CarsRatesModelsV2.equivalentRate : CarsRatesModelsV2.rate;
			rate.selectedRate = (businessModel === ModelTypeEnum.CASH ? rateModel : rateModelPoints) as unknown as SelectedRateV2 | undefined;

			switch (businessModel) {
				case ModelTypeEnum.CASH:
					if (rateModel === CarsRatesModelsV2.equivalentRate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									<span className={`rate text-center ${rate?.isRecommended ? 'mt-[12px]' : ''}`}>
										{numberToLocaleWithcurrency(rate.equivalentRate.rateFrom || 0, rate.equivalentRate.currency || '')}{' '}
										{rate.equivalentRate.currency || ''}
									</span>
								</div>
							),
							label: '',
							outPutData: rate,
						});
					} else if (rateModel === CarsRatesModelsV2.rate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									<span className={`rate text-center ${rate?.isRecommended ? 'mt-[12px]' : ''}`}>
										{numberToLocaleWithcurrency(rate.rate.rateFrom || 0, rate.rate.currency || '')} {rate.rate.currency || ''}
									</span>
								</div>
							),
							label: '',
							outPutData: rate,
						});
					}

					break;
				case ModelTypeEnum.POINTS:
					rates.push({
						value: (
							<div
								className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td ${
									rate?.selected ? 'carDetailCoverage__isSelected' : ''
								}`}
							>
								{isRecommended}
								<span className={`rate text-center ${rate?.isRecommended ? 'mt-[12px]' : ''}`}>
									{numberToLocaleWithcurrency(rate.rate.pointsFrom || 0)} {rate.rate.pointsCurrency || ''}
								</span>
							</div>
						),
						label: '',
						outPutData: rate,
					});
				case ModelTypeEnum.POINT_OR_MONEY:
				case ModelTypeEnum.POINT_PLUS_MONEY:
					if (rateModelPoints === CarsRatesModelsV2.equivalentRate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td flex flex-col items-center ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									{showChangedPrice ? (
										<>
											<span className='pointsRate rate text-center mt-2'>
												{numberToLocaleWithcurrency(rate.equivalentRate?.chargedPoints || 0, '', culture)} {rate.equivalentRate?.pointsCurrency || ''}
											</span>
											<span className='cashRate rate text-center'>
												+{' '}
												{numberToLocaleWithcurrency(
													rate.equivalentRate?.chargedAmount || 0,
													rate.equivalentRate?.currency || '',
													'',
													currencySymbol || '',
												)}
											</span>
										</>
									) : (
										<>
											<span className={`pointsRate rate text-center ${rate?.isRecommended ? 'mt-[12px]' : ''}`}>
												{rate.equivalentRate?.pointsFrom
													? `${numberToLocaleWithcurrency(rate.equivalentRate?.pointsFrom || 0)} ${rate.equivalentRate?.pointsCurrency || ''}`
													: `${numberToLocaleWithcurrency(rate.equivalentRate?.rateFrom || 0, rate.equivalentRate?.currency || '')} ${
														rate.equivalentRate?.currency || ''
													  }`}
											</span>
											{!!rate.equivalentRate?.pointsFrom && !!rate.equivalentRate?.rateFrom && (
												<span className='cashRate rate text-center'>
													+ {rate.equivalentRate?.currency || ''}{' '}
													{numberToLocaleWithcurrency(rate.equivalentRate?.rateFrom || 0, rate.equivalentRate?.currency || '')}
												</span>
											)}
										</>
									)}
								</div>
							),
							label: '',
							outPutData: rate,
						});
					} else if (rateModelPoints === CarsRatesModelsV2.rate) {
						rates.push({
							value: (
								<div
									className={`carDetailCoverage__${businessModel} relative carDetailCoverage__td flex flex-col items-center ${
										rate?.selected ? 'carDetailCoverage__isSelected' : ''
									}`}
								>
									{isRecommended}
									<span className={`pointsRate rate text-center ${rate?.isRecommended ? 'mt-[12px]' : ''}`}>
										{rate.rate?.pointsFrom
											? `${numberToLocaleWithcurrency(rate.rate?.pointsFrom || 0)} ${rate.rate?.pointsCurrency || ''}`
											: `${numberToLocaleWithcurrency(rate.rate?.rateFrom || 0, rate.rate?.currency || '')} ${rate.rate?.currency || ''}`}
									</span>
									{!!rate.rate?.pointsFrom && !!rate.rate?.rateFrom && (
										<span className='cashRate rate text-center'>
											+ {rate.rate?.currency || ''} {numberToLocaleWithcurrency(rate.rate?.rateFrom || 0, rate.rate?.currency || '')}
										</span>
									)}
								</div>
							),
							label: '',
							outPutData: rate,
						});
					}

					break;
				default:
					break;
			}
			rate.rateTypeLabel = t(rate.rateTypeLabel);

			bodyTable.push([
				{
					value: (
						<p className={`carDetailCoverage__total carDetailCoverage__td ${rate?.selected ? 'carDetailCoverage__isSelected' : ''}`}>
							<span className='relative z-[2]'>{t('total')}</span>
						</p>
					),
					label: '',
				},
				...rates,
			]);

			const actions: RowData[] = [];
			rates.forEach(rate => {
				const checkSelected: boolean = (rate.outPutData as GroupedCoveragesInterfaceV2)?.selected || false;
				actions.push({
					value: (
						<Button
							text={checkSelected ? t('selected') : t('toSelect')}
							styles={{
								background: checkSelected ? '#000000' : colorPrimary,
								color: checkSelected ? '#ffffff' : '#000000',
							}}
							onClick={() => {
								if (callBack) {
									const outPut: GroupedCoveragesInterfaceV2 = (rate?.outPutData as GroupedCoveragesInterfaceV2) || null;
									callBack(outPut);
								}
							}}
							customClass={`carDetailCoverages__btn w-full rounded-full text-white bg-black ${
								checkSelected ? 'carDetailCoverages__selectedBtn' : ''
							}`}
						/>
					),
					label: '',
				});
			});

			bodyTable.push([...actions]);

			tableDataCoverages.push({
				head: headTable,
				rows: bodyTable,
			});
		});

		return tableDataCoverages;
	}

	private validateRateTypeCodeIncludeCoverage(
		rateCodeType: CarGroupedCoveragesCodesV2,
		rateByCoverage: GroupedCoveragesInterfaceV2[],
		coverageTag: string | null,
	): JSX.Element {
		const checkCoverage: GroupedCoveragesInterfaceV2 | undefined = rateByCoverage.find(rate => rate.rateCodeType === rateCodeType);
		const checkIfIncludeCoverage: boolean | undefined = checkCoverage?.coverages?.some(coverage => coverage.tag === coverageTag);
		const isSelectedClass: string = checkCoverage?.selected ? 'carDetailCoverage__isSelected' : '';
		if (checkIfIncludeCoverage) {
			return (
				<div className={`carDetailCoverage__td ${rateCodeType} ${coverageTag || ''} ${isSelectedClass}`}>
					<span className='checkRoundIco text-[20px]'></span>
				</div>
			);
		}

		return <div className={`carDetailCoverage__td ${rateCodeType} ${coverageTag || ''} ${isSelectedClass}`}></div>;
	}

	public getSelectedRate(recommendation: CarInterfaceV2): string {
		const firstItem: number = 0;
		const coverage: GroupedCoveragesInterfaceV2 | undefined = recommendation?.rateByRentalCar[firstItem]?.rateByCoverage.find(
			(item: GroupedCoveragesInterfaceV2): boolean => !!item?.selected,
		);

		return coverage?.selectedRate || SelectedRateV2.rate;
	}
}
