import { CMSGeneralComponentInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import { SlideInterface } from '../../../../../shared/interfaces/generics/slider.interface';
import { GenericIntroduction } from '../introductions/generic-introduction/generic-introduction';
import { CardInformation } from '../../../../shared-components/card-information/card-information';
import { SkeletonInformativeCards } from './skeleton/skeleton-informative-cards';
import { useState, useEffect } from 'react';

export interface InformativeCardsProps extends CMSGeneralComponentInterface {
	loading: boolean;
	customClass?: string;
	introductionContainerClassName?: string;
	introductionClassName?: string;
	cardTitleClassName?: string;
	cardSubTitleClassName?: string;
	colorPrimary?: string;
}

export function InformativeCards({
	title,
	subtitle,
	description,
	slides,
	loading,
	customClass,
	introductionContainerClassName,
	introductionClassName,
	cardTitleClassName,
	cardSubTitleClassName,
	colorPrimary,
}: InformativeCardsProps): JSX.Element {
	const [showSkeleton, setShowSkeleton] = useState(true);
	useEffect(() => {
		setShowSkeleton(loading);
	}, [loading]);

	return showSkeleton ? (
		<SkeletonInformativeCards
			className={customClass}
			introductionContainerClassName={introductionContainerClassName}
			introductionClassName={introductionClassName}
		/>
	) : (
		<div className={`informativeCards ${customClass || ''}`}>
			<GenericIntroduction
				title={title}
				subtitle={subtitle}
				description={description}
				customClass={`${introductionClassName || ''}`}
				containerClassName={introductionContainerClassName}
				titleClassName='text-black'
				subtitleClassName='text-[#7a8083]'
				descriptionClassName='text-base text-[#494848] w-[95%] md:w-4/5'
				lineClassName='bg-[#7A8083] border-[#7A8083]'
				linkText=''
				link=''
			/>
			<div className='w-full block md:flex justify-between mt-2.5'>
				{slides.map((card: SlideInterface, index: number) => (
					<CardInformation
						key={index}
						iconClassName={card.iconClassName}
						title={card.title}
						description={card.description}
						customClass='w-full md:w-[32%] h-[250px] md:h-[330px] mb-[20px] md:mb-0 border-black'
						contentClassName='h-full p-10 justify-between flex-col'
						iconContainerClassName={'h-[50px] md:h-[70px] w-[50px] md:w-[70px]'}
						iconsClassName={'text-[22px] md:text-[30px]'}
						titleClassName={`text-2xl ${cardTitleClassName || ''}`}
						subTitleClassName={`text-xs md:mb-5 ${cardSubTitleClassName || ''}`}
						colorPrimary={colorPrimary}
						image=''
						traits={null}
						type=''
						subtitle=''
						link=''
						linkText=''
					/>
				))}
			</div>
		</div>
	);
}
