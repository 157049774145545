import { HotelsSearchFormSubmitInterface } from '@smartlinks/react-design-system';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import HotelsSearch from '../../templates/hotels-search/hotels-search';
import { HotelsService } from '../../shared/services/hotels.service';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import { CommonsUtil } from '../../shared/utils/commons';
import styles from 'bundle-text:./hotels-search.scss';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';

export interface HotelsSearchWidgetProps extends SearchWidgetInterface<HotelsSearchFormSubmitInterface> {}

const AppHotelsSearchWidget = ({ token, language, agency, callUrlSearch, callShowMobileFields }: HotelsSearchWidgetProps): JSX.Element => {
	const widgetClassName: string = 'hotelsSearchWidget';
	const {
		agencyState,
		isLoading,
		widgetStyles,
		widgetService: hotelsService,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<HotelsService, HotelsSearchFormSubmitInterface>({
		token,
		language,
		agency,
		className: widgetClassName,
		styles: styles?.toString() || '',
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: HotelsService,
	});

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
				{!isLoading && (
					<HotelsSearch
						language={language || 'es'}
						agency={agencyState}
						hotelsService={hotelsService}
						callUrlSearch={urlSearchHandler}
						t={t}
						callShowMobileFields={showMobileFieldsHandler}
					/>
				)}
			</div>
		</Suspense>
	);
};

export function HotelsSearchWidget(props: HotelsSearchWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget hotelsSearch'>
					<AppHotelsSearchWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
