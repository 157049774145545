import { InputType, TypePerson, validateInputType } from '../../../../../shared/enums';
import { inputValidationType } from '../../../../../shared/services/utils.service';
import { Select, Input, Button, RadioInput } from '../../../../ui-elements/index';
import { AlertStates, TypeDocument } from '../../../../../shared/enums/index';
import {
	OptionSelect,
	PaymentStepResultEventInterface,
	InputEventsHandler,
	BillingDataInterface,
	BillingTitlesInterface,
} from '../../../../../shared/interfaces/index';
import { RegexItem } from '../../../../../shared/regex/regex';
import { PopUpDialog } from '../../../../shared-components';
import styles from './billing-data.module.scss';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

export interface BillingDataProps {
	idComponent: string;
	title: string;
	billingData?: BillingDataInterface;
	billingTitles: BillingTitlesInterface;
	typeDocuments: OptionSelect[];
	continueText: string;
	previousText: string;
	alertTitle: string;
	alertDescription: string;
	alertOkButton: string;
	alertCancelButton: string;
	onNextClick?: (data: PaymentStepResultEventInterface) => void;
	onPreviousClick?: (data: PaymentStepResultEventInterface) => void;
	submitForm: (formData: BillingDataInterface) => void;
}

export const BillingData = ({
	idComponent,
	billingData,
	billingTitles,
	typeDocuments,
	continueText,
	previousText,
	alertTitle,
	alertDescription,
	alertOkButton,
	alertCancelButton,
	onNextClick,
	submitForm,
	onPreviousClick,
}: BillingDataProps): JSX.Element => {
	const { register, handleSubmit, setValue } = useForm();
	const [typePerson, setTypePerson] = useState<string>(TypePerson.naturalPerson);
	const [showModal, setshowModal] = useState<boolean>(false);
	const [typedocument, setTypedocument] = useState<string>('');
	const validationTypePerson = (e: InputEventsHandler): void => {
		if (e.value != null) {
			setTypePerson(String(e.value));
		}
	};

	const typeDocumentValidated = (value: string, name: string): void => {
		setValue(name, value);

		setTypedocument(String(value));
	};

	const validationNextEvent = (): void => {
		setshowModal(false);

		if (onNextClick) {
			onNextClick({ id: idComponent });
		}
	};

	const onSubmit = handleSubmit((data: Record<string, string | boolean>) => {
		const billingDataOur: BillingDataInterface = {
			typePerson: String(data.typePerson),
			firtsName: String(data.firstName),
			lastName: String(data.lastName),
			typedocument: String(data.typedocument),
			documentNumber: String(data.documentNumber),
			address: String(data.address),
			city: String(data.city),
			phone: String(data.phone),
			email: String(data.email),
			goPointDocument: String(data.goPointDocument),
			goPointName: String(data.goPointName),
		};

		setshowModal(true);

		submitForm(billingDataOur);
	});

	const validationInputType = (value: string, name: string, typeValidation: string): void => {
		const valueOut: string = inputValidationType(value, typeValidation);
		setValue(name, valueOut);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={'text-center text-1.5xl font-black mb-1.5'}>
				<form onSubmit={onSubmit} id={'billing-data'}>
					<div className={'px-1 flex'}>
						<div className='flex justify-center'>
							<div className='flex'>
								<div className='form-check mx-2'>
									<RadioInput
										id={billingTitles.naturalPersonTitle}
										label={billingTitles.naturalPersonTitle}
										value={billingTitles.naturalPersonLabel}
										inputClassName={'mt-2'}
										labelClassName={'text-[0.80rem]'}
										checked={true}
										register={register('typePerson', {
											onChange: (element: { target: InputEventsHandler }) => validationTypePerson(element.target),
										})}
									/>
								</div>
								<div className='form-check mx-2'>
									<RadioInput
										id={billingTitles.legalPersonTitle}
										label={billingTitles.legalPersonTitle}
										value={billingTitles.legalPersonLabel}
										inputClassName={'mt-2'}
										labelClassName={'text-[0.80rem]'}
										register={register('typePerson', {
											onChange: (element: { target: InputEventsHandler }) => validationTypePerson(element.target),
										})}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className={'pb-5 px-1 mb-4'}>
						<div className='grid grid-cols-2 '>
							{typePerson === TypePerson.naturalPerson ? (
								<>
									<Input
										type={InputType.text}
										placeholder={billingTitles.firtsNameLabel}
										label={billingTitles.firtsNameTitle}
										className={'dynamicForms__maxWidth '}
										register={register('firstName', {
											onChange: (element: { target: HTMLInputElement }) =>
												validationInputType(element.target.value, 'firstName', validateInputType.onlyLetters),
											required: true,
											pattern: RegexItem.onlyLetters,
										})}
										defaultValue={billingData?.firtsName ? billingData.firtsName : ''}
										required={true}
									/>

									<Input
										type={InputType.text}
										required={true}
										placeholder={billingTitles.lastNameLabel}
										label={billingTitles.lastNameTitle}
										className={'dynamicForms__maxWidth '}
										register={register('lastName', {
											onChange: (element: { target: HTMLInputElement }) =>
												validationInputType(element.target.value, 'lastName', validateInputType.onlyLetters),
											required: true,
											pattern: RegexItem.onlyLetters,
										})}
										defaultValue={billingData?.lastName ? billingData.lastName : ''}
									/>
								</>
							) : (
								<Input
									type={InputType.text}
									required={true}
									placeholder={billingTitles.businessNameTitle}
									label={billingTitles.businessNameTitle}
									className={'dynamicForms__maxWidth '}
									defaultValue={billingData?.firtsName ? billingData.firtsName : ''}
								/>
							)}

							<Select
								options={typeDocuments}
								placeHolder={billingTitles.typedocumentLabel}
								required={true}
								label={billingTitles.typedocumentTitle}
								className={'dynamicForms__maxWidth '}
								selectClassName={'bg-gray-100 font-family-regular border-2 border-red-500}'}
								register={register('typedocument', {
									onChange: (element: { target: HTMLInputElement }) => typeDocumentValidated(element.target.value, 'typedocument'),
									required: true,
									pattern: RegexItem.onlyLetters,
								})}
							/>

							<Input
								type={InputType.text}
								required={true}
								placeholder={billingTitles.documentNumberTitle}
								label={billingTitles.documentNumberTitle}
								className={'dynamicForms__maxWidth '}
								defaultValue={billingData?.documentNumber ? billingData.documentNumber : ''}
								register={register('documentNumber', {
									onChange: (element: { target: HTMLInputElement }) =>
										validationInputType(
											element.target.value,
											'documentNumber',
											typedocument === TypeDocument.pasaporte ? '' : validateInputType.onlyNumber,
										),
									required: true,
								})}
							/>

							<Input
								type={InputType.text}
								required={true}
								placeholder={billingTitles.addressLabel}
								label={billingTitles.addressTitle}
								className={'dynamicForms__maxWidth '}
								defaultValue={billingData?.address ? billingData.address : ''}
								register={register('address', {
									required: true,
								})}
							/>

							<Input
								type={InputType.text}
								required={true}
								placeholder={billingTitles.cityTitle}
								label={billingTitles.cityTitle}
								register={register('city', {
									onChange: (element: { target: HTMLInputElement }) =>
										validationInputType(element.target.value, 'city', validateInputType.onlyLetters),
									required: true,
									pattern: RegexItem.onlyLetters,
								})}
								className={'dynamicForms__maxWidth '}
								defaultValue={billingData?.city ? billingData.city : ''}
							/>
							<Input
								type={InputType.number}
								required={true}
								placeholder={billingTitles.phoneLabel}
								label={billingTitles.phoneTitle}
								className={'dynamicForms__maxWidth '}
								defaultValue={billingData?.phone ? billingData.phone : ''}
								register={register('phone', {
									required: true,
								})}
							/>

							<Input
								type={InputType.email}
								required={true}
								placeholder={billingTitles.emailLabel}
								label={billingTitles.emailTitle}
								className={'dynamicForms__maxWidth '}
								defaultValue={billingData?.email ? billingData.email : ''}
								register={register('email', {
									required: true,
								})}
							/>
						</div>
					</div>

					<div className={'pb-5 px-1'}>
						<div className='grid grid-cols-2 '>
							<Input
								type={InputType.text}
								placeholder={billingTitles.goPointNameLabel}
								label={billingTitles.goPointNameTitle}
								className={'dynamicForms__maxWidth '}
								defaultValue={billingData?.goPointName ? billingData.goPointName : ''}
								register={register('goPointName', {
									required: false,
								})}
							/>

							<Input
								type={InputType.text}
								placeholder={billingTitles.goPointDocumentLabel}
								label={billingTitles.goPointDocumentTitle}
								className={'dynamicForms__maxWidth '}
								defaultValue={billingData?.goPointDocument ? billingData.goPointDocument : ''}
								register={register('goPointDocument', {
									required: false,
								})}
							/>
						</div>
					</div>

					<div className='flex flex-col md:flex-row items-center md:justify-end mt-6'>
						<Button
							onClick={() => (onPreviousClick ? onPreviousClick({ id: idComponent }) : null)}
							text={previousText}
							customClass={'w-full md:w-1/5 md:mr-2 text-xs text-white rounded-lg my-2 md:my-0 billingData__button'}
						/>
						<Button
							text={continueText}
							type={'submit'}
							customClass={'w-full md:w-1/5 text-xs text-white rounded-lg my-2 md:my-0 billingData__button'}
						/>
					</div>
				</form>
				{showModal ? (
					<div
						className={
							'justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none billingData__modal'
						}
					>
						<PopUpDialog
							idComponent={'alert-payment'}
							title={alertTitle}
							description={alertDescription}
							okButtonText={alertOkButton}
							cancelButtonText={alertCancelButton}
							state={AlertStates.warning}
							showStateIcon={true}
							showCancelButton={true}
							className={'bg-white pt-14 pb-8 px-10'}
							onNextClick={() => validationNextEvent()}
							onPreviousClick={() => setshowModal(false)}
						/>
					</div>
				) : null}
			</div>
		</>
	);
};

export default BillingData;
