enum CardFranchise {
	visa = 'VISA',
	mastercard = 'MASTERCARD',
	amex = 'AMEX',
	discover = 'DISCOVER',
	diners = 'DINERS',
	unknown = 'UNKNOWN',
}

interface CardPattern {
	visa: RegExp;
	mastercard: RegExp;
	amex: RegExp;
	discover: RegExp;
	diners: RegExp;
}

const pattern: CardPattern = {
	visa: /^4/,
	mastercard: /^5[1-5]/,
	amex: /^3[47]/,
	discover: /^6(?:011|5)/,
	diners: /^3(?:0[0-5]|[68])/,
};

export function identifyCardFranchise(firstDigits: string): CardFranchise {
	for (const card in pattern) {
		if (pattern[card as keyof CardPattern].test(firstDigits)) {
			return card as CardFranchise;
		}
	}

	return CardFranchise.unknown;
}
