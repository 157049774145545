import HorizontalDecription from '../../../../ui-elements/horizontal-description/horizontal-description';
import AlertCard, { AlertCardProps } from '../../../../shared-components/alert-card/alert-card';
import {
	PaymentConfirmationDataInterface,
	PaymentConfirmationOrderInterface,
	PaymentConfirmationPassengerInterface,
} from '../../../../../shared/interfaces/payment-confirmation.interface';
import Button from '../../../../ui-elements/button/button';
import styles from './payment-confirmation.module.scss';

export interface PaymentConfirmationProps extends AlertCardProps {
	title: string;
	subTitle: string;
	summaryTitle: string;
	passengerTitle: string;
	orderTitle: string;
	buttonLabel: string;
	passenger: PaymentConfirmationPassengerInterface;
	order: PaymentConfirmationOrderInterface;
	generalData: PaymentConfirmationDataInterface;
	lineHeight?: string;
	buttonColor?: string;
	onClick?: () => void;
	className?: string;
	titleClassName?: string;
	subTitleClassName?: string;
	buttonClassName?: string;
}

export const PaymentConfirmation = ({
	title,
	subTitle,
	summaryTitle,
	passengerTitle,
	orderTitle,
	buttonLabel,
	passenger,
	order,
	generalData,
	className,
	titleClassName,
	subTitleClassName,
	buttonClassName,
	state,
	cardWidth,
	iconSize,
	iconBackgroundColor,
	lineHeight,
	buttonColor,
	onClick,
}: PaymentConfirmationProps): JSX.Element => {
	const contentAlert: JSX.Element = (
		<div className='flex flex-row items-start mt-5'>
			<div className='flex flex-col w-2/4 pr-12'>
				<span className='text-xl font-black mb-2.5'>{summaryTitle}</span>
				<span className='text-base font-black mb-1.5'>{passengerTitle}</span>
				<HorizontalDecription label={passenger.labels.name} value={passenger.values.name} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={passenger.labels.idCard} value={passenger.values.idCard} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={passenger.labels.email} value={passenger.values.email} className={'text-xs font-family-regular'} />
				<span className='text-base font-black mb-1.5 mt-2.5'>{orderTitle}</span>
				<HorizontalDecription label={order.labels.codeReserve} value={order.values.codeReserve} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={order.labels.type} value={order.values.type} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={order.labels.date} value={order.values.date} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={order.labels.departureCodes} value={order.values.departureCodes} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={order.labels.returnCodes} value={order.values.returnCodes} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={String(order.labels.total)} value={order.values.total} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={order.labels.paymentReference} value={order.values.paymentReference} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={order.labels.currency} value={order.values.currency} className={'text-xs font-family-regular'} />
			</div>
			<div
				className={'self-center paymentConfirmation__line'}
				style={{
					height: lineHeight,
				}}
			/>
			<div className='flex flex-col w-2/4 pl-12'>
				<span className='text-xl text-right font-black mb-2.5'>{`${generalData.values.total} ${order.values.currency}`}</span>
				<HorizontalDecription
					label={generalData.labels.paymentMethod}
					value={generalData.values.paymentMethod}
					className={'text-xs font-family-regular mt-12'}
				/>
				<HorizontalDecription label={generalData.labels.date} value={generalData.values.date} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={generalData.labels.permission} value={generalData.values.permission} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={String(generalData.labels.total)} value={generalData.values.total} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={generalData.labels.receipt} value={generalData.values.receipt} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={generalData.labels.bank} value={generalData.values.bank} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={generalData.labels.ipAddress} value={generalData.values.ipAddress} className={'text-xs font-family-regular'} />
				<HorizontalDecription label={generalData.labels.state} value={generalData.values.state} className={'text-xs font-family-regular'} />
				<HorizontalDecription
					label={generalData.labels.description}
					value={generalData.values.description}
					className={'text-xs font-family-regular'}
				/>
				<div className='flex flex-row justify-end mt-8'>
					<Button
						content={
							<span>
								<span className='printerIco'></span>
								{` ${buttonLabel}`}
							</span>
						}
						customClass={`text-xs rounded-lg paymentConfirmation__button ${buttonClassName || ''}`}
						styles={{
							backgroundColor: buttonColor,
						}}
						onClick={onClick}
					/>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`flex flex-col justify-center items-center ${className || ''}`}>
				<span className={`text-4xl font-black mb-1.5 ${titleClassName || ''}`}>{title}</span>
				<span className={`text-sm mb-12 ${subTitleClassName || ''}`}>{subTitle}</span>
				<AlertCard
					state={state}
					cardWidth={cardWidth}
					iconSize={iconSize}
					iconBackgroundColor={iconBackgroundColor}
					content={contentAlert}
					className={'px-8 pb-6'}
					style={{
						marginTop: `${iconSize / 2}px`,
					}}
				/>
			</div>
		</>
	);
};

PaymentConfirmation.defaultProps = {
	className: '',
	titleClassName: '',
	subTitleClassName: '',
	buttonClassName: '',
};

export default PaymentConfirmation;
