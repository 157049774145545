import { FieldButtonEventHandlerInterface } from '../../../../../shared/interfaces';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';

export interface AirlineMobileProps {
	passengersId: string;
	watchAirline: string;
	traductions: (value: string) => string;
	airlinesMobileFieldHandler: (event: FieldButtonEventHandlerInterface) => void;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	children?: JSX.Element;
}

export function AirlineMobile({
	passengersId,
	watchAirline,
	traductions,
	airlinesMobileFieldHandler,
	isSecondaryTemplate,
	isTertiaryTemplate,
	children,
}: AirlineMobileProps): JSX.Element {
	const defaultAirlineMobileElement: JSX.Element = (
		<div className='passengersFieldInputs__airlineInput md:hidden pb-[20px] md:px-[45px]'>
			<p
				className={`passengersFieldInputs__title m-0 text-base text-[#616161] whitespace-nowrap
					${isTertiaryTemplate ? '!text-[13px] !text-[#4D4D4F] font-medium pb-[2px]' : 'pb-[20px]'}
				`}
			>
				{traductions('airline') || 'airline'}
			</p>
			<div
				className={`w-full cursor-pointer bg-transparent 
					${
		isSecondaryTemplate
			? 'gap-2 py-2.5 px-3.5 rounded-md border border-gray-300'
			: isTertiaryTemplate
				? 'mt-1  py-2.5 px-3.5  border border-solid border-[#BEBEBE] text-[#4D4D4F] text-[14px] rounded-none'
				: 'py-[18px] px-[25px] border border-solid border-[#7A8083] rounded-[10px]'
		}`}
			>
				<div
					className={'flex flex-row justify-between items-center'}
					onClick={() =>
						airlinesMobileFieldHandler({
							id: passengersId,
						})
					}
				>
					<span className={`bg-transparent !p-0 m-0 ${watchAirline ? 'text-base' : 'text-xs text-[#C3C7CE]'}`}>
						{watchAirline || traductions('addYourAirline') || 'addYourAirline'}
					</span>
					<IcomoonIcon icon='chevronBottomIco' className='pl-[5px]' iconClassName='text-[5px]' />
				</div>
			</div>
		</div>
	);

	return (
		<>
			{isSecondaryTemplate ? (
				<div className='passengersFieldInputs__airlineInput md:hidden px-[16px] h-[94px] mt-1'>{children}</div>
			) : (
				defaultAirlineMobileElement
			)}
		</>
	);
}

export default AirlineMobile;
