import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { InputEventsHandler, DatePickerData } from '../../../../shared/interfaces/inputs.interface';
import { SearchFieldsNames, DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { ActivitiesSearchFormInputsNames } from '../enums/activities-search-fields.enum';
import {
	UseActivitiesSearchFormInterface,
	UseActivitiesSearchFormReturnInterface,
	ActivitiesSearchFormSubmitInterface,
} from '../interfaces/activities-search-form.interface';
import {
	FieldButtonEventHandlerInterface,
	DisplayValuesDatesFieldInterface,
	DatesFieldInputNamesInterface,
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import { ActivitiesSearchFormUtil } from '../utils/activities-search-form.util';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

const useActivitiesSearchForm = ({
	history,
	calendarRestriction,
	traductions,
	emitSubmitSearch,
	emitLocationsSearch,
	emitShowMobileFields,
}: UseActivitiesSearchFormInterface): UseActivitiesSearchFormReturnInterface => {
	const destinationHiddenName: string = ActivitiesSearchFormInputsNames.iataDestination;
	const destinationName: string = ActivitiesSearchFormInputsNames.destination;
	const datesHiddenName: string = ActivitiesSearchFormInputsNames.hiddenDates;
	const destinationId: SearchFieldsNames = SearchFieldsNames.destination;
	const datesName: string = ActivitiesSearchFormInputsNames.dates;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const passengersName: string = ActivitiesSearchFormInputsNames.passengers;
	const passengersId: SearchFieldsNames = SearchFieldsNames.passengers;
	const minPassengersNumber: number = 1;
	const defaultAge: number = 18;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm();

	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(destinationId);
	const inputRefs: React.MutableRefObject<Record<string, HTMLElement | null>> = useRef<Record<string, HTMLElement | null>>({});
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
	});

	const [passengersAges, setPassengersAges] = useState<number[]>([defaultAge]);
	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [minDate, setMinDate] = useState<Date>(new Date());
	const mobileFormHeight: number = useWindowHeight();
	const destinationFieldHandler = useCallback(
		(element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
			if (emitLocationsSearch) {
				emitLocationsSearch(element.target.value);
			}
		},
		[emitLocationsSearch],
	);

	const showMobileFieldsContainerHandler = useCallback((show: boolean, fieldId: string, fieldName: string): void => {
		const waitTime: number = 100;
		setShowContainer(show);

		setMobileStep(fieldId);

		setTimeout(() => {
			const element: HTMLElement = inputRefs.current[fieldId] as HTMLElement;
			if (element) {
				element.click();

				const input: HTMLInputElement = element.querySelector(`input[name="${fieldName}"]`) as HTMLInputElement;
				if (input) {
					input.focus();
				}
			}
		}, waitTime);
	}, []);

	const datesHandler = useCallback((event: InputEventsHandler): void => {
		const getData: DatePickerData = event.value as DatePickerData;
		setDefaultDates({
			startDate: getData.startDate,
		});
	}, []);

	const passengersHandler = useCallback(
		(passengersFieldInputs: PassengersFieldDataInterface, name: string): void | string => {
			const passengersDisplayValue: string = ActivitiesSearchFormUtil.getDisplayPassengersInfo(
				passengersFieldInputs.adults,
				traductions('passenger') || 'passenger',
				traductions('passengers') || 'passengers',
			);

			return passengersDisplayValue;
		},
		[traductions],
	);

	const passengersModalInputsHandler = useCallback(
		(currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string): number => {
			setPassengersAges((prevState: number[]) => {
				let tempState: number[] = [...prevState];
				if (tempState.length < newValue) {
					tempState = tempState.concat(Array(newValue - tempState.length).fill(defaultAge));
				} else if (tempState.length > newValue) {
					tempState.splice(newValue);
				}

				return [...tempState];
			});

			return newValue;
		},
		[],
	);

	const passengersAgesHandler = useCallback((event: InputEventsHandler, ageIndex: number): void => {
		const minAge: number = 0;
		const maxAge: number = 120;
		let newValue: number = Number(event.value);
		if (newValue < minAge) {
			newValue = minAge;
		}

		if (newValue > maxAge) {
			newValue = maxAge;
		}

		setPassengersAges((prevState: number[]) => {
			const tempState: number[] = [...prevState];
			tempState[ageIndex] = newValue;

			return [...tempState];
		});
	}, []);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(passengersName);
			const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
			const results: ActivitiesSearchFormSubmitInterface = {
				passengers: {
					passengers: data[tempPassengersNames.adultsName] || 0,
					passengersAges: [...passengersAges],
					display: data[tempPassengersNames.name] || '',
				},
				dates: data[datesName] || '',
				departureDate: data[tempDatesNames.startDate] || '',
				destination: data[destinationName] || '',
				destinationIata: data[destinationHiddenName] || '',
				urlPath: undefined,
			};

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.oneDate,
				{
					date: true,
					time: false,
				},
				results.departureDate,
				'',
				'',
				'',
			);

			setButtonSearchHistory({
				departure: results.destinationIata,
				arrival: '',
				departureDate: datesValues.largeStartDate || '',
				arrivalDate: '',
			});

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[passengersAges, emitSubmitSearch],
	);

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (event.id === destinationId) {
				setShowContainer(false);

				return;
			}

			if (event.id === datesId) {
				setShowContainer(false);

				return;
			}

			if (event.id === passengersId) {
				void (async () => {
					const delayTime: number = 500;
					setTimeout(() => {
						setShowContainer(false);

						setMobileStep(destinationId);
					}, delayTime);
				})();
			}
		},
		[onSubmit],
	);

	const backMobileFieldHandler = useCallback((event: FieldButtonEventHandlerInterface): void => {
		if (event.id === datesId) {
			setShowContainer(false);

			return;
		}

		if (event.id === passengersId) {
			setShowContainer(false);
		}
	}, []);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-activities-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		setMobileStep(destinationId);

		const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(passengersName);
		const tempPassengers: number = typeof history?.passengers?.passengers === 'number' ? history?.passengers?.passengers : minPassengersNumber;
		setValue(tempPassengersNames.adultsName, tempPassengers);

		const tempPassengersDisplay: string = ActivitiesSearchFormUtil.getDisplayPassengersInfo(
			tempPassengers,
			traductions('passenger') || 'passenger',
			traductions('passengers') || 'passengers',
		);

		setValue(tempPassengersNames.name, tempPassengersDisplay || history?.passengers?.display || '');

		let tempPassengersAges: number[] = history?.passengers?.passengersAges?.length
			? [...history.passengers.passengersAges]
			: Array(tempPassengers).fill(defaultAge);
		if (tempPassengersAges.length < tempPassengers) {
			tempPassengersAges = tempPassengersAges.concat(Array(tempPassengers).fill(defaultAge));
		} else {
			tempPassengersAges.splice(tempPassengers);
		}

		setPassengersAges([...tempPassengersAges]);

		setValue(destinationName, history?.destination || '');

		setValue(destinationHiddenName, history?.destinationIata || '');

		let tempDepartureDate: string = history?.departureDate ? history.departureDate : '';
		let tempStartDate: Date = new Date(`${tempDepartureDate}T00:00:00`);
		const validDate: boolean = !!history?.destinationIata && !!tempDepartureDate && tempStartDate >= minDate;
		if (!validDate) {
			tempDepartureDate = '';

			tempStartDate = new Date('T00:00:00');
		}

		setValue(tempDatesNames.startDate, tempDepartureDate);

		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.oneDate,
			{
				date: true,
				time: false,
			},
			tempDepartureDate,
			'',
			'',
			'',
		);

		setValue(datesName, validDate ? datesValues.dates : '');

		setButtonSearchHistory(
			history?.destinationIata
				? {
					departure: history?.destinationIata || '',
					arrival: '',
					departureDate: datesValues.largeStartDate || '',
					arrivalDate: '',
				  }
				: undefined,
		);

		setDefaultDates(
			validDate
				? {
					startDate: tempStartDate,
				  }
				: {
					startDate: minDate,
				  },
		);

		if (history?.passengers && history?.destination && history?.destinationIata && history?.departureDate && history?.dates) {
			void trigger([destinationName, destinationHiddenName, datesHiddenName, datesName, tempDatesNames.startDate]);
		}
	}, [history, minDate, traductions]);

	return {
		destinationName,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		passengersName,
		destinationId,
		datesId,
		passengersId,
		mobileFieldStep: mobileStep,
		buttonSearchHistory,
		minDate,
		defaultDates,
		showMobileFieldsContainer: showContainer,
		passengersAges,
		mobileFormHeight,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		destinationFieldHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		passengersHandler,
		passengersModalInputsHandler,
		passengersAgesHandler,
		onSubmit,
		inputRefs,
	};
};

export default useActivitiesSearchForm;
