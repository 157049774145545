import { InputEventsHandler, DatePickerData, OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import { FlightsSearchFormInputsNames, FlightsSearchFieldsNames } from '../enums/flights-search-fields.enum';
import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { SearchFieldsNames, DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { PassengersAndExtraInfoUtil } from '../utils/passengers-and-extra-info.util';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import {
	UseFlightsSearchFormInterface,
	UseFlightsSearchFormReturnInterface,
	FlightsSearchFormSubmitInterface,
	DestinationsNamesFlightsSearchFormInterface,
	DestinationsFlightsSearchFormSubmitInterface,
	DisabledTripTypeFlightsSearchFormInterface,
	FieldDestinationNamesFlightsSearchFormInterface,
	PassengersFieldInputNamesFlightsSearchFormInterface,
	ActiveFieldDestinationNamesFlightsSearchFormInterface,
} from '../interfaces/flights-search-form.interface';
import {
	FieldButtonEventHandlerInterface,
	DisplayValuesDatesFieldInterface,
	DatesFieldInputNamesInterface,
	FilterFieldInputNamesInterface,
	PassengersFieldDataInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import { FlightsSearchFormUtil } from '../utils/flights-search-form.util';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import { TabData } from '../../../../shared/interfaces/tabs';
import { FlightTripTypes } from '../enums/flight-types.enum';
import { useForm } from 'react-hook-form';

const useFlightsSearchForm = ({
	traductions,
	isDisplayPassengersExtraInfo,
	isFilterChangeBetweenCabinAndAirline,
	history,
	calendarRestriction,
	tripsRestriction,
	cabinsOptions,
	maxPassengers,
	airlines,
	disabledTripType,
	isTertiaryTemplate,
	emitSubmitSearch,
	emitDepartureAirportsSearch,
	emitArrivalAirportsSearch,
	emitShowMobileFields,
	hasPromoCodeActive,
	widgetStyles,
}: UseFlightsSearchFormInterface): UseFlightsSearchFormReturnInterface => {
	const onlyDirectFlightName: string = FlightsSearchFormInputsNames.onlyDirectFlight;
	const airlineId: FlightsSearchFieldsNames = FlightsSearchFieldsNames.airline;
	const withPromoCodeName: string = FlightsSearchFormInputsNames.withPromoCode;
	const passengersName: string = FlightsSearchFormInputsNames.passengers;
	const passengersId: SearchFieldsNames = SearchFieldsNames.passengers;
	const tripTypeName: string = FlightsSearchFormInputsNames.tripType;
	const airlineName: string = FlightsSearchFormInputsNames.airline;
	const minMultiDestinationsNumber: number = 2;
	const childrenByAdults: number = 2;
	const infantsByAdults: number = 1;
	const firstIndex: number = 0;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		setError,
		clearErrors,
		formState: { errors, isValid },
	} = useForm();

	const breakPointMobile: number = 768;
	const windowWidth: number = window.innerWidth;
	const isMobile: boolean = windowWidth < breakPointMobile;
	const inputRefs: React.MutableRefObject<Record<string, HTMLElement | null>> = useRef<Record<string, HTMLElement | null>>({});
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(SearchFieldsNames.departureDestination);
	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [destinationsNumber, setDestinationsNumber] = useState<number>(minMultiDestinationsNumber);
	const [tripType, setTripType] = useState<FlightTripTypes>(FlightTripTypes.round);
	const [showMobileAirlines, setShowMobileAirlines] = useState<boolean>(false);
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData[]>([]);
	const [showMultiActions, setShowMultiActions] = useState<boolean>(false);
	const [showTripTypeTabs, setShowTripTypeTabs] = useState<boolean>(true);
	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [cabinModalValue, setCabinModalValue] = useState<string>('');
	const [minDate, setMinDate] = useState<Date>(new Date());
	const mobileFormHeight: number = useWindowHeight();
	const watchAirline: string = watch ? watch(SearchFieldsUtil.getFilterInputsNames(airlineName).name) : '';
	const [promoCode, setPromoCode] = useState<string>('');
	const destinationsNames: DestinationsNamesFlightsSearchFormInterface[] = useMemo(
		() =>
			((tempTripsRestriction: number = minMultiDestinationsNumber): DestinationsNamesFlightsSearchFormInterface[] => {
				return FlightsSearchFormUtil.getMultiDestinationsInputsNames(FlightsSearchFormInputsNames.destinations, tempTripsRestriction);
			})(tripsRestriction),
		[tripsRestriction],
	);

	const tripTypesTabs: TabData[] = useMemo(
		() =>
			((tempTraductions: (label: string) => string, tempDisabledTripType?: DisabledTripTypeFlightsSearchFormInterface): TabData[] => {
				let tabs: TabData[] = [];
				const roundTrip: string = tempTraductions('roundTrip') || 'roundTrip';
				const onewayTrip: string = tempTraductions('onewayTrip') || 'onewayTrip';
				const multiTrip: string = tempTraductions('multiTrip') || 'multiTrip';
				const roundTripIndex: number = 0;
				const onewayTripIndex: number = 1;
				const multiTripIndex: number = 2;
				const elementsNumber: number = 1;
				tabs = [
					{ id: FlightTripTypes.round, label: roundTrip, disabled: false },
					{ id: FlightTripTypes.oneway, label: onewayTrip, disabled: false },
					{ id: FlightTripTypes.multiple, label: multiTrip, disabled: false },
				];

				if (tempDisabledTripType?.round) {
					tabs.splice(roundTripIndex, elementsNumber);
				}

				if (tempDisabledTripType?.oneway) {
					tabs.splice(onewayTripIndex, elementsNumber);
				}

				if (tempDisabledTripType?.multi) {
					tabs.splice(multiTripIndex, elementsNumber);
				}

				return tabs;
			})(traductions, disabledTripType),
		[traductions, disabledTripType],
	);

	const tripTypesOptions: OptionSelect[] = useMemo(
		() =>
			((tempTraductions: (label: string) => string, tempDisabledTripType?: DisabledTripTypeFlightsSearchFormInterface): OptionSelect[] => {
				let options: OptionSelect[] = [];
				const roundTrip: string = tempTraductions('roundTrip') || 'roundTrip';
				const onewayTrip: string = tempTraductions('onewayTrip') || 'onewayTrip';
				const multiTrip: string = tempTraductions('multiTrip') || 'multiTrip';
				const roundTripIndex: number = 0;
				const onewayTripIndex: number = 1;
				const multiTripIndex: number = 2;
				const elementsNumber: number = 1;
				options = [
					{ value: FlightTripTypes.round, label: roundTrip },
					{ value: FlightTripTypes.oneway, label: onewayTrip },
					{ value: FlightTripTypes.multiple, label: multiTrip },
				];

				if (tempDisabledTripType?.round) {
					options.splice(roundTripIndex, elementsNumber);
				}

				if (tempDisabledTripType?.oneway) {
					options.splice(onewayTripIndex, elementsNumber);
				}

				if (tempDisabledTripType?.multi) {
					options.splice(multiTripIndex, elementsNumber);
				}

				return options;
			})(traductions, disabledTripType),
		[traductions, disabledTripType],
	);

	const departureDestinationFieldHandler = useCallback(
		(element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
			if (emitDepartureAirportsSearch) {
				emitDepartureAirportsSearch(element.target.value);
			}
		},
		[emitDepartureAirportsSearch],
	);

	const arrivalDestinationFieldHandler = useCallback(
		(element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
			if (emitArrivalAirportsSearch) {
				emitArrivalAirportsSearch(element.target.value);
			}
		},
		[emitArrivalAirportsSearch],
	);

	const showMobileFieldsContainerHandler = useCallback(
		(show: boolean): void => {
			setShowContainer(show);

			setMobileStep(destinationsNames[firstIndex].departure.id);

			setShowTripTypeTabs(true);

			setShowMultiActions(false);

			setShowMobileAirlines(false);
		},
		[destinationsNames],
	);

	const showMobileFieldsSecondaryHandler = useCallback(
		(show: boolean, fieldId: string, fieldName: string): void => {
			const waitTime: number = 100;
			setMobileStep(fieldId);

			setShowContainer(show);

			setTimeout(() => {
				const element = inputRefs.current[fieldId] as HTMLElement;
				if (element) {
					element.click();

					const input = element.querySelector(`input[name="${fieldName}"]`) as HTMLInputElement;
					if (input) {
						input.focus();
					}
				}
			}, waitTime);

			setShowTripTypeTabs(false);

			setShowMultiActions(false);

			setShowMobileAirlines(false);
		},
		[destinationsNames],
	);

	const initTemporalDates = (): void => {
		const defaultDateList: DatePickerDefaultData[] = [];
		destinationsNames.forEach(_ => {
			defaultDateList.push({
				startDate: new Date(),
				endDate: new Date(),
			});
		});

		setDefaultDates(defaultDateList);
	};

	const setTemporalDates = (tempTripType: FlightTripTypes, tempStartDate: Date, tempEndDate: Date): DatePickerDefaultData => {
		let tempDefaultDates: DatePickerDefaultData = {
			startDate: minDate,
			endDate: minDate,
		};

		if (tempTripType === FlightTripTypes.round && tempStartDate >= minDate && tempEndDate >= minDate) {
			tempDefaultDates = {
				startDate: tempStartDate,
				endDate: tempEndDate,
			};
		} else if (tempTripType === FlightTripTypes.oneway && tempStartDate >= minDate) {
			tempDefaultDates = {
				startDate: tempStartDate,
				endDate: tempStartDate,
			};
		} else if (tempTripType === FlightTripTypes.multiple) {
			tempDefaultDates = {
				startDate: tempStartDate,
				endDate: tempStartDate,
			};
		}

		return tempDefaultDates;
	};

	const tripTypeTabsHandler = useCallback(
		(value: string): void => {
			const tempTripType: FlightTripTypes = value as FlightTripTypes;
			setValue(tripTypeName, tempTripType);

			setTripType(tempTripType);

			setMobileStep(destinationsNames[firstIndex].departure.id);

			setShowTripTypeTabs(true);

			setShowMultiActions(false);

			if (tempTripType !== FlightTripTypes.multiple) {
				setDestinationsNumber(minMultiDestinationsNumber);
			}

			destinationsNames.forEach((names: DestinationsNamesFlightsSearchFormInterface) => {
				const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(names.dates.hiddenName);
				setValue(tempDatesNames.endDate, '');

				setValue(tempDatesNames.startDate, '');

				setValue(names.dates.name, '');

				setValue(names.departure.name, '');

				setValue(names.departure.hiddenName, '');

				setValue(names.arrival.name, '');

				setValue(names.arrival.hiddenName, '');
			});
		},
		[tripTypeName, destinationsNames],
	);

	const tripTypeSelectHandler = useCallback(
		(event: InputEventsHandler): void => {
			tripTypeTabsHandler(String(event.value));
		},
		[tripTypeTabsHandler],
	);

	const datesHandler = useCallback(
		(event: InputEventsHandler, id: string, index: number): void => {
			const getData: DatePickerData = event.value as DatePickerData;
			setDefaultDatesCurrentOrNext(index, getData);

			const nextDate: number = index + 1;
			tripType === FlightTripTypes.multiple && setDefaultDatesCurrentOrNext(nextDate, getData);

			validateMinorDate(id);
		},
		[tripType],
	);

	const setDefaultDatesCurrentOrNext = (index: number, getData: DatePickerData): void => {
		if (index >= destinationsNames.length) return;

		const currentDate: Date = new Date();
		const currentStarDate: Date = getData?.startDate || currentDate;
		const previousStartDate: Date = defaultDates[index]?.startDate || currentDate;
		if (currentStarDate < previousStartDate && tripType === FlightTripTypes.multiple) return;

		defaultDates[index] = {
			startDate: getData.startDate,
			endDate: getData.endDate,
			startTime: getData.startTime,
			endTime: getData.endTime,
		};

		setDefaultDates(defaultDates);
	};

	const validateMinorDate = (id: string): void => {
		const firstIndex: number = 0;
		for (let index: number = 1; index < destinationsNames.length; index++) {
			const seed: string = 'StartDate';
			const id: string = destinationsNames[index].dates.hiddenName;
			const destinationId: string = `${id}${seed}`;
			const beforeDestinationId: string = `${destinationsNames[index - 1].dates.hiddenName}${seed}`;
			const destinationDate: Date = new Date(getValues([destinationId])[firstIndex]);
			const beforeDestinationDate: Date = new Date(getValues([beforeDestinationId])[firstIndex]);
			if (destinationDate <= beforeDestinationDate) {
				setError(id, { message: traductions('errorDateMinFlightMulti') });
			} else {
				clearErrors(id);
			}
		}
	};

	const getDisplayPassengersInfo = (addExtraInfo: boolean, info?: string): string => {
		const tempNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
		const extraInfo: string = addExtraInfo ? info ?? '' : '';
		const passengersDisplayValue: string = FlightsSearchFormUtil.getDisplayPassengersInfo(
			getValues(tempNames.adultsName) || '',
			getValues(tempNames.childrenName) || '',
			getValues(tempNames.infantsName) || '',
			extraInfo,
			traductions('adult') || 'adult',
			traductions('adults') || 'adults',
			traductions('child') || 'child',
			traductions('children') || 'children',
			traductions('infant') || 'infant',
			traductions('infants') || 'infants',
			isTertiaryTemplate,
		);

		return passengersDisplayValue;
	};

	const arlineHandler = useCallback((selectedOption: OptionSelect): void => {
		if (!isFilterChangeBetweenCabinAndAirline || isDisplayPassengersExtraInfo) {
			return;
		}

		const extraInfo: string = isDisplayPassengersExtraInfo ? '' : selectedOption.label;
		const tempNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
		const passengersDisplayValue: string = FlightsSearchFormUtil.getDisplayPassengersInfo(
			getValues(tempNames.adultsName) || '',
			getValues(tempNames.childrenName) || '',
			getValues(tempNames.infantsName) || '',
			extraInfo || '',
			traductions('adult') || 'adult',
			traductions('adults') || 'adults',
			traductions('child') || 'child',
			traductions('children') || 'children',
			traductions('infant') || 'infant',
			traductions('infants') || 'infants',
			isTertiaryTemplate,
		);

		setValue(tempNames.name, passengersDisplayValue || '');
	}, []);

	const cabinHandler = useCallback(
		(e: InputEventsHandler): void => {
			const tempNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
			if (e.name === tempNames.hiddenCabinName) {
				const tempNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
				const cabinOption: OptionSelect | undefined = cabinsOptions?.find((item: OptionSelect): boolean => item.value === (String(e.value) || ''));
				setCabinModalValue(cabinOption?.value || '');

				setValue(tempNames.cabinName, cabinOption?.value || '');

				if (isFilterChangeBetweenCabinAndAirline || isDisplayPassengersExtraInfo) {
					return;
				}

				const extraInfo: string = isDisplayPassengersExtraInfo ? '' : cabinOption?.label || '';
				const passengersDisplayValue: string = isMobile ? getDisplayPassengersInfo(false, extraInfo) : getDisplayPassengersInfo(true, extraInfo);
				setValue(tempNames.name, passengersDisplayValue || '');
			}
		},
		[cabinsOptions, setValue, getValues],
	);

	const passengersHandler = useCallback(
		(passengersFieldInputs: PassengersFieldDataInterface, name: string): void | string => {
			const tempNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
			const cabinOption: OptionSelect | undefined = cabinsOptions?.find((item: OptionSelect): boolean => item.value === cabinModalValue);
			const tempAirlineNames: FilterFieldInputNamesInterface = SearchFieldsUtil.getFilterInputsNames(airlineName);
			const airlineValue: string = getValues(tempAirlineNames.name);
			const extraInfo: string = isDisplayPassengersExtraInfo
				? ''
				: PassengersAndExtraInfoUtil.extraInfo(isFilterChangeBetweenCabinAndAirline, cabinOption?.label || '', airlineValue);

			const passengersDisplayValue: string = FlightsSearchFormUtil.getDisplayPassengersInfo(
				passengersFieldInputs.adults,
				passengersFieldInputs.children,
				passengersFieldInputs.infants,
				extraInfo,
				traductions('adult') || 'adult',
				traductions('adults') || 'adults',
				traductions('child') || 'child',
				traductions('children') || 'children',
				traductions('infant') || 'infant',
				traductions('infants') || 'infants',
				isTertiaryTemplate,
			);

			setValue(tempNames.cabinName, cabinOption?.value || '');

			return passengersDisplayValue;
		},
		[cabinsOptions, cabinModalValue],
	);

	const passengersModalFieldHandler = useCallback((data: PassengersFieldDataInterface, name: string): PassengersFieldDataInterface => {
		return {
			adults: data.adults,
			children: data.children > data.adults * childrenByAdults ? data.adults * childrenByAdults : data.children,
			infants: data.infants > data.adults * infantsByAdults ? data.adults * infantsByAdults : data.infants,
		};
	}, []);

	const passengersModalInputsHandler = useCallback(
		(currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string): number => {
			const tempNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
			if (inputName === tempNames.hiddenAdultsName) {
				const adultsOutOfRestriction: boolean = maxPassengers < newValue + currentData.children + currentData.infants;
				return adultsOutOfRestriction ? currentData.adults : newValue;
			}

			if (inputName === tempNames.hiddenChildrenName) {
				const childrenOutOfRestriction: boolean =
					maxPassengers < newValue + currentData.adults + currentData.infants || currentData.adults * childrenByAdults < newValue;

				return childrenOutOfRestriction ? currentData.children : newValue;
			}

			if (inputName === tempNames.hiddenInfantsName) {
				const infantsOutOfRestriction: boolean =
					maxPassengers < newValue + currentData.adults + currentData.children || currentData.adults * infantsByAdults < newValue;

				return infantsOutOfRestriction ? currentData.infants : newValue;
			}

			return newValue;
		},
		[maxPassengers],
	);

	const promoCodeHandler = useCallback((event: InputEventsHandler): void => {
		const value: string = String(event?.value);
		setPromoCode(value);
	}, []);

	const withPromoCodeHandler = useCallback((element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
		setPromoCode('');
	}, []);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const destinations: DestinationsFlightsSearchFormSubmitInterface[] = destinationsNames.map(
				(destinationNames: DestinationsNamesFlightsSearchFormInterface): DestinationsFlightsSearchFormSubmitInterface => {
					const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(destinationNames.dates.hiddenName);
					return {
						departure: {
							airport: data[destinationNames.departure.name] || '',
							airportCode: data[destinationNames.departure.hiddenName] || '',
							date: data[tempDatesNames.startDate] || '',
							countryCode: data[destinationNames.departure.original]?.countryCode || '',
						},
						arrival: {
							airport: data[destinationNames.arrival.name] || '',
							airportCode: data[destinationNames.arrival.hiddenName] || '',
							date: data[tempDatesNames.endDate] || '',
							countryCode: data[destinationNames.arrival.original]?.countryCode || '',
						},
						dates: data[destinationNames.dates.name] || '',
					};
				},
			);

			const tempAirlineNames: FilterFieldInputNamesInterface = SearchFieldsUtil.getFilterInputsNames(airlineName);
			const tempPassengersNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
			const results: FlightsSearchFormSubmitInterface = {
				destinations: [...destinations],
				adults: data[tempPassengersNames.adultsName] || 0,
				children: data[tempPassengersNames.childrenName] || 0,
				infants: data[tempPassengersNames.infantsName] || 0,
				cabin: data[tempPassengersNames.cabinName] || '',
				passengersAndExtraInfo: data[tempPassengersNames.name] || '',
				airline: data[tempAirlineNames.name] || '',
				airlineCode: data[tempAirlineNames.hiddenName] || '',
				tripType: data[tripTypeName] || '',
				onlyDirectFlight: !!data[onlyDirectFlightName],
				urlPath: undefined,
				withPromoCode: !!data[withPromoCodeName],
				promoCode,
			};

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				results?.tripType === FlightTripTypes.oneway ? DatesFieldTypes.oneDate : DatesFieldTypes.startEndDates,
				{
					date: true,
					time: false,
				},
				destinations[firstIndex].departure.date || '',
				'',
				destinations[firstIndex].arrival.date || '',
				'',
			);

			setButtonSearchHistory({
				departure: destinations[firstIndex].departure.airportCode,
				arrival: destinations[firstIndex].arrival.airportCode,
				departureDate: datesValues.largeStartDate || '',
				arrivalDate: datesValues.largeEndDate || '',
				countryCodeDeparture: destinations[firstIndex].departure.countryCode || '',
				countryCodeArrival: destinations[firstIndex].arrival.countryCode || '',
			});

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[destinationsNames, emitSubmitSearch, promoCode],
	);

	const handlePassengersEvent = async (): Promise<void> => {
		const tempAirlineNames: FilterFieldInputNamesInterface = SearchFieldsUtil.getFilterInputsNames(airlineName);
		const tempPassengersNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
		const resultName = await trigger(tempAirlineNames.name);
		const resultHiddenName = await trigger(tempAirlineNames.hiddenName);
		const resultCabinName = await trigger(tempPassengersNames.cabinName, {
			shouldFocus: true,
		});

		if (resultName && resultHiddenName && resultCabinName) {
			const delayTime: number = 500;
			setTimeout(() => {
				setShowContainer(false);

				setMobileStep(destinationsNames[firstIndex].departure.id);

				setShowTripTypeTabs(false);

				setShowMultiActions(false);
			}, delayTime);
		}
	};

	const handleDepartureStep = (destinationNames: DestinationsNamesFlightsSearchFormInterface): void => {
		setShowTripTypeTabs(false);

		setShowMultiActions(false);

		showMobileFieldsSecondaryHandler(true, destinationNames.arrival.id, destinationNames.arrival.name);
	};

	const handleArrivalStep = (destinationNames: DestinationsNamesFlightsSearchFormInterface, destinationIndex: number | undefined): void => {
		showMobileFieldsSecondaryHandler(false, destinationNames.arrival.id, destinationNames.arrival.name);

		setShowMultiActions(destinationIndex === destinationsNumber - 1);
	};

	const handleDatesStep = (destinationNames: DestinationsNamesFlightsSearchFormInterface, destinationIndex: number | undefined): void => {
		if (tripType !== FlightTripTypes.multiple || destinationIndex === destinationsNumber - 1) {
			showMobileFieldsSecondaryHandler(false, destinationNames.arrival.id, destinationNames.arrival.name);

			setShowTripTypeTabs(false);

			setShowMultiActions(false);

			return;
		}

		let nextDestinationIndex: number = destinationIndex || -1;
		if (destinationIndex === 0) {
			nextDestinationIndex = 0;
		}

		setMobileStep(destinationsNames[nextDestinationIndex + 1].departure.id);

		setShowTripTypeTabs(true);

		setShowMultiActions(false);
	};

	const handleDestinationNames = (
		destinationNames: DestinationsNamesFlightsSearchFormInterface | undefined,
		activeField: ActiveFieldDestinationNamesFlightsSearchFormInterface,
		destinationIndex: number | undefined,
	): boolean => {
		if (destinationNames && activeField.departure) {
			handleDepartureStep(destinationNames);

			return true;
		}

		if (destinationNames && activeField.arrival) {
			handleArrivalStep(destinationNames, destinationIndex);

			return true;
		}

		if (destinationNames && activeField.dates) {
			handleDatesStep(destinationNames, destinationIndex);

			return true;
		}

		return false;
	};

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (Object.keys(errors).length) {
				return;
			}

			if (event.id === passengersId) {
				void handlePassengersEvent();

				return;
			}

			const { destinationNames, destinationIndex, activeField }: FieldDestinationNamesFlightsSearchFormInterface =
				FlightsSearchFormUtil.findDestinationNamesFromId(destinationsNames, event.id);

			if (handleDestinationNames(destinationNames, activeField, destinationIndex)) {
				return;
			}

			setShowMultiActions(false);
		},
		[tripType, destinationsNumber, destinationsNames, onSubmit],
	);

	const backMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (Object.keys(errors).length) {
				return;
			}

			if (event.id === passengersId) {
				const destinationIndex: number = tripType !== FlightTripTypes.multiple ? firstIndex : destinationsNumber - 1;
				setMobileStep(destinationsNames[destinationIndex].dates.id);

				showMobileFieldsSecondaryHandler(true, destinationsNames[destinationIndex].dates.id, destinationsNames[destinationIndex].dates.name);

				setShowTripTypeTabs(false);

				setShowMultiActions(false);

				return;
			}

			const { destinationNames, destinationIndex, activeField }: FieldDestinationNamesFlightsSearchFormInterface =
				FlightsSearchFormUtil.findDestinationNamesFromId(destinationsNames, event.id);

			if (destinationNames && activeField.departure) {
				setMobileStep(destinationsNames[(destinationIndex || 1) - 1].dates.id);

				showMobileFieldsSecondaryHandler(
					false,
					destinationsNames[(destinationIndex || 1) - 1].departure.id,
					destinationsNames[(destinationIndex || 1) - 1].dates.name,
				);

				setShowTripTypeTabs(false);

				setShowMultiActions(false);

				return;
			}

			if (destinationNames && activeField.arrival) {
				showMobileFieldsSecondaryHandler(false, destinationNames.departure.id, destinationNames.departure.name);

				setShowMultiActions(false);

				return;
			}

			if (destinationNames && activeField.dates) {
				setMobileStep(destinationNames.arrival.id);

				showMobileFieldsSecondaryHandler(false, destinationNames.arrival.id, destinationNames.arrival.name);

				setShowMultiActions(false);
			}
		},
		[destinationsNumber, destinationsNames, tripType],
	);

	const airlinesMobileFieldHandler = useCallback((event: FieldButtonEventHandlerInterface): void => {
		if (event.id === passengersId) {
			setShowMobileAirlines(true);
		} else {
			setShowMobileAirlines(false);
		}
	}, []);

	const destinationFieldNumberHandler = useCallback(
		(step: number): void => {
			let newValue: number = destinationsNumber + step;
			if (newValue > destinationsNames.length || newValue < minMultiDestinationsNumber) {
				newValue = destinationsNumber;
			}

			if (newValue > destinationsNumber) {
				setShowMultiActions(false);
			}

			if (newValue < destinationsNumber) {
				setMobileStep(destinationsNames[newValue - 1].dates.id);

				setShowTripTypeTabs(false);

				setShowMultiActions(true);
			}

			setDestinationsNumber(newValue);

			for (let i = newValue; i < destinationsNames.length; i++) {
				const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(destinationsNames[i].dates.hiddenName);
				setValue(tempDatesNames.endDate, '');

				setValue(tempDatesNames.startDate, '');

				setValue(destinationsNames[i].dates.name, '');

				setValue(destinationsNames[i].departure.name, '');

				setValue(destinationsNames[i].departure.hiddenName, '');

				setValue(destinationsNames[i].departure.original, '');

				setValue(destinationsNames[i].arrival.name, '');

				setValue(destinationsNames[i].arrival.hiddenName, '');

				setValue(destinationsNames[i].arrival.original, '');
			}
		},
		[destinationsNumber, destinationsNames],
	);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-flights-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const applyAllfieldsHistory: boolean = FlightsSearchFormUtil.validatePath(history);
		initTemporalDates();

		const tempPassengersNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
		const tempAirlineNames: FilterFieldInputNamesInterface = SearchFieldsUtil.getFilterInputsNames(airlineName);
		const defaultAdults: number = 1;
		const defaultChildren: number = 0;
		const defaultInfants: number = 0;
		let counterAcceptableDestinations: number = 0;

		setMobileStep(destinationsNames[firstIndex].departure.id);

		setShowTripTypeTabs(true);

		const tempTripType: FlightTripTypes = FlightsSearchFormUtil.getTripTypeOption(history?.tripType, disabledTripType);
		setValue(tripTypeName, tempTripType);

		setTripType(tempTripType);

		setShowMultiActions(tempTripType === FlightTripTypes.multiple);

		const validateDestinations: boolean =
			(history?.destinations?.length && destinationsNames.length && history?.destinations?.length <= destinationsNames?.length) || false;

		if (applyAllfieldsHistory && validateDestinations) {
			const defaultDateList: DatePickerDefaultData[] = [];
			history?.destinations.forEach((destination: DestinationsFlightsSearchFormSubmitInterface, index: number): void => {
				const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(destinationsNames[index].dates.hiddenName);
				if (
					destination.dates &&
					destination.departure.airport &&
					destination.departure.airportCode &&
					destination.arrival.airport &&
					destination.arrival.airportCode &&
					destination.departure.date
				) {
					let departureDate: string = destination?.departure?.date || '';
					let arrivalDate: string = destination?.arrival?.date || '';
					let tempStartDate: Date = new Date(`${departureDate}T00:00:00`);
					let tempEndDate: Date = new Date(`${arrivalDate}T00:00:00`);
					let validDate: boolean = !!departureDate && !!arrivalDate;
					if (tempTripType === FlightTripTypes.round) {
						validDate = validDate && tempStartDate >= minDate && tempEndDate >= minDate && tempEndDate >= tempStartDate;
					} else {
						validDate = validDate && tempStartDate >= minDate;
					}

					if (!validDate && tempTripType !== FlightTripTypes.multiple) {
						departureDate = '';

						arrivalDate = '';

						tempStartDate = new Date('T00:00:00');

						tempEndDate = new Date('T00:00:00');
					}

					const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
						tempTripType !== FlightTripTypes.round ? DatesFieldTypes.oneDate : DatesFieldTypes.startEndDates,
						{
							date: true,
							time: false,
						},
						departureDate,
						'',
						arrivalDate,
						'',
					);

					const originalDeparture = {
						countryCode: destination.departure.countryCode,
					};

					const originalArrival = {
						countryCode: destination.arrival.countryCode,
					};

					setValue(destinationsNames[index].departure.name, destination.departure.airport);

					setValue(destinationsNames[index].departure.hiddenName, destination.departure.airportCode);

					setValue(destinationsNames[index].departure.original, originalDeparture);

					setValue(destinationsNames[index].arrival.name, destination.arrival.airport);

					setValue(destinationsNames[index].arrival.hiddenName, destination.arrival.airportCode);

					setValue(destinationsNames[index].arrival.original, originalArrival);

					setValue(tempDatesNames.startDate, departureDate);

					setValue(tempDatesNames.endDate, arrivalDate);

					setValue(destinationsNames[index].dates.name, datesValues.dates);

					defaultDateList.push(setTemporalDates(tempTripType, tempStartDate, tempEndDate));

					counterAcceptableDestinations++;
				}
			});

			setDefaultDates(defaultDateList);
		}

		let airlineOption: OptionSelect | undefined = FlightsSearchFormUtil.getAirlineOption(airlines || [], history?.airlineCode);
		if (!airlineOption) {
			airlineOption = FlightsSearchFormUtil.getAirlineOption(airlines || []);
		}

		const airlineOptionLabel: string = airlineOption ? airlineOption.label : '';
		setValue(tempAirlineNames.name, airlineOptionLabel);

		const arlineOptionValue: string = airlineOption ? airlineOption.value : '';
		setValue(tempAirlineNames.hiddenName, arlineOptionValue);

		const tempAdults: number = typeof history?.adults === 'number' && applyAllfieldsHistory ? history.adults : defaultAdults;
		const tempChildren: number = typeof history?.children === 'number' && applyAllfieldsHistory ? history.children : defaultChildren;
		const tempInfants: number = typeof history?.infants === 'number' && applyAllfieldsHistory ? history.infants : defaultInfants;
		setValue(tempPassengersNames.adultsName, tempAdults);

		setValue(tempPassengersNames.childrenName, tempChildren);

		setValue(tempPassengersNames.infantsName, tempInfants);

		let cabinOption: OptionSelect | undefined = FlightsSearchFormUtil.getCabinOption(cabinsOptions, history?.cabin);

		if (!cabinOption) {
			const firstOption: number = 0;
			cabinOption = cabinsOptions.length ? cabinsOptions[firstOption] : undefined;
		}

		const cabinOptionValue: string = cabinOption ? cabinOption.value : '';
		setValue(tempPassengersNames.cabinName, cabinOptionValue);

		const tempCabin: string = cabinOption ? cabinOption?.value : '';
		const tempCabinLabel: string = cabinOption ? cabinOption?.label : '';
		setCabinModalValue(tempCabin);

		const extraInfo: string = isDisplayPassengersExtraInfo
			? ''
			: PassengersAndExtraInfoUtil.extraInfo(isFilterChangeBetweenCabinAndAirline, tempCabinLabel, airlineOptionLabel);

		const tempPassengersDisplay: string = isMobile ? getDisplayPassengersInfo(false, extraInfo) : getDisplayPassengersInfo(true, extraInfo);
		if (hasPromoCodeActive) {
			const tempPromoCode: string = history?.promoCode ?? '';
			setValue(withPromoCodeName, !!history?.withPromoCode);

			setPromoCode(tempPromoCode);
		}

		const passengersAndExtraInfoValue: string = tempPassengersDisplay || history?.passengersAndExtraInfo || '';
		setValue(tempPassengersNames.name, passengersAndExtraInfoValue);

		const onlyDirectFlightValue: boolean = applyAllfieldsHistory && !!history?.onlyDirectFlight;
		setValue(onlyDirectFlightName, onlyDirectFlightValue);

		setDestinationsNumber(
			tempTripType !== FlightTripTypes.multiple || !counterAcceptableDestinations ? minMultiDestinationsNumber : counterAcceptableDestinations,
		);

		const departureDate: string = history?.destinations?.length ? history?.destinations[firstIndex]?.departure.date || '' : '';
		const arrivalDate: string = history?.destinations?.length ? history?.destinations[firstIndex]?.arrival.date || '' : '';
		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			tempTripType === FlightTripTypes.oneway ? DatesFieldTypes.oneDate : DatesFieldTypes.startEndDates,
			{
				date: true,
				time: false,
			},
			departureDate,
			'',
			arrivalDate,
			'',
		);

		setButtonSearchHistory(
			history?.destinations?.length && history?.destinations[firstIndex]
				? {
					departure: history.destinations[firstIndex]?.departure?.airportCode || '',
					arrival: history.destinations[firstIndex]?.arrival?.airportCode || '',
					countryCodeDeparture: history.destinations[firstIndex]?.departure?.countryCode || '',
					countryCodeArrival: history.destinations[firstIndex]?.arrival?.countryCode || '',
					departureDate: datesValues.largeStartDate || '',
					arrivalDate: datesValues.largeEndDate || '',
				  }
				: undefined,
		);
	}, [history, airlines, cabinsOptions, disabledTripType, minDate, destinationsNames, traductions]);

	return {
		destinationsNames,
		tripTypeName,
		onlyDirectFlightName,
		passengersName,
		airlineName,
		passengersId,
		airlineId,
		tripType,
		mobileFieldStep: mobileStep,
		showMobileAirlines,
		buttonSearchHistory,
		minDate,
		defaultDates,
		destinationsNumber,
		showTripTypeTabs,
		showMultiActions,
		showMobileFieldsContainer: showContainer,
		tripTypesTabs,
		tripTypesOptions,
		watchAirline,
		cabinModalValue,
		mobileFormHeight,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		showMobileFieldsSecondaryHandler,
		departureDestinationFieldHandler,
		arrivalDestinationFieldHandler,
		tripTypeSelectHandler,
		tripTypeTabsHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		airlinesMobileFieldHandler,
		destinationFieldNumberHandler,
		cabinHandler,
		arlineHandler,
		passengersHandler,
		passengersModalInputsHandler,
		passengersModalFieldHandler,
		onSubmit,
		promoCode,
		promoCodeHandler,
		withPromoCodeHandler,
		minMultiDestinationsNumber,
		withPromoCodeName,
		inputRefs,
	};
};

export default useFlightsSearchForm;
