import { DatePickerDefaultData, DatePickerLabels, DatePickerColors } from '../../../shared/interfaces/date-picker.interface';
import { InputEventsHandler, TimePickerConfig, DatePickerData } from '../../../shared/interfaces/inputs.interface';
import { DatePickerInputs, FormsInputsType } from '../../../shared/enums/inputs.enum';
import TimePicker from '../../ui-elements/time-picker/time-picker';
import { useState, useCallback, useEffect } from 'react';
import styles from './time-range-picker.module.scss';

export interface TimeRangePickerProps {
	name?: string;
	labels?: DatePickerLabels;
	startTimeConfig?: TimePickerConfig;
	endTimeConfig?: TimePickerConfig;
	defaultData?: DatePickerDefaultData;
	timePickerMaxHeight?: string;
	colors?: DatePickerColors;
	onChange?: (e: InputEventsHandler) => void;
	className?: string;
	timePickerClassName?: string;
	titleClassName?: string;
	timesContainerClassName?: string;
	isSecondaryTemplate?: boolean;
}

export const TimeRangePicker = (props: TimeRangePickerProps): JSX.Element => {
	const [time, setTime] = useState<DatePickerData>({
		startTime: props?.defaultData?.startTime,
		endTime: props?.defaultData?.endTime,
	});

	const timesHandler = useCallback(
		(event: InputEventsHandler): void => {
			let tempStartTime: string = String(time.startTime);
			let tempEndTime: string = String(time.endTime);
			switch (event.name) {
				case DatePickerInputs.startTime:
					tempStartTime = String(event.value);

					setTime((prevState: DatePickerData): DatePickerData => {
						return { ...prevState, startTime: tempStartTime };
					});

					break;
				case DatePickerInputs.endTime:
					tempEndTime = String(event.value);

					setTime((prevState: DatePickerData): DatePickerData => {
						return { ...prevState, endTime: tempEndTime };
					});

					break;
				default:
					break;
			}
			if (props.onChange) {
				const dataEvent: InputEventsHandler = {
					type: FormsInputsType.timeRangePicker,
					name: props?.name,
					value: {
						startTime: tempStartTime,
						endTime: tempEndTime,
					},
				};

				props.onChange(dataEvent);
			}
		},
		[props?.name, time],
	);

	useEffect(() => {
		if (props?.defaultData) {
			setTime({
				startTime: props?.defaultData?.startTime,
				endTime: props?.defaultData?.endTime,
			});
		}
	}, [props?.defaultData]);

	useEffect(() => {});

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`timeRangePicker flex flex-col  ${props?.isSecondaryTemplate ? '' : 'ml-4'} ${props.className || ''}`}>
				{props?.labels?.time && (
					<span
						className={`timeRangeTitle flex  items-center ${props?.isSecondaryTemplate ? 'justify-center' : 'justify-start'} ${
							props?.titleClassName || ''
						}`}
						style={{
							color: props.colors?.main,
						}}
					>
						{props?.labels?.time}
					</span>
				)}
				<div className={'timeRangePicker__body flex flex-col flex-grow'}>
					<div className={`timeRangePicker__time flex flex-row flex-grow ${props?.isSecondaryTemplate ? 'justify-around' : ''}`}>
						<TimePicker
							label={props?.labels?.startTime}
							value={time.startTime}
							name={DatePickerInputs.startTime}
							config={props.startTimeConfig}
							maxHeight={props.timePickerMaxHeight}
							className={`mr-5 ${props.timePickerClassName || ''}`}
							labelClassName={'text-xs'}
							timesContainerClassName={props?.timesContainerClassName}
							timeContainerClassName={'text-xs py-0.5'}
							style={{
								color: `${props?.isSecondaryTemplate ? '' : props.colors?.second || ''}`,
							}}
							colors={props?.colors}
							onClick={timesHandler}
						/>
						<TimePicker
							label={props?.labels?.endTime}
							value={time.endTime}
							name={DatePickerInputs.endTime}
							config={props.endTimeConfig}
							maxHeight={props.timePickerMaxHeight}
							className={`${props.timePickerClassName || ''}`}
							labelClassName={'text-xs'}
							timeContainerClassName={'text-xs py-0.5'}
							style={{
								color: `${props?.isSecondaryTemplate ? '' : props.colors?.second || ''}`,
							}}
							colors={props?.colors}
							onClick={timesHandler}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

TimeRangePicker.defaultProps = {
	className: '',
	timePickerClassName: '',
};

export default TimeRangePicker;
