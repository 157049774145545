import { SocialLinks } from '../../../../../shared/interfaces/social-links';
import { Accordion } from '../../../../ui-elements/accordion/accordion';
import { Logo } from '../../../../shared-components/logo/logo';
import { Menu } from '../../../../../shared/interfaces/menu';
import { Image } from '../../../../ui-elements/image/image';
import styles from './flights-footer-v1.module.scss';

/* eslint-disable-next-line */
export interface FlightsFooterV1Props {
	styles?: any;
	menus: Menu[];
	logoUrl: string;
	customImage?: string;
	socialNetwork: SocialLinks;
	extraText?: string;
}

export function FlightsFooterV1(props: FlightsFooterV1Props): JSX.Element {
	const extraText: string = props.extraText || '';
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div>
				<div className={'fligthsFooterV1 flex justify-center rounded-t-3xl'} style={{ ...props.styles }}>
					<div className='flex flex-col items-center p-16 px-6 md:p-16 w-full'>
						<div className={'fligthsFooterV1__container w-full flex items-center justify-center flex-wrap md:flex-nowrap'}>
							{props?.menus.map((item, index) => (
								<div key={index}>
									<Accordion
										title={item.title}
										content={item.contents}
										customClass={`w-full ${index + 1 !== props?.menus?.length ? 'md:mr-8 lg:mr-14' : ''}`}
										titleCustomClass={'text-white'}
										listCustomClass={'text-white'}
										disabledDeskTop={true}
										initOpenDeskTop={true}
									/>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className='flex flex-col justify-center items-center my-14'>
					<div className='mb-5'>
						<a href='/' className={'header__logo'}>
							<Logo url={props.logoUrl} />
						</a>
					</div>

					<div className='mb-5'>{props.customImage && <Image url={props.customImage} />}</div>

					<div className='mb-5'>
						{props.socialNetwork.whatsappLink && (
							<a href={`https://wa.me/${props.socialNetwork.whatsappLink}`} target='_blank' rel='noreferrer'>
								<span className='whatappIco mr-5'></span>
							</a>
						)}

						{props.socialNetwork.facebookLink && (
							<a href={props.socialNetwork.facebookLink} target='_blank' rel='noreferrer'>
								<span className='facebookIco mr-5'></span>
							</a>
						)}

						{props.socialNetwork.instagramLink && (
							<a href={props.socialNetwork.instagramLink} target='_blank' rel='noreferrer'>
								<span className='instagramIco mr-5'></span>
							</a>
						)}
					</div>

					<div className='max-w-5xl'>
						<p className='text-gray-500 text-sm font-family-regular' dangerouslySetInnerHTML={{ __html: extraText }}></p>
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightsFooterV1;
