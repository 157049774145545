import { DeepRequired, FieldErrorsImpl, FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import { InputType } from '../../../shared/enums';
import { ChangeEvent, useCallback } from 'react';
import styles from './age-input.module.scss';
import { Input } from '../../ui-elements';

export interface AgeInputProps {
	roomIndex: number;
	ageIndex: number;
	age: number | string;
	name: string;
	minAge: number;
	maxAge: number;
	isSecondaryTemplate: boolean;
	isTertiaryTemplate?: boolean;
	traductions: (key: string) => string | undefined;
	register: UseFormRegister<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	colorSecondary: string;
	truncate: boolean;
	onChangeAgeHandler: (event: ChangeEvent<HTMLInputElement>, roomIndex: number, ageIndex: number) => void;
	id?: string;
	traductionMinAgeRestriction?: string;
	traductionMaxAgeRestriction?: string;
	className?: string;
	isLastPosition?: boolean;
	defaultValue?: string;
	required?: boolean;
	arrowsCountainerClassName?: string;
}

const AgeInput = ({
	roomIndex,
	ageIndex,
	age,
	name,
	isSecondaryTemplate,
	isTertiaryTemplate,
	traductions,
	register,
	getValues,
	setValue,
	errors,
	colorSecondary,
	truncate,
	traductionMaxAgeRestriction,
	traductionMinAgeRestriction,
	isLastPosition,
	maxAge,
	minAge,
	className,
	defaultValue,
	required,
	arrowsCountainerClassName,
	id,
	onChangeAgeHandler,
}: AgeInputProps): JSX.Element => {
	const handleIconClick = useCallback(
		({ value, roomIndex, ageIndex, name }: { value: number; roomIndex: number; ageIndex: number; name: string }) => {
			let age = Number(getValues(name)) ?? minAge;
			age = age + value;

			if (age < minAge) {
				age = minAge;
			}

			if (age > maxAge) {
				age = maxAge;
			}

			setValue(name, age.toString());

			const newEvent: React.ChangeEvent<HTMLInputElement> = {
				target: {
					name,
					value: age.toString(),
				},
			} as any;

			onChangeAgeHandler(newEvent, roomIndex, ageIndex);
		},
		[getValues, setValue],
	);

	const ErrorMessage = (): JSX.Element => {
		const error = errors[name];
		return <>{error && <span className='error-message mr-5 text-red-600 text-sm font-normal leading-5'>{error.message}</span>}</>;
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				key={`${id || ''}_${name}`}
				className={`${className || ''} ageInput_container  ${isLastPosition && !isSecondaryTemplate ? 'mb-[15px] md:mb-0' : ''} ${
					isSecondaryTemplate
						? 'flex flex-col gap-2'
						: ageIndex
							? 'md:w-auto w-full md:min-w-1/4 mt-[15px] md:mt-[15px] md:pr-[15px] md:h-[65px]'
							: 'md:w-2/5 w-full md:pl-[15px] md:h-[65px]'
				}
					${isTertiaryTemplate ? 'flex flex-col gap-2 md:h-auto' : ''}
				`}
			>
				{(isSecondaryTemplate || isTertiaryTemplate) && (
					<span
						className={`font-medium leading-5 whitespace-nowrap
							${isTertiaryTemplate ? 'text-[13px] text-[#4D4D4F] font-medium' : 'md:text-gray-700 text-sm'}
						`}
						style={{
							color: isSecondaryTemplate || isTertiaryTemplate ? undefined : colorSecondary,
						}}
					>
						{`${traductions('ageOfChild') || 'ageOfChild'} ${ageIndex + 1}`}
					</span>
				)}
				<Input
					value={age.toString()}
					type={InputType.number}
					label={isSecondaryTemplate || isTertiaryTemplate ? '' : `${traductions('ageOfChild') || 'ageOfChild'} ${ageIndex + 1}`}
					placeholder={traductions('ageOfChild') || 'ageOfChild'}
					name={name}
					register={register(name, {
						required: {
							value: required || false,
							message: traductions('requiredField') || 'requiredField',
						},
						min: {
							value: minAge,
							message: traductionMinAgeRestriction ?? 'requiredField',
						},
						max: {
							value: maxAge,
							message: traductionMaxAgeRestriction ?? 'requiredField',
						},
						onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
							onChangeAgeHandler(event, roomIndex, ageIndex);
						},
					})}
					max={isSecondaryTemplate ? undefined : maxAge.toString()}
					min={isSecondaryTemplate ? undefined : minAge.toString()}
					step={'1'}
					className={`w-full bg-white border border-solid justify-center ${
						isSecondaryTemplate && errors[name] ? 'border-1 border-red-500 text-red-500' : ''
					}	${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 !rounded-[8px] flex' : 'py-[10px] px-[25px] rounded-[10px] flex-col'}
						${isTertiaryTemplate ? 'border-[#BEBEBE] rounded-none' : ''}
					`}
					labelClassName={'block text-[10px]'}
					inputClassName={`block w-full bg-white
						${isSecondaryTemplate ? '!p-0' : ''}
						${isTertiaryTemplate ? 'py-0 !text-[14px] !text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
					`}
					style={{
						color: isSecondaryTemplate ? undefined : colorSecondary,
						lineHeight: isTertiaryTemplate ? '1.5rem' : '',
					}}
					truncate={truncate}
					isSecondaryTemplate={isSecondaryTemplate}
					children={
						isSecondaryTemplate ? (
							<div className={`age_icons flex flex-col gap-1 justify-center items-center ${arrowsCountainerClassName || ''}`}>
								<IcomoonIcon
									onClick={() => handleIconClick({ value: 1, roomIndex, ageIndex, name })}
									icon={'chevronUpIco'}
									fontSize={5}
									className={isSecondaryTemplate && errors[name] ? ' text-red-500' : 'text-[#98A2B3]'}
								/>
								<IcomoonIcon
									onClick={() => handleIconClick({ value: -1, roomIndex, ageIndex, name })}
									icon={'chevronBottomIco'}
									fontSize={5}
									className={isSecondaryTemplate && errors[name] ? ' text-red-500' : 'text-[#98A2B3]'}
								/>
							</div>
						) : undefined
					}
				/>
				{isSecondaryTemplate && <ErrorMessage />}
			</div>
		</>
	);
};

AgeInput.defaultProps = {
	className: '',
};

export default AgeInput;
