/* eslint-disable no-useless-escape */

const RegexItem = {
	onlyLetters: /^[a-zA-Z\u00C0-\u00FF\s]*$/,
	onlyLettersWithSpecial: /^[a-zA-Z\d\u00C0-\u00FF\,\-\(\)\s]*$/,
	onlyNumber: /[^0-9]/g,
	onlyText: /[^a-zA-Z ]/g,
	onlySpaces: /\s+/g,
};

export { RegexItem };
