import Button from '../../ui-elements/button/button';
import styles from './travel-time.module.scss';
import { useState, useEffect } from 'react';
/* eslint-disable-next-line */
export interface TravelTimeProps {
	days?: (day: number) => void;
	styles?: any;
	customClass?: string;
	idAnchor?: string;
	isDisabled?: boolean;
	initDays?: number;
}

export function TravelTime(props: TravelTimeProps): JSX.Element {
	const [count, setCount] = useState(1);
	useEffect(() => {
		if (props?.initDays) {
			setCount(props?.initDays);
		}

		if (props.days) {
			props.days(props?.initDays || count);
		}
	}, []);

	const svgLoader: string = `<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>`;

	const incrementDay = (): void => {
		const maxDay: number = 10;
		if (count >= maxDay) {
			return;
		}

		setCount(count + 1);
	};

	const decrementDay = (): void => {
		const minDay: number = 1;
		if (count <= minDay) {
			return;
		}

		setCount(count - 1);
	};

	const emitDaysForSearch = (): void => {
		if (props.days) {
			props.days(count);
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div id='travelTime' className={`travelTime flex flex-col md:flex-row  md:bg-transparent ${props.customClass || ''}`}>
				<div className={'flex md:items-center md:justify-center flex-col md:flex-row travelTime__box'}>
					<div className='p-6 md:p-0'>
						<p className='text-base text-gray-600 font-family-black'>Duración del viaje</p>
						<p className='text-sm text-gray-400 font-family-regular'>¿Por cuántos días te gustaría viajar?</p>
					</div>
					<hr className={'hidden md:block bg-gray-300 mx-2 travelTime__line'} />
					<div>
						<div className='flex items-center justify-center md:justify-start'>
							<Button
								onClick={decrementDay}
								customClass={'text-4xl md:text-2xl mr-4 md:mr-2 text-gray-500 items-center'}
								styles={{
									width: '24px',
									height: '24px',
									backgroundColor: 'transparent',
									padding: '0',
								}}
								text={'-'}
							/>
							<span className='text-4xl md:text-2xl mx-1 text-gray-500 text-center w-6'>{count}</span>
							<Button
								onClick={incrementDay}
								customClass={'text-4xl md:text-2xl ml-4 md:ml-2 text-gray-500 items-center'}
								styles={{
									width: '24px',
									height: '24px',
									backgroundColor: 'transparent',
									padding: '0',
								}}
								text={'+'}
							/>
						</div>
					</div>
				</div>

				<div className={'flex items-center justify-center md:ml-6 pt-6 md:pt-0'}>
					<Button
						onClick={emitDaysForSearch}
						text={`${!props?.isDisabled ? 'Buscar' : `${svgLoader} Obteniendo resultados`}`}
						customClass={'w-full h-11 md:h-20 md:w-44 rounded-full items-center text-white bg-indigo-600 '}
						disabled={props?.isDisabled}
						styles={{ ...props.styles }}
					/>
				</div>
			</div>
		</>
	);
}

export default TravelTime;
