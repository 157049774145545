import { ElementTypes } from '../../../../../shared/enums/dynamic-forms.enum';
import { LoginInputsNames } from '../../../../../shared/enums/login.enum';
import Spinner from '../../../../ui-elements/spinner/spinner';
import Button from '../../../../ui-elements/button/button';
import Input from '../../../../ui-elements/input/input';
import { useForm } from 'react-hook-form';
import styles from './login.module.scss';

export interface LoginProps {
	loading?: boolean;
	title?: string;
	description?: string;
	buttonText: string;
	inputEmailPlaceholder: string;
	inputPasswordPlaceholder: string;
	submitForm?: (email: string, password: string) => void;
	className?: string;
	titleClassName?: string;
	descriptionClassName?: string;
	inputClassName?: string;
	buttonClassName?: string;
	inputIconClassName?: string;
	style?: any;
}

export const Login = ({
	loading,
	title,
	description,
	buttonText,
	inputEmailPlaceholder,
	inputPasswordPlaceholder,
	submitForm,
	className,
	titleClassName,
	descriptionClassName,
	inputClassName,
	buttonClassName,
	inputIconClassName,
	style,
}: LoginProps): JSX.Element => {
	const {
		register,
		handleSubmit,
		setFocus,
		formState: { errors },
	} = useForm();

	const onSubmit = handleSubmit((data: any) => {
		if (submitForm) {
			submitForm(data[ElementTypes.email], data[ElementTypes.password]);
		}
	});

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`login flex flex-col justify-center rounded-3xl ${className || ''}`} style={style}>
				<h3 className={`login__title text-center ${titleClassName || ''}`}>{title}</h3>
				<p className={`login__description text-center ${descriptionClassName || ''}`}>{description}</p>
				<form onSubmit={onSubmit} className={'flex flex-col'}>
					<Input
						type={ElementTypes.email}
						placeholder={inputEmailPlaceholder}
						icon='emailIco'
						inputClassName={`login__input text-left !p-0 ${inputClassName || ''}`}
						className={'w-full border-[1px] border-[#878787] rounded-[10px] py-[14px] px-[20px] mb-[10px] login__inputContainer'}
						iconClassName={`mr-4 ${inputIconClassName || ''}`}
						required={true}
						disabled={loading}
						name={LoginInputsNames.email}
						register={register(LoginInputsNames.email, {
							required: true,
							onChange: () => {
								if (errors[LoginInputsNames.email]) {
									const waitTime: number = 100;
									setTimeout(() => {
										setFocus(LoginInputsNames.email);
									}, waitTime);
								}
							},
						})}
					/>
					<Input
						type={ElementTypes.password}
						placeholder={inputPasswordPlaceholder}
						icon='lockIco'
						inputClassName={`login__input text-left !p-0 ${inputClassName || ''}`}
						className={'w-full border-[1px] border-[#878787] rounded-[10px] py-[14px] px-[20px] login__inputContainer'}
						iconClassName={`mr-4 ${inputIconClassName || ''}`}
						required={true}
						disabled={loading}
						name={LoginInputsNames.password}
						register={register(LoginInputsNames.password, {
							required: true,
							onChange: () => {
								if (errors[LoginInputsNames.password]) {
									const waitTime: number = 100;
									setTimeout(() => {
										setFocus(LoginInputsNames.password);
									}, waitTime);
								}
							},
						})}
					/>
					<Button
						type='submit'
						disabled={loading}
						customClass={`login__button text-center rounded-full font-bold login__spinnerContainer ${buttonClassName || ''}`}
						content={loading ? <Spinner className={'login__spinner'} /> : <span>{buttonText}</span>}
					/>
				</form>
			</div>
		</>
	);
};

Login.defaultProps = {
	className: '',
	contentClassName: '',
	titleClassName: '',
	descriptionClassName: '',
	inputClassName: '',
	buttonClassName: '',
	titleCompletionMessageClassName: '',
	descriptionCompletionMessageClassName: '',
	labelCompletionMessageClassName: '',
	buttonCompletionMessageClassName: '',
	imageClassName: '',
	inputIconClassName: '',
};

export default Login;
