import { ListInterface, ListItemInterface, OptionSelect } from '../../../../shared/interfaces/index';
import { CarUrlSegmentsPositions } from '../interfaces/car-url-segments-positions.interface';
import { DateFormat, ToolTipArrowType } from '../../../../shared/enums/index';
import { CarSearchPaymentType } from '../enums/car-search-payment-type.enum';
import ToolTip from '../../../ui-elements/tool-tip/tool-tip';
import {
	CarSearchFormSubmit,
	CarSearchTraductions,
	DisabledPaymentTypeCarsSearchFormInterface,
} from '../../shared/interfaces/car-search-form.interface';
import { formatDate } from '../../../../shared/services';

export class CarSearchUtil {
	static paymentTypeList(
		traductions: CarSearchTraductions,
		returnForList: boolean = false,
		toolTipArrowDirection: ToolTipArrowType = ToolTipArrowType.top,
		disabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface,
	): ListInterface[] | OptionSelect[] {
		const onlineLabel: string = traductions?.typePayment?.title || 'online';
		const onRentalLabel: string = traductions?.typePayment?.parentTitle || 'payOnArrival';
		if (returnForList) {
			const tempItems: ListItemInterface[] = [];
			if (!disabledPaymentType?.online) {
				const elementTemp: JSX.Element = (
					<>
						<span>{onlineLabel}</span>
						<ToolTip
							colorPrimary={'var(--carsSearchWidget-color-primary)'}
							body={`
							<p class="onlinePaymentType__toolTipText text-xs text-white m-0 w-36">
								${traductions?.onlinePaymentTypeTooltip || ''}
							</p>
							`}
							customClass='h-auto hidden'
							titleClassName='m-0'
							toolTipCustomClass={'onlinePaymentType__toolTip'}
							arrowType={toolTipArrowDirection}
							alwaysOpen={true}
						/>
					</>
				);

				tempItems.push({
					id: CarSearchPaymentType.online,
					name: elementTemp,
					link: '',
				});
			}

			if (!disabledPaymentType?.onrental) {
				tempItems.push({
					id: CarSearchPaymentType.onrental,
					name: onRentalLabel,
					link: '',
				});
			}

			return [
				{
					title: '',
					items: [...tempItems],
				},
			];
		}

		const tempOptions: OptionSelect[] = [];
		if (!disabledPaymentType?.online) {
			tempOptions.push({
				label: onlineLabel,
				value: CarSearchPaymentType.online,
			});
		}

		if (!disabledPaymentType?.onrental) {
			tempOptions.push({
				label: onRentalLabel,
				value: CarSearchPaymentType.onrental,
			});
		}

		return [...tempOptions];
	}

	static mapMobileBtn(departureIata: string, date: string, arrivalIata?: string, code?: string, noDiscountText?: string): JSX.Element {
		const hrClassName: string = 'h-[20px] w-[1px] mx-[10px] bg-gray-500';
		return (
			<div className='flex items-center'>
				{departureIata}
				<hr className={hrClassName} />
				{arrivalIata && (
					<>
						{arrivalIata}
						<hr className={hrClassName} />
					</>
				)}
				<div className={'pr-[23px] leading-none'}>{date}</div>
			</div>
		);
	}

	static getPaymentTypeOption(
		carsInSitePayment: boolean,
		value?: string,
		disabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface,
	): CarSearchPaymentType {
		let defaultPaymentType: CarSearchPaymentType | string = '';
		if (carsInSitePayment && !disabledPaymentType?.onrental) {
			defaultPaymentType = CarSearchPaymentType.onrental;
		} else if (!disabledPaymentType?.online) {
			defaultPaymentType = CarSearchPaymentType.online;
		}

		const validPaymentType: boolean = Object.values(CarSearchPaymentType).includes((value || '') as unknown as CarSearchPaymentType);
		const tempPaymentType: CarSearchPaymentType = (validPaymentType
			? value || defaultPaymentType
			: defaultPaymentType) as unknown as CarSearchPaymentType;

		return tempPaymentType;
	}

	static incrementPositions(positions: CarUrlSegmentsPositions): void {
		for (const key in positions) {
			positions[key]++;
		}
	}

	static updateCarHistoryFromRoute(
		routePath: string,
		positions: CarUrlSegmentsPositions,
		carHistory: CarSearchFormSubmit,
		isPathPaymentType?: boolean,
	): void {
		const splitRoute: string[] = routePath.split('/');
		const getParams: string = window.location.search;
		const positionToLength: number = 1;
		if (splitRoute.length >= positions.categoryPosition + positionToLength && splitRoute.length <= positions.paymentTypePosition + positionToLength) {
			const urlSearchParams: URLSearchParams = new URLSearchParams(getParams);
			const queryParams: Record<string, string> = Object.fromEntries(urlSearchParams.entries());
			CarSearchUtil.updateLocations(splitRoute, positions, carHistory);

			CarSearchUtil.updateDates(splitRoute, positions, carHistory);

			CarSearchUtil.updatePaymentAndPromotions(splitRoute, positions, carHistory, isPathPaymentType, queryParams);

			carHistory.urlPath = `${routePath}${getParams}`;

			carHistory.discount = carHistory.corporate_discount || carHistory.promotional_code;
		}
	}

	static updateLocations(splitRoute: string[], positions: CarUrlSegmentsPositions, carHistory: CarSearchFormSubmit): void {
		const destinations: string = splitRoute[positions.destinationPosition];
		const [departureIata, arrivalIata] = destinations.split('-');
		carHistory.departureLocation =
			departureIata !== carHistory?.departureLocationIata ? departureIata : carHistory?.departureLocation || departureIata;

		carHistory.departureLocationIata = departureIata;

		carHistory.arrivalLocation = arrivalIata !== carHistory?.arrivalLocationIata ? arrivalIata : carHistory?.arrivalLocation || arrivalIata;

		carHistory.arrivalLocationIata = arrivalIata;
	}

	static updatePaymentAndPromotions(
		splitRoute: string[],
		positions: CarUrlSegmentsPositions,
		carHistory: CarSearchFormSubmit,
		isPathPaymentType: boolean | undefined,
		queryParams: Record<string, string>,
	): void {
		if (isPathPaymentType) {
			carHistory.paymentType = splitRoute[positions.paymentTypePosition] || carHistory.paymentType || 'online';
		} else {
			carHistory.paymentType = queryParams.payment || carHistory.paymentType || 'online';
		}

		carHistory.corporate_discount = queryParams.corporate_discount || '';

		carHistory.promotional_code = queryParams.promotional_code || '';

		carHistory.rewards_points = queryParams.rewards_points || '';

		carHistory.rewards_points_preview = carHistory.rewards_points;
	}

	static historyHandle(carHistoryJson: CarSearchFormSubmit | null = null, isPathPaymentType?: boolean, cultureMatch?: boolean): CarSearchFormSubmit {
		const routePath: string = window?.location?.pathname;
		const carHistory: CarSearchFormSubmit = carHistoryJson || ({} as unknown as CarSearchFormSubmit);
		const positions: CarUrlSegmentsPositions = {
			destinationPosition: 3,
			departurePosition: 4,
			arrivalPosition: 5,
			categoryPosition: 6,
			paymentTypePosition: 7,
		};

		if (cultureMatch) {
			CarSearchUtil.incrementPositions(positions);
		}

		if (routePath.includes('cars')) {
			CarSearchUtil.updateCarHistoryFromRoute(routePath, positions, carHistory, isPathPaymentType);
		}

		return carHistory;
	}

	static updateDates(splitRoute: string[], positions: CarUrlSegmentsPositions, carHistory: CarSearchFormSubmit): void {
		const departureDate: string = splitRoute[positions.departurePosition];
		const arrivalDate: string = splitRoute[positions.arrivalPosition];
		const [departureDates, departureHour] = departureDate.split('_');
		carHistory.datesDepartureDate = departureDates;

		const startHourIndex: number = 0;
		const endHourIndex: number = 2;
		const [arrivalDates, arrivalHour] = arrivalDate.split('_');
		carHistory.datesDepartureHour = `${departureHour?.substring(startHourIndex, endHourIndex)}:${departureHour?.substring(
			endHourIndex,
			departureHour.length,
		)}`;

		carHistory.datesDepartureHour = carHistory.datesDepartureHour.replace('::', ':');

		carHistory.datesArrivalDate = arrivalDates;

		carHistory.datesArrivalHour = `${arrivalHour?.substring(startHourIndex, endHourIndex)}:${arrivalHour?.substring(
			endHourIndex,
			arrivalHour.length,
		)}`;

		carHistory.datesArrivalHour = carHistory.datesArrivalHour.replace('::', ':');

		const startDateFormatted: string = formatDate(`${departureDates?.toString()}T00:00:00`, DateFormat.largeDate, 'es-CO', false);
		const endDateFormatted: string = formatDate(`${arrivalDates?.toString()}T00:00:00`, DateFormat.largeDate, 'es-CO', false);
		carHistory.dates = `${startDateFormatted} - ${carHistory.datesDepartureHour} - ${endDateFormatted} - ${carHistory.datesArrivalHour}`;
	}

	static validatePath(carHistoryJson: CarSearchFormSubmit | null = null): boolean {
		const departureLocationIata: string = carHistoryJson?.departureLocationIata || '';
		const path: string = window.location.href;
		let withParams: boolean = false;

		if (path.includes(departureLocationIata)) {
			withParams = true;
		}

		return withParams;
	}

	static withPaymentTypeHistoryHandle(
		carHistoryJson: CarSearchFormSubmit | null = null,
		traductions?: (label: string) => string,
		cultureMatch: boolean = false,
	): CarSearchFormSubmit {
		return CarSearchUtil.historyHandle(carHistoryJson, true, cultureMatch);
	}

	static withOuthPaymentTypeHistoryHandle(
		carHistoryJson: CarSearchFormSubmit | null = null,
		traductions?: (label: string) => string,
		cultureMatch: boolean = false,
	): CarSearchFormSubmit {
		return CarSearchUtil.historyHandle(carHistoryJson, false, cultureMatch);
	}
}
