import {CarSearchFormSubmit} from "../../lib/cars";

export const CarHistoryJsonMock: CarSearchFormSubmit = {
    corporate_discount: 'ABC123',
    dates: '2024-07-01',
    datesArrivalDate: '2024-07-01',
    datesArrivalHour: '10:00 AM',
    datesDepartureDate: '2024-07-05',
    datesDepartureHour: '12:00 PM',
    departureLocation: 'New York',
    departureLocationIata: 'JFK',
    arrivalLocation: 'Los Angeles',
    arrivalLocationIata: 'LAX',
    discount: '10%',
    paymentType: 'Credit Card',
    promotional_code: 'SUMMER20',
    rewards_points: '1000',
    urlPath: '/search-results',
    arrivalLocationOriginal: 'Los Angeles, CA',
    departureLocationOriginal: 'New York, NY',
    rewards_points_preview: '900'
}
