import styles from './ares-button.module.scss';
export interface AresButtonProps {
	text: string;
	isButtonDisabled?: boolean;
	isSecondary?: boolean;
	className?: string;
	openForm?: () => void;
	onCloseResult?: () => void;
}

function AresButton({ text, isButtonDisabled, isSecondary, className, openForm, onCloseResult }: AresButtonProps): JSX.Element {
	const buttonStyle: string = isSecondary
		? 'border border-solid border-[#1075BB] text-[#1075BB]'
		: `bg-[#1075BB] text-white ${isButtonDisabled ? 'text-[#93C1E0]' : ''}`;

	const handleClick = (): void => {
		if (openForm) {
			openForm();
		}

		if (onCloseResult) {
			onCloseResult();
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<button
				className={`btn--container__mobile text-[14px] py-[13px] px-[15px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] font-medium ${className ?? ''} ${
					isButtonDisabled ? '' : 'hover:shadow-lg'
				} ${buttonStyle}`}
				disabled={isButtonDisabled}
				onClick={handleClick}
			>
				{text}
			</button>
		</>
	);
}

export default AresButton;
