class TableUtil {
	public chunkArray<T, U>(array: T[], chunkSize: number, innerCallBack?: (e: T) => U): (T | U)[][] {
		const results: Array<Array<U | T>> = [];
		while (array.length) {
			const chuncked: T[] = array.splice(0, chunkSize);
			if (innerCallBack) {
				const chunckedArray: U[] = chuncked.map((chunck: T) => innerCallBack(chunck));
				results.push(chunckedArray);
			} else {
				results.push(chuncked);
			}
		}
		return results;
	}
}
export { TableUtil };
