import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import Button from '../../../../ui-elements/button/button';
import Image from '../../../../ui-elements/image/image';
import styles from './error-page.module.scss';

export interface ErrorPageProps {
	title: string;
	description: string;
	codeError?: string;
	descriptionError?: string;
	className?: string;
	titleButton: string;
	redirectLink?: string;
	urlImg?: string;
	icon?: string;
	onNextClick?: () => void;
}

export function ErrorPage({
	title,
	description,
	codeError,
	descriptionError,
	className,
	titleButton,
	redirectLink,
	urlImg,
	icon,
	onNextClick,
}: ErrorPageProps): JSX.Element {
	const defaultImg = './imgs/error.png';
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`errorPage h-96 p-3 text-center  align-middle w-full ${className || ''}`}>
				<div className='errorPage__imageContainer flex justify-center'>
					{icon ? <IcomoonIcon icon={icon} /> : <Image url={urlImg || defaultImg} customClass={'text-center'} />}
				</div>
				<div>
					<h1 className={'errorPage__title pt-5 text-5xl leading-10 font-family-bold font-bold'}>{title}</h1>
					<p className={'errorPage__description pt-5 px-72 text-neutral-600 font-family-regular'}>{description}</p>
					<h2 className={'errorPage__codeError text-1xl leading-10 font-family-bold font-bold'}>
						{codeError} - {descriptionError}
					</h2>
				</div>

				<div className={'errorPage__buttonContainer flex justify-center'}>
					<Button
						onClick={() => (onNextClick ? onNextClick() : null)}
						text={titleButton}
						customClass={'p-2 font-semibold rounded-lg my-2 md:my-0 errorPage__button'}
					/>
				</div>
			</div>
		</>
	);
}
export default ErrorPage;
