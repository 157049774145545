import { ListInterface, ListItemInterface } from '../../../../../shared/interfaces/list.interface';
import styles from './destinations-field-list.module.scss';
import List from '../../../../ui-elements/list/list';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface DestinationsFieldListProps {
	destinations: ListInterface[];
	callBack?: (destintion: ListItemInterface) => void;
	showSpinner?: boolean;
	className?: string;
	ulClassName?: string;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	truncate?: boolean;
	secondaryColor?: string;
}

export function DestinationsFieldList({
	destinations,
	callBack,
	className,
	isSecondaryTemplate,
	isTertiaryTemplate,
	truncate,
	ulClassName,
	secondaryColor,
}: DestinationsFieldListProps): JSX.Element {
	const [activeOfficeId, setActiveOfficeId] = useState<string>('');
	const emitSelectedDestination = (item: ListItemInterface): void => {
		if (callBack && item) {
			setActiveOfficeId('');

			callBack(item);
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div
				className={`destinationsFieldList w-full rounded-lg flex bg-white  md:px-0 destinationsFieldList__container 
					${isSecondaryTemplate ? 'px-[16px]' : ''}
					${isTertiaryTemplate ? 'md:rounded-t-none border border-solid border-[#BEBEBE] ' : ''} 
				${className || ''}`}
				style={{ boxShadow: isTertiaryTemplate ? '0px 0px 15px 0px #0000001A' : '' }}
			>
				<div className={`flex flex-col w-full ${isSecondaryTemplate ? '' : 'md:pt-5'}`}>
					<div className={'destinationsFieldList__list'}>
						<List
							withPointer={true}
							ulClassName={ulClassName ?? ''}
							itemsClassName={`${isSecondaryTemplate ? 'md:border rounded-[8px] py-2 md:py-4' : ''}`}
							liClassName={`${isSecondaryTemplate ? 'py-2 md:px-[14px] md:py-[10px]' : 'text-xs pl-5 p-1 hover:bg-gray-200'}
								${isTertiaryTemplate ? 'py-2' : ' md:mb-1'}
							`}
							items={destinations}
							deactiveColor={'transparent'}
							activeColor={isSecondaryTemplate ? '#F9FAFB' : '#E5E7EB'}
							activeItemId={activeOfficeId}
							hoverCallBack={item => {
								// hoverExtendDestinationInfo(item);
							}}
							callBack={e => {
								emitSelectedDestination(e);
							}}
							truncate={truncate}
							isSecondaryTemplate={isSecondaryTemplate}
							isTertiaryTemplate={isTertiaryTemplate}
							secondaryColor={secondaryColor}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default DestinationsFieldList;
