import ShortProfile, { ShortProfileProps } from '../../../../shared-components/short-profile/short-profile';
export function UserInformationHeader(props: ShortProfileProps): JSX.Element {
	return (
		<header className={`w-full flex flex-row items-center justify-end py-2 px-9 ${props.className || ''}`}>
			<ShortProfile imageUrl={props.imageUrl} name={props.name} email={props.email} greetingText={props.greetingText} />
		</header>
	);
}

UserInformationHeader.defaultProps = {
	className: '',
};

export default UserInformationHeader;
