export class ImageUtil {
	public async optimizeHertzImages(urlImage: string, parentClass: string = 'body'): Promise<string> {
		return await new Promise((resolve, reject) => {
			fetch(urlImage, { mode: 'no-cors' })
				.then(async response => await response.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob);
					const image = new Image();
					const canvas: HTMLCanvasElement = document.createElement('canvas');
					canvas.className = 'canvas';

					canvas.width = 275;

					canvas.height = 95;

					canvas.style.display = 'none';

					document.body.append(canvas);

					const ctx = canvas.getContext('2d');
					image.src = url;

					image.onload = () => {
						if (ctx) {
							ctx.drawImage(
								image,
								0,
								0, // Start at 0/0 pixels from the left and the top of the image (crop),
								image.naturalWidth,
								image.naturalHeight, // "Get" a all (w * h) area from the source image (crop),
								0,
								0, // Place the result at 0, 0 in the canvas,
								canvas.width,
								canvas.height,
							);

							ctx.imageSmoothingEnabled = true;

							ctx.imageSmoothingQuality = 'high';

							const getImageData: ImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
							const imageData: Uint8ClampedArray = getImageData.data;
							let WhiteColorCount: number = 0;

							for (let i = 0; i < imageData.length; i += 4) {
								const red: number = imageData[i];
								const green: number = imageData[i + 1];
								const blue: number = imageData[i + 2];
								const alpha: number = imageData[i + 3];
								if (!(red === 0 && green === 0 && blue === 0 && alpha === 0)) {
									if (red > 240 && green > 240 && blue > 240) {
										WhiteColorCount++;
									}
								}
							}

							const umbralColor = 0.9;
							const totalPixel: number = imageData.length / 4;
							const whitePercentage: number = WhiteColorCount / totalPixel;
							const zoomIn: boolean = whitePercentage > umbralColor;
							if (zoomIn) {
								const scaleImageCarClass = 'scaleImageCar';
								const firstTimeOut: number = 100;
								setTimeout(() => {
									this.addAmpClassName(url, urlImage, scaleImageCarClass);
								}, firstTimeOut);
							} else {
								const firstTimeOut: number = 100;
								setTimeout(() => {
									this.removeZeClassName(parentClass);
								}, firstTimeOut);
							}

							setTimeout(() => {
								this.removeOpacityClassName(parentClass);
							}, 100);

							resolve(url);

							canvas.remove();
						}
					};

					image.onerror = () => {
						resolve(urlImage);
					};
				})
				.catch(error => {
					console.log(error);

					resolve(urlImage);
				});
		});
	}

	private addAmpClassName(urlBlob: string, urlOriginal: string, classNameToAdd: string): void {
		const imageElementsUrlBlob: NodeListOf<Element> = document.querySelectorAll<HTMLImageElement>(`.image img[src="${urlBlob}"]`);
		const imageElementsUrlOriginal: NodeListOf<Element> = document.querySelectorAll<HTMLImageElement>(`.image img[src="${urlOriginal}"]`);
		imageElementsUrlBlob?.forEach(imageElement => {
			const parentElement: HTMLElement | null = imageElement.parentElement;
			if (parentElement) {
				parentElement.classList.add(classNameToAdd);
			}
		});

		imageElementsUrlOriginal?.forEach(imageElement => {
			const parentElement: HTMLElement | null = imageElement.parentElement;
			if (parentElement) {
				parentElement.classList.add(classNameToAdd);
			}
		});
	}

	private removeZeClassName(parentClass: string): void {
		const parent: NodeListOf<Element> = document.querySelectorAll(parentClass);
		parent?.forEach(div => {
			const getZEImages: NodeListOf<Element> = div.querySelectorAll('.image');
			getZEImages?.forEach(image => {
				if (image.classList.contains('ZE')) {
					image.classList.remove('ZE');
				}
			});
		});
	}

	private removeOpacityClassName(parentClass: string): void {
		const parent: NodeListOf<Element> = document.querySelectorAll(parentClass);
		parent?.forEach(div => {
			const getZEImages: NodeListOf<Element> = div.querySelectorAll('.image');
			getZEImages?.forEach(image => {
				image.classList.remove('opacity-0');
			});
		});
	}
}
