import { RecommendationInterface } from '../../../../shared/interfaces/hotels/recommendation.interface';
import { AlertStates, DateFormat, DatesFieldTypes, ScrollHandlerType } from '../../../../shared/enums';
import useHotelsDisneyResumenAside from '../../shared/hooks/useHotelsDisneyResumenAside';
import { DatesField } from '../../../shared-components/search/dates-field/dates-field';
import { UseHotelsDisneyResumenAsideReturnInterface } from '../../shared/interfaces';
import { formatDate } from '../../../../shared/services/dates-managment.service';
import { numberToLocaleWithcurrency } from '../../../../shared/services';
import styles from './hotels-disney-resume-aside.module.scss';
import { ScrollHandlers } from '../../../../shared/hooks';
import Toggle from '../../../ui-elements/toggle/toggle';
import { useCallback, useState, useRef, useEffect } from 'react';
import { Button, IcomoonIcon, Image, Spinner } from '../../../ui-elements';
import { AddonsInterface, TicketSubmitInterface, TicketTypeOptionInterface } from '../../../../shared/interfaces/hotels/addons.interface';

export interface HotelsDisneyResumeAsideProps {
	hotel: RecommendationInterface;
	addons?: AddonsInterface[];
	colorPrimary?: string;
	colorSecondary?: string;
	agencyB2b?: string;
	t: (value: string) => string;
	emitDateSearch: (date: Date) => void;
	emitSubmitSearch: (data: TicketSubmitInterface) => void;
}
export function HotelsDisneyResumeAside({
	hotel,
	addons,
	colorPrimary,
	colorSecondary,
	agencyB2b,
	t,
	emitDateSearch,
	emitSubmitSearch,
}: HotelsDisneyResumeAsideProps): JSX.Element {
	const [selectDay, setSelectDay] = useState<AddonsInterface>();
	const [selectPlan, setSelectPlan] = useState<TicketTypeOptionInterface>();
	const {
		datesId,
		datesName,
		datesHiddenName,
		maxDate,
		minDate,
		defaultDates,
		errors,
		mobileFieldStep,
		showMobileFieldsContainer,
		loading,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		datesHandler,
		onSubmit,
		nextMobileFieldHandler,
		showMobileFieldsContainerHandler,
	}: UseHotelsDisneyResumenAsideReturnInterface = useHotelsDisneyResumenAside({
		hotel,
		addons,
		selectPlan,
		selectDay,
		t,
		emitDateSearch,
		emitSubmitSearch,
	});

	const [toggleDisney, setToggleDisney] = useState<boolean>(!!hotel.rooms.areDisneyTicketsRequiredToBook);
	const firstIndex: number = 0;
	const defaultImage: string = 'https://cdn-pdn.smartlinks.dev/cdn-images/sinfoto.svg';
	const moreThatTwoFares: number = 2;
	const emptyAddons: boolean = !!addons && !!addons.length;
	const hasAddons: boolean = loading || !addons;
	const hasDaySelected: boolean = loading || !selectDay;
	const hasPoints: boolean = hotel.pointValue > 0;
	const selectedHasPoints: boolean = hotel.rooms.requiredPoints ? hotel.rooms.requiredPoints.amount > 0 : false;
	const [hasMoney, setHasMoney] = useState<boolean>(false);
	const [openDetail, setOpenDetail] = useState<boolean>(false);
	const [disable, setDisable] = useState<boolean>(false);
	const fareContainerRef = useRef(null);
	const scrollHandlers: ScrollHandlers = new ScrollHandlers();
	const dayClickHandler = useCallback((data: AddonsInterface): void => {
		setSelectDay(data);

		const defaultPlanSelected = data.ticketTypeOptions.find((ticket: TicketTypeOptionInterface) => ticket.isSelected);
		if (defaultPlanSelected) {
			planClickHandler(defaultPlanSelected);
		} else {
			setSelectPlan(undefined);
		}
	}, []);

	const planClickHandler = useCallback((data: TicketTypeOptionInterface): void => {
		setSelectPlan(data);
	}, []);

	const moveScroll = (type: ScrollHandlerType): void => {
		const { current } = fareContainerRef;
		if (!current) {
			return;
		}

		scrollHandlers.ScrollManualMovement(current as unknown as HTMLDivElement, type, '.recommendation-detail__fares');
	};

	const formatText = (text: string): string => {
		let formattedText: string = text;
		const maxWordLength: number = 70;
		const minWordLength: number = 0;
		if (text.length > maxWordLength) {
			formattedText = `${text.substring(minWordLength, maxWordLength)}...`;
		}

		return formattedText;
	};

	const totalValue = (): string => {
		const firstPosition: number = 0;
		let value: number = 0;
		let formatValue: string;
		if (hasPoints && hotel.rooms.requiredAmount) {
			const roomAmount = hotel.rooms.requiredAmount.amount;
			if (selectPlan) {
				const seletedAmount: number | undefined = selectPlan?.dateTypeOptions[firstPosition]?.requiredAmount?.amount;
				if (roomAmount) {
					value = seletedAmount ? seletedAmount + roomAmount : roomAmount;

					formatValue = numberToLocaleWithcurrency(value, selectPlan.dateTypeOptions[firstPosition].ticketFare.currency);

					return '$' + ' ' + formatValue;
				}

				return '';
			}

			formatValue = numberToLocaleWithcurrency(roomAmount, hotel.rooms.rate.currency);

			return '$' + ' ' + formatValue;
		}

		if (selectPlan) {
			value = selectPlan?.dateTypeOptions[firstPosition].ticketFare.amount + hotel.rooms.rate.amount;

			return numberToLocaleWithcurrency(value, selectPlan?.dateTypeOptions[firstPosition].ticketFare.currency);
		}

		return hotel.rooms.rate.formattedAmount;
	};

	const totalPoints = (): string => {
		if (hotel.rooms.requiredPoints) {
			const roomAmount: number = hotel.rooms.requiredPoints.amount;
			let value: number = 0;
			const firstPosition: number = 0;
			if (selectPlan) {
				const seletedAmount: number | undefined = selectPlan?.dateTypeOptions[firstPosition]?.requiredPoints?.amount;
				value = seletedAmount ? seletedAmount + roomAmount : roomAmount;

				return numberToLocaleWithcurrency(value);
			}

			return numberToLocaleWithcurrency(hotel.rooms.requiredPoints.amount);
		}

		return '';
	};

	const agencyPoints = (): JSX.Element => {
		return (
			<>
				<p className={`${hasPoints && selectedHasPoints ? 'hotels-disney-resume__payment__point' : 'hidden'}`}>
					{hotel.pointsName} {totalPoints()}
				</p>
			</>
		);
	};

	const toggleDisneyTicket = (checked: boolean): void => {
		if (hotel.rooms.areDisneyTicketsRequiredToBook) {
			checked = true;
		}

		setToggleDisney(checked);
	};

	const mobileWidth: number = 768;
	const [colapsed, setColapsed] = useState<boolean>(false);
	useEffect(() => {
		if (window.screen.width > mobileWidth) {
			setColapsed(true);

			showMobileFieldsContainerHandler(true);
		}
	}, []);

	const isAgencyB2b = (): boolean => {
		const defaultValueAgency: string = 'B2B';
		if (agencyB2b === defaultValueAgency) {
			return true;
		}

		return false;
	};

	useEffect(() => {
		const addonAvailability: boolean = toggleDisney && !!hotel.rooms.hasDisneyTickets;
		const hasAddons: Boolean = addonAvailability || !!hotel.rooms.areDisneyTicketsRequiredToBook;
		const addonSelection: boolean = hasAddons && !selectPlan;
		if (addonSelection) {
			setDisable(true);
		} else {
			setDisable(false);
		}
	}, [selectPlan, toggleDisney]);

	useEffect(() => {
		if (addons?.length) {
			const addonsSelected = addons?.find(addon => addon.isSelected);
			if (addonsSelected) {
				dayClickHandler(addonsSelected);
			}
		}
	}, [addons, toggleDisney]);

	useEffect(() => {
		const firstPosition: number = 0;
		let selectPlanPoints: boolean = false;
		let selectPlanMoney: boolean = false;
		const moneyPlanPoints: boolean = hotel.rooms.requiredAmount ? hotel.rooms.requiredAmount.amount > 0 : false;
		const moneyPlanMoney: boolean = hotel.rooms.requiredAmount ? hotel.rooms.requiredAmount.amount > 0 : false;
		if (selectPlan) {
			const seletedAmount: number | undefined = selectPlan?.dateTypeOptions[firstPosition]?.requiredAmount?.amount;
			const seletedTicket: number | undefined = selectPlan?.dateTypeOptions[firstPosition]?.ticketFare.amount;
			selectPlanPoints = !!seletedAmount && hasPoints;

			selectPlanMoney = !!seletedTicket && !hasPoints;
		}

		if (selectPlanPoints || selectPlanMoney || moneyPlanPoints || moneyPlanMoney) {
			setHasMoney(true);
		} else {
			setHasMoney(false);
		}
	}, [selectPlan, addons]);

	return (
		<>
			<div className='hotels-disney-resume__container w-full'>
				<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
				<div className={'hotels-disney-resume my-4 min-h-[322px] bg-white rounded-lg bg-white shadow-lg flex flex-col px-[21px] pb-[35px]'}>
					<div className='hotels-disney-resume__card-header'>
						<h3 className='hotels-disney-resume__card-title'>
							<span className='hotels-disney-resume__card-title--number bg-[#00009A] w-[25px] h-[25px] inline-block'>{t('one')}</span>
							{hotel.name}
						</h3>
					</div>
					<section className='hotels-disney-resume__card-container flex flex-row'>
						<article className='hotels-disney-resume__card-container__photo mr-4'>
							<Image
								url={hotel.images[firstIndex] || defaultImage}
								customClass={'hotels-disney-resume__card-container__photo--img w-[450px] h-[217px]'}
							/>
						</article>
						<article className='hotels-disney-resume__card-container__description'>
							<div className='hotels-disney-resume__card-container__description--subtitle min-h-[40px]'>{hotel.rooms.description}</div>
							<div className='hotels-disney-resume__card-container__description--info'>
								<span className='font-bold text-black'>{t('passengers')}: </span>
								<p>
									<span>
										{hotel.rooms.numberOfAdults} {t('adults')}{' '}
									</span>
									<span className={`${!hotel.rooms.numberOfChildren ? 'hidden' : ''}`}>
										{hotel.rooms.numberOfChildren} {t('children')}{' '}
									</span>
								</p>
								<p className='font-bold text-black'>
									{t('checkIn')}:<span className='font-normal'> {formatDate(hotel.checkIn, DateFormat.largeDate)}</span>
								</p>
								<p className='font-bold text-black'>
									{t('checkOut')}:<span className='font-normal'> {formatDate(hotel.checkOut, DateFormat.largeDate)}</span>
								</p>
							</div>
						</article>
					</section>
				</div>
				<div
					className={`hotels-disney-resume my-4 bg-white rounded-lg bg-white shadow-lg flex flex-col px-[21px] pb-[35px] 
						${!hotel.rooms.hasDisneyTickets ? 'hidden' : ''}`}
				>
					<h1 className='font-semibold text-[30px]' style={{ color: colorSecondary }}>
						{t('startDisneyExperience')}
					</h1>
					<p className='text-[16px] mt-2'>
						{t('buyYourStandardTickets')}
						<span className='font-bold'> {t('moreOptionsToEnjoy')}</span>
					</p>
					<p className='font-semibold mt-3 text-[16px]'>{t('theMoreDaysYouChoose')}</p>
					<div className='hotels-disney-resume__card-header'>
						<h3 className={`hotels-disney-resume__card-title ${!toggleDisney ? 'relative top-[15px] active' : ''}`}>
							<span
								className={`hotels-disney-resume__card-title--number w-[25px] h-[25px] inline-block ${
									!toggleDisney ? 'bg-[#616161]' : 'bg-[#00009A]'
								}`}
							>
								{t('two')}
							</span>
							{t('addYourTicketsToPackages')}
							<div className='hotels-disney-resume-toggle'>
								<Toggle
									labelClassName='text-gray-500 mb-0'
									colorPrimary={colorSecondary}
									label={''}
									checked={hotel.rooms.areDisneyTicketsRequiredToBook}
									emitCheckedElement={({ checked }) => toggleDisneyTicket(checked)}
									readonly={hotel.rooms.areDisneyTicketsRequiredToBook}
								/>
							</div>
						</h3>
					</div>
					{toggleDisney ? (
						<div className={`hotels-disney-resume__card-container ${!toggleDisney ? 'hidden' : ''}`}>
							<h4 className='hotels-disney-resume__card-title'>{t('selectDate')}</h4>
							<div className='hotels-disney-resume__card-container__packages--date w-[258px] h-[48px]'>
								<div className={`${showMobileFieldsContainer ? 'hotels-disney-resume__card-container-date' : 'hidden'}`}>
									<DatesField
										fieldId={datesId}
										title={t('selectYourDates') || 'selectYourDates'}
										placeHolder={`${formatDate(hotel.checkIn, DateFormat.largeDate)}`}
										name={datesName}
										hiddenName={datesHiddenName}
										nextButtonTraduction={t('ready')}
										buttonText={t('ready') || 'ready'}
										type={DatesFieldTypes.oneDate}
										config={{
											date: true,
											time: false,
										}}
										minDate={minDate}
										maxDate={maxDate}
										defaultDates={defaultDates}
										useBackButton={false}
										iconClassName={'searchCalendarIco'}
										required={true}
										colorPrimary={colorPrimary}
										colorSecondary={colorSecondary}
										className={`${mobileFieldStep === datesId ? 'z-50' : ' w-full z-0'} md:z-auto`}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										watch={watch}
										errors={errors}
										onChange={datesHandler}
										nextCallBack={nextMobileFieldHandler}
										onShowDates={() => undefined}
										noOverlap={true}
									/>
								</div>
								<div className={`${!colapsed ? 'flex row items-center' : 'hidden'}`} onClick={() => showMobileFieldsContainerHandler(true)}>
									<IcomoonIcon className='relative' icon='searchCalendarIco' fontSize={26} color={colorSecondary} />
									{formatDate(hotel.checkIn, DateFormat.largeDate)}
								</div>
							</div>
							<div className='flex relative top-[30px] justify-center'>{loading ? <Spinner className={'login__spinner'} /> : ''}</div>
							<div className={`${hasAddons || emptyAddons ? 'hidden' : ''}`}>
								{AlertStates.warning && (
									<div className='hotels-disney-resume__notifications flex items-center rounded-lg bg-white shadow-lg p-3 mb-5 relative top-[20px]'>
										<span className='infoCirlce2Ico mr-3' style={{ color: colorPrimary }}></span>
										<p className='hotels-disney-resume__terms-hint text-xs'>{t('addonsAlert')}</p>
									</div>
								)}
							</div>
							<div className={`${loading ? 'hidden' : ''} hotels-disney-resume__card-container__packages flex flex-row`}>
								{addons && addons.length > moreThatTwoFares && (
									<span
										onClick={() => moveScroll(ScrollHandlerType.left)}
										className='recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute left-0 chevronLeftIco'
									></span>
								)}
								<div ref={fareContainerRef} className='recommendation-detail__scroll overflow-x-scroll lg:overflow-x-auto'>
									<div
										className={`recommendation-detail__fares ${
											selectDay && selectDay.ticketTypeOptions.length === 1 ? 'justify-center' : ''
										} flex relative`}
									>
										{addons?.map((item: AddonsInterface, index: number) => {
											return (
												<div
													key={index}
													className={`hotels-disney-resume__card-container__packages--days flex flex-col w-[80px] items-center cursor-pointer ${
														item.days === selectDay?.days ? 'active' : ''
													}`}
													onClick={() => dayClickHandler(item)}
												>
													<span className='hotels-disney-resume__card-container__packages--day w-[39px] h-[39px]'>{item.days}</span>
													{t('days')}
												</div>
											);
										})}
									</div>
								</div>
								{addons && addons.length > moreThatTwoFares && (
									<span
										onClick={() => moveScroll(ScrollHandlerType.right)}
										className=' recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute right-0  chevronRightIco'
									></span>
								)}
							</div>
							<div className={`${hasDaySelected || !emptyAddons ? 'hidden' : ''}`}>
								<h4 className='hotels-disney-resume__card-title-tickets'>{t('improveTicket')}</h4>
								<h5 className='hotels-disney-resume__card-subtitle-tickets'>{t('VisitMultipleParks')}</h5>
							</div>
							<div className={`recommendation-detail__ticketOption relative ${hasDaySelected || !emptyAddons ? 'hidden' : ''}`}>
								{selectDay && selectDay.ticketTypeOptions.length > moreThatTwoFares && (
									<span
										onClick={() => moveScroll(ScrollHandlerType.left)}
										className='recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute left-0 chevronLeftIco'
									></span>
								)}

								<div ref={fareContainerRef} className='recommendation-detail__scroll overflow-x-scroll lg:overflow-x-auto'>
									<div
										className={`recommendation-detail__fares ${
											selectDay && selectDay.ticketTypeOptions.length === 1 ? 'justify-center' : ''
										} my-4 flex relative`}
									>
										{selectDay?.ticketTypeOptions.map((item: TicketTypeOptionInterface, index: number) => (
											<div
												key={index}
												className={`hotels-disney-resume__card-container__packages--tickets w-[190px] h-[237px] ${
													item.type === selectPlan?.type ? 'active' : ''
												}`}
											>
												<div className='hotels-disney-resume__card-container__packages--tickets-name h-[70px] font-bold'>
													<span className='hotels-disney-resume__card-container__packages--tickets-entry block'>
														{t('entrance')}
														{':'}
													</span>
													<span>{item.type}</span>
												</div>
												<div className='hotels-disney-resume__card-container__packages--tickets-description min-h-[100px]'>
													<IcomoonIcon className='absolute' icon='checkRoundIco' fontSize={12} color='#FF0080' />
													<span className='hotels-disney-resume__card-container__packages--tickets-detail block' title={item.description}>
														{formatText(item.description)}
													</span>
												</div>
												<div className='hotels-disney-resume__card-container__packages--tickets-button h-[34px]'>
													<Button
														onClick={() => planClickHandler(item)}
														content={<span>{'Seleccionar'}</span>}
														customClass={'hotels-disney-resume__card-container__packages--tickets-button-select h-[34px]'}
														noHover={true}
													/>
												</div>
											</div>
										))}
									</div>
								</div>

								{selectDay && selectDay.ticketTypeOptions.length > moreThatTwoFares && (
									<span
										onClick={() => moveScroll(ScrollHandlerType.right)}
										className=' recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute right-0  chevronRightIco'
									></span>
								)}
							</div>
						</div>
					) : (
						''
					)}
				</div>
				<div className='hotels-disney__line w-full border-[#DDDDDD] md:border-[#00009A] border-b-[1px] md:border-b-2 mt-6 md:mt-0' />
				<div className='hotels-disney-resume__payment p-[10px]'>
					<p className={` ${isAgencyB2b() ? 'hotels-disney-resume__commissionNote' : 'hidden'} `}>{t('commissionNote')}</p>
					<p className='hotels-disney-resume__payment__tittle font-bold text-black'>
						{t('price')} {hotel.nights} {t('nights')} {selectPlan ? t('plusSign') : ''} {selectPlan ? t('parks') : ''}
					</p>
					{hasPoints ? agencyPoints() : ''}
					<p className={`${hasMoney ? 'hotels-disney-resume__payment__price' : 'hidden'}`}>
						{hasPoints && selectedHasPoints ? '+' : ''} {hotel.rooms.rate.currency} {totalValue()}
					</p>
					<div className='hotels-disney-resume__payment flex flex-row justify-between'>
						<div>
							<span
								className='hotels-disney-resume__payment--detail flex'
								onClick={() => {
									setOpenDetail(!openDetail);
								}}
							>
								<u>{t('reservationDetails')}</u>
								<span className={`relative left-[5px] ${!openDetail ? 'top-[5px]' : 'hotels-disney-resume__payment-icon'}`}>
									<IcomoonIcon className='absolspante' icon='chevronRightIco' fontSize={12} color='colorSecondary' />
								</span>
							</span>
							<div className={`${!openDetail ? 'hidden' : ''}`}>
								<div className='flex flex-row relative top-[10px]'>
									<p className='hotels-disney-resume__payment-packages flex flex-col h-[48px]'>
										<span>{hotel.name}</span>
										<span>
											{hotel.rooms.numberOfAdults} {t('adults')}
											<span className={`${!hotel.rooms.numberOfChildren ? 'hidden' : ''}`}>
												{' | '}
												{hotel.rooms.numberOfChildren} {t('children')}
											</span>
											{' | '}
											{hotel.nights} {t('nights')}
										</span>
									</p>
									<span className={`${hasPoints ? 'hidden' : 'hotels-disney-resume__payment__money'}`}>
										{hotel.rooms.rate.currency} {hotel.rooms.rate.formattedAmount}
									</span>
								</div>
								<div className={`flex flex-row ${!selectPlan ? 'hidden' : ''} relative top-[30px]`}>
									<p className='hotels-disney-resume__payment-packages flex flex-col h-[48px]'>
										<span>
											{}
											{selectDay?.days} {t('days')} {t('of')} {t('parks')} {t('disney')}
										</span>
										<span>
											{hotel.rooms.numberOfAdults} {t('adults')}
											<span className={`${!hotel.rooms.numberOfChildren ? 'hidden' : ''}`}>
												{' | '}
												{hotel.rooms.numberOfChildren} {t('children')}
											</span>
										</span>
									</p>
									<span className={`${hasPoints ? 'hidden' : 'hotels-disney-resume__payment__money'}`}>
										{selectPlan?.dateTypeOptions[firstIndex].ticketFare.currency} {selectPlan?.dateTypeOptions[firstIndex].ticketFare.formattedAmount}
									</span>
								</div>
							</div>
							<span className='hotels-disney-resume__payment__taxes relative top-[30px]'>{t('taxesFees')}</span>
						</div>
						<div>
							<Button
								type='submit'
								customClass={`hotelsSearchForm__searchBtn rounded-lg text-white w-[165] h-[45] text-[21px] items-center ${
									disable ? 'opacity-60' : ''
								}`}
								content={
									<>
										<span className='text-[21px] font-extrabold pl-[10px]'>{t('reserve')}</span>
									</>
								}
								styles={{
									background: colorSecondary || '#878787',
								}}
								disabled={disable}
								onClick={onSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HotelsDisneyResumeAside;
