import { CreditCardsInterface } from '../../../shared/interfaces/generics/credit-cards.interface';
import { CreditCardTypeEnum } from '../../../shared/enums/credit-card-type.enum';
import { RadioInput } from '../../../lib/ui-elements';
import { useEffect } from 'react';

export interface CreditCardsProps {
	callback?: (creditCard?: CreditCardsInterface) => void;
	creditCards: CreditCardsInterface[];
	addCreditCardText: string;
}

export function CreditCards({ callback, creditCards, addCreditCardText }: CreditCardsProps): JSX.Element {
	useEffect(() => {}, []);

	const selectedCard = (creditCard: CreditCardsInterface): void => {
		if (callback && creditCard) {
			callback(creditCard);
		}
	};

	const validateBackground = (creditCard: CreditCardsInterface): string => {
		const imageUrlMapping: Record<CreditCardTypeEnum, string> = {
			[CreditCardTypeEnum.VISA]: 'https://cdn-pdn.smartlinks.dev/cdn-images/visa-card.png',
			[CreditCardTypeEnum.MASTER_CARD]: 'https://cdn-pdn.smartlinks.dev/cdn-images/master-card.png',
			[CreditCardTypeEnum.AMEX]: 'https://cdn-pdn.smartlinks.dev/cdn-images/amex-card.png',
			[CreditCardTypeEnum.OTHER]: 'https://cdn-pdn.smartlinks.dev/cdn-images/default-card.png',
		};

		const imageUrl: string = creditCard?.image !== null ? creditCard.image?.small : imageUrlMapping[creditCard.cardType] ?? null;
		return imageUrl ? `url(${imageUrl})` : "url('')";
	};

	return (
		<form className='creditCards flex items-center flex-col'>
			{creditCards?.length ? (
				creditCards.map((creditCard, index) => (
					<div key={index} className={'creditCards__container w-full max-w-[351px]'}>
						<RadioInput
							id={index.toString()}
							label={'creditCard'}
							name={'creditCard'}
							value={index.toString()}
							className={'relative justify-center items-center'}
							inputClassName={'mt-2 absolute left-0 top-0 opacity-0'}
							labelClassName={'text-[0.80rem]'}
							parentLabelClassName={'w-full'}
							checked={creditCard.isSelected || !index}
							content={
								<div
									onClick={() => selectedCard(creditCard)}
									style={{ backgroundImage: validateBackground(creditCard) }}
									className='creditCard bg-no-repeat bg-cover bg-center bg-gray-200 cursor-pointer shadow-xl rounded-[20px] w-full h-[200px] p-[21px]'
								>
									<div className='creditCard__header flex justify-between mb-[32px]'>
										<div className='creditCard__radio rounded-full border-2 bg-transparent border-white w-[28px] h-[28px] flex items-center justify-center'>
											<span className='checkIco text-[13px] hidden'></span>
										</div>

										<div>{!!creditCard.category && <p className='creditCard__category'>{creditCard.category}</p>}</div>
									</div>

									<div className='creditCard__body mb-[24px]'>
										<p className='creditCard__serialNumber text-[28px] text-white'>{creditCard.maskedCardNumber}</p>
									</div>

									<div className='creditCard__footer flex justify-between items-center'>
										<div>
											<p className='creditCard__name text-xs text-white'>{creditCard.embossingName}</p>
											<p className='creditCard__value text-xs text-white'>
												{creditCard.balanceFormated} {creditCard.currencyName}
											</p>
										</div>

										<div>
											{!creditCard.image &&
												(creditCard.cardType !== CreditCardTypeEnum.AMEX ? (
													<img className='creditCard__franchiseLogo' src={creditCard.cardTypeImg} width={'46px'} height={'15px'} />
												) : (
													''
												))}
										</div>
									</div>
								</div>
							}
						/>
					</div>
				))
			) : (
				<div
					onClick={() => selectedCard({ new: true } as unknown as CreditCardsInterface)}
					className='creditCard creditCard__empty bg-transparent cursor-pointer rounded-[20px] w-[351px] h-[200px] p-[21px] border-dashed border-2'
				>
					<div className='creditCard__header flex justify-between mb-[32px]'>
						<div className='creditCard__radio rounded-full border-2 bg-transparent w-[28px] h-[28px] flex items-center justify-center border-dashed'></div>

						<div></div>
					</div>

					<div className='creditCard__body mb-[24px] text-center'>
						<p className='creditCard__serialNumber text-[28px]'>{addCreditCardText}</p>
						<p>**** **** **** ****</p>
					</div>
				</div>
			)}
		</form>
	);
}

export default CreditCards;
