import { numberToLocaleWithcurrency, validationNumberPhone } from '../../../../../shared/services/utils.service';
import { HorizontalDecription } from '../../../../ui-elements/horizontal-description/horizontal-description';
import { PaymentStepResultEventInterface } from '../../../../../shared/interfaces/payments/steps.interface';
import { FinalizePurchaseInterface } from '../../../../../shared/interfaces/finalize-purchase.interface';
import { Button } from '../../../../ui-elements/button/button';
import styles from './finalize-purchase.module.scss';

export interface finalizePurchaseProps {
	labels: FinalizePurchaseInterface;
	values: FinalizePurchaseInterface;
	continueText: string;
	currency: string;
	idComponent: string;
	onContinueClick?: (data: PaymentStepResultEventInterface) => void;
}

export function FinalizePurchase(props: finalizePurchaseProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='finalizePurchase flex flex-col justify-center'>
				<div className={'contFinallize'}>
					<div className={'description'}>
						<div className={'iconLine'}>
							<div></div>
						</div>
						<HorizontalDecription label={`${props.labels.reserve}:`} value={props.values.reserve} />
					</div>
					<div className={'description'}>
						<div className={'iconLine'}>
							<div></div>
						</div>
						<HorizontalDecription label={`${props.labels.productType}:`} value={props.values.productType} />
					</div>
					<div className={'description'}>
						<div className={'iconLine'}>
							<div></div>
						</div>
						<HorizontalDecription label={`${props.labels.client}:`} value={props.values.client} />
					</div>
					<div className={'description'}>
						<div className={'iconLine'}>
							<div></div>
						</div>
						<HorizontalDecription label={`${props.labels.phone}:`} value={validationNumberPhone(props.values.phone)} />
					</div>
					<div className={'description'}>
						<div className={'iconLine'}>
							<div></div>
						</div>
						<HorizontalDecription label={`${props.labels.email}:`} value={props.values.email} />
					</div>
					<div className={'description'}>
						<div className={'iconLine'}>
							<div></div>
						</div>
						<HorizontalDecription label={`${props.labels.paymentMethod}:`} value={props.values.paymentMethod} />
					</div>
					<div className={'description'}>
						<div className={'iconFinalLine'}>
							<div className={'LastLine'}></div>
						</div>
						<HorizontalDecription
							label={`${props.labels.total}:`}
							value={numberToLocaleWithcurrency(Number(props.values.total))}
							detailedDescriptionLeft={props.currency}
						/>
					</div>
				</div>
				<div className='flex flex-col md:flex-row items-center md:justify-end mt-6'>
					<Button
						onClick={() => (props?.onContinueClick ? props?.onContinueClick({ id: props.idComponent }) : null)}
						text={props.continueText}
						customClass={'w-full md:w-1/5 text-xs text-white rounded-lg my-2 md:my-0 button'}
					/>
				</div>
			</div>
		</>
	);
}
