import { useState, useEffect, useCallback } from 'react';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import styles from './accordion.module.scss';
export interface AccordionProps {
	initOpenDeskTop?: boolean;
	initOpenMobile?: boolean;
	disabledDeskTop?: boolean;
	disabled?: boolean;
	styles?: any;
	customClass?: string;
	title: string;
	content?: JSX.Element | string;
	openIcon?: string;
	closeIcon?: string;
	titleCustomClass?: string;
	listCustomClass?: string;
	className?: string;
	idComponent?: string;
	isIconInCircle?: boolean;
	onClick?: (id: string) => void;
}

export function Accordion(props: AccordionProps): JSX.Element {
	const [isActive, setIsActive] = useState<boolean>(false);
	useEffect(() => {
		const openInMobile = props.initOpenMobile && window.screen.width < 768;
		const openInDesktop = props.initOpenDeskTop && window.screen.width > 768;
		if (openInMobile || openInDesktop) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [props.initOpenMobile, props.initOpenDeskTop]);

	const validateScreen = useCallback((): void => {
		if (props?.onClick) {
			props.onClick(props?.idComponent ? props.idComponent : '');
		}

		if (props.disabledDeskTop && window.screen.width > 768 && isActive) {
			return;
		}

		setIsActive((prevState: boolean): boolean => !prevState);
	}, [props?.onClick, props.idComponent, props.disabledDeskTop]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div
				className={`accordion w-full rounded-lg mb-4 ${props.disabled ? 'opacity-40' : 'shadow-xl'} ${props.className || ''}`}
				style={{ ...props.styles }}
			>
				<div
					className={`flex flex-row items-center p-4 ${props.disabled ? 'cursor-default' : 'cursor-pointer'} ${props.titleCustomClass || ''}`}
					onClick={props.disabled ? undefined : validateScreen}
				>
					<p className={'w-full text-sm font-family-bold'}>{props.title}</p>
					{!props.openIcon && !props.closeIcon ? (
						<span className={`block text-center chevronRightIco accordion__iconArrow ${!isActive ? 'open rotate-90' : '-rotate-90'}`}></span>
					) : !isActive ? (
						<div className={`flex justify-center items-center text-lg ${props.isIconInCircle ? 'accordion__circle' : ''}`}>
							<IcomoonIcon icon={props?.openIcon ? props?.openIcon : ''} fontSize={16} />
						</div>
					) : (
						<div className={`flex justify-center items-center text-lg ${props.isIconInCircle ? 'accordion__circle' : ''}`}>
							<IcomoonIcon icon={props?.closeIcon ? props?.closeIcon : ''} />
						</div>
					)}
				</div>

				{typeof props?.content === 'string' ? (
					<div
						className={`w-full ${isActive ? 'block' : 'hidden'}  asString accordion__panel ${props.listCustomClass || ''}`}
						dangerouslySetInnerHTML={{ __html: props.content || '' }}
					></div>
				) : (
					<div className={`w-full ${isActive ? 'block' : 'hidden'} asElement accordion__panel ${props.listCustomClass || ''}`}>{props.content}</div>
				)}
			</div>
		</>
	);
}

Accordion.defaultProps = {
	titleCustomClass: '',
	listCustomClass: '',
	className: '',
};

export default Accordion;
