import { DisplayValuesDatesFieldInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { LocationKeyInterface } from '../interfaces/locationkey.interface';
import { LocationEnum } from '../enums/location.enum';
import {
	HotelsSearchFormSubmitInterface,
	RoomNameHotelsSearchFormInterface,
	FieldRoomNameHotelsSearchFormInterface,
	RoomsFieldsRestrictionsInterface,
	RoomsHotelsSearchFormSubmitInterface,
} from '../interfaces/hotels-search-form.interface';

export class HotelsSearchFormUtil {
	public static getRoomsInputsNames(
		name: string,
		roomsRestriction: RoomsFieldsRestrictionsInterface,
		forcedMaxRooms?: number,
	): RoomNameHotelsSearchFormInterface[] {
		const maxRooms: number = forcedMaxRooms || roomsRestriction.adults + roomsRestriction.children || 1;
		return Array(maxRooms)
			.fill(0)
			.map((item: number, index: number) => {
				return {
					name: `roomName${name}_${index}`,
					id: `roomFieldId${name}_${index}`,
				};
			});
	}

	public static findRoomNameFromId(roomsNames: RoomNameHotelsSearchFormInterface[], id: string): FieldRoomNameHotelsSearchFormInterface {
		let roomName: RoomNameHotelsSearchFormInterface | undefined;
		const roomIndex: number | undefined = roomsNames.findIndex((name: RoomNameHotelsSearchFormInterface) => name.id === id);
		if (typeof roomIndex === 'number') {
			roomName = { ...roomsNames[roomIndex] };
		}

		return {
			id,
			roomName,
			roomIndex,
		};
	}

	public static findRoomNameFromName(roomsNames: RoomNameHotelsSearchFormInterface[], name: string): FieldRoomNameHotelsSearchFormInterface {
		let roomName: RoomNameHotelsSearchFormInterface | undefined;
		const roomIndex: number | undefined = roomsNames.findIndex((room: RoomNameHotelsSearchFormInterface) => room.name === name);
		if (typeof roomIndex === 'number') {
			roomName = { ...roomsNames[roomIndex] };
		}

		return {
			id: '',
			roomName,
			roomIndex,
		};
	}

	static mapSearchUrl(data: HotelsSearchFormSubmitInterface): string {
		let roomsUrl: string = '1-adults_0-children';
		if (data?.rooms?.length) {
			roomsUrl = '';

			data.rooms.forEach((room: RoomsHotelsSearchFormSubmitInterface, index: number, rooms: RoomsHotelsSearchFormSubmitInterface[]): void => {
				let agesOfChildren: string = '';
				room?.childrenAges?.forEach((age: number, ageIndex: number, ages: number[]): void => {
					agesOfChildren += `${age}${ageIndex === ages.length - 1 ? '' : '-'}`;
				});

				roomsUrl += `${room?.adults || 1}-adults_${room?.children || 0}-children${agesOfChildren ? `-${agesOfChildren}` : ''}${
					index === rooms.length - 1 ? '' : '~'
				}`;
			});
		}

		const searchUrlParts: string[] = [];
		if (!data?.locationKey && data?.destinationIata) {
			searchUrlParts.push(data.destinationIata);
		}

		if (data?.departureDate) {
			searchUrlParts.push(data.departureDate);
		}

		if (data?.arrivalDate) {
			searchUrlParts.push(data.arrivalDate);
		}

		searchUrlParts.push(roomsUrl);

		let searchUrl: string = searchUrlParts.join('/');

		const queryParams: Record<string, string | undefined> = {
			nationality: data?.nationality,
			locationKey: data?.locationKey,
		};

		const queryParamsString = Object.entries(queryParams)
			.filter(([key, value]) => value)
			.map(([key, value]) => `${key}=${value ?? ''}`)
			.join('&');

		if (queryParamsString) {
			searchUrl += `?${queryParamsString}`;
		}

		return searchUrl;
	}

	public static historyHandle(
		historyJson: HotelsSearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): HotelsSearchFormSubmitInterface {
		const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
		const locationKey: string = urlParams.get(LocationEnum.key) ?? '';
		const hasLocationkey: boolean = !!locationKey;
		const destinationPosition: number = 3;
		const departurePosition: number = !hasLocationkey ? 4 : 3;
		const arrivalPosition: number = !hasLocationkey ? 5 : 4;
		const roomsPosition: number = !hasLocationkey ? 6 : 5;
		const routePath: string = window?.location?.pathname;
		const history: HotelsSearchFormSubmitInterface = (historyJson && { ...historyJson }) || ({} as unknown as HotelsSearchFormSubmitInterface);
		if (routePath.includes('hotels')) {
			const splitRoute: string[] = routePath.split('/');
			const positionToLength: number = 1;
			if (splitRoute.length >= roomsPosition + positionToLength) {
				const destinationIata = splitRoute[destinationPosition];
				if (!hasLocationkey) {
					history.destination = (destinationIata !== history?.destinationIata ? destinationIata : history?.destination || destinationIata) || '';

					history.destinationIata = destinationIata || '';
				} else {
					const cleanedBase64String: string = locationKey.replace(/\s+/g, '+');
					const urlDecodedString: string = decodeURIComponent(cleanedBase64String);
					const base64String: string = atob(urlDecodedString);
					const byteArray: Uint8Array = Uint8Array.from(base64String, c => c.charCodeAt(0));
					const decoder: TextDecoder = new TextDecoder('utf-8');
					const utf8String: string = decoder.decode(byteArray);
					const decodedLocationkey: LocationKeyInterface = JSON.parse(utf8String);
					const locationKeyString: string = locationKey?.substring(0, 5) ?? '';
					const latitude: string = decodedLocationkey?.latitude ? String(decodedLocationkey.latitude) : '';
					const longitude: string = decodedLocationkey?.longitude ? String(decodedLocationkey.longitude) : '';
					const stateString: string = decodedLocationkey?.state ? `- ${decodedLocationkey.state}` : '';
					history.destination =  `${decodedLocationkey?.city ?? ''} ${stateString}`;

					history.locationKey = locationKey;
					
					history.destinationIata =  `${locationKeyString}-${latitude}-${longitude}`;
				}

				const departureDate = splitRoute[departurePosition];
				const arrivalDate = splitRoute[arrivalPosition];
				history.departureDate = departureDate || '';

				history.arrivalDate = arrivalDate || '';

				const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
					DatesFieldTypes.startEndDates,
					{
						time: false,
						date: true,
					},
					history.departureDate,
					'',
					history.arrivalDate,
					'',
				);

				history.dates = datesValues?.dates ? datesValues.dates : '';

				const adultsAndChildrenDivision: number = 2;
				const firstIndex: number = 0;
				const adultsIndex: number = 0;
				const childrenIndex: number = 1;
				const childrenWithAges: number = 2;
				const adultsDefault: number = 1;
				const childrenDefault: number = 0;
				const ageDefault: number = 0;
				const extraElement: number = 1;
				const roomsString: string = splitRoute[roomsPosition];
				const rooms: RoomsHotelsSearchFormSubmitInterface[] = [];
				const roomsArray: string[] = roomsString.split('~');
				roomsArray.forEach((roomString: string) => {
					let adults: number = adultsDefault;
					let children: number = childrenDefault;
					const peopleArray: string[] = roomString.split('_');
					const isOkPeople: boolean =
						peopleArray?.length === adultsAndChildrenDivision &&
						peopleArray[adultsIndex].includes('adults') &&
						peopleArray[childrenIndex].includes('children');

					if (isOkPeople) {
						const adultsArray: string[] = peopleArray[adultsIndex].split('-adults');
						adults = adultsArray.length ? Number(adultsArray[firstIndex]) || adultsDefault : adultsDefault;

						const childrenArray: string[] = peopleArray[childrenIndex].split('-children');
						children = childrenArray.length ? Number(childrenArray[firstIndex]) || childrenDefault : childrenDefault;

						let childrenAges: number[] = [];
						if (childrenArray.length === childrenWithAges) {
							const childrenAgesStrings: string[] = childrenArray[childrenIndex].split('-');
							if (childrenAgesStrings.length === children + extraElement) {
								childrenAgesStrings.forEach((age: string, index: number) => {
									if (index + extraElement !== extraElement) {
										childrenAges.push(Number(age) || ageDefault);
									}
								});
							} else {
								childrenAges = [...Array(children).fill(ageDefault)];
							}
						}

						const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
							adults,
							children,
							0,
							traductions && (traductions('adult') || 'adult'),
							traductions && (traductions('adults') || 'adults'),
							traductions && (traductions('child') || 'child'),
							traductions && (traductions('children') || 'children'),
							'',
							'',
						);

						rooms.push({
							adults,
							children,
							childrenAges: [...childrenAges],
							display: passengersDisplayValue,
						});
					}
				});

				history.rooms = [...rooms];

				history.urlPath = HotelsSearchFormUtil.mapSearchUrl({ ...history });
			}
		}

		return { ...history };
	}

	static validatePath(historyJson: HotelsSearchFormSubmitInterface | null = null): boolean {
		const destinationIata: string = historyJson?.destinationIata || '';
		const path: string = window.location.href;
		let withParams: boolean = false;

		if (path.includes(destinationIata)) {
			withParams = true;
		}

		return withParams;
	}

	static validateQueryParams(urlSearch: string, keyParams: string): boolean {
		if (!urlSearch) {
			return false;
		}

		const urlParams: URLSearchParams = new URLSearchParams(urlSearch);
		const valueParams: string = urlParams.get(keyParams) ?? '';
		return !!valueParams;
	}
}
