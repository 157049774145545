import { SlideInterface } from '../../../shared/interfaces/generics/slider.interface';
import { addAlphaToColor } from '../../../shared/services';

export interface CardInformationProps extends SlideInterface {
	customClass?: string;
	contentClassName?: string;
	iconContainerClassName?: string;
	iconsClassName?: string;
	titleClassName?: string;
	subTitleClassName?: string;
	colorPrimary?: string;
}

export function CardInformation({
	customClass,
	iconClassName,
	title,
	description,
	contentClassName,
	iconContainerClassName,
	iconsClassName,
	titleClassName,
	subTitleClassName,
	colorPrimary,
}: CardInformationProps): JSX.Element {
	return (
		<div className={`cardInformation border border-solid rounded-[20px] ${customClass || ''}`}>
			<section className={`cardInformation__content flex ${contentClassName || ''}`}>
				<div
					className={`cardInformation__icon rounded-[50%] flex justify-center items-center ${iconContainerClassName || ''}`}
					style={{
						backgroundColor: colorPrimary ? addAlphaToColor(colorPrimary, 0.3) : undefined,
						color: colorPrimary,
					}}
				>
					<span className={`${iconClassName || ''}  ${iconsClassName || ''}`}></span>
				</div>
				<div>
					<h2 className={`cardInformation__title ${titleClassName || ''}`}>{title}</h2>
					<p className={`cardInformation__subtitle ${subTitleClassName || ''}`}>{description}</p>
				</div>
			</section>
		</div>
	);
}
