import { SearchFieldsNames, DatesFieldTypes, SearchDatesFieldsNames } from '../../../../shared/enums/search-fields.enum';
import { FieldValues, UseFormRegister, UseFormReturn, FieldErrorsImpl, DeepRequired } from 'react-hook-form';
import { InputEventsHandler, DatePickerData } from '../../../../shared/interfaces/inputs.interface';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { FormsInputsType, DatePickerInputs } from '../../../../shared/enums/inputs.enum';
import DateTimeRangePicker from '../../date-time-range-picker/date-time-range-picker';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { ElementTypes } from '../../../../shared/enums/dynamic-forms.enum';
import {
	DatesFieldConfigInterface,
	FieldButtonEventHandlerInterface,
	DatesFieldInputNamesInterface,
	DisplayValuesDatesFieldInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import DateRangePicker from '../../date-range-picker/date-range-picker';
import TimeRangePicker from '../../time-range-picker/time-range-picker';
import { DateFormat, DateLocales } from '../../../../shared/enums';
import Backdrop from '../../../ui-elements/backdrop/backdrop';
import { useCallback, useState, useEffect } from 'react';
import Button from '../../../ui-elements/button/button';
import DatePicker from '../../date-picker/date-picker';
import Input from '../../../ui-elements/input/input';
import styles from './dates-field.module.scss';

export interface DatesFieldProps {
	fieldId?: string;
	title?: string;
	placeHolder?: string;
	name: string;
	hiddenName: string;
	backButtonTraduction?: string;
	nextButtonTraduction?: string;
	errorRequiredFieldTraduction?: string;
	timeText?: string;
	startTimeText?: string;
	endTimeText?: string;
	buttonText?: string;
	type?: DatesFieldTypes;
	config: DatesFieldConfigInterface;
	minDate?: Date;
	maxDate?: Date;
	defaultDates?: DatePickerDefaultData;
	forceMobileStep?: SearchDatesFieldsNames;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	useBackButton?: boolean;
	iconClassName?: string;
	required?: boolean;
	colorPrimary?: string;
	colorSecondary?: string;
	colorThird?: string;
	className?: string;
	desktopDateTimeClassName?: string;
	register: UseFormRegister<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	trigger?: UseFormReturn['trigger'];
	watch?: UseFormReturn['watch'];
	nextCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	backCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	onChange?: (data: InputEventsHandler) => void;
	onShowDates?: (show: boolean) => void;
	visibleTitle?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	showSplitDates?: boolean;
	datesMessage?: string;
	cancelText?: string;
	isFixedTitle?: boolean;
	noOverlap?: boolean;
	traductions?: (label: string) => string;
	language?: DateLocales;
	dateFormat?: DateFormat;
}

export function DatesField({
	fieldId,
	title,
	placeHolder,
	name,
	hiddenName,
	backButtonTraduction,
	nextButtonTraduction,
	errorRequiredFieldTraduction,
	timeText,
	startTimeText,
	endTimeText,
	buttonText,
	type,
	config,
	minDate,
	maxDate,
	defaultDates,
	forceMobileStep,
	errors,
	useBackButton,
	iconClassName,
	required,
	colorPrimary,
	colorThird,
	cancelText,
	colorSecondary,
	className,
	desktopDateTimeClassName,
	register,
	setValue,
	getValues,
	watch,
	trigger,
	nextCallBack,
	backCallBack,
	onChange,
	onShowDates,
	visibleTitle,
	isSecondaryTemplate,
	isTertiaryTemplate,
	showSplitDates,
	datesMessage,
	isFixedTitle,
	noOverlap,
	traductions,
	language,
	dateFormat,
}: DatesFieldProps): JSX.Element {
	const [showDates, setShowDates] = useState<boolean>(false);
	const [mobileStep, setMobileStep] = useState<SearchDatesFieldsNames>();
	const [stepDates, setStepDates] = useState<DatePickerData>();
	const [splitDatesElement, setSplitDatesElement] = useState<JSX.Element | undefined>();
	const watchName: string = watch ? watch(name) : '';
	const showDatesHandler = useCallback(
		(show: boolean) => {
			if (onShowDates) {
				onShowDates(show);
			}

			setShowDates(show);
		},
		[onShowDates],
	);

	const changeHandler = useCallback(
		async (event: InputEventsHandler): Promise<void> => {
			if (getValues && setValue) {
				let getData: DatePickerData = {};
				if (event.type === FormsInputsType.dateRangePicker) {
					getData = { ...(event.value as DatePickerData) };

					if (!getData?.startTime) {
						getData.startTime = defaultDates?.startTime;
					}

					if (!getData?.endTime) {
						getData.endTime = defaultDates?.endTime;
					}
				} else if (event.type === FormsInputsType.timeRangePicker) {
					getData = { ...(event.value as DatePickerData) };

					getData.startDate = stepDates?.startDate;

					getData.endDate = stepDates?.endDate;
				} else if (event.type === FormsInputsType.datePicker) {
					getData.startDate = event.value as Date;

					if (!getData?.startTime) {
						getData.startTime = defaultDates?.startTime;
					}
				} else if (event.type === FormsInputsType.dateTimeRangePicker) {
					getData = { ...(event.value as DatePickerData) };
				}

				const tempNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(hiddenName);
				let currentStartDate: string = '';
				let currentEndDate: string = '';
				if ((type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && config.date) {
					currentStartDate = getValues(tempNames.startDate);
				}

				if (type === DatesFieldTypes.startEndDates && config.date) {
					currentEndDate = getValues(tempNames.endDate);
				}

				currentStartDate = currentStartDate ? `${currentStartDate}T00:00` : '';

				currentEndDate = currentEndDate ? `${currentEndDate}T00:00` : '';

				getData.startDate = getData?.startDate || new Date(currentStartDate);

				getData.endDate = getData?.endDate || new Date(currentEndDate);

				const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
					type || DatesFieldTypes.startEndDates,
					config,
					getData?.startDate,
					getData?.startTime,
					getData?.endDate,
					getData?.endTime,
					isTertiaryTemplate,
					language,
					dateFormat,
				);

				setValue(name, datesValues.dates);

				if ((type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && config.date) {
					setValue(tempNames.startDate, datesValues.startDate);
				} else {
					setValue(tempNames.startDate, '');
				}

				if ((type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && config.time) {
					setValue(tempNames.startTime, getData.startTime);
				} else {
					setValue(tempNames.startTime, '');
				}

				if (type === DatesFieldTypes.startEndDates && config.date) {
					setValue(tempNames.endDate, datesValues.endDate);
				} else {
					setValue(tempNames.endDate, '');
				}

				if (type === DatesFieldTypes.startEndDates && config.time) {
					setValue(tempNames.endTime, getData.endTime);
				} else {
					setValue(tempNames.endTime, '');
				}

				if (onChange) {
					onChange({ ...event, value: { ...getData } });
				}

				if (trigger) {
					await trigger(hiddenName, { shouldFocus: true });

					await trigger(name, { shouldFocus: true });

					await trigger(tempNames.startDate, { shouldFocus: true });

					await trigger(tempNames.startTime, { shouldFocus: true });

					await trigger(tempNames.endDate, { shouldFocus: true });

					await trigger(tempNames.endTime, { shouldFocus: true });
				}

				showDatesHandler(false);
			}
		},
		[name, hiddenName, defaultDates, type, config.date, config.time, stepDates, setValue, getValues, showDatesHandler, language],
	);

	const clickNextHandler = useCallback(async () => {
		if (trigger && getValues) {
			let itsOK: boolean = false;
			const tempNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(hiddenName);
			if (mobileStep === SearchDatesFieldsNames.date && config.time) {
				if (type === DatesFieldTypes.startEndDates) {
					const resultStartDate = await trigger(tempNames.startDate, { shouldFocus: true });
					const resultEndDate = await trigger(tempNames.endDate, { shouldFocus: true });
					itsOK = resultStartDate && resultEndDate;
				} else if (type === DatesFieldTypes.oneDate) {
					itsOK = await trigger(tempNames.startDate, { shouldFocus: true });
				}

				if (itsOK) {
					let currentStartDate: string = '';
					let currentEndDate: string = '';
					if (type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) {
						currentStartDate = getValues(tempNames.startDate);
					}

					if (type === DatesFieldTypes.startEndDates) {
						currentEndDate = getValues(tempNames.endDate);
					}

					currentStartDate = currentStartDate ? `${currentStartDate}T00:00` : '';

					currentEndDate = currentEndDate ? `${currentEndDate}T00:00` : '';

					setStepDates((prevState: DatePickerData | undefined): DatePickerData | undefined => ({
						startDate: currentStartDate ? new Date(currentStartDate) : prevState?.startDate ? prevState?.startDate : defaultDates?.startDate,
						startTime: prevState?.startTime ? prevState?.startTime : defaultDates?.startTime,
						endDate: currentEndDate ? new Date(currentEndDate) : prevState?.endDate ? prevState?.endDate : defaultDates?.endDate,
						endTime: prevState?.endTime ? prevState?.endTime : defaultDates?.endTime,
					}));

					setMobileStep(SearchDatesFieldsNames.time);
				}
			} else if (mobileStep === SearchDatesFieldsNames.date) {
				if (type === DatesFieldTypes.startEndDates) {
					const resultStartDate = await trigger(tempNames.startDate, { shouldFocus: true });
					const resultEndDate = await trigger(tempNames.endDate, { shouldFocus: true });
					itsOK = resultStartDate && resultEndDate;
				} else if (type === DatesFieldTypes.oneDate) {
					itsOK = await trigger(tempNames.startDate, { shouldFocus: true });
				}

				const resultName = await trigger(name, { shouldFocus: true });
				if (resultName && itsOK) {
					if (nextCallBack) {
						nextCallBack({ id: fieldId || SearchFieldsNames.dates });
					}

					setMobileStep(SearchDatesFieldsNames.date);
				}
			} else if (mobileStep === SearchDatesFieldsNames.time) {
				if (type === DatesFieldTypes.startEndDates) {
					const resultStartDate = await trigger(tempNames.startDate, { shouldFocus: true });
					const resultEndDate = await trigger(tempNames.endDate, { shouldFocus: true });
					const resultStartTime = await trigger(tempNames.startTime, { shouldFocus: true });
					const resultEndTime = await trigger(tempNames.endTime, { shouldFocus: true });
					itsOK = resultStartDate && resultEndDate && resultStartTime && resultEndTime;
				} else if (type === DatesFieldTypes.oneDate) {
					const resultStartDate = await trigger(tempNames.startDate, { shouldFocus: true });
					const resultStartTime = await trigger(tempNames.startTime, { shouldFocus: true });
					itsOK = resultStartDate && resultStartTime;
				}

				const resultName = await trigger(name, { shouldFocus: true });
				if (resultName && itsOK) {
					if (nextCallBack) {
						nextCallBack({ id: fieldId || SearchFieldsNames.dates });
					}

					setMobileStep(config.date ? SearchDatesFieldsNames.date : SearchDatesFieldsNames.time);
				}
			}
		}
	}, [name, hiddenName, fieldId, type, config.time, mobileStep, nextCallBack, trigger, getValues]);

	const clickBackHandler = useCallback(() => {
		if (mobileStep === SearchDatesFieldsNames.time && config.date) {
			setMobileStep(SearchDatesFieldsNames.date);
		} else if (mobileStep === SearchDatesFieldsNames.time) {
			setMobileStep(SearchDatesFieldsNames.time);

			if (backCallBack) {
				backCallBack({ id: fieldId || SearchFieldsNames.dates });
			}
		} else if (mobileStep === SearchDatesFieldsNames.date) {
			setMobileStep(SearchDatesFieldsNames.date);

			if (backCallBack) {
				backCallBack({ id: fieldId || SearchFieldsNames.dates });
			}
		}
	}, [fieldId, config.date, mobileStep, backCallBack]);

	useEffect(() => {
		if (config.date) {
			setMobileStep(SearchDatesFieldsNames.date);
		} else if (config.time) {
			setMobileStep(SearchDatesFieldsNames.time);
		}
	}, [config.date, config.time]);

	useEffect(() => {
		if (forceMobileStep) {
			setMobileStep(forceMobileStep);
		}
	}, [forceMobileStep]);

	useEffect(() => {
		if (showSplitDates) {
			let startDateText: string = placeHolder || 'datesPlaceHolder';
			let endDateText: string = placeHolder || 'datesPlaceHolder';
			if (watchName && getValues) {
				const tempNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(hiddenName);
				const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplaySplitDatesFormmated(
					getValues(tempNames.startDate),
					getValues(tempNames.startTime),
					getValues(tempNames.endDate),
					getValues(tempNames.endTime),
					type,
					config.date,
					config.time,
					language,
				);

				startDateText = datesValues.largeStartDate;

				endDateText = datesValues.largeEndDate;
			}

			setSplitDatesElement(
				<div className='flex flex-row items-stretch'>
					<div className='flex flex-col'>
						<span className='bg-transparent !p-0 text-xs text-[#333333]'>{title || 'title'}</span>
						<span
							className='bg-transparent !p-0 text-base'
							style={{
								color: watchName ? colorSecondary : '#C3C7CE',
							}}
						>
							{startDateText}
						</span>
					</div>
					<div className='w-[1px] mx-[23px] border-solid border-r-[1px] border-[#CCD0D6]' />
					<div className='flex flex-col'>
						<span className='bg-transparent !p-0 text-xs text-[#333333]'>{title || 'title'}</span>
						<span
							className='bg-transparent !p-0 text-base'
							style={{
								color: watchName ? colorSecondary : '#C3C7CE',
							}}
						>
							{endDateText}
						</span>
					</div>
				</div>,
			);
		}
	}, [showSplitDates, watchName, type, config, placeHolder, hiddenName, getValues, language]);

	const ErrorMessage = (): JSX.Element => {
		const error = errors[name] || errors[hiddenName];
		return (
			<>
				{error && (
					<span
						className={`error-message text-red-600 ${
							isSecondaryTemplate ? 'text-sm font-normal leading-5' : 'absolute bottom-0 left-0 ml-2 text-[10.5px] '
						}`}
					>
						{error.message}
					</span>
				)}
			</>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`datesField w-full ${
					isSecondaryTemplate ? 'secondaryTemplate' : 'bg-white'
				} md:bg-transparent absolute md:relative max-h-screen md:max-h-[none] h-screen md:h-auto left-0 top-0 flex flex-col ${className || ''}`}
			>
				<div
					className={`${isTertiaryTemplate ? '' : 'datesField__inputContainer'} flex flex-col 
						${visibleTitle ? 'flex-col' : 'md:flex-row border-0 border-b md:border border-solid'} 
						${
		isSecondaryTemplate
			? 'gap-2 md:py-0 px-[16px] md:px-0 pt-[25px] pb-[2px] md:rounded-[8px]'
			: `bg-white px-[40px] md:px-0 py-[25px] md:py-[10px] md:pr-[10px] md:rounded-[10px] ${isFixedTitle ? 'md:py-[12px]' : ''} `
		} 
						${isTertiaryTemplate ? 'md:border-[#BEBEBE] md:rounded-none md:px-0 md:w-full' : ''}
						rounded-none relative`}
					onClick={() => showDatesHandler(true)}
				>
					<div
						className={`datesField__title ${
							visibleTitle || isTertiaryTemplate ? '' : 'border-0 md:border-r md:border-solid'
						} flex flex-row items-center`}
						style={{ borderColor: colorSecondary }}
					>
						{!!iconClassName && (
							<div
								className={`datesField__ico flex items-center md:justify-center ${
									visibleTitle ? '' : isTertiaryTemplate ? 'md:w-[50px] w-[20px]' : 'min-w-[20px] w-[20px] md:min-w-[70px] md:w-[70px]'
								} pointers-none`}
							>
								{!isSecondaryTemplate && (
									<span
										className={`${iconClassName} text-[15px] 
										${isTertiaryTemplate ? '' : 'md:text-[25px]'} `}
										style={{ color: isTertiaryTemplate ? '#4D4D4F' : colorSecondary }}
									/>
								)}
							</div>
						)}
						<span
							className={`${visibleTitle ? '' : 'md:hidden'} ${
								isSecondaryTemplate ? 'pl-0 text-gray-700 text-sm font-medium leading-5' : 'pl-[10px] text-xs text-[#616161]'
							} whitespace-nowrap`}
						>
							{title || 'title'}
						</span>
					</div>

					<div
						className={`md:flex-grow relative w-full flex flex-row items-center ${
							isSecondaryTemplate ? 'pl-0' : isTertiaryTemplate ? 'pl-[30px] md:pl-0' : 'pl-[30px] md:pl-[25px]'
						}`}
					>
						<div className='datesField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
							<Input
								register={register(SearchFieldsUtil.getDatesInputsNames(hiddenName).startDate, {
									required: (type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && config.date ? required : false,
								})}
								type={ElementTypes.text}
								required={
									(type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && !isSecondaryTemplate && config.date
										? required
										: false
								}
							/>
							<Input
								register={register(SearchFieldsUtil.getDatesInputsNames(hiddenName).startTime, {
									required: (type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && config.time ? required : false,
								})}
								type={ElementTypes.text}
								required={
									(type === DatesFieldTypes.startEndDates || type === DatesFieldTypes.oneDate) && !isSecondaryTemplate && config.time
										? required
										: false
								}
							/>
							<Input
								register={register(SearchFieldsUtil.getDatesInputsNames(hiddenName).endDate, {
									required: type === DatesFieldTypes.startEndDates && config.date ? required : false,
								})}
								type={ElementTypes.text}
								required={type === DatesFieldTypes.startEndDates && config.date && !isSecondaryTemplate ? required : false}
							/>
							<Input
								register={register(SearchFieldsUtil.getDatesInputsNames(hiddenName).endTime, {
									required: type === DatesFieldTypes.startEndDates && config.time ? required : false,
								})}
								type={ElementTypes.text}
								required={type === DatesFieldTypes.startEndDates && config.time && !isSecondaryTemplate ? required : false}
							/>
						</div>
						<Input
							register={register(name, {
								required: {
									value: required ?? false,
									message: `${errorRequiredFieldTraduction || 'requiredField'}.`,
								},
							})}
							readonly={true}
							required={!isSecondaryTemplate ? required : false}
							defaultValue={''}
							isSecondaryTemplate={isSecondaryTemplate}
							iconClassName={errors[name] || errors[hiddenName] ? 'text-red-500' : ''}
							isTertiaryTemplate={isTertiaryTemplate}
							type={ElementTypes.text}
							placeholder={placeHolder || 'datesPlaceHolder'}
							className={`datesField__input ${errors[name] || errors[hiddenName] ? 'border-1 border-red-500 text-red-500' : ''} ${
								showSplitDates ? 'h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]' : ''
							} ${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'} ${
								showDates ? 'datesField__input-show' : ''
							}`}
							inputClassName={`bg-transparent !p-0 font-normal md placeholder:font-normal md:placeholder
									${isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base placeholder:text-[#C3C7CE]'}
									${isTertiaryTemplate ? 'text-base !text-[14px] text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
								`}
							icon={isSecondaryTemplate ? iconClassName : undefined}
							style={{
								color: !isTertiaryTemplate ? colorSecondary : '#4D4D4F',
							}}
						/>
						{showSplitDates && splitDatesElement}
					</div>
					<ErrorMessage />
				</div>
				{showDates && (
					<>
						<Backdrop
							show={showDates}
							onClick={() => showDatesHandler(false)}
							zIndex={noOverlap ? 0 : 10}
							backgroundColor={'transparent'}
							opacity={1}
							className='hidden md:block datesField__backdrop'
							portalBackdrop={true}
							styles={`
                  .datesField__backdrop {
                    display: none;
                  }

                  @media(min-width: 768px) {
                    .datesField__backdrop {
                      display: block;
                    }
                  }
                `}
						/>
						<div
							className={`datesField__desktopDateTime hidden md:flex w-full md:w-auto md:absolute md:z-[12] md:left-0 rounded-[10px] bg-white ${
								desktopDateTimeClassName || ''
							} ${isSecondaryTemplate ? 'md:top-[105%]' : 'md:top-[120%]'} ${isTertiaryTemplate ? 'squareDatePicker' : ''}`}
						>
							{type === DatesFieldTypes.startEndDates && config.time && (
								<>
									<DateTimeRangePicker
										className='rounded-lg'
										dateRangePickerClassName='p-6'
										rightContainerClassName={`p-6  rounded-r-lg ${isSecondaryTemplate ? 'border-l' : 'bg-gray-100'}`}
										timePickerMaxHeight={'200px'}
										minDate={minDate}
										locale={language}
										maxDate={maxDate}
										defaultData={defaultDates}
										labels={{
											time: timeText || 'time',
											startTime: startTimeText || 'startTime',
											endTime: endTimeText || 'endTime',
											button: buttonText || 'button',
											cancelText: cancelText || 'cancel',
										}}
										startTimeConfig={{
											startTime: '00:00',
											endTime: '23:30',
											stepMinutes: 30,
										}}
										endTimeConfig={{
											startTime: '00:00',
											endTime: '23:30',
											stepMinutes: 30,
										}}
										colors={{
											main: colorSecondary,
											second: isTertiaryTemplate ? '#4A4A4A' : colorPrimary,
											third: colorThird || '#7A8083',
										}}
										onClick={changeHandler}
										isSecondaryTemplate={isSecondaryTemplate}
										weekdayDisplayFormat={isSecondaryTemplate || isTertiaryTemplate ? 'iiiiii' : 'iiiii'}
										handleCancelClick={() => showDatesHandler(false)}
									/>
								</>
							)}
							{type === DatesFieldTypes.startEndDates && !config.time && (
								<>
									<DateRangePicker
										name={DatePickerInputs.dateRange}
										className={'p-6 rounded-lg'}
										dateRangeClassName='dateRanger__picker mt-0 w-full'
										defaultData={defaultDates}
										minDate={minDate}
										maxDate={maxDate}
										locale={language}
										colors={{
											main: colorSecondary,
											second: isTertiaryTemplate ? '#4A4A4A' : colorPrimary,
											third: colorThird || '#7A8083',
										}}
										useButton={true}
										buttonText={buttonText || 'button'}
										cancelText={cancelText || 'cancel'}
										onChange={changeHandler}
										datesMessage={datesMessage}
										isSecondaryTemplate={isSecondaryTemplate}
										weekdayDisplayFormat={isSecondaryTemplate || isTertiaryTemplate ? 'iiiiii' : 'iiiii'}
										handleCancelClick={() => showDatesHandler(false)}
									/>
								</>
							)}
							{type === DatesFieldTypes.oneDate && !config.time && (
								<>
									<DatePicker
										name={DatePickerInputs.datePicker}
										className={'p-6 rounded-lg'}
										dateClassName='dateRanger__picker mt-0 w-full'
										defaultData={defaultDates?.startDate}
										minDate={minDate}
										maxDate={maxDate}
										locale={language}
										colors={{
											main: colorSecondary,
											second: isTertiaryTemplate ? '#4A4A4A' : colorPrimary,
											third: colorThird || '#7A8083',
										}}
										cancelText={cancelText || 'cancel'}
										useButton={true}
										buttonText={buttonText || 'button'}
										onChange={changeHandler}
										weekdayDisplayFormat={isSecondaryTemplate || isTertiaryTemplate ? 'iiiiii' : 'iiiii'}
										handleCancelClick={() => showDatesHandler(false)}
										isSecondaryTemplate={isSecondaryTemplate}
									/>
								</>
							)}
						</div>
					</>
				)}
				<div className={'w-full md:hidden h-full flex-grow overflow-y-hidden py-[2px]'}>
					{type === DatesFieldTypes.startEndDates && (
						<>
							<div
								className={`datesField__mobileDate max-h-full h-full w-full overflow-y-auto overflow-x-hidden ${
									mobileStep === SearchDatesFieldsNames.date ? '' : 'hidden'
								}  ${isSecondaryTemplate ? 'px-[16px]' : 'px-[40px] py-[15px]'} ${isTertiaryTemplate ? 'squareDatePicker' : ''}`}
							>
								<DateRangePicker
									name={DatePickerInputs.dateRange}
									className={`${isSecondaryTemplate ? 'rounded-[8px] border p-1' : ''} flex-col w-full`}
									dateRangeClassName='dateRanger__picker mt-0 w-full'
									defaultData={defaultDates}
									minDate={minDate}
									maxDate={maxDate}
									locale={language}
									colors={{
										main: colorSecondary,
										second: isTertiaryTemplate ? '#4A4A4A' : colorPrimary,
										third: colorThird || '#7A8083',
									}}
									onChange={changeHandler}
									datesMessage={datesMessage}
									isSecondaryTemplate={isSecondaryTemplate}
									weekdayDisplayFormat={isSecondaryTemplate || isTertiaryTemplate ? 'iiiiii' : 'iiiii'}
								/>
							</div>
							<div
								className={`datesField__mobileTime max-h-full h-full px-[40px] w-full ${
									mobileStep === SearchDatesFieldsNames.time ? 'flex justify-center' : 'hidden'
								}`}
							>
								<TimeRangePicker
									key={String(mobileStep === SearchDatesFieldsNames.time)}
									name={DatePickerInputs.timeRange}
									defaultData={defaultDates}
									labels={{
										time: timeText || 'time',
										startTime: startTimeText || 'startTime',
										endTime: endTimeText || 'endTime',
										button: buttonText || 'button',
										cancelText: cancelText || 'cancel',
									}}
									timePickerMaxHeight={'515px'}
									startTimeConfig={{
										startTime: '00:00',
										endTime: '23:30',
										stepMinutes: 30,
									}}
									endTimeConfig={{
										startTime: '00:00',
										endTime: '23:30',
										stepMinutes: 30,
									}}
									colors={{
										main: colorSecondary,
										second: isTertiaryTemplate ? '#4A4A4A' : colorPrimary,
										third: colorThird || '#7A8083',
									}}
									className={'px-6 pb-6 pt-[10px] items-center m-0'}
									titleClassName='mb-4 text-base'
									timesContainerClassName={isSecondaryTemplate ? 'timePicker__custom-scrollbar' : ''}
									onChange={changeHandler}
									isSecondaryTemplate={isSecondaryTemplate}
								/>
							</div>
						</>
					)}
					{type === DatesFieldTypes.oneDate && (
						<>
							<div
								className={`datesField__mobileDate max-h-full h-full   w-full overflow-y-auto overflow-x-hidden ${
									mobileStep === SearchDatesFieldsNames.date ? '' : 'hidden'
								} ${isSecondaryTemplate ? 'rounded-[8px] px-[16px]' : 'px-[40px] py-[15px]'} ${isTertiaryTemplate ? 'squareDatePicker' : ''}`}
							>
								<DatePicker
									name={DatePickerInputs.datePicker}
									className={`${isSecondaryTemplate ? 'rounded-[8px] border p-1' : ''} flex-col w-full`}
									dateRangeClassName='dateRanger__picker mt-0 w-full'
									defaultData={defaultDates?.startDate}
									minDate={minDate}
									maxDate={maxDate}
									locale={language}
									colors={{
										main: colorSecondary,
										second: isTertiaryTemplate ? '#4A4A4A' : colorPrimary,
										third: colorThird || '#7A8083',
									}}
									onChange={changeHandler}
									weekdayDisplayFormat={isSecondaryTemplate || isTertiaryTemplate ? 'iiiiii' : 'iiiii'}
									isSecondaryTemplate={isSecondaryTemplate}
								/>
							</div>
							<div
								className={`datesField__mobileTime max-h-full h-full px-[40px] w-full ${
									mobileStep === SearchDatesFieldsNames.time ? 'flex justify-center' : 'hidden'
								}`}
							>
								{
									// TODO
								}
							</div>
						</>
					)}
				</div>
				<div className='datesField__actions w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid'>
					<Button
						onClick={clickNextHandler}
						type='button'
						customClass={`datesField__nextButton my-[15px] w-full flex items-center justify-center text-base text-white font-family-regular ${
							isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'
						} ${isTertiaryTemplate ? 'rounded-none' : ''}`}
						styles={{
							background: colorSecondary,
						}}
						text={nextButtonTraduction || 'next'}
					/>
					{useBackButton && (
						<Button
							onClick={clickBackHandler}
							type='button'
							customClass={`datesField__backButton my-0 py-0 w-full flex items-center justify-center text-base bg-transparent ${
								isSecondaryTemplate ? 'rounded-full border border-gray-300' : 'rounded-[10px]'
							} ${isTertiaryTemplate ? 'rounded-none' : ''}`}
							styles={{
								color: colorSecondary,
							}}
							text={backButtonTraduction || 'back'}
						/>
					)}
				</div>
			</div>
		</>
	);
}

DatesField.defaultProps = {
	type: DatesFieldTypes.startEndDates,
	colorSecondary: '#878787',
};

export default DatesField;
