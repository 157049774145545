import { RentalCarsLogosV2, CarGroupedCoveragesCodesV2, CarsRatesModelsV2 } from '../../../shared/enums/filter-cars-cards-v2.enum';
import CoverageCarCard, { CoverageCarCardProps } from '../../../components/cars/coverage-car-card/coverage-car-card';
import { numberToLocaleWithcurrency, addAlphaToColor } from '../../../../../shared/services/utils.service';
import { GenericMessage } from '../../../../ui-elements/generic-message/generic-message';
import { coverageTypeCamel } from '../../../shared/utils/car-coverage-types.util';
import PcoMessage from '../../../../shared-components/pco-message/pco-message';
import { ModelTypeEnum } from '../../../../../shared/enums/model-type.enum';
import { TableData, RowData } from '../../../../../shared/interfaces/table';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import {
	CarInterfaceV2,
	RentalCarInterfaceV2,
	GroupedCoveragesInterfaceV2,
	CoveragesInterfaceV2,
} from '../../../shared/interfaces/car-recommendation-v2.interface';
import { SliderType } from '../../../../../shared/enums/slider.enum';
import { useMemo, useCallback, useState, useEffect } from 'react';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import ToolTip from '../../../../ui-elements/tool-tip/tool-tip';
import useSlider from '../../../../../shared/hooks/useSlider';
import Spinner from '../../../../ui-elements/spinner/spinner';
import styles from './car-grid-recommendations.module.scss';
import Image from '../../../../ui-elements/image/image';
import Slider from '../../generics/slider/slider';

export interface CarGridRecommendationsProps extends CoverageCarCardProps {
	informationStandardMessage?: string;
	recommendations: CarInterfaceV2[];
	transitionTime?: number;
	descriptionText: string;
	soldOutText: string;
	informationCardMessage?: string;
	isActiveMultiLanguage?: boolean;
}

export function CarGridRecommendations({
	recommendations,
	transitionTime,
	descriptionText,
	dailyRateText,
	discountText,
	includeAirConditionText,
	includeLargeAirConditionText,
	passengersText,
	doorsText,
	baggageText,
	manualTransmissionText,
	automaticTransmissionText,
	rateText,
	coverageButtonText,
	soldOutText,
	colorPrimary,
	rateModel,
	disabled,
	filterCompany,
	companyImageClassName,
	className,
	onClick,
	t,
	informationCardMessage,
	informationStandardMessage,
	isWithUser,
	isShowPcoPrice,
	currencySymbol,
	culture,
	isActiveMultiLanguage,
}: CarGridRecommendationsProps): JSX.Element {
	const firstPosition: number = 1;
	const firstItem: number = 0;
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const [activeCar, setActiveCar] = useState<number>(0);
	const [gridData, setGridData] = useState<TableData>({
		head: [],
		rows: [],
	});

	const [activeCompany, setActiveCompany] = useState<string>('');
	const [activeCoverage, setActiveCoverage] = useState<string>('');
	const [clickLoading, setClickLoading] = useState<boolean>(false);
	const [activeCarData, setActiveCarData] = useState<CarInterfaceV2 | undefined>(undefined);
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const isMobile: boolean = windowWidth < responsiveSize;
	const stepSliderButton: number = isMobile ? 1 : 3;
	const slidesMargin: number = isMobile ? 10 : 20;
	const sliderSideMargin: number = isMobile ? 10 : slidesMargin / 2;
	const { position, nextPosition, previousPosition, moveToPosition, sliderRef, slidesContainerRef, slidesWidth } = useSlider(
		recommendations.length - 1,
		stepSliderButton,
		stepSliderButton,
		transitionTime,
		0,
		slidesMargin,
		sliderSideMargin,
		false,
		SliderType.noContinuous,
	);

	const detailClickHandler = useCallback(
		(data: CarInterfaceV2, company: RentalCarInterfaceV2, coverage: GroupedCoveragesInterfaceV2): void => {
			if (onClick) {
				onClick(data, company, coverage);

				setClickLoading(true);

				setActiveCoverage(coverage.rateCodeType);

				setActiveCompany(company.rentalCarCode);
			}
		},
		[onClick],
	);

	const slideClickHandler = useCallback((index: number, data: CarInterfaceV2): void => {
		setActiveCarData({ ...data });

		setActiveIndex(index);
	}, []);

	const renderTableData = useCallback(
		(index: number, data: CarInterfaceV2): void => {
			if (data) {
				const businessModel: string | ModelTypeEnum = data?.businessModel || '';
				const tempCoverages: GroupedCoveragesInterfaceV2[] = [];
				const companiesData: RowData[][] = [];
				data.rateByRentalCar.forEach((company: RentalCarInterfaceV2) => {
					company.rateByCoverage.forEach((coverage: GroupedCoveragesInterfaceV2) => {
						if (tempCoverages.findIndex((item: GroupedCoveragesInterfaceV2): boolean => item.rateCodeType === coverage.rateCodeType) === -1) {
							tempCoverages.push({ ...coverage });
						}
					});
				});

				const coverages: GroupedCoveragesInterfaceV2[] = [];
				Object.values(CarGroupedCoveragesCodesV2).forEach((value: string): void => {
					const tempCoverage: GroupedCoveragesInterfaceV2 | undefined = tempCoverages.find((element: GroupedCoveragesInterfaceV2): boolean => {
						return element.rateCodeType === value;
					});

					if (tempCoverage) {
						coverages.push({ ...tempCoverage });
					}
				});

				tempCoverages.forEach((element: GroupedCoveragesInterfaceV2) => {
					const tempCoverage: CarGroupedCoveragesCodesV2 | undefined = Object.values(CarGroupedCoveragesCodesV2).find((value: string) => {
						return element.rateCodeType === value;
					});

					if (!tempCoverage) {
						coverages.push({ ...element });
					}
				});

				data.rateByRentalCar.forEach((company: RentalCarInterfaceV2) => {
					const companyData: RowData[] = [];
					let showCompany: boolean = true;
					if (filterCompany && filterCompany !== company.rentalCarName) {
						showCompany = false;
					}

					if (showCompany) {
						companyData.push({
							value:
								company.rentalCarName && company.rentalCarName.toLocaleLowerCase() in RentalCarsLogosV2 ? (
									<div key={`${company.rentalCarName}_${data.car.model}`} className='text-[23px]'>
										{RentalCarsLogosV2[company.rentalCarName.toLocaleLowerCase()].color}
									</div>
								) : (
									<Image url={company.rentalCarImageUrl || ''} customClass={`${companyImageClassName || ''}`} key={company.rentalCarName} />
								),
							label: '',
						});

						const rateModelPoints: CarsRatesModelsV2 = data?.isAvailabilityInLocalCurrency
							? CarsRatesModelsV2.equivalentRate
							: CarsRatesModelsV2.rate;

						coverages.forEach((coverage: GroupedCoveragesInterfaceV2, index: number) => {
							const tempData: GroupedCoveragesInterfaceV2 | undefined = company.rateByCoverage.find(
								(item: GroupedCoveragesInterfaceV2): boolean => item.rateCodeType === coverage.rateCodeType,
							);

							if (tempData) {
								const applyAccumulationProductPoint: boolean = !!tempData.totalAccumulationProductPoint;
								companyData.push({
									value: (
										<div
											key={`${company.rentalCarName}_${tempData.rateCodeType}_${data.car.model}`}
											className={`${disabled ? 'carGridRecommendations__disabledCoverageCard' : 'carGridRecommendations__coverageCard'} ${
												tempData.isRecommended ? 'carGridRecommendations__recommendedcoverageCard' : ''
											} relative ${
												disabled ? '' : 'cursor-pointer'
											} rounded-[10px] w-[97%] pt-[24px] pb-[21px] flex flex-row items-center justify-center
                    `}
											style={{
												backgroundColor: tempData.isRecommended ? addAlphaToColor(colorPrimary || '', 0.32) : '#F9F9F9',
											}}
											onClick={disabled ? undefined : () => detailClickHandler(data, company, tempData)}
										>
											<div
												className={`carGridRecommendations__spinner ${
													tempData.isRecommended ? 'carGridRecommendations__recommendedSpinner' : 'carGridRecommendations__noRecommendedSpinner'
												}`}
											>
												{clickLoading &&
												disabled &&
												activeCoverage &&
												activeCoverage === tempData.rateCodeType &&
												activeCompany &&
												activeCompany === company.rentalCarCode ? (
														<Spinner
															className={
																tempData?.isRecommended ? 'carGridRecommendations__recommendedSpinner' : 'carGridRecommendations__noRecommendedSpinner'
															}
														/>
													) : (
														<>
															{tempData.isRecommended && (
																<div className='bg-[#FFF] rounded-full text-[#000] font-semibold text-[0.625rem] py-1 px-2 mb-[5px] md:mb-2 w-max'>
																	<span>{t?.('recommended') ?? 'recommended'}</span>
																</div>
															)}
															{businessModel === ModelTypeEnum.CASH && rateModel && rateModel && rateModel in tempData && (
																<>
																	<p
																		className={`carGridRecommendations__${businessModel} carGridRecommendations__totalRateText text-center ${
																			tempData.isRecommended ? 'text-black' : 'text-[#494848]'
																		} font-bold text-xs`}
																	>
																		{numberToLocaleWithcurrency(tempData[rateModel].rateFrom, tempData[rateModel].currency)}{' '}
																		<span className='text-xs'>{tempData[rateModel].currency}</span>
																	</p>
																	<p
																		className={`carGridRecommendations__${businessModel} carGridRecommendations__dailyRateText text-center font-medium ${
																			tempData.isRecommended ? 'text-black' : 'text-[#494848]'
																		} text-[10px]`}
																	>
																		{numberToLocaleWithcurrency(tempData[rateModel].dailyRate || 0, tempData[rateModel].currency)}/{dailyRateText}
																	</p>
																	{applyAccumulationProductPoint && tempData.totalAccumulationProductPoint && t && (
																		<PcoMessage points={String(tempData.totalAccumulationProductPoint)} t={t} isMatrixCard={true} />
																	)}
																</>
															)}
															{businessModel === ModelTypeEnum.POINTS && (
																<>
																	<p
																		className={`carGridRecommendations__${businessModel} carGridRecommendations__totalRateText text-center ${
																			tempData.isRecommended ? 'text-black' : 'text-[#494848]'
																		} font-bold text-xs`}
																	>
																		{numberToLocaleWithcurrency(tempData?.rate?.pointsFrom || 0)}{' '}
																		<span className='text-xs'>{tempData?.rate?.pointsCurrency}</span>
																	</p>
																</>
															)}
															{(businessModel === ModelTypeEnum.POINT_OR_MONEY ||
															(businessModel === ModelTypeEnum.POINT_PLUS_MONEY && rateModelPoints && rateModelPoints in tempData)) && (
																<>
																	{isShowPcoPrice ? (
																		<>
																			<p
																				className={`carGridRecommendations__${businessModel} carGridRecommendations__totalRateText text-center ${
																					tempData.isRecommended ? 'text-black' : 'text-[#494848]'
																				} font-bold text-xs`}
																			>
																				{numberToLocaleWithcurrency(tempData[rateModelPoints]?.chargedPoints || 0, '', culture)}{' '}
																				<span className='text-xs'>{tempData[rateModelPoints]?.pointsCurrency}</span>
																			</p>
																			<p
																				className={`carGridRecommendations__${businessModel} carGridRecommendations__dailyRateText text-center font-medium ${
																					tempData.isRecommended ? 'text-black' : 'text-[#494848]'
																				} text-[10px]`}
																			>
																			+{' '}
																				{numberToLocaleWithcurrency(
																					tempData[rateModelPoints]?.chargedAmount || 0,
																					tempData[rateModelPoints]?.currency || '',
																					'',
																					currencySymbol,
																				)}
																			</p>
																		</>
																	) : (
																		<>
																			<p
																				className={`carGridRecommendations__${businessModel} carGridRecommendations__totalRateText text-center ${
																					tempData.isRecommended ? 'text-black' : 'text-[#494848]'
																				} font-bold text-xs`}
																			>
																				{tempData[rateModelPoints]?.pointsFrom ? (
																					<>
																						{numberToLocaleWithcurrency(tempData[rateModelPoints]?.pointsFrom || 0)}{' '}
																						<span className='text-xs'>{tempData[rateModelPoints]?.pointsCurrency}</span>
																					</>
																				) : (
																					<>
																						{numberToLocaleWithcurrency(
																							tempData[rateModelPoints]?.rateFrom || 0,
																							tempData[rateModelPoints]?.currency || '',
																						)}{' '}
																						<span className='text-xs'>{tempData[rateModelPoints]?.currency}</span>
																					</>
																				)}
																			</p>
																			{!!tempData[rateModelPoints]?.pointsFrom && !!tempData[rateModelPoints]?.rateFrom && (
																				<p
																					className={`carGridRecommendations__${businessModel} carGridRecommendations__dailyRateText text-center font-medium ${
																						tempData.isRecommended ? 'text-black' : 'text-[#494848]'
																					} text-[10px]`}
																				>
																				+ {tempData[rateModelPoints]?.currency}{' '}
																					{numberToLocaleWithcurrency(
																						tempData[rateModelPoints]?.rateFrom || 0,
																						tempData[rateModelPoints]?.currency || '',
																					)}
																				</p>
																			)}
																		</>
																	)}
																</>
															)}
														</>
													)}
											</div>
										</div>
									),
									label: tempData.rateTypeLabel,
								});
							} else {
								companyData.push({
									value: (
										<div
											key={`${company.rentalCarName}_${index}_${data.car.model}`}
											className='relative rounded-[10px] w-[97%] pt-[24px] pb-[21px] flex flex-row items-center justify-center'
											style={{
												backgroundColor: '#F9F9F9',
											}}
										>
											<p className={'carGridRecommendations__soldOutText text-center font-bold text-[#7A8083] text-xs'}>{soldOutText}</p>
										</div>
									),
									label: coverage.rateTypeLabel,
								});
							}
						});

						companiesData.push([...companyData]);
					}
				});

				setGridData({
					head: [
						{ value: '', label: '' },
						...coverages.map((item: GroupedCoveragesInterfaceV2): RowData => {
							let htmlCoverages: string = '';
							item.coverages.forEach((itemCoverage: CoveragesInterfaceV2, index: number): void => {
								htmlCoverages += `
              <li class="carGridRecommendations__coverageItemText text-left font-normal text-[10px] md:text-xs flex flex-row items-start mt-[5px] md:mt-0">
                <span
                  class="${
	itemCoverage.included ? 'checkRoundIco' : 'unCheckRoundIco'
} text-white mr-1 text-[8px] flex flex-row justify-center items-center"
                ></span>
                <p class="flex-grow">${t ? t(itemCoverage.tag || itemCoverage.text) : ''}</p>
              </li>
              `;
							});

							const titleComponent: JSX.Element = (
								<span>{isActiveMultiLanguage && t ? t(coverageTypeCamel(item?.rateCodeType)) : item.rateTypeLabel || ''}</span>
							);

							return {
								value: (
									<div
										className='flex flex-row justify-start md:justify-center items-start pt-0 md:pt-4 pb-5 md:pb-4 pl-4 md:pl-0'
										key={`${item.rateCodeType}_${data.car.model}`}
									>
										<span className='carGridRecommendations__headerText text-xs font-medium'>{titleComponent || ''}</span>
										<ToolTip
											title={
												'<span class="carGridRecommendations__headerText infoCirlce2Ico text-xs font-medium ml-1.5" style="color: black"></span>'
											}
											colorPrimary={'black'}
											body={`
                  <ul class='list-none flex-grow'> ${htmlCoverages} </ul>
                  `}
											toolTipCustomClass={'carGridRecommendations__headerToolTip'}
										/>
									</div>
								),
								label: item.rateTypeLabel,
							};
						}),
					],
					rows: [...companiesData],
				});
			} else {
				setGridData({
					head: [],
					rows: [],
				});
			}

			setActiveCar(index);
		},
		[rateModel, disabled, filterCompany, t],
	);

	const customContentSlides = useMemo(
		() =>
			((tempData: CarInterfaceV2[], activeIndex: number, disabledSlide?: boolean): JSX.Element[] => {
				if (tempData && tempData.length) {
					return [
						...tempData.map((car: CarInterfaceV2, index: number) => {
							return (
								<CoverageCarCard
									key={`${car.car.model}_${car.car.vehicleType}_${index}`}
									carInfo={car}
									dailyRateText={dailyRateText}
									discountText={discountText}
									includeAirConditionText={includeAirConditionText}
									includeLargeAirConditionText={includeLargeAirConditionText}
									passengersText={passengersText}
									doorsText={doorsText}
									baggageText={baggageText}
									manualTransmissionText={manualTransmissionText}
									automaticTransmissionText={automaticTransmissionText}
									rateText={rateText}
									coverageButtonText={coverageButtonText}
									colorPrimary={colorPrimary}
									rateModel={rateModel}
									multipleCompanies={true}
									showRate={true}
									showButtons={false}
									className={`bg-white rounded-xl flex-grow flex flex-row items-center carGridRecommendations__coverageCarCard ${
										activeIndex === index ? 'border-2' : ''
									}`}
									companyImageClassName='h-[15px] w-[41px]'
									carImageClassName=''
									carContainerClassName=''
									forceMobileDisplay={true}
									filterCompany={null}
									onClick={() => null}
									onShareClick={() => null}
									disabled={disabledSlide}
									style={{
										borderColor: activeIndex === index ? colorPrimary : undefined,
									}}
									onCardClick={() => slideClickHandler(index, car)}
									isGrid={true}
									t={t}
									pointsAccumulation={car.totalAccumulationProductPoint}
									informationMessage={informationCardMessage}
									isWithUser={isWithUser}
									isShowPcoPrice={isShowPcoPrice}
									currencySymbol={currencySymbol || ''}
									isActiveMultiLanguage={isActiveMultiLanguage}
								/>
							);
						}),
					];
				} else {
					return [];
				}
			})(recommendations, activeCar, disabled),
		[recommendations, activeCar, disabled],
	);

	useEffect(() => {
		if (!disabled) {
			setClickLoading(false);

			setActiveCoverage('');

			setActiveCompany('');
		}
	}, [disabled]);

	useEffect(() => {
		if (activeCarData) {
			renderTableData(activeIndex, activeCarData);
		} else {
			setGridData({
				head: [],
				rows: [],
			});

			setActiveCar(0);
		}
	}, [activeCarData, activeIndex, disabled]);

	useEffect(() => {
		if (recommendations && recommendations.length) {
			slideClickHandler(firstItem, recommendations[firstItem]);
		} else {
			setGridData({
				head: [],
				rows: [],
			});

			setActiveCar(0);
		}
	}, [recommendations, rateModel]);

	useEffect(() => {
		if (recommendations && recommendations.length <= stepSliderButton) {
			moveToPosition(0);
		}
	}, [recommendations ? recommendations.length : null, moveToPosition]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`carGridRecommendations ${className || ''}`}>
				<div className='carGridRecommendations__sliderContainer w-full flex flex-row justify-center items-center'>
					{recommendations.length > stepSliderButton && (
						<div
							className={'carGridRecommendations__previousButton cursor-pointer min-w-[11px] mr-5'}
							style={{
								color: '#DDDDDD',
							}}
							onClick={previousPosition}
						>
							<IcomoonIcon icon='chevronLeftIco' fontSize={17} />
						</div>
					)}
					<Slider
						slides={[]}
						customContentSlides={customContentSlides}
						config={{
							showContent: true,
							showLinkButton: false,
							showSubtitleLine: false,
							showBackgroundImage: false,
							showOutContent: false,
						}}
						outerClassName='w-full flex-grow'
						className={'w-full min-h-[215px] py-[10px]'}
						slidesClassNames={{
							className: 'rounded-xl w-full flex flex-col flex-grow',
							outClassName: 'rounded-xl flex flex-col',
							imageClassName: 'rounded-xl',
						}}
						sliderRef={sliderRef}
						slidesContainerRef={slidesContainerRef}
						slidesWidth={slidesWidth}
						slidesMargin={slidesMargin}
						sliderSideMargin={sliderSideMargin}
						slidesNumberShow={stepSliderButton}
					/>
					{recommendations.length > stepSliderButton && (
						<div
							className={'carGridRecommendations__nextButton cursor-pointer min-w-[11px] ml-5'}
							style={{
								color: '#DDDDDD',
							}}
							onClick={nextPosition}
						>
							<IcomoonIcon icon='chevronRightIco' fontSize={17} />
						</div>
					)}
				</div>
				{!!customContentSlides.length && (
					<div className='flex flex-row justify-center items-center mt-7'>
						{new Array(Math.ceil(customContentSlides.length / stepSliderButton)).fill(0).map((item: number, index: number) => {
							const diffLengthIndex: number = 1;
							const isActive: boolean = position >= index * stepSliderButton && position < (index + diffLengthIndex) * stepSliderButton;
							return (
								<div
									key={index}
									onClick={() => moveToPosition(index * stepSliderButton)}
									className={`mx-1.5 cursor-pointer ${
										isActive ? 'h-px border-4 w-8 md:w-12 rounded-xl' : 'h-2 w-2 rounded-full'
									} carGridRecommendations__transition ${isActive ? '' : 'carGridRecommendations__slideButton'}`}
									style={{
										backgroundColor: isActive ? colorPrimary : '#E2E2E2',
										borderColor: isActive ? colorPrimary : '#E2E2E2',
									}}
								/>
							);
						})}
					</div>
				)}
				<div className='w-full flex flex-row justify-center items-center'>
					<div className={'min-w-[11px] mr-5'} />
					<div className='w-full flex-grow'>
						<p className='carGridRecommendations__descriptionText bg-black text-white text-center p-2.5 text-xs font-medium mt-7 rounded-[5px] mb-6 md:mb-0'>
							{descriptionText}
						</p>
						{!!gridData.head.length && (
							<div className='w-full flex flex-col'>
								{isMobile ? (
									gridData.head.slice(firstPosition).map((label: RowData, indexCoverage: number) => (
										<div className='w-full flex flex-col' key={`${label.label}_${activeCar}`}>
											<div key={`${indexCoverage}_${activeCar}`} className='w-full'>
												{label.value || <span>&nbsp;</span>}
											</div>
											{gridData.rows.map((row: RowData[], index: number) => (
												<div
													key={`${indexCoverage}_${activeCar}_${index}`}
													className={
														'w-full p-3 flex flex-row items-center justify-between mb-4 rounded-[10px] bg-white carGridRecommendations__bodyTableRow'
													}
												>
													<div key={activeCar} className='w-[100px] flex flex-row items-center justify-center'>
														{row[firstItem].value}
													</div>
													<div className={'flex-grow flex flex-row items-center justify-between'}>
														<div key={`${index}_${activeCar}`} className='w-full flex flex-row items-center justify-center'>
															{row[indexCoverage + firstPosition] && row[indexCoverage + firstPosition].value ? (
																row[indexCoverage + firstPosition].value
															) : (
																<span>&nbsp;</span>
															)}
														</div>
													</div>
												</div>
											))}
										</div>
									))
								) : (
									<>
										<div className={'w-full flex flex-row items-center justify-between'}>
											<div className='w-[138px]'>{gridData.head[firstItem].value}</div>
											<div key={activeCar} className={'flex-grow flex flex-row items-center justify-between'}>
												{gridData.head.slice(firstPosition).map((label: RowData, index: number) => (
													<div key={`${index}_${activeCar}`} className='w-full'>
														{label.value || <span>&nbsp;</span>}
													</div>
												))}
											</div>
										</div>
										<div className='w-full flex flex-col'>
											{gridData.rows.map((row: RowData[], indexCompany: number) => (
												<div
													key={`${indexCompany}_${activeCar}`}
													className={
														'w-full p-3 flex flex-row items-center justify-between mb-4 rounded-[10px] bg-white carGridRecommendations__bodyTableRow'
													}
												>
													<div key={activeCar} className='w-[138px] flex flex-row items-center justify-center'>
														{row[firstItem].value}
													</div>
													<div className={'flex-grow flex flex-row justify-between min-h-[75px]'}>
														{row.slice(firstPosition).map((value: RowData, indexCoverage: number) => (
															<div key={`${indexCompany}_${activeCar}_${indexCoverage}`} className='w-full flex flex-row justify-center'>
																{value.value || <span>&nbsp;</span>}
															</div>
														))}
													</div>
												</div>
											))}
										</div>
									</>
								)}
							</div>
						)}
						<GenericMessage message={informationStandardMessage} />
					</div>
					<div className={'min-w-[11px] ml-5'} />
				</div>
			</div>
		</>
	);
}

export default CarGridRecommendations;
