import { CMSGeneralComponentInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import DesktopReviewsSlider from './desktop-reviews-slider/desktop-reviews-slider';
import MobileReviewsSlider from './mobile-reviews-slider/mobile-reviews-slider';
import ReviewsSkeleton from './skeleton/skeleton-reviews';

export interface ReviewsProps extends CMSGeneralComponentInterface {
	loading?: boolean;
	transitionTime?: number;
	mainColor?: string;
	secondColor?: string;
	className?: string;
	titleClassName?: string;
	subtitleClassName?: string;
	descriptionClassName?: string;
	slideTitleClassName?: string;
	slideSubtitleClassName?: string;
	slideDescriptionClassName?: string;
	infoContainerClassName?: string;
	desktopSliderContainerClassName?: string;
	mobileSliderContainerClassName?: string;
}

export function Reviews({
	title,
	subtitle,
	description,
	slides,
	loading,
	transitionTime,
	mainColor,
	secondColor,
	className,
	titleClassName,
	subtitleClassName,
	descriptionClassName,
	slideTitleClassName,
	slideSubtitleClassName,
	slideDescriptionClassName,
	infoContainerClassName,
	desktopSliderContainerClassName,
	mobileSliderContainerClassName,
}: ReviewsProps): JSX.Element {
	return loading ? (
		<ReviewsSkeleton
			className={className}
			infoContainerClassName={infoContainerClassName}
			desktopSliderContainerClassName={desktopSliderContainerClassName}
			mobileSliderContainerClassName={mobileSliderContainerClassName || infoContainerClassName}
		/>
	) : (
		<div className={`${className || ''}`}>
			<div className={`reviews__content flex flex-col justify-start mb-12 md:mb-24 w-full md:w-4/5 ${infoContainerClassName || ''}`}>
				<div
					className='reviews__line h-px w-6 md:w-12 border-b-4 rounded-xl mb-2'
					style={{
						backgroundColor: mainColor,
						borderColor: mainColor,
					}}
				/>
				<p
					className={`font-bold text-left text-xs mb-2.5 md:mb-4 ${subtitleClassName || ''}`}
					style={{
						color: mainColor,
					}}
				>
					{subtitle ? subtitle.toUpperCase() : ''}
				</p>
				<h2 className={`reviews__title font-bold text-black mb-2.5 text-left text-2xl md:text-5xl ${titleClassName || ''}`}>{title}</h2>
				<p className={`reviews__description font-normal text-black text-left text-xs md:text-base ${descriptionClassName || ''}`}>{description}</p>
			</div>
			<div className={'hidden md:block'}>
				<DesktopReviewsSlider
					slides={slides}
					transitionTime={transitionTime}
					mainColor={mainColor}
					secondColor={secondColor}
					className={desktopSliderContainerClassName}
					slideTitleClassName={slideTitleClassName}
					slideSubtitleClassName={slideSubtitleClassName}
					slideDescriptionClassName={slideDescriptionClassName}
				/>
			</div>
			<div className={'block md:hidden'}>
				<MobileReviewsSlider
					slides={slides}
					mainColor={mainColor}
					secondColor={secondColor}
					className={mobileSliderContainerClassName}
					slideTitleClassName={slideTitleClassName}
					slideSubtitleClassName={slideSubtitleClassName}
					slideDescriptionClassName={slideDescriptionClassName}
				/>
			</div>
		</div>
	);
}

Reviews.defaultProps = {
	className: '',
	titleClassName: '',
	subtitleClassName: '',
	descriptionClassName: '',
	slideTitleClassName: '',
	slideSubtitleClassName: '',
	slideDescriptionClassName: '',
	infoContainerClassName: '',
	desktopSliderContainerClassName: '',
	mobileSliderContainerClassName: '',
};

export default Reviews;
