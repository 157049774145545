import { numberToLocaleWithcurrency } from '../../../shared/services';
import styles from './pco-price.module.scss';

export interface PcoPriceProps {
	points: number;
	pointsCurrency: string;
	money: number;
	moneyCurrency: string;
	totalRedemptionPoints: number;
	totalAccumulation: number;
	isWithUser: boolean;
	showTaxesLabel: boolean;
	fromLabel: string;
	taxesLabel: string;
	accumulationLabel: string;
	redemptionLabel: string;
	currencySymbol: string;
	primaryColor: string;
	culture: string;
	sectionClassName?: string;
}

export function PcoPrice({
	points,
	pointsCurrency,
	money,
	moneyCurrency,
	totalRedemptionPoints,
	totalAccumulation,
	isWithUser,
	sectionClassName,
	fromLabel,
	showTaxesLabel,
	taxesLabel,
	currencySymbol,
	accumulationLabel,
	primaryColor,
	redemptionLabel,
	culture,
}: PcoPriceProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<section className={`pricePco ${sectionClassName || ''}`}>
				<div className='pricePco__price'>
					{!isWithUser && fromLabel && <span>{fromLabel}</span>}
					<div className='pricePco__price-pointsAndMoney'>
						<span className='pricePco__price-points' style={{ color: primaryColor }}>
							{numberToLocaleWithcurrency(points, '', culture)} {pointsCurrency}
						</span>
						<div className='pricePco__price-plusAndMoney'>
							<span className='pricePco__price-plus'> + </span>
							<span className='pricePco__price-money'>{numberToLocaleWithcurrency(money, moneyCurrency, '', currencySymbol)}</span>
						</div>
					</div>
					{showTaxesLabel && taxesLabel && <span className='pricePco__price-taxesLabel'>{taxesLabel}</span>}
				</div>
				<div className='pricePco__total'>
					<div className='pricePco__accumulation' style={{ color: primaryColor }}>
						<span className='pricePco__accumulation-label'>{accumulationLabel}</span>
						<span className='pricePco__accumulation-points'>
							{numberToLocaleWithcurrency(totalAccumulation, '', culture)} {pointsCurrency}
						</span>
					</div>
					<div className='pricePco__totalRedemption'>
						<span className='pricePco__totalRedemption-label'>{redemptionLabel}</span>
						<span className='pricePco__totalRedemption-points' style={{ color: primaryColor }}>
							{' '}
							{numberToLocaleWithcurrency(totalRedemptionPoints, '', culture)} {pointsCurrency}
						</span>
					</div>
				</div>
			</section>
		</>
	);
}

export default PcoPrice;
