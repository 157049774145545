import { PassengersFieldInputNamesInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import { FlightAirlinesTypes, FlightCabinsTypes, FlightTripTypes } from '../enums/flight-types.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import { FlightsSearchHistoryUtil } from './flights-search-history.util';
import { FlightsSearchUrlUtil } from './flights-search-url.util';
import {
	FlightsSearchFormSubmitInterface,
	DestinationsNamesFlightsSearchFormInterface,
	FieldDestinationNamesFlightsSearchFormInterface,
	PassengersFieldInputNamesFlightsSearchFormInterface,
	DisabledTripTypeFlightsSearchFormInterface,
	DestinationsFlightsSearchFormSubmitInterface,
} from '../interfaces/flights-search-form.interface';

export class FlightsSearchFormUtil {
	static getPassengersInputsNames(name: string): PassengersFieldInputNamesFlightsSearchFormInterface {
		const tempNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
		return {
			...tempNames,
			cabinName: `${name}CabinName`,
			hiddenCabinName: `${name}HiddenCabinName`,
		};
	}

	static getDestinationsInputsNames(name: string): DestinationsNamesFlightsSearchFormInterface {
		return {
			departure: {
				name: `departureName${name}`,
				hiddenName: `hiddenDepartureName${name}`,
				id: `departureFieldId${name}`,
				original: `originalDeparture${name}`,
			},
			arrival: {
				name: `arrivalName${name}`,
				hiddenName: `hiddenArrivalName${name}`,
				id: `arrivalFieldId${name}`,
				original: `originalArrival${name}`,
			},
			dates: {
				name: `datesName${name}`,
				hiddenName: `hiddenDatesName${name}`,
				id: `datesFieldId${name}`,
				original: `originalDates${name}`,
			},
		};
	}

	static getMultiDestinationsInputsNames(name: string, tripsNumber: number): DestinationsNamesFlightsSearchFormInterface[] {
		return Array(tripsNumber)
			.fill(0)
			.map((item: number, index: number) => {
				return FlightsSearchFormUtil.getDestinationsInputsNames(`${name}_${index}`);
			});
	}

	static getAirlineOption(airlines: OptionSelect[], value?: string): OptionSelect | undefined {
		let allOption: OptionSelect | undefined;
		const optionValue: string = value || FlightAirlinesTypes.all;
		if (airlines?.length) {
			allOption = airlines.find((airline: OptionSelect): boolean => airline.value === optionValue);
		}

		return allOption;
	}

	static getCabinOption(cabins: OptionSelect[], value?: string): OptionSelect | undefined {
		let allOption: OptionSelect | undefined;
		const optionValue: string = value || FlightCabinsTypes.all;
		if (cabins?.length) {
			allOption = cabins.find((cabin: OptionSelect): boolean => cabin.value === optionValue);
		}

		return allOption;
	}

	static getTripTypeOption(value?: string, disabledTripType?: DisabledTripTypeFlightsSearchFormInterface): FlightTripTypes {
		let defaultTripType: FlightTripTypes = FlightTripTypes.round;
		if (!disabledTripType?.round) {
			defaultTripType = FlightTripTypes.round;
		} else if (!disabledTripType?.oneway) {
			defaultTripType = FlightTripTypes.oneway;
		} else if (!disabledTripType?.multi) {
			defaultTripType = FlightTripTypes.multiple;
		}

		const validTripType: boolean = Object.values(FlightTripTypes).includes((value || '') as unknown as FlightTripTypes);
		const tempTripType: string = validTripType ? value || defaultTripType : defaultTripType;
		return tempTripType as unknown as FlightTripTypes;
	}

	static findDestinationNamesFromId(
		destinationsNames: DestinationsNamesFlightsSearchFormInterface[],
		id: string,
	): FieldDestinationNamesFlightsSearchFormInterface {
		let destinationNames: DestinationsNamesFlightsSearchFormInterface | undefined;
		const destinationIndex: number | undefined = destinationsNames.findIndex(
			(names: DestinationsNamesFlightsSearchFormInterface) => names.departure.id === id || names.arrival.id === id || names.dates.id === id,
		);

		if (typeof destinationIndex === 'number') {
			destinationNames = { ...destinationsNames[destinationIndex] };
		}

		return {
			id,
			destinationNames,
			destinationIndex,
			activeField: {
				departure: destinationNames?.departure?.id === id,
				arrival: destinationNames?.arrival?.id === id,
				dates: destinationNames?.dates?.id === id,
			},
		};
	}

	static getDisplayPassengersInfo(
		adultsValue: number,
		childrenValue: number,
		infantsValue: number,
		extraInformation: string,
		adultText?: string,
		adultsText?: string,
		childText?: string,
		childrenText?: string,
		infantText?: string,
		infantsText?: string,
		isTertiaryTemplate?: boolean,
	): string {
		let passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
			adultsValue,
			childrenValue,
			infantsValue,
			adultText,
			adultsText,
			childText,
			childrenText,
			infantText,
			infantsText,
			isTertiaryTemplate,
		);
		passengersDisplayValue += extraInformation ? `${passengersDisplayValue ? ', ' : ''}${extraInformation}` : '';

		return passengersDisplayValue.toLocaleLowerCase();
	}

	static mapSearchUrl(data: FlightsSearchFormSubmitInterface): string {
		return FlightsSearchUrlUtil.mapSearchUrl(data);
	}

	static historyHandle(
		historyJson: FlightsSearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): FlightsSearchFormSubmitInterface {
		return FlightsSearchHistoryUtil.historyHandle(historyJson, traductions);
	}

	static validatePath(historyJson: FlightsSearchFormSubmitInterface | null = null): boolean {
		const destinationPosition: number = 0;
		const destinationsHistory: DestinationsFlightsSearchFormSubmitInterface | null = historyJson?.destinations?.length
			? historyJson.destinations[destinationPosition]
			: null;

		const departure: string = destinationsHistory?.departure?.airportCode || '';
		const path: string = window.location.href;
		let withParams: boolean = false;

		if (path.includes(departure)) {
			withParams = true;
		}

		return withParams;
	}
}
