import CarSpecialServicesDetail from '../../../../components/cars/car-detail/car-special-services/car-special-services-detail';
import { CarResumeSkeleton } from '../../../../components/cars/car-detail/car-resume/skeleton/car-resume-skeleton';
import CarCoveragesDetail from '../../../../components/cars/car-detail/car-coverages/car-coverages-detail';
import { DetailSteps } from '../../../../../../shared/enums/detail-steps.enum';
import IcomoonIcon from '../../../../../ui-elements/icomoon-icon/icomoon-icon';

export interface CarDetailSkeletonProps {
	step: DetailSteps;
}

export const CarDetailSkeleton = ({ step }: CarDetailSkeletonProps): JSX.Element => {
	let stepElement: JSX.Element = <div></div>;
	switch (step) {
		case DetailSteps.coverages:
			stepElement = <CarCoveragesDetail loading={true} coveragesMobileClassName={'block md:hidden'} coveragesDesktopClassName={'hidden md:block'} />;

			break;
		case DetailSteps.specialServices:
			stepElement = (
				<CarSpecialServicesDetail
					specialServices={[]}
					title=''
					description=''
					moreInformationText=''
					payOnLineText=''
					paymentOnArrivalText=''
					rateModel=''
					loading={true}
				/>
			);
		default:
			break;
	}
	return (
		<div className={'carDetailSkeleton animate-pulse'}>
			<div className='carDetailSkeleton__header flex flex-row items-center justify-start'>
				<div className='flex items-center mb-[20px] md:mb-[35px] '>
					<IcomoonIcon icon={'chevronLeftIco'} className='mr-[10px] text-gray-200' fontSize={16} />
					<p className='carDetailSkeleton__backHistory m-0 w-[86px] h-[12px] md:h-[21px] bg-gray-200' />
				</div>
			</div>
			<div className='flex flex-col md:flex-row'>
				<div className='w-full md:w-[70%] md:mr-[49px] order-2 md:order-1'>
					<div className='flex flex-col md:flex-row md:justify-between md:items-center'>
						<p className='carDetailSkeleton__callToActionText m-0 mr-[10px] w-[70%] h-[12px] bg-gray-200'></p>
						<div className='flex justify-end mt-[9px] md:mt-0'>
							<span className='cashIco text-[15px] md:text-[20px] mr-[5px] text-gray-200' />
						</div>
					</div>
					<div>{stepElement}</div>
				</div>
				<div className='w-full md:w-[30%] order-1 md:order-2'>
					<CarResumeSkeleton />
				</div>
			</div>
		</div>
	);
};

export default CarDetailSkeleton;
