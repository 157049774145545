import { DisplayValuesDatesFieldInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import { FlightTripTypes, OnlyDirectFlightTypes } from '../enums/flight-types.enum';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import {
	FlightsSearchFormSubmitInterface,
	DestinationsFlightsSearchFormSubmitInterface,
	AirportFlightsSearchFormSubmitInterface,
} from '../interfaces/flights-search-form.interface';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { ObjectLiteral } from '../../../../shared/enums/object-literal.enum';
import { FlightsSearchFormUtil } from './flights-search-form.util';

export class FlightsSearchHistoryUtil {
	static mapDestinations(
		urlSearchParams: URLSearchParams,
		history: FlightsSearchFormSubmitInterface,
	): DestinationsFlightsSearchFormSubmitInterface[] {
		const { departure, arrival, departureDate, arrivalDate } = Object.fromEntries(urlSearchParams.entries());
		const firstItem: number = 0;
		const thereDestinations: boolean = !!history?.destinations?.length;
		const tempDeparture: AirportFlightsSearchFormSubmitInterface = thereDestinations
			? { ...history?.destinations[firstItem]?.departure }
			: {
				airport: '',
				airportCode: '',
				date: '',
				countryCode: '',
			  };

		const tempArrival: AirportFlightsSearchFormSubmitInterface = thereDestinations
			? { ...history?.destinations[firstItem]?.arrival }
			: {
				airport: '',
				airportCode: '',
				date: '',
				countryCode: '',
			  };

		const tempDestinations = {
			departure: {
				airport: departure !== tempDeparture?.airportCode ? departure : tempDeparture?.airport || departure || '',
				airportCode: departure || '',
				date: SearchFieldsUtil.convertDatesFromUrl(departureDate || ''),
				countryCode: '',
			},
			arrival: {
				airport: arrival !== tempArrival?.airportCode ? arrival : tempArrival?.airport || arrival || '',
				airportCode: arrival || '',
				date: SearchFieldsUtil.convertDatesFromUrl(arrivalDate || ''),
				countryCode: '',
			},
			dates: '',
		};

		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.startEndDates,
			{
				time: false,
				date: true,
			},
			tempDestinations.departure.date,
			'',
			tempDestinations.arrival.date,
			'',
		);

		tempDestinations.dates = datesValues?.startDate ? datesValues.startDate : '';

		return [{ ...tempDestinations }];
	}

	static mapDestinationsMultiple(
		urlSearchParams: URLSearchParams,
		history: FlightsSearchFormSubmitInterface,
	): DestinationsFlightsSearchFormSubmitInterface[] {
		if (history?.destinations) {
			return history.destinations;
		}

		const destinations: DestinationsFlightsSearchFormSubmitInterface[] = [];
		const { departure, arrival, departureDate } = Object.fromEntries(urlSearchParams.entries());
		const keySplit: string = ',';
		const departureAiportCodes: string[] = departure.split(keySplit);
		const arrivalAiportCodes: string[] = arrival.split(keySplit);
		const departureDates: string[] = departureDate.split(keySplit);
		departureAiportCodes.forEach((_: string, index: number) => {
			const date: string = SearchFieldsUtil.convertDatesFromUrl(departureDates[index]);
			destinations.push({
				departure: {
					airport: departureAiportCodes[index],
					airportCode: departureAiportCodes[index],
					date,
					countryCode: '',
				},
				arrival: {
					airport: arrivalAiportCodes[index],
					airportCode: arrivalAiportCodes[index],
					date,
					countryCode: '',
				},
				dates: date,
			});
		});

		return destinations;
	}

	static historyHandle(
		historyJson: FlightsSearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): FlightsSearchFormSubmitInterface {
		const history: FlightsSearchFormSubmitInterface = (historyJson && { ...historyJson }) || ({} as unknown as FlightsSearchFormSubmitInterface);
		const routePath = window?.location?.pathname;
		if (routePath.includes('flights')) {
			const getParams: string = window.location.search;
			const urlSearchParams: URLSearchParams = new URLSearchParams(getParams);
			const { trip, adults, children, infants, cabin, airline, scale } = Object.fromEntries(urlSearchParams.entries());
			const detinations = {
				[FlightTripTypes.multiple]: FlightsSearchHistoryUtil.mapDestinationsMultiple(urlSearchParams, history),
				[ObjectLiteral.default]: FlightsSearchHistoryUtil.mapDestinations(urlSearchParams, history),
			};

			history.destinations = detinations[trip] || detinations[ObjectLiteral.default];

			history.adults = Number(adults) || 1;

			history.children = Number(children) || 0;

			history.infants = Number(infants) || 0;

			history.cabin = cabin || '';

			history.passengersAndExtraInfo = FlightsSearchFormUtil.getDisplayPassengersInfo(
				history.adults,
				history.children,
				history.infants,
				history.cabin,
				traductions ? traductions('adult') : 'adult',
				traductions ? traductions('adults') : 'adults',
				traductions ? traductions('child') : 'child',
				traductions ? traductions('children') : 'children',
				traductions ? traductions('infant') : 'infant',
				traductions ? traductions('infants') : 'infants',
			);

			history.airline = airline !== history?.airlineCode ? airline : history?.airline || airline;

			history.airlineCode = airline;

			const validTripType = Object.values(FlightTripTypes).includes((trip || '') as unknown as FlightTripTypes);
			history.tripType = validTripType ? trip : FlightTripTypes.round;

			history.onlyDirectFlight = scale === OnlyDirectFlightTypes.direct;

			history.urlPath = FlightsSearchFormUtil.mapSearchUrl({ ...history });
		}

		return history;
	}
}
