import moduleStyles from './button.module.scss';

/* eslint-disable-next-line */
export interface ButtonProps {
	text?: string;
	content?: JSX.Element;
	styles?: any;
	disabled?: boolean;
	onClick?: (e: any) => any;
	customClass?: string;
	type?: 'button' | 'submit' | 'reset' | undefined;
	form?: string;
	noHover?: boolean;
}

export function Button({ text, content, styles, disabled, onClick, customClass, type, form, noHover }: ButtonProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: moduleStyles.toString() }}></style>
			<button
				onClick={onClick}
				disabled={disabled}
				className={`
      flex justify-center py-3 px-6 font-family-black
      ${noHover ? '' : 'button__hover'}
      ${disabled ? 'bg-gray-600 cursor-not-allowed' : ''}
      ${customClass || ''}
      `}
				style={{ ...styles }}
				type={type || 'button'}
				form={form}
			>
				{content || text || ''}
			</button>
		</>
	);
}

export default Button;
