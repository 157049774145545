import { CreditCardUserInterface } from '../../../shared/interfaces/credit-card-user.interface';
import CardPointsResult from '../../shared-components/card-points-result/card-points-result';
import CreditCardForm from '../../shared-components/credit-card-form/credit-card-form';
import PointsButton from '../../ui-elements/points-button/points-button';
import { CardInfoSubmit } from '../../../shared/interfaces/card-info';

export interface PointsConsultationProps {
	creditCardUser: CreditCardUserInterface | null;
	loading: boolean | null;
	error: boolean | null;
	statusCode?: number | null;
	isFormOpen: boolean;
	isResultOpen: boolean;
	token: string;
	franchise: string;
	cardNumber?: string;
	openForm: () => void;
	closeForm: () => void;
	onCloseResult: () => void;
	t: (label: string) => string;
	emitPoints: (cardData: CardInfoSubmit, token: string) => void;
}

export function PointsConsultation({
	creditCardUser = null,
	loading,
	isFormOpen,
	error,
	statusCode,
	isResultOpen,
	token,
	franchise,
	cardNumber,
	t,
	openForm,
	closeForm,
	emitPoints,
	onCloseResult,
}: PointsConsultationProps): JSX.Element {
	const userPoints: number | null = creditCardUser ? creditCardUser?.points?.value : null;
	const userCurrency: string | null = creditCardUser?.points?.name ? creditCardUser?.points?.name : t('points');
	const displayForm: boolean = (loading || loading === null) && isFormOpen;
	const displayCardPoints: boolean = !loading;
	return (
		<div className='points-consultation'>
			<PointsButton clickPointsBtn={openForm} userPoints={userPoints} userCurrency={userCurrency} t={t} className='points-consultation__btn' />
			{displayForm && <CreditCardForm onClose={closeForm} getPoints={emitPoints} loading={loading} t={t} token={token} />}
			{displayCardPoints && isResultOpen && (
				<CardPointsResult
					error={error}
					statusCode={statusCode}
					creditCardUser={creditCardUser}
					onCloseResult={onCloseResult}
					openForm={openForm}
					t={t}
					franchise={franchise}
					cardNumber={cardNumber}
					userCurrency={userCurrency}
				/>
			)}
		</div>
	);
}

export default PointsConsultation;
