import { useState, useLayoutEffect, ReactNode, ReactPortal } from 'react';
import styles from 'bundle-text:./portal.scss';
import { createPortal } from 'react-dom';

interface PortalInterface {
	portalId: string;
	children: ReactNode;
}

export const Portal = ({ portalId, children }: PortalInterface): ReactPortal | null => {
	const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);
	useLayoutEffect(() => {
		let element: HTMLElement | null = document.getElementById(portalId);
		let elementCreated = false;
		if (element == null) {
			elementCreated = true;

			element = document.createElement('div');

			element.id = portalId;

			document.body.appendChild(element);
		}

		setWrapperElement(element);

		return () => {
			if (elementCreated && element?.parentNode != null) {
				element.parentNode.removeChild(element);
			}
		};
	}, [portalId]);

	return wrapperElement === null
		? null
		: createPortal(
			<>
				<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
				<div className='smartLinksWidgets__portal'>{children}</div>
			</>,
			wrapperElement,
		  );
};
