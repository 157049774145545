import { SearchReservesInterface } from '../../../../../../shared/interfaces/flights/search-reserves.interface';
import { Select } from '../../../../../ui-elements/select/select';
import styles from './search-reserves.module.scss';

export interface SearchReservesProps {
	data: SearchReservesInterface;
	callBack: (e: any) => void;
	customClass?: string;
}

export function SearchReserves({ data, callBack, customClass }: SearchReservesProps): JSX.Element {
	const defaultDate = '2022-07-22';
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<form className={`${customClass || ''} relative`} onSubmit={callBack}>
				<div className={'searchForm flex justify-between'}>
					<div className={'searchForm__inputShadow h-[45px] w-[380px] rounded-md flex items-center justify-between bg-white'}>
						<input className='pl-6 outline-none placeholder-[#878787]' type='text' name='search' placeholder={data.labelSearch} />
						<button className='bg-[#213261] h-full w-[45px] rounded-r-md' type='submit'>
							<span className='searchIco text-white'></span>
						</button>
					</div>
					<div className={'searchForm__inputShadow h-[45px] w-[280px] rounded-md flex items-center justify-center bg-white'}>
						<input className={'searchForm__inputSelect outline-none text-[#878787] text-[12px]'} type='date' name='trip-start' value={defaultDate} />
						<span className='h-[34px] w-[1px] bg-[#878787] mx-4'></span>
						<input className={'searchForm__inputSelect outline-none text-[#878787] text-[12px]'} type='date' name='trip-end' value={defaultDate} />
					</div>
					<Select
						options={data.arrayAgencies}
						className={
							'searchForm__inputSelect searchForm__inputShadow h-[45px] w-[180px] rounded-md flex items-center justify-center !py-0 !px-0 text-[#878787] text-[12px] bg-white'
						}
					/>
					<Select
						options={data.arrayCompanies}
						className={
							'searchForm__inputSelect searchForm__inputShadow h-[45px] w-[180px] rounded-md flex items-center justify-center !py-0 !px-0 text-[#878787] text-[12px] bg-white'
						}
					/>
				</div>
				<div className='mt-[20px] flex items-center absolute right-0 text-[#878787] text-[12px]'>
					<p className='mr-4 text-[12px]'>{data.LabelOrder}</p>
					<Select
						options={data.arrayOrder}
						name={'order'}
						className={
							'searchForm__inputSelect searchForm__inputShadow h-[45px] w-[180px] rounded-md flex items-center justify-center !py-0 !px-0 text-[#878787] text-[12px] bg-white'
						}
					/>
				</div>
			</form>
		</>
	);
}
