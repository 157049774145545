import { StepInterface } from '../../../shared/interfaces/steps';
import { StepStates } from '../../../shared/enums/steps.enum';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import styles from './steps.module.scss';

export interface StepsProps {
	steps: StepInterface[];
	completedTitle: string;
	incompletedTitle: string;
}

export function Steps(props: StepsProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<ul>
				{props.steps.map((step: StepInterface, index: number) => {
					let stepNumberClassName: string = '';
					if (step.status === StepStates.Completed) {
						stepNumberClassName = styles.steps__completedStepNumber;
					} else if (step.status === StepStates.Incompleted) {
						stepNumberClassName = styles.steps__incompletedStepNumber;
					} else if (step.status === StepStates.Inprogress) {
						stepNumberClassName = styles.steps__inProgressStepNumber;
					}

					return (
						<li className='flex' key={step.id}>
							<div className='flex flex-col'>
								<div className={`flex justify-center items-center m-4 text-lg steps__stepNumber ${stepNumberClassName}`}>
									<div className='text-center m-4 text-3xl font-family-bold'>
										{step.status === StepStates.Completed ? <IcomoonIcon icon='checkIco' /> : <span>{index + 1}</span>}
									</div>
								</div>
								{index < props.steps.length - 1 && <div className={'self-center border-l-2 border-solid steps__line'} />}
							</div>
							<div className={'text-left py-6'}>
								<p className={'justify-center text-lg font-family-black steps__stepTitle'}>{step.title}</p>
								<p className={'justify-center text-sm font-family-regular italic steps__stepSubtitle'}>
									{step.status === StepStates.Completed ? props.completedTitle : props.incompletedTitle}
								</p>
							</div>
						</li>
					);
				})}
			</ul>
		</>
	);
}

export default Steps;
