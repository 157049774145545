import { HotelsDisneyResumeAsideService } from '../services/hotel-disney-resume-aside.service';
import useCommonWidget, { UseCommonWidgetReturnInterface } from './useCommonWidget';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { HotelsDisneyService } from '../services/hotels-disney.service';
import { SearchWidgetInterface } from '../interfaces/search.interface';
import { AssistancesService } from '../services/assistances.service';
import { ActivitiesService } from '../services/activities.service';
import { FlightsService } from '../services/flights.service';
import { HotelsService } from '../services/hotels.service';
import { DisneyService } from '../services/disney.service';
import { PlansService } from '../services/plans.service';
import { CarService } from '../services/cars.service';
import { useCallback } from 'react';

export interface UseSearchWidgetInterface<
	T extends
	| CarService
	| FlightsService
	| ActivitiesService
	| HotelsService
	| AssistancesService
	| PlansService
	| DisneyService
	| HotelsDisneyService
	| HotelsDisneyResumeAsideService,
	U,
> extends SearchWidgetInterface<U> {
	className?: string;
	styles?: string;
	productServiceClass: new (token: string) => T;
	customHistoryName?: LocalStorageHistoriesNames;
}

export interface UseSearchWidgetReturnInterface<
	T extends
	| CarService
	| FlightsService
	| ActivitiesService
	| HotelsService
	| AssistancesService
	| PlansService
	| DisneyService
	| HotelsDisneyService
	| HotelsDisneyResumeAsideService,
	U,
> extends UseCommonWidgetReturnInterface<T> {
	urlSearchHandler: (data: U) => void;
	showMobileFieldsHandler: (show: boolean) => void;
}

const useSearchWidget = <
	T extends
	| CarService
	| FlightsService
	| ActivitiesService
	| HotelsService
	| AssistancesService
	| PlansService
	| DisneyService
	| HotelsDisneyService
	| HotelsDisneyResumeAsideService,
	U,
>({
	token,
	language,
	agency,
	className,
	styles,
	callUrlSearch,
	callShowMobileFields,
	productServiceClass,
	customHistoryName,
}: UseSearchWidgetInterface<T, U>): UseSearchWidgetReturnInterface<T, U> => {
	const { agencyState, isLoading, agencyError, widgetService, widgetStyles, t } = useCommonWidget({
		token,
		language,
		agency,
		className,
		styles,
		WidgetServiceClass: productServiceClass,
		customHistoryName,
	});

	const urlSearchHandler = useCallback(
		(data: U) => {
			if (callUrlSearch != null) {
				callUrlSearch(data);
			}
		},
		[callUrlSearch],
	);

	const showMobileFieldsHandler = useCallback(
		(show: boolean): void => {
			if (callShowMobileFields != null) {
				callShowMobileFields(show);
			}
		},
		[callShowMobileFields],
	);

	return {
		agencyState,
		isLoading,
		agencyError,
		widgetService,
		widgetStyles,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	};
};

export default useSearchWidget;
