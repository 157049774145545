import { CarSearchPaymentType, CarSearchFormSubmit, CarSearchUtil } from '@smartlinks/react-design-system';
import { CarRecommendationPayload } from '../interfaces/car-recommendation-payload.interface';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { CorporateDiscounts } from '../interfaces/discounts.interface';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { CarRecommendation } from '../interfaces/car-recommendation';
import { SearchLocations } from '../interfaces/locations.interface';
import { ResponseCommons } from '../interfaces/commons.interface';
import { endPoints } from '../end-points/end-points';
import { CommonsService } from './commons.service';

export class CarService extends CommonsService {
	constructor(token: string) {
		super(token, LocalStorageHistoriesNames.carsSearch);
	}

	public async getRecommendations(payload: CarRecommendationPayload): Promise<CarRecommendation[] | []> {
		const url: string = `${this.searchApi}/${endPoints.carRecommendations}/`;
		const carResponse: ResponseHttp<CarRecommendation[]> = await this.httpCall.post(url, payload, this.header);
		if (carResponse.response != null) {
			return carResponse.response;
		}

		return [];
	}

	public async getCorporateDiscounts(): Promise<CorporateDiscounts[]> {
		const url: string = `${this.managementApi}/${endPoints.corporateDiscounts}/`;
		const corporateDiscountResponse: ResponseHttp<CorporateDiscounts[]> = await this.httpCall.get(url, this.header);
		if (corporateDiscountResponse?.response != null) {
			return corporateDiscountResponse?.response;
		}

		return [];
	}

	public mapSearchUrlFromRecommnedation(params: CarRecommendationPayload | null, carRecommendation: CarRecommendation): string {
		return this.mapSearchUrl(
			carRecommendation?.pickUpLocation?.code || '',
			carRecommendation?.dropOffLocation?.code || '',
			params?.pickUpDate || '',
			params?.pickUpTime || '',
			params?.dropOffDate || '',
			params?.dropOffTime || '',
			carRecommendation.category.toLowerCase(),
			params?.rewardsPoints || '',
			params?.corporateDiscount || '',
			params?.promotionalDiscount || '',
			params?.paymentType,
			true,
		);
	}

	public mapSearchUrl(
		pickUpLocation: string,
		dropOffLocation: string,
		pickUpDate: string,
		pickUpTime: string,
		dropOffDate: string,
		dropOffTime: string,
		category: string,
		rewardsPoints: string,
		corporateDiscount: string,
		promotionalDiscount: string,
		paymentType?: string,
		fromRecommnedation?: boolean,
		isPathPaymentType?: boolean,
	): string {
		if (pickUpTime?.includes(':')) {
			/* eslint-disable no-useless-escape */
			pickUpTime = pickUpTime.replace(/\:/g, '');
		}

		if (dropOffTime?.includes(':')) {
			dropOffTime = dropOffTime.replace(/\:/g, '');
		}

		let paymentTypeValue: string = '';
		if (fromRecommnedation && paymentType) {
			switch (paymentType) {
				case '0':
					paymentTypeValue = CarSearchPaymentType.online;

					break;
				case '1':
					paymentTypeValue = CarSearchPaymentType.onrental;

					break;
				default:
					break;
			}
		} else if (isPathPaymentType && paymentType) {
			paymentTypeValue = paymentType;
		}

		let paramsUrl: string = `${pickUpLocation}-${
			dropOffLocation || pickUpLocation
		}/${pickUpDate}_${pickUpTime}/${dropOffDate}_${dropOffTime}/${category}/${paymentTypeValue || ''}?`;

		if (rewardsPoints) {
			paramsUrl += `&rewards_points=${rewardsPoints}`;
		}

		if (corporateDiscount) {
			const discount = corporateDiscount.split(' - ')[0];
			paramsUrl += `&corporate_discount=${discount}`;
		}

		if (promotionalDiscount) {
			paramsUrl += `&promotional_code=${promotionalDiscount}`;
		}

		if (paymentType && !fromRecommnedation && !isPathPaymentType) {
			paramsUrl += `&payment=${paymentType}`;
		}

		return paramsUrl.replace('?&', '?');
	}

	public async getRecommendationsByWords(value: string): Promise<SearchLocations | null> {
		const url: string = `${this.managementApi}/${endPoints.locations}${value}`;
		const locationsResponse: ResponseHttp<SearchLocations> = await this.httpCall.get(url, this.header);
		if (locationsResponse.response != null) {
			return locationsResponse.response;
		}

		return null;
	}

	public getCarsSearchFormHistory(traductions?: (label: string) => string, isPathPaymentType?: boolean, cultureMatch?: boolean): CarSearchFormSubmit | null {
		let historyHandle = CarSearchUtil.withOuthPaymentTypeHistoryHandle;
		if (isPathPaymentType) {
			historyHandle = CarSearchUtil.withPaymentTypeHistoryHandle;
		}

		const tempHistory: ResponseCommons<CarSearchFormSubmit> = this.getHistory<CarSearchFormSubmit>(historyHandle, traductions, cultureMatch);
		return tempHistory?.response ?? null;
	}
}
