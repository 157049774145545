import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { ResponseCommons } from '../interfaces/commons.interface';
import { Countries } from '../interfaces/countries.interface';
import { Agency } from '../interfaces/agency.interface';
import { endPoints } from '../end-points/end-points';
import { HttpCall } from './http-call.service';
import AgencyService from './agency.service';
import { AxiosRequestHeaders } from 'axios';

export class CommonsService {
	private readonly _token: string;
	private readonly _historyName: LocalStorageHistoriesNames;
	private readonly _header: AxiosRequestHeaders;
	private readonly _managementApi: string = process.env.REACT_APP_MANAGEMENT_API || '';
	private readonly _searchApi: string = process.env.REACT_APP_SEARCH_API || '';
	private readonly _countriesCDN: string = process.env.REACT_APP_CDN_JSON || '';

	public httpCall: HttpCall = new HttpCall();

	constructor(token: string, name: LocalStorageHistoriesNames) {
		this._token = token;

		this._historyName = name;

		this._header = {
			Authorization: `Bearer ${token}`,
		};
	}

	get managementApi(): string {
		return this._managementApi;
	}

	get searchApi(): string {
		return this._searchApi;
	}

	get header(): AxiosRequestHeaders {
		return this._header;
	}

	public async callAgency(agency?: Agency | null): Promise<ResponseCommons<Agency | null>> {
		try {
			let setAgency: Agency | null | undefined = agency;

			if (!agency) {
				setAgency = await AgencyService.getAgency(this._token);
			}

			const validateAgencyContext: boolean = !!(setAgency && Object.keys(setAgency).length > 0);
			const result: ResponseCommons<Agency | null> = {
				response: (validateAgencyContext ? { ...setAgency } : null) as Agency,
			};

			return result;
		} catch (error: any) {
			return {
				error,
			};
		}
	}

	public getLocalHistory<T>(): T | null {
		const history: string | null = localStorage.getItem(this._historyName);
		return history ? JSON.parse(history) : null;
	}

	public setLocalHistory<T>(history: T): void {
		localStorage.setItem(this._historyName, JSON.stringify(history));
	}

	public getHistory<T>(
		historyHandle: (history?: T | null, traductions?: (label: string) => string, cultureMatch?: boolean) => T,
		traductions?: (label: string) => string,
		cultureMatch: boolean = false,
	): ResponseCommons<T> {
		if (localStorage) {
			try {
				const tempHistory: T | null = this.getLocalHistory<T>();
				return {
					response: historyHandle(tempHistory, traductions, cultureMatch),
				};
			} catch (error) {
				console.error(error);

				return {
					error,
				};
			}
		}

		return {
			error: true,
		};
	}

	public async getCountries(): Promise<Countries[] | null> {
		try {
			const url: string = `${this._countriesCDN}/${endPoints.countries}`;
			const countriesResponse: ResponseHttp<Countries[]> = await this.httpCall.get(url, this.header);
			if (countriesResponse != null) {
				return countriesResponse.response || null;
			} else {
				return null;
			}
		} catch (error) {
			console.error(error);

			return null;
		}
	}
}
