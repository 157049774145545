import { AssistancesLocalStorageInterface } from '../../shared/interfaces/widget-localstorage.interface';
import { AssistancesSearchWidgetProps } from '../../widgets/assistances-search/assistancesSearchWidget';
import { AssistancesService } from '../../shared/services/assistances.service';
import { AssistancesUtil } from '../../shared/utils/assistances-search.util';
import { Agency } from '../../shared/interfaces/agency.interface';
import { endPoints } from '../../shared/end-points/end-points';
import { useCallback, useMemo, lazy, Suspense } from 'react';
import { TFunction } from 'react-i18next';
import { AssistancesSearchFormSubmitInterface, ListInterface, PassengersFieldRestrictionsInterface, Spinner } from '@smartlinks/react-design-system';

const AssistancesSearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system').then(module => {
		return { default: module.AssistancesSearchForm };
	});
});

interface AssistancesSearchTemplateProps extends AssistancesSearchWidgetProps {
	locations: ListInterface[];
	assistancesService: AssistancesService;
	t: TFunction<'translation', undefined>;
}

interface SubmitInterface extends AssistancesSearchFormSubmitInterface {
	angularUrlPath: string;
}

function AssistancesSearch({
	agency,
	locations,
	assistancesService,
	useExternalRedirect,
	t,
	callUrlSearch,
	callShowMobileFields,
}: AssistancesSearchTemplateProps): JSX.Element {
	const passengersRestrictions: PassengersFieldRestrictionsInterface = useMemo(
		() =>
			((tempAgency?: Agency | null): PassengersFieldRestrictionsInterface => {
				return AssistancesUtil.passengersRestrictions(9, 9, 0);
			})(agency),
		[agency],
	);

	const calendarRestriction: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return AssistancesUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const history: AssistancesSearchFormSubmitInterface | null = useMemo(
		() =>
			((tempAssistancesService: AssistancesService): AssistancesSearchFormSubmitInterface | null => {
				return tempAssistancesService.getAssistancesSearchFormHistory(t);
			})(assistancesService),
		[assistancesService, t],
	);

	const searchHandler = useCallback(
		(data: AssistancesSearchFormSubmitInterface): void => {
			const dataForm: SubmitInterface = {
				...data,
				urlPath: '',
				angularUrlPath: AssistancesUtil.mapSearchUrl(data),
			};

			const dataLocalStorage: AssistancesLocalStorageInterface = {
				passengers: data.passengers,
			};

			if (useExternalRedirect) {
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.flightsSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

				createAnchor.click();

				return;
			}

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			assistancesService.setLocalHistory(dataLocalStorage);
		},
		[assistancesService, useExternalRedirect, agency, callUrlSearch],
	);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<AssistancesSearchForm
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={t}
					locations={locations}
					calendarRestriction={calendarRestriction}
					passengersRestrictions={passengersRestrictions}
					history={history}
					emitSubmitSearch={searchHandler}
					emitShowMobileFields={callShowMobileFields}
					widgetStyles={agency?.widgetStyles}
				/>
			</Suspense>
		</div>
	);
}

export default AssistancesSearch;
