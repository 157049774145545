export enum FlightsSearchFormInputsNames {
	destinations = 'destinationsFlightsSearch',
	tripType = 'tripTypeFlightsSearch',
	onlyDirectFlight = 'onlyDirectFlightFlightsSearch',
	passengers = 'passengersFlightsSearch',
	airline = 'airlineFlightsSearch',
	withPromoCode = 'withPromoCodeFlightsSearch',
}

export enum FlightsDestinationsFieldTypes {
	arrivalLocation = 'arrivalLocation',
	departureLocation = 'departureLocation',
}

export enum FlightsSearchFieldsNames {
	airline = 'airlineFilterField',
	destinations = 'destinationsField',
}
