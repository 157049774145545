import { ActivitySearchFormMobile } from '../activities-search-form-mobile/activities-search-form-mobile';
import DestinationField from '../../../shared-components/search/destination-field/destination-field';
import PassengersField from '../../../shared-components/search/passengers-field/passengers-field';
import { InputEventsHandler } from '../../../../shared/interfaces/inputs.interface';
import DatesField from '../../../shared-components/search/dates-field/dates-field';
import useActivitiesSearchForm from '../../shared/hooks/useActivitiesSearchForm';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { ListInterface } from '../../../../shared/interfaces/list.interface';
import { InputType } from '../../../../shared/enums/inputs.enum';
import {
	UseActivitiesSearchFormInterface,
	UseActivitiesSearchFormReturnInterface,
	ActivitiesSearchFormSubmitInterface,
} from '../../shared/interfaces/activities-search-form.interface';
import {
	PassengersFieldRestrictionsInterface,
	CustomTraductionsLabelsPassengersFieldInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import { WidgetStyleInterface } from '../../../../shared/interfaces';
import useWindowWidth from '../../../../shared/hooks/useWidth';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './activities-search-form.module.scss';
import Button from '../../../ui-elements/button/button';
import Input from '../../../ui-elements/input/input';

export interface ActivitiesSearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: (label: string) => string;
	locations?: ListInterface[];
	isLocationsLoading?: boolean;
	calendarRestriction?: number;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	history: ActivitiesSearchFormSubmitInterface | null;
	className?: string;
	widgetStyles?: WidgetStyleInterface;
	emitSubmitSearch: (data: ActivitiesSearchFormSubmitInterface) => void;
	emitLocationsSearch: (name: string) => void;
	emitShowMobileFields?: (show: boolean) => void;
}

export function ActivitiesSearchForm({
	colorPrimary,
	colorSecondary,
	traductions,
	locations,
	isLocationsLoading,
	calendarRestriction,
	passengersRestrictions,
	history,
	className,
	widgetStyles,
	emitSubmitSearch,
	emitLocationsSearch,
	emitShowMobileFields,
}: ActivitiesSearchFormProps): JSX.Element {
	const {
		destinationName,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		passengersName,
		destinationId,
		datesId,
		passengersId,
		mobileFieldStep,
		minDate,
		defaultDates,
		showMobileFieldsContainer,
		passengersAges,
		mobileFormHeight,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		destinationFieldHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		passengersHandler,
		passengersModalInputsHandler,
		passengersAgesHandler,
		onSubmit,
		inputRefs,
	}: UseActivitiesSearchFormReturnInterface = useActivitiesSearchForm({
		history,
		calendarRestriction,
		widgetStyles,
		traductions,
		emitSubmitSearch,
		emitLocationsSearch,
		emitShowMobileFields,
	} as unknown as UseActivitiesSearchFormInterface);

	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement | null>(null);
	const passengersLabels: CustomTraductionsLabelsPassengersFieldInterface = useMemo((): CustomTraductionsLabelsPassengersFieldInterface => {
		return {
			adults: 'passengers',
		};
	}, []);

	const handleSubmitButtonClick = (): void => {
		if (formRef.current) {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
	};

	useEffect(() => {
		setIsMobile(windowWidth < responsiveSize);
	}, [windowWidth]);

	const { isTertiaryTemplate } = widgetStyles || {};
	const agesFieldsElements: JSX.Element[] | boolean =
		!!passengersAges?.length &&
		passengersAges.map((passengerAge: number, ageIndex: number, ages: number[]) => {
			return (
				<div
					key={`${passengersId}_${ageIndex}`}
					className={`activitiesSearchForm__age w-full ${
						ageIndex ? 'md:w-auto md:min-w-1/4 mt-[15px] md:mt-[15px] md:pr-[15px]' : 'md:w-2/5 md:pl-[15px]'
					} ${ages.length === ageIndex + 1 ? 'mb-[15px] md:mb-0' : ''}
						${isTertiaryTemplate ? 'flex flex-col gap-2' : 'md:h-[65px]'}
					`}
				>
					{isTertiaryTemplate && (
						<span className='font-medium whitespace-nowrap text-[13px] text-[#4D4D4F] font-medium'>
							{`${traductions('ageOfPassenger') || 'ageOfPassenger'} ${ageIndex + 1}`}
						</span>
					)}
					<Input
						value={passengerAge.toString()}
						onChange={(event: InputEventsHandler) => passengersAgesHandler(event, ageIndex)}
						type={InputType.number}
						label={isTertiaryTemplate ? '' : `${traductions('ageOfPassenger') || 'ageOfPassenger'} ${ageIndex + 1}`}
						placeholder={traductions('ageOfPassenger') || 'ageOfPassenger'}
						defaultValue={'18'}
						name={`${passengersName}_${ageIndex}`}
						min={'0'}
						max={'120'}
						step={'1'}
						className={`w-full bg-white rounded-[10px] border border-solid py-[10px] px-[25px] flex-col justify-center
							${isTertiaryTemplate ? 'border-[#BEBEBE] rounded-none' : ''}
						`}
						labelClassName={'block text-[10px]'}
						inputClassName={`block w-full bg-white
							${isTertiaryTemplate ? 'py-0 !text-[14px] !text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E] leading-6' : 'text-base'}
						`}
						style={{
							color: colorSecondary,
						}}
					/>
				</div>
			);
		});

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`activitiesSearchForm w-full flex flex-col ${className || ''}`}>
				<div className='activitiesSearchForm__searchButton block md:hidden'>
					<ActivitySearchFormMobile
						colorSecondary={colorSecondary ?? '#878787'}
						traductions={traductions}
						datesId={datesId}
						destinationId={destinationId}
						passengersId={passengersId}
						datesName={datesName}
						destinationName={destinationName}
						passengersName={passengersName}
						showMobileFieldsContainerHandler={showMobileFieldsContainerHandler}
						isSecondaryTemplate={false}
						isTertiaryTemplate={isTertiaryTemplate}
						watch={watch}
						truncate={widgetStyles?.isEnabledTruncatedInputs}
					>
						<div className={'flightsSearchForm__footer flex items-end'}>
							<Button
								type='submit'
								customClass={`flightsSearchForm__searchBtn text-white w-full text-[21px] items-center
									${!isValid ? 'disabled cursor-not-allowed ' : ''}
									${widgetStyles?.isTertiaryTemplate ? 'tertiary__shadow' : 'rounded-full'}`}
								content={<span className={'text-base !text-[14px]'}>{traductions('search') || 'search'}</span>}
								styles={{
									background: isValid ? colorSecondary ?? '#878787' : '#D1D1D1 ',
									color: isValid ? '' : '#9B9B9B',
									pointerEvents: !isValid ? 'none' : 'auto',
								}}
								disabled={!isValid}
								onClick={handleSubmitButtonClick}
							/>
						</div>
					</ActivitySearchFormMobile>
				</div>
				<form
					ref={formRef}
					onSubmit={onSubmit}
					className={`activitiesSearchForm__form w-full top-0 left-0 md:flex flex-col ${
						showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
					}
					${widgetStyles?.isTertiaryTemplate ? 'z-[2] md:z-auto' : ''}`}
					style={{
						height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
					}}
				>
					<div
						className={`activitiesSearchForm__header block md:hidden
							${widgetStyles?.isSecondaryTemplate ? 'py-3 px-6' : 'py-2 px-6 flex justify-end'}
							`}
						style={{
							backgroundColor: '#ffffff',
						}}
					>
						<button
							onClick={() => showMobileFieldsContainerHandler(false, destinationId, destinationName)}
							className={`activitiesSearchForm__backBtn  !p-0
								${widgetStyles?.isSecondaryTemplate ? 'text-sm text-[#475467] font-semibold chevronLeftIco' : 'icon-close-secondary text-base font-bold'}
								`}
							type='button'
						>
							{widgetStyles?.isSecondaryTemplate && <span className='pl-2'>{`${traductions('comeBack') || 'comeBack'}`}</span>}
						</button>
					</div>
					<div
						className={
							'activitiesSearchForm__body bg-white md:bg-transparent left-0 md:top-0 relative md:h-auto px-0 md:pt-0 md:rounded-t-none pt-0 top-0'
						}
					>
						<div className='activitiesSearchForm__content relative'>
							<div className={'activitiesSearchForm__firstRow flex flex-row flex-wrap'}>
								<div className='activitiesSearchForm__destination w-full md:w-3/5 md:pr-[15px]'>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('destination') || 'destination'}
										</span>
									)}
									<DestinationField
										inputRefs={inputRefs}
										fieldId={destinationId}
										name={destinationName}
										hiddenName={destinationHiddenName}
										iconClassName={'pinIco'}
										title={traductions('destination') || 'destination'}
										placeHolder={isMobile ? traductions('selectDestination') ?? 'destination' : traductions('destination') ?? 'destination'}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										showSpinner={isLocationsLoading}
										colorSecondary={colorSecondary}
										className={`${mobileFieldStep === destinationId ? 'z-50' : 'z-0'} md:z-auto
											${isTertiaryTemplate ? 'border-0 md:rounded-none md:border md:border-solid border-[#BEBEBE]' : ''}`}
										required={true}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										useBackButton={false}
										onChange={destinationFieldHandler}
										onShowDestinations={() => undefined}
										nextCallBack={nextMobileFieldHandler}
										destinations={locations}
										isTertiaryTemplate={isTertiaryTemplate}
										isAutomaticHandler={isMobile}
										isMobile={isMobile}
										truncate={widgetStyles?.isEnabledTruncatedInputs}
									/>
								</div>
								<div className='activitiesSearchForm__dates w-full md:w-2/5 relative'>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('selectYourDate') || 'selectYourDate'}
										</span>
									)}
									<DatesField
										fieldId={datesId}
										title={traductions('selectYourDate') || 'selectYourDate'}
										placeHolder={traductions('selectYourDate') || 'selectYourDate'}
										name={datesName}
										hiddenName={datesHiddenName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										buttonText={widgetStyles?.isTertiaryTemplate ? traductions('apply') || 'apply' : traductions('ready') || 'ready'}
										type={DatesFieldTypes.oneDate}
										config={{
											date: true,
											time: false,
										}}
										minDate={minDate}
										defaultDates={defaultDates}
										useBackButton={false}
										iconClassName={isTertiaryTemplate ? 'iconCalendarDepartureTertiary' : 'searchCalendarIco'}
										required={true}
										colorPrimary={colorPrimary}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
										isTertiaryTemplate={isTertiaryTemplate}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										watch={watch}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										onChange={datesHandler}
										onShowDates={() => undefined}
									/>
								</div>
							</div>
							<div className={'activitiesSearchForm__lastRow flex flex-row flex-wrap md:mt-[15px] items-end'}>
								<div className='activitiesSearchForm__room w-full md:w-4/5 relative flex flex-row flex-wrap md:pr-[15px]'>
									<PassengersField
										fieldId={passengersId}
										traductions={traductions}
										title={traductions('passengers') || 'passengers'}
										placeHolder={traductions('passengers') || 'passengers'}
										name={passengersName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										restrictions={passengersRestrictions}
										useBackButton={false}
										required={true}
										customTraductionsLabels={passengersLabels}
										colorSecondary={colorSecondary}
										className={`w-full md:w-3/5 ${mobileFieldStep === passengersId ? 'z-50' : 'z-0'} md:z-auto md:h-[65px]`}
										desktopModalClassName={isTertiaryTemplate ? 'shadow-xl md:flex-col top-[75px] md:w-full md:rounded-t-none px-[15px]' : ''}
										register={register}
										watch={watch}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										visibleTitle={true}
										iconClassName='searchAdultIco'
										isTertiaryTemplate={isTertiaryTemplate}
										disabledInputs={{
											infants: true,
											children: true,
										}}
										onChangePassengers={passengersHandler}
										onModalInputsHandler={passengersModalInputsHandler}
									>
										<div className={'activitiesSearchForm__agesFields w-full flex flex-col md:hidden'}>{agesFieldsElements}</div>
									</PassengersField>
									{agesFieldsElements}
								</div>
								<div
									className={`activitiesSearchForm__footer md:relative md:w-1/5 hidden md:flex
									${isTertiaryTemplate ? 'items-end' : 'md:h-[65px]'}
								`}
								>
									<Button
										type='submit'
										customClass={`activitiesSearchForm__searchBtn text-white w-full text-[21px] items-center
											${isTertiaryTemplate ? 'rounded-none tertiary__shadow' : 'rounded-lg'}
											${isTertiaryTemplate && !isValid ? 'tertiary__disabled' : ''}
										`}
										content={
											<>
												{isTertiaryTemplate ? null : <span className='searchIco text-[25px] font-extrabold' />}
												<span className={`${isTertiaryTemplate ? 'text-base !text-[14px]' : 'text-[21px] font-extrabold pl-[10px]'}`}>
													{traductions('search') || 'search'}
												</span>
											</>
										}
										styles={{
											background: colorSecondary || '#878787',
											padding: isTertiaryTemplate && '11px 5px',
										}}
										disabled={!isValid && isTertiaryTemplate}
									/>
								</div>
							</div>
							<div className={'block md:hidden w-full z-40 bg-white absolute left-0 top-[20px] h-screen'} />
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default ActivitiesSearchForm;
