import { SlideInterface } from '../../../../../../shared/interfaces/generics/slider.interface';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import Slide from '../../../../../shared-components/slide/slide';
import styles from './mobile-reviews-slider.module.scss';
import ReviewsCard from '../reviews-card/reviews-card';

export interface MobileReviewsSliderProps {
	slides: SlideInterface[];
	mainColor?: string;
	secondColor?: string;
	className?: string;
	slideTitleClassName?: string;
	slideSubtitleClassName?: string;
	slideDescriptionClassName?: string;
}

export function MobileReviewsSlider({
	slides,
	mainColor,
	secondColor,
	className,
	slideTitleClassName,
	slideSubtitleClassName,
	slideDescriptionClassName,
}: MobileReviewsSliderProps): JSX.Element {
	const beforeSlideIndex: number = -1;
	const firstSlideIndex: number = 0;
	const minimumSlidesNumber: number = 2;
	const fullWidth: number = 100;
	const lastSlideIndex: number = slides.length - 1;
	const slidesNumber: number = slides.length;
	const imageSize: string = '72px';
	const threshold: number = 50;
	const refContainer = useRef<HTMLDivElement>(null);
	const refSliderContainer = useRef<HTMLDivElement>(null);
	const [posX1, setPosX1] = useState<number>(0);
	const [posInitial, setPosInitial] = useState<number>(0);
	const [isTouched, setIsTouched] = useState<boolean>(false);
	const [indexShift, setIndexShift] = useState<number>(0);
	const [allowShift, setAllowShift] = useState<boolean>(true);
	const customContentSlides = useMemo(
		() =>
			((tempSlides: SlideInterface[], tempSlideActive: number): JSX.Element[] => {
				return [
					...tempSlides.map(
						(slide: SlideInterface, index: number): JSX.Element => (
							<ReviewsCard
								key={index}
								slide={slide}
								isActive={index === tempSlideActive}
								imageSize={imageSize}
								mainColor={mainColor}
								slideTitleClassName={slideTitleClassName}
								slideSubtitleClassName={slideSubtitleClassName}
								slideDescriptionClassName={slideDescriptionClassName}
							/>
						),
					),
				];
			})(slides, indexShift),
		[slides, indexShift],
	);

	const shiftSlide: (dir: number) => void = useCallback(
		(dir: number) => {
			if (refContainer.current && refSliderContainer.current) {
				refContainer.current.classList.add('mobileReviewsSlider__shifting');

				if (allowShift) {
					const step: number = 1;
					if (dir === 1) {
						refContainer.current.style.left = `${posInitial - refSliderContainer.current.offsetWidth}px`;

						setIndexShift((prevState: number) => prevState + step);
					} else if (dir === -1) {
						refContainer.current.style.left = `${posInitial + refSliderContainer.current.offsetWidth}px`;

						setIndexShift((prevState: number) => prevState - step);
					}
				}

				setAllowShift(false);
			}
		},
		[allowShift, posInitial, refContainer, refSliderContainer],
	);

	const dragAction: (e: any) => void = useCallback(
		e => {
			if (refContainer.current) {
				e = e || window.event;

				let tempX2: number = 0;
				if (e.type === 'touchmove') {
					const firstElement: number = 0;
					tempX2 = posX1 - e.touches[firstElement].clientX;

					setPosX1(e.touches[firstElement].clientX);
				} else {
					tempX2 = posX1 - e.clientX;

					setPosX1(e.clientX);
				}

				refContainer.current.style.left = `${refContainer.current.offsetLeft - tempX2}px`;
			}
		},
		[posX1, refContainer],
	);

	const dragEnd: (e: any) => void = useCallback(
		e => {
			if (refContainer.current) {
				if (refContainer.current.offsetLeft - posInitial < -threshold) {
					shiftSlide(1);
				} else if (refContainer.current.offsetLeft - posInitial > threshold) {
					shiftSlide(-1);
				} else {
					refContainer.current.style.left = `${posInitial}px`;
				}

				setIsTouched(false);
			}
		},
		[posInitial, refContainer, shiftSlide],
	);

	const dragStart: (e: any) => void = useCallback(
		e => {
			e = e || window.event;

			e.preventDefault();

			if (refContainer.current) {
				setPosInitial(refContainer.current.offsetLeft);

				if (e.type === 'touchstart') {
					const firstElement: number = 0;
					setPosX1(e.touches[firstElement].clientX);
				} else {
					setPosX1(e.clientX);

					setIsTouched(true);
				}
			}
		},
		[refContainer],
	);

	const checkIndex: () => void = useCallback(() => {
		if (refContainer.current && refSliderContainer.current) {
			const step: number = 1;
			refContainer.current.classList.remove('mobileReviewsSlider__shifting');

			if (indexShift === beforeSlideIndex) {
				refContainer.current.style.left = `${-(slidesNumber * refSliderContainer.current.offsetWidth)}px`;

				setIndexShift(slidesNumber - step);
			}

			if (indexShift === slidesNumber) {
				refContainer.current.style.left = `${-(step * refSliderContainer.current.offsetWidth)}px`;

				setIndexShift(0);
			}

			setAllowShift(true);
		}
	}, [refContainer, indexShift, slidesNumber, refSliderContainer]);

	useEffect(() => {
		if (isTouched) {
			document.addEventListener('mouseup', dragEnd);

			document.addEventListener('mousemove', dragAction);
		} else {
			document.removeEventListener('mouseup', dragEnd);

			document.removeEventListener('mousemove', dragAction);
		}

		return () => {
			document.removeEventListener('mouseup', dragEnd);

			document.removeEventListener('mousemove', dragAction);
		};
	}, [isTouched, dragEnd, dragAction]);

	useEffect(() => {
		if (refContainer.current && refSliderContainer.current) {
			refContainer.current.style.left = `${-refSliderContainer.current.offsetWidth}px`;
		}
	}, [refContainer?.current, refSliderContainer?.current?.offsetWidth]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`mobileReviewsSlider block md:hidden w-full ${className || ''}`}>
				<div className={'overflow-hidden block md:hidden w-full relative'} ref={refSliderContainer}>
					<div
						className={'py-[45px] flex relative top-0'}
						style={{
							width: `${slidesNumber >= minimumSlidesNumber ? (slidesNumber + minimumSlidesNumber) * fullWidth : slidesNumber * fullWidth}%`,
						}}
						onMouseDown={dragStart}
						onTouchStart={dragStart}
						onTouchEnd={dragEnd}
						onTouchMove={dragAction}
						onTransitionEnd={checkIndex}
						ref={refContainer}
					>
						{slidesNumber >= minimumSlidesNumber && (
							<Slide
								key={'first'}
								outClassName={'rounded-xl mx-[10px] w-full float-left relative mobileReviewsSlider__slideTransition mobileReviewsSlider__slide'}
								className={'rounded-xl w-full flex flex-col mobileReviewsSlider__slideTransition mobileReviewsSlider__slide'}
								height={'287px'}
								data={slides[lastSlideIndex]}
								config={{
									showContent: true,
									showLinkButton: false,
									showSubtitleLine: false,
									showBackgroundImage: false,
								}}
								imageClassName='rounded-xl'
								onClick={undefined}
								slideId={lastSlideIndex.toString()}
							>
								{customContentSlides[lastSlideIndex]}
							</Slide>
						)}
						{slides.map((slide: SlideInterface, index: number) => {
							return (
								<Slide
									key={index}
									outClassName={'rounded-xl mx-[10px] w-full float-left relative mobileReviewsSlider__slideTransition mobileReviewsSlider__slide'}
									className={'rounded-xl w-full flex flex-col mobileReviewsSlider__slideTransition mobileReviewsSlider__slide'}
									height={'287px'}
									data={slide}
									config={{
										showContent: true,
										showLinkButton: false,
										showSubtitleLine: false,
										showBackgroundImage: false,
									}}
									imageClassName='rounded-xl'
									onClick={undefined}
									slideId={index.toString()}
								>
									{customContentSlides[index]}
								</Slide>
							);
						})}
						{slidesNumber >= minimumSlidesNumber && (
							<Slide
								key={'last'}
								outClassName={'rounded-xl mx-[10px] w-full float-left relative mobileReviewsSlider__slideTransition mobileReviewsSlider__slide'}
								className={'rounded-xl w-full flex flex-col mobileReviewsSlider__slideTransition mobileReviewsSlider__slide'}
								height={'287px'}
								data={slides[firstSlideIndex]}
								config={{
									showContent: true,
									showLinkButton: false,
									showSubtitleLine: false,
									showBackgroundImage: false,
								}}
								imageClassName='rounded-xl'
								onClick={undefined}
								slideId={firstSlideIndex.toString()}
							>
								{customContentSlides[firstSlideIndex]}
							</Slide>
						)}
					</div>
					{!!slidesNumber && (
						<div className='flex flex-row justify-center items-center mt-10'>
							{new Array(slidesNumber).fill(0).map((item: number, index: number) => {
								const isActive: boolean = index === indexShift;
								return (
									<div
										key={index}
										onClick={() => null}
										className={`mx-1.5 cursor-pointer ${
											isActive ? 'h-px border-4 w-12 rounded-xl' : 'h-2 w-2 rounded-full'
										} mobileReviewsSlider__transition ${isActive ? '' : 'mobileReviewsSlider__slideButton'}`}
										style={{
											backgroundColor: isActive ? mainColor : secondColor,
											borderColor: isActive ? mainColor : secondColor,
										}}
									/>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</>
	);
}

MobileReviewsSlider.defaultProps = {
	className: '',
	slideTitleClassName: '',
	slideSubtitleClassName: '',
	slideDescriptionClassName: '',
};

export default MobileReviewsSlider;
