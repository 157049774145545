import { InputEventsHandler, OptionSelect } from '../../../../../shared/interfaces';
import { Select } from '../../../../ui-elements';

export interface MultiLanguageCurrencySelectProps {
	colorSecondary?: string;
	defaultValue?: string;
	label: string;
	options: OptionSelect[];
	t: (value: string) => string;
	onChange?: (e: InputEventsHandler) => void;
}

export function MultiLanguageCurrencySelect({
	t,
	colorSecondary,
	defaultValue,
	label,
	options,
	onChange,
}: Readonly<MultiLanguageCurrencySelectProps>): JSX.Element {
	return (
		<div className={'multi-language-currency-select w-full my-1 text-xs'} style={{ color: colorSecondary }}>
			<Select
				labelClassName=''
				label={t(label)}
				selectClassName='bg-transparent !p-0 m-0 mt-0 !text-base placeholder:text-base items-center justify-center'
				className={'h-[50px] border border-solid rounded-[10px] text-xs'}
				customSelectClassName='bg-transparent !p-0 m-0  text-xs'
				customSelectPlaceholderClassName='bg-transparent !p-0 text-xs font-normal text-[#C3C7CE]'
				name={label}
				defaultValue={defaultValue}
				options={options || []}
				onChange={onChange}
			/>
		</div>
	);
}

export default MultiLanguageCurrencySelect;
