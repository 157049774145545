import { SlideInterface } from '../../../../../../shared/interfaces/generics/slider.interface';
import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import useSlider from '../../../../../../shared/hooks/useSlider';
import Slide from '../../../../../shared-components/slide/slide';
import styles from './desktop-reviews-slider.module.scss';
import ReviewsCard from '../reviews-card/reviews-card';

export enum SlidePosition {
	zero = '0',
	one = '1',
	two = '2',
}

export interface AnimationSlideStyles {
	id: string;
	style: React.CSSProperties;
}

export interface DesktopReviewsSliderProps {
	slides: SlideInterface[];
	transitionTime?: number;
	mainColor?: string;
	secondColor?: string;
	className?: string;
	slideTitleClassName?: string;
	slideSubtitleClassName?: string;
	slideDescriptionClassName?: string;
}

export function DesktopReviewsSlider({
	slides,
	transitionTime,
	mainColor,
	secondColor,
	className,
	slideTitleClassName,
	slideSubtitleClassName,
	slideDescriptionClassName,
}: DesktopReviewsSliderProps): JSX.Element {
	const spaceSlide: number = 3.5;
	const double: number = 2;
	const widthSlide: number = 31;
	const imageSize: string = '86px';
	const lastSlideIndex: number = slides.length - 1;
	const stepSliderButton: number = 3;
	const slidesMargin: number = 20;
	const sliderSideMargin: number = slidesMargin / 2;
	const { position, moveToPosition } = useSlider(
		lastSlideIndex,
		stepSliderButton,
		stepSliderButton,
		transitionTime,
		0,
		slidesMargin,
		sliderSideMargin,
		false,
	);

	const sliderRef = useRef<HTMLDivElement>(null);
	const [sliderHeight, setSliderHeight] = useState<number>(0);
	const [animationConfig, setAnimationConfig] = useState<AnimationSlideStyles[]>([
		{
			id: SlidePosition.zero,
			style: { right: '0' },
		},
		{
			id: SlidePosition.one,
			style: { right: `-${spaceSlide}%` },
		},
		{
			id: SlidePosition.two,
			style: { right: `-${spaceSlide * double}%` },
		},
	]);

	const [slideActive, setSlideActive] = useState<number>();
	const slideHandler: (slideId: string) => void = useCallback((slideId: string): void => {
		setSlideActive(parseInt(slideId));

		setAnimationConfig((prevState: AnimationSlideStyles[]): AnimationSlideStyles[] => {
			const tempAnimationConfig: AnimationSlideStyles[] = [...prevState];
			let newAnimationConfig: AnimationSlideStyles[];
			const slideIndex: number = tempAnimationConfig.findIndex((item: AnimationSlideStyles): boolean => item.id === slideId);
			if (slideIndex === 0) {
				newAnimationConfig = [
					{
						...tempAnimationConfig[1],
						style: {
							right:
								tempAnimationConfig[1].id === SlidePosition.one
									? `${widthSlide}%`
									: tempAnimationConfig[1].id === SlidePosition.two
										? `${widthSlide * double}%`
										: '0',
						},
					},
					{
						...tempAnimationConfig[0],
						style: {
							right:
								tempAnimationConfig[0].id === SlidePosition.zero
									? `-${widthSlide + spaceSlide}%`
									: tempAnimationConfig[0].id === SlidePosition.two
										? `${widthSlide - spaceSlide}%`
										: `-${spaceSlide}%`,
						},
					},
					{
						...tempAnimationConfig[2],
						style: {
							right:
								tempAnimationConfig[2].id === SlidePosition.zero
									? `-${(widthSlide + spaceSlide) * double}%`
									: tempAnimationConfig[2].id === SlidePosition.one
										? `-${widthSlide + spaceSlide * double}%`
										: `-${spaceSlide * double}%`,
						},
					},
				];
			} else if (slideIndex === 2) {
				newAnimationConfig = [
					{
						...tempAnimationConfig[0],
						style: {
							right:
								tempAnimationConfig[0].id === SlidePosition.one
									? `${widthSlide}%`
									: tempAnimationConfig[0].id === SlidePosition.two
										? `${widthSlide * double}%`
										: '0',
						},
					},
					{
						...tempAnimationConfig[2],
						style: {
							right:
								tempAnimationConfig[2].id === SlidePosition.zero
									? `-${widthSlide + spaceSlide}%`
									: tempAnimationConfig[2].id === SlidePosition.two
										? `${widthSlide - spaceSlide}%`
										: `-${spaceSlide}%`,
						},
					},
					{
						...tempAnimationConfig[1],
						style: {
							right:
								tempAnimationConfig[1].id === SlidePosition.zero
									? `-${(widthSlide + spaceSlide) * double}%`
									: tempAnimationConfig[1].id === SlidePosition.one
										? `-${widthSlide + spaceSlide * double}%`
										: `-${spaceSlide * double}%`,
						},
					},
				];
			} else {
				newAnimationConfig = [...tempAnimationConfig];
			}

			return newAnimationConfig;
		});
	}, []);

	const customContentSlides = useMemo(
		() =>
			((
				tempSlides: SlideInterface[],
				tempPosition: number,
				tempAnimationConfig: AnimationSlideStyles[],
				tempSlideActive: number | undefined,
			): JSX.Element[] => {
				const tempCustomContentSlides: JSX.Element[] = [];
				new Array(Math.ceil(stepSliderButton)).fill(0).forEach((temp: number, index: number): void => {
					const slidePosition: number = tempPosition + index;
					const slide: SlideInterface | undefined = slidePosition < tempSlides.length ? tempSlides[slidePosition] : undefined;
					const config: AnimationSlideStyles | undefined = tempAnimationConfig.find(
						(item: AnimationSlideStyles): boolean => item.id === index.toString(),
					);

					const isActive: boolean = !!config && Number(config.id) === tempSlideActive;
					if (slide && config) {
						tempCustomContentSlides.push(
							<ReviewsCard
								key={`${slidePosition}-${slide.title || ''}-${slide.subtitle || ''}`}
								slide={slide}
								isActive={isActive}
								imageSize={imageSize}
								mainColor={mainColor}
								slideTitleClassName={slideTitleClassName}
								slideSubtitleClassName={slideSubtitleClassName}
								slideDescriptionClassName={slideDescriptionClassName}
							/>,
						);
					}
				});

				return [...tempCustomContentSlides];
			})(slides, position, animationConfig, slideActive),
		[slides, position, animationConfig, slideActive],
	);

	useEffect(() => {
		setSlideActive(Number(animationConfig[1].id));
	}, [position]);

	useEffect(() => {
		if (sliderRef.current) {
			const tempHeight: number = Number(sliderRef.current.offsetHeight);
			setSliderHeight((prevState: number): number => (tempHeight > prevState ? tempHeight : prevState));
		}
	}, [sliderRef?.current?.offsetHeight]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`desktopReviewsSlider hidden md:block w-full ${className || ''}`}>
				<div
					className={'relative flex flex-row items-center w-full'}
					ref={sliderRef}
					style={{
						minHeight: sliderHeight ? `${sliderHeight}px` : undefined,
					}}
				>
					{new Array(Math.ceil(stepSliderButton)).fill(0).map((tempItem: number, index: number) => {
						const tempPosition: number = index + position;
						const slide: SlideInterface | undefined = tempPosition < slides.length ? slides[tempPosition] : undefined;
						const config: AnimationSlideStyles | undefined = animationConfig.find(
							(item: AnimationSlideStyles): boolean => item.id === index.toString(),
						);

						return (
							slide &&
							config && (
								<Slide
									key={`${tempPosition}-${slide.title || ''}-${slide.subtitle || ''}`}
									outClassName={'relative rounded-xl desktopReviewsSlider__slideTransition desktopReviewsSlider__slide'}
									className={'rounded-xl w-full flex flex-col desktopReviewsSlider__slideTransition desktopReviewsSlider__slide'}
									height={slideActive === Number(config.id) ? '317px' : '230px'}
									data={slide}
									config={{
										showContent: true,
										showLinkButton: false,
										showSubtitleLine: false,
										showBackgroundImage: false,
									}}
									imageClassName='rounded-xl'
									onClick={slideHandler}
									slideId={config.id}
									style={{
										...config.style,
										top: '0',
										width: `${widthSlide}%`,
									}}
								>
									{customContentSlides[index]}
								</Slide>
							)
						);
					})}
				</div>
				{slides.length && (
					<div className='flex flex-row justify-center items-center mt-20'>
						{new Array(Math.ceil(slides.length / stepSliderButton)).fill(0).map((item: number, index: number) => {
							const isActive: boolean = position === index * stepSliderButton;
							return (
								<div
									key={index}
									onClick={() => moveToPosition(index * stepSliderButton)}
									className={`mx-1.5 cursor-pointer ${
										isActive ? 'h-px border-4 w-12 rounded-xl' : 'h-2 w-2 rounded-full'
									} desktopReviewsSlider__transition ${isActive ? '' : 'desktopReviewsSlider__slideButton'}`}
									style={{
										backgroundColor: isActive ? mainColor : secondColor,
										borderColor: isActive ? mainColor : secondColor,
									}}
								/>
							);
						})}
					</div>
				)}
			</div>
		</>
	);
}

DesktopReviewsSlider.defaultProps = {
	className: '',
	slideTitleClassName: '',
	slideSubtitleClassName: '',
	slideDescriptionClassName: '',
};

export default DesktopReviewsSlider;
