import { CarInterfaceV2, GroupedCoveragesInterfaceV2, RentalCarInterfaceV2 } from '../../../shared/interfaces/car-recommendation-v2.interface';
import CarSpecialServicesDetail from '../../../components/cars/car-detail/car-special-services/car-special-services-detail';
import { CarsCurrencySelectV2 } from '../../../components/cars/cars-currency-select-v2/cars-currency-select-v2';
import { CarCoveragesDetail } from '../../../components/cars/car-detail/car-coverages/car-coverages-detail';
import { CardAlertDropOff } from '../../../components/cars/card-alert-drop-off/card-alert-drop-off';
import { numberToLocaleWithcurrency } from '../../../../../shared/services/utils.service';
import { InputEventsHandler } from '../../../../../shared/interfaces/inputs.interface';
import { CarResume } from '../../../components/cars/car-detail/car-resume/car-resume';
import { CarsRatesModelsV2 } from '../../../shared/enums/filter-cars-cards-v2.enum';
import { FareSummaryInterface } from 'shared/interfaces/payments/summary.interface';
import { coverageTypeCamel } from '../../../shared/utils/car-coverage-types.util';
import { IcomoonIcon } from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import { DetailSteps } from '../../../../../shared/enums/detail-steps.enum';
import { ModelTypeEnum } from '../../../../../shared/enums/model-type.enum';
import { CarDetailUtil } from '../../../shared/utils/car-detail.util';
import { useState, useCallback, useEffect, useRef } from 'react';
import {
	SpecialServiceInterface,
	SpecialServiceEventHandler,
	TotalSpecialServicesRateInterface,
} from '../../../shared/interfaces/special-services.interface';
import CarDetailSkeleton from './skeleton/car-detail-skeleton';

export interface CarDetailProps {
	step: DetailSteps;
	carRecommendation?: CarInterfaceV2;
	specialServices?: SpecialServiceInterface[];
	colorPrimary?: string;
	coveragesTitle?: string;
	backHistoryLabel?: string;
	callToActionLabel?: string | JSX.Element;
	disabledButton?: boolean;
	zIndexModal?: number;
	extraZIndexModal?: number;
	topModal?: string;
	showChangedPrice?: boolean;
	currencySymbol?: string;
	culture?: string;
	loading?: boolean;
	t?: (item: string) => string;
	disabledCarsCurrencySelectV2?: boolean;
	callBackRecommendation: (carRecommendation: CarInterfaceV2) => void;
	onChangeRate?: (carRecommendation: CarInterfaceV2) => void;
	isActiveMultiLanguage?: boolean;
	language?: string;
}

export function CarDetail({
	step,
	carRecommendation,
	specialServices,
	colorPrimary,
	coveragesTitle,
	backHistoryLabel,
	callToActionLabel,
	disabledButton,
	zIndexModal,
	extraZIndexModal,
	topModal,
	showChangedPrice,
	loading,
	currencySymbol,
	culture,
	t,
	callBackRecommendation,
	disabledCarsCurrencySelectV2,
	onChangeRate,
	isActiveMultiLanguage,
	language,
}: CarDetailProps): JSX.Element {
	t = t as (item: string) => string;

	const carDetailUtil: CarDetailUtil = new CarDetailUtil();
	const showChangedPriceRef = useRef<boolean | undefined>(showChangedPrice);
	const cultureRef = useRef<string | undefined>(culture);
	const [rateType, setRateType] = useState<string>(disabledCarsCurrencySelectV2 ? CarsRatesModelsV2.rate : '');
	const [selectedRate, setSelectedRate] = useState<string>('');
	const [carData, setCarData] = useState<CarInterfaceV2 | undefined>(undefined);
	const [selectedSpecialServices, setSelectedSpecialServices] = useState<SpecialServiceInterface[]>([]);
	const [specialServicesRate, setSpecialServicesRate] = useState<TotalSpecialServicesRateInterface>({
		counter: {
			rate: {
				amount: 0,
				currency: '',
			},
			equivalentRate: {
				amount: 0,
				currency: '',
			},
		},
		online: {
			rate: {
				amount: 0,
				currency: '',
			},
			equivalentRate: {
				amount: 0,
				currency: '',
			},
		},
	});

	const [summary, setSummary] = useState<FareSummaryInterface>({
		pricePerDay: 99999999,
		currency: 'COP',
		subTotal: 99999999,
		total: 99999999,
		extras: 0,
	});

	const [coverage, setCoverage] = useState<string>('');
	const selectHandler = useCallback((event: InputEventsHandler): void => {
		setRateType(event?.value?.toString() || '');
	}, []);

	const resumeButtonHandler = useCallback(
		(carRecommendation: CarInterfaceV2) => {
			const selectedRecommendation: CarInterfaceV2 = { ...carRecommendation };
			selectedRecommendation.specialServices = [...selectedSpecialServices];

			callBackRecommendation({ ...selectedRecommendation });
		},
		[callBackRecommendation, selectedSpecialServices],
	);

	const specialServicesChangeHandler = useCallback(
		(event: SpecialServiceEventHandler[]): void => {
			if (specialServices && specialServices.length) {
				setSpecialServicesRate((prevState: TotalSpecialServicesRateInterface): TotalSpecialServicesRateInterface => {
					const tempTotalRate: TotalSpecialServicesRateInterface = { ...prevState };
					tempTotalRate.online.rate.amount = 0;

					tempTotalRate.online.equivalentRate.amount = 0;

					tempTotalRate.counter.rate.amount = 0;

					tempTotalRate.counter.equivalentRate.amount = 0;

					event.forEach((item: SpecialServiceEventHandler): void => {
						if (item.checked) {
							const temSpecialService: SpecialServiceInterface | undefined = specialServices?.find(
								(specialService: SpecialServiceInterface): boolean => specialService.code === item.code,
							);

							if (temSpecialService) {
								const tempRate: number = temSpecialService.rate.amount * item.counter;
								const tempEquivalentRate: number = temSpecialService.equivalentRate.amount * item.counter;
								if (temSpecialService.isPaidOnline) {
									tempTotalRate.online.rate.amount += tempRate;

									tempTotalRate.online.equivalentRate.amount += tempEquivalentRate;
								} else {
									tempTotalRate.counter.rate.amount += tempRate;

									tempTotalRate.counter.equivalentRate.amount += tempEquivalentRate;
								}
							}
						}
					});

					return { ...tempTotalRate };
				});

				setSelectedSpecialServices((prevState: SpecialServiceInterface[]): SpecialServiceInterface[] => {
					const tempSelectedSpecialServices: SpecialServiceInterface[] = [];
					event.forEach((item: SpecialServiceEventHandler): void => {
						if (item.checked) {
							const temSpecialService: SpecialServiceInterface | undefined = specialServices?.find(
								(specialService: SpecialServiceInterface): boolean => specialService.code === item.code,
							);

							if (temSpecialService) {
								tempSelectedSpecialServices.push({ ...temSpecialService, counter: item.counter });
							}
						}
					});

					return [...tempSelectedSpecialServices];
				});
			}
		},
		[specialServices],
	);

	const backHistory = useCallback((): void => {
		const validateHistory: boolean = history?.back as unknown as boolean;
		if (!validateHistory) {
			window.location.href = '/';

			return;
		}

		history.back();
	}, []);

	const selectedCoverage = useCallback((outPutData: GroupedCoveragesInterfaceV2 | null): void => {
		if (!outPutData) {
			return;
		}

		setCarData((prevState: CarInterfaceV2 | undefined): CarInterfaceV2 | undefined => {
			const tempData: CarInterfaceV2 | undefined = prevState ? { ...prevState } : undefined;
			if (tempData) {
				tempData.rateByRentalCar.forEach(rate => {
					rate.rateByCoverage.forEach(item => {
						item.selected = false;

						if (item.rateCodeType === outPutData.rateCodeType) {
							outPutData.selected = true;

							item = outPutData;
						}
					});
				});

				if (prevState && onChangeRate) {
					onChangeRate(tempData);
				}
			}

			return tempData;
		});
	}, []);

	const resolveSummaryResumen = useCallback(
		(
			recommendation: GroupedCoveragesInterfaceV2,
			businessModel: ModelTypeEnum | string,
			specialServicesRate: TotalSpecialServicesRateInterface,
			selectedRate?: string,
			isAvailabilityInLocalCurrency?: boolean,
		): void => {
			let tempSummary: FareSummaryInterface = {
				pricePerDay: 99999999,
				currency: '',
				subTotal: 99999999,
				total: 99999999,
				extras: 0,
			};
			const rateModelPoints: CarsRatesModelsV2 = isAvailabilityInLocalCurrency ? CarsRatesModelsV2.equivalentRate : CarsRatesModelsV2.rate;
			switch (businessModel) {
				case ModelTypeEnum.CASH:
					if (selectedRate === CarsRatesModelsV2.equivalentRate) {
						tempSummary = {
							pricePerDay: recommendation.equivalentRate.dailyRate,
							currency: recommendation.equivalentRate.currency,
							subTotal: recommendation.equivalentRate.rateFrom,
							total: recommendation.equivalentRate.rateFrom,
							extras: specialServicesRate.counter.equivalentRate.amount + specialServicesRate.online.equivalentRate.amount,
						};
					}

					if (selectedRate === CarsRatesModelsV2.rate) {
						tempSummary = {
							pricePerDay: recommendation.rate.dailyRate,
							currency: recommendation.rate.currency,
							subTotal: recommendation.rate.rateFrom,
							total: recommendation.rate.rateFrom,
							extras: specialServicesRate.counter.rate.amount + specialServicesRate.online.rate.amount,
						};
					}

					break;
				case ModelTypeEnum.POINTS:
					tempSummary = {
						pricePerDay: recommendation.rate?.pointsFrom
							? `${numberToLocaleWithcurrency(recommendation.rate?.pointsFrom)} ${recommendation.rate?.pointsCurrency}`
							: recommendation.rate?.rateFrom,
						currency: '',
						subTotal: '',
						total: '',
						extras: specialServicesRate.counter.rate.amount + specialServicesRate.online.rate.amount,
					};

					break;
				case ModelTypeEnum.POINT_OR_MONEY:
				case ModelTypeEnum.POINT_PLUS_MONEY:
					if (rateModelPoints === CarsRatesModelsV2.equivalentRate) {
						const subTotal: number | string =
							!!recommendation.equivalentRate?.pointsFrom && !!recommendation.equivalentRate?.rateFrom ? recommendation.equivalentRate?.rateFrom : '';

						const total: number | string = recommendation.equivalentRate?.pointsFrom
							? `${numberToLocaleWithcurrency(recommendation.equivalentRate?.pointsFrom)} ${recommendation.equivalentRate?.pointsCurrency}`
							: recommendation.equivalentRate?.rateFrom;

						tempSummary = {
							pricePerDay: '',
							currency: recommendation.equivalentRate?.currency,
							subTotal: showChangedPriceRef.current ? recommendation.equivalentRate?.chargedAmount || 0 : subTotal,
							total: showChangedPriceRef.current
								? `${numberToLocaleWithcurrency(recommendation.equivalentRate?.chargedPoints || 0, '', cultureRef.current)} ${
									recommendation.equivalentRate?.pointsCurrency
								  }`
								: total,
							extras: specialServicesRate.counter.rate.amount + specialServicesRate.online.rate.amount,
						};
					}

					if (rateModelPoints === CarsRatesModelsV2.rate) {
						tempSummary = {
							pricePerDay: '',
							currency: recommendation.rate.currency,
							subTotal: !!recommendation.rate?.pointsFrom && !!recommendation.rate?.rateFrom ? recommendation.rate?.rateFrom : '',
							total: recommendation.rate?.pointsFrom
								? `${numberToLocaleWithcurrency(recommendation.rate?.pointsFrom)} ${recommendation.rate?.pointsCurrency}`
								: recommendation.rate?.rateFrom,
							extras: specialServicesRate.counter.rate.amount + specialServicesRate.online.rate.amount,
						};
					}

					break;
				default:
					break;
			}

			setSummary(tempSummary);

			const code: string = coverageTypeCamel(recommendation.rateCodeType);
			setCoverage(isActiveMultiLanguage && t ? t(code) : recommendation.rateTypeLabel || '');
		},
		[],
	);

	useEffect(() => {
		if (carData && carRecommendation) {
			showChangedPriceRef.current = showChangedPrice;

			cultureRef.current = culture;

			const selecteRent: RentalCarInterfaceV2 | undefined = carRecommendation.rateByRentalCar.find((rate: RentalCarInterfaceV2): boolean =>
				rate.rateByCoverage.some((coverage: GroupedCoveragesInterfaceV2): boolean => !!coverage.selected),
			);

			const selectedCoverage: GroupedCoveragesInterfaceV2 | undefined = selecteRent?.rateByCoverage?.find(
				(coverage: GroupedCoveragesInterfaceV2): boolean => !!coverage.selected,
			);

			if (selecteRent && selectedCoverage) {
				resolveSummaryResumen(selectedCoverage, carData.businessModel, specialServicesRate, rateType, carData?.isAvailabilityInLocalCurrency);
			}
		}
	}, [rateType, carData, specialServicesRate, showChangedPrice]);

	useEffect(() => {
		if (carRecommendation) {
			setSelectedRate(carDetailUtil.getSelectedRate(carRecommendation));

			setCarData({ ...carRecommendation });
		}
	}, [carRecommendation]);

	useEffect(() => {
		if (specialServices && specialServices.length) {
			setSpecialServicesRate((prevState: TotalSpecialServicesRateInterface): TotalSpecialServicesRateInterface => {
				const tempTotalRate: TotalSpecialServicesRateInterface = { ...prevState };
				const firstElement: number = 0;
				tempTotalRate.online.rate.currency = specialServices[firstElement].rate.currency;

				tempTotalRate.online.equivalentRate.currency = specialServices[firstElement].equivalentRate.currency;

				tempTotalRate.counter.rate.currency = specialServices[firstElement].rate.currency;

				tempTotalRate.counter.equivalentRate.currency = specialServices[firstElement].equivalentRate.currency;

				return { ...tempTotalRate };
			});
		}
	}, [specialServices]);

	let stepElement: JSX.Element = <div></div>;
	if (carData) {
		switch (step) {
			case DetailSteps.coverages:
				stepElement = (
					<CarCoveragesDetail
						carRecommendation={carData}
						rateType={rateType}
						coveragesTitle={coveragesTitle}
						colorPrimary={colorPrimary}
						coveragesMobileClassName={'block md:hidden'}
						coveragesDesktopClassName={'hidden md:block'}
						t={t}
						onClick={selectedCoverage}
						showChangedPrice={showChangedPriceRef.current}
						currencySymbol={currencySymbol}
						culture={cultureRef.current}
						isActiveMultiLanguage={isActiveMultiLanguage}
					/>
				);

				break;
			case DetailSteps.specialServices:
				stepElement = (
					<CarSpecialServicesDetail
						specialServices={specialServices || []}
						title={t('specialServicesTitle') || 'specialServicesTitle'}
						description={t('specialServicesDescription') || 'specialServicesDescription'}
						moreInformationText={t('moreInformation') || 'moreInformation'}
						payOnLineText={t('payOnLine') || 'payOnLine'}
						paymentOnArrivalText={t('paymentOnArrival') || 'paymentOnArrival'}
						rateModel={rateType}
						loading={false}
						colorPrimary={colorPrimary}
						zIndexModal={zIndexModal}
						extraZIndexModal={extraZIndexModal}
						topModal={topModal}
						onItemsChange={specialServicesChangeHandler}
					/>
				);
			default:
				break;
		}
	}

	return loading ? (
		<CarDetailSkeleton step={step} />
	) : (
		<div className={'carDetail'}>
			<div className='carDetail__header flex flex-row items-center justify-start'>
				<div className='flex items-center cursor-pointer mb-[20px] md:mb-[35px]' onClick={backHistory}>
					<IcomoonIcon icon={'chevronLeftIco'} className='mr-[10px]' fontSize={16} />
					<p className='carDetail__backHistory text-xs md:text-[21px]'>{backHistoryLabel || ''}</p>
				</div>
			</div>
			<div className='flex flex-col md:flex-row'>
				<div className='w-full md:w-[70%] md:mr-[49px]'>
					{carRecommendation?.hasDropOffDetail && <CardAlertDropOff t={t} />}
					<div className='flex flex-col md:flex-row md:justify-between md:items-center'>
						<p className='carDetail__callToActionText mr-[10px] text-[12px]'>{callToActionLabel || ''}</p>
						<div className='flex justify-end mt-[9px] md:mt-0'>
							{carRecommendation && carRecommendation?.businessModel === ModelTypeEnum.CASH && !disabledCarsCurrencySelectV2 && (
								<CarsCurrencySelectV2 carData={carRecommendation} onChange={selectHandler} defaultValue={selectedRate} />
							)}
						</div>
					</div>
					<div>{stepElement}</div>
				</div>
				<div className='w-full md:w-[30%] '>
					{carRecommendation && (
						<CarResume
							step={step}
							carRecommendation={carData || carRecommendation}
							summary={summary}
							resumeTitle={t('resumeTitle') || 'resumeTitle'}
							pickupLabel={t('pickup') || 'pickup'}
							dropOffLabel={t('dropOff') || 'dropOff'}
							coverageLabel={t('coverage') || 'coverage'}
							summaryTitle={t('summaryTitle') || 'summaryTitle'}
							basePriceTitle={t('basePriceTitle') || 'basePriceTitle'}
							totalTitle={t('totalTitle') || 'totalTitle'}
							subTotalTitle={t('subTotalTitle') || 'subTotalTitle'}
							taxesTitle={t('taxesTitle') || 'taxesTitle'}
							feetitle={t('feetitle') || 'feetitle'}
							discountTitle={t('discountTitle') || 'discountTitle'}
							ivaTitle={t('ivaTitle') || 'ivaTitle'}
							pricePerDayTitle={t('pricePerDay') || 'pricePerDay'}
							btnActionLabel={t('continue') || 'continue'}
							extrasTitle={t('extrasTitle') || 'extrasTitle'}
							totalDaysLabelUnit={t('days') || 'days'}
							totalDaysLabel={t('finalFor') || 'finalFor'}
							disabled={disabledButton}
							coverageText={coverage || ''}
							callBack={resumeButtonHandler}
							t={t}
							showChangedPrice={showChangedPriceRef.current}
							currencySymbol={currencySymbol}
							language={language}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
