export interface IcomoonIconProps {
	icon: string;
	className?: string;
	fontSize?: number;
	color?: string;
	style?: React.CSSProperties;
	onClick?: () => void;
	iconClassName?: string;
}

export const IcomoonIcon = ({ icon, className, fontSize, color, style, iconClassName, onClick }: IcomoonIconProps): JSX.Element => {
	return (
		<div className={`icomoonIco text-center flex flex-row justify-center items-center ${className || ''}`} style={{ ...style }} onClick={onClick}>
			<span
				className={`icomoonIco__icon ${icon} block text-center mx-auto ${iconClassName || ''}`}
				style={{
					fontSize: fontSize ? `${fontSize}px` : undefined,
					color,
				}}
			/>
		</div>
	);
};

IcomoonIcon.defaultProps = {
	className: '',
	iconClassName: '',
};

export default IcomoonIcon;
