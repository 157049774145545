import { FieldValues, UseFormRegister, UseFormReturn, FieldErrorsImpl, DeepRequired } from 'react-hook-form';
import { ListInterface, ListItemInterface } from '../../../../shared/interfaces/list.interface';
import { CarDiscount } from '../../../cars/shared/interfaces/car-discount.interface';
import DiscountsFieldInputs from './discounts-field-inputs/discounts-field-inputs';
import { SearchFieldsNames } from '../../../../shared/enums/search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import { ElementTypes } from '../../../../shared/enums/dynamic-forms.enum';
import IcomoonIcon from '../../../ui-elements/icomoon-icon/icomoon-icon';
import {
	FieldButtonEventHandlerInterface,
	DiscountFieldConfigInterface,
	DiscountFieldInputNamesInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import Backdrop from '../../../ui-elements/backdrop/backdrop';
import Button from '../../../ui-elements/button/button';
import Input from '../../../ui-elements/input/input';
import styles from './discount-field.module.scss';
import { useCallback, useState } from 'react';

export interface DiscountFieldProps {
	fieldId?: string;
	title?: string;
	placeHolder?: string;
	name: string;
	backButtonTraduction?: string;
	nextButtonTraduction?: string;
	discountMajorTitle?: string;
	discountTitle?: string;
	discountPlaceHolder?: string;
	discountExtraPlaceHolder?: string;
	discountHint?: string;
	promotionalCodeTitle?: string;
	promotionalCodePlaceHolder?: string;
	promotionalCodeExtraPlaceHolder?: string;
	promotionalCodeHint?: string;
	rewardsPointsMajorTitle?: string;
	rewardsPointsTitle?: string;
	rewardsPointsPlaceHolder?: string;
	rewardsPointsDescription?: string;
	rewardsPointsExtraDescription?: string | JSX.Element;
	rewardsPointsHint?: string;
	buttonText?: string;
	corporateDiscounts?: OptionSelect[];
	promoCodesWithoutCorporateDiscountCode?: OptionSelect[];
	getPaymentOption?: ListInterface[];
	defaultPaymentType?: string;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	config?: DiscountFieldConfigInterface;
	useBackButton?: boolean;
	showAllTitles?: boolean;
	required?: boolean;
	colorSecondary?: string;
	className?: string;
	desktopDiscountsClassName?: string;
	register: UseFormRegister<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	trigger?: UseFormReturn['trigger'];
	nextCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	backCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	onShowDiscounts?: (show: boolean) => void;
	onChangeDiscounts?: (discountsFieldInputs: CarDiscount) => void | string;
	onChangePaymentType?: (item: ListItemInterface) => void;
	onRemoveHandler?: (name: string) => void;
	visibleTitle?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	truncate?: boolean;
	isHiddenPaymentMethods?: boolean;
	iconClassName?: string;
}

export function DiscountField({
	fieldId,
	title,
	placeHolder,
	name,
	backButtonTraduction,
	nextButtonTraduction,
	discountMajorTitle,
	discountTitle,
	discountPlaceHolder,
	discountExtraPlaceHolder,
	discountHint,
	promotionalCodeTitle,
	promotionalCodePlaceHolder,
	promotionalCodeExtraPlaceHolder,
	promotionalCodeHint,
	rewardsPointsMajorTitle,
	rewardsPointsTitle,
	rewardsPointsPlaceHolder,
	rewardsPointsDescription,
	rewardsPointsExtraDescription,
	rewardsPointsHint,
	buttonText,
	corporateDiscounts,
	promoCodesWithoutCorporateDiscountCode,
	getPaymentOption,
	defaultPaymentType,
	errors,
	config,
	useBackButton,
	showAllTitles,
	required,
	colorSecondary,
	className,
	desktopDiscountsClassName,
	isHiddenPaymentMethods,
	register,
	setValue,
	getValues,
	trigger,
	nextCallBack,
	backCallBack,
	onShowDiscounts,
	onChangeDiscounts,
	onChangePaymentType,
	onRemoveHandler,
	visibleTitle,
	iconClassName,
	isSecondaryTemplate,
	isTertiaryTemplate,
	truncate,
}: DiscountFieldProps): JSX.Element {
	const inputsNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(name);
	const [showDiscounts, setShowDiscounts] = useState<boolean>(false);
	const [showRemoveBtn, setShowRemoveBtn] = useState<boolean>(getValues ? !!getValues(name)?.length : false);
	const [forceSubmitDiscounts, setForceSubmitDiscounts] = useState<boolean>(false);
	const removeHandler = useCallback(
		(event: React.SyntheticEvent) => {
			event?.stopPropagation();

			if (setValue) {
				const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(name);
				if (config) {
					if (config.discount) {
						setValue(tempNames.discountName, '');
					}

					if (config.promotionalCode) {
						setValue(tempNames.promotionalCodeName, '');
					}

					if (config.rewardsPoints) {
						setValue(tempNames.rewardsPointsName, '');
					}
				} else {
					setValue(tempNames.discountName, '');
				}

				setValue(tempNames.name, '');

				if (onRemoveHandler && fieldId) {
					onRemoveHandler(fieldId);
				}
			}

			showDiscountsHandler(false);

			showRemoveBtnHandle();
		},
		[name, config, fieldId, setValue, onRemoveHandler],
	);

	const showDiscountsHandler = useCallback(
		(show: boolean) => {
			if (onShowDiscounts) {
				onShowDiscounts(show);
			}

			setShowDiscounts(show);
		},
		[onShowDiscounts],
	);

	const clickNextHandler = useCallback(async () => {
		if (trigger) {
			const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(name);
			const resultName = await trigger(tempNames.name, { shouldFocus: true });
			let itsOk: boolean = false;
			if (config) {
				let resultDiscountName: boolean = true;
				let resultPromotionalCodeName: boolean = true;
				let resultRewardsPointsName: boolean = true;
				if (config.discount) {
					resultDiscountName = await trigger(tempNames.discountName, { shouldFocus: true });
				}

				if (config.promotionalCode) {
					resultPromotionalCodeName = await trigger(tempNames.promotionalCodeName, { shouldFocus: true });
				}

				if (config.rewardsPoints) {
					resultRewardsPointsName = await trigger(tempNames.rewardsPointsName, { shouldFocus: true });
				}

				itsOk = resultDiscountName && resultPromotionalCodeName && resultRewardsPointsName;
			} else {
				itsOk = await trigger(tempNames.discountName, { shouldFocus: true });
			}

			setForceSubmitDiscounts(true);

			if (resultName && itsOk && nextCallBack) {
				nextCallBack({ id: fieldId || SearchFieldsNames.discount });
			}
		}
	}, [name, fieldId, nextCallBack, trigger]);

	const clickBackHandler = useCallback(() => {
		if (backCallBack) {
			backCallBack({ id: fieldId || SearchFieldsNames.discount });
		}
	}, [fieldId, backCallBack]);

	const changeDiscountsHandler = useCallback(
		(discounts: CarDiscount): void => {
			if (setValue) {
				const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(name);
				let discountDisplayValue: string = '';
				if (!config || config?.discount) {
					discountDisplayValue += `${discounts.corporateDiscount?.label || ''}`;

					setValue(tempNames.discountName, discounts.corporateDiscount?.value);
				}

				if (config?.promotionalCode) {
					discountDisplayValue += `${discountDisplayValue ? ', ' : ''}${discounts.promotionalCode?.label || ''}`;

					setValue(tempNames.promotionalCodeName, discounts.promotionalCode?.value);
				}

				if (config?.rewardsPoints) {
					discountDisplayValue += `${discountDisplayValue ? ', ' : ''}${discounts.rewardsPoints?.label || ''}`;

					setValue(tempNames.rewardsPointsName, discounts.rewardsPoints?.value);
				}

				showDiscountsHandler(false);

				if (onChangeDiscounts) {
					discountDisplayValue = onChangeDiscounts({ ...discounts }) || discountDisplayValue;
				}

				setValue(tempNames.name, discountDisplayValue);

				showRemoveBtnHandle();
			}
		},
		[config?.discount, config?.promotionalCode, config?.rewardsPoints, name, setValue, onChangeDiscounts],
	);

	const showRemoveBtnHandle = useCallback(() => {
		const showRemoveBtn: boolean = getValues ? !!getValues(name)?.length : false;
		setShowRemoveBtn(showRemoveBtn);
	}, [getValues, name]);

	const defaultDiscountsElement: JSX.Element = (
		<DiscountsFieldInputs
			name={name}
			discountDefaultValue={getValues ? '' : undefined}
			promotionalCodeDefaultValue={getValues ? getValues(inputsNames.promotionalCodeName) || '' : ''}
			rewardsPointsDefaultValue={getValues ? getValues(inputsNames.rewardsPointsName) || '' : ''}
			discountMajorTitle={discountMajorTitle}
			discountTitle={discountTitle}
			discountPlaceHolder={discountPlaceHolder}
			discountExtraPlaceHolder={discountExtraPlaceHolder}
			discountHint={discountHint}
			promotionalCodeTitle={promotionalCodeTitle}
			promotionalCodePlaceHolder={promotionalCodePlaceHolder}
			promotionalCodeExtraPlaceHolder={promotionalCodeExtraPlaceHolder}
			promotionalCodeHint={promotionalCodeHint}
			rewardsPointsMajorTitle={rewardsPointsMajorTitle}
			rewardsPointsTitle={rewardsPointsTitle}
			rewardsPointsPlaceHolder={rewardsPointsPlaceHolder}
			rewardsPointsDescription={rewardsPointsDescription}
			rewardsPointsExtraDescription={rewardsPointsExtraDescription}
			rewardsPointsHint={rewardsPointsHint}
			buttonText={buttonText}
			config={config}
			getPaymentOption={getPaymentOption}
			defaultPaymentType={defaultPaymentType}
			corporateDiscounts={corporateDiscounts}
			promoCodesWithoutCorporateDiscountCode={promoCodesWithoutCorporateDiscountCode}
			forceSubmit={forceSubmitDiscounts}
			showAllTitles={showAllTitles}
			colorSecondary={colorSecondary}
			register={register}
			setValue={setValue}
			getValues={getValues}
			required={required}
			callBack={changeDiscountsHandler}
			selectedPaymentType={onChangePaymentType}
			className={`${isSecondaryTemplate ? 'px-[16px] md:p-6 md:py-[25px] ' : 'px-0 py-[39px] md:py-[25px]'}`}
			isSecondaryTemplate={isSecondaryTemplate}
			isTertiaryTemplate={isTertiaryTemplate}
			truncate={truncate}
			isHiddenPaymentMethods={isHiddenPaymentMethods}
		/>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`discountField ${className || ''} ${
					isSecondaryTemplate ? '' : 'bg-white '
				} w-full md:bg-transparent absolute md:relative max-h-screen md:max-h-[none] h-screen md:h-auto left-0 top-0 flex flex-col`}
			>
				<div
					className={`discountField__inputContainer flex-col ${visibleTitle ? '' : ' border-0 border-b md:border border-solid'} ${
						isSecondaryTemplate
							? 'px-[16px] py-[25px] md:px-0 md:py-0 gap-2 discountField__inputContainer-secondaryTemplate'
							: 'bg-white px-[40px] md:px-0 py-[25px] md:py-[10px] md:pr-[10px] rounded-none md:rounded-[10px]'
					} flex flex-col relative
						${isTertiaryTemplate ? 'border-[#BEBEBE] md:rounded-none' : ''}
					
					`}
					onClick={() => showDiscountsHandler(!showDiscounts)}
				>
					{!isTertiaryTemplate && (
						<div className='discountField__title flex flex-row items-center overflow-x-hidden overflow-y-hidden'>
							{!!iconClassName && (
								<div
									className={`discountField__ico flex items-center md:justify-center ${
										visibleTitle ? '' : 'min-w-[20px] w-[20px] md:min-w-[70px] md:w-[70px]'
									}  pointers-none`}
								>
									<span className={`${iconClassName} text-[15px] md:text-[25px]`} style={{ color: colorSecondary }} />
								</div>
							)}
							<span
								className={`${iconClassName ? 'pl-[10px]' : ''} ${
									isSecondaryTemplate ? 'md:text-gray-700 text-sm font-medium leading-5' : 'text-xs text-[#616161] whitespace-pre-wrap'
								}`}
								style={{
									color: isSecondaryTemplate ? undefined : colorSecondary,
								}}
							>
								{title || 'title'}
							</span>
						</div>
					)}
					<div className='discountField__inputsContainer relative w-full flex flex-row items-center'>
						<div className='discountField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
							{(!config || config?.discount) && (
								<Input
									register={register(inputsNames.discountName, { required })}
									required={required}
									type={ElementTypes.text}
									autocomplete={'off'}
								/>
							)}
							{config?.promotionalCode && (
								<Input
									register={register(inputsNames.promotionalCodeName, { required })}
									required={required}
									type={ElementTypes.text}
									autocomplete={'off'}
								/>
							)}
							{config?.rewardsPoints && (
								<Input
									register={register(inputsNames.rewardsPointsName, { required })}
									required={required}
									type={ElementTypes.text}
									autocomplete={'off'}
								/>
							)}
						</div>
						<Input
							readonly={true}
							register={register(inputsNames.name, { required })}
							required={required}
							defaultValue={''}
							isSecondaryTemplate={isSecondaryTemplate}
							type={ElementTypes.text}
							placeholder={placeHolder || 'withOutDiscountPlaceHolder'}
							autocomplete={'off'}
							className={`discountField__input flex-grow ${
								errors[inputsNames.name] ||
								errors[inputsNames.discountName] ||
								errors[inputsNames.promotionalCodeName] ||
								errors[inputsNames.rewardsPointsName]
									? 'border-2 border-red-500 text-red-500'
									: ''
							} ${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'} ${
								showDiscounts ? 'discountField__openModal' : ''
							}`}
							inputClassName={`bg-transparent !p-0 font-normal md placeholder:font-normal md:placeholder ${
								isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base placeholder:text-[#C3C7CE]'
							}
								${isTertiaryTemplate ? 'leading-6 !text-[14px] !text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}`}
							style={{
								color: colorSecondary,
							}}
							truncate={truncate}
							children={
								isSecondaryTemplate ? (
									<button
										className={`discountField__removeBtn inline-block bg-white pl-[5px] cursor-pointer discountField__remove ${
											showRemoveBtn ? '' : 'hidden'
										}`}
										type='button'
										onClick={removeHandler}
									>
										<IcomoonIcon icon='closeIco' fontSize={10} />
									</button>
								) : undefined
							}
						/>
						{!isSecondaryTemplate && (
							<button
								className={`discountField__removeBtn inline-block bg-white pl-[5px] cursor-pointer discountField__remove ${
									showRemoveBtn ? '' : 'hidden'
								}`}
								type='button'
								onClick={removeHandler}
							>
								<IcomoonIcon icon='closeIco' fontSize={10} />
							</button>
						)}
					</div>
				</div>
				{showDiscounts && (
					<>
						<Backdrop
							show={showDiscounts}
							onClick={() => showDiscountsHandler(false)}
							zIndex={10}
							backgroundColor={'transparent'}
							opacity={1}
							className='hidden md:block discountField__backdrop'
							portalBackdrop={true}
							styles={`
                  .discountField__backdrop {
                    display: none;
                  }

                  @media(min-width: 768px) {
                    .discountField__backdrop {
                      display: block;
                    }
                  }
                `}
						/>
						<div
							className={`discountField__desktopDiscounts hidden md:flex w-full md:absolute md:z-[12] md:left-0 rounded-[10px] bg-white 
								${desktopDiscountsClassName || ''} 
								${isSecondaryTemplate ? 'md:top-[105%]' : isTertiaryTemplate ? '!top-[100%] md:w-[100%]' : 'md:w-[375px] md:top-[120%]'}
							`}
						>
							{defaultDiscountsElement}
						</div>
					</>
				)}
				<div className={'discountField__mobileDiscounts w-full md:hidden h-full flex-grow overflow-y-auto'}>{defaultDiscountsElement}</div>
				<div className='discountField__actions w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid'>
					<Button
						onClick={clickNextHandler}
						type='button'
						customClass={`discountField__nextButton my-[15px] w-full flex items-center justify-center text-base text-white ${
							isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'
						}
							${isTertiaryTemplate ? 'rounded-none' : ''}`}
						styles={{
							background: colorSecondary,
						}}
						text={nextButtonTraduction || 'next'}
					/>
					{useBackButton && (
						<Button
							onClick={clickBackHandler}
							type='button'
							customClass={`discountField__backButton my-0 py-0 w-full flex items-center justify-center text-base bg-transparent ${
								isSecondaryTemplate ? 'rounded-full border border-gray-300' : 'rounded-[10px]'
							}
								${isTertiaryTemplate ? 'rounded-none' : ''}`}
							styles={{
								color: colorSecondary,
							}}
							text={backButtonTraduction || 'back'}
						/>
					)}
				</div>
			</div>
		</>
	);
}

DiscountField.defaultProps = {
	required: false,
};

export default DiscountField;
