import { CreditCardUserInterface } from '../../../shared/interfaces/credit-card-user.interface';

export interface CreditCardConsultedProps {
	creditCardUser: CreditCardUserInterface | null;
	t: (label: string) => string;
	franchise: string;
	cardNumber: string;
}

function CreditCardConsulted({ creditCardUser, t, franchise, cardNumber }: CreditCardConsultedProps): JSX.Element {
	const unknownCard: string = 'UNKNOWN';
	const maskedNumber: string = creditCardUser ? `**** **** **** ${cardNumber}` : '';
	const franchiseName: string = franchise !== unknownCard ? `${franchise.toUpperCase()} ` : '';
	return (
		<div className='text-[14px]'>
			<p className='text-[#6D6E71]'>{t('consultedCreditCard')}</p>
			<p className='text-[#2B2B2B]'>
				<span>{franchiseName}</span>
				{maskedNumber}
			</p>
			<p className='text-[#2B2B2B]'>{creditCardUser ? creditCardUser.firstName : ''}</p>
		</div>
	);
}

export default CreditCardConsulted;
