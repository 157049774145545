import { GeoJsonFeaturesInterface, MarkersInterface } from '../interfaces/maps/static-maps.interface';
import { RentalCarCompaniesCode } from '../../lib/cars/shared/interfaces/car-destination.interface';
import { RentalCarLogoInterfaceV2 } from '../../lib/cars/shared/interfaces/index';
import { InteractiveCarMapStyle, StatictMapStyle } from '../../shared/enums';
import { RentalCarsLogosV2 } from '../../lib/cars/shared/enums/index';
import { renderToString } from 'react-dom/server';
import mapboxgl, { LngLatLike } from 'mapbox-gl';

export class MapUtil {
	private map: mapboxgl.Map | null;
	constructor() {
		this.map = null;
	}

	public initStaticMap(
		token: string,
		longitude: number,
		latitude: number,
		width: string = '300',
		height: string = '200',
		color: string = '555555',
		showInColor: boolean = false,
	): JSX.Element {
		const style: string = showInColor ? StatictMapStyle.outdoors : StatictMapStyle.light;
		return (
			<img
				src={`https://api.mapbox.com/styles/v1/mapbox/${style}/static/pin-s+${color}(${longitude},${latitude})/${longitude},${latitude},11,0,0/${width}x${height}?access_token=${token}`}
			/>
		);
	}

	public async initIteractiveCarMap(
		token: string,
		containerId: string,
		longitude: number,
		latitude: number,
		showInColor: boolean = false,
	): Promise<mapboxgl.Map | null | undefined> {
		if (this.map) {
			return;
		}

		const style: string = showInColor ? InteractiveCarMapStyle.outdoors : InteractiveCarMapStyle.light;
		const breakPoint: number = 800;
		const mobileZoom: number = 8;
		const desktopZoom: number = 10;
		const initZoom: number = window.screen.width < breakPoint ? mobileZoom : desktopZoom;
		this.map = new mapboxgl.Map({
			accessToken: token,
			container: containerId,
			style,
			center: [longitude, latitude],
			zoom: initZoom,
		});

		return this.map;
	}

	public async createMarkers(
		mapInstance: mapboxgl.Map,
		markerCallBack: (data: MarkersInterface) => void,
		markers: MarkersInterface[],
		color: string = '#494848',
		markerClass: string = 'marker',
		callBackPopupBtnSelected?: (data: MarkersInterface) => void,
		selectText: string = 'select',
	): Promise<mapboxgl.Marker[]> {
		const mapboxgl: any = await new Promise(resolve => {
			// any because unknow return type on dynamic imports
			import('mapbox-gl').then(
				mapboxgl => {
					resolve(mapboxgl);
				},
				error => {
					console.log(error);

					resolve(null);
				},
			);
		});

		const arrayMarkers: mapboxgl.Marker[] = [];
		markers.forEach(marker => {
			const featureProps: GeoJsonFeaturesInterface = {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [marker.longitude, marker.latitude],
				},
				properties: {
					title: marker.popupMsg,
					description: marker.popupMsg,
				},
			};

			const markElement: HTMLDivElement = document.createElement('div');
			markElement.className = `mapMarker ${markerClass}`;

			markElement.addEventListener('click', () => {
				markerCallBack(marker);
			});

			const firstColor: number = 0;
			const getMainRent: RentalCarLogoInterfaceV2 =
				RentalCarsLogosV2[(marker?.rentalCarsCompaniesCodes as RentalCarCompaniesCode[])[firstColor]?.rentalCarName?.toLocaleLowerCase()];

			const mainRentColor: string = getMainRent ? getMainRent.primaryColor : '#000000';
			const secondaryRentColor: string = getMainRent ? getMainRent.secondaryColor : '#ffffff';
			let rentsHtmlString = '';
			marker?.rentalCarsCompaniesCodes?.forEach((rents: RentalCarCompaniesCode, rentIndex: number) => {
				const jsxToString: string = renderToString(
					RentalCarsLogosV2[rents?.rentalCarName?.toLocaleLowerCase()] && RentalCarsLogosV2[rents?.rentalCarName?.toLocaleLowerCase()].filled,
				);

				rentsHtmlString += `<div key="${rentIndex}" class="interactiveMap__popupRents text-[17px] mr-[4px] text-white">
          ${jsxToString}
          </div>`;
			});

			const createInnerStyles: HTMLStyleElement = document.createElement('style');
			createInnerStyles.innerHTML = `
      .${marker.code}.interactiveMap__popup .mapboxgl-popup-content {
        background: ${color};
      }

      .${marker.code}.interactiveMap__popup .mapboxgl-popup-tip {
        border-top-color: ${color};
      }

      .${marker.code}.interactiveMap__popup.interactiveMap__selected .mapboxgl-popup-content {
        background: ${mainRentColor};
      }

      .${marker.code}.interactiveMap__popup.interactiveMap__selected .mapboxgl-popup-tip {
        border-top-color: ${mainRentColor};
      }

      .${marker.code}.interactiveMap__popup.interactiveMap__selected .interactiveMap__address {
        color: ${secondaryRentColor};
      }

      .${marker.code}.interactiveMap__popup.interactiveMap__selected .interactiveMap__popupRents{
        color: ${secondaryRentColor};
      }
      `;

			const btnElement: HTMLButtonElement = document.createElement('button');
			btnElement.innerHTML = selectText;

			btnElement.className = 'interactiveMap__btnPopup mt-1 p-1 block md:hidden rounded-full text-black';

			const popupElement: HTMLDivElement = document.createElement('div');
			popupElement.className = 'interactiveMap__popupContent flex flex-col text-white text-xs p-2';

			popupElement.innerHTML = `
      <div class="flex justify-center mb-2">
        ${rentsHtmlString.toString()}
      </div>
      <span class="md:hidden interactiveMap__address text-white">${featureProps.properties.description}</span>
      `;

			btnElement.addEventListener('click', () => {
				if (callBackPopupBtnSelected) {
					callBackPopupBtnSelected(marker);
				}
			});

			popupElement.append(createInnerStyles);

			popupElement.append(btnElement);

			popupElement.addEventListener('click', () => {
				markerCallBack(marker);
			});

			const popup: mapboxgl.Popup = new mapboxgl.Popup({
				className: `interactiveMap__popup ${marker.code} ${marker?.selected ? 'interactiveMap__selected' : ''}`,
				closeOnClick: false,
				closeButton: false,
			})
				.setDOMContent(popupElement)
				.setMaxWidth('300px');

			const generateMarker: mapboxgl.Marker = new mapboxgl.Marker(markElement, {
				color: `#${color}`,
			})
				.setLngLat(featureProps.geometry.coordinates as LngLatLike)
				.setPopup(popup)
				.addTo(mapInstance)
				.togglePopup();

			arrayMarkers.push(generateMarker);
		});

		if (document) {
			document.querySelectorAll('.interactiveMap__popup').forEach(mapItem => {
				mapItem.addEventListener('click', e => {
					if (e.currentTarget) {
						document.querySelectorAll('.interactiveMap__popup').forEach(item => {
							item.classList.remove('z-40', 'interactiveMap__selected');
						});

						const divElement: HTMLDivElement = e.currentTarget as HTMLDivElement;
						divElement.classList.add('z-40', 'interactiveMap__selected');
					}
				});
			});
		}

		return arrayMarkers;
	}

	public getColorForMap(colorSecondary: string): string {
		const transforRgb: [string, string, string] = colorSecondary?.replace(/[^\d,]/g, '').split(',') as [string, string, string];
		if (transforRgb.length) {
			return this.rgbToHex(...transforRgb);
		}

		return '#ffffff';
	}

	public interactiveMapId(length: number): string {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return result;
	}

	private rgbToHex(r: string, g: string, b: string): string {
		return ((1 << 24) + (Number(r) << 16) + (Number(g) << 8) + Number(b)).toString(16).slice(1);
	}
}
