import { DiscountFieldConfigInterface, DiscountFieldInputNamesInterface } from '../../../../../shared/interfaces/generics/search-fields.interface';
import { CarDiscount, CarDiscountProp } from '../../../../cars/shared/interfaces/car-discount.interface';
import { InputEventsHandler, OptionSelect } from '../../../../../shared/interfaces/inputs.interface';
import { ListInterface, ListItemInterface } from '../../../../../shared/interfaces/list.interface';
import { SearchFieldsUtil } from '../../../../../shared/utils/search-fields.util';
import { ElementTypes } from '../../../../../shared/enums/dynamic-forms.enum';
import { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import { ToolTipArrowType } from '../../../../../shared/enums/tooltip.enum';
import ToolTip from '../../../../ui-elements/tool-tip/tool-tip';
import Select from '../../../../ui-elements/select/select';
import Button from '../../../../ui-elements/button/button';
import styles from './discounts-field-inputs.module.scss';
import { useState, useEffect, useCallback } from 'react';
import Input from '../../../../ui-elements/input/input';
import List from '../../../../ui-elements/list/list';
import { Checkbox } from '../../../../ui-elements';

export interface DiscountsFieldInputsProps {
	name: string;
	discountDefaultValue?: string;
	promotionalCodeDefaultValue?: string;
	rewardsPointsDefaultValue?: string;
	discountMajorTitle?: string;
	discountTitle?: string;
	discountPlaceHolder?: string;
	discountExtraPlaceHolder?: string;
	discountHint?: string;
	promotionalCodeTitle?: string;
	promotionalCodePlaceHolder?: string;
	promotionalCodeExtraPlaceHolder?: string;
	promotionalCodeHint?: string;
	rewardsPointsMajorTitle?: string;
	rewardsPointsTitle?: string;
	rewardsPointsPlaceHolder?: string;
	rewardsPointsDescription?: string;
	rewardsPointsExtraDescription?: string | JSX.Element;
	rewardsPointsHint?: string;
	buttonText?: string;
	config?: DiscountFieldConfigInterface;
	corporateDiscounts?: OptionSelect[];
	promoCodesWithoutCorporateDiscountCode?: OptionSelect[];
	getPaymentOption?: ListInterface[];
	defaultPaymentType?: string;
	forceSubmit?: boolean;
	showAllTitles?: boolean;
	required?: boolean;
	truncate?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	isHiddenPaymentMethods?: boolean;
	colorSecondary?: string;
	style?: any;
	className?: string;
	register: UseFormRegister<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	callBack?: (discountsFieldInputs: CarDiscount) => void;
	selectedPaymentType?: (item: ListItemInterface) => void;
}

export function DiscountsFieldInputs({
	name,
	discountDefaultValue,
	promotionalCodeDefaultValue,
	rewardsPointsDefaultValue,
	discountMajorTitle,
	discountTitle,
	discountPlaceHolder,
	discountExtraPlaceHolder,
	discountHint,
	promotionalCodeTitle,
	promotionalCodePlaceHolder,
	promotionalCodeExtraPlaceHolder,
	promotionalCodeHint,
	rewardsPointsMajorTitle,
	rewardsPointsTitle,
	rewardsPointsPlaceHolder,
	rewardsPointsDescription,
	rewardsPointsExtraDescription,
	rewardsPointsHint,
	buttonText,
	config,
	corporateDiscounts,
	promoCodesWithoutCorporateDiscountCode,
	getPaymentOption,
	defaultPaymentType,
	forceSubmit,
	showAllTitles,
	required,
	colorSecondary,
	style,
	className,
	isSecondaryTemplate,
	isTertiaryTemplate,
	isHiddenPaymentMethods,
	truncate,
	callBack,
	selectedPaymentType,
	register,
}: DiscountsFieldInputsProps): JSX.Element {
	const inputsNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(name);
	const promotionalCodeCheckbox: string = 'promotionalCodeCheckbox';
	const discountCheckbox: string = 'discountCheckbox';
	const [promoCodeFromCorporate, setPromoCodeFromCorporate] = useState<OptionSelect[]>([]);
	const [isDiscountInputText, setIsDiscountInputText] = useState<boolean>(false);
	const [isPromotionalCodeInputText, setIsPromotionalCodeInputText] = useState<boolean>(false);
	const [discountTextValue, setDiscountTextValue] = useState<string>('');
	const [discountSelectValue, setDiscountSelectValue] = useState<string>('');
	const [promotionalCodeTextValue, setPromotionalCodeTextValue] = useState<string>('');
	const [promotionalCodeSelectValue, setPromotionalCodeSelectValue] = useState<string>('');
	const [rewardsPointsTextValue, setRewardsPointsTextValue] = useState<string>('');
	const settingPromoCodeWithoutCorporateCode = (): void => {
		const withoutselectedCorporateDiscount: boolean = !discountSelectValue || discountSelectValue === '0';
		const withoutselectedPromotionalCode: boolean = !promotionalCodeSelectValue || promotionalCodeSelectValue === '0';
		if (withoutselectedCorporateDiscount && withoutselectedPromotionalCode && promoCodesWithoutCorporateDiscountCode) {
			const promocodes: OptionSelect | undefined = promoCodesWithoutCorporateDiscountCode.find(promocode => promocode.value === '');
			if (promocodes?.dependencies?.length) {
				setPromoCodeFromCorporate(promocodes?.dependencies);
			}
		}
	};

	const changeInputHandler = useCallback(
		(e: InputEventsHandler): void => {
			const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(name);
			if (e.name === tempNames.discountNameText || e.name === tempNames.discountNameSelect) {
				if (isDiscountInputText) {
					setDiscountTextValue(String(e.value || ''));

					setDiscountSelectValue('');
				} else {
					const getCorporateSelected: OptionSelect | undefined = corporateDiscounts?.find(code => code.value === e.value);
					setDiscountSelectValue(String(e.value || ''));

					setDiscountTextValue('');

					if (getCorporateSelected?.dependencies?.length) {
						setPromoCodeFromCorporate(getCorporateSelected?.dependencies);

						setPromotionalCodeSelectValue('');

						setPromotionalCodeTextValue('');
					} else {
						setPromoCodeFromCorporate([]);

						setPromotionalCodeSelectValue('');

						setPromotionalCodeTextValue('');
					}
				}
			} else if (e.name === tempNames.promotionalCodeNameText || e.name === tempNames.promotionalCodeNameSelect) {
				if (isPromotionalCodeInputText) {
					setPromotionalCodeTextValue(String(e.value || ''));

					setPromotionalCodeSelectValue('');
				} else {
					setPromotionalCodeSelectValue(String(e.value || ''));

					setPromotionalCodeTextValue('');
				}
			} else if (e.name === tempNames.rewardsPointsNameText) {
				setRewardsPointsTextValue(String(e.value || ''));
			} else if (e.name === promotionalCodeCheckbox) {
				const promotionalCodeTarget: HTMLInputElement = e?.event?.target as HTMLInputElement;
				setIsPromotionalCodeInputText(promotionalCodeTarget?.checked);

				setPromotionalCodeSelectValue('');

				setPromotionalCodeTextValue('');
			} else if (e.name === discountCheckbox) {
				const discountTarget: HTMLInputElement = e?.event?.target as HTMLInputElement;
				setIsDiscountInputText(discountTarget?.checked);

				setDiscountSelectValue('');

				setDiscountTextValue('');
			}
		},
		[name, corporateDiscounts, isDiscountInputText, isPromotionalCodeInputText],
	);

	const changePaymentTypeHandler = useCallback(
		(item: ListItemInterface): void => {
			if (selectedPaymentType) {
				selectedPaymentType(item);
			}
		},
		[selectedPaymentType],
	);

	const onSubmit = useCallback((): void => {
		let discountData: CarDiscountProp = {
			query: '',
			value: '',
			label: '',
		};
		let promotionalCodeData: CarDiscountProp = {
			query: '',
			value: '',
			label: '',
		};
		let rewardsPointsData: CarDiscountProp = {
			query: '',
			value: '',
			label: '',
		};
		if (!config || config?.discount) {
			const discountValue: string = isDiscountInputText ? discountTextValue : discountSelectValue;
			const discountOption: OptionSelect | undefined = corporateDiscounts?.find((item: OptionSelect): boolean => item.value === discountValue);
			discountData = {
				query: discountValue || '',
				value: discountValue || '',
				label: (discountOption ? discountOption.label : discountValue) || '',
			};
		}

		if (config?.promotionalCode) {
			const promotionalCodeValue: string = isPromotionalCodeInputText ? promotionalCodeTextValue : promotionalCodeSelectValue;
			const promotionalCodeOption: OptionSelect | undefined = promoCodeFromCorporate?.find(
				(item: OptionSelect): boolean => item.value === promotionalCodeValue,
			);

			promotionalCodeData = {
				query: promotionalCodeValue || '',
				value: promotionalCodeValue || '',
				label: (promotionalCodeOption ? promotionalCodeOption.label : promotionalCodeValue) || '',
			};
		}

		if (config?.rewardsPoints) {
			const rewardsPointsValue: string = rewardsPointsTextValue;
			rewardsPointsData = {
				query: rewardsPointsValue || '',
				value: rewardsPointsValue || '',
				label: rewardsPointsValue || '',
			};
		}

		const discountResponse: CarDiscount = {
			corporateDiscount: { ...discountData },
			promotionalCode: { ...promotionalCodeData },
			rewardsPoints: { ...rewardsPointsData },
		};

		if (callBack) {
			callBack({ ...discountResponse });
		}
	}, [
		name,
		config?.discount,
		config?.promotionalCode,
		config?.rewardsPoints,
		corporateDiscounts,
		promoCodeFromCorporate,
		isDiscountInputText,
		isPromotionalCodeInputText,
		discountTextValue,
		discountSelectValue,
		promotionalCodeTextValue,
		promotionalCodeSelectValue,
		rewardsPointsTextValue,
		callBack,
	]);

	useEffect(() => {
		if (forceSubmit) {
			onSubmit();
		}
	}, [forceSubmit]);

	useEffect(() => {
		settingPromoCodeWithoutCorporateCode();
	}, [discountSelectValue, promotionalCodeSelectValue, isDiscountInputText, isPromotionalCodeInputText, promoCodesWithoutCorporateDiscountCode]);

	useEffect(() => {
		if (discountDefaultValue) {
			if (corporateDiscounts?.length && corporateDiscounts?.some((option: OptionSelect) => option.value === discountDefaultValue)) {
				setIsDiscountInputText(false);

				setDiscountTextValue('');

				setDiscountSelectValue(discountDefaultValue);
			} else {
				setIsDiscountInputText(true);

				setDiscountSelectValue('');

				setDiscountTextValue(discountDefaultValue);
			}
		} else {
			setIsDiscountInputText(false);

			setDiscountTextValue('');

			setDiscountSelectValue('');
		}

		if (promotionalCodeDefaultValue) {
			if (promoCodeFromCorporate?.length && promoCodeFromCorporate?.some((option: OptionSelect) => option.value === promotionalCodeDefaultValue)) {
				setIsPromotionalCodeInputText(false);

				setPromotionalCodeTextValue('');

				setPromotionalCodeSelectValue(promotionalCodeDefaultValue);
			} else {
				setIsPromotionalCodeInputText(true);

				setPromotionalCodeSelectValue('');

				setPromotionalCodeTextValue(promotionalCodeDefaultValue);
			}
		} else {
			setIsPromotionalCodeInputText(false);

			setPromotionalCodeTextValue('');

			setPromotionalCodeSelectValue('');

			settingPromoCodeWithoutCorporateCode();
		}

		if (rewardsPointsDefaultValue) {
			setRewardsPointsTextValue(rewardsPointsDefaultValue);
		} else {
			setRewardsPointsTextValue('');
		}
	}, [discountDefaultValue, promotionalCodeDefaultValue, rewardsPointsDefaultValue, corporateDiscounts]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				style={{
					boxShadow: isTertiaryTemplate ? '0px 0px 15px 0px #0000001A' : '',
					'--secondary-color-discount': colorSecondary,
					...style,
				}}
				className={`discountsFieldInputs w-full rounded-lg flex flex-col discountsFieldInputs__container ${className || ''} 
					${
		isSecondaryTemplate
			? ''
			: isTertiaryTemplate
				? 'md:px-[20px] md:rounded-t-none md:border border-solid border-[#BEBEBE]'
				: 'md:py-[25px] md:px-[45px]'
		}
					`}
			>
				{config?.rewardsPoints && (
					<div className='rewardsPointsInput'>
						{!isSecondaryTemplate && (
							<p
								className={`discountsFieldInputs__majorTitle flex flex-col m-0 ${
									isTertiaryTemplate ? 'text-[14px] font-medium pb-[15px]' : 'text-base pb-[20px]'
								}`}
								style={{ color: isTertiaryTemplate ? '#4D4D4F' : colorSecondary }}
							>
								{rewardsPointsMajorTitle || 'rewardsPointsMajorTitle'}
								{rewardsPointsDescription && (
									<span className='carDiscounts__subtitle text-xs text-gray-500 mt-[7px]'>{rewardsPointsDescription}</span>
								)}
							</p>
						)}
						<div className={'discountsFieldInputs__content mb-[15px] flex flex-col'}>
							<div
								className={`flex items-center
									${isSecondaryTemplate ? '' : 'rounded-[10px] border border-[#616161] border-solid'}
									${isTertiaryTemplate ? 'md:rounded-t-none border border-solid border-[#BEBEBE] rounded-none' : 'min-w-[280px]'}`}
							>
								{!isSecondaryTemplate && (
									<>
										<div className='h-[55px] min-h-[55px] w-[60px] min-w-[60px] flex items-center justify-center'>
											<span className='ticketDiscountIco text-[12px] text-[#616161]'></span>
										</div>
										<hr className='h-8 w-px bg-[#616161]' />
									</>
								)}
								<div className={`flex flex-row w-full min-h-[55px] ${isSecondaryTemplate ? '' : 'py-[5px] px-[20px]'}`}>
									<div className={`flex flex-col flex-grow ${isSecondaryTemplate ? 'gap-2' : ''}`}>
										<p
											className={`discountsFieldInputs__title m-0 whitespace-pre-wrap 
												${isSecondaryTemplate ? 'md:text-gray-700 text-sm font-medium leading-5' : 'text-xs text-[#616161]'}
												${isTertiaryTemplate ? '!text-[#4D4D4F] font-medium' : ''}
											`}
										>
											{rewardsPointsTitle || 'rewardsPointsTitle'}
										</p>
										<div className='flex'>
											<Input
												name={inputsNames.rewardsPointsNameText}
												onChange={changeInputHandler}
												value={rewardsPointsTextValue}
												autocomplete={'off'}
												required={required}
												inputClassName={`${
													isTertiaryTemplate
														? 'text-[#4D4D4F] leading-6 placeholder:text-[#9E9E9E] !text-[13px]'
														: 'placeholder:text-[#C3C7CE] placeholder:text-base !text-base'
												} bg-transparent !p-0 font-normal placeholder:font-normal md:placeholder:font-normal `}
												className={`${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'}`}
												type={ElementTypes.text}
												placeholder={rewardsPointsPlaceHolder || 'rewardsPointsPlaceHolder'}
												isSecondaryTemplate={isSecondaryTemplate}
												truncate={truncate}
												toolTip={
													isSecondaryTemplate ? (
														<ToolTip
															title={`<span class=" hidden md:block icon text-sm icon-help-circle relative" style="color: ${'#98A2B3'}"></span>`}
															colorPrimary={'#101828'}
															body={`
															<p class="text-sm font-semibold leading-5 text-white m-0 whitespace-pre-wrap">
																${rewardsPointsPlaceHolder || ''}
															</p>
															`}
															customClass='h-auto relative'
															titleClassName='m-0'
															toolTipCustomClass={'discountsFieldInputs__toolTip !py-2 !px-3 !rounded-lg'}
															arrowType={ToolTipArrowType.left}
															reverse={true}
														/>
													) : undefined
												}
											/>
										</div>
									</div>
									{!isSecondaryTemplate && (
										<div className={'flex items-center pl-[5px]'}>
											<ToolTip
												title={`<span class="discountsFieldInputs__toolTipIcon icon infoCirlce2Ico text-[12px]" style="color: ${
													colorSecondary || ''
												}"></span>`}
												colorPrimary={colorSecondary}
												body={`
													<p class="discountsFieldInputs__toolTipText text-xs text-white m-0 text-center whitespace-normal">
													${rewardsPointsPlaceHolder || ''}
													</p>
												`}
												customClass='h-auto'
												titleClassName='m-0'
												toolTipCustomClass={'discountsFieldInputs__toolTip'}
												arrowType={ToolTipArrowType.left}
												alwaysOpen={!isTertiaryTemplate}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
						{rewardsPointsExtraDescription && (
							<div className='hidden'>
								{typeof rewardsPointsExtraDescription === 'string' ? <p>{rewardsPointsExtraDescription}</p> : rewardsPointsExtraDescription}
							</div>
						)}
					</div>
				)}
				{config?.rewardsPoints && !showAllTitles ? (
					''
				) : (
					<div className={`discountTitle ${isSecondaryTemplate ? 'hidden' : ''}`}>
						<p
							className={`discountsFieldInputs__majorTitle flex flex-col m-0 ${
								isTertiaryTemplate ? 'text-[14px] font-medium pb-[15px]' : 'text-base pb-[20px]'
							}`}
							style={{ color: isTertiaryTemplate ? '#4D4D4F' : colorSecondary }}
						>
							{discountMajorTitle || 'discountMajorTitle'}
						</p>
					</div>
				)}
				{(!config || config?.discount) && (
					<div className='discountInput'>
						<div className={'discountsFieldInputs__content flex flex-col'}>
							<div
								className={`flex items-center 
									${isSecondaryTemplate ? '' : 'rounded-[10px] border border-[#616161] border-solid'}
									${isTertiaryTemplate ? 'md:rounded-t-none border border-solid border-[#BEBEBE] rounded-none' : 'min-w-[280px]'}`}
							>
								{!isSecondaryTemplate && (
									<>
										<div className='h-[55px] min-h-[55px] w-[60px] min-w-[60px] flex items-center justify-center'>
											<span className='circleStartDiscount text-[20px] text-[#616161]'></span>
										</div>
										<hr className='h-8 w-px bg-[#616161]' />
									</>
								)}
								<div className={`flex flex-col w-full min-h-[55px] relative ${isSecondaryTemplate ? 'gap-2' : 'py-[5px] px-[20px]'}`}>
									<p
										className={`discountsFieldInputs__title m-0 whitespace-pre-wrap ${
											isSecondaryTemplate ? 'md:text-gray-700 text-sm font-medium leading-5' : 'text-xs text-[#616161]'
										}
											${isTertiaryTemplate ? '!text-[#4D4D4F] font-medium' : ''}
										`}
									>
										{discountTitle || 'discountTitle'}
									</p>
									<Input
										name={inputsNames.discountNameText}
										onChange={changeInputHandler}
										value={discountTextValue}
										autocomplete={'off'}
										required={required}
										inputClassName={`bg-transparent !p-0 placeholder:font-normal ${
											isSecondaryTemplate
												? 'placeholder:text-[#667085] placeholder:text-md placeholder:leading-6'
												: 'placeholder:text-[#C3C7CE] placeholder:text-base'
										}
											${isTertiaryTemplate ? 'text-[#4D4D4F] leading-6' : '!text-base'}
										`}
										className={`${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'} ${
											isDiscountInputText ? 'block' : 'hidden h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'
										}`}
										type={ElementTypes.text}
										placeholder={discountExtraPlaceHolder || 'DiscountExtraPlaceHolder'}
										isSecondaryTemplate={isSecondaryTemplate}
										truncate={truncate}
									/>
									<Select
										selectClassName='bg-transparent !p-0 !m-0 !text-base placeholder:text-base'
										className={`${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'} ${
											isDiscountInputText ? 'h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]' : ''
										}
											${isTertiaryTemplate ? 'discountField__selectTertiary' : ''}
										`}
										customSelectClassName={`bg-transparent !p-0 m-0 overflow-ellipsis overflow-hidden max-w-full whitespace-pre-wrap
											${isTertiaryTemplate ? 'text-[#4D4D4F] text-[13px]' : 'text-base'}
										`}
										customSelectPlaceholderClassName={`bg-transparent !p-0 font-normal 
											${isSecondaryTemplate ? 'text-[#667085] text-md leading-6' : 'text-xs text-[#C3C7CE]'}
											${isTertiaryTemplate ? '!text-[#9E9E9E]' : ''}
										`}
										customClassOptionsContainer={`${isTertiaryTemplate ? 'md:rounded-t-none text-xs text-[#6D6E71]' : ''}`}
										name={inputsNames.discountNameSelect}
										options={corporateDiscounts || []}
										onChange={changeInputHandler}
										defaultValue={discountSelectValue}
										colorSecondary={colorSecondary}
										placeHolder={discountPlaceHolder || 'discountPlaceHolder'}
										isSecondaryTemplate={isSecondaryTemplate}
										isTertiaryTemplate={isTertiaryTemplate}
										truncate={truncate}
									/>
								</div>
							</div>
							<label>
								<Checkbox
									name={discountCheckbox}
									defaultChecked={isDiscountInputText}
									id={discountCheckbox}
									label={discountHint || 'discountHint'}
									className={`px-0 discountsFieldInputs__forceRow ${isSecondaryTemplate ? 'py-4' : 'py-[10px]'}`}
									onChange={changeInputHandler}
									isSecondaryTemplate={isSecondaryTemplate}
									primaryColor={isSecondaryTemplate ? '#FFFFFF' : colorSecondary}
									secondaryColor={isSecondaryTemplate ? colorSecondary : '#FFFFFF'}
									forcePrimaryColorBorder={true}
									checkmarkContainerSize={16}
									labelClassName={`discountsFieldInputs__labelTitle ${
										isSecondaryTemplate
											? 'text-black text-sm font-normal leading-5'
											: 'text-xs font-normal text-[#616161] order-1 ml-[5px] cursor-pointer'
									}
											${isTertiaryTemplate ? '!text-[#4D4D4F]' : ''}
									`}
									register={register(discountCheckbox)}
									customBorderColorWithoutSelected={isSecondaryTemplate ? '#D0D5DD' : ''}
								/>
							</label>
						</div>
					</div>
				)}
				{config?.promotionalCode && (
					<div className='promotionalCodeInput'>
						<div className={'discountsFieldInputs__content flex flex-col'}>
							<div
								className={`flex items-center 
									${isSecondaryTemplate ? '' : 'rounded-[10px] border border-[#616161] border-solid'}
									${isTertiaryTemplate ? 'md:rounded-t-none border border-solid border-[#BEBEBE] rounded-none' : 'min-w-[280px]'}`}
							>
								{!isSecondaryTemplate && (
									<>
										<div className='h-[55px] min-h-[55px] w-[60px] min-w-[60px] flex items-center justify-center'>
											<span className='squareDiscountIco text-[20px] text-[#616161]'></span>
										</div>
										<hr className='h-8 w-px bg-[#616161]' />
									</>
								)}
								<div className={`flex flex-col w-full min-h-[55px] ${isSecondaryTemplate ? 'gap-2' : 'py-[5px] px-[20px]'}`}>
									<p
										className={`discountsFieldInputs__title m-0 whitespace-pre-wrap ${
											isSecondaryTemplate ? 'md:text-gray-700 text-sm font-medium leading-5' : 'text-xs text-[#616161]'
										}
											${isTertiaryTemplate ? '!text-[#4D4D4F] font-medium' : ''}
										`}
									>
										{promotionalCodeTitle || 'promotionalCodeTitle'}
									</p>
									<Input
										name={inputsNames.promotionalCodeNameText}
										onChange={changeInputHandler}
										value={promotionalCodeTextValue}
										autocomplete={'off'}
										required={required}
										inputClassName={`bg-transparent !p-0 placeholder:font-normal ${
											isSecondaryTemplate
												? 'placeholder:text-[#667085] placeholder:text-md placeholder:leading-6'
												: 'placeholder:text-[#C3C7CE] placeholder:text-base'
										}
											${isTertiaryTemplate ? 'text-[#4D4D4F] leading-6' : '!text-base'}
										`}
										className={`${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'} ${
											isPromotionalCodeInputText ? 'block' : 'hidden h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'
										}`}
										type={ElementTypes.text}
										placeholder={promotionalCodeExtraPlaceHolder || 'promotionalCodeExtraPlaceHolder'}
										isSecondaryTemplate={isSecondaryTemplate}
										truncate={truncate}
									/>
									<Select
										selectClassName='bg-transparent !p-0 !m-0 !text-base'
										className={`${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'} ${
											isPromotionalCodeInputText ? 'h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]' : ''
										}
											${isTertiaryTemplate ? 'discountField__selectTertiary' : ''}
										`}
										customSelectClassName={`bg-transparent !p-0 m-0 overflow-ellipsis overflow-hidden
											${isTertiaryTemplate ? 'text-[#4D4D4F] text-[13px]' : 'text-base'}
										`}
										customSelectPlaceholderClassName={`bg-transparent !p-0 font-normal 
											${isSecondaryTemplate ? 'text-[#667085] text-md leading-6' : 'text-xs text-[#C3C7CE]'}
											${isTertiaryTemplate ? '!text-[#9E9E9E]' : ''}
										`}
										customClassOptionsContainer={`${isTertiaryTemplate ? 'md:rounded-t-none text-xs text-[#6D6E71]' : ''}`}
										name={inputsNames.promotionalCodeNameSelect}
										options={promoCodeFromCorporate || []}
										placeHolder={promotionalCodePlaceHolder || 'promotionalCodePlaceHolder'}
										onChange={changeInputHandler}
										colorSecondary={colorSecondary}
										defaultValue={promotionalCodeSelectValue}
										isSecondaryTemplate={isSecondaryTemplate}
										isTertiaryTemplate={isTertiaryTemplate}
										truncate={truncate}
									/>
								</div>
							</div>
							<label>
								<Checkbox
									name={promotionalCodeCheckbox}
									id={promotionalCodeCheckbox}
									defaultChecked={isPromotionalCodeInputText}
									label={promotionalCodeHint || 'promotionalCodeHint'}
									className={`px-0 discountsFieldInputs__forceRow ${isSecondaryTemplate ? 'py-4' : 'py-[10px]'}`}
									onChange={changeInputHandler}
									isSecondaryTemplate={isSecondaryTemplate}
									primaryColor={isSecondaryTemplate ? '#FFFFFF' : colorSecondary}
									secondaryColor={isSecondaryTemplate ? colorSecondary : '#FFFFFF'}
									forcePrimaryColorBorder={true}
									checkmarkContainerSize={16}
									labelClassName={`discountsFieldInputs__labelTitle ${
										isSecondaryTemplate
											? 'text-black text-sm font-normal leading-5'
											: 'text-xs font-normal text-[#616161] order-1 ml-[5px] cursor-pointer'
									}
										${isTertiaryTemplate ? '!text-[#4D4D4F]' : ''}
									`}
									register={register(promotionalCodeCheckbox)}
									customBorderColorWithoutSelected={isSecondaryTemplate ? '#D0D5DD' : ''}
								/>
							</label>
						</div>
					</div>
				)}
				{config?.paymentTypes && (
					<div
						className={`paymentTypesInput border-0 border-t border-solid border-[#7A8083] mt-[5px] pt-[20px] pb-[10px] ${
							isHiddenPaymentMethods ? 'hidden' : ''
						}`}
					>
						<List
							activeItemId={defaultPaymentType}
							activeColor={colorSecondary || ''}
							className='w-full !p-0'
							withPointer={true}
							ulClassName={'pl-0 pt-0 pr-0 pb-0 mb-0 flex flex-row justify-around'}
							itemsClassName='text-xs'
							liClassName={'mb-0 px-6 py-2 hover:bg-gray-200 rounded-full flex items-center border border-solid'}
							items={getPaymentOption || []}
							callBack={changePaymentTypeHandler}
							itemsStyle={{
								borderColor: colorSecondary,
								color: colorSecondary,
							}}
						/>
					</div>
				)}
				<div className='discountsFieldInputs__buttonContainer'>
					<Button
						customClass={`discountsFieldInputs__addBtn text-white  mt-[5px] w-full 
							${isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'}
							${isTertiaryTemplate ? 'md:rounded-none' : ''}
						`}
						styles={{ backgroundColor: colorSecondary }}
						onClick={onSubmit}
						text={buttonText || 'buttonText'}
					/>
				</div>
			</div>
		</>
	);
}

export default DiscountsFieldInputs;
