import { DestinationFieldRefHandle } from '../../../shared-components/search/destination-field/destination-field';
import { InputEventsHandler, DatePickerData, OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { DisabledPaymentTypeCarsSearchFormInterface } from '../interfaces/car-search-form.interface';
import { useState, useEffect, useCallback, useRef, useMemo, useImperativeHandle } from 'react';
import { ListItemInterface, ListInterface } from '../../../../shared/interfaces/list.interface';
import { SearchLocationsProps } from '../../../../shared/interfaces/maps/static-maps.interface';
import { CarSearchFormRefHandle } from '../../organisms/cars/car-search-form/car-search-form';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { CarDiscount, CarDiscountProp } from '../interfaces/car-discount.interface';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { CarSearchPaymentType } from '../enums/car-search-payment-type.enum';
import { CarSearchFormSubmit, CarSearchTraductions } from '../interfaces';
import { ToolTipArrowType } from '../../../../shared/enums/tooltip.enum';
import {
	FieldButtonEventHandlerInterface,
	DatesFieldInputNamesInterface,
	DisplayValuesDatesFieldInterface,
	DiscountFieldInputNamesInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import useWindowWidth from '../../../../shared/hooks/useWidth';
import { CarSearchUtil } from '../utils/car-search.util';
import {
	SearchFieldsNames,
	CarSearchFormInputsNames,
	SearchDatesFieldsNames,
	CarDestinationsFieldTypes,
	CarSearchFieldsNames,
	DatesFieldTypes,
} from '../../../../shared/enums/search-fields.enum';
import {
	useForm,
	FieldValues,
	UseFormRegister,
	UseFormGetValues,
	UseFormSetValue,
	UseFormTrigger,
	UseFormWatch,
	FieldErrorsImpl,
	DeepRequired,
} from 'react-hook-form';

export interface UseCarSearchFormReturnInterface {
	departureName: string;
	departureHiddenName: string;
	arrivalName: string;
	arrivalHiddenName: string;
	otherPlaceName: string;
	paymentTypeName: string;
	datesName: string;
	datesHiddenName: string;
	discountName: string;
	hertzDiscountName: string;
	departureId: SearchFieldsNames;
	arrivalId: SearchFieldsNames;
	datesId: SearchFieldsNames;
	datesDateId: SearchDatesFieldsNames;
	datesTimeId: SearchDatesFieldsNames;
	discountId: SearchFieldsNames;
	hertzDiscountId: CarSearchFieldsNames;
	buttonSearchHistory: ButtonSearchHistory | undefined;
	paymentType: string;
	paymentOptions: ListInterface[];
	minDate: Date;
	defaultDates: DatePickerDefaultData;
	departureFieldRef: React.RefObject<DestinationFieldRefHandle>;
	arrivalFieldRef: React.RefObject<DestinationFieldRefHandle>;
	showOtherPlaceForArrival: boolean;
	showMobileFieldsContainer: boolean;
	mobileFieldStep: SearchFieldsNames;
	dateMobileFieldStep: SearchDatesFieldsNames | undefined;
	mobileFormHeight: number;
	showMobileFieldsContainerHandler: (show: boolean, fieldId?: string) => void;
	onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
	nextMobileFieldHandler: (event: FieldButtonEventHandlerInterface) => void;
	backMobileFieldHandler: (event: FieldButtonEventHandlerInterface) => void;
	otherPlaceForArrivalHandler: (element: { target: HTMLInputElement; form: HTMLFormElement }) => void;
	changePaymentTypeHandler: (event: any) => void;
	emitHandler: (element: { target: HTMLInputElement; form: HTMLFormElement }) => void;
	destinationsOtherPlaceForArrivalHandler: (checked: boolean) => void;
	openDepartureLocalitiesHandler: () => void;
	openArrivalLocalitiesHandler: () => void;
	selectedDestination: (item: ListItemInterface, name: string) => void;
	changeDatesHandler: (event: InputEventsHandler) => void;
	changeHertzDiscountHandler: ({ rewardsPoints }: CarDiscount) => string;
	getDiscountDisplayValue: ({ corporateDiscount, promotionalCode, rewardsPoints }: CarDiscount) => string;
	changeDiscountPaymentTypeHandler: (item: ListItemInterface) => void;
	removeRewardsPointsDiscountValue: (fieldId: string) => void;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	isValid: boolean;
	register: UseFormRegister<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	trigger: UseFormTrigger<FieldValues>;
	watch: UseFormWatch<FieldValues>;
}

export interface UseCarSearchFormInterface {
	ref: React.ForwardedRef<CarSearchFormRefHandle>;
	traductions: CarSearchTraductions;
	defaultHour: string;
	calendarRestriction?: number;
	carsInSitePayment: boolean;
	corporateDiscountDefault: OptionSelect;
	promoCodeWithoutCorporateDiscountCodeDefault: OptionSelect;
	carHistory: CarSearchFormSubmit | null;
	disabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface;
	isNotHome?: boolean;
	openLocalities?: (value: string) => void;
	emitOptionActive?: (data: boolean) => void;
	emitInput?: (data?: string | undefined) => void;
	emitSubmitSearch?: (data?: CarSearchFormSubmit | undefined) => void;
	emitShowMobileFields?: (show: boolean) => void;
	language?: string;
	isSpreadForm?: boolean;
}

const useCarSearchForm = ({
	ref,
	traductions,
	defaultHour,
	calendarRestriction,
	carsInSitePayment,
	carHistory,
	corporateDiscountDefault,
	promoCodeWithoutCorporateDiscountCodeDefault,
	isNotHome,
	disabledPaymentType,
	openLocalities,
	emitOptionActive,
	emitInput,
	emitSubmitSearch,
	emitShowMobileFields,
	language,
	isSpreadForm,
}: UseCarSearchFormInterface): UseCarSearchFormReturnInterface => {
	const departureName: string = CarSearchFormInputsNames.departureLocation;
	const departureHiddenName: string = CarSearchFormInputsNames.iataDepartureLocation;
	const arrivalName: string = CarSearchFormInputsNames.arrivalLocation;
	const arrivalHiddenName: string = CarSearchFormInputsNames.iataArrivalLocation;
	const otherPlaceName: string = CarSearchFormInputsNames.otherPlaceForArrival;
	const paymentTypeName: string = CarSearchFormInputsNames.paymentType;
	const datesName: string = CarSearchFormInputsNames.dates;
	const datesHiddenName: string = CarSearchFormInputsNames.hiddenDates;
	const discountName: string = CarSearchFormInputsNames.discount;
	const hertzDiscountName: string = CarSearchFormInputsNames.hertzDiscount;
	const departureId: SearchFieldsNames = SearchFieldsNames.departureDestination;
	const arrivalId: SearchFieldsNames = SearchFieldsNames.arrivalDestination;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const datesDateId: SearchDatesFieldsNames = SearchDatesFieldsNames.date;
	const datesTimeId: SearchDatesFieldsNames = SearchDatesFieldsNames.time;
	const discountId: SearchFieldsNames = SearchFieldsNames.discount;
	const hertzDiscountId: CarSearchFieldsNames = CarSearchFieldsNames.hertzDiscount;
	const windowWidth: number = useWindowWidth();
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm();

	const [mobileStep, setMobileStep] = useState<SearchFieldsNames>(SearchFieldsNames.departureDestination);
	const [dateMobileStep, setDateMobileStep] = useState<SearchDatesFieldsNames | undefined>();
	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [showOtherPlaceForArrival, setShowOtherPlaceForArrival] = useState<boolean>(true);
	const [paymentType, setPaymentType] = useState<string>('');
	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [minDate, setMinDate] = useState<Date>(new Date());
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
		endDate: new Date(),
		startTime: defaultHour,
		endTime: defaultHour,
	});

	const paymentOptions: ListInterface[] = useMemo(
		() =>
			((
				tempTraductions?: CarSearchTraductions,
				tempWindowWidth?: number,
				tempDisabledPaymentType?: DisabledPaymentTypeCarsSearchFormInterface,
			): ListInterface[] => {
				if (tempTraductions) {
					let paymentOption: ListInterface[] = [];
					if (tempWindowWidth && tempWindowWidth <= 800) {
						paymentOption = CarSearchUtil.paymentTypeList(tempTraductions, true, ToolTipArrowType.top, tempDisabledPaymentType) as ListInterface[];
					} else {
						paymentOption = CarSearchUtil.paymentTypeList(tempTraductions, true, undefined, tempDisabledPaymentType) as ListInterface[];
					}

					return paymentOption && paymentOption.length ? [...paymentOption] : [];
				}

				return [];
			})(traductions, windowWidth, disabledPaymentType),
		[traductions, windowWidth, disabledPaymentType],
	);

	const departureFieldRef = useRef<DestinationFieldRefHandle>(null);
	const arrivalFieldRef = useRef<DestinationFieldRefHandle>(null);
	const mobileFormHeight: number = useWindowHeight();
	useImperativeHandle(ref, () => ({
		setDestinations(input: string | CarDestinationsFieldTypes, office: SearchLocationsProps) {
			if (input === CarDestinationsFieldTypes.departureLocation) {
				setValue(departureName, office.name || '');

				setValue(departureHiddenName, office.code || '');

				if (departureFieldRef?.current?.forceCloseDestinations) {
					departureFieldRef.current.forceCloseDestinations();
				}
			} else if (input === CarDestinationsFieldTypes.arrivalLocation) {
				setValue(arrivalName, office.name || '');

				setValue(arrivalHiddenName, office.code || '');

				if (arrivalFieldRef?.current?.forceCloseDestinations) {
					arrivalFieldRef.current.forceCloseDestinations();
				}
			}

			if (emitOptionActive) {
				emitOptionActive(false);
			}
		},
	}));

	const showMobileFieldsContainerHandler = useCallback((show: boolean, fieldId?: string): void => {
		if (fieldId) {
			setMobileStep(fieldId as SearchFieldsNames);
		} else {
			setMobileStep(departureId);
		}

		setShowContainer(show);
	}, []);

	const emitHandler = useCallback(
		(element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
			if (emitInput) {
				emitInput(element.target.value);
			}
		},
		[emitInput],
	);

	const otherPlaceForArrival = useCallback(
		(value: boolean): void => {
			setShowOtherPlaceForArrival(value);

			if (!value && arrivalName && arrivalHiddenName) {
				setValue(arrivalName, '');

				setValue(arrivalHiddenName, '');
			}
		},
		[arrivalName, arrivalHiddenName, setValue],
	);

	const otherPlaceForArrivalHandler = useCallback(
		(element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
			otherPlaceForArrival(element.target.checked);
		},
		[otherPlaceForArrival],
	);

	const destinationsOtherPlaceForArrivalHandler = useCallback(
		(checked: boolean): void => {
			otherPlaceForArrival(!checked);

			setValue(otherPlaceName, !checked);
		},
		[otherPlaceForArrival],
	);

	const openDepartureLocalitiesHandler = useCallback((): void => {
		if (openLocalities) {
			openLocalities(CarDestinationsFieldTypes.departureLocation);
		}
	}, [openLocalities]);

	const openArrivalLocalitiesHandler = useCallback((): void => {
		if (openLocalities) {
			openLocalities(CarDestinationsFieldTypes.arrivalLocation);
		}
	}, [openLocalities]);

	const selectedDestination = useCallback(
		async (item: ListItemInterface, name: string): Promise<void> => {
			if (name === departureName) {
				const valueName: string = (item.originalName as string) || (item.name as string);
				setValue(departureName, valueName);

				setValue(departureHiddenName, item?.id || '');

				assignValueArrival(valueName, item?.id || '');

				await trigger(departureName);

				await trigger(departureHiddenName);

				if (departureFieldRef?.current?.forceCloseDestinations) {
					departureFieldRef.current.forceCloseDestinations();
				}
			} else if (name === arrivalName) {
				setValue(arrivalName, item.originalName || item.name);

				setValue(arrivalHiddenName, item?.id || '');

				await trigger(arrivalHiddenName);

				await trigger(arrivalName);

				if (arrivalFieldRef?.current?.forceCloseDestinations) {
					arrivalFieldRef.current.forceCloseDestinations();
				}
			}

			if (emitOptionActive) {
				emitOptionActive(false);
			}
		},
		[departureName, arrivalName, departureFieldRef, arrivalFieldRef, setValue, emitOptionActive],
	);

	const changeDatesHandler = useCallback(
		(event: InputEventsHandler): void => {
			const getData: DatePickerData = event.value as DatePickerData;
			setDefaultDates({
				startDate: getData.startDate,
				endDate: getData.endDate,
				startTime: getData.startTime,
				endTime: getData.endTime,
			});
		},
		[emitInput],
	);

	const changeHertzDiscountHandler = useCallback(
		({ rewardsPoints }: CarDiscount): string => {
			const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(discountName);
			const validateHertzValue: string = (rewardsPoints?.value as string) || '';
			if (rewardsPoints?.query) {
				setValue(tempNames.rewardsPointsName, validateHertzValue);
			}

			// setValue(hertzDiscountName, validateHertzValue);
			return validateHertzValue;
		},
		[hertzDiscountName, discountName, watch, setValue],
	);

	const getDiscountDisplayValue = useCallback(
		({ corporateDiscount, promotionalCode, rewardsPoints }: CarDiscount): string => {
			const hertzOnMobile: number = 800;
			const validateScreen: boolean = window.screen.width <= hertzOnMobile;
			const validateHertzValue: string = validateScreen ? (rewardsPoints?.value as string) : '';
			let discountDisplayValue: string = '';
			if (validateScreen) {
				const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(hertzDiscountName);
				if (rewardsPoints?.query) {
					setValue(tempNames.rewardsPointsName, rewardsPoints?.query);
				}

				setValue(tempNames.name, `${validateHertzValue || ''}`);

				discountDisplayValue = `${validateHertzValue || (corporateDiscount?.value ? corporateDiscount.label : promotionalCode?.label) || ''}`;
			} else {
				const corporateDiscountLabel: string = corporateDiscount?.value ? corporateDiscount?.label || '' : validateHertzValue;
				const promotionalCodeLabel: string = promotionalCode?.label || '';
				discountDisplayValue = `${corporateDiscountLabel}${corporateDiscountLabel && promotionalCodeLabel ? ', ' : ''}${promotionalCodeLabel}`;
			}

			return discountDisplayValue;
		},
		[hertzDiscountName, setValue],
	);

	const removeRewardsPointsDiscountValue = useCallback(
		(fieldId: string): void => {
			if (fieldId === hertzDiscountId) {
				const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(discountName);
				setValue(tempNames.rewardsPointsName, '');
			} else if (fieldId === discountId) {
				const tempNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(hertzDiscountName);
				const hertzOnMobile: number = 800;
				const validateScreen: boolean = window.screen.width <= hertzOnMobile;
				if (validateScreen) {
					setValue(tempNames.rewardsPointsName, '');

					setValue(tempNames.rewardsPointsName, '');

					setValue(tempNames.name, '');
				}
			}
		},
		[hertzDiscountName, discountName, setValue],
	);

	const changePaymentTypeHandler = useCallback(
		(event): void => {
			setValue(paymentTypeName, event?.target?.value || '');

			setPaymentType(event?.target?.value || '');
		},
		[setValue],
	);

	const changeDiscountPaymentTypeHandler = useCallback(
		(item: ListItemInterface): void => {
			setValue(paymentTypeName, item.id);

			setPaymentType(item.id);

			if (emitOptionActive) {
				emitOptionActive(false);
			}
		},
		[setValue],
	);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
			const tempDiscountNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(discountName);
			const tempHertzDiscountNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(hertzDiscountName);
			const results: CarSearchFormSubmit = {
				corporate_discount: data[tempDiscountNames.discountName] || '',
				dates: data[datesName] || '',
				datesArrivalDate: data[tempDatesNames.endDate] || '',
				datesArrivalHour: data[tempDatesNames.endTime] || '',
				datesDepartureDate: data[tempDatesNames.startDate] || '',
				datesDepartureHour: data[tempDatesNames.startTime] || '',
				departureLocation: data[departureName] || '',
				departureLocationIata: data[departureHiddenName] || '',
				arrivalLocation: data[otherPlaceName] ? data[arrivalName] || '' : undefined,
				arrivalLocationIata: data[otherPlaceName] ? data[arrivalHiddenName] || '' : undefined,
				discount: data[discountName] || '',
				paymentType: data[paymentTypeName] || '',
				promotional_code: data[tempDiscountNames.promotionalCodeName] || '',
				rewards_points: data[tempHertzDiscountNames.rewardsPointsName] || data[tempDiscountNames.rewardsPointsName] || '',
				urlPath: undefined,
				arrivalLocationOriginal: undefined,
				departureLocationOriginal: undefined,
				rewards_points_preview: undefined,
			};

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.startEndDates,
				{
					date: true,
					time: true,
				},
				results?.datesDepartureDate,
				results?.datesDepartureHour,
				results?.datesArrivalDate,
				results?.datesArrivalHour,
				undefined,
				language,
			);

			setButtonSearchHistory({
				departure: results?.departureLocationIata || '',
				arrival: results?.arrivalLocationIata || '',
				departureDate: datesValues.largeStartDate || '',
				arrivalDate: datesValues.largeEndDate || '',
			});

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[emitSubmitSearch, language],
	);

	const assignValueArrival = (value: string, valueHidden: string): void => {
		const valueArrival: string = getValues(arrivalName) || '';
		const valueArrivalHiddenName: string = getValues(arrivalHiddenName) || '';
		const isValueDeparture: boolean = !!value && !!valueHidden;
		if (valueArrival && valueArrivalHiddenName) return;

		setValue(arrivalName, value);

		setValue(arrivalHiddenName, valueHidden);

		setValue(otherPlaceName, isValueDeparture);
	};

	const nextDepartureHandler = (): void => {
		const valueDeparture: string = getValues(departureName) || '';
		const valueDepartureHiddenName: string = getValues(departureHiddenName) || '';
		assignValueArrival(valueDeparture, valueDepartureHiddenName);

		setShowContainer(false);

		if (isSpreadForm) {
			setShowContainer(false);
		}
	};

	const nextArrivalHandler = (): void => {
		if (isSpreadForm) {
			setShowContainer(false);
		}

		setMobileStep(datesId);

		setDateMobileStep(datesDateId);
	};

	const nextDatesHandler = (): void => {
		if (isSpreadForm) {
			setShowContainer(false);
		}

		setMobileStep(discountId);

		setDateMobileStep(datesDateId);
	};

	const nextDiscountHandler = (): void => {
		const delayTime: number = 500;
		setTimeout(() => {
			if (!isSpreadForm) {
				void onSubmit();
			}

			setShowContainer(false);

			setMobileStep(departureId);
		}, delayTime);
	};

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (event.id === departureId) {
				nextDepartureHandler();
			} else if (event.id === arrivalId) {
				nextArrivalHandler();
			} else if (event.id === datesId) {
				nextDatesHandler();
			} else if (event.id === discountId) {
				nextDiscountHandler();
			}
		},
		[onSubmit, showOtherPlaceForArrival],
	);

	const backMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (event.id === arrivalId) {
				if (isSpreadForm) {
					setShowContainer(false);
				}

				setMobileStep(departureId);
			} else if (event.id === datesId && showOtherPlaceForArrival) {
				if (isSpreadForm) {
					setShowContainer(false);
				}

				setMobileStep(arrivalId);
			} else if (event.id === datesId) {
				if (isSpreadForm) {
					setShowContainer(false);
				}

				setMobileStep(departureId);
			} else if (event.id === discountId) {
				if (isSpreadForm) {
					setShowContainer(false);
				}

				setMobileStep(datesId);

				setDateMobileStep(datesTimeId);
			}
		},
		[showOtherPlaceForArrival],
	);

	const serializeDiscounts = useCallback(
		(discountName: string, corporateDiscountName: string, promotionalCodeName: string): void => {
			const applyDefault: boolean = !isNotHome && !carHistory?.corporate_discount && !carHistory?.promotional_code;
			if (corporateDiscountDefault && promoCodeWithoutCorporateDiscountCodeDefault && applyDefault) {
				const firstElement: number = 0;
				const discountData: CarDiscountProp = {
					query: '',
					value: '',
					label: '',
				};

				const promotionalCodeData: CarDiscountProp = {
					query: '',
					value: '',
					label: '',
				};

				const rewardsPointsData: CarDiscountProp = {
					query: '',
					value: '',
					label: '',
				};

				if (corporateDiscountDefault?.label) {
					discountData.label = corporateDiscountDefault.label;

					discountData.value = corporateDiscountDefault.value;

					discountData.query = corporateDiscountDefault.value;
				}

				if (corporateDiscountDefault?.dependencies || promoCodeWithoutCorporateDiscountCodeDefault) {
					if (corporateDiscountDefault?.dependencies?.length) {
						promotionalCodeData.label = corporateDiscountDefault.dependencies[firstElement].label;

						promotionalCodeData.value = corporateDiscountDefault.dependencies[firstElement].value;

						promotionalCodeData.query = corporateDiscountDefault.dependencies[firstElement].value;
					} else if (promoCodeWithoutCorporateDiscountCodeDefault?.dependencies?.length) {
						promotionalCodeData.label = promoCodeWithoutCorporateDiscountCodeDefault.dependencies[firstElement].label;

						promotionalCodeData.value = promoCodeWithoutCorporateDiscountCodeDefault.dependencies[firstElement].value;

						promotionalCodeData.query = promoCodeWithoutCorporateDiscountCodeDefault.dependencies[firstElement].value;
					}
				}

				const discountResponse: CarDiscount = {
					corporateDiscount: { ...discountData },
					promotionalCode: { ...promotionalCodeData },
					rewardsPoints: { ...rewardsPointsData },
				};

				const displayValue = getDiscountDisplayValue(discountResponse);
				setValue(discountName, displayValue);

				setValue(corporateDiscountName, discountResponse?.corporateDiscount?.value || '');

				setValue(promotionalCodeName, discountResponse?.promotionalCode?.value || '');
			} else if (carHistory?.corporate_discount || carHistory?.promotional_code) {
				setValue(discountName, carHistory?.discount ? carHistory.discount : '');

				setValue(corporateDiscountName, carHistory?.corporate_discount ? carHistory.corporate_discount : '');

				setValue(promotionalCodeName, carHistory?.promotional_code ? carHistory.promotional_code : '');
			}
		},
		[promoCodeWithoutCorporateDiscountCodeDefault, corporateDiscountDefault, carHistory],
	);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-cars-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const tempDiscountNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(discountName);
		serializeDiscounts(discountName, tempDiscountNames.discountName, tempDiscountNames.promotionalCodeName);
	}, [corporateDiscountDefault, promoCodeWithoutCorporateDiscountCodeDefault, isNotHome]);

	useEffect(() => {
		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		const tempDiscountNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(discountName);
		const tempHertzDiscountNames: DiscountFieldInputNamesInterface = SearchFieldsUtil.getDiscountsInputsNames(hertzDiscountName);
		const defaultRewardsPoints: string = carHistory?.rewards_points ? carHistory.rewards_points : '';
		setValue(tempHertzDiscountNames.rewardsPointsName, defaultRewardsPoints);

		setValue(tempHertzDiscountNames.name, defaultRewardsPoints);

		const applyAllfieldsHistory: boolean = CarSearchUtil.validatePath(carHistory);
		const validateDeparture: string = carHistory?.departureLocation ? carHistory.departureLocation : '';
		const validateDepartureIata: string = carHistory?.departureLocation ? carHistory?.departureLocationIata : '';
		if (applyAllfieldsHistory) {
			setValue(departureName, validateDeparture);

			setValue(departureHiddenName, validateDepartureIata);
		}

		setValue(tempDiscountNames.rewardsPointsName, defaultRewardsPoints);

		const validationOtherPlace: boolean = !!carHistory?.arrivalLocation && !!carHistory?.arrivalLocationIata;
		const arrivalLocationIata: string = carHistory?.arrivalLocationIata || '';
		const arrivalLocation: string = carHistory?.arrivalLocation || '';
		if (validationOtherPlace && applyAllfieldsHistory) {
			setValue(arrivalName, arrivalLocation);

			setValue(arrivalHiddenName, arrivalLocationIata);
		}

		setValue(otherPlaceName, validationOtherPlace);

		setShowOtherPlaceForArrival(validationOtherPlace);

		const tempPaymentType: CarSearchPaymentType = CarSearchUtil.getPaymentTypeOption(carsInSitePayment, carHistory?.paymentType, disabledPaymentType);
		setValue(paymentTypeName, tempPaymentType);

		setPaymentType(tempPaymentType);

		let tempDepartureDate: string = carHistory?.datesDepartureDate ? carHistory.datesDepartureDate : '';
		let tempDepartureHour: string = carHistory?.datesDepartureHour ? carHistory.datesDepartureHour : '';
		let tempArrivalDate: string = carHistory?.datesArrivalDate ? carHistory.datesArrivalDate : '';
		let tempArrivalHour: string = carHistory?.datesArrivalHour ? carHistory.datesArrivalHour : '';
		let tempStartDate: Date = new Date(`${tempDepartureDate}T00:00:00`);
		let tempEndDate: Date = new Date(`${tempArrivalDate}T00:00:00`);
		const validDate: boolean =
			!!tempDepartureDate && !!tempArrivalDate && tempStartDate >= minDate && tempEndDate >= minDate && tempEndDate >= tempStartDate;

		if (!validDate) {
			tempDepartureDate = '';

			tempArrivalDate = '';

			tempDepartureHour = '';

			tempArrivalHour = '';

			tempStartDate = new Date('T00:00:00');

			tempEndDate = new Date('T00:00:00');
		}

		setValue(tempDatesNames.startDate, tempDepartureDate);

		setValue(tempDatesNames.startTime, tempDepartureHour);

		setValue(tempDatesNames.endDate, tempArrivalDate);

		setValue(tempDatesNames.endTime, tempArrivalHour);

		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.startEndDates,
			{
				date: true,
				time: true,
			},
			tempDepartureDate,
			tempDepartureHour,
			tempArrivalDate,
			tempArrivalHour,
			undefined,
			language,
		);

		setValue(datesName, validDate ? datesValues.dates : '');

		setButtonSearchHistory(
			carHistory?.departureLocationIata
				? {
					departure: carHistory?.departureLocationIata || '',
					arrival: carHistory?.arrivalLocationIata || '',
					departureDate: datesValues.largeStartDate || '',
					arrivalDate: datesValues.largeEndDate || '',
				  }
				: undefined,
		);

		setDefaultDates(
			validDate
				? {
					startDate: tempStartDate,
					endDate: tempEndDate,
					startTime: tempDepartureHour,
					endTime: tempArrivalHour,
				  }
				: {
					startDate: minDate,
					endDate: minDate,
					startTime: defaultHour,
					endTime: defaultHour,
				  },
		);
	}, [carHistory, minDate, language]);

	return {
		departureName,
		departureHiddenName,
		arrivalName,
		arrivalHiddenName,
		otherPlaceName,
		paymentTypeName,
		datesName,
		datesHiddenName,
		discountName,
		hertzDiscountName,
		departureId,
		arrivalId,
		datesId,
		datesDateId,
		datesTimeId,
		discountId,
		hertzDiscountId,
		buttonSearchHistory,
		paymentType,
		paymentOptions,
		minDate,
		defaultDates,
		departureFieldRef,
		arrivalFieldRef,
		showOtherPlaceForArrival,
		showMobileFieldsContainer: showContainer,
		mobileFieldStep: mobileStep,
		dateMobileFieldStep: dateMobileStep,
		mobileFormHeight,
		showMobileFieldsContainerHandler,
		onSubmit,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		otherPlaceForArrivalHandler,
		changePaymentTypeHandler,
		emitHandler,
		destinationsOtherPlaceForArrivalHandler,
		openDepartureLocalitiesHandler,
		openArrivalLocalitiesHandler,
		selectedDestination,
		changeDatesHandler,
		changeHertzDiscountHandler,
		getDiscountDisplayValue,
		changeDiscountPaymentTypeHandler,
		removeRewardsPointsDiscountValue,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
	};
};

export default useCarSearchForm;
