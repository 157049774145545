import { DisneySearchFormSubmitInterface, PassengersDisneySearchFormSubmitInterface } from '../interfaces/disney-search-form.interface';
import { DisplayValuesDatesFieldInterface } from '../../../../shared/interfaces';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { DatesFieldTypes } from '../../../../shared/enums';

export class DisneySearchFormUtil {
	static mapSearchUrl(data: DisneySearchFormSubmitInterface): string {
		const searchUrl: string = `${data?.days ?? 0}-days/${data?.passengers.adults ?? 0}-adults/${data?.passengers.children ?? 0}-children/${
			data?.arrivalDate || ''
		}${data?.promoCode ? `&promoCode=${data.promoCode}` : ''}`;

		return searchUrl;
	}

	static mapSpecialEventsSearchUrl(data: DisneySearchFormSubmitInterface): string {
		return `adults=${data?.passengers.adults ?? 1}&children=${data?.passengers.children ?? 0}&filterByProduct=SpecialEvent${
			data?.promoCode ? `&promoCode=${data.promoCode}` : ''
		}`;
	}

	public static historyHandle(
		historyJson: DisneySearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): DisneySearchFormSubmitInterface {
		const history: DisneySearchFormSubmitInterface = (historyJson && { ...historyJson }) || ({} as unknown as DisneySearchFormSubmitInterface);
		if (DisneySearchFormUtil.checkUrlReact()) {
			DisneySearchFormUtil.historyHandleReact(history, traductions);
		} else {
			const routePath: string = window?.location?.pathname;
			const daysPosition: number = 3;
			const adultsPosition: number = 4;
			const childrenPosition: number = 5;
			const arrivalDatePosition: number = 6;
			if (routePath.includes('disney')) {
				const splitRoute: string[] = routePath.split('/');
				const positionToLength: number = 1;
				if (splitRoute.length >= daysPosition + positionToLength) {
					const days: number = parseInt(splitRoute[daysPosition].split('-')[0]);
					history.days = days || NaN;

					const departureDate = splitRoute[arrivalDatePosition];
					history.arrivalDate = departureDate || '';

					const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
						DatesFieldTypes.oneDate,
						{
							time: false,
							date: true,
						},
						history.arrivalDate,
						'',
						'',
						'',
					);

					history.dates = datesValues?.dates ? datesValues.dates : '';

					const passengersAdultDefault: number = 1;
					const passengersChildDefault: number = 0;
					const passengersAdults: number = parseInt(splitRoute[adultsPosition].split('-')[0]) || passengersAdultDefault;
					const passengersChildren: number = parseInt(splitRoute[childrenPosition].split('-')[0]) || passengersChildDefault;
					const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
						passengersAdults,
						passengersChildren,
						0,
						traductions && (traductions('adult') || 'adult'),
						traductions && (traductions('adults') || 'adults'),
						traductions && (traductions('child') || 'child'),
						traductions && (traductions('children') || 'children'),
						'',
						'',
					);

					const passengers: PassengersDisneySearchFormSubmitInterface = {
						adults: passengersAdults,
						children: passengersChildren,
						display: passengersDisplayValue || '',
					};

					history.passengers = { ...passengers };

					history.urlPath = DisneySearchFormUtil.mapSearchUrl({ ...history });
				}
			}
		}

		return { ...history };
	}

	static checkUrlReact(): boolean {
		const params: string = window?.location?.search;
		const queryParam: URLSearchParams = new URLSearchParams(params);
		const paramName: string = 'adults';
		return queryParam.has(paramName);
	}

	static historyHandleReact(history: DisneySearchFormSubmitInterface, traductions?: (label: string) => string): DisneySearchFormSubmitInterface {
		const params: string = window?.location?.search;
		const adultsPosition: number = 0;
		const childrenPosition: number = 1;
		const arrivalDatePosition: number = 2;
		const queryParam: URLSearchParams = new URLSearchParams(params);
		const urlData = Array.from(queryParam.values());
		if (urlData.length) {
			const departureDate = urlData[arrivalDatePosition];
			history.arrivalDate = departureDate || '';

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.oneDate,
				{
					time: false,
					date: true,
				},
				history.arrivalDate,
				'',
				'',
				'',
			);

			history.dates = datesValues?.dates ? datesValues.dates : '';

			const passengersAdultDefault: number = 1;
			const passengersChildDefault: number = 0;
			const passengersAdults: number = parseInt(urlData[adultsPosition]) || passengersAdultDefault;
			const passengersChildren: number = parseInt(urlData[childrenPosition]) || passengersChildDefault;
			const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
				passengersAdults,
				passengersChildren,
				0,
				traductions && (traductions('adult') || 'adult'),
				traductions && (traductions('adults') || 'adults'),
				traductions && (traductions('child') || 'child'),
				traductions && (traductions('children') || 'children'),
				'',
				'',
			);

			const passengers: PassengersDisneySearchFormSubmitInterface = {
				adults: passengersAdults,
				children: passengersChildren,
				display: passengersDisplayValue || '',
			};

			history.passengers = { ...passengers };

			history.urlPath = DisneySearchFormUtil.mapSearchUrl({ ...history });
		}

		return { ...history };
	}

	static validatePath(historyJson: DisneySearchFormSubmitInterface | null = null): boolean {
		const arrivalDate: string = historyJson?.arrivalDate || '';
		const path: string = window.location.href;
		let withParams: boolean = false;

		if (path.includes(arrivalDate)) {
			withParams = true;
		}

		return withParams;
	}
}
