import { DataVoidSkeletonMock, DataVoidSkeletonSingleTripMock } from '../../../../../../shared/mocks/flexible-flights-skeleton.mock';
import styles from './skeleton-flexible-flights.module.scss';
import Table from '../../../../../ui-elements/table/table';

export interface flexibleFlightsSkeletonProps {
	tripTypeRound: boolean;
}

export function FlexibleFlightsSkeleton(props: flexibleFlightsSkeletonProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={'contTables text-gray-500 relative flex animate-pulse'}>
				<Table
					className={`${!props.tripTypeRound ? 'hidden' : ''} tableArribal`}
					iconHead='icon-icon-plane-return-gray'
					data={DataVoidSkeletonMock}
				/>
				<div className={`tableResponsive ${props.tripTypeRound ? 'tripRound' : ''}`}>
					<Table
						className={'tableDeparture'}
						iconHead='flyPlaneIco'
						data={props.tripTypeRound ? DataVoidSkeletonMock : DataVoidSkeletonSingleTripMock}
					/>
				</div>
			</div>
		</>
	);
}
