import { InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { FormsInputsType } from '../../../shared/enums/inputs.enum';
import { KeyboardEvent as ReactKeyboardEvent } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './radio-input.module.scss';

export interface RadioInputProps {
	id?: string;
	name?: string;
	label?: string;
	value?: string;
	checked?: boolean;
	disabled?: boolean;
	content?: JSX.Element;
	onChange?: (e: InputEventsHandler) => void;
	className?: string;
	labelClassName?: string;
	inputClassName?: string;
	inputMarginCustomclass?: string;
	required?: boolean;
	register?: UseFormRegisterReturn;
	onClick?: () => void;
	parentLabelClassName?: string;
	isCustom?: boolean;
}

export const RadioInput = (props: RadioInputProps): JSX.Element => {
	const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (props?.onChange) {
			const dataEvent: InputEventsHandler = {
				type: FormsInputsType.radio,
				name: props?.name,
				value: event.target.value,
				event,
			};

			props.onChange(dataEvent);
		}
	};

	const handleLabelKeyPress = (event: ReactKeyboardEvent<HTMLLabelElement>): void => {
		if (event.key === 'Enter' || event.key === ' ') {
			if (props.onClick) {
				props.onClick();
			}
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`radioInput flex w-full rounded-lg py-2.5 px-2 flex-col justify-start  ${props?.className ?? ''}`}>
				{props?.isCustom ? (
					<label
						className={`flex items-center gap-2 cursor-pointer select-none ${props.parentLabelClassName ?? ''}`}
						onClick={props?.onClick}
						onKeyUp={event => handleLabelKeyPress(event)}
						htmlFor={props?.id}
					>
						<input
							type={FormsInputsType.radio}
							id={props?.id}
							name={props?.name}
							data-testid={props?.name}
							defaultChecked={props.checked}
							defaultValue={props?.value ?? ''}
							onChange={changeHandler}
							className={'hidden radioInput__radio-custom'}
							disabled={props?.disabled}
							required={props?.required}
							{...props?.register}
						/>
						<span className='radio_mask'></span>
						<span className={`${props?.labelClassName ?? ''}`} dangerouslySetInnerHTML={{ __html: props?.label ?? '' }}></span>
					</label>
				) : (
					<label
						className={`flex ${props.parentLabelClassName ?? ''}`}
						onKeyUp={event => handleLabelKeyPress(event)}
						htmlFor={props?.id}
						onClick={props?.onClick}
					>
						<input
							type={FormsInputsType.radio}
							id={props?.id}
							name={props?.name}
							data-testid={props?.name}
							defaultChecked={props.checked}
							defaultValue={props?.value ?? ''}
							onChange={changeHandler}
							className={`radioInput__radio ${props?.inputMarginCustomclass ?? 'mr-3'} ${props?.inputClassName ?? ''}`}
							disabled={props?.disabled}
							required={props?.required}
							{...props?.register}
						/>
						<>
							{props?.content ? (
								props.content
							) : (
								<span className={`${props?.labelClassName ?? ''}`} dangerouslySetInnerHTML={{ __html: props?.label ?? '' }}></span>
							)}
						</>
					</label>
				)}
			</div>
		</>
	);
};

RadioInput.defaultProps = {
	type: 'text',
	className: '',
	labelClassName: '',
	inputClassName: '',
};

export default RadioInput;
