import styles from '../car-resume.module.scss';
import { useState, useEffect } from 'react';

export function CarResumeSkeleton(): JSX.Element {
	const mobileWidth: number = 768;
	const [colapsed, setColapsed] = useState<boolean>(false);
	useEffect(() => {
		if (window.screen.width < mobileWidth) {
			setColapsed(true);
		}
	}, []);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={'carResume'}>
				<div className={'carResume__container p-[25px] md:p-[15px] lg:p-[35px]  rounded-[20px]'}>
					<p className='carResume__title text-[21px] flex justify-between w-[200px] h-[15px] bg-gray-200'></p>
					<div className={`${colapsed ? 'hidden md:block' : ''}`}>
						<hr className='my-[15px] border-gray-300' />
						<div className='carResume__images flex justify-between'>
							<div className='w-[80%]'>
								<div className='w-[173px] h-[107px] bg-gray-200'></div>
							</div>
							<div className='w-[20%]'>
								<div className='w-[40px] h-[32px] bg-gray-200'></div>
							</div>
						</div>

						<div className='mt-[10px]'>
							<p className='carResume__boldTitle w-[66px] h-[10px] mb-[10px] bg-gray-200'></p>
							<p className='carResume__regularText w-[165px] h-[10px] mb-[10px] bg-gray-200'></p>
						</div>
						<hr className='my-[15px] border-gray-300' />

						<div className='mb-[15px]'>
							<p className='carResume__boldTitle text-xs w-[53px] h-[10px] mb-[10px] bg-gray-200'></p>
							<p className='carResume__regularText text-xs w-[181px] h-[10px] mb-[10px] bg-gray-200'></p>
							<p className='carResume__mediumText w-[181px] h-[10px] mb-[10px] bg-gray-200'></p>
						</div>

						<div>
							<p className='carResume__boldTitle text-xs w-[53px] h-[10px] mb-[10px] bg-gray-200'></p>
							<p className='carResume__regularText text-xs w-[181px] h-[10px] mb-[10px] bg-gray-200'></p>
							<p className='carResume__mediumText w-[181px] h-[10px] mb-[10px] bg-gray-200'></p>
						</div>
						<hr className='my-[15px] border-gray-300' />

						<div>
							<p className='carResume__boldTitle flex items-center w-[116px] h-[32px] bg-gray-200'></p>
						</div>
						<hr className='my-[15px] border-gray-300' />

						<div>
							<div className='flex justify-between'>
								<div className='w-[79px] h-[10px] mb-[10px] bg-gray-200'></div>
								<div className='w-[62px] h-[10px] mb-[10px] bg-gray-200'></div>
							</div>

							<div className='flex justify-between'>
								<div className='w-[47px] h-[10px] mb-[10px] bg-gray-200'></div>
								<div className='w-[62px] h-[10px] mb-[10px] bg-gray-200'></div>
							</div>

							<div className='flex justify-between'>
								<div className='w-[35px] h-[10px] mb-[10px] bg-gray-200'></div>
								<div className='w-[181px] h-[10px] mb-[10px] bg-gray-200'></div>
							</div>
						</div>
					</div>
				</div>

				<div className='carResume__action'>
					<div className='w-full rounded-full h-[38px] my-[15px] bg-gray-200'></div>
				</div>
			</div>
		</>
	);
}
