import { DynamicFormService } from '../../../../../shared/services/dynamic-form.service';
import { ElementTypes } from '../../../../../shared/enums/dynamic-forms.enum';
import { RadioInput } from '../../../../ui-elements/radio-input/radio-input';
import { inputValidationType } from '../../../../../shared/services/index';
import { Button } from '../../../../ui-elements/button/button';
import { useForm } from 'react-hook-form';
import {
	ActionsProps,
	DynamicFormErrorLabel,
	ElementSets,
	FormsSets,
	OptionsItems,
	RegisterPropsInterface,
} from '../../../../../shared/interfaces/dynamic-forms.interface';
import { Select } from '../../../../ui-elements/select/select';
import { Input } from '../../../../ui-elements/input/input';
import styles from './dynamic-forms.module.scss';

export interface DynamicFormsProps {
	formSet: FormsSets;
	submitButtonProps?: ActionsProps;
	cancelButtonProps?: ActionsProps;
	showTextErrors?: boolean;
	showlabel?: boolean;
	actionsClassName?: string;
	formClassName?: string;
	containerElementClassName?: string;
	extraLabelContent?: JSX.Element;
	errorForm?: (flag: boolean) => boolean;
	cancelForm?: (flag: boolean) => void;
	submitForm?: (data: Record<string, string | boolean>) => void;
}

export function DynamicForms(props: DynamicFormsProps): JSX.Element {
	const {
		register,
		handleSubmit,
		setFocus,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	const dynamicFormService: DynamicFormService = new DynamicFormService(getValues);
	const inputTypes = [ElementTypes.text, ElementTypes.number, ElementTypes.email, ElementTypes.phone, ElementTypes.password];
	const onSubmit = handleSubmit((data: Record<string, string | boolean>) => {
		if (props?.submitForm) {
			props.submitForm(data);
		}
	});

	const validationInputType = (value: string, name: string, typeValidation: string): void => {
		const valueOut: string = inputValidationType(value, typeValidation);
		setValue(name, valueOut);
	};

	const NestedInput = (elementSet: ElementSets): JSX.Element => {
		const registerProps: {
			errorData: DynamicFormErrorLabel;
			registerProps: RegisterPropsInterface;
		} = dynamicFormService.registerProps(elementSet);

		return (
			<div
				className={`flex flex-col items-center dynamicForms__column dynamicForms__column-${elementSet.elementStyle.columnSize}`}
				style={{ order: elementSet?.elementStyle?.order }}
			>
				<Input
					register={register(elementSet.bindType, {
						required: elementSet.isRequired || false,
						...registerProps.registerProps,
						onChange: (element: { target: HTMLInputElement }) => {
							validationInputType(element.target.value, elementSet.bindType, elementSet.elementStyle.typeCondition || '');

							if (errors[elementSet.bindType]) {
								const waitTime: number = 100;
								setTimeout(() => {
									setFocus(elementSet.bindType);
								}, waitTime);
							}
						},
						validate: (value: string) => dynamicFormService.inputValidator(value, elementSet),
					})}
					defaultValue={elementSet?.elementStyle?.defaultValue || ''}
					type={elementSet.elementStyle.elementType}
					placeholder={elementSet.elementStyle.placeHolder}
					required={elementSet.isRequired || false}
					label={props.showlabel ? elementSet.label : ''}
					className={`dynamicForms__maxWidth ${props?.containerElementClassName || ''} ${
						errors[elementSet.bindType] ? 'border-2 border-red-500 text-red-500' : ''
					}`}
					max={elementSet?.elementStyle?.max}
					min={elementSet?.elementStyle?.min}
					inputClassName={'bg-gray-100 font-family-regular'}
				/>
				<ErrorTextLabel {...registerProps.errorData} />
			</div>
		);
	};

	const NestedSelect = (elementSet: ElementSets): JSX.Element => {
		const registerProps: {
			errorData: DynamicFormErrorLabel;
			registerProps: RegisterPropsInterface;
		} = dynamicFormService.registerProps(elementSet);

		const elementSets: ElementSets[] = props?.formSet?.elements;
		dynamicFormService.dependencyValidator(elementSet, elementSets); // init stance

		const items = elementSet?.elementStyle?.option;
		const defaultValue: string = getValues(elementSet.bindType) || elementSet.elementStyle?.option?.items.find(item => !item.hidden)?.value;
		return (
			<div
				className={`flex flex-col items-center dynamicForms__column dynamicForms__column-${elementSet.elementStyle.columnSize}`}
				style={{ order: elementSet?.elementStyle?.order }}
			>
				<Select
					register={register(elementSet.bindType, {
						required: elementSet.isRequired || false,
						...registerProps.registerProps,
						onChange: (element: { target: HTMLSelectElement; form: HTMLFormElement }) => {
							dynamicFormService.dependencyValidator(elementSet, elementSets, setValue, element);
						},
					})}
					options={items?.cloneItems || items?.items || []}
					defaultValue={defaultValue}
					placeHolder={elementSet.elementStyle.placeHolder}
					required={elementSet.isRequired || false}
					label={props.showlabel ? elementSet.label : ''}
					className={`dynamicForms__maxWidth w-full ${props?.containerElementClassName || ''}`}
					selectClassName={`bg-gray-100 font-family-regular ${errors[elementSet.bindType] ? 'border-2 border-red-500' : ''}`}
				/>

				<ErrorTextLabel {...registerProps.errorData} />
			</div>
		);
	};

	const NestedRadio = (elementSet: ElementSets): JSX.Element => {
		const registerProps: {
			errorData: DynamicFormErrorLabel;
			registerProps: RegisterPropsInterface;
		} = dynamicFormService.registerProps(elementSet);

		const selectedValue: string = getValues(elementSet.bindType);
		return (
			<div
				className={`flex flex-col items-center dynamicForms__column dynamicForms__column-${elementSet.elementStyle.columnSize}`}
				style={{ order: elementSet?.elementStyle?.order }}
			>
				{elementSet?.elementStyle?.option?.items.map((item: OptionsItems) => (
					<RadioInput
						key={elementSet.index}
						required={elementSet.isRequired || false}
						register={register(elementSet.bindType, {
							required: elementSet.isRequired || false,
							...registerProps.registerProps,
						})}
						value={item.value}
						checked={selectedValue === item.value || item?.checked === 'true'}
						label={`${item.label} ${item.extraLabel ? `<br> ${item.extraLabel}` : ''}`}
						className={`dynamicForms__maxWidth font-family-regular ${props?.containerElementClassName || ''} ${
							errors[elementSet.bindType] ? 'border-2 border-red-500 text-red-500' : ''
						}`}
					/>
				))}

				<ErrorTextLabel {...registerProps.errorData} />
			</div>
		);
	};

	const NestedLabel = (elementSet: ElementSets): JSX.Element => {
		return (
			<div
				className={`flex flex-col items-center dynamicForms__column dynamicForms__column-${elementSet.elementStyle.columnSize}`}
				style={{ order: elementSet?.elementStyle?.order }}
			>
				<div className={`w-full ${props?.containerElementClassName || ''}`}>
					<p className='nestedLabel__item'>{elementSet.label}</p>
					<p className='nestedLabel__item'>{elementSet.elementStyle.defaultValue}</p>
					{props?.extraLabelContent || ''}
					<Input
						register={register(elementSet.bindType)}
						defaultValue={elementSet?.elementStyle?.defaultValue || ''}
						className='w-full'
						hidden={true}
					/>
				</div>
			</div>
		);
	};

	const ErrorTextLabel = (erroData: DynamicFormErrorLabel): JSX.Element => {
		if (props?.showTextErrors && errors[erroData.bind] && props.errorForm) {
			props.errorForm(true);
		}

		return (
			<div className='w-full'>
				{props?.showTextErrors && errors[erroData.bind] && <p className={'px-5 text-red-500 font-family-regular'}>Error {erroData.label}</p>}
			</div>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`dynamic-forms ${props?.formSet?.paymentMethodType}`}>
				{props?.formSet?.description && <p>{props.formSet.description}</p>}
				<form onSubmit={onSubmit} id={props?.formSet?.paymentMethodType} className={props?.formClassName || ''}>
					<div className={'dynamicForms__elementsContainer'}>
						{props?.formSet?.elements?.map((form: ElementSets, index: number) => {
							form.index = index;

							if (inputTypes.some(element => element.includes(form.elementStyle.elementType))) {
								return <NestedInput key={index} {...form} />;
							}

							if (ElementTypes.select === form.elementStyle.elementType) {
								return <NestedSelect key={index} {...form} />;
							}

							if (ElementTypes.radio === form.elementStyle.elementType) {
								return <NestedRadio key={index} {...form} />;
							}

							if (ElementTypes.label === form.elementStyle.elementType) {
								return <NestedLabel key={index} {...form} />;
							}

							return '';
						})}
					</div>

					<div className={`flex ${props?.actionsClassName || ''}`}>
						{!props.cancelButtonProps?.hide && (
							<Button
								customClass={`dynamicForms__btn ${props?.cancelButtonProps?.className || ''}` || ''}
								text={props?.cancelButtonProps?.title || 'cancel'}
								onClick={props?.cancelForm}
							/>
						)}

						{!props.submitButtonProps?.hide && (
							<Button
								customClass={`dynamicForms__btn ${props?.cancelButtonProps?.className || ''}` || ''}
								text={props?.submitButtonProps?.title || 'submit'}
								type={'submit'}
							/>
						)}
					</div>
				</form>
			</div>
		</>
	);
}

export default DynamicForms;
