export enum FormDynamicTypes {
	adminPaymentMethods,
}

export enum FormTypes {
	paymentMethods = 'paymentMethods',
	billingData = 'billingData',
}

export enum BindTypes {
	name = 'name',
	lastName = 'lastName',
	documentType = 'documentType',
	documentNumber = 'documentNumber',
	installments = 'installments',
	creditCardNumber = 'creditCardNumber',
	month = 'month',
	year = 'year',
	cvc = 'cvc',
	creditCardCorporative = 'creditCardCorporative',
	bankEntity = 'bankEntity',
	customerType = 'customerType',
	approvalCode = 'approvalCode',
	briefcaseCurrentAmount = 'briefcaseCurrentAmount',
	null = 'null',
}

export enum ColumnSize {
	zero,
	first,
	second,
	third,
	fourth,
	fifth,
	sixth,
	seventh,
	eighth,
	nineth,
	tenth,
	eleventh,
	twelveth,
}

export enum Target {
	documentType = 'documentType',
	banks = 'banks',
}

export enum ElementTypes {
	text = 'text',
	select = 'select',
	radio = 'radio',
	checkbox = 'checkbox',
	date = 'date',
	number = 'number',
	label = 'label',
	email = 'email',
	inputMask = 'inputMask',
	password = 'password',
	phone = 'phone',
	file = 'file',
}

export enum PaymentMethodsTypes {
	creditCard = 'creditCard',
	corporative = 'corporative',
	pse = 'pse',
	approvalCode = 'approvalCode',
	briefcase = 'briefcase',
}
