import Button from '../../../../../ui-elements/button/button';
import styles from './redirect-newsletter.module.scss';

/* eslint-disable-next-line */
export interface RedirectNewsletterProps {
	title: string;
	subtitle: string;
	description?: string;
}

export function RedirectNewsletter(props: RedirectNewsletterProps): JSX.Element {
	const descriptionHtml: string = props.description || '';
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='redirectNewsletter flex justify-center'>
				<div className='flex flex-col items-center p-16 px-6 md:p-16'>
					<p className={'border-b-2 border-gray-500  text-gray-500 font-family-bold pb-4 redirectNewsletter__subtitle'}>{props.subtitle}</p>
					<h3 className='text-4xl text-gray-600 font-family-black mt-3.5 mb-3.5 text-center max-w-xl'>{props.title}</h3>
					<p className='text-center max-w-3xl text-gray-600 text-base font-family-regular' dangerouslySetInnerHTML={{ __html: descriptionHtml }}></p>

					<div className={'flex flex-col md:flex-row items-center justify-center mt-8 w-full'}>
						<Button
							text={'Suscríbete'}
							customClass={'rounded-full items-center text-white bg-indigo-600 w-full max-w-xs tracking-widest font-family-bold'}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default RedirectNewsletter;
