import { TableData, RowData } from '../../../../../../../../shared/interfaces/table';
import Button from '../../../../../../../ui-elements/button/button';
import styles from './car-coverages-desktop-skeleton.module.scss';
import Table from '../../../../../../../ui-elements/table/table';
import { useMemo } from 'react';

export interface CarCoveragesDesktopSkeletonProps {
	rows?: number;
	columns?: number;
}

export const CarCoveragesDesktopSkeleton = ({ rows, columns }: CarCoveragesDesktopSkeletonProps): JSX.Element => {
	const dataDesktop: TableData = useMemo(
		() =>
			((tempRows: number, tempColumns: number): TableData => {
				function getRndInteger(min: number, max: number): number {
					return Math.floor(Math.random() * (max - min + 1)) + min;
				}
				const headTable: RowData[] = [
					{ value: '', label: '' },
					...Array(tempColumns)
						.fill(0)
						.map((): RowData => {
							return {
								value: (
									<div className={'carCoveragesDesktopSkeleton__td'}>
										<span className='bg-gray-200 h-[16px] w-[50%]' />
									</div>
								),
								label: 'td_1',
							};
						}),
				];

				const bodyTable: RowData[][] = [
					...Array(tempRows)
						.fill(0)
						.map((): RowData[] => {
							return [
								{
									value: (
										<span className='carCoveragesDesktopSkeleton__td bg-gray-200 h-[12px] block' style={{ width: `${getRndInteger(50, 90)}%` }} />
									),
									label: '',
								},
								...Array(tempColumns)
									.fill(0)
									.map((): RowData => {
										return { value: <div className={'carCoveragesDesktopSkeleton__td'} />, label: '' };
									}),
							];
						}),
					[
						{ value: <p className='carCoveragesDesktopSkeleton__total bg-gray-200 h-[12px] w-[30%]' />, label: '' },
						...Array(tempColumns)
							.fill(0)
							.map((): RowData => {
								return {
									value: (
										<div className={'carCoveragesDesktopSkeleton__td w-full h-full flex items-center justify-center'}>
											<span className='rate bg-gray-200 h-[12px] w-[40%] block' />
										</div>
									),
									label: '',
								};
							}),
					],
					[
						{ value: '', label: '' },
						...Array(tempColumns)
							.fill(0)
							.map((): RowData => {
								return {
									value: (
										<Button
											text={''}
											disabled={true}
											customClass='carCoveragesDesktopSkeleton__btn w-full rounded-full text-white my-[20px] bg-black h-[42px] !bg-gray-200'
										/>
									),
									label: '',
								};
							}),
					],
				];

				return {
					head: headTable,
					rows: bodyTable,
				};
			})(rows || 0, columns || 0),
		[rows, columns],
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={'carCoveragesDesktopSkeleton animate-pulse carCoveragesDesktopSkeleton'}>
				<Table
					data={dataDesktop}
					className={'w-full CustomerDataMock mx-auto table-fixed carCoveragesDesktopSkeleton__table'}
					tdClassName={'text-center'}
				/>
			</div>
		</>
	);
};

CarCoveragesDesktopSkeleton.defaultProps = {
	rows: 4,
	columns: 5,
};

export default CarCoveragesDesktopSkeleton;
