import { FlightsSearchFormMobile } from './sub-components/flights-search-form-mobile/flights-search-form-mobile';
import DestinationField from '../../../shared-components/search/destination-field/destination-field';
import PassengersField from '../../../shared-components/search/passengers-field/passengers-field';
import { InputEventsHandler, OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import PromoCodeInput from '../../../../lib/shared-components/input-promocode/input-promocode';
import { FlightsSearchFormUtil } from '../../shared/utils/flights-search-form.util';
import DatesField from '../../../shared-components/search/dates-field/dates-field';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { ListInterface } from '../../../../shared/interfaces/list.interface';
import useFlightsSearchForm from '../../shared/hooks/useFlightsSearchForm';
import { ElementTypes } from '../../../../shared/enums/dynamic-forms.enum';
import { FlightTripTypes } from '../../shared/enums/flight-types.enum';
import { WidgetStyleInterface } from '../../../../shared/interfaces';
import {
	UseFlightsSearchFormInterface,
	UseFlightsSearchFormReturnInterface,
	FlightsSearchFormSubmitInterface,
	DestinationsNamesFlightsSearchFormInterface,
	DisabledTripTypeFlightsSearchFormInterface,
	PassengersFieldInputNamesFlightsSearchFormInterface,
	PassengersFieldRestrictionsFlightsSearchFormInterface,
} from '../../shared/interfaces/flights-search-form.interface';
import useWindowWidth from '../../../../shared/hooks/useWidth';
import { TripTypeSelector } from '../../../shared-components';
import Checkbox from '../../../ui-elements/checkbox/checkbox';
import AirlineMobile from './sub-components/airline-mobile';
import CabinMobile from './sub-components/cabin-mobile';
import Tabs from '../../../shared-components/tabs/tabs';
import Button from '../../../ui-elements/button/button';
import styles from './flights-search-form.module.scss';
import { DateFormat } from '../../../../shared/enums';
import Input from '../../../ui-elements/input/input';
import { useEffect, useRef, useState } from 'react';
import Airline from './sub-components/airline';
import Cabin from './sub-components/cabin';

/* eslint-disable-next-line */
export interface FlightsSearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	isDisplayPassengersExtraInfo?: boolean;
	isFilterChangeBetweenCabinAndAirline?: boolean;
	traductions: (label: string) => string;
	departureAirports?: ListInterface[];
	isDepartureAirportsLoading?: boolean;
	arrivalAirports?: ListInterface[];
	isArrivalAirportsLoading?: boolean;
	airlines?: OptionSelect[];
	calendarRestriction?: number;
	passengersRestrictions: PassengersFieldRestrictionsFlightsSearchFormInterface;
	tripsRestriction?: number;
	cabinsOptions: OptionSelect[];
	history: FlightsSearchFormSubmitInterface | null;
	className?: string;
	disabledTripType?: DisabledTripTypeFlightsSearchFormInterface;
	widgetStyles?: WidgetStyleInterface;
	emitSubmitSearch: (data: FlightsSearchFormSubmitInterface) => void;
	emitDepartureAirportsSearch: (airportName: string) => void;
	emitArrivalAirportsSearch: (airportName: string) => void;
	emitShowMobileFields?: (show: boolean) => void;
	hasPromoCodeActive?: boolean;
}

export function FlightsSearchForm({
	colorPrimary,
	colorSecondary,
	isDisplayPassengersExtraInfo,
	isFilterChangeBetweenCabinAndAirline,
	traductions,
	departureAirports,
	isDepartureAirportsLoading,
	arrivalAirports,
	isArrivalAirportsLoading,
	airlines,
	calendarRestriction,
	passengersRestrictions,
	tripsRestriction,
	cabinsOptions,
	history,
	className,
	disabledTripType,
	widgetStyles,
	emitSubmitSearch,
	emitDepartureAirportsSearch,
	emitArrivalAirportsSearch,
	emitShowMobileFields,
	hasPromoCodeActive,
}: FlightsSearchFormProps): JSX.Element {
	const {
		destinationsNames,
		tripTypeName,
		onlyDirectFlightName,
		passengersName,
		airlineName,
		passengersId,
		airlineId,
		tripType,
		mobileFieldStep,
		showMobileAirlines,
		minDate,
		defaultDates,
		destinationsNumber,
		showMultiActions,
		showMobileFieldsContainer,
		tripTypesTabs,
		tripTypesOptions,
		watchAirline,
		cabinModalValue,
		mobileFormHeight,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		showMobileFieldsSecondaryHandler,
		departureDestinationFieldHandler,
		arrivalDestinationFieldHandler,
		tripTypeSelectHandler,
		tripTypeTabsHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		airlinesMobileFieldHandler,
		destinationFieldNumberHandler,
		cabinHandler,
		arlineHandler,
		passengersHandler,
		passengersModalInputsHandler,
		passengersModalFieldHandler,
		onSubmit,
		minMultiDestinationsNumber,
		promoCode,
		promoCodeHandler,
		withPromoCodeName,
		withPromoCodeHandler,
		inputRefs,
	}: UseFlightsSearchFormReturnInterface = useFlightsSearchForm({
		traductions,
		isDisplayPassengersExtraInfo,
		isFilterChangeBetweenCabinAndAirline,
		history,
		calendarRestriction,
		tripsRestriction,
		cabinsOptions,
		maxPassengers: passengersRestrictions.maxPassengers,
		airlines,
		disabledTripType,
		isTertiaryTemplate: widgetStyles?.isTertiaryTemplate,
		emitSubmitSearch,
		emitDepartureAirportsSearch,
		emitArrivalAirportsSearch,
		emitShowMobileFields,
		hasPromoCodeActive,
		widgetStyles,
	} as unknown as UseFlightsSearchFormInterface);

	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement | null>(null);
	const tempPassengersNames: PassengersFieldInputNamesFlightsSearchFormInterface = FlightsSearchFormUtil.getPassengersInputsNames(passengersName);
	const getPaasengerTranslate = (): string => {
		if (isFilterChangeBetweenCabinAndAirline) {
			if (widgetStyles?.isTertiaryTemplate) {
				return traductions('passengers') || 'passengers';
			}

			return traductions('passengersAndAirlines') || 'passengersAndAirlines';
		}

		return traductions('passengersAndClass') || 'passengersAndClass';
	};

	const passengerTranslate: string = getPaasengerTranslate();
	const watchWithPromoCode = watch(withPromoCodeName);
	const promoCodeInputElement: JSX.Element = (
		<PromoCodeInput promoCode={promoCode} promoCodeHandler={promoCodeHandler} colorSecondary={colorSecondary} traductions={traductions} />
	);

	const handleSubmitButtonClick = (): void => {
		if (formRef.current) {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
	};

	useEffect(() => {
		setIsMobile(windowWidth < responsiveSize);
	}, [windowWidth]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`flightsSearchForm w-full flex flex-col ${className || ''}`}>
				<div className='flightsSearchForm__searchButton block md:hidden'>
					<FlightsSearchFormMobile
						register={register}
						setValue={setValue}
						tripTypesTabs={tripTypesTabs}
						tripType={tripType}
						tripTypeName={tripTypeName}
						tripTypesOptions={tripTypesOptions}
						tripTypeOnChange={tripTypeSelectHandler}
						triptypeDefaultValue={tripType}
						tripTypeTabsHandler={tripTypeTabsHandler}
						passengersId={passengersId}
						passengersName={passengersName}
						customClass='flightsSearchForm__searchButton__secondary'
						isSecondaryTemplate={!!widgetStyles?.isSecondaryTemplate}
						isTertiaryTemplate={!!widgetStyles?.isTertiaryTemplate}
						colorSecondary={colorSecondary ?? ''}
						truncate={widgetStyles?.isEnabledTruncatedInputs}
						destinationsNames={destinationsNames}
						traductions={traductions}
						showMobileFieldsContainerHandler={showMobileFieldsSecondaryHandler}
						watch={watch}
					>
						<div className={'flightsSearchForm__footer flex items-end'}>
							<Button
								type='submit'
								customClass={`flightsSearchForm__searchBtn text-white w-full text-[21px] items-center 
								${!isValid ? 'disabled cursor-not-allowed ' : ''} 
								${widgetStyles?.isTertiaryTemplate ? 'tertiary__shadow' : 'rounded-full'}`}
								content={
									<span className={'text-base !text-[14px]'}>
										{widgetStyles?.isTertiaryTemplate ? traductions('searchFlights') || 'searchFlights' : traductions('search') || 'search'}
									</span>
								}
								styles={{
									background: isValid ? colorSecondary ?? '#878787' : '#D1D1D1 ',
									color: isValid ? '' : '#9B9B9B',
									pointerEvents: !isValid ? 'none' : 'auto',
								}}
								disabled={!isValid}
								onClick={handleSubmitButtonClick}
							/>
						</div>
					</FlightsSearchFormMobile>
				</div>

				<form
					ref={formRef}
					onSubmit={e => {
						void onSubmit(e);
					}}
					className={`flightsSearchForm__form w-full top-0 left-0 md:flex flex-col ${
						showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
					}
					${widgetStyles?.isTertiaryTemplate ? 'z-[2] md:z-auto' : ''}`}
					style={{
						height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
					}}
				>
					<div
						className={`flightsSearchForm__header block md:hidden
							${widgetStyles?.isSecondaryTemplate ? 'py-3 px-6' : 'py-2 px-6 flex justify-end'}
							`}
						style={{
							backgroundColor: '#ffffff',
						}}
					>
						<button
							onClick={() => showMobileFieldsContainerHandler(false)}
							className={`flightsSearchForm__backBtn  !p-0
								${widgetStyles?.isSecondaryTemplate ? 'text-sm text-[#475467] font-semibold chevronLeftIco' : 'icon-close-secondary text-base font-bold'}
								`}
							type='button'
						>
							{widgetStyles?.isSecondaryTemplate && <span className='pl-2'>{`${traductions('comeBack') || 'comeBack'}`}</span>}
						</button>
					</div>
					<div className='flightsSearchForm__body bg-white md:bg-transparent left-0 md:top-0 relative md:h-auto px-0 md:pt-0 md:rounded-t-none pt-0 top-0'>
						<div
							className={`flightsSearchForm__toolbar hidden md:flex flex-row justify-between items-center  ${
								widgetStyles?.isSecondaryTemplate ? 'mb-[24px]' : 'mb-[10px]'
							}`}
						>
							<div className='flightsSearchForm__tripTypeSelect'>
								{!widgetStyles?.isTertiaryTemplate && (
									<TripTypeSelector
										name={tripTypeName}
										options={tripTypesOptions}
										register={register}
										onChange={tripTypeSelectHandler}
										defaultValue={tripType}
										setValue={setValue}
										className='!py-0 !px-0 hidden md:block'
										customSelectClassName='text-base text-white font-family-regular'
										optionsMarginTop={'10px'}
										isRadioInpustSelector={widgetStyles?.isEnabledFlightTypeRadioButtons}
									/>
								)}
								{widgetStyles?.isTertiaryTemplate && (
									<Tabs
										className={'flightsSearchForm__tripTypeTabs md:flex z-50 top-0 justify-center w-full left-0'}
										tabClassName={
											'no-underline hover:shadow-lg border border-[#BEBEBE] text-[#4D4D4F] mr-2 py-[10px] px-[15px] text-sm  min-w-[140px]'
										}
										activeTab={tripType}
										tabs={tripTypesTabs}
										activeColor={'#F8FBFD'}
										tabActiveClass={'border !border-[#0A609C] md:shadow-[0px_2px_2px_0px_rgba(16,117,187,0.22)]'}
										desactiveColor={'transparent'}
										isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
										onClick={tripTypeTabsHandler}
									/>
								)}
							</div>
							<div className={`flightsSearchForm__onlyDirectflight ${widgetStyles?.isSecondaryTemplate ? 'md:w-2/6 pl-8' : ''}`}>
								<Checkbox
									name={onlyDirectFlightName}
									id={onlyDirectFlightName}
									primaryColor={colorSecondary}
									secondaryColor={'#FFFFFF'}
									forcePrimaryColorBorder={true}
									checkmarkContainerSize={19}
									label={traductions('onlyDirectFlights') || 'onlyDirectflights'}
									className=''
									isSwitch={widgetStyles?.isEnabledDirectFlightSwitch}
									labelClassName={`${
										widgetStyles?.isEnabledDirectFlightSwitch
											? 'text-gray-900 text-xs font-normal leading-4'
											: widgetStyles?.isTertiaryTemplate
												? 'text-[#4D4D4F]'
												: 'text-base font-extrabold text-white'
									}`}
									register={register(onlyDirectFlightName)}
									getValues={getValues}
								/>
							</div>
						</div>
						<div className='flightsSearchForm__content relative'>
							{destinationsNames.map((names: DestinationsNamesFlightsSearchFormInterface, index: number) => {
								return (
									<div
										key={names.departure.id}
										className={`flightsSearchForm__flightContainer flex-row flex-wrap md:mt-[20px] ${
											index === 0 || (tripType === FlightTripTypes.multiple && index < destinationsNumber) ? 'flex' : 'hidden'
										} ${index ? 'md:mt-[20px]' : ''}`}
									>
										{widgetStyles?.isTertiaryTemplate && tripType === FlightTripTypes.multiple && (
											<div className='w-full font-medium text-[14px] hidden md:flex'>
												{widgetStyles?.isTertiaryTemplate && (
													<span>
														{traductions('flight') || 'flight'} {index + 1}
													</span>
												)}
												{index > 1 && (
													<button
														onClick={() => destinationFieldNumberHandler(-1)}
														className={`flightsSearchForm__addDestinationBtn iconRemoveTertiary !p-0 
															${widgetStyles?.isTertiaryTemplate ? 'text-[#1075BB] text-[14px] ml-[10px]' : 'font-bold text-base text-white'} 
														`}
														type='button'
													>
														<span>{traductions('remove') || 'remove'}</span>
													</button>
												)}
											</div>
										)}
										<div
											className={`flightsSearchForm__flightFields w-full   ${
												widgetStyles?.isSecondaryTemplate ? 'md:w-4/6 pl-0' : 'md:w-3/5 md:pr-[15px]'
											}`}
										>
											{widgetStyles?.isTertiaryTemplate && (
												<span className='hidden md:flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
													{traductions('whereTravel') || 'whereTravel'}
												</span>
											)}
											<div
												className={`flightsSearchForm__destinationsContainer w-full flex flex-row relative 
													${widgetStyles?.isSecondaryTemplate ? 'gap-8 items-start ' : 'items-center md:border md:border-solid bg-white'}
													${widgetStyles?.isTertiaryTemplate ? 'border-[#BEBEBE] md-rounded-none' : 'md:rounded-[10px]'} 
												`}
											>
												<DestinationField
													inputRefs={inputRefs}
													showDescription={true}
													tripType={tripType}
													fieldId={names.departure.id}
													name={names.departure.name}
													hiddenName={names.departure.hiddenName}
													iconClassName={`${widgetStyles?.isSecondaryTemplate ? 'iconPlaneSecondary text-[#98A2B3]' : 'pinIco'} 
														${widgetStyles?.isTertiaryTemplate ? 'iconAirplaneDepartureTertiary' : ''}`}
													originalName={names.departure.original}
													title={traductions('origin') || 'origin'}
													placeHolder={
														widgetStyles?.isSecondaryTemplate
															? traductions('selectAnOrigin') || 'selectAnOrigin'
															: traductions('selectOrigin') || 'selectOrigin'
													}
													backButtonTraduction={traductions('back') || 'back'}
													nextButtonTraduction={traductions('next') || 'next'}
													errorRequiredFieldTraduction={traductions('requiredField') || 'requiredField'}
													showSpinner={isDepartureAirportsLoading}
													colorSecondary={colorSecondary}
													className={`${mobileFieldStep === names.departure.id ? 'z-50' : 'z-0'} 
														md:z-auto flightsSearchForm__departureDestination 
														${widgetStyles?.isTertiaryTemplate ? 'border-0 md:border-r md:border-solid border-[#BEBEBE]' : ''}`}
													required={index === 0 || (tripType === FlightTripTypes.multiple && index < destinationsNumber)}
													register={register}
													setValue={setValue}
													getValues={getValues}
													trigger={trigger}
													errors={errors}
													useBackButton={index !== 0}
													onChange={departureDestinationFieldHandler}
													onShowDestinations={() => undefined}
													nextCallBack={nextMobileFieldHandler}
													backCallBack={backMobileFieldHandler}
													isAutomaticHandler={isMobile}
													visibleTitle={widgetStyles?.isSecondaryTemplate}
													isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
													isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
													destinations={departureAirports}
													truncate={widgetStyles?.isEnabledTruncatedInputs}
													isMobile={isMobile}
												/>
												<DestinationField
													showDescription={true}
													inputRefs={inputRefs}
													tripType={tripType}
													fieldId={names.arrival.id}
													name={names.arrival.name}
													hiddenName={names.arrival.hiddenName}
													originalName={names.arrival.original}
													iconClassName={`${widgetStyles?.isSecondaryTemplate ? 'iconPlaneSecondary text-[#98A2B3]' : 'pinIco'} 
														${widgetStyles?.isTertiaryTemplate ? 'iconAirplaneReturnTertiary' : ''}`}
													title={traductions('destination') || 'destination'}
													placeHolder={
														widgetStyles?.isSecondaryTemplate
															? traductions('selectADestination') || 'selectADestination'
															: traductions('selectDestination') || 'selectDestination'
													}
													backButtonTraduction={traductions('back') || 'back'}
													nextButtonTraduction={traductions('next') || 'next'}
													errorRequiredFieldTraduction={traductions('requiredField') || 'requiredField'}
													showSpinner={isArrivalAirportsLoading}
													colorSecondary={colorSecondary}
													className={`w-full ${mobileFieldStep === names.arrival.id ? 'z-50' : 'z-0'} 
														md:z-auto ${widgetStyles?.isTertiaryTemplate ? '' : 'flightsSearchForm__arrivalDestination'}`}
													required={index === 0 || (tripType === FlightTripTypes.multiple && index < destinationsNumber)}
													register={register}
													setValue={setValue}
													getValues={getValues}
													trigger={trigger}
													errors={errors}
													useBackButton={true}
													onChange={arrivalDestinationFieldHandler}
													onShowDestinations={() => undefined}
													nextCallBack={nextMobileFieldHandler}
													backCallBack={backMobileFieldHandler}
													isAutomaticHandler={isMobile}
													isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
													isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
													visibleTitle={widgetStyles?.isSecondaryTemplate}
													destinations={arrivalAirports}
													truncate={widgetStyles?.isEnabledTruncatedInputs}
													isMobile={isMobile}
												/>
											</div>
										</div>
										<div className={`flightsSearchForm__dates w-full relative ${widgetStyles?.isSecondaryTemplate ? 'md:w-2/6 pl-8' : 'md:w-2/5'}`}>
											{widgetStyles?.isTertiaryTemplate && (
												<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
													{traductions('whenTravel') || 'whenTravel'}
												</span>
											)}
											<DatesField
												fieldId={names.dates.id}
												title={traductions('selectYourDates') || 'selectYourDates'}
												placeHolder={
													widgetStyles?.isSecondaryTemplate
														? traductions('selectAnOption') || 'selectAnOption'
														: traductions('selectYourDates') || 'selectYourDates'
												}
												name={names.dates.name}
												hiddenName={names.dates.hiddenName}
												backButtonTraduction={traductions('back') || 'back'}
												nextButtonTraduction={traductions('next') || 'next'}
												errorRequiredFieldTraduction={traductions('requiredField') || 'requiredField'}
												buttonText={
													widgetStyles?.isSecondaryTemplate || widgetStyles?.isTertiaryTemplate
														? traductions('apply') || 'apply'
														: traductions('ready') || 'ready'
												}
												cancelText={traductions('cancel') || 'cancel'}
												type={
													tripType === FlightTripTypes.multiple || tripType === FlightTripTypes.oneway
														? DatesFieldTypes.oneDate
														: DatesFieldTypes.startEndDates
												}
												config={{
													date: true,
													time: false,
												}}
												minDate={minDate}
												defaultDates={defaultDates[index]}
												useBackButton={true}
												required={index === 0 || (tripType === FlightTripTypes.multiple && index < destinationsNumber)}
												colorPrimary={colorPrimary}
												colorSecondary={colorSecondary}
												colorThird={widgetStyles?.isSecondaryTemplate ? '#344054' : '#7A8083'}
												className={`w-full ${mobileFieldStep === names.dates.id ? 'z-50' : 'z-0'} ${
													tripType === FlightTripTypes.multiple && index === destinationsNumber - 1 ? 'flightsSearchForm__lastdMultiDate' : ''
												} md:z-auto`}
												register={register}
												setValue={setValue}
												getValues={getValues}
												trigger={trigger}
												watch={watch}
												errors={errors}
												iconClassName={
													widgetStyles?.isSecondaryTemplate
														? 'iconCalendarSecondary text-[#98A2B3]'
														: widgetStyles?.isTertiaryTemplate
															? 'iconCalendarDepartureTertiary'
															: 'searchCalendarIco'
												}
												visibleTitle={widgetStyles?.isSecondaryTemplate}
												isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
												isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
												nextCallBack={nextMobileFieldHandler}
												backCallBack={backMobileFieldHandler}
												onChange={(data: InputEventsHandler) => datesHandler(data, names.dates.hiddenName, index)}
												onShowDates={() => undefined}
												traductions={traductions}
												dateFormat={widgetStyles?.isSecondaryTemplate ? DateFormat.weekdayDayMonth : undefined}
											/>
										</div>
									</div>
								);
							})}
							<div
								className={`flightsSearchForm__multiActions w-full absolute md:relative flex-row flex-wrap justify-between md:mt-[20px] z-50 md:z-auto md:top-0 left-0 ${
									tripType === FlightTripTypes.multiple ? 'md:flex' : 'md:hidden'
								} ${tripType === FlightTripTypes.multiple && showMultiActions ? 'flex px-[40px] md:px-0' : 'hidden'}`}
							>
								{destinationsNumber !== minMultiDestinationsNumber && !widgetStyles?.isTertiaryTemplate ? (
									<button
										onClick={() => destinationFieldNumberHandler(-1)}
										className='flightsSearchForm__addDestinationBtn minusIco !p-0 text-base font-bold text-white'
										type='button'
									>
										<span className='pl-[10px]'>{traductions('removeFlight') || 'removeFlight'}</span>
									</button>
								) : (
									<span className='flightsSearchForm__addDestinationBtn !p-0 text-base font-bold text-white'></span>
								)}

								{destinationsNumber < destinationsNames.length && (
									<button
										onClick={() => destinationFieldNumberHandler(1)}
										className={`flightsSearchForm__addDestinationBtn plusIco !p-0
											${widgetStyles?.isTertiaryTemplate ? 'text-[14px]' : 'font-bold text-base text-white'} 
										`}
										style={{
											color: widgetStyles?.isTertiaryTemplate ? '#1075BB' : '',
										}}
										type='button'
									>
										<span className='pl-[10px]'>
											{widgetStyles?.isTertiaryTemplate
												? traductions('addAnotherFlight') || 'addAnotherFlight'
												: traductions('addFlight') || 'addFlight'}
										</span>
									</button>
								)}
							</div>
							<div
								className={`flightsSearchForm__rowContent flex flex-row flex-wrap
									${widgetStyles?.isTertiaryTemplate ? 'md:mt-[25px]' : 'md:mt-[20px]'}`}
							>
								<div
									className={`flightsSearchForm__passengers w-full 
										${widgetStyles?.isSecondaryTemplate ? 'md:w-2/6 md:pr-[15px]' : 'md:w-2/5 md:pr-[8px]'} 
										relative `}
								>
									<div className='passengersField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
										<Input
											register={register(tempPassengersNames.cabinName, {
												required: true,
											})}
											required={true}
											type={ElementTypes.text}
											autocomplete={'off'}
										/>
									</div>
									<PassengersField
										fieldId={passengersId}
										traductions={traductions}
										title={passengerTranslate}
										placeHolder={passengerTranslate}
										useOptionalText={true}
										name={passengersName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										restrictions={passengersRestrictions}
										maxPassengers={passengersRestrictions.maxPassengers}
										useBackButton={true}
										required={true}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === passengersId && !showMobileAirlines ? 'z-50' : 'z-0'} md:z-auto`}
										desktopModalClassName={
											widgetStyles?.isTertiaryTemplate ? 'shadow-xl md:flex-col top-[75px] md:w-full md:rounded-t-none px-[15px]' : ''
										}
										register={register}
										watch={watch}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										onChangePassengers={passengersHandler}
										onModalInputsHandler={passengersModalInputsHandler}
										onModalFieldHandler={passengersModalFieldHandler}
										visibleTitle={true}
										isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
										isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
										iconClassName={widgetStyles?.isSecondaryTemplate ? 'iconUserPco' : 'searchAdultIco'}
										truncate={widgetStyles?.isEnabledTruncatedInputs}
									>
										{(!isFilterChangeBetweenCabinAndAirline || showMobileFieldsContainer) && (
											<CabinMobile
												cabinsOptions={cabinsOptions}
												cabinModalValue={cabinModalValue}
												tempPassengersNames={tempPassengersNames}
												traductions={traductions}
												cabinHandler={cabinHandler}
												isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
												isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
												colorSecondary={colorSecondary}
											/>
										)}
										<AirlineMobile
											passengersId={passengersId}
											watchAirline={watchAirline}
											traductions={traductions}
											airlinesMobileFieldHandler={airlinesMobileFieldHandler}
											isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
											isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
										>
											<Airline
												customClass={''}
												airlineName={airlineName}
												hideOnDesktop={isFilterChangeBetweenCabinAndAirline}
												colorSecondary={colorSecondary}
												airlines={airlines}
												airlineId={airlineId}
												showMobileAirlines={false}
												traductions={traductions}
												register={register}
												getValues={getValues}
												setValue={setValue}
												trigger={trigger}
												errors={errors}
												isMobile={true}
												useOptionalText={true}
												arlineHandler={arlineHandler}
												airlinesMobileFieldHandler={airlinesMobileFieldHandler}
												isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
												truncate={widgetStyles?.isEnabledTruncatedInputs}
											/>
										</AirlineMobile>
										{isFilterChangeBetweenCabinAndAirline && !showMobileFieldsContainer && (
											<Airline
												customClass={`passengersFieldInputs__airline
													${widgetStyles?.isTertiaryTemplate ? 'md:py-[12px] px-[7px]' : 'p-[20px]'}
												`}
												airlineName={airlineName}
												airlines={airlines}
												airlineId={airlineId}
												showMobileAirlines={showMobileAirlines}
												arlineHandler={arlineHandler}
												traductions={traductions}
												register={register}
												getValues={getValues}
												setValue={setValue}
												trigger={trigger}
												errors={errors}
												airlinesMobileFieldHandler={airlinesMobileFieldHandler}
												isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
												isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
												truncate={widgetStyles?.isEnabledTruncatedInputs}
											/>
										)}
										{hasPromoCodeActive && (
											<div className={'passengersFieldInputs__content__promoCode flex-grow flex flex-col mb-4 md:hidden'}>
												<p className={'passengersFieldInputs__title__promoCode mb-3 text-base text-[#616161] whitespace-nowrap'}>
													{traductions('promoCode') || 'promoCode'}
												</p>
												<span>{promoCodeInputElement}</span>
											</div>
										)}
									</PassengersField>
								</div>

								<Airline
									customClass={`${widgetStyles?.isSecondaryTemplate ? 'hidden md:flex md:w-2/6' : 'md:w-2/5'}`}
									airlineName={airlineName}
									hideOnDesktop={isFilterChangeBetweenCabinAndAirline}
									colorSecondary={colorSecondary}
									airlines={airlines}
									airlineId={airlineId}
									showMobileAirlines={showMobileAirlines}
									traductions={traductions}
									register={register}
									getValues={getValues}
									setValue={setValue}
									trigger={trigger}
									errors={errors}
									useOptionalText={true}
									arlineHandler={arlineHandler}
									airlinesMobileFieldHandler={airlinesMobileFieldHandler}
									isSecondaryTemplate={widgetStyles?.isSecondaryTemplate}
									isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
									truncate={widgetStyles?.isEnabledTruncatedInputs}
								/>

								{isFilterChangeBetweenCabinAndAirline && (
									<Cabin
										labelClassName={`hidden md:flex ${widgetStyles?.isTertiaryTemplate ? 'font-medium text-[13px] ' : 'text-xs'}`}
										label={traductions('class') || 'class'}
										cabinsOptions={cabinsOptions}
										colorSecondary={colorSecondary}
										cabinModalValue={cabinModalValue}
										tempPassengersNames={tempPassengersNames}
										traductions={traductions}
										cabinHandler={cabinHandler}
										isTertiaryTemplate={widgetStyles?.isTertiaryTemplate}
									/>
								)}

								<div
									className={`flightsSearchForm__footer md:relative hidden md:flex 
										${widgetStyles?.isSecondaryTemplate ? 'md:w-2/6 md:pl-8 items-end' : 'md:w-1/5 md:pl-[15px]'}
											${widgetStyles?.isTertiaryTemplate ? 'items-end' : ''}
										`}
								>
									<Button
										type='submit'
										customClass={`flightsSearchForm__searchBtn text-white w-full text-[21px] items-center
											${widgetStyles?.isSecondaryTemplate ? 'rounded-full' : 'rounded-lg'} 
											${widgetStyles?.isTertiaryTemplate ? 'rounded-none tertiary__shadow' : ''}
											${widgetStyles?.isTertiaryTemplate && !isValid ? 'tertiary__disabled' : ''}`}
										content={
											<>
												{widgetStyles?.isSecondaryTemplate || widgetStyles?.isTertiaryTemplate ? null : (
													<span className='searchIco text-[25px] font-extrabold' />
												)}
												<span
													className={`${
														widgetStyles?.isSecondaryTemplate || widgetStyles?.isTertiaryTemplate
															? 'text-base !text-[14px]'
															: 'text-[21px] font-extrabold pl-[10px]'
													} `}
												>
													{widgetStyles?.isTertiaryTemplate ? traductions('searchFlights') || 'searchFlights' : traductions('search') || 'search'}
												</span>
											</>
										}
										styles={{
											background: colorSecondary || '#878787',
											padding: widgetStyles?.isTertiaryTemplate && '11px 5px',
										}}
										disabled={!isValid && widgetStyles?.isTertiaryTemplate}
									/>
								</div>
								<div className='block md:hidden w-full z-40 bg-white absolute left-0  h-screen' />
							</div>

							{hasPromoCodeActive && (
								<div className='w-full flex-col gap-4 justify-start md:justify-start items-start mt-[30px] z-50 md:z-auto md:top-0 left-0 md:flex '>
									<div className={`hidden w-full md:w-2/5 relative flex-row flex-wrap md:pr-[8px] ${watchWithPromoCode ? 'md:flex' : 'md:hidden'}`}>
										{promoCodeInputElement}
									</div>

									<div className='ml-[10px]'>
										<Checkbox
											name={withPromoCodeName}
											id={withPromoCodeName}
											primaryColor={colorSecondary}
											secondaryColor={'#FFFFFF'}
											forcePrimaryColorBorder={true}
											checkmarkContainerSize={19}
											label={traductions('withPromoCode') || 'withPromoCode'}
											className=''
											labelClassName='text-base text-black md:text-white'
											register={register(withPromoCodeName, {
												onChange: withPromoCodeHandler,
											})}
											getValues={getValues}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default FlightsSearchForm;
