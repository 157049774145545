import { FilterElement, FilterTypes } from '../../shared/enums';
import { GenericFilter } from '../../shared/interfaces';

export const genericFiltersMock: GenericFilter[] = [
	{
		type: FilterTypes.HISTORICAL_TYPE,
		elementType: FilterElement.CHECKBOX,
		options: [
			{
				label: 'Hotel',
				value: 'Hotel',
				extraDescription: undefined,
			},
			{
				label: 'Air',
				value: 'Air',
				extraDescription: undefined,
			},
			{
				label: 'Plan',
				value: 'Plan',
				extraDescription: undefined,
			},
		],
	},
	{
		type: FilterTypes.HISTORICAL_STATUS,
		elementType: FilterElement.RADIO,
		options: [
			{
				label: 'Active',
				value: 'Active',
				extraDescription: undefined,
			},
			{
				label: 'Completed',
				value: 'Completed',
				extraDescription: undefined,
			},
		],
	},
];
