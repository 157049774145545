import { Reserves, SearchReservesInterface } from '../../../../../../shared/interfaces/flights/search-reserves.interface';
import { StateResponseImport } from '../../../../../../shared/enums/reserve-list.enum';
import { tableFilling } from '../../../../../../shared/services/reserves-list.service';
import { OptionSelect } from '../../../../../../shared/interfaces/inputs.interface';
import { Spinner } from '../../../../../ui-elements/spinner/spinner';
import { SearchReserves } from '../search-reserves/search-reserves';
import { Select } from '../../../../../ui-elements/select/select';
import Table from '../../../../../ui-elements/table/table';
import styles from './reserves-list.module.scss';
import { useState } from 'react';

export interface ReservesListProps {
	dataSearchReserves: SearchReservesInterface;
	reserves: Reserves[];
	labelButtonImport: string;
	labelButtonDownload: string;
	labelImportReserve: string;
	labelCancel: string;
	labelReserve: string;
	labelProduct: string;
	labelSupplier: string;
	headsReserves: string[];
	productStatesLabels: string[];
	optionsProducts: OptionSelect[];
	optionsSupplier: OptionSelect[];
	stateResponseImport: StateResponseImport;
	linkDetailReserve: string;
	callbackSearchReserve: (e: any) => void;
	callbackImportReserve: (e: any) => void;
}

export function ReservesList({
	dataSearchReserves,
	reserves,
	labelButtonImport,
	labelButtonDownload,
	labelImportReserve,
	labelCancel,
	labelReserve,
	labelProduct,
	labelSupplier,
	headsReserves,
	productStatesLabels,
	optionsProducts,
	optionsSupplier,
	stateResponseImport,
	linkDetailReserve,
	callbackImportReserve,
	callbackSearchReserve,
}: ReservesListProps): JSX.Element {
	const [showModal, setShowModal] = useState<boolean>(false);
	const modalImportReserve = (stateResponseImport: StateResponseImport): JSX.Element => {
		if (stateResponseImport === StateResponseImport.loading) {
			return (
				<div
					className={`${
						showModal ? 'visible' : 'invisible'
					} fixed h-screen w-screen bg-[rgba(33,50,97,0.4)] z-10 flex items-center justify-center left-0 top-0`}
				>
					<div className={'reservesList_shadowModal h-[270px] w-[760px] rounded-md bg-white flex items-center justify-center'}>
						<Spinner className={'text-4xl'} />
					</div>
				</div>
			);
		}

		if (stateResponseImport === StateResponseImport.fail) {
			return (
				<div
					className={`fixed h-screen w-screen bg-[rgba(33,50,97,0.4)] z-10 flex items-center justify-center left-0 top-0 ${
						showModal ? 'visible' : 'invisible'
					}`}
				>
					<div className={'reservesList_shadowModal h-[270px] w-[760px] rounded-md bg-white flex flex-col items-center justify-center px-12'}>
						<div className='flex items-center'>
							<span className='errorStateIco text-red-600 text-[90px]'></span>
							<div className='pl-5'>
								<h2 className='text-[24px] font-bold'>¡Error!</h2>
								<p className='text-[16px]'>Hubo error al momento de importar tu reserva. Te invitamos a volver a intentarlo.</p>
							</div>
						</div>
						<div className='w-full flex items-center justify-end mt-4'>
							<button className='h-[45px] w-[136px] rounded-md bg-[#213261] text-white' onClick={() => setShowModal(false)}>
								Volver
							</button>
						</div>
					</div>
				</div>
			);
		}

		if (stateResponseImport === StateResponseImport.successful) {
			return (
				<div
					className={`fixed h-screen w-screen bg-[rgba(33,50,97,0.4)] z-10 flex items-center justify-center left-0 top-0 ${
						showModal ? 'visible' : 'invisible'
					}`}
				>
					<div className={'reservesList_shadowModal h-[270px] w-[760px] rounded-md bg-white flex flex-col items-center justify-center px-12'}>
						<div className='flex items-center'>
							<span className='correctStateIco text-green-400 text-[90px]'></span>
							<div className='pl-5'>
								<h2 className='text-[24px] font-bold'>¡Felicidades!</h2>
								<p className='text-[16px]'>Reserva importada correctamente. Ahora puedes ver el detalle de la reserva o importar más elementos.</p>
							</div>
						</div>
						<div className='w-full flex items-center justify-end mt-4'>
							<h2 className='cursor-pointer text-[#878787]' onClick={() => setShowModal(false)}>
								Volver
							</h2>
							<a
								target='_blank'
								href={linkDetailReserve}
								className='h-[45px] w-[136px] rounded-md bg-[#213261] text-white font-bold ml-4 flex items-center justify-center'
								rel='noreferrer'
							>
								Ver detalle
							</a>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div
				className={`${
					showModal ? 'visible' : 'invisible'
				} fixed h-screen w-screen bg-[rgba(33,50,97,0.4)] z-10 flex items-center justify-center left-0 top-0`}
			>
				<div className={'reservesList_shadowModal h-[270px] w-[760px] rounded-md bg-white'}>
					<div className='h-[68px] w-full px-8 flex items-center justify-between border-b-[1px] border-[#CDCDCD]'>
						<h2 className='font-bold text-[#393939]'>{labelImportReserve}</h2>
						<button className='text-[20px] text-[#CDCDCD]' onClick={() => setShowModal(false)}>
							<span className='closeCircleIco' />
						</button>
					</div>
					<form className='p-8 text-[16px]' onSubmit={callbackImportReserve}>
						<div className='flex justify-between'>
							<div className='w-[40%] flex flex-col'>
								<label className='font-bold text-[#393939]' htmlFor='text'>
									{labelReserve}
								</label>
								<input className='h-[45px] w-full px-4 bg-[#F4F4F4] rounded-md outline-none mt-2' type='text' name='reserve' />
							</div>
							<div className='w-[25%] flex flex-col'>
								<label className='font-bold text-[#393939]' htmlFor='select'>
									{labelProduct}
								</label>
								<Select options={optionsProducts} className='h-[45px] w-full mt-2 py-0 px-3 bg-[#F4F4F4] text-[#878787]' />
							</div>
							<div className='w-[25%] flex flex-col'>
								<label className='font-bold text-[#393939]' htmlFor='select'>
									{labelSupplier}
								</label>
								<Select options={optionsSupplier} className='h-[45px] w-full mt-2 py-0 px-3 bg-[#F4F4F4] text-[#878787]' />
							</div>
						</div>
						<div className='flex items-center justify-end mt-4'>
							<h2 className='cursor-pointer text-[#878787]' onClick={() => setShowModal(false)}>
								{labelCancel}
							</h2>
							<button className='h-[45px] w-[136px] rounded-md bg-[#213261] text-white font-bold ml-4' type='submit'>
								{labelButtonImport}
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={'reservesList'}>
				{modalImportReserve(stateResponseImport)}
				<div className='w-full flex justify-end mb-8'>
					<button
						onClick={() => setShowModal(true)}
						className={'reservesList_shadowButton h-[45px] w-[170px] rounded-md bg-[#108FCF] text-white text-[16px] flex items-center justify-center'}
					>
						<span className='heartIco mr-2' />
						<p className='font-bold'>{labelButtonImport.toUpperCase()}</p>
					</button>
					<button className={'reservesList_shadowButton h-[45px] w-[170px] rounded-md bg-[#108FCF] text-white text-[16px] ml-6'}>
						<p className='font-bold'>{labelButtonDownload}</p>
					</button>
				</div>
				<SearchReserves data={dataSearchReserves} callBack={callbackSearchReserve} />
				<Table
					data={tableFilling({
						dataSearchReserves,
						reserves,
						labelButtonImport,
						labelButtonDownload,
						labelImportReserve,
						labelCancel,
						labelReserve,
						labelProduct,
						labelSupplier,
						headsReserves,
						productStatesLabels,
						optionsProducts,
						optionsSupplier,
						stateResponseImport,
						linkDetailReserve,
						callbackImportReserve,
						callbackSearchReserve,
					})}
					className={'w-full text-[12px] text-[#393939] rounded-md mt-24 bg-white reservesList_tableReserves'}
				/>
			</div>
		</>
	);
}
