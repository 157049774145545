export enum ColorFormatsNames {
	rgb = 'rgb',
	hex = '#',
}

export enum ColorFormats {
	rgb = 3,
	rgba = 4,
	hex = 6,
	hexa = 8,
}
