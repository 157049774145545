import { AssistancesSearchFormSubmitInterface } from '../interfaces/assistances-search-form.interface';
export class AssistancesSearchFormUtil {
	static mapSearchUrl(data: AssistancesSearchFormSubmitInterface): string {
		const searchUrl: string = `${data?.destinationCode || ''}/${data?.departureDate || ''}/${data?.arrivalDate || ''}`;
		return searchUrl;
	}

	public static historyHandle(
		historyJson: AssistancesSearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): AssistancesSearchFormSubmitInterface {
		const history: AssistancesSearchFormSubmitInterface =
			(historyJson && { ...historyJson }) || ({} as unknown as AssistancesSearchFormSubmitInterface);

		return { ...history };
	}
}
