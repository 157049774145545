import { Button } from '../../../ui-elements';
export interface CallActionBannerProps {
	imageUlr?: string;
	title: string;
	description: string;
	callActionText?: string;
	urlCallAction: string;
}

export const CallActionBanner = ({ imageUlr, title, description, callActionText, urlCallAction }: CallActionBannerProps): JSX.Element => {
	const callActionRedirection = (): void => {
		if (urlCallAction) {
			window.open(urlCallAction, '_blank');
		}
	};

	return (
		<div className='callActionBanner bg-gray-200 bg-no-repeat bg-cover' style={{ backgroundImage: `URL("${imageUlr || ''}")` }}>
			<div className='callActionBanner__container flex md:justify-end md:max-h-[346px]'>
				<div className='max-w-[525px] py-[50px] px-4'>
					<h4 className='callActionBanner__title text-[26px] lg:text-[36px] mb-6'>{title}</h4>
					<p className='callActionBanner__description'>{description}</p>
					<Button
						text={callActionText}
						customClass='callActionBanner__btn mt-4 w-[273px] !bg-gray-400 text-white rounded-md'
						onClick={callActionRedirection}
					/>
				</div>
			</div>
		</div>
	);
};
