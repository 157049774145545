import { useState, useCallback } from 'react';

export const useDebounce = (): ((callback: (...args: any[]) => void, wait: number) => (...args: any[]) => void) => {
	const [timeoutId, setTimeoutId] = useState<number>(0);
	const debounceHandler = useCallback(
		(callback: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
			return (...args): void => {
				clearTimeout(timeoutId);

				const timeout: number = Number(
					setTimeout(() => {
						callback.apply(null, args);
					}, wait),
				);

				setTimeoutId(timeout);
			};
		},
		[timeoutId],
	);

	return debounceHandler;
};

export default useDebounce;
