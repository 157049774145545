import { SpecialEventsDefaultValueProp } from '../../shared/interfaces/special-events.interface';
import SpecialEventsSearch from '../../templates/special-events-search/special-events-search';
import { LocalStorageHistoriesNames } from '../../shared/enums/local-storage.enum';
import { DisneySearchFormSubmitInterface } from '@smartlinks/react-design-system';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import { SpecialEventsLayout } from '../../shared/enums/special-events.enum';
import { DisneyService } from '../../shared/services/disney.service';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import { CommonsUtil } from '../../shared/utils/commons';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';

export interface SpecialEventsSearchProps extends SearchWidgetInterface<any> {
	customLayout?: string;
	defaultValue?: SpecialEventsDefaultValueProp;
}

const AppSpecialEventsSearchWidget = ({
	elementId,
	token,
	language,
	agency,
	customLayout,
	defaultValue,
	callUrlSearch,
}: SpecialEventsSearchProps): JSX.Element => {
	const widgetClassName: string = 'specialEventsSearchWidget';
	const {
		agencyState,
		isLoading,
		widgetService: carsService,
		widgetStyles,
		urlSearchHandler,
		t,
	} = useSearchWidget<DisneyService, DisneySearchFormSubmitInterface>({
		className: widgetClassName,
		styles: '',
		token,
		language,
		agency,
		callUrlSearch,
		productServiceClass: DisneyService,
		customHistoryName: LocalStorageHistoriesNames.specialEventsSearch,
	});

	const customLayoutEnum: SpecialEventsLayout = (SpecialEventsLayout as any)[customLayout || ''] || SpecialEventsLayout.BUTTON;
	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			{!isLoading && (
				<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
					<SpecialEventsSearch
						elementId={elementId}
						language={language || 'es'}
						agency={agencyState}
						layout={customLayoutEnum || SpecialEventsLayout.BUTTON}
						callUrlSearch={urlSearchHandler}
						t={t}
						disneyService={carsService}
						defaultValue={defaultValue}
					/>
				</div>
			)}
		</Suspense>
	);
};

export function SpecialEventsSearchWidget(props: SpecialEventsSearchProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className={`reactWidget specialEvents`}>
					<AppSpecialEventsSearchWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}
