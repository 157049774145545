import { FieldButtonEventHandlerInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import { FieldValues, UseFormRegister, UseFormReturn, FieldErrorsImpl, DeepRequired } from 'react-hook-form';
import { useCallback, useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { ListInterface, ListItemInterface } from '../../../../shared/interfaces/list.interface';
import DestinationsFieldList from './destinations-field-list/destinations-field-list';
import { SearchFieldsNames } from '../../../../shared/enums/search-fields.enum';
import { ElementTypes } from '../../../../shared/enums/dynamic-forms.enum';
import { LocationEnum } from '../../../hotels/shared/enums/location.enum';
import IcomoonIcon from '../../../ui-elements/icomoon-icon/icomoon-icon';
import Backdrop from '../../../ui-elements/backdrop/backdrop';
import Spinner from '../../../ui-elements/spinner/spinner';
import Button from '../../../ui-elements/button/button';
import Input from '../../../ui-elements/input/input';
import styles from './destination-field.module.scss';
import { FlightTripTypes } from '../../../flights';
export interface DestinationFieldProps {
	tripType?: FlightTripTypes;
	fieldId?: string;
	title?: string;
	originalName?: string;
	placeHolder?: string;
	name: string;
	hiddenName: string;
	backButtonTraduction?: string;
	nextButtonTraduction?: string;
	errorRequiredFieldTraduction?: string;
	useBackButton?: boolean;
	showSpinner?: boolean;
	destinationsElement?: JSX.Element;
	iconClassName?: string;
	required?: boolean;
	disabled?: boolean;
	destinations?: ListInterface[];
	colorSecondary?: string;
	className?: string;
	desktopDestinationsClassName?: string;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	register: UseFormRegister<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	trigger?: UseFormReturn['trigger'];
	nextCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	backCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	onChange?: (element: { target: HTMLInputElement; form: HTMLFormElement }) => void;
	listCallBack?: (destintion: ListItemInterface, name: string) => void;
	onShowDestinations?: (show: boolean) => void;
	visibleTitle?: boolean;
	preventClearListAtInit?: boolean;
	showDescription?: boolean;
	isFixedTitle?: boolean;
	isAutomaticHandler?: boolean;
	truncate?: boolean;
	isMobile?: boolean;
	topCustomElement?: JSX.Element;
	inputRefs?: React.MutableRefObject<Record<string, HTMLElement | null>>;
}

export interface DestinationFieldRefHandle {
	forceCloseDestinations: () => void;
}

export const DestinationField = forwardRef<DestinationFieldRefHandle, DestinationFieldProps>(
	(
		{
			tripType,
			fieldId,
			originalName,
			title,
			placeHolder,
			name,
			hiddenName,
			backButtonTraduction,
			nextButtonTraduction,
			errorRequiredFieldTraduction,
			useBackButton,
			showSpinner,
			destinationsElement,
			iconClassName,
			required,
			disabled,
			destinations,
			colorSecondary,
			className,
			desktopDestinationsClassName,
			isSecondaryTemplate,
			isTertiaryTemplate,
			errors,
			register,
			setValue,
			getValues,
			trigger,
			nextCallBack,
			backCallBack,
			onChange,
			onShowDestinations,
			listCallBack,
			visibleTitle,
			preventClearListAtInit,
			showDescription,
			isFixedTitle,
			topCustomElement,
			truncate,
			inputRefs,
			isAutomaticHandler,
			isMobile,
		},
		ref,
	) => {
		const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
		const [showDestinations, setShowDestinations] = useState<boolean>(false);
		const [tempValue, setTempValue] = useState<string>('');
		const [classInputError, setClassInputError] = useState<string>('');
		const [showRemoveBtn, setShowRemoveBtn] = useState<boolean>(getValues ? !!getValues(name)?.length : false);
		const removeHandler = useCallback(
			async (event: React.SyntheticEvent) => {
				event?.stopPropagation();

				if (setValue) {
					setValue(name, '');

					setValue(hiddenName, '');

					if (originalName) {
						setValue(originalName, '');
					}
				}

				if (trigger) {
					await trigger(hiddenName);

					await trigger(name);

					if (originalName) {
						await trigger(originalName);
					}
				}

				showRemoveBtnHandle();
			},
			[name, hiddenName, originalName, setValue],
		);

		const onChangeHandler = useCallback(
			(element: { target: HTMLInputElement; form: HTMLFormElement }) => {
				if (onChange) {
					onChange(element);
				}
			},
			[onChange],
		);

		const loadDestinationMultiple = (): void => {
			const value: string = getValues && getValues(name);
			if (value) {
				const tempTarget: HTMLInputElement = { value: tempValue } as unknown as HTMLInputElement;
				const emptyForm: HTMLFormElement = {} as unknown as HTMLFormElement;
				const data: { target: HTMLInputElement; form: HTMLFormElement } = {
					target: tempTarget,
					form: emptyForm,
				};

				onChange && onChange(data);
			}
		};

		const clearDestinationValueEmpty = (): void => {
			const value: string = getValues && getValues(name);
			const firstIndex: number = 0;
			if (preventClearListAtInit) {
				return;
			}

			if (!value && destinations && destinations[firstIndex]) {
				destinations = [
					{
						title: destinations[firstIndex].title,
						items: [],
					},
				];
			}
		};

		clearDestinationValueEmpty();

		const showDestinationsHandler = useCallback(
			(show: boolean) => {
				tripType === FlightTripTypes.multiple && show && loadDestinationMultiple();

				if (!disabled) {
					if (onShowDestinations) {
						onShowDestinations(show);
					}

					setShowDestinations(show);

					showRemoveBtnHandle();
				}
			},
			[onShowDestinations, disabled, tempValue],
		);

		const handleShowDescription = (selectedItem: ListItemInterface): void => {
			if (setValue && showDescription) {
				const isManyChildren: boolean = Array.isArray((selectedItem.name as JSX.Element)?.props?.children);
				setValue(name, (selectedItem.name as JSX.Element)?.props?.children || selectedItem.originalName || '');

				if (isManyChildren && selectedItem.original?.description) {
					setValue(name, selectedItem.original?.description || selectedItem.originalName || '');
				}
			}
		};

		const selectedItemHandler = useCallback(
			async (selectedItem: ListItemInterface): Promise<void> => {
				if (setValue) {
					if (showDescription) {
						handleShowDescription(selectedItem);
					} else {
						setValue(name, selectedItem.originalName || selectedItem.name || '');
					}

					if (originalName && selectedItem.original) {
						setValue(originalName, selectedItem.original);
					}

					setValue(hiddenName, selectedItem?.id || '');

					if (selectedItem?.locationKey) {
						setValue(LocationEnum.key, selectedItem.locationKey || '');
					}
				}

				showDestinationsHandler(false);

				showRemoveBtnHandle();

				if (trigger) {
					await trigger(hiddenName);

					await trigger(name);
				}

				if (listCallBack) {
					listCallBack(selectedItem, name);
				}

				if (isAutomaticHandler) {
					await clickNextHandler();
				}
			},
			[name, hiddenName, setValue, showDestinationsHandler, listCallBack],
		);

		const clickNextHandler = useCallback(async () => {
			if (trigger) {
				const resultName = await trigger(name, { shouldFocus: true });
				const resultHiddenName = await trigger(hiddenName, {
					shouldFocus: true,
				});

				if (resultName && resultHiddenName && nextCallBack) {
					nextCallBack({
						id: fieldId || SearchFieldsNames.destination,
					});

					showDestinationsHandler(false);
				}
			}
		}, [name, hiddenName, fieldId, nextCallBack, trigger, showDestinationsHandler]);

		const clickBackHandler = useCallback(() => {
			if (backCallBack) {
				backCallBack({
					id: fieldId || SearchFieldsNames.destination,
				});

				showDestinationsHandler(false);
			}
		}, [fieldId, backCallBack, showDestinationsHandler]);

		useImperativeHandle(ref, () => ({
			forceCloseDestinations() {
				showDestinationsHandler(false);
			},
		}));

		useEffect(() => {
			if (disabled) {
				showDestinationsHandler(false);
			}
		}, [disabled, showDestinationsHandler]);

		useEffect(() => {
			if (errors[name] || errors[hiddenName]) {
				setClassInputError('border-1 !border-red-500 !text-red-500');
			} else {
				setClassInputError('');
			}
		}, [errors[name], errors[hiddenName], setValue]);

		const ErrorMessage = (): JSX.Element => {
			const error = errors[name] || errors[hiddenName];
			return (
				<>
					{error && (
						<span data-testid='destinationField__errorMessage' className='error-message mr-5 text-red-600 text-sm font-normal leading-5'>
							{error.message}
						</span>
					)}
				</>
			);
		};

		const showRemoveBtnHandle = useCallback(() => {
			const showRemoveBtn: boolean = getValues ? !!getValues(name)?.length : false;
			setShowRemoveBtn(showRemoveBtn);
		}, [getValues, name, showDestinations]);

		const setInputRef = (el: HTMLElement | null): void => {
			if (inputRefs && fieldId) {
				inputRefs.current[fieldId] = el;
			}
		};

		const handleFocus = (): void => {
			containerRef.current?.classList.add('input-focused');
		};

		const handleBlur = (): void => {
			containerRef.current?.classList.remove('input-focused');
		};

		return (
			<>
				<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
				<div
					className={`destinationField w-full ${
						isSecondaryTemplate ? 'secondaryTemplate' : 'bg-white'
					}  md:bg-transparent absolute md:relative max-h-screen md:max-h-[none] h-screen md:h-auto left-0 top-0 flex flex-col ${className || ''}`}
				>
					{topCustomElement && topCustomElement}
					<div
						ref={el => setInputRef(el)}
						data-testid='destinationField__inputContainer'
						className={`destinationField__inputContainer flex flex-col ${visibleTitle ? 'flex-col' : 'md:flex-row'} ${
							isSecondaryTemplate
								? 'gap-2 px-6 pt-4 pb-[2px] md:px-0 md:rounded-[8px] md:py-0'
								: 'bg-white md:px-0 px-6 py-2 md:py-[10px] md:pr-[10px] md:rounded-[10px]'
						} 
							${isTertiaryTemplate ? 'rounded-none border-none' : ''}
						rounded-none relative`}
						onClick={() => showDestinationsHandler(true)}
					>
						<div
							className={`destinationField__title ${
								!isTertiaryTemplate && !visibleTitle ? 'border-0 md:border-r md:border-solid' : ''
							} flex flex-row items-center overflow-x-hidden`}
							style={!isTertiaryTemplate ? { borderColor: disabled ? '#CCD0D6' : colorSecondary } : {}}
						>
							{!!iconClassName && !isMobile && (
								<div
									className={`destinationField__ico flex items-center md:justify-center ${
										visibleTitle ? '' : isTertiaryTemplate ? 'md:w-[50px] w-[20px]' : 'min-w-[20px] w-[20px] md:min-w-[70px] md:w-[70px]'
									}  pointers-none`}
								>
									{!isSecondaryTemplate && (
										<span
											className={`${iconClassName} text-[15px]
											${isTertiaryTemplate ? 'fontTertiaryTemplate' : 'md:text-[25px]'} `}
											style={{ color: disabled ? '#CCD0D6' : isTertiaryTemplate ? '#4D4D4F' : colorSecondary }}
										/>
									)}
								</div>
							)}
							<span
								className={`destinationField__subTitle ${
									isSecondaryTemplate
										? 'pl-0 text-[#344054] text-sm font-medium leading-5'
										: 'md:pl-[10px] md:text-xs md:text-[#616161] text-[#2C2C2C] text-base font-normal'
								} ${visibleTitle ? '' : 'md:hidden'}  whitespace-nowrap`}
							>
								{title || 'title'}
							</span>
						</div>
						<div
							ref={containerRef}
							className={`destinationField__container md:flex-grow relative w-full flex ${
								isFixedTitle ? 'flex-col items-start' : 'flex-row items-center'
							}
							${
			isSecondaryTemplate || isTertiaryTemplate
				? 'pl-0'
				: 'md:pl-[25px] md:py-0 md:pr-0 py-3 px-4 md:border-none border border-solid rounded-lg border-[#696969] bg-white md:mt-0 mt-2'
			}
							${isTertiaryTemplate ? 'rounded-none' : ''}
							`}
						>
							<span
								className={`hidden ${isFixedTitle ? 'md:block' : ''} text-title text-[#616161] whitespace-nowrap`}
								style={{
									color: colorSecondary,
								}}
							>
								{title || 'title'}
							</span>
							<div className='destinationField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
								<Input
									register={register(hiddenName, {
										required: {
											value: required ?? false,
											message: `${errorRequiredFieldTraduction || 'requiredField'}.`,
										},
										disabled,
									})}
									type={ElementTypes.text}
									autocomplete={'off'}
								/>
							</div>
							<Input
								onFocus={handleFocus}
								onBlur={handleBlur}
								register={register(name, {
									required: {
										value: required ?? false,
										message: `${errorRequiredFieldTraduction || 'requiredField'}.`,
									},
									disabled,
									onChange: onChangeHandler,
								})}
								name={name}
								required={!isSecondaryTemplate ? required : false}
								defaultValue={''}
								icon={isSecondaryTemplate && !isMobile ? iconClassName : undefined}
								iconClassName={classInputError ? '!text-red-600' : ''}
								type={ElementTypes.text}
								placeholder={placeHolder || 'destinationPlaceHolder'}
								isSecondaryTemplate={isSecondaryTemplate}
								isTertiaryTemplate={isTertiaryTemplate}
								className={`destinationField__input ${
									isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'
								}  flex-grow ${classInputError}`}
								inputClassName={`bg-transparent !p-0 font-normal md md:placeholder placeholder:font-normal
									${isFixedTitle ? 'text-Subtitle' : 'text-base'}
									${isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base placeholder:text-[#696969]'}
									${isTertiaryTemplate ? '!text-[14px] text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
								`}
								autocomplete={'off'}
								style={{
									color: disabled ? '#696969' : isTertiaryTemplate ? '#4D4D4F!important' : isMobile ? '#3A3A3A' : colorSecondary,
								}}
								truncate={truncate}
								children={
									showSpinner && showDestinations ? (
										<Spinner
											style={{ color: colorSecondary }}
											data-testid='destinationField__spinnerChild'
											className={`destinationField__spinner right-0 absolute z-30 bg-white destinationField__spinner ${
												isSecondaryTemplate ? '' : '!hidden'
											}`}
										/>
									) : (
										<button
											className={`destinationField__removeBtnchild inline-block bg-white px-[3px] pl-[5px] cursor-pointer destinationField__remove ${
												showRemoveBtn && !isFixedTitle ? '' : 'hidden'
											} ${isSecondaryTemplate ? '' : '!hidden'}`}
											type='button'
											onClick={removeHandler}
											data-testid='destinationField__removeBtn'
										>
											<IcomoonIcon icon='closeIco' fontSize={10} />
										</button>
									)
								}
							/>
							{showSpinner && showDestinations ? (
								<Spinner
									data-testid='destinationField__spinner'
									className={`destinationField__spinner right-0 absolute z-30 bg-white destinationField__spinner ${
										isSecondaryTemplate ? '!hidden' : ''
									}`}
								/>
							) : (
								<button
									className={`destinationField__removeBtn inline-block bg-white px-[3px] pl-[5px] cursor-pointer destinationField__remove ${
										showRemoveBtn && !isFixedTitle ? '' : 'hidden'
									} ${isSecondaryTemplate ? '!hidden' : ''}`}
									type='button'
									onClick={removeHandler}
									data-testid='destinationField__removeBtn'
								>
									<IcomoonIcon icon='closeIco' fontSize={10} />
								</button>
							)}
						</div>
						{isSecondaryTemplate && <ErrorMessage />}
					</div>
					{showDestinations && (
						<>
							<Backdrop
								show={showDestinations}
								onClick={() => showDestinationsHandler(false)}
								zIndex={10}
								backgroundColor={'transparent'}
								opacity={1}
								className='hidden md:block destinationField__backdrop'
								portalBackdrop={true}
								styles={`
                  .destinationField__backdrop {
                    display: none;
                  }

                  @media(min-width: 768px) {
                    .destinationField__backdrop {
                      display: block;
                    }
                  }
                `}
							/>
							<div
								className={`destinationField__desktopDestinations hidden md:block w-full md:min-w-full md:absolute md:z-[12]  md:left-0 ${
									desktopDestinationsClassName ?? ''
								} 
									${isSecondaryTemplate ? 'md:top-[105%]' : 'md:w-auto md:top-[120%]'}
									${isTertiaryTemplate ? '!top-[100%]' : ''}
								`}
							>
								{destinationsElement || (
									<DestinationsFieldList
										destinations={destinations || []}
										callBack={async (destintion: ListItemInterface) => {
											setTempValue(getValues && getValues(name));

											await selectedItemHandler(destintion);
										}}
										isSecondaryTemplate={isSecondaryTemplate}
										isTertiaryTemplate={isTertiaryTemplate}
										truncate={truncate}
										ulClassName={isSecondaryTemplate ? '' : 'pt-3'}
										secondaryColor={colorSecondary}
									/>
								)}
							</div>
						</>
					)}
					<div
						className={`destinationField__mobileDestinations md:hidden w-full h-full px-0 py-[2px] flex-grow overflow-y-hidden ${
							destinationsElement ? 'block' : 'flex'
						}`}
					>
						{destinationsElement || (
							<DestinationsFieldList
								destinations={destinations || []}
								callBack={async (destintion: ListItemInterface) => {
									setTempValue(getValues && getValues(name));

									await selectedItemHandler(destintion);
								}}
								className={`
									${isSecondaryTemplate && showDestinations ? '!py-4 !px-6' : ''}
									${isSecondaryTemplate && !showDestinations ? 'hidden' : ''}
									`}
								isSecondaryTemplate={isSecondaryTemplate}
								ulClassName={isSecondaryTemplate ? '' : 'pt-3'}
								truncate={truncate}
								secondaryColor={colorSecondary}
							/>
						)}
					</div>
					{!isAutomaticHandler && (
						<div className='destinationField__actions w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid'>
							<Button
								onClick={clickNextHandler}
								type='button'
								customClass={`destinationField__nextButton my-[15px] w-full flex items-center justify-center text-base text-white font-family-regular ${
									isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'
								}
									${isTertiaryTemplate ? 'rounded-none' : ''}`}
								styles={{
									background: colorSecondary,
								}}
								text={nextButtonTraduction || 'next'}
							/>
							{useBackButton && (
								<Button
									onClick={clickBackHandler}
									type='button'
									customClass={`destinationField__backButton my-0 py-0 w-full flex items-center justify-center text-base bg-transparent ${
										isSecondaryTemplate ? 'rounded-full border border-gray-300' : 'rounded-[10px]'
									}`}
									styles={{
										color: colorSecondary,
									}}
									text={backButtonTraduction || 'back'}
								/>
							)}
						</div>
					)}
				</div>
			</>
		);
	},
);

DestinationField.defaultProps = {
	colorSecondary: '#878787',
};

export default DestinationField;
