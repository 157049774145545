import { SlideInterface } from '../../../../../shared/interfaces/generics/slider.interface';
import { CMSGeneralComponentInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import SliderHomeSkeleton from './skeleton/skeleton-slider-home';
import useSlider from '../../../../../shared/hooks/useSlider';
import styles from './slider-home.module.scss';
import Slider from '../slider/slider';
import { useEffect } from 'react';

export interface SliderHomeProps extends CMSGeneralComponentInterface {
	iconText: string;
	transitionTime?: number;
	loading?: boolean;
	mainColor?: string;
	secondColor?: string;
	onClick?: () => void;
	className?: string;
	titleClassName?: string;
	subtitleClassName?: string;
	descriptionClassName?: string;
	buttonClassName?: string;
}

export function SliderHome({
	slides,
	iconText,
	transitionTime,
	loading,
	mainColor,
	secondColor,
	onClick,
	className,
	titleClassName,
	subtitleClassName,
	descriptionClassName,
	buttonClassName,
}: SliderHomeProps): JSX.Element {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const slidesMargin: number = 0;
	const sliderSideMargin: number = 0;
	const { position, moveToPosition, sliderRef, slidesContainerRef, slidesWidth } = useSlider(
		slides.length - 1,
		1,
		1,
		transitionTime,
		0,
		slidesMargin,
		sliderSideMargin,
		false,
	);

	const iconWidth: number = 65;
	const half: number = 2;
	useEffect(() => {
		const root = document.documentElement;
		if (mainColor) {
			root?.style.setProperty('--slider-home-main-color', mainColor);
		}
	}, [mainColor]);

	return loading ? (
		<SliderHomeSkeleton className={className} />
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`sliderHome relative ${className || ''}`}
				style={{
					height: windowWidth >= responsiveSize ? '800px' : '640px',
				}}
			>
				<Slider
					slides={slides}
					slidesHeight={windowWidth >= responsiveSize ? '800px' : '640px'}
					config={{
						showContent: true,
						showLinkButton: true,
						showSubtitleLine: true,
						showBackgroundImage: true,
						showImageBackdrop: true,
					}}
					slidesClassNames={{
						outClassName: 'sliderHome__fade',
						contentClassName: 'sliderHome__container z-10 pb-48 md:pb-[255px] px-6 sm:px-12 md:px-20 lg:px-28 xl:px-0 xl:pl-64 w-full xl:max-w-5xl',
						imageClassName: 'object-cover',
						titleClassName: `text-white text-2xl md:text-6xl ${titleClassName || ''}`,
						subtitleClassName: `text-white text-xl md:text-4xl ${subtitleClassName || ''}`,
						descriptionClassName: `text-white text-base md:text-2xl w-full md:w-4/5 xl:w-full ${descriptionClassName || ''}`,
						subtitleLineClassName: 'border-white',
						buttonClassName: `rounded-full text-black bg-white opacity-80 mt-8 text-xs md:text-base w-40 md:w-48 ${buttonClassName || ''}`,
					}}
					sliderRef={sliderRef}
					slidesContainerRef={slidesContainerRef}
					slidesWidth={slidesWidth}
					slidesMargin={slidesMargin}
					sliderSideMargin={sliderSideMargin}
					slidesNumberShow={1}
				/>
				{windowWidth >= responsiveSize && (
					<div
						className={'absolute bottom-32 z-20 flex flex-col justify-center cursor-pointer sliderHome__iconButton'}
						style={{
							width: `${iconWidth}px`,
							left: `calc(50% - ${iconWidth / half}px)`,
						}}
						onClick={onClick}
					>
						<span
							className='text-xs font-medium'
							style={{
								color: secondColor,
							}}
						>
							{iconText}
						</span>
						<IcomoonIcon icon='mouseIco' className='font-medium' fontSize={35} color={secondColor} />
					</div>
				)}
				{slides.length && (
					<div className='absolute bottom-20 md:bottom-32 right-7 md:right-14 z-10 flex flex-row justify-end items-center'>
						{slides.map((slide: SlideInterface, index: number) => {
							const isActive: boolean = position === index;
							return (
								<div
									key={index}
									onClick={() => moveToPosition(index)}
									className={`mx-1.5 cursor-pointer ${
										isActive ? 'h-px w-6 md:w-9 border-2 md:border-4 rounded-xl' : 'h-1.5 md:h-2 w-1.5 md:w-2 rounded-full'
									} sliderHome__transition ${isActive ? '' : 'sliderHome__slideButton'}`}
									style={{
										backgroundColor: isActive ? mainColor : secondColor,
										borderColor: isActive ? mainColor : secondColor,
									}}
								/>
							);
						})}
					</div>
				)}
			</div>
		</>
	);
}

SliderHome.defaultProps = {
	className: '',
	titleClassName: '',
	subtitleClassName: '',
	descriptionClassName: '',
	buttonClassName: '',
};

export default SliderHome;
