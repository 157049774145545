import { formatDate } from '../../../../../../shared/services/dates-managment.service';
import { BookingLog } from '../../../../../../shared/interfaces/flights/booking-logs';
import { TableData, RowData } from '../../../../../../shared/interfaces/table';
import { Accordion } from '../../../../../ui-elements/accordion/accordion';
import { DateFormat } from '../../../../../../shared/enums/date.enum';
import Table from '../../../../../ui-elements/table/table';
import styles from './flight-logs.module.scss';
import { useMemo } from 'react';

export interface FlightLogsProps {
	dateHead: string;
	timeHead: string;
	userHead: string;
	descriptionHead: string;
	logs: BookingLog[];
	title: string;
	className?: string;
}

export const FlightLogs = (props: FlightLogsProps): JSX.Element => {
	const dateLabel: string = 'date';
	const timeLabel: string = 'time';
	const userLabel: string = 'user';
	const descriptionLabel: string = 'description';
	const logContent = useMemo<JSX.Element>(() => {
		const data: TableData = {
			head: [
				{ value: props.dateHead, label: dateLabel },
				{ value: props.timeHead, label: timeLabel },
				{ value: props.userHead, label: userLabel },
				{ value: props.descriptionHead, label: descriptionLabel },
			],
			rows: props.logs.map((log: BookingLog): RowData[] => {
				return [
					{
						value: formatDate(log.createdAt, DateFormat.smallDate),
						label: dateLabel,
					},
					{
						value: formatDate(log.createdAt, DateFormat.timeDate),
						label: timeLabel,
					},
					{ value: log.userName, label: userLabel },
					{ value: log.description, label: descriptionLabel },
				];
			}),
		};

		return (
			<div className='w-full px-4 pb-6'>
				<Table data={data} className={'w-full CustomerDataMock table-auto mx-auto flightLogs__table'} />
			</div>
		);
	}, [props.logs, props.dateHead, props.timeHead, props.userHead, props.descriptionHead, dateLabel, timeLabel, userLabel, descriptionLabel]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<Accordion
				title={props.title.toLocaleUpperCase()}
				content={logContent}
				titleCustomClass={'text-lg leading-6 font-medium text-color-primary'}
				className={props.className}
			/>
		</>
	);
};

FlightLogs.defaultProps = {
	className: '',
};

export default FlightLogs;
