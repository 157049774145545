import { AvailabilityFiltersUtil } from '../../../flights/shared/utils/availability-filters.util';
import { Button, Checkbox, RadioInput, RangeInput, Select } from '../../../../lib/ui-elements';
import { FiltersData } from '../../shared/interfaces/availability-filters.interface';
import SkeletonAvailabilitySkeleton from './skeleton/availability-filters-skeleton';
import { FilterElement, RangeInputTemplate } from '../../../../shared/enums';
import { validateTypeObject } from '../../../../shared/utils';
import styles from './availability-filters.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export interface AvailabilityFiltersProps {
	filterTitle?: string;
	colorPrimary?: string;
	isMobileOpen?: boolean;
	currentCurrency?: string;
	filterData: FiltersData[];
	t: (value: string) => string;
	outPut: (data: unknown) => void;
}

export function AvailabilityFilters({
	t,
	outPut,
	filterData,
	filterTitle,
	colorPrimary,
	isMobileOpen,
	currentCurrency,
}: AvailabilityFiltersProps): JSX.Element {
	const { register, watch, setValue, handleSubmit, reset } = useForm();
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);
	const [currentfilters, setCurrentfilters] = useState<{}>({});
	const [filterItems, setFilterItems] = useState<FiltersData[]>([]);
	const [disableForm, setDisableForm] = useState<boolean>(false);
	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			const currentWidth: number = window.screen.width;
			const laptopScreen: number = 1024;
			if (currentWidth >= laptopScreen && name) {
				const getValue = validateTypeObject(value);
				setCurrentfilters(getValue);

				outPut(getValue);

				setDisableForm(true);
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, outPut]);

	useEffect(() => {
		setMobileOpen(isMobileOpen as boolean);
	}, [isMobileOpen]);

	useEffect(() => {
		reset();

		filterData.forEach(filter => validateSelectedValue(filter));

		setFilterItems(filterData || []);

		const filtersUrl = AvailabilityFiltersUtil.currentFiltersFromUrl(filterData || []);
		if (Object.keys(filtersUrl).length) {
			setCurrentfilters(filtersUrl);
		}

		setDisableForm(false);
	}, [filterData]);

	const onSubmit = (value: {}): void => {
		const getValue = validateTypeObject(value);
		setCurrentfilters(getValue);

		outPut(getValue);

		setMobileOpen(false);
	};

	const clearForm = (): void => {
		Object.keys(currentfilters).forEach(key => {
			currentfilters[key] = '';
		});

		outPut(currentfilters);

		setCurrentfilters(currentfilters);

		setFilterItems([]);

		reset();

		setTimeout(() => {
			filterData.forEach(filter => {
				delete filter.maxDefaultValue;

				delete filter.minDefaultValue;

				filter.options.forEach(option => (option.selected = false));
			});

			setFilterItems(filterData);
		}, 400);
	};

	const validateSelectedValue = (data: FiltersData): void => {
		data = AvailabilityFiltersUtil.validateSelectedValue(data);
	};

	const closeMobileWithoutData = (): void => {
		setMobileOpen(false);

		outPut(null);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`availability-filters hidden lg:block lg:bg-transparent ${mobileOpen ? 'bg-white availability-filters__open' : ''}`}>
				{!filterItems?.length ? (
					<SkeletonAvailabilitySkeleton />
				) : (
					<form className={'availability-filters__container px-[10px]'} onSubmit={handleSubmit(onSubmit)}>
						<div className={'availability-filters__item pb-[35px] border-b border-gray-400 flex justify-between items-center'}>
							<p className='availability-filters__title text-[24px] flex flex-col md:flex-row items-baseline justify-between w-full'>
								<span>{t(filterTitle || '')}</span>
								{!!Object.values(currentfilters).some(check => check) && (
									<span className='text-xs text-gray-500 cursor-pointer pt-3' onClick={clearForm}>
										{t('resetFilter')}
									</span>
								)}
							</p>

							<span onClick={() => closeMobileWithoutData()} className='closeIco text-gray-500 text-[20px] lg:hidden'></span>
						</div>

						{filterItems.map((data, index) => (
							<div key={index} className={'availability-filters__item py-[35px] border-b border-gray-400'}>
								<div className='flex justify-between pb-[19px]'>
									<p className='availability-filters__secondaryTitle'>{t(data.type || '')}</p>

									{data.subTitle && <p className='availability-filters__subtitle text-gray-500'>{t(data.subTitle)}</p>}
								</div>

								{data.elementType === FilterElement.SELECT && (
									<>
										{data?.options.forEach(option => (option.label = t(option.label)))}
										<Select
											key={`${data.type}_break_${index}`}
											defaultValue={data?.options?.find(option => !!option.selected)?.value || data?.options[0]?.value}
											options={data.options}
											setValue={setValue}
											register={register(`${data.type}`, { disabled: disableForm })}
											name={`${data.type}`}
											label={''}
											disabled={disableForm}
											className={'rounded-[10px] border-[1px] text-gray-500 border-gray-500'}
											labelClassName={''}
											selectClassName={''}
											optionsMarginTop='10px'
											optionsHeight={'245px'}
										/>
									</>
								)}

								{data.elementType === FilterElement.CHECKBOX && (
									<>
										{data.options.map((option, secondIndex) => (
											<div key={`${index}_${secondIndex}_${option.value}`} className='flex items-center justify-between'>
												<Checkbox
													register={register(`${data.type}[${secondIndex}][${option.value}]`, { disabled: disableForm })}
													primaryColor={colorPrimary || '#f3f3f3'}
													secondaryColor={'#000000'}
													label={t(option.label)}
													checkmarkContainerSize={20}
													className=''
													defaultChecked={option?.selected}
													logoUrl={option.logoUrl}
												/>
												{option.extraDescription && <p className='text-gray-500'>{option.extraDescription}</p>}
											</div>
										))}
									</>
								)}

								{data.elementType === FilterElement.BUTTON && (
									<>
										{data.options.map((option, secondIndex) => (
											<div key={`${index}_${secondIndex}_${option.value}`} className=''>
												<RadioInput
													id={`${index}_${secondIndex}_break_${option.value}`}
													value={option.value}
													register={register(`${data.type}`, { disabled: disableForm })}
													name={`${index}_${secondIndex}_break_${data.type}`}
													label={'cursor-pointer'}
													className={''}
													checked={!!option?.selected}
													inputClassName={'hidden radioInput__radioBackground rounded-lg rounded-[10px] '}
													content={
														<Button
															type='button'
															content={
																<>
																	<div className=''>
																		<p className={''}>
																			<span className={`icon${option.value} text-[25px]`}></span>
																			<span className='mx-2 availability-filter__label'>{t(option.label)}</span>
																			<span className='availability-filter__optionExtra'>{option.extraDescription}</span>
																		</p>
																	</div>
																</>
															}
															customClass={
																'w-full pointer-events-none rounded-lg text-[15px] rounded-[10px] border-[1px] !pl-0 !pr-[5px] border-gray-500 text-gray-500 mb-0'
															}
														/>
													}
												/>
											</div>
										))}
									</>
								)}

								{data.elementType === FilterElement.RANGE && (
									<>
										<RangeInput
											key={`range_${index}_break_${data.type}`}
											name={`range_${index}_break_${data.type}`}
											label={''}
											min={data.minValue}
											max={data.maxValue}
											minDefaultValue={Number(data.minDefaultValue) || Number(data.minValue)}
											maxDefaultValue={Number(data.maxDefaultValue) || Number(data.maxValue)}
											step='1'
											template={RangeInputTemplate.InputSquares}
											currency={currentCurrency}
											minLabel={t('rateMinRangeText')}
											maxLabel={t('rateMaxRangeText')}
											labelClassName=''
											colorPrimary={colorPrimary}
											containerClassName={''}
											minRegister={register(`min${data.type}`)}
											maxRegister={register(`max${data.type}`)}
										/>
									</>
								)}
							</div>
						))}

						<div className='availability__actions lg:hidden'>
							<Button
								type='submit'
								text={t('actionFilter')}
								styles={{
									backgroundColor: colorPrimary,
								}}
								customClass={'w-full text-white rounded-lg rounded-[10px] border-[1px] border-gray-500 mb-[10px]'}
							/>

							<Button
								onClick={() => closeMobileWithoutData()}
								type='button'
								text={t('cancel')}
								customClass={'w-full rounded-lg rounded-[10px] border-[1px] text-gray-500 border-gray-500 mb-[10px]'}
							/>
						</div>
					</form>
				)}
			</div>
		</>
	);
}

export default AvailabilityFilters;
