import styles from './bio-security.module.scss';

export interface BioSecurityProps {
	title: string;
	subtitle: string;
	description?: string;
}

export function BioSecurity(props: BioSecurityProps): JSX.Element {
	const descriptionHtml: string = props.description || '';
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='bioSecurity bg-gray-100 flex justify-center'>
				<div className='flex flex-col items-center p-16 px-6 md:p-16'>
					<p className={'border-b-2 border-gray-500  text-gray-500 font-family-bold pb-4 bioSecurity__subtitle'}>{props.subtitle}</p>
					<h2 className='text-4xl text-gray-600 font-family-black mt-3.5 mb-3.5 text-center'>{props.title}</h2>
					<p className='text-center max-w-3xl text-gray-600 text-base font-family-regular' dangerouslySetInnerHTML={{ __html: descriptionHtml }}></p>

					<div className={'flex flex-col md:flex-row items-center justify-between mt-16 bioSecurity__container'}>
						<div className='w-full md:w-2/6 border-2 rounded-lg border-gray-600 mr-2 lg:mr-8 mb-5 h-64 pl-5 pr-12 pt-5'>
							<span className={'washHandsIco text-gray-600 mt-4 bioSecurity__icon'}></span>
							<p className='font-family-black text-sm lg:text-base text-gray-600 mb-4  '>Lavado de manos cada dos horas</p>
							<p className={'font-family-regular text-xs text-gray-600 bioSecurity__description'}>
								Lava tus manos cada dos horas con abundante agua y jabón, desinfectando toda la superficie de tus manos y muñecas.
							</p>
						</div>

						<div className='w-full md:w-2/6 border-2 rounded-lg border-gray-600 mr-2 lg:mr-8 mb-5 h-64 pl-5 pr-12 pt-5'>
							<span className={'maskIco text-gray-600 bioSecurity__icon'}></span>
							<p className='font-family-black text-sm lg:text-base  text-gray-600 mb-4 '>Uso de mascarilla obligatorio y a todo momento</p>
							<p className={'font-family-regular text-xs text-gray-600 bioSecurity__description'}>
								Es indispensable que utilices la mascarilla todo el tiempo durante tu viaje, y que además mantegas una distancia de 2 metros.
							</p>
						</div>

						<div className='w-full md:w-2/6 border-2 rounded-lg border-gray-600 mb-5 h-64 pl-5 pr-12 pt-5'>
							<span className={'thermometerIco text-gray-600 bioSecurity__icon'}></span>
							<p className='font-family-black text-sm lg:text-base  text-gray-600 mb-4 '>Prueba de COVID-19 negativa antes del viaje</p>
							<p className={'font-family-regular text-xs text-gray-600 bioSecurity__description'}>
								Para ingresar a Miami, es necesario que presentes una prueba de COVID negativa de máximo tres días de antelación.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BioSecurity;
