export function HistoricalDetailSkeleton(): JSX.Element {
	return (
		<section className='historical-detail__content animate-pulse p-6 bg-white rounded-lg flex-col flex-wrap'>
			<div className='md:flex w-full mb-6 justify-between'>
				<div className='flex mb-2'>
					<div className='bg-gray-200 w-[20px] h-[21px] rounded-md mr-2'></div>
					<div className='bg-gray-200 w-[200px] h-[21px] rounded-md'></div>
				</div>
				<div className='flex'>
					<div className='bg-gray-200 w-[200px] h-[21px] rounded-md'></div>
				</div>
			</div>
			<div className='flex mb-2'>
				<div className='bg-gray-200 w-[210px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-2'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-8'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-2'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-2'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-2'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-8'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-2'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-8'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-2'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>
			<div className='flex mb-8'>
				<div className='bg-gray-200 w-[20px] h-[16px] rounded-md mr-2'></div>
				<div className='bg-gray-200 w-[200px] h-[16px] rounded-md'></div>
			</div>

			<div className='historical-detail__purchase mb-8'>
				<hr className='my-3' />
				<div className='flex mb-2 justify-between'>
					<div className='bg-gray-200 w-[100px] h-[18px] rounded-md'></div>
					<div className='bg-gray-200 w-[100px] h-[18px] rounded-md'></div>
				</div>
				<div className='flex mb-2 justify-between'>
					<div className='bg-gray-200 w-[100px] h-[16px] rounded-md'></div>
					<div className='bg-gray-200 w-[100px] h-[16px] rounded-md'></div>
				</div>
			</div>
		</section>
	);
}

export default HistoricalDetailSkeleton;
