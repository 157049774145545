import { GenericFilter, InputEventsHandler, OptionSelect, Params } from '../../../shared/interfaces';
import { Backdrop, Button, Checkbox, Input, RadioInput } from '../../ui-elements';
import { DateFormat, ElementTypes, FilterElement } from '../../../shared/enums';
import { SearchFieldsUtil } from '../../../shared/utils/search-fields.util';
import GenericFiltersSkeleton from './skeleton/generic-filters-skeleton';
import DatePicker from '../../shared-components/date-picker/date-picker';
import { formatDate } from '../../../shared/services/index';
import { validateTypeObject } from '../../../shared/utils';
import styles from './generic-filters.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

enum keyInputDates {
	startDate = 'startDate',
	endDate = 'endDate',
}

interface DateFormated {
	formatDateUrl: string;
	dateFormated: string;
}

export interface GenericFiltersProps {
	colorPrimary?: string;
	colorSecondary?: string;
	isMobileOpen?: boolean;
	filterData: GenericFilter[];
	keyFilterTypeTraduction?: string;
	t: (value: string) => string;
	change: (value: Params) => void;
	onMobileOpen?: (value: boolean) => void;
}

export function GenericFilters({
	t,
	colorPrimary,
	colorSecondary,
	isMobileOpen,
	onMobileOpen,
	keyFilterTypeTraduction = 'historicalFilterBy',
	filterData,
	change,
}: GenericFiltersProps): JSX.Element {
	const { register, watch, handleSubmit, setValue, reset, getValues } = useForm();
	const [showDates, setShowDates] = useState<boolean>(false);
	const [nameDate, setNameDate] = useState<string>();
	const [mobileOpen, setMobileOpen] = useState<boolean>(false);
	const [filters, setFilters] = useState<GenericFilter[]>([]);
	const [currentfilters, setCurrentfilters] = useState<{}>({});
	const initDates = {
		endDate: '',
		startDate: '',
	};

	useEffect(() => {
		setMobileOpen(isMobileOpen as boolean);

		if (isMobileOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}
	}, [isMobileOpen]);

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			const currentWidth: number = window.screen.width;
			const laptopScreen: number = 1024;
			const getValue = validateTypeObject(value);
			if (currentWidth >= laptopScreen && name && validateRangeDates(name as keyInputDates)) {
				change(getValue);
			}

			setCurrentfilters(getValue);
		});

		return () => subscription.unsubscribe();
	}, [watch, change]);

	const validateRangeDates = (keyDate: keyInputDates): boolean => {
		if (keyDate === keyInputDates.startDate || keyDate === keyInputDates.endDate) {
			const startDate: string = getValues(keyInputDates.startDate);
			const endDate: string = getValues(keyInputDates.endDate);
			if (startDate !== '' && endDate !== '') {
				return true;
			}

			return false;
		}

		return true;
	};

	const setUrlDates = (params: Params): void => {
		const startDate: Date | undefined = setUrlDateValid(keyInputDates.startDate, params);
		const endDate: Date | undefined = setUrlDateValid(keyInputDates.endDate, params);
		const baseDateFormated: DateFormated = {
			formatDateUrl: '',
			dateFormated: '',
		};

		const starDateFormated: DateFormated = startDate ? getDateFormated(startDate) : baseDateFormated;
		const endDateFormated: DateFormated = endDate ? getDateFormated(endDate) : baseDateFormated;
		if (starDateFormated.dateFormated === '' || endDateFormated.dateFormated === '') {
			return;
		}

		setValue(keyInputDates.startDate, starDateFormated.formatDateUrl);

		setValue(keyInputDates.endDate, endDateFormated.formatDateUrl);

		setDatesFormated({
			startDate: starDateFormated.dateFormated,
			endDate: endDateFormated.dateFormated,
		});
	};

	const setUrlDateValid = (keyDate: keyInputDates, params: Params): Date | undefined => {
		const dateUrl: string = params[keyDate];
		const currentDateUrl: string = getValues(keyDate);
		if (dateUrl) {
			const dateFormated: string = SearchFieldsUtil.convertDatesFromUrl(dateUrl);
			const date: Date = new Date(`${dateFormated}T00:00:00`);
			if (isNaN(date.getTime()) || currentDateUrl === dateUrl) return undefined;

			return date;
		}

		return undefined;
	};

	const setUrlFilters = (params: Params): void => {
		Object.keys(params).forEach((key: string) => {
			const value: string = params[key];
			filterData.forEach((data: GenericFilter) => {
				if (data.type === key) {
					data.options.forEach((option: OptionSelect) => {
						const splitKey: string = ',';
						value.split(splitKey).forEach((val: string) => {
							if (option.value === val) {
								option.selected = true;
							}
						});
					});
				}
			});
		});
	};

	const availabilityFiltersUrl = (params: Params): {} => {
		let filtersFromUrl = {};
		Object.keys(params).forEach(key => {
			const value: string = params[key];
			const valuekeyInputDates = key as keyInputDates;
			const datesValidation: boolean = valuekeyInputDates === keyInputDates.startDate || valuekeyInputDates === keyInputDates.endDate;
			const filter: boolean = filterData.some(filter => filter.type === key);
			if (filter || datesValidation) {
				filtersFromUrl = { ...filtersFromUrl, ...{ [key]: value } };
			}
		});

		return filtersFromUrl;
	};

	useEffect(() => {
		const params: Params = Object.fromEntries(new URLSearchParams(window.location.search));
		setUrlDates(params);

		setUrlFilters(params);

		setCurrentfilters(availabilityFiltersUrl(params));

		setFilters(filterData);
	}, [filterData]);

	const formatDateDisplay = (date: Date): string => {
		const dateFormated: string = date.toString();
		return `${formatDate(dateFormated, DateFormat.weekdaySingle)}, ${formatDate(dateFormated, DateFormat.dayMonth)}`;
	};

	const [datesFormated, setDatesFormated] = useState<{
		startDate: string;
		endDate: string;
	}>(initDates);

	const onSubmit = (value: Params): void => {
		onMobileOpen && onMobileOpen(false);

		change(validateTypeObject(value));
	};

	const openDate = (nameInputDate: string): void => {
		setNameDate(nameInputDate);

		setShowDates(true);
	};

	const clearForm = (): void => {
		Object.keys(currentfilters).forEach(key => {
			currentfilters[key] = '';
		});

		onSubmit(currentfilters);

		setCurrentfilters(currentfilters);

		setDatesFormated(initDates);

		setFilters([]);

		reset();

		const milliseconds: number = 400;
		setTimeout(() => {
			filters.forEach((filter: GenericFilter) => {
				filter.options.forEach(option => (option.selected = false));
			});

			setFilters(filterData);
		}, milliseconds);
	};

	const setDates = (keyDate: keyInputDates, date: Date): void => {
		const formatDateUrl: string = formatDate(date.toString(), DateFormat.url);
		const dateFormated: string = formatDateDisplay(date);
		if (keyDate === keyInputDates.startDate) {
			setValue(keyInputDates.startDate, formatDateUrl);

			setDatesFormated({
				...datesFormated,
				startDate: dateFormated,
			});
		} else {
			setValue(keyInputDates.endDate, formatDateUrl);

			setDatesFormated({
				...datesFormated,
				endDate: dateFormated,
			});
		}
	};

	const getDateFormated = (date: Date): DateFormated => {
		const formatDateUrl: string = formatDate(date.toString(), DateFormat.url);
		const dateFormated: string = formatDateDisplay(date);
		return {
			formatDateUrl,
			dateFormated,
		};
	};

	const getDefaultData = (): Date => {
		const dateFormated: string = SearchFieldsUtil.convertDatesFromUrl(getValues(nameDate as keyInputDates));
		const date: Date = new Date(`${dateFormated}T00:00:00`);
		if (isNaN(date.getTime())) {
			return new Date();
		} else {
			return date;
		}
	};

	const changeHandler = (event: InputEventsHandler): void => {
		setShowDates(false);

		const date: Date = event.value as Date;
		setDates(nameDate as keyInputDates, date);
	};

	const ShowDateElement = (): JSX.Element => {
		return (
			<>
				{showDates && (
					<>
						<Backdrop
							show={showDates}
							onClick={() => setShowDates(false)}
							zIndex={1}
							backgroundColor={'transparent'}
							opacity={1}
							portalBackdrop={false}
						/>
						<div className='generic-filters__datePicker absolute shadow-lg w-auto bg-white z-10 px-[40px] py-[15px]  overflow-y-auto overflow-x-hidden'>
							<DatePicker
								name={nameDate}
								defaultData={getDefaultData()}
								className={'flex-col w-full'}
								dateRangeClassName='dateRanger__picker text-black mt-0 w-full'
								colors={{
									main: colorSecondary,
									second: colorPrimary,
									third: '#7A8083',
								}}
								onChange={changeHandler}
							/>
						</div>
					</>
				)}
			</>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<form
				className={`generic-filters flex flex-col my-4 px-6 py-8 text-black rounded-lg bg-white shadow-lg justify-between flex-wrap ${
					mobileOpen ? 'bg-white generic-filters__open' : ''
				}`}
				onSubmit={handleSubmit(onSubmit)}
			>
				{mobileOpen && (
					<span
						onClick={() => {
							onMobileOpen && onMobileOpen(false);

							setMobileOpen(false);
						}}
						className='closeIco text-gray-500 text-[20px] md:hidden'
					></span>
				)}

				{!filters?.length ? (
					<GenericFiltersSkeleton />
				) : (
					<>
						<div className='generic-filters__date my-4'>
							<h4 className='generic-filters__dateTitle mb-2 flex items-center justify-between'>
								<span>{t('byDates')}:</span>
								{!!Object.values(currentfilters).some(check => check) && (
									<span className='text-xs text-gray-500 cursor-pointer' onClick={clearForm}>
										{t('resetFilter')}
									</span>
								)}
							</h4>
							<div className='flex rounded-lg px-4 border border-gray-500'>
								<div className='w-full pb-1'>
									<p className='text-[10px] text-gray-500'>{t('dateFrom')}</p>
									<p onClick={() => openDate(keyInputDates.startDate)} className='generic-filters__dateOpen text-[12px] h-[12px] text-gray-500'>
										{datesFormated?.startDate}
									</p>
									<Input
										className='h-0 w-0 overflow-hidden opacity-0 absolute left-[10%]'
										register={register(keyInputDates.startDate)}
										type={ElementTypes.text}
									/>
									{<ShowDateElement />}
								</div>
								<div className='w-full pl-2 pb-1 border-l border-gray-500'>
									<p className='text-[10px] text-gray-500'> {t('dateTo')}</p>
									<p onClick={() => openDate(keyInputDates.endDate)} className='generic-filters__dateOpen text-[12px] h-[12px] text-gray-500'>
										{datesFormated?.endDate}
									</p>
									<Input
										className='h-0 w-0 overflow-hidden opacity-0 absolute left-[60%]'
										register={register(keyInputDates.endDate)}
										type={ElementTypes.text}
									/>
								</div>
							</div>
						</div>

						{filters.map((data: GenericFilter, index: number) => (
							<div key={index} className='generic-filters__type my-4'>
								<h4 className='generic-filters__title mb-2'>{t(keyFilterTypeTraduction + data.type)}:</h4>

								{data.elementType === FilterElement.CHECKBOX && (
									<>
										{data.options.map((option: OptionSelect, secondIndex: number) => (
											<div
												key={`${index}_${secondIndex}_${option.value}`}
												className='generic-filters__checkbox flex ml-1 mb-1 items-center justify-between'
											>
												<Checkbox
													register={register(`${data.type}[${secondIndex}][${option.value}]`)}
													primaryColor={colorPrimary}
													secondaryColor='#979797'
													labelClassName='generic-filters__checkboxTitle'
													label={t(option.label)}
													defaultChecked={option?.selected}
												/>
												{option.extraDescription && <p className='text-gray-500'>{option.extraDescription}</p>}
											</div>
										))}
									</>
								)}

								{data.elementType === FilterElement.RADIO && (
									<>
										{data.options.map((option: OptionSelect, secondIndex: number) => (
											<div
												key={`${index}_${secondIndex}_${option.value}`}
												className='generic-filters__radio flex ml-1 mb-1 items-center justify-between'
											>
												<RadioInput
													className='!py-0 !px-0'
													register={register(`${data.type}`)}
													labelClassName='generic-filters__radioTitle'
													label={t(option.label)}
													checked={option?.selected}
													value={option.value}
												/>
												{option.extraDescription && <p className='text-gray-500'>{option.extraDescription}</p>}
											</div>
										))}
									</>
								)}
							</div>
						))}

						<Button
							type='submit'
							customClass='generic-filters__button self-end mt-8 h-[40px] w-[140px] md:hidden rounded-xl px-6 text-white items-center'
							styles={{
								background: colorSecondary,
							}}
							content={<span className='generic-filters__buttonTitle text-[14px]'>{t('apply')}</span>}
						/>
					</>
				)}
			</form>
		</>
	);
}

export default GenericFilters;
