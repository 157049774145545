import { capitalize, validationNumberPhone, formatDate, addAlphaToColor, numberToLocaleWithcurrency } from '../../../../../../shared/services/index';
import { Passaggers, PassengerTickets } from '../../../../../../shared/interfaces/passagers';
import { PaymentStepResultEventInterface } from '../../../../../../shared/interfaces/index';
import { ProductStatus, TicketStatus } from '../../../../../../shared/enums/product.enum';
import PopUpDialog from '../../../../../shared-components/pop-up-dialog/pop-up-dialog';
import Accordion from '../../../../../ui-elements/accordion/accordion';
import { DateFormat } from '../../../../../../shared/enums/date.enum';
import { AlertStates } from '../../../../../../shared/enums/index';
import { TabData } from '../../../../../../shared/interfaces/tabs';
import Tabs from '../../../../../shared-components/tabs/tabs';
import Button from '../../../../../ui-elements/button/button';
import { useState, useMemo, useCallback } from 'react';
import styles from './flight-passengers.module.scss';

export interface FlightPassengersProps {
	idComponent: string;
	title: string;
	tabTitle: string;
	passaggers: Passaggers[];
	nameHead?: string;
	typeHead?: string;
	documentHead?: string;
	emailHead?: string;
	phoneHead?: string;
	genderHead?: string;
	birthDateHead?: string;
	frequentTravelHead?: string;
	detailsTitle?: string;
	totalHead?: string;
	passengertotalHead?: string;
	ticketHead?: string;
	statusHead?: string;
	carrierHead?: string;
	paymentMethodHead?: string;
	cancelTicketText?: string;
	alertTitle?: string;
	alertDescription?: string;
	alertOkButton?: string;
	alertCancelButton?: string;
	paymentMethod?: string;
	productStatus?: string;
	titlesGender: Record<string, string>;
	titlesTicketStatus: Record<string, string>;
	titlesPassengerType: Record<string, string>;
	className?: string;
	modalClassName?: string;
	cancelClick?: () => void;
	onOkCancelClick?: (data: PaymentStepResultEventInterface) => void;
}

export function FlightPassengers({
	idComponent,
	title,
	tabTitle,
	passaggers,
	nameHead,
	typeHead,
	documentHead,
	emailHead,
	phoneHead,
	genderHead,
	birthDateHead,
	frequentTravelHead,
	detailsTitle,
	totalHead,
	passengertotalHead,
	ticketHead,
	statusHead,
	carrierHead,
	paymentMethodHead,
	cancelTicketText,
	alertTitle,
	alertDescription,
	alertOkButton,
	alertCancelButton,
	paymentMethod,
	productStatus,
	titlesGender,
	titlesTicketStatus,
	titlesPassengerType,
	className,
	modalClassName,
	cancelClick,
	onOkCancelClick,
}: FlightPassengersProps): JSX.Element {
	const firstElement: number = 0;
	const [activeTab, setActiveTab] = useState<string>('');
	const [activePassenger, setActivePassenger] = useState<Passaggers | undefined>(undefined);
	const [showModal, setshowModal] = useState<boolean>(false);
	const passengersTabs = useMemo(
		() =>
			((tempData: Passaggers[], tempTitle: string): TabData[] => {
				const tabs: TabData[] = [];
				if (tempData && tempData.length) {
					const diffIndexCount: number = 1;
					tempData.forEach((passenger: Passaggers, index: number): void => {
						tabs.push({
							id: `${passenger?.reference}_${passenger?.document}_${index}`,
							label: `${tempTitle} ${index + diffIndexCount}`,
							disabled: false,
							className: '',
						});
					});

					setActiveTab(tabs.length ? tabs[firstElement].id : '');

					setActivePassenger(tempData.length ? tempData[firstElement] : undefined);
				}

				return [...tabs];
			})(passaggers, tabTitle),
		[passaggers, tabTitle],
	);

	const tabsHandler = useCallback(
		(tab: string) => {
			const passenger: Passaggers | undefined = passaggers.find(
				(passenger: Passaggers, index: number): boolean => `${passenger?.reference}_${passenger?.document}_${index}` === tab,
			);

			if (passenger) {
				setActiveTab(tab);

				setActivePassenger(passenger);
			} else {
				setActiveTab('');

				setActivePassenger(undefined);
			}
		},
		[passaggers],
	);

	const okCancelClickHandler = useCallback((): void => {
		setshowModal(false);

		if (onOkCancelClick) {
			onOkCancelClick({ id: idComponent });
		}
	}, [onOkCancelClick]);

	const cancelClickHandler = useCallback((): void => {
		setshowModal(true);

		if (cancelClick) {
			cancelClick();
		}
	}, [cancelClick]);

	let showTickets: boolean = false;
	switch (productStatus) {
		case ProductStatus.issued:
		case ProductStatus.canceled:
			showTickets = true;

			break;
		default:
			break;
	}
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<Accordion
				title={title.toLocaleUpperCase()}
				content={
					<div className='flex flex-col px-[42px]'>
						<Tabs
							activeTab={activeTab}
							tabs={passengersTabs}
							onClick={tabsHandler}
							className={'border-b-[1px] border-[#878787] flightPassengers__tabs'}
							tabClassName='pb-[10px] text-xs font-family-black'
						/>
						{showModal ? (
							<div
								className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${
									modalClassName || ''
								}`}
							>
								<PopUpDialog
									idComponent={`alert-${idComponent}`}
									title={alertTitle}
									description={alertDescription}
									okButtonText={alertOkButton}
									cancelButtonText={alertCancelButton}
									data-testid={'alert-testComponent'}
									state={AlertStates.error}
									showStateIcon={true}
									showCancelButton={true}
									className={'bg-white pt-14 pb-8 px-10'}
									onNextClick={() => okCancelClickHandler()}
									onPreviousClick={() => setshowModal(false)}
								/>
							</div>
						) : null}
						<div className='w-full pb-[20px]'>
							{activeTab && activePassenger && (
								<div key={activeTab} className='w-full pl-[23px] pt-[15px]'>
									<div className='overflow-hidden sm:rounded-lg'>
										<div>
											<dl>
												<div className='flex flex-row'>
													<dt className='text-xs font-family-bold text-[#393939]'>{`${nameHead ?? ''}:`}</dt>
													<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>
														{capitalize(activePassenger.firstName)} {capitalize(activePassenger.lastName)}
													</dd>
												</div>
												<div className='flex flex-row mt-[5px]'>
													<dt className='text-xs font-family-bold text-[#213261]'>{`${typeHead ?? ''}:`}</dt>
													<dd className='ml-[5px] text-xs font-family-regular text-[#213261]'>
														{titlesPassengerType[activePassenger.type] ? titlesPassengerType[activePassenger.type] : activePassenger.type}
													</dd>
												</div>
												<div className='flex flex-row mt-[5px]'>
													<dt className='text-xs font-family-bold text-[#393939]'>{`${documentHead ?? ''}:`}</dt>
													<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>{activePassenger.document}</dd>
												</div>
												<div className='flex flex-row mt-[5px]'>
													<dt className='text-xs font-family-bold text-[#393939]'>{`${emailHead ?? ''}:`}</dt>
													<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>{activePassenger.email}</dd>
												</div>
												<div className='flex flex-row mt-[5px]'>
													<dt className='text-xs font-family-bold text-[#393939]'>{`${phoneHead ?? ''}:`}</dt>
													<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>
														{validationNumberPhone(activePassenger.phoneNumber)}
													</dd>
												</div>
												<div className='flex flex-row justify-between mt-[5px]'>
													<div className='flex flex-col'>
														<div className='flex flex-row'>
															<dt className='text-xs font-family-bold text-[#393939]'>{`${genderHead ?? ''}:`}</dt>
															<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>
																{titlesGender[activePassenger.gender] ? titlesGender[activePassenger.gender] : activePassenger.gender}
															</dd>
														</div>
														<div className='flex flex-row mt-[5px]'>
															<dt className='text-xs font-family-bold text-[#393939]'>{`${birthDateHead ?? ''}:`}</dt>
															<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>
																{activePassenger.birthDate ? formatDate(activePassenger.birthDate, DateFormat.birthdayDate) : null}
															</dd>
														</div>
														<div className='flex flex-row mt-[5px]'>
															<dt className='text-xs font-family-bold text-[#393939]'>{`${frequentTravelHead ?? ''}:`}</dt>
															<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'></dd>
														</div>
													</div>
													<div className='flex flex-col items-end'>
														<span className='text-xs font-family-bold text-[#108FCF]'>{detailsTitle}</span>
														<div className='flex flex-row mt-[5px]'>
															<dt className='text-xs font-family-bold text-[#393939]'>{`${totalHead ?? ''}:`}</dt>
															<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>
																{activePassenger?.fares && !!activePassenger.fares.length
																	? `${activePassenger.fares[firstElement].currency.toUpperCase()} ${numberToLocaleWithcurrency(
																		activePassenger.fares[firstElement].totalAmount || 0,
																		activePassenger.fares[firstElement].currency,
																	  )}`
																	: 0}
															</dd>
														</div>
														<div className='flex flex-row mt-[5px]'>
															<dt className='text-xs font-family-bold text-[#393939]'>{`${passengertotalHead ?? ''}:`}</dt>
															<dd className='ml-[5px] text-xs font-family-regular text-[#393939]'>
																{activePassenger?.fares && !!activePassenger.fares.length
																	? `${activePassenger.fares[firstElement].currency.toUpperCase()} ${numberToLocaleWithcurrency(
																		activePassenger.fares[firstElement].base || 0,
																		activePassenger.fares[firstElement].currency,
																	  )}`
																	: 0}
															</dd>
														</div>
													</div>
												</div>
											</dl>
										</div>
									</div>
									{showTickets && activePassenger.tickets && !!activePassenger.tickets.length && (
										<div
											className='w-full rounded-[5px] py-[15px] pl-[35px] pr-[11px] mt-[20px]'
											style={{
												backgroundColor: addAlphaToColor('#878787', 0.15),
											}}
										>
											{activePassenger.tickets.map((ticket: PassengerTickets, index: number) => {
												return (
													<div
														className={`w-full flex flex-row justify-between items-center ${index ? 'mt-[10px]' : ''}`}
														key={`${activeTab}_${index}`}
													>
														<dl className='flex flex-row'>
															<div className='flex flex-col'>
																<dt className='text-[10px] font-family-bold text-[#393939]'>{ticketHead}</dt>
																<dd className='text-xs font-family-regular text-[#393939]'>{ticket.number}</dd>
															</div>
															<div className='flex flex-col pl-[20px]'>
																<dt className='text-[10px] font-family-bold text-[#393939]'>{statusHead}</dt>
																<dd className='text-xs font-family-regular text-[#393939]'>
																	{titlesTicketStatus[ticket.status] ? titlesTicketStatus[ticket.status] : ticket.status}
																</dd>
															</div>
															<div className='flex flex-col pl-[20px]'>
																<dt className='text-[10px] font-family-bold text-[#393939]'>{carrierHead}</dt>
																<dd className='text-xs font-family-regular text-[#393939]'>{ticket.validatingCarrier.code}</dd>
															</div>
															<div className='flex flex-col pl-[20px]'>
																<dt className='text-[10px] font-family-bold text-[#393939]'>{paymentMethodHead}</dt>
																<dd className='text-xs font-family-regular text-[#393939]'>{paymentMethod}</dd>
															</div>
														</dl>
														{ticket.status !== TicketStatus.canceled && (
															<Button
																onClick={cancelClickHandler}
																data-testid={`cancel-${idComponent}`}
																content={<span>{cancelTicketText}</span>}
																customClass={
																	'rounded-[5px] py-[10px] px-[20px] font-family-bold text-xs flex flex-row items-center justify-center bg-[#D2413E] text-white'
																}
															/>
														)}
													</div>
												);
											})}
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				}
				titleCustomClass={'text-lg leading-6 font-medium text-gray-900 text-color-primary'}
				className={className}
			/>
		</>
	);
}

export default FlightPassengers;
