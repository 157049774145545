export function HistoricalCardSkeleton(): JSX.Element {
	return (
		<section className='historical-card md:flex my-4 flex-wrap px-6 py-8 text-black rounded-lg bg-white shadow-lg justify-between animate-pulse'>
			<div className='historical-card__content1 mb-1 mx-2'>
				<div className='bg-gray-200 w-[120px] h-[14px] mb-2 rounded-md'></div>
				<div className='bg-gray-200 w-[160px] h-[14px] rounded-md'></div>
			</div>
			<div className='historical-card__content2 mx-2'>
				<div className='bg-gray-200 w-[160px] h-[14px] mb-2 rounded-md'></div>
				<div className='bg-gray-200 w-[150px] h-[14px] rounded-md'></div>
			</div>

			<div className='historical-card__split my-2 border bg-gray-200' />

			<div className='historical-card__content3  mx-2'>
				<div className='bg-gray-200 w-[120px] h-[14px] mb-1 rounded-md'></div>
				<div className='bg-gray-200 w-[120px] h-[14px] mb-1 rounded-md'></div>
				<div className='bg-gray-200 w-[100px] h-[14px] rounded-md'></div>
			</div>
			<div className='bg-gray-200 w-[140px] my-2 h-[30px] rounded-xl'></div>
		</section>
	);
}

export default HistoricalCardSkeleton;
