import styles from './spinner.module.scss';
export interface SpinnerProps {
	className?: string;
	style?: any;
}

export const Spinner = ({ className, style }: SpinnerProps): JSX.Element => {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<span className={`spinner-lib spinner ${className || ''}`} style={{ ...style }} />
		</>
	);
};

Spinner.defaultProps = {
	className: '',
};

export default Spinner;
