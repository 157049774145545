import CountrySelect from '../country-select/country-select';
import { Image } from '../../ui-elements/image/image';
import styles from './ares-header.module.scss';
import { useState } from 'react';

export interface AresHeaderProps {
	logoUrl: string;
	navItems: { name: string; url: string; active: boolean }[];
	countryFlagUrl: string;
	countryList: Record<string, string>;
	children?: JSX.Element;
	selectedCountry: string;
	showCountryList: boolean;
	countryListUrls: Record<string, string>;
	setSelectedCountry: (countryCode: string) => void;
	setShowCountryList: (value: boolean) => void;
	traductions: (label: string) => string;
}

export function AresHeader({
	logoUrl,
	countryFlagUrl,
	countryList,
	navItems,
	children,
	selectedCountry,
	showCountryList,
	countryListUrls,
	setSelectedCountry,
	setShowCountryList,
	traductions,
}: Readonly<AresHeaderProps>): JSX.Element {
	const [menuOpen, setMenuOpen] = useState(false);
	const onHeaderIconClick = (): void => {
		window.location.href = '/';
	};

	const toggleMenu = (): void => {
		setMenuOpen(!menuOpen);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`w-full ${menuOpen ? 'h-[80px] p-[10px]' : 'ares-header__container'} text-[#6D6E71]`}>
				<div className='ares-header grid grid-cols-12 items-center'>
					<button className='ares-header__logo col-span-3 md:col-span-3' onClick={onHeaderIconClick}>
						<Image url={logoUrl} className='w-full max-w-[150px] md:max-w-none header-ares__img--mobile' />
					</button>
					<div className='ares-header__nav hidden md:col-span-6 md:flex justify-center items-center'>
						<ul className='flex flex-row gap-[25px]'>
							{navItems.map(item => (
								<li key={item.name}>
									<a
										className={`font-medium hover:text-[black] hover:border-b-2 hover:border-[#E4002B]${
											item.active ? ' text-black border-b-2 border-[#E4002B]' : ''
										}`}
										href={item.url}
									>
										{item.name}
									</a>
								</li>
							))}
						</ul>
					</div>
					<div className='ares-header__redirect md:col-span-3 md:flex justify-center items-center gap-[31px]'>
						{children}
						<div className='ares-header__country-nav ares-header__country-select  gap-[4px]'>
							<CountrySelect
								countryFlagUrl={countryFlagUrl}
								countryList={countryList}
								selectedCountry={selectedCountry}
								showCountryList={showCountryList}
								countryListUrls={countryListUrls}
								setSelectedCountry={setSelectedCountry}
								setShowCountryList={setShowCountryList}
							/>
						</div>
					</div>

					<button
						className='block justify-self-end focus:outline-none header-ares__menu--hamburger'
						onClick={toggleMenu}
						aria-label={menuOpen ? 'Close Menu' : 'Open Menu'}
						data-testid='menu-toggle-button'
					>
						{menuOpen ? (
							<svg
								className='w-8 h-8'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
								style={{ color: '#000000' }}
							>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
							</svg>
						) : (
							<svg
								className='w-8 h-8'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'
								xmlns='http://www.w3.org/2000/svg'
								style={{ color: '#000000' }}
							>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M4 6h16M4 12h16m-7 6h7' />
							</svg>
						)}
					</button>
				</div>

				{menuOpen && (
					<div className={'header-ares__container-menu absolute right-0 w-full bg-white'}>
						<div className='flex flex-col items-end pr-[40px] header-ares__menu--mobile w-full'>
							<ul className='flex flex-col gap-[15px]'>
								{navItems.map(item => (
									<li key={item.name}>
										<a
											className={`font-medium hover:text-[black] hover:border-b-2 hover:border-[#E4002B] text-[#6D6E71]${
												item.active ? ' text-black border-b-2 border-[#E4002B]' : ''
											}`}
											href={item.url}
										>
											{item.name}
										</a>
									</li>
								))}
							</ul>
							<hr className='my-[15px]' style={{ width: '130px' }} />
							<div className='ares-header__country-nav--mobile flex flex-col items-end gap-[15px]'>
								<CountrySelect
									countryFlagUrl={countryFlagUrl}
									countryList={countryList}
									selectedCountry={selectedCountry}
									showCountryList={showCountryList}
									countryListUrls={countryListUrls}
									setSelectedCountry={setSelectedCountry}
									setShowCountryList={setShowCountryList}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className='container'>
				<div className='col-sm-4 header-ares__consulting-points--text'>{traductions('checkPoints') || 'checkPoints'}</div>
				<div className='col-sm-8'>{children}</div>
			</div>
		</>
	);
}

export default AresHeader;
