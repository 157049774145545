import { CreateUserGuestResponse } from '../interfaces/user-guest.interface';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { CardInfoSubmit } from '../interfaces/card-info.interface';
import { endPoints } from '../end-points/end-points';
import { signature } from '../utils/signature.util';
import { HttpCall } from './http-call.service';

export async function createUserGuest(data: CardInfoSubmit, token: string, publicKey: string): Promise<CreateUserGuestResponse | null> {
	const httpCall: HttpCall = new HttpCall();
	const url: string = `${process.env.REACT_APP_AUTH_API || ''}/${endPoints.userGuest}`;
	const headersSignature: string = signature(JSON.stringify(data), token, publicKey);
	const headers: { Authorization: string; 'content-type-custom': string } = {
		Authorization: `Bearer ${token}`,
		'content-type-custom': headersSignature,
	};

	try {
		const guestInfo: ResponseHttp<CreateUserGuestResponse | null> = await httpCall.post(url, data, headers);
		if (guestInfo && guestInfo.response != null) {
			return guestInfo.response;
		} else {
			return guestInfo.error.response?.data?.statusCode;
		}
	} catch (error) {
		console.error(error);

		return null;
	}
}
