import MultiLanguageCurrencySelect from '../multi-language-currency-select/multi-language-currency-select';
import { InputEventsHandler, OptionSelect } from 'shared/interfaces';
import { Button, IcomoonIcon } from '../../../../ui-elements';

export interface MultiLanguageCurrencySelectorProps {
	colorPrimary?: string;
	colorSecondary?: string;
	isShowCurrency?: boolean;
	isShowLanguage?: boolean;
	defaultLanguage?: string;
	defaultCurrency?: string;
	languages: OptionSelect[];
	currencies: OptionSelect[];
	t: (value: string) => string;
	close?: () => void;
	onSave?: (language: string, currency: string) => void;
}

export function MultiLanguageCurrencySelector({
	t,
	colorPrimary,
	colorSecondary,
	isShowCurrency,
	isShowLanguage,
	defaultLanguage,
	defaultCurrency,
	languages,
	currencies,
	close,
	onSave,
}: Readonly<MultiLanguageCurrencySelectorProps>): JSX.Element {
	const labelLanguage: string = 'language';
	const labelCurrency: string = 'currency';
	let currency: string | undefined = defaultCurrency;
	let language: string | undefined = defaultLanguage;
	const onChangeLanguage = (e: InputEventsHandler): void => {
		language = e.value as string;
	};

	const onChangeCurrency = (e: InputEventsHandler): void => {
		currency = e.value as string;
	};

	const onSaveConfirm = (): void => {
		onSave && onSave(language ?? '', currency ?? '');
	};

	const getKeyTranslateTitle = (): string => {
		if (isShowLanguage && isShowCurrency) {
			return 'languageAndCurrency';
		} else if (!isShowLanguage && isShowCurrency) {
			return labelCurrency;
		} else {
			return labelLanguage;
		}
	};

	const getKeyTranslateDescription = (): string => {
		if (isShowCurrency) {
			return 'languageAndCurrencyDescription';
		} else {
			return 'languageDescription';
		}
	};

	return (
		<section className='multi-language-currency-selector'>
			<div className='multi-language-currency-selector__information mt-2'>
				<IcomoonIcon className='multi-language-currency-selector__iconClose' icon='closeIco' color={colorPrimary} onClick={() => close && close()} />
				<h2 className='multi-language-currency-selector__informationTitle mt-2'>{t(getKeyTranslateTitle())}</h2>
				<p className='multi-language-currency-selector__informationMessage text-sm text-gray-500 my-2'>{t(getKeyTranslateDescription())}</p>
			</div>
			{isShowLanguage && (
				<div className='multi-language-currency-selector__language'>
					<MultiLanguageCurrencySelect
						t={t}
						colorSecondary={colorSecondary}
						defaultValue={defaultLanguage}
						label={labelLanguage}
						options={languages}
						onChange={onChangeLanguage}
					/>
				</div>
			)}
			{isShowCurrency && (
				<div className='multi-language-currency-selector__currency'>
					<MultiLanguageCurrencySelect
						t={t}
						colorSecondary={colorSecondary}
						defaultValue={defaultCurrency}
						label={labelCurrency}
						options={currencies}
						onChange={onChangeCurrency}
					/>
				</div>
			)}
			<div className='multi-language-currency-selector__action'>
				<Button
					customClass='multi-language-currency-selector__save h-[36px] w-full rounded-lg px-6 my-2 text-white items-center'
					onClick={() => onSaveConfirm()}
					styles={{
						background: colorSecondary,
					}}
					content={<span className='multi-language-currency-selector__save text-[14px]'>{t('save')}</span>}
				/>

				<Button
					customClass='multi-language-currency-selector__close h-[36px] w-full rounded-lg px-6 items-center bg-white'
					onClick={() => close && close()}
					styles={{
						color: colorSecondary,
					}}
					content={<span className='multi-language-currency-selector__closeTitle text-[14px]'>{t('cancel')}</span>}
				/>
			</div>
		</section>
	);
}

export default MultiLanguageCurrencySelector;
