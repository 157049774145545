import { useState, useEffect, useCallback, useRef } from 'react';
import { SliderType } from '../enums/slider.enum';
import { UseSliderInterface } from '../interfaces/generics/slider.interface';

const useSlider = (
	maxSlidePosition: number,
	slidesNumberShow: number,
	transitionStep: number = 1,
	transitionTime: number = 0,
	initPosition: number = 0,
	slidesMargin: number = 10,
	sliderSideMargin: number = 5,
	reverseMovement: boolean = false,
	type: SliderType = SliderType.normal,
): UseSliderInterface => {
	const diffPosition: number = 1;
	const firstPosition: number = 0;
	const twoSides: number = 2;
	const diffSlidesMargins: number = 1;
	const [position, setPosition] = useState<number>(initPosition);
	const [containerWidth, setContainerWidth] = useState<number>(0);
	const [slidesWidth, setSlidesWidth] = useState<number>(0);
	const sliderRef = useRef<HTMLDivElement>(null);
	const slidesContainerRef = useRef<HTMLDivElement>(null);
	const moveToPosition = useCallback(
		(newPosition: number): void => {
			if (newPosition > maxSlidePosition) {
				setPosition(firstPosition);
			} else if (newPosition < firstPosition) {
				setPosition(maxSlidePosition);
			} else {
				setPosition(newPosition);
			}
		},
		[maxSlidePosition],
	);

	const nextPosition = useCallback((): void => {
		setPosition((prevState: number): number => {
			if (prevState + transitionStep > maxSlidePosition) {
				if (type === SliderType.noContinuous) {
					return firstPosition;
				} else {
					return prevState + transitionStep - (maxSlidePosition + diffPosition);
				}
			} else {
				return prevState + transitionStep;
			}
		});
	}, [transitionStep, maxSlidePosition, type]);

	const previousPosition = useCallback((): void => {
		setPosition((prevState: number): number => {
			if (prevState - transitionStep < firstPosition) {
				if (type === SliderType.noContinuous) {
					return !(maxSlidePosition % transitionStep) ? maxSlidePosition : Math.floor(maxSlidePosition / transitionStep) * transitionStep;
				} else {
					return maxSlidePosition + diffPosition + (prevState - transitionStep);
				}
			} else {
				return prevState - transitionStep;
			}
		});
	}, [transitionStep, maxSlidePosition, type]);

	useEffect(() => {
		setPosition(initPosition);
	}, [transitionStep, initPosition]);

	useEffect(() => {
		if (sliderRef.current) {
			setContainerWidth(Number(sliderRef.current.offsetWidth));
		}
	}, [sliderRef?.current?.offsetWidth]);

	useEffect(() => {
		if (containerWidth && slidesNumberShow) {
			setSlidesWidth((containerWidth - slidesMargin * (slidesNumberShow - diffSlidesMargins) - sliderSideMargin * twoSides) / slidesNumberShow);
		}
	}, [containerWidth, slidesNumberShow, sliderSideMargin, slidesMargin]);

	useEffect(() => {
		if (slidesContainerRef.current) {
			slidesContainerRef.current.style.left = `${
				-Math.floor(position / transitionStep) *
				((slidesWidth + (slidesMargin * (slidesNumberShow - diffSlidesMargins) + sliderSideMargin * twoSides) / slidesNumberShow) * slidesNumberShow)
			}px`;
		}
	}, [slidesContainerRef, position, transitionStep, slidesWidth, slidesNumberShow, slidesMargin, sliderSideMargin]);

	useEffect(() => {
		if (transitionTime && maxSlidePosition > slidesNumberShow) {
			const interval = setInterval(() => {
				setPosition((prevState: number): number => {
					if (!reverseMovement && prevState + transitionStep > maxSlidePosition) {
						if (type === SliderType.noContinuous) {
							return firstPosition;
						} else {
							return prevState + transitionStep - (maxSlidePosition + diffPosition);
						}
					} else if (reverseMovement && prevState - transitionStep < firstPosition) {
						if (type === SliderType.noContinuous) {
							return !(maxSlidePosition % transitionStep) ? maxSlidePosition : Math.floor(maxSlidePosition / transitionStep) * transitionStep;
						} else {
							return maxSlidePosition + diffPosition + (prevState - transitionStep);
						}
					} else {
						return reverseMovement ? prevState - transitionStep : prevState + transitionStep;
					}
				});
			}, transitionTime);

			return () => clearInterval(interval);
		} else {
			return () => null;
		}
	}, [transitionStep, transitionTime, maxSlidePosition, reverseMovement, slidesNumberShow, type]);

	return {
		position,
		slidesWidth,
		sliderRef,
		slidesContainerRef,
		moveToPosition,
		nextPosition,
		previousPosition,
	};
};

export default useSlider;
