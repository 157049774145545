import PassengersField from '../../../shared-components/search/passengers-field/passengers-field';
import PromoCodeInput from '../../../../lib/shared-components/input-promocode/input-promocode';
import { DisneyMobileSearchForm } from './disney-mobile-search-form/disney-mobile-search-form';
import DatesField from '../../../shared-components/search/dates-field/dates-field';
import useDisneySearchForm from '../../shared/hooks/useDisneySearchForm';
import { WidgetStyleInterface } from '../../../../shared/interfaces';
import { InputType } from '../../../../shared/enums/inputs.enum';
import useWindowWidth from '../../../../shared/hooks/useWidth';
import Checkbox from '../../../ui-elements/checkbox/checkbox';
import Button from '../../../ui-elements/button/button';
import styles from './disney-search-form.module.scss';
import Input from '../../../ui-elements/input/input';
import { useEffect, useRef, useState } from 'react';
import {
	UseDisneySearchFormInterface,
	UseDisneySearchFormReturnInterface,
	DisneySearchFormSubmitInterface,
	DaysRestrictionDisneySearchFormInterface,
	PassengersFieldRestrictionsDisneySearchFormInterface,
} from '../../shared/interfaces/disney-search-form.interface';
import { DatesFieldTypes, SearchFieldsNames } from '../../../../shared/enums/search-fields.enum';

export interface DisneySearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: (label: string) => string;
	daysRestriction?: DaysRestrictionDisneySearchFormInterface;
	calendarRestriction?: number;
	passengersRestrictions: PassengersFieldRestrictionsDisneySearchFormInterface;
	disneyPassengersCombinedRestriction?: number;
	history: DisneySearchFormSubmitInterface | null;
	className?: string;
	useMinimunOnePassenger?: boolean;
	widgetStyles?: WidgetStyleInterface;
	emitSubmitSearch: (data: DisneySearchFormSubmitInterface) => void;
	emitShowMobileFields?: (show: boolean) => void;
	hasPromoCodeActive?: boolean;
}

export function DisneySearchForm({
	colorPrimary,
	colorSecondary,
	traductions,
	calendarRestriction,
	daysRestriction,
	passengersRestrictions,
	disneyPassengersCombinedRestriction,
	history,
	className,
	widgetStyles,
	useMinimunOnePassenger,
	emitSubmitSearch,
	emitShowMobileFields,
	hasPromoCodeActive,
}: DisneySearchFormProps): JSX.Element {
	const {
		datesName,
		datesHiddenName,
		passengersName,
		datesId,
		passengersId,
		withPromoCodeName,
		mobileFieldStep,
		minDate,
		defaultDates,
		days,
		promoCode,
		showMobileFieldsContainer,
		mobileFormHeight,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		showMobileFieldsSearchHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		daysHandler,
		promoCodeHandler,
		withPromoCodeHandler,
		onSubmit,
		passengersModalInputsHandler,
	}: UseDisneySearchFormReturnInterface = useDisneySearchForm({
		history,
		calendarRestriction,
		daysRestriction,
		maxPassengers: passengersRestrictions.maxPassengers,
		widgetStyles,
		traductions,
		emitSubmitSearch,
		emitShowMobileFields,
	} as unknown as UseDisneySearchFormInterface);

	const responsiveSize: number = 768;
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const windowWidth: number = useWindowWidth();
	const watchWithPromoCode = watch(withPromoCodeName);
	const formRef = useRef<HTMLFormElement | null>(null);
	const { isTertiaryTemplate } = widgetStyles || {};
	const daysInputElement: JSX.Element = (
		<Input
			value={String(days)}
			onChange={daysHandler}
			type={InputType.number}
			label={isTertiaryTemplate ? '' : `${traductions('howManyDays') || 'howManyDays'}`}
			placeholder={traductions('howManyDays') || 'howManyDays'}
			defaultValue={String(daysRestriction?.default) ?? '2'}
			required={true}
			name={SearchFieldsNames.days}
			min={String(daysRestriction?.min) || undefined}
			max={String(daysRestriction?.max) || undefined}
			step={'1'}
			className={`w-full bg-white rounded-[10px] border border-solid py-[10px] px-[25px] flex-col justify-center ${
				errors[SearchFieldsNames.days] ? 'border-2 border-red-500' : ''
			}
				${isTertiaryTemplate ? 'border-[#BEBEBE] rounded-none' : ''}
			`}
			labelClassName={'block text-xs'}
			inputClassName={`block w-full bg-white
				${isTertiaryTemplate ? 'py-0 text-[14px] !text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E] leading-6' : ''}	
			`}
			isTertiaryTemplate={isTertiaryTemplate}
			style={{
				color: colorSecondary,
			}}
		/>
	);

	const promoCodeInputElement: JSX.Element = (
		<PromoCodeInput
			promoCode={promoCode}
			promoCodeHandler={promoCodeHandler}
			colorSecondary={colorSecondary}
			traductions={traductions}
			isTertiaryTemplate={isTertiaryTemplate}
		/>
	);

	const handleSubmitButtonClick = (): void => {
		if (formRef.current) {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
	};

	useEffect(() => {
		setIsMobile(windowWidth < responsiveSize);
	}, [windowWidth]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`disneySearchForm w-full flex flex-col ${className || ''}`}>
				<div className='disneySearchForm__searchButton block md:hidden'>
					<DisneyMobileSearchForm
						datesId={datesId}
						datesName={datesName}
						passengersId={passengersId}
						passengersName={passengersName}
						customClass='flightsSearchForm__searchButton__secondary'
						isSecondaryTemplate={!!widgetStyles?.isSecondaryTemplate}
						colorSecondary={colorSecondary ?? ''}
						truncate={widgetStyles?.isEnabledTruncatedInputs}
						traductions={traductions}
						showMobileFieldsContainerHandler={showMobileFieldsSearchHandler}
						watch={watch}
						register={register}
						isTertiaryTemplate={!!widgetStyles?.isTertiaryTemplate}
					>
						<div className={'flex items-end'}>
							<Button
								type='submit'
								customClass={`disneySearchForm__searchBtn text-white w-full text-[21px] items-center
								${widgetStyles?.isTertiaryTemplate ? 'tertiary__shadow' : 'rounded-full'}`}
								content={<span className={'text-base !text-[14px]'}>{traductions('search') || 'search'}</span>}
								styles={{
									background: isValid ? colorSecondary ?? '#878787' : '#D1D1D1 ',
									color: isValid ? '' : '#9B9B9B',
									pointerEvents: !isValid ? 'none' : 'auto',
								}}
								disabled={!isValid}
								onClick={handleSubmitButtonClick}
							/>
						</div>
					</DisneyMobileSearchForm>
				</div>
				<form
					ref={formRef}
					onSubmit={onSubmit}
					className={`disneySearchForm__form w-full top-0 left-0 md:flex flex-col ${
						showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
					}
					${widgetStyles?.isTertiaryTemplate ? 'z-[2] md:z-auto' : ''}`}
					style={{
						height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
					}}
				>
					<div
						className='disneySearchForm__header block md:hidden pt-[33px] pb-[53px] px-[45px]'
						style={{
							backgroundColor: colorSecondary,
						}}
					>
						<button
							onClick={() => showMobileFieldsContainerHandler(false)}
							className='disneySearchForm__backBtn chevronLeftIco !p-0 text-white text-base font-bold'
							type='button'
						>
							<span className='pl-[10px]'>{traductions('getBack') || 'getBack'}</span>
						</button>
					</div>
					<div
						className={
							'disneySearchForm__body bg-white md:bg-transparent left-0 top-[-20px] md:top-0 relative md:h-auto px-0 pt-[20px] md:pt-0 rounded-t-[20px] md:rounded-t-none'
						}
					>
						<div className='disneySearchForm__content relative' style={{ paddingBottom: isTertiaryTemplate ? '15px' : '' }}>
							<div className={'disneySearchForm__firstRow flex flex-row flex-wrap'}>
								<div
									className={`disneySearchForm__dates flex items-stretch w-full md:w-3/5 md:pr-[15px]
									${isTertiaryTemplate ? 'flex-col' : 'flex-row'}
								`}
								>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('selectYourDate') || 'selectYourDate'}
										</span>
									)}
									<DatesField
										fieldId={datesId}
										title={traductions('selectYourDate') || 'selectYourDate'}
										placeHolder={traductions('selectYourDate') || 'selectYourDate'}
										name={datesName}
										hiddenName={datesHiddenName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										buttonText={widgetStyles?.isTertiaryTemplate ? traductions('apply') || 'apply' : traductions('ready') || 'ready'}
										type={DatesFieldTypes.oneDate}
										config={{
											date: true,
											time: false,
										}}
										minDate={minDate}
										defaultDates={defaultDates}
										useBackButton={true}
										iconClassName={isTertiaryTemplate ? 'iconCalendarDepartureTertiary' : 'searchCalendarIco'}
										isTertiaryTemplate={isTertiaryTemplate}
										required={true}
										colorPrimary={colorPrimary}
										colorSecondary={colorSecondary}
										className={`w-full md:flex-row items-stretch ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										watch={watch}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										onChange={datesHandler}
										onShowDates={() => undefined}
									/>
								</div>
								<div
									className={`disneySearchForm__days w-full md:w-2/5 relative hidden md:flex
									${isTertiaryTemplate ? 'flex-col' : ''}
								`}
								>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('howManyDays') || 'howManyDays'}
										</span>
									)}
									{daysInputElement}
								</div>
							</div>
							<div
								className={'disneySearchForm__lastRow flex flex-row flex-wrap md:mt-[15px] items-end'}
								style={{
									alignItems: isTertiaryTemplate ? 'flex-end' : '',
								}}
							>
								<div
									className={`disneySearchForm__passengers w-full relative flex flex-row flex-wrap md:pr-[15px] 
										${watchWithPromoCode ? 'md:w-2/5' : 'md:w-4/5'}
										${isTertiaryTemplate ? 'passengersTertiaryTemplate' : ''}
									`}
								>
									<PassengersField
										fieldId={passengersId}
										traductions={traductions}
										title={traductions('passengers') || 'passengers'}
										placeHolder={traductions('passengers') || 'passengers'}
										name={passengersName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										restrictions={passengersRestrictions}
										useBackButton={true}
										required={true}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === passengersId ? 'z-50' : 'z-0'} md:z-auto md:h-[65px]`}
										desktopModalClassName={isTertiaryTemplate ? 'shadow-xl md:flex-col top-[75px] md:w-full md:rounded-t-none px-[15px]' : ''}
										register={register}
										watch={watch}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										onModalInputsHandler={passengersModalInputsHandler}
										visibleTitle={true}
										iconClassName='searchAdultIco'
										backCallBack={backMobileFieldHandler}
										isTertiaryTemplate={isTertiaryTemplate}
										disabledInputs={{
											infants: true,
										}}
										passengersAmountsRestrictions={disneyPassengersCombinedRestriction}
										useMinimunOnePassenger={useMinimunOnePassenger}
										typeProduct={'disney'}
									>
										<div
											className={`disneySearchForm__days flex md:hidden
											${isTertiaryTemplate ? 'flex-col' : ''}
										`}
										>
											{isTertiaryTemplate && (
												<span className='flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
													{traductions('howManyDays') || 'howManyDays'}
												</span>
											)}
											{daysInputElement}
										</div>
										{hasPromoCodeActive && (
											<div
												className={`disneySearchForm__promoCode flex md:hidden mt-[20px] ${watchWithPromoCode ? '' : 'hidden'}
												${isTertiaryTemplate ? 'gap-2 promoCodeTertiaryTemplate flex-col' : ''} `}
											>
												{isTertiaryTemplate && (
													<span className='w-full text-[13px] text-[#4D4D4F] font-medium'>{traductions('promoCode') || 'promoCode'}</span>
												)}
												{promoCodeInputElement}
											</div>
										)}
									</PassengersField>
								</div>
								{hasPromoCodeActive && (
									<div
										className={`disneySearchForm__promoCode hidden w-full md:w-2/5 relative flex-row flex-wrap md:pr-[15px] 
											${watchWithPromoCode ? 'md:flex' : 'md:hidden'}
											${isTertiaryTemplate ? 'gap-2 promoCodeTertiaryTemplate' : ''}
										`}
									>
										{isTertiaryTemplate && (
											<span className='hidden md:flex w-full text-[13px] text-[#4D4D4F] font-medium'>{traductions('promoCode') || 'promoCode'}</span>
										)}
										{promoCodeInputElement}
									</div>
								)}
								<div
									className={`disneySearchForm__footer md:relative md:w-1/5 hidden md:flex
										${isTertiaryTemplate ? 'items-end' : 'md:h-[65px]'}
									`}
									style={{
										width: isTertiaryTemplate ? '20%' : '',
									}}
								>
									<Button
										type='submit'
										customClass={`${isTertiaryTemplate ? '' : 'disneySearchForm__searchBtn'} text-white w-full text-[21px] items-center
											${isTertiaryTemplate ? 'rounded-none tertiary__shadow' : 'rounded-lg'}
											${isTertiaryTemplate && !isValid ? 'tertiary__disabled' : ''}
										`}
										content={
											<>
												{isTertiaryTemplate ? null : <span className='searchIco text-[25px] font-extrabold' />}
												<span className={`${isTertiaryTemplate ? 'text-base !text-[14px]' : 'text-[21px] font-extrabold pl-[10px]'} `}>
													{traductions('search') || 'search'}
												</span>
											</>
										}
										styles={{
											background: colorSecondary || '#878787',
											padding: isTertiaryTemplate && '11px 5px',
										}}
										disabled={!isValid && widgetStyles?.isTertiaryTemplate}
									/>
								</div>
							</div>
							<div className={'block md:hidden w-full z-40 bg-white absolute left-0 top-[20px] h-screen'} />
						</div>
						<div
							className={`disneySearchForm__toolbar w-full absolute md:relative flex-row justify-start md:justify-end items-center mt-[10px] z-50 md:z-auto md:top-0 left-0 md:flex  ${
								mobileFieldStep === passengersId ? 'flex px-[40px] md:px-0' : 'hidden'
							}`}
						>
							{hasPromoCodeActive && (
								<div
									className={`disneySearchForm__withPromoCode  ${isTertiaryTemplate ? 'promoCodeTertiaryTemplate' : ''}
									`}
								>
									<Checkbox
										name={withPromoCodeName}
										id={withPromoCodeName}
										primaryColor={colorSecondary}
										secondaryColor={'#FFFFFF'}
										forcePrimaryColorBorder={true}
										checkmarkContainerSize={19}
										label={traductions('withPromoCode') || 'withPromoCode'}
										className=''
										labelClassName={isTertiaryTemplate ? 'text-[#4D4D4F] text-base' : 'text-base text-black md:text-white'}
										register={register(withPromoCodeName, {
											onChange: withPromoCodeHandler,
										})}
										getValues={getValues}
									/>
								</div>
							)}
						</div>
					</div>
				</form>
				<div
					className={`disneySearchForm__freeEntranceMessage text-sm text-left 
					${
		isTertiaryTemplate
			? 'text-[#4D4D4F] font-normal md:text-[16px]'
			: isMobile
				? 'font-semibold text-black'
				: 'md:text-[18px] text-white md:font-extrabold font-semibold'
		}
				`}
				>
					<p>{traductions('freeEntranceMessage') || 'freeEntranceMessage'}</p>
				</div>
			</div>
		</>
	);
}

export default DisneySearchForm;
