import { IcomoonIcon } from '../../ui-elements/icomoon-icon/icomoon-icon';
import { ToolTipArrowType } from '../../../shared/enums/tooltip.enum';
import { ToolTip } from '../../ui-elements/tool-tip/tool-tip';
import '../../../tailwind.css';

export interface PcoMessageProps {
	points: string;
	titleClassName?: string;
	tooltipTitleColor?: string;
	isCarResume?: boolean;
	isGridCard?: boolean;
	isMatrixCard?: boolean;
	isCoverageCard?: boolean;
	t: (item: string) => string;
}

export function PcoMessage({
	points,
	titleClassName,
	t,
	tooltipTitleColor,
	isCarResume = false,
	isGridCard = false,
	isMatrixCard = false,
	isCoverageCard = false,
}: PcoMessageProps): JSX.Element {
	const toolTipBodyTextSize: string = 'text-[10px]';
	const tooltipTextColor: string = isCoverageCard ? 'text-neutral-950' : ' text-white';
	const matrixCardTextSize: string = 'text-[9px] lg:text-[8px]';
	const pcoTooltipText = `<p class="accumulation__featureToolTipText whitespace-normal ${toolTipBodyTextSize} ${tooltipTextColor} text-center">${t(
		'pcoTooltip',
	)}</p>`;

	const pcoText: string = `${t('accumulate')} ${points} ${t('points')}`;
	const toolTipCustomClass = `${isGridCard ? 'absolute -mt-[153px]' : ''} ${isCoverageCard ? '!bg-gray-50 shadow-lg shadow-zinc-950/80' : ''}`;
	const PcoTextComponent = (): JSX.Element => {
		return <span className={`text-colorPco ${isMatrixCard ? matrixCardTextSize : 'text-xs'}`}>{pcoText}</span>;
	};

	const PcoResumeTextComponent = (): JSX.Element => {
		return (
			<p>
				<span className='text-neutral-950 text-xs'>{t('forThisPurchase')}</span>{' '}
				<span className='text-colorPco text-xs'>
					{points} {t('colombiaPoints')}
				</span>
			</p>
		);
	};

	return (
		<section className={`font-semibold flex flex-col items-center self-center ${titleClassName || ''} `}>
			<div className='flex items-center'>
				<IcomoonIcon icon={'iconPco'} className='mr-1 text-purple-600' fontSize={isMatrixCard ? 12 : 16} />
				{isCarResume ? <PcoResumeTextComponent /> : <PcoTextComponent />}
			</div>
			<div className={`flex ${isMatrixCard ? 'hidden' : ''}`}>
				<ToolTip
					useInfoIcon={false}
					title={t('pcoTooltipTitle')}
					colorPrimary={`${tooltipTitleColor || ''} || '#3730a3'`}
					body={pcoTooltipText}
					titleClassName={`${titleClassName || ''} text-[9px]`}
					toolTipCustomClass={toolTipCustomClass}
					arrowType={isGridCard ? ToolTipArrowType.bottom : ToolTipArrowType.top}
				/>
			</div>
		</section>
	);
}

export default PcoMessage;
