import { AlertStateInfo } from '../../../shared/interfaces/alert.interface';
import { getAlertStateInfo } from '../../../shared/services/alert.service';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import { AlertStates } from '../../../shared/enums/alert.enum';
import styles from './alert-card.module.scss';
import { useEffect } from 'react';

export interface AlertCardProps {
	state: AlertStates;
	cardWidth: string;
	iconSize: number;
	iconBackgroundColor: string;
	content: JSX.Element;
	className?: string;
	style?: React.CSSProperties;
}

export const AlertCard = (props: AlertCardProps): JSX.Element => {
	const extraSize: number = 10;
	const toHalfSize: number = 2;
	const iconContainerSize: string = `${props.iconSize + extraSize}px`;
	const satetInfo: AlertStateInfo = getAlertStateInfo(props.state);
	useEffect(() => {
		const root = document.documentElement;
		root?.style.setProperty('--alert-card-icon-container-size', iconContainerSize);

		root?.style.setProperty('--alert-card-extra-size', `${extraSize}px`);
	}, [props.iconSize, iconContainerSize, extraSize]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='alert-card inline-block relative mt-5' style={props.style}>
				<IcomoonIcon
					icon={satetInfo.icon}
					color={satetInfo.color}
					fontSize={props.iconSize}
					className={'z-50 absolute rounded-full shadowTop'}
					style={{
						width: iconContainerSize,
						height: iconContainerSize,
						backgroundColor: props.iconBackgroundColor,
					}}
				/>
				<div
					className={'pb-5 px-7 rounded-xl contentCupon'}
					style={{
						width: props.cardWidth,
						paddingTop: `${(props.iconSize + extraSize) / toHalfSize + extraSize}px`,
					}}
				>
					<div className={`${props.className || ''}`}>{props.content}</div>
				</div>
			</div>
		</>
	);
};

AlertCard.defaultProps = {
	className: '',
};

export default AlertCard;
