import { UseCountriesReturnInterface } from '../interfaces/useCountriesReturnInterface';
import { TransformedCountries } from '../interfaces/countries.interface';
import { CommonsService } from '../services/commons.service';
import { useEffect, useState } from 'react';

const useCountriesFrom = (service: CommonsService): UseCountriesReturnInterface => {
	const [countriesList, setCountriesList] = useState<TransformedCountries[]>([]);
	const [error, setError] = useState(false);
	useEffect(() => {
		const getCountries = async (): Promise<void> => {
			const value = await service.getCountries();
			if (value === null) {
				setError(true);

				return;
			}

			setCountriesList(
				value.map(country => ({
					label: country.name,
					value: country.code,
				})),
			);
		};

		void getCountries();
	}, []);

	return { countriesList, error };
};

export default useCountriesFrom;
