import { PlansSearchFormSubmitInterface, OptionSelect } from '@smartlinks/react-design-system';
import { SearchWidgetInterface } from '../../shared/interfaces/search.interface';
import PlansSearch from '../../templates/plans-search/plans-search';
import { PlansService } from '../../shared/services/plans.service';
import { PlansUtil } from '../../shared/utils/plans-search.util';
import useSearchWidget from '../../shared/hooks/useSearchWidget';
import { CommonsUtil } from '../../shared/utils/commons';
import { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export interface PlansSearchWidgetProps extends SearchWidgetInterface<PlansSearchFormSubmitInterface> {}

const AppPlansSearchWidget = ({
	token,
	language,
	useExternalRedirect,
	agency,
	callUrlSearch,
	callShowMobileFields,
}: PlansSearchWidgetProps): JSX.Element => {
	const widgetClassName: string = 'plansSearchWidget';
	const {
		agencyState,
		isLoading,
		widgetService: plansService,
		widgetStyles,
		urlSearchHandler,
		showMobileFieldsHandler,
		t,
	} = useSearchWidget<PlansService, PlansSearchFormSubmitInterface>({
		token,
		language,
		agency,
		className: widgetClassName,
		callUrlSearch,
		callShowMobileFields,
		productServiceClass: PlansService,
	});

	const [categories, setCategories] = useState<OptionSelect[]>([]);
	useEffect(() => {
		if (!isLoading && !(agencyState == null) && !(Object.keys(agencyState).length === 0)) {
			((): void => {
				try {
					void (async () => {
						const tempCategories: any[] | null = await plansService.getCategories();
						setCategories([...PlansUtil.mapCategoriesOptions(tempCategories || [])]);
					})();

					return;
				} catch (error) {
					console.error(error);
				}
				setCategories([]);
			})();
		}
	}, [isLoading]);

	return (
		<Suspense fallback={<div>...</div>}>
			{widgetStyles}
			<div id={agencyState?.profile?.name?.replace(/\s+/g, '') || 'noName'} className={widgetClassName}>
				{!isLoading && (
					<PlansSearch
						agency={agencyState}
						categories={categories}
						plansService={plansService}
						callUrlSearch={urlSearchHandler}
						t={t}
						useExternalRedirect={useExternalRedirect || false}
						callShowMobileFields={showMobileFieldsHandler}
					/>
				)}
			</div>
		</Suspense>
	);
};

export function PlansSearchWidget(props: PlansSearchWidgetProps): void {
	const element: HTMLDivElement | null = CommonsUtil.verifyWidgetElement(props?.elementId);
	if (element) {
		void CommonsUtil.createShadowRoot(element, true, true).then((renderIn: HTMLDivElement) => {
			ReactDOM.render(
				<div className='reactWidget plansSearch'>
					<AppPlansSearchWidget {...props} />
				</div>,
				renderIn,
				() => {
					if (props?.callBack) {
						props.callBack(element);
					}
				},
			);
		});
	}
}

export default PlansSearchWidget;
