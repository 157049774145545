import { FlightRoutePaymentsInterface, PassengerPaymentsInterface, PaymentStepResultEventInterface } from '../../../../../shared/interfaces/index';
import { capitalize, validationNumberPhone, formatDate } from '../../../../../shared/services/index';
import PurchaseTicket from '../../../../flights/organisms/admin/flights/purchase-ticket/purchase-ticket';
import { RowData, TableData } from '../../../../../shared/interfaces/table';
import { DateFormat } from '../../../../../shared/enums/date.enum';
import { Button } from '../../../../ui-elements/button/button';
import Table from '../../../../ui-elements/table/table';
import styles from './purchase-information.module.scss';
import { useMemo } from 'react';

export interface PurchaseInformationProps {
	idComponent: string;
	nameHead: string;
	documentHead: string;
	phoneNumberHead: string;
	genderHead: string;
	emailHead: string;
	bookingId: string;
	bookingDate: string;
	flightPaths: FlightRoutePaymentsInterface[];
	passengers: PassengerPaymentsInterface[];
	title: string;
	maleOrigin: string;
	femaleOrigin: string;
	maleTranslated: string;
	femaleTranslated: string;
	nextText: string;
	className?: string;
	onNextClick?: (data: PaymentStepResultEventInterface) => void;
}

export const PurchaseInformation = (props: PurchaseInformationProps): JSX.Element => {
	const nameLabel: string = 'name';
	const documentLabel: string = 'document';
	const phoneNumberLabel: string = 'phoneNumber';
	const genderLabel: string = 'gender';
	const emailLabel: string = 'email';
	const data = useMemo<TableData>(() => {
		const translateGender = (gender: string, male: string, female: string, maleTranslated: string, femaleTranslated: string): string => {
			if (gender === male) {
				return maleTranslated;
			} else if (gender === female) {
				return femaleTranslated;
			} else {
				return '';
			}
		};

		return {
			head: [
				{ value: props.nameHead, label: nameLabel },
				{ value: props.documentHead, label: documentLabel },
				{ value: props.phoneNumberHead, label: phoneNumberLabel },
				{ value: props.genderHead, label: genderLabel },
				{ value: props.emailHead, label: emailLabel },
			],
			rows: props.passengers.map((passenger: PassengerPaymentsInterface): RowData[] => {
				return [
					{ value: capitalize(passenger.fullName), label: nameLabel },
					{ value: passenger.identification, label: documentLabel },
					{ value: validationNumberPhone(passenger.phoneNumber), label: phoneNumberLabel },
					{
						value: translateGender(passenger.gender, props.maleOrigin, props.femaleOrigin, props.maleTranslated, props.femaleTranslated),
						label: genderLabel,
					},
					{ value: passenger.email, label: emailLabel },
				];
			}),
		};
	}, [
		props.passengers,
		props.nameHead,
		props.documentHead,
		props.phoneNumberHead,
		props.genderHead,
		props.emailHead,
		props.maleOrigin,
		props.femaleOrigin,
		props.maleTranslated,
		props.femaleTranslated,
		nameLabel,
		documentLabel,
		phoneNumberLabel,
		emailLabel,
		genderLabel,
	]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`flex flex-col justify-center bg-white	 ${props?.className || ''}`}>
				<p className={'font-family-black text-lg leading-3 mb-6 purchaseInformation__mainColor'}>
					{`${props.title}${props.bookingId}`}
					<span className={'font-family-regular text-xs leading-3 ml-1.5 purchaseInformation__secondColor'}>
						{formatDate(props.bookingDate, DateFormat.fullDate, 'es-CO', true, false)}
					</span>
				</p>
				<div className='flex justify-center mb-5'>
					<PurchaseTicket flightPaths={props.flightPaths} />
				</div>
				<Table data={data} className={'w-full table-auto purchaseInformation__table'} />
				<div className='flex flex-col md:flex-row items-center md:justify-end mt-6'>
					<Button
						onClick={() => (props?.onNextClick ? props?.onNextClick({ id: props.idComponent }) : null)}
						text={props.nextText}
						customClass={'w-full md:w-1/5 text-xs text-white rounded-lg my-2 md:my-0 purchaseInformation__button'}
					/>
				</div>
			</div>
		</>
	);
};

PurchaseInformation.defaultProps = {
	className: '',
};

export default PurchaseInformation;
