import { FlightsUtil, FlightsUtil as FlightsLocalUtil } from '../../shared/utils/flights-search.util';
import { FlightsLocalStorageInterface } from '../../shared/interfaces/widget-localstorage.interface';
import { FlightsSearchWidgetProps } from '../../widgets/flights-search/flightsSearchWidget';
import { FlightsService } from '../../shared/services/flights.service';
import useAirportsByWord from '../../shared/hooks/useAirportsByWord';
import { ProductType } from '../../shared/enums/product-types.enum';
import { Agency } from '../../shared/interfaces/agency.interface';
import { WidgetSearch } from '../../shared/enums/widget-search';
import { endPoints } from '../../shared/end-points/end-points';
import { useCallback, useMemo, lazy, Suspense } from 'react';
import {
	PassengersFieldRestrictionsFlightsSearchFormInterface,
	FlightsSearchFormSubmitInterface,
	FlightsSearchFormUtil,
	OptionSelect,
	Spinner,
} from '@smartlinks/react-design-system';
import { TFunction } from 'react-i18next';

const FlightsSearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system').then(module => {
		return { default: module.FlightsSearchForm };
	});
});

interface FlightsSearchTemplateProps extends FlightsSearchWidgetProps {
	airlines: OptionSelect[];
	t: TFunction<'translation', undefined>;
	flightsService: FlightsService;
}

interface FlightsForm extends FlightsSearchFormSubmitInterface {
	angularUrlPath: string;
}

function FlightsSearch({
	agency,
	airlines,
	useExternalRedirect,
	flightsService,
	disabledTripType,
	t,
	callUrlSearch,
	callShowMobileFields,
}: FlightsSearchTemplateProps): JSX.Element {
	const {
		isSearchLoading: isDepartureAirportsLoading,
		airports: departureAirports,
		callSearchByWord: callDepartureAirportsByWord,
	} = useAirportsByWord({
		flightsService,
		t,
		agency,
		isDeparture: true,
	});

	const {
		isSearchLoading: isArrivalAirportsLoading,
		airports: arrivalAirports,
		callSearchByWord: callArrivalAirportsByWord,
	} = useAirportsByWord({
		flightsService,
		t,
		agency,
		isDeparture: false,
	});

	const cabinsOptions: OptionSelect[] = useMemo(
		() =>
			((tempTraductions?: (label: string) => string, tempAgency?: Agency | null): OptionSelect[] => {
				if (tempTraductions != null && tempAgency != null) {
					return FlightsUtil.mapCabins(tempTraductions, tempAgency);
				}

				return [];
			})(t, agency),
		[t, agency],
	);

	const passengersRestrictions: PassengersFieldRestrictionsFlightsSearchFormInterface = useMemo(
		() =>
			((tempAgency?: Agency | null): PassengersFieldRestrictionsFlightsSearchFormInterface => {
				return FlightsUtil.passengersRestrictions(tempAgency);
			})(agency),
		[agency],
	);

	const calendarRestriction: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return FlightsUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const history: FlightsSearchFormSubmitInterface | null = useMemo(
		() =>
			((): FlightsSearchFormSubmitInterface | null => {
				return flightsService.getFlightsSearchFormHistory(t);
			})(),
		[flightsService, t],
	);

	const searchHandler = useCallback(
		(data: FlightsSearchFormSubmitInterface): void => {
			const agencyCountryCode: string = agency?.profile?.countryCode || '';
			const dataForm: FlightsForm = {
				...data,
				urlPath: FlightsSearchFormUtil.mapSearchUrl(data),
				angularUrlPath: FlightsLocalUtil.mapAngularRoute(data, agencyCountryCode),
			};

			const dataLocalStorage: FlightsLocalStorageInterface = {
				tripType: 'Round',
				adults: data.adults,
				airline: data.airline,
				airlineCode: data.airlineCode,
				cabin: data.cabin,
				children: data.children,
				infants: data.infants,
				passengersAndExtraInfo: data.passengersAndExtraInfo,
				destinations: data.destinations,
				withPromoCode: data.withPromoCode || false,
				promoCode: data.promoCode || '',
			};

			if (useExternalRedirect) {
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor: HTMLAnchorElement = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.flightsSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

				createAnchor.click();

				return;
			}

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			flightsService.setLocalHistory(dataLocalStorage);
		},
		[flightsService, useExternalRedirect, agency, callUrlSearch],
	);

	const isFilterChangeBetweenCabinAndAirline: boolean =
		agency?.widgets?.search?.includes(WidgetSearch.isFilterChangeBetweenCabinAndAirlineFlight) || false;

	const isDisplayPassengersExtraInfo: boolean = agency?.widgets?.search?.includes(WidgetSearch.isDisplayPassengersExtraInfo) || false;
	const hasPromoCodeActive: boolean = (agency?.couponsConfig?.productsPromoCode || []).some(
		product => product.productType === ProductType.Flight && product.active,
	);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<FlightsSearchForm
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					isFilterChangeBetweenCabinAndAirline={isFilterChangeBetweenCabinAndAirline}
					isDisplayPassengersExtraInfo={isDisplayPassengersExtraInfo}
					traductions={t}
					departureAirports={departureAirports}
					isDepartureAirportsLoading={isDepartureAirportsLoading}
					arrivalAirports={arrivalAirports}
					isArrivalAirportsLoading={isArrivalAirportsLoading}
					airlines={airlines}
					calendarRestriction={calendarRestriction}
					passengersRestrictions={passengersRestrictions}
					tripsRestriction={4}
					cabinsOptions={cabinsOptions}
					history={history}
					emitSubmitSearch={searchHandler}
					emitDepartureAirportsSearch={callDepartureAirportsByWord}
					emitArrivalAirportsSearch={callArrivalAirportsByWord}
					emitShowMobileFields={callShowMobileFields}
					disabledTripType={disabledTripType}
					widgetStyles={agency?.widgetStyles}
					hasPromoCodeActive={hasPromoCodeActive}
				/>
			</Suspense>
		</div>
	);
}

export default FlightsSearch;
