import { LocateCurrencyConfig } from '../interfaces/locate-currency-config';
import { ColorFormatsNames, ColorFormats } from '../enums/color.enum';
import { validateInputType } from '../../shared/enums/index';
import { LocaleProps } from '../interfaces/locale-props';
import { RegexItem } from '../../shared/regex/regex';

const formatLocalCurrency = (currencyDate: number, culture: string = 'es-ES'): string => {
	const numberFormat = new Intl.NumberFormat(culture);
	const currencyFormmat = numberFormat.format(currencyDate);
	return currencyFormmat;
};

const numberToLocaleWithcurrency = (numberToLocale: number, currency: string = 'USD', language: string = 'en-US', sign: string = ''): string => {
	const zeroValue: string = '.00';
	let setCurrency: LocateCurrencyConfig = {};
	const validateCOP: boolean = currency.toLocaleLowerCase() === 'cop';
	const validateUSD: boolean = currency.toLocaleLowerCase() === 'usd';
	if (currency !== '') {
		setCurrency = {
			currency,
		};

		if (currency === 'COP') {
			language = 'es-CO';
		}
	}

	if (!language) {
		language = 'en-US';
	}

	let maxDigits: number = 2;
	if (validateCOP) {
		maxDigits = 3;
	}

	if (validateUSD) {
		maxDigits = 2;
	}

	const localeProps: LocaleProps = {
		...setCurrency,
		minimumFractionDigits: 0,
		maximumFractionDigits: maxDigits,
	};

	const valueFormat: string = typeof numberToLocale === 'number' ? numberToLocale.toLocaleString(language, localeProps) : '0';
	let value: string;
	if (currency.toLocaleLowerCase() === 'usd') {
		const lastTwoChars: number = 2;
		const fixZeroinAmount: string = valueFormat?.replace(zeroValue, '');
		const checkDecimalLength: string = fixZeroinAmount.slice(fixZeroinAmount.length - lastTwoChars);
		const tempValue: string = checkDecimalLength.includes('.') || checkDecimalLength.includes(',') ? `${fixZeroinAmount}0` : fixZeroinAmount;
		value = sign ? `${sign}${tempValue}` : tempValue;
	} else {
		value = sign ? `${sign}${valueFormat}` : valueFormat;
	}

	return value;
};

const paginate = (arrayItem: any[], size: number): any[] => {
	return arrayItem?.reduce((acc, val, i) => {
		const idx = Math.floor(i / size);
		const page = acc[idx] || (acc[idx] = []);
		page.push(val);

		return acc;
	}, []);
};

const capitalize = (text: string): string => {
	return text
		? text
			.split(' ')
			.map((word: string) => {
				const lower = word.toLowerCase();
				return word.charAt(0).toUpperCase() + lower.slice(1);
			})
			.join(' ')
		: text;
};

const differenceBetweenDates = (dateOne: string, dateTwo: string): number => {
	const dateOneInTime = new Date(dateOne).getTime();
	const dateTwoInTime = new Date(dateTwo).getTime();
	const milliseconds = dateTwoInTime - dateOneInTime;
	return milliseconds / (1000 * 60 * 60 * 24);
};

const removeZuluTime = (date: string): string => {
	const removeZTime = date.replace('Z', '');
	return removeZTime;
};

const addAlphaToColor = (color: string, alpha: number): string => {
	const tempAlpha: number = alpha >= 0 && alpha <= 1 ? alpha : 1;
	if (color.includes(ColorFormatsNames.rgb)) {
		const plusIndex: number = 1;
		const sep: string = color.includes(',') ? ',' : ' ';
		const initIndex: number = color.indexOf('(');
		let rgba: string[] = color
			.substring(initIndex + plusIndex)
			.split(')')[0]
			.split(sep);
		rgba = rgba.filter((item: string): boolean => item !== '' && item !== '/').map((item: string): string => item.replace(RegexItem.onlySpaces, ''));

		if (rgba && (rgba.length === ColorFormats.rgb || rgba.length === ColorFormats.rgba)) {
			return `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${tempAlpha})`;
		}
	} else if (color.includes(ColorFormatsNames.hex)) {
		const halfDigits: number = 2;
		const convertHexToRGB: string = '0x';
		const values: string = color.replace(RegexItem.onlySpaces, '').replace(ColorFormatsNames.hex, '');
		let r: string, g: string, b: string;
		if (values && values.length === ColorFormats.hex / halfDigits) {
			r = convertHexToRGB + values[0] + values[0];

			g = convertHexToRGB + values[1] + values[1];

			b = convertHexToRGB + values[2] + values[2];

			return `rgba(${+r},${+g},${+b},${tempAlpha})`;
		} else if ((values && values.length === ColorFormats.hex) || values.length === ColorFormats.hexa) {
			r = convertHexToRGB + values[0] + values[1];

			g = convertHexToRGB + values[2] + values[3];

			b = convertHexToRGB + values[4] + values[5];

			return `rgba(${+r},${+g},${+b},${tempAlpha})`;
		}
	}

	return color;
};

const inputValidationType = (value: string, typeValidation: string): string => {
	switch (typeValidation) {
		case validateInputType.onlyNumber:
			return value.replace(RegexItem.onlyNumber, '');
		case validateInputType.onlyLetters:
			return value.replace(RegexItem.onlyText, '');
		case validateInputType.noCondition:
			return value;
		default:
			return value;
	}
};

const validationNumberPhone = (value: string): string => {
	if (!value) {
		return '';
	}

	const phoneOut: string = inputValidationType(value, validateInputType.onlyNumber);
	return phoneOut;
};

export {
	numberToLocaleWithcurrency,
	paginate,
	formatLocalCurrency,
	capitalize,
	differenceBetweenDates,
	removeZuluTime,
	inputValidationType,
	addAlphaToColor,
	validationNumberPhone,
};
