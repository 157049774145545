import { ListInterface, ListItemInterface } from '../../../shared/interfaces/list.interface';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import Backdrop from '../../ui-elements/backdrop/backdrop';
import useWindowWidth from '../../../shared/hooks/useWidth';
import { useState, useEffect, useCallback } from 'react';
import List from '../../ui-elements/list/list';

export interface HamburguerMenuProps {
	items: ListInterface[];
	iconFontSize: number;
	zIndex: number;
	activeItemId?: string;
	openBackgroundColor?: string;
	hamburguerIconColor?: string;
	hamburguerIcon?: JSX.Element;
	activeItemColor?: string;
	deactiveItemColor?: string;
	openTopPosition?: string;
	openBottomPosition?: string;
	openRightPosition?: string;
	openLeftPosition?: string;
	listHeight?: string;
	onClick: (item: ListItemInterface) => void;
	className?: string;
	openIconClassName?: string;
	closeIconClassName?: string;
	listClassName?: string;
	itemsClassName?: string;
	liClassName?: string;
	closeIconStyle?: React.CSSProperties;
	customOpenController?: boolean;
	useNativeAnchor?: boolean;
	customHeaderOpen?: JSX.Element;
}

export function HamburguerMenu(props: HamburguerMenuProps): JSX.Element {
	const responsiveSize: number = 768;
	const hamburguerLines: number = 3;
	const diffZIndex: number = 1;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const windowWidth: number = useWindowWidth();
	const lines: number[] = [...Array(hamburguerLines).keys()];
	const widthExtend: number = 7;
	const existCustomOpenController: boolean = Object.prototype.hasOwnProperty.call(props, 'customOpenController');
	useEffect(() => {
		if (isOpen && windowWidth < responsiveSize) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}
	}, [isOpen, windowWidth]);

	useEffect(() => {
		if (existCustomOpenController && windowWidth < responsiveSize) {
			setIsOpen(props.customOpenController || false);
		}
	}, [props.customOpenController]);

	const clickHandler = useCallback(
		(item: ListItemInterface): void => {
			if (props.onClick) {
				props.onClick(item);
			}

			if (existCustomOpenController) {
				return;
			}

			setIsOpen(false);
		},
		[props.onClick],
	);

	return (
		<>
			<Backdrop
				show={windowWidth < responsiveSize && isOpen}
				onClick={(): void => setIsOpen((prevState: boolean): boolean => !prevState)}
				zIndex={props.zIndex - diffZIndex}
				backgroundColor={props.openBackgroundColor}
				opacity={0.6}
			/>
			<div
				className={`hamburguerMenu flex flex-col items-center ${isOpen ? 'hamburguerMenu__open fixed md:absolute' : ''} ${props.className || ''}`}
				style={{
					backgroundColor: isOpen ? props.openBackgroundColor : 'transparent',
					top: isOpen ? props.openTopPosition : undefined,
					bottom: isOpen ? props.openBottomPosition : undefined,
					right: isOpen ? props.openRightPosition : undefined,
					left: isOpen ? props.openLeftPosition : undefined,
					zIndex: props.zIndex,
				}}
			>
				{isOpen ? (
					<div className={`w-full flex justify-end ${props.closeIconClassName || ''}`} style={props.closeIconStyle}>
						{props.customHeaderOpen}

						<button className='cursor-pointer' onClick={(): void => setIsOpen((prevState: boolean): boolean => !prevState)}>
							<IcomoonIcon
								icon='closeIco'
								fontSize={props.iconFontSize}
								color={'#ffffff'}
								style={{
									width: `${props.iconFontSize}px`,
									height: `${props.iconFontSize}px`,
								}}
							/>
						</button>
					</div>
				) : (
					<div
						className={`flex flex-col justify-between cursor-pointer ${props.openIconClassName || ''}`}
						onClick={() => setIsOpen((prevState: boolean): boolean => !prevState)}
						style={{
							width: `${props.iconFontSize + widthExtend}px`,
							height: `${props.iconFontSize}px`,
						}}
					>
						{props.hamburguerIcon
							? props.hamburguerIcon
							: lines.map((index: number) => (
								<span
									key={`line_${index}`}
									className={'w-full rounded-xl'}
									style={{
										height: '3px',
										backgroundColor: props.hamburguerIconColor,
									}}
								/>
							  ))}
					</div>
				)}
				{isOpen && (
					<div className={'flex-grow flex flex-col'}>
						<List
							items={props.items}
							callBack={clickHandler}
							activeItemId={props.activeItemId}
							className={`flex-grow flex flex-col ${props.listClassName || ''}`}
							withPointer={true}
							deactiveColor={props.deactiveItemColor}
							activeColor={props.activeItemColor}
							itemsClassName={`flex flex-col ${props.itemsClassName || ''}`}
							ulClassName='flex-grow'
							liClassName={props.liClassName}
							height={props.listHeight}
							useNativeAnchor={props.useNativeAnchor || false}
						/>
					</div>
				)}
			</div>
		</>
	);
}

HamburguerMenu.defaultProps = {
	className: '',
	openIconClassName: '',
	closeIconClassName: '',
	listClassName: '',
	liClassName: '',
	itemsClassName: '',
};

export default HamburguerMenu;
