import { CarDropOffInterfaceV2, CarInterfaceV2, GroupedCoveragesInterfaceV2 } from '../../../../shared/interfaces';
import { formatDate, formatTimeWithDoubleDot } from '../../../../../../shared/services/dates-managment.service';
import { FareSummaryInterface } from '../../../../../../shared/interfaces/payments/summary.interface';
import { RentalCarsLogosV2 } from '../../../../shared/enums/filter-cars-cards-v2.enum';
import { PcoMessage } from '../../../../../shared-components/pco-message/pco-message';
import SummaryFares from '../../../../organisms/generics/summary-fares/summary-fares';
import { IcomoonIcon } from '../../../../../ui-elements/icomoon-icon/icomoon-icon';
import { ModelTypeEnum } from '../../../../../../shared/enums/model-type.enum';
import { DetailSteps } from '../../../../../../shared/enums/detail-steps.enum';
import { capitalize } from '../../../../../../shared/services/utils.service';
import { DateFormat } from '../../../../../../shared/enums/date.enum';
import CarFloatButton from '../car-float-button/car-float-button';
import Spinner from '../../../../../ui-elements/spinner/spinner';
import Button from '../../../../../ui-elements/button/button';
import Image from '../../../../../ui-elements/image/image';
import { useState, useEffect, useCallback } from 'react';
import styles from './car-resume.module.scss';

export interface CarResumeProps {
	step: DetailSteps;
	resumeTitle?: string;
	summary: FareSummaryInterface;
	carRecommendation: CarInterfaceV2;
	pickupLabel?: string;
	dropOffLabel?: string;
	coverageLabel?: string;
	coverageText?: string;
	summaryTitle?: string;
	basePriceTitle?: string;
	totalTitle?: string;
	subTotalTitle?: string;
	taxesTitle?: string;
	feetitle?: string;
	discountTitle?: string;
	ivaTitle?: string;
	pricePerDayTitle?: string;
	btnActionLabel?: string;
	extrasTitle?: string;
	totalDaysLabelUnit?: string;
	totalDaysLabel?: string;
	disabled?: boolean;
	t?: (item: string) => string;
	callBack: (carRecommendation: CarInterfaceV2) => void;
	showChangedPrice?: boolean;
	currencySymbol?: string;
	language?: string;
}

export function CarResume({
	step,
	resumeTitle,
	summary,
	carRecommendation,
	pickupLabel,
	dropOffLabel,
	coverageLabel,
	coverageText,
	summaryTitle,
	basePriceTitle,
	totalTitle,
	subTotalTitle,
	taxesTitle,
	feetitle,
	discountTitle,
	ivaTitle,
	pricePerDayTitle,
	btnActionLabel,
	extrasTitle,
	disabled,
	totalDaysLabelUnit,
	totalDaysLabel,
	showChangedPrice,
	currencySymbol,
	callBack,
	t,
	language,
}: CarResumeProps): JSX.Element {
	const mobileWidth: number = 768;
	const [colapsed, setColapsed] = useState<boolean>(false);
	const firstElement: number = 0;
	const [clickLoading, setClickLoading] = useState<boolean>(false);
	const [pointsAccumulation, setPointsAccumulation] = useState<number | null>(null);
	const [dropOff, setDropOff] = useState<CarDropOffInterfaceV2 | null>(null);
	const pointsAccumulationString: string = pointsAccumulation ? pointsAccumulation.toString() : '';
	const buttonClickHandler = useCallback(
		(data: CarInterfaceV2): void => {
			if (callBack) {
				callBack(data);

				setClickLoading(true);
			}
		},
		[callBack],
	);

	useEffect(() => {
		if (window.screen.width < mobileWidth) {
			setColapsed(true);
		}
	}, []);

	useEffect(() => {
		if (!disabled) {
			setClickLoading(false);
		}
	}, [disabled]);

	useEffect(() => {
		const positionCar: number = 0;
		const carSelected: GroupedCoveragesInterfaceV2 | undefined = carRecommendation.rateByRentalCar[positionCar].rateByCoverage.find(
			(coverage: GroupedCoveragesInterfaceV2) => coverage.selected,
		);

		if (carSelected && carSelected.totalAccumulationProductPoint) {
			setPointsAccumulation(carSelected.totalAccumulationProductPoint);
		}

		if (carSelected && carSelected.rate.dropOff) {
			setDropOff(carSelected.rate.dropOff);
		}
	}, [carRecommendation]);

	const colapsedHandle = (): void => {
		if (window.screen.width < mobileWidth) {
			setColapsed(!colapsed);
		}
	};

	const DropOffZero = (): JSX.Element => {
		return (
			<div className='carResume__dropOffContainer flex flex-col rounded-[10px] my-2'>
				<div className='carResume__dropOffContainerIcon flex items-center'>
					<IcomoonIcon icon={'infoCirlce2Ico'} className='mr-1' fontSize={22} />
					<div className='flex flex-col ml-1 text-sm'>
						<p className='carResume__dropOffZeroIconInfo'>{(t && t('dropOffNoFees')) || 'dropOffNoFees'}</p>
						<span className='carResume__dropOffZeroTitle'>{(t && t('dropOffNoCosts')) || 'dropOffNoCosts'}</span>
					</div>
				</div>
			</div>
		);
	};

	const DropOff = (): JSX.Element => {
		return (
			<>
				{dropOff && carRecommendation.hasDropOffDetail && (
					<div className='carResume__dropOffContainer flex flex-col rounded-[10px] p-4 my-2'>
						{dropOff.amount ? (
							<>
								<div className='carResume__dropOffContainerIcon flex items-center'>
									<IcomoonIcon icon={'infoCirlce2Ico'} className='mr-1' fontSize={16} />
									<p className='carResume__dropOffIconInfo text-xs'>{(t && t('remember')) || 'remember'}</p>
								</div>
								<div className='carResume__dropOffContainerInfo flex justify-between text-sm mb-1'>
									<span className='carResume__dropOffTitle'>{(t && t('paymentAtCounter')) || 'paymentAtCounter'}</span>
									<span className='carResume__dropOffPrice'>
										{dropOff.currency} {dropOff.amount}
									</span>
								</div>
								<p className='carResume__dropDescription text-xs'>{(t && t('approximateValuesInfo')) || 'approximateValuesInfo'}</p>
							</>
						) : (
							<DropOffZero />
						)}
					</div>
				)}
			</>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={'carResume'}>
				<div className={'carResume__container  p-[25px] md:p-[15px] lg:p-[35px]  rounded-[20px]'}>
					<p className='carResume__title text-base md:text-[21px] flex justify-between' onClick={colapsedHandle}>
						{resumeTitle || 'resumeTitle'}
						<span className='chevronLeftIco -rotate-90 block md:hidden'></span>
					</p>
					<div className={`${colapsed ? 'hidden md:block' : ''}`}>
						<hr className='my-[15px] border-gray-300' />
						<div className='carResume__images flex justify-between'>
							<Image
								className={`w-[100%] md:w-[70%] mx-[auto] overflow-hidden ${carRecommendation.rateByRentalCar[firstElement].rentalCarCode}`}
								url={carRecommendation.car.imageUrl || ''}
								customClass={'mr-3.5'}
							/>
							<div className='w-[20%]'>
								{carRecommendation?.rateByRentalCar &&
								carRecommendation.rateByRentalCar.length &&
								carRecommendation.rateByRentalCar[firstElement] &&
								carRecommendation.rateByRentalCar[firstElement].rentalCarName &&
								carRecommendation.rateByRentalCar[firstElement].rentalCarName.toLocaleLowerCase() in RentalCarsLogosV2 ? (
										RentalCarsLogosV2[carRecommendation.rateByRentalCar[firstElement].rentalCarName.toLocaleLowerCase()].color
									) : (
										<Image url={carRecommendation.rateByRentalCar[firstElement].rentalCarImageUrl || ''} customClass={''} />
									)}
							</div>
						</div>

						<div className='mt-[10px]'>
							<p className='carResume__carTitle'>{carRecommendation.car.vehicleTypeLabel}</p>
							<p className='carResume__carSubTitle'>{capitalize(carRecommendation.car.model)}</p>
						</div>
						<hr className='my-[15px] border-gray-300' />

						<div className='mb-[15px]'>
							<p className='carResume__boldTitle text-xs'>{pickupLabel || 'pickupLabel'}</p>
							<p className='carResume__regularText text-xs'>{capitalize(carRecommendation?.rentalSchedule?.pickUpAddress || '')}</p>
							<p className='carResume__mediumText'>
								{formatDate(carRecommendation?.rentalSchedule?.pickUpDate ?? '', DateFormat.largeDate, language)}
								<span className='ml-[5px] mr-[2px]'>|</span> {formatTimeWithDoubleDot(carRecommendation?.rentalSchedule?.pickUpTime || '')}
							</p>
						</div>

						<div>
							<p className='carResume__boldTitle text-xs'>{dropOffLabel || 'dropOffLabel'}</p>
							<p className='carResume__regularText text-xs'>{capitalize(carRecommendation?.rentalSchedule?.dropOffAddress || '')}</p>
							<p className='carResume__mediumText'>
								{formatDate(carRecommendation?.rentalSchedule?.dropOffDate ?? '', DateFormat.largeDate, language)}
								<span className='ml-[5px] mr-[2px]'>|</span> {formatTimeWithDoubleDot(carRecommendation?.rentalSchedule?.dropOffTime || '')}
							</p>
						</div>
						<hr className='my-[15px] border-gray-300' />

						<div>
							<p className='carResume__boldTitle flex items-center'>
								{coverageLabel || 'coverageLabel'}: <span className='carResume__regularText'>{coverageText}</span>
							</p>
						</div>

						<DropOff />

						<div>
							{summary && (
								<SummaryFares
									title={summaryTitle || 'summaryTitle'}
									summaryFares={summary}
									basePriceTitle={basePriceTitle || 'basePriceTitle'}
									totalTitle={totalTitle || 'totalTitle'}
									subTotalTitle={
										carRecommendation?.businessModel === ModelTypeEnum.POINT_OR_MONEY ||
										carRecommendation?.businessModel === ModelTypeEnum.POINT_PLUS_MONEY ||
										carRecommendation?.businessModel === ModelTypeEnum.POINTS
											? ' '
											: subTotalTitle || 'subTotalTitle'
									}
									taxesTitle={taxesTitle || 'taxesTitle'}
									feetitle={feetitle || 'feetitle'}
									discountTitle={discountTitle || 'discountTitle'}
									ivaTitle={ivaTitle || 'ivaTitle'}
									pricePerDayTitle={
										carRecommendation?.businessModel === ModelTypeEnum.POINT_OR_MONEY ||
										carRecommendation?.businessModel === ModelTypeEnum.POINT_PLUS_MONEY ||
										carRecommendation?.businessModel === ModelTypeEnum.POINTS
											? ' '
											: pricePerDayTitle || 'pricePerDayTitle'
									}
									extrasTitle={extrasTitle || 'extrasTitle'}
									forcedDisplay={step === DetailSteps.specialServices ? { extras: true } : undefined}
									className={`carResume__${carRecommendation?.businessModel}`}
									businessModel={(carRecommendation?.businessModel || ModelTypeEnum.CASH) as ModelTypeEnum}
									showCurrencySymbol={showChangedPrice}
									currencySymbol={currencySymbol}
								/>
							)}
						</div>
					</div>
				</div>

				{pointsAccumulation && (
					<div className={'carResume__container mt-[15px] p-[15px] md:p-[15px] lg:p-[25px] rounded-[20px]'}>
						{t && <PcoMessage points={pointsAccumulationString} t={t} isCarResume={true} />}
					</div>
				)}

				<div className='carResume__action'>
					<Button
						content={clickLoading && disabled ? <Spinner className={'carResume__spinner'} /> : <span>{btnActionLabel || 'btnActionLabel'}</span>}
						customClass={'w-full rounded-full text-white my-[20px] bg-black carResume__spinner'}
						onClick={() => buttonClickHandler(carRecommendation)}
						disabled={disabled}
					/>
				</div>

				<CarFloatButton
					summary={summary}
					coverageLabel={coverageLabel}
					coverageText={coverageText}
					totalDaysLabelUnit={totalDaysLabelUnit}
					btnActionLabel={btnActionLabel}
					disabled={disabled}
					carRecommendation={carRecommendation}
					totalDaysLabel={totalDaysLabel}
					callBack={callBack}
					showChangedPrice={showChangedPrice}
					currencySymbol={currencySymbol}
				/>
			</div>
		</>
	);
}
