import './tailwind.css';
import './icons.css';
import './rents-icons.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

export * from './shared/enums/index';
export * from './shared/interfaces/index';
export * from './shared/services/index';
export * from './shared/utils/index';
export * from './shared/hooks/index';
export * from './shared/mocks/index';
export * from './lib/flights/shared/index';
export * from './lib/flights/shared/utils/index';
export * from './lib/shared-components/index';
export * from './lib/ui-elements/index';
export * from './lib/cars/index';
export * from './lib/flights/index';
export * from './lib/hotels/index';
export * from './lib/activities/index';
export * from './lib/assistances/index';
export * from './lib/plans/index';
export * from './lib/disney/index';
export * from './lib/shared-organisms/index';
export * from './lib/hotels-disney/index';
