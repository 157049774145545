import { FlightsSearchAirportsInterface, FlightsSearchAirlinesInterface } from '../interfaces/flights-airports.interface';
import { faPlaneDeparture, faPlaneArrival } from '@awesome.me/kit-dc2201b3ec/icons/modules/classic/regular';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';
import { MapFlightsUrlUtil } from './map-flights-search-url.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	OptionSelect,
	FlightAirlinesTypes,
	PassengersFieldRestrictionsFlightsSearchFormInterface,
	ListItemInterface,
	DestinationsFlightsSearchFormSubmitInterface,
	FlightsSearchFormSubmitInterface,
} from '@smartlinks/react-design-system';

export class FlightsUtil {
	public static mapCabins(traductions: (label: string) => string, agency: Agency): OptionSelect[] {
		const allowedCabins = agency.flightsCriteriaConfiguration?.allowedCabins;
		if (allowedCabins?.length) {
			let options: OptionSelect[] = [];
			const defaultCabin: string = 'economy';
			const priorizeCabin = allowedCabins.find(cabin => cabin === defaultCabin);
			let filterCabin = allowedCabins;

			if (priorizeCabin) {
				options = [
					{
						label: traductions(priorizeCabin),
						value: priorizeCabin,
					},
				];

				filterCabin = allowedCabins.filter(cabin => cabin !== defaultCabin);
			}

			filterCabin.forEach((cabin: string): void => {
				options.push({
					label: traductions(cabin),
					value: cabin,
				});
			});

			return [...options];
		}

		return [];
	}

	public static passengersRestrictions(agency?: Agency | null): PassengersFieldRestrictionsFlightsSearchFormInterface {
		return {
			maxPassengers: 9,
			infants: {
				default: agency?.flightsCriteriaConfiguration?.infants?.default || 0,
				minimum: agency?.flightsCriteriaConfiguration?.infants?.minimum || 0,
				maximum: agency?.flightsCriteriaConfiguration?.infants?.maximum || 0,
			},
			children: {
				default: agency?.flightsCriteriaConfiguration?.children?.default || 0,
				minimum: agency?.flightsCriteriaConfiguration?.children?.minimum || 0,
				maximum: agency?.flightsCriteriaConfiguration?.children?.maximum || 0,
			},
			adults: {
				default: agency?.flightsCriteriaConfiguration?.adults?.default || 1,
				minimum: agency?.flightsCriteriaConfiguration?.adults?.minimum || 1,
				maximum: agency?.flightsCriteriaConfiguration?.adults?.maximum || 0,
			},
		};
	}

	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find((restriction: CalendarRestrictions) => restriction.product === CalendarRestrictionTypes.flights)?.value || 0
		);
	}

	public static mapAirportsList(
		airports: FlightsSearchAirportsInterface[],
		truncate: boolean = false,
		isSecodnatyTemplate: boolean = false,
		isDeparture: boolean = true,
	): ListItemInterface[] {
		const airportsList: ListItemInterface[] = [];
		airports.forEach((airport: FlightsSearchAirportsInterface) => {
			const mapAirport = {
				name: this.getAiportName(airport, isSecodnatyTemplate, truncate, isDeparture),
				originalName: airport.name,
				id: airport.code,
				value: airport.code,
				link: '',
				original: {
					countryCode: airport.countryCode,
					description: airport.description,
				},
			};

			airportsList.push({ ...mapAirport });
		});

		return [...airportsList];
	}

	public static mapAirlinesOptions(traductions: (label: string) => string, airlines: FlightsSearchAirlinesInterface[]): OptionSelect[] {
		const airportsList: OptionSelect[] = [];
		airlines.forEach((airline: FlightsSearchAirlinesInterface) => {
			airportsList.push({
				label: airline.name,
				value: airline.code,
			});
		});

		airportsList.sort((a: OptionSelect, b: OptionSelect): number => {
			const aString = a.label.toLowerCase();
			const bString = b.label.toLowerCase();
			if (aString < bString) {
				return -1;
			}

			if (aString > bString) {
				return 1;
			}

			return 0;
		});

		airportsList.unshift({
			label: traductions('all'),
			value: FlightAirlinesTypes.all,
		});

		return [...airportsList];
	}

	public static getStatusNational(
		agency: Agency,
		airports: FlightsSearchAirportsInterface[],
		destinations: DestinationsFlightsSearchFormSubmitInterface[],
	): boolean {
		const countryCode: string = agency.profile.countryCode;
		const destinationInternational: DestinationsFlightsSearchFormSubmitInterface | undefined = destinations.find(
			(destination: DestinationsFlightsSearchFormSubmitInterface): boolean => {
				if (!destination.departure.airportCode) {
					return false;
				}

				const airport: FlightsSearchAirportsInterface | undefined = airports.find((airport: FlightsSearchAirportsInterface): boolean => {
					return (
						(airport.code === destination.departure.airportCode || airport.code === destination.arrival.airportCode) &&
						countryCode !== airport.countryCode
					);
				});

				return !(airport == null);
			},
		);

		return destinationInternational == null;
	}

	public static mapAngularRoute(data: FlightsSearchFormSubmitInterface, agencyCountryCode: string): string {
		return MapFlightsUrlUtil.mapAngular(data, agencyCountryCode);
	}

	public static getAiportName(
		airport: FlightsSearchAirportsInterface,
		isSecondaryTemplate: boolean,
		truncate: boolean,
		isDeparture: boolean,
	): JSX.Element {
		const firstElement: number = 0;
		const descriptionArray: string[] = airport.description.split('-');
		const cityName: string = descriptionArray[firstElement].trim() || '';
		const airportText: string = `(${airport.code} - ${airport.name})`;
		return (
			<div
				className={`${truncate ? 'md:overflow-ellipsis md:overflow-hidden md:max-w-full md:whitespace-nowrap' : ''} 
					 airportName flex gap-4 place-items-start`}
			>
				<div className='md:hidden'>
					{isSecondaryTemplate ? (
						<span className='iconLightPlaneSecondary text-xl text-[#101828]'></span>
					) : (
						<FontAwesomeIcon
							icon={isDeparture ? faPlaneDeparture : faPlaneArrival}
							width='1.5rem'
							height='1.5rem'
							className='text-xl text-[#101828]'
						/>
					)}
				</div>
				<div className='flex flex-col max-w-full pt-0.5 md:hidden'>
					<span className='text-wrap max-w-full whitespace-normal text-base font-semibold text-[#101828]'>
						{cityName} {airportText}
					</span>
					<span className='text-sm text-[#344054] font-normal'>{airport.country}</span>
				</div>
				<span
					className={`${truncate ? 'md:overflow-ellipsis md:overflow-hidden md:max-w-full md:whitespace-nowrap' : ''} 
					 hidden md:block`}
				>
					{airport.description}
				</span>
			</div>
		);
	}
}
