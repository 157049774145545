import { differenceBetweenDates, removeZuluTime } from '../../../../../../shared/services/utils.service';
import { FlightPathsInterface } from '../../../../../../shared/interfaces/flight-paths.interface';
import { formatDate } from '../../../../../../shared/services/dates-managment.service';
import { DateFormat } from '../../../../../../shared/enums/date.enum';

export interface SummaryRoutesFlightsProps {
	title: string;
	flightPaths: FlightPathsInterface[];
	numberFlightTitle?: string;
}

export function SummaryRoutesFlights({ title, flightPaths, numberFlightTitle }: SummaryRoutesFlightsProps): JSX.Element {
	return (
		<div>
			{flightPaths.map((item: FlightPathsInterface, index: number) => {
				const arrivalDate: string = removeZuluTime(item.arrivalDate);
				const departureDate: string = removeZuluTime(item.departureDate);
				const validationArrivalDate: string = formatDate(arrivalDate, DateFormat.standarStringDate);
				const validationDepartureDate: string = formatDate(departureDate, DateFormat.standarStringDate);
				return (
					<div className='container mx-auto shadow-lg flex grid-flow-row rounded-lg mx-5 my-2 py-3 border' key={index}>
						<div className='w-full px-4 border-r'>
							<h4 className='text-right text-xs font-family-regular'>
								{item.fareFamily && item.fareFamily.airline ? item.fareFamily?.airline : numberFlightTitle?.toUpperCase()}{' '}
								{item.legs[0]?.flightNumber}
							</h4>
							<h2 className='title-black font-bold text-left text-xl leading-6'>{item.departureCity.airportCode}</h2>
							<h4 className='text-left font-family-regular text-xs mb-1 text-black text-opacity-75 my-1'>{item.departureCity.cityName}</h4>
							<h4 className='text-left font-family-regular text-xs italic'>{formatDate(departureDate, DateFormat.largeDate)}</h4>
							<h4 className='text-left font-family-regular text-xs italic'>{formatDate(item.departureTime, DateFormat.timeTime)}</h4>
						</div>
						<div className='w-full px-4'>
							<h4 className='text-right  text-xs font-family-regular'>
								{item.fareFamily && item.fareFamily.airline ? item.fareFamily?.airline : numberFlightTitle?.toUpperCase()}{' '}
								{item.legs[item.legs.length - 1]?.flightNumber}
							</h4>
							<h2 className='title-black font-bold text-left text-xl leading-5'>{item.arrivalCity.airportCode}</h2>
							<h4 className='text-left font-family-regular text-xs mb-1 text-black text-opacity-75 my-1'>{item.arrivalCity.cityName}</h4>
							<h4 className=' flex font-family-regular text-xs italic'>
								{formatDate(arrivalDate, DateFormat.largeDate)}
								{validationArrivalDate !== validationDepartureDate && (
									<span className='text-[0.55rem] ml-1 not-italic text-red-500'>{`+ ${differenceBetweenDates(
										validationDepartureDate,
										validationArrivalDate,
									)}`}</span>
								)}
							</h4>
							<h4 className='text-left font-family-regular text-xs italic'>{formatDate(item.arrivalTime, DateFormat.timeTime)}</h4>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default SummaryRoutesFlights;
