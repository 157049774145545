import styles from '../../car-coverages-desktop/car-coverages-desktop.module.scss';
import { TableData, RowData } from '../../../../../../../../shared/interfaces';
import innerStyles from './car-coverages-mobile-skeleton.module.scss';
import Button from '../../../../../../../ui-elements/button/button';
import Table from '../../../../../../../ui-elements/table/table';
import { useMemo } from 'react';

export interface CarCoveragesMobileSkeletonProps {
	rows?: number;
	columns?: number;
}

export const CarCoveragesMobileSkeleton = ({ rows, columns }: CarCoveragesMobileSkeletonProps): JSX.Element => {
	const dataDesktop: TableData = useMemo(
		() =>
			((tempRows: number): TableData => {
				function getRndInteger(min: number, max: number): number {
					return Math.floor(Math.random() * (max - min + 1)) + min;
				}
				const headTable: RowData[] = [];
				const bodyTable: RowData[][] = [
					...Array(tempRows)
						.fill(0)
						.map((): RowData[] => {
							return [
								{
									value: (
										<span
											className='carCoveragesDesktopSkeleton__td bg-gray-200 h-[12px] block mx-0 my-0 ml-[25px]'
											style={{ width: `${getRndInteger(50, 90)}%` }}
										/>
									),
									label: '',
								},
								{ value: <div className={'carCoveragesMobileSkeleton__td'} />, label: '' },
							];
						}),
					[
						{ value: <p className='carCoveragesMobileSkeleton__total bg-gray-200 h-[12px] w-[30%] mx-0 my-0 ml-[25px]' />, label: '' },
						{
							value: (
								<div className={'carCoveragesMobileSkeleton__td w-full h-full flex items-center justify-center'}>
									<span className='rate bg-gray-200 h-[12px] w-[40%] block' />
								</div>
							),
							label: '',
						},
					],
				];

				return {
					head: headTable,
					rows: bodyTable,
				};
			})(rows || 0),
		[rows],
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<style dangerouslySetInnerHTML={{ __html: innerStyles.toString() }}></style>

			<div className={'carCoveragesMobileSkeleton animate-pulse flex flex-col carCoveragesDesktop carCoveragesMobileSkeleton'}>
				{Array(columns)
					.fill(0)
					.map((item: number, index: number): JSX.Element => {
						return (
							<div key={index}>
								<div className={'carCoveragesMobileSkeleton__title h-[48px] flex items-center'}>
									<div className='w-full'>
										<span className='bg-gray-200 h-[16px] w-[40%] block mx-0 my-0 ml-[25px]' />
									</div>
								</div>
								<Table
									data={dataDesktop}
									className={'w-full CustomerDataMock mx-auto table-fixed carCoveragesDesktop__table'}
									tdClassName={'text-center'}
								/>
								<div className={'carCoveragesMobileSkeleton__button py-[20px] carCoveragesMobileSkeleton__button'}>
									<Button
										text={''}
										disabled={true}
										customClass='carCoveragesMobileSkeleton__btn w-full rounded-full text-white my-[20px] bg-black h-[42px] !bg-gray-200'
									/>
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
};

CarCoveragesMobileSkeleton.defaultProps = {
	rows: 4,
	columns: 2,
};

export default CarCoveragesMobileSkeleton;
