import { FieldValues, UseFormRegister, UseFormReturn, FieldErrorsImpl, DeepRequired, UseFormWatch } from 'react-hook-form';
import PassengersFieldInputs from './passengers-field-inputs/passengers-field-inputs';
import { SearchFieldsNames } from '../../../../shared/enums/search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { ElementTypes } from '../../../../shared/enums/dynamic-forms.enum';
import {
	FieldButtonEventHandlerInterface,
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
	PassengersFieldRestrictionsInterface,
	DisabledInputsPassengersFieldInterface,
	CustomTraductionsLabelsPassengersFieldInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import { CounterInputData } from '../../../../shared/interfaces';
import Backdrop from '../../../ui-elements/backdrop/backdrop';
import { useCallback, useState, FC, useEffect } from 'react';
import Button from '../../../ui-elements/button/button';
import Input from '../../../ui-elements/input/input';
import styles from './passengers-field.module.scss';

export interface PassengersFieldProps {
	fieldId?: string;
	title?: string;
	placeHolder?: string;
	name: string;
	backButtonTraduction?: string;
	nextButtonTraduction?: string;
	restrictions: PassengersFieldRestrictionsInterface;
	useBackButton?: boolean;
	required?: boolean;
	customTraductionsLabels?: CustomTraductionsLabelsPassengersFieldInterface;
	colorSecondary?: string;
	className?: string;
	desktopModalClassName?: string;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	onlyOneTypeOfPassenger?: boolean;
	useMinimunOnePassenger?: boolean;
	useOptionalText?: boolean;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	traductions: (label: string) => string;
	register: UseFormRegister<FieldValues>;
	watch?: UseFormWatch<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	trigger?: UseFormReturn['trigger'];
	nextCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	backCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	onShowModal?: (show: boolean) => void;
	onChangePassengers?: (passengersFieldInputs: PassengersFieldDataInterface, name: string) => void | string;
	onModalInputsHandler?: (currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string) => number;
	onModalFieldHandler?: (data: PassengersFieldDataInterface, name: string) => PassengersFieldDataInterface;
	visibleTitle?: boolean;
	iconClassName?: string;
	disabledInputs?: DisabledInputsPassengersFieldInterface;
	passengersAmountsRestrictions?: number;
	passengersAlwaysVisible?: boolean;
	typeProduct?: string;
	maxRooms?: number;
	maxGuestsPerRoom?: number;
	maxPassengers?: number;
	truncate?: boolean;
	inputContainerClassName?: string;
}

export const PassengersField: FC<PassengersFieldProps> = ({
	fieldId,
	title,
	placeHolder,
	name,
	backButtonTraduction,
	nextButtonTraduction,
	restrictions,
	errors,
	useBackButton,
	required,
	customTraductionsLabels,
	colorSecondary,
	className,
	desktopModalClassName,
	onlyOneTypeOfPassenger,
	useMinimunOnePassenger,
	useOptionalText,
	traductions,
	register,
	watch,
	setValue,
	getValues,
	trigger,
	nextCallBack,
	backCallBack,
	onShowModal,
	onChangePassengers,
	onModalInputsHandler,
	onModalFieldHandler,
	visibleTitle,
	isSecondaryTemplate,
	isTertiaryTemplate,
	iconClassName,
	disabledInputs,
	children,
	passengersAmountsRestrictions,
	passengersAlwaysVisible,
	typeProduct,
	maxRooms,
	maxGuestsPerRoom,
	maxPassengers,
	truncate,
	inputContainerClassName,
}) => {
	const inputsNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [childrenValue, setChildrenValue] = useState<number>(0);
	const [infantsValue, setInfantsValue] = useState<number>(0);
	const [adultsValue, setAdultsValue] = useState<number>(0);
	const [changePassengerValue, setChangePassengerValue] = useState<boolean>(false);
	const [adultsRestriction, setAdultsRestriction] = useState<CounterInputData>({
		max: restrictions.adults.maximum,
		min: isTertiaryTemplate ? 1 : restrictions.adults.minimum,
	});

	const [childrenRestriction, setChildrenRestriction] = useState<CounterInputData>({
		max: restrictions.children.maximum,
		min: restrictions.children.minimum,
	});

	const [infantsRestriction, setInfantsRestriction] = useState<CounterInputData>({
		max: restrictions.infants.maximum,
		min: restrictions.infants.minimum,
	});

	const [iconClassNameValue, setIconClassNameValue] = useState<string>('chevronBottomIco text-[7px]');
	const watchAdults: string = watch ? watch(inputsNames.adultsName) : '';
	const watchChildren: string = watch ? watch(inputsNames.childrenName) : '';
	const watchInfants: string = watch ? watch(inputsNames.infantsName) : '';
	const showModalHandler = useCallback(
		(show: boolean) => {
			if (onShowModal) {
				onShowModal(show);
			}

			setShowModal(show);

			setIconClassNameValue(show ? 'chevronUpIco text-[7px]' : 'chevronBottomIco text-[7px]');
		},
		[onShowModal],
	);

	const clickNextHandler = useCallback(async () => {
		if (trigger) {
			let itsOk: boolean = false;
			const tempNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
			const resultName = await trigger(tempNames.name, { shouldFocus: true });
			let resultAdultsName: boolean = true;
			let resultChildrenName: boolean = true;
			let resultInfantsName: boolean = true;
			resultAdultsName = await trigger(tempNames.adultsName, {
				shouldFocus: true,
			});

			resultChildrenName = await trigger(tempNames.childrenName, {
				shouldFocus: true,
			});

			resultInfantsName = await trigger(tempNames.infantsName, {
				shouldFocus: true,
			});

			itsOk = resultName && resultAdultsName && resultChildrenName && resultInfantsName;

			if (itsOk && nextCallBack) {
				const prepareCallBack: FieldButtonEventHandlerInterface = {
					id: fieldId || SearchFieldsNames.passengers,
				};

				nextCallBack(prepareCallBack);
			}
		}
	}, [name, fieldId, nextCallBack, trigger]);

	const clickBackHandler = useCallback(() => {
		if (backCallBack) {
			const prepareCallBack: FieldButtonEventHandlerInterface = {
				id: fieldId || SearchFieldsNames.passengers,
			};

			backCallBack(prepareCallBack);
		}
	}, [fieldId, backCallBack]);

	const changePassengersHandler = useCallback(
		(data: PassengersFieldDataInterface): void => {
			if (setValue) {
				const tempNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
				let passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
					data.adults,
					data.children,
					data.infants,
					traductions('adult') || 'adult',
					traductions('adults') || 'adults',
					traductions('child') || 'child',
					traductions('children') || 'children',
					traductions('infant') || 'infant',
					traductions('infants') || 'infants',
					isTertiaryTemplate,
				);
				setValue(tempNames.adultsName, data.adults);

				setValue(tempNames.childrenName, data.children);

				setValue(tempNames.infantsName, data.infants);

				if (onChangePassengers) {
					passengersDisplayValue = onChangePassengers({ ...data }, name) || passengersDisplayValue;
				}

				setValue(tempNames.name, passengersDisplayValue);
			}
		},
		[name, setValue, onChangePassengers, showModalHandler, traductions],
	);

	useEffect(() => {
		setAdultsValue(Number(watchAdults || 0));

		setChildrenValue(Number(watchChildren || 0));

		setInfantsValue(Number(watchInfants || 0));

		setChangePassengerValue(true);
	}, [watchAdults, watchChildren, watchInfants]);

	useEffect(() => {
		if (maxPassengers && isSecondaryTemplate) {
			const maxAdults: number = maxPassengers - childrenValue - infantsValue;
			const maxChildren: number = maxPassengers - adultsValue - infantsValue;
			const maxInfants: number = maxPassengers - adultsValue - childrenValue;
			setAdultsRestriction({ ...adultsRestriction, max: maxAdults });

			setChildrenRestriction({ ...childrenRestriction, max: maxChildren });

			setInfantsRestriction({ ...infantsRestriction, max: maxInfants });
		}
	}, [adultsValue, infantsValue, childrenValue]);

	const defaultPassengersElement: JSX.Element = (
		<PassengersFieldInputs
			name={name}
			adultsDefaultValue={changePassengerValue ? adultsValue : restrictions?.adults?.default}
			childrenDefaultValue={changePassengerValue ? childrenValue : restrictions?.children?.default}
			infantsDefaultValue={infantsValue || restrictions?.infants?.default}
			adultsCounterData={adultsRestriction}
			childrenCounterData={childrenRestriction}
			infantsCounterData={infantsRestriction}
			traductions={traductions}
			customTraductionsLabels={customTraductionsLabels}
			colorSecondary={colorSecondary}
			className={isSecondaryTemplate ? (showModal ? '' : 'hidden') : ''}
			register={register}
			setValue={setValue}
			getValues={getValues}
			callBack={changePassengersHandler}
			changeInputsValidation={onModalInputsHandler}
			changeFieldValidation={onModalFieldHandler}
			disabledInputs={disabledInputs}
			passengersAmountsRestrictions={passengersAmountsRestrictions}
			onlyOneTypeOfPassenger={onlyOneTypeOfPassenger}
			useMinimunOnePassenger={useMinimunOnePassenger}
			typeProduct={typeProduct}
			maxRooms={maxRooms}
			maxGuestsPerRoom={maxGuestsPerRoom}
			isSecondaryTemplate={isSecondaryTemplate}
			isTertiaryTemplate={isTertiaryTemplate}
		>
			{children}
		</PassengersFieldInputs>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`passengersField ${className || ''} ${
					isSecondaryTemplate ? 'secondaryTemplate' : 'bg-white '
				} w-full md:bg-transparent absolute md:relative max-h-screen md:max-h-[none] h-screen md:h-auto left-0 top-0 flex flex-col`}
			>
				{passengersAlwaysVisible ? (
					<>
						{defaultPassengersElement}
						<div className='passengersField__actions w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid'>
							<Button
								onClick={clickNextHandler}
								type='button'
								customClass='passengersField__nextButton my-[15px] w-full flex items-center justify-center text-base rounded-[10px] text-white'
								styles={{
									background: colorSecondary,
								}}
								text={nextButtonTraduction || 'next'}
							/>
							{useBackButton && (
								<Button
									onClick={clickBackHandler}
									type='button'
									customClass='passengersField__backButton my-0 py-0 w-full flex items-center justify-center text-base rounded-[10px] bg-transparent'
									styles={{
										color: colorSecondary,
									}}
									text={backButtonTraduction || 'back'}
								/>
							)}
						</div>
					</>
				) : (
					<>
						<div
							className={`${isTertiaryTemplate ? 'px-[40px] md:px-0 py-[20px] md:py-0' : 'passengersField__inputContainer'} 
							${isSecondaryTemplate && !isTertiaryTemplate ? 'px-[16px] md:px-0 pt-[25px] pb-[2px] md:py-0' : ''}
							${
					!isSecondaryTemplate && !isTertiaryTemplate
						? 'bg-white px-[40px] md:px-0 py-[25px] md:py-[10px] md:pr-[10px] border-0 border-b md:border border-solid rounded-none md:rounded-[10px]'
						: ''
					}
							${inputContainerClassName ?? ''}  flex flex-col md:flex-row relative`}
							onClick={() => showModalHandler(!showModal)}
						>
							{!isSecondaryTemplate && !isTertiaryTemplate && (
								<div
									className={'passengersField__title flex flex-row items-center border-0 md:border-r md:border-solid'}
									style={{ borderColor: colorSecondary }}
								>
									{!!iconClassName && (
										<div
											className={
												'passengersField__ico flex items-center md:justify-center min-w-[20px] w-[20px] md:min-w-[70px] md:w-[70px] pointers-none'
											}
										>
											{!isSecondaryTemplate && <span className={`${iconClassName} text-[15px] md:text-[25px]`} style={{ color: colorSecondary }} />}
										</div>
									)}
									<span
										className={`${iconClassName ? 'pl-[10px]' : ''} md:hidden text-xs text-[#616161] whitespace-nowrap`}
										style={{
											color: colorSecondary,
										}}
									>
										{title || 'title'}
									</span>
								</div>
							)}

							<div
								className={`w-full flex flex-col md:flex-grow font-family-regular ${
									isSecondaryTemplate || isTertiaryTemplate ? 'pl-0 gap-2' : 'pl-[30px] md:pl-[25px]'
								}`}
							>
								<span
									className={`${visibleTitle ? 'md:block' : ''} ${isSecondaryTemplate ? 'md:text-gray-700 text-sm font-medium leading-5' : ''}
											${isTertiaryTemplate && !isSecondaryTemplate ? 'block font-medium text-[13px] text-[#4D4D4F]' : 'hidden text-xs text-[#616161]'}
											whitespace-nowrap`}
									style={{
										color: isSecondaryTemplate || isTertiaryTemplate ? undefined : colorSecondary,
									}}
								>
									{title || 'title'}
									{isSecondaryTemplate && useOptionalText && (
										<span className='text-gray-400 ml-[4px]'>({traductions('optionalLabel') || 'optional'})</span>
									)}
								</span>
								<div className='relative w-full flex flex-row items-center'>
									<div className='passengersField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
										<Input
											register={register(inputsNames.adultsName, {
												required: disabledInputs?.adults ? false : required,
											})}
											required={disabledInputs?.adults ? false : required}
											type={ElementTypes.text}
											autocomplete={'off'}
										/>
										<Input
											register={register(inputsNames.childrenName, {
												required: disabledInputs?.children ? false : required,
											})}
											required={disabledInputs?.children ? false : required}
											type={ElementTypes.text}
											autocomplete={'off'}
										/>
										<Input
											register={register(inputsNames.infantsName, {
												required: disabledInputs?.infants ? false : required,
											})}
											required={disabledInputs?.infants ? false : required}
											type={ElementTypes.text}
											autocomplete={'off'}
										/>
									</div>
									<Input
										readonly={true}
										register={register(inputsNames.name, { required })}
										required={required}
										defaultValue={''}
										icon={isSecondaryTemplate || isTertiaryTemplate ? iconClassNameValue : undefined}
										isSecondaryTemplate={isSecondaryTemplate}
										isTertiaryTemplate={isTertiaryTemplate}
										type={ElementTypes.text}
										placeholder={placeHolder || 'withOutPassengersPlaceHolder'}
										autocomplete={'off'}
										className={`passengersField__input ${
											errors[inputsNames.name] ||
											errors[inputsNames.adultsName] ||
											errors[inputsNames.infantsName] ||
											errors[inputsNames.childrenName]
												? 'border-2 border-red-500 text-red-500'
												: ''
										}
										${isTertiaryTemplate ? 'gap-2 py-2.5 px-3.5 border-[#BEBEBE] text-[#BEBEBE] border border-solid rounded-none' : ''}
										${isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : ''}
										${!isSecondaryTemplate && !isTertiaryTemplate ? '!py-0 !px-0' : ''}
										${showModal ? 'passengersField__openModal' : ''}`}
										inputClassName={`${
											isTertiaryTemplate ? 'text-[#4D4D4F] !text-[14px]' : '!text-base'
										} bg-transparent !p-0 text-base font-normal md placeholder:text-base placeholder:font-normal md:placeholder placeholder:text-[#C3C7CE]
										`}
										style={{
											color: isSecondaryTemplate || isTertiaryTemplate ? undefined : colorSecondary,
										}}
										truncate={truncate}
									/>
								</div>
							</div>
						</div>
						{showModal && (
							<>
								<Backdrop
									show={showModal}
									onClick={() => showModalHandler(false)}
									zIndex={10}
									backgroundColor={'transparent'}
									opacity={1}
									className='hidden md:block passengersField__backdrop'
									portalBackdrop={true}
									styles={`
											.passengersField__backdrop {
												display: none;
											}

											@media(min-width: 768px) {
												.passengersField__backdrop {
													display: block;
												}
											}
										`}
								/>
								<div
									className={`passengersField__desktopPassengers hidden md:flex w-full md:w-[375px] md:absolute md:z-[12]  md:left-0 rounded-[10px] bg-white ${
										desktopModalClassName || ''
									} 
									${isSecondaryTemplate ? 'top-[80px]' : ''}
									${!isSecondaryTemplate || !isTertiaryTemplate ? 'top-[120%]' : ''}`}
								>
									{defaultPassengersElement}
									{isTertiaryTemplate && (
										<button
											className='md:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] px-[15px] py-[10px] mb-[20px] text-white'
											onClick={() => showModalHandler(false)}
											style={{ backgroundColor: colorSecondary }}
										>
											{traductions('apply') || 'apply'}
										</button>
									)}
								</div>
							</>
						)}
						<div
							className={`passengersField__mobilePassengers w-full md:hidden h-full flex-grow overflow-y-auto ${
								isSecondaryTemplate ? 'px-[16px]' : ' px-[40px]'
							}`}
						>
							{defaultPassengersElement}
						</div>
						<div className='passengersField__actions w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid'>
							<Button
								onClick={clickNextHandler}
								type='button'
								customClass={`passengersField__nextButton my-[15px] w-full flex items-center justify-center text-base text-white font-family-regular ${
									isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'
								} ${isTertiaryTemplate ? 'rounded-none' : ''}`}
								styles={{
									background: colorSecondary,
								}}
								text={nextButtonTraduction || 'next'}
							/>
							{useBackButton && (
								<Button
									onClick={clickBackHandler}
									type='button'
									customClass={`passengersField__backButton my-0 py-0 w-full flex items-center justify-center text-base rounded-[10px] bg-transparent ${
										isSecondaryTemplate ? 'rounded-full border border-gray-300' : 'rounded-[10px]'
									} ${isTertiaryTemplate ? 'rounded-none' : ''}`}
									styles={{
										color: colorSecondary,
									}}
									text={backButtonTraduction || 'back'}
								/>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

PassengersField.defaultProps = {
	required: false,
};

export default PassengersField;
