import { useState, useEffect, useCallback } from 'react';
import { Button, Input } from '../../ui-elements';
import styles from './search-input.module.scss';

export interface SearchInputProps {
	colorPrimary?: string;
	defaultValue: string;
	name?: string;
	placeHolder?: string;
	isReactiveMode?: boolean;
	callBackValue: (value: string) => void;
}

export const SearchInput = ({ colorPrimary, defaultValue, name, callBackValue, placeHolder, isReactiveMode }: SearchInputProps): JSX.Element => {
	const [searchValue, setSearchValue] = useState<string>('');
	const emitSearchOnClick = useCallback(
		(e: React.FormEvent<HTMLFormElement>): void => {
			e.preventDefault();

			callBackValue(searchValue);
		},
		[callBackValue, searchValue],
	);

	const handleInputChange = (newValue: string): void => {
		setSearchValue(newValue);

		if (isReactiveMode) {
			callBackValue(newValue);
		}
	};

	useEffect(() => {
		if (defaultValue) {
			setSearchValue(defaultValue);
		}
	}, [defaultValue]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<form
				autoComplete='off'
				onSubmit={emitSearchOnClick}
				className={'searchInput rounded-[10px] w-full h-[50px] bg-white flex flex-row justify-start items-center relative flex-nowrap p-[5px]'}
			>
				{isReactiveMode && <span className='searchIco flex justify-center items-center p-1.5 text-[15px]'></span>}
				<Input
					name={name || 'searchInput'}
					required={true}
					placeholder={placeHolder || 'search...'}
					defaultValue={defaultValue || ''}
					inputClassName={'bg-white !pr-0 !py-0 pl-[10px] md:pl-[30px]'}
					onChange={e => {
						handleInputChange(e.value as string);
					}}
					className='!p-0'
				/>
				{!isReactiveMode && (
					<Button
						type='submit'
						customClass={'searchInput__searchButton w-[40px] h-[40px] !p-0 flex items-center rounded-[5px]'}
						styles={{
							background: colorPrimary,
						}}
						content={<span className='searchIco flex justify-center items-center p-1.5 text-[15px]'></span>}
					/>
				)}
			</form>
		</>
	);
};

export default SearchInput;
