import IcomoonIcon from '../../../../../ui-elements/icomoon-icon/icomoon-icon';
import useWindowWidth from '../../../../../../shared/hooks/useWidth';
import Slider from '../../slider/slider';

export interface SliderCategoriesSkeletonProps {
	className?: string;
	titleClassName?: string;
	categoriesContainerClassName?: string;
	sliderClassName?: string;
	slideTitleClassName?: string;
	containerButtonClassName?: string;
}

export function SliderCategoriesSkeleton({
	className,
	titleClassName,
	categoriesContainerClassName,
	sliderClassName,
	slideTitleClassName,
	containerButtonClassName,
}: SliderCategoriesSkeletonProps): JSX.Element {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	return (
		<div className={`sliderCategoriesSkeleton flex flex-col animate-pulse ${className || ''}`}>
			<div className={`mb-4 md:mb-8 ${titleClassName || ''}`}>
				<h2 className={'bg-gray-200 h-6 md:h-9 w-2/5 md:w-1/5'} />
			</div>
			<div className={`flex flex-col md:flex-row justify-center items-center mb-7 md:mb-10 ${categoriesContainerClassName || ''}`}>
				{windowWidth >= responsiveSize ? (
					<div className='mr-5 text-gray-200'>
						<IcomoonIcon icon='chevronLeftIco' fontSize={17} />
					</div>
				) : (
					<div className='w-full flex flex-row justify-end items-center mb-7'>
						<div className='mr-8 text-gray-200'>
							<IcomoonIcon icon='chevronLeftIco' fontSize={17} />
						</div>
						<div className='text-gray-200 mr-5'>
							<IcomoonIcon icon='chevronRightIco' fontSize={17} />
						</div>
					</div>
				)}
				<Slider
					slides={[]}
					slidesHeight='200px'
					config={{
						showContent: false,
						showLinkButton: false,
						showSubtitleLine: false,
						showOutContent: true,
						showBackgroundImage: true,
					}}
					loading={true}
					skeletonSlides={windowWidth >= responsiveSize ? 3 : 1}
					slidesSkeletonClassNames={{
						className: 'rounded-xl w-full',
						outClassName: 'sliderCategoriesSkeleton__slide w-full md:w-[31%]',
						imageClassName: 'rounded-xl',
						titleClassName: `w-full h-9 md:h-12 ${slideTitleClassName || ''}`,
						outContentClassName: 'mt-2.5 md:mt-4',
					}}
					className='w-full justify-between items-center'
					outerClassName={`w-full flex-grow ${sliderClassName || ''}`}
					slidesWidth={0}
					slidesMargin={0}
					sliderSideMargin={0}
					slidesNumberShow={0}
				/>
				{windowWidth >= responsiveSize && (
					<div className='ml-5 text-gray-200'>
						<IcomoonIcon icon='chevronRightIco' fontSize={17} />
					</div>
				)}
			</div>
			<div className={`flex flex-col ${containerButtonClassName || ''}`}>
				<div className='sliderCategoriesSkeleton__button h-14 w-full md:w-48 mx-auto rounded-full bg-gray-200' />
			</div>
		</div>
	);
}

export default SliderCategoriesSkeleton;
