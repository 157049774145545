import { Image } from '../../../ui-elements/image/image';
import { Button } from '../../../../lib/ui-elements';
export interface IntroductionSingleImageProps {
	altImage?: string;
	imageUlr?: string;
	subtitle?: string;
	title: string;
	description: string;
	callActionText: string;
	urlCallAction: string;
}

export const IntroductionSingleImage = ({
	altImage,
	imageUlr,
	subtitle,
	title,
	description,
	callActionText,
	urlCallAction,
}: IntroductionSingleImageProps): JSX.Element => {
	const callActionRedirection = (): void => {
		if (urlCallAction) {
			window.open(urlCallAction, '_blank');
		}
	};

	return (
		<div className='introductionSingleImage bg-gray-100'>
			<div className='introductionSingleImage__container flex flex-col md:flex-row md:max-h-[431px]'>
				<div className='introductionSingleImage__item order-1 md:order md:w-6/12 flex items-center px-3 md:px-6 lg:px-12'>
					<div className='introductionSingleImage__info py-4'>
						<h2 className='introductionSingleImage__subtitle text-[15px] lg:text-[18px]'>{subtitle}</h2>
						<h1 className='introductionSingleImage__title text-[38px] md:text-[45px] lg:text-[55px]'>{title}</h1>
						<p className='introductionSingleImage__description text-[20px] lg:text-[24px]'>{description}</p>
						<Button
							text={callActionText}
							customClass='introductionSingleImage__btn mt-4 w-[273px] bg-gray text-white rounded-md'
							onClick={callActionRedirection}
						/>
					</div>
				</div>

				<div className='introductionSingleImage__item introductionSingleImage__image order md:order-1 md:w-6/12 overflow-hidden'>
					<Image url={imageUlr || ''} width={'100%'} height={'431px'} alt={altImage || ''} styles={{}} />
				</div>
			</div>
		</div>
	);
};
