export function CarMapOfficeSkeleton(): JSX.Element {
	return (
		<div className={'animate-pulse mt-[20px] h-[333px] overflow-y-auto'}>
			<div className={'carMapOffice__officeLocation relative border-b border-gray-300 pb-[15px] mb-[20px]'}>
				<p className='h-3 w-[95px] bg-gray-100 carMapOffice__officeTitle mt-[10px] mb-[3px] text-[20px]'></p>
				<div className='flex text-[25px] my-[10px]'>
					<div className='h-12 w-12 bg-gray-100 mr-[7px] rent'></div>
					<div className='h-12 w-12 bg-gray-100 mr-[7px] rent'></div>
					<div className='h-12 w-12 bg-gray-100 mr-[7px] rent'></div>
				</div>
				<p className='carMapOffice__address h-3 w-[180px] bg-gray-100'></p>
			</div>

			<div className={'carMapOffice__officeLocation relative border-b border-gray-300 pb-[15px] mb-[20px]'}>
				<p className='h-3 w-[95px] bg-gray-100 carMapOffice__officeTitle mt-[10px] mb-[3px] text-[20px]'></p>
				<div className='flex text-[25px] my-[10px]'>
					<div className='h-12 w-12 bg-gray-100 mr-[7px] rent'></div>
					<div className='h-12 w-12 bg-gray-100 mr-[7px] rent'></div>
					<div className='h-12 w-12 bg-gray-100 mr-[7px] rent'></div>
				</div>
				<p className='carMapOffice__address h-3 w-[180px] bg-gray-100'></p>
			</div>
		</div>
	);
}
