import { OptionSelect } from '../../interfaces/inputs.interface';
import { LocationOptionInterface } from '../../interfaces/generics/culture-inputs.interface';

export const DefaultLocationValueMock: string = '1';

export const LocationOptionsMock: LocationOptionInterface[] = [
	{
		image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Colombia.svg/1280px-Flag_of_Colombia.svg.png',
		option: {
			value: DefaultLocationValueMock,
			label: 'Colombia',
		},
	},
	{
		image: 'https://s1.significados.com/foto/american-flag-1311743-180_sm.png',
		option: {
			value: '2',
			label: 'EE. UU.',
		},
	},
	{
		image:
			'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Flag_of_Ecuador_%281900%E2%80%932009%29.svg/300px-Flag_of_Ecuador_%281900%E2%80%932009%29.svg.png',
		option: {
			value: '3',
			label: 'Ecuador',
		},
	},
];

export const DefaultCurrencyValueMock: string = '1';

export const CurrencyOptionsMock: OptionSelect[] = [
	{
		value: DefaultCurrencyValueMock,
		label: 'USD',
	},
	{
		value: '2',
		label: 'COP',
	},
];
