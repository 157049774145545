import { FiltersData } from '../interfaces/availability-filters.interface';
import { FilterElement } from '../../../../shared/enums';

export class AvailabilityFiltersUtil {
	public static currentFiltersFromUrl(filterData: FiltersData[]): {} {
		const params = Object.fromEntries(new URLSearchParams(window.location.search));
		let filtersFromUrl = {};
		Object.keys(params).forEach(key => {
			const value: string = params[key];
			const rangeValidation: boolean = key === 'minprice' || key === 'maxprice';
			const filter: boolean = filterData.some(filter => filter.type === key);
			if (filter || rangeValidation) {
				filtersFromUrl = { ...filtersFromUrl, ...{ [key]: value } };
			}
		});

		return filtersFromUrl;
	}

	public static validateSelectedValue(data: FiltersData): FiltersData {
		const params = Object.fromEntries(new URLSearchParams(window.location.search));
		Object.keys(params).forEach(key => {
			const value = params[key];
			const optionsElementsValidate =
				data.elementType === FilterElement.SELECT || data.elementType === FilterElement.CHECKBOX || data.elementType === FilterElement.BUTTON;

			if (optionsElementsValidate && data.type === key) {
				data.options.forEach(option => (option.selected = value.includes(option.value)));
			}

			if (data.elementType === FilterElement.RANGE) {
				if (key === 'minprice') {
					data.minDefaultValue = value || data.minValue;
				}

				if (key === 'maxprice') {
					data.maxDefaultValue = value || data.maxValue;
				}
			}
		});

		return data;
	}
}
