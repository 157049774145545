import { DataResponseFlexibleFlights } from '../../../../../lib/flights/shared';
import { FlexibleFlightsSkeleton } from './skeleton/skeleton-flexible-flights';
import { FlexibleFlightsEnums } from '../../../../../lib/flights/shared/enums';
import { FlexibleFlightsUtil } from '../../../../../lib/flights/shared/utils';
import { TableData } from '../../../../../shared/interfaces/table';
import Table from '../../../../ui-elements/table/table';
import styles from './flexible-flights.module.scss';
import { useState, useEffect } from 'react';

export interface flexibleFlightsProps {
	data: DataResponseFlexibleFlights | undefined;
	params: flexibleFlightsParams;
	t: (value: string) => string;
	loading: boolean;
}

export interface flexibleFlightsParams {
	departureDate: string;
	arrivalDate: string;
	departureCity: string;
	arrivalCity: string;
	promoCode?: string;
	directFlightsOnly?: boolean;
	adultPassengersAmount?: number;
	childrenPassengersAmount?: number;
	infantePassengersAmount?: number;
	trip: FlexibleFlightsEnums;
}

export function FlexibleFlights({ data, t, loading, params }: flexibleFlightsProps): JSX.Element {
	const [showSkeleton, setShowSkeleton] = useState(true);
	const [dataArrivalDates, setDataArrivalDates] = useState<TableData>({ head: [], rows: [] });
	const [dataDepartureDates, setDataDepartureDates] = useState<TableData>({ head: [], rows: [] });
	const { trip } = params;
	useEffect(() => {
		setShowSkeleton(loading);
	}, [loading]);

	useEffect(() => {
		if (data) {
			const arrivalDates = FlexibleFlightsUtil.mapFlexibleFlighttoTableData(t('next'), t('of'), true, data, params);
			const departureDates = FlexibleFlightsUtil.mapFlexibleFlighttoTableData(t('next'), t('of'), false, data, params);
			setDataArrivalDates(arrivalDates);

			setDataDepartureDates(departureDates);
		}
	}, [data]);

	return showSkeleton ? (
		<FlexibleFlightsSkeleton tripTypeRound={trip === FlexibleFlightsEnums.tripRound} />
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			{dataArrivalDates.head.length & dataDepartureDates.rows.length ? (
				<div className={'contTables flex'}>
					<Table
						className={`${!(trip === FlexibleFlightsEnums.tripRound) ? 'hidden' : ''} tableArribal`}
						iconHead='icon-icon-plane-return-gray'
						data={dataArrivalDates}
					/>
					<div className={`tableResponsive ${trip === FlexibleFlightsEnums.tripRound ? 'tripRound' : ''}`}>
						<Table className={'tableDeparture'} iconHead='flyPlaneIco' data={dataDepartureDates} />
					</div>
				</div>
			) : (
				<div className={'contTables error'}>
					<span className='errorFlexibleFlights'>
						<span className='path1'></span>
						<span className='path2'></span>
						<span className='path3'></span>
						<span className='path4'></span>
						<span className='path5'></span>
						<span className='path6'></span>
						<span className='path7'></span>
						<span className='path8'></span>
					</span>
					<div>
						<h2>{t('titleMessageErrorFlexibleDates')}</h2>
						<h3>{t('subTitleMessageErrorFlexibleDates')}</h3>
						<p>{t('paragraphMessageErrorFlexibleDates')}</p>
					</div>
				</div>
			)}
		</>
	);
}
