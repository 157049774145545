import { Fares, AmountFares } from '../interfaces/index';

export const calculateAmountFares = (fares: Fares[]): AmountFares => {
	const totalFares = new AmountFares();
	fares.forEach(element => {
		totalFares.totalBaseAmount += element.base;

		totalFares.totalTaxes += element.totalTaxes;

		totalFares.subTotalAmount += element.subTotal;
	});

	return totalFares;
};
