import { InputEventsHandler, MaskInputConfiguration } from '../../../shared/interfaces/inputs.interface';
import { InputType, FormsInputsType } from '../../../shared/enums/inputs.enum';
import styles from './mask-input.module.scss';
import { IMaskInput } from 'react-imask';

export interface MaskInputProps {
	type?: InputType;
	value: string;
	label?: string;
	name?: string;
	placeholder?: string;
	disabled?: boolean;
	maskConfiguration: MaskInputConfiguration;
	onChange?: (e: InputEventsHandler) => void;
	className?: string;
	labelClassName?: string;
	inputClassName?: string;
}

export const MaskInput = (props: MaskInputProps): JSX.Element => {
	const changeHandler = (value: string): void => {
		if (props?.onChange) {
			const dataEvent: InputEventsHandler = {
				type: props?.type ? FormsInputsType[props.type] : FormsInputsType.text,
				name: props?.name,
				value,
			};

			props.onChange(dataEvent);
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`maskInput ${props?.className || ''}`}>
				{props.label && (
					<label className={`${props.labelClassName || ''}`} htmlFor={props?.name}>
						{props.label}
					</label>
				)}
				<IMaskInput
					unmask={true}
					type={props.type}
					id={props?.name}
					name={props?.name}
					placeholder={props?.placeholder}
					value={props.value}
					onAccept={(value, mask) => changeHandler(value as string)}
					className={`maskInput__input ${props.inputClassName || ''}`}
					disabled={props?.disabled}
					{...props.maskConfiguration}
				/>
			</div>
		</>
	);
};

MaskInput.defaultProps = {
	type: InputType.text,
	className: '',
	labelClassName: '',
	inputClassName: '',
};

export default MaskInput;
