export enum ProductType {
	Flight = 'air',
	Hotel = 'hotel',
	Activity = 'activity',
	Assistance = 'assistance',
	Plan = 'plan',
	Car = 'car',
	Disney = 'plan',
	HotelDisney = 'hotelDisney',
}
