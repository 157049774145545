import {
	CustomTraductionsLabelsPassengersFieldInterface,
	FieldButtonEventHandlerInterface,
	PassengersFieldDataInterface,
	PassengersFieldRestrictionsInterface,
} from '../../../../shared/interfaces';
import { DeepRequired, FieldErrorsImpl, FieldValues, UseFormRegister, UseFormReturn, UseFormWatch } from 'react-hook-form';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { RoomNameHotelsSearchFormInterface } from '../../shared/interfaces';
import { PassengersFieldV2 } from './passengers-field/passengers-field-v2';
import AgeInput from '../../../shared-components/age-input/age-input';
import useWindowWidth from '../../../../shared/hooks/useWidth';
import { SearchFieldsNames } from '../../../../shared/enums';
import { Button } from '../../../ui-elements';
import styles from './rooms-v2.module.scss';

export interface RoomsV2Props {
	roomsNames: RoomNameHotelsSearchFormInterface[];
	minChildAge: number;
	maxChildAge: number;
	colorSecondary: string;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	maxRooms: number;
	maxGuestsPerRoom?: number;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	childrenAges: number[][];
	truncate: boolean;
	roomsNumber: number;
	isSecondaryTemplate?: boolean;
	mobileFieldStep: SearchFieldsNames | string;
	traductions: (label: string) => string;
	register: UseFormRegister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormReturn['setValue'];
	getValues: UseFormReturn['getValues'];
	trigger: UseFormReturn['trigger'];
	nextCallBack: (event: FieldButtonEventHandlerInterface) => void;
	backCallBack: (event: FieldButtonEventHandlerInterface) => void;
	onModalInputsHandler: (currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string) => number;
	onChangeAgeHandler: (event: ChangeEvent<HTMLInputElement>, roomIndex: number, ageIndex: number) => void;
	addOrRemoveRoomsComponent: JSX.Element;
}

export const RoomsV2 = ({
	roomsNames,
	minChildAge,
	maxChildAge,
	colorSecondary,
	errors,
	maxRooms,
	maxGuestsPerRoom,
	passengersRestrictions,
	childrenAges,
	truncate,
	roomsNumber,
	isSecondaryTemplate,
	mobileFieldStep,
	traductions,
	register,
	watch,
	setValue,
	getValues,
	trigger,
	nextCallBack,
	backCallBack,
	onModalInputsHandler,
	onChangeAgeHandler,
	addOrRemoveRoomsComponent,
}: RoomsV2Props): JSX.Element => {
	const [showRoomsStep, setShowRoomsStep] = useState<boolean | undefined>(undefined);
	const [roomsWithErrors, setRoomsWithErrors] = useState<string[]>();
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const isMobile: boolean = windowWidth <= responsiveSize;
	const customTraductionsLabels: CustomTraductionsLabelsPassengersFieldInterface = useMemo((): CustomTraductionsLabelsPassengersFieldInterface => {
		return {
			adultsDescription: traductions('overEighteenYears') || 'overEighteenYears',
			childrenDescription: traductions('betweenOneAndSeventeenYears') || 'betweenOneAndSeventeenYears',
		};
	}, []);

	const handleRoomsValidate = useCallback(() => {
		const errorKeys = Object.keys(errors);
		const roomsErrors: string[] = [];
		for (const room of roomsNames) {
			if (errorKeys.some(key => key.includes(room.name))) {
				roomsErrors.push(room.id);
			}
		}

		setRoomsWithErrors(roomsErrors);
	}, [errors]);

	useEffect(() => {
		const findRoomsField: RoomNameHotelsSearchFormInterface | undefined = roomsNames.find(
			(room: RoomNameHotelsSearchFormInterface) => room.id === mobileFieldStep,
		);

		if (findRoomsField !== undefined) {
			setShowRoomsStep(true);
		} else {
			setShowRoomsStep(false);
		}
	}, [mobileFieldStep, roomsNames]);

	const errorKeys = Object.keys(errors).join(',');
	useEffect(() => {
		handleRoomsValidate();
	}, [errorKeys]);

	const clickNextHandler = useCallback(async () => {
		if (roomsNames.length) {
			const isValid = await trigger();
			if (nextCallBack && isValid) {
				const prepareCallBack: FieldButtonEventHandlerInterface = {
					id: SearchFieldsNames.rooms,
				};

				nextCallBack(prepareCallBack);
			} else {
				handleRoomsValidate();
			}
		}
	}, [roomsNames, nextCallBack, trigger]);

	const agesFieldsElements = (room: RoomNameHotelsSearchFormInterface, roomIndex: number): JSX.Element[] | undefined => {
		if (childrenAges[roomIndex] !== undefined && childrenAges[roomIndex].length > 0) {
			return childrenAges[roomIndex].map((age: number, ageIndex: number, ages: number[]) => {
				return (
					<AgeInput
						key={`${room.id}_${roomIndex}_${ageIndex}`}
						defaultValue={age.toString() ?? minChildAge.toString()}
						age={age > 0 ? age : ''}
						ageIndex={ageIndex}
						roomIndex={roomIndex}
						name={`${room.name}_${roomIndex}_${ageIndex}`}
						onChangeAgeHandler={onChangeAgeHandler}
						register={register}
						setValue={setValue}
						getValues={getValues}
						minAge={minChildAge ?? 1}
						maxAge={maxChildAge ?? 17}
						traductionMaxAgeRestriction={traductions('maximumAgeRestrictionMessageForChildren')}
						traductionMinAgeRestriction={traductions('messageMinimumAgeRestrictionForChildren')}
						errors={errors}
						isSecondaryTemplate={!!isSecondaryTemplate}
						truncate={truncate}
						colorSecondary={colorSecondary ?? '#878787'}
						isLastPosition={ages.length === ageIndex + 1}
						id={room.id}
						arrowsCountainerClassName={'hidden md:flex'}
						traductions={traductions}
						required={roomIndex < roomsNumber}
						className={`hotelsSearchForm__age ${
							childrenAges[roomIndex].length === 1 ? 'w-full md:max-w-[120px]' : 'w-auto min-w-[100px] md:max-w-[120px]'
						}`}
					/>
				);
			});
		} else {
			return undefined;
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`hotelsSearchForm__room-v2 w-full md:bg-transparent relative max-h-screen md:max-h-[none] h-screen md:h-auto left-0 top-0 flex flex-col justify-between ${
					showRoomsStep ? 'z-50' : 'z-0'
				} md:z-auto`}
			>
				<div className='hotelsSearchForm__roomFields flex flex-col gap-5'>
					{roomsNames.map((room: RoomNameHotelsSearchFormInterface, index: number) => {
						return (
							<div
								className={`hotelsSearchForm__roomFields flex flex-row flex-wrap relative ${index < roomsNumber ? 'flex' : 'hidden'}`}
								key={room.id}
							>
								<PassengersFieldV2
									name={room.name}
									traductions={traductions}
									title={`${traductions('room')} ${index + 1}`}
									placeHolder={traductions('adultsAndChildren') || 'adultsAndChildren'}
									backButtonTraduction={traductions('back') || 'back'}
									nextButtonTraduction={traductions('next') || 'next'}
									restrictions={passengersRestrictions}
									useBackButton={true}
									required={index < roomsNumber}
									colorSecondary={colorSecondary}
									className={'md:w-5/12 md:pr-4 isSecondaryTemplate'}
									desktopModalClassName='top-[78px]'
									register={register}
									watch={watch}
									setValue={setValue}
									getValues={getValues}
									errors={errors}
									index={room.id}
									visibleTitle={true}
									disabledInputs={{
										infants: true,
									}}
									customTraductionsLabels={customTraductionsLabels}
									onModalInputsHandler={onModalInputsHandler}
									maxGuestsPerRoom={maxGuestsPerRoom}
									maxRooms={maxRooms}
									inputContainerClassName={'pt-[25px] pt-[2px] md:py-0 md:px-0'}
									isSecondaryTemplate={isSecondaryTemplate}
									iconClassName={'iconUserPco'}
									truncate={true}
									forceError={roomsWithErrors?.includes(room.id) && isMobile}
								>
									<div className={`hotelsSearchForm__agesFieldsMobile w-full gap-8 px-4 md:hidden ${agesFieldsElements(room, index) ? 'py-4' : ''}`}>
										{agesFieldsElements(room, index)}
									</div>
								</PassengersFieldV2>
								<div className={'hotelsSearchForm__agesFields md:w-7/12 md:pl-4 hidden md:flex jus items-start flex-wrap gap-8'}>
									{agesFieldsElements(room, index)}
								</div>
							</div>
						);
					})}
				</div>
				<div className='hotelsSearchForm__rooms-actions'>
					<div className='md:hidden'>{addOrRemoveRoomsComponent}</div>
					<div className='w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid'>
						<Button
							onClick={clickNextHandler}
							type='button'
							customClass={`hotelsSearchForm__rooms-nextButton my-[15px] w-full flex items-center justify-center text-base text-white font-family-regular ${
								isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'
							}`}
							styles={{
								background: colorSecondary,
							}}
							text={traductions('next') || 'next'}
						/>
						<Button
							onClick={backCallBack}
							type='button'
							customClass={`hotelsSearchForm__rooms-backButton my-0 py-0 w-full flex items-center justify-center text-base rounded-[10px] bg-transparent ${
								isSecondaryTemplate ? 'rounded-full border border-gray-300' : 'rounded-[10px]'
							}`}
							styles={{
								color: colorSecondary,
							}}
							text={traductions('back') || 'back'}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default RoomsV2;
