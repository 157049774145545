import { FlightRoutePaymentsInterface } from '../../../../../../shared/interfaces/payments/flight-route.interface';
import { formatDate } from '../../../../../../shared/services/dates-managment.service';
import { DateFormat } from '../../../../../../shared/enums/date.enum';
import styles from './purchase-ticket.module.scss';
import { Fragment } from 'react';

export interface PurchaseTicketProps {
	flightPaths: FlightRoutePaymentsInterface[];
}

export const PurchaseTicket = (props: PurchaseTicketProps): JSX.Element => {
	const directPath: number = 1;
	const isPairPaths: number = 2;
	const infoPath = (item: FlightRoutePaymentsInterface, index: number): JSX.Element => {
		return (
			<Fragment key={index}>
				<div className={'font-family-regular '}>
					<h4 className={'text-right font-sans text-xs purchaseTicket__secondColor'}>{item.flightNumbers}</h4>
					<div className='flex flex-row items-center'>
						<div className='w-full'>
							<h3 className={'font-family-black font-bold text-left text-xl leading-6purchaseTicket__mainColor'}>{item.departure.code}</h3>
						</div>
						<div className='flex flex-row justify-center items-center'>
							<div className={'purchaseTicket__arrowLine'} />
							<div className={'purchaseTicket__arrowRight'} />
						</div>
						<div className='w-full'>
							<h3 className={'font-family-black font-bold text-right text-xl leading-6 purchaseTicket__mainColor'}>{item.arrival.code}</h3>
						</div>
					</div>
					<div className='flex flex-row'>
						<div className='w-full'>
							<h4 className={'text-left text-xs mb-1 text-black text-opacity-75 my-1 purchaseTicket__mainColor'}>{item.departure.cityName}</h4>
							<h4 className={'text-left text-xs italic purchaseTicket__secondColor'}>
								{formatDate(item.departure.dateTime, DateFormat.largeDate, 'es-CO', true, false)}
							</h4>
							<h4 className={'text-left text-xs italic purchaseTicket__secondColor'}>
								{formatDate(item.departure.dateTime, DateFormat.timeDate, 'es-CO', true, false)}
							</h4>
						</div>
						<div className='w-full'>
							<h4 className={'text-right text-xs mb-1 text-black text-opacity-75 my-1 purchaseTicket__mainColor'}>{item.arrival.cityName}</h4>
							<h4 className={'text-right text-xs italic purchaseTicket__secondColor'}>
								{formatDate(item.arrival.dateTime, DateFormat.largeDate, 'es-CO', true, false)}
							</h4>
							<h4 className={'text-right text-xs italic purchaseTicket__secondColor'}>
								{formatDate(item.arrival.dateTime, DateFormat.timeDate, 'es-CO', true, false)}
							</h4>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	const getFragments = (item: FlightRoutePaymentsInterface, index: number): JSX.Element => {
		if (props.flightPaths.length === directPath) {
			return <div className={'w-full py-6 px-8 mb-3 rounded-lg purchaseTicket'}>{infoPath(item, index)}</div>;
		}

		return (
			<div className={'flex'}>
				<div
					className={`w-full py-6 px-8  mb-3 rounded-lg  ${
						index % isPairPaths === 0 ? 'purchaseTicket__cardFragmentPair' : 'purchaseTicket__cardFragmentOdd'
					}`}
				>
					{infoPath(item, index)}
				</div>
				{index % isPairPaths === 0 ? (
					<div className={' mt-4 relative'}>
						<span className={'bg-white z-50 absolute rounded-full purchaseTicket__circuleTop'} />
						<span className={'md:my-0 purchaseTicket__line'}></span>
						<span className={'bg-white z-50 absolute rounded-full purchaseTicket__circuleBottom'} />
					</div>
				) : null}
			</div>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='w-full relative flex '>
				<div className={'w-full py-5 px-2 rounded-xl flex items-center overflow-x-auto grid grid-cols-2 '}>
					{props.flightPaths.map((item: FlightRoutePaymentsInterface, index: number) => {
						return getFragments(item, index);
					})}
				</div>
			</div>
		</>
	);
};

export default PurchaseTicket;
