/* eslint-disable react/prop-types */
import { DisneyParkFareGroup, DisneyParkSearchResult } from '../../shared/interfaces';
import { ModelTypeEnum, ScrollHandlerType } from '../../../../shared/enums';
import DisneyFareCard from '../disney-fare-card/disney-fare-card';
import styles from './disney-recommendation-detail.module.scss';
import { addAlphaToColor } from '../../../../shared/services';
import { ScrollHandlers } from '../../../../shared/hooks';
import { translateDay } from '../../../../shared/utils';
import { Backdrop } from '../../../ui-elements';
import { useRef, useState } from 'react';

export interface DisneyRecommendationDetailProps {
	closeCallback?: (disneyDetail?: DisneyParkSearchResult) => void;
	disneyDetail: DisneyParkSearchResult;
	t: (value: string) => string;
	colorPrimary?: string;
	colorSecondary?: string;
	disabledButtons: boolean;
	isAresLookAndFeel?: boolean;
}

export function DisneyRecommendationDetail({
	t,
	colorPrimary,
	colorSecondary,
	closeCallback,
	disneyDetail,
	disabledButtons,
	isAresLookAndFeel,
}: Readonly<DisneyRecommendationDetailProps>): JSX.Element {
	const alphaColorPrimary: string | undefined = colorPrimary ? addAlphaToColor(colorPrimary, 0.25) : undefined;
	const { fareGroup, businessModel } = disneyDetail;
	const [disneyParkFareGroup, setDisneyParkFareGroup] = useState<DisneyParkFareGroup[]>(fareGroup);
	const scrollHandlers: ScrollHandlers = new ScrollHandlers();
	const fareContainerRef = useRef(null);
	const moreThatTwoFares: number = 2;
	const useAsFontWeight: string = isAresLookAndFeel ? 'font-medium' : 'font-bold';
	const useAsAresFontColor: string = '#000000';
	const moveScroll = (type: ScrollHandlerType): void => {
		const { current } = fareContainerRef;
		if (!current) {
			return;
		}

		scrollHandlers.ScrollManualMovement(current as unknown as HTMLDivElement, type, '.recommendation-detail__fares');
	};

	const onSelected = (disneyFareGroup: DisneyParkFareGroup): void => {
		setDisneyParkFareGroup(
			disneyParkFareGroup.map((fareGroup: DisneyParkFareGroup) => {
				return {
					...fareGroup,
					isRecommended: disneyFareGroup === fareGroup,
				};
			}),
		);
	};

	const fareGroupRecommended = (): DisneyParkFareGroup | undefined => {
		return disneyParkFareGroup.find((fareGroup: DisneyParkFareGroup) => fareGroup.isRecommended);
	};

	const isRangeDay = (disneyFareGroup?: DisneyParkFareGroup): boolean => {
		const rangeDay: number = 1;
		if (!disneyFareGroup) {
			return false;
		}

		return disneyFareGroup.durationDays + disneyFareGroup.usageBufferDays > rangeDay;
	};

	const RangeDay = (): JSX.Element => {
		return (
			<>
				{fareGroupRecommended()?.usageDate.startDateFormated} {t('to').toLowerCase()} {fareGroupRecommended()?.usageDate.endDateFormated}
			</>
		);
	};

	const RangeDayText = (): JSX.Element => {
		const theLowerCase: string = t('the').toLowerCase();
		return (
			<>
				{disneyDetail.information.durationDays} {translateDay(t, disneyDetail.information.durationDays).toLowerCase()} {t('toVisitParks')}{' '}
				{isRangeDay(fareGroupRecommended()) ? t('comeIn').toLowerCase() + ' ' + theLowerCase : theLowerCase}{' '}
			</>
		);
	};

	return (
		<>
			<Backdrop
				show={true}
				onClick={() => closeCallback && closeCallback()}
				zIndex={80}
				backgroundColor={isAresLookAndFeel ? colorSecondary : alphaColorPrimary}
				opacity={0.5}
			/>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='recommendation-detail z-[100] flex justify-end fixed right-0 top-0 scroll-smooth max-h-[100vh]  overflow-y-scroll'>
				<div className='recommendation-detail__container w-full bg-white py-[20px]  px-[25px] md:px-[55px] h-full'>
					<span onClick={() => closeCallback && closeCallback()} className='closeIco text-gray-500 text-[20px]'></span>
					<div className='recommendation-detail__airline flex items-center'></div>

					<div className='recommendation-detail__scales pt-[40px]'>
						<div className='flex-col mb-5'>
							<p className={`text-base ${useAsFontWeight}`} style={{ color: isAresLookAndFeel ? useAsAresFontColor : colorPrimary }}>
								{t('currentSelection')}
							</p>
							<p className={`text-2xl ${useAsFontWeight} mb-3`} style={{ color: isAresLookAndFeel ? useAsAresFontColor : colorSecondary }}>
								{t('fareOf')} {disneyDetail.information.durationDays} {translateDay(t, disneyDetail.information.durationDays).toLowerCase()}
							</p>
						</div>
					</div>

					<div className='recomendation-detail__seeDetails'>
						<div className='flex flex-col mb-3'>
							<p className={useAsFontWeight}>{t('validTicketDate')}</p>
							<p className='text-xs'>
								{t('yourTicketsAreValid')} <RangeDayText />
								{isRangeDay(fareGroupRecommended()) ? <RangeDay /> : fareGroupRecommended()?.usageDate.startDateFormated}
								{', ' + t('subjectToAvailability')}
							</p>
						</div>
					</div>

					<div>
						<p className={`text-2xl ${useAsFontWeight} mt-8`} style={{ color: isAresLookAndFeel ? useAsAresFontColor : colorPrimary }}>
							{t('improveYourEntryType')}
						</p>
						<p className='text-base' style={{ color: isAresLookAndFeel ? '#4D4D4F' : '#7A8083' }}>
							{t('visitAParkOrSeveralParksPerDay')} <span className={useAsFontWeight}>{t('parksWithMoreFun')}</span>
						</p>
					</div>

					<div className='recommendation-detail__fareContainer relative'>
						{fareGroup.length > moreThatTwoFares && (
							<span
								onClick={() => moveScroll(ScrollHandlerType.left)}
								className='recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute left-0 chevronLeftIco'
							></span>
						)}

						<div ref={fareContainerRef} className='recommendation-detail__scroll overflow-x-scroll lg:overflow-x-auto'>
							<div className={`recommendation-detail__fares ${fareGroup.length === 1 ? 'justify-center' : ''} my-4 flex relative`}>
								{disneyParkFareGroup.map((fareGroup: DisneyParkFareGroup, index: number) => (
									<div className='mr-1 mb-4 flex' key={index}>
										<DisneyFareCard
											t={t}
											colorPrimary={colorPrimary}
											colorSecondary={colorSecondary}
											useAlphaBackground={!fareGroup.isRecommended}
											fareGroup={fareGroup}
											businessModel={businessModel as ModelTypeEnum}
											markedRecommendation={(disneyFare: DisneyParkFareGroup) => {
												disneyDetail.fareGroup.forEach((fare: DisneyParkFareGroup, fareIndex: number) => {
													if (fareIndex === index) {
														disneyDetail.fareGroup[index] = disneyFare;

														return;
													}

													fare.isSelected = false;
												});

												closeCallback && closeCallback(disneyDetail);
											}}
											onSelected={onSelected}
											disabled={disabledButtons}
											isAresLookAndFeel={isAresLookAndFeel}
										/>
									</div>
								))}
							</div>
						</div>

						{fareGroup.length > moreThatTwoFares && (
							<span
								onClick={() => moveScroll(ScrollHandlerType.right)}
								className=' recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute right-0  chevronRightIco'
							></span>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default DisneyRecommendationDetail;
