import { PassengersFieldRestrictionsInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import DestinationField from '../../../shared-components/search/destination-field/destination-field';
import PassengersField from '../../../shared-components/search/passengers-field/passengers-field';
import { HotelsMobileSearchForm } from './sub-components/mobile-form/hotels-mobile-search-form';
import DatesField from '../../../shared-components/search/dates-field/dates-field';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import { ListInterface } from '../../../../shared/interfaces/list.interface';
import useHotelsSearchForm from '../../shared/hooks/useHotelsSearchForm';
import AgeInput from '../../../shared-components/age-input/age-input';
import { WidgetStyleInterface } from '../../../../shared/interfaces';
import useWindowWidth from '../../../../shared/hooks/useWidth';
import {
	UseHotelsSearchFormReturnInterface,
	HotelsSearchFormSubmitInterface,
	RoomNameHotelsSearchFormInterface,
	RoomsFieldsRestrictionsInterface,
} from '../../shared/interfaces';
import { NationalityField } from '../../../shared-components';
import { useState, useEffect, useCallback, useRef } from 'react';
import Button from '../../../ui-elements/button/button';
import styles from './hotels-search-form.module.scss';
import { RoomsV2 } from '../../components';

export interface HotelsSearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: (label: string) => string;
	cities?: ListInterface[];
	isCitiesLoading?: boolean;
	calendarRestriction?: number | undefined;
	roomsRestriction?: RoomsFieldsRestrictionsInterface;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	history: HotelsSearchFormSubmitInterface | null;
	className?: string;
	emitSubmitSearch: (data: HotelsSearchFormSubmitInterface) => void;
	emitCitiesSearch: (cityName: string) => void;
	emitShowMobileFields?: (show: boolean) => void;
	maxRooms?: number;
	maxGuestsPerRoom?: number;
	maxDaysStay?: number;
	datesMessage?: string;
	countries?: OptionSelect[];
	displayFilterField?: boolean;
	defaultOption?: OptionSelect;
	widgetStyles?: WidgetStyleInterface;
	showMobileIataField?: boolean;
}

export function HotelsSearchForm({
	colorPrimary,
	colorSecondary,
	traductions,
	cities,
	isCitiesLoading,
	calendarRestriction,
	roomsRestriction,
	passengersRestrictions,
	history,
	className,
	emitSubmitSearch,
	emitCitiesSearch,
	emitShowMobileFields,
	maxRooms,
	maxGuestsPerRoom,
	maxDaysStay,
	datesMessage,
	countries,
	displayFilterField,
	defaultOption,
	widgetStyles,
	showMobileIataField,
}: HotelsSearchFormProps): JSX.Element {
	const {
		destinationName,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		roomsNames,
		destinationId,
		datesId,
		mobileFieldStep,
		minDate,
		defaultDates,
		roomsNumber,
		showMultiActions,
		showAddRoomButtons,
		showMobileFieldsContainer,
		childrenAges,
		mobileFormHeight,
		errors,
		isValid,
		nationalitySelectionId,
		nationalitySelectionName,
		showMobileNationalities,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		showMobileFieldsSecondaryHandler,
		destinationFieldHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		roomsFieldNumberHandler,
		roomsModalInputsHandler,
		childrenAgesHandler,
		onSubmit,
		inputRefs,
	}: UseHotelsSearchFormReturnInterface = useHotelsSearchForm({
		history,
		calendarRestriction,
		roomsRestriction,
		maxRooms: maxRooms || 2,
		countries,
		traductions,
		emitSubmitSearch,
		emitCitiesSearch,
		emitShowMobileFields,
		displayFilterField,
		widgetStyles,
		showMobileIataField,
	});

	const minChildAge: number = 1;
	const maxChildAge: number = 17;
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement | null>(null);
	const [initDefaultOption, setInitDefaultOption] = useState<OptionSelect>();
	const { isSecondaryTemplate, isEnabledTruncatedInputs, isTertiaryTemplate } = widgetStyles || {};
	const onChangeAgeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>, roomIndex: number, ageIndex: number) => {
		childrenAgesHandler(event, roomIndex, ageIndex, minChildAge, maxChildAge);

		trigger(event.target.name).catch(error => console.error(error));
	}, []);

	const handleSubmitButtonClick = (): void => {
		if (formRef.current) {
			formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
	};

	useEffect(() => {
		setInitDefaultOption(defaultOption);
	}, [defaultOption]);

	useEffect(() => {
		setIsMobile(windowWidth < responsiveSize);
	}, [windowWidth]);

	const MultiOptionsDefault = (): JSX.Element => {
		return (
			<>
				<button
					onClick={() => roomsFieldNumberHandler(-1)}
					disabled={roomsNumber <= 1}
					className={`hotelsSearchForm__removeRoomBtn minusIco !p-0 text-base font-bold mr-0 md:mr-[25px] mb-[5px] md:mb-0 ${
						roomsNumber <= 1 ? 'text-[#C3C7CE]' : 'text-black md:text-white'
					}
						${isTertiaryTemplate && roomsNumber > 1 ? '!text-[#4D4D4F] font-medium' : ''}
					`}
					type='button'
				>
					<span className='pl-[10px]'>{traductions('removeRoom') || 'removeRoom'}</span>
				</button>
				<button
					onClick={() => roomsFieldNumberHandler(1)}
					disabled={roomsNumber >= roomsNames.length}
					className={`hotelsSearchForm__addRoomBtn plusIco  !p-0 text-base font-bold ${
						roomsNumber >= roomsNames.length ? 'text-[#C3C7CE]' : 'text-black md:text-white'
					}
						${isTertiaryTemplate && roomsNumber < roomsNames.length ? '!text-[#4D4D4F] font-medium' : ''}
					`}
					type='button'
				>
					<span className='pl-[10px]'>{traductions('addRoom') || 'addRoom'}</span>
				</button>
			</>
		);
	};

	const MultiOptionsSecondaryTemplate = (className: string): JSX.Element => {
		return (
			<div
				className={`hotelsSearchForm__multiActions-btns w-full flex justify-center md:flex-row items-end md:items-center md:w-7/12 md:pl-4 md:justify-between md:mt-3 ${
					className || ''
				}`}
			>
				<button
					onClick={() => roomsFieldNumberHandler(-1)}
					disabled={roomsNumber <= 1}
					className={`hotelsSearchForm__removeRoomBtn !p-0 text-base font-bold mr-0 md:mr-[25px]  md:mb-0 ${
						roomsNumber <= 1 ? 'hidden' : 'text-[#C3C7CE]'
					}`}
					type='button'
				>
					<span className='iconSubstractionSecondary'></span>
					<span className='pl-[10px]'>{traductions('removeRoom') || 'removeRoom'}</span>
				</button>
				<button
					onClick={() => roomsFieldNumberHandler(1)}
					disabled={roomsNumber >= roomsNames.length}
					className={`hotelsSearchForm__addRoomBtn !p-0 text-base font-bold ${roomsNumber >= roomsNames.length ? 'hidden' : 'text-[#C3C7CE]'}`}
					type='button'
				>
					<span className='iconSumSecondary'></span>
					<span className='pl-[10px]'>{traductions('addRoom') || 'addRoom'}</span>
				</button>
				{isSecondaryTemplate && (
					<div className={'hotelsSearchForm__footer md:relative md:w-3/5 hidden md:flex '}>
						<Button
							type='submit'
							customClass='hotelsSearchForm__searchBtn rounded-full text-white w-full text-[21px] items-center'
							content={
								<>
									{!isSecondaryTemplate && <span className='searchIco text-[25px] font-extrabold' />}
									<span className='text-[16px]'>{traductions('search') || 'search'}</span>
								</>
							}
							styles={{
								background: colorSecondary || '#878787',
							}}
						/>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`hotelsSearchForm w-full flex flex-col ${className || ''} ${isSecondaryTemplate ? 'isSecondaryTemplate' : ''}`}>
				<div className='hotelsSearchForm__searchButton block md:hidden'>
					<HotelsMobileSearchForm
						traductions={traductions}
						destinationId={destinationId}
						destinationName={destinationName}
						datesId={datesId}
						datesName={datesName}
						nationalitySelectionId={nationalitySelectionId}
						nationalitySelectionName={nationalitySelectionName}
						roomsNames={roomsNames}
						showMobileFieldsContainerHandler={showMobileFieldsSecondaryHandler}
						customClass='hotelsSearchForm__searchButton__secondary'
						isSecondaryTemplate={!!widgetStyles?.isSecondaryTemplate}
						isTertiaryTemplate={!!widgetStyles?.isTertiaryTemplate}
						colorSecondary={colorSecondary ?? ''}
						watch={watch}
						displayFilterFields={displayFilterField}
					>
						<div className={'hotelsSearchForm__footer flex items-end'}>
							<Button
								type='submit'
								customClass={`hotelsSearchForm__searchBtn text-white w-full text-[21px] items-center
									${!isValid ? 'disabled cursor-not-allowed ' : ''}
									${widgetStyles?.isTertiaryTemplate ? 'tertiary__shadow' : 'rounded-full'}`}
								content={<span className={'text-base !text-[14px]'}>{traductions('search') || 'search'}</span>}
								styles={{
									background: isValid ? colorSecondary ?? '#878787' : '#D1D1D1 ',
									color: isValid ? '' : '#9B9B9B',
									pointerEvents: !isValid ? 'none' : 'auto',
								}}
								disabled={!isValid}
								onClick={handleSubmitButtonClick}
							/>
						</div>
					</HotelsMobileSearchForm>
				</div>
				<form
					ref={formRef}
					onSubmit={onSubmit}
					className={`hotelsSearchForm__form w-full top-0 left-0 md:flex flex-col ${
						showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
					}
					${widgetStyles?.isTertiaryTemplate ? 'z-[2] md:z-auto' : ''}`}
					style={{
						height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
					}}
				>
					<div
						className={`flightsSearchForm__header block md:hidden ${isSecondaryTemplate ? 'py-3 px-6' : 'py-2 px-6 flex justify-end'}`}
						style={{
							backgroundColor: '#ffffff',
						}}
					>
						<button
							onClick={() => showMobileFieldsContainerHandler(false)}
							className={`flightsSearchForm__backBtn  !p-0
								${widgetStyles?.isSecondaryTemplate ? 'text-sm text-[#475467] font-semibold chevronLeftIco' : 'icon-close-secondary text-base font-bold'}
								`}
							type='button'
						>
							{isSecondaryTemplate && <span className='pl-2'>{`${traductions('comeBack') || 'comeBack'}`}</span>}
						</button>
					</div>
					<div
						className={
							'hotelsSearchForm__body bg-white md:bg-transparent left-0 md:top-0 relative md:h-auto px-0 md:pt-0 md:rounded-t-none pt-0 top-0'
						}
					>
						<div className='hotelsSearchForm__content relative h-full w-full'>
							<div className={'hotelsSearchForm__firstRow relative flex flex-row flex-wrap'}>
								<div className={`hotelsSearchForm__destination w-full md:pr-4 ${displayFilterField ? 'md:w-5/12' : 'md:w-7/12'}`}>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('destination') || 'destination'}
										</span>
									)}
									<DestinationField
										inputRefs={inputRefs}
										fieldId={destinationId}
										name={destinationName}
										hiddenName={destinationHiddenName}
										iconClassName={isSecondaryTemplate ? 'iconMarkerSecondary text-[#98A2B3]' : 'pinIco'}
										title={traductions('destination') || 'destination'}
										placeHolder={traductions('selectDestination') || 'selectDestination'}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										errorRequiredFieldTraduction={traductions('requiredField') || 'requiredField'}
										showSpinner={isCitiesLoading}
										colorSecondary={colorSecondary}
										className={`${mobileFieldStep === destinationId && !showMobileNationalities ? 'z-50' : 'z-0'} md:z-auto  
											${isTertiaryTemplate ? 'border-0 md:rounded-none md:border md:border-solid border-[#BEBEBE]' : ''}`}
										required={true}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										useBackButton={false}
										onChange={destinationFieldHandler}
										onShowDestinations={() => undefined}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										isAutomaticHandler={isMobile}
										destinations={cities}
										truncate={isEnabledTruncatedInputs}
										isSecondaryTemplate={isSecondaryTemplate}
										isTertiaryTemplate={isTertiaryTemplate}
										visibleTitle={isSecondaryTemplate}
										isMobile={isMobile}
									/>
								</div>

								<div className={`hotelsSearchForm__dates w-full relative  ${displayFilterField ? 'md:w-4/12 md:px-4' : 'md:w-5/12 md:pl-4'}`}>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('selectYourDates') || 'selectYourDates'}
										</span>
									)}
									<DatesField
										fieldId={datesId}
										title={traductions('selectYourDates') || 'selectYourDates'}
										placeHolder={
											isSecondaryTemplate ? traductions('selectAnOption') || 'selectAnOption' : traductions('selectYourDates') || 'selectYourDates'
										}
										name={datesName}
										hiddenName={datesHiddenName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										errorRequiredFieldTraduction={traductions('requiredField') || 'requiredField'}
										buttonText={isSecondaryTemplate || isTertiaryTemplate ? traductions('apply') || 'apply' : traductions('ready') || 'ready'}
										cancelText={traductions('cancel') || 'cancel'}
										type={DatesFieldTypes.startEndDates}
										config={{
											date: true,
											time: false,
										}}
										minDate={minDate}
										defaultDates={defaultDates}
										useBackButton={true}
										iconClassName={`${isSecondaryTemplate ? 'iconCalendarSecondary text-[#98A2B3]' : 'searchCalendarIco'}
											${isTertiaryTemplate ? 'iconCalendarDepartureTertiary' : ''}
										`}
										required={true}
										colorPrimary={colorPrimary}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										watch={watch}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										onChange={datesHandler}
										onShowDates={() => undefined}
										datesMessage={datesMessage}
										isSecondaryTemplate={isSecondaryTemplate}
										isTertiaryTemplate={isTertiaryTemplate}
										visibleTitle={isSecondaryTemplate}
									/>
								</div>
								{displayFilterField && (
									<div className='hotelsSearchForm__nationality relative w-full md:w-3/12 md:pl-4'>
										{isTertiaryTemplate && (
											<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
												{traductions('selectYourNationality') || 'selectYourNationality'}
											</span>
										)}
										<NationalityField
											fieldId={nationalitySelectionId}
											name={nationalitySelectionName}
											title={traductions('selectYourNationality') || 'selectYourNationality'}
											placeHolder={traductions('country') || 'Country'}
											searchPlaceHolder={
												isSecondaryTemplate
													? traductions('selectYourNationality') || 'selectYourNationality'
													: traductions('searchYourCountry') || 'searchYourCountry'
											}
											nextButtonTraduction={traductions('next') || 'next'}
											backButtonTraduction={traductions('back') || 'back'}
											colorSecondary={colorSecondary}
											className={`${mobileFieldStep === nationalitySelectionId ? 'z-50' : 'z-0'} w-full`}
											required={false}
											register={register}
											setValue={setValue}
											getValues={getValues}
											trigger={trigger}
											errors={errors}
											useBackButton={true}
											visibleTitle={true}
											useLightTemplate={false}
											nextCallBack={nextMobileFieldHandler}
											backCallBack={backMobileFieldHandler}
											options={countries}
											defaultValue={initDefaultOption}
											isSecondaryTemplate={isSecondaryTemplate}
											isTertiaryTemplate={isTertiaryTemplate}
											truncate={isEnabledTruncatedInputs}
											showMobileActions={true}
										/>
									</div>
								)}
							</div>
							<div
								className={`hotelsSearchForm__lastRow flex flex-row flex-wrap items-end ${isSecondaryTemplate ? 'md:mt-8' : 'relative md:mt-[15px]'}`}
							>
								{isSecondaryTemplate ? (
									<div className={'w-full flex-col flex-wrap'}>
										<RoomsV2
											roomsNames={roomsNames}
											minChildAge={1}
											maxChildAge={17}
											colorSecondary={colorSecondary || '#878787'}
											errors={errors}
											maxRooms={maxRooms || 2}
											maxGuestsPerRoom={maxGuestsPerRoom}
											traductions={traductions}
											register={register}
											watch={watch}
											setValue={setValue}
											getValues={getValues}
											trigger={trigger}
											nextCallBack={nextMobileFieldHandler}
											backCallBack={backMobileFieldHandler}
											onModalInputsHandler={roomsModalInputsHandler}
											passengersRestrictions={passengersRestrictions}
											onChangeAgeHandler={onChangeAgeHandler}
											truncate={!!widgetStyles?.isEnabledTruncatedInputs}
											isSecondaryTemplate={!!widgetStyles?.isSecondaryTemplate}
											childrenAges={childrenAges}
											roomsNumber={roomsNumber}
											mobileFieldStep={mobileFieldStep}
											addOrRemoveRoomsComponent={MultiOptionsSecondaryTemplate('md:hidden px-[14px] py-2 mb-3')}
										/>
									</div>
								) : (
									<div className={'hotelsSearchForm__rooms w-full flex-col flex-wrap md:w-4/5 md:pr-[15px]'}>
										{roomsNames.map((names: RoomNameHotelsSearchFormInterface, roomIndex: number) => {
											const agesFieldsElements: JSX.Element[] | boolean =
												childrenAges[roomIndex] !== undefined &&
												childrenAges[roomIndex]?.map((roomAges: number, ageIndex: number, ages: number[]) => (
													<AgeInput
														key={`${names.id}_${roomIndex}_${ageIndex}`}
														age={roomAges}
														ageIndex={ageIndex}
														roomIndex={roomIndex}
														name={`${names.name}_${roomIndex}_${ageIndex}`}
														onChangeAgeHandler={onChangeAgeHandler}
														register={register}
														setValue={setValue}
														getValues={getValues}
														minAge={1}
														maxAge={17}
														traductionMaxAgeRestriction={traductions('maximumAgeRestrictionMessageForChildren')}
														traductionMinAgeRestriction={traductions('messageMinimumAgeRestrictionForChildren')}
														errors={errors}
														isSecondaryTemplate={!!isSecondaryTemplate}
														truncate={!!isEnabledTruncatedInputs}
														colorSecondary={colorSecondary ?? '#878787'}
														isLastPosition={ages.length === ageIndex + 1}
														id={names.id}
														arrowsCountainerClassName={'hidden md:flex'}
														traductions={traductions}
														required={true}
														className={'hotelsSearchForm__age'}
														isTertiaryTemplate={isTertiaryTemplate}
													/>
												));

											return (
												<div
													key={names.id}
													className={`hotelsSearchForm__room w-full relative md:pr-[8px] flex-col ${roomIndex < roomsNumber ? 'flex' : 'hidden'} ${
														mobileFieldStep === names.id ? '' : 'h-0 md:h-auto'
													} ${roomIndex ? 'md:mt-[20px]' : ''}`}
													style={{ marginTop: isTertiaryTemplate && roomIndex ? '15px' : '' }}
												>
													{showAddRoomButtons && (
														<p
															className={`hotelsSearchForm__roomTitle w-full absolute md:relative text-base text-[#616161] md:text-white pb-[10px] pl-[40px] md:pl-0 md:z-auto top-[3px] md:top-0 left-0 md:block ${
																mobileFieldStep === names.id ? 'z-[51]' : 'z-0'
															}
															${isTertiaryTemplate ? '!text-[#4D4D4F] font-medium !text-[14px]' : ''}`}
														>{`${traductions('room') || 'room'} ${roomIndex + 1}`}</p>
													)}
													<div className='hotelsSearchForm__roomFields flex flex-row flex-wrap'>
														<PassengersField
															fieldId={names.id}
															traductions={traductions}
															title={traductions('adultsAndChildren') || 'adultsAndChildren'}
															placeHolder={traductions('adultsAndChildren') || 'adultsAndChildren'}
															name={names.name}
															backButtonTraduction={traductions('back') || 'back'}
															nextButtonTraduction={traductions('next') || 'next'}
															restrictions={passengersRestrictions}
															useBackButton={true}
															required={roomIndex < roomsNumber}
															colorSecondary={colorSecondary}
															className={`w-full md:w-3/5 ${mobileFieldStep === names.id ? 'z-50' : 'z-0'} md:z-auto md:h-[65px] ${
																roomIndex === roomsNumber - 1 ? 'hotelsSearchForm__lastRoom' : ''
															}`}
															desktopModalClassName={
																isTertiaryTemplate ? 'shadow-xl md:flex-col top-[75px] md:w-full md:rounded-t-none px-[15px]' : ''
															}
															register={register}
															watch={watch}
															setValue={setValue}
															getValues={getValues}
															trigger={trigger}
															errors={errors}
															nextCallBack={nextMobileFieldHandler}
															backCallBack={backMobileFieldHandler}
															visibleTitle={true}
															iconClassName='searchAdultIco'
															isTertiaryTemplate={isTertiaryTemplate}
															disabledInputs={{
																infants: true,
															}}
															onModalInputsHandler={roomsModalInputsHandler}
															maxGuestsPerRoom={maxGuestsPerRoom}
															maxRooms={maxRooms}
														>
															<div className={'hotelsSearchForm__agesFields w-full flex flex-col md:hidden'}>{agesFieldsElements}</div>
														</PassengersField>
														{agesFieldsElements}
													</div>
												</div>
											);
										})}
									</div>
								)}

								<div
									className={`hotelsSearchForm__footer md:relative md:w-1/5 hidden 
									${isSecondaryTemplate ? '' : 'md:flex'}
									${isTertiaryTemplate ? 'items-end' : 'md:h-[65px]'}
								`}
								>
									<Button
										type='submit'
										customClass={`hotelsSearchForm__searchBtn text-white w-full text-[21px] items-center
											${isTertiaryTemplate ? 'rounded-none tertiary__shadow' : 'rounded-lg'}
											${isTertiaryTemplate && !isValid ? 'tertiary__disabled' : ''} `}
										content={
											<>
												{isTertiaryTemplate ? null : <span className='searchIco text-[25px] font-extrabold' />}
												<span className={`${isTertiaryTemplate ? 'text-base !text-[14px]' : 'text-[21px] font-extrabold pl-[10px]'}`}>
													{traductions('search') || 'search'}
												</span>
											</>
										}
										styles={{
											background: colorSecondary || '#878787',
											padding: isTertiaryTemplate && '11.5px 5px',
										}}
										disabled={!isValid && isTertiaryTemplate}
									/>
								</div>
							</div>
							<div
								className={`${
									showAddRoomButtons
										? `hotelsSearchForm__multiActions w-full absolute md:relative flex-col md:flex-row	 flex-wrap md:justify-end items-end md:items-center md:mt-[20px] z-50 md:z-auto md:top-0 left-0 md:flex ${
											showMultiActions ? 'flex px-[40px] md:px-0' : 'hidden'
										  }`
										: 'hidden'
								}`}
							>
								{isSecondaryTemplate ? MultiOptionsSecondaryTemplate('hidden md:flex') : <MultiOptionsDefault />}
							</div>
							<div className={`block md:hidden w-full z-40 bg-white absolute left-0  h-screen ${isSecondaryTemplate ? 'top-[0px]' : 'top-[20px]'}`} />
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default HotelsSearchForm;
