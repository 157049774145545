import { PassangerType } from './passanger-type';
import { Fees, Taxes } from './fees';

export interface Fares {
	reference: string | null;
	base: number;
	totalAmount: number;
	currency: string;
	fee: string | null;
	totalTaxes: number;
	totalBaseAmount: number;
	subTotal: number;
	grandSubTotal: number;
	grandTotal: number;
	taxesCurrency: number | null;
	equivalentAmount: number;
	equivalentAmountCurrency: string;
	taxDetail: TaxDetail[];
}

export interface TaxDetail {
	code: string;
	amount?: number;
	currency: string;
}

export class AmountFares {
	totalBaseAmount: number = 0;
	totalTaxes: number = 0;
	subTotalAmount: number = 0;
}

export interface Rate {
	amount: number;
	currency: string;
	totalMoney?: number;
	minPointsToRedeem?: number;
	taxes: number | [];
	formattedAmount: string;
	isPoints?: boolean;
	tax?: number;
	fee?: Fees;
	extraCharges?: [];
}

export interface NetRate {
	amount: number;
	currency: string;
	taxes?: Taxes[];
	tax?: number;
	fee?: Fees;
	passengerType?: PassangerType;
	extraCharges?: number[];
	formattedAmount?: string;
}
