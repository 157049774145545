import { SpecialEventsLayout } from '../../shared/enums/special-events.enum';
import PassengersQuantity from '../passengers-quantity/passengers-quantity';
import { validateCalendarRestrictions } from '../../../../shared/utils';
import { DatePickerAvailability } from '../../../shared-components';
import { SpecialEventsSearchForm } from '../../organisms';
import styles from './special-event-detail.module.scss';
import { PassangerType } from '../../../disney/shared';
import { InputEventsHandler, PassengersFieldRestrictionsInterface } from '../../../../shared/interfaces';
import { addAlphaToColor, numberToLocaleWithcurrency } from '../../../../shared/services';
import { useEffect, useState, useCallback, createContext, useContext } from 'react';
import { Backdrop, Button, IcomoonIcon, Spinner } from '../../../ui-elements';
import { DateLocales, ModelTypeEnum } from '../../../../shared/enums';
import {
	DisneyParkFareGroup,
	DisneySearchFormSubmitInterface,
	RecommendationEventSpecial,
	TicketInformationEventSpecial,
} from '../../shared/interfaces';

export interface SpecialEventDetailProps {
	closeCallback?: (disneyDetail?: RecommendationEventSpecial) => void;
	disneyDetail: RecommendationEventSpecial | undefined;
	specialEventName: string;
	traductions: (value: string) => string;
	colorPrimary?: string;
	colorSecondary?: string;
	disabledButtons: boolean;
	locale?: DateLocales;
	calendarRestriction?: number;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	emitSubmitSearch: (data: DisneySearchFormSubmitInterface) => void;
	loadingSpecialEvent: boolean;
	typeAgency: boolean;
}

export interface DisneyDetailContextType {
	disneyDetail: RecommendationEventSpecial | null;
}

const DisneyDetailContext = createContext<DisneyDetailContextType | null>(null);
export const useDisneyDetailContext = (): DisneyDetailContextType => {
	const context = useContext(DisneyDetailContext);
	if (!context) {
		return { disneyDetail: null };
	}

	return context;
};

export function SpecialEventDetail({
	traductions,
	colorPrimary,
	colorSecondary,
	closeCallback,
	disneyDetail,
	specialEventName,
	disabledButtons,
	locale,
	calendarRestriction,
	passengersRestrictions,
	loadingSpecialEvent,
	typeAgency,
	emitSubmitSearch,
}: SpecialEventDetailProps): JSX.Element {
	const firstPosition: number = 0;
	const defaultValue: number = 0;
	const numberDays: number = 1;
	const [initialDate, setInitialDate] = useState<Date>(new Date());
	const [availabilityDates, setAvailabilityDates] = useState<Date[]>([]);
	const [disneyParkFareGroup, setDisneyParkFareGroup] = useState<DisneyParkFareGroup>();
	const alphaColorPrimary: string | undefined = colorPrimary ? addAlphaToColor(colorPrimary, 0.25) : undefined;
	const [openDetail, setOpenDetail] = useState<number[]>([]);
	const nameSpecialevents = (): string => {
		if (disneyDetail) {
			return disneyDetail?.fareGroup[firstPosition]?.name;
		} else {
			return specialEventName;
		}
	};

	useEffect(() => {
		if (disneyDetail) {
			const defaultValueSpecialEvent: boolean = true;
			disneyDetail.isSpecialEvent = defaultValueSpecialEvent;

			init();
		}
	}, [disneyDetail]);

	const init = (): void => {
		if (disneyDetail?.fareGroup) {
			const firstRecomendation: DisneyParkFareGroup | undefined = disneyDetail.fareGroup.find(fare => {
				const fareDate: Date = new Date(fare.usageDate.startDate);
				fareDate.setHours(0, 0, 0, 0);

				if (!validateCalendarRestrictions(calendarRestriction || 0, newDate(fareDate, numberDays))) {
					if (fare.isRecommended) {
						return fare;
					}

					return fare;
				}

				return null;
			});

			getInitialDate(firstRecomendation, numberDays);

			const initDate = getInitialDate(firstRecomendation, numberDays);
			setInitialDate(initDate);

			const activeDates: Date[] = [];
			setDisneyParkFareGroup(firstRecomendation);

			disneyDetail?.fareGroup.forEach(fare => {
				const usageStartDate: Date = new Date(fare.usageDate.startDate);
				activeDates.push(newDate(usageStartDate, numberDays));
			});
			

			setAvailabilityDates(activeDates);
		}
	};

	const getInitialDate = (firstRecomendation: DisneyParkFareGroup | undefined, numberDays: number): Date => {
		let initDate: Date;

		if (firstRecomendation) {
			initDate = new Date(firstRecomendation.usageDate.startDate);

			initDate = newDate(initDate, numberDays);
		} else {
			const defaultDays: number = 0;
			const days: number = calendarRestriction ? calendarRestriction + numberDays : defaultDays;
			initDate = new Date();

			initDate = newDate(initDate, days);
		}

		return initDate;
	};

	const newDate = (date: Date, addDays: number): Date => {
		const newDate = new Date(date);
		newDate.setDate(newDate.getDate() + addDays);

		return newDate;
	};

	const passengerTranslation = (type: PassangerType): string => {
		const tranlations = {
			adult: traductions('olderThan10Years'),
			child: traductions('olderThan3years'),
		};

		return tranlations[type].toLowerCase() || '';
	};

	const totalValue = (disneyParkFareGroup: DisneyParkFareGroup): string => {
		const defaultValueCurrency: string = 'USD';
		switch (disneyParkFareGroup?.paymentMethod) {
			case ModelTypeEnum.POINTS:
				return `${numberToLocaleWithcurrency(disneyParkFareGroup?.fareGroupSummary?.requiredPoints ?? defaultValue)} ${
					disneyParkFareGroup?.fareGroupSummary?.pointsName ?? traductions('points')
				}`;
			case ModelTypeEnum.POINT_PLUS_MONEY:
				return `${numberToLocaleWithcurrency(disneyParkFareGroup?.fareGroupSummary?.requiredPoints ?? defaultValue)} ${
					disneyParkFareGroup?.fareGroupSummary?.pointsName ?? traductions('points')
				} + 
				${numberToLocaleWithcurrency(
		disneyParkFareGroup?.fareGroupSummary?.requiredAmount ?? defaultValue,
		disneyParkFareGroup?.fareGroupSummary?.currency ?? defaultValueCurrency,
	)} ${disneyParkFareGroup?.fareGroupSummary?.currency ?? defaultValueCurrency}`;
			default:
				return disneyParkFareGroup?.fareGroupSummary?.amountFormated ?? '';
		}
	};

	const selectedDate = useCallback(
		(inputEvent: InputEventsHandler): void => {
			const selectDate: Date = inputEvent.value as Date;
			if (disneyDetail) {
				disneyDetail.fareGroup.forEach(fare => {
					const usageDay = new Date(fare.usageDate.startDate);
					usageDay.setHours(0, 0, 0, 0);

					selectDate.setHours(0, 0, 0, 0);

					if (newDate(usageDay, numberDays).getTime() === selectDate.getTime()) {
						setDisneyParkFareGroup(fare);
					}
				});
			}
		},
		[disneyDetail, disneyParkFareGroup],
	);

	const onSelected = (disneyFareGroup: DisneyParkFareGroup): void => {
		disneyFareGroup.isRecommended = true;

		disneyFareGroup.isSelected = true;

		const disneyDetailClone: RecommendationEventSpecial = JSON.parse(JSON.stringify(disneyDetail));
		disneyDetailClone.fareGroup = [disneyFareGroup];

		closeCallback && closeCallback(disneyDetailClone);
	};

	return (
		<DisneyDetailContext.Provider value={{ disneyDetail: disneyDetail || null }}>
			<>
				<Backdrop show={true} onClick={() => closeCallback && closeCallback()} zIndex={80} backgroundColor={alphaColorPrimary} opacity={0.5} />
				<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
				<div className='specialEventDetail z-[100] flex justify-end fixed right-0 top-0 '>
					<div
						className={` ${
							loadingSpecialEvent ? 'md:w-[950px]' : 'w-full'
						} specialEventDetail__container bg-white py-[20px]  px-[25px] md:px-[55px] h-full flex`}
					>
						<div className={` ${loadingSpecialEvent ? '' : 'md:w-[55%] scroll-smooth max-h-[100vh]  overflow-y-scroll md:pr-4'}`}>
							<button
								data-testid='closeIco'
								className='closeIco text-gray-500 text-[20px] cursor-pointer'
								onClick={() => closeCallback && closeCallback()}
								onKeyDown={event => {
									if (event.key === 'Enter' || event.key === ' ') {
										event.preventDefault();

										closeCallback && closeCallback();
									}
								}}
							></button>
							<div className='specialEventDetail__airline flex items-center'></div>

							<div className={` ${loadingSpecialEvent ? 'md:w-[160%]' : ''} specialEventDetail__intro pt-[40px]`}>
								<div className='flex-col mb-5'>
									<p className='text-2xl specialEventDetail__bold mb-3 max-w-[500px]' style={{ color: colorPrimary }}>
										{nameSpecialevents()}
									</p>
									<h2 className='font-bold' style={{ color: colorSecondary }}>
										{traductions('childrenDonotNeedTicket')}
									</h2>
									<div className='specialEventsSearchForm relative pt-[10px] font-bold'>
										<h2>
											{'1.'} {traductions('selectNumberTickets')}
										</h2>
										<div>
											<SpecialEventsSearchForm
												colorPrimary={colorPrimary}
												colorSecondary={colorSecondary}
												traductions={traductions}
												passengersRestrictions={passengersRestrictions}
												emitSubmitSearch={emitSubmitSearch}
												history={null}
												layout={SpecialEventsLayout.SEARCH}
												passengerButton={traductions('calculate')}
											/>
										</div>
									</div>
								</div>
								<div></div>
							</div>
							<div className={` ${loadingSpecialEvent ? 'h-[550px]' : ''} flex relative top-[30px] justify-center`}>
								{loadingSpecialEvent ? <Spinner className={'login__spinner'} /> : ''}
							</div>
							<div className={`${loadingSpecialEvent ? 'hidden' : ''}`}>
								{disneyDetail ? (
									<>
										<div>
											<div className='specialEventDetail__calendar'>
												<div className='flex flex-col mb-3'>
													<p className='font-bold'>
														{'2.'} {traductions('selectwhenGo')}
													</p>
													<DatePickerAvailability
														locale={locale || DateLocales.es}
														onChange={selectedDate}
														colors={{
															main: colorPrimary || '#FDD008',
															second: '#494848',
															third: '#7A8083',
														}}
														name={'dateAvailability'}
														defaultData={{
															startDate: initialDate,
														}}
														disabledDates={[]}
														availabilityDates={availabilityDates}
														t={traductions}
														minDate={newDate(new Date(disneyDetail?.information?.usageDate?.startDate), numberDays)}
														maxDate={newDate(new Date(disneyDetail?.information?.usageDate?.endDate), numberDays)}
														disabledButtons={disabledButtons}
														calendarRestriction={calendarRestriction}
													/>
												</div>
											</div>
											<div className='specialEventDetail__fareContainer relative border-t-4 border-dotted'>
												<div className='flex'>
													<span className='infoCirlce2Ico mr-3 relative top-[10px]' style={{ color: colorPrimary }}></span>
													<p className='text-lg specialEventDetail__bold mb-3 max-w-[500px] relative top-[5px]'>{traductions('eventEntrance')}</p>
												</div>
												{disneyDetail && disneyDetail.fareGroup[firstPosition] && disneyDetail.fareGroup[firstPosition].description ? (
													<p dangerouslySetInnerHTML={{ __html: disneyDetail.fareGroup[firstPosition].description || '' }} />
												) : null}
											</div>
											<div className='md:mb-0 mb-[9rem]'>
												{disneyDetail?.fareGroup[firstPosition]?.ticketInformation?.map((item: TicketInformationEventSpecial, index: number) => (
													<div
														className='rounded-lg pb-[16px] bg-white shadow-md mb-[25px] mt-1 flex pt-[10px] flex-row justify-between'
														key={index}
														onClick={() => {
															if (openDetail.includes(index)) {
																setOpenDetail(openDetail.filter(i => i !== index));
															} else {
																setOpenDetail([...openDetail, index]);
															}
														}}
													>
														<div className='flex flex-col py-[5px] px-[7px]'>
															<div>
																<h2 className='font-bold'>{item.title}</h2>
															</div>
															<div className={`${!openDetail.includes(index) ? 'hidden' : 'hotels-disney-resume__payment-icon'} pt-[10px]`}>
																{item.items.map((item: string, index: number) => (
																	<div className='flex' key={index}>
																		<IcomoonIcon className='absolspante' icon='icon-bullet' fontSize={20} />
																		<p>{item}</p>
																	</div>
																))}
															</div>
														</div>
														<span className={`relative ${openDetail.includes(index) ? 'specialEventDetail__iconRotation' : 'py-[10px] px-[10px]'}`}>
															<IcomoonIcon className='absolspante' icon='icon-arrow-down' fontSize={10} color='colorSecondary' />
														</span>
													</div>
												))}
											</div>
										</div>
									</>
								) : (
									<>
										<div className='h-[40rem]'>
											<div className='flex flex-col'>
												<div className='specialEventRecommendation-badge absolute flex rounded-[30px] px-4 py-[10px] bg-[#CCD0D6]'>
													<span className='flex divide-gray-800'>
														<IcomoonIcon icon='infoCirlce2Ico font-semibold' color={colorPrimary} className='mr-2' />
														{traductions('availabilityMessage')}
													</span>
												</div>
												<Button
													content={
														disabledButtons ? (
															<Spinner className={'specialEventDetail__spinner !border-white after:!border-b-white'} />
														) : (
															<>{traductions('back')}</>
														)
													}
													onClick={() => closeCallback && closeCallback()}
													styles={{ background: colorPrimary }}
													disabled={disabledButtons}
													customClass='w-[30px] left-[40%] relative flex items-center rounded-lg text-white md:!px-[55px] my-[20px] bg-black h-[50px] top-[50px]'
												/>
											</div>
										</div>
									</>
								)}
								<div className='md:hidden fixed bottom-0 w-[100%] bg-white shadow-md mb-[25px] mt-1 flex justify-between p-4 pr-4 right-0 h-[88px]'>
									<div className='specialEventDetail__fareTaxes flex items-start flex-col-reverse my-4 relative top-[10px]'>
										<p className='specialEventRecommendation__passengerPrice text-xs flex flex-col md:flex-row'>
											<span
												data-testid='valueTotal'
												className='specialEventRecommendation__passengerTypeLabel md:ml-2 specialEventRecommendation__bold text-center md:text-start'
											>
												{disneyParkFareGroup && totalValue(disneyParkFareGroup)}
											</span>
										</p>
										<p className='specialEventRecommendation__passengerPrice text-xs flex flex-col md:flex-row'>
											<span className='specialEventRecommendation__passengerPriceLabel'>{traductions('total')}</span>
										</p>
									</div>
									<div className='specialEventDetail__fareTaxes flex items-center my-4'>
										<Button
											data-testid='specialEventDetail__button-one'
											content={
												disabledButtons ? (
													<Spinner className={'specialEventDetail__spinner !w-[30px] !h-[30px] !border-white after:!border-b-white'} />
												) : (
													<>{traductions('buy')}</>
												)
											}
											onClick={() => disneyParkFareGroup && onSelected(disneyParkFareGroup)}
											styles={{ background: colorSecondary }}
											disabled={disabledButtons}
											customClass='specialEventDetail__btn flex items-center rounded-lg text-white md:!px-[55px] my-[20px] bg-black h-[50px] w-10'
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							data-testid='specialEventDetail__fareContainer'
							className={`${
								loadingSpecialEvent ? 'hidden' : 'fixed bottom-0 specialEventDetail__fareContainer specialEventDetail__payment right-0 top-[140px]'
							}`}
						>
							<div className={`${disneyParkFareGroup?.paymentMethod === ModelTypeEnum.CASH ? 'w-[90%]' : ''} relative right-[50px]`}>
								<span className='my-4 relative left-1 font-bold'>{traductions('currentSelection')}</span>
								{!!disneyParkFareGroup?.fareGroupSummary?.feeAmount && (
									<div className='specialEventDetail__fareTaxes flex items-center flex-row-reverse my-4'>
										<p className='specialEventRecommendation__passengerPrice text-sm flex flex-col md:flex-row items-center'>
											<span className='specialEventRecommendation__passengerPriceLabel specialEventRecommendation__bold'>{traductions('taxes')}</span>
										</p>

										<p className='specialEventRecommendation__passengerPrice text-sm flex flex-col md:flex-row'>
											<span className='specialEventRecommendation__passengerTypeLabel md:ml-2 specialEventRecommendation__bold text-center md:text-start'>
												{disneyParkFareGroup.fareGroupSummary.feeAmount}
											</span>
										</p>
									</div>
								)}

								{disneyParkFareGroup && (
									<PassengersQuantity
										disneyParkFareGroup={disneyParkFareGroup}
										disneyParkFareDetail={disneyParkFareGroup.fareDetails}
										adultTranslation={passengerTranslation(PassangerType.adult)}
										childTranslation={passengerTranslation(PassangerType.child)}
										traductions={traductions}
										typeAgency={typeAgency}
									/>
								)}

								<div className='specialEventDetail__fareTaxes flex items-end flex-col-reverse my-4'>
									<p className='specialEventRecommendation__passengerPrice text-lg flex flex-col md:flex-row specialEventRecommendation__bold'>
										<span
											data-testid='valueTotal'
											className='specialEventRecommendation__passengerTypeLabel md:ml-2 specialEventRecommendation__bold text-center md:text-start'
											style={{ color: colorPrimary }}
										>
											{disneyParkFareGroup && totalValue(disneyParkFareGroup)}
										</span>
									</p>
									<p className='specialEventRecommendation__passengerPrice text-lg flex flex-col md:flex-row items-center'>
										<span
											className='specialEventRecommendation__passengerPriceLabel specialEventRecommendation__bold'
											style={{ color: colorPrimary }}
										>
											{traductions('total')}
										</span>
									</p>
								</div>

								<div className='specialEventDetail__fareTaxes flex items-end justify-end my-4'>
									<Button
										data-testid='specialEventDetail__button-two'
										content={
											disabledButtons ? (
												<Spinner className={'specialEventDetail__spinner !w-[30px] !h-[30px] !border-white after:!border-b-white'} />
											) : (
												<>{traductions('buy')}</>
											)
										}
										onClick={() => disneyParkFareGroup && onSelected(disneyParkFareGroup)}
										styles={{ background: colorSecondary }}
										disabled={disabledButtons}
										customClass='specialEventDetail__btn flex items-center rounded-lg text-white md:!px-[55px] my-[20px] bg-black h-[50px]'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</DisneyDetailContext.Provider>
	);
}

export default SpecialEventDetail;
