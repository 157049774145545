import { PaymentStepResultEventInterface } from '../../../../../shared/interfaces/payments/steps.interface';
import { InputEventsHandler } from '../../../../../shared/interfaces/inputs.interface';
import MaskInput from '../../../../ui-elements/mask-input/mask-input';
import { InputType } from '../../../../../shared/enums/inputs.enum';
import Button from '../../../../ui-elements/button/button';
import styles from './commission-payment.module.scss';

export interface CommissionPaymentProps {
	idComponent: string;
	fee: number;
	currency: string;
	maxFee: number;
	minFee: number;
	title: string;
	description: string;
	feeInputLabel: string;
	feetDisabled?: boolean;
	nextText: string;
	previousText: string;
	onChange?: (e: InputEventsHandler) => void;
	onNextClick?: (data: PaymentStepResultEventInterface) => void;
	onPreviousClick?: (data: PaymentStepResultEventInterface) => void;
}

export const CommissionPayment = (props: CommissionPaymentProps): JSX.Element => {
	const copCurrency: string = 'cop';
	const copFeeScale: number = 0;
	const otherFeeScale: number = 2;
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='flex flex-col justify-center'>
				<p className={'text-base font-family-black font-black mb-3.5 paymentCommisionAccordion__text'}>{props.title}</p>
				<p className={'text-xs font-family-regular paymentCommisionAccordion__text'}>{props.description}</p>
				<div className='flex flex-col md:flex-row items-center justify-between mt-6'>
					<MaskInput
						type={InputType.text}
						name='fee'
						label={props.feeInputLabel}
						value={String(props.fee)}
						onChange={props.onChange}
						disabled={props?.feetDisabled}
						className='flex flex-row w-full md:w-3/5 md:items-center md:pr-2 mb-2 md:mb-0'
						inputClassName={
							'w-2/3 bg-gray-100 rounded-lg text-xs font-family-regular md:mr-4 h-10 px-3 text-right paymentCommisionAccordion__feeInput'
						}
						labelClassName={'flex items-center w-1/3 text-xs font-family-regular paymentCommisionAccordion__text'}
						maskConfiguration={{
							mask: '$num',
							blocks: {
								num: {
									mask: Number,
									thousandsSeparator: ',',
									scale: props.currency.toLocaleLowerCase() !== copCurrency ? otherFeeScale : copFeeScale,
									radix: '.',
									min: props.minFee,
									max: props.maxFee,
								},
							},
						}}
					/>
					<Button
						onClick={() => (props?.onPreviousClick ? props?.onPreviousClick({ id: props.idComponent, data: props.fee }) : null)}
						text={props.previousText}
						customClass={'w-full md:w-1/5 md:mr-2 text-xs text-white rounded-lg my-2 md:my-0 paymentCommisionAccordion__button'}
					/>
					<Button
						onClick={() => (props?.onNextClick ? props?.onNextClick({ id: props.idComponent, data: props.fee }) : null)}
						text={props.nextText}
						customClass={'w-full md:w-1/5 text-xs text-white rounded-lg my-2 md:my-0 paymentCommisionAccordion__button'}
					/>
				</div>
			</div>
		</>
	);
};

CommissionPayment.defaultProps = {
	currency: 'COP',
	maxFee: 10000000000,
	minFee: 0,
};

export default CommissionPayment;
