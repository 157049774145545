import { CMSGeneralComponentInterface, CMSTabInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import { SlideInterface } from '../../../../../shared/interfaces/generics/slider.interface';
import { GenericIntroduction } from '../../generics/introductions/generic-introduction/generic-introduction';
import { CardCarSimple } from '../../../../shared-components/card-car-simple/card-car-simple';
import { ButtonLink } from '../../../../ui-elements/button-link/button-link';
import { SkeletonCarsFleets } from './skeleton/skeleton-cars-fleets';
import { useState, useEffect, useCallback } from 'react';
import styles from './cars-fleets.module.scss';

export interface CarsFleetsProps extends CMSGeneralComponentInterface {
	loading: boolean;
	customClass?: string;
	icons: string[];
	introductionClassName?: string;
	introductionContainerClassName?: string;
	tabsContainerClassName?: string;
	tabClassName?: string;
	buttonClassName?: string;
}

export function CarsFleets({
	title,
	subtitle,
	description,
	linkText,
	link,
	slides,
	tabsList,
	loading,
	customClass,
	icons,
	introductionClassName,
	tabsContainerClassName,
	tabClassName,
	introductionContainerClassName,
	buttonClassName,
}: CarsFleetsProps): JSX.Element {
	const [tapLoading, setTapLoading] = useState<boolean>(false);
	const [positionTap, setPositionTap] = useState<string>('');
	const tapClickHandler = useCallback((value: string) => {
		setTapLoading(true);

		setPositionTap(value);
	}, []);

	useEffect(() => {
		setPositionTap(tabsList && tabsList.length && tabsList[0] && tabsList[0].value ? tabsList[0].value : '');
	}, [tabsList && tabsList.length && tabsList[0]]);

	useEffect(() => {
		if (tapLoading) {
			const interval = setTimeout(() => {
				setTapLoading(false);
			}, 500);

			return () => clearTimeout(interval);
		} else {
			return () => null;
		}
	}, [tapLoading]);

	const mapCarsList = (carsList: SlideInterface[], position: boolean): JSX.Element[] => {
		const listTop = carsList.slice(0, 3);
		const listBot = carsList.slice(3, 7);
		if (position) {
			return listTop.map((car: SlideInterface, index: number) => (
				<div className='carsFleets__topCard w-full md:w-[32%] h-[215px] mb-[15px] md:mb-[35px]' key={index}>
					<CardCarSimple
						image={car.image}
						title={car.title}
						subtitle={car.subtitle}
						traits={car.traits}
						icons={icons}
						link={car.link}
						customBlock={'w-full'}
						titleClassName='text-xs md:text-base'
						subtitleClassName='text-xs md:text-base'
						valueClassName='text-xs'
						iconsClassName='text-base'
						arrowClassName='text-xs md:text-base'
						imageClassName='h-[215px]'
						description=''
						linkText=''
						iconClassName=''
						type={car.type}
					/>
				</div>
			));
		} else {
			return listBot.map((car: SlideInterface, index: number) => (
				<div className='carsFleets__bottomCard w-full md:w-[23.5%] h-[150px] hidden md:block' key={index}>
					<CardCarSimple
						image={car.image}
						title={car.title}
						subtitle={car.subtitle}
						traits={car.traits}
						icons={icons}
						link={car.link}
						customBlock={'w-full'}
						customElementHover={'px-[20px] py-[12px]'}
						titleClassName='text-xs'
						subtitleClassName='text-xs'
						valueClassName='text-xs'
						iconsClassName='text-base'
						arrowClassName='text-xs'
						imageClassName='h-[150px]'
						description=''
						linkText=''
						iconClassName=''
						type={car.type}
					/>
				</div>
			));
		}
	};

	return loading ? (
		<SkeletonCarsFleets
			className={customClass}
			introductionClassName={introductionClassName}
			introductionContainerClassName={introductionContainerClassName}
			tabsContainerClassName={tabsContainerClassName}
		/>
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`carsFleets ${customClass || ''}`}>
				<GenericIntroduction
					title={title}
					subtitle={subtitle}
					description={description}
					customClass={`${introductionContainerClassName || ''}`}
					containerClassName={introductionClassName}
					titleClassName='text-black'
					subtitleClassName='text-[#7a8083]'
					descriptionClassName='text-[#494848] w-[95%] md:w-4/5'
					lineClassName='bg-[#7A8083] border-[#7A8083]'
					linkText=''
					link=''
				/>
				<div className={`carsFleets__container ${tabsContainerClassName || ''}`}>
					<div className='carsFleets__item py-[10px] mx-auto flex justify-center'>
						<div className='flex justify-between h-[40px] overflow-x-auto overflow-y-hidden carsFleets__child'>
							{tabsList &&
								tabsList.map((fleet: CMSTabInterface, index: number) => (
									<div
										key={index}
										className={`flex items-center h-[30px] mr-[14px] last:mr-0 px-[24px] py-[6px] rounded-[5px] cursor-pointer bg-[#F2F2F2] text-xs md:text-base ${
											positionTap === fleet.value ? 'bg-[#000000] text-white' : ''
										} ${tabClassName || ''}`}
										onClick={() => tapClickHandler(fleet.value)}
									>
										<span>{fleet.label}</span>
									</div>
								))}
						</div>
					</div>
					<div className='carsFleets__item pt-[20px] pb-[30px] mx-auto flex flex-col items-center'>
						<div className='w-full flex flex-col items-center'>
							{tapLoading ? (
								<SkeletonCarsFleets
									className={customClass}
									introductionClassName={introductionClassName}
									introductionContainerClassName={introductionContainerClassName}
									tabsContainerClassName={tabsContainerClassName}
									onlyCards={true}
								/>
							) : (
								<>
									<div className='w-full md:flex md:flex-row md:justify-between md:items-center'>
										{mapCarsList(
											slides.filter((car: SlideInterface): boolean => car.type === positionTap),
											true,
										)}
									</div>

									<div className={'scale-90 pt-[10px] w-full md:flex md:flex-row md:justify-between md:items-center'}>
										{mapCarsList(
											slides.filter((car: SlideInterface): boolean => car.type === positionTap),
											false,
										)}
									</div>
								</>
							)}
						</div>
						<ButtonLink
							href={link || ''}
							children={
								<>
									<div className='flex'>
										<p>{linkText}</p>
										<span className='iconIconArrowRightSelect text-[12px] ml-[14px]'></span>
									</div>
								</>
							}
							className={`w-full md:w-auto mx-auto md:mt-[40px] rounded-[30px] border-2 border-black text-base md:px-8 ${buttonClassName || ''}`}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
