import styles from 'bundle-text:./error-template.scss';

interface ErrorTemplateProps {
	title?: string;
	description: string;
}

function ErrorTemplate(props: ErrorTemplateProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`errorTemplate__card w-full flex bg-white rounded-2xl flex-col m-4 p-3.5 justify-center`}>
				<span className='withOutResults text-4xl text-center'></span>
				<p className='text-center font-latoBold text-[20px]'>{props?.title || ''}</p>
				<p className='text-center text-gray-400'>{props?.description || ''}</p>
			</div>
		</>
	);
}

export default ErrorTemplate;
