import { InputsFilterCarsNamesV2, CarsRatesModelsV2 } from '../../../shared/enums/filter-cars-cards-v2.enum';
import { OptionSelect, InputEventsHandler } from '../../../../../shared/interfaces/inputs.interface';
import { ModelTypeEnum } from '../../../../../shared/enums/model-type.enum';
import { FormsInputsType } from '../../../../../shared/enums/inputs.enum';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { CarInterfaceV2 } from '../../../shared/interfaces';
import Select from '../../../../ui-elements/select/select';
import styles from './cars-currency-select-v2.module.scss';

export interface CarsCurrencySelectV2Props {
	carData: CarInterfaceV2;
	defaultValue?: string;
	className?: string;
	readonly?: boolean;
	disabled?: boolean;
	onChange?: (e: InputEventsHandler) => void;
}

export function CarsCurrencySelectV2({ carData, readonly, disabled, className, defaultValue, onChange }: CarsCurrencySelectV2Props): JSX.Element {
	const [currency, setCurrency] = useState<string>('');
	const [defaultCurrency, setDefaultCurrency] = useState<string>('');
	const currencyOptions = useMemo(
		() =>
			((tempData: CarInterfaceV2): OptionSelect[] => {
				const firstItem: number = 0;
				const options: OptionSelect[] = [];
				if (tempData) {
					if (tempData?.businessModel === ModelTypeEnum.CASH) {
						if (CarsRatesModelsV2.rate in tempData) {
							options.push({
								value: CarsRatesModelsV2.rate,
								label: tempData[CarsRatesModelsV2.rate].currency,
							});

							if (
								CarsRatesModelsV2.equivalentRate in tempData &&
								tempData[CarsRatesModelsV2.rate].currency !== tempData[CarsRatesModelsV2.equivalentRate].currency
							) {
								options.push({
									value: CarsRatesModelsV2.equivalentRate,
									label: tempData[CarsRatesModelsV2.equivalentRate].currency,
								});
							}
						}
					}
				}

				setDefaultCurrency(options && options.length && options[firstItem] ? options[firstItem].value : '');

				return [...options];
			})(carData),
		[carData],
	);

	const selectHandler = useCallback((event: InputEventsHandler): void => {
		setCurrency(String(event.value));
	}, []);

	useEffect(() => {
		if (onChange && currency) {
			const dataEvent: InputEventsHandler = {
				type: FormsInputsType.select,
				name: InputsFilterCarsNamesV2.currency,
				value: currency,
			};

			onChange(dataEvent);
		}
	}, [currency]);

	useEffect(() => {
		if (defaultValue) {
			setCurrency(defaultValue);
		} else if (!defaultValue && defaultCurrency) {
			setCurrency(defaultCurrency);
		}
	}, [defaultValue, defaultCurrency]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`carsCurrencySelectV2 ${className || ''}`}>
				<Select
					key={defaultValue || defaultCurrency}
					options={currencyOptions}
					defaultValue={defaultValue || defaultCurrency}
					label={<span className='cashIco text-[15px] md:text-[20px] mr-[5px]' />}
					name={InputsFilterCarsNamesV2.currency}
					className={'carsCurrencySelectV2__selectContainer carsCurrencySelectV2__select carsCurrencySelectV2__currencySelect items-center'}
					labelClassName={`carsCurrencySelectV2__label text-xs ${disabled ? 'text-[#7A8083]' : 'text-black'}`}
					selectClassName={`carsCurrencySelectV2__select text-xs font-medium bg-transparent w-auto inline-block mt-0  ${
						disabled ? 'text-[#7A8083]' : 'text-black'
					}`}
					onChange={selectHandler}
					readonly={readonly}
					disabled={disabled}
					optionsMarginTop='10px'
				/>
			</div>
		</>
	);
}

export default CarsCurrencySelectV2;
