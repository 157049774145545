export enum ProductStatus {
	canceled = 'canceled',
	pending = 'pending',
	booked = 'booked',
	issued = 'issued',
	paymentPending = 'paymentPending',
}

export enum StateResponseImport {
	initial = 'initial',
	successful = 'successful',
	fail = 'fail',
	loading = 'loading',
}
