import { Button, IcomoonIcon } from '../../../../ui-elements';

export interface MultiCurrencyConfirmProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	onEvent?: (value: boolean) => void;
}

export function MultiCurrencyConfirm({ t, colorPrimary, colorSecondary, onEvent }: Readonly<MultiCurrencyConfirmProps>): JSX.Element {
	return (
		<section className='multi-language-currency-confirm'>
			<div className='multi-language-currency-confirm__information mt-2'>
				<IcomoonIcon className='multi-language-currency-confirm__iconClose mr-2' icon={'infoCirlce2Ico'} fontSize={62} color={colorPrimary} />
				<p className='multi-language-currency-confirm__informationMessage text-sm text-gray-500 mb-2'>{t('currencyConfirmDescription')}</p>
			</div>
			<div className='multi-language-currency-confirm__action'>
				<Button
					customClass='multi-language-currency-confirm__save h-[36px] w-full rounded-lg px-6 my-2 text-white items-center'
					onClick={() => onEvent && onEvent(true)}
					styles={{
						background: colorSecondary,
					}}
					content={<span className='multi-language-currency-confirm__save text-[14px]'>{t('accept')}</span>}
				/>

				<Button
					customClass='multi-language-currency-confirm__close h-[36px] w-full rounded-lg px-6 items-center bg-white'
					onClick={() => onEvent && onEvent(false)}
					styles={{
						color: colorSecondary,
					}}
					content={<span className='multi-language-currency-confirm__closeTitle text-[14px]'>{t('cancel')}</span>}
				/>
			</div>
		</section>
	);
}

export default MultiCurrencyConfirm;
