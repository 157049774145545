import { ActivitiesSearchLocationsInterface } from '../../shared/interfaces/activities-locations.interface';
import { ActivitiesLocalStorageInterface } from '../../shared/interfaces/widget-localstorage.interface';
import { ActivitiesSearchWidgetProps } from '../../widgets/activities-search/activitiesSearchWidget';
import { useState, useCallback, useMemo, useEffect, lazy, Suspense } from 'react';
import { ActivitiesService } from '../../shared/services/activities.service';
import { ActivitiesUtil } from '../../shared/utils/activities-search.util';
import { Agency } from '../../shared/interfaces/agency.interface';
import { endPoints } from '../../shared/end-points/end-points';
import { TFunction } from 'react-i18next';
import {
	ActivitiesSearchFormSubmitInterface,
	ListInterface,
	ListItemInterface,
	PassengersFieldRestrictionsInterface,
	useDebounce,
	ActivitiesSearchFormUtil,
	Spinner,
} from '@smartlinks/react-design-system';

const ActivitiesSearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system').then(module => {
		return { default: module.ActivitiesSearchForm };
	});
});

interface ActivitiesSearchTemplateProps extends ActivitiesSearchWidgetProps {
	activitiesService: ActivitiesService;
	t: TFunction<'translation', undefined>;
}

function ActivitiesSearch({
	agency,
	useExternalRedirect,
	activitiesService,
	t,
	callUrlSearch,
	callShowMobileFields,
}: ActivitiesSearchTemplateProps): JSX.Element {
	const airportsDebounceHandler = useDebounce();
	const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);
	const [history, setHistory] = useState<ActivitiesSearchFormSubmitInterface | null>(null);
	const [locations, setLocations] = useState<ListInterface[]>([
		{
			title: (
				<span className='searchLocationIco hidden md:block destinationTitle pl-5'>
					<span className='px-3 pl-title'>{t('destinationsList')}</span>
				</span>
			),
			items: [],
		},
	]);

	const passengersRestrictions: PassengersFieldRestrictionsInterface = useMemo(
		() =>
			((): PassengersFieldRestrictionsInterface => {
				return ActivitiesUtil.passengersRestrictions();
			})(),
		[],
	);

	const calendarRestriction: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return ActivitiesUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const searchHandler = useCallback(
		(data: ActivitiesSearchFormSubmitInterface): void => {
			const dataForm: ActivitiesSearchFormSubmitInterface = {
				...data,
				urlPath: ActivitiesSearchFormUtil.mapSearchUrl(data),
			};

			const dataLocalStorage: ActivitiesLocalStorageInterface = {
				passengers: data.passengers,
			};

			if (useExternalRedirect) {
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.flightsSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

				createAnchor.click();

				return;
			}

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			activitiesService.setLocalHistory(dataLocalStorage);
		},
		[activitiesService, useExternalRedirect, agency, callUrlSearch],
	);

	const callLocationsByWord = useCallback(
		(locationName: string): void => {
			setIsLocationsLoading(true);

			airportsDebounceHandler((value: string): void => {
				let locationsList: ListInterface[] = [
					{
						title: (
							<span className='searchLocationIco hidden md:block destinationTitle pl-5'>
								<span className='px-3 pl-title'>{t('destinationsList')}</span>
							</span>
						),
						items: [],
					},
				];
				try {
					const wordLimit: number = 3;
					if (value.length >= wordLimit) {
						void (async (tempValue: string) => {
							const tempAirports: ActivitiesSearchLocationsInterface[] | null = await activitiesService.getLocationsByWords(tempValue);
							const mapAirportsList: ListItemInterface[] = ActivitiesUtil.mapLocationsList(tempAirports || []);
							locationsList = [
								{
									title: (
										<span className='searchLocationIco hidden md:block destinationTitle pl-5'>
											<span className='px-3 pl-title'>{t('destinationsList')}</span>
										</span>
									),
									items: mapAirportsList,
								},
							];

							setLocations([...locationsList]);

							setIsLocationsLoading(false);
						})(value);

						return;
					}
				} catch (error) {
					console.error(error);
				}
				setLocations([...locationsList]);

				setIsLocationsLoading(false);
			}, 300)(locationName);
		},
		[activitiesService, airportsDebounceHandler],
	);

	useEffect(() => {
		const tempHistory: ActivitiesSearchFormSubmitInterface | null = activitiesService.getActivitiesSearchFormHistory(t);
		if (tempHistory?.destinationIata && (!tempHistory?.destination || tempHistory?.destination === tempHistory?.destinationIata)) {
			try {
				void (async (tempValue: string) => {
					const tempLocations: ActivitiesSearchLocationsInterface[] | null = await activitiesService.getLocationsByWords(tempValue);
					const historyLocation: ActivitiesSearchLocationsInterface | undefined = tempLocations?.find(
						(location: ActivitiesSearchLocationsInterface) => location?.iata === tempValue,
					);

					if (historyLocation != null) {
						tempHistory.destination = `${historyLocation?.city || ''} - ${historyLocation?.country} (${historyLocation?.iata})`;
					}

					setHistory({ ...tempHistory });
				})(tempHistory.destinationIata);
			} catch (error) {
				console.error(error);

				setHistory(tempHistory ? { ...tempHistory } : null);
			}
		} else {
			setHistory(tempHistory != null ? { ...tempHistory } : null);
		}
	}, [activitiesService, t]);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<ActivitiesSearchForm
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={t}
					locations={locations}
					isLocationsLoading={isLocationsLoading}
					calendarRestriction={calendarRestriction}
					passengersRestrictions={passengersRestrictions}
					history={history}
					emitSubmitSearch={searchHandler}
					emitLocationsSearch={callLocationsByWord}
					emitShowMobileFields={callShowMobileFields}
					widgetStyles={agency?.widgetStyles}
				/>
			</Suspense>
		</div>
	);
}

export default ActivitiesSearch;
