import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { DisneySearchFormInputsNames } from '../enums/disney-search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { DisneySearchFormUtil } from '../utils/disney-search-form.util';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import { useForm } from 'react-hook-form';
import { InputEventsHandler, DatePickerData } from '../../../../shared/interfaces/inputs.interface';
import { SearchFieldsNames, DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
	UseDisneySearchFormInterface,
	UseDisneySearchFormReturnInterface,
	DisneySearchFormSubmitInterface,
} from '../interfaces/disney-search-form.interface';
import {
	FieldButtonEventHandlerInterface,
	DisplayValuesDatesFieldInterface,
	DatesFieldInputNamesInterface,
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';

const useDisneySearchForm = ({
	history,
	daysRestriction,
	calendarRestriction,
	traductions,
	emitSubmitSearch,
	emitShowMobileFields,
	passengersRestrictions,
	isSpecialEvents,
	maxPassengers,
}: UseDisneySearchFormInterface): UseDisneySearchFormReturnInterface => {
	const withPromoCodeName: string = DisneySearchFormInputsNames.withPromoCode;
	const datesHiddenName: string = DisneySearchFormInputsNames.hiddenDates;
	const passengersName: string = DisneySearchFormInputsNames.passengers;
	const passengersId: SearchFieldsNames = SearchFieldsNames.passengers;
	const datesName: string = DisneySearchFormInputsNames.dates;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const daysName: SearchFieldsNames = SearchFieldsNames.days;
	let minAdults: number = 1;
	let minChildren: number = 0;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		setError,
		clearErrors,
		formState: { errors, isValid },
	} = useForm();

	const inputRefs: React.MutableRefObject<Record<string, HTMLElement | null>> = useRef<Record<string, HTMLElement | null>>({});
	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(datesId);
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
	});

	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [minDate, setMinDate] = useState<Date>(new Date());
	const [days, setDays] = useState<number | string>(daysRestriction?.default ?? 2);
	const [promoCode, setPromoCode] = useState<string>('');
	const mobileFormHeight: number = useWindowHeight();
	const showMobileFieldsContainerHandler = useCallback((show: boolean): void => {
		setShowContainer(show);

		setMobileStep(datesId);
	}, []);

	const showMobileFieldsSearchHandler = useCallback(
		(show: boolean, fieldId: string, fieldName: string): void => {
			const waitTime: number = 100;
			setMobileStep(fieldId);

			setShowContainer(show);

			setTimeout(() => {
				const element = inputRefs.current[fieldId] as HTMLElement;
				if (element) {
					element.click();

					const input = element.querySelector(`input[name="${fieldName}"]`) as HTMLInputElement;
					if (input) {
						input.focus();
					}
				}
			}, waitTime);
		},
		[datesId, datesName],
	);

	const datesHandler = useCallback((event: InputEventsHandler): void => {
		const getData: DatePickerData = event.value as DatePickerData;
		setDefaultDates({
			startDate: getData.startDate,
		});
	}, []);

	const daysHandler = useCallback(
		(event: InputEventsHandler): void => {
			const value: number = Number(event?.value);
			const zero: number = 0;
			if (daysRestriction && daysRestriction?.min <= value && daysRestriction?.max >= value) {
				setDays(value);
			} else if (daysRestriction == null) {
				setDays(value);
			} else if (value === zero) {
				setDays(0);
			}

			clearErrors(daysName);
		},
		[daysRestriction],
	);

	const promoCodeHandler = useCallback((event: InputEventsHandler): void => {
		const value: string = String(event?.value);
		setPromoCode(value);
	}, []);

	const withPromoCodeHandler = useCallback((element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
		setPromoCode('');
	}, []);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(passengersName);
			const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
			const results: DisneySearchFormSubmitInterface = {
				passengers: {
					adults: data[tempPassengersNames.adultsName] || 0,
					children: data[tempPassengersNames.childrenName] || 0,
					display: data[tempPassengersNames.name] || '',
				},
				dates: data[datesName] || '',
				arrivalDate: data[tempDatesNames.startDate] || '',
				days: Number(days),
				withPromoCode: !!data[withPromoCodeName],
				promoCode,
				urlPath: undefined,
			};

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.oneDate,
				{
					date: true,
					time: false,
				},
				results.arrivalDate,
				'',
				'',
				'',
			);

			setButtonSearchHistory({
				departure: '',
				arrival: '',
				departureDate: datesValues.largeStartDate || '',
				arrivalDate: '',
				passengers: isSpecialEvents ? results?.passengers?.display || history?.passengers?.display || '' : '',
			});

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[emitSubmitSearch, days, promoCode],
	);

	const validateMobileMinDays = (): boolean => {
		const value: number = Number(days);
		if (daysRestriction && value < daysRestriction?.min) {
			setError(daysName, {});

			return true;
		}

		return false;
	};

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (event.id === datesId) {
				setShowContainer(false);

				return;
			}

			if (event.id === passengersId) {
				void (async () => {
					const delayTime: number = 500;
					setTimeout(() => {
						if (validateMobileMinDays()) {
							return;
						}

						if (isSpecialEvents) {
							void onSubmit();
						}

						setShowContainer(false);

						setMobileStep(datesId);
					}, delayTime);
				})();
			}
		},
		[onSubmit],
	);

	const backMobileFieldHandler = useCallback((event: FieldButtonEventHandlerInterface): void => {
		if (event.id === passengersId) {
			setShowContainer(false);

			return;
		}

		if (event.id === datesId) {
			setShowContainer(false);
		}
	}, []);

	const passengersModalInputsHandler = useCallback(
		(currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string): number => {
			const tempNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(passengersName);
			if (inputName === tempNames.hiddenAdultsName) {
				const adultsOutOfRestriction: boolean = maxPassengers < newValue + currentData.children + currentData.infants;
				return adultsOutOfRestriction ? currentData.adults : newValue;
			}

			if (inputName === tempNames.hiddenChildrenName) {
				const childrenOutOfRestriction: boolean = maxPassengers < newValue + currentData.adults + currentData.infants;
				return childrenOutOfRestriction ? currentData.children : newValue;
			}

			return newValue;
		},
		[maxPassengers],
	);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-disney-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const applyAllfieldsHistory: boolean = DisneySearchFormUtil.validatePath(history);
		if (passengersRestrictions?.adults?.default) {
			minAdults = passengersRestrictions?.adults?.default;
		}

		if (passengersRestrictions?.adults?.default) {
			minChildren = passengersRestrictions?.children?.default;
		}

		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		setMobileStep(datesId);

		const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(passengersName);
		const tempAdults: number = typeof history?.passengers?.adults === 'number' ? history?.passengers?.adults : minAdults;
		setValue(tempPassengersNames.adultsName, tempAdults);

		const tempChildren: number = typeof history?.passengers?.children === 'number' ? history?.passengers?.children : minChildren;
		setValue(tempPassengersNames.childrenName, tempChildren);

		const tempPassengersDisplay: string = SearchFieldsUtil.getDisplayPassengersInfo(
			tempAdults,
			tempChildren,
			0,
			traductions('adult') || 'adult',
			traductions('adults') || 'adults',
			traductions('child') || 'child',
			traductions('children') || 'children',
			'',
			'',
		);

		setValue(tempPassengersNames.name, tempPassengersDisplay || history?.passengers?.display || '');

		if (applyAllfieldsHistory) {
			const tempPromoCode: string = history?.promoCode ?? '';
			setValue(withPromoCodeName, !!history?.withPromoCode);

			setPromoCode(tempPromoCode);

			const tempDays: number = Number(history?.days ?? daysRestriction?.default ?? 2);
			setDays(tempDays);

			let tempArrivalDate: string = history?.arrivalDate ? history.arrivalDate : '';
			let tempStartDate: Date = new Date(`${tempArrivalDate}T00:00:00`);
			const validDate: boolean = !!tempArrivalDate && tempStartDate >= minDate;
			if (!validDate) {
				tempArrivalDate = '';

				tempStartDate = new Date('T00:00:00');
			}

			setValue(tempDatesNames.startDate, tempArrivalDate);

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.oneDate,
				{
					date: true,
					time: false,
				},
				tempArrivalDate,
				'',
				'',
				'',
			);

			setValue(datesName, validDate ? datesValues.dates : '');

			setButtonSearchHistory(
				history?.arrivalDate || isSpecialEvents
					? {
						departure: '',
						arrival: '',
						departureDate: datesValues?.largeStartDate || '',
						arrivalDate: '',
						passengers: isSpecialEvents ? tempPassengersDisplay || history?.passengers?.display || '' : '',
					  }
					: undefined,
			);

			setDefaultDates(
				validDate
					? {
						startDate: tempStartDate,
					  }
					: {
						startDate: minDate,
					  },
			);
		}
	}, [history, minDate, traductions]);

	return {
		datesName,
		datesHiddenName,
		passengersName,
		datesId,
		passengersId,
		withPromoCodeName,
		mobileFieldStep: mobileStep,
		buttonSearchHistory,
		minDate,
		defaultDates,
		days,
		promoCode,
		showMobileFieldsContainer: showContainer,
		mobileFormHeight,
		errors,
		isValid,
		isSpecialEvents,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		showMobileFieldsSearchHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		daysHandler,
		promoCodeHandler,
		withPromoCodeHandler,
		onSubmit,
		passengersModalInputsHandler,
	};
};

export default useDisneySearchForm;
