import { CMSGeneralComponentInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import { PopUpDialogEventInterface } from '../../../../../shared/interfaces/pop-up-dialog.interface';
import SkeletonNewsletterSubscription from './skeleton/skeleton-newsletter-subscription';
import { ElementTypes } from '../../../../../shared/enums/dynamic-forms.enum';
import Button from '../../../../ui-elements/button/button';
import styles from './newsletter-subscription.module.scss';
import Input from '../../../../ui-elements/input/input';
import { useForm } from 'react-hook-form';
import { MouseEventHandler } from 'react';
import Modal from '../modal/modal';

export interface NewsletterSubscriptionProps extends CMSGeneralComponentInterface {
	loading?: boolean;
	inputPlaceholder: string;
	titleCompletionMessage: string;
	descriptionCompletionMessage: string;
	labelCompletionMessage: string;
	textButtonCompletionMessage: string;
	widthCompletionMessage: number;
	zIndexCompletionMessage: number;
	showCompletionMessage?: boolean;
	topCompletionMessage?: string;
	image?: string;
	submitForm?: (email: string) => void;
	onClickButtonMessage?: MouseEventHandler<HTMLButtonElement>;
	closeMessage?: (data: PopUpDialogEventInterface) => void;
	className?: string;
	contentClassName?: string;
	titleClassName?: string;
	descriptionClassName?: string;
	inputClassName?: string;
	buttonClassName?: string;
	titleCompletionMessageClassName?: string;
	descriptionCompletionMessageClassName?: string;
	labelCompletionMessageClassName?: string;
	buttonCompletionMessageClassName?: string;
	style?: any;
}

export const NewsletterSubscription = ({
	loading,
	title,
	description,
	inputPlaceholder,
	linkText,
	titleCompletionMessage,
	descriptionCompletionMessage,
	labelCompletionMessage,
	textButtonCompletionMessage,
	widthCompletionMessage,
	showCompletionMessage,
	zIndexCompletionMessage,
	topCompletionMessage,
	image,
	submitForm,
	onClickButtonMessage,
	closeMessage,
	className,
	contentClassName,
	titleClassName,
	descriptionClassName,
	inputClassName,
	buttonClassName,
	titleCompletionMessageClassName,
	descriptionCompletionMessageClassName,
	labelCompletionMessageClassName,
	buttonCompletionMessageClassName,
	style,
}: NewsletterSubscriptionProps): JSX.Element => {
	const inputName: string = 'newsletter-email';
	const {
		register,
		handleSubmit,
		setFocus,
		getValues,
		formState: { errors },
	} = useForm();

	const onSubmit = handleSubmit((data: any) => {
		if (submitForm) {
			submitForm(data[inputName]);
		}
	});

	return loading ? (
		<SkeletonNewsletterSubscription
			image={image}
			className={className}
			contentClassName={contentClassName}
			inputClassName={inputClassName}
			style={style}
		/>
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<Modal
				width={widthCompletionMessage}
				show={showCompletionMessage || false}
				zIndex={zIndexCompletionMessage}
				backgroundColor='rgba(0,0,0,0.4)'
				idComponent='newsletter-subscription'
				onBackdropClick={closeMessage}
				onCloseClick={closeMessage}
				closeIconSize={18}
				showCloseButton={true}
				className='pt-5 pb-7 px-6'
				top={topCompletionMessage}
			>
				<div className='flex flex-col justify-center items-center px-11 pt-8'>
					<p className={`text-center font-black mb-11 ${titleCompletionMessageClassName || ''}`}>{titleCompletionMessage || ''}</p>
					<p className={`text-center font-medium mb-5 ${descriptionCompletionMessageClassName || ''}`}>{descriptionCompletionMessage}</p>
					<p className={'text-center font-bold'}>{getValues(inputName)}</p>
					<div className='flex flex-row justify-center items-center mt-12'>
						<p className={`text-center font-normal mr-4 ${labelCompletionMessageClassName || ''}`}>{labelCompletionMessage}</p>
						<button onClick={onClickButtonMessage} type='button' className={`text-center font-normal ${buttonCompletionMessageClassName || ''}`}>
							{textButtonCompletionMessage}
						</button>
					</div>
				</div>
			</Modal>
			<div className={`newsletterSubscription ${className || ''}`}>
				<div
					className={'newsletterSubscription__content flex flex-col justify-center rounded-3xl'}
					style={{
						...style,
					}}
				>
					<div
						className={`${contentClassName || ''}`}
						style={{
							backgroundImage: image ? `url(${image})` : undefined,
						}}
					>
						<h3 className={`text-center font-bold mb-2.5 md:mb-4 ${titleClassName || ''}`}>{title}</h3>
						<p className={`text-center font-normal mb-5 md:mb-4 ${descriptionClassName || ''}`}>{description}</p>
						<form onSubmit={onSubmit} className={'flex newsletterSubscription__form flex-row justify-center items-center'}>
							<Input
								type={ElementTypes.email}
								placeholder={inputPlaceholder}
								inputClassName={`newsletterSubscription__input text-center md:text-left font-medium text-[#494848] ${inputClassName || ''}`}
								className='w-full md:w-80 px-0'
								required={true}
								register={register(inputName, {
									required: true,
									onChange: () => {
										if (errors[inputName]) {
											const waitTime: number = 100;
											setTimeout(() => {
												setFocus(inputName);
											}, waitTime);
										}
									},
								})}
							/>
							<Button text={linkText || ''} type='submit' customClass={`text-center rounded-full font-bold ${buttonClassName || ''}`} />
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

NewsletterSubscription.defaultProps = {
	className: '',
	contentClassName: '',
	titleClassName: '',
	descriptionClassName: '',
	inputClassName: '',
	buttonClassName: '',
	titleCompletionMessageClassName: '',
	descriptionCompletionMessageClassName: '',
	labelCompletionMessageClassName: '',
	buttonCompletionMessageClassName: '',
};

export default NewsletterSubscription;
