export function SkeletonAvailabilitySkeleton(): JSX.Element {
	return (
		<>
			<div className={'availability-filters  animate-pulse'}>
				<form className={'availability-filters__container px-[10px]'}>
					<div className={'availability-filters__item pb-[35px] border-b border-gray-400 flex justify-between items-center'}>
						<p className='availability-filters__title text-[24px] bg-gray-200 w-[208px] h-[36px] rounded-lg'></p>
					</div>

					<div className={'availability-filters__item py-[35px] border-b border-gray-400'}>
						<div className='flex justify-between pb-[19px]'>
							<p className='availability-filters__secondaryTitle bg-gray-200 w-[60px] h-[24px] rounded-lg'></p>
						</div>

						<div className='border border-gray-200 rounded-lg w-full h-[54px] rounded-lg py-2.5 px-2 mb-4'>
							<span className='block ml-2 w-[120px] h-[13px] bg-gray-200 mt-2'></span>
						</div>
					</div>

					<div className={'availability-filters__item py-[35px] border-b border-gray-400'}>
						<div className='flex justify-between pb-[19px]'>
							<p className='availability-filters__secondaryTitle bg-gray-200 w-[90px] h-[24px] rounded-lg'></p>
						</div>

						<div className='flex items-center justify-between mb-2'>
							<div className='flex items-center'>
								<div className='w-[20px] h-[20px] bg-gray-200 mr-1'></div>
								<span className='w-[70px] h-[20px] bg-gray-200 block'></span>
							</div>

							<p className='w-[40px] h-[20px] bg-gray-200'></p>
						</div>

						<div className='flex items-center justify-between mb-2'>
							<div className='flex items-center'>
								<div className='w-[20px] h-[20px] bg-gray-200 mr-1'></div>
								<span className='w-[70px] h-[20px] bg-gray-200 block'></span>
							</div>

							<p className='w-[40px] h-[20px] bg-gray-200'></p>
						</div>

						<div className='flex items-center justify-between mb-2'>
							<div className='flex items-center'>
								<div className='w-[20px] h-[20px] bg-gray-200 mr-1'></div>
								<span className='w-[70px] h-[20px] bg-gray-200 block'></span>
							</div>

							<p className='w-[40px] h-[20px] bg-gray-200'></p>
						</div>
					</div>

					<div className={'availability-filters__item py-[35px] border-b border-gray-400'}>
						<div className='flex justify-between pb-[19px]'>
							<p className='availability-filters__secondaryTitle bg-gray-200 w-[120px] h-[24px] rounded-lg'></p>
						</div>

						<div className='border border-gray-400 bg-gray-200 rounded-lg w-full h-[54px] rounded-lg py-2.5 px-2 mb-4'></div>

						<div className='border border-gray-400 bg-gray-200 rounded-lg w-full h-[54px] rounded-lg py-2.5 px-2 mb-4'></div>

						<div className='border border-gray-400 bg-gray-200 rounded-lg w-full h-[54px] rounded-lg py-2.5 px-2 mb-4'></div>
					</div>
				</form>
			</div>
		</>
	);
}

export default SkeletonAvailabilitySkeleton;
