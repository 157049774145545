import { DeepRequired, FieldErrorsImpl, FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { FieldButtonEventHandlerInterface, OptionSelect } from '../../../../../shared/interfaces';
import { FlightsSearchFieldsNames } from '../../../../flights/shared/enums';
import { FilterField } from '../../../../shared-components';

export interface AirlineProps {
	hideOnDesktop?: boolean;
	customClass?: string;
	airlineName: string;
	colorSecondary?: string;
	airlines?: OptionSelect[];
	airlineId: FlightsSearchFieldsNames;
	showMobileAirlines: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	useOptionalText?: boolean;
	truncate?: boolean;
	isMobile?: boolean;
	traductions: (value: string) => string;
	register: UseFormRegister<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	trigger: UseFormTrigger<FieldValues>;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	arlineHandler?: (selectedOption: OptionSelect) => void;
	airlinesMobileFieldHandler: (event: FieldButtonEventHandlerInterface) => void;
}

export function Airline({
	hideOnDesktop,
	customClass,
	airlineName,
	colorSecondary,
	airlines,
	airlineId,
	isSecondaryTemplate,
	isTertiaryTemplate,
	showMobileAirlines,
	useOptionalText,
	isMobile,
	truncate,
	traductions,
	register,
	getValues,
	setValue,
	trigger,
	errors,
	arlineHandler,
	airlinesMobileFieldHandler,
}: AirlineProps): JSX.Element {
	return (
		<div
			className={`flightsSearchForm__airline w-full relative  ${customClass || ''} ${hideOnDesktop ? 'md:hidden' : ''}  ${
				isSecondaryTemplate ? 'md:pl-[15px]' : 'md:pl-[7px]'
			}`}
		>
			<FilterField
				fieldId={airlineId}
				name={airlineName}
				title={traductions('airline') || 'airline'}
				placeHolder={traductions('addYourAirline') || 'addYourAirline'}
				searchPlaceHolder={traductions('searchTheAirline') || 'searchTheAirline'}
				nextButtonTraduction={traductions('comeBack') || 'comeBack'}
				optionalText={traductions('optionalLabel') || 'optional'}
				colorSecondary={colorSecondary}
				className={`w-full ${showMobileAirlines ? 'z-50' : 'z-0'} md:z-auto`}
				required={true}
				register={register}
				setValue={setValue}
				getValues={getValues}
				trigger={trigger}
				errors={errors}
				isSecondaryTemplate={isSecondaryTemplate}
				isTertiaryTemplate={isTertiaryTemplate}
				useBackButton={false}
				arlineHandler={arlineHandler}
				nextCallBack={airlinesMobileFieldHandler}
				options={airlines}
				visibleTitle={true}
				useOptionalText={useOptionalText}
				isMobile={isMobile}
				truncate={truncate}
			/>
		</div>
	);
}

export default Airline;
