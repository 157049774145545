import useTextSkeleton from '../../../../../../shared/hooks/useTextSkeleton';
import useWindowWidth from '../../../../../../shared/hooks/useWidth';
import Slider from '../../slider/slider';

export interface InspirationalDestinationsSkeletonProps {
	className?: string;
}

export function InspirationalDestinationsSkeleton({ className }: InspirationalDestinationsSkeletonProps): JSX.Element {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const descriptionComnponents = useTextSkeleton(
		2,
		5,
		20,
		windowWidth >= responsiveSize ? 95 : 85,
		windowWidth >= responsiveSize ? 100 : 90,
		'mt-2 h-3 md:h-4',
	);

	return (
		<div className={`inspirationalDestinationsSkeleton animate-pulse ${className || ''}`}>
			<div className='flex flex-col justify-start mb-4 w-full md:w-4/5'>
				<div className='h-px w-6 md:w-12 border-b-4 rounded-xl mb-4 border-gray-200 bg-gray-200' />
				<p className='inspirationalDestinationsSkeleton__subtitle mb-2.5 md:mb-4 bg-gray-200 h-3 w-20' />
				<h2 className='inspirationalDestinationsSkeleton__title mb-2.5 bg-gray-200 h-6 md:h-12 w-4/5' />
				<div className='inspirationalDestinationsSkeleton__description w-full'>{descriptionComnponents}</div>
			</div>
			<div className='flex flex-row justify-end items-center md:pr-5 mb-7 md:mb-14'>
				<div className='flex justify-center items-center rounded-full border-4 w-[30px] md:w-[50px] h-[30px] md:h-[50px] bg-gray-200 border-gray-200' />
				<div className='flex justify-center items-center rounded-full border-4 w-[30px] md:w-[50px] h-[30px] md:h-[50px] ml-8 bg-gray-200 border-gray-200' />
			</div>
			<Slider
				slides={[]}
				slidesHeight={windowWidth >= responsiveSize ? '370px' : '300px'}
				config={{
					showContent: true,
					showLinkButton: true,
					showSubtitleLine: false,
					showBackgroundImage: true,
				}}
				loading={true}
				skeletonSlides={windowWidth >= responsiveSize ? 3 : 1}
				slidesSkeletonClassNames={{
					className: 'rounded-xl w-full',
					outClassName: `w-full ${windowWidth >= responsiveSize ? 'mr-5' : ''}`,
					imageClassName: 'rounded-xl',
					contentClassName: 'px-6 pb-8 md:pb-10 w-full',
					titleClassName: 'mb-2.5 w-4/5 h-9 md:h-12',
					subtitleClassName: 'hidden',
					descriptionClassName: 'h-4 mt-2',
					buttonClassName: 'hidden',
				}}
				slidesWidth={0}
				slidesMargin={0}
				sliderSideMargin={0}
				slidesNumberShow={0}
			/>
		</div>
	);
}

export default InspirationalDestinationsSkeleton;
