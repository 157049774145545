import useTextSkeleton from '../../../../../../shared/hooks/useTextSkeleton';
import useWindowWidth from '../../../../../../shared/hooks/useWidth';
import Slider from '../../slider/slider';
import React from 'react';

export interface ReviewsSkeletonProps {
	className?: string;
	infoContainerClassName?: string;
	desktopSliderContainerClassName?: string;
	mobileSliderContainerClassName?: string;
}

export function ReviewsSkeleton({
	className,
	infoContainerClassName,
	desktopSliderContainerClassName,
	mobileSliderContainerClassName,
}: ReviewsSkeletonProps): JSX.Element {
	const maxSlides: number = 9;
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const stepSliderButton: number = windowWidth >= responsiveSize ? 3 : 1;
	const descriptionComnponents = useTextSkeleton(
		2,
		5,
		20,
		windowWidth >= responsiveSize ? 95 : 85,
		windowWidth >= responsiveSize ? 100 : 90,
		'mt-2 h-3 md:h-4',
	);

	const slideDescriptionComnponents = useTextSkeleton(3, 5, 20, 93, 100, 'mt-0.5 h-4 md:h-5');
	const customContentSlides: JSX.Element[] = new Array(stepSliderButton).fill(0).map((item: number, index: number): JSX.Element => {
		return (
			<React.Fragment key={index}>
				<span className='absolute left-7 -top-10 text-center font-normal text-9xl text-gray-200'>&#10077;</span>
				<div className='flex-grow rounded-xl w-full h-full px-8 md:px-20 py-12 md:py-14'>
					<h3 className='mb-2.5 h-7 md:h-9 w-full md:w-11/12 bg-gray-200' />
					<p className='mb-2.5 font-bold text-left h-4 md:h-6 w-10/12 md:w-8/12 bg-gray-200' />
					{slideDescriptionComnponents}
				</div>
				<div
					className='absolute right-7 -bottom-9 md:-bottom-10 inline-block rounded-full bg-gray-100 border-4 border-gray-200'
					style={{
						width: windowWidth >= responsiveSize ? '86px' : '72px',
						height: windowWidth >= responsiveSize ? '86px' : '72px',
					}}
				/>
			</React.Fragment>
		);
	});

	return (
		<div className={`reviewsSkeleton animate-pulse ${className || ''}`}>
			<div className={`flex flex-col justify-start mb-14 md:mb-24 w-full md:w-4/5 ${infoContainerClassName || ''}`}>
				<div className='h-px w-6 md:w-12 border-b-4 rounded-xl mb-4 border-gray-200 bg-gray-200' />
				<p className='mb-2.5 md:mb-4 bg-gray-200 h-3 w-20' />
				<h2 className='mb-2.5 bg-gray-200 h-6 md:h-12 w-4/5 md:w-3/5' />
				<div className='reviewsSkeleton__description w-full md:w-[80%]'>{descriptionComnponents}</div>
			</div>
			<div className={`${windowWidth >= responsiveSize ? desktopSliderContainerClassName || '' : mobileSliderContainerClassName || ''}`}>
				<Slider
					slides={[]}
					slidesHeight={windowWidth >= responsiveSize ? '317px' : '287px'}
					config={{
						showContent: true,
						showLinkButton: false,
						showSubtitleLine: false,
						showBackgroundImage: false,
					}}
					loading={true}
					skeletonSlides={windowWidth >= responsiveSize ? 3 : 1}
					customContentSlides={customContentSlides}
					slidesSkeletonClassNames={{
						className: 'rounded-xl w-full flex flex-col',
						outClassName: `rounded-xl w-full ${windowWidth >= responsiveSize ? 'mr-14' : ''}`,
						imageClassName: 'rounded-xl',
					}}
					slidesWidth={0}
					slidesMargin={0}
					sliderSideMargin={0}
					slidesNumberShow={0}
				/>
			</div>
			<div className='flex flex-row justify-center items-center mt-20'>
				{new Array(Math.ceil(maxSlides / stepSliderButton)).fill(0).map((item: number, index: number) => {
					const isActive: boolean = index === 0;
					return (
						<div
							key={index}
							className={`mx-1.5 bg-gray-200 border-gray-200 ${isActive ? 'h-px border-4 w-8 md:w-12 rounded-xl' : 'h-2 w-2 rounded-full'}`}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default ReviewsSkeleton;
