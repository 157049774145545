import { addDays } from 'date-fns';

export const translateDay = (t: (value: string) => string, day: number | undefined): string => {
	const oneDay: number = 1;
	return day === oneDay ? t('day') : t('days');
};

export const validateCalendarRestrictions = (calendarRestriction: number, activeDate: Date): boolean => {
	let today: Date = new Date();
	today = addDays(today, calendarRestriction || 0);

	today.setHours(0, 0, 0, 0);

	return !!(calendarRestriction && activeDate.getTime() <= today.getTime());
};
