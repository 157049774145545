import LanguageDetector from 'i18next-browser-languagedetector';
import esCoTranslation from '../locales/es-co/translation.json';
import enUsTranslation from '../locales/en-us/translation.json';
import ptBrTranslation from '../locales/pt-br/translation.json';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { Culture } from './culture.enum';
import i18n from 'i18next';

void i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		compatibilityJSON: 'v3',
		fallbackLng: Culture.EsCo,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			[Culture.EsCo]: {
				translation: esCoTranslation,
			},
			[Culture.PtBr]: {
				translation: ptBrTranslation,
			},
			[Culture.EnUs]: {
				translation: enUsTranslation,
			},
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
