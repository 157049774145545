import { FlightSearchResult } from '../../shared/interfaces/flight-search-result.interface';
import { ModelTypeEnum, ScrollHandlerType } from '../../../../shared/enums';
import { addAlphaToColor } from '../../../../shared/services';
import { ScrollHandlers } from '../../../../shared/hooks';
import styles from './recommendation-detail.module.scss';
import { Backdrop } from '../../../../lib/ui-elements';
import { useEffect, useRef, useState } from 'react';
import FareCard from '../fare-card/fare-card';

export interface RecommendationDetailProps {
	closeCallback?: (flightDetail?: FlightSearchResult) => void;
	onlySelectedRecommendation?: boolean;
	flightDetail: FlightSearchResult;
	t: (value: string) => string;
	colorPrimary?: string;
}

export function RecommendationDetail({
	t,
	colorPrimary,
	closeCallback,
	flightDetail,
	onlySelectedRecommendation,
}: RecommendationDetailProps): JSX.Element {
	const [showScaleDetail, setShowScaleDetail] = useState<boolean>(false);
	const alphaColorPrimary: string | undefined = colorPrimary ? addAlphaToColor(colorPrimary, 0.25) : undefined;
	const { flightInformation, legs, faresGroup, businessModel } = flightDetail;
	const scrollHandlers: ScrollHandlers = new ScrollHandlers();
	const fareContainerRef = useRef(null);
	const moreThatTwoFares: number = 2;
	useEffect(() => {}, []);

	const moveScroll = (type: ScrollHandlerType): void => {
		const { current } = fareContainerRef;
		if (!current) {
			return;
		}

		scrollHandlers.ScrollManualMovement(current as unknown as HTMLDivElement, type, '.recommendation-detail__fares');
	};

	return (
		<>
			<Backdrop show={true} onClick={() => closeCallback && closeCallback()} zIndex={80} backgroundColor={alphaColorPrimary} opacity={0.5} />
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='recommendation-detail z-[100] flex justify-end fixed right-0 top-0 scroll-smooth max-h-[100vh] overflow-y-scroll'>
				<div className='recommendation-detail__container w-full bg-white  py-[20px]  px-[25px] md:px-[55px] h-full'>
					<span onClick={() => closeCallback && closeCallback()} className='closeIco text-gray-500 text-[20px]'></span>
					<div className='recommendation-detail__head flex justify-between items-center'>
						<p className='text-[21px]'>
							{flightInformation.departureLocation.name} {`${flightInformation.arrivalLocation && `- ${flightInformation.arrivalLocation.name}`}`}(
							{flightInformation.departureLocation.code} {`${flightInformation.arrivalLocation && `- ${flightInformation.arrivalLocation.code}`}`})
						</p>
					</div>

					<div className='recommendation-detail__airline flex items-center'>
						<img src={flightInformation.airline.logoUrl} alt='' width={30} height={30} />
						<p className='recommendation-detail__airline-title text-gray-500'>{flightInformation.airline.name}</p>
					</div>

					<div className='recommendation-detail__scales pt-[40px]'>
						<p className='recommendation-detail__scalesDetail-title'>
							{flightInformation.departure.dateFormated} ({t(flightInformation.stops)})
						</p>
						<p className='recommendation-detail__scalesDetail-timeTotal text-gray-400'>{flightInformation.totalTimeOfFlightFormated}</p>

						<p
							onClick={() => {
								setShowScaleDetail(!showScaleDetail);
							}}
							className='recommendation-detail__scalesDetail-detail flex items-center text-xs text-blue-500 cursor-pointer'
						>
							Ver detalles
							<span className={`${showScaleDetail ? 'chevronUpIco' : 'chevronBottomIco'} flex ml-2 text-[7px]`}></span>{' '}
						</p>

						{showScaleDetail &&
							legs.map((leg, index) => (
								<div key={index}>
									<div className='recommendation-detail__scalesDetail pt-[20px]'>
										<p className='recommendation-detail__scalesDetail-title'>
											<span className='flyPlaneIco'></span> {leg.departure.timeFormated} - {leg.departureLocation.name}
										</p>
										<p className='text-xs recommendation-detail__scalesDetail-extraAirPort text-gray-500'>{leg.departureLocation.airportName}</p>
										<p className='text-xs recommendation-detail__scalesDetail-extra text-gray-400'>
											{t('flight')} {leg.timeOfFlightFormated}
										</p>
										<p className='text-xs recommendation-detail__scalesDetail-extra text-gray-400'>
											{t('flightNumber')} {leg.flightNumber}
										</p>
										<p className='text-xs recommendation-detail__scalesDetail-extra text-gray-400'>{leg.airplaneName}</p>
										<p className='text-xs recommendation-detail__scalesDetail-extra text-gray-400'>{t(leg.cabin)}</p>
									</div>

									<div className='recommendation-detail__scalesDetail pt-[20px]'>
										<p className='recommendation-detail__scalesDetail-title'>
											<span className='iconPlaneArrival text-[19px]'></span> {leg.arrival.timeFormated} - {leg.arrivalLocation.name}
										</p>
										<p
											className={`text-xs recommendation-detail__scalesDetail-extraAirPort ${
												leg.waitTime === '00:00:00' ? 'recommendation-detail__scalesDetail-arrivalEnd' : ''
											} text-gray-500`}
										>
											{leg.arrivalLocation.airportName}
										</p>

										{leg.waitTime !== '00:00:00' && (
											<>
												<div className='border-t border-b py-3 my-3 border-gray-500'>
													<p className='text-xs recommendation-detail__scalesDetail-time text-gray-500'>
														{!!leg.technicalStop && <span className='iconGasFlight text-gray-500 mr-1 text-[15px]'></span>}
														{!!leg.trainStation && <span className='iconTrain text-gray-500 mr-1 text-[15px]'></span>}
														{!!leg.transferFromAirport && <span className='iconChange  text-gray-500 mr-1 text-[15px]'></span>}
														{t('waitingFor')} {leg.waitTimeFormated} {t('in')} {leg.arrivalLocation.name}
														{!!leg.technicalStop && ` - ${t('technicalStop')}`}
														{!!leg.trainStation && ` - ${t('changeOfAirportByTrain')}`}
														{!!leg.transferFromAirport && ` - ${t('transferFromAirport')}`}
													</p>
												</div>
											</>
										)}
									</div>
								</div>
							))}
					</div>

					<div className='recommendation-detail__hint pt-[20px]'>
						<p className='recommendation-detail__hint-title'>
							{t('flightRateTo')} {flightInformation.arrivalLocation.name}
						</p>
						<p className='recommendation-detail__hint-description text-xs text-gray-500'>{t('flightRateToDescription')}.</p>
					</div>

					<div className='recommendation-detail__fareContainer relative'>
						{faresGroup.length > moreThatTwoFares && (
							<span
								onClick={() => moveScroll(ScrollHandlerType.left)}
								className='recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute left-0 chevronLeftIco'
							></span>
						)}

						<div ref={fareContainerRef} className='recommendation-detail__scroll overflow-x-scroll lg:overflow-x-auto'>
							<div className={`recommendation-detail__fares ${faresGroup.length === 1 ? 'justify-center' : ''} my-4 flex relative`}>
								{faresGroup.map((fareGroup, index) => (
									<div className='mr-1 mb-4 flex' key={index}>
										<FareCard
											t={t}
											colorPrimary={colorPrimary}
											useAlphaBackground={!fareGroup.isRecommended}
											fareGroup={fareGroup}
											businessModel={businessModel as ModelTypeEnum}
											onlySelectedRecommendation={onlySelectedRecommendation}
											markedRecommendation={flightFare => {
												fareGroup = flightFare;

												closeCallback && closeCallback(flightDetail);
											}}
										/>
									</div>
								))}
							</div>
						</div>

						{faresGroup.length > moreThatTwoFares && (
							<span
								onClick={() => moveScroll(ScrollHandlerType.right)}
								className=' recommendation-detail__arrow opacity-0 pointer-events-none rounded-full shadow-lg text-xs text-center cursor-pointer bg-white w-[24px] h-[24px] z-10 absolute right-0  chevronRightIco'
							></span>
						)}
					</div>

					<div className='recommendation-detail__footer text-gray-500'>
						<p className='recommendation-detail__footer-title text-xs'>{t('recomendaciones')}</p>
						<p className='recommendation-detail__footerText text-xs'>{t('recommendationFirstDescription')}.</p>
						<p className='recommendation-detail__footerText text-xs'>{t('recommendationSecondDescription')}.</p>
						<p className='recommendation-detail__footerText text-xs'>{t('recommendationthirdDescription')}</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default RecommendationDetail;
