import { AddonsInterface, TicketSubmitInterface, TicketTypeOptionInterface } from '../../../../shared/interfaces/hotels/addons.interface';
import { UseHotelsDisneyResumenAsideInterface, UseHotelsDisneyResumenAsideReturnInterface } from '../../shared/interfaces';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { HotelsDisneySearchFormInputsNames } from '../enums';
import { SearchFieldsNames } from '../../../../shared/enums';
import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
	DatePickerData,
	DatePickerDefaultData,
	DatesFieldInputNamesInterface,
	FieldButtonEventHandlerInterface,
	InputEventsHandler,
} from '../../../../shared/interfaces';

const useHotelsDisneyResumenAside = ({
	addons,
	hotel,
	selectPlan,
	selectDay,
	t,
	emitDateSearch,
	emitSubmitSearch,
}: UseHotelsDisneyResumenAsideInterface): UseHotelsDisneyResumenAsideReturnInterface => {
	const datesHiddenName: string = HotelsDisneySearchFormInputsNames.hiddenDates;
	const datesName: string = HotelsDisneySearchFormInputsNames.dates;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors },
	} = useForm();

	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
	});

	const [minDate, setMinDate] = useState<Date>(new Date());
	const [maxDate, setMaxDate] = useState<Date>(new Date());
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(datesId);
	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	useEffect(() => {
		let currentDate: Date = new Date();
		let maxDate: Date = new Date();
		if (hotel.checkIn) {
			currentDate = new Date(hotel.checkIn);

			maxDate = new Date(hotel.checkOut);
		}

		setMinDate(currentDate);

		setMaxDate(maxDate);
	}, [hotel]);

	const datesHandler = useCallback(
		(event: InputEventsHandler): void => {
			const getData: DatePickerData = event.value as DatePickerData;
			setDefaultDates({
				startDate: getData.startDate,
			});

			if (emitDateSearch && getData.startDate) {
				setLoading(true);

				emitDateSearch(getData.startDate);
			}
		},
		[emitDateSearch],
	);

	useEffect(() => {
		if (addons) {
			setLoading(false);
		}
	}, [addons]);

	useEffect(() => {
		setDefaultDates({
			startDate: minDate,
		});
	}, [minDate, t]);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
			const selectTicket: AddonsInterface | undefined = selectDay ? { ...selectDay } : undefined;
			if (selectTicket && selectTicket.ticketTypeOptions) {
				const ticketOption: TicketTypeOptionInterface[] = selectTicket.ticketTypeOptions.filter(
					(ticket: TicketTypeOptionInterface) => ticket.type === selectPlan?.type,
				);

				selectTicket.ticketTypeOptions = ticketOption;
			}

			const results: TicketSubmitInterface = {
				startDate: getValues(tempDatesNames.startDate) || '',
				selectTicket: selectTicket || undefined,
			};

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[selectPlan, selectDay, emitSubmitSearch],
	);

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (event.id === datesId) {
				setShowContainer(false);

				setMobileStep(datesId);
			}
		},
		[onSubmit],
	);

	const showMobileFieldsContainerHandler = useCallback((show: boolean): void => {
		setShowContainer(show);

		setMobileStep(datesId);
	}, []);

	return {
		datesId,
		datesName,
		datesHiddenName,
		maxDate,
		minDate,
		defaultDates,
		errors,
		mobileFieldStep: mobileStep,
		showMobileFieldsContainer: showContainer,
		loading,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		datesHandler,
		onSubmit,
		nextMobileFieldHandler,
		showMobileFieldsContainerHandler,
	};
};

export default useHotelsDisneyResumenAside;
