export function GenericFiltersSkeleton(): JSX.Element {
	return (
		<section className='animate-pulse flex flex-col flex-wrap'>
			<div className='bg-gray-200 w-[120px] h-[16px] rounded-md mb-3'></div>
			<div className='bg-gray-200 w-[200px] h-[50px] rounded-md mb-8'></div>
			<div className='bg-gray-200 w-[120px] h-[16px] rounded-md mb-2'></div>

			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-1'></div>
			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-1'></div>
			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-1'></div>
			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-8'></div>

			<div className='bg-gray-200 w-[120px] h-[16px] rounded-md mr-2 mb-3'></div>
			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-1'></div>
			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-1'></div>
			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-1'></div>
			<div className='bg-gray-200 w-[60px] h-[16px] rounded-md ml-1 mb-8'></div>

			<div className='generic-filters__button self-end mt-8 h-[40px] w-[140px] bg-gray-200 rounded-xl md:hidden'></div>
		</section>
	);
}

export default GenericFiltersSkeleton;
