import { Logo } from '../../../../shared-components/logo/logo';
import styles from './flights-header-v1.module.scss';

export interface FlightsHeaderV1Props {
	logoUrl: string;
}

export function FlightsHeaderV1(props: FlightsHeaderV1Props): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='header object-none object-top absolute w-full z-10  pt-6 px-10 md:px-28'>
				<a href='/' className={'header__logo'}>
					<Logo url={'https://res.cloudinary.com/ultragroup/image/upload/c_limit,h_100,w_200/utr8aiyycdl7iv9ahpjl'} />
				</a>
			</div>
		</>
	);
}

export default FlightsHeaderV1;
