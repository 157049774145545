import { FaresummaryResumen } from '../../../../lib/shared-components/fare-summary-resumen/fare-summary-resumen';
import { SummaryDetail } from '../../../../shared/interfaces/summary-detail.interface';
import { RecommendationV2 } from '../../../../lib/flights/components';
import { AlertStates, ModelTypeEnum } from '../../../../shared/enums';
import { FlightSearchResult } from '../../../../lib/flights/shared';
import { IcomoonIcon } from '../../../../lib/ui-elements';
import { useCallback, useEffect, useState } from 'react';
import styles from './flight-detail.module.scss';
export interface FlightDetailProps {
	callBack: (checkoutId: string) => void;
	flightData: SummaryDetail<FlightSearchResult>;
	t?: (item: string) => string;
	colorPrimary?: string;
	colorSecondary?: string;
}

export function FlightDetail({ colorSecondary, colorPrimary, flightData, callBack, t }: FlightDetailProps): JSX.Element {
	const [modelType, setModelType] = useState<ModelTypeEnum>(ModelTypeEnum.CASH);
	t = t as (item: string) => string;

	useEffect(() => {
		if ((flightData?.flights as FlightSearchResult[])?.length) {
			const firstOption: number = 0;
			const modelType: FlightSearchResult = flightData?.flights[firstOption];
			setModelType(modelType.businessModel as ModelTypeEnum);
		}
	}, [flightData]);

	const backHistory = useCallback((): void => {
		const validateHistory: boolean = history?.back as unknown as boolean;
		if (!validateHistory) {
			window.location.href = '/';

			return;
		}

		history.back();
	}, []);

	const goToCheckout = (): void => {
		callBack(flightData.checkoutId);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className='flightDetail'>
				<div className='flightDetail__header flex flex-col justify-start'>
					<div className='flex items-center cursor-pointer mb-[20px]' onClick={backHistory}>
						<IcomoonIcon icon={'chevronLeftIco'} className='mr-[10px]' fontSize={16} />
						<p className='flightDetail__backHistory text-xs md:text-[21px]'>{t('back') || ''}</p>
					</div>

					<h4 className='flightDetail__title text-[24px]'>{t('purchaseSummary')}</h4>
				</div>

				<div className='flightDetail__body flex flex-col md:flex-row  mt-6'>
					<div className='flightDetail__body-item order-1 md:order-0 w-full md:w-[55%] mt-[25px] md:mt-0'>
						{flightData?.notifications?.map((notification, index) => (
							<div key={index}>
								{notification?.type === AlertStates.warning && (
									<div className='flightDetail__notifications flex items-center rounded-lg bg-white shadow-lg p-3 mb-5'>
										<span className='infoCirlce2Ico mr-3' style={{ color: colorPrimary }}></span>
										<p className='flightDetail__terms-hint text-xs'>{notification?.label}</p>
									</div>
								)}
							</div>
						))}

						<div className='flightDetail__terms flex items-center rounded-lg bg-white shadow-lg p-3 mb-5'>
							<span className='infoCirlce2Ico mr-3' style={{ color: colorPrimary }}></span>
							<p className='flightDetail__terms-hint text-xs'>
								{t('cancelationTermsShortDescription')}.{' '}
								<a href='/' target='_blank' className='flightDetail__terms-conditions' style={{ color: colorPrimary }}>
									{t('seeContidions')}
								</a>{' '}
							</p>
						</div>

						<RecommendationV2
							t={t}
							colorPrimary={colorPrimary}
							tagTitle={'modelTypeTagTitles?.tag' || ''}
							toolTipText={'modelTypeTagTitles?.tooltip' || ''}
							flightData={(flightData?.flights as FlightSearchResult[]) || []}
							onlySelectedRecommendation={true}
							skeletonRecommendation={2}
							pillMessageText={'unbeatableSale'}
						/>
					</div>

					<div className='flightDetail__body-item order md:order-1 w-full md:w-[45%]'>
						<div className='flightDetail__bodyFare md:pl-[60px] lg:pr-[60px]'>
							<FaresummaryResumen
								t={t}
								businessModel={modelType}
								faresummary={flightData?.fareSummary}
								colorPrimary={colorSecondary || ''}
								callBack={goToCheckout}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
