import { PassengersFieldInputNamesFlightsSearchFormInterface } from '../../../../flights/shared';
import { InputEventsHandler, OptionSelect } from '../../../../../shared/interfaces';
import { Select } from '../../../../ui-elements';

export interface CabinMobileProps {
	cabinsOptions: OptionSelect[];
	cabinModalValue: string;
	tempPassengersNames: PassengersFieldInputNamesFlightsSearchFormInterface;
	traductions: (value: string) => string;
	cabinHandler: (e: InputEventsHandler) => void;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	colorSecondary?: string;
}

export function CabinMobile({
	cabinsOptions,
	cabinModalValue,
	tempPassengersNames,
	traductions,
	cabinHandler,
	isSecondaryTemplate,
	isTertiaryTemplate,
	colorSecondary,
}: CabinMobileProps): JSX.Element {
	return (
		<div
			className={`passengersFieldInputs__cabinInput
				${isTertiaryTemplate ? 'md:py-[12px] py-[20px]' : 'py-[20px] md:px-[45px]'}
				${isSecondaryTemplate ? 'px-[16px]' : ''}
		`}
		>
			<p
				className={`m-0 whitespace-nowrap 
					${isSecondaryTemplate ? 'pb-[6px] text-[#344054] text-sm font-medium leading-5' : 'pb-[20px] text-[#616161] text-base'}
					${isTertiaryTemplate ? '!text-[13px] !text-[#4D4D4F] font-medium' : 'passengersFieldInputs__title'}
				`}
				style={{ paddingBottom: isTertiaryTemplate ? '2px' : '' }}
			>
				{isTertiaryTemplate ? traductions('class') || 'class' : traductions('selectTheClass') || 'selectTheClass'}
			</p>
			<Select
				selectClassName={`${
					isTertiaryTemplate
						? 'py-2.5 px-3.5 md:p-0 border-[#BEBEBE] text-[#4D4D4F] text-[14px] border border-solid rounded-none'
						: 'bg-transparent !p-0 m-0 mt-0 !text-base placeholder:text-base'
				}`}
				className={`${
					isSecondaryTemplate
						? 'gap-2 py-2.5 px-3.5 rounded-md border passengersFieldInputs__cabinInput-Select'
						: isTertiaryTemplate
							? 'text-[#4D4D4F] py-2.5 px-3.5 gap-2 mt-1 py-2.5 px-3.5 border border-solid border-[#BEBEBE] text-[14px] rounded-none'
							: 'py-[18px] px-[25px] border border-solid border-[#7A8083] rounded-[10px]'
				} `}
				customSelectClassName='bg-transparent !p-0 m-0 text-base'
				customSelectPlaceholderClassName='bg-transparent !p-0 text-xs font-normal text-[#C3C7CE]'
				name={tempPassengersNames.hiddenCabinName}
				options={cabinsOptions || []}
				onChange={cabinHandler}
				defaultValue={cabinModalValue}
				isSecondaryTemplate={isSecondaryTemplate}
				isTertiaryTemplate={isTertiaryTemplate}
				placeHolder={traductions('selectTheClass') || 'selectTheClass'}
				colorSecondary={colorSecondary}
			/>
		</div>
	);
}

export default CabinMobile;
