import {
	CustomTraductionsLabelsPassengersFieldInterface,
	PassengersFieldRestrictionsInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import PassengersField from '../../../shared-components/search/passengers-field/passengers-field';
import ButtonSearch from '../../../shared-components/search/button-search/button-search';
import { SpecialEventsLayout } from '../../shared/enums/special-events.enum';
import useDisneySearchForm from '../../shared/hooks/useDisneySearchForm';
import { IcomoonIcon, Input, Button } from '../../../ui-elements';
import { InputType } from '../../../../shared/enums/inputs.enum';
import {
	UseDisneySearchFormInterface,
	UseDisneySearchFormReturnInterface,
	DisneySearchFormSubmitInterface,
} from '../../shared/interfaces/disney-search-form.interface';
import styles from './special-events-search-form.module.scss';
import { useMemo } from 'react';

export interface SpecialEventsSearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: (label: string) => string;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	history: DisneySearchFormSubmitInterface | null;
	emitSubmitSearch: (data: DisneySearchFormSubmitInterface) => void;
	layout?: SpecialEventsLayout;
	passengerButton?: string;
}

export function SpecialEventsSearchForm({
	colorPrimary,
	colorSecondary,
	traductions,
	passengersRestrictions,
	history,
	emitSubmitSearch,
	layout,
	passengerButton,
}: SpecialEventsSearchFormProps): JSX.Element {
	const {
		passengersName,
		passengersId,
		withPromoCodeName,
		mobileFieldStep,
		buttonSearchHistory,
		promoCode,
		showMobileFieldsContainer,
		mobileFormHeight,
		errors,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		promoCodeHandler,
		onSubmit,
	}: UseDisneySearchFormReturnInterface = useDisneySearchForm({
		history,
		traductions,
		emitSubmitSearch,
		passengersRestrictions,
		isSpecialEvents: true,
	} as unknown as UseDisneySearchFormInterface);

	layout = layout || SpecialEventsLayout.BUTTON;

	const watchWithPromoCode = watch(withPromoCodeName);
	const passengersLabels: CustomTraductionsLabelsPassengersFieldInterface = useMemo((): CustomTraductionsLabelsPassengersFieldInterface => {
		return {
			adultsDescription: `(${traductions('labelAdultDisney')})`,
			childrenDescription: `(${traductions('labelChildrenDisney')})`,
		};
	}, []);

	const promoCodeInputElement: JSX.Element = (
		<Input
			value={promoCode}
			onChange={promoCodeHandler}
			type={InputType.text}
			label={`${traductions('promoCode') || 'promoCode'}`}
			placeholder={traductions('promoCode') || 'promoCode'}
			defaultValue={''}
			name={'promoCode'}
			className={'w-full !hidden bg-white rounded-[10px] border border-solid py-[10px] px-[25px] flex-col justify-center'}
			labelClassName={'block text-xs'}
			inputClassName={'block w-full bg-white text-base'}
			style={{
				color: colorSecondary,
			}}
		/>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={'specialEventsSearchForm w-full flex flex-col'}>
				{layout === SpecialEventsLayout.BUTTON && (
					<>
						<form onSubmit={onSubmit} className='specialEventsSearchForm__button flex items-center cursor-pointer flex-col md:flex-row'>
							<Button
								type='submit'
								customClass='specialEventsSearchForm__searchBtnTicket w-full md:w-auto bg-transparent flex flex-col md:flex-row items-center'
								content={
									<>
										<div className='specialEventsSearchForm__buttonItem w-full md:w-auto border-b-[3px] md:border-b-[0px] md:border-r-[3px] border-dotted flex items-center bg-[#00A8DC] py-[15px] md:px-[22px] rounded-tr-lg rounded-tl-lg md:rounded-tr-none md:rounded-bl-lg'>
											<IcomoonIcon
												icon='iconCastle'
												className='text-white ml-[23px] md:ml-auto mr-4 w-[10px] h-[10px] relative top-[-2px]'
												fontSize={25}
											/>
											<p className='specialEventsSearchForm__buttonTitle text-white'>{traductions('ticketsForSpecialEvents') || ''}</p>
										</div>

										<div
											className='specialEventsSearchForm__buttonItem w-full md:w-auto flex items-center py-[15px] md:px-[22px] md:rounded-tr-lg rounded-br-lg rounded-bl-lg md:rounded-bl-none'
											style={{ background: colorPrimary }}
										>
											<IcomoonIcon icon='iconDoubleTickets' className='text-white ml-[23px] md:ml-auto mr-4 w-[10px] h-[10px]' fontSize={20} />
											<p className='specialEventsSearchForm__buttonSecondTitle text-white'>{traductions('buyNow') || ''}</p>
										</div>
									</>
								}
								styles={{
									background: 'transparent',
									padding: '0',
								}}
							/>

							<div className='!hidden'>
								<PassengersField
									fieldId={passengersId}
									traductions={traductions}
									title={traductions('passengers') || 'passengers'}
									placeHolder={traductions('passengers') || 'passengers'}
									name={passengersName}
									backButtonTraduction={traductions('back') || 'back'}
									nextButtonTraduction={passengerButton}
									restrictions={passengersRestrictions}
									useBackButton={false}
									required={true}
									customTraductionsLabels={passengersLabels}
									colorSecondary={colorSecondary}
									className={`w-full ${mobileFieldStep === passengersId ? 'z-50' : 'z-0'} md:z-auto md:h-[65px]`}
									desktopModalClassName=''
									register={register}
									watch={watch}
									setValue={setValue}
									getValues={getValues}
									trigger={trigger}
									errors={errors}
									nextCallBack={nextMobileFieldHandler}
									visibleTitle={true}
									backCallBack={backMobileFieldHandler}
									iconClassName='searchAdultIco'
									disabledInputs={{
										infants: true,
									}}
									passengersAlwaysVisible={true}
									passengersAmountsRestrictions={20}
									maxGuestsPerRoom={20}
									useMinimunOnePassenger={true}
								>
									<div className='specialEventsSearchForm__promoCode flex md:hidden mt-[20px]'>{promoCodeInputElement}</div>
								</PassengersField>
							</div>
						</form>
					</>
				)}

				{layout === SpecialEventsLayout.SEARCH && (
					<>
						<div className='specialEventsSearchForm__searchButton block md:hidden'>
							<ButtonSearch
								iconName='searchCalendarIco'
								placeHolder={traductions('selectYourPassengers') || 'selectYourPassengers'}
								emitClick={() => showMobileFieldsContainerHandler(true)}
								colorSecondary={colorSecondary}
								history={buttonSearchHistory}
								searchText={traductions('search') || 'search'}
							/>
						</div>
						<form
							onSubmit={onSubmit}
							className={`specialEventsSearchForm__form w-full top-0 left-0 md:flex flex-col ${
								showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
							}`}
							style={{
								height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
							}}
						>
							<div
								className='specialEventsSearchForm__header block md:hidden pt-[33px] pb-[53px] px-[45px]'
								style={{
									backgroundColor: colorSecondary,
								}}
							>
								<button
									onClick={() => showMobileFieldsContainerHandler(false)}
									className='specialEventsSearchForm__backBtn chevronLeftIco !p-0 text-white text-base font-bold'
									type='button'
								>
									<span className='pl-[10px]'>{traductions('getBack') || 'getBack'}</span>
								</button>
							</div>

							<div
								className={
									'specialEventsSearchForm__body bg-white md:bg-transparent left-0 top-[-20px] md:top-0 relative md:h-auto px-0 pt-[20px] md:pt-0 rounded-t-[20px] md:rounded-t-none'
								}
							>
								<div className='specialEventsSearchForm__content relative'>
									<div className={'specialEventsSearchForm__lastRow flex flex-row flex-wrap md:mt-[15px] items-end'}>
										<div
											className={`specialEventsSearchForm__passengers w-full relative flex flex-row flex-wrap md:pr-[100px] ${
												watchWithPromoCode ? 'md:w-2/5' : ''
											}`}
										>
											<PassengersField
												fieldId={passengersId}
												traductions={traductions}
												title={traductions('passengers') || 'passengers'}
												placeHolder={traductions('passengers') || 'passengers'}
												backButtonTraduction={traductions('back') || 'back'}
												name={passengersName}
												nextButtonTraduction={passengerButton}
												restrictions={passengersRestrictions}
												useBackButton={false}
												required={true}
												customTraductionsLabels={passengersLabels}
												colorSecondary={colorSecondary}
												className={`w-full ${mobileFieldStep === passengersId ? 'z-50' : 'z-0'} md:z-auto md:h-[65px]`}
												desktopModalClassName=''
												register={register}
												watch={watch}
												setValue={setValue}
												getValues={getValues}
												trigger={trigger}
												errors={errors}
												nextCallBack={nextMobileFieldHandler}
												visibleTitle={true}
												iconClassName='searchAdultIco'
												disabledInputs={{
													infants: true,
												}}
												passengersAmountsRestrictions={20}
												maxGuestsPerRoom={20}
												passengersAlwaysVisible={true}
												useMinimunOnePassenger={true}
											>
												<div className='specialEventsSearchForm__promoCode flex md:hidden mt-[20px]'>{promoCodeInputElement}</div>
											</PassengersField>
										</div>

										<div className={'specialEventsSearchForm__footer md:relative md:w-[35%] hidden md:flex md:h-[45px] pb-[6px]'}>
											<Button
												type='submit'
												customClass='specialEventsSearchForm__searchBtn rounded-lg text-white w-full text-[21px] items-center'
												content={
													<>
														<span className={`text-[25px] font-extrabold ${passengerButton ? '' : 'searchIco'}`} />
														<span className='text-[15px] font-extrabold pl-[10px] relative right-[5px]'>
															{passengerButton || traductions('search') || 'search'}
														</span>
													</>
												}
												styles={{
													background: colorPrimary || '#878787',
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
						<div className='disneySearchForm__freeEntranceMessage md:text-[18px] md:font-extrabold text-sm font-semibold  text-left text-white'>
							<p>{traductions('specialEventsMessage') || ''}</p>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default SpecialEventsSearchForm;
