import { RentalCarInfoInterfaceV2, SpecialServicesIconsInterface } from '../interfaces';

export enum InputsFilterCarsNamesV2 {
	currency = 'currency',
	order = 'order',
	type = 'type',
	company = 'company',
	transmission = 'transmission',
	rate = 'rate',
	carDetailCurrency = 'carDetailCurrency',
	minRate = 'minRate',
	maxRate = 'maxRate',
}

export enum OrderOptionsValuesV2 {
	cheap = 'cheap',
	expensive = 'expensive',
}

export enum DisplayTypeV2 {
	list = 'list',
	grid = 'grid',
}

export enum TransmissionTypeV2 {
	automatic = 'automatic',
	manual = 'manual',
}

export enum CarsRatesModelsV2 {
	rate = 'rate',
	equivalentRate = 'equivalentRate',
}

export const AllOptionsV2 = 'all';

export enum SelectedRateV2 {
	rate = 'rate',
	equivalentRate = 'equivalentRate',
}

export enum CarGroupedCoveragesCodesV2 {
	only = 'only',
	basic = 'basic',
	basicPlus = 'basicPlus',
	basicPlusGps = 'basicPlusGps',
	full = 'full',
	fullGps = 'fullGps',
}

export enum CarCoveragesTagsV2 {
	onlyCar = 'onlyCar',
	insurances = 'insurances',
	lowDamageWaiver = 'lowDamageWaiver',
	taxes = 'taxes',
	liabilitySupplementalInsurance = 'liabilitySupplementalInsurance',
	secondDriver = 'secondDriver',
	fullGas = 'fullGas',
}

export enum SpecialServicesCodes {
	nav = 'NAV',
	bst = 'BST',
	csi = 'CSI',
	hcl = 'HCL',
	csr = 'CSR',
	cst = 'CST',
	hcr = 'HCR',
	sno = 'SNO',
	skv = 'SKV',
}

export const SpecialServicesIcons: SpecialServicesIconsInterface = {
	NAV: 'navIco',
	BST: 'bstIco',
	CSI: 'csiIco',
	HCL: 'hclIco',
	CSR: 'csrIco',
	CST: 'cstIco',
	HCR: 'hcrIco',
	SNO: 'snoIco',
	SKV: 'skvIco',
};

export enum RentalCarsNamesV2 {
	hertz = 'hertz',
	dollar = 'dollar',
	thrifty = 'thrifty',
	fireFly = 'firefly',
}

export const RentalCarsLogosV2: RentalCarInfoInterfaceV2 = {
	hertz: {
		filled: <span className='hertzIco' />,
		color: (
			<span className='icon-Logo-Hertz---Color'>
				<span className='path1'></span>
				<span className='path2'></span>
				<span className='path3'></span>
				<span className='path4'></span>
				<span className='path5'></span>
				<span className='path6'></span>
				<span className='path7'></span>
				<span className='path8'></span>
			</span>
		),
		primaryColor: '#FDD008',
		secondaryColor: '#000000',
	},
	dollar: {
		filled: <span className='dollarIco' />,
		color: (
			<span className='icon-Logo-Dollar---Color'>
				<span className='path1'></span>
				<span className='path2'></span>
				<span className='path3'></span>
				<span className='path4'></span>
				<span className='path5'></span>
				<span className='path6'></span>
				<span className='path7'></span>
				<span className='path8'></span>
				<span className='path9'></span>
				<span className='path10'></span>
				<span className='path11'></span>
			</span>
		),
		primaryColor: '#E51733',
		secondaryColor: '#FFFFFF',
	},
	thrifty: {
		filled: <span className='thriftyIco' />,
		color: <span className='icon-Logo-Thrifty---Color'></span>,
		primaryColor: '#0068B7',
		secondaryColor: '#FFFFFF',
	},
	firefly: {
		filled: <span className='fireFlyIco' />,
		color: (
			<span className='icon-Logo-Firefly---Color'>
				<span className='path1'></span>
				<span className='path2'></span>
				<span className='path3'></span>
				<span className='path4'></span>
				<span className='path5'></span>
				<span className='path6'></span>
				<span className='path7'></span>
				<span className='path8'></span>
				<span className='path9'></span>
				<span className='path10'></span>
				<span className='path11'></span>
				<span className='path12'></span>
				<span className='path13'></span>
				<span className='path14'></span>
				<span className='path15'></span>
				<span className='path16'></span>
				<span className='path17'></span>
				<span className='path18'></span>
				<span className='path19'></span>
				<span className='path20'></span>
				<span className='path21'></span>
				<span className='path22'></span>
				<span className='path23'></span>
				<span className='path24'></span>
				<span className='path25'></span>
				<span className='path26'></span>
				<span className='path27'></span>
				<span className='path28'></span>
				<span className='path29'></span>
				<span className='path30'></span>
				<span className='path31'></span>
				<span className='path32'></span>
				<span className='path33'></span>
				<span className='path34'></span>
				<span className='path35'></span>
				<span className='path36'></span>
				<span className='path37'></span>
				<span className='path38'></span>
				<span className='path39'></span>
				<span className='path40'></span>
				<span className='path41'></span>
				<span className='path42'></span>
				<span className='path43'></span>
				<span className='path44'></span>
				<span className='path45'></span>
				<span className='path46'></span>
				<span className='path47'></span>
				<span className='path48'></span>
				<span className='path49'></span>
				<span className='path50'></span>
				<span className='path51'></span>
				<span className='path52'></span>
				<span className='path53'></span>
				<span className='path54'></span>
				<span className='path55'></span>
				<span className='path56'></span>
				<span className='path57'></span>
				<span className='path58'></span>
				<span className='path59'></span>
				<span className='path60'></span>
				<span className='path61'></span>
				<span className='path62'></span>
				<span className='path63'></span>
				<span className='path64'></span>
				<span className='path65'></span>
				<span className='path66'></span>
				<span className='path67'></span>
				<span className='path68'></span>
				<span className='path69'></span>
				<span className='path70'></span>
				<span className='path71'></span>
				<span className='path72'></span>
				<span className='path73'></span>
				<span className='path74'></span>
				<span className='path75'></span>
				<span className='path76'></span>
				<span className='path77'></span>
				<span className='path78'></span>
				<span className='path79'></span>
				<span className='path80'></span>
				<span className='path81'></span>
				<span className='path82'></span>
				<span className='path83'></span>
				<span className='path84'></span>
				<span className='path85'></span>
				<span className='path86'></span>
				<span className='path87'></span>
				<span className='path88'></span>
				<span className='path89'></span>
				<span className='path90'></span>
				<span className='path91'></span>
				<span className='path92'></span>
				<span className='path93'></span>
				<span className='path94'></span>
				<span className='path95'></span>
				<span className='path96'></span>
				<span className='path97'></span>
				<span className='path98'></span>
				<span className='path99'></span>
				<span className='path100'></span>
				<span className='path101'></span>
			</span>
		),
		primaryColor: '#722D84',
		secondaryColor: '#000000',
	},
};
