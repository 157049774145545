import DisneyRecommendationDetail from '../../components/disney-recommendation-detail/disney-recommendation-detail';
import { DisneyParkFareDetail, DisneyParkSearchResult, DisneyParkFareGroup } from '../../shared/interfaces';
import { numberToLocaleWithcurrency } from '../../../../shared/services/utils.service';
import DisneySkeletonRecommendation from './skeleton/disney-recommendation-skeleton';
import { Button, IcomoonIcon } from '../../../ui-elements';
import { ModelTypeEnum } from '../../../../shared/enums';
import styles from './disney-recommendation.module.scss';
import { translateDay } from '../../../../shared/utils';
import { PassangerType } from '../../shared/enums';
import { useEffect, useState } from 'react';

export interface DisneyRecommendationProps {
	callBack?: (data: DisneyParkSearchResult) => void;
	t: (value: string) => string;
	colorPrimary?: string;
	colorSecondary?: string;
	disneyData: DisneyParkSearchResult[];
	skeletonRecommendation?: number;
	disabledButtons: boolean;
	isAresLookAndFeel?: boolean;
}

export interface DisneyValidDatesProps {
	disneyData: DisneyParkSearchResult[];
	t: (value: string) => string;
}

export function DisneyRecommendation({
	skeletonRecommendation,
	colorPrimary,
	colorSecondary,
	disneyData,
	callBack,
	t,
	disabledButtons,
	isAresLookAndFeel,
}: Readonly<DisneyRecommendationProps>): JSX.Element {
	const [disneyDetailData, setDisneyDetailData] = useState<DisneyParkSearchResult>();
	const [disneyResult, setDisneyResult] = useState<DisneyParkSearchResult[]>([]);
	const [openDetail, setOpenDetail] = useState<boolean>(false);
	const [disneyParkFareGroup, setDisneyParkFareGroup] = useState<DisneyParkFareGroup[]>([]);
	const defaultItemSkeleton: number = 8;
	const useAsPrimaryColor: string | undefined = isAresLookAndFeel ? colorSecondary : colorPrimary;
	const useAsFontWeight: string = isAresLookAndFeel ? 'font-medium' : 'font-bold';
	const useAresFontColor: string = '#000000';
	useEffect(() => {
		if (openDetail) {
			(document.querySelector('body') as HTMLBodyElement).style.overflow = 'hidden';

			return;
		}

		(document.querySelector('body') as HTMLBodyElement).style.overflow = 'auto';
	}, [openDetail]);

	useEffect(() => {
		if (!disneyData?.length) {
			setOpenDetail(false);
		}

		setDisneyResult(disneyData || []);
	}, [disneyData]);

	const openDetailComponent = (entry: DisneyParkSearchResult): void => {
		setDisneyDetailData(entry);

		setOpenDetail(true);
	};

	const Badge = (): JSX.Element => {
		return (
			<div
				className='disney-recommendation-badge absolute top-0 right-0 flex rounded-tr-[4000px] rounded-l-full px-3 py-[2px]'
				style={{ backgroundColor: colorSecondary }}
			>
				<p className={`text-[16px] text-white ${isAresLookAndFeel ? 'font-medium' : 'font-semibold'}`}>{t('bestPrice')}</p>
				<IcomoonIcon className='ml-3' icon='iconTag' color='white' />
			</div>
		);
	};

	const passengerTranslation = (type: PassangerType): string => {
		const translations = {
			adult: t('olderThan10Years'),
			child: t('olderThan3years'),
		};

		return translations[type] ? translations[type].toLowerCase() : '';
	};

	const PassengerPrice = ({ passenger, showPoints }: { passenger: DisneyParkFareDetail; showPoints: boolean }): JSX.Element => {
		return (
			<div className='flex itemx-center justify-center'>
				<p className='disney-recommendation__passengerPrice text-xs flex flex-col mr-4'>
					<span
						className={`disney-recommendation__passengerPriceLabel ${
							isAresLookAndFeel ? 'disney-recommendation__passenger-price-label-ares' : ''
						} ${useAsFontWeight}`}
					>
						{t('pricePer')} {t(passenger.passengerType) ? t(passenger.passengerType).toLowerCase() : ''}
					</span>

					<span
						className={`disney-recommendation__passengerTypeLabel text-[10px] ${
							isAresLookAndFeel ? 'disney-recommendation__passenger-price-label-ares' : ''
						}`}
					>
						{passengerTranslation(passenger.passengerType)}
					</span>
				</p>

				<p
					className={`disney-recommendation__passengerPrice text-xs ${isAresLookAndFeel ? 'disney-recommendation__passenger-price-label-ares' : ''}`}
				>
					{showPoints ? `(${numberToLocaleWithcurrency(passenger.requiredPoints ?? 0, passenger.currency)} ${t('points')} / ` : `(${passenger.totalFormated ?? ''} / `}
					{t('ticket') ? t('ticket').toLowerCase() + ')' : ''}
				</p>
			</div>
		);
	};

	const PassengerPriceCash = (passenger: DisneyParkFareDetail): JSX.Element => {
		return <PassengerPrice passenger={passenger} showPoints={false} />;
	};

	const PassengerPointsRequired = (passenger: DisneyParkFareDetail): JSX.Element => {
		return <PassengerPrice passenger={passenger} showPoints={true} />;
	};

	const Price = ({ priceFormat, bestPrice }: { priceFormat: string; bestPrice: boolean }): JSX.Element => {
		let bestPriceColor: string | undefined;

		if (bestPrice) {
			bestPriceColor = colorSecondary;
		} else if (isAresLookAndFeel) {
			bestPriceColor = useAresFontColor;
		} else {
			bestPriceColor = useAsPrimaryColor;
		}

		return (
			<>
				<div className='disney-recommendation__priceValue flex text-[30px] justify-center leading-none mb-3' style={{ color: bestPriceColor }}>
					{bestPrice && <IcomoonIcon fontSize={12} className='mr-2' icon='iconBestPrice' />}
					<div className={`text-[15px] flex relative items-end right-[5px] ${isAresLookAndFeel ? 'font-medium' : 'font-semibold'}`}>{t('from')}</div>
					<div>{priceFormat}</div>
				</div>
				<p
					className={`disney-recommendation__priceDayLabel text-center text-xs mb-3 ${useAsFontWeight} ${
						isAresLookAndFeel ? 'disney-recommendation__price-day-label-ares' : ''
					}`}
				>
					{t('finalPriceAverage')} <span className={`disney-recommendation__priceDay ${useAsFontWeight}`}>{t('perDay').toLowerCase()}</span>
				</p>
			</>
		);
	};

	const Points = ({ pointsRequired, pointsName }: { pointsRequired: number; pointsName: string }): JSX.Element => {
		return (
			<>
				<div
					className='disney-recommendation__priceValue flex flex-col items-center justify-center leading-none mb-3'
					style={{ color: pointsName ? useAsPrimaryColor : colorSecondary }}
				>
					<div className={`flex items-end mb-1 ${isAresLookAndFeel ? 'font-medium' : 'font-semibold'}`}>
						<div className='text-[18px] mr-[5px]'>{t('from')}</div>
						<div className={`text-[25px] ${useAsFontWeight}`}>{` ${numberToLocaleWithcurrency(pointsRequired)}`}</div>
					</div>

					<div className={`w-full flex justify-center text-[23px] ${isAresLookAndFeel ? 'font-medium' : 'font-semibold'}`}>{pointsName}</div>
				</div>
				<p className='disney-recommendation__priceDayLabel text-center text-xs mb-3'>
					{t('finalPriceAverage')} <span className={`disney-recommendation__priceDay ${useAsFontWeight}`}>{t('perDay').toLowerCase()}</span>
				</p>
			</>
		);
	};

	const PointsPlusMoney = ({
		pointsRequired,
		pointsName,
		priceFormated,
		currencySymbol,
		bestPrice,
	}: {
		pointsRequired: number;
		pointsName: string;
		priceFormated: number;
		currencySymbol: string;
		bestPrice: boolean;
	}): JSX.Element => {
		let priceValueColor: string | undefined;

		if (!pointsName) {
			priceValueColor = colorSecondary;
		} else if (isAresLookAndFeel) {
			priceValueColor = useAresFontColor;
		} else {
			priceValueColor = useAsPrimaryColor;
		}

		let bestPriceColor: string | undefined;

		if (bestPrice) {
			bestPriceColor = colorSecondary;
		} else if (isAresLookAndFeel) {
			bestPriceColor = useAresFontColor;
		} else {
			bestPriceColor = useAsPrimaryColor;
		}

		return (
			<>
				<div className='disney-recommendation__priceValue flex justify-center items-end' style={{ color: priceValueColor }}>
					<div className={`flex text-[15px] mr-[5px] mb-[4px] ${isAresLookAndFeel ? 'font-medium' : 'font-semibold'}`}>{t('from')}</div>
					<div className={`text-[23px] ${useAsFontWeight}`}>
						{` ${numberToLocaleWithcurrency(pointsRequired)}`} {pointsName}
					</div>
				</div>

				<div className='disney-recommendation__priceValue flex text-[15px] justify-center leading-none mb-2' style={{ color: bestPriceColor }}>
					{bestPrice && <IcomoonIcon fontSize={12} className={`mr-2 ${useAsFontWeight}`} icon='iconBestPrice' />}
					<div className={`mb-2 text-[18px] ${useAsFontWeight}`}>
						{' '}
						+{` $ ${numberToLocaleWithcurrency(priceFormated)}`} {currencySymbol}
					</div>
				</div>
				<p className='disney-recommendation__priceDayLabel text-center text-xs mb-3'>
					{t('finalPriceAverage')}{' '}
					<span className={`disney-recommendation__priceDay ${useAsFontWeight}`}>{t('perDay') ? t('perDay').toLowerCase() : ''}</span>
				</p>
			</>
		);
	};

	const SelectedButtom = (): JSX.Element => {
		return (
			<div
				style={{ backgroundColor: colorSecondary }}
				className='disney-recommendation-button__selected flex rounded-b absolute left-0 bottom-0 w-full py-3 justify-center items-center'
			>
				<IcomoonIcon className='mr-2 text-white' icon='checkRoundIco' />
				<span className={`text-white text-[14px] ${isAresLookAndFeel ? 'font-medium' : 'font-semibold'}`}>{t('selectedEntry')}</span>
			</div>
		);
	};

	const selectedItem = (current: DisneyParkSearchResult): boolean => {
		return disneyDetailData === current;
	};

	useEffect(() => {
		setDisneyParkFareGroup(disneyDetailData?.fareGroup || []);
	}, [disneyDetailData]);

	const fareGroupRecommended = (): DisneyParkFareGroup | undefined => {
		return disneyParkFareGroup.find((fareGroup: DisneyParkFareGroup) => fareGroup.isRecommended);
	};

	const isRangeDay = (disneyFareGroup?: DisneyParkFareGroup): boolean => {
		const rangeDay: number = 1;
		if (!disneyFareGroup) {
			return false;
		}

		return disneyFareGroup.durationDays + disneyFareGroup.usageBufferDays > rangeDay;
	};

	const RangeDay = (): JSX.Element => {
		return (
			<>
				{fareGroupRecommended()?.usageDate.startDateFormated} {t('to').toLowerCase()} {fareGroupRecommended()?.usageDate.endDateFormated}
			</>
		);
	};

	const RangeDayText = (): JSX.Element => {
		const theLowerCase: string = t('the').toLowerCase();
		return (
			<>
				{disneyDetailData?.information.durationDays} {translateDay(t, disneyDetailData?.information.durationDays).toLowerCase()} {t('toVisitParks')}{' '}
				{isRangeDay(fareGroupRecommended()) ? t('comeIn').toLowerCase() + ' ' + theLowerCase : theLowerCase}{' '}
			</>
		);
	};

	const ValidDates = (): JSX.Element => {
		return (
			<>
				<section className={`flex mt-[100px] mb-4 text-[12px] ${isAresLookAndFeel ? 'text-[#4D4D4F]' : 'text-gray-500'} `}>
					<IcomoonIcon fontSize={24} icon='calendarBlankIco self-start' className='mr-2' />
					<div className='recomendation-detail__seeDetails'>
						<div className='flex flex-col mb-3'>
							<p className={useAsFontWeight}>{t('validTicketDate')}</p>
							<p className='text-xs'>
								{t('yourTicketsAreValid')} <RangeDayText />
								{isRangeDay(fareGroupRecommended()) ? <RangeDay /> : fareGroupRecommended()?.usageDate.startDateFormated}
								{', ' + t('subjectToAvailability')}
							</p>
						</div>
					</div>
				</section>
			</>
		);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={'disney-recommendation flex flex-wrap'}
				style={{
					marginBottom: !openDetail && disneyDetailData ? '0px' : 'mb-10',
				}}
			>
				{!disneyData?.length
					? Array.from(Array(skeletonRecommendation || defaultItemSkeleton).keys()).map((_, index: number) => (
						<DisneySkeletonRecommendation key={index} />
					  ))
					: disneyResult.map((parkEntrance, index: number) => {
						const { information, paymentMethod } = parkEntrance;
						return (
							<div
								style={{
									border: `${selectedItem(parkEntrance) ? `solid ${colorSecondary || ''}` : 'none'}`,
								}}
								key={index}
								className='
								disney-recommendation__card rounded-lg bg-white shadow-lg cursor-pointer
								mx-[6px] my-4 w-full max-w-[350px] xs:max-w-[20%] sm:max-w-[20%] lg:max-w-[20%] xl:max-w-[20%] min-w-[350px] xl:min-w-[350px] lg:min-w-[350px] 2xl:max-w-[17%] mb-[25px] h-[380px]'
								onClick={() => openDetailComponent(parkEntrance)}
							>
								<div className={'disney-recommendation__cardContainer relative flex flex-col justify-between h-full'}>
									{information.bestPrice && <Badge />}
									<div className='disney-recommendation__card-dates flex flex-col flex-wrap px-5 pt-5'>
										<p
											className={`disney-recommendation__cardTitle text-[30px] ${useAsFontWeight}`}
											style={{ color: isAresLookAndFeel ? useAresFontColor : useAsPrimaryColor }}
										>
											{information.durationDays} {translateDay(t, information.durationDays)}
										</p>

										<div className='disney-recommendation__cardContent flex flex-col'>
											<div className='flex flex-row items-center'>
												<IcomoonIcon fontSize={22} color={useAsPrimaryColor} className='h-[21px] self-start mr-2' icon='searchCalendarIco' />
												<p
													className={`disney-recommendation__cardText ${
														isAresLookAndFeel ? 'disney-recommendation__ares-card-text' : ''
													} text-[13px] whitespace-nowrap ${useAsFontWeight}`}
												>
													{t('dateOfAdmission') + ':'}{' '}
													<span className='disney-recommendation__cardText-span font-normal'>{information.usageDate.startDateFormated}</span>
												</p>
											</div>

											<div className='flex flex-row items-center'>
												<IcomoonIcon fontSize={22} color={useAsPrimaryColor} className='h-[21px] self-start mr-2' icon='searchCalendarIco' />
												<p
													className={`disney-recommendation__cardText ${
														isAresLookAndFeel ? 'disney-recommendation__ares-card-text' : ''
													} text-[13px] whitespace-nowrap ${useAsFontWeight}`}
												>
													{t('firstValidEntry') + ':'}{' '}
													<span className='disney-recommendation__cardText-span font-normal'>{information.usageDate.endDateFormated}</span>
												</p>
											</div>
										</div>
									</div>

									<div className='disney-recommendation__price flex flex-col text-center'>
										<div className='disney-recommendation__passanger mb-[9px] h-[50px]'>
											{paymentMethod === ModelTypeEnum.CASH
												? information.fareDetails.map((passenger, pIndex) => <PassengerPriceCash key={pIndex} {...passenger} />)
												: paymentMethod === ModelTypeEnum.POINTS || paymentMethod === ModelTypeEnum.POINT_PLUS_MONEY
													? information.fareDetails.map((passenger, pIndex) => <PassengerPointsRequired key={pIndex} {...passenger} />)
													: null}
										</div>

										<div className='px-5 mt-[20px]'>
											{paymentMethod === ModelTypeEnum.POINTS ? (
												<Points
													pointsRequired={information.fareSummary.totalPerDayPerPassengerPoints as number}
													pointsName={information.fareSummary.pointsName as string}
												/>
											) : paymentMethod === ModelTypeEnum.POINT_PLUS_MONEY || paymentMethod === ModelTypeEnum.POINT_OR_MONEY ? (
												<>
													<PointsPlusMoney
														pointsRequired={information.fareSummary.totalPerDayPerPassengerPoints as number}
														pointsName={information.fareSummary.pointsName as string}
														priceFormated={information.fareSummary.totalPerDayPerPassenger as number}
														currencySymbol={information.fareSummary.currency}
														bestPrice={information.bestPrice || false}
													/>
												</>
											) : (
												<Price
													priceFormat={information.fareSummary.totalPerDayPerPassengerFormated as string}
													bestPrice={information.bestPrice || false}
												/>
											)}
										</div>
									</div>

									<div className='px-5 pb-4'>
										{selectedItem(parkEntrance) && <SelectedButtom />}
										<Button
											customClass={`disney-recommendation__button ${
												selectedItem(parkEntrance) ? 'opacity-0' : ''
											} rounded-md text-white w-full items-center h-[50px] ${isAresLookAndFeel ? 'text-medium' : ''}`}
											styles={{
												background: useAsPrimaryColor,
											}}
											content={<span className={`${isAresLookAndFeel ? 'font-medium' : 'font-semibold'} text-[14px]`}>{t('select')}</span>}
										/>
									</div>
								</div>
							</div>
						);
					  })}

				{openDetail && disneyDetailData && (
					<div className='disney-recommendation__detail'>
						<DisneyRecommendationDetail
							colorPrimary={colorPrimary}
							colorSecondary={colorSecondary}
							t={t}
							closeCallback={(result?: DisneyParkSearchResult) => {
								if (result) {
									callBack && callBack(result);

									return;
								}

								if (!disabledButtons) {
									setOpenDetail(false);
								}
							}}
							disneyDetail={disneyDetailData}
							disabledButtons={disabledButtons}
							isAresLookAndFeel={isAresLookAndFeel}
						/>
					</div>
				)}
			</div>
			<div>{disneyDetailData && <ValidDates />}</div>
		</>
	);
}

export default DisneyRecommendation;
