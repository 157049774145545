import { DisneyLocalStorageInterface } from '../../shared/interfaces/widget-localstorage.interface';
import { DisneySearchWidgetProps } from '../../widgets/disney-search/disneySearchWidget';
import { DisneyService } from '../../shared/services/disney.service';
import { ProductType } from '../../shared/enums/product-types.enum';
import { DisneyUtil } from '../../shared/utils/disney-search.util';
import { Agency } from '../../shared/interfaces/agency.interface';
import { endPoints } from '../../shared/end-points/end-points';
import { useCallback, useMemo, lazy, Suspense } from 'react';
import { TFunction } from 'react-i18next';
import {
	DisneySearchFormSubmitInterface,
	PassengersFieldRestrictionsInterface,
	DisneySearchFormUtil,
	DaysRestrictionDisneySearchFormInterface,
	Spinner,
} from '@smartlinks/react-design-system';

const DisneySearchForm = lazy(async () => {
	return await import('@smartlinks/react-design-system').then(module => {
		return { default: module.DisneySearchForm };
	});
});

interface DisneySearchTemplateProps extends DisneySearchWidgetProps {
	disneyService: DisneyService;
	t: TFunction<'translation', undefined>;
}

function DisneySearch({
	agency,
	disneyService,
	useExternalRedirect,
	useMinimunOnePassenger,
	t,
	callUrlSearch,
	callShowMobileFields,
}: DisneySearchTemplateProps): JSX.Element {
	const passengersRestrictions: PassengersFieldRestrictionsInterface = useMemo(
		() =>
			((tempAgency?: Agency | null): PassengersFieldRestrictionsInterface => {
				const maxDays: number = 20;
				return DisneyUtil.passengersRestrictions(maxDays, maxDays, useMinimunOnePassenger);
			})(agency),
		[agency],
	);

	const calendarRestriction: number = useMemo(
		() =>
			((tempAgency?: Agency | null): number => {
				return DisneyUtil.calendarRestriction(tempAgency);
			})(agency),
		[agency],
	);

	const daysRestriction: DaysRestrictionDisneySearchFormInterface = useMemo(
		() =>
			((): DaysRestrictionDisneySearchFormInterface => {
				return DisneyUtil.daysRestriction();
			})(),
		[],
	);

	const history: DisneySearchFormSubmitInterface | null = useMemo(
		() =>
			((tempDisneyService: DisneyService): DisneySearchFormSubmitInterface | null => {
				return tempDisneyService.getDisneySearchFormHistory(t);
			})(disneyService),
		[disneyService, t],
	);

	const searchHandler = useCallback(
		(data: DisneySearchFormSubmitInterface): void => {
			const dataForm: DisneySearchFormSubmitInterface = {
				...data,
				urlPath: DisneySearchFormUtil.mapSearchUrl(data),
			};

			const dataLocalStorage: DisneyLocalStorageInterface = {
				passengers: data.passengers,
				arrivalDate: data.arrivalDate,
				dates: data.dates,
				withPromoCode: data.withPromoCode,
				promoCode: data.promoCode,
			};

			if (useExternalRedirect) {
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.flightsSearch(agencyDomainToRedirect, dataForm?.urlPath || '');

				createAnchor.click();

				return;
			}

			if (callUrlSearch != null) {
				callUrlSearch(dataForm);
			}

			disneyService.setLocalHistory(dataLocalStorage);
		},
		[disneyService, useExternalRedirect, agency, callUrlSearch],
	);

	const hasPromoCodeActive: boolean = (agency?.couponsConfig?.productsPromoCode || []).some(
		product => product.productType === ProductType.Disney && product.active,
	);

	return (
		<div className='flex w-full'>
			<Suspense fallback={<Spinner />}>
				<DisneySearchForm
					hasPromoCodeActive={hasPromoCodeActive}
					colorPrimary={agency?.lookAndFeel?.style?.primaryColor || '#000000'}
					colorSecondary={agency?.lookAndFeel?.style?.secondaryColor}
					traductions={t}
					daysRestriction={daysRestriction}
					calendarRestriction={calendarRestriction}
					passengersRestrictions={passengersRestrictions}
					history={history}
					emitSubmitSearch={searchHandler}
					emitShowMobileFields={callShowMobileFields}
					useMinimunOnePassenger={useMinimunOnePassenger}
					widgetStyles={agency?.widgetStyles}
				/>
			</Suspense>
		</div>
	);
}

export default DisneySearch;
