import { InputEventsHandler, TimePickerConfig } from '../../../shared/interfaces/inputs.interface';
import { timeToMinutes, timeFromMinutes } from '../../../shared/services/dates-managment.service';
import { DatePickerColors } from '../../../shared/interfaces/date-picker.interface';
import { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import { addAlphaToColor } from '../../../shared/services/utils.service';
import { FormsInputsType } from '../../../shared/enums/inputs.enum';
import styles from './time-picker.module.scss';

export interface TimePickerProps {
	label?: string;
	name?: string;
	value?: string;
	config?: TimePickerConfig;
	maxHeight?: string;
	onClick?: (e: InputEventsHandler) => void;
	colors?: DatePickerColors;
	style?: React.CSSProperties;
	className?: string;
	labelClassName?: string;
	timesContainerClassName?: string;
	timeContainerClassName?: string;
}

export const TimePicker = ({
	label,
	name,
	value,
	config,
	maxHeight,
	onClick,
	colors,
	style,
	className,
	labelClassName,
	timesContainerClassName,
	timeContainerClassName,
}: TimePickerProps): JSX.Element => {
	const generateTimes = useCallback((): string[] => {
		const defaultStartTime: string = '00:00';
		const defaultEndTime: string = '24:00';
		const defaultStepMinutes: number = 1;
		const startMinutes: number = timeToMinutes(config?.startTime ? config.startTime : defaultStartTime);
		const endMinutes: number = timeToMinutes(config?.endTime ? config.endTime : defaultEndTime);
		const stepMinutes: number = config?.stepMinutes ? config.stepMinutes : defaultStepMinutes;
		const times: string[] = [];
		for (let minutes = startMinutes; minutes <= endMinutes; minutes += stepMinutes) {
			times.push(timeFromMinutes(minutes));
		}

		if (times.length && times.includes(times[times.length - 1])) {
			times.pop();
		}

		return [...times];
	}, [config?.startTime, config?.endTime, config?.stepMinutes]);

	const [times, setTimes] = useState<string[]>(generateTimes());
	const olRef = useRef<HTMLOListElement>(null);
	const [isScrollable, setIsScrollable] = useState<boolean>(false);
	const [moveScroll, setMoveScroll] = useState<boolean>(true);
	const changeHandler = (value: string): void => {
		if (onClick) {
			const dataEvent: InputEventsHandler = {
				type: FormsInputsType.timePicker,
				name,
				value,
			};

			onClick(dataEvent);
		}
	};

	useEffect(() => {
		setTimes(generateTimes());
	}, [config?.startTime, config?.endTime, config?.stepMinutes]);

	useEffect(() => {
		const root = document.documentElement;
		if (colors?.main) {
			root?.style.setProperty('--main-time-picker-alpha-color', addAlphaToColor(colors.main, 0.2));

			root?.style.setProperty('--main-time-picker-color', colors.main);
		}
	}, [colors?.main]);

	useEffect(() => {
		if (olRef?.current?.scrollHeight && olRef?.current?.clientHeight) {
			setIsScrollable(olRef.current.scrollHeight > olRef.current.clientHeight);
		}
	}, [olRef]);

	useLayoutEffect(() => {
		if (olRef.current && isScrollable && moveScroll) {
			const activeElement: HTMLLIElement = olRef.current?.getElementsByClassName('timePicker__activeTime')[0] as HTMLLIElement;
			if (activeElement) {
				olRef.current?.scrollTo({
					top: activeElement?.offsetTop - olRef.current?.offsetTop - olRef.current?.clientHeight / 2 + activeElement?.clientHeight / 2,
					behavior: 'smooth',
				});
			}

			setMoveScroll(false);
		}
	}, [olRef, isScrollable, moveScroll]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`timePicker flex-col justify-center ${className || ''}`} style={style}>
				<span className={`timePicker__title pb-2.5 text-center ${labelClassName || ''}`}>{label}</span>
				<ol
					className={`timePicker__timesContainer ${timesContainerClassName || ''}`}
					style={{
						maxHeight,
					}}
					ref={olRef}
				>
					{times.map((item: string): JSX.Element => {
						return (
							<li
								key={item}
								className={`timePicker__time text-center cursor-pointer timePicker__timeContainer ${
									value === item ? 'timePicker__activeTimeContainer timePicker__activeTime' : ''
								} ${timeContainerClassName || ''}`}
								onClick={() => {
									changeHandler(item);
								}}
							>
								{item}
							</li>
						);
					})}
				</ol>
			</div>
		</>
	);
};

TimePicker.defaultProps = {
	className: '',
	labelClassName: '',
	timesContainerClassName: '',
	timeContainerClassName: '',
};

export default TimePicker;
