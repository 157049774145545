import tailwindStyles from 'bundle-text:../../../public/tailwind.css';
import indexStyles from 'bundle-text:../../index.css';
import { publicFonts } from '../../global-fonts';

export class CommonsUtil {
	public static async createShadowRoot(getElement: HTMLDivElement, getIndexStyles?: boolean, exposePublicFonts?: boolean): Promise<HTMLDivElement> {
		/* eslint-disable no-useless-escape */
		if (getIndexStyles) {
			const indexStylesElement: HTMLStyleElement = document.createElement('style');
			indexStylesElement.textContent = indexStyles?.toString() || '';

			document.body.appendChild(indexStylesElement);
		}

		if (exposePublicFonts) {
			if (document.querySelector('style[data-description="smartlinks-fonts"]') == null) {
				const style = document.createElement('style');
				style.dataset.description = 'smartlinks-fonts';

				style.appendChild(document.createTextNode(publicFonts));

				document.head.appendChild(style);
			}
		}

		const shadow: ShadowRoot = getElement.attachShadow({ mode: 'open' });
		const renderIn: HTMLDivElement = document.createElement('div');
		shadow.innerHTML = `
    <style data-id=["tailwindcss"]>
      ${tailwindStyles?.toString() || ''}

      @media(min-width: 768px) {
        .md\:h-\[571px\] {
          height: 571px;
        }
      }

      .TestVivaMx .toolTip__container,
      .VivaAerobusMX .toolTip__container {
        background: #002612!important;
      }
    </style>
    `;

		shadow.appendChild(renderIn);

		return renderIn;
	}

	public static debounce(callback: () => void, wait: number, timeoutId: number) {
		return (...args: []) => {
			window.clearTimeout(timeoutId || 0);

			return window.setTimeout(() => {
				callback.apply(null, args);
			}, wait);
		};
	}

	static verifyWidgetElement(elementId?: string): HTMLDivElement | null {
		const getElement: HTMLDivElement | null = elementId ? (document.getElementById(elementId) as HTMLDivElement) : null;
		if (!getElement) {
			const noFoundErroMsg: string = 'elementNofound';
			console.error(noFoundErroMsg);
		}

		return getElement;
	}
}
