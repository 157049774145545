import { ShortRecommendation } from '../../../../../shared/interfaces/short-recommendation';
import Recommendation from '../../../components/recommendation/recommendation';
import { paginate } from '../../../../../shared/services/utils.service';
import styles from './paginate-recommendations.module.scss';
import Button from '../../../../ui-elements/button/button';
import Select from '../../../../ui-elements/select/select';
import { useState, useEffect } from 'react';

export interface PaginateRecommendationsProps {
	paginateRecommendationList: ShortRecommendation[];
	days: number;
	emitRecommendation?: (data?: ShortRecommendation) => void;
}

export function PaginateRecommendations(props: PaginateRecommendationsProps): JSX.Element {
	const [paginateRecommendationList, setPaginateRecommendationList] = useState<any[]>([]);
	const [currentPaginateList, setCurrentPaginateList] = useState<any[]>([]);
	const [pageSize, setPageSize] = useState<number>(5);
	useEffect(() => {
		createRecommendations(5);
	}, [props.paginateRecommendationList]);

	const createRecommendations = (customPageSize?: number): void => {
		const paginateData = initPaginate(props?.paginateRecommendationList, customPageSize || pageSize);
		if (paginateData?.length) {
			const firstPagination: number = 0;
			setPaginateRecommendationList(paginateData);

			setCurrentPaginateList(paginateData[firstPagination]);
		} else {
			setPaginateRecommendationList([]);

			setCurrentPaginateList([]);

			setPageSize(5);
		}
	};

	const initPaginate = (list: ShortRecommendation[], pageSize: number): ShortRecommendation[][] => {
		return paginate(list, pageSize);
	};

	const moreResults = (): void => {
		if (props.paginateRecommendationList.length === paginateRecommendationList[0].length) {
			return;
		}

		const pageSizeIncrement: number = 5;
		const increment: number = pageSize + pageSizeIncrement;
		setPageSize(increment);

		createRecommendations(increment);
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div id='paginateRecommendations' className={'paginateRecommendations flex justify-center bg-gray-100'}>
				<div className='flex flex-col items-center p-16 px-6 md:p-16 w-full'>
					<div className={'flex flex-col md:flex-row items-center w-full mb-14 justify-between paginateRecommendations__container'}>
						<div>
							<h3 className='text-4xl font-family-black text-gray-600 mt-3.5 mb-3.5 text-center max-w-xl'>Selecciona tu vuelo a Miami</h3>
							<hr className='mx-auto md:mx-0 w-44 h-1 bg-gray-600' />
						</div>

						<div className='mt-5 md:mt-0 flex items-center'>
							<p className='font-family-regular text-xs'>Ordenar por:</p>
							<Select
								options={[
									{
										label: 'Más económico',
										value: '1',
									},
									{
										label: 'Mayor precio',
										value: '2',
									},
								]}
								defaultValue=''
								label=''
								name='temp'
								className={'bg-transparent font-family-black text-xs'}
								labelClassName='hidden'
							/>
						</div>
					</div>

					<div className={'paginateRecommendations__container w-full'}>
						{currentPaginateList.length ? (
							currentPaginateList.map((item: any, index: number) => (
								<div key={index}>
									<Recommendation
										onClick={data => {
											if (props.emitRecommendation) {
												props.emitRecommendation(data);
											}
										}}
										data={item}
									/>
								</div>
							))
						) : (
							<div>
								<Recommendation />
								<Recommendation />
								<Recommendation />
								<Recommendation />
								<Recommendation />
							</div>
						)}
					</div>

					<div className={'paginateRecommendations__container w-full flex justify-end'}>
						{props?.paginateRecommendationList?.length !== paginateRecommendationList[0]?.length && (
							<Button
								onClick={moreResults}
								text={'Más resultados'}
								customClass={'rounded-full items-center text-white bg-indigo-600 '}
								disabled={!paginateRecommendationList.length}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default PaginateRecommendations;
