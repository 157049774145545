import HistoricalProductActivity from './sub-components/historical-product-activity';
import { numberToLocaleWithcurrency } from '../../../shared/services/utils.service';
import HistoricalProductDisney from './sub-components/historical-product-disney';
import HistoricalProductHotel from './sub-components/historical-product-hotel';
import HistoricalDetailSkeleton from './skeleton/historical-detail-skeleton';
import { ObjectLiteral } from '../../../shared/enums/object-literal.enum';
import HistoricalProductAir from './sub-components/historical-product-air';
import HistoricalProductCar from './sub-components/historical-product-car';
import HistoricalItemInfo from './sub-components/historical-item-info';
import { HorizontalDecription, IcomoonIcon } from '../../ui-elements';
import { ProductType } from '../../../shared/enums/product.enum';
import styles from './historical-detail.module.scss';
import {
	HistoricalCustomer,
	HistoricalDetailData,
	HistoricalDetailProductActivity,
	HistoricalDetailProductAir,
	HistoricalDetailProductCar,
	HistoricalDetailProductDisney,
	HistoricalDetailProductHotel,
	HistoricalDetailBase,
} from '../../../shared/interfaces';

export interface HistoricalDetailProps {
	colorPrimary?: string;
	colorSecondary?: string;
	historicalDetail: HistoricalDetailData<HistoricalDetailProductHotel> | undefined;
	t: (value: string) => string;
}

export function HistoricalDetail({ t, colorPrimary, colorSecondary, historicalDetail }: HistoricalDetailProps): JSX.Element {
	const ZERO: number = 0;
	const HistoricalProductIcon = ({ historicalPorductType }: { historicalPorductType: ProductType }): JSX.Element => {
		const historicalProductIcons = {
			[ProductType.hotel]: 'hotelsIco',
			[ProductType.activity]: 'iconResumen',
			[ProductType.car]: 'carsIco',
			[ProductType.air]: 'frontPlaneIco',
			[ProductType.plan]: 'disneyIco',
			[ObjectLiteral.default]: 'iconResumen',
		};

		return (
			<IcomoonIcon
				className='mr-2'
				icon={historicalProductIcons[historicalPorductType] || historicalProductIcons[ObjectLiteral.default]}
				color={colorPrimary}
			/>
		);
	};

	const HistoricalPurchase = (historicalDetail: HistoricalDetailData<HistoricalDetailBase>): JSX.Element => {
		return (
			<div className='historical-detail__purchase  mt-8'>
				<hr className='historical-detail__split my-3' />
				{historicalDetail.historicalResponse.fareSummary.totalPoints !== ZERO && (
					<HorizontalDecription
						className='historical-detail__pointName text-[16px]'
						fullFontBold={true}
						noBoldLabel={false}
						label={historicalDetail.historicalResponse.fareSummary.pointsCurrency}
						value={numberToLocaleWithcurrency(historicalDetail.historicalResponse.fareSummary.totalPoints)}
					/>
				)}
				{historicalDetail.historicalResponse.fareSummary.totalCash !== ZERO && (
					<HorizontalDecription
						className='historical-detail__creditCardCharge text-[14px]'
						fullFontBold={true}
						noBoldLabel={false}
						label={t('creditCardCharge')}
						value={`${historicalDetail.historicalResponse.fareSummary.currency} ${numberToLocaleWithcurrency(
							historicalDetail.historicalResponse.fareSummary.totalCash,
							historicalDetail.historicalResponse.fareSummary.currency,
						)}`}
					/>
				)}
			</div>
		);
	};

	const HistoricalStatus = ({ status }: { status: string }): JSX.Element => {
		const isActive: string = 'active';
		return (
			<h3 className='flex text-[16px] historical-detail__status' style={{ color: status === isActive ? '#53B908' : 'black' }}>
				<span className='historical-detail__reservationStatus mr-1' style={{ color: colorPrimary }}>
					{t('reservationStatus')}:
				</span>
				{t(status).toUpperCase()}
			</h3>
		);
	};

	const HistoricalHeader = (historicalDetail: HistoricalDetailData<HistoricalDetailBase>): JSX.Element => {
		const customer: HistoricalCustomer | undefined = historicalDetail.detail.customer;
		return (
			<>
				<div className='historical-detail__header md:flex justify-between mb-4'>
					<h3 className='md:flex text-[21px] text-black'>
						<HistoricalProductIcon historicalPorductType={historicalDetail.historicalResponse.productType} />
						<span className='historical-detail__bookingType  mr-1' style={{ color: colorPrimary }}>
							{t('bookingType')}:
						</span>
						{t(historicalDetail.historicalResponse.productType)}
					</h3>
					<HistoricalStatus status={historicalDetail.historicalResponse.status} />
				</div>

				<HistoricalItemInfo
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					customClass='historical-detail__reservationNumber'
					isIcon={false}
					classIcon='personIco'
					title='reservationNumber'
					value={historicalDetail.historicalResponse.providerCode}
				/>
				<HistoricalItemInfo
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					customClass=''
					isIcon={true}
					classIcon='calendarBlankIco'
					title='reservationDate'
					value={historicalDetail.historicalResponse.bookingDateFormated}
				/>
				{customer?.name && (
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						customClass='historical-detail__reservationInName'
						isIcon={true}
						classIcon='personIco'
						title='reservationInName'
						value={customer.name}
					/>
				)}
				{customer?.email && (
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						customClass='historical-detail__email'
						isIcon={true}
						classIcon='personIco'
						title='e-mail'
						value={customer?.email}
					/>
				)}
			</>
		);
	};

	const HistoricalProduct = (historicalDetail: HistoricalDetailData<HistoricalDetailBase>): JSX.Element => {
		const historicalProcucts = {
			[ProductType.hotel]: (
				<HistoricalProductHotel
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					historicalDetail={historicalDetail as HistoricalDetailData<HistoricalDetailProductHotel>}
				/>
			),
			[ProductType.activity]: (
				<HistoricalProductActivity
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					historicalDetail={historicalDetail as HistoricalDetailData<HistoricalDetailProductActivity>}
				/>
			),
			[ProductType.car]: (
				<HistoricalProductCar
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					historicalDetail={historicalDetail as HistoricalDetailData<HistoricalDetailProductCar>}
				/>
			),
			[ProductType.air]: (
				<HistoricalProductAir
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					historicalDetail={historicalDetail as HistoricalDetailData<HistoricalDetailProductAir>}
				/>
			),
			[ProductType.plan]: (
				<HistoricalProductDisney
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					historicalDetail={historicalDetail as HistoricalDetailData<HistoricalDetailProductDisney>}
				/>
			),
			[ObjectLiteral.default]: <></>,
		};

		return historicalProcucts[historicalDetail.historicalResponse.productType] || historicalProcucts[ObjectLiteral.default];
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			{!historicalDetail ? (
				<HistoricalDetailSkeleton />
			) : (
				<section className='historical-detail__content p-6 bg-white rounded-lg flex-col flex-wrap'>
					<HistoricalHeader {...historicalDetail} />
					<HistoricalProduct {...historicalDetail} />
					<HistoricalPurchase {...historicalDetail} />
				</section>
			)}
		</>
	);
}

export default HistoricalDetail;
