export const endPoints = {
	getAgency: `v2/agencies`,
	getUser: `users`,
	carRecommendations: 'cars/widget',
	carSearch: (domain: string, params: string) => `https://${domain}/cars/search/${params}`,
	corporateDiscounts: 'agencies/corporateDiscounts',
	locations: 'v2/locations?term=',
	airports: 'airports',
	airportsByWords: (value: string) => `v2/airports?term=${value || ''}`,
	airlines: 'airlines',
	flightsSearch: (domain: string, params: string) => `https://${domain}/flights/recommendations${params}`,
	hotelsLocationsByWords: (value: string) => `v2/locations/hotels?term=${value || ''}`,
	activitiesLocationsByWords: (value: string) => `v2/locations/activities?term=${value || ''}`,
	hotelDisneyAddons: 'v2/hotels/disney/addons',
	countries: 'countries.json',
	userGuest: 'auth/create-guest',
};
