import { Image } from '../../../ui-elements/image/image';
/* eslint-disable-next-line */
export interface InformativeIntroductionProps {
	altImage?: string;
	imageUlr?: string;
	title: string;
	description: string;
	callActionText?: string;
	urlCallAction: string;
}

export const InformativeIntroduction = ({
	altImage,
	imageUlr,
	title,
	description,
	callActionText,
	urlCallAction,
}: InformativeIntroductionProps): JSX.Element => {
	return (
		<div className='informativeIntroduction'>
			<div className='informativeIntroduction__container flex flex-col md:flex-row md:max-h-[431px]'>
				<div className='informativeIntroduction__item order-1 md:order md:w-6/12 flex items-center px-3 md:px-6 lg:px-12'>
					<div className='informativeIntroduction__info py-4'>
						<h4 className='informativeIntroduction__title text-[26px] lg:text-[36px] mb-6'>{title}</h4>
						<p className='informativeIntroduction__description'>{description}</p>

						{callActionText && (
							<a className='informativeIntroduction__link underline' href={urlCallAction} target={'_blank'} rel='noreferrer'>
								{callActionText}
							</a>
						)}
					</div>
				</div>

				<div className='informativeIntroduction__item informativeIntroduction__image order md:order-1 md:w-6/12 overflow-hidden'>
					<Image url={imageUlr || ''} width={'100%'} height={'431px'} alt={altImage || ''} styles={{}} />
				</div>
			</div>
		</div>
	);
};
