import { useState, useCallback, useEffect } from 'react';
import { FormsInputsType } from '../../../shared/enums/inputs.enum';
import { InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { useDebounce } from '../../../shared/hooks/useDebounce';

export interface CounterProps {
	name?: string;
	min: number;
	max: number;
	defaultValue?: number;
	debounceWait?: number;
	valueHandler?: (e: InputEventsHandler) => number;
	onChange?: (e: InputEventsHandler) => void;
	plusIcon?: string;
	minusIcon?: string;
	buttonClassName?: string;
	iconClassName?: string;
	valueClassName?: string;
	className?: string;
	passengersAmountsRestrictions?: number;
	totalPassengers?: number;
	displayPlusButtonCheck?: string;
	isTertiaryTemplate?: boolean;
}

export const Counter = ({
	name,
	min,
	max,
	defaultValue,
	debounceWait,
	valueHandler,
	onChange,
	plusIcon,
	minusIcon,
	buttonClassName,
	iconClassName,
	valueClassName,
	className,
	passengersAmountsRestrictions,
	totalPassengers,
	displayPlusButtonCheck,
	isTertiaryTemplate,
}: CounterProps): JSX.Element => {
	const [value, setValue] = useState<number>(0);
	const debounceHandler = useDebounce();
	const changeHandler = useCallback(
		(step: number) => {
			let tempValue: number = value + step;
			if (tempValue < min) {
				tempValue = min;
			}

			if (tempValue > max) {
				tempValue = max;
			}

			if (valueHandler) {
				const oldDataEvent: InputEventsHandler = {
					type: FormsInputsType.counter,
					name,
					value: tempValue,
				};

				tempValue = valueHandler(oldDataEvent);
			}

			if (onChange) {
				const dataEvent: InputEventsHandler = {
					type: FormsInputsType.counter,
					name,
					value: tempValue,
				};

				onChange(dataEvent);
			}

			setValue(tempValue);
		},
		[name, min, max, valueHandler, value, onChange],
	);

	const changeDebounceHandler = debounceHandler(changeHandler, debounceWait || 100);
	useEffect(() => {
		const defaultValueInRestriction: boolean = typeof defaultValue === 'number' && max >= defaultValue && defaultValue >= min;
		if (defaultValueInRestriction) {
			setValue(defaultValue as number);

			return;
		}

		setValue(min);
	}, [defaultValue, min, max]);

	return (
		<div className={`counterLib flex flex-row items-center ${className || ''}`}>
			<button
				type='button'
				className={`counterLib__button inline-block ${buttonClassName || ''} 
					${value === min ? 'counterBtnDisabled' : ''}
					${isTertiaryTemplate && value === min ? 'border-[#9E9E9E] text-[#9E9E9E]' : ''}
					${isTertiaryTemplate && value !== min ? 'border-[#1075BB] text-[#1075BB]' : ''}
				`}
				onClick={() => changeDebounceHandler(-1)}
			>
				{minusIcon ? (
					<span className={`counterLib__icon text-[16px]  ${minusIcon} ${iconClassName || ''}`} />
				) : (
					<span className='counterLib__icon text-[16px] minusRoundedIco'>
						<span className='path1' />
						<span className='path2' />
					</span>
				)}
			</button>
			<span className={`counterLib__value px-[10px] ${valueClassName || ''}`}>{value}</span>
			<button
				type='button'
				className={`counterLib__button inline-block  ${displayPlusButtonCheck || ''} ${buttonClassName || ''} ${
					value === max ? 'counterBtnDisabled' : ''}
					${isTertiaryTemplate ? 'border-[#1075BB] text-[#1075BB]' : ''}
				`}
				onClick={() => changeDebounceHandler(1)}
			>
				{plusIcon ? (
					<span className={`counterLib__icon text-[16px] ${plusIcon} ${iconClassName || ''}`} />
				) : (
					<span className='counterLib__icon text-[16px] plusRoundedIco'>
						<span className='path1' />
						<span className='path2' />
					</span>
				)}
			</button>
		</div>
	);
};

Counter.defaultProps = {};

export default Counter;
