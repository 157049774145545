import Image from '../../../../../ui-elements/image/image';
import styles from './image-introduction.module.scss';
export interface ImageIntroductionProps {
	imageUrl: string;
	title: string;
	subtitle: string;
	description?: string;
}

export function ImageIntroduction(props: ImageIntroductionProps): JSX.Element {
	const descriptionHtml = props.description || '';
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={'imageIntroduction flex justify-center'}>
				<div className='flex px-18 px-6 md:py-32 w-full justify-center'>
					<div className={'imageIntroduction__container w-full flex flex-col md:flex-row'}>
						<div className='lg:w-3/5 md:pr-9 flex flex-col items-center md:items-start text-center md:text-left order-2 md:order-1'>
							<p className='font-family-bold text-base tracking-widest text-gray-500 mb-8'>{props.subtitle}</p>
							<h4 className='text-4xl text-gray-600 font-family-black mt-3.5 text-center md:text-left mb-6'>{props.title}</h4>
							<hr className='mx-auto md:mx-0 w-44 h-1 bg-gray-600 mb-8' />

							<p
								className='font-family-regular text-base max-w-sm text-center md:text-left'
								dangerouslySetInnerHTML={{ __html: descriptionHtml }}
							></p>
						</div>

						<div className='lg:w-2/5 flex justify-center md:justify-start order-1 md:order-2 mb-4'>
							<Image url={props.imageUrl} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ImageIntroduction;
