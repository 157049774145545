import { Spinner } from '../../../../ui-elements/spinner/spinner';

export interface LoadPageProps {
	title: string;
	description?: string;
	className?: string;
}

export function LoadPage(props: LoadPageProps): JSX.Element {
	return (
		<div className={`h-96 text-center  align-middle w-full ${props.className || ''}`}>
			<Spinner className={'text-4xl'} />
			<h1 className={'pt-5 text-5xl leading-10 font-family-bold font-bold'}>{props.title}</h1>
			<p className={'pt-5 px-72 text-neutral-600 font-family-regular'}>{props.description}</p>
		</div>
	);
}

export default LoadPage;
