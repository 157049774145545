import styles from './card-alert-drop-off.module.scss';
import { CardInformation } from '../..';

export interface CardAlertDropOffProps {
	t?: (item: string) => string;
}

export const CardAlertDropOff = ({ t }: CardAlertDropOffProps): JSX.Element => {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<CardInformation
				iconClassName={'infoCirlce2Ico'}
				title={(t && t('alertDropOffTitle')) || 'alertDropOffTitle'}
				description={(t && t('alertDropOffDescription')) || 'alertDropOffDescription'}
				customClass='border border-solid  mb-4 cardAlertDropOff__container'
				contentClassName='h-full pt-2 px-4 flex-row'
				iconContainerClassName={'sm:h-[22px] sm:w-[22px] h-[26px] w-[26px]  me-4 items-center cardAlertDropOff__info'}
				iconsClassName={'sm:text-[22px] text-[26px] self-start text-white cardAlertDropOff__icon'}
				titleClassName={'cardAlertDropOff__title text-white'}
				subTitleClassName={'text-xs mb-3 cardAlertDropOff__subtitle text-white'}
				image=''
				traits={null}
				type=''
				subtitle=''
				link=''
				linkText=''
			/>
		</>
	);
};

export default CardAlertDropOff;
