import { CarInterfaceV2, GroupedCoveragesInterfaceV2 } from '../../../../shared/interfaces/car-recommendation-v2.interface';
import { CarsRatesModelsV2 } from '../../../../shared/enums/filter-cars-cards-v2.enum';
import CarCoveragesDetailSkeleton from './skeleton/car-coverages-detail-skeleton';
import CarCoveragesDesktop from './car-coverages-desktop/car-coverages-desktop';
import CarCoveragesMobile from './car-coverages-mobile/car-coverages-mobile';
import { CarDetailUtil } from '../../../../shared/utils/car-detail.util';
import CoverageCarCard from '../../coverage-car-card/coverage-car-card';
import { TableData } from '../../../../../../shared/interfaces/table';
import styles from './car-coverages-detail.module.scss';
import { useCallback, useMemo } from 'react';

export interface CarCoveragesDetailProps {
	carRecommendation?: CarInterfaceV2;
	rateType?: string;
	coveragesTitle?: string;
	colorPrimary?: string;
	t?: (item: string) => string;
	onClick?: (outPutData: GroupedCoveragesInterfaceV2 | null) => void;
	className?: string;
	coveragesMobileClassName?: string;
	coveragesDesktopClassName?: string;
	currencySymbol?: string;
	culture?: string;
	loading?: boolean;
	showChangedPrice?: boolean;
	isActiveMultiLanguage?: boolean;
}

export const CarCoveragesDetail = ({
	carRecommendation,
	rateType,
	coveragesTitle,
	colorPrimary,
	t,
	onClick,
	className,
	coveragesMobileClassName,
	coveragesDesktopClassName,
	loading,
	showChangedPrice,
	currencySymbol,
	culture,
	isActiveMultiLanguage,
}: CarCoveragesDetailProps): JSX.Element => {
	t = t as (item: string) => string;

	const firstItem: number = 0;
	const carDetailUtil: CarDetailUtil = new CarDetailUtil(isActiveMultiLanguage);
	const onClickHandler = useCallback((outPutData: GroupedCoveragesInterfaceV2 | null): void => {
		if (outPutData && onClick) {
			onClick(outPutData);
		}
	}, []);

	const dataDesktop: TableData | undefined = useMemo(
		() =>
			((
				carData?: CarInterfaceV2,
				tempRateType?: string,
				tempColorPrimary?: string,
				translation?: (item: string) => string,
			): TableData | undefined => {
				const tempDatetable: TableData | undefined = carData
					? carDetailUtil.mapCoveragesForTable(
						carData.rateByRentalCar[firstItem],
						carData?.businessModel,
						translation as unknown as (item: string) => string,
						tempRateType as CarsRatesModelsV2,
						tempColorPrimary,
						carData?.isAvailabilityInLocalCurrency,
						onClickHandler,
						showChangedPrice,
						currencySymbol,
						culture,
					  )
					: undefined;

				return tempDatetable;
			})(carRecommendation, rateType, colorPrimary, t),
		[carRecommendation, rateType, colorPrimary, t, showChangedPrice],
	);

	const dataMobile: TableData[] | undefined = useMemo(
		() =>
			((
				carData?: CarInterfaceV2,
				tempRateType?: string,
				tempColorPrimary?: string,
				translation?: (item: string) => string,
			): TableData[] | undefined => {
				return carData
					? carDetailUtil.mapMobileCoveragesforTable(
						carData.rateByRentalCar[firstItem],
						carData?.businessModel,
						translation as unknown as (item: string) => string,
						tempRateType as CarsRatesModelsV2,
						tempColorPrimary,
						carData?.isAvailabilityInLocalCurrency,
						onClickHandler,
						showChangedPrice,
						currencySymbol,
						culture,
					  )
					: undefined;
			})(carRecommendation, rateType, colorPrimary, t),
		[carRecommendation, rateType, colorPrimary, t, showChangedPrice],
	);

	return loading ? (
		<CarCoveragesDetailSkeleton
			colorPrimary={colorPrimary}
			className={className}
			coveragesMobileClassName={coveragesMobileClassName}
			coveragesDesktopClassName={coveragesDesktopClassName}
		/>
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`carCoveragesDetail order-2 md:order-1 ${className || ''}`}>
				<CoverageCarCard
					rateModel={rateType}
					carInfo={carRecommendation}
					colorPrimary={colorPrimary || '#FDD008'}
					dailyRateText={t('day').toLowerCase()}
					discountText={t('saveUpTo10')}
					rateText={t('from')}
					coverageButtonText={t('showDetail')}
					includeAirConditionText={t('AC')}
					passengersText={t('passengers')}
					doorsText={t('doors')}
					baggageText={t('baggages')}
					includeLargeAirConditionText={t('withAirCondition')}
					manualTransmissionText={t('manual')}
					automaticTransmissionText={t('automatic')}
					showRate={false}
					showButtons={false}
					multipleCompanies={false}
					className={'bg-white mx-auto mt-[20px] w-[100%] rounded-xl shadow-md carCoveragesDetail__boxShadow'}
					companyImageClassName={'h-[15px] w-[41px]'}
					carImageClassName={''}
					t={t}
				/>
				{coveragesTitle && <p className='carCoveragesDetail__coveragesTitle my-[20px] text-[21px]'>{coveragesTitle}</p>}
				<div className={`carCoveragesDetail__coveragesMobile carCoveragesDetail__coveragesMobile ${coveragesMobileClassName || ''}`}>
					<CarCoveragesMobile coveragesTables={dataMobile} noCoveragesText={t('noCoverages')} />
				</div>

				<div className={`carCoveragesDetail__coveragesDesktop carCoveragesDetail__coveragesDesktop ${coveragesDesktopClassName || ''}`}>
					<CarCoveragesDesktop coveragesTable={dataDesktop} noCoveragesText={t('noCoverages')} />
				</div>
			</div>
		</>
	);
};

export default CarCoveragesDetail;
