import { InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { InputType, FormsInputsType } from '../../../shared/enums/inputs.enum';
import { ElementTypes } from '../../../shared/enums/dynamic-forms.enum';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './input.module.scss';
export interface InputProps {
	type?: InputType | ElementTypes;
	label?: string;
	icon?: string;
	name?: string;
	placeholder?: string;
	disabled?: boolean;
	onChange?: (e: InputEventsHandler) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onInput?: () => void;
	onClick?: () => void;
	className?: string;
	labelClassName?: string;
	inputClassName?: string;
	iconClassName?: string;
	register?: UseFormRegisterReturn;
	required?: boolean;
	defaultValue?: string;
	max?: string;
	min?: string;
	hidden?: boolean;
	readonly?: boolean;
	defaultChecked?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	checked?: boolean;
	truncate?: boolean;
	style?: any;
	autocomplete?: string;
	value?: string;
	step?: string;
	toolTip?: JSX.Element;
	children?: JSX.Element;
}

export const Input = (props: InputProps): JSX.Element => {
	const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (props?.onChange) {
			const dataEvent: InputEventsHandler = {
				type: props?.type ? FormsInputsType[props.type] : FormsInputsType.text,
				name: props?.name,
				value: event.target.value,
				event,
			};

			props.onChange(dataEvent);
		}
	};

	const focusHandler = (): void => {
		if (props?.onFocus) {
			props.onFocus();
		}
	};

	const inputHandler = (): void => {
		if (props?.onInput) {
			props.onInput();
		}
	};

	const clickHandler = (): void => {
		if (props?.onClick) {
			props.onClick();
		}
	};

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div
				className={`inputLib flex items-center w-full rounded-lg py-2.5 px-2 ${
					props.isSecondaryTemplate || props.isTertiaryTemplate ? 'flex-row-reverse gap[8px]' : 'flex-col'
				}  justify-center ${props?.className ?? ''}`}
			>
				{props.label && (
					<label
						className={`inputLib__label inputLabel flex whitespace-nowrap w-full font-family-regular ${props.labelClassName ?? ''}`}
						htmlFor={props?.name}
					>
						{props.label} {props.required ? '*' : ''}
					</label>
				)}
				{props.toolTip && !props.icon && props.toolTip}
				{props.icon && (
					<span
						data-testid={`${props?.name ?? ''}_icon`}
						className={`inputLib__icon ${props.icon} ${props.iconClassName ?? ''} ${
							props.isTertiaryTemplate ? 'text-[#6D6E71] !text-[5px] hidden md:block ' : ''
						}`}
					/>
				)}
				{props.children && props.children}
				{props?.value !== undefined ? (
					<input
						style={{ ...props.style }}
						defaultChecked={props?.defaultChecked}
						className={`${props.isTertiaryTemplate ? '' : 'inputLib__input text-sm'} input__input block ${
							props?.type === ElementTypes.checkbox ? 'w-auto' : 'w-full '
						} rounded p-1 min-xs ${props.inputClassName ?? ''} ${props?.hidden ? 'hidden' : ''} ${
							props.truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap' : ''
						}`}
						type={props.type}
						id={props?.name}
						data-testid={props?.name}
						name={props?.name}
						placeholder={props?.placeholder}
						onChange={changeHandler}
						disabled={props?.disabled}
						required={props?.required}
						min={props?.min}
						max={props?.max}
						readOnly={props?.readonly}
						maxLength={Number(props?.max) || undefined}
						onFocus={focusHandler}
						onInput={inputHandler}
						onClick={clickHandler}
						autoComplete={props?.autocomplete ?? 'on'}
						checked={props?.checked}
						value={props?.value}
						{...props?.register}
					/>
				) : (
					<input
						style={{ ...props.style }}
						defaultChecked={props?.defaultChecked}
						className={`${props.isTertiaryTemplate ? '' : 'inputLib__input text-sm'} input__input block ${
							props?.type === ElementTypes.checkbox ? 'w-auto' : 'w-full '
						} rounded p-1 min-xs ${props.inputClassName ?? ''} ${props?.hidden ? 'hidden' : ''} ${
							props.truncate ? 'overflow-ellipsis overflow-hidden max-w-full whitespace-nowrap' : ''
						}`}
						type={props.type}
						id={props?.name}
						data-testid={props?.name}
						name={props?.name}
						placeholder={props?.placeholder}
						defaultValue={props?.defaultValue}
						onChange={changeHandler}
						disabled={props?.disabled}
						required={props?.required}
						min={props?.min}
						max={props?.max}
						step={props?.step}
						readOnly={props?.readonly}
						maxLength={Number(props?.max) || undefined}
						onFocus={focusHandler}
						onInput={inputHandler}
						onClick={clickHandler}
						autoComplete={props?.autocomplete ?? 'on'}
						checked={props?.checked}
						{...props?.register}
					/>
				)}
			</div>
		</>
	);
};

Input.defaultProps = {
	type: InputType.text,
	labelClassName: 'block text-sm font-family-black px-1',
	inputClassName: 'bg-gray-100 font-family-regular',
	iconClassName: '',
};

export default Input;
