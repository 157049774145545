import { HistoricalDetailData, HistoricalDetailProductHotel } from '../../../../shared/interfaces';
import HistoricalPassengersQuantityDetail from './historical-passanger-quantity';
import HistoricalItemInfo from './historical-item-info';

export interface HistoricalProductHotelProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	historicalDetail: HistoricalDetailData<HistoricalDetailProductHotel>;
}

export function HistoricalProductHotel({ t, colorPrimary, colorSecondary, historicalDetail }: HistoricalProductHotelProps): JSX.Element {
	return (
		<>
			<div className='historical-detail__split my-8' />
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__hotel'
				isIcon={true}
				classIcon='hotelsIco'
				title='hotel'
				value={historicalDetail.detail.name}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__location'
				isIcon={true}
				classIcon='searchLocationIco'
				title='location'
				value={`${historicalDetail.detail.address}, ${historicalDetail.detail.city || ''}, ${historicalDetail.detail.country || ''}`}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__contact'
				isIcon={true}
				classIcon='hotelsIco'
				title='contact'
				value={historicalDetail.detail.phoneNumber}
			/>
			<div className='historical-detail__split my-4' />
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__entry'
				isIcon={true}
				classIcon='calendarBlankIco'
				title='entry'
				value={historicalDetail.detail.checkInFormated}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__departure'
				isIcon={true}
				classIcon='calendarBlankIco'
				title='departure'
				value={historicalDetail.detail.checkOutFormated}
			/>
			<HistoricalPassengersQuantityDetail
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				historicalPassengersQuantity={historicalDetail.passengerQuantity}
			/>
		</>
	);
}

export default HistoricalProductHotel;
