import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { InputEventsHandler, DatePickerData } from '../../../../shared/interfaces/inputs.interface';
import { SearchFieldsNames, DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { HotelsSearchFormInputsNames } from '../enums/hotels-search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
	RoomNameHotelsSearchFormInterface,
	FieldRoomNameHotelsSearchFormInterface,
	UseHotelsSearchFormInterface,
	UseHotelsSearchFormReturnInterface,
	HotelsSearchFormSubmitInterface,
	RoomsHotelsSearchFormSubmitInterface,
	RoomsFieldsRestrictionsInterface,
} from '../interfaces/hotels-search-form.interface';
import {
	FieldButtonEventHandlerInterface,
	DisplayValuesDatesFieldInterface,
	DatesFieldInputNamesInterface,
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import { HotelsSearchFormUtil } from '../utils/hotels-search-form.util';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import { LocationEnum } from '../enums/location.enum';

const useHotelsSearchForm = ({
	history,
	calendarRestriction,
	roomsRestriction,
	maxRooms,
	displayFilterField,
	traductions,
	emitSubmitSearch,
	emitCitiesSearch,
	emitShowMobileFields,
	widgetStyles,
	showMobileIataField,
}: UseHotelsSearchFormInterface): UseHotelsSearchFormReturnInterface => {
	const destinationHiddenName: string = HotelsSearchFormInputsNames.iataDestination;
	const destinationName: string = HotelsSearchFormInputsNames.destination;
	const locationKey: string = LocationEnum.key;
	const datesHiddenName: string = HotelsSearchFormInputsNames.hiddenDates;
	const destinationId: SearchFieldsNames = SearchFieldsNames.destination;
	const datesName: string = HotelsSearchFormInputsNames.dates;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const nationalitySelectionName: string = HotelsSearchFormInputsNames.nationalities;
	const nationalitySelectionId: SearchFieldsNames = SearchFieldsNames.nationality;
	const roomsId: SearchFieldsNames = SearchFieldsNames.rooms;
	const defaultChildren: number = 0;
	const minRoomsNumber: number = 1;
	const defaultAdults: number = 1;
	const defaultChildrenAge: number = 1;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors, isValid },
	} = useForm();

	const inputRefs: React.MutableRefObject<Record<string, HTMLElement | null>> = useRef<Record<string, HTMLElement | null>>({});
	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(destinationId);
	const [totalChildren, setTotalChildren] = useState<number>(defaultChildren);
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
		endDate: new Date(),
	});

	const [showMobileNationalities, setShowMobileNationalities] = useState<boolean>(false);
	const [showMultiActions, setShowMultiActions] = useState<boolean>(false);
	const [showAddRoomButtons, setShowAddRoomButtons] = useState<boolean>(true);
	const [roomsNumber, setRoomsNumber] = useState<number>(minRoomsNumber);
	const [totalAdults, setTotalAdults] = useState<number>(defaultAdults);
	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [childrenAges, setChildrenAges] = useState<number[][]>(
		Array(maxRooms || 1)
			.fill([])
			.map(() => [defaultChildrenAge]),
	);

	const [minDate, setMinDate] = useState<Date>(new Date());
	const mobileFormHeight: number = useWindowHeight();
	const roomsNames: RoomNameHotelsSearchFormInterface[] = useMemo(
		() =>
			((tempRoomsRestriction: RoomsFieldsRestrictionsInterface, tempMaxRooms?: number): RoomNameHotelsSearchFormInterface[] => {
				return HotelsSearchFormUtil.getRoomsInputsNames(HotelsSearchFormInputsNames.rooms, tempRoomsRestriction, tempMaxRooms);
			})(roomsRestriction as RoomsFieldsRestrictionsInterface, maxRooms),
		[roomsRestriction, maxRooms],
	);

	const destinationFieldHandler = useCallback(
		(element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
			if (emitCitiesSearch) {
				emitCitiesSearch(element.target.value);
			}
		},
		[emitCitiesSearch],
	);

	const showMobileFieldsContainerHandler = useCallback((show: boolean): void => {
		setShowContainer(show);

		setMobileStep(destinationId);

		setShowMultiActions(false);

		setShowMobileNationalities(false);
	}, []);

	const showMobileFieldsSecondaryHandler = useCallback((show: boolean, fieldId: string, fieldName: string): void => {
		const waitTime: number = 100;
		const firstIndex: number = 0;
		const minusOne: number = -1;
		const isFieldIdInRoomsNames = roomsNames.slice(firstIndex, minusOne).some(room => room.id === fieldId);
		setShowMultiActions(isFieldIdInRoomsNames);

		setMobileStep(fieldId);

		setShowContainer(show);

		setTimeout(() => {
			const element = inputRefs.current[fieldId] as HTMLElement;
			if (element) {
				element.click();

				const input = element.querySelector(`input[name="${fieldName}"]`) as HTMLInputElement;
				if (input) {
					input.focus();
				}
			}
		}, waitTime);
	}, []);

	const datesHandler = useCallback((event: InputEventsHandler): void => {
		const getData: DatePickerData = event.value as DatePickerData;
		setDefaultDates({
			startDate: getData.startDate,
			endDate: getData.endDate,
		});
	}, []);

	const roomsFieldNumberHandler = useCallback(
		(step: number): void => {
			const noPeople: number = 0;
			let newValue: number = roomsNumber + step;

			if (newValue > roomsNames.length || newValue < minRoomsNumber || (newValue > roomsNumber && totalAdults >= (roomsRestriction?.adults || 0))) {
				newValue = roomsNumber;
			}

			if (newValue > roomsNumber) {
				const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomsNames[newValue - 1].name);
				const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
					defaultAdults,
					defaultChildren,
					0,
					traductions('adult') || 'adult',
					traductions('adults') || 'adults',
					traductions('child') || 'child',
					traductions('children') || 'children',
					'',
					'',
				);

				setValue(tempPassengersNames.adultsName, defaultAdults);

				setValue(tempPassengersNames.childrenName, defaultChildren);

				setValue(tempPassengersNames.name, passengersDisplayValue || '');

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					tempState[newValue - 1] = Array(defaultChildren).fill(defaultChildrenAge); // Initialize all children ages to defaultChildrenAge

					return [...tempState];
				});

				setShowMultiActions(false);
			}

			if (newValue < roomsNumber) {
				setMobileStep(roomsNames[newValue - 1].id);

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					tempState[newValue] = [];

					return [...tempState];
				});

				setShowMultiActions(true);
			}

			setRoomsNumber(newValue);

			let tempTotalAdults: number = 0;
			let tempTotalChildren: number = 0;
			for (let i = 0; i < roomsNames.length; i++) {
				const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomsNames[i].name);
				if (i >= newValue) {
					setValue(tempPassengersNames.adultsName, noPeople);

					setValue(tempPassengersNames.childrenName, noPeople);

					setValue(tempPassengersNames.name, '');
				} else {
					tempTotalAdults += Number(getValues(tempPassengersNames.adultsName) || 0);

					tempTotalChildren += Number(getValues(tempPassengersNames.childrenName) || 0);
				}
			}

			setTotalAdults(tempTotalAdults || defaultAdults);

			setTotalChildren(tempTotalChildren || defaultChildren);
		},
		[roomsNumber, roomsNames, totalAdults, roomsRestriction, getValues, traductions],
	);

	const roomsModalInputsHandler = useCallback(
		(currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string): number => {
			const roomIndex: number = roomsNames.findIndex((roomName: RoomNameHotelsSearchFormInterface): boolean => roomName.name === name);
			const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
			if (inputName === tempPassengersNames.hiddenAdultsName) {
				const adultsOutOfRestriction: boolean =
					newValue - currentData.adults > 0 && (roomsRestriction?.adults || 0) < newValue - currentData.adults + totalAdults;

				const tempNewValue: number = adultsOutOfRestriction ? currentData.adults : newValue;
				setTotalAdults((prevState: number): number => prevState + (tempNewValue - currentData.adults));

				return tempNewValue;
			}

			if (inputName === tempPassengersNames.hiddenChildrenName) {
				const childrenOutOfRestriction: boolean =
					newValue - currentData.children > 0 && (roomsRestriction?.children || 0) < newValue - currentData.children + totalChildren;

				const tempNewValue: number = childrenOutOfRestriction ? currentData.children : newValue;
				setTotalChildren((prevState: number): number => prevState + (tempNewValue - currentData.children));

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					if (tempState[roomIndex]) {
						if (tempState[roomIndex].length < tempNewValue) {
							tempState[roomIndex] = tempState[roomIndex].concat(Array(tempNewValue - tempState[roomIndex].length).fill(defaultChildrenAge));
						} else if (tempState[roomIndex].length > tempNewValue) {
							tempState[roomIndex].splice(tempNewValue);
						}
					} else {
						tempState[roomIndex] = [...Array(tempNewValue).fill(defaultChildrenAge)];
					}

					return [...tempState];
				});

				return tempNewValue;
			}

			return newValue;
		},
		[roomsNames, totalAdults, totalChildren, roomsRestriction],
	);

	const childrenAgesHandler = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, roomIndex: number, ageIndex: number, minAge: number = 0, maxAge: number = 17): void => {
			let newValue: number = Number(event.target.value);
			if (event.target.value) {
				if (newValue < minAge) {
					newValue = minAge;
				}

				if (newValue > maxAge) {
					newValue = maxAge;
				}

				setValue(event.target.name, newValue);

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					tempState[roomIndex][ageIndex] = newValue;

					return [...tempState];
				});
			} else {
				if (!/^[0-9]+$/.test(event.target.value)) {
					event.target.value = '0';
				}

				setValue(event.target.name, event.target.value);

				setChildrenAges((prevState: number[][]) => {
					const tempState: number[][] = [...prevState];
					tempState[roomIndex][ageIndex] = 0;

					return [...tempState];
				});
			}
		},
		[],
	);

	const onSubmit = useCallback(
		handleSubmit(async (data: Record<string, any>) => {
			const isValid = await trigger();
			if (isValid) {
				const tempValidRoomsNames = roomsNames?.length ? [...roomsNames] : [];
				tempValidRoomsNames.splice(roomsNumber);

				const rooms: RoomsHotelsSearchFormSubmitInterface[] = tempValidRoomsNames.map(
					(roomName: RoomNameHotelsSearchFormInterface, roomIndex: number): RoomsHotelsSearchFormSubmitInterface => {
						const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomName.name);
						return {
							adults: data[tempPassengersNames.adultsName] || 0,
							children: data[tempPassengersNames.childrenName] || 0,
							display: data[tempPassengersNames.name] || '',
							childrenAges: childrenAges[roomIndex]?.length ? [...childrenAges[roomIndex]] : [],
						};
					},
				);

				const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
				const results: HotelsSearchFormSubmitInterface = {
					rooms: [...rooms],
					dates: data[datesName] || '',
					departureDate: data[tempDatesNames.startDate] || '',
					arrivalDate: data[tempDatesNames.endDate] || '',
					destination: data[destinationName] || '',
					destinationIata: data[destinationHiddenName] || '',
					urlPath: undefined,
					nationality: data.nationalitiesHotelSearchHiddenName || '',
					locationKey: data.locationKey || '',
				};

				const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
					DatesFieldTypes.startEndDates,
					{
						date: true,
						time: false,
					},
					results.departureDate,
					'',
					results.arrivalDate,
					'',
				);

				setButtonSearchHistory({
					departure: showMobileIataField ? results.destinationIata : results.destination,
					arrival: '',
					departureDate: datesValues.largeStartDate || '',
					arrivalDate: datesValues.largeEndDate || '',
				});

				setShowMultiActions(false);

				if (emitSubmitSearch) {
					emitSubmitSearch({ ...results });
				}
			}
		}),
		[roomsNumber, roomsNames, childrenAges, emitSubmitSearch],
	);

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			const firstIndex: number = 0;
			const isSecondaryTemplate: boolean = widgetStyles?.isSecondaryTemplate || false;
			if (event.id === destinationId) {
				setShowContainer(false);

				setShowMultiActions(false);

				return;
			}

			if (event.id === datesId) {
				setShowContainer(false);

				setShowMultiActions(false);

				return;
			}

			if (event.id === nationalitySelectionId) {
				setShowContainer(false);

				setShowMultiActions(firstIndex === roomsNumber - 1);

				return;
			}

			const { roomName, roomIndex }: FieldRoomNameHotelsSearchFormInterface = HotelsSearchFormUtil.findRoomNameFromId(roomsNames, event.id);
			if (roomIndex === roomsNumber - 1 && roomName) {
				void (async () => {
					const delayTime: number = 500;
					setTimeout(() => {
						setShowContainer(false);

						setMobileStep(destinationId);

						setShowMultiActions(false);
					}, delayTime);
				})();

				return;
			}

			if (isSecondaryTemplate && event.id === roomsId) {
				void (async () => {
					const isValid = await trigger();
					const delayTime: number = 500;
					setTimeout(() => {
						if (isValid) {
							void onSubmit();

							setShowContainer(false);

							setMobileStep(destinationId);

							setShowMultiActions(false);
						}
					}, delayTime);
				})();

				return;
			}

			if (roomName && !isSecondaryTemplate) {
				const nextIndex: number = typeof roomIndex === 'number' ? roomIndex + 1 : 0;
				setMobileStep(roomsNames[nextIndex].id);

				setShowMultiActions(nextIndex === roomsNumber - 1);
			}
		},
		[roomsNames, roomsNumber, onSubmit, displayFilterField],
	);

	const backMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			setShowMultiActions(false);

			if (event.id === datesId) {
				setMobileStep(destinationId);

				return;
			}

			if (event.id === nationalitySelectionId) {
				setMobileStep(datesId);

				return;
			}

			const firstIndex: number = 0;
			const { roomName, roomIndex }: FieldRoomNameHotelsSearchFormInterface = HotelsSearchFormUtil.findRoomNameFromId(roomsNames, event.id);
			const beforeIndex: number | undefined = roomIndex ? roomIndex - 1 : undefined;
			if ((beforeIndex || beforeIndex === firstIndex) && roomName && !widgetStyles?.isSecondaryTemplate) {
				setMobileStep(roomsNames[beforeIndex].id);

				return;
			}

			if (roomName) {
				if (displayFilterField) {
					setMobileStep(nationalitySelectionId);
				} else {
					setMobileStep(datesId);
				}
			}
		},
		[roomsNames, displayFilterField],
	);

	const nationalitiesMobileFieldHandler = useCallback((event: FieldButtonEventHandlerInterface): void => {
		if (event.id === destinationId) {
			setShowMobileNationalities(true);
		} else {
			setShowMobileNationalities(false);
		}
	}, []);

	useEffect(() => {
		setChildrenAges([
			...Array(maxRooms || (roomsRestriction?.adults || 0) + (roomsRestriction?.children || 0))
				.fill(0)
				.map(() => []),
		]);
	}, [roomsRestriction, maxRooms]);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-hotels-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const applyAllfieldsHistory: boolean =
			HotelsSearchFormUtil.validatePath(history) || HotelsSearchFormUtil.validateQueryParams(window?.location?.search, LocationEnum.key);

		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		let counterAcceptableRooms: number = 0;
		const firstIndex: number = 0;
		setMobileStep(destinationId);

		setShowMultiActions(false);

		const isRoomsDataOk: boolean = !!(history?.rooms?.length && roomsNames.length && history.rooms.length <= roomsNames.length);
		let tempTotalAdults: number = 0;
		let tempTotalChildren: number = 0;
		const tempRoomsChildrenAges: number[][] = [];
		if (applyAllfieldsHistory && isRoomsDataOk) {
			history?.rooms.forEach((room: RoomsHotelsSearchFormSubmitInterface, index: number): void => {
				const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(roomsNames[index].name);
				const tempAdults: number = typeof room?.adults === 'number' ? room.adults : defaultAdults;
				const tempChildren: number = typeof room?.children === 'number' ? room.children : defaultChildren;
				const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
					tempAdults,
					tempChildren,
					0,
					traductions('adult') || 'adult',
					traductions('adults') || 'adults',
					traductions('child') || 'child',
					traductions('children') || 'children',
					'',
					'',
				);

				setValue(tempPassengersNames.adultsName, tempAdults);

				setValue(tempPassengersNames.childrenName, tempChildren);

				setValue(tempPassengersNames.name, passengersDisplayValue || room?.display || '');

				let tempChildrenAges: number[] = room?.childrenAges?.length ? [...room.childrenAges] : Array(tempChildren).fill(0);
				if (tempChildrenAges.length < tempChildren) {
					tempChildrenAges = tempChildrenAges.concat(Array(tempChildren).fill(0));
				} else {
					tempChildrenAges.splice(tempChildren);
				}

				tempRoomsChildrenAges.push([...tempChildrenAges]);

				tempTotalAdults += tempAdults;

				tempTotalChildren += tempChildren;

				counterAcceptableRooms++;
			});
		} else {
			const tempPassengersNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(
				roomsNames?.length ? roomsNames[firstIndex].name : '',
			);

			const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
				defaultAdults,
				defaultChildren,
				0,
				traductions('adult') || 'adult',
				traductions('adults') || 'adults',
				traductions('child') || 'child',
				traductions('children') || 'children',
				'',
				'',
			);

			setValue(tempPassengersNames.adultsName, defaultAdults);

			setValue(tempPassengersNames.childrenName, defaultChildren);

			setValue(tempPassengersNames.name, passengersDisplayValue || '');

			tempRoomsChildrenAges.push([...Array(defaultChildren).fill(0)]);

			tempTotalAdults += defaultAdults;

			tempTotalChildren += defaultChildren;

			counterAcceptableRooms++;
		}

		if (applyAllfieldsHistory) {
			setRoomsNumber(!counterAcceptableRooms ? minRoomsNumber : counterAcceptableRooms);

			setTotalAdults(tempTotalAdults || defaultAdults);

			setTotalChildren(tempTotalChildren || defaultChildren);

			setChildrenAges([...tempRoomsChildrenAges]);

			setValue(datesName, history?.dates || '');

			setValue(tempDatesNames.startDate, history?.departureDate || '');

			setValue(tempDatesNames.endDate, history?.arrivalDate || '');
		}

		setValue(destinationName, history?.destination || '');

		setValue(destinationHiddenName, history?.destinationIata || '');

		setValue(locationKey, history?.locationKey ?? '');

		const departureDate: string = history?.departureDate || '';
		const arrivalDate: string = history?.arrivalDate || '';
		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.startEndDates,
			{
				date: true,
				time: false,
			},
			departureDate,
			'',
			arrivalDate,
			'',
		);

		setButtonSearchHistory(
			history?.destinationIata
				? {
					departure: showMobileIataField ? history.destinationIata : history.destination,
					arrival: '',
					departureDate: datesValues.largeStartDate || '',
					arrivalDate: datesValues.largeEndDate || '',
				  }
				: undefined,
		);

		const tempStartDate: Date = new Date(`${departureDate}T00:00:00`);
		const tempEndDate: Date = new Date(`${arrivalDate}T00:00:00`);
		setDefaultDates(
			tempStartDate >= minDate && tempEndDate >= minDate
				? {
					startDate: tempStartDate,
					endDate: tempEndDate,
				  }
				: {
					startDate: minDate,
					endDate: minDate,
				  },
		);
	}, [history, minDate, roomsNames, traductions]);

	useEffect(() => {
		if (maxRooms === 1) {
			setShowAddRoomButtons(false);
		} else {
			setShowAddRoomButtons(true);
		}
	}, []);

	return {
		destinationName,
		locationKey,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		roomsNames,
		destinationId,
		datesId,
		mobileFieldStep: mobileStep,
		buttonSearchHistory,
		minDate,
		defaultDates,
		roomsNumber,
		showMultiActions,
		showAddRoomButtons,
		showMobileFieldsContainer: showContainer,
		childrenAges,
		mobileFormHeight,
		errors,
		isValid,
		nationalitySelectionName,
		nationalitySelectionId,
		showMobileNationalities,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		showMobileFieldsSecondaryHandler,
		destinationFieldHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		roomsFieldNumberHandler,
		roomsModalInputsHandler,
		childrenAgesHandler,
		onSubmit,
		nationalitiesMobileFieldHandler,
		inputRefs,
	};
};

export default useHotelsSearchForm;
