import { encryptWithPublicKey } from './encrypt-with-key.util';

export function encryptObject<T extends Record<string, any>>(
	obj: T,
	propertiesToEncrypt: string[],
	publicKey: string | null,
	propertiesToExcludeEncrypt: string[] = [],
): T {
	const arrayType: string = 'Array';
	if (obj !== null && typeof obj === 'object' && publicKey) {
		const keys: string[] = Object.keys(obj);
		for (const key of keys) {
			let toEncrypt: boolean = false;
			const value: string | T = obj[key];
			const isExcluded: boolean = propertiesToExcludeEncrypt.some(property => property === key);
			if (value !== null && typeof value === 'object' && !isExcluded) {
				const newObj: T = encryptObject(value, propertiesToEncrypt, publicKey, propertiesToExcludeEncrypt);
				(obj as Record<string, T>)[key] = newObj;
			} else if (obj.constructor.name === arrayType) {
				if (/\d+/.test(key)) {
					toEncrypt = propertiesToEncrypt.includes(key);
				}
			} else {
				toEncrypt = propertiesToEncrypt.includes(key);
			}

			if (toEncrypt && typeof value === 'string') {
				(obj[key] as string) = encryptWithPublicKey(value, publicKey);
			}
		}
	}

	return obj;
}
