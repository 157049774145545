import { PassengersFieldRestrictionsInterface, ListItemInterface } from '@smartlinks/react-design-system';
import { ActivitiesSearchLocationsInterface } from '../interfaces/activities-locations.interface';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';

export class ActivitiesUtil {
	public static passengersRestrictions(): PassengersFieldRestrictionsInterface {
		return {
			infants: {
				default: 0,
				minimum: 0,
				maximum: 0,
			},
			children: {
				default: 0,
				minimum: 0,
				maximum: 0,
			},
			adults: {
				default: 1,
				minimum: 1,
				maximum: 9,
			},
		};
	}

	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find((restriction: CalendarRestrictions) => restriction.product === CalendarRestrictionTypes.activities)?.value ||
			0
		);
	}

	public static mapLocationsList(locations: ActivitiesSearchLocationsInterface[]): ListItemInterface[] {
		const locationsList: ListItemInterface[] = [];
		locations.forEach((location: ActivitiesSearchLocationsInterface) => {
			const formattedLocation: string = `${location.city ?? ''} (${location.iata}) - ${location.country}`;
			const mapAirport: ListItemInterface = {
				name: (
					<>
						<span className='md:ml-6 text-xs hidden md:block'> {`${location.city ?? ''} - ${location.country} (${location.iata})`}</span>
						<div className='activitiesSearchForm__listContainer flex flex-col gap-3 md:hidden pb-8 text-black'>
							<text className='pinIco text-[16px] font-bold'>{`${location.city ?? ''} (${location.iata})`}</text>
							<span className='pl-5 text-[14px]'>{location.country}</span>
						</div>
					</>
				),
				originalName: formattedLocation,
				id: location.iata,
				value: location.iata,
				link: '',
			};

			locationsList.push({ ...mapAirport });
		});

		return [...locationsList];
	}
}
