import CoverageCarCard, { CoverageCarCardProps } from '../../../components/cars/coverage-car-card/coverage-car-card';
import CarsCurrencySelectV2 from '../../../components/cars/cars-currency-select-v2/cars-currency-select-v2';
import { OptionSelect, InputEventsHandler } from '../../../../../shared/interfaces/inputs.interface';
import { CardAlertDropOff } from '../../../components/cars/card-alert-drop-off/card-alert-drop-off';
import CarGridRecommendations from '../car-grid-recommendations/car-grid-recommendations';
import { GenericMessage } from '../../../../ui-elements/generic-message/generic-message';
import CarsRecommendationV2Skeleton from './skeleton/skeleton-cars-recommendation-v2';
import { RangeInputTemplate } from '../../../../../shared/enums/range-input.enum';
import { addAlphaToColor } from '../../../../../shared/services/utils.service';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import { ModelTypeEnum } from '../../../../../shared/enums/model-type.enum';
import RangeInput from '../../../../ui-elements/range-input/range-input';
import {
	CarInterfaceV2,
	RentalCarInterfaceV2,
	FilterRateRangeInterface,
	GroupedCoveragesInterfaceV2,
} from '../../../shared/interfaces/car-recommendation-v2.interface';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import { useState, useCallback, useMemo, useEffect } from 'react';
import Select from '../../../../ui-elements/select/select';
import Button from '../../../../ui-elements/button/button';
import styles from './cars-recommendation-v2.module.scss';
import {
	InputsFilterCarsNamesV2,
	OrderOptionsValuesV2,
	DisplayTypeV2,
	AllOptionsV2,
	SelectedRateV2,
	TransmissionTypeV2,
	CarsRatesModelsV2,
} from '../../../shared/enums/filter-cars-cards-v2.enum';
import { useForm } from 'react-hook-form';

export interface CarsRecommendationV2Props extends CoverageCarCardProps {
	data: CarInterfaceV2[];
	orderOptions: OptionSelect[];
	orderSelectText: string;
	filterText: string;
	carTypeSelectText: string;
	companySelectText: string;
	transmissionSelectText: string;
	allOptionText: string;
	rateMinRangeText: string;
	rateMaxRangeText: string;
	rateRangeText: string;
	gridDescriptionText: string;
	noRecommendationsText: string;
	soldOutText: string;
	editText: string;
	restoreText: string;
	applyText: string;
	mobileZIndex: number;
	outsideFilterButton?: boolean;
	showMobileFilter?: boolean;
	isWithUser?: boolean;
	isShowPcoPrice?: boolean;
	disabledCarsButtons?: boolean;
	defaultViewType?: DisplayTypeV2;
	defaultCurrency?: string;
	currencySymbol?: string;
	culture?: string;
	informationCardMessage?: string;
	closeMobileFilter?: () => void;
	detailClick?: (data: CarInterfaceV2) => void;
	editClick?: () => void;
	viewTypeClick?: (type: DisplayTypeV2 | string) => void;
	changeCurrencyEvent?: (currency: string) => void;
	informationStandardMessage?: string;
	disabledCarsCurrencySelectV2?: boolean;
	isActiveMultiLanguage?: boolean;
}

export function CarsRecommendationV2({
	data,
	orderOptions,
	dailyRateText,
	discountText,
	includeAirConditionText,
	includeLargeAirConditionText,
	passengersText,
	doorsText,
	baggageText,
	manualTransmissionText,
	automaticTransmissionText,
	rateText,
	coverageButtonText,
	orderSelectText,
	filterText,
	carTypeSelectText,
	companySelectText,
	transmissionSelectText,
	allOptionText,
	rateMinRangeText,
	rateMaxRangeText,
	rateRangeText,
	gridDescriptionText,
	noRecommendationsText,
	soldOutText,
	editText,
	restoreText,
	applyText,
	outsideFilterButton,
	showMobileFilter,
	loading,
	disabledCarsButtons,
	defaultViewType,
	textureImage,
	colorPrimary,
	mobileZIndex,
	className,
	isWithUser,
	isShowPcoPrice,
	currencySymbol,
	defaultCurrency,
	culture,
	onShareClick,
	closeMobileFilter,
	detailClick,
	editClick,
	viewTypeClick,
	changeCurrencyEvent,
	t,
	informationCardMessage,
	informationStandardMessage,
	disabledCarsCurrencySelectV2,
	isActiveMultiLanguage,
}: CarsRecommendationV2Props): JSX.Element {
	const firstItem: number = 0;
	const windowWidth: number = useWindowWidth();
	const {
		register,
		handleSubmit,
		setFocus,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();

	const [carSample, setCarSample] = useState<CarInterfaceV2 | undefined>();
	const [carsRecommendation, setCarsRecommendation] = useState<CarInterfaceV2[]>([]);
	const [currency, setCurrency] = useState<string>(disabledCarsCurrencySelectV2 ? CarsRatesModelsV2.rate : '');
	const [order, setOrder] = useState<string>('');
	const [displayType, setDisplayType] = useState<DisplayTypeV2>(defaultViewType || DisplayTypeV2.list);
	const [carType, setCarType] = useState<string>('');
	const [company, setCompany] = useState<string>('');
	const [transmission, setTransmission] = useState<string>('');
	const [rate, setRate] = useState<FilterRateRangeInterface>({} as unknown as FilterRateRangeInterface);
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const [restoreFlag, setRestoreFlag] = useState<number>(0);
	const [openFilterFlag, setOpenFilterFlag] = useState<number>(0);
	const isActive: boolean = data && !!data.length;
	const noFilteredData: boolean = !carsRecommendation.length;
	const alphaColorPrimary: string | undefined = colorPrimary ? addAlphaToColor(colorPrimary, 0.25) : undefined;
	const carTypeOptions = useMemo(
		() =>
			((tempData: CarInterfaceV2[]): OptionSelect[] => {
				const options: OptionSelect[] = [
					{
						value: AllOptionsV2,
						label: allOptionText,
					},
				];

				tempData.forEach((car: CarInterfaceV2) => {
					if (options.findIndex((option: OptionSelect): boolean => option.value === car.car.vehicleType) === -1) {
						options.push({
							value: car.car.vehicleType,
							label: car.car.vehicleTypeLabel,
						});
					}
				});

				const tempCarType: string = options && options.length && options[firstItem] ? options[firstItem].value : '';
				setCarType(tempCarType);

				return [...options];
			})(data),
		[data],
	);

	const companyOptions = useMemo(
		() =>
			((tempData: CarInterfaceV2[]): OptionSelect[] => {
				const options: OptionSelect[] = [
					{
						value: AllOptionsV2,
						label: allOptionText,
					},
				];

				tempData.forEach((car: CarInterfaceV2) => {
					car.rateByRentalCar.forEach((company: RentalCarInterfaceV2) => {
						if (options.findIndex((option: OptionSelect): boolean => option.value === company.rentalCarName) === -1) {
							options.push({
								value: company.rentalCarName,
								label: company.rentalCarName,
							});
						}
					});
				});

				const tempCompany: string = options && options.length && options[firstItem] ? options[firstItem].value : '';
				setCompany(tempCompany);

				return [...options];
			})(data),
		[data],
	);

	const transmissionOptions = useMemo(
		() =>
			((tempData: CarInterfaceV2[], tempAutomatic?: string, tempManual?: string): OptionSelect[] => {
				const options: OptionSelect[] = [
					{
						value: AllOptionsV2,
						label: allOptionText,
					},
				];

				tempData.forEach((car: CarInterfaceV2) => {
					if (options.findIndex((option: OptionSelect): boolean => option.value === car.car.transmmisionType) === -1) {
						let label: string = '';
						switch (car.car.transmmisionType) {
							case TransmissionTypeV2.automatic:
								label = tempAutomatic || '';

								break;
							case TransmissionTypeV2.manual:
								label = tempManual || '';

								break;
							default:
								break;
						}
						options.push({
							value: car.car.transmmisionType,
							label,
						});
					}
				});

				const tempTransmission: string = options && options.length && options[firstItem] ? options[firstItem].value : '';
				setTransmission(tempTransmission);

				return [...options];
			})(data, automaticTransmissionText, manualTransmissionText),
		[data, automaticTransmissionText, manualTransmissionText],
	);

	const rateRange = useMemo(
		() =>
			((tempData: CarInterfaceV2[], tempCurrency: string, tempCarSample?: CarInterfaceV2): FilterRateRangeInterface => {
				let tempMin: number | undefined;
				let tempMax: number | undefined;
				if (tempCarSample?.businessModel === ModelTypeEnum.CASH || tempCarSample?.businessModel === ModelTypeEnum.POINTS) {
					tempData.forEach((car: CarInterfaceV2) => {
						const currencyIsCorrect: boolean =
							(tempCarSample?.businessModel === ModelTypeEnum.CASH && tempCurrency in car) || tempCarSample?.businessModel === ModelTypeEnum.POINTS;

						if (currencyIsCorrect) {
							if (!tempMin && tempMin !== 0) {
								tempMin = tempCarSample?.businessModel === ModelTypeEnum.CASH ? car[tempCurrency].rateFrom : car?.rate?.pointsFrom;

								tempMax = tempCarSample?.businessModel === ModelTypeEnum.CASH ? car[tempCurrency].rateFrom : car?.rate?.pointsFrom;
							} else {
								const tempValue: number =
									(tempCarSample?.businessModel === ModelTypeEnum.CASH ? car[tempCurrency].rateFrom : car?.rate?.pointsFrom) || 0;

								if (tempValue < tempMin) {
									tempMin = tempValue;
								}

								if (tempValue > Number(tempMax)) {
									tempMax = tempValue;
								}
							}
						}
					});
				}

				const tempRate: FilterRateRangeInterface = {
					maxValue: Math.ceil(Number(tempMax)),
					minValue: Math.floor(Number(tempMin)),
					currency: tempCarSample?.businessModel === ModelTypeEnum.CASH ? tempCurrency : tempCarSample?.rate?.pointsCurrency || '',
				};

				setValue(InputsFilterCarsNamesV2.minRate, String(tempRate.minValue));

				setValue(InputsFilterCarsNamesV2.maxRate, String(tempRate.maxValue));

				setRate({ ...tempRate });

				return { ...tempRate };
			})(data, currency, carSample),
		[data, currency, carSample, setValue],
	);

	const selectHandler = useCallback(
		(event: InputEventsHandler): void => {
			if (event.name === InputsFilterCarsNamesV2.currency) {
				setCurrency(String(event.value));

				if (changeCurrencyEvent) {
					changeCurrencyEvent(String(event.value));
				}
			} else if (event.name === InputsFilterCarsNamesV2.order) {
				setOrder(String(event.value));
			}
		},
		[changeCurrencyEvent],
	);

	const formInputsHandler = useCallback((): void => {
		const responsiveSize: number = 1024;
		const inputsValues: Record<string, any> = getValues();
		Object.entries(errors).forEach(([key, value]): void => {
			if (value && key) {
				const waitTime: number = 100;
				setTimeout(() => {
					setFocus(key);
				}, waitTime);
			}
		});

		if (windowWidth < responsiveSize) {
			return;
		}

		if (inputsValues[InputsFilterCarsNamesV2.type]) {
			setCarType(String(inputsValues[InputsFilterCarsNamesV2.type]));
		}

		if (inputsValues[InputsFilterCarsNamesV2.company]) {
			setCompany(String(inputsValues[InputsFilterCarsNamesV2.company]));
		}

		if (inputsValues[InputsFilterCarsNamesV2.transmission]) {
			setTransmission(String(inputsValues[InputsFilterCarsNamesV2.transmission]));
		}

		if (inputsValues[InputsFilterCarsNamesV2.minRate] && inputsValues[InputsFilterCarsNamesV2.maxRate]) {
			setRate((prevState: FilterRateRangeInterface) => {
				return {
					...prevState,
					minValue: Number(inputsValues[InputsFilterCarsNamesV2.minRate]),
					maxValue: Number(inputsValues[InputsFilterCarsNamesV2.maxRate]),
				};
			});
		}
	}, [windowWidth, errors, getValues, setFocus]);

	const filterSelectHandler = useCallback(
		(event: InputEventsHandler): void => {
			formInputsHandler();
		},
		[formInputsHandler],
	);

	const detailClickHandler = useCallback(
		(car: CarInterfaceV2, company: RentalCarInterfaceV2, coverage: GroupedCoveragesInterfaceV2): void => {
			const tempCar: CarInterfaceV2 = car ? { ...car } : ({} as unknown as CarInterfaceV2);
			const rateModelPoints: CarsRatesModelsV2 = car?.isAvailabilityInLocalCurrency ? CarsRatesModelsV2.equivalentRate : CarsRatesModelsV2.rate;
			const tempCurrency: string | CarsRatesModelsV2 = car?.businessModel === ModelTypeEnum.CASH ? currency : rateModelPoints;
			const tempCompany: RentalCarInterfaceV2 | undefined = tempCar.rateByRentalCar.find(
				(item: RentalCarInterfaceV2): boolean => item.rentalCarCode === company.rentalCarCode,
			);

			if (tempCompany) {
				tempCar.rateByRentalCar = [{ ...tempCompany }];

				tempCar.rateByRentalCar[firstItem].rateByCoverage = tempCar.rateByRentalCar[firstItem].rateByCoverage.map(
					(item: GroupedCoveragesInterfaceV2): GroupedCoveragesInterfaceV2 => {
						return {
							...item,
							selected: item.rateCodeType === coverage.rateCodeType,
							selectedRate: tempCurrency as SelectedRateV2,
						};
					},
				);

				if (detailClick) {
					detailClick(tempCar);
				}
			}
		},
		[currency],
	);

	const displayButtonHandler = useCallback(
		(type: DisplayTypeV2): void => {
			setDisplayType(type);

			if (viewTypeClick) {
				viewTypeClick(type);
			}
		},
		[viewTypeClick],
	);

	const closeFilter = useCallback((): void => {
		if (closeMobileFilter) {
			closeMobileFilter();
		}

		setOpenFilterFlag((prevState: number): number => prevState + 1);

		setIsFilterOpen(false);
	}, []);

	const closeFilterHandler = useCallback((): void => {
		setValue(InputsFilterCarsNamesV2.type, carType);

		setValue(InputsFilterCarsNamesV2.company, company);

		setValue(InputsFilterCarsNamesV2.transmission, transmission);

		setValue(InputsFilterCarsNamesV2.minRate, String(rate.minValue));

		setValue(InputsFilterCarsNamesV2.maxRate, String(rate.maxValue));

		closeFilter();
	}, [carType, company, transmission, rate]);

	const restoreFilterHandler = useCallback((): void => {
		const tempCarType: string = carTypeOptions && carTypeOptions.length && carTypeOptions[firstItem] ? carTypeOptions[firstItem].value : '';
		const tempCompany: string = companyOptions && companyOptions.length && companyOptions[firstItem] ? companyOptions[firstItem].value : '';
		const tempTransmission: string =
			transmissionOptions && transmissionOptions.length && transmissionOptions[firstItem] ? transmissionOptions[firstItem].value : '';

		setCarType(tempCarType);

		setValue(InputsFilterCarsNamesV2.type, tempCarType);

		setCompany(tempCompany);

		setValue(InputsFilterCarsNamesV2.company, tempCompany);

		setTransmission(tempTransmission);

		setValue(InputsFilterCarsNamesV2.transmission, tempTransmission);

		setRate({ ...rateRange });

		setValue(InputsFilterCarsNamesV2.minRate, rateRange.minValue);

		setValue(InputsFilterCarsNamesV2.maxRate, rateRange.maxValue);

		setRestoreFlag(prevState => prevState + 1);

		closeFilter();
	}, [carTypeOptions, companyOptions, transmissionOptions, rateRange, setValue, closeFilter]);

	const applyFilterHandler = useCallback(
		handleSubmit((data: Record<string, any>): void => {
			if (data[InputsFilterCarsNamesV2.type]) {
				setCarType(String(data[InputsFilterCarsNamesV2.type]));
			}

			if (data[InputsFilterCarsNamesV2.company]) {
				setCompany(String(data[InputsFilterCarsNamesV2.company]));
			}

			if (data[InputsFilterCarsNamesV2.transmission]) {
				setTransmission(String(data[InputsFilterCarsNamesV2.transmission]));
			}

			if (data[InputsFilterCarsNamesV2.minRate] && data[InputsFilterCarsNamesV2.maxRate]) {
				setRate((prevState: FilterRateRangeInterface) => {
					return {
						...prevState,
						minValue: Number(data[InputsFilterCarsNamesV2.minRate]),
						maxValue: Number(data[InputsFilterCarsNamesV2.maxRate]),
					};
				});
			}

			closeFilter();
		}),
		[closeFilter, handleSubmit],
	);

	useEffect(() => {
		if (!loading && data && data.length) {
			setCarSample({ ...data[firstItem] });
		}
	}, [data, loading]);

	useEffect(() => {
		if (!loading) {
			setOrder(orderOptions && orderOptions.length && orderOptions[firstItem] ? orderOptions[firstItem].value : '');
		}
	}, [orderOptions, loading]);

	useEffect(() => {
		if (data && !loading) {
			let tempData: CarInterfaceV2[] = [...data];
			tempData = tempData.filter((car: CarInterfaceV2): boolean => {
				let showCar: boolean = true;
				const rateFrom: number = currency in car ? car[currency].rateFrom : 0;
				if (carType !== AllOptionsV2 && carType !== car.car.vehicleType) {
					showCar = false;
				}

				if (
					company !== AllOptionsV2 &&
					car.rateByRentalCar.findIndex((option: RentalCarInterfaceV2): boolean => option.rentalCarName === company) === -1
				) {
					showCar = false;
				}

				if (transmission !== AllOptionsV2 && transmission !== car.car.transmmisionType) {
					showCar = false;
				}

				if (rateFrom < rate.minValue || rateFrom > rate.maxValue) {
					showCar = false;
				}

				return showCar;
			});

			switch (order) {
				case OrderOptionsValuesV2.cheap:
					tempData.sort((a: CarInterfaceV2, b: CarInterfaceV2): number => a.rate.rateFrom - b.rate.rateFrom);

					break;
				case OrderOptionsValuesV2.expensive:
					tempData.sort((a: CarInterfaceV2, b: CarInterfaceV2): number => b.rate.rateFrom - a.rate.rateFrom);

					break;
				default:
					break;
			}
			setCarsRecommendation([...tempData]);
		}
	}, [data, order, loading, currency, carType, company, transmission, rate]);

	useEffect(() => {
		if (!loading) {
			if (showMobileFilter) {
				setIsFilterOpen(true);
			}
		}
	}, [showMobileFilter, loading]);

	return loading ? (
		<CarsRecommendationV2Skeleton textureImage={textureImage} />
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`carsRecommendationV2 flex flex-row items-start ${className || ''}`}>
				<div
					key={`filter-${openFilterFlag}`}
					className={`carsRecommendationV2__filterContainer w-full lg:w-[362px] lg:min-w-[362px] lg:max-w-[362px] xl:w-[410px] xl:min-w-[410px] xl:max-w-[410px] 2xl:w-[462px] 2xl:min-w-[462px] 2xl:max-w-[462px] lg:block lg:h-auto lg:min-h-0 lg:overflow-y-visible lg:overflow-x-visible bg-white lg:bg-inherit lg:rounded-[20px] ${
						isFilterOpen ? 'carsRecommendationV2__open fixed overflow-y-auto overflow-x-hidden top-0 left-0 min-h-screen h-screen' : 'hidden'
					}`}
					style={{
						zIndex: isFilterOpen ? mobileZIndex : undefined,
					}}
				>
					<form
						key={`form-${openFilterFlag}`}
						onSubmit={applyFilterHandler}
						className={
							'carsRecommendationV2__filterForm w-full lg:w-[362px] lg:min-w-[362px] lg:max-w-[362px] xl:w-[410px] xl:min-w-[410px] xl:max-w-[410px] 2xl:w-[462px] 2xl:min-w-[462px] 2xl:max-w-[462px] min-h-screen lg:min-h-0 bg-[#7a80831a] lg:rounded-[20px] px-6 lg:px-12 pt-6 lg:pt-12 pb-24 lg:pb-12 bg-no-repeat bg-cover'
						}
						style={{
							backgroundImage: textureImage ? `url(${textureImage})` : undefined,
						}}
					>
						<div className='flex flex-row justify-between items-start text-black font-bold text-base lg:text-xl'>
							<p className='carsRecommendationV2__filterTitle mb-6'>{filterText}</p>
							<Button
								onClick={(): void => closeFilterHandler()}
								content={<IcomoonIcon icon='closeIco' fontSize={16} />}
								customClass={'flex lg:hidden px-0 py-0'}
								noHover={true}
							/>
						</div>
						<Select
							key={`carType-${restoreFlag}-${openFilterFlag}`}
							options={carTypeOptions}
							defaultValue={carType}
							label={carTypeSelectText}
							name={InputsFilterCarsNamesV2.type}
							className={'carsRecommendationV2__filterSelectContainer'}
							labelClassName={`carsRecommendationV2__filterLabel text-xs font-medium ${isActive ? 'text-[#494848]' : 'text-[#7A8083]'}`}
							selectClassName={`carsRecommendationV2__filterSelect font-bold text-xs font-medium bg-transparent ${
								isActive ? 'text-[#494848]' : 'text-[#7A8083]'
							}`}
							selectConatinerClassName={`mt-4 rounded-[10px] border-[1px] ${isActive ? 'border-[#494848]' : 'border-[#7A8083]'}`}
							onChange={filterSelectHandler}
							isSelectInsideDiv={true}
							readonly={!isActive}
							disabled={!isActive}
							register={register(InputsFilterCarsNamesV2.type, {
								required: false,
							})}
							optionsMarginTop='25px'
							setValue={setValue}
						/>
						<div className='w-full border-[#DDDDDD] border-b-[1px] my-6' />
						<Select
							key={`company-${restoreFlag}-${openFilterFlag}`}
							options={companyOptions}
							defaultValue={company}
							label={companySelectText}
							name={InputsFilterCarsNamesV2.company}
							className={'carsRecommendationV2__filterSelectContainer'}
							labelClassName={`carsRecommendationV2__filterLabel text-xs font-medium ${isActive ? 'text-[#494848]' : 'text-[#7A8083]'}`}
							selectClassName={`carsRecommendationV2__filterSelect font-bold text-xs font-medium bg-transparent ${
								isActive ? 'text-[#494848]' : 'text-[#7A8083]'
							}`}
							selectConatinerClassName={`mt-4 rounded-[10px] border-[1px] ${isActive ? 'border-[#494848]' : 'border-[#7A8083]'}`}
							onChange={filterSelectHandler}
							isSelectInsideDiv={true}
							readonly={!isActive}
							disabled={!isActive}
							register={register(InputsFilterCarsNamesV2.company, {
								required: false,
							})}
							optionsMarginTop='25px'
							setValue={setValue}
						/>
						<div className='w-full border-[#DDDDDD] border-b-[1px] my-6' />
						<Select
							key={`transmission-${restoreFlag}-${openFilterFlag}`}
							options={transmissionOptions}
							defaultValue={transmission}
							label={transmissionSelectText}
							name={InputsFilterCarsNamesV2.transmission}
							className={'carsRecommendationV2__filterSelectContainer carsRecommendationV2__transmissionFilterSelect'}
							labelClassName={`carsRecommendationV2__filterLabel text-xs font-medium ${isActive ? 'text-[#494848]' : 'text-[#7A8083]'}`}
							selectClassName={`carsRecommendationV2__filterSelect font-bold text-xs font-medium bg-transparent ${
								isActive ? 'text-[#494848]' : 'text-[#7A8083]'
							}`}
							selectConatinerClassName={`mt-4 rounded-[10px] border-[1px] ${isActive ? 'border-[#494848]' : 'border-[#7A8083]'}`}
							onChange={filterSelectHandler}
							isSelectInsideDiv={true}
							readonly={!isActive}
							disabled={!isActive}
							register={register(InputsFilterCarsNamesV2.transmission, {
								required: false,
							})}
							optionsMarginTop='25px'
							setValue={setValue}
						/>
						{(carSample?.businessModel === ModelTypeEnum.CASH || carSample?.businessModel === ModelTypeEnum.POINTS) && (
							<>
								<div className='w-full border-[#DDDDDD] border-b-[1px] my-6' />
								{(!isActive ||
									((rateRange?.minValue || rateRange?.minValue === 0) &&
										(rateRange?.maxValue || rateRange?.maxValue === 0) &&
										rateRange?.currency &&
										rateRange.currency === currency)) && (
									<RangeInput
										key={`${currency}-${rateRange.minValue}-${rateRange.maxValue}-${restoreFlag}-${openFilterFlag}`}
										name={InputsFilterCarsNamesV2.rate}
										label={rateRangeText}
										min={!isActive ? '0' : String(rateRange.minValue)}
										max={!isActive ? '100' : String(rateRange.maxValue)}
										step='1'
										template={RangeInputTemplate.InputSquares}
										currency={carSample && currency in carSample ? carSample[currency].currency : ''}
										minLabel={rateMinRangeText}
										maxLabel={rateMaxRangeText}
										minDefaultValue={rate?.minValue || 0}
										maxDefaultValue={rate?.maxValue || 100}
										onChange={selectHandler}
										labelClassName='carsRecommendationV2__filterLabel text-xs font-medium text-[#494848] mb-[21px]'
										disabled={!isActive || data.length === 1}
										colorPrimary={!isActive || data.length === 1 ? '#7A8083' : colorPrimary}
										containerClassName={'lg:px-2'}
										minRegister={register(InputsFilterCarsNamesV2.minRate, {
											required: false,
											onChange: formInputsHandler,
										})}
										maxRegister={register(InputsFilterCarsNamesV2.maxRate, {
											required: false,
											onChange: formInputsHandler,
										})}
									/>
								)}
							</>
						)}
						<div className='flex lg:hidden flex-col mt-6'>
							<Button
								text={applyText}
								customClass={
									'carsRecommendationV2__filterButton carsRecommendationV2__filterSubmitButton w-full rounded-[10px] py-[11px] px-[0] font-bold text-base text-black min-h-[45px]'
								}
								noHover={true}
								styles={{
									backgroundColor: colorPrimary,
								}}
								type='submit'
							/>
							<Button
								onClick={restoreFilterHandler}
								text={restoreText}
								customClass={
									'carsRecommendationV2__filterButton carsRecommendationV2__filterRestoreButton bg-transparent py-[0] px-[0] font-normal text-xs text-[#CCCCCC] carsRecommendationV2__restoreButton'
								}
								noHover={true}
							/>
						</div>
					</form>
				</div>
				<div className={'carsRecommendationV2__carsContainer flex-grow flex flex-col pl-0 lg:pl-6 xl:pl-12 min-w-0 '}>
					<div
						className={`flex flex-row items-center ${
							carSample?.businessModel === ModelTypeEnum.CASH || carSample?.businessModel === ModelTypeEnum.POINTS ? 'justify-between' : 'justify-end'
						}`}
					>
						{(carSample?.businessModel === ModelTypeEnum.CASH || carSample?.businessModel === ModelTypeEnum.POINTS) && (
							<Select
								key={`${order}`}
								options={orderOptions}
								defaultValue={order}
								label={`${orderSelectText}:`}
								name={InputsFilterCarsNamesV2.order}
								className={'carsRecommendationV2__headerSelectContainer carsRecommendationV2__headerOrderSelect items-center mr-2 md:mr-0'}
								labelClassName={`carsRecommendationV2__headerLabel text-xs ${isActive ? 'text-black' : 'text-[#7A8083]'}`}
								selectClassName={`carsRecommendationV2__headerSelect ml-[5px] text-xs font-medium bg-transparent w-auto inline-block mt-0 ${
									isActive ? 'text-black' : 'text-[#7A8083]'
								}`}
								onChange={selectHandler}
								readonly={!isActive || noFilteredData}
								disabled={!isActive || noFilteredData}
								optionsMarginTop='10px'
							/>
						)}
						<div className='flex flex-row items-center'>
							{carSample?.businessModel === ModelTypeEnum.CASH && !disabledCarsCurrencySelectV2 && (
								<CarsCurrencySelectV2
									key={`${defaultCurrency || ''}`}
									carData={carSample || ({} as unknown as CarInterfaceV2)}
									onChange={selectHandler}
									className='mr-2 md:mr-5'
									readonly={!isActive || noFilteredData}
									disabled={!isActive || noFilteredData}
									defaultValue={defaultCurrency}
								/>
							)}
							{!outsideFilterButton && (
								<Button
									onClick={(): void => setIsFilterOpen(true)}
									content={<IcomoonIcon icon='filterIco' fontSize={15} color={colorPrimary} />}
									customClass={
										'flex md:hidden flex-row items-center justify-center carsRecommendationV2__activeHeaderButton carsRecommendationV2__activeHeaderButton mr-3.5 rounded-[5px]'
									}
									noHover={true}
									styles={{
										backgroundColor: alphaColorPrimary,
									}}
								/>
							)}
							<Button
								onClick={() => displayButtonHandler(DisplayTypeV2.list)}
								content={
									<IcomoonIcon
										icon='listIco'
										fontSize={15}
										color={displayType === DisplayTypeV2.list && !noFilteredData ? colorPrimary : undefined}
									/>
								}
								customClass={`flex flex-row items-center justify-center mr-3.5 md:mr-6 ${
									displayType === DisplayTypeV2.list ? 'carsRecommendationV2__activeHeaderButton rounded-[5px]' : 'carsRecommendationV2__headerButton'
								} ${!isActive || noFilteredData ? 'text-gray-200 bg-transparent' : ''}`}
								noHover={true}
								styles={{
									backgroundColor: displayType === DisplayTypeV2.list && !noFilteredData ? alphaColorPrimary : undefined,
								}}
								disabled={!isActive || noFilteredData}
							/>
							<Button
								onClick={() => displayButtonHandler(DisplayTypeV2.grid)}
								content={
									<IcomoonIcon
										icon='squaresIco'
										fontSize={15}
										color={displayType === DisplayTypeV2.grid && !noFilteredData ? colorPrimary : undefined}
									/>
								}
								customClass={`flex flex-row items-center justify-center ${
									displayType === DisplayTypeV2.grid ? 'carsRecommendationV2__activeHeaderButton rounded-[5px]' : 'carsRecommendationV2__headerButton'
								} ${!isActive || noFilteredData ? 'text-gray-200 !bg-transparent' : ''}`}
								noHover={true}
								styles={{
									backgroundColor: displayType === DisplayTypeV2.grid && !noFilteredData ? alphaColorPrimary : undefined,
								}}
								disabled={!isActive || noFilteredData}
							/>
						</div>
					</div>
					<div className='pt-8'>
						{carSample?.hasDropOffDetail && <CardAlertDropOff t={t} />}
						{isActive && !noFilteredData ? (
							<>
								{displayType === DisplayTypeV2.grid && (
									<CarGridRecommendations
										recommendations={carsRecommendation}
										transitionTime={60000}
										descriptionText={gridDescriptionText}
										soldOutText={soldOutText}
										dailyRateText={dailyRateText}
										discountText={discountText}
										rateText={rateText}
										includeAirConditionText={includeAirConditionText}
										includeLargeAirConditionText={includeLargeAirConditionText}
										passengersText={passengersText}
										doorsText={doorsText}
										baggageText={baggageText}
										manualTransmissionText={manualTransmissionText}
										automaticTransmissionText={automaticTransmissionText}
										colorPrimary={colorPrimary}
										rateModel={currency}
										disabled={disabledCarsButtons}
										filterCompany={company === AllOptionsV2 ? null : company}
										onClick={detailClickHandler}
										companyImageClassName='h-[24px] w-[65px]'
										className='carsRecommendationV2__carsGrid'
										isWithUser={isWithUser}
										isShowPcoPrice={isShowPcoPrice}
										currencySymbol={currencySymbol || ''}
										culture={culture || ''}
										t={t}
										informationCardMessage={informationCardMessage}
										isActiveMultiLanguage={isActiveMultiLanguage}
									/>
								)}
								{displayType === DisplayTypeV2.list && (
									<div className='carsRecommendationV2__carsList flex flex-col'>
										{carsRecommendation.map((car: CarInterfaceV2) => {
											return (
												<CoverageCarCard
													key={`${car.car.model}-${car.car.vehicleType}-${car.car.type}`}
													carInfo={car}
													dailyRateText={dailyRateText}
													discountText={discountText}
													includeAirConditionText={includeAirConditionText}
													includeLargeAirConditionText={includeLargeAirConditionText}
													passengersText={passengersText}
													doorsText={doorsText}
													baggageText={baggageText}
													manualTransmissionText={manualTransmissionText}
													automaticTransmissionText={automaticTransmissionText}
													rateText={rateText}
													coverageButtonText={coverageButtonText}
													colorPrimary={colorPrimary}
													rateModel={currency}
													multipleCompanies={true}
													showRate={true}
													showButtons={true}
													textureImage={textureImage}
													className={'bg-white w-[95%] md:w-full md:min-w-[700px] rounded-xl mb-5 carsRecommendationV2__coverageCarCard'}
													companyImageClassName='h-[15px] w-[41px]'
													carImageClassName=''
													carContainerClassName=''
													filterCompany={company === AllOptionsV2 ? null : company}
													onClick={detailClickHandler}
													onShareClick={onShareClick}
													disabled={disabledCarsButtons}
													isWithUser={isWithUser}
													isShowPcoPrice={isShowPcoPrice}
													currencySymbol={currencySymbol || ''}
													culture={culture || ''}
													t={t}
													pointsAccumulation={car.totalAccumulationProductPoint}
													informationMessage={informationCardMessage}
													isActiveMultiLanguage={isActiveMultiLanguage}
												/>
											);
										})}
									</div>
								)}
							</>
						) : (
							<div className='w-full flex flex-col items-center'>
								<p className='carsRecommendationV2__noRecommendationsText w-full bg-black text-white text-center p-2.5 text-xs font-medium rounded-[5px]'>
									{noRecommendationsText}
								</p>
							</div>
						)}
					</div>
					<GenericMessage message={informationStandardMessage} />
				</div>
			</div>
		</>
	);
}
