import { BreadcrumbInterface } from '../../../shared/interfaces/breadcrumbs.interface';
import { BreadcrumbStates } from '../../../shared/enums/breadcrumbs.enum';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import styles from './breadcrumbs.module.scss';

export interface BreadcrumbsProps {
	breadcrumbs: BreadcrumbInterface[];
	color?: string;
	colorActive?: string;
}

export function Breadcrumbs({ breadcrumbs, color, colorActive }: BreadcrumbsProps): JSX.Element {
	const ignoreFirstIndexIcon: number = 0;
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<ul className='breadcrumbs'>
				{breadcrumbs.map((breadcrumb: BreadcrumbInterface, index: number) => {
					return (
						<li className='breadcrumbs__item' key={index}>
							{index > ignoreFirstIndexIcon && <IcomoonIcon color={color} className='breadcrumbs__icon' icon='chevronRightIco' />}
							<a
								href={breadcrumb.link}
								style={{ color: breadcrumb.status === BreadcrumbStates.Active ? colorActive : color }}
								className={`breadcrumbs__title font-bold ${breadcrumb.status === BreadcrumbStates.Active ? 'breadcrumbs__title--active' : ''}`}
							>
								{breadcrumb.title}
							</a>
						</li>
					);
				})}
			</ul>
		</>
	);
}

export default Breadcrumbs;
