import { InputEventsHandler, RangeInputOutput, RangeInputMinMax } from '../../../shared/interfaces/inputs.interface';
import { RangeInputTemplate } from '../../../shared/enums/range-input.enum';
import { numberToLocaleWithcurrency } from '../../../shared/services';
import { FormsInputsType } from '../../../shared/enums/inputs.enum';
import { useEffect, useState, useCallback } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from './range-input.module.scss';

export interface RangeInputProps extends RangeInputMinMax {
	onChange?: (e: InputEventsHandler) => void;
	containerClassName?: string;
	labelClassName?: string;
	inputClassName?: string;
	colorPrimary?: string;
	step?: string;
	label?: string;
	style?: React.CSSProperties;
	currency?: string;
	template?: RangeInputTemplate;
	minLabel?: string;
	maxLabel?: string;
	name?: string;
	required?: boolean;
	disabled?: boolean;
	minRegister?: UseFormRegisterReturn;
	maxRegister?: UseFormRegisterReturn;
}

export const RangeInput = ({
	min,
	max,
	containerClassName,
	minDefaultValue,
	maxDefaultValue,
	step,
	label,
	style,
	onChange,
	template,
	currency,
	minLabel,
	maxLabel,
	colorPrimary,
	name,
	labelClassName,
	required,
	disabled,
	minRegister,
	maxRegister,
}: RangeInputProps): JSX.Element => {
	const limit: number = 100;
	const zIndexDefault: string = 'z-10';
	const [minValue, setMinValue] = useState<number>(minDefaultValue || 0);
	const [maxValue, setMaxValue] = useState<number>(maxDefaultValue || 100);
	const [minZIndex, setMinZIndex] = useState<string>(zIndexDefault);
	const [maxZIndex, setMaxZIndex] = useState<string>(zIndexDefault);
	const [firstBarPercent, setFirstBarPercent] = useState<number>(0);
	const [secondBarPercent, setSecondBarPercent] = useState<number>(100);
	const leftRangeId: string = `${name || ''}LeftRange`;
	const rightRangeId: string = `${name || ''}RightRange`;
	const zIndexCalculation = useCallback((): void => {
		const inputMin: HTMLInputElement | null = document.querySelector(`#${leftRangeId}`);
		const inputMax: HTMLInputElement | null = document.querySelector(`#${rightRangeId}`);
		const minInputValue: number = inputMin ? Number(inputMin.value) : 0;
		const maxInputValue: number = inputMax ? Number(inputMax.value) : 0;
		const zIndexHigher: string = 'z-30';
		if (minInputValue === Number(max)) {
			setMinZIndex(zIndexHigher);

			setMaxZIndex(zIndexDefault);
		}

		if (maxInputValue === Number(min)) {
			setMaxZIndex(zIndexHigher);

			setMinZIndex(zIndexDefault);
		}
	}, [leftRangeId]);

	const minValueCalculation = useCallback(
		(tempValue: number, tempMaxValue: number): void => {
			const inputMin: HTMLInputElement | null = document.querySelector(`#${leftRangeId}`);
			if (tempMaxValue - tempValue <= 0 && inputMin) {
				tempValue = tempMaxValue;

				inputMin.value = tempValue.toString();
			}

			setMinValue(tempValue);
		},
		[leftRangeId],
	);

	const maxValueCalculation = useCallback(
		(tempValue: number, tempMinValue: number): void => {
			const inputMax: HTMLInputElement | null = document.querySelector(`#${rightRangeId}`);
			if (tempMinValue - tempValue >= 0 && inputMax) {
				tempValue = tempMinValue;

				inputMax.value = tempValue.toString();
			}

			setMaxValue(tempValue);
		},
		[rightRangeId],
	);

	const minValueHandle = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			const value: string = event.target.value;
			const selectedValue: number = Number(value);
			minValueCalculation(selectedValue, maxValue);

			zIndexCalculation();
		},
		[maxValue, minValueCalculation],
	);

	const maxValueHandle = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>): void => {
			const value: string = event.target.value;
			const selectedValue: number = Number(value);
			maxValueCalculation(selectedValue, minValue);

			zIndexCalculation();
		},
		[minValue],
	);

	useEffect(() => {
		if ((minDefaultValue || minDefaultValue === 0) && (maxDefaultValue || maxDefaultValue === 0)) {
			minValueCalculation(minDefaultValue, minDefaultValue);

			maxValueCalculation(maxDefaultValue, maxDefaultValue);
		}
	}, [min, max]);

	useEffect(() => {
		if (onChange) {
			const rangeValue: RangeInputOutput = {
				minValue,
				maxValue,
			};

			const dataEvent: InputEventsHandler = {
				type: FormsInputsType.range,
				name,
				value: rangeValue,
			};

			onChange(dataEvent);
		}

		const m: number = limit / (Number(max) - Number(min));
		const percent1: number = m * (minValue - Number(min));
		const percent2: number = m * (maxValue - Number(min));
		setFirstBarPercent(percent1);

		setSecondBarPercent(percent2);
	}, [minValue, maxValue, min, max]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`rangeInput  ${containerClassName || ''} ${disabled ? 'rangeInput_disabled' : 'rangeInput_actived'}`}>
				{label && (
					<label className={`rangeInput__label w-full block ${labelClassName || ''}`}>
						{label} {required ? '*' : ''}
					</label>
				)}
				<div className='rangeInput__container relative' style={{ ...style }}>
					<div
						className={'rangeInput__track'}
						style={{
							background: `linear-gradient(to right, #dadae5 ${firstBarPercent}% , ${colorPrimary || '#FDD008'} ${firstBarPercent}% , ${
								colorPrimary || '#FDD008'
							} ${secondBarPercent}%, #dadae5 ${secondBarPercent}%)`,
						}}
					/>
					<input
						className={`rangeInput__input rangeInput__input-left ${minZIndex} ${disabled ? 'cursor-default' : ''}`}
						id={leftRangeId}
						type='range'
						min={min}
						max={max}
						defaultValue={minValue}
						step={step}
						onInput={minValueHandle}
						disabled={disabled}
						{...minRegister}
					/>
					<input
						className={`rangeInput__input rangeInput__input-right ${maxZIndex} ${disabled ? 'cursor-default' : ''}`}
						id={rightRangeId}
						type='range'
						min={min}
						max={max}
						defaultValue={maxValue}
						step={step}
						onInput={maxValueHandle}
						disabled={disabled}
						{...maxRegister}
					/>
				</div>
				{template === RangeInputTemplate.InputSquares && (
					<div className='rangeInput__template-inputSquares flex flex-col md:flex-row md:justify-between pt-[30px]'>
						<div className='rangeInput__item w-full md:w-2/4 mr-[10px]'>
							<div className='rangeInput__square rounded-lg flex border border-black px-[20px] py-[8px] justify-between items-center'>
								<p className='rangeInput__currency mr-[10px]'>{currency}</p>
								<p className='rangeInput__value'>{numberToLocaleWithcurrency(minValue, currency)}</p>
							</div>
							<p className='rangeInput__hint text-xs text-left text-gray-400 pt-[10px]'>
								{minLabel} {numberToLocaleWithcurrency(Number(min), currency)} {currency}
							</p>
						</div>

						<div className='rangeInput__item w-full md:w-2/4 mt-[5px] md:mt-0'>
							<div className='rangeInput__square rounded-lg flex border border-black px-[20px] py-[8px] justify-between items-center'>
								<p className='rangeInput__currency mr-[10px]'>{currency}</p>
								<p className='rangeInput__value'>{numberToLocaleWithcurrency(maxValue, currency)}</p>
							</div>
							<p className='rangeInput__hint text-xs text-gray-400 text-left md:text-right pt-[10px]'>
								{maxLabel} {numberToLocaleWithcurrency(Number(max), currency)} {currency}
							</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

RangeInput.defaultProps = {
	min: '0',
	max: '100',
	step: '1',
	template: RangeInputTemplate.InputSquares,
	currency: 'USD',
	minLabel: 'minLabel',
	maxLabel: 'maxLabel',
	minDefaultValue: 40,
	maxDefaultValue: 60,
};

export default RangeInput;
