import { CarRecommendationPayload } from '../../shared/interfaces/car-recommendation-payload.interface';
import { WidgetCommonInterface } from '../../shared/interfaces/commons.interface';
import { ShortCarRecommendation, Spinner } from '@smartlinks/react-design-system';
import { CarRecommendation } from '../../shared/interfaces/car-recommendation';
import { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { CarService } from '../../shared/services/cars.service';
import { endPoints } from '../../shared/end-points/end-points';
import ErrorTemplate from '../common/error/errorTemplate';
import { TFunction } from 'react-i18next';

const RecommendationCarV1 = lazy(async () => {
	return await import('@smartlinks/react-design-system').then(module => {
		return { default: module.RecommendationCarV1 };
	});
});

interface CarRecommendationsProps extends WidgetCommonInterface {
	carList: CarRecommendation[];
	carSearch: CarRecommendationPayload | null;
	useEquivalentCurrency?: boolean;
	onlyDailyPrice: boolean;
	carsService: CarService;
	t: TFunction<'translation', undefined>;
}

function CarRecommendations({
	carList,
	agency,
	carSearch,
	useEquivalentCurrency,
	onlyDailyPrice,
	carsService,
	t,
}: CarRecommendationsProps): JSX.Element {
	const [typePrice, setTypePrice] = useState<string>('');
	useEffect(() => {
		if (agency != null) {
			if (!agency?.profile?.pointsSystem) {
				setTypePrice('onlyCash');
			}

			if (agency?.profile?.pointsSystem && !agency?.profile?.usePointsAndMoney) {
				setTypePrice('onlyPoints');
			}

			if (agency?.profile?.pointsSystem && agency?.profile?.usePointsAndMoney) {
				setTypePrice('usePointsAndMoney');
			}
		}
	}, [agency]);

	const traduceList = useCallback(
		(car: CarRecommendation): CarRecommendation => {
			const tempCar: CarRecommendation = { ...car };
			tempCar.rateType = t(tempCar.rateType);

			tempCar.passengersTraduction = t('amountPassengers');

			tempCar.doorsTraduction = t('amountDoors');

			tempCar.baggageTraduction = t('amountBaggage');

			tempCar.airTraduction = t('airConditioning');

			tempCar.transmissionTraduction = t('transmission');

			tempCar.generalCoverage.forEach(coverage => {
				coverage.includeTraduction = t('thisCoverageIncludes');

				if (coverage.tag) {
					coverage.tag = t(coverage.tag);
				}
			});

			return { ...tempCar };
		},
		[t],
	);

	const search = useCallback(
		(carRecommendationItem?: CarRecommendation | ShortCarRecommendation | undefined): void => {
			if (carRecommendationItem != null) {
				const search: string = carsService.mapSearchUrlFromRecommnedation(carSearch, carRecommendationItem as CarRecommendation);
				const utm: string = 'utm_source=pwa_booking&utm_medium=widget';
				const agencyDomainToRedirect: string = agency?.profile?.domainUrl || '';
				const createAnchor = document.createElement('a');
				createAnchor.target = '_blank';

				createAnchor.href = endPoints.carSearch(agencyDomainToRedirect, `${search}${utm}`);

				createAnchor.click();
			}
		},
		[carSearch, agency],
	);

	return (
		<div className={`carRecommendationWidget flex flex-wrap justify-center md:justify-start`}>
			{carList?.length ? (
				carList.map((car: CarRecommendation, index: number) => {
					const tempCar: CarRecommendation = traduceList(car);
					return (
						<div key={index}>
							<Suspense fallback={<Spinner />}>
								<RecommendationCarV1
									data={tempCar}
									typePrice={typePrice}
									colorPrimary={agency?.lookAndFeel.style.primaryColor}
									colorSecondary={agency?.lookAndFeel.style.secondaryColor}
									colorTertiary={agency?.lookAndFeel.style.colorPrimaryAccent}
									buttonText={t('viewDeal')}
									dailyRateText={t('dailyRateText')}
									includeAirConditionText={t('yes')}
									useEquivalentCurrency={useEquivalentCurrency}
									onlyDailyPrice={onlyDailyPrice}
									onClick={search}
								/>
							</Suspense>
						</div>
					);
				})
			) : (
				<ErrorTemplate title={t('weSorry')} description={t('noResultsForSelectedDates')} />
			)}
		</div>
	);
}

export default CarRecommendations;
