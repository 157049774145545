import { FareSummaryInterface, DisplayFareSummaryInterface } from '../../../../../shared/interfaces/payments/summary.interface';
import { numberToLocaleWithcurrency } from '../../../../../shared/services/utils.service';
import { ModelTypeEnum } from '../../../../../shared/enums/model-type.enum';
import { Cupon } from '../../../../../shared/interfaces/index';
import styles from './summary-fares.module.scss';

export interface SummaryFaresProps {
	title: string;
	summaryFares: FareSummaryInterface;
	coupon?: Cupon;
	totalTitle?: string;
	subTotalTitle?: string;
	taxesTitle?: string;
	feetitle?: string;
	discountTitle?: string;
	basePriceTitle?: string;
	ivaTitle?: string;
	pricePerDayTitle?: string;
	extrasTitle?: string;
	forcedDisplay?: DisplayFareSummaryInterface;
	className?: string;
	businessModel?: ModelTypeEnum;
	showCurrencySymbol?: boolean;
	currencySymbol?: string;
}

export function SummaryFares(props: SummaryFaresProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`summaryFares mx-5 mt-5 ${props.className || ''}`}>
				{(props?.forcedDisplay?.base || !!props?.summaryFares?.base) && (
					<>
						<div className='summaryFares__base grid grid-cols-2 gap-4'>
							<div className='summaryFares__title text-left	font-family-bold	text-sm'>{props.basePriceTitle}</div>
							<div className='text-right font-family-bold text-sm summaryFares__value'>
								{typeof props.summaryFares?.base === 'number' || props.summaryFares?.base === undefined
									? `${props.summaryFares?.currency} ${numberToLocaleWithcurrency(props.summaryFares?.base || 0, props.summaryFares?.currency)}`
									: props.summaryFares?.base}
							</div>
						</div>
						<hr className='my-2' />
					</>
				)}

				{(props?.forcedDisplay?.pricePerDay || !!props?.summaryFares?.pricePerDay) && (
					<div className='summaryFares__pricePerDay grid grid-cols-2 gap-4'>
						<p className='text-left font-family-regular  text-xs summaryFares__title'>{props.pricePerDayTitle || 'pricePerDay'}</p>
						<p className='text-right font-family-regular text-xs summaryFares__value'>
							{typeof props?.summaryFares?.pricePerDay === 'number' || props?.summaryFares?.pricePerDay === undefined
								? `${props.summaryFares?.currency} ${numberToLocaleWithcurrency(props?.summaryFares?.pricePerDay || 0, props.summaryFares?.currency)}`
								: props?.summaryFares?.pricePerDay}
						</p>
					</div>
				)}

				{(props?.forcedDisplay?.extras || !!props?.summaryFares?.extras) && (
					<div className='summaryFares__extras grid grid-cols-2 gap-4'>
						<p className='text-left font-family-regular  text-xs summaryFares__title'>{props.extrasTitle || 'extrasTitle'}</p>
						<p className='text-right font-family-regular text-xs summaryFares__value'>
							{typeof props?.summaryFares?.extras === 'number' || props?.summaryFares?.extras === undefined
								? `${props.summaryFares?.currency} ${numberToLocaleWithcurrency(props?.summaryFares?.extras || 0, props.summaryFares?.currency)}`
								: props?.summaryFares?.extras}
						</p>
					</div>
				)}

				{(props?.forcedDisplay?.ivaTax || !!props?.summaryFares?.ivaTax) && (
					<div className='summaryFares__ivaTax grid grid-cols-2 gap-4'>
						<p className='text-left font-family-regular  text-xs summaryFares__title'>{props.ivaTitle}</p>
						<p className='text-right font-family-regular text-xs summaryFares__value'>
							{typeof props.summaryFares?.ivaTax === 'number' || props.summaryFares?.ivaTax === undefined
								? `${numberToLocaleWithcurrency(props.summaryFares?.ivaTax || 0, props.summaryFares?.currency)}`
								: props.summaryFares?.ivaTax}
						</p>
					</div>
				)}

				{(props?.forcedDisplay?.otherTaxes || !!props?.summaryFares?.otherTaxes) && (
					<div className='summaryFares__otherTaxes grid grid-cols-2 gap-4'>
						<p className='text-left font-family-regular text-xs summaryFares__title'>{props.taxesTitle}</p>
						<p className='text-right font-family-regular text-xs summaryFares__value'>
							{typeof props.summaryFares?.otherTaxes === 'number' || props.summaryFares?.otherTaxes === undefined
								? `${numberToLocaleWithcurrency(props.summaryFares?.otherTaxes || 0, props.summaryFares?.currency)}`
								: props.summaryFares?.otherTaxes}
						</p>
					</div>
				)}

				{(props?.forcedDisplay?.totalFee || !!props.summaryFares?.totalFee) && (
					<>
						<div className='summaryFares__totalFee grid grid-cols-2 gap-4'>
							<p className='text-left font-family-regular  text-xs summaryFares__title'>{props.feetitle}</p>
							<p className='text-right font-family-regular  text-xs summaryFares__value'>
								{typeof props.summaryFares?.totalFee === 'number' || props.summaryFares?.totalFee === undefined
									? `${numberToLocaleWithcurrency(props.summaryFares?.totalFee || 0, props.summaryFares?.currency)}`
									: props.summaryFares?.totalFee}
							</p>
						</div>
						<hr className='my-2' />
					</>
				)}

				<div className='summaryFares__subTotal grid grid-cols-2 gap-4'>
					{!!props.summaryFares?.subTotal &&
						props.businessModel !== ModelTypeEnum.POINT_OR_MONEY &&
						props.businessModel !== ModelTypeEnum.POINT_PLUS_MONEY && (
						<>
							<div className='text-left font-family-bold	text-sm summaryFares__title summaryFares__subTotalTitle'>{props.subTotalTitle}</div>
							<div className='text-right font-family-bold	text-sm summaryFares__value summaryFares__subTotal'>
								{typeof props.summaryFares?.subTotal === 'number' || props.summaryFares?.subTotal === undefined
									? `${props.summaryFares?.currency} ${numberToLocaleWithcurrency(props.summaryFares?.subTotal || 0, props.summaryFares?.currency)}`
									: props.summaryFares?.subTotal}
							</div>
						</>
					)}
				</div>
				<div className='grid grid-cols-2 gap-4 my-2'>
					<div className='text-left'>
						{!props?.coupon ? (
							''
						) : (
							<span className={'rounded-full px-2 py-1 font-family-bold summaryFares__title	text-sm descontBackGrount'}>{props.discountTitle}</span>
						)}
					</div>
					{!props?.coupon ? (
						''
					) : (
						<div className='summaryFares__discount text-right font-family-bold text-sm text-color-primary summaryFares__value'>
							<span className={!props.coupon?.isDiscount ? 'cuponIco mr-2' : 'discountIco mr-2'}></span>
							{typeof props.summaryFares.discount === 'number' || props.summaryFares.discount === undefined
								? `${props.summaryFares?.currency} ${
									props.coupon && props?.summaryFares?.discount
										? numberToLocaleWithcurrency(props.summaryFares.discount, props.summaryFares?.currency)
										: 0
								  }`
								: props.summaryFares.discount}
						</div>
					)}
				</div>
				<hr className='my-2' />
				<div className='summaryFares__total grid grid-cols-2 gap-4 my-4 text-color-primary'>
					{!!props?.summaryFares?.total && (
						<>
							<div className='text-left	font-family-bold	text-sm summaryFares__title summaryFares__totalTitle'>{props.totalTitle}</div>
							<div className='text-right'>
								<h2 className='title-black font-family-bold text-right text-base leading-6 summaryFares__value summaryFares__totalContainer'>
									{typeof props.summaryFares?.total === 'number' || props.summaryFares?.total === undefined
										? `${props.summaryFares?.currency} ${numberToLocaleWithcurrency(props.summaryFares?.total, props.summaryFares?.currency)}`
										: props.summaryFares?.total}
								</h2>
							</div>
						</>
					)}
				</div>

				<div className='summaryFares__total grid grid-cols-2 gap-4 my-4 text-color-primary'>
					<div className='text-left	font-family-bold	text-sm summaryFares__title summaryFares__totalTitle'></div>
					{(props.businessModel === ModelTypeEnum.POINT_OR_MONEY || props.businessModel === ModelTypeEnum.POINT_PLUS_MONEY) &&
						props?.summaryFares?.subTotal && (
						<div className='text-right'>
							{typeof props.summaryFares?.subTotal === 'number' || props.summaryFares?.subTotal === undefined
								? props.showCurrencySymbol
									? `+ ${numberToLocaleWithcurrency(props.summaryFares?.subTotal || 0, props.summaryFares?.currency, '', props.currencySymbol)}`
									: `+ ${props.summaryFares?.currency} ${numberToLocaleWithcurrency(
										props.summaryFares?.subTotal || 0,
										props.summaryFares?.currency,
										  )}`
								: `${props?.summaryFares?.subTotal ? '+' : ''} ${props.summaryFares?.subTotal}`}
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default SummaryFares;
