import { PassengersFieldInputNamesFlightsSearchFormInterface } from '../../../../flights/shared';
import { InputEventsHandler, OptionSelect } from '../../../../../shared/interfaces';
import { Select } from '../../../../ui-elements';

export interface CabinProps {
	cabinsOptions: OptionSelect[];
	cabinModalValue: string;
	colorSecondary?: string;
	label?: string;
	labelClassName?: string;
	isTertiaryTemplate?: boolean;
	tempPassengersNames: PassengersFieldInputNamesFlightsSearchFormInterface;
	traductions: (value: string) => string;
	cabinHandler: (e: InputEventsHandler) => void;
}

export function Cabin({
	cabinsOptions,
	cabinModalValue,
	colorSecondary,
	tempPassengersNames,
	labelClassName,
	label,
	isTertiaryTemplate,
	traductions,
	cabinHandler,
}: CabinProps): JSX.Element {
	return (
		<div className={'flightsSearchForm__cabinInput w-full md:w-2/5 relative md:pl-[7px]'} style={{ color: colorSecondary }}>
			<Select
				labelClassName={labelClassName}
				label={label}
				selectClassName={`${isTertiaryTemplate ? '!p-0 border-[#BEBEBE] text-[#4D4D4F] text-[14px] border border-solid rounded-none' 
					: 'bg-transparent !p-0 m-0 mt-0 !text-base placeholder:text-base'}`}
				className={isTertiaryTemplate ? 'text-[#4D4D4F] border border-none !py-0 gap-2' : 'py-[18px] px-[25px] border border-solid rounded-[10px]'}
				customSelectClassName={`${isTertiaryTemplate ? '!text-[14px] text-[#4D4D4F]' : ''} bg-transparent !p-0 m-0 text-base`}
				customSelectPlaceholderClassName='bg-transparent !p-0 text-xs font-normal text-[#C3C7CE]'
				name={tempPassengersNames.hiddenCabinName}
				options={cabinsOptions || []}
				onChange={cabinHandler}
				defaultValue={cabinModalValue}
				placeHolder={traductions('selectTheClass') || 'selectTheClass'}
				isTertiaryTemplate={isTertiaryTemplate}
			/>
		</div>
	);
}

export default Cabin;
