import { InputEventsHandler, CounterInputData } from '../../../../../shared/interfaces/inputs.interface';
import { SearchFieldsUtil } from '../../../../../shared/utils/search-fields.util';
import { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import {
	PassengersFieldInputNamesInterface,
	PassengersFieldDataInterface,
	DisabledInputsPassengersFieldInterface,
	CustomTraductionsLabelsPassengersFieldInterface,
} from '../../../../../shared/interfaces/generics/search-fields.interface';
import Counter from '../../../../ui-elements/counter/counter';
import { useState, useEffect, useCallback, FC } from 'react';
import styles from './passengers-field-inputs.module.scss';

/* eslint-disable-next-line */
export interface PassengersFieldInputsProps {
	name: string;
	adultsDefaultValue?: number;
	childrenDefaultValue?: number;
	infantsDefaultValue?: number;
	adultsCounterData: CounterInputData;
	childrenCounterData: CounterInputData;
	infantsCounterData: CounterInputData;
	disabledInputs?: DisabledInputsPassengersFieldInterface;
	customTraductionsLabels?: CustomTraductionsLabelsPassengersFieldInterface;
	colorSecondary?: string;
	style?: any;
	className?: string;
	passengersAmountsRestrictions?: number;
	onlyOneTypeOfPassenger?: boolean;
	useMinimunOnePassenger?: boolean;
	traductions: (label: string) => string;
	register: UseFormRegister<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	callBack?: (passengersFieldInputs: PassengersFieldDataInterface, name: string) => void;
	changeFieldValidation?: (data: PassengersFieldDataInterface, name: string) => PassengersFieldDataInterface;
	changeInputsValidation?: (currentData: PassengersFieldDataInterface, newValue: number, inputName: string, name: string) => number;
	typeProduct?: string;
	maxGuestsPerRoom?: number;
	maxRooms?: number;
	maxStay?: number;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
}

export const PassengersFieldInputs: FC<PassengersFieldInputsProps> = ({
	name,
	adultsDefaultValue,
	childrenDefaultValue,
	infantsDefaultValue,
	adultsCounterData,
	childrenCounterData,
	infantsCounterData,
	disabledInputs,
	customTraductionsLabels,
	colorSecondary,
	style,
	className,
	traductions,
	callBack,
	changeFieldValidation,
	changeInputsValidation,
	children,
	passengersAmountsRestrictions,
	onlyOneTypeOfPassenger,
	useMinimunOnePassenger,
	typeProduct,
	maxGuestsPerRoom,
	isSecondaryTemplate,
	isTertiaryTemplate,
}) => {
	const inputsNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
	const maxGuests: number = maxGuestsPerRoom || 40;
	const [isChangeInput, setIsChangeInput] = useState<boolean>(false);
	const [childrenValue, setChildrenValue] = useState<number>(0);
	const [infantsValue, setInfantsValue] = useState<number>(0);
	const [adultsValue, setAdultsValue] = useState<number>(0);
	const [totalPassengers, setTotalPassengers] = useState<number>(0);
	const [displayPlusButtonCheck, setDisplayPlusButtonCheck] = useState<string>('');
	const [maxAdults, setMaxAdults] = useState<number>(maxGuests);
	const [maxChildren, setMaxChildren] = useState<number>(maxGuests);
	const changeInputHandler = useCallback(
		(e: InputEventsHandler): void => {
			const tempNames: PassengersFieldInputNamesInterface = SearchFieldsUtil.getPassengersInputsNames(name);
			let newInputsValues: PassengersFieldDataInterface = {
				adults: e.name === tempNames.hiddenAdultsName ? Number(e.value || 0) : adultsValue,
				children: e.name === tempNames.hiddenChildrenName ? Number(e.value || 0) : childrenValue,
				infants: e.name === tempNames.hiddenInfantsName ? Number(e.value || 0) : infantsValue,
			};
			const totals = newInputsValues.adults + newInputsValues.children + newInputsValues.infants;
			if (onlyOneTypeOfPassenger) {
				onlyOneTypeOfPassengerValidation(tempNames, newInputsValues, e);
			}

			if (useMinimunOnePassenger && !onlyOneTypeOfPassenger) {
				restrictToUseMinimumOnePassenger(tempNames, newInputsValues, e);
			}

			if (changeFieldValidation) {
				newInputsValues = {
					...changeFieldValidation({ ...newInputsValues }, name),
				};
			}

			const totalPassengers: number = newInputsValues.adults + newInputsValues.children + newInputsValues.infants;
			setAdultsValue(newInputsValues.adults);

			setChildrenValue(newInputsValues.children);

			setInfantsValue(newInputsValues.infants);

			setTotalPassengers(totalPassengers);

			setMaxAdults(totals === maxGuests ? newInputsValues.adults : maxGuests - totals + newInputsValues.adults);

			setMaxChildren(totals === maxGuests ? newInputsValues.children : maxGuests - totals + newInputsValues.children);

			setIsChangeInput(true);
		},
		[name, adultsValue, childrenValue, infantsValue, changeFieldValidation, totalPassengers],
	);

	const countersValuesHandler = useCallback(
		(e: InputEventsHandler): number => {
			const newValue: number = Number(e.value || 0);
			const inputsValues: PassengersFieldDataInterface = {
				adults: adultsValue,
				children: childrenValue,
				infants: infantsValue,
			};

			if (changeInputsValidation) {
				return changeInputsValidation({ ...inputsValues }, Number(e.value || 0), e.name || '', name);
			}

			return newValue;
		},
		[name, adultsValue, childrenValue, infantsValue, changeInputsValidation],
	);

	const restrictToUseMinimumOnePassenger = (
		tempNames: PassengersFieldInputNamesInterface,
		newInputsValues: PassengersFieldDataInterface,
		event: InputEventsHandler,
	): void => {
		const isAdultAction: boolean = event.name === tempNames.hiddenAdultsName;
		const isChildrenAction: boolean = event.name === tempNames.hiddenChildrenName;
		if (isAdultAction && !newInputsValues.adults && !newInputsValues.children) {
			newInputsValues.children = 1;

			return;
		}

		if (isChildrenAction && !newInputsValues.adults && !newInputsValues.children) {
			newInputsValues.adults = 1;
		}
	};

	const onlyOneTypeOfPassengerValidation = (
		tempNames: PassengersFieldInputNamesInterface,
		newInputsValues: PassengersFieldDataInterface,
		event: InputEventsHandler,
	): void => {
		if (!newInputsValues.adults && !newInputsValues.children) {
			newInputsValues.adults = 1;

			return;
		}

		if (newInputsValues.adults && newInputsValues.children && event.name === tempNames.hiddenChildrenName) {
			newInputsValues.children = 1;

			newInputsValues.adults = 0;

			return;
		}

		if (newInputsValues.adults && newInputsValues.children && event.name === tempNames.hiddenAdultsName) {
			newInputsValues.children = 0;

			newInputsValues.adults = 1;
		}
	};

	useEffect(() => {
		setAdultsValue(adultsDefaultValue || 0);

		setChildrenValue(childrenDefaultValue || 0);

		setInfantsValue(infantsDefaultValue || 0);

		setTotalPassengers((adultsDefaultValue || 0) + (childrenDefaultValue || 0)); // Crear una función para calcular el total de pasajeros
	}, [adultsDefaultValue, childrenDefaultValue, infantsDefaultValue]);

	useEffect(() => {
		onChangeDisplayPlusButtonCheck();
	}, [adultsValue, childrenValue, infantsValue]);

	const onChangeDisplayPlusButtonCheck = (): void => {
		const sumTotalPassengers: number = adultsValue + childrenValue + infantsValue;
		const displayButton: boolean = (passengersAmountsRestrictions || 20) > (sumTotalPassengers || 0);
		const displayButtonCheck: boolean = maxGuests === (sumTotalPassengers || 0);
		if (!isSecondaryTemplate) {
			setDisplayPlusButtonCheck(displayButton ? '' : 'hidden');

			setDisplayPlusButtonCheck(displayButtonCheck ? 'hidden' : '');
		}
	};

	useEffect((): void => {
		if (isChangeInput) {
			const passengerResponse: PassengersFieldDataInterface = {
				adults: adultsValue,
				children: childrenValue,
				infants: infantsValue,
			};

			if (callBack) {
				callBack({ ...passengerResponse }, name);
			}

			setIsChangeInput(false);
		}
	}, [name, isChangeInput, adultsValue, childrenValue, infantsValue, callBack]);

	const tempAdultsLabel: string = customTraductionsLabels?.adults ? customTraductionsLabels.adults : 'adults';
	const tempChildrenLabel: string = customTraductionsLabels?.children ? customTraductionsLabels.children : 'children';
	let tempAdultsDescriptionLabel: string = '';
	let tempChildrenDescriptionLabel: string = '';

	if (typeProduct === 'disney') {
		tempAdultsDescriptionLabel = customTraductionsLabels?.adultsDisneyDescription
			? customTraductionsLabels.adultsDisneyDescription
			: 'adultsDisneyDescription';

		tempChildrenDescriptionLabel = customTraductionsLabels?.childrenDisneyDescription
			? customTraductionsLabels.childrenDisneyDescription
			: 'childrenDisneyDescription';
	} else {
		tempAdultsDescriptionLabel = customTraductionsLabels?.adultsDescription ? customTraductionsLabels.adultsDescription : 'adultsDescription';

		tempChildrenDescriptionLabel = customTraductionsLabels?.childrenDescription ? customTraductionsLabels.childrenDescription : 'childrenDescription';
	}

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				style={{ ...style }}
				className={`passengersFieldInputs w-full  flex flex-col bg-white md:py-[5px] ${className || ''} ${
					isSecondaryTemplate ? 'border rounded-md md:border-none md:rounded-md' : 'rounded-lg'
				}
					${isTertiaryTemplate ? '' : 'passengersFieldInputs__container'}
				`}
			>
				<div
					className={`passengersFieldInputs__adultsInput flex-row items-center ${
						isSecondaryTemplate
							? 'pt-[24px] pb-[16px] px-[16px] md:px-[24px]'
							: isTertiaryTemplate
								? 'md:py-[12px] py-[20px]'
								: 'py-[20px] md:px-[45px]'
					} ${disabledInputs?.adults ? 'hidden' : 'flex'} ${disabledInputs?.children ? '' : 'border-b border-solid'}`}
				>
					<div className={'passengersFieldInputs__content flex-grow flex flex-col'}>
						<p
							className={`passengersFieldInputs__title m-0 whitespace-nowrap ${
								isTertiaryTemplate ? 'text-[#000000] text-[14px]' : 'text-[#616161] text-base'
							}`}
						>
							{traductions(tempAdultsLabel) || tempAdultsLabel}
						</p>
						<p
							className={`passengersFieldInputs__description m-0 text-xs whitespace-nowrap ${
								isTertiaryTemplate ? 'text-[#6D6E71]' : 'text-[#616161] '
							} `}
						>
							{traductions(tempAdultsDescriptionLabel) || tempAdultsDescriptionLabel}
						</p>
					</div>
					{(adultsCounterData.max > adultsCounterData.min || isSecondaryTemplate) && (
						<Counter
							defaultValue={adultsValue}
							name={inputsNames.hiddenAdultsName}
							min={adultsCounterData.min}
							max={maxGuestsPerRoom ? maxAdults : adultsCounterData.max}
							minusIcon='minusIco'
							plusIcon='plusIco'
							valueClassName={`${isTertiaryTemplate ? '!text-[20px] w-[40px] px-[5px] text-center' : 'text-base px-[14px]'} `}
							iconClassName='text-[9px] text-center font-black'
							buttonClassName={`rounded-[5px] border border-solid w-[25px] h-[25px] flex items-center justify-center ${
								isSecondaryTemplate ? 'border-[#101828] border-2' : 'border'
							}`}
							className={'passengersFieldInputs__counter'}
							onChange={changeInputHandler}
							valueHandler={countersValuesHandler}
							passengersAmountsRestrictions={passengersAmountsRestrictions}
							totalPassengers={totalPassengers}
							displayPlusButtonCheck={displayPlusButtonCheck}
							isTertiaryTemplate={isTertiaryTemplate}
						/>
					)}
				</div>
				<div
					className={`passengersFieldInputs__childrenInput flex-row items-center ${
						isSecondaryTemplate
							? 'pt-[24px] pb-[16px] md:px-[24px] px-[16px]'
							: isTertiaryTemplate
								? 'md:py-[12px] py-[20px]'
								: 'py-[20px] md:px-[45px]'
					} ${disabledInputs?.children ? 'hidden' : 'flex'} ${disabledInputs?.infants ? '' : 'border-b border-solid'}`}
				>
					<div className={'passengersFieldInputs__content flex-grow flex flex-col'}>
						<p
							className={`passengersFieldInputs__title m-0 whitespace-nowrap ${
								isTertiaryTemplate ? 'text-[#000000] text-[14px]' : 'text-[#616161] text-base'
							}`}
						>
							{traductions(tempChildrenLabel) || tempChildrenLabel}
						</p>
						<p
							className={`passengersFieldInputs__description m-0 text-xs whitespace-nowrap ${
								isTertiaryTemplate ? 'text-[#6D6E71]' : 'text-[#616161] '
							} `}
						>
							{traductions(tempChildrenDescriptionLabel) || tempChildrenDescriptionLabel}
						</p>
					</div>
					{(childrenCounterData.max > childrenCounterData.min || isSecondaryTemplate) && (
						<Counter
							defaultValue={childrenValue}
							name={inputsNames.hiddenChildrenName}
							min={childrenCounterData.min}
							max={maxGuestsPerRoom ? maxChildren : childrenCounterData.max}
							minusIcon='minusIco'
							plusIcon='plusIco'
							valueClassName={`${isTertiaryTemplate ? '!text-[20px] w-[40px] px-[5px] text-center' : 'text-base px-[14px]'} `}
							iconClassName='text-[9px] text-center font-black'
							buttonClassName={`rounded-[5px] border border-solid w-[25px] h-[25px] flex items-center justify-center ${
								isSecondaryTemplate ? 'border-[#101828] border-2' : 'border'
							}`}
							className={'passengersFieldInputs__counter'}
							onChange={changeInputHandler}
							valueHandler={countersValuesHandler}
							totalPassengers={totalPassengers}
							isTertiaryTemplate={isTertiaryTemplate}
						/>
					)}
				</div>
				<div
					className={`passengersFieldInputs__infantsInput flex-row items-center border-b border-solid
						${isSecondaryTemplate ? 'pt-[24px] pb-[16px] md:px-[24px] px-[16px]' : isTertiaryTemplate ? 'md:py-[12px] py-[20px]' : 'py-[20px] md:px-[45px]'} 
						${disabledInputs?.infants ? 'hidden' : 'flex'}
					  `}
				>
					<div className={'passengersFieldInputs__content flex-grow flex flex-col'}>
						<p
							className={`passengersFieldInputs__title m-0 whitespace-nowrap ${
								isTertiaryTemplate ? 'text-[#000000] text-[14px]' : 'text-[#616161] text-base'
							}`}
						>
							{traductions('infants') || 'infants'}
						</p>
						<p
							className={`passengersFieldInputs__description m-0 text-xs whitespace-nowrap ${
								isTertiaryTemplate ? 'text-[#6D6E71]' : 'text-[#616161] '
							} `}
						>
							{traductions('infantsDescription') || 'infantsDescription'}
						</p>
					</div>
					{(infantsCounterData.max > infantsCounterData.min || isSecondaryTemplate) && (
						<Counter
							defaultValue={infantsValue}
							name={inputsNames.hiddenInfantsName}
							min={infantsCounterData.min}
							max={infantsCounterData.max}
							minusIcon='minusIco'
							plusIcon='plusIco'
							valueClassName={`${isTertiaryTemplate ? '!text-[20px] w-[40px] px-[5px] text-center' : 'text-base px-[14px]'} `}
							iconClassName='text-[9px] text-center font-black'
							buttonClassName={`rounded-[5px] border-solid w-[25px] h-[25px] flex items-center justify-center ${
								isSecondaryTemplate ? 'border-[#101828] border-2' : 'border'
							}`}
							className={'passengersFieldInputs__counter'}
							onChange={changeInputHandler}
							valueHandler={countersValuesHandler}
							totalPassengers={totalPassengers}
							isTertiaryTemplate={isTertiaryTemplate}
						/>
					)}
				</div>
				{children}
			</div>
		</>
	);
};

export default PassengersFieldInputs;
