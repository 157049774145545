import { PassangerType } from '../../shared';
import { DisneyParkFareDetail, DisneyParkFareGroup } from '../../shared/interfaces';

export interface PassengersQuantityProps {
	readonly disneyParkFareGroup: DisneyParkFareGroup;
	readonly disneyParkFareDetail: DisneyParkFareDetail[];
	readonly adultTranslation: string;
	readonly childTranslation: string;
	readonly traductions: (value: string) => string;
	readonly typeAgency: boolean;
}

export function PassengersQuantity({
	disneyParkFareGroup,
	adultTranslation,
	childTranslation,
	disneyParkFareDetail,
	traductions,
	typeAgency,
}: PassengersQuantityProps): JSX.Element {
	const firstPosition: number = 0;
	const renderPassengerPrice = (quantity: number, type: string, translation: string): JSX.Element => (
		<p className='specialEventRecommendation__passengerPrice text-sm flex md:flex-row items-center font-bold right-1 relative'>
			<span className='specialEventRecommendation__passengerQuantity text-xs'>{quantity}</span>
			<span data-testid={`passenger${type}`} className='specialEventRecommendation__passengerTypeLabel text-xs md:text-sm md:ml-2'>
				{'x'} {translation}
			</span>
		</p>
	);

	const renderFareDetail = (detail: DisneyParkFareDetail, index: number, typeAgency: boolean, passanger: string): JSX.Element | null => {
		if (detail.passengerType === passanger && !typeAgency) {
			return (
				<p key={index} className='specialEventRecommendation__passengerPrice text-sm flex flex-col md:flex-row font-bold'>
					<span
						data-testid='valuePassenger'
						className='specialEventRecommendation__passengerTypeLabel specialEventRecommendation__bold text-center md:text-start'
					>
						({detail.totalFormated} {'/ ' + traductions('ticketEventSpecial').toLowerCase()})
					</span>
				</p>
			);
		}

		return null;
	};

	return (
		<>
			<div className='flex itemx-center flex-row-reverse my-4 relative right-[15px] w-[115%]'>
				{disneyParkFareDetail.map((detail, index) => renderFareDetail(detail, index, typeAgency, PassangerType.adult))}
				{disneyParkFareGroup.passengersQuantity.adults > firstPosition &&
					renderPassengerPrice(disneyParkFareGroup.passengersQuantity.adults, PassangerType.adult, adultTranslation)}
			</div>
			<div className='flex itemx-center flex-row-reverse my-4 relative right-[15px] w-[115%]'>
				{disneyParkFareDetail.map((detail, index) => renderFareDetail(detail, index, typeAgency, PassangerType.child))}
				{disneyParkFareGroup.passengersQuantity.children > firstPosition &&
					renderPassengerPrice(disneyParkFareGroup.passengersQuantity.children, PassangerType.child, childTranslation)}
			</div>
		</>
	);
}

export default PassengersQuantity;
