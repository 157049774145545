export interface TabProps {
	idTab: string;
	label: string | JSX.Element;
	active?: boolean;
	disabled?: boolean;
	className?: string;
	desactiveColor?: string;
	activeClass?: string;
	activeColor?: string;
	clickOnEntireContainer?: boolean;
	onClick: (idTab: string) => void;
	isOutLine?: boolean;
	isPills?: boolean;
	isTertiaryTemplate?: boolean;
}

interface TabStyles {
	background?: string;
	borderColor?: string;
	color?: string;
	border?: string;
}

export const Tab = ({
	idTab,
	label,
	active,
	disabled,
	className,
	activeClass,
	clickOnEntireContainer,
	onClick,
	desactiveColor,
	activeColor,
	isOutLine,
	isPills,
	isTertiaryTemplate,
}: TabProps): JSX.Element => {
	let stylesTab: TabStyles = {
		background: !disabled && active ? activeColor : desactiveColor,
		borderColor: 'transparent',
	};

	if (isTertiaryTemplate) {
		stylesTab = {
			color: !disabled && active ? '#0A609C' : '',
			background: !disabled && active ? '#F8FBFD' : 'transparent',
		};
	}

	if (isOutLine) {
		stylesTab = {
			color: !disabled && active ? activeColor : desactiveColor,
			borderColor: !disabled && active ? activeColor : 'transparent',
		};
	}

	if (isPills) {
		stylesTab = {
			color: !disabled && active ? activeColor : desactiveColor,
			border: 'none',
		};
	}

	const activeClassTab = isTertiaryTemplate ? 'tertiaryActiveTab' : 'activeTab';
	const noActiveClassTab = isTertiaryTemplate ? 'noTertiaryActiveTab' : 'noActiveTab';	return (
		<div
			className={`tab font-family-regular text-base px-1.5
				${!disabled && active ? `${activeClassTab} ${activeClass || ''}` : noActiveClassTab}
        ${clickOnEntireContainer ? (disabled ? 'cursor-default' : 'cursor-pointer') : ''}
        ${className || ''}`}
			style={stylesTab}
			onClick={clickOnEntireContainer ? (disabled ? undefined : () => onClick(idTab)) : undefined}
		>
			{typeof label === 'string' ? (
				<span
					className={`tab__content block text-center ${!clickOnEntireContainer ? (disabled ? 'cursor-default' : 'cursor-pointer') : ''}`}
					onClick={!clickOnEntireContainer ? (disabled ? undefined : () => onClick(idTab)) : undefined}
				>
					{label}
				</span>
			) : (
				<div
					className={`tab__content ${!clickOnEntireContainer ? (disabled ? 'cursor-default' : 'cursor-pointer') : ''}`}
					onClick={!clickOnEntireContainer ? (disabled ? undefined : () => onClick(idTab)) : undefined}
				>
					{label}
				</div>
			)}
		</div>
	);
};

Tab.defaultProps = {
	className: '',
	active: false,
	disabled: false,
};

export default Tab;
