import { numberToLocaleWithcurrency } from '../../../../shared/services/utils.service';
import { DisneyParkFareGroup } from '../../shared/interfaces';
import { ModelTypeEnum } from '../../../../shared/enums';
import { Button, Spinner } from '../../../ui-elements';
import styles from './disney-fare-card.module.scss';
import { useEffect, useState } from 'react';

export interface DisneyFareCardProps {
	colorPrimary?: string;
	colorSecondary?: string;
	fareGroup?: DisneyParkFareGroup;
	useAlphaBackground?: boolean;
	t: (value: string) => string;
	businessModel?: ModelTypeEnum;
	markedRecommendation?: (fareGroup: DisneyParkFareGroup) => void;
	onSelected?: (fareGroup: DisneyParkFareGroup) => void;
	disabled: boolean;
	isAresLookAndFeel?: boolean;
}

export function DisneyFareCard({
	t,
	fareGroup,
	colorPrimary,
	colorSecondary,
	businessModel,
	useAlphaBackground,
	markedRecommendation,
	onSelected,
	disabled,
	isAresLookAndFeel,
}: DisneyFareCardProps): JSX.Element {
	const [clickLoading, setClickLoading] = useState<boolean>(false);
	const useAsColorPrimary: string | undefined = isAresLookAndFeel ? '#2b2b2b' : colorPrimary;
	const useAsFontWeight: string | undefined = isAresLookAndFeel ? 'font-medium' : 'font-bold';
	const useAsCardTextColor: string | undefined = isAresLookAndFeel ? useAsColorPrimary : '#292929';
	const cardTextColor: string | undefined = fareGroup?.isRecommended ? '#ffffff' : useAsCardTextColor;
	const cardTitleColor: string | undefined = fareGroup?.isRecommended ? '#ffffff' : useAsColorPrimary;
	let cardBackground: string | undefined;
	let iconColor: string | undefined;
	if (fareGroup?.isRecommended) {
		cardBackground = isAresLookAndFeel ? colorSecondary : useAsColorPrimary;
	} else {
		cardBackground = '#ffffff';

		iconColor = isAresLookAndFeel ? colorSecondary : useAsColorPrimary;
	}

	let cardBtnBackGroundColor: string;

	if (!isAresLookAndFeel || fareGroup?.isRecommended) {
		cardBtnBackGroundColor = '#ffffff';
	} else {
		cardBtnBackGroundColor = colorSecondary ?? '';
	}

	let cardBtnTextColor: string;

	if (!isAresLookAndFeel || fareGroup?.isRecommended) {
		cardBtnTextColor = colorSecondary ?? '#1075BB';
	} else {
		cardBtnTextColor = '#ffffff';
	}

	const cardBorderColor: string | undefined = isAresLookAndFeel ? colorSecondary : colorPrimary;
	useEffect(() => {
		if (!disabled) {
			setClickLoading(false);
		}
	}, [disabled]);

	const fareCardPointAndMoney: JSX.Element = (
		<>
			<div className='disney-fare__pricePoints'>
				<p className='text-white text-xs disney-fare__priceTotalTitle' style={{ color: cardTitleColor }}>
					{t('totalValueForAllPassengers')}:
				</p>

				<p
					className={`${
						fareGroup?.promoCode?.isAppliedInPoints ? 'disney-fare__oldPriceTotal line-through text-sm' : 'text-[18px]'
					} ${useAsFontWeight} text-white disney-fare__priceTotal`}
					style={{ color: cardTitleColor }}
				>
					{numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.requiredPoints as number)} {fareGroup?.fareGroupSummary?.pointsName}
				</p>

				{!!fareGroup?.promoCode && fareGroup?.promoCode.isAppliedInPoints && (
					<p className='text-white text-[18px] disney-fare__priceTotal' style={{ color: cardTitleColor }}>
						{`${numberToLocaleWithcurrency(fareGroup.promoCode.valueWithDiscountPoints)} `}
						{fareGroup.promoCode.currencyPoints}
					</p>
				)}
			</div>

			<div className='disney-fare__priceCash'>
				<p
					className={`${
						fareGroup?.promoCode && !fareGroup?.promoCode.isAppliedInPoints ? 'disney-fare__oldPriceTotal line-through text-sm' : 'text-[18px]'
					} text-white disney-fare__priceTotal`}
					style={{ color: cardTitleColor }}
				>
					{' '}
					{'+ '} {'$ '}
					{` ${numberToLocaleWithcurrency(
						fareGroup?.fareGroupSummary?.requiredAmount ?? fareGroup?.fareGroupSummary?.amount ?? 0,
						fareGroup?.fareGroupSummary?.currency,
					)} `}
					{fareGroup?.fareGroupSummary?.currency}
				</p>

				{!!fareGroup?.promoCode && !fareGroup?.promoCode.isAppliedInPoints && (
					<p className='text-white text-[18px] disney-fare__priceTotal' style={{ color: cardTitleColor }}>
						{'$'}
						{`${numberToLocaleWithcurrency(fareGroup.promoCode.valueWithDiscount, fareGroup.promoCode.currency)} `}
						{fareGroup.promoCode.currency}
					</p>
				)}
			</div>
		</>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				onClick={() => {
					if (onSelected && fareGroup) {
						onSelected(fareGroup);
					}
				}}
				className={`disney-fare relative flex flex-col rounded-md border-2 pb-[15px] pt-[25px] w-72 ${useAlphaBackground ? 'scale-95' : ''}`}
				style={{
					background: cardBackground,
					borderColor: cardBorderColor,
				}}
			>
				<div className='flex-grow flex flex-col px-[30px]'>
					<div className='flex items-start justify-between w-full mb-2'>
						<div>
							<p className='disney-fare__title text-white mb-0 pt-[10px] leading-none'>
								<span style={{ color: cardTitleColor }}>{t('Entrada')}:</span>
							</p>
							<p
								className={`disney-fare__fareName text-white text-base ${isAresLookAndFeel ? useAsFontWeight : 'font-extrabold'} mb-0 text-[18px]`}
								style={{ color: cardTitleColor }}
							>
								{fareGroup?.name}
							</p>
						</div>
					</div>
					<div className='disney-fare__items flex-grow border-b-2 border-white pb-2' style={{ borderColor: cardTitleColor }}>
						{fareGroup?.inclusions.map((inclusion, index) => (
							<div key={index}>
								{inclusion.isIncluded ? (
									<p className='text-white flex items-center mb-2'>
										<span
											style={{ color: isAresLookAndFeel ? iconColor : colorSecondary }}
											className={`${inclusion.isIncluded && 'checkRoundIco'} text-base mr-2`}
										></span>
										<span className={'disney-fare__items-text text-xs'} style={{ color: cardTextColor }}>
											{inclusion.description}
										</span>
									</p>
								) : (
									''
								)}
							</div>
						))}
					</div>

					<div className='disney-fare__price my-2'>
						{!!fareGroup?.promoCode && (
							<div className='disney-fare__promoCode  mb-4'>
								<p
									className={`disney-fare__promoCodeTitle ${
										isAresLookAndFeel && fareGroup?.isRecommended ? 'disney-ares-fare__promoCode' : ''
									} uppercase mb-2 mt-2`}
									style={{ color: cardTitleColor }}
								>
									<span className='discountIco'></span>
									{t('promocode')}
								</p>

								<div className='disney-fare__promoCodeContent rounded-lg bg-gray-200 text-gray-600 p-2 '>{fareGroup.promoCode.code}</div>

								{fareGroup.promoCode?.isAppliedInPoints ? (
									<div className='disney-fare__promoCodePoints mt-2 text-[14px]' style={{ color: cardTitleColor }}>
										(
										{fareGroup.promoCode.pointsDiscountedValue
											? `${fareGroup.promoCode.pointsDiscountedValue} ${fareGroup.promoCode.currencyPoints} ${t('ofDiscount')}`
											: `${fareGroup.promoCode.fixedAmount} ${fareGroup.promoCode.currency} ${t('ofDiscount')}`}
										)
									</div>
								) : (
									<div className='disney-fare__promoCodeCash mt-2 text-[14px]' style={{ color: cardTitleColor }}>
										(
										{fareGroup.promoCode.percentageAmount
											? `${fareGroup.promoCode.percentageAmount} % ${t('ofDiscount')}`
											: `${fareGroup.promoCode.currency} ${fareGroup.promoCode.fixedAmount} ${t('ofDiscount')}`}
										)
									</div>
								)}
							</div>
						)}

						{fareGroup?.paymentMethod === ModelTypeEnum.CASH && (
							<div className='disney-fare__priceCash'>
								<p className='text-white disney-fare__priceTotalTitle' style={{ color: cardTitleColor }}>
									{t('totalValueForAllPassengers')}:
								</p>

								<p
									className={`${
										fareGroup?.promoCode ? 'disney-fare__oldPriceTotal line-through text-sm' : 'text-[18px]'
									} text-white disney-fare__priceTotal`}
									style={{ color: cardTitleColor }}
								>
									{'$'}
									{` ${numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.netTotalFare, fareGroup?.fareGroupSummary?.currency)} `}
									{fareGroup?.fareGroupSummary?.currency}
								</p>

								{!!fareGroup?.promoCode && (
									<p className='text-white text-[18px] disney-fare__priceTotal' style={{ color: cardTitleColor }}>
										{'$'}
										{` ${numberToLocaleWithcurrency(fareGroup.promoCode.valueWithDiscount, fareGroup.promoCode.currency)} `}
										{fareGroup.promoCode.currency}
									</p>
								)}
							</div>
						)}

						{fareGroup?.paymentMethod === ModelTypeEnum.POINTS && (
							<div className='disney-fare__pricePoints'>
								<p className='text-white text-xs disney-fare__priceTotalTitle' style={{ color: cardTitleColor }}>
									{t('totalValueForAllPassengers')}:
								</p>

								<p
									className={`${
										fareGroup?.promoCode ? 'disney-fare__oldPriceTotal line-through text-sm' : 'text-[18px]'
									} text-white disney-fare__priceTotal`}
									style={{ color: cardTitleColor }}
								>
									{fareGroup?.fareGroupSummary?.pointsName}: <br />
									<strong>{numberToLocaleWithcurrency(fareGroup?.fareGroupSummary?.requiredPoints as number)}</strong>
								</p>

								{!!fareGroup?.promoCode && (
									<p className='text-white text-[18px] disney-fare__priceTotal' style={{ color: cardTitleColor }}>
										{`${numberToLocaleWithcurrency(fareGroup.promoCode.valueWithDiscountPoints)} `}
										{fareGroup.promoCode.currencyPoints}
									</p>
								)}
							</div>
						)}

						{fareGroup?.paymentMethod === ModelTypeEnum.POINT_PLUS_MONEY && fareCardPointAndMoney}

						{fareGroup?.paymentMethod === ModelTypeEnum.POINT_OR_MONEY && fareCardPointAndMoney}
					</div>
				</div>

				<div className='flex justify-center'>
					<Button
						onClick={() => {
							if (markedRecommendation && fareGroup) {
								fareGroup.isSelected = true;

								setClickLoading(true);

								markedRecommendation(fareGroup);
							}
						}}
						customClass={`${isAresLookAndFeel ? 'disney-fare__no-hover-btn text-medium' : 'disney-fare__button'} ${
							fareGroup?.isRecommended && isAresLookAndFeel ? 'disney-ares__recommended-fare' : ''
						} rounded-lg w-5/6 items-center`}
						styles={{
							background: cardBtnBackGroundColor,
							color: cardBtnTextColor,
							border: `solid 1px ${(isAresLookAndFeel && colorSecondary ? colorSecondary : colorPrimary) ?? ''}`,
						}}
						content={
							clickLoading && disabled ? (
								<Spinner className={'disney-fare__recommendedSpinner'} />
							) : (
								<span className={`${isAresLookAndFeel ? 'font-medium' : 'font-semibold'} text-[14px]`}>{t('select')}</span>
							)
						}
						disabled={disabled}
						noHover={isAresLookAndFeel}
					/>
				</div>
			</div>
		</>
	);
}

export default DisneyFareCard;
