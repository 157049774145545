import styles from './loading-button.module.scss';
export interface LoadingButtonProps {
	isButtonDisabled: boolean;
	type: 'button' | 'submit' | 'reset' | undefined;
	loading: boolean | null;
	t: (label: string) => string;
}

function LoadingButton({ isButtonDisabled, type, loading, t }: LoadingButtonProps): JSX.Element {
	const buttonContent: JSX.Element = loading ? <span className='text-white animate-pulse'>{t('consulting')}</span> : <span>{t('consult')}</span>;
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<button
				className={`loading-button__container bg-[#1075BB] text-[14px] w-[138px] h-[45px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] font-medium
		${isButtonDisabled ? 'text-[#93C1E0]' : 'text-white hover:shadow-xl'}`}
				disabled={isButtonDisabled}
				type={type}
			>
				{buttonContent}
			</button>
		</>
	);
}

export default LoadingButton;
