import { InputEventsHandler } from '../../../shared/interfaces/inputs.interface';
import { UseFormRegisterReturn, UseFormReturn } from 'react-hook-form';
import { FormsInputsType } from '../../../shared/enums/inputs.enum';
import { addAlphaToColor } from '../../../shared/services';
import { useState, useCallback, useEffect } from 'react';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import styles from './checkbox.module.scss';

export interface CheckboxProps {
	label?: string;
	name?: string;
	disabled?: boolean;
	onChange?: (e: InputEventsHandler) => void;
	onFocus?: () => void;
	onInput?: () => void;
	onClick?: () => void;
	getValues?: UseFormReturn['getValues'];
	className?: string;
	labelClassName?: string;
	inputClassName?: string;
	register?: UseFormRegisterReturn;
	required?: boolean;
	hidden?: boolean;
	readonly?: boolean;
	defaultChecked?: boolean;
	checkmarkContainerSize?: number;
	primaryColor?: string;
	secondaryColor?: string;
	customBorderColorWithoutSelected?: string;
	forcePrimaryColorBorder?: boolean;
	isSwitch?: boolean;
	style?: any;
	id?: string;
	isSecondaryTemplate?: boolean;
	logoUrl?: string;
}

export const Checkbox = ({
	label,
	name,
	disabled,
	onChange,
	onFocus,
	onInput,
	onClick,
	getValues,
	className,
	labelClassName,
	inputClassName,
	register,
	required,
	hidden,
	readonly,
	defaultChecked,
	checkmarkContainerSize,
	primaryColor,
	secondaryColor,
	forcePrimaryColorBorder,
	style,
	isSwitch,
	id,
	isSecondaryTemplate,
	customBorderColorWithoutSelected,
	logoUrl,
}: CheckboxProps): JSX.Element => {
	const [checked, setChecked] = useState<boolean>(false);
	const [borderPrimaryColor, setBorderPrimaryColor] = useState<string>(primaryColor || '');
	const [backgroundColorCheck, setBackgroundColorCheck] = useState<string>(primaryColor || '');
	const inputClassSwitch: string = isSwitch ? 'switchLib__input' : '';
	const containerSize: string = checkmarkContainerSize ? `${checkmarkContainerSize}px` : '16px';
	const iconSize: number = checkmarkContainerSize ? checkmarkContainerSize * 0.5 : 8;
	const changeHandler = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const tempChecked: boolean = event.target.checked;
			if (register?.onChange) {
				void register.onChange(event);
			}

			if (onChange) {
				const dataEvent: InputEventsHandler = {
					type: FormsInputsType.checkbox,
					name,
					value: tempChecked,
					event,
				};

				onChange(dataEvent);
			}

			setChecked(tempChecked);
		},
		[name, register, onChange],
	);

	const focusHandler = (): void => {
		if (onFocus) {
			onFocus();
		}
	};

	const inputHandler = (): void => {
		if (onInput) {
			onInput();
		}
	};

	const clickHandler = (): void => {
		if (onClick) {
			onClick();
		}
	};

	useEffect(() => {
		if (typeof defaultChecked === 'boolean') {
			setChecked(defaultChecked);
		}
	}, [defaultChecked]);

	useEffect(() => {
		if (getValues && name) {
			setChecked(getValues(name));
		}
	}, [getValues && name ? getValues(name) : undefined]);

	useEffect(() => {
		if (isSecondaryTemplate) {
			if (checked) {
				setBorderPrimaryColor(secondaryColor || '');

				setBackgroundColorCheck(addAlphaToColor(secondaryColor || '', 0.2));
			} else {
				setBackgroundColorCheck(primaryColor || '');
			}
		} else {
			setBackgroundColorCheck(primaryColor || '');
		}
	}, [isSecondaryTemplate, checked]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`checkboxLib flex flex-row items-center ${className || ''} ${hidden ? 'hidden' : ''}`}>
				<label className={`checkboxLib__container flex flex-row items-center ${disabled ? '' : 'cursor-pointer'}`} htmlFor={name}>
					<input
						style={{ ...style }}
						defaultChecked={defaultChecked}
						className={`checkboxLib__input ${inputClassSwitch} h-0 w-0 opacity-0 checkbox__input ${inputClassName || ''}`}
						type={FormsInputsType.checkbox}
						id={id}
						name={name}
						disabled={disabled}
						required={required}
						readOnly={readonly}
						onFocus={focusHandler}
						onInput={inputHandler}
						onClick={clickHandler}
						{...register}
						onChange={changeHandler}
					/>
					{isSwitch ? (
						<div
							className='relative inline-block w-9 h-5 rounded-full transition-colors duration-200 ease-in-out'
							style={{
								backgroundColor: checked ? primaryColor : '#f0f0f0',
							}}
						>
							<div
								className={`absolute top-[2px] left-0 w-4 h-4 bg-white border-2 border-solid rounded-full transition-transform duration-200 ease-in-out 
								${checked ? 'transform translate-x-5' : ''}`}
							></div>
						</div>
					) : (
						<div
							className={`checkboxLib__checkmarkContainer flex flex-row justify-center items-center  border-solid  checkbox__checkmarkContainer ${
								isSecondaryTemplate ? 'border rounded-[4px]' : 'border-2 rounded-[2px]'
							}`}
							style={{
								borderColor: checked
									? borderPrimaryColor
									: forcePrimaryColorBorder
										? customBorderColorWithoutSelected || primaryColor
										: secondaryColor,
								height: containerSize,
								width: containerSize,
								backgroundColor: checked ? backgroundColorCheck : isSecondaryTemplate ? primaryColor : undefined,
							}}
						>
							{checked && <IcomoonIcon icon='checkIco' fontSize={iconSize} color={secondaryColor} iconClassName='checkboxLib__checkmark' />}
						</div>
					)}
					{logoUrl && (
						<img className='checkboxLib__logo ml-1' src={logoUrl} height={checkmarkContainerSize} width={checkmarkContainerSize} alt='logo url' />
					)}
					{label && (
						<span className={`checkboxLib__label font-family-regular ${isSecondaryTemplate ? 'pl-[8px]' : 'pl-[10px]'} ${labelClassName || ''}`}>
							{label} {required ? '*' : ''}
						</span>
					)}
				</label>
			</div>
		</>
	);
};

Checkbox.defaultProps = {
	labelClassName: 'block text-sm font-family-black px-1',
	inputClassName: 'bg-gray-100 font-family-regular',
	secondaryColor: 'black',
	primaryColor: 'white',
};

export default Checkbox;
