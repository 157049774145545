export enum CarSearchPaymentType {
	online = 'online',
	onrental = 'onrental',
}

export enum CarSearchCategoryType {
	standard = 'standard',
	vans = 'vans',
	suv = 'suv',
	luxury = 'luxury',
	adrenaline = 'adrenaline',
	prestige = 'prestige',
	dream = 'dream',
	green = 'green',
}
