import { HistoricalCard } from '../../shared-components/historical-card/historical-card';
import GenericFilters from '../../shared-components/generic-filters/generic-filters';
import { GenericFilter, Historical, Params } from '../../../shared/interfaces';
import HistoricalCardSkeleton from './skeleton/historical-card-skeleton';
import styles from './historicals.module.scss';
import { Spinner } from '../../ui-elements';
import { useState } from 'react';

export interface HistoricalsProps {
	colorPrimary?: string;
	colorSecondary?: string;
	maximumNumberToDisplaySkeleton?: number;
	loading: boolean;
	error: boolean;
	historicals: Historical[];
	filters: GenericFilter[];
	t: (value: string) => string;
	onSelected?: (item: Historical) => void;
	onChangeFilters: (value: Params) => void;
}

export function Historicals({
	t,
	colorPrimary,
	colorSecondary,
	historicals,
	loading,
	filters,
	maximumNumberToDisplaySkeleton,
	onSelected,
	onChangeFilters,
}: HistoricalsProps): JSX.Element {
	const [isMobile, setIsmobile] = useState<boolean>(false);
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<section className='historicals flex flex-col px-[50px]'>
				<h1 className='historicals__title mt-8 flex justify-between text-[24px]'>
					{t('filterBy')} <span className='filterIco md:hidden' onClick={() => setIsmobile(true)}></span>
				</h1>
				<div className='historicals__content flex w-full'>
					<div className={`historicals__filters w-[360px] mr-4 ${isMobile ? 'historicals__filters--open' : ''}`}>
						<GenericFilters
							t={t}
							isMobileOpen={isMobile}
							onMobileOpen={() => setIsmobile(false)}
							colorPrimary={colorPrimary}
							colorSecondary={colorSecondary}
							filterData={filters}
							change={onChangeFilters}
						/>
					</div>

					<div className={'historicals__list w-full'}>
						{!historicals?.length
							? Array.from(Array(maximumNumberToDisplaySkeleton || 10).keys()).map((_, index: number) => <HistoricalCardSkeleton key={index} />)
							: historicals.map((item: Historical, index: number) => (
								<HistoricalCard
									key={index}
									colorSecondary={colorSecondary}
									colorPrimary={colorPrimary}
									onSelected={onSelected}
									t={t}
									historical={item}
								/>
							  ))}
						{loading && (
							<div className='flex justify-center'>
								<Spinner />
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
}

export default Historicals;
