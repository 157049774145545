import { ButtonSearchHistory } from '../../../../shared/interfaces/generics/button-search.interface';
import { InputEventsHandler, DatePickerData } from '../../../../shared/interfaces/inputs.interface';
import { SearchFieldsNames, DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { DatePickerDefaultData } from '../../../../shared/interfaces/date-picker.interface';
import { PlansSearchFormInputsNames } from '../enums/plans-search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import {
	FieldButtonEventHandlerInterface,
	DisplayValuesDatesFieldInterface,
	DatesFieldInputNamesInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import useWindowHeight from '../../../../shared/hooks/useHeight';
import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
	UsePlansSearchFormInterface,
	UsePlansSearchFormReturnInterface,
	PlansSearchFormSubmitInterface,
} from '../interfaces/plans-search-form.interface';

const usePlansSearchForm = ({
	history,
	calendarRestriction,
	traductions,
	emitSubmitSearch,
	emitShowMobileFields,
}: UsePlansSearchFormInterface): UsePlansSearchFormReturnInterface => {
	const destinationHiddenName: string = PlansSearchFormInputsNames.codeDestination;
	const categoryName: string = PlansSearchFormInputsNames.category;
	const destinationName: string = PlansSearchFormInputsNames.destination;
	const datesHiddenName: string = PlansSearchFormInputsNames.hiddenDates;
	const destinationId: SearchFieldsNames = SearchFieldsNames.destination;
	const passengersId: SearchFieldsNames = SearchFieldsNames.passengers;
	const datesName: string = PlansSearchFormInputsNames.dates;
	const datesId: SearchFieldsNames = SearchFieldsNames.dates;
	const {
		register,
		handleSubmit,
		watch,
		getValues,
		setValue,
		trigger,
		formState: { errors },
	} = useForm();

	const [buttonSearchHistory, setButtonSearchHistory] = useState<ButtonSearchHistory | undefined>(undefined);
	const [mobileStep, setMobileStep] = useState<SearchFieldsNames | string>(destinationId);
	const [defaultDates, setDefaultDates] = useState<DatePickerDefaultData>({
		startDate: new Date(),
	});

	const [showContainer, setShowContainer] = useState<boolean>(false);
	const [minDate, setMinDate] = useState<Date>(new Date());
	const mobileFormHeight: number = useWindowHeight();
	const destinationFieldHandler = useCallback((element: { target: HTMLInputElement; form: HTMLFormElement }): void => {
		// TODO
	}, []);

	const showMobileFieldsContainerHandler = useCallback((show: boolean): void => {
		setShowContainer(show);

		setMobileStep(destinationId);
	}, []);

	const datesHandler = useCallback((event: InputEventsHandler): void => {
		const getData: DatePickerData = event.value as DatePickerData;
		setDefaultDates({
			startDate: getData.startDate,
		});
	}, []);

	const onSubmit = useCallback(
		handleSubmit((data: Record<string, any>) => {
			const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
			const results: PlansSearchFormSubmitInterface = {
				dates: data[datesName] || '',
				departureDate: data[tempDatesNames.startDate] || '',
				destination: data[destinationName] || '',
				category: data[categoryName] || '',
				categoryCode: '',
				urlPath: undefined,
			};

			const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
				DatesFieldTypes.oneDate,
				{
					date: true,
					time: false,
				},
				results.departureDate,
				'',
				'',
				'',
			);

			setButtonSearchHistory({
				departure: results.destination,
				arrival: '',
				departureDate: datesValues.largeStartDate || '',
				arrivalDate: '',
			});

			if (emitSubmitSearch) {
				emitSubmitSearch({ ...results });
			}
		}),
		[emitSubmitSearch],
	);

	const nextMobileFieldHandler = useCallback(
		(event: FieldButtonEventHandlerInterface): void => {
			if (event.id === destinationId) {
				setMobileStep(datesId);

				return;
			}

			if (event.id === datesId) {
				void (async () => {
					const delayTime: number = 500;
					setTimeout(() => {
						void onSubmit();

						setShowContainer(false);

						setMobileStep(destinationId);
					}, delayTime);
				})();
			}
		},
		[onSubmit],
	);

	const backMobileFieldHandler = useCallback((event: FieldButtonEventHandlerInterface): void => {
		if (event.id === datesId) {
			setMobileStep(destinationId);
		}
	}, []);

	useEffect(() => {
		const currentDate: Date = new Date();
		if (calendarRestriction) {
			currentDate.setDate(currentDate.getDate() + calendarRestriction);
		}

		setMinDate(currentDate);
	}, [calendarRestriction]);

	useEffect(() => {
		if (emitShowMobileFields) {
			emitShowMobileFields(showContainer);
		}
	}, [showContainer]);

	useEffect(() => {
		const root = document.documentElement;
		if (mobileFormHeight) {
			root?.style.setProperty('--mobile-plans-search-form-height', `${mobileFormHeight}px`);
		}
	}, [mobileFormHeight]);

	useEffect(() => {
		const tempDatesNames: DatesFieldInputNamesInterface = SearchFieldsUtil.getDatesInputsNames(datesHiddenName);
		setMobileStep(destinationId);

		setValue(categoryName, history?.category || '');

		setValue(destinationName, history?.destination || '');

		setValue(destinationHiddenName, history?.destination || '');

		let tempDepartureDate: string = history?.departureDate ? history.departureDate : '';
		let tempStartDate: Date = new Date(`${tempDepartureDate}T00:00:00`);
		const validDate: boolean = !!history?.destination && !!tempDepartureDate && tempStartDate >= minDate;
		if (!validDate) {
			tempDepartureDate = '';

			tempStartDate = new Date('T00:00:00');
		}

		setValue(tempDatesNames.startDate, tempDepartureDate);

		const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
			DatesFieldTypes.oneDate,
			{
				date: true,
				time: false,
			},
			tempDepartureDate,
			'',
			'',
			'',
		);

		setValue(datesName, validDate ? datesValues.dates : '');

		setButtonSearchHistory(
			history?.destination
				? {
					departure: history?.destination || '',
					arrival: '',
					departureDate: datesValues.largeStartDate || '',
					arrivalDate: '',
				  }
				: undefined,
		);

		setDefaultDates(
			validDate
				? {
					startDate: tempStartDate,
				  }
				: {
					startDate: minDate,
				  },
		);
	}, [history, minDate, traductions]);

	return {
		destinationName,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		destinationId,
		datesId,
		passengersId,
		mobileFieldStep: mobileStep,
		buttonSearchHistory,
		minDate,
		defaultDates,
		showMobileFieldsContainer: showContainer,
		mobileFormHeight,
		errors,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		destinationFieldHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		onSubmit,
	};
};

export default usePlansSearchForm;
