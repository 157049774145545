import IcomoonIcon from '../../../../../ui-elements/icomoon-icon/icomoon-icon';
import Button from '../../../../../ui-elements/button/button';

export interface CoverageCarCardSkeletonProps {
	multipleCompanies?: boolean;
	showRate?: boolean;
	showButtons?: boolean;
	className?: string;
	carContainerClassName?: string;
	carImageClassName?: string;
	companyImageClassName?: string;
}

export const CoverageCarCardSkeleton = ({
	multipleCompanies,
	showRate,
	showButtons,
	className,
	carContainerClassName,
	carImageClassName,
	companyImageClassName,
}: CoverageCarCardSkeletonProps): JSX.Element => {
	return (
		<div className={`coverageCarCardSkeleton animate-pulse ${className || ''}`}>
			<div className={`w-full relative flex flex-col px-10 py-5 ${carContainerClassName || ''}`}>
				<div className={'flex flex-row justify-end items-center mb-2.5'}>
					{!multipleCompanies && <div className={`bg-gray-100 ${companyImageClassName || ''}`} />}
				</div>
				{!multipleCompanies && (
					<IcomoonIcon icon='shareIco' fontSize={15} className={`text-gray-200 inline-block md:hidden self-end ${showRate ? 'mb-2.5' : ''}`} />
				)}
				<div className='flex flex-col md:flex-row md:items-start'>
					<div className={`bg-gray-100 mr-3.5 ${carImageClassName || ''}`} />
					<div className='relative flex-grow'>
						<div className='flex flex-row justify-between items-end'>
							<p className={`coverageCarCardSkeleton__typeText bg-gray-200 ${multipleCompanies ? 'h-3' : 'h-4'} md:h-5 w-[40%] mt-[5px] md:mt-0`} />
							{showRate && multipleCompanies && <p className='coverageCarCardSkeleton__rateText bg-gray-200 h-3 w-[8%] hidden md:block' />}
						</div>
						<div className='flex flex-col md:flex-row mt-[5px]'>
							<div className='flex-grow flex flex-col'>
								<p className={`coverageCarCardSkeleton__modelText bg-gray-200 h-4 md:h-5 ${showRate ? 'w-[90%]' : 'w-[70%]'}`} />
								<div className='flex-grow flex flex-row flex-wrap justify-start items-start mt-1.5'>
									<p className={`flex flex-row items-center ${showRate ? 'mr-4' : 'w-2/4'}`}>
										<span className='coverageCarCardSkeleton__featureText passenger2Ico text-base font-medium mr-1 text-gray-200' />
										<span className={`coverageCarCardSkeleton__featureText bg-gray-200 h-3 ${showRate ? 'w-[8px]' : 'w-[60px]'}`} />
									</p>
									<p className={`flex flex-row items-center ${showRate ? 'mr-4' : 'w-2/4'}`}>
										<span className='coverageCarCardSkeleton__featureText door2Ico text-base font-medium mr-1 text-gray-200' />
										<span className={`coverageCarCardSkeleton__featureText bg-gray-200 h-3 ${showRate ? 'w-[8px]' : 'w-[60px]'}`} />
									</p>
									<p className={`flex flex-row items-center ${showRate ? 'mr-4' : 'w-2/4'}`}>
										<span className='coverageCarCardSkeleton__featureText baggage2Ico text-base font-medium mr-1 text-gray-200' />
										<span className={`coverageCarCardSkeleton__featureText bg-gray-200 h-3 ${showRate ? 'w-[8px]' : 'w-[60px]'}`} />
									</p>
									<p className={`flex flex-row items-center ${showRate ? 'mr-4' : 'w-2/4'}`}>
										<span className='coverageCarCardSkeleton__featureText airCondition2Ico text-base font-medium mr-1 text-gray-200' />
										<span className={`coverageCarCardSkeleton__featureText bg-gray-200 h-3 ${showRate ? 'w-[8px]' : 'w-[60px]'}`} />
									</p>
									<p className={`flex flex-row items-center ${showRate ? '' : 'w-2/4'}`}>
										<span className='coverageCarCardSkeleton__featureText transmission2Ico text-base font-medium mr-1 text-gray-200' />
										<span className={`coverageCarCardSkeleton__featureText bg-gray-200 h-3 ${showRate ? 'w-[8px]' : 'w-[60px]'}`} />
									</p>
								</div>
							</div>
							<div className={`flex flex-col md:items-end mt-4 md:mt-0 ${showRate ? 'w-full md:w-[30%]' : ''}`}>
								{!multipleCompanies && (
									<IcomoonIcon icon='shareIco' fontSize={15} className={`text-gray-200 hidden md:inline-block ${showRate ? 'mb-2.5' : ''}`} />
								)}
								{showRate && (
									<>
										{!multipleCompanies && <p className='coverageCarCardSkeleton__rateText bg-gray-200 self-center md:self-end h-3 w-[30%]' />}
										<p className='coverageCarCardSkeleton__fromTotalRateText bg-gray-200 self-center md:self-end h-6 w-[50%] md:w-[90%] ml-0 md:ml-3.5' />
										<p className='coverageCarCardSkeleton__fromDailyRateText bg-gray-200 self-center md:self-end h-3 md:h-3.5 w-[30%] md:w-[50%] mt-[5px]' />
									</>
								)}
							</div>
						</div>
						{showButtons && multipleCompanies && (
							<div className='coverageCarCardSkeleton__buttonsContainer relative -bottom-px flex flex-col md:flex-row justify-center md:justify-end items-center md:items-start mt-4 md:mt-6'>
								<Button
									onClick={() => null}
									content={<div className={`bg-gray-100 ${companyImageClassName || ''}`} />}
									customClass={
										'coverageCarCardSkeleton__button cursor-default rounded-[15px] border-[2px] border-gray-200 py-1 min-w-[80%] md:min-w-0 px-5 mb-1.5 md:mb-0 mr-0 md:mr-2.5 bg-white'
									}
									noHover={true}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

CoverageCarCardSkeleton.defaultProps = {};

export default CoverageCarCardSkeleton;
