import { encryptWithPublicKey } from './encrypt-with-key.util';
import CryptoJS from 'crypto-js';

export function signature(jsonText: string, apiToken: string, publicKey: string): string {
	const apiKey: string = window.btoa(unescape(encodeURIComponent(apiToken)));
	const apiSecret: string = window.btoa(unescape(encodeURIComponent(jsonText)));
	const key = CryptoJS.enc.Base64.parse(apiSecret);
	const prehash = CryptoJS.enc.Utf8.parse(apiKey);
	const hash = CryptoJS.HmacSHA256(prehash, key);
	const signature = hash.toString(CryptoJS.enc.Base64);
	const encryptedSignature = encryptWithPublicKey(signature, publicKey);
	return encryptedSignature;
}
