export enum FilterTypes {
	SCALES = 'scales',
	FARE_FAMILY = 'fareFamily',
	BAGGAGE = 'baggage',
	AIRLINE = 'airline',
	SCHEDULE = 'schedule',
	PRICE = 'price',
	HISTORICAL_TYPE = 'productType',
	HISTORICAL_STATUS = 'status',
}
