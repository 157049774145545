import { useState, useEffect } from 'react';

const useTextSkeleton = (
	minTextLines: number = 2,
	maxTextLines: number = 4,
	minTextWidth: number = 20,
	medTextWidth: number = 95,
	maxTextWidth: number = 100,
	style: string = '',
): JSX.Element[] => {
	const [textSkeleton, setTextSkeleton] = useState<JSX.Element[]>([]);
	useEffect(() => {
		function getRndInteger(min: number, max: number): number {
			return Math.floor(Math.random() * (max - min + 1)) + min;
		}
		setTextSkeleton(
			Array(getRndInteger(minTextLines, maxTextLines))
				.fill(0)
				.map((item: number, index: number, items: number[]) => {
					return (
						<p
							className={`bg-gray-200 ${style}`}
							style={{
								width: `${index === items.length - 1 ? getRndInteger(minTextWidth, maxTextWidth) : getRndInteger(medTextWidth, maxTextWidth)}%`,
							}}
							key={index}
						/>
					);
				}),
		);
	}, [minTextLines, maxTextLines, minTextWidth, medTextWidth, maxTextWidth, style]);

	return textSkeleton;
};

export default useTextSkeleton;
