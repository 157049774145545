import { ReservesListProps } from '../../lib/flights/organisms/admin/flights/reserves-list/reserves-list';
import { formatDate } from '../../shared/services/dates-managment.service';
import { TableData, RowData, Reserves } from '../../shared/interfaces';
import { ProductStatus } from '../../shared/enums/reserve-list.enum';
import { DateFormat } from '../../shared/enums/date.enum';

export const tableFilling = (props: ReservesListProps): TableData => {
	const productStateValidation = (status: ProductStatus, labels: string[], validation: boolean): JSX.Element => {
		if (validation) {
			if (status === ProductStatus.canceled) {
				return <p className='bg-[#CD5851] rounded px-3 w-max text-white font-bold'>{labels[0]}</p>;
			}

			if (status === ProductStatus.issued) {
				return <p className='bg-[#5BB55E] rounded px-3 w-max text-white font-bold'>{labels[1]}</p>;
			}

			if (status === ProductStatus.paymentPending) {
				return <p className='bg-[#E9AF50] rounded px-3 w-max text-white font-bold'>{labels[2]}</p>;
			}

			if (status === ProductStatus.pending) {
				return <p className='bg-[#E9AF50] rounded px-3 w-max text-white font-bold'>{labels[3]}</p>;
			}

			if (status === ProductStatus.booked) {
				return <p className='bg-[#E9AF50] rounded px-3 w-max text-white font-bold'>{labels[4]}</p>;
			}
		}

		if (!validation) {
			if (status === ProductStatus.canceled) {
				return (
					<div className='flex items-center'>
						<div className='bg-[#CD5851] rounded-full h-3 w-3 mr-2' />
						<p>{labels[0]}</p>
					</div>
				);
			}

			if (status === ProductStatus.issued) {
				return (
					<div className='flex items-center'>
						<div className='bg-[#5BB55E] rounded-full h-3 w-3 mr-2' />
						<p>{labels[1]}</p>
					</div>
				);
			}

			if (status === ProductStatus.paymentPending) {
				return (
					<div className='flex items-center'>
						<div className='bg-[#E9AF50] rounded-full h-3 w-3 mr-2' />
						<p>{labels[2]}</p>
					</div>
				);
			}

			if (status === ProductStatus.pending) {
				return (
					<div className='flex items-center'>
						<div className='bg-[#E9AF50] rounded-full h-3 w-3 mr-2' />
						<p>{labels[3]}</p>
					</div>
				);
			}

			if (status === ProductStatus.booked) {
				return (
					<div className='flex items-center'>
						<div className='bg-[#E9AF50] rounded-full h-3 w-3 mr-2' />
						<p>{labels[4]}</p>
					</div>
				);
			}
		}

		return <span />;
	};

	const headsReserves: RowData[] = props.headsReserves.map((label: string, index: number) => ({
		value: (
			<div className={`w-full h-[58px] text-[#878787] flex items-center justify-start ${!index ? 'justify-center' : ''}`}>
				<p>{label}</p>
			</div>
		),
		label: '',
	}));

	const rowsReserves = (): RowData[][] => {
		return props.reserves.map((reserve: Reserves) => {
			return [
				{
					value: (
						<div className='flex justify-center'>
							<div className='w-[70%] flex justify-between'>
								<span className={`${reserve.download ? 'heartIco' : ''} w-[40px] text-center`} />
								<p className='w-[90px] text-center'>{formatDate(reserve.bookingDate, DateFormat.standarStringDate)}</p>
								<p className='w-[40px] text-center'>{reserve.codeLetter}</p>
							</div>
						</div>
					),
					label: '',
				},
				{
					value: (
						<div>
							<p className='font-bold mb-1'>{reserve.codeReserve}</p>
							{productStateValidation(reserve.productStatus, props.productStatesLabels, true)}
						</div>
					),
					label: '',
				},
				{
					value: reserve.nameBiller,
					label: '',
				},
				{
					value: reserve.agency,
					label: '',
				},
				{
					value: reserve.company,
					label: '',
				},
				{
					value: (
						<div className='flex items-center justify-between'>
							{productStateValidation(reserve.productStatus, props.productStatesLabels, false)}
							<div className='flex w-5 justify-between mr-8'>
								<div className='h-1 w-1 bg-[#393939] rounded-full' />
								<div className='h-1 w-1 bg-[#393939] rounded-full' />
								<div className='h-1 w-1 bg-[#393939] rounded-full' />
							</div>
						</div>
					),
					label: '',
				},
			];
		});
	};

	return { head: headsReserves, rows: rowsReserves() };
};
