import SpecialEventSkeletonRecommendation from './skeleton/special-event-redommendation-skeleton';
import SpecialEventDetail from '../../components/special-event-detail/special-event-detail';
import { PassengersFieldRestrictionsInterface } from '../../../../shared/interfaces';
import { ModelTypeEnum } from '../../../../shared/enums';
import { translateDay } from '../../../../shared/utils';
import { PassangerType } from '../../../disney/shared';
import { addAlphaToColor, numberToLocaleWithcurrency } from '../../../../shared/services';
import { Backdrop, Button, IcomoonIcon, Image } from '../../../ui-elements';
import { useEffect, useState } from 'react';
import {
	DisneyEventSearchResult,
	DisneyParkFareDetail,
	DisneyParkFareGroup,
	DisneyParkInclusion,
	DisneySearchFormSubmitInterface,
	RecommendationEventSpecial,
} from '../../shared/interfaces';

export interface SpecialEventRecommendationProps {
	callBack?: (data: RecommendationEventSpecial) => void;
	t: (value: string) => string;
	colorPrimary?: string;
	colorSecondary?: string;
	disneyData: DisneyEventSearchResult;
	skeletonRecommendation?: number;
	disabledButtons: boolean;
	calendarRestriction?: number;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	loadingSpecialEvent: boolean;
	typeAgency: boolean;
	emitSubmitSearch: (data: DisneySearchFormSubmitInterface) => void;
}

export interface SpecialEventRecommendaProps {
	disneyData: DisneyEventSearchResult;
	t: (value: string) => string;
}

export function SpecialEventRecommendation({
	skeletonRecommendation,
	colorPrimary,
	colorSecondary,
	disneyData,
	callBack,
	t,
	disabledButtons,
	calendarRestriction,
	passengersRestrictions,
	loadingSpecialEvent,
	typeAgency,
	emitSubmitSearch,
}: SpecialEventRecommendationProps): JSX.Element {
	const firstPosition: number = 0;
	const [disneyDetailData, setDisneyDetailData] = useState<RecommendationEventSpecial>();
	const [disneyResult, setDisneyResult] = useState<RecommendationEventSpecial[]>([]);
	const [openDetail, setOpenDetail] = useState<boolean>(false);
	const [openMoreInformation, setOpenMoreInformation] = useState<boolean>(false);
	const [disneyParkFareGroup, setDisneyParkFareGroup] = useState<DisneyParkFareGroup[]>([]);
	const [specialEventName, setSpecialEventName] = useState<string>('');
	const alphaColorPrimary: string | undefined = colorPrimary ? addAlphaToColor(colorPrimary, 0.25) : undefined;
	const defaultItemSkeleton: number = 8;
	const defaultImage: string = 'https://cdn-pdn.smartlinks.dev/cdn-images/sinfoto.svg';
	const defaultparkName: string = 'Walt Disney world, Florida, Estados Unidos';
	const findDisneyDetail = (
		disneyData: DisneyEventSearchResult,
		specialEventDetail: RecommendationEventSpecial,
	): RecommendationEventSpecial | undefined => {
		const disneyName = specialEventDetail.information.name;
		setSpecialEventName(disneyName);

		if (disneyData && disneyData.recommendations && disneyData.recommendations.length > 0) {
			return disneyData?.recommendations.find(recomendation => recomendation?.information?.name === disneyName);
		}

		return undefined;
	};

	useEffect(() => {
		if (openDetail) {
			(document.querySelector('body') as HTMLBodyElement).style.overflow = 'hidden';

			return;
		}

		(document.querySelector('body') as HTMLBodyElement).style.overflow = 'auto';
	}, [openDetail]);

	useEffect(() => {
		if (!disneyData?.recommendations.length) {
			setOpenDetail(false);
		}

		setDisneyResult(disneyData.recommendations || []);

		if (disneyDetailData) {
			const disneyDatUpdate = findDisneyDetail(disneyData, disneyDetailData);
			setDisneyDetailData(disneyDatUpdate);
		}
	}, [disneyData]);

	const openDetailComponent = (entry: RecommendationEventSpecial): void => {
		setDisneyDetailData(entry);

		setOpenDetail(true);
	};

	const namePark = (disneyData: RecommendationEventSpecial): string => {
		if (disneyData?.fareGroup[firstPosition]?.inclusions) {
			const trueInclusions: DisneyParkInclusion[] = disneyData.fareGroup[firstPosition].inclusions.filter(inclusion => inclusion?.isIncluded);
			if (trueInclusions.length === 1) {
				return trueInclusions[firstPosition].description;
			}
		}

		return defaultparkName;
	};

	const passengerTranslation = (type: PassangerType): string => {
		const tranlations = {
			adult: t('olderThan10Years'),
			child: t('olderThan3years'),
		};

		return tranlations[type].toLowerCase() || '';
	};

	const valuePassenger = (passanger: DisneyParkFareDetail): string => {
		if (disneyData) {
			const defaultValue: number = 0;
			const defaultValueCurrency: string = 'USD';
			switch (disneyData?.recommendations[firstPosition]?.paymentMethod) {
				case ModelTypeEnum.POINTS:
					return `${numberToLocaleWithcurrency(passanger.requiredPoints ?? defaultValue)} ${passanger.pointsName ?? t('points')}`;
				case ModelTypeEnum.POINT_PLUS_MONEY:
					return `${numberToLocaleWithcurrency(passanger.requiredPoints ?? defaultValue)} ${passanger.pointsName ?? t('points')} + 
					${numberToLocaleWithcurrency(passanger.requiredAmount ?? defaultValue, passanger.currency ?? defaultValueCurrency)} ${
	passanger.currency ?? defaultValueCurrency
}`;
				default:
					return passanger.totalFormated ?? '';
			}
		}

		return '';
	};

	const Passanger = (passanger: DisneyParkFareDetail): JSX.Element => {
		return (
			<div className='flex itemx-center justify-between'>
				<p className='specialEventRecommendation__passengerPrice md:text-[21px] flex flex-col md:flex-row items-center'>
					<span className='specialEventRecommendation__passengerPriceLabel specialEventRecommendation__bold'>
						{t('pricePer')} {t(passanger.passengerType).toLowerCase()}
					</span>

					<span className='specialEventRecommendation__passengerTypeLabel text-xs md:text-[21px] md:ml-2'>
						({passengerTranslation(passanger.passengerType)})
					</span>
				</p>

				<p className='specialEventRecommendation__passengerPrice md:text-[21px] flex flex-col md:flex-row' style={{ color: colorPrimary }}>
					<span className='specialEventRecommendation__passengerPriceLabel specialEventRecommendation__bold text-center md:text-start'>
						{t('fromTo')}
					</span>

					<span
						data-testid='valuePassenger'
						className='specialEventRecommendation__passengerTypeLabel md:ml-2 specialEventRecommendation__bold text-center md:text-start'
					>
						{valuePassenger(passanger)} {'/ ' + t('ticket').toLowerCase()}
					</span>
				</p>
			</div>
		);
	};

	const SelectedButtom = (parkEntrance: RecommendationEventSpecial): JSX.Element => {
		return (
			<div
				onClick={() => openDetailComponent(parkEntrance)}
				style={{ backgroundColor: colorSecondary }}
				className='specialEventRecommendation-button__selected flex rounded-b absolute left-0 bottom-0 w-full py-3 justify-center items-center'
			>
				<IcomoonIcon className='mr-2 text-white' icon='checkRoundIco' />
				<span className='text-white text-[14px]'>{t('selectedEntry')}</span>
			</div>
		);
	};

	const selectedItem = (current: RecommendationEventSpecial): boolean => {
		return disneyDetailData === current;
	};

	useEffect(() => {
		setDisneyParkFareGroup(disneyDetailData?.fareGroup || []);
	}, [disneyDetailData]);

	const fareGroupRecommended = (): DisneyParkFareGroup | undefined => {
		return disneyParkFareGroup.find((fareGroup: DisneyParkFareGroup) => fareGroup.isRecommended);
	};

	const isRangeDay = (disneyFareGroup?: DisneyParkFareGroup): boolean => {
		const rangeDay: number = 1;
		if (!disneyFareGroup) {
			return false;
		}

		return disneyFareGroup.durationDays + disneyFareGroup.usageBufferDays > rangeDay;
	};

	const RangeDay = (): JSX.Element => {
		return (
			<>
				{fareGroupRecommended()?.usageDate.startDateFormated} {t('to').toLowerCase()} {fareGroupRecommended()?.usageDate.endDateFormated}
			</>
		);
	};

	const RangeDayText = (): JSX.Element => {
		const theLowerCase: string = t('the').toLowerCase();
		return (
			<>
				{disneyDetailData?.fareGroup[firstPosition].durationDays}{' '}
				{translateDay(t, disneyDetailData?.fareGroup[firstPosition].durationDays).toLowerCase()} {t('toVisitParks')}{' '}
				{isRangeDay(fareGroupRecommended()) ? t('comeIn').toLowerCase() + ' ' + theLowerCase : theLowerCase}{' '}
			</>
		);
	};

	const ValidDates = (): JSX.Element => {
		return (
			<>
				<section className='flex mt-[100px] mb-4 text-[12px] text-gray-500'>
					<IcomoonIcon fontSize={24} icon='calendarBlankIco self-start' className='mr-2' />
					<div className='recomendation-detail__seeDetails'>
						<div className='flex flex-col mb-3'>
							<p className='font-bold'>{t('validTicketDate')}</p>
							<p className='text-xs'>
								{t('yourTicketsAreValid')} <RangeDayText />
								{isRangeDay(fareGroupRecommended()) ? <RangeDay /> : fareGroupRecommended()?.usageDate.startDateFormated}
								{', ' + t('subjectToAvailability')}
							</p>
						</div>
					</div>
				</section>
			</>
		);
	};

	const closeDescriptionModal = (): void => {
		setOpenMoreInformation(false);

		setDisneyDetailData(undefined);
	};

	return (
		<>
			<div
				className={'specialEventRecommendation flex flex-col'}
				style={{
					marginBottom: !openDetail && disneyDetailData ? '0px' : '140px',
				}}
			>
				{!disneyData?.recommendations.length
					? Array.from(Array(skeletonRecommendation || defaultItemSkeleton).keys()).map((_, index: number) => (
						<SpecialEventSkeletonRecommendation key={index} />
					  ))
					: disneyResult.map((parkEntrance, index: number) => {
						const { information } = parkEntrance;
						return (
							<div
								style={{
									border: `${selectedItem(parkEntrance) ? `solid ${colorSecondary || ''}` : 'none'}`,
								}}
								key={index}
								className='specialEventRecommendation__card rounded-lg pb-[16px] bg-white shadow-lg mb-[25px]'
							>
								<div className={'specialEventRecommendation__cardContainer relative flex flex-col justify-between h-full'}>
									<div className='flex md:flex-row flex-col'>
										<article className='hotels-disney-resume__card-container__photo mr-4'>
											<Image
												url={information.thumbnailUrl || defaultImage}
												customClass={'hotels-disney-resume__card-container__photo--img w-[450px] h-[217px]'}
											/>
										</article>
										<div className='flex flex-col w-[100%] md:top-0 relative top-1'>
											<div className='specialEventRecommendation__head bg-[#CCD0D6] rounded-tl-lg rounded-tr-lg'>
												<div className='specialEventRecommendation__headContent  px-[20px] md:px-[32px] flex items-center'>
													<p
														className='specialEventRecommendation__headTitle specialEventRecommendation__bold text-[30px]'
														style={{ color: colorPrimary }}
													>
														{information.name}
													</p>
												</div>
											</div>

											<div className='specialEventRecommendation__body pt-[29px] px-[20px] md:px-[32px]'>
												<div className='specialEventRecommendation__row flex flex-col md:flex-row justify-between items-center mb-[13px]'>
													<p className='text-[21px] order-2 md:order'>{information.description}</p>
												</div>

												{information.usageDate.startDateFormated === information.usageDate.endDateFormated ? (
													<div className='specialEventRecommendation__row flex flex-col md:flex-row items-center mb-[13px]'>
														<div className='specialEventRecommendation__datesFrom flex items-center md:mr-2'>
															<IcomoonIcon fontSize={22} color={colorPrimary} className='h-[21px] self-start mr-2' icon='searchCalendarIco' />
															<p className='specialEventRecommendation__bold mr-2'>{t('useTicket')}:</p>
															<p className='specialEventRecommendation__date'>{information.usageDate.startDateFormated}</p>
														</div>
													</div>
												) : (
													<div className='specialEventRecommendation__row flex flex-col md:flex-row items-center mb-[13px]'>
														<div className='specialEventRecommendation__datesFrom flex items-center md:mr-2'>
															<IcomoonIcon fontSize={22} color={colorPrimary} className='h-[21px] self-start mr-2' icon='searchCalendarIco' />
															<p className='specialEventRecommendation__bold mr-2'>{t('useTicketBetween')}:</p>
															<p className='specialEventRecommendation__date'>{information.usageDate.startDateFormated}</p>
														</div>
														{information.usageDate.endDateFormated && (
															<div className='specialEventRecommendation__datesTo flex items-center'>
																<p className='specialEventRecommendation__bold mr-2'>{t('toFrom')}:</p>
																<p className='specialEventRecommendation__date'>{information.usageDate.endDateFormated}</p>
															</div>
														)}
													</div>
												)}

												<div className='specialEventRecommendation__row flex justify-center md:justify-start items-center mb-[13px]'>
													<IcomoonIcon fontSize={22} color={'black'} className='h-[21px] self-start mr-2' icon='iconCastle' />
													<p className='specialEventRecommendation__bold text-center'>{namePark(parkEntrance)}</p>
												</div>
											</div>
										</div>
									</div>

									<div className='specialEventRecommendation__footer mt-[10px] px-[32px] flex flex-col lg:flex-row items-center justify-between'>
										<div className='specialEventRecommendation__prices w-full lg:w-[75%] mb-4 lg:mr-8'>
											{information.fareDetails.map((passanger, pIndex) => {
												return <Passanger key={pIndex} {...passanger} />;
											})}
										</div>

										<div className='specialEventRecommendation__callToAction w-full lg:w-[25%]'>
											{selectedItem(parkEntrance) && <SelectedButtom {...parkEntrance} />}
											<Button
												onClick={() => openDetailComponent(parkEntrance)}
												customClass={`specialEventRecommendation__button ${
													selectedItem(parkEntrance) ? 'opacity-0' : ''
												} rounded-md text-white w-full items-center`}
												styles={{
													background: colorPrimary,
												}}
												content={<span className='specialEventRecommendation__buttonText text-[14px]'>{t('select')}</span>}
											/>
										</div>
									</div>
								</div>
							</div>
						);
					  })}

				{openDetail && disneyDetailData && (
					<div className='specialEventRecommendation__detail'>
						<SpecialEventDetail
							colorPrimary={colorPrimary}
							colorSecondary={colorSecondary}
							traductions={t}
							closeCallback={(result?: RecommendationEventSpecial) => {
								if (result) {
									callBack && callBack(result);

									return;
								}

								if (!disabledButtons) {
									setDisneyDetailData(undefined);

									setOpenDetail(false);
								}
							}}
							disneyDetail={disneyDetailData}
							disabledButtons={disabledButtons}
							calendarRestriction={calendarRestriction}
							passengersRestrictions={passengersRestrictions}
							emitSubmitSearch={emitSubmitSearch}
							specialEventName={specialEventName}
							loadingSpecialEvent={loadingSpecialEvent}
							typeAgency={typeAgency}
						/>
					</div>
				)}

				{openMoreInformation && disneyDetailData && (
					<>
						<Backdrop show={true} onClick={() => closeDescriptionModal()} zIndex={80} backgroundColor={alphaColorPrimary} opacity={0.5} />
						<div className='specialEventDetail z-[100] flex justify-end fixed right-0 top-0 scroll-smooth max-h-[100vh]  overflow-y-scroll'>
							<div className='specialEventDetail__container mt-[9%] mx-[5%] md:mx-[20%] rounded-lg w-full bg-white py-[20px]  px-[25px] md:px-[55px] h-full'>
								<span
									onClick={() => closeDescriptionModal()}
									className='closeIco text-gray-500 text-[20px] cursor-pointer w-full block text-right'
								></span>
								<div className='specialEventDetail__content flex flex-col justify-center text-center'>
									<IcomoonIcon fontSize={40} color={colorSecondary} className='self-start mr-2 w-full' icon='iconDisneyMickeyMouse' />
									<p className='specialEventDetail__modalTitle specialEventDetail__bold text-[30px] my-4'>{disneyDetailData.information.name}</p>
									<p className='specialEventDetail__modalDescription text-[18px]'>{disneyDetailData.information.description}</p>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div>{disneyDetailData && !openMoreInformation && <ValidDates />}</div>
		</>
	);
}

export default SpecialEventRecommendation;
