import { SlideInterface } from '../../../../../../shared/interfaces/generics/slider.interface';
import Image from '../../../../../ui-elements/image/image';

export interface ReviewsCardProps {
	slide: SlideInterface;
	isActive?: boolean;
	imageSize?: string;
	mainColor?: string;
	slideTitleClassName?: string;
	slideSubtitleClassName?: string;
	slideDescriptionClassName?: string;
}

export function ReviewsCard({
	slide,
	isActive,
	imageSize,
	mainColor,
	slideTitleClassName,
	slideSubtitleClassName,
	slideDescriptionClassName,
}: ReviewsCardProps): JSX.Element {
	return (
		<>
			<span
				className='reviewsCard__icon absolute left-7 -top-10 text-center font-normal text-9xl'
				style={{
					color: mainColor,
				}}
			>
				&#10077;
			</span>
			<div
				className='reviewsCard__container flex-grow rounded-xl w-full h-full px-8 py-12 md:py-14 flex flex-col justify-center'
				style={{
					backgroundColor: isActive ? 'black' : 'white',
				}}
			>
				<h3
					className={`font-light mb-2.5 md:mb-3.5 text-left text-3xl ${isActive ? 'md:text-4xl' : ''} ${slideTitleClassName || ''}`}
					style={{
						color: mainColor,
					}}
				>
					{slide.title}
				</h3>
				<p
					className={`font-bold mb-2.5 md:mb-3.5 text-left text-lg ${isActive ? 'md:text-2xl' : ''} ${slideSubtitleClassName || ''}`}
					style={{
						color: isActive ? 'white' : 'black',
					}}
				>
					{slide.subtitle ? slide.subtitle.toUpperCase() : ''}
				</p>
				<p
					className={`font-normal text-left text-base ${isActive ? 'md:text-xl' : ''} ${slideDescriptionClassName || ''}`}
					style={{
						color: isActive ? 'white' : 'black',
					}}
				>
					{slide.description}
				</p>
			</div>
			<div className='reviewsCard__image absolute right-7 -bottom-9 md:-bottom-10 inline-block'>
				<Image
					url={slide.image || ''}
					width={imageSize}
					height={imageSize}
					styles={{
						width: imageSize,
						height: imageSize,
						backgroundColor: mainColor,
						borderColor: mainColor,
					}}
					customClass='align-middle object-cover rounded-full mx-auto my-0 bg-white border-4'
				/>
			</div>
		</>
	);
}

ReviewsCard.defaultProps = {
	slideTitleClassName: '',
	slideSubtitleClassName: '',
	slideDescriptionClassName: '',
};

export default ReviewsCard;
