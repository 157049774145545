export interface PillProps {
	text: string;
	color?: string;
	style?: React.CSSProperties;
	className?: string;
	textClassName?: string;
	onClick?: () => void;
}

export const Pill = ({ text, color, style, className, textClassName, onClick }: PillProps): JSX.Element => {
	return (
		<div
			className={`pillLib inline-flex flex-row justify-center items-center rounded-full py-1 px-3 ${className || ''}`}
			style={{
				backgroundColor: color,
				...style,
			}}
			onClick={onClick}
		>
			<span className={`pillLib__text ${textClassName || ''}`}>{text}</span>
		</div>
	);
};

Pill.defaultProps = {
	className: '',
};

export default Pill;
