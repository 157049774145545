import { FilterElement, FilterTypes } from '../../../../shared/enums';
import { FiltersData } from '../interfaces/availability-filters.interface';

export const FiltersDataMock: FiltersData[] = [
	{
		type: FilterTypes.PRICE,
		elementType: FilterElement.RANGE,
		options: [],
		minValue: '471020.0',
		subTitle: '',
		maxValue: '6028040.0',
	},
	{
		type: FilterTypes.SCALES,
		elementType: FilterElement.SELECT,
		options: [
			{
				label: 'direct',
				value: '0',
				extraDescription: '',
			},
			{
				label: '1_Scale',
				value: '1',
				extraDescription: '',
			},
			{
				label: '2_Scale',
				value: '2',
				extraDescription: '',
			},
			{
				label: '3_Scale',
				value: '3',
				extraDescription: '',
			},
			{
				label: 'moreThanThree',
				value: '4',
				extraDescription: '',
			},
			{
				label: 'all',
				value: '5',
				extraDescription: '',
			},
		],
		minValue: '0.0',
		subTitle: '',
		maxValue: '0.0',
	},
	{
		type: FilterTypes.AIRLINE,
		elementType: FilterElement.CHECKBOX,
		options: [
			{
				label: 'Avianca AV',
				value: 'AV',
				extraDescription: 'COP 471020',
				logoUrl: 'https://www.gstatic.com/flights/airline_logos/70px/AV.png',
			},
			{
				label: 'Latam Airlines (LA)',
				value: 'LA',
				extraDescription: 'COP 869180',
				logoUrl: 'https://www.gstatic.com/flights/airline_logos/70px/LA.png',
			},
			{
				label: 'Copa Airlines',
				value: 'CM',
				extraDescription: 'COP 3999760',
			},
		],
		minValue: '0.0',
		subTitle: 'Desde',
		maxValue: '0.0',
	},
	{
		type: FilterTypes.SCHEDULE,
		elementType: FilterElement.BUTTON,
		options: [
			{
				label: 'morning',
				value: 'Morning',
				extraDescription: 'En la mañana',
			},
			{
				label: 'afternoon',
				value: 'Afternoon',
				extraDescription: 'En la tarde',
			},
			{
				label: 'night',
				value: 'Night',
				extraDescription: 'En la noche',
			},
		],
		minValue: '0.0',
		subTitle: '',
		maxValue: '0.0',
	},
];

export const FiltersOriginalDataMock: FiltersData[] = [
	{
		type: FilterTypes.SCALES,
		options: [
			{
				label: 'all',
				value: 'all',
			},
			{
				label: 'direct',
				value: 'direct',
			},
			{
				label: '1_scale',
				value: '1_scale',
			},
			{
				label: '2_or_more_scale',
				value: '2_or_more_scale',
			},
		],
		elementType: FilterElement.SELECT,
	},
	{
		type: FilterTypes.FARE_FAMILY,
		options: [
			{
				label: 'all',
				value: 'all',
			},
			{
				label: 'basic',
				value: 'basic',
			},
			{
				label: 'plus',
				value: 'plus',
			},
			{
				label: 'full',
				value: 'full',
			},
		],
		elementType: FilterElement.SELECT,
	},
	{
		type: FilterTypes.BAGGAGE,
		options: [
			{
				label: 'all',
				value: 'all',
			},
			{
				label: 'PersonalItem',
				value: 'PersonalItem',
			},
			{
				label: 'CarryOn',
				value: 'CarryOn',
			},
			{
				label: 'Checked',
				value: 'Checked',
			},
		],
		elementType: FilterElement.SELECT,
	},
	{
		type: FilterTypes.AIRLINE,
		options: [
			{
				label: 'avianca',
				value: 'avianca',
				extraDescription: 'MXN 1,255',
			},
			{
				label: 'viva',
				value: 'viva',
				extraDescription: 'MXN 2,255',
			},
			{
				label: 'latam',
				value: 'latam',
				extraDescription: 'MXN 3,255',
			},
			{
				label: 'copa',
				value: 'copa',
				extraDescription: 'MXN 4,255',
			},
		],
		elementType: FilterElement.CHECKBOX,
		subTitle: 'Desde',
	},
	{
		type: FilterTypes.SCHEDULE,
		options: [
			{
				label: 'morning',
				value: 'Morning',
				extraDescription: '(5:00 a.m. - 11:59 a.m.)',
			},
			{
				label: 'afternoon',
				value: 'Afternoon',
				extraDescription: '(5:00 a.m. - 11:59 a.m.)',
			},
			{
				label: 'night',
				value: 'Night',
				extraDescription: '(5:00 a.m. - 11:59 a.m.)',
			},
		],
		elementType: FilterElement.BUTTON,
	},
	{
		type: FilterTypes.PRICE,
		options: [],
		elementType: FilterElement.RANGE,
		minValue: '0',
		maxValue: '20000000',
	},
];
