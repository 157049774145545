import { PopUpDialogEventInterface } from '../../../shared/interfaces/pop-up-dialog.interface';
import { AlertEventResponses, AlertStates } from '../../../shared/enums/alert.enum';
import { AlertStateInfo } from '../../../shared/interfaces/alert.interface';
import { getAlertStateInfo } from '../../../shared/services/alert.service';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import Button from '../../ui-elements/button/button';
import styles from './pop-up-dialog.module.scss';
import React from 'react';

export interface PopUpDialogProps {
	idComponent?: string;
	title?: string;
	description?: string;
	showStateIcon?: boolean;
	state?: AlertStates;
	okButtonText?: string;
	showCancelButton?: boolean;
	showCloseButton?: boolean;
	cancelButtonText?: string;
	children?: JSX.Element;
	style?: React.CSSProperties;
	closeIconSize?: number;
	className?: string;
	closeIconClassName?: string;
	onNextClick?: (data: PopUpDialogEventInterface) => void;
	onPreviousClick?: (data: PopUpDialogEventInterface) => void;
	onCloseClick?: (data: PopUpDialogEventInterface) => void;
}

export const PopUpDialog = React.forwardRef<HTMLDivElement, PopUpDialogProps>((props, ref) => {
	const satetInfo: AlertStateInfo = getAlertStateInfo(props?.state ? props.state : AlertStates.unknown);
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`popUpDialog flex flex-col rounded-lg ${props.className || ''}`} style={props?.style} ref={ref}>
				{props.showCloseButton && (
					<div className={`w-full flex justify-end ${props.closeIconClassName || ''}`}>
						<div
							className='popUpDialog__closeButton cursor-pointer'
							onClick={() => (props?.onCloseClick ? props?.onCloseClick({ id: props.idComponent, response: AlertEventResponses.void }) : null)}
						>
							<IcomoonIcon
								icon='closeIco'
								fontSize={props.closeIconSize}
								style={{
									width: `${props.closeIconSize || '0'}px`,
									height: `${props.closeIconSize || '0'}px`,
								}}
							/>
						</div>
					</div>
				)}
				{props.children ? (
					props.children
				) : (
					<>
						<div className={'flex flex-row'}>
							{props.showStateIcon && <IcomoonIcon icon={satetInfo.icon} color={satetInfo.color} fontSize={55} />}
							<div className='flex-grow pl-7'>
								<p className={'popUpDialog__title text-base font-family-black font-black mb-3.5 popUpDialog__text'}>{props.title}</p>
								<p className={'popUpDialog__description text-xs font-family-regular popUpDialog__text'}>{props.description}</p>
							</div>
						</div>
						<div className='flex flex-col md:flex-row md:justify-end mt-6'>
							{props.showCancelButton && (
								<Button
									onClick={() =>
										props?.onPreviousClick ? props?.onPreviousClick({ id: props.idComponent, response: AlertEventResponses.cancel }) : null
									}
									text={props.cancelButtonText}
									customClass={'popUpDialog__previousButton w-full md:w-auto text-xs text-white rounded-lg mr-5 popUpDialog__button'}
								/>
							)}
							<Button
								onClick={() => (props?.onNextClick ? props?.onNextClick({ id: props.idComponent, response: AlertEventResponses.ok }) : null)}
								text={props.okButtonText}
								customClass={'popUpDialog__nextButton mt-[10px] md:mt-0 w-full md:w-auto text-xs text-white rounded-lg popUpDialog__button'}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
});

PopUpDialog.defaultProps = {
	className: '',
	closeIconClassName: '',
};

export default PopUpDialog;
