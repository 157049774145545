import { SlideInterface, SlideConfigInterface, SlideStylesInterface } from '../../../../../shared/interfaces/generics/slider.interface';
import Slide from '../../../../shared-components/slide/slide';
import { useCallback, useMemo, RefObject } from 'react';
import SliderSkeleton from './skeleton/skeleton-slider';
import styles from './slider.module.scss';

export interface SliderProps {
	slides: SlideInterface[];
	slidesNumberShow: number;
	slidesHeight?: string;
	slidesWidth: number;
	slidesMargin: number;
	sliderSideMargin: number;
	config?: SlideConfigInterface;
	loading?: boolean;
	customContentSlides?: JSX.Element[];
	sliderRef?: RefObject<HTMLDivElement>;
	slidesContainerRef?: RefObject<HTMLDivElement>;
	onSlideClick?: (id: string) => void;
	className?: string;
	slideOutClassName?: string;
	outerClassName?: string;
	slidesClassNames?: SlideStylesInterface;
	slidesSkeletonClassNames?: SlideStylesInterface;
	skeletonSlides: number;
}

export function Slider({
	slides,
	slidesNumberShow,
	slidesHeight,
	slidesWidth,
	slidesMargin,
	sliderSideMargin,
	config,
	loading,
	customContentSlides,
	sliderRef,
	slidesContainerRef,
	onSlideClick,
	className,
	outerClassName,
	slidesClassNames,
	slidesSkeletonClassNames,
	skeletonSlides,
}: SliderProps): JSX.Element {
	const twoSides: number = 2;
	const diffSlidesMargins: number = 1;
	const diffCommonMultiple: number = 1;
	const slidesNumber: number = customContentSlides && customContentSlides.length ? customContentSlides.length : slides.length;
	const preventDefaultHandler: (e: any) => void = useCallback(e => {
		e = e || window.event;

		if (e?.type === 'touchmove') {
			e?.preventDefault();
		}
	}, []);

	const slidesComponent = useMemo(
		() =>
			((
				tempSlides: SlideInterface[],
				tempSlidesNumberShow: number,
				tempSlidesWidth: number,
				tempSliderSideMargin: number,
				tempSlidesMargin: number,
				tempCustomSlides?: JSX.Element[],
			): JSX.Element[] => {
				if (!tempSlidesWidth) {
					return [];
				} else if (tempCustomSlides && tempCustomSlides.length) {
					return [
						...tempCustomSlides.map((customSlide: JSX.Element, index: number) => {
							return (
								<Slide
									key={index}
									outClassName={`float-left relative slider__slideTransition ${slidesClassNames?.outClassName || ''}`}
									className={`slider__slideTransition ${slidesClassNames?.className || ''}`}
									height={slidesHeight}
									data={{
										title: null,
										subtitle: null,
										description: null,
										linkText: null,
										link: null,
										image: null,
										iconClassName: null,
										traits: null,
										type: null,
									}}
									config={config}
									imageClassName={slidesClassNames?.imageClassName}
									imageBackdropClassName={slidesClassNames?.imageBackdropClassName}
									contentClassName={slidesClassNames?.contentClassName}
									titleClassName={slidesClassNames?.titleClassName}
									subtitleClassName={slidesClassNames?.subtitleClassName}
									descriptionClassName={slidesClassNames?.descriptionClassName}
									subtitleLineClassName={slidesClassNames?.subtitleLineClassName}
									buttonClassName={slidesClassNames?.buttonClassName}
									outContentClassName={slidesClassNames?.outContentClassName}
									iconClassName={slidesClassNames?.iconClassName}
									mainColor={slidesClassNames?.mainColor}
									onClick={onSlideClick}
									slideId={index.toString()}
									style={{
										width: `${tempSlidesWidth}px`,
										minWidth: `${tempSlidesWidth}px`,
										maxWidth: `${tempSlidesWidth}px`,
										marginRight: !((index + diffCommonMultiple) % tempSlidesNumberShow) ? `${tempSliderSideMargin}px` : `${tempSlidesMargin}px`,
										marginLeft: !(index % tempSlidesNumberShow) ? `${tempSliderSideMargin}px` : undefined,
									}}
								>
									{customSlide}
								</Slide>
							);
						}),
					];
				} else if (tempSlides.length) {
					return [
						...tempSlides.map((slideInfo: SlideInterface, index: number) => {
							return (
								<Slide
									key={index}
									outClassName={`float-left relative slider__slideTransition ${slidesClassNames?.outClassName || ''}`}
									className={`slider__slideTransition ${slidesClassNames?.className || ''}`}
									height={slidesHeight}
									data={slideInfo}
									config={config}
									imageClassName={slidesClassNames?.imageClassName}
									imageBackdropClassName={slidesClassNames?.imageBackdropClassName}
									contentClassName={slidesClassNames?.contentClassName}
									titleClassName={slidesClassNames?.titleClassName}
									subtitleClassName={slidesClassNames?.subtitleClassName}
									descriptionClassName={slidesClassNames?.descriptionClassName}
									subtitleLineClassName={slidesClassNames?.subtitleLineClassName}
									buttonClassName={slidesClassNames?.buttonClassName}
									outContentClassName={slidesClassNames?.outContentClassName}
									iconClassName={slidesClassNames?.iconClassName}
									mainColor={slidesClassNames?.mainColor}
									onClick={onSlideClick}
									slideId={index.toString()}
									style={{
										width: `${tempSlidesWidth}px`,
										minWidth: `${tempSlidesWidth}px`,
										maxWidth: `${tempSlidesWidth}px`,
										marginRight: !((index + diffCommonMultiple) % tempSlidesNumberShow) ? `${tempSliderSideMargin}px` : `${tempSlidesMargin}px`,
										marginLeft: !(index % tempSlidesNumberShow) ? `${tempSliderSideMargin}px` : undefined,
									}}
								/>
							);
						}),
					];
				} else {
					return [];
				}
			})(slides, slidesNumberShow, slidesWidth, sliderSideMargin, slidesMargin, customContentSlides),
		[slides, slidesNumberShow, slidesWidth, sliderSideMargin, slidesMargin, customContentSlides],
	);

	return loading ? (
		<SliderSkeleton
			slides={skeletonSlides}
			slidesHeight={slidesHeight}
			slideConfig={config}
			customContentSlides={customContentSlides}
			className={className}
			outerClassName={outerClassName}
			slideClassNames={slidesSkeletonClassNames}
		/>
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`slider overflow-hidden relative ${outerClassName || ''}`} ref={sliderRef}>
				<div
					className={`slider__slidesContainer flex relative top-0 slider__slidesContainerShifting ${className || ''}`}
					style={{
						minWidth: `${
							(slidesWidth + (slidesMargin * (slidesNumberShow - diffSlidesMargins) + sliderSideMargin * twoSides) / slidesNumberShow) * slidesNumber
						}px`,
					}}
					onTouchMove={preventDefaultHandler}
					ref={slidesContainerRef}
				>
					{slidesComponent}
				</div>
			</div>
		</>
	);
}

Slider.defaultProps = {
	className: '',
	outerClassName: '',
	slideClassName: '',
	slideOutClassName: '',
	slideImageClassName: '',
	slideContentClassName: '',
	slideTitleClassName: '',
	slideSubtitleClassName: '',
	slideDescriptionClassName: '',
	slideSubtitleLineClassName: '',
	slideButtonClassName: '',
	skeletonSlides: 1,
};

export default Slider;
