import { GenericIntroduction } from '../../introductions/generic-introduction/generic-introduction';

export interface SkeletonBlogFeaturedStoriesProps {
	className?: string;
	introductionContainerClassName?: string;
	introductionClassName?: string;
}

export function SkeletonBlogFeaturedStories({
	className,
	introductionContainerClassName,
	introductionClassName,
}: SkeletonBlogFeaturedStoriesProps): JSX.Element {
	return (
		<div className={`animate-pulse ${className || ''}`}>
			<GenericIntroduction
				loading={true}
				title=''
				subtitle=''
				customClass={introductionClassName}
				containerClassName={`md:px-12 ${introductionContainerClassName || ''}`}
				link=''
				linkText=''
				description=''
			/>
			<div className={'flex w-full relative h-[600px] md:h-[400px] rounded-[20px] bg-black flex-col-reverse md:flex-row'}>
				<div
					className={
						'flex flex-col justify-center items-center w-full  md:w-[40%] h-[50%] md:h-auto rounded-bl-[20px] rounded-tr-none rounded-tl-none md:rounded-tl-[20px] rounded-br-[20px] md:rounded-br-none'
					}
				>
					<div className='w-[80%] md:w-[60%] flex flex-col items-start'>
						<p className={'w-[96%] h-4 md:h-6 mb-2 bg-gray-200'} />
						<p className={'w-[30%] h-4 md:h-6 mb-2.5 bg-gray-200'} />
					</div>
					<div className='w-[80%] md:w-[60%]'>
						<p className={'w-[90%] h-3 mb-2 bg-gray-200'} />
						<p className={'w-[95%] h-3 mb-2 bg-gray-200'} />
						<p className={'w-[93%] h-3 mb-2 bg-gray-200'} />
						<p className={'w-[40%] h-3 mb-2.5 bg-gray-200'} />
						<div className='flex items-center'>
							<p className={'h-3 md:h-4 w-[50px] md:w-[68px] bg-gray-200'} />
							<span className='iconIconArrowRightSelect ml-2.5 text-xs text-gray-200'></span>
						</div>
					</div>
				</div>
				<div
					className={
						'bg-gray-200 h-[50%] md:h-auto w-full md:w-[60%] rounded-tr-[20px] rounded-bl-none rounded-tl-[20px] md:rounded-tl-none  rounded-br-none md:rounded-br-[20px]'
					}
				>
					<div className='flex items-center justify-end'>
						<p className='w-[130px] h-7 bg-white rounded-[50px] mt-2.5 md:mt-5 mr-2.5 md:mr-5' />
					</div>
				</div>
			</div>
		</div>
	);
}
