import { CarGeneralCoverages, ShortCarRecommendation } from '../../../shared/interfaces/short-car-recommendation';
import { numberToLocaleWithcurrency } from '../../../../../shared/services/utils.service';
import { TypePrice } from '../../../../../shared/enums/type-price.enum';
import ToolTip from '../../../../ui-elements/tool-tip/tool-tip';
import Button from '../../../../ui-elements/button/button';
import styles from './recommendation-car-v1.module.scss';
import Image from '../../../../ui-elements/image/image';
import { useState, useEffect } from 'react';
export interface RecommendationCarV1Props {
	data?: ShortCarRecommendation;
	typePrice: TypePrice | string;
	colorPrimary?: string;
	colorSecondary?: string;
	colorTertiary?: string;
	buttonText: string;
	dailyRateText: string;
	includeAirConditionText: string;
	useEquivalentCurrency?: boolean;
	onlyDailyPrice?: boolean;
	onClick?: (data?: ShortCarRecommendation) => void;
}

export function RecommendationCarV1(props: RecommendationCarV1Props): JSX.Element {
	const [skeleton, setIsSkeleton] = useState<JSX.Element[]>([]);
	const [coverages, setCoverages] = useState<string>('');
	const [carBaggageCount, setCarBaggageCount] = useState<number>(0);
	const [totalRate, setTotalRate] = useState<number>(0);
	const [totalRateCurrency, setTotalRateCurrency] = useState<string>('');
	const [dailyRate, setDailyRate] = useState<number>(0);
	const [dailyRateCurrency, setDailyRateCurrency] = useState<string>('');
	const [modelName, setModelName] = useState<{ modelName?: string; orSimilar?: string; largeText?: boolean }>({});
	useEffect(() => {
		createskeleton();

		const generalCoverage: CarGeneralCoverages[] = props.data?.generalCoverage || [];
		if (generalCoverage.length) {
			let htmlCoverages: string = '';
			generalCoverage.forEach((coverage: CarGeneralCoverages, index: number) => {
				if (!index && coverage.includeTraduction) {
					htmlCoverages += `
          <li class="recommendationCarV1__list-item mb-3">
            <p class="recommendationCarV1__list-includeTraduction font-family-bold text-sm">
              ${coverage.includeTraduction}
            </p>
          </li>
          `;
				}

				htmlCoverages += `
        <li class="recommendationCarV1__list-item flex align-center">
          <span style="color: ${coverage.included ? props.colorPrimary || 'rgb(21, 19, 134)' : '#DC2626'}" class="${
	coverage.included ? 'checkIco' : 'closeIco'
} text-xs mr-1"></span>
          <p class="font-family-regular text-xs">
            ${coverage.tag ? coverage.tag : coverage.text || ''}
          </p>
        </li>
        `;
			});

			setCoverages(htmlCoverages);
		}

		if (props?.data?.carBaggage?.length) {
			let count: number = 0;
			props.data.carBaggage.forEach(baggae => {
				count += Number(baggae.quantity);
			});

			setCarBaggageCount(count);
		}

		let totalRate: number = props.data?.totalRate || 0;
		let totalRateCurrency: string = props.data?.totalRateCurrency || '';
		let dailyRate: number = props.data?.dailyRate || 0;
		let dailyRateCurrency: string = props.data?.dailyRateCurrency || '';
		if (props?.useEquivalentCurrency) {
			totalRate = props.data?.equivalentTotalRate || 0;

			totalRateCurrency = props.data?.equivalentTotalRateCurrency || '';

			dailyRate = props.data?.equivalentDailyRate || 0;

			dailyRateCurrency = props.data?.equivalentDailyRateCurrency || '';
		}

		formmatModelName();

		setTotalRate(totalRate);

		setTotalRateCurrency(totalRateCurrency);

		setDailyRate(dailyRate);

		setDailyRateCurrency(dailyRateCurrency);
	}, []);

	const createskeleton = (): void => {
		const arraySkeleton: JSX.Element[] = [];
		[...Array(3).keys()].forEach((item, index: number) => {
			const skeletonItem: JSX.Element = (
				<div className={'animate-pulse'} key={index}>
					<div className={'flex bg-white rounded-2xl flex-col m-4 p-3.5 recommendationCarV1__card'}>
						<div className='flex justify-between'>
							<div
								style={{
									width: '149px',
									height: '92px',
								}}
							></div>
						</div>

						<div className=''>
							<div
								style={{
									width: '76px',
									height: '28px',
								}}
							></div>
						</div>

						<div className='mt-1'>
							<p className='font-family-black text-2xl text-color-jet'></p>
							<p className='font-family-regular text-sm text-gray-500 uppercase h-8'></p>
						</div>

						<div className='my-1 flex align-center'>
							<div className={'flex align-center mr-2 recommendationCarV1__flipTooltip'}>icono</div>

							<div className={'flex align-center mr-2 recommendationCarV1__flipTooltip'}>icono</div>

							<div className={'flex align-center mr-2 recommendationCarV1__flipTooltip'}>icono</div>

							<div className={'flex align-center mr-2 recommendationCarV1__flipTooltip'}>icono</div>

							<div className={'flex align-center mr-2 recommendationCarV1__flipTooltip'}>icono</div>
						</div>

						<hr className='my-2 border-gray-400' />

						<div className=''>
							<ul className={'flex flex-wrap flex-col recommendationCarV1__publicCoverages'}>
								<li>iocno</li>
								<li>iocno</li>
								<li>iocno</li>
								<li>iocno</li>
								<li>iocno</li>
							</ul>
						</div>

						<div className='my-2'>
							<div>
								<p className='text-center font-family-black text-2xl' style={{ color: props.colorPrimary || 'rgb(21, 19, 134)' }}></p>
								<p className='text-center font-family-regular text-gray-500 text-sm'></p>
							</div>
						</div>

						<div className=''>
							<div>button</div>
						</div>
					</div>
				</div>
			);

			arraySkeleton.push(skeletonItem);
		});

		setIsSkeleton(arraySkeleton);
	};

	const formmatModelName = (): void => {
		const validateKey: number = props?.data?.model?.toLowerCase().indexOf(' o ') || 0;
		const validateSecondKey: number = props?.data?.model?.toLowerCase().indexOf(' or ') || 0;
		const preventNegative: number = 0;
		const key: number = ((validateKey > preventNegative && validateKey) || (validateSecondKey > preventNegative && validateSecondKey)) as number;
		if (key > preventNegative) {
			// prevent -1
			const largeTextLimit: number = 27;
			const orSimilar: string = (props?.data?.model?.slice(key) || '').replace(/o o/g, 'o');
			const modelName: string = props?.data?.model?.slice(0, key) || '';
			const largeText: boolean = modelName.length > largeTextLimit;
			setModelName({
				modelName,
				orSimilar,
				largeText,
			});
		}
	};

	return (
		<div>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			{props?.data ? (
				<div className={'recommendationCarV1 flex bg-white rounded-2xl flex-col my-4 mr-[20px] p-3.5 recommendationCarV1__card'}>
					<div className='recommendationCarV1__item order-1 recommendationCarV1__mainImage flex justify-between'>
						<Image width={'149px'} height={'92px'} url={props.data.imageUrl || ''} />
						<ToolTip
							title={props?.data?.rateType}
							useInfoIcon={true}
							colorPrimary={props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'}
							styles={{
								minWidth: '200px',
							}}
							body={`
              <ul class="carTooltip__list">
                ${coverages}
              </ul>
              `}
						/>
					</div>

					<div className='recommendationCarV1__item order-2 recommendationCarV1__names mt-1'>
						<p
							className='recommendationCarV1__names-model mb-0 flex items-center font-family-black text-[18px] text-color-jet h-[25px] leading-[18px]'
							style={{ color: props.colorTertiary || props.colorPrimary }}
						>
							{modelName.modelName}
						</p>
						<p
							className={`recommendationCarV1__names-orSimilar mb-0 font-family-regular text-sm text-gray-500 h-[20px] pt-[3px] ${
								modelName.largeText ? 'relative top-[4px]' : ''
							}`}
						>
							{modelName.orSimilar}
							<span
								className={'recommendationCarV1__names-category font-family-bold ml-[5px]'}
								style={{ color: props.colorTertiary || props.colorPrimary }}
							>
								&#8226; {props.data.category}
							</span>
						</p>
					</div>

					<div className='recommendationCarV1__item order-3 mt-4 mb-1 flex align-center recommendationCarV1__extra'>
						{props.data.passengers && (
							<div className={'flex align-center mr-[20px] recommendationCarV1__flipTooltip'}>
								<ToolTip
									title={`<span class="icon passengerIco text-sm uppercase" style="color: ${
										props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'
									}"></span> <span class="text-color-jet">${props.data.passengers}</span>`}
									colorPrimary={props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'}
									body={`
                  <p class="text-xs whitespace-nowrap">
                    ${props.data.passengersTraduction || ''}
                  </p>
                  `}
								/>
							</div>
						)}

						{props.data.doors && (
							<div className={'flex align-center mr-[20px]  recommendationCarV1__flipTooltip'}>
								<ToolTip
									title={`<span class="icon doorIco text-sm uppercase" style="color: ${
										props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'
									}"></span><span class="text-color-jet"> ${props.data.doors}</span>`}
									colorPrimary={props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'}
									body={`
                  <p class="text-xs whitespace-nowrap">
                    ${props.data.doorsTraduction || ''}
                  </p>
                  `}
								/>
							</div>
						)}

						{!!props.data.carBaggage?.length && (
							<div className='flex align-center mr-[20px]'>
								<ToolTip
									title={`<span class="icon baggageIco text-sm uppercase" style="color: ${
										props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'
									}"></span> <span class="text-color-jet">${carBaggageCount}</div>`}
									colorPrimary={props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'}
									body={`
                  <p class="text-xs whitespace-nowrap">
                    ${props.data.baggageTraduction || ''}
                  </p>
                  `}
								/>
							</div>
						)}

						{props.data.airConditioning && (
							<div className='flex align-center mr-[20px]'>
								<ToolTip
									title={`<span class="icon airConditionIco text-sm  mr-1 uppercase" style="color: ${
										props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'
									}"></span> <span class="text-color-jet">${props.includeAirConditionText}</span>`}
									colorPrimary={props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'}
									body={`
                  <p class="text-xs whitespace-nowrap">
                    ${props.data.airTraduction || ''}
                  </p>
                  `}
								/>
							</div>
						)}

						<div className='flex align-center mr-[20px]'>
							<ToolTip
								title={`<span class="icon transmissionIco text-sm uppercase" style="color: ${
									props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'
								}"></span>
                ${props.data.transmmisionType === 'manual' ? '<span class="text-color-jet">MT</span>' : '<span class="text-color-jet">AT</span>'}`}
								colorPrimary={props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)'}
								body={`
                <p class="text-xs whitespace-nowrap">
                  ${props.data.transmissionTraduction || ''} : ${props.data.transmmisionType || ''}
                </p>
                `}
							/>
						</div>
					</div>

					<div className='recommendationCarV1__item order-5 recommendationCarV1__coverages'>
						<ul
							className={'flex flex-wrap flex-col h-[100px] recommendationCarV1__publicCoverages'}
							dangerouslySetInnerHTML={{ __html: coverages.toString() || '' }}
						></ul>
					</div>

					<div className='recommendationCarV1__item mt-[2px] order-6 recommendationCarV1__companyLogo'>
						<Image
							width={'76px'}
							height={'28px'}
							url={props.data.companyImageUrl || ''}
							styles={{
								maxWidth: '55px',
							}}
						/>
					</div>

					<div className='recommendationCarV1__item mt-[10px] pt-[10px]  border-t  border-gray-300 flex-row-reverse order-7 recommendationCarV1__actions flex justify-between'>
						<div className=''>
							{props.typePrice === TypePrice.onlyCash && (
								<div className='recommendationCarV1__price'>
									{!props.onlyDailyPrice ? (
										<>
											<p
												className='font-family-black text-xl text-right  mb-0'
												style={{ color: props.colorTertiary || props.colorPrimary || 'rgb(21, 19, 134)' }}
											>
												{numberToLocaleWithcurrency(totalRate, totalRateCurrency)} {totalRateCurrency}
											</p>
											<p className='font-family-regular text-gray-500 text-[13px] text-right'>
												{numberToLocaleWithcurrency(dailyRate || 0, dailyRateCurrency)} {totalRateCurrency} / {props.dailyRateText}
											</p>
										</>
									) : (
										<>
											<p className='text-right text-[20px] font-semibold mb-0' style={{ color: props.colorTertiary || 'rgb(21, 19, 134)' }}>
												{numberToLocaleWithcurrency(dailyRate || 0, dailyRateCurrency)}
												<span className='recommendationCarV1__mainCurrency hidden'>{totalRateCurrency}</span>
												<span className='recommendationCarV1__dollarSymbol  text-[13px] text-gray-500 relative top-[-4px] font-thin'>$</span>
											</p>
											<p className='font-family-regular text-gray-500 text-[13px] text-center'>
												{totalRateCurrency} / {props.dailyRateText}
											</p>
										</>
									)}
								</div>
							)}

							{props.typePrice === TypePrice.onlyPoints && (
								<div className='recommendationCarV1__price'>
									<p className='text-center font-family-black text-xl text-right  mb-0' style={{ color: props.colorPrimary || 'rgb(21, 19, 134)' }}>
										{numberToLocaleWithcurrency(props.data.pointsPlusMoney?.requiredPointsTotalRate || 0, '')} {props.data.pointsPlusMoney?.name}
									</p>
									<p className='text-center font-family-regular text-gray-500 text-[13px]'>
										{numberToLocaleWithcurrency(props.data.pointsPlusMoney?.requiredPointsDailyRate || 0, '')} {props.data.pointsPlusMoney?.name} /{' '}
										{props.dailyRateText}
									</p>
								</div>
							)}

							{props.typePrice === TypePrice.usePointsAndMoney && <div className='recommendationCarV1__price mb-0'>construction</div>}
						</div>

						<div className=''>
							<Button
								onClick={e => {
									if (props.onClick) {
										props?.onClick(props?.data);
									}
								}}
								text={props.buttonText}
								styles={{ backgroundColor: props.colorPrimary || 'rgb(21, 19, 134)' }}
								customClass={'recommendationCarV1__btn w-full text-white rounded-lg text-[15px]'}
							/>
						</div>
					</div>
				</div>
			) : (
				skeleton
			)}
		</div>
	);
}

export default RecommendationCarV1;
