import { faCalendar, faUser, faPassport, faLocationDot } from '@awesome.me/kit-dc2201b3ec/icons/classic/solid';
import { RoomNameHotelsSearchFormInterface } from '../../../../shared/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ElementTypes } from '../../../../../../shared/enums';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { Input } from '../../../../../ui-elements';
import { FC, useEffect, useState } from 'react';

export interface HotelsMobileSearchFormProps {
	traductions: (label: string) => string;
	watch: UseFormWatch<FieldValues>;
	showMobileFieldsContainerHandler: (show: boolean, fieldId: string, fieldName: string) => void;
	customClass?: string;
	destinationId: string;
	destinationName: string;
	isSecondaryTemplate: boolean;
	isTertiaryTemplate?: boolean;
	truncate?: boolean;
	colorSecondary: string;
	datesId: string;
	datesName: string;
	nationalitySelectionId: string;
	nationalitySelectionName: string;
	roomsNames: RoomNameHotelsSearchFormInterface[];
	displayFilterFields?: boolean;
}

export interface HotelsSearchMobileFields {
	label: string;
	idRef: string;
	nameRef: string;
	icon: string; // | IconDefinition;
	value: string;
	placeholder: string;
}

export const HotelsMobileSearchForm: FC<HotelsMobileSearchFormProps> = ({
	children,
	traductions,
	watch,
	showMobileFieldsContainerHandler,
	destinationId,
	destinationName,
	isSecondaryTemplate,
	isTertiaryTemplate,
	truncate,
	colorSecondary,
	datesId,
	datesName,
	nationalitySelectionId,
	nationalitySelectionName,
	roomsNames,
	customClass,
	displayFilterFields,
}) => {
	const firstElement: number = 0;
	const [destinationValue, setDestinationValue] = useState<string>('');
	const [datesValue, setDatesValue] = useState<string>('');
	const [nationalitySelectionValue, setNationalitySelectionValue] = useState<string>('');
	const [roomsValue, setRoomsValue] = useState<string>('');
	const handleClick = (id: string, name: string): void => {
		showMobileFieldsContainerHandler(true, id, name);
	};

	const destinationInputValue: string = watch(destinationName);
	const datesInputValue: string = watch(datesName);
	const nationalitySelectionInputValue: string = watch(nationalitySelectionName);
	const roomsInputValue: string = watch(roomsNames[firstElement].name);
	useEffect(() => {
		setDestinationValue(destinationInputValue);

		setDatesValue(datesInputValue);

		setNationalitySelectionValue(nationalitySelectionInputValue);

		setRoomsValue(roomsInputValue);
	}, [roomsNames, destinationName, destinationInputValue, datesInputValue, nationalitySelectionInputValue, roomsInputValue]);

	const fields: HotelsSearchMobileFields[] = [
		{
			label: traductions('destination') || 'destination',
			value: destinationValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconLightLocationDotSecondary';
					case isTertiaryTemplate:
						return 'pinIco';
					default:
						return faLocationDot;
				}
			})(),
			placeholder: isSecondaryTemplate ? traductions('selectADestination') || 'selectADestination' : traductions('destination') || 'destination',
			idRef: destinationId,
			nameRef: destinationName,
		},
		{
			label: traductions('labelDates') || 'labelDates',
			value: datesValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconLightCalendarSecondary';
					case isTertiaryTemplate:
						return 'iconCalendarDepartureTertiary';
					default:
						return faCalendar;
				}
			})(),
			placeholder: isSecondaryTemplate ? traductions('selectAnOption') || 'selectAnOption' : traductions('labelDates') || 'labelDates',
			idRef: datesId,
			nameRef: datesName,
		},
		...(displayFilterFields
			? [
				{
					label: traductions('selectYourNationality') || 'selectYourNationality',
					value: nationalitySelectionValue ?? '',
					icon: isSecondaryTemplate ? 'iconPassportSecondary' : faPassport,
					placeholder: traductions('selectYourNationality') || 'selectYourNationality',
					idRef: nationalitySelectionId,
					nameRef: nationalitySelectionName,
				},
			  ]
			: []),
		{
			label: isSecondaryTemplate ? traductions('guests') || 'guests' : traductions('rooms') || 'rooms',
			value: roomsValue ?? '',
			icon: isSecondaryTemplate ? 'iconLightUserSecondary' : faUser,
			placeholder: isSecondaryTemplate
				? traductions('selectYourNationality') || 'selectYourNationality'
				: traductions('searchYourCountry') || 'searchYourCountry',
			idRef: roomsNames[firstElement].id,
			nameRef: roomsNames[firstElement].name,
		},
	];

	return (
		<div className={`flex flex-col gap-4 ${customClass ?? ''}`}>
			<div className='flex flex-col gap-2'>
				{fields.map((field: HotelsSearchMobileFields) => (
					<div
						key={field.idRef}
						className={`flex 
						${isSecondaryTemplate ? 'flex-col gap-1' : 'border bg-white gap-2 px-4 py-3 border-rad border-[#696969]'}
						${isTertiaryTemplate ? 'rounded-none border-[#BEBEBE]' : ''}
						${!isTertiaryTemplate && !isSecondaryTemplate ? 'rounded-lg' : ''}
					`}
					>
						{isSecondaryTemplate && (
							<div className='text-sm font-medium'>
								<span className='field__labelText'>{field.label}</span>
							</div>
						)}
						{!isSecondaryTemplate && (
							<div className='w-[24px]'>
								{typeof field.icon === 'string' ? (
									<span
										className={`${field.icon} text-base
										${isTertiaryTemplate ? 'text-[#4D4D4F] flex justify-center' : ''}`}
									></span>
								) : (
									<FontAwesomeIcon
										data-testid='icon'
										icon={field.icon}
										width={24}
										height={24}
										fixedWidth={true}
										color={isTertiaryTemplate ? '#4D4D4F' : colorSecondary}
										fontSize={16}
									/>
								)}
							</div>
						)}
						<Input
							value={field.value}
							readonly={true}
							icon={isSecondaryTemplate ? field.icon : ''}
							iconClassName={'text-[#98A2B3]'}
							type={ElementTypes.text}
							placeholder={field.placeholder}
							isSecondaryTemplate={isSecondaryTemplate}
							isTertiaryTemplate={isTertiaryTemplate}
							className={`destinationField__input cursor-pointer ${
								isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'
							}  flex-grow`}
							inputClassName={`bg-transparent !p-0 font-normal md md:placeholder placeholder:font-normal placeholder:text-[#696969]
                ${isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base'}
                ${isTertiaryTemplate ? '!text-[14px] !text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
              `}
							autocomplete={'off'}
							style={{
								color: '#696969',
							}}
							truncate={truncate}
							onClick={() => handleClick(field.idRef, field.nameRef)}
						/>
					</div>
				))}
			</div>
			{children}
		</div>
	);
};
