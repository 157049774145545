import { PriceMonthList } from '../../../../../shared/interfaces/price-month';
import Button from '../../../../ui-elements/button/button';
import { useState, useEffect } from 'react';

export interface PriceMonthProps {
	styles?: any;
	customClass?: string;
	onClick?: (data?: PriceMonthList) => void;
	monthsList: PriceMonthList[];
}

type HTMLElementEvent<T extends HTMLElement> = Event & {
	target: T;
};

export function PriceMonth(props: PriceMonthProps): JSX.Element {
	const [skeleton, setIsSkeleton] = useState<JSX.Element[]>([]);
	const [monthList, setMonthList] = useState<JSX.Element[]>([]);
	useEffect(() => {
		createskeleton();
	}, []);

	useEffect(() => {
		const arrayMonths: any[] = [];
		props?.monthsList.forEach((item: PriceMonthList, index: number) => {
			const monthButton = (
				<Button
					key={index}
					onClick={e => selectMonth(item, e)}
					text={`<p class="font-family-black text-2xl pointer-events-none">${item.month.substring(0, 3)}</p>
              <p class="font-family-bold text-base pointer-events-none">${item.value} <span class="font-family-regular">${item.currency}</span></p>`}
					customClass={'h-24 w-24 rounded-xl flex-col m-2 bg-transparent text-gray-600 border border-gray-600 items-center'}
				/>
			);

			arrayMonths.push(monthButton);
		});

		setMonthList(arrayMonths);
	}, [props.monthsList]);

	const emitSelectedMonth = (priceMonthSelected?: PriceMonthList): void => {
		if (!props.onClick) {
			return;
		}

		props.onClick(priceMonthSelected);
	};

	const createskeleton = (): void => {
		const arraySkeleton: JSX.Element[] = [];
		[...Array(7).keys()].forEach((item, index: number) => {
			const monthButton: JSX.Element = (
				<div key={index}>
					<Button
						text={`<p class="h-4 bg-gray-300 rounded w-12 mb-2"></p>
              <p class="h-4 bg-gray-300 rounded w-3/4"></p>`}
						customClass={'animate-pulse px-1 h-24 w-24 rounded-xl flex-col m-2 bg-transparent border border-gray-300 items-center'}
					/>
				</div>
			);

			arraySkeleton.push(monthButton);
		});

		setIsSkeleton(arraySkeleton);
	};

	const selectMonth = (item: PriceMonthList, element: HTMLElementEvent<HTMLButtonElement>): void => {
		unselectMonths();

		element.target.classList.add(...['priceMonth__selected', 'bg-primary', 'text-white']);

		emitSelectedMonth(item);
	};

	const unselectMonths = (): void => {
		document.querySelectorAll('.priceMonth__selected').forEach(item => {
			item.classList.remove(...['priceMonth__selected', 'bg-primary', 'text-white']);
		});
	};

	return (
		<div
			id='priceMonth'
			className='priceMonth flex align-center lg:justify-center max-w-7xl md:max-w-none overflow-x-auto p-10 pt-0 pl-0 md:p-16 md:pt-0'
		>
			<Button
				onClick={() => {
					emitSelectedMonth();

					unselectMonths();
				}}
				text={'<p class="font-family-black">Ver</p><p class="font-family-black">Todo</p>'}
				customClass={'h-24 w-24 rounded-xl flex-col m-2 items-center text-white bg-indigo-600'}
				styles={{ ...props.styles }}
			/>
			{props.monthsList.length ? monthList : skeleton}
		</div>
	);
}

export default PriceMonth;
