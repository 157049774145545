export function SpecialEventSkeletonRecommendation(): JSX.Element {
	return (
		<>
			<div className='specialEventRecommendation__card rounded-lg pb-[16px] bg-white shadow-lg animate-pulse mb-[25px]'>
				<div className={'specialEventRecommendation__cardContainer relative flex flex-col justify-between h-full'}>
					<div className='specialEventRecommendation__head bg-[#CCD0D6] rounded-tl-lg rounded-tr-lg'>
						<div className='specialEventRecommendation__headContent  px-[20px] md:px-[32px] flex items-center'>
							<div className='specialEventRecommendation__headTitle bg-gray-200 h-[60px] w-[30%] specialEventRecommendation__bold text-[30px]'></div>
						</div>
					</div>

					<div className='specialEventRecommendation__body pt-[29px] px-[20px] md:px-[32px]'>
						<div className='specialEventRecommendation__row flex flex-col md:flex-row justify-between items-center mb-[13px]'>
							<p className='specialEventRecommendation__infoTitle bg-gray-200 w-[40%] h-[20px] order-2 md:order'></p>

							<p className='specialEventRecommendation__moreInfo bg-gray-200 w-[10%] h-[20px] cursor-pointer text-base order md:order-2 '></p>
						</div>

						<div className='specialEventRecommendation__row flex flex-col md:flex-row items-center mb-[13px]'>
							<div className='specialEventRecommendation__datesFrom w-[40%] flex items-center md:mr-6'>
								<p className='specialEventRecommendation__bold bg-gray-200 mr-2 w-[40%] h-[20px]'></p>

								<p className='specialEventRecommendation__date bg-gray-200 w-[40%] h-[20px]'></p>
							</div>

							<div className='specialEventRecommendation__datesFrom w-[40%] flex items-center md:mr-6'>
								<p className='specialEventRecommendation__bold bg-gray-200 mr-2 w-[40%] h-[20px]'></p>

								<p className='specialEventRecommendation__date bg-gray-200 w-[40%] h-[20px]'></p>
							</div>
						</div>

						<div className='specialEventRecommendation__row flex justify-center md:justify-start items-center mb-[13px]'>
							<p className='specialEventRecommendation__bold text-center bg-gray-200 w-[25%] h-[20px]'></p>
						</div>
					</div>

					<div className='specialEventRecommendation__footer mt-[10px] pt-[20px] px-[32px] border-t-4 border-[#ff0080] border-dotted flex flex-col lg:flex-row items-center justify-between'>
						<div className='specialEventRecommendation__prices w-full lg:w-[75%] mb-4 lg:mr-8'>
							<div className='h-[20px] w-[40%] bg-gray-200 mb-4'></div>
							<div className='h-[20px] w-[40%] bg-gray-200'></div>
						</div>

						<div className='specialEventRecommendation__callToAction w-full lg:w-[25%]'>
							<div className='h-[40px] w-full bg-gray-200 rounded-lg'></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SpecialEventSkeletonRecommendation;
