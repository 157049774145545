import styles from './car-search-mobile-button.module.scss';
import { Button } from '../../../../../ui-elements';

export interface CarSearchMobileButtonProps {
	btnText?: string;
	colorPrimary?: string;
	colorSecondary?: string;
	content?: JSX.Element | null;
}

export function CarSearchMobileButton({ colorPrimary, colorSecondary, btnText, content }: CarSearchMobileButtonProps): JSX.Element {
	let renderContent: JSX.Element | null = null;
	if (content) {
		renderContent = content;
	} else {
		renderContent = <p className='text-sm text-left pl-[10px]'>{btnText || 'searchText'}</p>;
	}

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<Button
				customClass={'w-full h-[47px] rounded-full py-1 carSearchForm__mobileBtn bg-white carSearchMobileButton__mobileBtn'}
				content={
					<div className='w-full h-full bg-white flex items-center relative'>
						<div className='carSearchForm__mobileContent w-full flex-grow'>{renderContent}</div>
						<div className=''>
							<span
								className='searchIco rounded-full p-1.5 w-10 h-10 block'
								style={{
									background: colorSecondary,
								}}
							></span>
						</div>
					</div>
				}
			/>
		</>
	);
}
export default CarSearchMobileButton;
