import CreditCardConsulted from '../../ui-elements/credit-card-consulted/credit-card-consulted';
import { CreditCardUserInterface } from '../../../shared/interfaces/credit-card-user.interface';
import { HttpResponseCodeEnum } from '../../../shared/enums/http-response-code.enum';
import { numberToLocaleWithcurrency } from '../../../shared/services/utils.service';
import AresButton from '../../ui-elements/ares-button/ares-button';
import BackDrop from '../../ui-elements/backdrop/backdrop';
import { Image } from '../../ui-elements/image/image';
import styles from './card-points-result.module.scss';

export interface CardPointsResultProps {
	franchise: string;
	cardNumber?: string;
	error: boolean | null;
	statusCode?: number | null;
	creditCardUser: CreditCardUserInterface | null;
	userCurrency: string;
	openForm: () => void;
	onCloseResult: () => void;
	t: (label: string) => string;
}

export function CardPointsResult({
	error,
	statusCode,
	creditCardUser,
	onCloseResult,
	openForm,
	t,
	franchise,
	cardNumber,
	userCurrency,
}: Readonly<CardPointsResultProps>): JSX.Element {
	const imageUrl: string = !error
		? 'https://res.cloudinary.com/ultragroup/image/upload/v1707497421/z1d9u6w6d4zouhamxmlq.png'
		: 'https://res.cloudinary.com/ultragroup/image/upload/v1707497420/tfjjmwjljng94qcom3co.png';

	const successBody = (): JSX.Element => {
		return (
			<div className='points-consultation__result--success mt-[40px] flex flex-col justify-center'>
				<div className='points-consultation__result--mobile grid grid-cols-2 px-[35px]'>
					<CreditCardConsulted creditCardUser={creditCardUser} t={t} franchise={franchise} cardNumber={cardNumber ?? ''} />
					<div className='flex flex-col text-end'>
						<p className='text-[24px] text-[#000000] font-medium'>{numberToLocaleWithcurrency(creditCardUser?.points?.value ?? 0)}</p>
						<p className='text-[14px] text-[#2B2B2B]'>{`${userCurrency} ${t('availableKey')}`}</p>
					</div>
				</div>
				<div className='points-consultation__btn--container flex flex-row justify-end gap-[15px] px-[35px] mt-[64px]'>
					<AresButton text={t('consultAnotherCard')} className='w-[175px] h-[45px]' isSecondary={true} openForm={openForm} />
					<AresButton text={t('ready')} className='w-[175px] h-[45px]' onCloseResult={onCloseResult} />
				</div>
			</div>
		);
	};

	const errorBody = (): JSX.Element => {
		return (
			<div className='points-consultation-error mt-[20px] flex flex-col justify-center'>
				<p className='points-consultation-error__text text-[16px] text-[#000000] w-[507px] px-[48px]'>
					<span className='card-points-result__title'>{t('verifyInformation')}</span> {<br />}
					<span className='card-points-result__sub-title pl-[10px]'>{t('verifyStatusCard')}</span>
					{<br />} <span className='card-points-result__sub-title pl-[10px]'>{t('validateCardAffiliated')}</span>
					{<br />} {<br />} <span className='font-medium card-points-result__sub-title'>{t('checkCardAgain')}</span>
					<span className='card-points-result__sub-title'>{t('contactCustomer')}</span>
				</p>
				<div className='points-consultation__btn--container  flex flex-row justify-end gap-[15px] px-[35px] mt-[34px] mb-[25px]'>
					<AresButton text={t('close')} className='w-[190px] h-[45px]' isSecondary={true} onCloseResult={onCloseResult} />
					<AresButton text={t('consultAnotherCard')} className='w-[190px] h-[45px]' openForm={openForm} />
				</div>
			</div>
		);
	};

	const errorBinBody = (): JSX.Element => {
		return (
			<div className='points-consultation-error mt-[40px] flex flex-col justify-center'>
				<p className='points-consultation-error__text text-[16px] text-[#000000] w-[507px] px-[48px] text-center self-center'>
					{t('toConsultPoints')} {<br />} <span className='font-medium'>{t('mustEnterData')}</span>
				</p>
				<div className='points-consultation__btn--container  flex flex-row justify-end gap-[15px] px-[35px] mt-[34px]'>
					<AresButton text={t('close')} className='w-[190px] h-[45px]' isSecondary={true} onCloseResult={onCloseResult} />
					<AresButton text={t('consultAnotherCard')} className='w-[190px] h-[45px]' openForm={openForm} />
				</div>
			</div>
		);
	};

	let body: JSX.Element;
	let isLargeSize: boolean = false;

	if (error && statusCode === HttpResponseCodeEnum.ProcessableEntity) {
		body = errorBody();

		isLargeSize = true;
	} else if (statusCode === HttpResponseCodeEnum.NotFound) {
		body = errorBinBody();
	} else {
		body = successBody();
	}

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<BackDrop show={true} backgroundColor={'#060708'} opacity={0.85} className='z-50' />
			<div className={`points-consultation__result w-[603px] ${!isLargeSize ? 'h-[399px]' : ''} bg-white z-50`}>
				<button onClick={onCloseResult} className='absolute top-[25px] right-[24px] text-[#858383] font-bold'>
					X
				</button>
				<Image url={imageUrl} customClass='img-size' />
				{isLargeSize ? (
					<div>
						<div className='card-points-result__container--message-error'>
							<img
								className='card-points-result__error-icon'
								src='https://res.cloudinary.com/ultragroup/image/upload/v1724168255/qfbiwc7bipvtfosbcjzf.svg'
								alt='Error Icon'
							/>
							<span className='card-points-result__text-points'>{t('unableVerifyCard')}</span>
						</div>
						<hr />
					</div>
				) : null}

				{body}
			</div>
		</>
	);
}

export default CardPointsResult;
