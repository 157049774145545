import { CreditCardsInterface } from '../../../shared/interfaces/generics/credit-cards.interface';
import { Backdrop, Button } from '../../../lib/ui-elements';
import styles from './credit-card-selector.module.scss';
import CreditCards from '../credit-cards/credit-cards';
import { useEffect } from 'react';

export interface CreditCardSelectorProps {
	closeCallback?: (creditCard?: CreditCardsInterface) => void;
	creditCards: [];
	title: string;
	subTitle: string;
	description: string;
	colorPrimary?: string;
	t: (value: string) => string;
	show: boolean;
	urlExternalRedirect?: string;
}

export function CreditCardSelector({
	closeCallback,
	creditCards,
	title,
	subTitle,
	description,
	colorPrimary,
	t,
	show,
	urlExternalRedirect,
}: CreditCardSelectorProps): JSX.Element {
	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}
	}, [show]);

	const selectedCreditCard = (creditCard: CreditCardsInterface): void => {
		closeCallback && closeCallback(creditCard);
	};

	const addNewCreditCard = (url: string): void => {
		window.location.href = url;
	};

	return (
		<>
			<Backdrop show={show} onClick={() => closeCallback && closeCallback()} zIndex={80} backgroundColor={colorPrimary} opacity={0.5} />
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`${
					!show ? 'hidden' : ''
				} credit-card-selector z-[100] flex justify-end fixed right-0 top-0 w-full md:max-w-[450px] max-h-[100vh] bg-white scroll-smooth overflow-y-auto`}
			>
				<div className='credit-card-selector__container md:px-6'>
					<div className='pb-12 pt-6'>
						<span onClick={() => closeCallback && closeCallback()} className='closeIco cursor-pointer text-gray-500 text-[20px] !ml-[15px]'></span>
					</div>

					<div className='credit-card-selector__introduction px-2 !ml-[10px]'>
						<p className='credit-card-selector__title text-[40px] leading-none mb-4'>{title}</p>
						<p className='credit-card-selector__subtitle text-[21px] leading-none mb-4'>{subTitle}</p>
						<p className='credit-card-selector__description mb-4' dangerouslySetInnerHTML={{ __html: description || '' }}></p>
					</div>

					<div className={`credit-card-selector__cards h-[460px] bg-gray ${creditCards?.length > 2 ? 'scroll-smooth overflow-y-scroll' : ''}`}>
						<div>
							<CreditCards
								callback={creditCard => creditCard && selectedCreditCard(creditCard)}
								creditCards={creditCards}
								addCreditCardText={t('addCreditCardText')}
							/>
						</div>
					</div>

					<div className='credit-card-selector__actions px-2'>
						{urlExternalRedirect ? (
							<Button
								text={t('addCard')}
								customClass='credit-card-selector__addMore text-white rounded-lg mb-4 w-full'
								styles={{ background: colorPrimary }}
								onClick={() => addNewCreditCard(urlExternalRedirect)}
							/>
						) : (
							<Button
								text={t('addCard')}
								customClass='credit-card-selector__addMore text-white rounded-lg mb-4 w-full'
								styles={{ background: colorPrimary }}
							/>
						)}
						<Button text={t('goToProfile')} customClass='credit-card-selector__goProfile bg-transparent rounded-lg border-2 w-full' />

						<div className='h-[20px]'></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CreditCardSelector;
