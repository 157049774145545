import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import { Backdrop } from '../../ui-elements/backdrop/backdrop';
import Image from '../../ui-elements/image/image';
import styles from './country-select.module.scss';
import { useEffect } from 'react';
export interface CountrySelectProps {
	countryFlagUrl: string;
	countryList: Record<string, string>;
	selectedCountry: string;
	showCountryList: boolean;
	countryListUrls: Record<string, string>;
	setSelectedCountry: (countryCode: string) => void;
	setShowCountryList: (value: boolean) => void;
}

export function CountrySelect({
	countryFlagUrl,
	countryList,
	selectedCountry,
	showCountryList,
	countryListUrls,
	setSelectedCountry,
	setShowCountryList,
}: Readonly<CountrySelectProps>): JSX.Element {
	const optionFlagsSrc = '/static-images/';
	useEffect(() => {
		const handleScroll = (): void => {
			if (window.innerWidth <= 992) {
				setShowCountryList(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='relative' onClick={() => setShowCountryList(!showCountryList)} role='button'>
				<div className='ares-header__country-nav flex gap-[4px]'>
					<Image url={countryFlagUrl} />
					<span className='flex flex-row justify-center items-center gap-[6px]'>
						<span className='font-semibold text-[#4D4D4F]' data-testid='selectedCountry'>
							{selectedCountry}
						</span>{' '}
						<IcomoonIcon icon={'iconArrowDownCircle'} fontSize={20} color='#4D4D4F' />
					</span>
				</div>

				<div className={`header-ares__dropdown absolute bg-white right-0 mt-3 z-50 cursor-pointer ${showCountryList ? '' : 'hidden'}`}>
					<Backdrop show={showCountryList} />
					<ul className='relative z-60 divide-y-2 shadow-md'>
						{Object.keys(countryList).map(countryCode => (
							<li
								key={countryCode}
								onClick={() => setSelectedCountry(countryCode)}
								className={`w-[170px] text-center py-[15px] px-[15px] text-black ${countryCode === selectedCountry ? 'font-semibold' : ''}`}
							>
								<div className='grid grid-cols-3 items-center gap-2'>
									<Image width='35' height='35' url={`${optionFlagsSrc}${countryCode}.png`} />
									<span className='col-span-2 justify-self-start'>{countryList[countryCode]}</span>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</>
	);
}

export default CountrySelect;
