import { PassengersFieldRestrictionsInterface, ListItemInterface, AssistancesSearchFormSubmitInterface } from '@smartlinks/react-design-system';
import { AssistanceDestinations } from '../interfaces/assistance-destinations.interface';
import { CalendarRestrictionTypes } from '../enums/calendar-restrictions.enum';
import { Agency, CalendarRestrictions } from '../interfaces/agency.interface';

export class AssistancesUtil {
	public static passengersRestrictions(
		maxChildrenAdults: number,
		maxOlderAdults: number,
		minimunAdults?: number,
	): PassengersFieldRestrictionsInterface {
		return {
			infants: {
				default: 0,
				minimum: 0,
				maximum: 0,
			},
			children: {
				default: 0,
				minimum: 0,
				maximum: maxOlderAdults,
			},
			adults: {
				default: 1,
				minimum: minimunAdults || 0,
				maximum: maxChildrenAdults,
			},
		};
	}

	public static calendarRestriction(agency?: Agency | null): number {
		return (
			agency?.calendarRestrictions?.find((restriction: CalendarRestrictions) => restriction.product === CalendarRestrictionTypes.assistances)
				?.value || 0
		);
	}

	public static mapLocationsList(locations: AssistanceDestinations[]): ListItemInterface[] {
		const locationsList: ListItemInterface[] = [];
		locations.forEach((location: AssistanceDestinations) => {
			const mapLocation: ListItemInterface = {
				name: <span className='text-xs'>{location.description}</span>,
				originalName: location.name,
				id: location.code,
				value: location.code,
				link: '',
			};

			locationsList.push({ ...mapLocation });
		});

		return [...locationsList];
	}

	public static mapSearchUrl(data: AssistancesSearchFormSubmitInterface): string {
		const promoCode: string = data.promoCode;
		const queryPromoCode: string = promoCode.length ? `&promoCode=${promoCode || ''}` : '';
		const searchUrl: string = `assistance/search/${data?.departureDate || ''}/${data?.arrivalDate || ''}/${
			data?.destinationCode?.toLowerCase() || ''
		}/0/0-${data?.passengers?.childrenAdults || 0}-${data?.passengers?.olderAdults || 0}-0/0${queryPromoCode}`;

		return searchUrl;
	}
}
