import { capitalize } from '../../../../../../shared/services/utils.service';
import { Accordion } from '../../../../../ui-elements/accordion/accordion';
import { Customer } from '../../../../../../shared/interfaces/customer';
import { TableData } from '../../../../../../shared/interfaces/table';
import Table from '../../../../../ui-elements/table/table';
import styles from './flight-customer.module.scss';
import { useMemo } from 'react';

export interface FlightCustomerProps {
	nameHead: string;
	documentHead: string;
	phoneNumberHead: string;
	emailHead: string;
	customer: Customer;
	title: string;
	className?: string;
}

export const FlightCustomer = (props: FlightCustomerProps): JSX.Element => {
	const nameLabel: string = 'name';
	const documentLabel: string = 'document';
	const phoneNumberLabel: string = 'phoneNumber';
	const emailLabel: string = 'email';
	const customerContent = useMemo<JSX.Element>(() => {
		const data: TableData = {
			head: [
				{ value: props.nameHead, label: nameLabel },
				{ value: props.documentHead, label: documentLabel },
				{ value: props.phoneNumberHead, label: phoneNumberLabel },
				{ value: props.emailHead, label: emailLabel },
			],
			rows: [
				[
					{
						value: capitalize(`${props.customer.name} ${props.customer?.lastName || ''}`),
						label: nameLabel,
					},
					{ value: props.customer?.customerId || '', label: documentLabel },
					{ value: props.customer?.phoneNumber || '', label: phoneNumberLabel },
					{ value: props.customer?.emailAddress || '', label: emailLabel },
				],
			],
		};

		return (
			<div className='w-full px-4 pb-6'>
				<Table data={data} className={'w-full CustomerDataMock table-auto mx-auto flightCustomer__table'} />
			</div>
		);
	}, [
		props.customer,
		props.nameHead,
		props.documentHead,
		props.phoneNumberHead,
		props.emailHead,
		nameLabel,
		documentLabel,
		phoneNumberLabel,
		emailLabel,
	]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<Accordion
				title={props.title.toLocaleUpperCase()}
				content={customerContent}
				titleCustomClass={'text-lg leading-6 font-medium text-color-primary'}
				className={props.className}
			/>
		</>
	);
};

FlightCustomer.defaultProps = {
	className: '',
};

export default FlightCustomer;
