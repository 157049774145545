import { HotelsDisneyResumeAsideService } from '../services/hotel-disney-resume-aside.service';
import { WidgetCommonInterface, ResponseCommons } from '../interfaces/commons.interface';
import { HotelsDisneyService } from '../services/hotels-disney.service';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { AssistancesService } from '../services/assistances.service';
import { ActivitiesService } from '../services/activities.service';
import { FlightsService } from '../services/flights.service';
import { HotelsService } from '../services/hotels.service';
import { PlansService } from '../services/plans.service';
import { DisneyService } from '../services/disney.service';
import { Agency } from '../interfaces/agency.interface';
import { CarService } from '../services/cars.service';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface UseCommonWidgetInterface<
	T extends
	| CarService
	| FlightsService
	| ActivitiesService
	| HotelsService
	| AssistancesService
	| PlansService
	| DisneyService
	| HotelsDisneyService
	| HotelsDisneyResumeAsideService,
> extends WidgetCommonInterface {
	className?: string;
	styles?: string;
	WidgetServiceClass: new (token: string, customHistoryName?: LocalStorageHistoriesNames | undefined) => T;
	customHistoryName?: LocalStorageHistoriesNames;
}

export interface UseCommonWidgetReturnInterface<
	T extends
	| CarService
	| FlightsService
	| ActivitiesService
	| HotelsService
	| AssistancesService
	| PlansService
	| DisneyService
	| HotelsDisneyService
	| HotelsDisneyResumeAsideService,
> {
	agencyState: Agency | null;
	isLoading: boolean;
	agencyError: boolean;
	widgetService: T;
	widgetStyles: JSX.Element;
	t: (label?: string) => string;
}

const useCommonWidget = <
	T extends
	| CarService
	| FlightsService
	| ActivitiesService
	| HotelsService
	| AssistancesService
	| PlansService
	| DisneyService
	| HotelsDisneyService
	| HotelsDisneyResumeAsideService,
>({
	token,
	language,
	agency,
	className,
	styles,
	WidgetServiceClass,
	customHistoryName,
}: UseCommonWidgetInterface<T>): UseCommonWidgetReturnInterface<T> => {
	const [agencyState, setAgencyState] = useState<Agency | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isTranslationLoading, setIsTranslationLoading] = useState<boolean>(true);
	const [agencyError, setAgencyErrore] = useState<boolean>(false);
	const { t, i18n } = useTranslation();
	const widgetService: T = useMemo(
		() =>
			((tempToken?: string): T => {
				if (customHistoryName) {
					console.log('customHistoryName', customHistoryName);

					return new WidgetServiceClass(tempToken || '', customHistoryName);
				}

				return new WidgetServiceClass(tempToken || '');
			})(token),
		[token],
	);

	const widgetStyles: JSX.Element = useMemo(
		() =>
			((tempAgency: Agency | null, tempClassName?: string, tempStyles?: string): JSX.Element => {
				return (
					<style
						dangerouslySetInnerHTML={{
							__html: `
        ${
					tempClassName
						? `.${tempClassName} {
            --${tempClassName}-color-primary: ${tempAgency?.lookAndFeel?.style?.primaryColor || '#000000'};
            --${tempClassName}-color-secondary: ${tempAgency?.lookAndFeel?.style?.secondaryColor || '#ffffff'};
          }`
						: ''
					}
        ${tempStyles || ''}
        `,
						}}
					/>
				);
			})(agencyState, className, styles),
		[agencyState, className, styles],
	);

	useEffect(() => {
		setIsLoading(true);

		void (async (): Promise<void> => {
			const agencyCall: ResponseCommons<Agency | null> = await widgetService.callAgency(agency);
			if (agencyCall?.response != null && !agencyCall?.error) {
				setAgencyState({ ...agencyCall.response });

				setTranlationsCustom(agencyCall.response);
			} else {
				setAgencyErrore(true);

				setAgencyState(null);
			}

			setIsLoading(false);
		})();
	}, [widgetService, agency]);

	const setTranlationsCustom = (agency: Agency): void => {
		if (language) {
			i18n.addResourceBundle(language, 'translation', agency.customTranslations, true, true);

			void i18n.changeLanguage(language);
		}
	};

	useEffect(() => {
		if (language) {
			void i18n.changeLanguage(language);
		}

		if (language) {
			setIsTranslationLoading(true);

			i18n.changeLanguage(language).finally(() => {
				setIsTranslationLoading(false);
			});
		} else {
			setIsTranslationLoading(false);
		}
	}, [language]);

	return {
		agencyState,
		isLoading: isLoading || isTranslationLoading,
		agencyError,
		widgetService,
		widgetStyles,
		t,
	};
};

export default useCommonWidget;
