import { HistoricalDetailData, HistoricalDetailProductCar, HistoricalLocation } from '../../../../shared/interfaces';
import HistoricalItemInfo from './historical-item-info';

export interface HistoricalProductCarProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	historicalDetail: HistoricalDetailData<HistoricalDetailProductCar>;
}

export function HistoricalProductCar({ t, colorPrimary, colorSecondary, historicalDetail }: HistoricalProductCarProps): JSX.Element {
	const HistoricalLocation = ({ userHistoricalLocation, title }: { userHistoricalLocation: HistoricalLocation; title: string }): JSX.Element => {
		return (
			<>
				<h3 className='historical-detail__locationTitle mr-1' style={{ color: colorPrimary }}>
					{t(title)}:
				</h3>
				<HistoricalItemInfo
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					customClass='historical-detail__place'
					isIcon={true}
					classIcon='searchLocationIco'
					title='place'
					value={userHistoricalLocation.location}
				/>
				<HistoricalItemInfo
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					customClass='historical-detail__date'
					isIcon={true}
					classIcon='calendarBlankIco'
					title='date'
					value={userHistoricalLocation.date}
				/>
				<HistoricalItemInfo
					colorPrimary={colorPrimary}
					colorSecondary={colorSecondary}
					t={t}
					customClass='historical-detail__entryTime'
					isIcon={true}
					classIcon='calendarBlankIco'
					title='entryTime'
					value={userHistoricalLocation.time}
				/>
				<div className='historical-detail__split my-4' />
			</>
		);
	};

	return (
		<>
			<div className='historical-detail__split my-8' />
			<HistoricalLocation title='pickup' userHistoricalLocation={historicalDetail.detail.pickUp} />
			<HistoricalLocation title='dropOff' userHistoricalLocation={historicalDetail.detail.dropOff} />
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__company'
				isIcon={false}
				classIcon='personIco'
				title='company'
				value={historicalDetail.detail.rentalCarCompany}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__model'
				isIcon={false}
				classIcon='personIco'
				title='model'
				value={historicalDetail.detail.carModel}
			/>
			<div className={'historical-detail__coverage flex flex-col flex-wrap text-[14px] mt-6 text-gray-500'}>
				<span className='historical-detail__coverageTitle mr-1' style={{ color: colorPrimary }}>
					{t('coverage')}:
				</span>
				<ul className='historical-detail__coverage--list'>
					{historicalDetail.detail.coverages.map((coverage: string, index: number) => (
						<li key={index}>{coverage}</li>
					))}
				</ul>
			</div>
			<div className='historical-detail__split my-3' />
		</>
	);
}

export default HistoricalProductCar;
