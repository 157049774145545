import PopUpDialog, { PopUpDialogProps } from '../../../../shared-components/pop-up-dialog/pop-up-dialog';
import { PopUpDialogEventInterface } from '../../../../../shared/interfaces/pop-up-dialog.interface';
import Backdrop, { BackdropProps } from '../../../../ui-elements/backdrop/backdrop';
import { AlertEventResponses } from '../../../../../shared/enums/alert.enum';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import { useRef, useEffect, useState } from 'react';
import styles from './modal.module.scss';

export interface ModalProps extends BackdropProps, PopUpDialogProps {
	width: number;
	top?: string;
	className?: string;
	extraZIndex?: number;
	onBackdropClick?: (data: PopUpDialogEventInterface) => void;
}

export const Modal = ({
	idComponent,
	title,
	description,
	showStateIcon,
	state,
	okButtonText,
	cancelButtonText,
	show,
	showCancelButton,
	showCloseButton,
	children,
	onNextClick,
	onPreviousClick,
	onCloseClick,
	onBackdropClick,
	width,
	top,
	backgroundColor,
	opacity,
	closeIconSize,
	zIndex,
	extraZIndex,
	className,
	closeIconClassName,
}: ModalProps): JSX.Element => {
	const refContainer = useRef<HTMLDivElement>(null);
	const responsiveSize: number = 768;
	const half: number = 2;
	const windowWidth: number = useWindowWidth();
	const [lineStyleState, setLineStyleState] = useState<React.CSSProperties>({
		width: windowWidth < responsiveSize ? '100%' : `${width}px`,
		left: windowWidth < responsiveSize ? '0' : `calc(50% - ${width / half}px)`,
		top: windowWidth < responsiveSize ? undefined : top || '50%',
		bottom: windowWidth < responsiveSize ? '0' : undefined,
		transform: show ? 'translateY(0)' : 'translateY(-100vh)',
		opacity: show ? '1' : '0',
		zIndex: `${Number(zIndex) + (extraZIndex || 10)}`,
		borderBottomRightRadius: windowWidth < responsiveSize ? '0' : undefined,
		borderBottomLeftRadius: windowWidth < responsiveSize ? '0' : undefined,
	});

	useEffect(() => {
		if (refContainer.current) {
			setLineStyleState((prevState: React.CSSProperties): React.CSSProperties => {
				return {
					...prevState,
					marginTop:
						windowWidth < responsiveSize
							? undefined
							: top
								? undefined
								: refContainer && refContainer.current
									? `-${refContainer.current.offsetHeight}px`
									: undefined,
				};
			});
		}
	}, [refContainer.current, show]);

	useEffect(() => {
		setLineStyleState({
			width: windowWidth < responsiveSize ? '100%' : `${width}px`,
			left: windowWidth < responsiveSize ? '0' : `calc(50% - ${width / half}px)`,
			top: windowWidth < responsiveSize ? undefined : top || '50%',
			bottom: windowWidth < responsiveSize ? '0' : undefined,
			transform: show ? 'translateY(0)' : 'translateY(-100vh)',
			opacity: show ? '1' : '0',
			zIndex: `${Number(zIndex) + (extraZIndex || 10)}`,
			borderBottomRightRadius: windowWidth < responsiveSize ? '0' : undefined,
			borderBottomLeftRadius: windowWidth < responsiveSize ? '0' : undefined,
		});
	}, [width, top, zIndex, show, windowWidth]);

	return (
		<>
			{show && (
				<>
					<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
					<div className='modal'>
						<Backdrop
							show={show}
							onClick={() => (onBackdropClick ? onBackdropClick({ id: idComponent, response: AlertEventResponses.void }) : null)}
							zIndex={zIndex}
							backgroundColor={backgroundColor}
							opacity={opacity}
						/>
						<PopUpDialog
							idComponent={idComponent}
							title={title}
							description={description}
							showStateIcon={showStateIcon}
							state={state}
							okButtonText={okButtonText}
							showCancelButton={showCancelButton}
							showCloseButton={showCloseButton}
							cancelButtonText={cancelButtonText}
							style={lineStyleState}
							ref={refContainer}
							closeIconClassName={closeIconClassName}
							closeIconSize={closeIconSize}
							className={`modal__popUp fixed bg-white modal__dialog ${className || ''}`}
							onNextClick={onNextClick}
							onPreviousClick={onPreviousClick}
							onCloseClick={onCloseClick}
						>
							{children}
						</PopUpDialog>
					</div>
				</>
			)}
		</>
	);
};

Modal.defaultProps = {
	className: '',
};

export default Modal;
