import { Component } from 'react';
import ReactDOM from 'react-dom';

interface PortalInterface {
	children?: JSX.Element;
}

interface PortalState {
	props: PortalInterface;
}

export class Portal extends Component<PortalInterface, PortalState> {
	private readonly el: HTMLDivElement;
	constructor(props: PortalInterface) {
		super(props);

		this.el = document.createElement('div');
	}

	componentDidMount(): void {
		document.body.appendChild(this.el);
	}

	componentWillUnmount(): void {
		document.body.removeChild(this.el);
	}

	render(): React.ReactPortal {
		return ReactDOM.createPortal(this.props.children, this.el);
	}
}
