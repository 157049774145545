import { ActivitiesSearchFormSubmitInterface, PassengersActivitiesSearchFormSubmitInterface } from '../interfaces/activities-search-form.interface';
import { DisplayValuesDatesFieldInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';

export class ActivitiesSearchFormUtil {
	public static getDisplayPassengersInfo(adultsValue: number, passengerText?: string, passengersText?: string): string {
		return `${adultsValue} ${(adultsValue === 1 ? passengerText : passengersText) || ''}`;
	}

	static mapSearchUrl(data: ActivitiesSearchFormSubmitInterface): string {
		let passengerUrl: string = 'passengers-';
		if (data?.passengers?.passengersAges?.length) {
			data.passengers.passengersAges.forEach((age: number, index: number, ages: number[]): void => {
				passengerUrl += `${age || 0}${index === ages.length - 1 ? '' : ','}`;
			});
		} else {
			passengerUrl += '0';
		}

		const searchUrl: string = `${data?.destinationIata || ''}/${data?.departureDate || ''}/${data?.departureDate || ''}/${passengerUrl}`;
		return searchUrl;
	}

	public static historyHandle(
		historyJson: ActivitiesSearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): ActivitiesSearchFormSubmitInterface {
		let destinationPosition: number = 2;
		let departurePosition: number = 3;
		let passengersPosition: number = 5;
		const routePath: string = window?.location?.pathname;
		const history: ActivitiesSearchFormSubmitInterface =
			(historyJson && { ...historyJson }) || ({} as unknown as ActivitiesSearchFormSubmitInterface);

		if (routePath.includes('activities')) {
			const splitRoute: string[] = routePath.split('/');
			const positionToLength: number = 1;
			const filteredPosition: number = 2;
			const validationFilteredUrl: boolean = splitRoute.length >= filteredPosition + positionToLength && splitRoute[filteredPosition] === 'filtered';
			if (validationFilteredUrl) {
				destinationPosition++;

				departurePosition++;

				passengersPosition++;
			}

			if (splitRoute.length >= passengersPosition + positionToLength) {
				const destinationIata = splitRoute[destinationPosition];
				history.destination = (destinationIata !== history?.destinationIata ? destinationIata : history?.destination || destinationIata) || '';

				history.destinationIata = destinationIata || '';

				const departureDate = splitRoute[departurePosition];
				history.departureDate = departureDate || '';

				const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
					DatesFieldTypes.oneDate,
					{
						time: false,
						date: true,
					},
					history.departureDate,
					'',
					'',
					'',
				);

				history.dates = datesValues?.dates ? datesValues.dates : '';

				const agesIndex: number = 1;
				const ageDefault: number = 18;
				const passengersDefault: number = 1;
				const passengersString: string = splitRoute[passengersPosition];
				const passengersArray: string[] = passengersString.split('passengers-');
				const passengersAges: number[] = [];
				if (passengersArray.length === agesIndex + positionToLength) {
					passengersArray[agesIndex].split(',').forEach((age: string) => {
						passengersAges.push(Number(age) || ageDefault);
					});
				}

				const passengersNumber = passengersAges.length || passengersDefault;
				const tempPassengersDisplay: string = ActivitiesSearchFormUtil.getDisplayPassengersInfo(
					passengersNumber,
					traductions && (traductions('passenger') || 'passenger'),
					traductions && (traductions('passengers') || 'passengers'),
				);

				const passengers: PassengersActivitiesSearchFormSubmitInterface = {
					passengers: passengersNumber,
					passengersAges: passengersAges.length ? [...passengersAges] : [...Array(passengersDefault).fill(ageDefault)],
					display: tempPassengersDisplay || '',
				};

				history.passengers = { ...passengers };

				history.urlPath = ActivitiesSearchFormUtil.mapSearchUrl({ ...history });
			}
		}

		return { ...history };
	}
}
