import DestinationField from '../../../shared-components/search/destination-field/destination-field';
import PassengersField from '../../../shared-components/search/passengers-field/passengers-field';
import ButtonSearch from '../../../shared-components/search/button-search/button-search';
import useAssistancesSearchForm from '../../shared/hooks/useAssistancesSearchForm';
import DatesField from '../../../shared-components/search/dates-field/dates-field';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { ListInterface } from '../../../../shared/interfaces/list.interface';
import {
	UseAssistancesSearchFormInterface,
	UseAssistancesSearchFormReturnInterface,
	AssistancesSearchFormSubmitInterface,
} from '../../shared/interfaces/assistances-search-form.interface';
import {
	PassengersFieldRestrictionsInterface,
	CustomTraductionsLabelsPassengersFieldInterface,
} from '../../../../shared/interfaces/generics/search-fields.interface';
import Checkbox from '../../../ui-elements/checkbox/checkbox';
import styles from './assistances-search-form.module.scss';
import Button from '../../../ui-elements/button/button';
import Input from '../../../ui-elements/input/input';
import { InputType } from '../../../../shared/enums/inputs.enum';
import { useMemo } from 'react';
import { WidgetStyleInterface } from '../../../../shared/interfaces';

export interface AssistancesSearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: (label: string) => string;
	locations?: ListInterface[];
	calendarRestriction?: number;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	history: AssistancesSearchFormSubmitInterface | null;
	className?: string;
	widgetStyles?: WidgetStyleInterface;
	emitSubmitSearch: (data: AssistancesSearchFormSubmitInterface) => void;
	emitShowMobileFields?: (show: boolean) => void;
}

export function AssistancesSearchForm({
	colorPrimary,
	colorSecondary,
	traductions,
	locations,
	calendarRestriction,
	passengersRestrictions,
	history,
	className,
	widgetStyles,
	emitSubmitSearch,
	emitShowMobileFields,
}: AssistancesSearchFormProps): JSX.Element {
	const {
		destinationName,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		passengersName,
		destinationId,
		datesId,
		passengersId,
		withPromoCodeName,
		annualCoverageName,
		mobileFieldStep,
		buttonSearchHistory,
		minDate,
		promoCode,
		defaultDates,
		showMobileFieldsContainer,
		mobileFormHeight,
		errors,
		isValid,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		promoCodeHandler,
		withPromoCodeHandler,
		showMobileFieldsContainerHandler,
		destinationFieldHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		passengersHandler,
		onSubmit,
		calculateAnnualCoverage,
	}: UseAssistancesSearchFormReturnInterface = useAssistancesSearchForm({
		history,
		calendarRestriction,
		widgetStyles,
		traductions,
		emitSubmitSearch,
		emitShowMobileFields,
	} as unknown as UseAssistancesSearchFormInterface);

	const passengersLabels: CustomTraductionsLabelsPassengersFieldInterface = useMemo((): CustomTraductionsLabelsPassengersFieldInterface => {
		return {
			adults: 'childrenAndAdults',
			children: 'olderAdults',
			adultsDescription: 'childrenAndAdultsDescription',
			childrenDescription: 'olderAdultsDescription',
		};
	}, []);

	const watchWithPromoCode = watch(withPromoCodeName);
	const { isTertiaryTemplate } = widgetStyles || {};
	const promoCodeInputElement: JSX.Element = (
		<Input
			value={promoCode}
			onChange={promoCodeHandler}
			type={InputType.text}
			placeholder={traductions('promoCode') || 'promoCode'}
			defaultValue={''}
			name={'promoCode'}
			className={`${
				isTertiaryTemplate
					? 'gap-2 py-2.5 px-3.5 border-[#BEBEBE] text-[#BEBEBE] border border-solid rounded-none'
					: 'w-full bg-white rounded-[10px] border border-solid py-[10px] px-[25px] flex-col justify-center'
			} `}
			labelClassName={'block text-xs'}
			inputClassName={`${
				isTertiaryTemplate ? 'text-[#4D4D4F] text-base !text-[14px] !p-0 md:placeholder placeholder:text-sm placeholder:text-[#9E9E9E]' : '!text-base'
			}  block w-full bg-white`}
			isTertiaryTemplate={isTertiaryTemplate}
			style={{
				color: isTertiaryTemplate ? undefined : colorSecondary,
			}}
		/>
	);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`assistancesSearchForm w-full flex flex-col ${className || ''}`}>
				<div className='assistancesSearchForm__searchButton block md:hidden'>
					<ButtonSearch
						iconName='assistanceIco'
						placeHolder={traductions('startYourAssistancesSearch') || 'startYourAssistancesSearch'}
						emitClick={() => showMobileFieldsContainerHandler(true)}
						colorSecondary={colorSecondary}
						history={buttonSearchHistory}
						searchText={traductions('search') || 'search'}
					/>
				</div>
				<form
					onSubmit={onSubmit}
					className={`assistancesSearchForm__form w-full top-0 left-0 md:flex flex-col ${
						showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
					}
					${widgetStyles?.isTertiaryTemplate ? 'z-[2] md:z-auto' : ''}`}
					style={{
						height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
					}}
				>
					<div
						className='assistancesSearchForm__header block md:hidden pt-[33px] pb-[53px] px-[45px]'
						style={{
							backgroundColor: colorSecondary,
						}}
					>
						<button
							onClick={() => showMobileFieldsContainerHandler(false)}
							className='assistancesSearchForm__backBtn chevronLeftIco !p-0 text-white text-base font-bold'
							type='button'
						>
							<span className='pl-[10px]'>{traductions('getBack') || 'getBack'}</span>
						</button>
					</div>
					<div
						className={
							'assistancesSearchForm__body bg-white md:bg-transparent left-0 top-[-20px] md:top-0 relative md:h-auto px-0 pt-[20px] md:pt-0 rounded-t-[20px] md:rounded-t-none'
						}
					>
						<div className='assistancesSearchForm__content relative'>
							<div className={'assistancesSearchForm__firstRow flex flex-row flex-wrap'}>
								<div className='assistancesSearchForm__destination w-full md:w-3/5 md:pr-[15px]'>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('destination') || 'destination'}
										</span>
									)}
									<DestinationField
										fieldId={destinationId}
										name={destinationName}
										hiddenName={destinationHiddenName}
										iconClassName={'pinIco'}
										title={traductions('destination') || 'destination'}
										placeHolder={traductions('destination') || 'destination'}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										showSpinner={false}
										colorSecondary={colorSecondary}
										className={`${mobileFieldStep === destinationId ? 'z-50' : 'z-0'} md:z-auto
											${isTertiaryTemplate ? 'border-0 md:rounded-none md:border md:border-solid border-[#BEBEBE]' : ''}`}
										required={true}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										useBackButton={false}
										onChange={destinationFieldHandler}
										onShowDestinations={() => undefined}
										nextCallBack={nextMobileFieldHandler}
										destinations={locations}
										preventClearListAtInit={true}
										isTertiaryTemplate={isTertiaryTemplate}
									/>
								</div>
								<div className='assistancesSearchForm__dates w-full md:w-2/5 relative'>
									{isTertiaryTemplate && (
										<span className='hidden md:block flex w-full text-[13px] text-[#4D4D4F] font-medium mb-[9px]'>
											{traductions('selectYourDate') || 'selectYourDate'}
										</span>
									)}
									<DatesField
										fieldId={datesId}
										title={traductions('selectYourDate') || 'selectYourDate'}
										placeHolder={traductions('selectYourDate') || 'selectYourDate'}
										name={datesName}
										hiddenName={datesHiddenName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										buttonText={widgetStyles?.isTertiaryTemplate ? traductions('apply') || 'apply' : traductions('ready') || 'ready'}
										type={DatesFieldTypes.startEndDates}
										config={{
											date: true,
											time: false,
										}}
										minDate={minDate}
										defaultDates={defaultDates}
										useBackButton={true}
										iconClassName={isTertiaryTemplate ? 'iconCalendarDepartureTertiary' : 'searchCalendarIco'}
										required={true}
										colorPrimary={colorPrimary}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
										isTertiaryTemplate={isTertiaryTemplate}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										watch={watch}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										onChange={datesHandler}
										onShowDates={() => undefined}
									/>
								</div>
							</div>
							<div className={'assistancesSearchForm__lastRow flex flex-row flex-wrap md:mt-[15px] items-end'}>
								<div
									className={`assistancesSearchForm__passengers w-full relative flex flex-row flex-wrap md:pr-[15px] ${
										watchWithPromoCode ? 'md:w-2/5' : 'md:w-4/5'
									}`}
								>
									<PassengersField
										fieldId={passengersId}
										traductions={traductions}
										title={traductions('passengers') || 'passengers'}
										placeHolder={traductions('passengers') || 'passengers'}
										name={passengersName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										restrictions={passengersRestrictions}
										useBackButton={true}
										required={true}
										customTraductionsLabels={passengersLabels}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === passengersId ? 'z-50' : 'z-0'} md:z-auto`}
										desktopModalClassName={isTertiaryTemplate ? 'shadow-xl md:flex-col top-[75px] md:w-full md:rounded-t-none px-[15px]' : ''}
										register={register}
										watch={watch}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										visibleTitle={true}
										onlyOneTypeOfPassenger={true}
										iconClassName='searchAdultIco'
										isTertiaryTemplate={isTertiaryTemplate}
										disabledInputs={{
											infants: true,
										}}
										onChangePassengers={passengersHandler}
									>
										<div className='assistancesSearchForm__promoCode flex md:hidden mt-[20px]'>{promoCodeInputElement}</div>
									</PassengersField>
								</div>
								<div
									className={`assistancesSearchForm__promoCode hidden w-full md:w-2/5 relative flex-row flex-wrap md:pr-[15px] 
										${watchWithPromoCode ? 'md:flex' : 'md:hidden'}
										${isTertiaryTemplate ? 'gap-2' : ''}
									`}
								>
									{isTertiaryTemplate && (
										<span className='hidden md:flex w-full text-[13px] text-[#4D4D4F] font-medium'>{traductions('promoCode') || 'promoCode'}</span>
									)}
									{promoCodeInputElement}
								</div>
								<div
									className={`assistancesSearchForm__footer md:relative md:w-1/5 hidden md:flex 
									${isTertiaryTemplate ? 'items-end' : 'md:h-[65px]'}
								`}
								>
									<Button
										type='submit'
										customClass={`assistancesSearchForm__searchBtn text-white w-full text-[21px] items-center
											${isTertiaryTemplate ? 'rounded-none tertiary__shadow' : 'rounded-lg'}
											${isTertiaryTemplate && !isValid ? 'tertiary__disabled' : ''} `}
										content={
											<>
												{isTertiaryTemplate ? null : <span className='searchIco text-[25px] font-extrabold' />}
												<span className={`${isTertiaryTemplate ? 'text-base !text-[14px]' : 'text-[21px] font-extrabold pl-[10px]'}`}>
													{traductions('search') || 'search'}
												</span>
											</>
										}
										styles={{
											background: colorSecondary || '#878787',
											padding: isTertiaryTemplate && '11.5px 5px',
										}}
										disabled={!isValid && widgetStyles?.isTertiaryTemplate}
									/>
								</div>
							</div>
							<div className={'block md:hidden w-full z-40 bg-white absolute left-0 top-[20px] h-screen'} />
						</div>
						<div
							className={`assistancesSearchForm__toolbar w-full absolute md:relative gap-4 flex-row justify-start md:justify-end items-center z-50 md:z-auto md:top-0 left-0 md:flex  
								${mobileFieldStep === passengersId ? 'flex px-[40px] md:px-0' : 'hidden'}
								${isTertiaryTemplate ? 'mt-[15px]' : 'mt-[10px]'}
								
							`}
						>
							<div className='assistancesSearchForm__withPromoCode'>
								<Checkbox
									name={withPromoCodeName}
									id={withPromoCodeName}
									primaryColor={colorSecondary}
									secondaryColor={'#FFFFFF'}
									forcePrimaryColorBorder={true}
									checkmarkContainerSize={19}
									label={traductions('withPromoCode') || 'withPromoCode'}
									className=''
									labelClassName={`${isTertiaryTemplate ? 'text-[#4D4D4F] text-[14px]' : 'text-base text-black md:text-white'}`}
									register={register(withPromoCodeName, {
										onChange: withPromoCodeHandler,
									})}
									getValues={getValues}
								/>
							</div>
							<div className='assistancesSearchForm__annualCoverage'>
								<Checkbox
									name={annualCoverageName}
									id={annualCoverageName}
									primaryColor={colorSecondary}
									secondaryColor={'#FFFFFF'}
									forcePrimaryColorBorder={true}
									checkmarkContainerSize={19}
									label={traductions('annualCoverage') || 'annualCoverage'}
									className=''
									labelClassName={`${isTertiaryTemplate ? 'text-[#4D4D4F] text-[14px]' : 'text-base text-black md:text-white'}`}
									register={register(annualCoverageName, {
										onChange: calculateAnnualCoverage,
									})}
									getValues={getValues}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AssistancesSearchForm;
