import { MarkersInterface, OfficesCardinal, SearchLocationsProps } from '../../../../../shared/interfaces/maps/static-maps.interface';
import { RentalCarCompaniesCode } from '../../../shared/interfaces/car-destination.interface';
import { InteractiveMap } from '../../../components/maps/interactive-map/interactive-map';
import { RentalCarsLogosV2 } from '../../../shared/enums/filter-cars-cards-v2.enum';
import SearchInput from '../../../../shared-components/search-input/search-input';
import { addAlphaToColor } from '../../../../../shared/services/utils.service';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import { CarMapOfficeSkeleton } from './skeleton/car-map-office-skeleton';
import { WidgetStyleInterface } from '../../../../../shared/interfaces';
import mapErrorImage from '../../../../../public/imgs/maperror.jpg';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import { TabData } from '../../../../../shared/interfaces/tabs';
import Tabs from '../../../../shared-components/tabs/tabs';
import Button from '../../../../ui-elements/button/button';
import Image from '../../../../ui-elements/image/image';
import { useEffect, useState, useRef } from 'react';
import styles from './car-map-office.module.scss';

export interface CarMapOfficeProps {
	token: string;
	longitude: number;
	latitude: number;
	defaultSearch?: string;
	officesByLocation: OfficesCardinal[];
	colorPrimary?: string;
	isLoading?: boolean;
	mapHeight?: string;
	className?: string;
	headerClassName?: string;
	widgetStyles?: WidgetStyleInterface;
	t?: (item: string) => string;
	callBackMarkerSelected?: (e: MarkersInterface) => void;
	callBackCloseContent?: () => void;
	callBackSelectedOffice?: (location: SearchLocationsProps) => void;
	callBackSearchFromInput?: (value: string) => void;
}

export function CarMapOffice({
	token,
	defaultSearch,
	colorPrimary,
	officesByLocation,
	t,
	callBackMarkerSelected,
	callBackCloseContent,
	longitude,
	latitude,
	callBackSelectedOffice,
	callBackSearchFromInput,
	isLoading,
	mapHeight,
	className,
	headerClassName,
	widgetStyles,
}: CarMapOfficeProps): JSX.Element {
	const responsiveSize: number = 768;
	const paddingBottom: number = 35;
	let [markers, setMarkers] = useState<MarkersInterface[]>([]);
	const [tabState, setTabState] = useState<string>('');
	let [officeTab, setOfficeTab] = useState<TabData[]>([]);
	const [selectedCity, setSelectedCity] = useState<OfficesCardinal | null>();
	const [selectedOffice, setSelectedOffice] = useState<SearchLocationsProps>();
	const [selectedLongitude, setSelectedLongitude] = useState<number>();
	const [selectedLatitude, setSelectedLatitude] = useState<number>();
	const [listContainerHeight, setListContainerHeight] = useState<number>(0);
	const [showList, setShowList] = useState<boolean>(false);
	const refListContainer = useRef<HTMLDivElement>(null);
	const windowWidth: number = useWindowWidth();
	const { isSecondaryTemplate } = widgetStyles || {};
	t = t as (item: string) => string;

	useEffect(() => {
		resetStates();

		officeTab = [];

		if (officesByLocation.length) {
			const firstOption: number = 0;
			officesByLocation[firstOption].selected = true;

			setTabState(officesByLocation[firstOption].locationName || '');

			setSelectedCity(officesByLocation[firstOption]);

			officesByLocation[firstOption].locations.forEach(location => {
				const createMark: MarkersInterface = {
					longitude: location.longitude,
					latitude: location.latitude,
					popupMsg: location.address || '',
					code: location.code,
					rentalCarsCompaniesCodes: location.rentalCarsCompaniesCodes,
					extra: location,
				};

				markers = [...markers, createMark];
			});

			setMarkers(markers);

			officesByLocation.forEach(office => {
				const tabData: TabData = {
					id: office.locationName || '',
					label: office.locationName || '',
					disabled: false,
				};

				officeTab = [...officeTab, tabData];

				setOfficeTab(officeTab);
			});
		}
	}, [officesByLocation]);

	useEffect(() => {
		if (selectedCity) {
			const firstItem: number = 0;
			markers = [];

			selectedCity.locations.forEach(location => {
				const createMark: MarkersInterface = {
					longitude: location.longitude,
					latitude: location.latitude,
					popupMsg: location.address || '',
					code: location.code,
					rentalCarsCompaniesCodes: location.rentalCarsCompaniesCodes,
					extra: location,
				};

				markers = [...markers, createMark];
			});

			setSelectedLongitude(selectedCity?.locations[firstItem]?.longitude);

			setSelectedLatitude(selectedCity?.locations[firstItem]?.latitude);

			setMarkers(markers);
		}
	}, [selectedCity]);

	useEffect(() => {
		if (selectedOffice) {
			document.querySelectorAll('.interactiveMap__popup').forEach(item => item.classList.remove('interactiveMap__selected'));

			markers.forEach(marker => {
				if (marker.code === selectedOffice?.code) {
					marker.selected = true;

					document.querySelector(`.${marker.code}`)?.classList.add('interactiveMap__selected');

					return;
				}

				marker.selected = false;
			});

			setMarkers(markers);
		}
	}, [selectedOffice]);

	const clickClose = (): void => {
		if (callBackCloseContent) {
			callBackCloseContent();
		}
	};

	const searchFromInput = (value: string): void => {
		if (callBackSearchFromInput) {
			callBackSearchFromInput(value);
		}
	};

	const clickEmitSelectedOffice = (location: SearchLocationsProps): void => {
		if (callBackSelectedOffice) {
			callBackSelectedOffice(location);
		}
	};

	const resetStates = (): void => {
		setMarkers([]);

		setOfficeTab([]);

		setSelectedLongitude(0);

		setSelectedLatitude(0);

		setSelectedCity(null);
	};

	useEffect(() => {
		if (refListContainer?.current?.offsetHeight && windowWidth) {
			setListContainerHeight(
				windowWidth < responsiveSize ? Number(refListContainer.current.offsetHeight) : Number(refListContainer.current.offsetHeight) - paddingBottom,
			);
		} else {
			setListContainerHeight(0);
		}
	}, [refListContainer?.current?.offsetHeight, windowWidth]);

	useEffect(() => {
		setShowList(!isLoading && !!listContainerHeight);
	}, [isLoading, listContainerHeight]);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`carMapOffice flex flex-col relative ${className || ''}`}>
				<div
					className={`carMapOffice__header flex flex-row justify-between items-center py-3 md:py-[30px] px-[30px] md:px-[45px] relative ${
						headerClassName || ''
					}`}
					style={{
						backgroundColor: colorPrimary,
					}}
				>
					<p className='carMapOffice__title flex-grow text-base text-white font-family-regular'>{t('searchByLocations')}</p>
					<button
						type='button'
						className={`carMapOffice__closeButton inline-block w-[30px] h-[30px] ${isSecondaryTemplate ? '' : 'rounded-full'}`}
						style={{
							backgroundColor: isSecondaryTemplate ? undefined : addAlphaToColor('#FFFFFF', 0.2),
						}}
						onClick={clickClose}
					>
						<IcomoonIcon
							icon={isSecondaryTemplate ? 'icon-close-secondary' : 'closeIco'}
							className={`${isSecondaryTemplate ? 'text-white' : 'text-gray-400'}`}
							fontSize={isSecondaryTemplate ? 15 : 10}
						/>
					</button>
				</div>
				<div
					className={'flex flex-col flex-grow carMapOffice__container pt-[40px] px-[20px] md:px-[30px]'}
					style={{
						paddingBottom: windowWidth < responsiveSize ? undefined : `${paddingBottom}px`,
					}}
				>
					<div className='w-[100%] md:w-[50%] pr-[20px] md:pr-[30px] flex flex-col'>
						<SearchInput
							placeHolder={t ? t('startYourSearch') : 'search...'}
							colorPrimary={colorPrimary}
							defaultValue={defaultSearch || ''}
							callBackValue={value => {
								searchFromInput(value);

								resetStates();
							}}
							isReactiveMode={isSecondaryTemplate}
						/>

						<div
							className={`carMapOffice__tabsContainer max-w-[586px] overflow-x-auto ${isSecondaryTemplate ? 'carMapOffice__custom-scrollbar' : ''}`}
						>
							{!!officeTab.length && (
								<Tabs
									className={`carMapOffice__tabs w-full px-0 m-0 ${
										isSecondaryTemplate ? 'carMapOffice__custom-scrollbar pt-[16px] pb-[5px] gap-2' : 'pt-[20px] pb-[3px]'
									}`}
									activeTab={tabState}
									tabs={officeTab}
									activeColor={isSecondaryTemplate ? '#667085' : colorPrimary}
									desactiveColor={'#878787'}
									isOutLine={!isSecondaryTemplate}
									isPills={isSecondaryTemplate}
									tabActiveClass={'carMapOffice__activeTab '}
									tabClassName={`carMapOffice__tabs carMapOffice__tabs  text-[13px!important] ${
										isSecondaryTemplate ? 'whitespace-nowrap px-3 py-2' : 'mr-[5px]'
									}`}
									onClick={(value: string) => {
										setTabState(value);

										const selectedOffice = officesByLocation.find(office => office.locationName === value);
										if (selectedOffice) {
											setSelectedCity(selectedOffice);
										}
									}}
								/>
							)}
						</div>

						<p className='carMapOffice__officeResults text-xs mt-[20px!important]'>
							{selectedCity?.locations?.length || 0} {t('results')}
						</p>

						{!officesByLocation.length && !isLoading && (
							<p className='carMapOffice__noResults text-center '>{t ? t('localittiesNoFound') : 'No results found'}</p>
						)}
						<div className={'flex-grow w-full mt-[20px]'} ref={refListContainer}>
							{!isLoading ? (
								<div
									className={`carMapOffice__list md:block overflow-y-auto ${isSecondaryTemplate ? 'carMapOffice__custom-scrollbar' : ''}`}
									style={{
										maxHeight: listContainerHeight ? `${listContainerHeight}px` : undefined,
									}}
								>
									{showList &&
										selectedCity?.locations.map((location, index) => (
											<div
												id={location.code}
												onClick={() => {
													selectedCity?.locations.forEach(itemLocation => (itemLocation.selected = false));

													location.selected = true;

													setSelectedOffice(location);

													setSelectedLongitude(location.longitude);

													setSelectedLatitude(location.latitude);
												}}
												key={index}
												className={`carMapOffice__officeLocation relative border-b cursor-pointer   mb-[20px] ${
													location?.selected ? 'caMapOffice__selected ' : ''
												} ${isSecondaryTemplate ? 'border-black pb-4' : 'border-gray-300 pb-[15px]'}`}
											>
												<p
													className={`carMapOffice__officeTitle mt-[10px] mb-[3px] ${isSecondaryTemplate ? '' : 'text-[20px]'}`}
													style={{
														color: location?.selected && !isSecondaryTemplate ? colorPrimary : '',
													}}
												>
													{index < 10 ? `0${index + 1}` : index + 1}. {location.name}
												</p>
												<div className='flex text-[25px]'>
													{location?.rentalCarsCompaniesCodes?.map((rents: RentalCarCompaniesCode, rentIndex: number) => (
														<div className='mr-[7px]' key={rentIndex}>
															{RentalCarsLogosV2[rents?.rentalCarName?.toLocaleLowerCase()] &&
																RentalCarsLogosV2[rents?.rentalCarName?.toLocaleLowerCase()].color}
														</div>
													))}
												</div>
												<p className={`carMapOffice__address ${isSecondaryTemplate ? '' : 'text-xs text-gray-500'}`}>{location.address}</p>
												{location?.selected && (
													<Button
														styles={{
															backgroundColor: colorPrimary,
														}}
														onClick={() => clickEmitSelectedOffice(location)}
														customClass='carMapOffice__addressBtn !p-0 pl-0 pr-0 pb-0 pt-0 w-[100px] h-[34px] text-[14px] flex items-center mr-4 rounded-full md:absolute md:right-[0px] md:bottom-[15px] '
														text={t ? t('select') : 'select'}
													/>
												)}
											</div>
										))}
								</div>
							) : (
								<>
									<p className='animate-pulse carMapOffice__noResults text-center h-3 w-[40px]'></p>
									<CarMapOfficeSkeleton />
								</>
							)}
						</div>
					</div>

					<div className='w-[100%] md:w-[50%]'>
						{officesByLocation.length ? (
							<InteractiveMap
								token={token}
								color={'#494848'}
								markers={markers}
								height={mapHeight}
								longitude={selectedLongitude || longitude}
								latitude={selectedLatitude || latitude}
								selectText={t ? t('select') : 'Select'}
								callBackMarker={e => {
									const selectedOffice: OfficesCardinal = officesByLocation.find(
										office => office.locationName === (e.extra as { cityName: string }).cityName,
									) as OfficesCardinal;

									const location: SearchLocationsProps = selectedOffice?.locations.find(
										itemLocation => itemLocation.code === e.code,
									) as SearchLocationsProps;

									if (location) {
										selectedCity?.locations.forEach(itemLocation => (itemLocation.selected = false));

										location.selected = true;

										const itemPosition: HTMLDivElement = document?.querySelector(`#${location.code}`) as HTMLDivElement;
										const listPosition: HTMLDivElement = document?.querySelector('.carMapOffice__list') as HTMLDivElement;
										listPosition?.scrollTo({ top: itemPosition?.offsetTop - listPosition?.offsetTop, behavior: 'smooth' });

										setSelectedOffice(location);
									}

									if (callBackMarkerSelected) {
										callBackMarkerSelected(e);
									}
								}}
								callBackPopupBtnSelected={e => {
									clickEmitSelectedOffice(e.extra as SearchLocationsProps);
								}}
								isSecondaryTemplate={isSecondaryTemplate}
							/>
						) : (
							<>
								<Image className='h-full flex justify-center' customClass='h-full' url={mapErrorImage} />
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
