import { faCalendar, faUser } from '@awesome.me/kit-dc2201b3ec/icons/classic/solid';
import { FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { disneyMobileSearchFormFiled } from '../../../shared';
import { ElementTypes } from '../../../../../shared/enums';
import { Input } from '../../../../ui-elements';
import { FC, useEffect, useState } from 'react';

export interface DisneyMobileSearchFormProps {
	datesId: string;
	datesName: string;
	passengersName: string;
	passengersId: string;
	traductions: (label: string) => string;
	register: UseFormRegister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	showMobileFieldsContainerHandler: (show: boolean, fieldId: string, fieldName: string) => void;
	isSecondaryTemplate: boolean;
	isTertiaryTemplate?: boolean;
	truncate?: boolean;
	colorSecondary: string;
	customClass?: string;
}

export const DisneyMobileSearchForm: FC<DisneyMobileSearchFormProps> = ({
	children,
	datesId,
	datesName,
	passengersName,
	passengersId,
	showMobileFieldsContainerHandler,
	traductions,
	register,
	watch,
	isSecondaryTemplate,
	isTertiaryTemplate,
	colorSecondary,
	truncate,
	customClass,
}) => {
	const [datesValue, setDatesValue] = useState<string>('');
	const [passengersValue, setPassengersValue] = useState<string>('');
	const handleClick = (id: string, name: string): void => {
		showMobileFieldsContainerHandler(true, id, name);
	};

	const datesInputValue: string = watch(datesName);
	const passengersInputValue: string = watch(passengersName);
	useEffect(() => {
		setDatesValue(datesInputValue);

		setPassengersValue(passengersInputValue);
	}, [datesInputValue, passengersInputValue]);

	const fields: disneyMobileSearchFormFiled[] = [
		{
			label: traductions('labelDates') || 'labelDates',
			value: datesValue ?? '',
			icon: (() => {
				switch (true) {
					case isSecondaryTemplate:
						return 'iconLightCalendarSecondary';
					case isTertiaryTemplate:
						return 'iconCalendarDepartureTertiary';
					default:
						return faCalendar;
				}
			})(),
			placeholder: isSecondaryTemplate ? traductions('selectAnOption') || 'selectAnOption' : traductions('labelDates') || 'labelDates',
			idRef: datesId,
			nameRef: datesName,
		},
		{
			label: traductions('passengers') || 'passengers',
			value: passengersValue ?? '',
			icon: isSecondaryTemplate ? 'iconLightUserSecondary' : faUser,
			placeholder: isSecondaryTemplate ? traductions('selectAnOption') || 'selectAnOption' : traductions('passengers') || 'passengers',
			idRef: passengersId,
			nameRef: passengersName,
		},
	];

	return (
		<div className={`flex flex-col gap-4 ${customClass ?? ''}`}>
			<div className='flex flex-col gap-2'>
				{fields.map((field: disneyMobileSearchFormFiled) => (
					<div
						key={field.idRef}
						className={`flex 
						${isSecondaryTemplate ? 'flex-col gap-1' : 'border bg-white gap-2 px-4 py-3 border-rad border-[#696969]'}
						${isTertiaryTemplate ? 'rounded-none border-[#BEBEBE]' : ''}
						${!isTertiaryTemplate && !isSecondaryTemplate ? 'rounded-lg' : ''}
					`}
					>
						{isSecondaryTemplate && (
							<div className='text-sm font-medium'>
								<span className='field__labelText'>{field.label}</span>
							</div>
						)}
						{!isSecondaryTemplate && (
							<div className='w-[24px]'>
								{typeof field.icon === 'string' ? (
									<span
										className={`${field.icon} text-base
										${isTertiaryTemplate ? 'text-[#4D4D4F] flex justify-center' : ''}`}
									></span>
								) : (
									<FontAwesomeIcon
										data-testid='icon'
										icon={field.icon}
										width={24}
										height={24}
										fixedWidth={true}
										color={isTertiaryTemplate ? '#4D4D4F' : colorSecondary}
										fontSize={16}
									/>
								)}
							</div>
						)}
						<Input
							value={field.value}
							readonly={true}
							icon={isSecondaryTemplate ? (field.icon as string) : ''}
							iconClassName={'text-[#98A2B3]'}
							type={ElementTypes.text}
							placeholder={field.placeholder}
							isSecondaryTemplate={isSecondaryTemplate}
							isTertiaryTemplate={isTertiaryTemplate}
							className={`destinationField__input cursor-pointer ${
								isSecondaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'
							}  flex-grow`}
							inputClassName={`bg-transparent !p-0 font-normal md md:placeholder placeholder:font-normal placeholder:text-[#696969]
                ${isSecondaryTemplate ? 'placeholder:text-md placeholder:text-[#667085]' : 'placeholder:text-base'}
                ${isTertiaryTemplate ? '!text-[14px] text-[#4D4D4F] placeholder:text-sm !placeholder:text-[#9E9E9E]' : '!text-base'}
              `}
							autocomplete={'off'}
							style={{
								color: '#696969',
							}}
							truncate={truncate}
							onClick={() => handleClick(field.idRef, field.nameRef)}
						/>
					</div>
				))}
			</div>

			{children}
		</div>
	);
};
