import { PassengersFieldRestrictionsInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import DestinationField from '../../../shared-components/search/destination-field/destination-field';
import PassengersField from '../../../shared-components/search/passengers-field/passengers-field';
import ButtonSearch from '../../../shared-components/search/button-search/button-search';
import { InputEventsHandler } from '../../../../shared/interfaces/inputs.interface';
import DatesField from '../../../shared-components/search/dates-field/dates-field';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import useHotelsDisneySearchForm from '../../shared/hooks/useHotelsDisneySearchForm';
import { InputType } from '../../../../shared/enums/inputs.enum';
import {
	UseHotelsDisneySearchFormReturnInterface,
	HotelsDisneySearchFormSubmitInterface,
	RoomNameHotelsDisneySearchFormInterface,
	RoomsFieldsRestrictionsInterfaceDisney,
} from '../../shared/interfaces/hotels-disney-search-form.interface';
import Button from '../../../ui-elements/button/button';
import styles from './hotels-disney-search-form.module.scss';
import Input from '../../../ui-elements/input/input';

export interface HotelsDisneySearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: (label: string) => string;
	calendarRestriction?: number | undefined;
	roomsRestriction?: RoomsFieldsRestrictionsInterfaceDisney;
	passengersRestrictions: PassengersFieldRestrictionsInterface;
	history: HotelsDisneySearchFormSubmitInterface | null;
	className?: string;
	emitSubmitSearch: (data: HotelsDisneySearchFormSubmitInterface) => void;
	emitShowMobileFields?: (show: boolean) => void;
}

export function HotelsDisneySearchForm({
	colorPrimary,
	colorSecondary,
	traductions,
	calendarRestriction,
	roomsRestriction,
	passengersRestrictions,
	history,
	className,
	emitSubmitSearch,
	emitShowMobileFields,
}: HotelsDisneySearchFormProps): JSX.Element {
	const {
		datesName,
		datesHiddenName,
		destinationName,
		destinationId,
		roomsNames,
		datesId,
		mobileFieldStep,
		buttonSearchHistory,
		minDate,
		defaultDates,
		roomsNumber,
		showMobileFieldsContainer,
		childrenAges,
		mobileFormHeight,
		errors,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		roomsModalInputsHandler,
		childrenAgesHandler,
		onSubmit,
	}: UseHotelsDisneySearchFormReturnInterface = useHotelsDisneySearchForm({
		history,
		calendarRestriction,
		roomsRestriction,
		maxRooms: 2,
		traductions,
		emitSubmitSearch,
		emitShowMobileFields,
	});

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`hotelsDisneySearchForm w-full flex flex-col ${className || ''}`}>
				<div className='hotelsDisneySearchForm__searchButton block md:hidden'>
					<ButtonSearch
						iconName='hotelsIco'
						placeHolder={traductions('startYourHotelsSearch') || 'startYourHotelsSearch'}
						emitClick={() => showMobileFieldsContainerHandler(true)}
						colorSecondary={colorSecondary}
						history={buttonSearchHistory}
						searchText={traductions('search') || 'search'}
					/>
				</div>
				<form
					onSubmit={onSubmit}
					className={`hotelsDisneySearchForm__form w-full top-0 left-0 md:flex flex-col ${
						showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
					}`}
					style={{
						height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
					}}
				>
					<div
						className='hotelsDisneySearchForm__header block md:hidden pt-[33px] pb-[53px] px-[45px]'
						style={{
							backgroundColor: colorSecondary,
						}}
					>
						<button
							onClick={() => showMobileFieldsContainerHandler(false)}
							className='hotelsDisneySearchForm__backBtn chevronLeftIco !p-0 text-white text-base font-bold'
							type='button'
						>
							<span className='pl-[10px]'>{traductions('getBack') || 'getBack'}</span>
						</button>
					</div>
					<div
						className={
							'hotelsDisneySearchForm__body bg-white md:bg-transparent left-0 top-[-20px] md:top-0 relative md:h-auto px-0 pt-[20px] md:pt-0 rounded-t-[20px] md:rounded-t-none'
						}
					>
						<div className='hotelsDisneySearchForm__content relative'>
							<div className={'hotelsDisneySearchForm__firstRow flex flex-row flex-wrap'}>
								<div className='hotelsSearchForm__destination w-full md:w-3/5 md:pr-[15px]'>
									<DestinationField
										fieldId={destinationId}
										name={destinationName}
										hiddenName={destinationName}
										iconClassName={'pinIco'}
										title={traductions('parks') || 'parks'}
										placeHolder={traductions('parks') || 'parks'}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										colorSecondary={colorSecondary}
										className={`${mobileFieldStep === destinationId ? 'z-50' : 'z-0'} md:z-auto`}
										required={true}
										disabled={true}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										useBackButton={false}
										onShowDestinations={() => undefined}
										nextCallBack={nextMobileFieldHandler}
										isFixedTitle={true}
									/>
								</div>
								<div className='hotelsDisneySearchForm__dates w-full md:w-2/5 relative'>
									<DatesField
										fieldId={datesId}
										title={traductions('selectYourDates') || 'selectYourDates'}
										placeHolder={traductions('selectYourDates') || 'selectYourDates'}
										name={datesName}
										hiddenName={datesHiddenName}
										nextButtonTraduction={traductions('next') || 'next'}
										buttonText={traductions('ready') || 'ready'}
										type={DatesFieldTypes.startEndDates}
										config={{
											date: true,
											time: false,
										}}
										minDate={minDate}
										defaultDates={defaultDates}
										useBackButton={false}
										iconClassName={'searchCalendarIco'}
										required={true}
										colorPrimary={colorPrimary}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										watch={watch}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										onChange={datesHandler}
										onShowDates={() => undefined}
										isFixedTitle={true}
									/>
								</div>
							</div>
							<div className={'hotelsDisneySearchForm__lastRow flex flex-row flex-wrap md:mt-[15px] items-end'}>
								<div className={'hotelsDisneySearchForm__rooms w-full md:w-4/5 flex-col flex-wrap md:pr-[15px]'}>
									{roomsNames.map((names: RoomNameHotelsDisneySearchFormInterface, roomIndex: number) => {
										const agesFieldsElements: JSX.Element[] | boolean =
											childrenAges[roomIndex] !== undefined &&
											childrenAges[roomIndex]?.map((roomAges: number, ageIndex: number, ages: number[]) => {
												return (
													<div
														key={`${names.id}_${roomIndex}_${ageIndex}`}
														className={`hotelsDisneySearchForm__age w-full md:h-[65px] ${
															ageIndex ? 'md:w-auto md:min-w-1/4 mt-[15px] md:mt-[15px] md:pr-[15px]' : 'md:w-2/5 md:pl-[15px]'
														} ${ages.length === ageIndex + 1 ? 'mb-[15px] md:mb-0' : ''}`}
													>
														<Input
															value={roomAges.toString()}
															onChange={(event: InputEventsHandler) => childrenAgesHandler(event, roomIndex, ageIndex)}
															type={InputType.number}
															label={`${traductions('ageOfChild') || 'ageOfChild'} ${ageIndex + 1}`}
															placeholder={traductions('ageOfChild') || 'ageOfChild'}
															defaultValue={'0'}
															name={`${names.name}_${roomIndex}_${ageIndex}`}
															min={'0'}
															max={'17'}
															step={'1'}
															className={'w-full bg-white rounded-[10px] border border-solid py-[10px] px-[25px] flex-col justify-center'}
															labelClassName={'block text-[10px]'}
															inputClassName={'block w-full bg-white text-base'}
															style={{
																color: colorSecondary,
															}}
														/>
													</div>
												);
											});

										return (
											<div
												key={names.id}
												className={`hotelsDisneySearchForm__room w-full relative md:pr-[8px] flex-col ${
													roomIndex < roomsNumber ? 'flex' : 'hidden'
												} ${mobileFieldStep === names.id ? '' : 'h-0 md:h-auto'} ${roomIndex ? 'md:mt-[20px]' : ''}`}
											>
												<p
													className={`hotelsDisneySearchForm__roomTitle w-full absolute md:relative text-base text-[#616161] md:text-white pb-[10px] pl-[40px] md:pl-0 md:z-auto top-[3px] md:top-0 left-0 md:block ${
														mobileFieldStep === names.id ? 'z-[51]' : 'z-0'
													}`}
												>{`${traductions('room') || 'room'} ${roomIndex + 1}`}</p>
												<div className='hotelsDisneySearchForm__roomFields flex flex-row flex-wrap'>
													<PassengersField
														fieldId={names.id}
														traductions={traductions}
														title={traductions('adultsAndChildren') || 'adultsAndChildren'}
														placeHolder={traductions('adultsAndChildren') || 'adultsAndChildren'}
														name={names.name}
														backButtonTraduction={traductions('back') || 'back'}
														nextButtonTraduction={traductions('next') || 'next'}
														restrictions={passengersRestrictions}
														useBackButton={true}
														passengersAmountsRestrictions={16}
														required={roomIndex < roomsNumber}
														colorSecondary={colorSecondary}
														className={`w-full md:w-3/5 ${mobileFieldStep === names.id ? 'z-50' : 'z-0'} md:z-auto md:h-[65px] ${
															roomIndex === roomsNumber - 1 ? 'hotelsDisneySearchForm__lastRoom' : ''
														}`}
														desktopModalClassName=''
														register={register}
														watch={watch}
														setValue={setValue}
														getValues={getValues}
														trigger={trigger}
														errors={errors}
														nextCallBack={nextMobileFieldHandler}
														backCallBack={backMobileFieldHandler}
														visibleTitle={true}
														iconClassName='searchAdultIco'
														disabledInputs={{
															infants: true,
														}}
														onModalInputsHandler={roomsModalInputsHandler}
													>
														<div className={'hotelsDisneySearchForm__agesFields w-full flex flex-col md:hidden'}>{agesFieldsElements}</div>
													</PassengersField>
													{agesFieldsElements}
												</div>
											</div>
										);
									})}
								</div>
								<div className={'hotelsDisneySearchForm__footer md:relative md:w-1/5 hidden md:flex md:h-[65px]'}>
									<Button
										type='submit'
										customClass='hotelsDisneySearchForm__searchBtn rounded-lg text-white w-full text-[21px] items-center'
										content={
											<>
												<span className='searchIco text-[25px] font-extrabold' />
												<span className='text-[21px] font-extrabold pl-[10px]'>{traductions('search') || 'search'}</span>
											</>
										}
										styles={{
											background: colorSecondary || '#878787',
										}}
									/>
								</div>
							</div>
							<div className={'block md:hidden w-full z-40 bg-white absolute left-0 top-[20px] h-screen'} />
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default HotelsDisneySearchForm;
