import {
	DestinationsFlightsSearchFormSubmitInterface,
	FlightsSearchFormSubmitInterface,
	FlightAirlinesTypes,
	FlightCabinsTypes,
	FlightTripTypes,
	ObjectLiteral,
} from '@smartlinks/react-design-system';
import { TypeOfFlight } from '../enums/type-flights.enum';

const EMPTY_TEXT: string = '';
const ARRIVAL_DATE_DEFAULT: string = '00000000';
export class MapFlightsUrlUtil {
	static mapSearchUrlDestination(data: FlightsSearchFormSubmitInterface): string {
		const firstIndex: number = 0;
		let destinationsUrl: string = '';
		destinationsUrl = `${data.destinations[firstIndex]?.departure?.date?.replace(/-/g, EMPTY_TEXT) || EMPTY_TEXT}_${
			data.destinations[firstIndex]?.departure.airportCode || EMPTY_TEXT
		}_${data.destinations[firstIndex]?.arrival.airportCode || EMPTY_TEXT}`;

		if (data.tripType !== FlightTripTypes.oneway) {
			destinationsUrl += `-${
				data.tripType === FlightTripTypes.oneway
					? ARRIVAL_DATE_DEFAULT
					: `${data.destinations[firstIndex]?.arrival?.date?.replace(/-/g, EMPTY_TEXT) || EMPTY_TEXT}`
			}_${data.destinations[firstIndex]?.arrival.airportCode || EMPTY_TEXT}_${data.destinations[firstIndex]?.departure.airportCode || EMPTY_TEXT}`;
		}

		return destinationsUrl;
	}

	static mapSearchUrlDestinationMultiple(data: FlightsSearchFormSubmitInterface): string {
		let destinationsUrl: string = '';
		const separator: string = '-';
		const ignoreSeperatorIndex: number = 1;
		let countValidData: number = 0;
		data.destinations.forEach((destination: DestinationsFlightsSearchFormSubmitInterface) => {
			if (destination?.departure?.airportCode === EMPTY_TEXT) return;

			countValidData++;

			const _separator: string = countValidData === ignoreSeperatorIndex ? EMPTY_TEXT : separator;
			const departure = destination?.departure?.airportCode || EMPTY_TEXT;
			const arrival = destination?.arrival?.airportCode || EMPTY_TEXT;
			const departureDate = destination?.departure?.date?.replace(/-/g, EMPTY_TEXT) || EMPTY_TEXT;
			destinationsUrl += `${_separator}${departureDate}_${departure}_${arrival}`;
		});

		return destinationsUrl;
	}

	public static mapAngular(data: FlightsSearchFormSubmitInterface, agencyCountryCode: string): string {
		const detinationPatch = {
			[FlightTripTypes.multiple]: MapFlightsUrlUtil.mapSearchUrlDestinationMultiple(data),
			[ObjectLiteral.default]: MapFlightsUrlUtil.mapSearchUrlDestination(data),
		};

		const typeOfFlight: string = this.getTypeOfFlight(data.destinations, agencyCountryCode);
		const destinationsUrl: string =
			detinationPatch[
				data.tripType as keyof {
					multiple: string;
					default: string;
				}
			] || detinationPatch[ObjectLiteral.default];

		const promoCode: string = data.promoCode ?? '';
		const queryPromoCode: string = promoCode.length ? `&promoCode=${promoCode || ''}` : '';
		const searchUrl: string = `flights/availability/${data.tripType || 'round'}/${destinationsUrl}/${
			!data.airlineCode || data.airlineCode === FlightAirlinesTypes.all ? 'all' : `${data.airlineCode}`
		}/${!data.cabin || data.cabin === FlightCabinsTypes.all ? 'any' : `${data.cabin}`}/${
			data.onlyDirectFlight ? `non-stop` : 'all-stops'
		}/${typeOfFlight}/${data.adults || 1}_adult/${data.children || 0}_child/${data.infants || 0}_infant${queryPromoCode}`;

		return searchUrl;
	}

	public static getTypeOfFlight(destinations: DestinationsFlightsSearchFormSubmitInterface[], agencyCountryCode: string): string {
		const InternationalsDestinations: boolean[] = [];
		destinations.forEach((destination: DestinationsFlightsSearchFormSubmitInterface) => {
			if (destination.departure.airport) {
				const countryDeparture = destination.departure.countryCode;
				const countryArrival = destination.arrival.countryCode;
				let isInternational: boolean = false;
				isInternational = isInternational || countryDeparture !== agencyCountryCode;

				isInternational = isInternational || countryArrival !== agencyCountryCode;

				InternationalsDestinations.push(isInternational);
			}
		});

		return InternationalsDestinations.some((element: boolean) => element) ? TypeOfFlight.international : TypeOfFlight.domestic;
	}
}
