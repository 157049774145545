export enum FlightTripTypes {
	oneway = 'single',
	round = 'round',
	multiple = 'multiple',
}

export enum FlightCabinsTypes {
	first = 'first',
	economic = 'economic',
	business = 'business',
	economy = 'economy',
	premiumEconomy = 'premiumEconomy',
	premiumFirst = 'premiumFirst',
	premiumBusiness = 'premiumBusiness',
	all = 'allClassesLabel',
	any = 'any',
}

export enum FlightAirlinesTypes {
	all = 'all',
}

export enum OnlyDirectFlightTypes {
	direct = '1',
}
