import { HistoricalDestination, HistoricalDetailData, HistoricalDetailProductAir, HistoricalPassangerDetail } from '../../../../shared/interfaces';
import HistoricalItemInfo from './historical-item-info';

export interface HistoricalProductAirProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	historicalDetail: HistoricalDetailData<HistoricalDetailProductAir>;
}

export function HistoricalProductAir({ t, colorPrimary, colorSecondary, historicalDetail }: HistoricalProductAirProps): JSX.Element {
	const HistoricalDestination = (userHistoricalDestination: HistoricalDestination): JSX.Element => {
		return (
			<section className='historical-detail__destinationItem justify-between flex-wrap flex mt-2 mb-8 md:w-1/2'>
				<div className='flex flex-col'>
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						customClass='historical-detail__departure'
						isIcon={true}
						classIcon='calendarBlankIco'
						title='departure'
						value={userHistoricalDestination.departureDateFormated}
					/>
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						customClass='historical-detail__from'
						isIcon={true}
						classIcon='searchLocationIco'
						title='from'
						value={userHistoricalDestination.from}
					/>
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						customClass='historical-detail__airline'
						isIcon={true}
						classIcon='frontPlaneIco'
						title='airline'
						value={userHistoricalDestination.airline}
					/>
				</div>
				<div className='flex flex-col'>
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						customClass='historical-detail__departure'
						isIcon={true}
						classIcon='calendarBlankIco'
						title='comming'
						value={userHistoricalDestination.arrivalDateFormated}
					/>
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						customClass='historical-detail__towards'
						isIcon={true}
						classIcon='searchLocationIco'
						title='towards'
						value={userHistoricalDestination.to}
					/>
				</div>
			</section>
		);
	};

	const HistoricalPassanger = ({
		userHistoricalPassangerDetail,
		title,
	}: {
		userHistoricalPassangerDetail: HistoricalPassangerDetail[];
		title: string;
	}): JSX.Element => {
		if (!userHistoricalPassangerDetail.length) {
			return <></>;
		}

		return (
			<>
				<h3 className={'historical-detail__passangerTitle flex flex-wrap text-[14px] mt-4 mb-1 text-gray-500'}>
					<span className='historical-detail__itemInfo mr-1' style={{ color: colorPrimary }}>
						{t(title)}
					</span>
				</h3>
				{userHistoricalPassangerDetail.map((passanger: HistoricalPassangerDetail, index: number) => (
					<HistoricalItemInfo
						colorPrimary={colorPrimary}
						colorSecondary={colorSecondary}
						t={t}
						key={index}
						customClass='historical-detail__passangerName'
						isIcon={false}
						classIcon=''
						title='name'
						value={passanger.fullName}
					/>
				))}
			</>
		);
	};

	return (
		<>
			<div className='historical-detail__split my-8' />
			<HistoricalPassanger userHistoricalPassangerDetail={historicalDetail.detail.passengers.adults} title='adults' />
			<HistoricalPassanger userHistoricalPassangerDetail={historicalDetail.detail.passengers.children} title='children' />
			<HistoricalPassanger userHistoricalPassangerDetail={historicalDetail.detail.passengers.infants} title='infants' />
			<hr className='historical-detail__split my-4 md:w-[300px]' />
			<div className='historical-detail__destinations'>
				{historicalDetail.detail.flights.map((track: HistoricalDestination, index: number) => (
					<HistoricalDestination key={index} {...track} />
				))}
			</div>
		</>
	);
}

export default HistoricalProductAir;
