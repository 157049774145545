import Checkbox from '../../../../../ui-elements/checkbox/checkbox';
import Counter from '../../../../../ui-elements/counter/counter';
import styles from './special-service-card-skeleton.module.scss';

export interface SpecialServiceCardSkeletonProps {
	className?: string;
}

export const SpecialServiceCardSkeleton = ({ className }: SpecialServiceCardSkeletonProps): JSX.Element => {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`specialServiceCardSkeleton animate-pulse flex flex-col py-[15px] px-[15px] md:px-[35px] ${className || ''}`}>
				<div className='flex flex-row items-center'>
					<span className='specialServiceCardSkeleton__icon h-[35px] w-[35px] bg-gray-100' />
					<div className='flex-grow flex flex-col items-start justify-start pl-[16px] md:pl-[35px]'>
						<span className='specialServiceCardSkeleton__name h-[12px] w-[160px] max-w-full bg-gray-200' />
						<span className='specialServiceCardSkeleton__moreInformation mt-[5px] h-[10px] w-[74px] max-w-full bg-gray-200' />
					</div>
					<div className='hidden md:flex flex-row items-center'>
						<Counter defaultValue={0} className={'pr-[48px] specialServiceCard__counter'} min={0} max={0} />
						<p className='specialServiceCardSkeleton__rate hidden md:block h-[16px] w-[72px] bg-gray-200 m-0' />
					</div>
					<Checkbox
						secondaryColor={'#E5E7EB'}
						disabled={true}
						readonly={true}
						checkmarkContainerSize={20}
						className='specialServiceCardSkeleton__select pl-[13px] md:pl-[25px]'
					/>
					<div className={'specialServiceCardSkeleton__infoContainer flex justify-center pl-[15px] md:pl-[25px]'}>
						<span className='specialServiceCardSkeleton__infoIcon icon infoCirlce2Ico text-[15px] text-gray-200' />
					</div>
				</div>
				<div className='flex md:hidden flex-col items-center'>
					<Counter defaultValue={0} className={'pt-[16px] specialServiceCard__counter'} min={0} max={0} />
					<p className='specialServiceCardSkeleton__rate block md:hidden h-[16px] w-[72px] pt-[10px] m-0 bg-gray-200' />
				</div>
			</div>
		</>
	);
};

SpecialServiceCardSkeleton.defaultProps = {};

export default SpecialServiceCardSkeleton;
