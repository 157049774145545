import { FieldButtonEventHandlerInterface, FilterFieldInputNamesInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import { FieldValues, UseFormRegister, UseFormReturn, FieldErrorsImpl, DeepRequired } from 'react-hook-form';
import { SearchFieldsNames } from '../../../../shared/enums/search-fields.enum';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import { ElementTypes } from '../../../../shared/enums/dynamic-forms.enum';
import FilterFieldList from './filter-field-list/filter-field-list';
import Backdrop from '../../../ui-elements/backdrop/backdrop';
import Button from '../../../ui-elements/button/button';
import Input from '../../../ui-elements/input/input';
import { useCallback, useState, useEffect } from 'react';
export interface FilterFieldProps {
	fieldId?: string;
	title?: string;
	placeHolder?: string;
	searchPlaceHolder?: string;
	name: string;
	backButtonTraduction?: string;
	nextButtonTraduction?: string;
	useBackButton?: boolean;
	iconClassName?: string;
	required?: boolean;
	useOptionalText?: boolean;
	options?: OptionSelect[];
	colorSecondary?: string;
	optionalText?: string;
	className?: string;
	desktopModalClassName?: string;
	errors: FieldErrorsImpl<DeepRequired<FieldValues>>;
	register: UseFormRegister<FieldValues>;
	setValue?: UseFormReturn['setValue'];
	getValues?: UseFormReturn['getValues'];
	trigger?: UseFormReturn['trigger'];
	nextCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	backCallBack?: (event: FieldButtonEventHandlerInterface) => void;
	visibleTitle?: boolean;
	useLightTemplate?: boolean;
	isMobile?: boolean;
	truncate?: boolean;
	isSecondaryTemplate?: boolean;
	isTertiaryTemplate?: boolean;
	showMobileActions?: boolean;
	defaultValue?: OptionSelect;
	arlineHandler?: (selectedOption: OptionSelect) => void;
}

export const FilterField = ({
	fieldId,
	title,
	placeHolder,
	searchPlaceHolder,
	name,
	backButtonTraduction,
	nextButtonTraduction,
	useBackButton,
	iconClassName,
	required,
	options,
	colorSecondary,
	optionalText,
	className,
	desktopModalClassName,
	useOptionalText,
	errors,
	isSecondaryTemplate,
	isTertiaryTemplate,
	isMobile,
	truncate,
	register,
	setValue,
	getValues,
	trigger,
	nextCallBack,
	backCallBack,
	visibleTitle,
	useLightTemplate,
	defaultValue,
	showMobileActions,
	arlineHandler,
}: FilterFieldProps): JSX.Element => {
	const inputsNames: FilterFieldInputNamesInterface = SearchFieldsUtil.getFilterInputsNames(name);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showMobileModal, setShowMobileModal] = useState<boolean>(true);
	const showModalHandler = useCallback((show: boolean) => {
		setShowModal(show);
	}, []);

	const [initDefaultValue, setInitDefaultValue] = useState<OptionSelect>();
	useEffect(() => {
		if (defaultValue && setValue) {
			setInitDefaultValue(defaultValue);

			setValue(inputsNames.hiddenName, defaultValue.value);

			setValue(inputsNames.name, defaultValue.label);
		}
	}, [defaultValue]);

	const listHandler = useCallback(
		(selectedOption: OptionSelect): void => {
			if (setValue) {
				const tempAirlineNames: FilterFieldInputNamesInterface = SearchFieldsUtil.getFilterInputsNames(name);
				setValue(tempAirlineNames.name, selectedOption.label);

				setValue(tempAirlineNames.hiddenName, selectedOption.value);

				arlineHandler && arlineHandler(selectedOption);
			}

			showModalHandler(false);
		},
		[name, setValue],
	);

	const clickNextHandler = useCallback(async () => {
		if (trigger) {
			const tempNames: FilterFieldInputNamesInterface = SearchFieldsUtil.getFilterInputsNames(name);
			const resultName = await trigger(tempNames.name, { shouldFocus: true });
			const resultHiddenName = await trigger(tempNames.hiddenName, { shouldFocus: true });
			if (resultName && resultHiddenName && nextCallBack) {
				nextCallBack({ id: fieldId || SearchFieldsNames.filter });

				showModalHandler(false);
			}
		}
	}, [name, fieldId, nextCallBack, trigger]);

	const clickBackHandler = useCallback(() => {
		if (backCallBack) {
			backCallBack({ id: fieldId || SearchFieldsNames.filter });

			showModalHandler(false);
		}
	}, [fieldId, backCallBack]);

	const showMobileModalHandler = (show: boolean): void => {
		setShowMobileModal(show);

		setShowModal(show);
	};

	return (
		<>
			<div
				className={`filterField w-full ${
					isSecondaryTemplate ? 'secondaryTemplate' : 'bg-white h-screen max-h-screen'
				} md:bg-transparent absolute md:relative md:max-h-[none] md:h-auto left-0 top-0 flex flex-col ${!showMobileModal ? '!h-auto' : ''} ${
					className || ''
				}`}
			>
				<div
					className={`filterField__inputContainer flex flex-col md:flex-row ${
						isSecondaryTemplate || isTertiaryTemplate
							? 'md:px-0 md:py-0'
							: `bg-white px-[40px] md:px-0 py-[25px] md:py-[10px] md:pr-[10px] ${
								useLightTemplate ? 'font-bold md:w-[170px] md:self-end' : 'border-b'
							  } border-0 border-b md:border border-solid rounded-none md:rounded-[10px]`
					}  relative
						${isTertiaryTemplate ? 'px-[40px] py-[20px] gap-2' : ''}
					`}
					onClick={useLightTemplate ? () => showMobileModalHandler(true) : () => showModalHandler(!showModal)}
				>
					<div
						className={`filterField__title flex flex-row items-center border-0 ${iconClassName ? 'md:border-r md:border-solid' : ''}
							${isTertiaryTemplate ? 'hidden' : ''}
						`}
						style={{ borderColor: colorSecondary }}
					>
						{!!iconClassName && (
							<div className={'filterField__ico flex items-center md:justify-center min-w-[20px] w-[20px] md:min-w-[70px] md:w-[70px] pointers-none'}>
								<span className={`${iconClassName} text-[15px] md:text-[25px]`} style={{ color: colorSecondary }} />
							</div>
						)}
						<span
							className={`${iconClassName ? 'pl-[10px]' : ''} ${
								isSecondaryTemplate && isMobile ? 'pb-[6px] text-[#344054] text-sm font-medium leading-5' : 'text-xs text-[#616161]'
							} md:hidden  whitespace-nowrap`}
							style={{
								color: isSecondaryTemplate ? undefined : colorSecondary,
							}}
						>
							{title || 'title'}
						</span>
					</div>
					<div
						className={`w-full flex flex-col md:flex-grow font-family-regular ${
							isSecondaryTemplate || isTertiaryTemplate ? 'pl-0 gap-2' : `${useLightTemplate ? 'justify-start pl-0' : 'pl-[30px]'} md:pl-[25px]`
						} `}
					>
						{visibleTitle && (
							<span
								className={`hidden md:block 
									${isSecondaryTemplate ? 'text-gray-700 text-sm font-medium leading-5' : 'text-xs text-[#616161]'} whitespace-nowrap 
									${useLightTemplate ? 'text-end' : ''}
									${isTertiaryTemplate ? '!text-[13px] !text-[#4D4D4F] font-medium' : ''}
								`}
								style={{
									color: isSecondaryTemplate || isTertiaryTemplate ? undefined : colorSecondary,
									lineHeight: isTertiaryTemplate ? 'inherit' : '',
								}}
							>
								{title || 'title'}
								{isSecondaryTemplate && useOptionalText && <span className='text-gray-400 ml-[4px]'>({optionalText || 'optional'})</span>}
							</span>
						)}
						<div
							className={`filterField__container relative w-full flex flex-row flex-grow items-center 
								${isSecondaryTemplate || isTertiaryTemplate ? 'gap-2 py-2.5 px-3.5 bg-white rounded-[8px] border border-solid' : '!py-0 !px-0'}
								${isTertiaryTemplate ? 'border-[#BEBEBE] text-[#BEBEBE] border border-solid rounded-none' : ''}
							`}
							style={
								useLightTemplate
									? {
										color: colorSecondary,
									  }
									: undefined
							}
						>
							<div className='filterField__hiddenInput h-0 w-0 overflow-hidden opacity-0 absolute left-[50%] top-[100%]'>
								<Input
									register={register(inputsNames.hiddenName, { required })}
									required={required}
									type={ElementTypes.text}
									autocomplete={'off'}
									defaultValue={initDefaultValue?.value || ''}
								/>
							</div>
							<Input
								register={register(inputsNames.name, {
									required,
								})}
								required={required}
								readonly={true}
								defaultValue={initDefaultValue?.label || ''}
								type={ElementTypes.text}
								placeholder={placeHolder || 'filterPlaceHolder'}
								className={`filterField__input !py-0 !px-0 flex-grow ${
									errors[inputsNames.name] || errors[inputsNames.hiddenName] ? 'border-2 border-red-500 text-red-500' : ''
								}`}
								inputClassName={`bg-transparent !p-0 font-normal md placeholder:text-base placeholder:font-normal md:placeholder placeholder:text-[#C3C7CE]  ${
									useLightTemplate ? 'md:!text-right md:!mr-5 !pl-0' : ''
								}
									${isTertiaryTemplate ? 'text-base !text-[14px] text-[#4D4D4F] placeholder:text-sm placeholder:text-[#9E9E9E]' : '!text-base'}
								`}
								autocomplete={'off'}
								style={{
									color: isSecondaryTemplate || isTertiaryTemplate ? undefined : colorSecondary,
								}}
								truncate={truncate}
								isTertiaryTemplate={isTertiaryTemplate}
							/>
							<div className={'flex items-center md:justify-center pointers-none'}>
								<span
									className={`${showModal ? 'chevronUpIco' : 'chevronBottomIco'} text-[7px]  
									${useLightTemplate ? 'mt-1' : ''}
									${isTertiaryTemplate ? 'text-[#6D6E71] !text-[5px] hidden md:block' : ''}
								`}
								/>
							</div>
						</div>
					</div>
				</div>
				{showModal && (
					<>
						<Backdrop
							show={showModal}
							onClick={() => showModalHandler(false)}
							zIndex={10}
							backgroundColor={'transparent'}
							opacity={1}
							className='hidden md:block filterField__backdrop'
							portalBackdrop={true}
							styles={`
                  .filterField__backdrop {
                    display: none;
                  }

                  @media(min-width: 768px) {
                    .filterField__backdrop {
                      display: block;
                    }
                  }
                `}
						/>
						<div
							className={`filterField__desktopModal md:block w-full md:min-w-full md:absolute md:z-[12] md:left-0 
								${useLightTemplate ? 'md:!flex justify-end' : ''} ${isSecondaryTemplate ? 'top-[105%]' : 'md:w-auto top-[120%]'} 
								${desktopModalClassName || ''} ${isSecondaryTemplate ? 'flex' : ' hidden'}
								${isTertiaryTemplate ? '!top-[98%]' : ''}
							`}
						>
							<FilterFieldList
								placeHolder={searchPlaceHolder || 'searchFilterPlaceHolder'}
								options={options || []}
								defaultValue={getValues ? getValues(inputsNames.name) || '' : ''}
								colorSecondary={colorSecondary}
								callBack={listHandler}
								lightTemplate={useLightTemplate}
								isSecondaryTemplate={isSecondaryTemplate}
								isTertiaryTemplate={isTertiaryTemplate}
								truncate={truncate}
							/>
						</div>
					</>
				)}

				{showMobileModal && (!isSecondaryTemplate || showMobileActions) && (
					<>
						<div className={'filterField__mobileModal block md:hidden w-full h-full px-0 py-[2px] flex-grow overflow-y-hidden'}>
							<FilterFieldList
								placeHolder={searchPlaceHolder || 'searchFilterPlaceHolder'}
								options={options || []}
								defaultValue={getValues ? getValues(inputsNames.name) || '' : ''}
								colorSecondary={colorSecondary}
								callBack={listHandler}
								lightTemplate={useLightTemplate}
								isSecondaryTemplate={isSecondaryTemplate}
								isTertiaryTemplate={isTertiaryTemplate}
								truncate={truncate}
							/>
						</div>
						<div
							className={`filterField__actions w-full pb-[15px] md:hidden px-[40px] bg-white border-0 border-t border-solid ${
								useLightTemplate ? 'mb-[100px]' : ''
							}`}
						>
							<Button
								onClick={useLightTemplate ? () => showMobileModalHandler(false) : clickNextHandler}
								type='button'
								customClass={`filterField__nextButton my-[15px] w-full flex items-center justify-center text-base text-white font-family-regular ${
									isSecondaryTemplate ? 'rounded-full' : 'rounded-[10px]'
								} ${isTertiaryTemplate ? 'rounded-none' : ''}`}
								styles={{
									background: colorSecondary,
								}}
								text={nextButtonTraduction || 'next'}
							/>
							{useBackButton && (
								<Button
									onClick={clickBackHandler}
									type='button'
									customClass={`filterField__backButton my-0 py-0 w-full flex items-center justify-center text-base bg-transparent ${
										isSecondaryTemplate ? 'rounded-full border border-gray-300' : 'rounded-[10px]'
									}`}
									styles={{
										color: colorSecondary,
									}}
									text={backButtonTraduction || 'back'}
								/>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};

FilterField.defaultProps = {
	colorSecondary: '#878787',
};

export default FilterField;
