import { CarInterfaceV2 } from '../../../../shared/interfaces/car-recommendation-v2.interface';
import CoverageCarCard from '../../../../components/cars/coverage-car-card/coverage-car-card';
import { CarsRatesModelsV2 } from '../../../../shared/enums/filter-cars-cards-v2.enum';
import IcomoonIcon from '../../../../../ui-elements/icomoon-icon/icomoon-icon';
import styles from './skeleton-cars-recommendation-v2.module.scss';
import Button from '../../../../../ui-elements/button/button';
import React from 'react';

export interface CarsRecommendationV2SkeletonProps {
	textureImage?: string;
}

export function CarsRecommendationV2Skeleton({ textureImage }: CarsRecommendationV2SkeletonProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className='carsRecommendationV2Skeleton animate-pulse flex flex-row items-start'>
				<div className={'carsRecommendationV2Skeleton__filterContainer hidden md:block w-[410px] rounded-[20px]'}>
					<div
						className={'w-[410px] bg-[#7a80831a] rounded-[20px] p-12'}
						style={{
							backgroundImage: textureImage ? `url(${textureImage})` : undefined,
						}}
					>
						<p className='carsRecommendationV2Skeleton__filterTitle mb-6 bg-gray-200 h-5 w-[20%]' />
						{Array(3)
							.fill(0)
							.map((item: number, index: number) => {
								return (
									<React.Fragment key={index}>
										<div className='flex flex-col'>
											<p className='carsRecommendationV2Skeleton__filterLabel bg-gray-200 h-3 w-[25%]' />
											<div className='mt-4 border-gray-200 rounded-[10px] border-[1px] py-[16px] px-[25px]'>
												<p className='carsRecommendationV2Skeleton__filterSelect bg-gray-200 h-3 w-[25%]' />
											</div>
										</div>
										<div className='w-full border-[#DDDDDD] border-b-[1px] my-6' />
									</React.Fragment>
								);
							})}
						<div className='flex flex-col'>
							<p className='carsRecommendationV2Skeleton__filterLabel bg-gray-200 h-3 w-[25%]' />
							<div className='w-full border-[#DDDDDD] border-b-[5px] mt-6' />
							<div className='flex flex-row items-center justify-between'>
								{Array(2)
									.fill(0)
									.map((item: number, index: number, items: number[]) => {
										return (
											<div key={index} className='w-[40%] flex flex-col'>
												<div className='mt-4 border-gray-200 rounded-[10px] border-[1px] py-[16px] px-[25px]'>
													<p className='bg-gray-200 h-3 w-[60%]' />
												</div>
												<p className={`bg-gray-200 h-3 w-[40%] mt-[5px] ${index === items.length - 1 ? 'self-end' : ''}`} />
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</div>
				<div className={'flex-grow flex flex-col pl-0 md:pl-12'}>
					<div className='flex flex-row justify-between items-center'>
						<p className='bg-gray-200 h-3 w-[40%] md:w-[20%]' />
						<div className='flex flex-row items-center'>
							<div className='flex flex-row items-center w-[70px] mr-3.5'>
								<span className='cashIco text-gray-200 text-[15px] md:text-[20px] mr-[5px]' />
								<p className='bg-gray-200 h-3 w-full' />
							</div>
							<Button
								onClick={() => null}
								content={<IcomoonIcon icon='listIco' fontSize={15} className='text-gray-200' />}
								customClass={
									'carsRecommendationV2Skeleton__activeHeaderButton cursor-default bg-gray-100 flex flex-row items-center justify-center mr-3.5 md:mr-6 rounded-[5px]'
								}
								noHover={true}
							/>
							<Button
								onClick={() => null}
								content={<IcomoonIcon icon='squaresIco' fontSize={15} className='text-gray-200' />}
								customClass={
									'carsRecommendationV2Skeleton__headerButton cursor-default flex flex-row items-center justify-center carsRecommendationV2__headerButton'
								}
								noHover={true}
							/>
						</div>
					</div>
					<div className='pt-8 overflow-y-auto'>
						{Array(3)
							.fill(0)
							.map((item: number, index: number, items: number[]) => {
								return (
									<CoverageCarCard
										key={index}
										loading={true}
										carInfo={{} as unknown as CarInterfaceV2}
										dailyRateText=''
										discountText=''
										includeAirConditionText=''
										includeLargeAirConditionText=''
										passengersText=''
										doorsText=''
										baggageText=''
										manualTransmissionText=''
										automaticTransmissionText=''
										rateText=''
										coverageButtonText=''
										rateModel={CarsRatesModelsV2.rate}
										multipleCompanies={true}
										showRate={true}
										showButtons={true}
										textureImage={textureImage}
										className={'bg-white mx-auto w-[95%] md:w-[720px] md:min-w-[700px] rounded-xl mb-5 carsRecommendationV2Skeleton__coverageCarCard'}
										companyImageClassName='h-[15px] w-[41px]'
										carImageClassName='h-36 w-60'
										carContainerClassName=''
									/>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
}

export default CarsRecommendationV2Skeleton;
