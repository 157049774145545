import { CMSGeneralComponentInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import SliderCategoriesSkeleton from './skeleton/skeleton-slider-categories';
import IcomoonIcon from '../../../../ui-elements/icomoon-icon/icomoon-icon';
import ButtonLink from '../../../../ui-elements/button-link/button-link';
import useWindowWidth from '../../../../../shared/hooks/useWidth';
import useSlider from '../../../../../shared/hooks/useSlider';
import styles from './slider-categories.module.scss';
import Slider from '../slider/slider';
import { useEffect } from 'react';

export interface SliderCategoriesProps extends CMSGeneralComponentInterface {
	loading?: boolean;
	transitionTime?: number;
	mainColor?: string;
	secondColor?: string;
	className?: string;
	titleClassName?: string;
	buttonClassName?: string;
	slideTitleClassName?: string;
	sliderClassName?: string;
	categoriesContainerClassName?: string;
	containerButtonClassName?: string;
}

export function SliderCategories({
	title,
	linkText,
	link,
	slides,
	loading,
	transitionTime,
	mainColor,
	secondColor,
	className,
	titleClassName,
	buttonClassName,
	slideTitleClassName,
	sliderClassName,
	categoriesContainerClassName,
	containerButtonClassName,
}: SliderCategoriesProps): JSX.Element {
	const responsiveSize: number = 768;
	const mediumResponsiveSize: number = 1100;
	const windowWidth: number = useWindowWidth();
	const slidesToShow: number = windowWidth < responsiveSize ? 1 : windowWidth < mediumResponsiveSize ? 2 : 3;
	const slidesMargin: number = windowWidth < responsiveSize ? 0 : windowWidth < mediumResponsiveSize ? 20 : 30;
	const sliderSideMargin: number = 0;
	const { nextPosition, previousPosition, sliderRef, slidesContainerRef, slidesWidth } = useSlider(
		slides.length - 1,
		slidesToShow,
		slidesToShow,
		transitionTime,
		0,
		slidesMargin,
		sliderSideMargin,
		false,
	);

	useEffect(() => {
		const root = document.documentElement;
		if (mainColor) {
			root?.style.setProperty('--slider-categories-main-color', mainColor);
		}
	}, [mainColor]);

	return loading ? (
		<SliderCategoriesSkeleton
			className={className}
			titleClassName={titleClassName}
			categoriesContainerClassName={categoriesContainerClassName}
			sliderClassName={sliderClassName}
			slideTitleClassName={slideTitleClassName}
			containerButtonClassName={containerButtonClassName}
		/>
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`sliderCategories flex flex-col ${className || ''}`}>
				<h2 className={`font-bold text-black mb-4 md:mb-8 text-left text-base md:text-2xl ${titleClassName || ''}`}>{title}</h2>
				<div className={`flex flex-col md:flex-row justify-center items-center mb-7 md:mb-10 ${categoriesContainerClassName || ''}`}>
					{windowWidth >= responsiveSize ? (
						<div
							className={'cursor-pointer mr-5 sliderCategories__iconButton'}
							style={{
								color: secondColor,
							}}
							onClick={previousPosition}
						>
							<IcomoonIcon icon='chevronLeftIco' fontSize={17} />
						</div>
					) : (
						<div className='w-full flex flex-row justify-end items-center mb-7'>
							<div
								className={'cursor-pointer mr-8 sliderCategories__iconButton'}
								style={{
									color: secondColor,
								}}
								onClick={previousPosition}
							>
								<IcomoonIcon icon='chevronLeftIco' fontSize={17} />
							</div>
							<div
								className={'cursor-pointer mr-5 sliderCategories__iconButton'}
								style={{
									color: secondColor,
								}}
								onClick={nextPosition}
							>
								<IcomoonIcon icon='chevronRightIco' fontSize={17} />
							</div>
						</div>
					)}
					<div className={`sliderCategories__sliderContainer w-full flex-grow ${sliderClassName || ''}`}>
						<Slider
							slides={slides}
							slidesHeight='200px'
							config={{
								showContent: false,
								showLinkButton: false,
								showSubtitleLine: false,
								showOutContent: true,
								showBackgroundImage: true,
								showImageBackdrop: true,
							}}
							className='w-full justify-between items-center'
							outerClassName={'w-full'}
							slidesClassNames={{
								className: 'rounded-xl w-full',
								outClassName: 'sliderCategories__slide w-full md:w-[31%]',
								imageClassName: 'rounded-xl object-cover',
								imageBackdropClassName: 'sliderCategories__backdrop',
								titleClassName: `text-xs md:text-base ${slideTitleClassName || ''}`,
								outContentClassName: 'mt-2.5 md:mt-4',
								mainColor,
								iconClassName: 'font-black',
							}}
							sliderRef={sliderRef}
							slidesContainerRef={slidesContainerRef}
							slidesWidth={slidesWidth}
							slidesMargin={slidesMargin}
							sliderSideMargin={sliderSideMargin}
							slidesNumberShow={slidesToShow}
						/>
					</div>
					{windowWidth >= responsiveSize && (
						<div
							className={'cursor-pointer ml-5 sliderCategories__iconButton'}
							style={{
								color: secondColor,
							}}
							onClick={nextPosition}
						>
							<IcomoonIcon icon='chevronRightIco' fontSize={17} />
						</div>
					)}
				</div>
				<div className={`flex flex-col ${containerButtonClassName || ''}`}>
					<ButtonLink
						href={link || ''}
						className={`w-full md:w-auto rounded-full text-black bg-white border-2 opacity-80 text-base mx-auto sliderCategories__button ${
							buttonClassName || ''
						}`}
						style={{
							borderColor: secondColor,
						}}
					>
						<>
							<span className='mr-2.5'>{linkText}</span>
							<IcomoonIcon icon='iconIconArrowRightSelect' fontSize={14} />
						</>
					</ButtonLink>
				</div>
			</div>
		</>
	);
}

SliderCategories.defaultProps = {
	className: '',
	titleClassName: '',
	buttonClassName: '',
	slideTitleClassName: '',
	sliderClassName: '',
	categoriesContainerClassName: '',
	containerButtonClassName: '',
};

export default SliderCategories;
