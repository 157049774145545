import { ListInterface, ListItemInterface } from '../../../shared/interfaces/list.interface';
import Image from '../../ui-elements/image/image';
import List from '../../ui-elements/list/list';

export interface NavBarProps {
	items: ListInterface[];
	activeItemId?: string;
	logoImage?: string;
	backgroundColor?: string;
	activeItemColor?: string;
	deactiveItemColor?: string;
	imageWidth?: string;
	onClick: (item: ListItemInterface) => ListItemInterface;
	className?: string;
	listClassName?: string;
}

export function NavBar(props: NavBarProps): JSX.Element {
	const style: React.CSSProperties = {
		backgroundColor: props.backgroundColor,
	};

	return (
		<aside className={`flex flex-col items-center pt-6 px-6 ${props.className || ''}`} style={{ ...style }}>
			{props.logoImage && <Image url={props.logoImage} width={props.imageWidth} mobileLargeHeight={true} />}
			<div className={'flex flex-col items-start'}>
				<List
					items={props.items}
					callBack={props.onClick}
					activeItemId={props.activeItemId}
					className={`flex flex-col ${props.listClassName || ''}`}
					withPointer={true}
					deactiveColor={props.deactiveItemColor}
					activeColor={props.activeItemColor}
				/>
			</div>
		</aside>
	);
}

NavBar.defaultProps = {
	className: '',
	listClassName: '',
};

export default NavBar;
