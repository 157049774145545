import { Component } from 'react';

const currentDomain: string = process.env.REACT_APP_CURRENT_DOMAIN || '';
export const publicFonts = `
  @font-face {
    font-family: "Lato-Bold";
    src: local("Lato-Bold"),
    url("${currentDomain}/public/fonts/Lato-Bold.ttf"),
    url("dist/fonts/Lato-Bold.ttf");
    font-display: swap;
  }
  @font-face {
    font-family: "Lato-Regular";
    src: local("Lato-Regular"),
    url("${currentDomain}/public/fonts/Lato-Regular.ttf"),
    url("dist/fonts/Lato-Regular.ttf");;
    font-display: swap;
  }
  @font-face {
    font-family: "Lato-Black";
    src: local("Lato-Black"),
    url("${currentDomain}/public/fonts/Lato-Black.ttf"),
    url("dist/fonts/Lato-Black.ttf");
    font-display: swap;
  }
  @font-face {
    font-family: "icomoon";
    src: local("icomoon"),
    url("${currentDomain}/public/fonts/icomoon/icomoon.woff") format("woff"),
    url("${currentDomain}/public/fonts/icomoon/icomoon.svg") format("svg"),
    url("dist/fonts/icomoon/icomoon.woff") format("woff"),
    url("dist/fonts/icomoon/icomoon.svg") format("svg");
    font-weight: normal;
    font-size: normal;
    font-display: block;
  }
  @font-face {
    font-family: 'Poppins-SemiBold';
    src: local('Poppins-SemiBold'), url('${currentDomain}/public/static-fonts/poppins/Poppins-SemiBold.ttf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins-Medium'), url('${currentDomain}/public/static-fonts/poppins/Poppins-Medium.ttf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins-Regular';
    src: local('Poppins-Regular'), url('${currentDomain}/public/static-fonts/poppins/Poppins-Regular.ttf');
    font-display: swap;
  }
  `;
class ExposePublicFonts extends Component {
	componentDidMount(): void {
		if (document.querySelector('style[data-description="smartlinks-fonts"]')) {
			return;
		}

		const style = document.createElement('style');
		style.dataset.description = 'smartlinks-fonts';

		style.appendChild(document.createTextNode(publicFonts));

		document.head.appendChild(style);
	}

	render(): JSX.Element {
		return <></>;
	}
}
export default ExposePublicFonts;
