import DestinationField from '../../../shared-components/search/destination-field/destination-field';
import ButtonSearch from '../../../shared-components/search/button-search/button-search';
import DatesField from '../../../shared-components/search/dates-field/dates-field';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import { OptionSelect } from '../../../../shared/interfaces/inputs.interface';
import usePlansSearchForm from '../../shared/hooks/usePlansSearchForm';
import {
	UsePlansSearchFormInterface,
	UsePlansSearchFormReturnInterface,
	PlansSearchFormSubmitInterface,
} from '../../shared/interfaces/plans-search-form.interface';
import Button from '../../../ui-elements/button/button';
import Select from '../../../ui-elements/select/select';
import styles from './plans-search-form.module.scss';

export interface PlansSearchFormProps {
	colorPrimary?: string;
	colorSecondary?: string;
	traductions: (label: string) => string;
	categories: OptionSelect[];
	calendarRestriction?: number;
	history: PlansSearchFormSubmitInterface | null;
	className?: string;
	emitSubmitSearch: (data: PlansSearchFormSubmitInterface) => void;
	emitShowMobileFields?: (show: boolean) => void;
}

export function PlansSearchForm({
	colorPrimary,
	colorSecondary,
	traductions,
	categories,
	calendarRestriction,
	history,
	className,
	emitSubmitSearch,
	emitShowMobileFields,
}: PlansSearchFormProps): JSX.Element {
	const {
		destinationName,
		destinationHiddenName,
		datesName,
		datesHiddenName,
		destinationId,
		datesId,
		mobileFieldStep,
		buttonSearchHistory,
		minDate,
		defaultDates,
		showMobileFieldsContainer,
		mobileFormHeight,
		errors,
		register,
		getValues,
		setValue,
		trigger,
		watch,
		showMobileFieldsContainerHandler,
		destinationFieldHandler,
		datesHandler,
		nextMobileFieldHandler,
		backMobileFieldHandler,
		onSubmit,
	}: UsePlansSearchFormReturnInterface = usePlansSearchForm({
		history,
		calendarRestriction,
		traductions,
		emitSubmitSearch,
		emitShowMobileFields,
	} as unknown as UsePlansSearchFormInterface);

	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`plansSearchForm w-full flex flex-col ${className || ''}`}>
				<div className='plansSearchForm__searchButton block md:hidden'>
					<ButtonSearch
						iconName='plansIco'
						placeHolder={traductions('startYourPlansSearch') || 'startYourPlansSearch'}
						emitClick={() => showMobileFieldsContainerHandler(true)}
						colorSecondary={colorSecondary}
						history={buttonSearchHistory}
						searchText={traductions('search') || 'search'}
					/>
				</div>
				<form
					onSubmit={onSubmit}
					className={`plansSearchForm__form w-full top-0 left-0 md:flex flex-col ${
						showMobileFieldsContainer ? 'md:!h-auto fixed md:relative flex' : 'hidden'
					}`}
					style={{
						height: showMobileFieldsContainer ? `${mobileFormHeight}px` : 'auto',
					}}
				>
					<div
						className='plansSearchForm__header block md:hidden pt-[33px] pb-[53px] px-[45px]'
						style={{
							backgroundColor: colorSecondary,
						}}
					>
						<button
							onClick={() => showMobileFieldsContainerHandler(false)}
							className='plansSearchForm__backBtn chevronLeftIco !p-0 text-white text-base font-bold'
							type='button'
						>
							<span className='pl-[10px]'>{traductions('getBack') || 'getBack'}</span>
						</button>
					</div>
					<div
						className={
							'plansSearchForm__body bg-white md:bg-transparent left-0 top-[-20px] md:top-0 relative md:h-auto px-0 pt-[20px] md:pt-0 rounded-t-[20px] md:rounded-t-none'
						}
					>
						<div className='plansSearchForm__content relative'>
							<div className={'plansSearchForm__firstRow flex flex-row flex-wrap'}>
								<div className='plansSearchForm__destination w-full'>
									<DestinationField
										fieldId={destinationId}
										name={destinationName}
										hiddenName={destinationHiddenName}
										iconClassName={'pinIco'}
										title={traductions('destination') || 'destination'}
										placeHolder={traductions('destination') || 'destination'}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										showSpinner={false}
										colorSecondary={colorSecondary}
										className={`${mobileFieldStep === destinationId ? 'z-50' : 'z-0'} md:z-auto`}
										required={true}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										errors={errors}
										useBackButton={false}
										onChange={destinationFieldHandler}
										onShowDestinations={() => undefined}
										nextCallBack={nextMobileFieldHandler}
										destinations={[]}
									/>
								</div>
							</div>
							<div className={'plansSearchForm__lastRow flex flex-row flex-wrap md:mt-[15px] items-end'}>
								<div className='plansSearchForm__dates w-full md:w-2/5 relative md:pr-[15px]'>
									<DatesField
										fieldId={datesId}
										title={traductions('selectYourDate') || 'selectYourDate'}
										placeHolder={traductions('selectYourDate') || 'selectYourDate'}
										name={datesName}
										hiddenName={datesHiddenName}
										backButtonTraduction={traductions('back') || 'back'}
										nextButtonTraduction={traductions('next') || 'next'}
										buttonText={traductions('ready') || 'ready'}
										type={DatesFieldTypes.oneDate}
										config={{
											date: true,
											time: false,
										}}
										minDate={minDate}
										defaultDates={defaultDates}
										useBackButton={true}
										iconClassName={'searchCalendarIco'}
										required={true}
										colorPrimary={colorPrimary}
										colorSecondary={colorSecondary}
										className={`w-full ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
										register={register}
										setValue={setValue}
										getValues={getValues}
										trigger={trigger}
										watch={watch}
										errors={errors}
										nextCallBack={nextMobileFieldHandler}
										backCallBack={backMobileFieldHandler}
										onChange={datesHandler}
										onShowDates={() => undefined}
									/>
								</div>
								<div className='plansSearchForm__categories w-full md:w-2/5 relative flex flex-row flex-wrap md:pr-[15px]'>
									<Select
										selectClassName='bg-white !p-0 m-0 mt-0 !text-base placeholder:text-base'
										className={'py-[10px] px-[15px] border border-solid rounded-[10px] w-full'}
										customSelectClassName='bg-white !p-0 m-0 text-base'
										customSelectPlaceholderClassName='bg-white !p-0 text-xs font-normal text-[#C3C7CE]'
										labelClassName='text-[10px]'
										name={''}
										label={traductions('categories') || 'categories'}
										options={categories}
										onChange={() => {}}
										defaultValue={''}
										placeHolder={traductions('selectTheCategory') || 'selectTheCategory'}
									/>
								</div>
								<div className={'plansSearchForm__footer md:relative md:w-1/5 hidden md:flex md:h-[65px]'}>
									<Button
										type='submit'
										customClass='plansSearchForm__searchBtn rounded-lg text-white w-full text-[21px] items-center'
										content={
											<>
												<span className='searchIco text-[25px] font-extrabold' />
												<span className='text-[21px] font-extrabold pl-[10px]'>{traductions('search') || 'search'}</span>
											</>
										}
										styles={{
											background: colorSecondary || '#878787',
										}}
									/>
								</div>
							</div>
							<div className={'block md:hidden w-full z-40 bg-white absolute left-0 top-[20px] h-screen'} />
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default PlansSearchForm;
