import { SlideInterface, SlideConfigInterface, SlideStylesInterface } from '../../../shared/interfaces/generics/slider.interface';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import ButtonLink from '../../ui-elements/button-link/button-link';
import SlideSkeleton from './skeleton/skeleton-slide';
import Image from '../../ui-elements/image/image';
import styles from './slide.module.scss';

export interface SlideProps extends SlideStylesInterface {
	data: SlideInterface;
	height?: string;
	config?: SlideConfigInterface;
	loading?: boolean;
	slideId?: string;
	children?: JSX.Element;
	onClick?: (id: string) => void;
	style?: React.CSSProperties;
	skeletonClassNames?: SlideStylesInterface;
}

export const Slide = ({
	data,
	height,
	config,
	loading,
	slideId,
	children,
	onClick,
	style,
	skeletonClassNames,
	className,
	outClassName,
	imageClassName,
	imageBackdropClassName,
	contentClassName,
	titleClassName,
	subtitleClassName,
	descriptionClassName,
	subtitleLineClassName,
	buttonClassName,
	outContentClassName,
	iconClassName,
	mainColor,
}: SlideProps): JSX.Element => {
	return loading ? (
		<SlideSkeleton height={height} config={config} styles={skeletonClassNames}>
			{children}
		</SlideSkeleton>
	) : (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div
				className={`slide ${onClick ? 'cursor-pointer' : ''} ${outClassName || ''}`}
				onClick={onClick ? () => onClick(slideId || '') : undefined}
				style={style}
			>
				<div
					className={`slide__content relative ${className || ''}`}
					style={{
						minHeight: height,
					}}
				>
					{config?.showBackgroundImage && data.image && (
						<Image
							url={data.image}
							mobileLargeHeight={false}
							className={`slide__image ${config.showImageBackdrop ? `slide__backdrop ${imageBackdropClassName || ''}` : ''}`}
							customClass={`w-full ${imageClassName || ''}`}
							styles={{
								height,
							}}
						/>
					)}
					{config?.showContent &&
						(children || (
							<div className={`slide__info-content absolute bottom-0 left-0 ${contentClassName || ''}`}>
								{config?.showSubtitleLine && (
									<div className={`slide__line h-px w-6 md:w-12 border-b-4 rounded-xl mb-2 ${subtitleLineClassName || ''}`} />
								)}
								{data.subtitle && (
									<p
										className={`slide__subtitle font-black text-left ${subtitleClassName || ''}`}
										dangerouslySetInnerHTML={{ __html: data.subtitle || '' }}
									/>
								)}
								<h3
									className={`slide__title font-black mb-2.5 text-left ${titleClassName || ''}`}
									dangerouslySetInnerHTML={{ __html: data.title || '' }}
								/>
								<p
									className={`slide__description font-normal text-left ${descriptionClassName || ''}`}
									dangerouslySetInnerHTML={{ __html: data.description || '' }}
								/>
								{config?.showLinkButton && (
									<ButtonLink href={data.link || ''} className={`slide__button ${buttonClassName || ''}`}>
										{data.linkText || ''}
									</ButtonLink>
								)}
							</div>
						))}
				</div>
				{config?.showOutContent && (
					<div className={`slide__out-content ${outContentClassName || ''}`}>
						<a href={data.link || ''}>
							<h3 className={`slide__title flex flex-row justify-start items-center font-black mb-2.5 text-left ${titleClassName || ''}`}>
								{data.iconClassName && <IcomoonIcon icon={data.iconClassName} className={`mr-2.5 ${iconClassName || ''}`} color={mainColor} />}
								{data.title}
							</h3>
						</a>
					</div>
				)}
			</div>
		</>
	);
};

Slide.defaultProps = {
	className: '',
	outClassName: '',
	imageClassName: '',
	imageBackdropClassName: '',
	contentClassName: '',
	titleClassName: '',
	subtitleClassName: '',
	descriptionClassName: '',
	buttonClassName: '',
	subtitleLineClassName: '',
	outContentClassName: '',
	iconClassName: '',
};

export default Slide;
