import useCarSearchForm, { UseCarSearchFormInterface, UseCarSearchFormReturnInterface } from '../../../../shared/hooks/useCarSearchForm';
import CarDestinations from '../../../../components/cars/car-search-form/car-destinations/car-destinations';
import { ButtonSearch, DatesField, DestinationField, DiscountField } from '../../../../components';
import { ListItemInterface } from '../../../../../../shared/interfaces/list.interface';
import { CarSearchFormProps, CarSearchFormRefHandle } from '../car-search-form';
import { DatesFieldTypes } from '../../../../../../shared/enums';
import Button from '../../../../../ui-elements/button/button';
import styles from './bar-car-search-form.module.scss';
import { forwardRef } from 'react';

export const BarCarSearchForm = forwardRef<CarSearchFormRefHandle, CarSearchFormProps>(
	(
		{
			mapBoxToken,
			calendarRestriction,
			traductions,
			colorPrimary,
			colorSecondary,
			destinations,
			emitInput,
			corporateDiscounts,
			promoCodesWithoutCorporateDiscountCode,
			emitSubmitSearch,
			carHistory,
			emitOptionActive,
			openLocalities,
			isLocationsLoading,
			language,
		},
		ref,
	) => {
		const defaultHour: string = '12:00';
		const {
			departureName,
			departureHiddenName,
			arrivalName,
			arrivalHiddenName,
			datesName,
			datesHiddenName,
			discountName,
			hertzDiscountName,
			departureId,
			arrivalId,
			datesId,
			discountId,
			buttonSearchHistory,
			hertzDiscountId,
			paymentType,
			paymentOptions,
			minDate,
			defaultDates,
			departureFieldRef,
			arrivalFieldRef,
			showOtherPlaceForArrival,
			showMobileFieldsContainer,
			mobileFieldStep,
			dateMobileFieldStep,
			showMobileFieldsContainerHandler,
			onSubmit,
			nextMobileFieldHandler,
			backMobileFieldHandler,
			emitHandler,
			destinationsOtherPlaceForArrivalHandler,
			openDepartureLocalitiesHandler,
			openArrivalLocalitiesHandler,
			selectedDestination,
			changeDatesHandler,
			changeHertzDiscountHandler,
			getDiscountDisplayValue,
			changeDiscountPaymentTypeHandler,
			errors,
			register,
			getValues,
			setValue,
			trigger,
		}: UseCarSearchFormReturnInterface = useCarSearchForm({
			ref,
			traductions,
			defaultHour,
			calendarRestriction,
			carHistory,
			openLocalities,
			emitOptionActive,
			emitInput,
			emitSubmitSearch,
			language,
		} as unknown as UseCarSearchFormInterface);

		return (
			<>
				<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
				<div className='w-full'>
					<div className={'carSearchForm__search carSearchForm'}>
						<div className='carSearchForm__searchButton block md:hidden'>
							<div className={'carSearchForm__mobile'}>
								<div>
									<ButtonSearch
										iconName='carsIco'
										searchText={traductions.searchText}
										placeHolder={traductions.mobileTextBtn || 'startYourSearch'}
										emitClick={() => showMobileFieldsContainerHandler(true)}
										colorSecondary={colorSecondary}
										history={buttonSearchHistory}
									/>
								</div>
							</div>
						</div>

						<form
							onSubmit={onSubmit}
							className={`carSearchForm__form   ${
								showMobileFieldsContainer ? 'w-full top-0 left-0 md:flex flex-col h-[100vh] md:h-auto fixed md:relative flex' : 'hidden md:flex'
							}`}
						>
							<div
								className='carSearchForm__header block md:hidden pt-[33px] pb-[53px] px-[45px]'
								style={{
									backgroundColor: colorSecondary,
								}}
							>
								<button
									onClick={() => showMobileFieldsContainerHandler(false)}
									className='carSearchForm__backBtn chevronLeftIco !p-0 text-white text-base'
									type='button'
								>
									<span className='pl-[10px]'>{traductions.getBackBtn || 'getBack'}</span>
								</button>
							</div>

							<div
								className={
									'carSearchForm__body w-full rounded-t-[20px]  md:rounded-full md:shadow-[5px_5px_10px_0px_rgba(0,0,0,0.3)] left-0 top-[-20px] md:top-0 relative md:h-auto md:pr-[10px] md:pl-8 pt-[20px] md:pt-2 md:pb-2 flex md:items-center bg-white'
								}
							>
								<div className={'w-full relative flex justify-between md:items-center carSearchForm__inputsContainer'}>
									<div
										className={`carSearchForm__destination absolute md:relative flex border-r border-gray-300 border-r-solid w-full ${
											showOtherPlaceForArrival ? 'md:w-[35%]' : 'md:w-[25%]'
										}`}
									>
										<div className={`${showOtherPlaceForArrival ? 'w-full md:w-1/2' : 'w-full'}`}>
											<DestinationField
												ref={departureFieldRef}
												fieldId={departureId}
												name={departureName}
												hiddenName={departureHiddenName}
												iconClassName={'pinIco'}
												title={traductions.destinations.title}
												placeHolder={traductions.destinations.placeHolder}
												backButtonTraduction={traductions.backBtn}
												nextButtonTraduction={traductions.nextBtn}
												showSpinner={isLocationsLoading}
												colorSecondary={colorSecondary}
												className={`${mobileFieldStep === departureId ? 'z-50' : 'z-0'} md:z-auto`}
												desktopDestinationsClassName='md:top-[150%]'
												required={true}
												register={register}
												setValue={setValue}
												getValues={getValues}
												trigger={trigger}
												errors={errors}
												useBackButton={false}
												onChange={emitHandler}
												onShowDestinations={emitOptionActive}
												nextCallBack={nextMobileFieldHandler}
												visibleTitle={true}
												destinationsElement={
													<CarDestinations
														mapBoxToken={mapBoxToken}
														colorSecondary={colorSecondary}
														traductions={traductions}
														otherDestinationFlag={!showOtherPlaceForArrival}
														destinations={destinations || []}
														callBack={(item: ListItemInterface) => selectedDestination(item, departureName)}
														callBackShowOtherArrivalPlace={destinationsOtherPlaceForArrivalHandler}
														callBackOpenLocalities={openDepartureLocalitiesHandler}
													/>
												}
											/>
										</div>

										{showOtherPlaceForArrival && (
											<div className='flex flex-col border-l border-w-2 border-l-solid pl-2 w-full md:w-1/2' style={{ borderColor: colorSecondary }}>
												<DestinationField
													ref={arrivalFieldRef}
													fieldId={arrivalId}
													name={arrivalName}
													hiddenName={arrivalHiddenName}
													iconClassName={'pinIco'}
													title={traductions.destinationsArrival.title}
													placeHolder={traductions.destinationsArrival.placeHolder}
													backButtonTraduction={traductions.backBtn}
													nextButtonTraduction={traductions.nextBtn}
													showSpinner={isLocationsLoading}
													colorSecondary={colorSecondary}
													className={`w-full ${mobileFieldStep === arrivalId ? 'z-50' : 'z-0'} md:z-auto carSearchForm__arrivalDestination`}
													desktopDestinationsClassName='md:top-[150%]'
													required={showOtherPlaceForArrival}
													register={register}
													setValue={setValue}
													getValues={getValues}
													trigger={trigger}
													errors={errors}
													useBackButton={true}
													onChange={emitHandler}
													onShowDestinations={emitOptionActive}
													nextCallBack={nextMobileFieldHandler}
													backCallBack={backMobileFieldHandler}
													visibleTitle={true}
													destinationsElement={
														<CarDestinations
															mapBoxToken={mapBoxToken}
															colorSecondary={colorSecondary}
															traductions={traductions}
															otherDestinationFlag={false}
															destinations={destinations || []}
															callBack={(item: ListItemInterface) => selectedDestination(item, arrivalName)}
															callBackOpenLocalities={openArrivalLocalitiesHandler}
														/>
													}
												/>
											</div>
										)}
									</div>

									<div
										className={`carSearchForm__dates flex flex-col border-r border-gray-300 border-r-solid absolute md:relative pl-2 w-full ${
											showOtherPlaceForArrival ? 'md:w-[25%]' : 'md:w-[25%]'
										}`}
									>
										<DatesField
											fieldId={datesId}
											title={traductions.dates.title}
											placeHolder={traductions.dates.placeHolder}
											name={datesName}
											hiddenName={datesHiddenName}
											backButtonTraduction={traductions.backBtn}
											nextButtonTraduction={traductions.nextBtn}
											timeText={traductions?.datesCalendar?.time}
											startTimeText={traductions?.datesCalendar?.startTime}
											endTimeText={traductions?.datesCalendar?.endTime}
											buttonText={traductions?.datesCalendar?.button}
											type={DatesFieldTypes.startEndDates}
											visibleTitle={true}
											language={language}
											config={{
												date: true,
												time: true,
											}}
											minDate={minDate}
											defaultDates={defaultDates}
											forceMobileStep={dateMobileFieldStep}
											useBackButton={true}
											iconClassName={'searchCalendarIco'}
											required={true}
											colorPrimary={colorPrimary}
											colorSecondary={colorSecondary}
											className={`w-full ${mobileFieldStep === datesId ? 'z-50' : 'z-0'} md:z-auto`}
											desktopDateTimeClassName='md:top-[150%]'
											register={register}
											setValue={setValue}
											getValues={getValues}
											trigger={trigger}
											errors={errors}
											nextCallBack={nextMobileFieldHandler}
											backCallBack={backMobileFieldHandler}
											onChange={changeDatesHandler}
											onShowDates={emitOptionActive}
										/>
									</div>

									<div
										className={`carSearchForm__hertzDiscount flex flex-col border-r border-gray-300 border-r-solid absolute md:relative pl-2 pr-[5px] w-full ${
											showOtherPlaceForArrival ? 'md:w-[20%]' : 'md:w-[25%]'
										}`}
									>
										<DiscountField
											fieldId={hertzDiscountId}
											title={traductions.hertzGold.title}
											placeHolder={traductions.hertzGold.secondPlaceHolder}
											iconClassName={'ticketDiscountIco'}
											name={hertzDiscountName}
											backButtonTraduction={traductions.backBtn}
											nextButtonTraduction={traductions.nextBtn}
											rewardsPointsMajorTitle={traductions?.hertzGold?.parentTitle}
											rewardsPointsTitle={traductions?.hertzGold?.title}
											rewardsPointsPlaceHolder={traductions?.hertzGold?.placeHolder}
											rewardsPointsDescription={traductions?.hertzGold?.extraPlaceHolder}
											rewardsPointsExtraDescription={traductions?.hertzGold?.extraDescription}
											rewardsPointsHint={traductions?.hertzGold?.hint}
											buttonText={traductions?.actionBtn || traductions?.endBtn}
											visibleTitle={true}
											config={{
												discount: false,
												promotionalCode: false,
												rewardsPoints: true,
											}}
											useBackButton={true}
											colorSecondary={colorSecondary}
											className={'w-full z-0 md:z-auto'}
											desktopDiscountsClassName='md:top-[150%]'
											required={false}
											register={register}
											setValue={setValue}
											getValues={getValues}
											trigger={trigger}
											errors={errors}
											nextCallBack={nextMobileFieldHandler}
											backCallBack={backMobileFieldHandler}
											onShowDiscounts={emitOptionActive}
											onChangeDiscounts={changeHertzDiscountHandler}
										/>
									</div>

									<div
										className={`carSearchForm__discount flex flex-col absolute md:relative w-full pl-2 ${
											showOtherPlaceForArrival ? 'md:w-[20%]' : 'md:w-[25%]'
										}`}
									>
										<DiscountField
											fieldId={discountId}
											title={traductions.discounts.title}
											iconClassName={'flagDiscountIco'}
											placeHolder={traductions.discounts.placeHolder}
											name={discountName}
											backButtonTraduction={traductions.backBtn}
											nextButtonTraduction={traductions.nextBtn}
											discountMajorTitle={traductions?.discounts?.title}
											discountTitle={traductions?.discountItem?.title}
											discountPlaceHolder={traductions?.discountItem?.placeHolder}
											discountExtraPlaceHolder={traductions?.discountItem?.extraPlaceHolder}
											discountHint={traductions?.discountItem?.hint}
											promotionalCodeTitle={traductions?.promoCode?.title}
											promotionalCodePlaceHolder={traductions?.promoCode?.placeHolder}
											promotionalCodeExtraPlaceHolder={traductions?.promoCode?.extraPlaceHolder}
											promotionalCodeHint={traductions?.promoCode?.hint}
											rewardsPointsMajorTitle={traductions?.hertzGold?.thirdTitle}
											rewardsPointsTitle={traductions?.hertzGold?.title}
											rewardsPointsPlaceHolder={traductions?.hertzGold?.placeHolder}
											rewardsPointsHint={traductions?.hertzGold?.hint}
											buttonText={traductions?.actionBtn || traductions?.endBtn}
											visibleTitle={true}
											config={{
												discount: true,
												promotionalCode: true,
												rewardsPoints: true,
												paymentTypes: true,
											}}
											corporateDiscounts={corporateDiscounts}
											promoCodesWithoutCorporateDiscountCode={promoCodesWithoutCorporateDiscountCode}
											getPaymentOption={paymentOptions}
											defaultPaymentType={paymentType}
											useBackButton={true}
											showAllTitles={true}
											colorSecondary={colorSecondary}
											className={`w-full ${mobileFieldStep === discountId ? 'z-50' : 'z-0'} md:z-auto`}
											desktopDiscountsClassName='md:top-[150%]'
											required={false}
											register={register}
											setValue={setValue}
											getValues={getValues}
											trigger={trigger}
											errors={errors}
											nextCallBack={nextMobileFieldHandler}
											backCallBack={backMobileFieldHandler}
											onShowDiscounts={emitOptionActive}
											onChangeDiscounts={getDiscountDisplayValue}
											onChangePaymentType={changeDiscountPaymentTypeHandler}
										/>
									</div>
								</div>

								<div className={'carSearchForm__footer flex items-center justify-end absolute md:relative w-[60px] min-w-[60px]'}>
									<Button
										type='submit'
										customClass='carSearchForm__submit w-[60px] min-w-[60px] h-[60px] py-px px-px rounded-full'
										content={
											<span
												className='searchIco rounded-full p-1.5 w-full h-full flex items-center justify-center text-2xl '
												style={{
													background: colorSecondary,
												}}
											></span>
										}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	},
);

export default BarCarSearchForm;
