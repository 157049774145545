import { DisplayValuesDatesFieldInterface } from '../../../../shared/interfaces/generics/search-fields.interface';
import { SearchFieldsUtil } from '../../../../shared/utils/search-fields.util';
import { DatesFieldTypes } from '../../../../shared/enums/search-fields.enum';
import {
	HotelsDisneySearchFormSubmitInterface,
	RoomNameHotelsDisneySearchFormInterface,
	FieldRoomNameHotelsDisneySearchFormInterface,
	RoomsFieldsRestrictionsInterfaceDisney,
	RoomsHotelsDisneySearchFormSubmitInterface,
} from '../interfaces/hotels-disney-search-form.interface';

export class HotelsDisneySearchFormUtil {
	public static getRoomsInputsNames(
		name: string,
		roomsRestriction: RoomsFieldsRestrictionsInterfaceDisney,
		forcedMaxRooms?: number,
	): RoomNameHotelsDisneySearchFormInterface[] {
		const maxRooms: number = forcedMaxRooms || roomsRestriction.adults + roomsRestriction.children || 1;
		return Array(maxRooms)
			.fill(0)
			.map((item: number, index: number) => {
				return {
					name: `roomName${name}_${index}`,
					id: `roomFieldId${name}_${index}`,
				};
			});
	}

	public static findRoomNameFromId(roomsNames: RoomNameHotelsDisneySearchFormInterface[], id: string): FieldRoomNameHotelsDisneySearchFormInterface {
		let roomName: RoomNameHotelsDisneySearchFormInterface | undefined;
		const roomIndex: number | undefined = roomsNames.findIndex((name: RoomNameHotelsDisneySearchFormInterface) => name.id === id);
		if (typeof roomIndex === 'number') {
			roomName = { ...roomsNames[roomIndex] };
		}

		return {
			id,
			roomName,
			roomIndex,
		};
	}

	public static findRoomNameFromName(
		roomsNames: RoomNameHotelsDisneySearchFormInterface[],
		name: string,
	): FieldRoomNameHotelsDisneySearchFormInterface {
		let roomName: RoomNameHotelsDisneySearchFormInterface | undefined;
		const roomIndex: number | undefined = roomsNames.findIndex((room: RoomNameHotelsDisneySearchFormInterface) => room.name === name);
		if (typeof roomIndex === 'number') {
			roomName = { ...roomsNames[roomIndex] };
		}

		return {
			id: '',
			roomName,
			roomIndex,
		};
	}

	static mapSearchUrl(data: HotelsDisneySearchFormSubmitInterface): string {
		let roomsUrl: string = '1-adults_0-children';
		if (data?.rooms?.length) {
			roomsUrl = '';

			data.rooms.forEach(
				(room: RoomsHotelsDisneySearchFormSubmitInterface, index: number, rooms: RoomsHotelsDisneySearchFormSubmitInterface[]): void => {
					let agesOfChildren: string = '';
					room?.childrenAges?.forEach((age: number, ageIndex: number, ages: number[]): void => {
						agesOfChildren += `${age}${ageIndex === ages.length - 1 ? '' : '-'}`;
					});

					roomsUrl += `${room?.adults || 1}-adults_${room?.children || 0}-children${agesOfChildren ? `-${agesOfChildren}` : ''}${
						index === rooms.length - 1 ? '' : '~'
					}`;
				},
			);
		}

		const searchUrl: string = `${data?.destinationIata || ''}/${data?.departureDate || ''}/${data?.arrivalDate || ''}/${roomsUrl}`;
		return searchUrl;
	}

	public static historyHandle(
		historyJson: HotelsDisneySearchFormSubmitInterface | null = null,
		traductions?: (label: string) => string,
	): HotelsDisneySearchFormSubmitInterface {
		const destinationPosition: number = 3;
		const departurePosition: number = 4;
		const arrivalPosition: number = 5;
		const roomsPosition: number = 6;
		const routePath: string = window?.location?.pathname;
		const history: HotelsDisneySearchFormSubmitInterface =
			(historyJson && { ...historyJson }) || ({} as unknown as HotelsDisneySearchFormSubmitInterface);

		if (routePath.includes('hotels')) {
			const splitRoute: string[] = routePath.split('/');
			const positionToLength: number = 1;
			if (splitRoute.length >= roomsPosition + positionToLength) {
				const destinationIata = splitRoute[destinationPosition];
				history.destination = (destinationIata !== history?.destinationIata ? destinationIata : history?.destination || destinationIata) || '';

				history.destinationIata = destinationIata || '';

				const departureDate = splitRoute[departurePosition];
				const arrivalDate = splitRoute[arrivalPosition];
				history.departureDate = departureDate || '';

				history.arrivalDate = arrivalDate || '';

				const datesValues: DisplayValuesDatesFieldInterface = SearchFieldsUtil.getDisplayDatesFormmated(
					DatesFieldTypes.startEndDates,
					{
						time: false,
						date: true,
					},
					history.departureDate,
					'',
					history.arrivalDate,
					'',
				);

				history.dates = datesValues?.dates ? datesValues.dates : '';

				const adultsAndChildrenDivision: number = 2;
				const firstIndex: number = 0;
				const adultsIndex: number = 0;
				const childrenIndex: number = 1;
				const childrenWithAges: number = 2;
				const adultsDefault: number = 1;
				const childrenDefault: number = 0;
				const ageDefault: number = 0;
				const extraElement: number = 1;
				const roomsString: string = splitRoute[roomsPosition];
				const rooms: RoomsHotelsDisneySearchFormSubmitInterface[] = [];
				const roomsArray: string[] = roomsString.split('~');
				roomsArray.forEach((roomString: string) => {
					let adults: number = adultsDefault;
					let children: number = childrenDefault;
					const peopleArray: string[] = roomString.split('_');
					const isOkPeople: boolean =
						peopleArray?.length === adultsAndChildrenDivision &&
						peopleArray[adultsIndex].includes('adults') &&
						peopleArray[childrenIndex].includes('children');

					if (isOkPeople) {
						const adultsArray: string[] = peopleArray[adultsIndex].split('-adults');
						adults = adultsArray.length ? Number(adultsArray[firstIndex]) || adultsDefault : adultsDefault;

						const childrenArray: string[] = peopleArray[childrenIndex].split('-children');
						children = childrenArray.length ? Number(childrenArray[firstIndex]) || childrenDefault : childrenDefault;

						let childrenAges: number[] = [];
						if (childrenArray.length === childrenWithAges) {
							const childrenAgesStrings: string[] = childrenArray[childrenIndex].split('-');
							if (childrenAgesStrings.length === children + extraElement) {
								childrenAgesStrings.forEach((age: string, index: number) => {
									if (index + extraElement !== extraElement) {
										childrenAges.push(Number(age) || ageDefault);
									}
								});
							} else {
								childrenAges = [...Array(children).fill(ageDefault)];
							}
						}

						const passengersDisplayValue: string = SearchFieldsUtil.getDisplayPassengersInfo(
							adults,
							children,
							0,
							traductions && (traductions('adult') || 'adult'),
							traductions && (traductions('adults') || 'adults'),
							traductions && (traductions('child') || 'child'),
							traductions && (traductions('children') || 'children'),
							'',
							'',
						);

						rooms.push({
							adults,
							children,
							childrenAges: [...childrenAges],
							display: passengersDisplayValue,
						});
					}
				});

				history.rooms = [...rooms];

				history.urlPath = HotelsDisneySearchFormUtil.mapSearchUrl({ ...history });
			}
		}

		return { ...history };
	}
}
