import { useState, useEffect, useCallback } from 'react';

const useWindowHeight = (): number => {
	const [windowHeight, setWindowHeight] = useState<number>(0);
	const updateDimensions = useCallback(() => {
		const newHeight: number = Number(window.innerHeight) || 0;
		setWindowHeight(newHeight);
	}, []);

	useEffect(() => {
		updateDimensions();

		window.addEventListener('resize', updateDimensions);

		return () => window.removeEventListener('resize', updateDimensions);
	}, [updateDimensions]);

	return windowHeight;
};

export default useWindowHeight;
