import LoadingButton from '../../ui-elements/loading-button/loading-button';
import { IcomoonIcon } from '../../ui-elements/icomoon-icon/icomoon-icon';
import { CardInfoSubmit } from '../../../shared/interfaces/card-info';
import DatePicker, { registerLocale } from 'react-datepicker';
import BackDrop from '../../ui-elements/backdrop/backdrop';
import { useForm, SubmitHandler } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './credit-card-form.module.scss';
import { useState, forwardRef, Ref } from 'react';
import es from 'date-fns/locale/es';

export interface CreditCardFormProps {
	loading: boolean | null;
	token: string;
	onClose: () => void;
	t: (label: string) => string;
	getPoints: (cardData: CardInfoSubmit, token: string) => void;
}

export interface customInputProps {
	value?: string;
	onClick?: () => void;
}

interface MonthPickerProps {
	handleDateChange: (date: Date | null) => void;
}

function CreditCardForm({ loading, token, onClose, getPoints, t }: CreditCardFormProps): JSX.Element {
	registerLocale('es', es);

	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [displayCardNumber, setDisplayCardNumber] = useState<string>('');
	const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const numberToSend: string = e.target.value.replace(/[^\d]/g, '');
		const formattedNumber: string = numberToSend.replace(/(\d{4})(?=\d)/g, '$1 ');
		setDisplayCardNumber(formattedNumber);

		setValue('number', numberToSend, { shouldValidate: true });
	};

	const handleDateChange = (date: Date | null): void => {
		if (date) {
			setSelectedDate(date);

			const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
			const year: string = date.getFullYear().toString().slice(-2);
			const formattedDate: string = year + month;
			setValue('expirationDate', formattedDate, { shouldValidate: true });
		}
	};

	const {
		register,
		handleSubmit,
		setValue,
		formState: { isValid },
	} = useForm<CardInfoSubmit>({
		defaultValues: {
			firstName: '',
			number: '',
			expirationDate: '',
			verificationCode: '',
		},
	});

	const inputStyles: string =
		'credit-card-form__content--controllers credit-card-form__content--controllers__input border border-solid w-100 h-[40px] outline-none p-[10px] hover:shadow-lg border-[#BEBEBE] text-[#000000] text-[14px]';

	const labelStyles: string = 'text-[#4D4D4F] mb-[9px] text-[13px]';
	const sectionStyles: string = 'flex flex-col mb-[26px]';
	const onSubmit: SubmitHandler<CardInfoSubmit> = data => {
		getPoints(data, token);
	};

	const MonthPicker = ({ handleDateChange }: MonthPickerProps): JSX.Element => {
		const ExampleCustomInput = forwardRef(({ value, onClick }: customInputProps, ref: Ref<HTMLDivElement>) => (
			<div
				className={`credit-card-form__date-picker ${inputStyles} py-0 px-3 flex items-center justify-between text-[13px] text-[#bdbfc3]`}
				onClick={onClick}
				ref={ref}
			>
				{value ? <span className='text-black'>{value}</span> : <span>{t('selectAnOption')}</span>}
				<IcomoonIcon icon='searchCalendarIco' color='text-[#bdbfc3]' className='mr-1' fontSize={20} />
			</div>
		));

		return (
			<DatePicker
				locale={'es'}
				dateFormat='MM/yy'
				showMonthYearPicker
				selected={selectedDate}
				onChange={handleDateChange}
				customInput={<ExampleCustomInput />}
				minDate={new Date()}
				maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 15))}
			/>
		);
	};

	return (
		<div>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<BackDrop show={true} backgroundColor={'#060708'} opacity={0.85} zIndex={50} />
			<form className='credit-card-form bg-white z-50' onSubmit={handleSubmit(onSubmit)}>
				<button onClick={onClose} className='credit-card-form__close-btn absolute top-[25px] right-[24px] text-[#858383] font-bold'>
					X
				</button>
				<div className='credit-card-form__content grid grid-rows-[1fr_95px_95px_95px] grid-cols-1 gap-y-0'>
					<div className='credit-card-form__content--header flex flex-col mb-[25px] text-black'>
						<div className='flex flex-row items-center text-[20px] mb-[15px] min-w-[11px]'>
							<IcomoonIcon icon='iconStar' className='mr-1' fontSize={24} />
							<span>{t('consultMyPoints')}</span>
						</div>
						<p className='text-[14px]'>
							{t('inputYourData')}
							<span className='font-medium'> {t('bacCreditCard')}</span> {t('forConsultingPoints')}
						</p>
					</div>
					<div className={`credit-card-form__content--controllers ${sectionStyles}`}>
						<label className={labelStyles} htmlFor='cardHolder'>
							{t('cardHolderName')}
						</label>
						<input
							{...register('firstName', {
								required: true,
								pattern: /^[A-Za-z ]+$/i,
							})}
							className={inputStyles}
							type='text'
							id='cardHolder'
							name='cardHolder'
							onKeyDown={e => {
								if (!/^[A-Za-z ]+$/i.test(e.key)) {
									e.preventDefault();
								}
							}}
							onChange={e => setValue('firstName', e.target.value, { shouldValidate: true })}
						/>
					</div>
					<div className={sectionStyles}>
						<label className={labelStyles} htmlFor='cardNumber'>
							{t('cardNumber')}
						</label>
						<input
							{...register('number', { required: true, maxLength: 16, minLength: 15, pattern: /^[0-9]+$/i })}
							className={inputStyles}
							type='text'
							id='cardNumber'
							name='cardNumber'
							value={displayCardNumber}
							onKeyDown={e => {
								if (
									!/[0-9]/i.test(e.key) &&
									e.key !== 'Backspace' &&
									e.key !== 'Delete' &&
									e.key !== 'Tab' &&
									e.key !== 'ArrowLeft' &&
									e.key !== 'ArrowRight'
								) {
									e.preventDefault();
								} else if (e.currentTarget.value.length >= 19 && e.key !== 'Backspace' && e.key !== 'Delete') {
									e.preventDefault();
								}
							}}
							onChange={handleCardNumberChange}
						/>
					</div>
					<div className='credit-card-form__result--mobile grid grid-cols-2 gap-x-[20px]'>
						<div className={sectionStyles}>
							<label className={labelStyles} htmlFor='expirationDate'>
								{t('expirationDate')}
							</label>
							<MonthPicker handleDateChange={handleDateChange} />
							<input type='hidden' {...register('expirationDate', { required: true })} />
						</div>
						<div className={sectionStyles}>
							<label className={labelStyles} htmlFor='verificationCode'>
								{t('cvc')}
							</label>
							<input
								{...register('verificationCode', { required: true, maxLength: 4, minLength: 3 })}
								className={inputStyles}
								type='text'
								id='verificationCode'
								name='verificationCode'
								onKeyDown={e => {
									if (
										!/[0-9]/i.test(e.key) &&
										e.key !== 'Backspace' &&
										e.key !== 'Delete' &&
										e.key !== 'Tab' &&
										e.key !== 'ArrowLeft' &&
										e.key !== 'ArrowRight'
									) {
										e.preventDefault();
									} else if (e.currentTarget.value.length >= 4 && e.key !== 'Backspace' && e.key !== 'Delete') {
										e.preventDefault();
									}
								}}
								onChange={e => setValue('verificationCode', e.target.value, { shouldValidate: true })}
							/>
						</div>
					</div>
				</div>
				<div className='flex justify-end'>
					<LoadingButton type='submit' isButtonDisabled={!isValid} loading={loading} t={t} />
				</div>
			</form>
		</div>
	);
}

export default CreditCardForm;
