import { HistoricalDetailData, HistoricalDetailProductActivity } from '../../../../shared/interfaces';
import HistoricalPassengersQuantityDetail from './historical-passanger-quantity';
import HistoricalItemInfo from './historical-item-info';
import { translateDay } from '../../../../shared/utils';

export interface HistoricalProductActivityProps {
	colorPrimary?: string;
	colorSecondary?: string;
	t: (value: string) => string;
	historicalDetail: HistoricalDetailData<HistoricalDetailProductActivity>;
}

export function HistoricalProductActivity({ t, colorPrimary, colorSecondary, historicalDetail }: HistoricalProductActivityProps): JSX.Element {
	return (
		<>
			<div className='historical-detail__split my-8' />
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__activity'
				isIcon={true}
				classIcon='iconResumen'
				title='activity'
				value={historicalDetail.detail.name}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__duration'
				isIcon={true}
				classIcon='calendarBlankIco'
				title='activityDate'
				value={historicalDetail.detail.dateFormated}
			/>
			<HistoricalItemInfo
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				customClass='historical-detail__duration'
				isIcon={true}
				classIcon='calendarBlankIco'
				title='duration'
				value={`${historicalDetail.detail.duration} ${translateDay(t, historicalDetail.detail.duration)}`}
			/>
			<hr className='historical-detail__split my-3' />
			<h3 className={'flex flex-wrap text-[14px] text-gray-500'}>
				<span className='historical-detail__description mr-1' style={{ color: colorPrimary }}>
					{t('description')}:
				</span>
				<span
					className='max-h-[300px] overflow-x-auto'
					dangerouslySetInnerHTML={{
						__html: historicalDetail.detail.description,
					}}
				/>
			</h3>
			<hr className='historical-detail__split my-3' />
			<HistoricalPassengersQuantityDetail
				colorPrimary={colorPrimary}
				colorSecondary={colorSecondary}
				t={t}
				historicalPassengersQuantity={historicalDetail.passengerQuantity}
			/>
		</>
	);
}

export default HistoricalProductActivity;
