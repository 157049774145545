import styles from './image.module.scss';

/* eslint-disable-next-line */
export interface ImageProps {
	alt?: string;
	height?: string;
	url: string;
	width?: string;
	styles?: any;
	mobileLargeHeight?: boolean;
	customClass?: string;
	className?: string;
}

export function Image(props: ImageProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles.toString() }}></style>
			<div className={`image ${props.mobileLargeHeight ? 'image__largeFlex' : ''} ${props.className || ''}`}>
				<img
					className={`${props.mobileLargeHeight ? 'image__large' : ''} ${props.customClass || ''}`}
					style={{ ...props.styles }}
					src={props.url}
					alt={props.alt}
					width={props.width}
					height={props.height}
				/>
			</div>
		</>
	);
}

export default Image;
