import { TabData } from '../../../shared/interfaces/tabs';
import { Tab } from '../../ui-elements/tab/tab';

export interface TabsProps {
	activeTab: string;
	tabs: TabData[];
	clickOnEntireContainer?: boolean;
	onClick: (tab: string) => void;
	className?: string;
	tabClassName?: string;
	tabActiveClass?: string;
	desactiveColor?: string;
	activeColor?: string;
	isOutLine?: boolean;
	isPills?: boolean;
	isTertiaryTemplate?: boolean;
}

export const Tabs = ({
	activeTab,
	tabs,
	clickOnEntireContainer,
	onClick,
	className,
	tabActiveClass,
	tabClassName,
	desactiveColor,
	activeColor,
	isOutLine,
	isPills,
	isTertiaryTemplate,
}: TabsProps): JSX.Element => {
	return (
		<div className={`tabs flex flex-row md:justify-center md:items-center ${className || ''}`}>
			{tabs.map((tab: TabData) => (
				<Tab
					key={tab.id}
					idTab={tab.id}
					label={tab.label}
					active={activeTab === tab.id}
					disabled={tab.disabled}
					onClick={onClick}
					className={tab.className || tabClassName}
					activeClass={tabActiveClass}
					clickOnEntireContainer={clickOnEntireContainer}
					desactiveColor={desactiveColor || 'transparent'}
					activeColor={activeColor || '#213261'}
					isOutLine={isOutLine}
					isPills={isPills}
					isTertiaryTemplate={isTertiaryTemplate}
				/>
			))}
		</div>
	);
};

Tabs.defaultProps = {
	className: '',
	tabClassName: '',
};

export default Tabs;
