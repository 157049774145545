import { numberToLocaleWithcurrency } from '../../../../../shared/services/utils.service';
import { Cupon } from '../../../../../shared/interfaces/index';
import styles from './cupon-ticket.module.scss';

export interface CuponTicketProps {
	title: string;
	data: Cupon;
	currency: string;
	grandTotalAmount: number;
	notCuponTitle?: string;
	notCuponDescription?: string;
	conditionsTitle?: string;
	totalTitle?: string;
	linkConditions?: string;
}

export function CuponTicket(props: CuponTicketProps): JSX.Element {
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			{props.data ? (
				<div className={'container mx-auto m-1 px-10 py-5 rounded-xl contentCupon'}>
					<div className='flex mb-1'>
						{!props.data.isDiscount ? (
							<div className={'text-center ml-3 iconCupon'}>
								<span className={'cuponIco text-center icon'}></span>
							</div>
						) : (
							<div className={'text-center ml-3 iconCupon'}>
								<span className={'discountIco text-center icon'}></span>
							</div>
						)}
						<div className='w-4/5'>
							<h4 className='text-center font-sans text-xs mb-1 text-white	text-opacity-75 my-1 Lato-Regular'>{props.title.toUpperCase()}</h4>
							<h2 className='text-white font-bold text-center text-xl leading-5 '>
								{props.currency} {props.data.fixedAmount ? numberToLocaleWithcurrency(props.data.fixedAmount || 0, props.currency) : 0}
							</h2>
						</div>
					</div>
					<hr className={'my-2 hrCupon'} />
					<div className='h-12 flex flex-col items-center justify-center'>
						<h4 className='text-white font-sans text-xs'>
							{props.totalTitle}{' '}
							<b>
								{props.currency} {numberToLocaleWithcurrency(props.grandTotalAmount || 0, props.currency)}
							</b>
						</h4>
						{!props.data.isDiscount ? (
							<a href={props.linkConditions} target='_blank' className='text-white font-sans text-xs italic ' rel='noreferrer'>
								{props.conditionsTitle}
							</a>
						) : null}
					</div>
				</div>
			) : (
				<div className={'container mx-auto m-5  px-10 rounded-xl contentCupon contentNotCupon'}>
					<div className='flex mb-1'>
						<div className='w-full'>
							<div className={'text-center'}>
								<span className={'notCouponIco text-center icon'}></span>
							</div>
							<h4 className='text-center font-sans text-sm mb-1 text-white	text-opacity-75 my-1 Lato-Regular'>{props.notCuponTitle}</h4>
							<h4 className='text-center font-sans text-sm mb-1 text-white	text-opacity-75 my-1 Lato-Regular'>{props.notCuponDescription}</h4>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default CuponTicket;
