import { FlightsSearchFormSubmitInterface, DestinationsFlightsSearchFormSubmitInterface } from '../interfaces/flights-search-form.interface';
import { FlightAirlinesTypes, FlightCabinsTypes, FlightTripTypes, OnlyDirectFlightTypes } from '../enums/flight-types.enum';
import { ObjectLiteral } from '../../../../shared/enums/object-literal.enum';

const EMPTY_TEXT: string = '';
const ARRIVAL_DATE_DEFAULT: string = '00000000';
export class FlightsSearchUrlUtil {
	static mapSearchUrlDestination(data: FlightsSearchFormSubmitInterface): string {
		const firstIndex: number = 0;
		let destinationsUrl: string = '';
		if (data.tripType !== FlightTripTypes.multiple) {
			destinationsUrl = `&departure=${data.destinations[firstIndex]?.departure.airportCode || EMPTY_TEXT}&arrival=${
				data.destinations[firstIndex]?.arrival.airportCode || EMPTY_TEXT
			}&departureDate=${data.destinations[firstIndex]?.departure?.date?.replace(/-/g, EMPTY_TEXT) || EMPTY_TEXT}&arrivalDate=${
				data.tripType === FlightTripTypes.oneway
					? ARRIVAL_DATE_DEFAULT
					: `${data.destinations[firstIndex]?.arrival?.date?.replace(/-/g, EMPTY_TEXT) || ''}`
			}`;
		}

		return destinationsUrl;
	}

	static mapSearchUrlDestinationMultiple(data: FlightsSearchFormSubmitInterface): string {
		let destinationsUrl: string = '';
		let departure: string = '';
		let arrival: string = '';
		let departureDate: string = '';
		const separator: string = ',';
		const ignoreSeperatorIndex: number = 1;
		let countValidData: number = 0;
		data.destinations.forEach((destination: DestinationsFlightsSearchFormSubmitInterface) => {
			if (destination?.departure?.airportCode === EMPTY_TEXT) return;

			countValidData++;

			const _separator: string = countValidData === ignoreSeperatorIndex ? EMPTY_TEXT : separator;
			departure += `${_separator}${destination?.departure?.airportCode || EMPTY_TEXT}`;

			arrival += `${_separator}${destination?.arrival?.airportCode || EMPTY_TEXT}`;

			departureDate += `${_separator}${destination?.departure?.date?.replace(/-/g, EMPTY_TEXT) || EMPTY_TEXT}`;
		});

		destinationsUrl = `&departure=${departure}&arrival=${arrival}&departureDate=${departureDate}&arrivalDate=${ARRIVAL_DATE_DEFAULT}`;

		return destinationsUrl;
	}

	static mapSearchUrl(data: FlightsSearchFormSubmitInterface): string {
		const detinationPatchs = {
			[FlightTripTypes.multiple]: FlightsSearchUrlUtil.mapSearchUrlDestinationMultiple(data),
			[ObjectLiteral.default]: FlightsSearchUrlUtil.mapSearchUrlDestination(data),
		};

		const destinationsUrl: string = detinationPatchs[data.tripType] || detinationPatchs[ObjectLiteral.default];
		const searchUrl: string = `?trip=${data.tripType || FlightTripTypes.round}${destinationsUrl}&adults=${data.adults || 1}&children=${
			data.children || 0
		}&infants=${data.infants || 0}${!data.cabin || data.cabin === FlightCabinsTypes.all ? '' : `&cabin=${data.cabin}`}${
			!data.airlineCode || data.airlineCode === FlightAirlinesTypes.all ? '' : `&airline=${data.airlineCode}`
		}${data.onlyDirectFlight ? `&scale=${OnlyDirectFlightTypes.direct}` : ''}${data?.promoCode ? `&promoCode=${data.promoCode}` : ''}`;

		return searchUrl;
	}
}
