import useTextSkeleton from '../../../../../../../shared/hooks/useTextSkeleton';
import useWindowWidth from '../../../../../../../shared/hooks/useWidth';

/* eslint-disable-next-line */
export interface GenericIntroductionSkeletonProps {
	className?: string;
	containerClassName?: string;
}

export function GenericIntroductionSkeleton({ className, containerClassName }: GenericIntroductionSkeletonProps): JSX.Element {
	const responsiveSize: number = 768;
	const windowWidth: number = useWindowWidth();
	const descriptionComnponents = useTextSkeleton(
		2,
		5,
		20,
		windowWidth >= responsiveSize ? 95 : 85,
		windowWidth >= responsiveSize ? 100 : 90,
		'genericIntroductionSkeleton__description mt-2 h-3 md:h-4',
	);

	return (
		<div className={`genericIntroductionSkeleton flex justify-center ${className || ''}`}>
			<div className={`genericIntroductionSkeleton__content flex flex-col items-center mb-7 md:mb-14 ${containerClassName || ''}`}>
				<div className='genericIntroductionSkeleton__line h-px w-6 md:w-12 border-b-4 rounded-xl mb-4 border-gray-200 bg-gray-200' />
				<p className='genericIntroductionSkeleton__subtitle mb-2.5 md:mb-4 bg-gray-200 h-3 w-20' />
				<h2 className='genericIntroductionSkeleton__title h-6 md:h-12 w-2/4 bg-gray-200 mb-2.5 md:mb-6' />
				<div className='genericIntroductionSkeleton__descriptionContainer w-full'>{descriptionComnponents}</div>
			</div>
		</div>
	);
}

export default GenericIntroductionSkeleton;
