import { HotelsSearchFormSubmitInterface, HotelsSearchFormUtil } from '@smartlinks/react-design-system';
import { endPoints } from '../end-points/end-points';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { ResponseCommons } from '../../shared/interfaces/commons.interface';
import { HotelsSearchLocationsInterface } from '../../shared/interfaces/hotels-locations.interface';
import { CommonsService } from './commons.service';

export class HotelsService extends CommonsService {
	constructor(token: string) {
		super(token, LocalStorageHistoriesNames.hotelsSearch);
	}

	public async getLocationsByWords(value: string): Promise<HotelsSearchLocationsInterface[] | null> {
		const url: string = `${this.managementApi}/${endPoints.hotelsLocationsByWords(value)}`;
		const locationsResponse: ResponseHttp<HotelsSearchLocationsInterface[]> = await this.httpCall.get(url, this.header);
		if (locationsResponse.response != null) {
			return locationsResponse.response;
		}

		return null;
	}

	public getHotelsSearchFormHistory(traductions?: (label: string) => string): HotelsSearchFormSubmitInterface | null {
		const tempHistory: ResponseCommons<HotelsSearchFormSubmitInterface> = this.getHistory<HotelsSearchFormSubmitInterface>(
			HotelsSearchFormUtil.historyHandle,
			traductions,
		);

		return tempHistory?.response || null;
	}
}
