import { useState, useEffect } from 'react';
import { ListInterface, ListItemInterface } from '../../../shared/interfaces/list.interface';
import { PopUpDialogEventInterface } from '../../../shared/interfaces/pop-up-dialog.interface';
import IcomoonIcon from '../../ui-elements/icomoon-icon/icomoon-icon';
import { Modal } from '../../cars/organisms/generics/modal/modal';
import List from '../../ui-elements/list/list';

export interface MobileBottomBarProps {
	list: ListInterface;
	submenuList?: ListInterface[];
	activeItem: string;
	mainColor: string;
	showSubMenuModal?: boolean;
	zIndexModal?: number;
	extraZIndexModal?: number;
	topModal?: string;
	onItemClick: (item: ListItemInterface) => void;
	onCloseModal?: (data: PopUpDialogEventInterface) => void;
	className?: string;
	itemClassName?: string;
	contentClassName?: string;
	modalClassName?: string;
}

export function MobileBottomBar({
	list,
	submenuList,
	activeItem,
	mainColor,
	showSubMenuModal,
	zIndexModal,
	extraZIndexModal,
	topModal,
	onItemClick,
	onCloseModal,
	className,
	itemClassName,
	contentClassName,
	modalClassName,
}: MobileBottomBarProps): JSX.Element {
	const [newItems, setNewItems] = useState<ListInterface | null>(null);
	useEffect(() => {
		const newItems: ListItemInterface[] = list.items.map((item: ListItemInterface): ListItemInterface => {
			const active: boolean = activeItem === item.id;
			return {
				...item,
				name: (
					<div className={`flex flex-col ${itemClassName || ''}`}>
						{active && (
							<div
								className='w-full border-b-4'
								style={{
									borderColor: mainColor,
								}}
							/>
						)}
						{item?.icon && <IcomoonIcon icon={item.icon} className='mt-3.5' fontSize={22} color={active ? mainColor : ''} />}
						<div className='font-normal text-xs'>{item.name}</div>
					</div>
				),
			};
		});

		setNewItems({ ...list, items: [...newItems] });
	}, [list, activeItem]);

	return (
		<>
			<Modal
				width={768}
				show={showSubMenuModal || false}
				zIndex={zIndexModal}
				backgroundColor='rgba(0,0,0,0.4)'
				idComponent='newsletter-subscription'
				onBackdropClick={onCloseModal}
				onCloseClick={onCloseModal}
				closeIconSize={18}
				showCloseButton={true}
				className={`pt-5 pb-7 px-6 ${modalClassName || ''}`}
				top={topModal || '0'}
				extraZIndex={extraZIndexModal}
			>
				<div className='flex-grow flex flex-col'>
					{submenuList && !!submenuList.length && (
						<List
							items={submenuList}
							callBack={onItemClick}
							withPointer={true}
							ulClassName={'flex-grow'}
							itemsClassName='w-full flex flex-col'
							className='flex-grow flex flex-col '
						/>
					)}
				</div>
			</Modal>
			<div className={`mobileBottomBar fixed md:hidden bottom-0 right-0 w-full ${className || ''}`}>
				{newItems && newItems.items && !!newItems.items.length && (
					<List
						items={[newItems]}
						callBack={onItemClick}
						withPointer={true}
						ulClassName={`w-full flex flex-row items-center ${contentClassName || ''}`}
						itemsClassName='w-full'
						className='w-full'
					/>
				)}
			</div>
		</>
	);
}

MobileBottomBar.defaultProps = {
	className: '',
	itemClassName: '',
	modalClassName: '',
};

export default MobileBottomBar;
