import { PlansSearchFormSubmitInterface, PlansSearchFormUtil } from '@smartlinks/react-design-system';
import { endPoints } from '../end-points/end-points';
import { LocalStorageHistoriesNames } from '../enums/local-storage.enum';
import { ResponseHttp } from '../interfaces/response-http.interface';
import { ResponseCommons } from '../interfaces/commons.interface';
import { CommonsService } from './commons.service';

export class PlansService extends CommonsService {
	constructor(token: string) {
		super(token, LocalStorageHistoriesNames.plansSearch);
	}

	public async getCategories(): Promise<any[] | null> {
		const url: string = `${this.managementApi}/${endPoints.airports}`;
		const locationsResponse: ResponseHttp<any[]> = await this.httpCall.get(url, this.header);
		if (locationsResponse.response != null) {
			return locationsResponse.response;
		}

		return null;
	}

	public getPlansSearchFormHistory(traductions?: (label: string) => string): PlansSearchFormSubmitInterface | null {
		const tempHistory: ResponseCommons<PlansSearchFormSubmitInterface> = this.getHistory<PlansSearchFormSubmitInterface>(
			PlansSearchFormUtil.historyHandle,
			traductions,
		);

		return tempHistory?.response || null;
	}
}
