export enum ProductType {
	air = 'air',
	hotel = 'hotel',
	offlineHotel = 'offlineHotel',
	car = 'car',
	plan = 'plan',
	transfer = 'transfer',
	travelPackage = 'travelPackage',
	assistance = 'assistance',
	activity = 'activity',
}

export enum ProductStatus {
	canceled = 'canceled',
	pending = 'pending',
	booked = 'booked',
	issued = 'issued',
	paymentPending = 'paymentPending',
}

export enum TicketStatus {
	issued = 'ET',
	canceled = 'EV',
}
