import useTextSkeleton from '../../../../../../shared/hooks/useTextSkeleton';
import styles from './skeleton-newsletter-subscription.module.scss';
import Input from '../../../../../ui-elements/input/input';

export interface SkeletonNewsletterSubscriptionProps {
	image?: string;
	className?: string;
	contentClassName?: string;
	inputClassName?: string;
	style?: any;
}

export const SkeletonNewsletterSubscription = ({
	image,
	className,
	contentClassName,
	inputClassName,
	style,
}: SkeletonNewsletterSubscriptionProps): JSX.Element => {
	const descriptionComnponents = useTextSkeleton(2, 4, 20, 85, 90, 'mt-2 h-3 md:h-4');
	return (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`skeletonNewsletterSubscription animate-pulse ${className || ''}`}>
				<div
					className={'skeletonNewsletterSubscription__content flex flex-col justify-center rounded-3xl'}
					style={{
						...style,
					}}
				>
					<div
						className={`${contentClassName || ''} flex flex-col items-center justify-center w-full`}
						style={{
							backgroundImage: image ? `url(${image})` : undefined,
						}}
					>
						<h3 className='mb-2.5 md:mb-4 bg-gray-200 h-5 md:h-9 w-4/5' />
						{descriptionComnponents}
						<div className={'w-full flex skeletonNewsletterSubscription__form flex-row justify-center items-center mt-5 md:mt-4'}>
							<Input
								inputClassName={`skeletonNewsletterSubscription__input font-medium ${inputClassName || ''}`}
								className='w-full md:w-80 px-0'
								disabled={true}
							/>
							<div className='skeletonNewsletterSubscription__button h-12 w-full md:w-32 md:ml-4 rounded-full bg-gray-200' />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

SkeletonNewsletterSubscription.defaultProps = {
	className: '',
	contentClassName: '',
	inputClassName: '',
};

export default SkeletonNewsletterSubscription;
