import { CMSGeneralComponentInterface } from '../../../../../shared/interfaces/generics/cms.interface';
import { GenericIntroduction } from '../introductions/generic-introduction/generic-introduction';
import { SkeletonBlogFeaturedStories } from './skeleton/skeleton-blog-featured-storie';
import { Image } from '../../../../ui-elements/image/image';
import styles from './blog-featured-storie.module.scss';
import { useState, useEffect } from 'react';

export interface BlogFeaturedStoriesProps extends CMSGeneralComponentInterface {
	loading: boolean;
	customClass?: string;
	introductionContainerClassName?: string;
	introductionClassName?: string;
	featurecontainerClassName?: string;
	titleClassName?: string;
	descriptionClassName?: string;
	linkClassName?: string;
	pillClassName?: string;
}

export function BlogFeaturedStories({
	title,
	subtitle,
	description,
	slides,
	loading,
	customClass,
	introductionContainerClassName,
	introductionClassName,
	featurecontainerClassName,
	titleClassName,
	descriptionClassName,
	linkClassName,
	pillClassName,
}: BlogFeaturedStoriesProps): JSX.Element {
	const [showSkeleton, setShowSkeleton] = useState(true);
	useEffect(() => {
		setShowSkeleton(loading);
	}, [loading]);

	return showSkeleton ? (
		<SkeletonBlogFeaturedStories
			className={customClass}
			introductionClassName={`genericIntroduction ${introductionClassName || ''}`}
			introductionContainerClassName={introductionContainerClassName}
		/>
	) : slides?.length ? (
		<>
			<style dangerouslySetInnerHTML={{ __html: styles?.toString() || '' }} />
			<div className={`blogFeaturedStory ${customClass || ''}`}>
				<GenericIntroduction
					title={title}
					subtitle={subtitle}
					description={description}
					customClass={`genericIntroduction ${introductionClassName || ''}`}
					containerClassName={introductionContainerClassName}
					titleClassName='text-black'
					subtitleClassName='text-[#7a8083]'
					descriptionClassName='text-[#494848] w-[95%] md:w-4/5'
					lineClassName='bg-[#7A8083] border-[#7A8083]'
					linkText=''
					link=''
				/>
				<div
					className={`${
						featurecontainerClassName || ''
					} h-[600px] md:h-[400px] rounded-[20px] bg-black text-white flex flex-col-reverse md:flex-row w-full relative`}
				>
					<div className={'blogFeaturedStory__infoContainer flex flex-col justify-center items-center w-full md:w-[40%] h-[50%] md:h-auto'}>
						<h2 className={`blogFeaturedStory__title w-[80%] md:w-[60%] text-base md:text-2xl mb-2.5 ${titleClassName || ''}`}>{slides[0].title}</h2>
						<div className='w-[80%] md:w-[60%]'>
							<p className={`blogFeaturedStory__description text-xs mb-2.5 ${descriptionClassName || ''}`}>{slides[0].description}</p>
							<a
								href={slides[0].link || ''}
								target='_blank'
								className={`blogFeaturedStory__link flex items-center text-xs md:text-base ${linkClassName || ''}`}
								rel='noreferrer'
							>
								<p>{slides[0].linkText}</p>
								<span className='iconIconArrowRightSelect ml-2.5 text-xs'></span>
							</a>
						</div>
					</div>
					<div className={'blogFeaturedStory__imageContainer'}>
						<Image url={slides[0].image || ''} className={'blogFeaturedStory__backdrop'} />
						<div className={`blogFeaturedStory__pill flex absolute items-center justify-center text-xs ${pillClassName || ''}`}>
							<span className='iconIconRoundedLineStar'></span>
							<p>{slides[0].subtitle}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	) : (
		<></>
	);
}
