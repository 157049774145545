import enUS from 'date-fns/locale/en-US';
import { DateLocales } from '../enums';
import pt from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';
import { Locale } from 'date-fns';

export const getDateLocal = (locale?: DateLocales): Object => {
	const locales: Record<string, Locale> = { es, 'en-US': enUS, en: enUS, 'pt-BR': pt };
	return locale && locales[locale] ? locales[locale] : locales.es;
};
